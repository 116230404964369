(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:ContactDataVerificationCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('ContactDataVerificationCtrl', ContactDataVerificationCtrl);

  function ContactDataVerificationCtrl($rootScope, $state, $http, $timeout, UserSettingsService, AlertService, HelperService, NotificationService) {
    var vm = this;

    vm.sendSMSDisabled = false;
    vm.steps = {
      input: true,
      resume: false,
      finish: false
    };

    vm.model = {
      gsm: '',
      email: "",
      isConfirmed: false
    };

    vm.isPhoneVerification = $state.current.url.includes('phone');
    var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
    if (vm.isPhoneVerification) {
      vm.model.gsm = userSettings.gsm;
    } else {
      vm.model.email = userSettings.notificationEmail || userSettings.email;
    }
    function createInputObject() {
      return {
        email: vm.model.email,
        dataConfirmation: vm.model.isConfirmed,
        gsm: vm.model.gsm,
        signData: vm.model.signData
      }
    }

    vm.next = function () {
      if (vm.validationInProgress) return;
      vm.formSubmitted = true;
      if (vm.contactDataForm.$invalid) return;
      vm.validationInProgress = true;
      if (vm.model.gsm) {
        vm.model.gsmPhoneFormatted = vm.model.gsm.replace(/(.{3})/g, '$1 ').trim();
      }
      vm.model.orderDate = new Date();
      vm.steps = {
        input: false,
        resume: true,
        finish: false
      }
    };

    vm.confirm = function () {
      if (vm.confirmationInProgress) return;

      vm.resumeFormSubmitted = true;
      if (vm.phoneNumberResumeForm.$invalid) return;
      vm.confirmationInProgress = true;

      UserSettingsService.updateUserInfoOrders(createInputObject()).then(function(response) {
        AlertService.infoAlert({
          text: vm.isPhoneVerification ? "mobilePhoneSettings.numberChangeSuccess" : "emailVerification.emailChangeSuccess"
        }).then(function () {
          $state.go("settings", {}, {reload: true})
        })
        
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      }).finally(function () {
        vm.confirmationInProgress = false;
      });
    };

  }
}());
