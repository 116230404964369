<section id="main">
  <div class="container">
    <div class="row main-row">
      <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
        <h2 translate="documents.header" class="title-style"></h2>
      </div>

      <div class="timeline-row col-sm-12">




        <div class="hypo-timeline-wrapper app-white-card ">

          <div class="row p-15" >
            <div class="col-sm-12 p-10 bgm-white" style="z-index:1;">
              <div class="col-sm-6 col-xs-12 p-0">
                <div class="tml-filter-container tmlf-wide" style="overflow: hidden;" id="searchFilterExpandedDiv" click-outside="dashboard.searchFilterExpanded = false;">
                  <div id="searchBtnLeftDiv" class="inner-section left" >
                    <i class="icon icon-search  icon-hc-2x m-l-5"></i>
                  </div>
                  <div id="searchBtnCenterDiv" class="inner-section center">
                    <input id="searchTimelineInput" type="text" class="search-input" placeholder="{{'timelineFilter.placeholder'|translate}}"
                           ng-model="domesticPaymentSlo.tmpSearchText" ng-model-options="{ debounce: 300 }"
                    >
                  </div>
                  <div ng-show="dashboard.searchInputExpanded" id="searchBtnRightDiv" class="inner-section right" ng-init="dashboard.showDeleteInputBtn = false">
                    <i ng-show="!dashboard.showDeleteInputBtn" class="icon icon-filter icon-hc-2x i-r-0" ng-click="dashboard.toggleFilterSelection();"></i>
                    <i ng-show="dashboard.showDeleteInputBtn" class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0" ng-click="dashboard.timelineSearchFilter.descriptionFTS = ''; dashboard.timelineSearchAction(); dashboard.showDeleteInputBtn = false"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-12 p-0 p-r-10">
                <div class="dropdown acc-period-select pull-right" style="display: inline-block">
                  <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    {{documents.selectedPeriod.item.description | translate}}
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li data-ng-repeat="period in documents.periodList">
                      <a href="" class="f-13" data-ng-click="documents.selectPeriod(period)">
                        {{period.item.description | translate}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="t-a-c m-t-10 p-10 no-data-image" ng-show="documents.timelineListGrouped.result.length == 0">
            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
            <h3 class="no-data-label" translate="documents.noItemData"></h3>
          </div>
          <div class="timeline-row col-sm-12 p-l-0 p-r-0"
               ng-show="documents.timelineListGrouped.result.length > 0">
            <div class="hypo-timeline-wrapper app-white-card no-top-radius p-b-10">
              <ul class="hypo-timeline" >
                <li data-ng-repeat="item in documents.timelineListGrouped.result | filter:domesticPaymentSlo.tmpSearchText" ng-class="{'time-label': item.isVirtual === true, 'tl-badge-icon': item.isVirtual === undefined}">
                  <span ng-if="item.isVirtual === true && documents.country == 'hr'" class="bg-white">{{item.date | date:'shortDate' }}</span>
                  <span ng-if="item.isVirtual === true && documents.country == 'sl'" class="bg-white">{{item.date }}</span>
                  <i ng-if="item.isVirtual == undefined" class="zmdi icon f-18 lh-27 c-nar icon-acrobat"></i>

                  <div ng-if="item.isVirtual == undefined" class="hypo-timeline-item">

                    <div class="hypo-timeline-body">
                      <div class="row">
                        <div class="col-md-9 col-sm-6 col-xs-12 t-a-l ">
                          <div class="row">
                            <div class="col-xs-12">
                              {{item.date | date}}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <h4 ng-if="item.title" class="m-b-0 m-t-0 c-gray">
                                <b ng-if="item.unread">{{item.title}}</b>
                                <span ng-if="!item.unread">{{item.title}}</span>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 t-a-r">
                          <button type="input" class="btn btn-default app-btn-orange m-b-0" translate="documents.download"
                                  ng-disabled="item.downloadPdfInProgress"
                                  ng-click="documents.downloadPdf(item)"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>


        </div>


      </div>

    </div>
    <div class="row">
      <div class="show-more-btn">
        <button ng-show="documents.timelineListGrouped.hasMore" data-ng-click="documents.loadMoreTimelineItemsGrouped()" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="documents.loadEvents">Load older events</button>
      </div>
    </div>
  </div>
</section>
