(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name dashboard.controller:QuickPaymentModalCtrl
     *
     * @description
     *
     */
    angular
        .module('dashboard')
        .controller('QuickPaymentModalCtrl', QuickPaymentModalCtrl);

    function QuickPaymentModalCtrl($scope, $uibModalInstance) {
        var vm = this;
        vm.ctrlName = 'QuickPaymentModalCtrl';

        vm.qpModalDate = null;
        vm.getDate = function () {
            vm.qpModalDate =  new Date();
        };
        vm.getDate();
        vm.datepickerPopup = {
            opened: false
        };
        vm.openDatepicker = function() {
            vm.datepickerPopup.opened = true;
        };
        vm.dateOptions = {
            dateDisabled: disabled,
            formatYear: 'yy',
            minDate: new Date(),
            maxDate: vm.qpModalDate.getMonth+6,
            startingDay: 1
        };
        vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
        vm.format = vm.formats[0];
        function disabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        }

        vm.toAccountType = 1;

        vm.closeQPModal = function(){
            $uibModalInstance.close();
        };

    }
}());
