(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name dashboard.factory:QuickPaymentModalService
     *
     * @description
     *
     */
    angular
        .module('dashboard')
        .factory('QuickPaymentModalService', QuickPaymentModalService);

    function QuickPaymentModalService($uibModal, $log) {
        var QuickPaymentModalServiceBase = {};
        QuickPaymentModalServiceBase.someValue = 'QuickPaymentModalService';
        QuickPaymentModalServiceBase.openQPModal = function () {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'dashboard/quick-payment-modal.tpl.html',
                controller: 'QuickPaymentModalCtrl',
                controllerAs: 'qpModalCtrl',
                size: 'lg'
            });

            modalInstance.result.then(function (selectedItem) {
                
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });


            return 'QuickPaymentModalService';
        };
        return QuickPaymentModalServiceBase;
    }
}());