(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      payments: {
        "quickPaymentTransfer": "Brzo plaćanje ili transfer",
        "domesticPayments": "Domaći platni promet",
        "currencyConversion": "Konverzija valuta",
        "internationalPayment": "Internacionalno plaćanje",
        "rejectedPayments": "Odbijene uplate",
        "pendingPayments": "Uplate na čekanju",
        "templates": "Šabloni",
        "searchTemplateName": "Traži predložak po nazivu",
        "topUsed": "Najčešće upotrebljeni",
        "alphabetically": "Po abecedi",
        "to": "ka",
        "showMoreTemplates": "Prikaži više šablona",
        "eInvoices": "eRačuni",
        "showMoreInvoices": "Prikaži sve eRačune",
        "sepaDirectDebit": "SEPA izravno dugovanje",
        "viewAllTransactions": "Pregled svih transakcija",
        "due": "dospio",
        "allPastPayments": "Pregled svih izvršenih plaćanja",
        "exchangeRatesList": "Tečajna lista",
        "pay": "Plati",
        "internalTransfer": "Interni prenos",
        "noEinvoices": "Nema podataka za eRačune",
        "noTemplates": "Nema podataka za predloške",
        "forCurrencyConversion": "pretvorba valuta",
        "midrate": "srednja stopa",
        "forForeignNotes": "strane bilješke, čekovi",
        "onDate": "dana ",
        "buy": "Kupi",
        "goBackToPayments": "Povratak na plaćanja",
        "currencyName": "Naziv valute",
        "code": "Kod",
        "unit": "Jedinica",
        "buyRate": "Kupovni kurs",
        "middleRate": "Srednji kurs",
        "sellRate": "Prodajni kurs",
        "action": "Akcija",
        "noRejectedPayments": "Za odabrani period nema odbijenih uplata",
        "noPendingPayments": "Za odabrani period nema uplata na čekanju",
        "donationToCharity": "Donacija u dobrotvorne svrhe",
        "subscribe": "Pretplata",
        "settings": "Postavke",
        "moreRejected": "Još odbijenih plaćanja",
        "morePending": "Još plaćanja na čekanju",
        "currencyExchange": "Mjenjačnica",
        "exchange": "Razmjeni",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "willSell": "Prodaja",
        "willBuy": "Kupovina",
        "available": "Dostupno",
        "delete": "Obriši",
        "edit": "Promijeni",
        "loanPayment": "Plaćanje kredita",
        "paymentMode": "Svrha plaćanja",
        "toAcc": "na račun",
        "fromAcc": "s računa",
        "bicNumber": "BIC broj",
        "bicBankName": "Banka primaoca",
        "bicAddress": "BIC adresa / SWIFT kod",
        "bicCity": "BIC grad",
        "bicCountry":"Država",
        "bicRecipientStatus": "Primalac (osoba)",
        "bicRecipientStatusOptionLegal": "Pravna",
        "bicRecipientStatusOptionPhysical": "Fizička",
        "bicBankCharges": "Troškovna opcija",
        "bicNumberRequired": "BIC adresa je obavezna!",
        "bicBankNameRequired": "Banka primaoca je obavezna!",
        "bicCityRequired": "BIC grad je obavezan!",
        "bicCountryRequired": "Država je obavezna!",
        "creditRefNumberRequired": "Ref. broj kredita je obavezan!",
        "creditYearRequired": "Godina kredita je obavezna!",
        "resend": "Pošalji ponovo",
        "minimalAccountNumber": "Račun nije ispravan!",
        "cancel": "Odustani",
        "templateSavedSuccessfully": "Uspješno ste kreirali šablon !",
        "templateUpdatedSuccessfully": "Promjena šablona je uspješno izvršena!",
        "bankAddressInputRequired": "Bank address is required!."

      },
      paymentsOverview: {
        "pohReciver": " za ",
        "pohAmount": " u iznosu od ",
        "pohDate": " na dan ",
        "povlStatus": "Status",
        "povlId": "Id naloga",
        "povlReceiver": "Naziv primaoca",
                "povlFrom": "Sa računa",
        "povlTo": "Na račun",
        "povlAmount": "Iznos",
        "povlDueDate": "Datum izvršenja",
        "povlValueDate": "Datum valutacije",
        "filterStatus": "Filtriraj po statusu",
        "loadMoreBtn": "Prikaži još naloga",
        "referenceTo": "Referentni broj",
        "titleLabel": "Lista naloga",
        "signatoryName": "Potpisnik",
        "signDate": "Datum akcije",
        "processed2": "Procesuiran",
        "paymentGroupType": "Vrsta plaćanja",
        "paymentsType": {
          "all": "Svi",
          "opened": "Nepotpisan",
          "partiallysigned": "Djelimično potpisani",
          "cancelled": "Otkazani",
          "processed2": "Procesuiran",
          "processed": "Realizovan",
          "rejected": "Odbijen",
          "closed": "Zatvoren",
          "signed": "Potpisan",
          "notSigned": "Nepotpisani",
          "inprocessing": "U obradi",
          "forcancelling": "Za otkazivanje",
          "storned": "Stornirani",
          "rejectedaftercomplaint": "Odbijeni nakon žalbe",
          "acceptedaftercomplaint": "Prihvaćeni nakon žalbe",
          "allTab": "Svi",
          "realizedTab": "Realizovani nalozi",
          "pendingTab": "Nalozi na čekanju",
          "rejectedTab": "Odbijeni nalozi"
        },
        "status":{
              "Opened":"Nepotpisan",
              "Rejected":"Odbijen",
              "WaitingQueue":"Na čekanju",
              "Processed":"Realizovan",
        },

        "dateFrom": "vrijeme od",
        "dateTo": "vrijeme do",
        "filterByPurpose": "Pretraži po namjeni",
        "noPaymentData": "Za odabrani filter nema naloga.",
        "selectAll": "Označi sve",
        "selectNone": "Odznači sve",
        "signSelectedPayments": "Potpiši odabrana plaćanja",
        "povlNote":"Napomena",
        "referenceNumber": "Referentni broj",
        "cancelled": "Opozvan",
        "processed": "Realizovan",
        "rejected": "Odbijen",
        "open": "Nepotpisan",
        "inProcessing": "U obradi",
        "waitingQueque": "Na čekanju",
        "signed": "Potpisan",
        "Cancelled": "Opozvan",
        "Processed": "Realizovan",
        "Processed2": "Procesuiran",
        "Rejected": "Odbijen",
        "Opened": "Nepotpisan",
        "InProcessing": "U obradi",
        "WaitingQueue": "Na čekanju",
        "Signed": "Potpisan"
      },
      domesticPayment: {
        "header": "Domaći platni promet",

                "fromAccountSelectLabel": "Sa računa",
        "availableAmountLabel": "Raspoloživo",
        "purposeCodeSelectLabel": "Šifra plaćanja",
        "purposeOfPaymentInputLabel": "Svrha plaćanja",
        "purposeModalInfoText": 'Molimo vas unesite precizne podatke u polje “Svrha plaćanja”',
        "amountInputLabel": "Iznos",
        "dateInputLabel": "Datum",
        "bicCodeInputLabel": "BIC kod",
        "toAccountInputLabel": "Račun primaoca",
        "toAccountInputLabelSRB": "Račun primaoca",
        "toPayee": "Primalac",
        "toAccountInputBtn": "Dopuni primaoca",
        "referenceNumberLabel": "Poziv na broj (odobrenja)",
        "referenceNumberLabelV2": "Poziv na broj (zaduženje)",
        "referenceModelLabel": "Model",
        "receiversNameInputLabel": "Ime primaoca",
        "receiversAddressInputLabel": "Adresa primaoca",
        "receiversPostalCodeAndCityInputLabel": "Poštanski broj i grad primaoca",
        "receiversCountrySelectLabel": "Zemlja primaoca",
        "payersNameInputLabel": "Ime i prezime pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljaoca",
        "payersCityInputLabel": "Grad pošiljaoca",
        "amountPlaceholder": "eg. 1.000,00",
        "reviewAndSubmitBtn": "Pregledajte i pošaljite",
        "selectUrgentLabel": "Označite kao hitnu uplatu",
        "selectUrgentLabelSRB": "Označite kao hitnu uplatu",
        "urgentPaymentInfo": "Za usluge platnog prometa naknada će biti obračunata u skladu sa Tarifnikom Banke.",
        "processingFeeLabel": "Naknada za obradu naloga",
        "receiverAccountError": "Račun primaoca je obavezan!",
        "receiverAccountErrorHR":"IBAN / Broj računa primaoca je obavezan!",
        "receiverNameError": "Naziv primaoca je obavezan!",
        "receiverCityError": "Grad primaoca je obavezan!",
        "receiverAddressError": "Adresa primaoca je obavezna!",
        "sepaDebitorLabel": " Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primaoc",
        "confirmBtn": "Potvrdi",
        "selectFromTemplate": "Odaberi iz šablona",
        "listOfTemplates": "Lista šablona",
        "bankCharges": "Provizija",
        "urgentPayment": "Ovaj nalog je hitan",
        "checkBankCharges": "Provjeri dodatne naknade",
        "referenceModelInputError": "Poziv na broj je obavezan!",
        "referenceModelInputInvalid": "Poziv na broj nije ispravan!",
        "toPayeeInputError": "Naziv primaoca je obavezan!",
        "toPayee2":"Primalac (naziv/ime i adresa)",
        "newPayment":"Novi nalog",
        "delete":"Obriši",
        "edit":"Izmijeni",
        "sign":"Potpiši",
        "ibanStructError":"IBAN nije ispravan"
      },
      internationalPayment: {
        "guide": "Uputstvo za ino plaćanja",
        "hasAvailableBalanceWarning": "Iznos uplate je veći od preostalog iznosa na vašem računu",
        international: "Internacionalno plaćanje",
        "totalAmountWithFee": "Ukupan iznosa sa naknadom",
        "email": "Email za slanje SWIFT-a",
        "phone": "Broj telefona",
        required: "Obavezan unos.",

        "fromAccount": "Sa računa",
        "chargeAccount": "Račun naknade",
        "totalAmount": "Ukupan iznos i valuta",
        "paymentDate": "Datum plaćanja",
        "paymentPurpose": "Opis transakcije",
        "paymentPurposeReceiverInfo": "Svrha uplate - informacije za primaoca",
        "ibanSwiftNotMatchError": "Država IBAN-a i SWIFT-a se ne slažu.",
        "swiftCharectersCount": "Polje SWIFT/BIC može sadržavati 8 ili 11 karaktera!",
        "recipient": "Primalac",
        "purposeCode": "Šifra svrhe",
        "recipientStatus": "Status primaoca",
        "companyName": "Ime i prezime ili puni naziv tvrtke",
        "address": "Adresa",
        "city": "Grad",
        "country": "Država",
        "recipientAccount": "Račun primaoca",
        "recipientBank": "Banka primaoca",
        "bankName": "Naziv banke",
        "swiftCode": "SWIFT kod",
        "bankCharges": "Bankovna naknada",
        "sharedCharges": "Zajednički troškovi",
        "payersPaysCharges": "Isplatitelj plaća troškove",
        "beneficiaryPaysCharges": "Korisnik plaća troškove",
        "naturalPerson": "Fizičko lice",
        "legalPerson": "Pravno lice",
        "tooltipInfo": "Trebate pomoć?",
        "fillData": "Popunite podatke o banci",
        "reviewandSubmit": "Pregled i potvrda",
        "companyNameInputError": "Ime i prezime je obavezno!",
        "recipientAddressInputError": "Adresa primaoca je obavezna!",
        "recipientCityInputError": "Grad primaoca je obavezan!",
        "recipientAccountInputError": "Račun primaoca je obavezan!",
        "bankNameInputError": "Naziv banke je obavezan!",
        "bankAddressInputError": "Adresa banke je obevezna!",
        "bankCityInputError": "Grad banke je obavezan!",
        "swiftCodeInputError": "SWIFT kod je obavezan!",
        "payersNameInputLabel": "Ime i prezime pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljaoca",
        "payersCityInputLabel": "Grad pošiljaoca",
        "sepaDebitorLabel": " Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primaoc",
        "paymentMode": "Svrha plaćanja",
        "toPayee2":"Primaoc (naziv/ime i adresa)",
        "toAccountInputLabel": "IBAN / Broj računa primaoca",
        "bicBankName": "Banka primaoca",
        "creditRefNumber": "Ref. broj kredita",
        "creditYear": "Godina kredita",
        "bicBankCountry": "Država banke primaoca"

      },
      internalTransfer: {
        "transferToAccount": "Prenos na moj račun",
        "fromAccount": "Račun platitelja (IBAN)",
        "toAccount": "Račun primaoca (IBAN)",
        "dueDate": "Datum izvršenja",
        "valueDate": "Datum kreiranja",
        "amount": "Iznos",
        "sign": "Potpiši",
        "saveAsTemplate": "Snimi kao šablon",
        "templateName": "Naziv šablona",
        "newPayment": "Kreiraj novi nalog",
        "toPaymentOverview": "Idi na pregeled svih plaćanja",
        "signSuccess": "Platni nalog je uspješno potpisan.",
        "toAccountInputError": "Račun primaoca je obavezan!",
        "purposeCodeInputError": "Šifra svrhe je obavezna!",
        "paymentPurposeInputError": "Opis transakcije je obavezan!",
        "amountInputError": "Iznos je obavezan!",
        "dueDateError": "Datum izvršenja je obavezan!",
        "templateNameError": "Naziv je obavezan!",
        "emailError": "Email je obavezan!",
        "phoneError": "Broj telefona je obavezan!",
        "purposeError": "Svrha uplate je obavezna!",
        "chargesError": "Troškovna opcija je obavezna!",
        "when": "Datum",
        "transferComplete": "Prenos je završen",
        "makeAnotherPayment": "Kreiraj novi nalog",
        "successfullyTransfer": "Uspješno ste prebacili",
        "amountLengthError": "Maksimalni iznos je 99.999.999.999,99",
        "nodata": "Nema podataka za prikaz",
        "infoMne0":"Napomena za uplate na Revolving i Mastercard kartice:",
        "infoMne1":"Uplate izvršene do 15:30h će biti proknjižene i sredstva raspoloživa na kartici u toku sledećeg radnog dana.",
        "infoMne2":"Uplate izvršene nakon 15:30h će biti proknjižene i sredstva raspoloživa na kartici u toku sledeća dva radna dana."
      },
      paymentSignModal: {
        title: "Potpisivanje naloga",
        challengeResponse: "Unesite kod",
        ok: "Uredu"
      },
      loanPayment: {
        "loanPayment": "Plaćanje kredita",
        "recieverNumber": "Broj primaoca",
        "purpose": "Svrha plaćanja",
        "when":"Datum",
        "exchangeRate":"Kurs",
        "referenceNumber":"Poziv na broj"
      },
      currencyConversion: {
        "exchangeOffice": "Razmena valute",
        "exchangeRatesCalculator": "Lista valuta i kalkulator",
        "sellingAmount": "Prodaja",
        "buyingAmount": "Kupovina",
        "availableBalance": "Raspoloživo stanje",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "amount": "Iznos",
        "sign": "Potpiši",
        "confirm": "Potvrdi",
        "back": "Novi nalog",
        "smsAuthentification": "SMS autentifikacija plaćanja",
        "sendSms": "Pošalji SMS kod",
        "smsPassword": "SMS jednokratna šifra",
        "moneyExchangeComplete": "Novčana razmjena je završena",
        "successfullyExchange": "Uspješno ste razmjenili",
        "makeAnotherExchange": "Napravi još jednu razmjenu",
        "goToPayments": "Idi na pregled naloga",
        "exchangeRateRatio": "Kursna lista",
        "exchangeOrder": "Nalog promjene valuta",
        "signPayment": "Potpiši nalog",
        "deletePayment": "Obriši nalog",
        "recallPayment": "Opozovi nalog",
        "balance":"Stanje računa",
        "bankCharges":"Cenovnik",
        "when":"Datum",
        "buying":"Kupovni kurs",
        "selling":"Prodajni kurs",
        "calculatedAmount":"Preračunati iznos",
        "agreedRate":"Kurs"
      },
      paymentsAndTransferOverview: {
        rejectedLabel:"Poslednji neplaćeni nalozi",
        eInvoicesLabel:"Neplaćeni eRačuni",
        groupedPaymentsLabel:"Grupa naloga za potpis",
        repeatAction:"Ponovi",
        payAction:"Plati",
        signAllAction:"Potpiši sve",
        domPayToSign:"opštih naloga za potpis",
        paymentsToSign: "naloga za potpis",
        "confirm": "Potvrdi"
      },
      ebook: {
        "ebookPayIn":"Uplata na eknjižicu",
        "ebookPayOut": "Isplata sa eknjižice"
      },
      savings: {
        "savingsPayIn": "Interni devizni prijenos"
      },
      signPayments: {
        "paymentsInvalidDueDate": "Neka od plaćanja imaju zastarjel krajnji datum plaćanja. Da li želite ažurirati te datume na današnji dan?",
        "singlePaymentInvalidDueDate": "Odabrani nalog ima zastarjeli datum izvršenja. Da li želite ažurirati datum na današnji dan?",
        "updatePaymentsDueDate": "Ažuriraj krajnje datume plaćanja",
        "updatePaymentDueDate": "Ažuriraj datum plaćanja",
        "dateCreated": "Datum kreiranja",
        "dueDate": "Datum izvršenja",
        "confirm": "Potvrdi",
        "challengeResponse": "Kod potvrde",
        "challenge": "Validacijski kod",
        "challengeFirstCode": "Šifra 1 (unosi se u token)",
        "challengeSecondCode": "Šifra 2 (iz tokena)",
        "challengeDescription": "Uključite token, unesite PIN i odmah na prazan ekran (piše samo CHAL na vrhu) unesite broj koji je ispisan na ekranu 'Šifra 1', i kliknite na gumb 'OK'. Token će ispisati šifru 2 koju je potrebno prepisati u polje 'Šifra 2'. Kada ste završili akciju, odaberite gumb 'POTVRDI'.",
        "finalStepSuccessfullySigned": "Uspješno ste potpisali nalog",
        "finalStepPaymentServices": "Za usluge platnog prometa naknada će biti obračunata u skladu sa ",
        "finalStepBankTariff": "Tarifnikom Banke.",
        "finalStepStatus": "Status: ",
        "finalStepPaymentID": "Broj transakcije: ",
        "finalStepNumberOfPayments": "Broj naloga: ",
        "finalStepSentForProcessing": "Status naloga možete povjeriti u Listi plaćanja (pregled naloga).",
        "finalStepPaymentSloHeaderEx": "N/A",
        "finalStepPaymentSloHeaderPen": "N/A",
        "finalStepPaymentSloSubHeader": "N/A",
        "finalStepPaymentSloSubHeaderLink": "N/A",
        "finalStepPaymentSloTextEx": "N/A",
        "finalStepPaymentSloTextPen": "N/A",
        "cancel":"Odustani",
        "resendSms": "Ponovo pošalji SMS",
        "SmsCode": "SMS kod",
        "SmsDescription": "SMS poruka je poslata na Vaš uređaj. Unesite kod iz SMS poruke u polje 'SMS kod'. Nakon unosa koda, odaberite opciju 'POTVRDI'.",
        "SmsSentSuccessfully": "SMS poruka uspješno poslana!",
        "paymentExecutionDateTitle": "Promena datuma valute",
        "paymentExecutionDateBody": "Obrada naloga je završena. Vaše plaćanje će biti poslato na izvršenje prvog sledećeg radnog dana.",
        "infoDomMne0":"Vaše plaćanje će biti izvršeno u skladu sa definisanim vremenskim okvirima ",
        "infoDomMne1":"(Terminski plan).",
        "infoDomMneLink":"https://www.addiko.me/sites/addiko.me/files/content/terminski_plan_obrade_naloga_addiko.xlsx.pdf"
      },
      paymentActions: {
        "repeat": "Ponovi nalog",
        "copy": "Kopiraj nalog",
        "documentation": "Dokumentacija",
        "delete": "Obriši",
        "print": "Štampaj nalog",
        "recall": "Opozovi nalog",
        "sign": "Potpiši",
        "cancel": "Otkaži",
        "rejectRecall": "Odbij zahtjev",
        "approveRecall": "Prihvati zahtjev",
        "edit":"Izmijeni",
        "confirmation": "Order confirmation"
      },
      statementConfirmModalTranslations: {
        title: "Izjava",
        p1: "Sukladno Zakonu o međunarodnim mjerama ograničavanja u Banci za provedbu naloga, potrebno je popuniti ovu Izjavu. U protivnom, nalog neće biti proveden.",
        p2: "Pod kaznenom i materijalnom odgovornošću izjavljujem da je, za nalog u iznosu od ",
        p2_2: " za državu",
        p2_0: " osnova plaćanja",
        p2_1: "detaljno obrazloženje (specifikacija)*",
        p4: "Izjava je kreirana u elektroničkom obliku i važeća je bez pečata i potpisa.",
        radioMerchandise: "Roba*",
        radioPresent: "Poklon",
        radioOther: "Ostalo*",
        confirmButton: "Potvrdi",
        cancelButton: "Odustani"
      },
      currencyCalculator: {
        "exchangeCalculator": "Valutni kalkulator",
        "exchangeRates": "Kursna lista",
        "msg1": "Tečajna lista primjenjuje se za kupnju i prodaju deviza do iznosa protuvrijednosti od HRK 150.000,00",
        "msg2": "Za veće iznose molimo kontaktirajte Odjel Prodaje proizvoda Riznice Addiko Banke na " +
        "tel.: 01 603 3513, 01 603 1746, 01 603 0616, 01 603 3533. ili 01/603-0889, i za regiju Slavonija i Baranja " +
        "tel: 031/231-553 i 031/231-223. ",
        "updated":"Ažurirano"
      },
      paymentDocumentation: {
        title: 'Unos prateće dokumentacije',
        user: 'Poštovani korisniče, ',
        info: 'Prateću dokumentaciju uz opšti devizni nalog pomoću ove funkcionalnosti možete prenijeti na server i poslati banci. Banka će uraditi potrebnu provjeru dokumentacije u cilju bržeg odobrenja realizacije vašeg naloga za opšti devizni nalog.',
        list1: 'Tipovi dokumenata koji su dozvoljeni su PDF, PNG i IMAGE (jpg, jpeg).',
        list2: 'Da bi Vam bio omogućen potpis naloga za iznos preko 15.000 EUR potrebno je da unesete bar jedan dokument.',
        list3: 'Ukoliko banka, prilikom provjere potrebne dokumentacije, ustanovi da je potrebno unijeti dodatnu dokumentaciju, bićete obavješteni porukom.',
        uploadText: 'Kliknite za upload dokumenta ili prevucite fajlove ovde',
        confirmText: 'Potvrdi',
        cancelText: 'Odustani',
        downloadText: 'Preuzmi',
        removeText: 'Izbriši',
        numberOfRowsPerPage: 'Broj redova po stranici:',
        pageNumber: 'Stranica br:',
        numberDocuments: 'Ukupan broj unesenih dokumenata uz nalog je:',
        lastUpdated: 'Last updated:'
      },
      paymentsReturn: {
        openedTab: "Otvoreni",
        archivedTab: "Arhivirani",
        titleLabel: "Saglasnost za povrat sredstava",
        modalTitle: "Odaberite razlog za povrat sredstava",
        recallReason: "Razlog za povrat",
        additionalInfo: "Dodatne informacije",
        checkAdditionalInfo: "Ukloni dodatne informacije",
        additionalInfoError: "Ovo polje je obavezno",
        reasonTech: "Tehnički problemi koji rezultiraju pogrešnim platnim nalogom kreditnog transfera",
        reasonFrad: "Lažno iniciranje platnog naloga kreditnog transfera (required additiona info field)",
        reasonDupl: "Duplo slanje"
      },
      paymentsFinal: {
        "InProcessing":"U obradi",
        "Signed":"Potpisan",
        "WaitingQueue":"Nalog je poslat na čekanje"
      },
      paymentsTypeGroup: {
        "CurrencyExchange":"CurrencyExchange",
        "LocalCurrencyTransfer":"LocalCurrencyTransfer",
    }
    };

    $translateProvider
      .translations('mne', data);
  }
}());
