<table class="table table-transparent table-responsive">
  <tbody ng-include="'accounts/merchant-info-details/merchant-info-details.tpl.html'"></tbody>

  <tbody ng-hide="transaction.account.accountType.group=='CardAccountTypes' || transaction.countryCode=='sr'">
    <tr ng-if="transaction.bookingType && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">

      <td class="left-col"><span class="tridm-key-label" translate="transactions.statusTransaction"></span></td>
      <td class="right-col">
        <span class="tridm-value-label" style="color: red" ng-if="transaction.bookingType == '1'"
          translate="accounts.accAutorization"></span>
        <span class="tridm-value-label" style="color: green" ng-if="transaction.bookingType == '2'"
          translate="accounts.accProvedena"></span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && transaction.detail.purpose">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.purpose}}</span></td>
    </tr>
  
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
      <td class="right-col">
        <span class="tridm-value-label outcome-tr" ng-if="transaction.debit"><span
            ng-if="accounts.countryCode == 'sr'">- </span>{{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
        <span class="tridm-value-label income-tr" ng-if="transaction.credit">{{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
      </td>
    </tr>
    <tr ng-if="transaction.countryCode === 'hr' && transaction.bookingType != '1'">
      <td><span class="tlidm-key-label" translate="transactions.availableAmount"></span></td>
      <td><span class="tlidm-value-label">{{transaction.bankAvailableAmount | number:2}}
          {{transaction.account.currencySymbol}}</span></td>
    </tr>
    <tr
      ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && (transaction.credit && !transaction.creditorOrDebtorName || transaction.debit && !transaction.detail.payeeName)">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorName"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorName"></span></td>
      <td class="right-col" ng-if="transaction.credit"><span
          class="tridm-value-label">{{transaction.creditorOrDebtorName }}</span></td>
      <td class="right-col" ng-if="transaction.debit"><span
          class="tridm-value-label">{{transaction.detail.payeeName}}</span></td>
    </tr>
    <tr ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.payeeAddress1">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorAddress"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorAddress"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeAddress1}}</span></td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && transaction.detail.payeeCity">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.payeeCity"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeCity}}</span></td>
    </tr>

    <tr ng-if="transaction.account.accountType.id !== 'SVGSUB'"
      ng-hide="accounts.countryCode === 'sl' && (!transaction.account.iban || !transaction.detail.hasOwnProperty('fromAccountNumber')) ">
      <td ng-if="accounts.countryCode != 'bh' && accounts.countryCode != 'sl'" class="left-col"><span
          class="tridm-key-label" translate="transactions.ibanFrom"></span></td>
      <td ng-if="accounts.countryCode == 'bh'" class="left-col"><span class="tridm-key-label"
          translate="transactions.ibanFromBh"></span></td>
      <td ng-if="accounts.countryCode == 'sl'" class="left-col"><span class="tridm-key-label"
          translate="transactions.accountFrom"></span></td>

      <!--<td ng-if="accounts.countryCode != 'bh'" class="right-col"><span class="tridm-value-label">{{transaction.account.iban}}</span></td>-->
      <!--<td ng-if="accounts.countryCode == 'bh' && transaction.debit" class="right-col"><span class="tridm-value-label">{{transaction.account.iban}}</span></td>-->
      <td ng-if="accounts.countryCode == 'bh' && transaction.credit" class="right-col"><span
          class="tridm-value-label">{{transaction.detail.toAccountNumber}}</span></td>
      <td ng-if="transaction.debit" class="right-col"><span
          class="tridm-value-label">{{transaction.account.iban}}</span></td>
      <td ng-if="accounts.countryCode != 'bh' && transaction.credit" class="right-col"><span
          class="tridm-value-label">{{transaction.detail.fromAccountNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.account.accountType.id !== 'SVGSUB'"
      ng-hide="accounts.countryCode === 'sl' && !transaction.detail.hasOwnProperty('toAccountNumber')">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountTo"></span></td>
      <td ng-if="accounts.countryCode != 'bh'" class="right-col"><span
          class="tridm-value-label">{{transaction.detail.toAccountNumber}}</span></td>
      <td ng-if="accounts.countryCode == 'bh' && transaction.debit" class="right-col"><span
          class="tridm-value-label">{{transaction.detail.toAccountNumber}}</span></td>
      <td ng-if="accounts.countryCode == 'bh' && transaction.credit" class="right-col"><span
          class="tridm-value-label">{{transaction.account.iban}}</span></td>
    </tr>
    <tr ng-if="transaction.account.accountType.id === 'SVGSUB'"
      ng-hide="accounts.countryCode === 'sl' && !transaction.hasOwnProperty('fromAccountNumber')">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountFrom"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.fromAccountNumber}}</span></td>
    </tr>
    <tr
      ng-if="transaction.account.accountType.group=='SavingAccountTypes' && transaction.account.accountType.id ==='SVGSUB'"
      ng-hide="accounts.countryCode === 'sl' && !transaction.hasOwnProperty('toAccountNumber') && transaction.debit">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountTo"></span></td>
      <td class="right-col">
        <span ng-if="transaction.toAccountNumber" class="tridm-value-label">{{transaction.toAccountNumber}}</span>
        <span ng-if="!transaction.toAccountNumber && accounts.countryCode === 'sl' && transaction.credit"
          class="tridm-value-label">{{accounts.selectedAccount.iban}}</span>
        <span ng-if="!transaction.toAccountNumber && accounts.countryCode === 'sl' && transaction.debit"
          class="tridm-value-label">{{transaction.toAccountNumber}}</span>
      </td>
    </tr>
    <tr ng-if="transaction.referenceNumber">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.referenceNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.referenceNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.bookingDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.bookingDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.bookingDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.valueDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.valueDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.valueDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <!-- Transaction confirmation -->
    <tr>
      <td>
        <button type="button" translate="dashboard.complaint"
          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
          data-ng-click="accounts.navigateToReclamationForm(transaction);">
        </button>
      </td>
      <td>
        <button
          ng-hide="accounts.countryCode != 'hr' || transaction.account.accountType.group =='SavingAccountTypes' || transaction.account.accountType.group=='DepositAccountTypes' || transaction.account.accountType.group=='LoanAccountType'"
          type="button" translate="dashboard.confirmation"
          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
          data-ng-click="accounts.downloadTransactionReport(transaction);">
        </button>
      </td>
    </tr>
  </tbody>

  <!--za tekuci SRB-->
  <tbody ng-hide="transaction.account.accountType.group=='CardAccountTypes' || transaction.countryCode!='sr'">
    <tr ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.purpose">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.purpose}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
      <td class="right-col">
        <span class="tridm-value-label outcome-tr" ng-if="transaction.debit"><span
            ng-if="accounts.countryCode == 'sr'">-</span> {{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
        <span class="tridm-value-label income-tr" ng-if="transaction.credit">{{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
      </td>
    </tr>
    <tr ng-if="transaction.countryCode === 'hr'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.availableAmount"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.availableBalance | number:2}}</span>
      </td>
    </tr>
    <tr ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.payeeName">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorName"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorName"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeName}}</span></td>
    </tr>
    <tr ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.payeeAddress1">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorAddress"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorAddress"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeAddress1}}</span></td>
    </tr>
    <tr
      ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.hasOwnProperty('toAccountNumber')">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.toAccountNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.referenceNumber">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.referenceNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.referenceNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.bookingDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.date"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.bookingDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.valueDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.valueDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.valueDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' && transaction.paymentType === '2'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.MCC"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.mcc}}</span></td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' && transaction.paymentType === '2'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.POSReference"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.posReference}}</span></td>
    </tr>
    <!-- Transaction confirmation -->
    <tr>
      <td>
        <button type="button" translate="dashboard.complaint"
          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
          data-ng-click="accounts.navigateToReclamationForm(transaction);">
        </button>
      </td>
      <td>
        <button type="button" translate="transactions.retrieval"
          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
          ng-if="transaction.retrieval && transaction.retrieval === true"
          ng-click="accounts.retrievalRequest(transaction)">
        </button>
      </td>
    </tr>

  </tbody>
  <!--za card cro i srb-->
  <tbody ng-hide="transaction.account.accountType.group!='CardAccountTypes'">
    <tr ng-if="transaction.bookingType && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.statusTransaction"></span></td>
      <td class="right-col">
        <span class="tridm-value-label" style="color: red" ng-if="transaction.bookingType == '1'"
          translate="accounts.accAutorization"></span>
        <span class="tridm-value-label" style="color: green" ng-if="transaction.bookingType == '2'"
          translate="accounts.accProvedena"></span>
      </td>
    </tr>
    <tr ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.detail.purpose">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.purpose}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
      <td class="right-col">
        <span class="tridm-value-label outcome-tr" ng-if="transaction.debit"><span
            ng-if="accounts.countryCode == 'sr'">- </span>{{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
        <span class="tridm-value-label income-tr" ng-if="transaction.credit">{{transaction.amount | number:2}}
          {{transaction.currencySymbol}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorName"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorName"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeName}}</span></td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <td class="left-col" ng-if="transaction.credit"><span class="tridm-key-label"
          translate="transactions.creditorAddress"></span></td>
      <td class="left-col" ng-if="transaction.debit"><span class="tridm-key-label"
          translate="transactions.debitorAddress"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.payeeAddress1}}</span></td>
    </tr>
    <tr
      ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !transaction.account.hasOwnProperty('accountNumber')">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountNumber}}</span></td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.card"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountType.description}}</span></td>
    </tr>
    <tr ng-if="transaction.referenceNumber && transaction.referenceNumber != '0'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.referenceNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.referenceNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.bookingDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.bookingDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.bookingDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.valueDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.valueDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.valueDate | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.hasOwnProperty('datePeriodStart') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.statementPeriod"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.datePeriodStart | date:'shortDate' :
          '+0200'}}</span></td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' && transaction.paymentType === '2'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.MCC"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.mcc}}</span></td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' && transaction.paymentType === '2'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.POSReference"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.posReference}}</span></td>
    </tr>

        <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && transaction.transactionDivision">
          <td class="left-col"><span class="tridm-key-label" translate="transactionDivision.installmentNumber"></span></td>
          <td class="right-col"><span class="tridm-value-label">{{transaction.transactionDivision.installmentNumber}}</span></td>
        </tr>
        <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && transaction.transactionDivision">
          <td class="left-col"><span class="tridm-key-label" translate="transactionDivision.installmentAmount"></span></td>
          <td class="right-col"><span class="tridm-value-label">{{(transaction.transactionDivision.amount / transaction.transactionDivision.installmentNumber).toFixed(2)}}</span></td>
        </tr>
        <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && transaction.transactionDivision">
          <td class="left-col"><span class="tridm-key-label" translate="transactionDivision.creationDate"></span></td>
          <td class="right-col"><span class="tridm-value-label">{{transaction.transactionDivision.creationDate | date:'shortDate' : '+0200'}}</span></td>
        </tr>
        <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && transaction.transactionDivision">
          <td class="left-col"><span class="tridm-key-label" translate="transactionDivision.status"></span></td>
          <td class="right-col"><span class="tridm-value-label">{{('transactionDivision.' + transaction.transactionDivision.status) | translate}}</span></td>
        </tr>
    <!-- Transaction confirmation -->
    <tr>
      <td class="left-col"></td>
      <td class="right-col">
        <button type="button" translate="dashboard.complaint"
          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
          data-ng-click="accounts.navigateToReclamationForm(transaction);">
        </button>
      </td>
      <!--<td>-->
      <!--<button ng-hide="accounts.countryCode != 'hr'" type="button" translate="dashboard.confirmation" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"-->
      <!--data-ng-click = "accounts.downloadTransactionReport(transaction);">-->
      <!--</button>-->
      <!--</td>-->
    </tr>
  </tbody>
</table>