(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name transactions.controller:TransactionsReclamationCtrl
   *
   * @description
   *
   */
  angular
    .module('transactions')
    .controller('TransactionsReclamationCtrl', TransactionsReclamationCtrl);

  function TransactionsReclamationCtrl($rootScope, $stateParams, $log, $state, $localStorage, TransactionsService, HelperService,
                                       ConfigEndpointService, MessagesService, ValidationService,
                                       AlertService, NotificationService) {
    var vm = this;
    vm.tempReclamationObj = {};
    vm.formSubmitted = false;
    vm.submitInProgress = false;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    vm.init = function () {
      if ($stateParams.transactionId) {
        if (!$localStorage.code) {
          getDefaultData();
        }
        loadTransaction($stateParams.transactionId);
      }
    };

    vm.createReclamation = function () {
      var params = {};

      vm.formSubmitted = true;

      if (!vm.paymentForm.$valid) {
        return false;
      }

      vm.submitInProgress = true;

      switch ($localStorage.code) {

        case 'hr':
        case 'sl':
          params = {
            transactionId: $stateParams.transactionId,
            reclamationText: vm.tempReclamationObj.reclamationInput || " ",
            referenceNumber: vm.tempReclamationObj.referenceNumber
          };
          TransactionsService.insertComplaint(params)
            .then(function (response) {
              var translatedText = 'core.confirmation';

              if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
                translatedText = 'core.confirmationSlo';
              }

              AlertService.infoAlert({
                text: translatedText
              })
                .then(function () {
                  $state.go('accounts', {accountId: vm.tempReclamationObj.accountId});
                });
            })
            .catch(function (error) {
              vm.formSubmitted = false;
              vm.submitInProgress = false;
              NotificationService.showMessage(error, 'error');
            });
          break;
        case 'sr':
        case 'bh':
          params = {
            subject: "Referenca: " + vm.tempReclamationObj.referenceNumber,
            body: vm.tempReclamationObj.reclamationInput || " "
          };
          MessagesService.sendMessage(params)
            .then(function (response) {
              $state.go('accounts', {accountId: vm.tempReclamationObj.accountId});
            })
            .catch(function (error) {
              vm.formSubmitted = false;
              vm.submitInProgress = false;

              NotificationService.showMessage(error, 'error');
            });
          break;
        case 'mne':
          params = {
            subject: vm.tempReclamationObj.referenceNumber,
            body: vm.tempReclamationObj.reclamationInput || " "
          };
          MessagesService.sendMessage(params)
            .then(function (response) {
              $state.go('accounts', {accountId: vm.tempReclamationObj.accountId});
            })
            .catch(function (error) {
              vm.formSubmitted = false;
              vm.submitInProgress = false;

              NotificationService.showMessage(error, 'error');
            });
          break;
      }
    };

    var loadTransaction = function (id) {
      TransactionsService.getById(id)
        .then(function (response) {
          vm.tempReclamationObj = response;
          $log.debug('Transaction object: ', response);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var getDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response.APILocation) {
            $localStorage.code = response.APILocation;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.cancel = function () {
      if ($state.params.accountId && $state.params.url) {
        $state.go($state.params.url, {
          accountId: $state.params.accountId
        });
      }
    }
    vm.init();
  }
}());
