<table class="table table-transparent table-responsive">
    <tbody>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlStatus">Status:</span>
            </td>
            <td>
                <span class="po-value-label" translate="{{'paymentsOverview.'+payment.status}}"></span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlId">Payment id:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.id}}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlReceiver">Receiver name:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.receiverName}}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlFrom">From account:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.fromAccountNumber}}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlTo">To account:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.toAccountNumber}}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlAmount">Amount:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.amount|number:2}} {{::payment.currencySymbol}}</span>
            </td>
        </tr>
           <tr>
            <td>
                <span class="po-desc-label" translate="signPayments.dateCreated">Create date:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.dateCreated| date:'shortDate' : '+0200'}}</span>
            </td>
        </tr>
        <tr>
            <td>
                <span class="po-desc-label" translate="paymentsOverview.povlDueDate">Due date:</span>
            </td>
            <td>
                <span class="po-value-label">{{::payment.dueDate| date:'shortDate' : '+0200'}}</span>
            </td>
        </tr>


        <tr>
            <td colspan="2">
                <div>
                    <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                        <span ng-switch on="actionObject.methodName">
                            <button ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed"
                                    ng-click="dashboard.recallPaymentAction(payment.id);"
                                    class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                    translate="paymentActions.recall">Recall payment</button>
                            <!--<button ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed" ng-click="dashboard.signPaymentAction(payment.id);" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase">Sign payment</button>-->
                          <button ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed"
                                  data-ui-sref="payments.sign({paymentId: payment.id})"
                                  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                  translate="paymentActions.sign">Sign payment</button>
                            <button ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed"
                                    ng-click="dashboard.deletePaymentAction(payment.id);"
                                    class="btn btn-primary-warning app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                    translate="paymentActions.delete">Delete payment</button>
                        </span>
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>
