(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name accounts.directive:transactionsFilter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="accounts">
   <file name="index.html">
   <transactions-filter></transactions-filter>
   </file>
   </example>
   *
   */
  angular
      .module('accounts')
      .directive('transactionsFilter', transactionsFilter);

  function transactionsFilter() {
    return {
      restrict: 'EA',
      scope: {
        statements: "=statements",
        defaultDates: "=defaultDates"
      },
      bindToController: {
        transactions: "=transactions",
        currenciesList: "=transactionCurrencyList",
        transactionFilterChange: "&transactionFilterChange",
        currency: "=currency",
        allowCustomDatepicker: "=allowCustomDatepicker",
        statementDates: "=statementDates"
      },
      controllerAs: "vm",
      templateUrl: 'accounts/transactions-filter-directive.tpl.html',
      controller: function ($rootScope, $scope) {
        var vm = this;

        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.showStatementsFilter = false;
        $scope.$watch('statements', function (newValue) {
          vm.showStatementsFilter = newValue;
        }, true);

        $scope.$watch('defaultDates', function (newValue) {
          vm.transactionFilterOptions.dateFrom = newValue.dateFrom;
          vm.transactionFilterOptions.dateTo = newValue.dateTo;
        }, true);
        // Init values
        vm.showFilterOptionsWrapper = false;
        vm.transactionFilterOptions = {
          genericSearch: "",
          transactionType: undefined,
          amountGTE: undefined,
          amountLTE: undefined,
          currencySymbol: undefined,
          dateFrom: undefined,
          dateTo: undefined,
          selectedStatementDate: undefined
        };

        var currency = _.find(vm.currenciesList, {symbol: vm.currency});
        if (currency) {
          vm.transactionFilterOptions.currencySymbol = currency.symbol;
        }

        // if(vm.statementDates){
        //   vm.transactionFilterOptions.selectedStatementDate = vm.statementDates[0];
        // }

        var setDefaultCurrency = function () {
          var currency = _.find(vm.currenciesList, {symbol: vm.currency});
          if (currency) {
            vm.transactionFilterOptions.currencySymbol = currency.symbol;
          }
        };

        // vm.datesFromDisabled = function (date, mode) {
        //   return (mode === 'day' && (date.getTime() < tomorrow));
        // };

        vm.datesToDisabled = function (date, mode) {
          return (mode === 'day' && (date.getTime() < vm.transactionFilterOptions.dateFrom));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
        };

        vm.onDateFromChange = function () {
          if (vm.transactionFilterOptions.dateTo < vm.transactionFilterOptions.dateFrom) {
            vm.transactionFilterOptions.dateTo = angular.copy(vm.transactionFilterOptions.dateFrom);
          }
        };


        vm.applyFilter = function () {
          vm.showFilterOptionsWrapper = false;
          vm.transactionFilterChange({filterParams: vm.transactionFilterOptions});
        };

        vm.search = function () {
          vm.transactionFilterChange({filterParams: {genericSearch: vm.transactionFilterOptions.genericSearch}});
        };

        vm.clearSearch = function () {
          vm.transactionFilterOptions.genericSearch = '';
          vm.transactionFilterChange({filterParams: {genericSearch: vm.transactionFilterOptions.genericSearch}});
        };

        vm.resetFilter = function () {
          vm.showFilterOptionsWrapper = false;
          vm.transactionFilterOptions.genericSearch = undefined;
          vm.transactionFilterOptions.transactionType = undefined;
          vm.transactionFilterOptions.amountGTE = undefined;
          vm.transactionFilterOptions.amountLTE = undefined;
          vm.transactionFilterOptions.currencySymbol = undefined;
          vm.transactionFilterOptions.dateTo = undefined;
          vm.transactionFilterOptions.dateFrom = undefined;
          vm.transactionFilterChange({filterParams: vm.transactionFilterOptions});
          setDefaultCurrency();
        };

      }
    };
  }
}());
