(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            timeline: {
                "description": "Opis",
                "amount": "Znesek",
                "availableBalance": "Razpoložljivo stanje",
                "accountNumber": "Številka računa",
                "bookingDate": "Datum knjiženja",
                "transactionDetails": "Podrobnosti transakcije",
                "referenceType": "Promet",
                "group": "Račun",
                "valueDate": "Datum valute",
                "einvoiceDetails": "Podrobnosti eRačuna",
                "purpose": "Namen",
                "creditorName": "Naziv plačnika",
                "tldmMessageDetails": "Podrobnosti sporočila:",
                "tldmMessageDate": "Datum:",
                "tldmMessageSubject": "Naslov sporočila:",
                "debitorName":"Naziv prejemnika",
                "creditorAddress":"Naslov plačnika",
                "debitorAddress":"Naslov prejemnika",
                "creditorAccount":"Račun plačnika",
                "debitorAccount":"Račun prejemnika",
                "Transaction": "Transakcija",
                itemTypeStatus: {
                  EInvoice: "eRačun",
                  TransactionCredit: "Promet v dobro",
                  TransactionDebit: "Promet v dobro",
                  Payment: "Plačilo",
                  MessageIn: "Prejeto sporočilo",
                  MessageOut: "Poslano sporočilo",
                  Statement: "Izpisek",
                  LoginSuccessful: "Uspešna prijava",
                  LoginFailed: "Neuspešna prijava",
                  TokenRefresh: "Ovežitev generatorja gesel"
                }
            }
        };

        $translateProvider
            .translations('sl', data);

    }
}());
