<section id="main">
  <div class="container-fluid">
    <div class="row main-row">
      <div class="main-column col-xs-12">
        <!-- START Title -->
        <div class="row">
          <div class="col-md-12">
            <h2>
              <h2 class="f-28" translate="accountsOverview.title"></h2>
            </h2>
          </div>
        </div>
        <!-- END Title -->
        <!-- START Multi Accounts View-->
        <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
          <div ng-switch-default class="row m-b-15">
            <div ng-if="accountsOverview.multiAccounts.length"
              ng-class="{'col-md-6': accountsOverview.singleAccounts.length, 'col-md-12': !accountsOverview.singleAccounts.length}">
              <div class="col-md-12 multi-currency-account-card m-b-15"
                ng-class="{'col-md-6': !accountsOverview.singleAccounts.length, 'col-md-12': accountsOverview.singleAccounts.length}"
                ng-repeat="multiAccount in accountsOverview.multiAccounts">
                <div style="background: #ff4d5a;">
                  <div class="multi-account-item-widget in-padding"
                    ng-style="multiAccount.accounts[0].linkList.itemList.imageUrl ?{'background-image': 'url(' + multiAccount.accounts[0].linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <!-- START MultiCard Title -->
                    <h4 class="acc-name c-white m-t-0 m-b-0">
                      <!--<b class="col-xs-10 p-0  no-wrap-text">
                        Multi-currency account
                      </b>-->
                      <b class="col-xs-10 p-0  no-wrap-text">{{multiAccount.accounts[0].friendlyName}}</b>
                    </h4>
                    <span ng-if="multiAccount.iban" class="c-white f-14  no-wrap-text">{{multiAccount.iban}}</span>
                    <!-- END MultiCard Title -->
                    <div class="no-padding no-margin" ng-repeat="account in multiAccount.accounts">
                      <div data-ng-click="accountsOverview.goToAccount(account.accountId)">
                        <h4 class="acc-name c-white m-t-0 m-b-0">
                          <b class="col-xs-10 p-0  no-wrap-text"></b>
                          <span class="col-xs-2 p-0 t-a-r c-white">
                            <i class="c-white"
                              ng-class="{'icon icon-favourite favorite-account':account.isFavorite,'zmdi zmdi-star-outline':!account.isFavorite}"></i>
                          </span>
                        </h4>
                        <h2 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'" class="amount m-b-5 c-white">
                          <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                            currency="{{account.currencySymbol}}" from-type="dashboard"
                            aditional-val="{{account.balance|number:2}}" has-underline="true">
                          </amount-formatter>
                        </h2>
                        <h2 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class="amount m-b-5 c-white">
                          <div class="d-inline-block">
                            <amount-formatter amount="{{account.balance|number:2}}"
                              class="float-left"
                              custom-classes="{{'c-white'}}" currency="{{account.currencySymbol}}"
                              from-type="dashboard" aditional-val="{{account.balance|number:2}}">
                            </amount-formatter>
                            <amount-formatter ng-if="account.balance2 !== null && account.balance2 !== undefined" amount="{{account.balance2|number:2}}"
                              custom-classes="{{'c-white mt-3'}}" currency="{{account.currencySymbol2}}"
                              class="float-left m-t-7"
                              is-secondary-value="true"
                              from-type="dashboard" aditional-val="{{account.balance2|number:2}}" 
                              has-bracket="true">
                            </amount-formatter>
                          </div>
                          
                          <div class="underline"></div>
                        </h2>
                        <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl' && account.accountType.group === 'CardAccountTypes'"
                          translate="accounts.accBalance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                        <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl' || account.accountType.group !== 'CardAccountTypes'"
                          translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END Multi accounts-->
            <!-- START Single accounts -->
            <div ng-if="accountsOverview.singleAccounts.length"
              ng-class="{'col-md-6': accountsOverview.multiAccounts.length, 'col-md-12 no-padding': !accountsOverview.multiAccounts.length}">
              <div class="m-b-15" ng-repeat="account in accountsOverview.singleAccounts"
                data-ng-click="accountsOverview.goToAccount(account.accountId)"
                ng-class="{'col-md-6': !accountsOverview.multiAccounts.length}">
                <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
              </div>
              <!--</div>-->
            </div>
            <!-- END  Single accounts -->
          </div>


          <div ng-switch-when="sl" class="row m-b-15">
            <div ng-if="accountsOverview.multiAccounts.length"
              ng-class="{'col-md-6 p-0': accountsOverview.cardAccounts.length, 'col-md-12': !accountsOverview.cardAccounts.length}">
              <div class="col-md-12 multi-currency-account-card m-b-15 "
                ng-class="{'col-md-6': !accountsOverview.cardAccounts.length, 'col-md-12': accountsOverview.cardAccounts.length}"
                ng-repeat="multiAccount in accountsOverview.multiAccounts | orderBy: ['-isOwn']">
                <div style="background: #ff4d5a;">
                  <div class="multi-account-item-widget in-padding"
                    ng-style="multiAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + multiAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <!-- START MultiCard Title -->
                    <h4 class="acc-name c-white m-t-0 m-b-0">
                      <!--<b class="col-xs-10 p-0  no-wrap-text">
                        Multi-currency account
                      </b>-->
                      <b class="col-xs-10 p-0  no-wrap-text">{{multiAccount.friendlyName}}</b>
                    </h4>
                    <span ng-if="multiAccount.iban" class="c-white f-14  no-wrap-text">
                      {{multiAccount.iban}}
                    </span>
                    <!-- END MultiCard Title -->
                    <div class="no-padding no-margin"
                      ng-repeat="account in multiAccount.subAccountList | orderBy: ['sortOrderId']">
                      <div data-ng-click="accountsOverview.goToAccount(account.accountId)">
                        <h4 class="acc-name c-white m-t-0 m-b-0">
                          <b class="col-xs-10 p-0  no-wrap-text"></b>
                          <span class="col-xs-2 p-0 t-a-r c-white">
                            <i class="c-white"
                              ng-class="{'icon icon-favourite favorite-account':account.isFavorite,'zmdi zmdi-star-outline':!account.isFavorite}"></i>
                          </span>
                        </h4>
                        <h2 class="amount m-b-5 c-white">
                          <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                            currency="{{account.currencySymbol}}" from-type="dashboard"
                            aditional-val="{{account.balance|number:2}}" has-underline="true">
                          </amount-formatter>
                        </h2>
                        <p ng-if="account.accountType.group === 'CardAccountTypes'"
                          translate="accounts.accBalance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                        <p ng-if="account.accountType.group !== 'CardAccountTypes'"
                          translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END Multi accounts-->
            <!-- START Single accounts -->
            <div ng-if="accountsOverview.cardAccounts.length"
              ng-class="{'col-md-6': accountsOverview.multiAccounts.length, 'col-md-12 gno-paddin': !accountsOverview.multiAccounts.length}">
              <div class="m-b-15" ng-repeat="account in accountsOverview.cardAccounts | orderBy: ['-isOwn']"
                data-ng-click="accountsOverview.goToAccount(account.accountId)"
                ng-class="{'col-md-6': !accountsOverview.multiAccounts.length}">

                <div class="account-item-widget app-red-card"
                  ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="acc-name c-white m-t-0 m-b-0">
                    <b class="col-xs-10 p-0  no-wrap-text">{{::account.friendlyName}}</b>

                    <span class="col-xs-2 p-0 t-a-r c-white">
                      <i class="c-white"
                        ng-class="{'icon icon-favourite favorite-account':account.isFavorite,'f-24 zmdi zmdi-star-outline':!account.isFavorite}">

                      </i>
                    </span>
                  </h4>
                  <span class="c-white f-14  no-wrap-text fw-cm">{{::account.accountNumber}}</span>
                  <h2 class="amount m-b-5 c-white">
                    <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                      currency="{{account.currencySymbol}}" from-type="dashboard"
                      aditional-val="{{account.balance|number:2}}" has-underline="true">
                    </amount-formatter>
                  </h2>
                  <p ng-if="account.accountType.group === 'CardAccountTypes'"
                    translate="accounts.accBalance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                  <p ng-if="account.accountType.group !== 'CardAccountTypes'"
                    translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
                  <div>
                    <!--<span class="c-white f-14  no-wrap-text fw-cm">-->
                    <!--{{account.iban}}-->
                    <!--</span>-->

                    <!--<div ng-if="account.accountDescription" class="c-white f-14  no-wrap-text fw-cm">-->
                    <!--{{::account.accountDescription}}-->
                    <!--</div>-->
                  </div>
                  <!--<div class="p-t-15 amount-formater-wrapper has-underline"></div>-->
                  <!--<p class="p-t-15 c-white f-14  no-wrap-text fw-cm">-->
                  <!--<span translate="accounts.approvedLimit"></span>-->
                  <!--<span class="pull-right " ng-if="account.hasOwnProperty('limit')">{{account.limit | number: 2}} {{::account.currencySymbol}}</span>-->
                  <!--<span class="pull-right" ng-if="!account.hasOwnProperty('limit')"-->
                  <!--translate="accounts.dataNotAvailable"></span>-->
                  <!--</p>-->
                </div>
              </div>
            </div>
          </div>
          <!-- END  Single accounts -->
        </div>


        <!-- START Card list-->
        <div class="row" ng-if="accountsOverview.cards && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
          ng-hide="APP_GLOBAL_SETTINGS.userSettings.isViberUser == 'Y' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'bh'">
          <div class="col-md-12">
            <div class="debit-card-group clearfix" style="background: white" ng-repeat="card in accountsOverview.cards"
              ng-class="{'debit-card-group-blocked': (card.cardStatus !== 'Active')}">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="connected-debit-card-widget"></div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <h3>
                  {{card.accountName}}
                </h3>

                <h5 ng-show="card.cardNumber">{{card.cardNumber}}</h5>
                <h5 ng-show="card.cardType && accountsOverview.countryCode == 'hr'">{{card.cardType}}</h5>
                <h5 ng-show="card.cardTypeDescription && accountsOverview.countryCode != 'hr'">
                  {{card.cardTypeDescription}}</h5>
                <h5 ng-show="card.validToDate">
                  <span translate="accounts.accLimitValidUntilDate">Expiry date:</span> {{card.validToDate| date:
                  "dd.MM.yyyy"}}
                </h5>
                <h5 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'bh'" ng-show="card.cardStatus"><b>Status:</b>
                  {{'accounts.' + card.cardStatus| translate }}</h5>
                <h5 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'" ng-show="card.cardStatus"><b>Status:</b>
                  {{'accounts.cardStatus.' + card.cardStatus| translate }}</h5>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <h3 ng-show="card.ownerName">{{card.ownerName}}</h3>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <button class="btn btn-info btn-block" style="white-space: normal"
                  ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
                  ng-disabled="card.selected" ng-class="{
                          'btn-card-active': (card.cardStatus === 'Active'),
                          'btn-card-blocked': (card.cardStatus !== 'Active')}"
                  ng-click="accountsOverview.updateCardStatus(card)">
                  <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentUnblockRequest'"
                    translate="accounts.unblockCardRequest">Unblock request sent</span>
                  <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentBlockRequest'"
                    translate="accounts.blockCardRequest">Block request sent</span>
                  <span ng-show="!card.temporaryStatus && card.cardStatus == 'Active'"
                    translate="accounts.blockCard"></span>
                  <span ng-show="!card.temporaryStatus && card.cardStatus != 'Active'"
                    translate="accounts.unblockCard"></span>
                </button>
                <!--                <button class="btn btn-info btn-block"
                                        ng-show="accountsOverview.countryCode == 'hr'"
                                        ng-disabled="card.selected"
                                        ng-class="{'btn-card-active': (card.cardStatus === 'Active'), 'btn-card-blocked': (card.cardStatus !== 'Active')}"
                                        ng-click="accountsOverview.updateCardStatusCro()">
                                  <span ng-show="card.cardStatus === 'Active'" translate="accounts.blockCard"></span>
                                  <span ng-hide="card.cardStatus === 'Active'" translate="accounts.unblockCard"></span>
                                </button>-->
              </div>
            </div>
          </div>
        </div>
        <!-- END Card list-->
        <!-- START Add New Deposit and New eSaving Account Buttons -->
        <div class="row">
          <div>
            <!--            <div ng-if="accountsOverview.currentCountry != 'hr'" class="col-md-6 m-b-10">
                          <div class="account-item-add app-dblue-card " >
                            <div class="round-btn-container2">
                              <div class="round-btn2">
                                <i class="icon-r icon-add"></i>
                              </div>
                              <div class="round-btn-label2" style="width: 150px">
                                <b translate="accountsOverview.newTermDepositButtonLabel"></b>
                              </div>
                            </div>
                          </div>
                        </div>-->

            <div ng-if="accountsOverview.currentCountry === 'hr'" class="col-md-6 m-b-10 p-l-15">
              <div class="account-item-add app-dblue-card " ui-sref="orders.termDeposit">
                <div class="round-btn-container2">
                  <div class="round-btn2">
                    <i class="icon-r icon-add"></i>
                  </div>
                  <div class="round-btn-label2">
                    <b translate="accountsOverview.newTermDepositButtonLabel"></b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 p-b-15"
              ng-if="accountsOverview.currentCountry != 'sr' && accountsOverview.currentCountry != 'mne' && accountsOverview.currentCountry != 'bh'"
              ng-hide="accountsOverview.currentCountry == 'sl' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'N'">
              <div class="account-item-add app-dblue-card " ng-click="accountsOverview.openNewSavingOrMasterCard()">
                <div class="round-btn-container2">

                  <div class="round-btn2">
                    <i class="icon-r icon-add"></i>
                  </div>
                  <div ng-switch="accountsOverview.currentCountry" class="round-btn-label2">
                    <b ng-switch-default translate="accountsOverview.newESavingAccountButtonLabel"></b>
                    <b ng-switch-when="sl" translate="accountsOverview.newSavingButtonLabel_SLO"></b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END Add New Deposit and New eSaving Account Buttons -->
      </div>
    </div>
  </div>
  </div>
</section>