(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      transactions:{
        transactionTypes:{
          credit: "Uplata"
          ,debit: "Isplata"
          ,all: "Svi"
        }
      },
      reclamation: {
        title: "Reklamacija/pritužba",
        number: "Broj reklamacije/pritužbe",
        paymentReference: "Referenca naloga",
        bookingDate: "Datum knjiženja",
        valueDate: "Datum valute",
        amount: "Iznos",
        purpose: "Svrha plaćanja",
        description: "Tekst reklamacije/pritužbe",
        wantComplain: "Želim reklamirati",
        confirm: "Potvrdi",
        cancel: "Odustani"
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());
