<section id="main" data-ng-controller="MaterialAdminCtrl as mactrl">
    <div class="container-fluid" ng-init="cddMenuSelected=1">
        <div>
            <!-- Account informations -->
            <div class="account-header-container" ng-if="accounts.selectedAccount">

                <!-- Carousel navigation start -->
                <div class="row" data-ng-show="accounts.accountsForCarousel.length > 1">
                    <ol class="custom-carousel-indicators pull-right">
                        <li data-ng-repeat="account in accounts.accountsForCarousel" data-ng-class="{'active': accounts.currentIndex === account.index}" data-ng-click="accounts.chooseFromCarousel(account.accountId);"></li>
                    </ol>
                </div>

                <div class="pull-right icon-container m-r-15" ng-if="accounts.isNextButtonVisible" data-ng-click="accounts.goToNextItem()">
                    <a class="account-navigation-icons">
                        <i class="zmdi zmdi-chevron-right zmdi-hc-4x"></i>
                    </a>
                </div>

                <div class="pull-left icon-container m-l-15" ng-if="accounts.isPreviousButtonVisible" data-ng-click="accounts.goToPreviousItem()">
                    <a class="account-navigation-icons">
                        <i class="zmdi zmdi-chevron-left zmdi-hc-4x"></i>
                    </a>
                </div>
                <!-- Carousel navigation end -->

                <div class="row account-carousel-row" ng-style="accounts.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + accounts.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <div class="col-xs-8 account-header-inner m-l-25" ng-switch on="accounts.selectedAccount.accountType.group">
                        <!-- Change name of account -->
                        <div class="row">
                            <div class="col-xs-12 no-padding">
                                <div ng-show="accounts.isEditable == true">
                                    <div class="col-sm-8 m-t-14 m-b-9">
                                        <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'"
                                               type="text" class="form-control"
                                               data-ng-model="accounts.newDescription"
                                               maxlength="50"
                                               ng-pattern-restrict="{{accounts.regexPatterns.standardInputFriendlyName}}">
                                        <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'" type="text" class="form-control" data-ng-model="accounts.newDescription" maxlength="50">
                                    </div>
                                    <div class="col-sm-4 m-t-14 m-b-9">
                                        <i class="zmdi zmdi-check zmdi-hc-2x edit-check-btn" data-ng-click="accounts.editAccount();"></i>
                                    </div>
                                </div>

                                <h2 class="account-name m-l-15" ng-show="accounts.selectedAccount.friendlyName && !accounts.isEditable">
                  <!--<span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">{{accounts.selectedAccount.accountNumber}} - </span> -->
                                  <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">{{accounts.selectedAccount.friendlyName | limitTo: 28}}{{accounts.selectedAccount.friendlyName.length > 28 ? '...':''}}</span>
                                  <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'">{{accounts.selectedAccount.friendlyName}}</span>

                  <a href="" class="p-l-30">
                    <span class="account-edit" data-ng-click="accounts.showEditAccount()"
                          translate="accounts.rename"></span>
                  </a>

                  <a href="" data-ng-click="accounts.markAsFavorite()">
                    <i ng-if="!accounts.isFavoriteAccount" class="zmdi zmdi-star-outline "></i>
                    <i ng-if="accounts.isFavoriteAccount" class="zmdi zmdi-star is-favorite"></i>
                  </a>
                </h2>

                  <h2 class="account-name m-l-15 f-20" ng-show="!accounts.selectedAccount.friendlyName && !accounts.isEditable">
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">
                      {{accounts.selectedAccount.accountType.description | limitTo: 28}}{{accounts.selectedAccount.accountType.description.length > 28 ? '...':''}}
                    </span>
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'">
                      {{accounts.selectedAccount.accountType.description}}
                    </span>

                  <a href="" class="p-l-30">
                    <span class="account-edit" data-ng-click="accounts.showEditAccount()"
                          translate="accounts.rename"></span>
                  </a>

                  <a href="" data-ng-click="accounts.markAsFavorite()">
                    <i ng-if="!accounts.isFavoriteAccount" class="zmdi zmdi-star-outline"></i>
                    <i ng-if="accounts.isFavoriteAccount" class="zmdi zmdi-star is-favorite"></i>
                  </a>
                </h2>

                            </div>

                            <div class="col-xs-12 p-b-10" ng-show="accounts.isEditable">

                                <span class="account-edit" translate="accounts.accResetName" data-ng-click="accounts.resetAccountName()"></span>
                            </div>
                        </div>
                        <!--<span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="account-name-iban bold-font">-->
                          <!--{{accounts.selectedAccount.accountDescription}}-->
                        <!--</span>-->
                        <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                          <span class="account-name-iban" ng-switch-default ng-if="accounts.selectedAccount.iban" ng-bind="accounts.selectedAccount.iban"></span>
                          <span class="account-name-iban" ng-switch-when="sl" ng-if="accounts.selectedAccount.iban">
                            <span ng-if="accounts.selectedAccount.accountType.group != 'CardAccountTypes'">{{accounts.selectedAccount.iban}}</span>
                            <span ng-if="accounts.selectedAccount.accountType.group === 'CardAccountTypes'">{{accounts.selectedAccount.accountNumber}}</span>
                          </span>
                        </span>
                        <!-- <span ng-if="!accounts.selectedAccount.iban" ng-bind="accounts.selectedAccount.accountNumber"></span> -->
                        <span class="account-name-iban" ng-if="!accounts.selectedAccount.iban" ng-bind="accounts.selectedAccount.iban"></span>
                        <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                          <span ng-switch-default="">
                            <span class="account-name-iban" >{{accounts.selectedAccount.ownerName}}</span>
                          </span>
                          <span  ng-switch-when="sl">
                            <span class="account-name-iban" >{{accounts.selectedAccount.ownerName}}</span>
                            <!--<div class="account-name-iban" ng-show="accounts.selectedAccount.cardAccount.cardAccountType">{{accounts.selectedAccount.cardAccount.cardAccountType}}</div>-->
                            <!--<div class="account-name-iban" ng-show="accounts.selectedAccount.cardAccount.interestRateAmount"> <span translate="accounts.interestRate"></span>: {{accounts.selectedAccount.cardAccount.interestRateAmount}} %</div>-->
                            <!--<div class="account-name-iban" ng-show="accounts.selectedAccount.cardAccount.payDate"> <span translate="accounts.accDueDate"></span>: {{accounts.selectedAccount.cardAccount.payDate | date: 'dd.'}}</div>-->
                          </span>
                        </span>

                        <!--CURRENT-->
                        <div ng-switch-when="CurrentAccount">
                            <div data-ng-include="'accounts/CurrentAccount-detail.tpl.html'"></div>
                        </div>
                        <!--LOAN-->
                        <div ng-switch-when="LoanAccountType">
                            <div data-ng-include="'accounts/LoanAccountType-detail.tpl.html'"></div>
                        </div>
                        <!--CARD-->
                        <div ng-switch-when="CardAccountTypes">
                            <div data-ng-include="'accounts/CardAccountTypes-detail.tpl.html'"></div>
                        </div>
                        <!--SAVING ACCOUNT-->
                        <div ng-switch-when="SavingAccountTypes">
                            <div data-ng-include="'accounts/SavingAccountTypes-detail.tpl.html'"></div>
                        </div>
                        <!-- GIRO ACCOUNT-->
                        <div ng-switch-when="GiroAccount">
                            <div data-ng-include="'accounts/GiroAccount-detail.tpl.html'"></div>
                        </div>
                        <!--DEPOSIT-->
                        <div ng-switch-when="DepositAccountTypes">
                            <div data-ng-include="'accounts/DepositAccountTypes-detail.tpl.html'"></div>
                        </div>
                    </div>

                    <div class="col-xs-4">
                        <!-- TODO: Add image based on account type -->
                    </div>
                </div>

                <!-- Accounts & card details bottom part -->
                <div class="account-details-container clearfix">
                    <div class="account-open-more-details" ng-if="!accounts.isAccountDetailsOpen && accounts.countryCode != 'sr'" data-ng-click="accounts.toggleAccountDetails()" translate="accounts.accAccountAndCardsMoreDetails">
                    </div>
                    <div class="account-open-more-details" ng-if="!accounts.isAccountDetailsOpen &&
                                                            accounts.countryCode == 'sr' &&
                                                            accounts.selectedAccount.accountType.group !== 'LoanAccountType' &&
                                                            accounts.selectedAccount.accountType.group !== 'DepositAccountTypes' " data-ng-click="accounts.toggleAccountDetails()" translate="accounts.accAccountAndCardsMoreDetails1">
                    </div>
                    <div class="account-open-more-details" ng-if="!accounts.isAccountDetailsOpen &&
                                                            accounts.countryCode == 'sr' &&
                                                            (accounts.selectedAccount.accountType.group === 'LoanAccountType' ||
                                                            accounts.selectedAccount.accountType.group === 'DepositAccountTypes') " data-ng-click="accounts.toggleAccountDetails()" translate="accounts.accAccountAndCardsMoreDetails">
                    </div>

                    <div ng-if="accounts.isAccountDetailsOpen">

                        <!-- Connected debit cards-->
                        <div ng-if="accounts.selectedAccount.cardList"
                              ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                            <div data-ng-include="'accounts/connected-debit-card.tpl.html'"></div>
                        </div>

                        <!-- SavingAccountTypes - Documents -->
                        <div ng-if="accounts.selectedAccount.accountType.group === 'SavingAccountTypes'"
                              ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
                              class="m-t-15">
                            <div class="row m-l-5">
                                <a data-ng-click="accounts.downloadFile_ESavingBook('DocumentRequest')" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="ebook.doc1"></a>
                            </div>
                            <div class="row m-l-5 m-t-5">
                              <a data-ng-click="accounts.downloadFile_ESavingBook('PreContractForm')" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="ebook.doc2"></a>
                            </div>

                            <div class="row m-l-5 m-t-5">
                              <a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.eSavings_generalTerms}}" target="_blank" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="ebook.doc4"></a>
                            </div>
                            <div class="row m-l-5 m-t-5">
                              <a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.Deposit_basicInfo}}" target="_blank" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="ebook.informationFormOnDeposits"></a>
                            </div>

                            <div class="row m-l-5 m-t-5" style="font-weight: bold;font-size: 15px">
                              <span translate="ebook.otherDocs1"></span>
                              <a href="https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo/" target="_blank" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;">
                                <span translate="ebook.otherDocs2"></span>
                              </a>
                            </div>
                        </div>

                        <!-- DepositAccountTypes - Documents -->
                        <div ng-if="accounts.selectedAccount.accountType.group === 'DepositAccountTypes'"
                              ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
                              class="m-t-15">
                            <div class="row m-l-5">
                                <a data-ng-click="accounts.downloadFile_Deposits('Contract')" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="termDeposit.hrDoc6"></a>
                            </div>
                            <div class="row m-l-5 m-t-5">
                              <a data-ng-click="accounts.downloadFile_Deposits('PreContractInfo')" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;" translate="termDeposit.hrDoc7"></a>
                            </div>
                            <div class="row m-l-5 m-t-5" style="font-weight: bold;font-size: 15px">
                              <span translate="termDeposit.otherDocs1"></span>
                              <a href="https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo/" target="_blank" style="text-decoration: underline; font-weight: bold;font-size: 15px; cursor: pointer;">
                                <span translate="termDeposit.otherDocs2"></span>
                              </a>
                            </div>
                        </div>

                        <!-- Authorized persons -->
                        <div class="row col-sm-12 p-0" ng-if="accounts.selectedAccount.accountType.group !== 'LoanAccountType'">
                            <div  ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="account-container m-l-25 m-r-25">
                                <span class="acrs-acc-info-text m-b-5 auth-persons-text" translate="accounts.accAuthorizedPersons"></span>
                                <hr class="account-header-line" />

                                <div class="ac-authorized-person-container" data-ng-repeat="item in accounts.selectedAccount.userAccountListFiltered">
                                    <span>{{item.user.firstName}} {{item.user.lastName}} </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" ng-if="accounts.selectedAccount.accountType.group == 'CurrentAccount' && (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr')">
                            <button class="btn btn-default app-btn-blue m-t-15 m-b-10" translate="orders.largeAmount" ng-click="accounts.goToCashWithdrawal()">
                                Large amount of cash withdrawal
                            </button>
                        </div>
                        <div class="col-md-12" ng-if="accounts.selectedAccount.accountType.group == 'CurrentAccount' && (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') && accounts.selectedAccount.canCashWithDrawal">
                            <button class="btn btn-default app-btn-blue m-t-15 m-b-10" translate="orders.largeAmount" ng-click="accounts.goToCashWithdrawal()">
                                Large amount of cash withdrawal
                            </button>
                        </div>

                    </div>

                    <div class="row col-xs-12">
                        <span class="account-open-more-details" ng-if="accounts.isAccountDetailsOpen" data-ng-click="accounts.toggleAccountDetails()" translate="accounts.accAccountAndCardsLessDetails">
                        </span>
                    </div>
                </div>
            </div>

            <div class="row" ng-if="accounts.selectedAccount.accountType.group === 'CurrentAccount'">
                <div ng-hide="accounts.countryCode == 'hr' && accounts.defaultCurrency != accounts.selectedAccount.currencySymbol" 
                     class="col-xs-12">
                    <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5"
                         ng-hide="accounts.multiCurrency || (accounts.countryCode == 'bh' && accounts.defaultCurrency != accounts.selectedAccount.currencySymbol)">
                        <a data-ui-sref="payments.domesticPayment.fromAccount({accountId: accounts.selectedAccount.accountId})">
                            <div class="round-btn-container">
                                <!--<div class="round-btn"><i class="zmdi zmdi-home zmdi-hc-3x"></i></div>-->
                                <div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>
                                <div class="round-btn-label">{{'dashboard.domesticPaymentBtn1'|translate}}
                                    <br>{{'dashboard.domesticPaymentBtn2'|translate}}</div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                        <a ng-click="accounts.goToInternalPayment();" ng-if="!accounts.multiCurrency">
                            <div class="pointer round-btn-container">
                                <!--<div class="round-btn"><i class="zmdi zmdi-swap zmdi-hc-3x"></i></div>-->
                                <div class="round-btn"><i class="icon icon-transfer" style="font-size:2em"></i></div>
                                <div class="round-btn-label">{{'dashboard.transferBtn1'|translate}}
                                    <br>{{'dashboard.transferBtn2'|translate}}</div>
                            </div>
                        </a>
                        <a ng-click="accounts.goToForeignCurrencyTransfer()" ng-if="accounts.multiCurrency">
                            <div class="pointer round-btn-container">
                                <!--<div class="round-btn"><i class="zmdi zmdi-swap zmdi-hc-3x"></i></div>-->
                                <div class="round-btn"><i class="icon icon-transfer" style="font-size:2em"></i></div>
                                <div class="round-btn-label">{{'dashboard.transferBtn1'|translate}}
                                    <br>{{'dashboard.transferBtn2'|translate}}</div>
                            </div>
                        </a>

                    </div>

                    <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                        <div ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh' && APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency != accounts.selectedAccount.currencySymbol"
                             class="pointer round-btn-container" ng-click="accounts.loadPaymentTemplates();">
                            <div class="round-btn"><i class="icon icon-template" style="font-size:1.6em"></i></div>
                            <div class="round-btn-label">{{'dashboard.templateBtn1'|translate}}
                                <br>{{'dashboard.templateBtn2'|translate}}</div>
                        </div>

                        <div class="templates-container animated fadeIn" ng-show="accounts.showTemplatesView">
                            <div class="form-group m-b-0">
                                <div class="input-group" style="height:50px;border: 1px solid black;">
                                    <div class="input-group-btn srch-icon" style="border:1px solid lightgray; border-right:none;">
                                        <button class="btn" type="submit" style="box-shadow:none;background-color: transparent;">
                                            <i class="glyphicon glyphicon-search" style="font-size:20px;"></i>
                                        </button>
                                    </div>
                                    <input id="searchTemplatesInput" style="height:50px;" data-ng-model="accounts.searchTemplates" type="text" class="form-control srch-input" placeholder="{{'dashboard.searchTemplatesPlaceholder' | translate}}" ng-change="accounts.showTemplatesView = (accounts.searchTemplates == '') ? false : true">
                                </div>
                            </div>
                            <div class="p-10 templates-search-container" scrollable>
                                <!--<div class="m-b-10" ng-show="false">-->
                                <!--<h4 translate="templateSearch.favorites"></h4>-->
                                <!--<div class="list-group">-->
                                <!--<a href="" class="list-group-item">-->
                                <!--<div class="row">-->
                                <!--<div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>-->
                                <!--<div class="col-xs-10">-->
                                <!--<h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>-->
                                <!--<p class="list-group-item-text">HR4015290098745696</p>-->
                                <!--</div>-->
                                <!--<div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>-->
                                <!--</div>-->
                                <!--</a>-->
                                <!--<a href="" class="list-group-item">-->
                                <!--<div class="row">-->
                                <!--<div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>-->
                                <!--<div class="col-xs-10">-->
                                <!--<h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>-->
                                <!--<p class="list-group-item-text">HR4015290098745696</p>-->
                                <!--</div>-->
                                <!--<div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>-->
                                <!--</div>-->
                                <!--</a>-->
                                <!--<a href="" class="list-group-item">-->
                                <!--<div class="row">-->
                                <!--<div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>-->
                                <!--<div class="col-xs-10">-->
                                <!--<h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>-->
                                <!--<p class="list-group-item-text">HR4015290098745696</p>-->
                                <!--</div>-->
                                <!--<div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>-->
                                <!--</div>-->
                                <!--</a>-->
                                <!--</div>-->
                                <!--</div>-->
                                <div class="m-b-10">
                                    <h4 translate="templateSearch.templatesList"></h4>
                                    <div class="list-group">
                                        <a href="" class="list-group-item" data-ng-repeat="template in accounts.templateList.result | filter:accounts.searchTemplates" ng-click="accounts.navigateToPaymentForm({id: template.id, type: template.paymentTypeGroup, template: template})">
                                            <div class="row">
                                                <div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>
                                                <div class="col-xs-11">
                                                    <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                                                    <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                  <!-- SLO - change withdrawal limit -->
                    <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5"
                         ng-if="accounts.countryCode === 'sl' && accounts.selectedAccount.currencySymbol == 'EUR' && accounts.selectedAccount.isOwn == true && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'">
                    <a ng-click="accounts.goToChangeWithdrawal()">
                      <div class="round-btn-container">
                        <!--<div class="round-btn"><i class="zmdi zmdi-home zmdi-hc-3x"></i></div>-->
                        <div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>
                        <div class="round-btn-label">{{'navigation.withdrawal1'|translate}}
                            <br>{{'navigation.withdrawal2'|translate}}
                        </div>
                      </div>
                    </a>
                  </div>
                  <!-- SLO - change ATM withdrawal limit -->
                  <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5"
                       ng-if="accounts.countryCode === 'sl' && accounts.selectedAccount.currencySymbol == 'EUR' && accounts.selectedAccount.isOwn == true">
                    <a data-ui-sref="changeATMWithdrawal({accountId: accounts.selectedAccount.accountId})">
                      <div class="round-btn-container">
                        <!--<div class="round-btn"><i class="zmdi zmdi-home zmdi-hc-3x"></i></div>-->
                        <div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>
                        <div class="round-btn-label">{{'navigation.changeATMWithdrawal1'|translate}}
                            <br>{{'navigation.changeATMWithdrawal2'|translate}}</div>
                      </div>
                    </a>
                  </div>
                  <!-- SHOW FOR NOW - PIN OVER SMS - START -->
                  <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5"
                       ng-if="accounts.countryCode === 'sl' && accounts.selectedAccount.currencySymbol == 'EUR' && accounts.selectedAccount.isOwn == true">
                    <a data-ui-sref="reorderPinAccounts({accountId: accounts.selectedAccount.accountId})">
                      <div class="round-btn-container">
                        <div class="round-btn">
                          <div class="icon icon-addiko-pin" style="font-size:2em">
                          </div>
                        </div>
                        <div class="round-btn-label">{{'navigation.reorderPin'|translate}}</div>
                      </div>
                    </a>
                  </div>
                  <!-- SHOW FOR NOW - PIN OVER SMS - END -->

                  <!-- SLO - master card order limit -->
                  <!--<div class="col-xs-12 col-sm-6 m-b-20 p-l-5 p-r-5"-->
                       <!--ng-if="accounts.countryCode === 'sl'">-->
                    <!--<a data-ui-sref="masterCardOrder({accountId: accounts.selectedAccount.accountId})">-->
                      <!--<div class="round-btn-container">-->
                        <!--&lt;!&ndash;<div class="round-btn"><i class="zmdi zmdi-home zmdi-hc-3x"></i></div>&ndash;&gt;-->
                        <!--<div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>-->
                        <!--<div class="round-btn-label" translate="navigation.masterCardOrder"></div>-->
                      <!--</div>-->
                    <!--</a>-->
                  <!--</div>-->

                </div>
            </div>



            <!-- Account actions -->
            <div class="m-b-15">
                <div ng-if="accounts.selectedAccount.accountType.group === 'LoanAccountType'">
                    <div class="col-xs-12">
                        <div ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh' && accounts.selectedAccount.accountType.id != '1801'" style="padding-left: 1px;">
                            <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container" data-ng-click="accounts.goToLoanPayment(); accounts.pushGtmPayInEvent('LoanAccount');">
                                    <div class="round-btn"><i class="icon icon-epayin" style="font-size:2em"></i></div>
                                    <div class="round-btn-label" translate="accounts.accPayIn" style="padding-top:22px;"></div>
                                </div>

                            </div>
                        </div>

                        <div style="padding-left: 1px;" ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne')" data-ng-click="accounts.toggleRepaymentPlan()">

                            <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container">
                                    <div class="round-btn"><i class="icon icon-epayin" style="font-size:2em"></i></div>
                                    <div ng-if="accounts.showRepaymentPlan" class="round-btn-label" translate="accounts.closeRepaymentPlan" style="padding-top:22px;">
                                    </div>
                                    <div ng-if="!accounts.showRepaymentPlan" class="round-btn-label" translate="accounts.accRepaymentPlan" style="padding-top:22px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


              <div class="row">
                <div class="col-sm-3 col-xs-12 m-b-20"
                     ng-if="accounts.selectedAccount.accountType.group === 'CardAccountTypes' && accounts.selectedAccount.linkList.itemList.payIn">
                  <div class="pointer round-btn-container">
                    <div class="round-btn"><i class="icon icon-ebin" style="font-size:2em"></i></div>
                    <div class="round-btn-label" translate="accounts.accPayIn"
                         data-ng-click="accounts.goToInternalPayment(); accounts.pushGtmPayInEvent('CardAccount');"></div>
                  </div>
                </div>

                <!-- Transaction division button (specific for BiH, SRB and MNE environments) -->
                <div class="col-sm-3 col-xs-12 m-b-20"
                     ng-if="accounts.selectedAccount.linkList && accounts.selectedAccount.linkList.itemList.transactionDivisionLink">
                  <a data-ui-sref="transactionDivison.create({
                    accountId:accounts.selectedAccount.accountId, 
                    currency: accounts.selectedAccount.currencySymbol,
                    card: accounts.selectedAccount.accountNumber.substr(accounts.selectedAccount.accountNumber.length - 4, 4), 
                    accountTypeId: accounts.selectedAccount.accountType.id
                    })">
                    <div class="pointer round-btn-container">
                      <div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>
                      <div class="round-btn-label">{{'accounts.transDivisionBtn1'|translate}}
                        <br>{{'accounts.transDivisionBtn2'|translate}}
                      </div>
                    </div>
                  </a>
                </div>
                <!-- End Transaction division button (specific for BiH, SRB and MNE environments) -->
              </div>

                <!--MNE SPECIFIC START-->
                <div ng-if="(accounts.selectedAccount.accountType.group === 'SavingAccountTypes' || accounts.selectedAccount.accountType.group === 'DepositAccountTypes') &&
                    APP_GLOBAL_SETTINGS.defaultData.APILocation === 'mne' && (accounts.selectedAccount.accountType.id.substr(0,2) === '60')">

                    <button type="input" class="btn btn-default app-btn-blue m-t-5" ng-if="accounts.selectedAccount.accountType.id !== 'SVGSUB'"
                            data-ng-click="accounts.goToSavingsPayIn(accounts.selectedAccount); accounts.pushGtmPayInEvent('DepositAccount');" translate="accounts.accPayIn"></button>
                </div>
                <!--MNE SPECIFIC END-->
                <div ng-if="accounts.selectedAccount.accountType.group === 'SavingAccountTypes' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'mne'">
                    <div class="col-xs-12 m-b-10">
                        <div class="col-xs-12 col-sm-4">
                            <div class="pointer round-btn-container">

                                <div class="round-btn" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'"
                                     data-ng-click="accounts.goToEbookPay('Pay In'); accounts.pushGtmPayInEvent('Ebook');"><i class="icon icon-ebin" style="font-size:2em"></i>
                                </div>
                                <div class="round-btn-label" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'"
                                     data-ng-click="accounts.goToEbookPay('Pay In'); accounts.pushGtmPayInEvent('Ebook');" translate="accounts.eBookPayIn">

                                </div>

                              <div class="round-btn"  ng-if="accounts.selectedAccount.accountType.id !== 'SVGSUB'"
                                   data-ng-click="accounts.goToSavingsPayIn(accounts.selectedAccount); accounts.pushGtmPayInEvent('SavingsAccount');"><i class="icon icon-ebin" style="font-size:2em"></i>
                              </div>
                              <div class="round-btn-label" ng-if="accounts.selectedAccount.accountType.id !== 'SVGSUB'"
                                   data-ng-click="accounts.goToSavingsPayIn(accounts.selectedAccount); accounts.pushGtmPayInEvent('SavingsAccount');" translate="accounts.accPayIn">
                              </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="pointer round-btn-container" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'">
                                <div class="round-btn"><i class="icon icon-ebout" style="font-size:2em"></i></div>
                                <div class="round-btn-label" data-ng-click="accounts.goToEbookPay('Pay Out')" translate="accounts.eBookPayOut"></div>
                            </div>
                        </div>
                        <!--<button type="input" class="btn btn-default app-btn-blue m-t-5" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'"-->
                        <!--translate="accounts.accNewEbook" data-ng-click="accounts.goToNewEbook()"></button>-->
                        <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                          <div ng-switch-default class="col-xs-12 col-sm-4">
                              <div class="pointer round-btn-container" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'">
                                  <div class="round-btn"><i class="icon icon-ebclose" style="font-size:2em"></i></div>
                                    <div class="round-btn-label" translate="accounts.closeEbook" data-ng-click="accounts.closeEbook(accounts.selectedAccount.accountId)">
                                  </div>
                              </div>
                          </div>
                          <div class="col-xs-12 col-sm-4" ng-switch-when="hr" ng-show="accounts.selectedAccount.currencySymbol == accounts.defaultCurrency">
                              <div class="pointer round-btn-container" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB'">
                                  <div class="round-btn"><i class="icon icon-ebclose" style="font-size:2em"></i></div>
                                    <div class="round-btn-label" translate="accounts.closeEbook" data-ng-click="accounts.closeEbook(accounts.selectedAccount.accountId)">
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <!-- Displaying ebook saving requests -->
                    <!--<button type="input" class="btn btn-default app-btn-blue m-t-5" ng-if="accounts.selectedAccount.accountType.id === 'SVGSUB' && accounts.eBookSavingsRequests.result.length > 0"-->
                    <!--ng-class="{'ebook-req-btn': accounts.displayeBookSavingRequest}"-->
                    <!--data-ng-click="accounts.showeSavingBookRequests();" translate="ebook.eBookRequests"></button>-->
                </div>

                <div ng-if="accounts.selectedAccount.accountType.group === 'DepositAccountTypes'">
                    <div class="row" style="padding-left: 1px;" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation=='hr'">
                        <div class="col-xs-12">
                            <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container">
                                    <div class="round-btn"><i class="icon icon-close" style="font-size:2em"></i></div>
                                    <!--<button type="input" class="btn btn-default app-btn-blue m-t-5" translate="accounts.accPayIn"></button>
          <button type="input" class="btn btn-default app-btn-blue m-t-5"
                  translate="accounts.accCancelAutoExtension"></button>
          <button type="input" class="btn btn-default app-btn-blue m-t-5"
                  translate="accounts.accCancelDeposit"
                  data-ng-click="accounts.cancelDepositAction()"></button>-->
                                    <div class="round-btn-label" translate="accounts.accCancelDeposit" data-ng-click="accounts.cancelDepositAction()" style="padding-top:22px;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div ng-if="accounts.selectedAccount.accountType.group === 'CurrentAccount' && accounts.countryCode =='hr' && accounts.defaultCurrency == accounts.selectedAccount.currencySymbol">
                    <div class="row" style="padding-left: 1px;">
                        <div class="col-xs-12">
                            <div ng-if="accounts.selectedAccount.currencySymbol == accounts.defaultCurrency" class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container">
                                    <div class="round-btn"><i class="icon icon-ebnew" style="font-size:2em"></i></div>
                                    <div class="round-btn-label" translate="accounts.accNewEbook" data-ng-click="accounts.goToNewEbook()"></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container">
                                    <div class="round-btn"><i class="icon icon-epayin" style="font-size:2em"></i></div>
                                    <div class="round-btn-label" translate="accounts.eBookPayIn" data-ng-click="accounts.goToEbookPay('Pay In')"></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-4 m-b-20 p-l-5 p-r-5">
                                <div class="round-btn-container">
                                    <div class="round-btn"><i class="icon icon-eborder" style="font-size:2em"></i></div>
                                    <!-- Displaying ebook saving requests -->
                                    <div class="round-btn-label" ng-class="{'ebook-req-btn': accounts.displayeBookSavingRequest}" data-ng-click="accounts.showeSavingBookRequests();" translate="ebook.eBookRequests"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Transactions & PDF statements -->
                <!--ng-show="accounts.selectedAccount.accountType.group!='LoanAccountType'
        && accounts.selectedAccount.accountType.group!='DepositAccountTypes'
        && !accounts.displayeBookSavingRequest"-->
                <div ng-if="accounts.accountLoaded && APP_GLOBAL_SETTINGS.defaultData.APILocation === 'mne' && (accounts.selectedAccount.accountType.group!='LoanAccountType'
        && (accounts.selectedAccount.accountType.id.substr(0,2) === '60' || accounts.selectedAccount.accountType.group!='DepositAccountTypes')
        && !accounts.displayeBookSavingRequest)" class="col-sm-12 p-0 m-b-0 app-white-card no-bottom-radius">
                    <div data-ng-include="'accounts/accountsSubmenu.tpl.html'"></div>
                </div>
                <div ng-if="accounts.accountLoaded && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'mne' && (accounts.selectedAccount.accountType.group!='LoanAccountType'
        && accounts.selectedAccount.accountType.group!='DepositAccountTypes'
        && !accounts.displayeBookSavingRequest)" class="col-sm-12 p-0 m-b-0 app-white-card no-bottom-radius">
                    <div data-ng-include="'accounts/accountsSubmenu.tpl.html'"></div>
                </div>

                <!-- START Repayment plan-->
                <div ng-if="accounts.showRepaymentPlan && !accounts.displayeBookSavingRequest" class="col-sm-12 col-md-12 p-0 m-b-0 app-white-card no-bottom-radius">
                    <div data-ng-include="'accounts/repayment-plan.tpl.html'"></div>
                </div>
                <!-- END Repayment plan-->

                <!-- EBookSavings requests timeline -->
                <div ng-if="accounts.displayeBookSavingRequest">
                    <div data-ng-include="'accounts/tl-ebook-savings.tpl.html'">
                    </div>
                </div>


                <!--      <div class="hidden-md hidden-sm hidden-lg"
                 data-ng-include="'accounts/accounts-transaction-filter.tpl.html'"></div>-->

                <!--      <div class="col-sm-12 col-md-12 col-lg-12 no-padding-on-phone">
              <div class="grey-margin">
                &lt;!&ndash;<div class="row">&ndash;&gt;
                  <div class="col-sm-1 col-xs-2 favorite-container">
                    <a href="" data-ng-click="accounts.markAsFavorite();">
                      <i ng-if="!accounts.isFavoriteAccount" class="zmdi zmdi-star-outline zmdi-hc-2x"></i>
                      <i ng-if="accounts.isFavoriteAccount" class="zmdi zmdi-star zmdi-hc-2x is-favorite"></i>
                    </a>
                  </div>
                  <div class="col-sm-11 col-xs-10 no-padding">
                    &lt;!&ndash; container for editing accounts &ndash;&gt;
                    <div ng-show="accounts.isEditable == true">
                      <div class="col-sm-8 m-t-14 m-b-9">
                        <input type="text" class="form-control" data-ng-model="accounts.newDescription">
                      </div>
                      <div class="col-sm-4 m-t-14 m-b-9">
                        <i class="zmdi zmdi-check zmdi-hc-2x edit-check-btn" data-ng-click="accounts.editAccount();"></i>
                      </div>
                    </div>

                    <h2 class="account-name m-l-0" ng-show="accounts.selectedAccount.friendlyName != undefined && !accounts.isEditable">
                      {{accounts.selectedAccount.friendlyName}}
                      <a href="">
                        <span class="clickable-link account-edit" data-ng-click="accounts.showEditAccount();" translate="accounts.rename"></span>
                      </a>
                    </h2>
                    <h2 class="account-name" ng-show="accounts.selectedAccount.friendlyName == undefined && !accounts.isEditable">
                      {{accounts.selectedAccount.accountType.description}}
                      <a href="">
                        <span class="clickable-link account-edit" data-ng-click="accounts.showEditAccount();" translate="accounts.rename"></span>
                      </a>
                    </h2>
                  </div>

                <div class="col-sm-12 ac-left-section p-l-25 p-b-10" ng-show="accounts.isEditable">

                  <a href=""><span class="m-l-15 clickable-link" ng-show="accounts.selectedAccount.accountType.group=='CurrentAccount' || accounts.selectedAccount.accountType.group=='CardAccountTypes'" translate="accounts.accResetName"  data-ng-click="accounts.resetAccountName();"></span></a>
                </div>

              </div>
              <div class="col-sm-12 p-0 m-b-15 app-white-card" ng-switch on="accounts.selectedAccount.accountType.group">
                &lt;!&ndash;CURRENT&ndash;&gt;
                <div ng-switch-when="CurrentAccount">
                  <div data-ng-include="'accounts/CurrentAccount-detail.tpl.html'"></div>
                </div>
                &lt;!&ndash;LOAN&ndash;&gt;
                <div ng-switch-when="LoanAccountType">
                  <div data-ng-include="'accounts/LoanAccountType-detail.tpl.html'"></div>
                </div>
                &lt;!&ndash;CARD&ndash;&gt;
                <div ng-switch-when="CardAccountTypes">
                  <div data-ng-include="'accounts/CardAccountTypes-detail.tpl.html'"></div>
                </div>
                &lt;!&ndash;SAVING ACCOUNT&ndash;&gt;
                <div ng-switch-when="SavingAccountTypes">
                  <div data-ng-include="'accounts/SavingAccountTypes-detail.tpl.html'"></div>
                </div>
                &lt;!&ndash; GIRO ACCOUNT&ndash;&gt;
                <div ng-switch-when="GiroAccount">
                  <div data-ng-include="'accounts/GiroAccount-detail.tpl.html'"></div>
                </div>
                &lt;!&ndash;DEPOSIT&ndash;&gt;
                <div ng-switch-when="DepositAccountTypes">
                  <div data-ng-include="'accounts/DepositAccountTypes-detail.tpl.html'"></div>
                </div>
              </div>
              <div ng-show="accounts.selectedAccount.accountType.group!='LoanAccountType' && accounts.selectedAccount.accountType.group!='DepositAccountTypes'" class="col-sm-12 p-0 m-b-0 app-white-card no-bottom-radius">
                <div data-ng-include="'accounts/accountsSubmenu.tpl.html'"></div>
              </div>
              <div class="hidden-md hidden-sm hidden-lg" data-ng-include="'accounts/accounts-transaction-filter.tpl.html'"></div>
            </div>-->
            </div>
        </div>
    </div>
</section>
