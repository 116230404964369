(function () {
  'use strict';
  angular
    .module('dashboard')
    .controller('ThankYouPopupCtrl', ThankYouPopupCtrl);

  function ThankYouPopupCtrl($rootScope,
                                  $http,
                                  $state,
                                  $uibModalInstance,
                                  data,
                                  $translate) {

    var vm = this;
    vm.tYDText1 = '';
    vm.tYDText2 = '';
    vm.currentLang = '';
    vm.data = {};
    vm.buttonClicked = {};

    init();

    function init() {
      vm.buttonClicked = data.buttonClicked;
      //vm.campaignData = data.campaign;
      vm.data = data;
      vm.currentLang = $translate.proposedLanguage() || $translate.use();
      loadData();
    }

    function loadData() {
      if (vm.buttonClicked === 'button1') {
        vm.tYDText2 = vm.data.text1;
      }
      if (vm.buttonClicked === 'button2') {
        vm.tYDText2 = vm.data.text2;
      }
      if (vm.buttonClicked === 'button3') {
        vm.tYDText2 = vm.data.text3;
      }
    }
  }

}());
