(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name template.controller:TemplateCtrl
   *
   * @description
   *
   */
  angular
    .module('template')
    .controller('TemplateCtrl', TemplateCtrl);

  function TemplateCtrl() {
    var vm = this;
    vm.ctrlName = 'TemplateCtrl';
  }
}());
