<section id="main">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 no-padding-on-phone p-r-0">

                <!--REJECTED PAYMENTS-->

                <div class="pato-wrapper">
                    <div class="pato-dropdown" ng-click="patoCtrl.isRejectedPaymentsPanelOpen = !patoCtrl.isRejectedPaymentsPanelOpen">
                        <div class="row">
                            <div class="h-100 col-xs-8 p-l-25 pato-dropdown-label-div">
                                <div class="pato-dropdown-label pato-line-height no-wrap-text" translate="paymentsAndTransferOverview.rejectedLabel"></div>
                            </div>
                            <div class="h-100 col-xs-3 p-l-0 p-r-0 pato-dropdown-counter-div">
                                <div class="pato-dropdown-counter">{{patoCtrl.rejectedPaymentsList.result.length}}</div>
                            </div>
                            <div class="h-100 col-xs-1 p-r-25 p-l-0 pato-dropdown-icon-div"><i class="pato-line-height zmdi zmdi-chevron-down pull-right zmdi-hc-2x"></i></div>
                        </div>
                    </div>
                    <div ng-show="patoCtrl.isRejectedPaymentsPanelOpen" class="animated fadeIn pato-dropdown-content">
                        <div class="pato-payment-item-container" ng-repeat="payment in patoCtrl.rejectedPaymentsList.result">
                            <div class="col-xs-2 p-l-5 p-r-5 pato-pic-date-div">
                                <div class="month">{{::payment.dueDate | date:'d'}}</div>
                                <div class="year">{{::payment.dueDate | date:'MMMM'}}</div>
                            </div>
                            <div class="col-xs-4 p-l-5 p-r-5 pato-pic-label-div">
                                <div class="purpose no-wrap-text">{{::payment.purpose}}</div>
                                <div class="account">{{::payment.fromAccountNumber}}</div>
                            </div>
                            <div class="col-xs-4 p-l-5 p-r-5 pato-pic-amount-div">
                                <div class="amount">{{::payment.amount | number:2}} {{::payment.currencySymbol}}</div>
                            </div>
                            <div class="col-xs-2 p-l-5 p-r-5 pato-pic-action-div">
                                <button class="btn app-btn-blue f-uppercase" translate="paymentsAndTransferOverview.repeatAction"
                                data-ng-click="patoCtrl.navigateToPaymentForm({paymentTypeGroup: payment.paymentTypeGroup, id: payment.id});"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>

                <!--EINVOICES PAYMENTS-->

                <div class="pato-wrapper" ng-if="patoCtrl.countryCode !== 'sr'">
                    <div class="pato-dropdown" ng-click="patoCtrl.isEInvoicesPanelOpen = !patoCtrl.isEInvoicesPanelOpen">
                        <div class="row">
                            <div class="h-100 col-xs-8 p-l-25 pato-dropdown-label-div">
                                <div class="pato-dropdown-label pato-line-height no-wrap-text" translate="paymentsAndTransferOverview.eInvoicesLabel"></div>
                            </div>
                            <div class="h-100 col-xs-3 p-l-0 p-r-0 pato-dropdown-counter-div">
                                <div class="pato-dropdown-counter">{{patoCtrl.eInvoiceDocumentsList.result.length}}</div>
                            </div>
                            <div class="h-100 col-xs-1 p-r-25 p-l-0 pato-dropdown-icon-div"><i class="pato-line-height zmdi zmdi-chevron-down pull-right zmdi-hc-2x"></i></div>
                        </div>
                    </div>
                    <div ng-show="patoCtrl.isEInvoicesPanelOpen" class="animated fadeIn pato-dropdown-content">
                        <div class="pato-payment-item-container" ng-repeat="einvoice in patoCtrl.eInvoiceDocumentsList.result">
                            <div class="col-xs-2 p-l-5 p-r-5 pato-pic-date-div">
                                <div class="month">{{::einvoice.valueDate | date:'d'}}</div>
                                <div class="year">{{::einvoice.valueDate | date:'MMMM'}}</div>
                            </div>
                            <div class="col-xs-4 p-l-5 p-r-5 pato-pic-label-div">
                                <div class="purpose no-wrap-text">{{::einvoice.purpose}}</div>
                                <div class="account">{{::einvoice.paymentReferenceNumber}}</div>
                            </div>
                            <div class="col-xs-4 p-l-5 p-r-5 pato-pic-amount-div">
                                <div class="amount">{{::einvoice.amount | number:2}} {{::einvoice.currencySymbol}}</div>
                            </div>
                            <div class="col-xs-2 p-l-5 p-r-5 pato-pic-action-div">
                                <button class="btn app-btn-blue f-uppercase" translate="paymentsAndTransferOverview.payAction"
                                data-ng-click="patoCtrl.navigatoEInvoicePayment(einvoice.id);"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>

                <!--GROUPED PAYMENTS-->

              <!-- New grouped payments -->
              <div class="pato-wrapper">
                <div class="pato-dropdown" ng-click="patoCtrl.isGroupedPaymentsPanelOpen = !patoCtrl.isGroupedPaymentsPanelOpen">
                  <div class="row">
                    <div class="h-100 col-xs-8 p-l-25 pato-dropdown-label-div">
                      <div class="pato-dropdown-label pato-line-height no-wrap-text" translate="paymentsAndTransferOverview.groupedPaymentsLabel"></div>
                    </div>
                    <div class="h-100 col-xs-3 p-l-0 p-r-0 pato-dropdown-counter-div">
                      <div class="pato-dropdown-counter">{{patoCtrl.groupedPayments.payments.length}}</div>
                    </div>
                      <div class="h-100 col-xs-1 p-r-25 p-l-0 pato-dropdown-icon-div"><i class="pato-line-height zmdi zmdi-chevron-down pull-right zmdi-hc-2x"></i></div>
                  </div>
                </div>

                <div ng-show="patoCtrl.isGroupedPaymentsPanelOpen" class="animated fadeIn pato-dropdown-content">
                  <div data-ng-repeat="payment in patoCtrl.groupedPayments.payments" class="clearfix pato-group-item-container">
                    <div class="col-xs-2 p-l-5 p-r-5 pato-pic-date-div">
                      <div class="month">{{::patoCtrl.getToday() | date:'d'}}</div>
                      <div class="year">{{::patoCtrl.getToday() | date:'MMMM'}}</div>
                    </div>
                    <div class="col-xs-5 p-l-5 p-r-5">
                      <div class="purpose no-wrap-text">{{::payment.paymentList.length}} {{'paymentsAndTransferOverview.paymentsToSign'| translate}}</div>
                      <div class="purpose no-wrap-text">{{::payment.description}}</div>
                      <div class="group-list">
                        <ul>
                          <li ng-repeat="singlePayment in payment.paymentList">{{::singlePayment.purpose}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-xs-3 p-l-5 p-r-5 pato-pic-amount-div">
                      <div ng-if="payment.paymentList.length > 0" class="amount">{{::payment.totalPaymentsAmount | number:2}} {{::payment.paymentList[0].currencySymbol}}</div>
                    </div>
                    <div class="col-xs-2 p-l-5 p-r-5 pato-pic-action-div">
                      <button class="btn app-btn-blue f-uppercase" translate="paymentsAndTransferOverview.signAllAction"></button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
    </div>
</section>
