(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name accounts.factory:AccountLimitOrderService
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .factory('AccountLimitOrderService', AccountLimitOrderService);

    function AccountLimitOrderService($q, $http, EndpointsService, $log) {
        var AccountLimitOrderServiceBase = {};

      AccountLimitOrderServiceBase.insertOrder = function (orderObject) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("AccountLimitOrderServiceBase.insertOrder object:", orderObject);
                $http.post(response.links.accountLimitOrder, orderObject)
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
      AccountLimitOrderServiceBase.validateOrder = function (orderObject) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $log.debug("AccountLimitOrderServiceBase.insertOrder object:", orderObject);
          $http.put(response.links.accountLimitOrder_validate, orderObject)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };

      AccountLimitOrderServiceBase.accountLimitPosTerminalOrderAmountType = function (params) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.accountLimitPosTerminalOrderAmountType, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };
      AccountLimitOrderServiceBase.accountLimitPosTerminalOrderCardType = function (params) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.accountLimitPosTerminalOrderCardType, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };
      AccountLimitOrderServiceBase.accountLimitPosTerminalOrderPersonType = function (params) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.accountLimitPosTerminalOrderPersonType, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };
      AccountLimitOrderServiceBase.validateAccountLimitPosTerminalOrder = function (orderObject) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $log.debug("AccountLimitOrderServiceBase.insertOrder object:", orderObject);
          $http.put(response.links.accountLimitPosTerminalOrder_validate, orderObject)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };
      AccountLimitOrderServiceBase.insertPosTerminalOrder = function (order) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.accountLimitPosTerminalOrder, order)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };

      return AccountLimitOrderServiceBase;
    }
}());
