(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name eInvoices.factory:EinvoicesIssuersOverviewService
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .factory('EinvoicesIssuersOverviewService', EinvoicesIssuersOverviewService);

  function EinvoicesIssuersOverviewService(EndpointsService, $http, $q) {
    var EinvoicesIssuersOverviewServiceBase = {};


    EinvoicesIssuersOverviewServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.eInvoiceIssuers, {
          params: params
        }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EinvoicesIssuersOverviewServiceBase.getById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.eInvoiceIssuers_id.replace('{id}', id))
          .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return EinvoicesIssuersOverviewServiceBase;
  }
}());
