(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      paymentTemplates: {
        "payingFrom": "Paying from:",
        "payingTo": "Paying to:",
        "howMuch": "How much",
        "description": "Description",
        "when": "When",
        "fullPaymentForm": "Show full payment form",
        "showFullPaymentForm": "Show full payment form",
        "pay": "Pay",
        "delete": "Delete",
        "save": "Save",
        "signPayment": "Sign",
        "cancel": "Cancel",
        "status": "Status",
        "bankName": "Bank name",
        "bankAddress": "Bank address",
        "address": "Recipient's address",
        "dueDate": "Due date",
        "fromAccount": "From account",
        "toAccount": "To account",
        "receiver": "Recipient",
        "receiverAddress": "Recipient's address",
        "amount": "Amount",
        "signMsg": "Verify the information and sign",
        "saveTemplate": "New template",
        "invalidDate": "Date is invalid!",
        "descriptionError": "Description is required!",
        "templateName": "Template name",
        "templateIcon": "Template icon",
        "deleteSuccess1": "Template",
        "deleteSuccess2": "was successfully deleted."
      },
      templatesList: {
        "header":"List of my templates",
        "searchTemplates": "Search templates",
        "addNewTemplate": "+ Add new payment template",
        "listOfTemplates":"List of templates",
        "fromAccount":"From account",
        "amount":"Amount",
        "toPayee":"To recipient",
        "accountNumber":"Account number",
        "payeeStreet":"Recipient's street",
        "payeeCity":"Recipient's city",
        "purposeCode":"Purpose code",
        "referenceModel":"Reference model",
        "referenceNumber":"Reference number",
        "addTemplateToFavorites":"Add this template to favorites",
        "edit":"Edit",
        "save": "Save",
        "cancel": "Cancel",
        "bicBankPrejemnika": "Recipient's bank BIC",
        "pay":"Pay",
        "maxLengthReached":"Max length is reached!"
      }
    };

    $translateProvider
      .translations('en', data);
  }
}());
