<div class="modal-header smsModalHeader">
  <div class="row">
    <div class="col-xs-12" translate="eInvoicesModal.rejectDocumentAlert.headerText"></div>
  </div>
</div>

<div class="p-t-20">
  <div class="modal-body">
    <div class="row p-b-10">
      <div class="col-xs-12">
          <div class="form-group">
            <label class="domp-form-label-review" translate="eInvoicesModal.rejectDocumentAlert.labelText"></label>
            <input type="text" class="form-control input-field" name="note" id="note"
                   required
                   maxlength="200"
                   ng-class="{'domp-error': vm.tempObj.form.note$invalid && vm.tempObj.formSubmitted}"
                   ng-model="vm.tempObj.note">
          </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="col-xs-12">
      <button class="btn btn-default m-5 pull-right" ng-click="$close(false);" translate="payments.cancel"></button>
      <button class="btn btn-default m-5 pull-right" ng-click="$close(vm.tempObj.note);"
              translate="domesticPayment.confirmBtn">
      </button>
    </div>

  </div>
</div>
