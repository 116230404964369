(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name core.filter:formatIban
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('core')
    .filter('formatIban', formatIban);

  function formatIban($rootScope) {
    return function (input) {
      if(input && input.substring(0, 4) == "SI56" && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl')
        return input.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      return input;
    };
  }
}());
