<div class="main-header">
  <div class="p-l-20 p-r-20 hidden-xs">
    <div class="row">
      <div class="col-sm-7 ">
        <ul class="nav navbar-nav">
          <li>

            <span class="header-app-name"><span style="vertical-align:sub"><img data-ng-src="img/logo/AB_Logo.svg" alt="Addiko Bank"></span></span>
            <!--<img class="top-header-logo-img" src="img/hypo/hypobank_logo_grey@2x.png" height="25px" alt="" />-->
          </li>
          <li class="dropdown">
            <a class="dropdown-toggle language-select" data-toggle="dropdown" role="button" aria-haspopup="true"
               aria-expanded="false">{{hctrl.selectedLanguage.translate | translate}}
              <i class="zmdi zmdi-caret-down zmdi-hc-lg"></i></a>
            <ul class="dropdown-menu" ng-if="hctrl.countryCode !== 'bh'">
              <li ng-repeat="language in hctrl.languageOptions">
                <a ng-click="hctrl.changeLanguage(language.code)">{{language.translate | translate}}</a>
              </li>
            </ul>
            <!-- in case of BiH show flags -->
            <ul class="dropdown-menu" ng-if="hctrl.countryCode === 'bh'">
              <li ng-repeat="language in hctrl.languageOptions">
                <a ng-click="hctrl.changeLanguage(language.code)"><img ng-src="{{'img/hypo/flags/' + language.flag}}" height="35px" alt="" />{{language.translate | translate}}</a>
              </li>
            </ul>

            <!-- end in case of BiH show flags -->
            <!--<ul class="dropdown-menu">
                <li>
                    <a ng-click="hctrl.changeLanguage('en');"><img src="img/hypo/flags/en.png" height="35px" alt="" /> {{'header.langEn' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('hr');"><img src="img/hypo/flags/hr.png" height="35px" alt="" /> {{'header.langHr' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('sr');"><img src="img/hypo/flags/sr.png" height="35px" alt="" /> {{'header.langSr' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('mtr');"><img src="img/hypo/flags/mtr.png" height="35px" alt="" /> {{'header.langMtr' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('ba');"><img src="img/hypo/flags/ba.png" height="35px" alt="" /> {{'header.langBa' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('sl');"><img src="img/hypo/flags/sl.png" height="35px" alt="" /> {{'header.langSl' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('de');"><img src="img/hypo/flags/de.png" height="35px" alt="" /> {{'header.langDe' | translate}}</a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                    <a ng-click="hctrl.changeLanguage('ru');"><img src="img/hypo/flags/ru.png" height="35px" alt="" /> {{'header.langRu' | translate}}</a>
                </li>
            </ul>-->
          </li>
        </ul>
      </div>

      <div class="col-sm-5">
        <div class="row custom-l-h-60">
            <span class="logout-link pull-right">
             <a href="" ng-click="hctrl.logOut()">
                <i class="icon icon-logout"></i>
             </a>
              <!--<a translate="header.logout"></a>-->
            </span>
            <span class="login-info-username pull-right m-r-10">
                {{APP_GLOBAL_SETTINGS.userSettings.firstName}} {{APP_GLOBAL_SETTINGS.userSettings.middleName}} {{APP_GLOBAL_SETTINGS.userSettings.lastName}}
            </span>
        </div>
      </div>


      <!--            <div class="col-sm-4 notifications-wrapper">
                      <ul class="top-menu">
                          &lt;!&ndash;MESSAGES&ndash;&gt;
                          <li class="dropdown" uib-dropdown="" ng-click="hctrl.openMessagesNotificationLink();"><a uib-dropdown-toggle="" href="" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"><i class="tm-icon zmdi zmdi-comment-text-alt"></i> <i ng-if="hctrl.messagesList.result.length" class="tmn-counts  ">{{hctrl.messagesList.result.length}}</i></a>
                              <div class="dropdown-menu dropdown-menu-lg stop-propagate pull-left">
                                  <div class="listview">
                                      <div class="lv-header">{{'header.notificationMessages' | translate}}
                                      </div>
                                      <div class="t-a-c p-10" ng-if="hctrl.messagesList.result.length == 0">
                                        <i class="zmdi zmdi-cloud-off zmdi-hc-3x"></i>
                                        <h6 translate="header.noMessages"></h6>
                                      </div>
                                      <div class="lv-body mCustomScrollbar" data-mcs-theme="minimal-dark" ng-if="hctrl.messagesList.result.length > 0">
                                          <div class="list-group">
                                              <div ng-hide="hctrl.openReplySection" ng-repeat="message in hctrl.messagesList.result | limitTo:hctrl.numberOfMessagesToShow">
                                                  <a ng-class-even="'even-list-item'" class="list-group-item" ng-class="{'animated flipOutX':hctrl.messageMarkedAsReadId===message.id}">
                                                      <div class="m-b-10">
                                                          <span class="notification-message-date">{{message.creationDate| date:'shortDate' : '+0200'}}</span>
                                                          <span style="color:orange;" class="pull-right" translate="header.notificationMessagesUnread"></span>
                                                      </div>
                                                      <h4 class="list-group-item-heading">{{message.subject}}</h4>
                                                      <div class="list-group-item-text notification-message-body has-error" ng-bind-html="message.body"></div>
                                                      <div class="t-a-r m-t-10">
                                                          <button ng-hide="hctrl.openReplySection" type="input" class="btn btn-sm btn-default app-btn-blue" ng-click="hctrl.markMessageAsRead(message)">{{'header.notificationMessagesMarkAsRead' | translate}}</button>
                                                          <button ng-hide="hctrl.openReplySection" type="input" class="btn btn-sm btn-default app-btn-blue" ng-click="hctrl.openReplySectionAction($event,message)">{{'header.notificationMessagesReply' | translate}}</button>

                                                      </div>

                                                  </a>
                                              </div>
                                              <div ng-show="hctrl.openReplySection">
                                                  <a class="list-group-item">
                                                      <div class="m-b-10">
                                                          <span class="notification-message-date">{{hctrl.messageToShow.creationDate| date:'shortDate' : '+0200' }}</span>
                                                          <span style="color:orange;" class="pull-right" translate="header.notificationMessagesUnread"></span>
                                                      </div>
                                                      <h4 class="list-group-item-heading">{{hctrl.messageToShow.subject}}</h4>
                                                      <div class="list-group-item-text notification-message-body has-error" ng-bind-html="hctrl.messageToShow.body"></div>
                                                      <div class="m-t-10" ng-click="$event.preventDefault();" ng-class="{'animated fadeInDown': hctrl.openReplySection, 'animated zoomOutRight': hctrl.messageSuccessfullySent}">
                                                          <form name="sendMessageForm" novalidate>
                                                              <input name="subject" required ng-model="hctrl.sendMessageObject.subject" type="text" class="form-control m-b-5" placeholder="{{'header.notificationMessagesPlaceholderSubject' | translate}}" ng-class="{'b-c-error': sendMessageForm.subject.$invalid}">
                                                              <textarea name="body" required ng-model="hctrl.sendMessageObject.body" class="form-control" ng-class="{'b-c-error': sendMessageForm.body.$invalid}" rows="3" placeholder="{{'header.notificationMessagesPlaceholder' | translate}}"></textarea>
                                                              <div class="t-a-r m-t-10">
                                                                  <button ng-show="hctrl.openReplySection" type="input" class="btn btn-sm btn-default app-btn-blue" ng-click="hctrl.cancelReply()" translate="header.notificationMessagesCancelSend"></button>
                                                                  <button ng-show="hctrl.openReplySection" type="input" class="btn btn-sm btn-default app-btn-blue" ng-click="hctrl.sendMessage($event)" ng-disabled="!sendMessageForm.$valid" translate="header.notificationMessagesSend"></button>
                                                              </div>
                                                          </form>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="clearfix"></div><a class="lv-footer" href="">{{'header.notificationMessagesViewAll' | translate}}</a></div>
                                </div>
                          </li>
                          &lt;!&ndash;/MESSAGES&ndash;&gt;
                          &lt;!&ndash;E-INVOICES&ndash;&gt;
                        <li ng-if="hctrl.countryCode!='sr'" class="dropdown" uib-dropdown=""><a uib-dropdown-toggle="" href="" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"><i class="tm-icon zmdi zmdi-file-text"></i> <i ng-if="hctrl.eInvoiceDocumentsList.result.length" class="tmn-counts ">{{hctrl.eInvoiceDocumentsList.result.length}}</i></a>
                          <div class="dropdown-menu dropdown-menu-lg stop-propagate pull-left">
                            <div class="listview">
                                      <div class="lv-header">{{'header.notificationEInvoices' | translate}}</div>
                                      <div class="t-a-c p-10" ng-if="hctrl.eInvoiceDocumentsList.result.length == 0">
                                        <i class="zmdi zmdi-cloud-off zmdi-hc-3x"></i>
                                        <h6 translate="header.noeInvoices"></h6>
                                      </div>
                                      <div class="lv-body mCustomScrollbar" data-mcs-theme="minimal-dark" ng-if="hctrl.eInvoiceDocumentsList.result.length > 0">
                                          <div class="list-group">
                                              <div ng-repeat="eInvoice in hctrl.eInvoiceDocumentsList.result | limitTo:10">
                                                  <a ng-class-even="'even-list-item'" class="list-group-item">

                                                      <h4 class="list-group-item-heading">{{eInvoice.creditorName}}</h4>
                                                      <p class="list-group-item-text notification-einvoice-body">{{eInvoice.purpose}}</p>
                                                      <div class="m-t-10 m-b-10">
                                                          <span class="notification-einvoice-date">{{'header.notificationEInvoicesDueDate' | translate}}: <b>{{eInvoice.valueDate| date:'shortDate' : '+0200' }}</b></span>
                                                          <span class="notification-einvoice-amount pull-right"><b>{{eInvoice.amount|number:2}} {{eInvoice.currencySymbol}}</b></span>
                                                      </div>
                                                      <div class="t-a-r">
                                                          <button type="input" class="btn btn-sm btn-default app-btn-blue" ng-click="hctrl.openEInvoicesModal(eInvoice)">{{'header.notificationEInvoicesPay' | translate}}</button>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="clearfix"></div><a class="lv-footer" data-ui-sref="eInvoices.einvoicesOverview">{{'header.notificationEInvoicesViewAll' | translate}}</a>
                                    </div>
                              </div>
                          </li>
                          &lt;!&ndash;/E-INVOICES&ndash;&gt;
                          &lt;!&ndash;PENDING PAYMENTS&ndash;&gt;
                          <li class="dropdown" uib-dropdown=""><a uib-dropdown-toggle="" href="" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"><i class="tm-icon zmdi zmdi-time-interval"></i> <i ng-if="hctrl.pendingPaymentsList.result.length" class="tmn-counts  ">{{hctrl.pendingPaymentsList.result.length}}</i></a>
                              <div class="dropdown-menu dropdown-menu-lg stop-propagate pull-left">
                                <div class="listview">
                                      <div class="lv-header bg-warning c-white f-14"><i class="zmdi zmdi-time-interval"></i> {{'header.notificationPendingPayments' | translate}}</div>
                                      <div class="t-a-c p-10" ng-if="hctrl.pendingPaymentsList.result.length == 0">
                                        <i class="zmdi zmdi-cloud-off zmdi-hc-3x"></i>
                                        <h6 translate="header.noPendingPayments"></h6>
                                      </div>
                                      <div class="lv-body mCustomScrollbar" data-mcs-theme="minimal-dark" ng-if="hctrl.pendingPaymentsList.result.length > 0">
                                          <div class="list-group">
                                              <div ng-repeat="pendingPayment in hctrl.pendingPaymentsList.result">
                                                  <a ng-class-even="'even-list-item'" class="list-group-item">

                                                      <h4 class="list-group-item-heading">{{pendingPayment.receiverName}}</h4>
                                                      <p class="list-group-item-text notification-einvoice-body clickable-link">{{pendingPayment.purpose}}</p>
                                                      <div class="m-t-10 m-b-10">
                                                          <span class="notification-einvoice-date">{{'header.notificationPendingPaymentsDateLabel' | translate}}: <b>{{pendingPayment.dueDate| date:'shortDate' : '+0200' }}</b></span>
                                                          <span class="notification-einvoice-amount pull-right"><b>{{pendingPayment.amount|number:2}} {{pendingPayment.currencySymbol}}</b></span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="clearfix"></div><a class="lv-footer" data-ui-sref="payments">{{'header.notificationPendingPaymentsViewAll' | translate}}</a>
                                  </div>
                              </div>
                          </li>
                          &lt;!&ndash;/PENDING PAYMENTS&ndash;&gt;
                          &lt;!&ndash;REJECTED PAYMENTS&ndash;&gt;
                          <li class="dropdown" uib-dropdown=""><a uib-dropdown-toggle="" href="" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false"><i class="tm-icon zmdi zmdi-alert-triangle"></i> <i ng-if="hctrl.rejectedPaymentsList.result.length" class="tmn-counts">{{hctrl.rejectedPaymentsList.result.length}}</i></a>
                              <div class="dropdown-menu dropdown-menu-lg stop-propagate pull-left">
                                  <div class="listview">
                                      <div class="lv-header bg-danger c-white f-14"><i class="zmdi zmdi-alert-triangle"></i> {{'header.notificationRejectedPayments' | translate}}</div>
                                      <div class="t-a-c p-10" ng-if="hctrl.rejectedPaymentsList.result.length == 0">
                                        <i class="zmdi zmdi-cloud-off zmdi-hc-3x"></i>
                                        <h6 translate="header.noRejectedPayments"></h6>
                                      </div>
                                      <div class="lv-body mCustomScrollbar" data-mcs-theme="minimal-dark" ng-if="hctrl.rejectedPaymentsList.result.length > 0">
                                          <div class="list-group">
                                              <div ng-repeat="rejectedPayment in hctrl.rejectedPaymentsList.result">
                                                  <a ng-class-even="'even-list-item'" class="list-group-item">
                                                      <h4 class="list-group-item-heading">{{rejectedPayment.receiverName}}</h4>
                                                      <p class="list-group-item-text notification-einvoice-body clickable-link">{{rejectedPayment.purpose}}</p>
                                                      <div class="m-t-10 m-b-10">
                                                        <span class="notification-einvoice-date">{{'header.notificationRejectedPaymentsDateLabel' | translate}}: <b>{{rejectedPayment.dueDate| date:'shortDate' : '+0200' }}</b></span>
                                                          <span class="notification-einvoice-amount pull-right"><b>{{rejectedPayment.amount|number:2}} {{rejectedPayment.currencySymbol}}</b></span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="clearfix"></div><a class="lv-footer" data-ui-sref="payments">{{'header.notificationRejectedPaymentsViewAll' | translate}}</a>
                                  </div>
                              </div>
                          </li>
                          &lt;!&ndash;/REJECTED PAYMENTS&ndash;&gt;
                          &lt;!&ndash;SETTINGS&ndash;&gt;
                          <li class="dropdown hidden-sm hidden-md hidden-lg" uib-dropdown="" is-open="hctrl.mobileSettingsDropdownIsOpen">
                              <a uib-dropdown-toggle="" href="" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false">
                                  <i class="tm-icon zmdi zmdi-settings"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-lg stop-propagate pull-left">
                                  <div class="listview">
                                      <div class="lv-header f-14"><i class="zmdi zmdi-settings"></i> Settings</div>
                                      <div class="lv-body mCustomScrollbar" data-mcs-theme="minimal-dark">
                                          <ul class="list-group">
                                              <li class="list-group-item">
                                                  <div>
                                                      <h4 class="login-info-username">{{'header.signedInAs' | translate}} {{hctrl.userSettings.result[0].firstName}} {{hctrl.userSettings.result[0].middleName}} {{hctrl.userSettings.result[0].lastName}}</h4>
                                                      <p class="last-login-info">{{'header.lastLogin' | translate}}: {{hctrl.getDate() | date:'shortDate' : '+0200'}}</p>
                                                  </div>
                                                  <br>
                                                  <div class="t-a-c">
                                                      <button style="width:100%" class="btn btn-default app-btn-blue btn-sm"><i class="zmdi zmdi-square-right"></i> {{'header.logout' | translate}}</button>
                                                  </div>

                                              </li>
                                              <li class="list-group-item">
                                                  <p>Choose app language:</p>
                                                  <div class="list-group">
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('en'); hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/en.png" height="20px" alt="" /> {{'header.langEn' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('hr');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/hr.png" height="20px" alt="" /> {{'header.langHr' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('sr');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/sr.png" height="20px" alt="" /> {{'header.langSr' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('mtr');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/mtr.png" height="20px" alt="" /> {{'header.langMtr' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('ba');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/ba.png" height="20px" alt="" /> {{'header.langBa' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('sl');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/sl.png" height="20px" alt="" /> {{'header.langSl' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('de');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/de.png" height="20px" alt="" /> {{'header.langDe' | translate}}</a>
                                                      <a class="list-group-item" ng-click="hctrl.changeLanguage('ru');  hctrl.closeMobileSettingsDropdown();"><img src="img/hypo/flags/ru.png" height="20px" alt="" /> {{'header.langRu' | translate}}</a>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                      <div class="clearfix"></div><a class="lv-footer" data-ui-sref="payments">View all settings</a></div>
                              </div>
                          </li>
                          &lt;!&ndash;/SETTINGS&ndash;&gt;
                      </ul>
                  </div>
                  <div class="col-sm-4 hidden-xs login-info-wrapper p-l-0 p-r-0">
                      <img style="float:right;" src="img/hypo/illustration.png" height="60px" alt="" class="hidden-sm hidden-xs" />
                      <div>
                          <h4 class="login-info-username">{{'header.signedInAs' | translate}} {{hctrl.userSettings.result[0].firstName}} {{hctrl.userSettings.result[0].middleName}} {{hctrl.userSettings.result[0].lastName}} - <span class="logout-link"><a href="">{{'header.logout' | translate}}</a></span></h4>
                          <p class="last-login-info">{{'header.lastLogin' | translate}}: {{hctrl.getDate() | date:'shortDate' : '+0200'}}</p>
                      </div>

                  </div>-->
    </div>
  </div>
  <div class="visible-xs">
    <span class="header-app-name pull-right p-r-20"><span style="vertical-align:sub"><img data-ng-src="img/logo/AB_Logo.svg" alt="Addiko Bank"></span></span>
  </div>
</div>
<!--<nav id="appHeader" class="navbar navbar-default">
    <div class="container">
        &lt;!&ndash; Brand and toggle get grouped for better mobile display &ndash;&gt;
        <div class="navbar-header">
            <button id="mobileMenuBtn" type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-hypo-navbar-collapse-1" aria-expanded="true">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand visible-xs hidden-sm hidden-md hidden-lg"><img id="brandImg" src="img/hypo/hypobank_logo_grey@2x.png" height="40px" alt="" /></a>
            &lt;!&ndash;<span class="header-label-on-phone hidden-sm hidden-md hidden-lg">Dashboard</span>&ndash;&gt;
        </div>
        &lt;!&ndash; Collect the nav links, forms, and other content for toggling &ndash;&gt;
        <div class="collapse navbar-collapse" id="bs-hypo-navbar-collapse-1">
            <ul class="nav navbar-nav" ng-init="menuSelected=1">
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="dashboard" data-ui-sref-active="selected toggled"><a href="">{{'header.dashboard' | translate}}</a></div>
                </li>
              <li>
              </li>
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="accounts" data-ng-class="{ 'selected toggled': hctrl.isModuleActive('accounts') }"><a href="">{{'header.accounts' | translate}}</a></div>
                </li>
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="payments" data-ui-sref-active="selected toggled" ng-class="{selected: menuSelected == 3}"><a href="">{{'header.payments' | translate}}</a></div>
                </li>
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="orders" data-ui-sref-active="selected toggled"><a href="">{{'header.orders' | translate}}</a></div>
                </li>
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="branches" data-ui-sref-active="selected toggled" ng-class="{selected: menuSelected == 4}"><a href="">{{'header.branches' | translate}}</a></div>
                </li>
                <li class="app-header-custom-li">
                    <div ng-click="hctrl.closeMobileMenu('mobileMenuBtn')" class="app-header-menu-div" data-ui-sref="settings" data-ui-sref-active="selected toggled" ng-class="{selected: menuSelected == 4}"><a href="">{{'header.settings' | translate}}</a></div>
                </li>

            </ul>
        </div>
        &lt;!&ndash; /.navbar-collapse &ndash;&gt;
    </div>

    &lt;!&ndash; /.container-fluid &ndash;&gt;
</nav>-->
