(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
        secure3d: {
          text1: 'Addiko Secure Online Shopping is a service that provides the most secure standards that protect you from unauthorized use of your cards on the Internet. Depending on the type of the card that you use, either Mastercard Secure Code or Veryfied by Visa is engaged.',
          text12: 'The service needs to be activated prior to the first use. However, you activate it only once and it is applied to all of your Addiko cards. It is not necessary to activate the service for each card separately.',
          text2: 'The activation is mandatory and is being processed at the ',
          text3link: "Addiko Bank's website",
          text4: ' where you are going to be presented with all the details about the service and the activation procedure.',
          text5: 'Activate your Secure Online Shopping service today and shop safely!',
          buttonText: 'I want to activate the service',
          buttonTitle: 'Sigurna internetska kupnja'
        }
    };

    $translateProvider.translations('en', data);

  }
}());

