(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            settings: {
                "title": "Podešavanja",
                "tabAccount": "NA",
                "tabNotifications": "NA",
                "tabSecurity": "NA",
                "contactInfo": "Vaši kontaktni podaci",
                "contactInfoBtn": "Promijeni",
                "contactInfoConfirmBtn": "Potvrdi",
                "contactInfoCancelBtn": "Otkaži",
                "contactInfoName": "Ime",
                "contactInfoAddress": "Adresa",
                "contactInfoPhone": "Telefon",
                "blocades": "Blokade",
                "blockLogin": "Blokiranje logiranja u internet bankarstvo",
                "blockMobile": "Blokiranje logiranja u mobilno bankarstvo",
                "blockPayment": "Blokiranje kartice za plaćanje",
                "accountSettings": "Postavke računa",
                "defaultAccount": "Podrazumijevani račun",
                "statementsDelivery": "Dostava izvoda",
                "statementsDeliveryPaper": "Dostava izvoda na kućnu adresu",
                "statementsDeliveryElectronic": "Dostava izvoda u putem internet bankarstva",
                "messagesSettings": "Postavke poruka",
                "messagesSettingsLabel": "Označite ako želite da sve poruke od banke primate i na email",
                "securitySettings": "Sigurnosne postavke",
                "transactionsConfirmation": "Potvrda o transakcijama",
                "transactionsConfirmationSMS": "Slanjem SMS-a na mobitel",
                "transactionsConfirmationOTP": "Generisanjem jednokratne zaporke",
                "notificationSettings": "Postavke obaviještenja",
                'forceNewPassword': 'Potrebno je da obnovite/unesete novu lozinku.',
                'passwordWillExpire': 'Your password is about to expire. You need to change your password.',
                "gsm":"GSM",
                "oldPass":"Stara lozinka",
                "newPass":"Nova lozinka",
                "againPass":"Potvrdi novu lozinku",
                "noMatch":"Proverite da li ste dobro uneli lozinku.",
                "change":"Izmeni",
                "required": "Obavezan unos",
                "mothersLastName":"Devojačko prezime majke",
                "email":"Email",
                "lengthError":" Morate uneti najmanje {{value}} znakova u polje Nova lozinka !",
                "lengthMaxError":"Lozinka može sadržati najviše {{value}} znakova !",
                "lengthOldError":" Morate uneti najmanje {{value}} znakova u polje Stara lozinka !",
            }
        };

        $translateProvider.translations('sr', data);

    }
} ());
