<div id="savingsAccountWidget" class="p10 app-white-card">
  <div class="col-md-8 wc-right-div">
    <h4>Car savings fund</h4>
    <h2 class="no-bottom-margin">35.000,000,00 RSD</h2>
    <p>reached of 50.000,000,00</p>
  </div>
  <div class="col-md-4 wc-left-div">
    <div class="wc-left-div-wrapper">
      <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">View savings</button>
    </div>
  </div>
</div>
