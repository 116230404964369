
<div class="col-sm-12 col-md-12 p-r-0">

    <div class="col-sm-12 col-md-6 p-0 m-t-5">
     <!--<transactions-filter ng-if="accounts.currenciesList.length" transactions="accounts.connectedCardTransactionsList.result"-->
                         <!--transaction-currency-list="accounts.currenciesList"-->
                          <!--data-statements="(accounts.countryCode === 'sl')"-->
                         <!--transaction-filter-change="accounts.refreshCardTransactionsByFilter(filterParams)"-->
                         <!--currency="accounts.defaultCurrency">-->
    <!--</transactions-filter>-->

      <div data-ng-include="'accounts/connected-cards-transaction-filter.tpl.html'"></div>
    </div>
  <div id="periodViewSelector" class="period-selector">
    <div class="period-view-select-div left"><span translate="accounts.accPeriod"></span>:</div>
    <div class="period-view-select-div right">
      <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
        <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true">
          {{accounts.connectedCardsSelectedPeriod.description | translate}}
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li data-ng-repeat="period in accounts.periodList">
            <a href ng-click="accounts.changeConnectedCardsTransactionPeriod(period)">{{period.description | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

 <div class="t-a-c p-10 m-t-20 m-b-30 no-data-image" ng-show="accounts.connectedCardTransactionsList.result.length == 0">
  <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
  <h3 class="no-data-label" translate="transactions.noData"></h3>
</div>

<!--<div class="hypo-timeline-header-line col-sm-12 no-bottom-radius no-top-radius visible-md-block visible-sm-block visible-lg-block" ng-show="accounts.selectedAccount.transactions.result.length > 0">
<div class="col-md-6 col-sm-4 t-a-l p-0" translate="accounts.accDescription"></div>
<div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accAmount"></div>
<div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accBalance"></div>
</div>-->

<div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="row p-t-15" ng-show="accounts.connectedCardTransactionsList.result.length > 0">
  <div class="col-xs-12 col-sm-6 t-a-r hidden-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumCredit' | translate}} {{accounts.connectedCardTransactionsList.result[1].balanceSumCredit
      | number:2}} {{accounts.connectedCardTransactionsList.result[1].currencySymbol}}
    </label>
  </div>
  <div class="col-xs-12 col-sm-6 t-a-l hidden-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumDebit' | translate}} {{accounts.connectedCardTransactionsList.result[1].balanceSumDebit |
      number:2}} {{accounts.connectedCardTransactionsList.result[1].currencySymbol}}
    </label>
  </div>

  <div class="col-xs-12 t-a-c visible-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumCredit' | translate}} {{accounts.connectedCardTransactionsList.result[1].balanceSumCredit
      | number:2}} {{accounts.connectedCardTransactionsList.result[1].currencySymbol}}
    </label>
  </div>
  <div class="col-xs-12 t-a-c visible-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumDebit' | translate}} {{accounts.connectedCardTransactionsList.result[1].balanceSumDebit |
      number:2}} {{accounts.connectedCardTransactionsList.result[1].currencySymbol}}
    </label>
  </div>
</div>


<div class="timeline-row col-sm-12 p-l-0 p-r-0" ng-show="accounts.connectedCardTransactionsList.result.length > 0">
  <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0 no-top-radius">
    <ul class="hypo-timeline statements-timeline">
      <li data-ng-repeat="transaction in accounts.connectedCardTransactionsList.result" ng-class="{'time-label': transaction.isVirtual === true, 'tl-badge-icon': transaction.isVirtual === undefined}">
        <span ng-if="transaction.isVirtual === true">{{transaction.valueDate | date: 'shortDate' : '+0200'}}</span>
          <i ng-if="transaction.isVirtual == undefined" style="z-index: 1;"
            data-ng-style="transaction.transactionLogo ?{'background-image': 'url(' + transaction.transactionLogo +')'}: {}"
           ng-class="'icon icon-' + transaction.icon"></i>
        <div ng-if="transaction.isVirtual == undefined" class="hypo-timeline-item transaction-item" data-ng-click="transaction.selected = !transaction.selected; accounts.getTransactionDetails(transaction);" ng-class="{'opened': transaction.selected}">
          <div class="hypo-timeline-body">
            <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
              <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{transaction.merchantName || transaction.detail.purpose}}</b></h4>
              <p class="m-b-0 m-t-0 no-wrap-text">{{::transaction.detail.purpose}}</p>
            </div>
            <div ng-if="transaction.credit == null" class="col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.debit | number:2}} {{::transaction.currencySymbol}}</b></span></div>
            <div ng-if="transaction.debit == null" class="col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.credit | number:2}} {{::transaction.currencySymbol}}</b></span></div>


          </div>
          <div ng-if="transaction.selected" class="m-l-0 transaction-details">
            <div data-ng-include="'accounts/connected-cards-trans-details.tpl.html'"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div ng-if="accounts.connectedCardTransactionsList.hasMore == true" class="col-xs-12 t-a-c m-t-15 m-b-15">
  <button data-ng-click="accounts.loadMoreConnectedCardsTransactions(false)"
          type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
          translate="paymentsOverview.loadMoreBtn">Load more</button>
</div>
