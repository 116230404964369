<div class="modal-header statement-confirm-header p-10">
    <div class="row">
        <div class="col-md-12">
          <i class="float-right pointer zmdi zmdi-close zmdi-hc-2x" ng-click="$close()"></i>
        </div>
      </div>
</div>
<div class="modal-body text-center">
  <h3 translate="accounts.title">Information</h3>
  <p translate="alert.securityMessageHeading"></p>
  <p translate="alert.securityMessageBody"></p>
</div>
<div class="modal-footer text-center">
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="row text-center" style="display: flex; align-items: center; justify-content: center;">
        <button class="col-md-1 btn btn-default" ng-click="vm.setSecurityMessageSeen()"><span>Ok</span></button>
      </div>
    </div>
    <!-- <div class="col-md-5">
      <button class="btn btn-default" ng-click="$close()"><span
        translate="accounts.close"></span></button>
    </div> -->
  </div>
</div>
