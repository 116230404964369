
  <div class="container">

    <div class="currency-conversion-msg">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-10 m-t-30 m-b-30 int-payment">
            <!-- For all countries except SLO -->
            <div ng-if="transactionDivision.apiLocation != 'sl'" class="f-15">

              <h2 ng-if="transactionDivision.apiLocation != 'hr'" class="c-white f-28" translate="transactionDivision.successMessage" ></h2>
              <h2 ng-if="transactionDivision.apiLocation == 'hr'" 
                  class="c-white f-28" 
                  translate="transactionDivision.successMessageCro"
                  translate-values="{value1: transactionDivision.postResponseObj.amount, value2: transactionDivision.postResponseObj.installmentNumber}"></h2>
              
              <div ng-if="transactionDivision.apiLocation != 'hr'" class="m-t-15" >
                <span translate="transactionDivision.finalStepStatus"></span>
                <span [translate]="'transactionDivision.'+transactionDivision.postResponseObj.status" ></span>
              </div>
              <div ng-if="transactionDivision.apiLocation != 'hr'" >
                <span translate="transactionDivision.finalStepId"></span>
                <span>{{transactionDivision.postResponseObj.id}}</span>
              </div>
              <div ng-if="transactionDivision.apiLocation != 'hr'" class="m-t-15" >
                <span translate="transactionDivision.finalStepSentForProcessing"></span>
              </div>
              <div ng-if="transactionDivision.currentCountrySRB" class="m-t-15">
                <span translate="transactionDivision.finalStepSentForProcessingV2"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-t-20">
        <div class="col-xs-12 col-sm-6">
          <a href="" data-ng-click="transactionDivision.goToTransactionsPageSRB();" >
            <span class="money-exchange-icon"><i class="icon icon-reclam zmdi-hc-2x" style="color:#062a42;"></i></span>
            <span ng-if="!transactionDivision.currentCountrySRB && transactionDivision.apiLocation != 'hr'" 
                  class="f-18 money-exchange-text" translate="transactionDivision.makeAnotherPayment" ></span>
            <span ng-if="transactionDivision.currentCountrySRB || transactionDivision.apiLocation == 'hr'" 
                  class="f-18 money-exchange-text" translate="transactionDivision.backToTransactions" ></span>
          </a>
        </div>
      </div>
    </div>

  </div>
