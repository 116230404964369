<section id="main">
  <div class="container-fluid">
    <div ng-if="transactionDivision.step != 'final'" class="row domp-header-wrapper">
      <div class="domp-header-row col-md-12">
        <h2 class="f-28" translate="transactionDivision.title"></h2>
      </div>
    </div>
    <div class="m-b-30">
      <ui-view/>
    </div>
  </div>
</section>

