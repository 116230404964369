(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl($rootScope, $log, $state, $translate, CTLocalizationService, AccountsService, PaymentTemplatesService,
                         QuickPaymentModalService, TimelineService, HelperService, EInvoiceDocumentsService,
                         TransactionsService, MessagesService, DashboardWidgetModalService, CurrencyService,
                         $timeout, PaymentsService, $q, ImagesService, CampaignOfferService,ConfigService,
                         NotificationCountService, LoyaltyPointsService, $filter, $window, AlertService, ViberService, base64, GoogleTagManagerHelperService, $interval, $uibModal, NotificationService) {
    var vm = this;
    vm.localCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.recompile = {
      timeline: true
    };

    vm.currencySelectObject = {};

    vm.bannerCampaignEng = [];
    vm.bannerCampaignLocal = [];
    vm.carouselCampaignEng = [];
    vm.carouselCampaignLocal = [];
    vm.currenciesList = [];
    vm.currenciesFilter = {
      pageSize: 1000
    };
    vm.campaignsForCarouselEng = [];
    vm.answeredCampaignsLocal = [];
    vm.answeredCampaignsEng = [];


    vm.currentLang = '';
    if (vm.countryCode === 'hr' || vm.countryCode === 'bh') {
      vm.currentLang = $translate.proposedLanguage() || $translate.use();
      $rootScope.$on('appLanguageChangedNavigation', function (evt, lang) {
        if (lang && lang.langCode) {
          vm.currentLang = $translate.proposedLanguage() || $translate.use();
          console.log('lang', vm.currentLang);
        }
      });
      $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
        if (lang && lang.langCode) {
          vm.currentLang = $translate.proposedLanguage() || $translate.use();

          console.log('lang', vm.currentLang);
        }
      });
    }

    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      },
      {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }, {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }, {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
    ];

    if (vm.countryCode !== 'sl') {
      vm.periodList.push({
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      });
    }

    vm.periodList.push({
      item: {
        key: 'all',
        description: vm.countryCode === 'sl' ? 'core.period.last6Months' : 'core.period.all'
      }
    });

    vm.selectedPeriod = vm.periodList[3];

    vm.timelineTimeFilter = function (item) {
      vm.selectedPeriod = item;
      appendTimeRangeTlFilter();
      vm.resetTimelineFilter();
      vm.timelineFilter.itemTypeList = null;

      if (vm.countryCode === 'sl')
        vm.timelineFilter.currency = vm.currencySelectObject.symbol;

      vm.getTimelineItemsGrouped(true);

      GoogleTagManagerHelperService.pushEvent('Dashboard', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

    };

    vm.getDateFromDatestring = function (datestring) {
      return new Date(datestring);
    };

    vm.getGroupDateTitle = function (tlItem) {
      var date = new Date(tlItem.date);
    };

    vm.tmpSearchText = {
      name: ''
    };

    vm.showFavoriteTemplates = true;
    vm.showTemplatesView = false;
    vm.toggleTemplatesView = function () {

      vm.showTemplatesView = !vm.showTemplatesView;

      if (vm.showTemplatesView === true) {

        if (!vm.templateList.result || vm.templateList.result.length === 0) {
          vm.getPaymentTemplatesPage();
        }

        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleFavoriteTemplates = function (searchValue) {

      if (searchValue !== '') {
        vm.showFavoriteTemplates = false;
      } else {
        vm.showFavoriteTemplates = true;
      }
    };

    vm.changeLanguage = function (langKey) {
      CTLocalizationService.setLanguage(langKey);
    };

    vm.accountsFilter = {
      page: 0,
      pageSize: 1000,
      isFavorite: true,
      includeList: ['loanAccount']
    };

    // account filter for BiH environment
    if (vm.countryCode === 'bh') {
      vm.accountsFilter.orderBy = 'accountType';
    } else if(vm.countryCode === 'hr') {
      vm.accountsFilter.orderBy = 'accountType,iban,currencySymbol';
    } else {
      vm.accountsFilter.orderBy = 'accountType, iban';
    }

    vm.templatesFilter = {
      page: 0,
      pageSize: 1000
    };
    vm.timelineFilter = {
      page: 0,
      amountGTE: null,
      amountLTE: null,
      orderBy: 'date desc',
      pageSize: 10
    };

    var appendTimeRangeTlFilter = function () {
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl' && vm.selectedPeriod.item.key === 'all') {
        timeRange = HelperService.getDateRange('6months');
      }
      vm.timelineFilter.dateGTE = timeRange.valueDateGTE;
      vm.timelineFilter.dateLTE = timeRange.valueDateLTE;
    };

    appendTimeRangeTlFilter();
    vm.busy = false;

    vm.accountList = {};
    vm.accountListAll = {};
    vm.templateList = {};
    vm.timelineList = {};
    vm.timelineListGrouped = {};

    function showPaymentsReturnInfo() {
      var filter = {
        page: 0,
        orderBy: 'dateCreated DESC',
        status: 'Opened',
        pageSize: 15,
        paymentTypeGroupList: 'SEPARecall'
      };
      PaymentsService.getPage(filter)
        .then(function (response) {
          if (response.result && response.result.length > 0) {
            $window.sessionStorage.setItem('paymentsReturnInfoViewed', 'true')
            AlertService.paymentsReturnInfo();

          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    }

    vm.init = function () {
      vm.answeredBannerCampaignsEng = JSON.parse(sessionStorage.getItem('answeredBannerCampaignsEng'));
      if (vm.answeredBannerCampaignsEng === null) vm.answeredBannerCampaignsEng = [];

      vm.answeredBannerCampaignsLocal = JSON.parse(sessionStorage.getItem('answeredBannerCampaignsLocal'));
      if (vm.answeredBannerCampaignsLocal === null) vm.answeredBannerCampaignsLocal = [];

      vm.answeredCarouselCampaignsLocal = JSON.parse(sessionStorage.getItem('answeredCarouselCampaignsLocal'));
      if (vm.answeredCarouselCampaignsLocal === null) vm.answeredCarouselCampaignsLocal = [];

      vm.answeredCarouselCampaignsEng = JSON.parse(sessionStorage.getItem('answeredCarouselCampaignsEng'));
      if (vm.answeredCarouselCampaignsEng === null) vm.answeredCarouselCampaignsEng = [];

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
        vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        vm.countryCode = vm.defaultData.APILocation;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
        vm.userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
        // console.log("USEEEEERR", vm.userSettings);
      }

      vm.showBannerLocal = false;
      vm.showBannerEng = false;
      vm.showCarouselLocal = false;
      vm.showCarouselEng = false;

      vm.marketingInfo = vm.defaultData.MarketingInfo;


      // getNotificationsCount();
      vm.getAccountsPage(true);
      //vm.getAccountsPageAll();
      loadBannerImages();
      getCampaignOffer();

      if (vm.countryCode === 'sl') {
        vm.getLoyaltyPointsPage();
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.SyncEnabled && $rootScope.APP_GLOBAL_SETTINGS.defaultData.SyncEnabled === 'false') {
        vm.getTimelineItemsGrouped(true);
      } else {
        // Sync timeline to ensure that timeline is up to date
        syncTimeline().then(function () {
          vm.getTimelineItemsGrouped(true);
        });
      }

      vm.getExchangeListPage();

      var _tempSessionStorage = sessionStorage.getItem('paymentsReturnInfoViewed');
      if (vm.countryCode === 'hr' && !(_tempSessionStorage == 'true')) {
        showPaymentsReturnInfo();
      }

      vm.initLang = String(vm.currentLang);
    };


    var syncTimeline = function () {
      return TimelineService.syncTimeline()
        .then(function (response) {
          $rootScope.APP_GLOBAL_SETTINGS.loginInfo = response[0];
          console.log('Synced: ');
        }).catch(function (error) {
          console.log('Error: ' + error);
        });
    };

    vm.loadMoreAccounts = function () {
      vm.getAccountsPage(false);
    };

    vm.loadMoreTimelineItemsGrouped = function () {
      vm.getTimelineItemsGrouped(false);
    };

    vm.getAccountsPage = function (reset) {
      if (vm.busy) return;
      vm.busy = true;
      if (reset) {
        vm.accountsFilter.page = 0;
      } else {
        vm.accountsFilter.page += 1;
      }

      if (vm.accountList.hasMore || vm.accountsFilter.page === 0) {
        AccountsService.getPage(vm.accountsFilter)
          .then(function (response) {
            if (!reset) {
              vm.accountList.hasMore = response.hasMore;
              vm.accountList.result = vm.accountList.result.concat(response.result);
            } else {
              vm.accountList = response;
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
              sortAccountsForSloDashboard();
            }
            vm.busy = false;
            $log.debug(vm.accountList);
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };

    function sortAccountsForSloDashboard() {
      vm.currentAndCreditAccounts = [];
      vm.loanAccounts = [];
      vm.depositAccounts = [];
      vm.savingAccounts = [];
      vm.giroAccounts = [];

      if (vm.accountList.result.length !== 0) {
        _.forEach(vm.accountList.result, function (value) {
          if (value.accountType.group === 'CardAccountTypes' || value.accountType.group === 'CurrentAccount') {
            vm.currentAndCreditAccounts.push(value);
          } else if (value.accountType.group === 'LoanAccountType') {
            vm.loanAccounts.push(value);
          } else if (value.accountType.group === 'DepositAccountTypes') {
            vm.depositAccounts.push(value);
          } else if (value.accountType.group === 'SavingAccountTypes') {
            vm.savingAccounts.push(value);
          } else if (value.accountType.group === 'GiroAccount') {
            vm.giroAccounts.push(value);
          }
        });
        vm.currentAndCreditAccounts = $filter('orderBy')(vm.currentAndCreditAccounts, ['-accountType.group', '-isOwn', 'sortOrderId']);
        vm.depositAccounts = $filter('orderBy')(vm.depositAccounts, ['-isFavorite', '-isOwn', '-accountType.group']);
        vm.savingAccounts = $filter('orderBy')(vm.savingAccounts, ['-isFavorite', '-isOwn', '-accountType.group']);
      }
    }

    vm.getAccountsPageAll = function () {
      var deferred = $q.defer();
      AccountsService.getAll()
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          $log.error(error);
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    vm.getPaymentTemplatesPage = function () {
      // if(vm.countryCode == 'sl'){
      //   vm.templatesFilter = {
      //     paymentTypeGroupList: ['DomesticPayment','LocalCurrencyTransfer','ForeignPayment']
      //   }
      // }

      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
          vm.templateList = response;
          $log.debug(vm.templateList);
        }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

    };


  vm.openReceiverInfoDialog = function (event, transactionData) {
    event.stopPropagation();
    $uibModal.open({
        templateUrl: "accounts/merchant-info-modal/merchant-info-modal.tpl.html",
        size: "sm",
        controller: "MerchantInfoModalCtrl",
        controllerAs: "merchantInfo",
        resolve: {
            transaction: function () { return transactionData },
            googleMapsKey: function () { return ConfigService.googleMapsKey }
        },
        windowClass: 'merchant-info-modal',
        backdropClass: 'merchant-info-modal-backdrop',
        backdrop: "static",
    });
}

    // vm.markedLastLogin = false;
    vm.getTimelineItemsGrouped = function (reset) {
      if (reset) {
        vm.timelineFilter.page = 0;
      } else {
        vm.timelineFilter.page += 1;
      }


      if (vm.pendingPaymentsOpened) {
        vm.pendingPaymentsFilter.purposeFTS = vm.timelineSearchFilter.descriptionFTS;
        vm.getPendingPaymentsPage();
      } 
        var searchFilter = angular.extend(vm.timelineSearchFilter, vm.timelineFilter);
        TimelineService.getPage(vm.timelineSearchFilter)
          .then(function (response) {
            $(response.result).each(function (i, e) {
              if (e.itemType === 'TransactionCredit') {
                e.credit = e.amount;
                e.debit = null;
              } else if (e.itemType === 'TransactionDebit') {
                e.credit = null;
                e.debit = e.amount;
              } else {
                e.credit = null;
                e.debit = null;
              }

              HelperService.extractMerchantData(e);
            });

            vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
              existingData: vm.timelineListGrouped,
              newData: response,
              groupFunction: function (item) {
                var date = new Date(item.date);
                // If we want to group only by date component, without hours
                date.setHours(0, 0, 0, 0);
                return date;
              },
              virtualRowFunction: function (e) {
                var month = new Date(e).getMonth() + 1;
                var day = new Date(e).getDate();
                var virtualRow = {
                  isVirtual: true,
                  date: e,
                  month: month,
                  day: day
                };
                $log.debug('virtualRow', e, virtualRow);
                return virtualRow;
              },
              reset: reset,
              reverse: false
            });

            var todayDateRange = HelperService.getTodayWithoutOffset();
            var yesterdayDateRange = HelperService.getYesterdayWithoutOffset();


            _.forEach(vm.timelineListGrouped.result, function (item) {
              item.dateValue = new Date(item.date);
              if (item.dateValue >= todayDateRange.firstDay && item.dateValue <= todayDateRange.lastDay) {
                item.showText = true;
                item.dateLabel = 'core.period.today';
              } else if (item.dateValue >= yesterdayDateRange.firstDay && item.dateValue < yesterdayDateRange.lastDay) {
                item.showText = true;
                item.dateLabel = 'core.period.yesterday';
              } else {
                item.showDate = true;
              }
            });

            // Timeline items are sorted by date descending. Find first login item in results list and mark it as last login item.
            var lastLoginItem = _.find(vm.timelineListGrouped.result, function (item) {
              return item.itemType === 'LoginSuccessful';
            });
            if (lastLoginItem) {
              lastLoginItem.lastLogin = true;
            }

            $log.debug('groupedTimeline', vm.timelineListGrouped);
            //alert(2);
            vm.recompile.timeline = true;
          }).catch(function (error) {
            $log.error(error);
          });
    };

    vm.gotoAccount = function (accountId) {
      $state.go('accounts', {
        accountId: accountId
      });
    };
    vm.gotoAccountFromTimelineSearch = function (timelineItemId) {
      var temp = timelineItemId.split('_');
      var accountId = temp[1];


      $state.go('accounts', {
        accountId: accountId
      });
    };

    vm.gotoEInvoiceFromTimelineSearch = function (timelineItemId) {
      $state.go('eInvoices.detail', {
        eInvoiceId: timelineItemId
      });
    };

    vm.openQPModal = function () {
      QuickPaymentModalService.openQPModal();

    };

    vm.navigateToPaymentForm = function (templateParams) {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl' && templateParams.template.paymentSubTypeGroup === 'NotSupportedPayment') {
        AlertService.infoAlert({
          text: 'paymentsOverview.notSupportedPaymentMsg'
        });
        return;
      }
      var stateLink;
      var params = {
        templateId: templateParams.id
      };
      var template = templateParams.template;
      //var strObj = JSON.stringify({
      //  id: templateParams.id,
      //  type: 'template'
      //});
      switch (templateParams.type) {
        case 'LocalCurrencyTransfer':
          stateLink = 'payments.internalTransfer.fromTemplate';
          if (vm.countryCode === 'sl') {
            stateLink = 'payments.domesticPayment.fromTemplatesToPay';
          }
          break;
        case 'ForeignCurrencyTransfer':
          stateLink = 'payments.internalTransfer.fromTemplate';
          break;
        case 'DomesticPayment':
        case 'SEPAPayment':
        case 'RevenuePayment':
          stateLink = 'payments.domesticPayment.fromTemplate';
          if (vm.countryCode === 'sl') {
            stateLink = 'payments.domesticPayment.fromTemplatesToPay';
          }
          break;
        case 'CurrencyExchange':
          stateLink = 'payments.currencyConversion.fromTemplate';
          break;
        case 'ForeignPayment':
          stateLink = 'payments.internationalPayment.fromTemplate';
          if (vm.countryCode === 'sl') {
            stateLink = 'payments.internationalPayment.fromTemplatesToPay';
          }
          break;
      }


      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
        // AccountsService.getAccountById(template.accountId)
        //   .then (function (response) {
        //     angular.extend (template, response);
        // if (template.paymentTypeGroup == "LocalCurrencyTransfer" && (template.toAccountNumber.substring(0, 4) != "SI56" || template.fromAccountNumber.substring(0, 4) != "SI56")) {
        //   stateLink = 'payments.internalTransfer.fromTemplate';
        // } else if (template.paymentTypeGroup == "LocalCurrencyTransfer" && template.toAccountNumber.substring(0, 4) == "SI56") {
        //   stateLink = 'payments.domesticPayment.fromTemplate'
        // }


        if (template.paymentTypeGroup === 'LocalCurrencyTransfer' && template.paymentSubTypeGroup === 'SEPAPayment') {
          stateLink = 'payments.domesticPayment.fromTemplate';
        } else if (template.paymentTypeGroup === 'LocalCurrencyTransfer' && template.paymentSubTypeGroup === 'LocalCurrencyTransfer') {
          stateLink = 'payments.internalTransfer.fromTemplate';
        }


        // }).finally(function () {
        if (stateLink) {
          $state.go(stateLink, params);
        }
        // })

      } else {
        if (stateLink) {
          $state.go(stateLink, params);
        }
      }
    };

    vm.getTransactionDetails = function (timelineItem) {
      if (timelineItem.selected) {
        TransactionsService.getById(timelineItem.referenceId)
          .then(function (response) {
            timelineItem.transaction = response;
            if (response.accountId) {
              AccountsService.getAccountById(response.accountId)
                .then(function (response) {
                  timelineItem.account = response;
                })
                .catch(function (error) {
                  $log.error(error);
                });
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.getEinvoiceDetails = function (timelineItem) {
      EInvoiceDocumentsService.getById(timelineItem.referenceId)
        .then(function (response) {
          if (response) {
            timelineItem.einvoiceDetails = response;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.getMessageDetails = function (timelineItem) {
      var id;
      if (timelineItem.referenceType === 'Payment') {
        id = timelineItem.id.split('_')[0];
      } else {
        id = timelineItem.referenceId;
      }

      if (timelineItem.selected) {
        MessagesService.getById(id)
          .then(function (response) {
            timelineItem.messageDetails = response;
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.openPayment = function (paymentId) {
      PaymentsService.downloadPaymentPdf(paymentId);
    };

    vm.searchInputExpanded = true;
    vm.searchFilterExpanded = false;

    vm.showSearchResultWrapper = false;

    vm.expandSearch = function () {
      vm.timelineFilter.itemType = '';
      vm.resetTimelineFilter();


      if (vm.searchInputExpanded === false && vm.searchFilterExpanded === false) {
        vm.searchInputExpanded = !vm.searchInputExpanded;
        vm.searchFilterExpanded = !vm.searchFilterExpanded;

        $timeout(function () {
          var searchTimelineInput = angular.element('#searchTimelineInput');
          searchTimelineInput.focus();
        }, 500);

      } else if (vm.searchInputExpanded === true && vm.searchFilterExpanded === true) {
        vm.searchInputExpanded = !vm.searchInputExpanded;
        vm.searchFilterExpanded = !vm.searchFilterExpanded;
        vm.getTimelineItemsGrouped(true);

      } else if (vm.searchInputExpanded === true && vm.searchFilterExpanded === false) {
        vm.searchInputExpanded = !vm.searchInputExpanded;
        vm.getTimelineItemsGrouped(true);

      } else if (vm.searchInputExpanded === false && vm.searchFilterExpanded === true) {
        vm.searchFilterExpanded = !vm.searchFilterExpanded;

      }
    };

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.timelineSearchFilter.descriptionFTS = '';

      if (vm.searchFilterExpanded && vm.currenciesList.length === 0) {
        // Load currencies only when user opens filter
        vm.getExchangeListPage();
      }
    };

    vm.timelineItemTypeSelected = 1;

    vm.selectTimelineItemType = function (type) {
      switch (type) {
        case 'all':
          vm.timelineFilter.itemTypeList = null;
          vm.timelineItemTypeSelected = 1;
          break;
        case 'credit':
          vm.timelineFilter.itemTypeList = ['TransactionCredit'];
          vm.timelineItemTypeSelected = 2;
          break;
        case 'debit':
          vm.timelineFilter.itemTypeList = ['TransactionDebit'];
          vm.timelineItemTypeSelected = 3;
          break;
        case 'others':
          vm.timelineFilter.itemTypeList = ['MessageIn', 'MessageOut', 'EInvoice', 'LoginSuccessful', 'LoginFailed'];
          vm.timelineItemTypeSelected = 4;
          break;
        default:
          vm.timelineFilter.itemTypeList = null;
          vm.timelineItemTypeSelected = 1;

      }
      // $translate.instant(vm.selectedPeriod.item.description)
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'TimelineFilterCategorySelection', $translate.instant('timelineFilter.' + type));

      vm.resetTimelineFilter();
      //vm.getTimelineItemsGrouped(true)

    };

    vm.resetTimelineFilter = function () {
      vm.timelineFilter.amountGTE = null;
      vm.timelineFilter.amountLTE = null;
      vm.timelineFilter.currency = null;
      vm.timelineSearchFilter.descriptionFTS = '';
      setDefaultCurrencyOnFilter();
    };
    vm.applyTimelineFilter = function () {
      if (vm.timelineFilter.amountGTE || vm.timelineFilter.amountLTE) {
        vm.timelineFilter.currency = vm.currencySelectObject.symbol;
      }

      if (vm.countryCode === 'sl') {
        vm.timelineFilter.currency = vm.currencySelectObject.symbol;
      }

      $log.debug('timelineFiler', vm.timelineFilter);
      vm.getTimelineItemsGrouped(true);
      vm.searchFilterExpanded = false;
      // vm.numberOfPendingPaymentsToShow = 0;
    };

    vm.openWidgetsModal = function () {
      vm.getAccountsPageAll()
        .then(function (response) {
          vm.accountListAll = response;
          DashboardWidgetModalService.openDashboardWidgetModal(vm.accountListAll).then(function (closed) {
            if (closed) {
              vm.getAccountsPage(true);
            }
          })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        });

      GoogleTagManagerHelperService.pushEvent('Dashboard', 'FavoritesButton', 'Favorites button clicked');
    };

    vm.timelineSearchFilter = {
      descriptionFTS: '',
      pageSize: 20,
      orderBy: 'date DESC'
    };
    vm.timelineSearchResult = [];
    vm.getTimelineSearchPage = function () {
      TimelineService.getPage(vm.timelineSearchFilter)
        .then(function (response) {

          vm.timelineSearchResult = response.result;

          if (vm.timelineSearchResult.length !== 0) {
            vm.showSearchResultWrapper = true;
            vm.searchFilterExpanded = false;
          } else {
            vm.showSearchResultWrapper = false;
            vm.searchFilterExpanded = false;
          }

          $log.debug('timeline search result', response);
          vm.recompile.timeline = true;
        }).catch(function (error) {
          $log.error(error);
        });
    };


    function setDefaultCurrencyOnFilter() {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
        vm.currencySelectObject = vm.currenciesList[0];
      } else {
        vm.currencySelectObject = _.find(vm.currenciesList, function (item) {
          return item.symbol === vm.localCurrency;
        });
      }
    }

    vm.getExchangeListPage = function () {

      CurrencyService.getCurrencyPage(vm.currenciesFilter)
        .then(function (response) {
          vm.currenciesList = response.result;
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
            vm.currenciesList.unshift({code: '', symbol: ''});
          }
          setDefaultCurrencyOnFilter();
          //$log.debug("currenciesList", vm.currenciesList);
        }).catch(function (error) {
          $log.error(error);
        });

    };

    //GET PENDING PAYMENTS
    vm.pendingPaymentsFilter = {
      page: 0,
      status: null,
      statusList: ['InProcessing', 'WaitingQueue'],
      orderBy: 'dateCreated DESC',
      pageSize: 10
    };
    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
      vm.pendingPaymentsFilter.pageSize = 100;
    }

    if (vm.countryCode === 'sl')
      vm.pendingPaymentsFilter.statusList = ['InProcessing', 'WaitingQueue', 'Opened'];

    if (vm.countryCode === 'hr') {
      vm.orderBy = 'dateCreated DESC';
    }

    vm.pendingPaymentsList = {};
    vm.getPendingPaymentsPage = function () {

      PaymentsService.getPendingPayments(vm.pendingPaymentsFilter)
        .then(function (response) {
          vm.pendingPaymentsList = response;
          vm.pendingPaymentsOpened = true;
          $log.debug('pendingPaymentsList', vm.pendingPaymentsList);
        })
        .catch(function (error) {
          $log.error(error);
        });
    };
    vm.isNuN = HelperService.isNuN;

    vm.showPendingPayments = function () {
      if (!vm.pendingPaymentsList.result || vm.pendingPaymentsList.result.length === 0) {
        vm.pendingPaymentsFilter.purposeFTS = vm.timelineSearchFilter.descriptionFTS;
        vm.getPendingPaymentsPage();
      } else {
        vm.pendingPaymentsOpened = true;
      }
    };

    vm.hidePendingPayments = function () {
      vm.pendingPaymentsOpened = false;
    };

    vm.recallPaymentAction = function (paymentId) {
      var params = {
        id: paymentId
      };
      PaymentsService.recallPayment(params)
        .then(function (response) {
          vm.getPaymentsPage(true);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.signPaymentAction = function (paymentId) {
      PaymentsService.openSignModal(paymentId)
        .then(function (signed) {
          if (signed) {
            vm.getPaymentsPage(true);
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.deletePaymentAction = function (paymentId) {
      PaymentsService.deletePayment(paymentId)
        .then(function (response) {
          HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], paymentId);
          vm.getPaymentsPage(true);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var _tempSessionStorage1 = $window.sessionStorage.getItem('showNotificationsPanel');

    vm.showNotificationsPanel = (_tempSessionStorage1) ? _tempSessionStorage1 == 'true' ? true : false : true;

    //NOTIFICATIONS

    var geteInvoiceDocumentsPage = function () {
      var notifyParams = {
        type: 'eInvoiceDocuments',
        query: {
          status: 'Inserted'
        }
      };
      if (vm.userSettings.lastLoginDate) {
        notifyParams.query.valueDateGTE = new Date(vm.userSettings.lastLoginDate);
      }

      if (vm.countryCode === 'sl') { //temporarly put first day of the current year
        var date = new Date();
        date.setDate(new Date().getDate() - 365);
        notifyParams.query.valueDateGTE = new Date(date);
      }

      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.eInvoiceNotifications = +response;
        })
        .catch(function (error) {
          vm.eInvoiceNotifications = 0;
          $log.error(error);
        });
    };

    var getRejectedPaymentsPage = function () {
      var notifyParams = {
        type: 'Payments',
        query: {
          status: 'Rejected'
        }
      };

      if (vm.userSettings.lastLoginDate) {
        if (vm.countryCode === 'sl') {
          notifyParams.query.dueDateGTE = new Date(vm.userSettings.lastLoginDate);
        } else {
          notifyParams.query.dateCreatedGTE = new Date(vm.userSettings.lastLoginDate);
        }
      }
      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.rejectedPaymentsNotifications = +response;
        })
        .catch(function (error) {
          vm.rejectedPaymentsNotifications = 0;
          $log.error(error);
        });
    };

    vm.dismissNotifications = function () {
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'MessagesNotices', 'Discard');
      vm.showNotificationsPanel = false;
      $window.sessionStorage.setItem('showNotificationsPanel', 'false');
    };

    vm.pushNotificationsGtmEvent = function (desc) {
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'MessagesNotices', desc);
    };

    vm.downloadTransactionReport = function (transaction) {
      var id = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') ? transaction.transactionId : transaction.id;
      TransactionsService.downloadTransactionPdf(id);
    };

    vm.timelineSearchAction = function () {

      if (vm.countryCode === 'sl') {
        vm.timelineFilter.currency = vm.currencySelectObject.symbol;
      }

      vm.getTimelineItemsGrouped(true);


      // if (vm.timelineSearchFilter.descriptionFTS === '') {
      //     vm.showSearchResultWrapper = false;
      //     vm.showDeleteInputBtn = false;
      //     vm.getTimelineItemsGrouped(true);
      // } else {
      //     console.log(vm.pendingPaymentsList.result);
      //     console.log(vm.timelineListGrouped.result);
      //     vm.getTimelineItemsGrouped(true)
      //     vm.getTimelineSearchPage();
      //     vm.showDeleteInputBtn = true;
      // }
    };

    var getUnsignedPaymentsNotifications = function () {
      var notifyParams = {
        type: 'Payments',
        query: {
          status: 'Opened'
        }
      };

      if (vm.countryCode === 'sl') {
        notifyParams.query.status = '';
        notifyParams.query.statusList = ['InProcessing', 'WaitingQueue', 'Opened'];
      }

      if (vm.userSettings.lastLoginDate && vm.countryCode !== 'sl') {
        notifyParams.query.dateCreatedGTE = new Date(vm.userSettings.lastLoginDate);
      }
      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.unsignedPaymentsNotifications = +response;
        })
        .catch(function (error) {
          vm.unsignedPaymentsNotifications = 0;
          $log.error(error);
        });
    };

    var getNotificationsCount = function () {
      //
      // getGroupPaymentsNotifications();

      var promises = [getRejectedPaymentsPage(), getUnsignedPaymentsNotifications(), geteInvoiceDocumentsPage()];

      var _tempSessionStorage1 = $window.sessionStorage.getItem('notificationsLoaded');
      // If notifications were loaded once, don't load them again
      if (!(_tempSessionStorage1 == 'true')) {
        $q.all(promises).then(function (data) {
          vm.showNotificationsPanel = true;
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }

    };

    var loadBannerImages = function () {
      ImagesService.getImages({
        pageSize: 2,
        category: 'MarketingMessages'
      })
        .then(function (response) {
          if (response) {
            if (response.result[0]) {
              if (response.result[0].fileName.toLowerCase().includes('viber')) {
                vm.viberImageUrl = response.result[0].downloadLink;
              } else {
                vm.showNonViberBanner = true;
                vm.marketText1 = response.result[0].text1;
                vm.marketText2 = response.result[0].text2;
                vm.marketText3 = response.result[0].text3;
                vm.marketText1ENG = response.result[0].text1ENG;
                vm.marketText2ENG = response.result[0].text2ENG;
                vm.marketText3ENG = response.result[0].text3ENG;
                vm.marketButtonText = response.result[0].buttonText;
                vm.marketButtonTextENG = response.result[0].buttonTextENG;
                vm.bannerImageUrl = response.result[0].downloadLink;
                vm.bannerUrl = response.result[0].bannerUrl;
                vm.bannerUrlEng = response.result[0].bannerUrlEng;
              }
            }
            if (response.result[1]) {
              if (response.result[1].fileName.toLowerCase().includes('viber')) {
                vm.viberImageUrl = response.result[1].downloadLink;
              } else {
                vm.showNonViberBanner = true;
                vm.marketText1 = response.result[1].text1;
                vm.marketText2 = response.result[1].text2;
                vm.marketText3 = response.result[1].text3;
                vm.marketText1ENG = response.result[1].text1ENG;
                vm.marketText2ENG = response.result[1].text2ENG;
                vm.marketText3ENG = response.result[1].text3ENG;
                vm.marketButtonText = response.result[1].buttonText;
                vm.marketButtonTextENG = response.result[1].buttonTextENG;
                vm.bannerImageUrl = response.result[1].downloadLink;
                vm.bannerUrl = response.result[1].bannerUrl;
                vm.bannerUrlEng = response.result[1].bannerUrlEng;
              }
            }
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var filterAnsweredCampaigns = function (campaigns, filter) {
      if (filter.length === 0) {
        return campaigns;
      } else {
        return campaigns.filter(function (item) {
          return !filter.includes(item.campaignId);
        });
      }
    };

    var formatCampaignText = function (arrayOfCampaigns) {
      _.forEach(arrayOfCampaigns, function (campaign) {
        campaign.subjectText = campaign.subjectText.replace(/\r/g, '\n');
        campaign.subjectText = campaign.subjectText.replace(/\\n/g, '\n');
        campaign.subjectText = campaign.subjectText.replace(/\n/g, ' \n');
      });
      return arrayOfCampaigns;
    };

    var sortCampaignsByType = function (response, showedBannerCampaign, carouselCampaigns, selectedCampaign,
                                        answeredBannerCampaigns, answeredCarouselCampaigns, showBanner,
                                        showCarousel, currentIndex) {
      var sortedBannerCampaign = response.result.filter(function (campaign) {
        return campaign.templateType === 'Sidebanner';
      });
      var sortedCarouselCampaign = response.result.filter(function (campaign) {
        return campaign.templateType === 'Carousel';
      });
      sortedBannerCampaign = filterAnsweredCampaigns(sortedBannerCampaign, answeredBannerCampaigns);
      sortedCarouselCampaign = filterAnsweredCampaigns(sortedCarouselCampaign, answeredCarouselCampaigns);
      if (sortedBannerCampaign.length > 0) {
        // sortedBannerCampaign = _.orderBy(sortedBannerCampaign, 'priority', 'asc');
        sortedBannerCampaign.sort(function (a, b) {
          return b.priority - a.priority;});
        showBanner = true;
        vm.showNonViberBanner = false;
        showedBannerCampaign = sortedBannerCampaign;
      }
      showedBannerCampaign = formatCampaignText(showedBannerCampaign);

      if (sortedCarouselCampaign.length > 0) {
        showCarousel = true;
        // sortedCarouselCampaign = _.orderBy(sortedCarouselCampaign, 'priority', 'asc');
        sortedCarouselCampaign.sort(function (a, b) {
          return b.priority - a.priority;});
        if (sortedCarouselCampaign.length > 5) {
          sortedCarouselCampaign = _.slice(sortedCarouselCampaign, 0, 5);
        }
        sortedCarouselCampaign = formatCampaignText(sortedCarouselCampaign);
        carouselCampaigns = sortedCarouselCampaign;
        var currIndex = 0;
        _.forEach(carouselCampaigns, function (item) {
          item.index = currIndex++;
        });
        currentIndex = 0;
        selectedCampaign = sortedCarouselCampaign[0];

      }

      return {
        bannerCampaign: showedBannerCampaign,
        campaignsForCarousel: carouselCampaigns,
        selectedCampaign: selectedCampaign,
        answeredBannerCampaigns: answeredBannerCampaigns,
        answeredCarouselCampaigns: answeredCarouselCampaigns,
        showBanner: showBanner,
        showCarousel: showCarousel,
        currentIndex: currentIndex
      };

    };

    var getCampaignOffer = function () {
      vm.showBannerLocal = false;
      vm.showBannerEng = false;
      CampaignOfferService.getOffer()
        .then(function (response) {
          if (!(response.data && response.data.hasErrors)) {
            vm.currentLang = $translate.proposedLanguage() || $translate.use();
            //ENG LANGUAGE
            if (response[0] && response[0] !== [] && response[0].result && response[0].result.length > 0) {
              var values = sortCampaignsByType(response[0], vm.bannerCampaignEng, vm.campaignsForCarouselEng, vm.selectedCampaignEng, vm.answeredBannerCampaignsEng,
                vm.answeredCarouselCampaignsEng, vm.showBannerEng, vm.showCarouselEng, vm.currentIndexEng);
              vm.bannerCampaignEng = values.bannerCampaign;
              vm.campaignsForCarouselEng = values.campaignsForCarousel;
              vm.selectedCampaignEng = values.selectedCampaign;
              vm.answeredBannerCampaignsEng = values.answeredBannerCampaigns;
              vm.answeredCarouselCampaignsEng = values.answeredCarouselCampaigns;
              vm.showBannerEng = values.showBanner;
              vm.showCarouselEng = values.showCarousel;
              vm.currentIndexEng = values.currentIndex;
            }

            //LOCAL LANGUAGE
            if (response[1] && response[1] !== [] && response[1].result && response[1].result.length > 0) {
              var values = sortCampaignsByType(response[1], vm.bannerCampaignLocal, vm.campaignsForCarouselLocal, vm.selectedCampaignLocal, vm.answeredBannerCampaignsLocal,
                vm.answeredCarouselCampaignsLocal, vm.showBannerLocal, vm.showCarouselLocal, vm.currentIndexLocal);
              vm.bannerCampaignLocal = values.bannerCampaign;
              vm.campaignsForCarouselLocal = values.campaignsForCarousel;
              vm.selectedCampaignLocal = values.selectedCampaign;
              vm.answeredBannerCampaignsLocal = values.answeredBannerCampaigns;
              vm.answeredCarouselCampaignsLocal = values.answeredCarouselCampaigns;
              vm.showBannerLocal = values.showBanner;
              vm.showCarouselLocal = values.showCarousel;
              vm.currentIndexLocal = values.currentIndex;

            }
            if (vm.selectedCampaignEng) {
              setCurrentCampaign(vm.selectedCampaignEng.id, 0);
            }
            if (vm.selectedCampaignLocal) {
              setCurrentCampaign(vm.selectedCampaignLocal.id, 0);
            }
          } else {
            vm.showBannerLocal = false;
            vm.showBannerEng = false;
            vm.showCarouselLocal = false;
            vm.showCarouselEng = false;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.startAutoSlide = function () {
      if (angular.isUndefined(vm.autoSlide)) {
        vm.autoSlide = $interval(function () {
          vm.goToNextItem();
        }, 5000);
      }
    };

    vm.stopAutoSlide = function () {
      if (angular.isDefined(vm.autoSlide)) {
        $interval.cancel(vm.autoSlide);
        vm.autoSlide = undefined;
      }
    };

    vm.goToNextItem = function () {
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'Carousel', 'NextSlide');
      var campaigns = [];
      if (vm.currentLang === 'en') {
        vm.currentIndexEng++;
        campaigns = vm.campaignsForCarouselEng;
        if (vm.initLang !== vm.currentLang) {
          vm.stopAutoSlide();
          vm.initLang = String(vm.currentLang);
          setCurrentCampaign(campaigns[0].id);
        }
        if (vm.currentIndexEng === campaigns.length) {
          vm.currentIndexEng = 0;
        }
        vm.isPreviousButtonVisible = vm.currentIndexEng > 0;
        vm.isNextButtonVisible = vm.currentIndexEng < campaigns.length - 1;
        if (vm.currentIndexEng <= campaigns.length - 1) {
          vm.selectedCampaignEng = campaigns[vm.currentIndexEng];
        }
      } else {
        vm.currentIndexLocal++;
        campaigns = vm.campaignsForCarouselLocal;
        if (vm.initLang !== vm.currentLang) {
          vm.stopAutoSlide();
          vm.initLang = String(vm.currentLang);
          setCurrentCampaign(campaigns[0].id);
        }
        if (vm.currentIndexLocal === campaigns.length) {
          vm.currentIndexLocal = 0;
        }
        vm.isPreviousButtonVisible = vm.currentIndexLocal > 0;
        vm.isNextButtonVisible = vm.currentIndexLocal < campaigns.length - 1;
        if (vm.currentIndexLocal <= campaigns.length - 1) {
          vm.selectedCampaignLocal = campaigns[vm.currentIndexLocal];
        }
      }
    };

    vm.goToPreviousItem = function () {
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'Carousel', 'PreviousSlide');
      var campaigns = [];
      if (vm.currentLang === 'en') {
        vm.currentIndexEng--;
        vm.isPreviousButtonVisible = vm.currentIndexEng > 0;
        campaigns = vm.campaignsForCarouselEng;
        vm.isNextButtonVisible = vm.currentIndexEng < campaigns.length - 1;
        if (vm.currentIndexEng >= 0) {
          vm.selectedCampaignEng = campaigns[vm.currentIndexEng];
        }
      } else {
        campaigns = vm.campaignsForCarouselLocal;
        vm.currentIndexLocal--;
        vm.isPreviousButtonVisible = vm.currentIndexLocal > 0;
        vm.isNextButtonVisible = vm.currentIndexLocal < campaigns.length - 1;
        if (vm.currentIndexLocal >= 0) {
          vm.selectedCampaignLocal = campaigns[vm.currentIndexLocal];
        }
      }
    };

    var setCurrentCampaign = function (campaignId, currentIndex) {
      var filteredBy = null;
      var campaigns = [];
      if (vm.currentLang === 'en') {
        campaigns = vm.campaignsForCarouselEng;
        filteredBy = currentIndex ? currentIndex : campaignId;
        if (currentIndex === 0) {
          vm.currentIndexEng = 0;
        } else {
          vm.currentIndexEng = _.findIndex(campaigns, function (item) {
            return filteredBy === item.id;
          });
        }
        vm.isNextButtonVisible = vm.currentIndexEng < campaigns.length - 1;
        vm.isPreviousButtonVisible = vm.currentIndexEng > 0;
      }
      if (vm.currentLang !== 'en') {
        campaigns = vm.campaignsForCarouselLocal;
        filteredBy = currentIndex ? currentIndex : campaignId;
        if (currentIndex === 0) {
          vm.currentIndexLocal = 0;
        } else {
          vm.currentIndexLocal = _.findIndex(campaigns, function (item) {
            return filteredBy === item.id;
          });
        }
        vm.isNextButtonVisible = vm.currentIndexLocal < campaigns.length - 1;
        vm.isPreviousButtonVisible = vm.currentIndexLocal > 0;
      }
      vm.startAutoSlide();
    };

    vm.chooseFromCarousel = function (campaignId) {
      if (vm.currentLang === 'en') {
        vm.selectedCampaignEng = vm.campaignsForCarouselEng[vm.currentIndexEng];
        setCurrentCampaign(campaignId);
      } else {
        vm.selectedCampaignLocal = vm.campaignsForCarouselLocal[vm.currentIndexLocal];
        setCurrentCampaign(campaignId);
      }
    };


    vm.loyaltyPointsResult = {};
    vm.getLoyaltyPointsPage = function () {
      LoyaltyPointsService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          vm.loyaltyPointsResult = response.result[0];
          $log.debug('loyaltyPointsResult ', vm.loyaltyPointsResult);
        })
        .catch(function (error) {
          $log.error(error);
        });

    };

    vm.openAddikoClubSl = function () {
      $window.open('http://www.klub.addiko.si/', '_blank');
    };

    /*vm.openAddikoSurveySl = function () {
      $window.open("https://addiko.satmetrix.com/app/datacollection/datacollection/dynaSurvey.jsp?p=MTYAAAAAAAAAAFm9NvRcPLXpaPiA6QvWfuWnonz9HtqIX6JKsrvbWtR9IW%2FMZ5cWvE9IKTNvmYYDettGOsIIA%2BgtqF4zUvP94Q0Z4OBysb%2F34oVwNAhdr1eXF9dlXDNtvARGmZC0mZAoAK7PqAX9MYmcKcWqQVxAqW1fIrhUXnIjsN11u8827wCi5ygdReK1jN8RyLlnZXC0zaTIxP38J8BCIZuVDdT3NnAbgQZU2nog0q%2BQdXJu77pR2ejtWHP9tR9q02Q%2BvPozMA%3D%3D&id=-683512774&peid=ADDIKO&collectorType=LINK&follow-up_owner_1=olga.rusjan@addiko.com&country=Slovenia&hierarchy_1_node_code=ABS_ONLINE", "_blank");
    };*/

    vm.pushGtmLinkEvent = function (linkDesc) {
      GoogleTagManagerHelperService.pushEvent('Dashboard', 'ExternalLink', linkDesc);
    };

    // Viber functionality is just for BiH environment
    vm.checkUserViberActivation = function () {

      ViberService.checkActivation()
        .then(function (response) {

          $rootScope.APP_GLOBAL_SETTINGS.viberData = {
            phoneNumber: response.phoneNumber,
            activationDate: response.activationDate
          };

          var stateToGo = response.isViberUser ? 'viber.deactivate' : 'viber';
          $state.go(stateToGo);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });

      // var stateToGo = $rootScope.APP_GLOBAL_SETTINGS.isViberActivated ? 'viber.deactivate' : 'viber';
      // console.log("State to go");
      // $state.go(stateToGo);
    };

    vm.downloadMessageAttachment = function (link) {
      if (link.itemList) {
        MessagesService.downloadMessageAttachment(link.itemList.linkAttachment);
      }
    };

    vm.goToMarketingLinkCro = function () {
      var _link = vm.currentLang === 'en' ? vm.bannerUrlEng : vm.bannerUrl;
      if (_link) {
        $window.open(_link, '_blank');
      }
    };

    vm.goToMarketingLinkCustom = function (link) {
      if (link) {
        $window.open(link, '_blank');
      }
    };

    vm.formatText = function (text) {
      text.replace(/\r/g, '\n');
      text.replace(/\\n/g, '\n');
      text.replace(/\n/g, ' \n');
      return text;
    };

    vm.openThankYouPopup = function (buttonClicked, campaign) {
      var params = {
        buttonClicked: buttonClicked,
        text1: campaign.additionalDescription1 ? campaign.additionalDescription1 : '',
        text2: campaign.additionalDescription2 ? campaign.additionalDescription2 : '',
        text3: campaign.additionalDescription3 ? campaign.additionalDescription3 : ''
      };
      params.text1 = vm.formatText(params.text1);
      params.text2 = vm.formatText(params.text2);
      params.text3 = vm.formatText(params.text3);
      return $uibModal.open({
        templateUrl: 'dashboard/thank-you-popup.tpl.html',
        size: 'md',
        controller: 'ThankYouPopupCtrl',
        controllerAs: 'thankYouPopup',
        resolve: {
          data: function () {
            return params;
          }
        }
      });
    };


    vm.goToBannerLink = function (campaign, link, buttonClicked, campaignType) {
      if (link === 'TYD') {
        vm.openThankYouPopup(buttonClicked, campaign);
      }
      if (link && link !== '' && link !== 'TYD') {
        $window.open(link, '_blank');
      }
      CampaignOfferService.putCampaignResponse(campaign, buttonClicked).then(function () {
        if (campaignType === 'banner') {
          if (vm.currentLang === 'en') {
            if (buttonClicked === 'button2') {
              vm.answeredBannerCampaignsEng.push(vm.bannerCampaignEng[0].campaignId);
              sessionStorage.setItem('answeredBannerCampaignsEng', JSON.stringify(vm.answeredBannerCampaignsEng));
            }
            if (vm.bannerCampaignEng.length > 1) {
              vm.bannerCampaignEng.shift();
              //vm.bannerCampaignEng = _.orderBy(vm.bannerCampaignEng, 'priority', 'asc');
              vm.bannerCampaignEng.sort(function (a, b) {
                return b.priority - a.priority;});
            } else {
              vm.bannerCampaignEng = [];
              vm.showBannerEng = false;
            }
          }
          if (vm.currentLang !== 'en') {
            if (buttonClicked === 'button2') {
              vm.answeredBannerCampaignsLocal.push(vm.bannerCampaignLocal[0].campaignId);
              sessionStorage.setItem('answeredBannerCampaignsLocal', JSON.stringify(vm.answeredBannerCampaignsLocal));
            }
            if (vm.bannerCampaignLocal.length > 1) {
              vm.bannerCampaignLocal.shift();
              //vm.bannerCampaignLocal = _.orderBy(vm.bannerCampaignLocal, 'priority', 'asc');
              vm.bannerCampaignLocal.sort(function (a, b) {
                return b.priority - a.priority;});

            } else {
              vm.bannerCampaignLocal = [];
              vm.showBannerLocal = false;
            }
          }
        }

        if (campaignType === 'carousel') {
          if (vm.currentLang === 'en') {
            if (buttonClicked === 'button2') {
              vm.answeredCarouselCampaignsEng.push(campaign.campaignId);
              sessionStorage.setItem('answeredCarouselCampaignsEng', JSON.stringify(vm.answeredCarouselCampaignsEng));
            }
            vm.campaignsForCarouselEng.splice(_.findIndex(vm.campaignsForCarouselEng, function (item) {
              return item.id === campaign.id;
            }), 1);
            vm.currentIndexEng++;
            var currIndex = 0;
            _.forEach(vm.campaignsForCarouselEng, function (item) {
              item.index = currIndex++;
            });
            if (vm.campaignsForCarouselEng.length === 0) {
              vm.showCarouselEng = false;
            }
            setCurrentCampaign(vm.campaignsForCarouselEng[0].id, 0);
          }
          if (vm.currentLang !== 'en') {
            if (buttonClicked === 'button2') {
              vm.answeredCarouselCampaignsLocal.push(campaign.campaignId);
              sessionStorage.setItem('answeredCarouselCampaignsLocal', JSON.stringify(vm.answeredCarouselCampaignsLocal));
            }
            vm.campaignsForCarouselLocal.splice(_.findIndex(vm.campaignsForCarouselLocal, function (item) {
              return item.id === campaign.id;
            }), 1);
            vm.currentIndexLocal++;
            var currIndex = 0;
            _.forEach(vm.campaignsForCarouselLocal, function (item) {
              item.index = currIndex++;
            });
            if (vm.campaignsForCarouselLocal.length === 0) {
              vm.showCarouselLocal = false;
            }
            setCurrentCampaign(vm.campaignsForCarouselLocal[0].id, 0);
          }
        }


      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.init();
  }

}());
