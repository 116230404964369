<section id="main" ng-controller="TermDepositCtrlOther as termDeposit">
    <div class="container-fluid">
        <div class="row f-14">

            <div class="col-sm-12">
                <div class="app-white-card m-b-15">
                    <div class="intp-header-wrapper bottom-border" ng-show="!termDeposit.steps.step3">
                        <div class="row intp-header-row">
                            <div class="col-xs-12">
                                <h2 class="title-style" translate="termDeposit.termDepositHeader"></h2>
                            </div>
                        </div>
                    </div>
                    <div ng-show="termDeposit.steps.step1">
                        <form name="termDepositForm" novalidate>
                            <div class="intp-form-container bottom-border">
                                <div class="row">
                                    <div class="col-sm-6 p-0">
                                        <div class="col-sm-12">
                                            <label class="ntd-form-label m-b-5" translate="termDeposit.debitAccountSelectLabel"></label>
                                        </div>
                                        <div class="col-sm-12" >

                                            <div class="accounts-dropdown m-b-10" ng-if="termDeposit.accountsFrom.length > 0">
                                                <!--<div class="debit-account-widget dashboard-widget-item bc-size-10" data-ng-click="termDeposit.toggleFromAccount();">
                                                    <h4 class="no-wrap-text bold-font">
                                                        {{termDeposit.tempTermDepositObj.fromAccountSelect.friendlyName}}
                                                        <span class="favorite-star-container">
                                                            <i class="zmdi "
                                                               ng-class="{'zmdi-star favorite-account':termDeposit.tempTermDepositObj.fromAccountSelect.isFavorite,
                                                                         'zmdi-star-outline':!termDeposit.tempTermDepositObj.fromAccountSelect.isFavorite}"></i>
                                                        </span>
                                                    </h4>
                                                    <p class="no-bottom-margin">{{termDeposit.tempTermDepositObj.fromAccountSelect.iban}}</p>
                                                    <p class="m-b-0 bold-font f-18">
                                                        {{termDeposit.tempTermDepositObj.fromAccountSelect.availableBalance | number:2}} {{termDeposit.tempTermDepositObj.fromAccountSelect.currencySymbol}}
                                                    </p>
                                                    <span class="d-block m-b-9" translate="currencyConversion.availableBalance"></span>
                                                    <span class="caret-icon">
                                                        <i class="zmdi zmdi-caret-down zmdi-hc-2x"></i>
                                                    </span>
                                                </div>
                                                <div class="accounts-list m-l-15 m-r-15" style="border:1px solid black;" ng-show="termDeposit.fromAccountDropdown" scrollable>
                                                    <div class="accounts-list-item" data-ng-repeat="item in termDeposit.accountsFrom" data-ng-click="termDeposit.setAccountFrom(item);">
                                                        <span class="d-block f-16 bold-font">{{item.friendlyName}}</span>
                                                        <span class="d-block">{{item.iban}}</span>
                                                        <span class="d-block bold-font f-18">{{item.availableBalance | number:2}} {{item.currencySymbol}}</span>
                                                        <span class="d-block" translate="currencyConversion.availableBalance"></span>
                                                    </div>
                                                </div>-->


                                                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="termDeposit.toggleFromAccount();"  ng-style="termDeposit.tempTermDepositObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + termDeposit.tempTermDepositObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                                    <h4 class="no-wrap-text bold-font m-b-0">
                                                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{termDeposit.tempTermDepositObj.fromAccountSelect.friendlyName}}</b>
                                                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':termDeposit.tempTermDepositObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!termDeposit.tempTermDepositObj.fromAccountSelect.isFavorite}"></i></span>
                                                    </h4>
                                                    <p class="m-b-0 m-t-0 iban-style">{{termDeposit.tempTermDepositObj.fromAccountSelect.iban}}</p>
                                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                        {{termDeposit.tempTermDepositObj.fromAccountSelect.availableBalance | number:2}} {{termDeposit.tempTermDepositObj.fromAccountSelect.currencySymbol}}
                                                    </p>
                                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                                    <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                                                </div>
                                                <div class="from-accounts-list m-l-15" ng-show="termDeposit.fromAccountDropdown" scrollable>
                                                    <div class="accounts-list-item p-0" data-ng-repeat="item in termDeposit.accountsFrom" data-ng-click="termDeposit.setAccountFrom(item);">

                                                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                                            <h4 class="no-wrap-text bold-font m-b-0">
                                                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                                            </h4>
                                                            <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                                                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                                {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                                            </p>
                                                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div class="accounts-dropdown m-b-10" ng-if="!termDeposit.accountsFrom.length || termDeposit.accountsFrom.length === 0">
                                                <!--<div class="debit-account-widget dashboard-widget-item" style="height: 114px; line-height: 114px" translate="internalTransfer.noData"  ng-style="termDeposit.tempTermDepositObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + termDeposit.tempTermDepositObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                                    No available accounts
                                                </div>-->
                                              <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                                                   ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                                <div class="m-t-30 m-b-30">
                                                  <p translate="internalTransfer.nodata"></p>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 p-0">
                                        <div class="col-xs-12">
                                            <label class="ntd-form-label m-b-5" translate="termDeposit.amountInputLabel"></label>
                                        </div>
                                        <div class="col-xs-12">
                                            <div class="input-group">
                                                <input required type="text" class="form-control h-48 t-a-r"  amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" ng-model="termDeposit.tempTermDepositObj.amount" ng-change="termDeposit.amountChanged(termDeposit.tempTermDepositObj.amount)">
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default input-group-app-btn" disabled>{{termDeposit.tempTermDepositObj.fromAccountSelect.currencySymbol}}</button>
                                                    </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-xs-12 m-t-10">
                                            <div class="hidden-sm hidden-md hidden-lg">
                                                <label class="ntd-form-label compact" translate="termDeposit.maturityInMonthsSelectLabel"></label>
                                            </div>
                                            <div>
                                                <select ng-options="option.periodFrom for option in termDeposit.maturityInMonthsList" ng-model="termDeposit.tempTermDepositObj.maturityInMonths" class="form-control t-a-c"></select>
                                            </div>
                                            <div class="hidden-xs">
                                                <label class="ntd-form-label compact" translate="termDeposit.maturityInMonthsSelectLabel"></label>
                                            </div>

                                        </div>
                                        <div class="col-sm-4 col-xs-12 m-t-10">
                                            <div class="hidden-sm hidden-md hidden-lg">
                                                <label class="ntd-form-label compact" translate="termDeposit.stimulativeInterestRateInputLabel"></label>
                                            </div>
                                            <div>
                                                <input type="text" class="form-control t-a-r" readonly ng-value="termDeposit.tempTermDepositObj.stimulativeInterestRate + ' %'">
                                            </div>
                                            <div class="hidden-xs">
                                                <label class="ntd-form-label compact" translate="termDeposit.stimulativeInterestRateInputLabel"></label>
                                            </div>

                                        </div>
                                        <div class="col-sm-4 col-xs-12 m-t-10">
                                            <div class="hidden-sm hidden-md hidden-lg">
                                                <label class="ntd-form-label compact" translate="termDeposit.totalInterestRateInputLabel"></label>
                                            </div>
                                            <div>
                                                <input type="text" class="form-control t-a-r" readonly ng-value="termDeposit.tempTermDepositObj.totalInterestRate + ' %'">
                                            </div>
                                            <div class="hidden-xs">
                                                <label class="ntd-form-label compact" translate="termDeposit.totalInterestRateInputLabel"></label>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="intp-form-container bottom-border">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <!--<label class="ntd-form-label" translate="termDeposit.interestRatePayoutTypeSelectLabel"></label>-->
                                    </div>
                                    <div class="col-sm-6">

                                        <div ng-init="termDeposit.isPayoutTypeSelectionCollapsed = true">
                                            <button type="button" class="btn btn-default w-100 m-l-5 m-r-5" ng-click="termDeposit.isPayoutTypeSelectionCollapsed = !termDeposit.isPayoutTypeSelectionCollapsed"><span style="
                                                top: 5px;
                                                max-width: 80%;
                                                overflow: hidden;
                                                display: inline-block;
                                                white-space: nowrap;
                                                position: relative;
                                                text-overflow: ellipsis;
                                                ">{{termDeposit.selectedTermDepositPayoutType.description|translate}}</span><span class="zmdi zmdi-caret-down pull-right zmdi-hc-2x"></span></button>
                                            <div uib-collapse="termDeposit.isPayoutTypeSelectionCollapsed">
                                                <div class="">
                                                    <ul class="list-group">
                                                        <li class="list-group-item list-hover-selection" ng-repeat="payoutItem in termDeposit.termDepositPayoutTypeList">
                                                            <a ng-click="termDeposit.selectPayoutType(payoutItem);" href="">
                                                                <p class="m-b-5">{{payoutItem.description|translate}}
                                                                    <br>{{payoutItem.explanation|translate}}</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="intp-form-container bottom-border">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="ntd-form-label active-link pointer" translate="termDeposit.contractDraftLinkLabel" ng-click="termDeposit.manageDocumentDownload('PreContractForm', null)"></label>
                                    </div>
                                </div>

                                <!-- HR documents START -->
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label active-link pointer" translate="termDeposit.hrDoc1" ng-click="termDeposit.manageDocumentDownload('PreContractInfo', null)"></label>
                                  </div>
                                </div>
                                <!-- <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label active-link pointer">
                                        <a ng-href="{{termDeposit.additionalLinks.link1}}" target="_blank" translate="termDeposit.hrDoc2"></a>
                                      </label>
                                  </div>
                                </div> -->
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label active-link pointer">
                                        <a  translate="termDeposit.hrDoc3" ng-href="{{termDeposit.additionalLinks.link1}}" target="_blank"></a>
                                      </label>
                                  </div>
                                </div>
                                <!-- <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label active-link pointer">
                                        <a translate="termDeposit.hrDoc4" ng-href="{{termDeposit.additionalLinks.link3}}" target="_blank"></a>
                                      </label>
                                  </div>
                                </div> -->
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label active-link pointer">
                                        <a translate="termDeposit.hrDoc5" ng-href="{{termDeposit.additionalLinks.link4}}" target="_blank"></a>
                                      </label>
                                  </div>
                                </div>
                               
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                    <div class="col-sm-12">
                                        <label class="ntd-form-label active-link pointer" translate="termDeposit.hrDocNew" ng-click="termDeposit.manageDocumentDownload('InfoForm', null)"></label>
                                    </div>
                                </div>
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                    <div class="col-sm-12">
                                        <label class="ntd-form-label active-link pointer">
                                          <a translate="termDeposit.hrDoc6" ng-href="{{termDeposit.additionalLinks.link5}}" target="_blank"></a>
                                        </label>
                                    </div>
                                  </div>
                                <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                  <div class="col-sm-12">
                                      <label class="ntd-form-label">
                                        <span translate="termDeposit.hrDocRest"></span>
                                        <a class="active-link pointer" translate="termDeposit.hrDocRestLink" ng-href="{{'https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo/'}}" target="_blank"></a>
                                      </label>
                                  </div>
                                </div>


                                <!-- HR documents END -->

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div>
                                            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value pointer">
                                                <input type="checkbox" ng-model="termDeposit.isCondition1Checked" required>
                                                <i class="input-helper"></i>
                                            </label><a class="m-l-25 pointer" ng-click="termDeposit.openConditionsLink(termDeposit.configData.DepositOrderConditionURLs.GeneralConditionURL)">{{'termDeposit.generalConditionsLinkLabel1' | translate}}</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                          <div class="intp-form-container bottom-border">
                            <div class="row">
                              <div class="col-xs-6 t-a-l">
                                <button type="button" class="btn btn-primary" translate="termDeposit.cancelBtnLabel"
                                        data-ui-sref="depositAccountsOverview">
                                </button>
                              </div>
                              <div class="col-xs-6 t-a-r">
                                <button type="button" class="btn btn-default" translate="termDeposit.confirmBtnLabel"
                                        ng-disabled="!termDepositForm.$valid" ng-click="termDeposit.confirmAction();">
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                    </div>
                    <div ng-show="termDeposit.steps.step2">
                        <div data-ng-include="'orders/term-deposit-resume.tpl.html'">
                        </div>
                    </div>
                    <div ng-if="termDeposit.contryCode == 'hr'" class="row m-t-20"
                         style="margin-left: 0; margin-right: 0;"
                         ng-show="termDeposit.steps.step3">
                        <addiko-mtoken-sign
                            show-cancel="false"
                            placeholder="termDeposit.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'termDeposit.signTitle'" 
                            qr-challenge="termDeposit.qrChallenge" show-challenge="termDeposit.showChallenge" 
                            challenge1="termDeposit.challengeFirstCode" challenge2="termDeposit.challengeSecondCode"
                            action-disabled="termDeposit.confirmSignBtnDisabled"></addiko-mtoken-sign>
                    </div>
                    <div ng-if="termDeposit.contryCode != 'hr'" class="row" ng-show="termDeposit.steps.step3">
                      <div class="exchange-rate-header m-l-25">
                        <div class="row">
                          <div class="col-sm-6 col-xs-12 f-20" translate="termDeposit.signTitle"></div>
                        </div>
                      </div>
                      <div class="col-xs-12 m-t-30 m-b-30">
                          <div class="col-xs-12 m-b-20">
                              <span class="f-w-bold">{{'signPayments.challengeDescription' | translate}}</span>
                          </div>
                          <div class="col-sm-4 col-xs-12">
                              <span class="f-w-bold">{{'signPayments.challengeFirstCode' | translate}}</span>
                              <input type="text" class="form-control input-field" disabled data-ng-model="termDeposit.insertDepositOrderResponse.challenge">
                          </div>
                          <div class="col-sm-4 col-xs-12">
                              <span class="f-w-bold">{{'signPayments.challengeSecondCode' | translate}}</span>
                              <input type="text" class="form-control input-field" data-ng-model="termDeposit.challengeResponse" ng-pattern-restrict="{{termDeposit.regexPatterns.onlyNumbers}}" placeholder="{{'signPayments.challengeSecondCode' | translate}}" autofocus>
                          </div>
                          <div class="col-sm-4 col-xs-12 m-t-20">
                              <button ng-if="termDeposit.contryCode != 'hr'" class="btn btn-primary col-sm-8 col-xs-4 pull-right" 
                                      data-ng-click="termDeposit.signConfirmAction()" 
                                      ng-disabled="termDeposit.challengeResponse === ''"
                                      translate="signPayments.confirm">Confirm</button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div ng-show="termDeposit.steps.step1" data-ng-include="'orders/deposit-overview-timeline.tpl.html'"></div>
    </div>
</section>
