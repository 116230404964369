(function () {
  'use strict';

  /* @ngdoc object
   * @name comtradeBankingWeb
   * @description
   *
   */
  angular
    .module('comtradeBankingWeb', [
      'ui.router',
      'ngSanitize',
      'ui.select',
      'ngCookies',
      'ngPatternRestrict',
      'tmh.dynamicLocale',
      'home',
      'template',
      'materialAdmin',
      'accounts',
      'core',
      'dashboard',
      'transactions',
      'statements',
      'localization',
      'messages',
      'eInvoices',
      'paymentTemplates',
      'payments',
      'user',
      'timeline',
      'orders',
      'ngMap',
      'settings',
      'navigation',
      'angular-click-outside',
      'ui.mask',
      'investments',
      'employeePayroll',
      'toastr',
      'app.constants',
      'ui-notification',
      'alert',
      'documents',
      'sepa',
      'base64',
      'ab-base64',
      'blockade',
      'viber',
      'secure3d',
      'complaints',
      'location',
      'ui.bootstrap',
      'cfp.loadingBar'
    ]);
}());
