(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orders.controller:OrdersCtrl
   *
   * @description
   *
   */
  angular
    .module('orders')
    .controller('OrdersCtrl', OrdersCtrl)
    .filter('orderStatusString', orderStatusString);

  function OrdersCtrl() {
    console.log("ORDERS CTRL");
  }

  function orderStatusString() {
    return function (input) {
      var status = ['orders.statusOpen'];
      var out = '';
      for (var i = 0; i < status.length; i++) {
        if (i === parseInt(input))
          out = status[i];
      }
      return out;
    };
  }

}());
