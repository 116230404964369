<section id="main">
  <div class="container-fluid">
    <div class="exchange-rates">
      <div class="no-padding-on-phone">

        <div ng-if="domesticPayment.location=='hr'" data-ng-include="'payments/domestic-payment-cro.tpl.html'"></div>
        <div ng-if="domesticPayment.location=='sr'" data-ng-include="'payments/domestic-payment-srb.tpl.html'"></div>
        <div ng-if="domesticPayment.location=='mne'" data-ng-include="'payments/domestic-payment-mne.tpl.html'"></div>
        <div ng-if="domesticPayment.location=='sl'" data-ng-include="'payments/domestic-payment-slo.tpl.html'"></div>
        <div ng-if="domesticPayment.location=='bh'" data-ng-include="'payments/domestic-payment-bh.tpl.html'"></div>

      </div>
    </div>
  </div>
</section>
