(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      blockade: {
        title: "Block Addiko EBank/Addiko Mobile",
        timeRestrictedBlockadeText: "If you would like to be able to use Addiko EBank at a later time, you must visit your branch office, where you have applied for Addiko Ebank and fill out the form to unblock Addiko EBank.",
        info1: "You can disable the use of the Addiko EBank/Addiko Mobile for a fixed or indefinite period of time. Note: For the time the application is disabled you will not be able to use Addiko EBank and/or Addiko Mobile. If you choose to disable the application for an indefinite period of time you will have to visit one of the Addiko branch offices to re-activate it.",
        info2: "",
        blockadeReason: "Blockade reason",
        blockadeOf: "Blockade of",
        timeRestrictedBlockade: "Time-restricted blockade",
        dateFrom: "From",
        dateTo: "To",
        orderDate: "Order date",
        orderTypeLabel: "Order type",
        orderTypeText: "Block operations of",
        and: "and",
        blockadeTypeLabel: "Blockade type",
        blockadeTypeText: "Block operations between",
        blockadeUnrestricted: "Time-unrestricted blockade",
        blockadeFinishedTitle: "Blockade has been enabled.",
        blockadeFinishedMsg1: "Between",
        blockadeFinishedMsg2: "operations in",
        blockadeFinishedMsg3: "will not be possible.",

        errors: {
          requiredField: "Required field",
          blockadeOfErr: "At least one blockade option has to be selected.",
          dateToAndFromRequired: "From and To are required fields for time-restricted blockade.",
          timeRestrictedBlockadeErr: "The end date of the blockade (to) must be greater than or equal to the start date (from)!"
        }
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
