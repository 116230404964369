<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div ng-show="!addNewExpressLoan.steps.step3">
      <div class="row">
        <div class="col-md-12" style="z-index:1;">
          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
            <h2 ng-switch-default class="title-style" translate="addNewExpressLoan.header">Change overdraft limit</h2>
            <h2 ng-switch-when="sl" class="title-style" translate="addNewExpressLoan.header_SLO">Change overdraft limit</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info" style="background: white; color: black; border: 1px solid lightgray" role="alert">
            <p ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">

              <span ng-switch-default translate="addNewExpressLoan.note1"></span>
              <span ng-switch-when="sl" translate="addNewExpressLoan.note1_SLO"></span>

            </p>

            <p ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">

              <span ng-switch-default translate="addNewExpressLoan.note2"></span>
              <span ng-switch-when="sl" translate="addNewExpressLoan.note2_SLO"></span>
              <a href="https://www.addiko.si/ceniki" target="blank" class="underline" translate="addNewExpressLoan.noteLink1"></a>
              <span translate="addNewExpressLoan.noteAnd"></span>
              <a href="https://www.addiko.si/obrestne-mere" target="blank" class="underline" translate="addNewExpressLoan.noteLink2"></a>

            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <div class="p-20 bg-white clearfix">

      <!-- ================= -->
      <!-- ====  STEP 1 ==== -->
      <!-- ================= -->
      <div ng-show="addNewExpressLoan.steps.step1" >
      <ng-form name="addNewExpressLoan.newExpressLoanForm">
          <div class="row">
            <div class="col-md-6">
              <label class="domp-form-label" translate="addNewExpressLoan.account">Account</label>
              <!-- START Dropdown account -->
              <div class="accounts-dropdown">
                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="addNewExpressLoan.showAccountToDropdown = !addNewExpressLoan.showAccountToDropdown" ng-style="addNewExpressLoan.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + addNewExpressLoan.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                                            {{addNewExpressLoan.selectedAccount.friendlyName}}</b>
                                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                                            <i class="zmdi"
                                               ng-class="{'zmdi-star favorite-account': account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                                        </span>
                                    </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{addNewExpressLoan.selectedAccount.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{addNewExpressLoan.selectedAccount.availableBalance | number:2}} {{addNewExpressLoan.selectedAccount.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  <span class="caret-icon">
                                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                                    </span>
                </div>
                <div class="from-accounts-list m-l-15" style="z-index:12343" ng-show="addNewExpressLoan.showAccountToDropdown" scrollable>
                  <div class="accounts-list-item p-0" data-ng-repeat="account in addNewExpressLoan.accountList" data-ng-click="addNewExpressLoan.selectAccount(account); addNewExpressLoan.showAccountToDropdown = !addNewExpressLoan.showAccountToDropdown">
                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{account.friendlyName}}</b>
                                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                            </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{account.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{account.availableBalance | number:2}} {{account.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END Dropdown account -->

            </div>
            <div class="col-xs-6">
              <!-- START Amount -->
              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="addNewExpressLoan.amount">Amount</label>
                  <div class="input-group">
                    <input id="amount" name="amount" type="text" class="form-control input-field t-a-r" amount-input-mask
                           placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                            ng-model="addNewExpressLoan.tempObj.amount" ng-class="{'domp-error': addNewExpressLoan.newExpressLoanForm.amount.$invalid && addNewExpressLoan.formSubmitted}" maxlength="12" autocomplete="off" required>
                    <span class="input-group-btn">
                        <button type="button"
                                class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                                style="border:1px solid #062a42;">
                            {{addNewExpressLoan.selectedAccount.currencySymbol}}
                        </button>
                    </span>
                  </div>
                  <label class="c-red" ng-show="addNewExpressLoan.newExpressLoanForm.amount.$error.required && addNewExpressLoan.formSubmitted" translate="cashWithdrawalTranslate.amountRequired">
                  </label>
                </div>
              </div>
              <!-- END Amount-->
                <div class="col-md-12 col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput" translate="addNewExpressLoan.approvalDate">Date:</label>
                  <div class="input-group ">
                    <input type="text" class="form-control input-field" name="dateInput"
                           ng-class="{'domp-error': addNewExpressLoan.NewExpressLoanForm.dateInput.$invalid && addNewExpressLoan.formSubmitted}"
                           id="dateInput" uib-datepicker-popup="shortDate" data-ng-model="addNewExpressLoan.tempObj.dateInput"
                           show-weeks="false" is-open="addNewExpressLoan.datePickerOpened" datepicker-options="addNewExpressLoan.dateOptions"
                           show-button-bar="false"
                           date-disabled="addNewExpressLoan.disabled(date, mode)"
                           readonly required>
                    <span class="input-group-btn">
                      <button ng-readonly="addNewExpressLoan.disableAllFields" data-ng-click="addNewExpressLoan.openDatePicker()" type="button"
                              class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>

                <div class="col-md-6 col-xs-12 p-0">
                  <div class="form-group">
                    <label class="domp-form-label" translate="addNewExpressLoan.cardType"></label>
                    <input name="monthsOfCredit" class="form-control input-field p-l-15 p-r-10"
                           ng-class="{'domp-error': addNewExpressLoan.newExpressLoanForm.monthsOfCredit.$invalid && addNewExpressLoan.formSubmitted}"
                           ng-pattern-restrict="{{addNewExpressLoan.regexPatterns.onlyNumbersWithoutFirstZero}}"
                           ng-model="addNewExpressLoan.tempObj.monthsOfCredit"
                           maxlength="3"
                           required/>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12 p-r-0">
                  <div class="form-group">
                    <label class="domp-form-label" translate="addNewExpressLoan.paymentDay"></label>
                    <select name="paymentDay" class="form-control input-field p-l-15 p-r-10" ng-options="option.description for option in addNewExpressLoan.paymentDays " ng-model="addNewExpressLoan.tempObj.paymentDay">
                    </select>
                  </div>
                </div>

              </div>

              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                  <input type="checkbox" ng-model="addNewExpressLoan.iAmAware">
                  <i class="input-helper"></i>
                  {{'cashWithdrawalTranslate.info' | translate}}
                </label>
              </div>
            </div>

          <div class="row p-b-20 p-t-20">
            <div class="col-md-12">
              <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left" data-ui-sref="loansOverview">
                <span translate="addNewExpressLoan.cancel">Cancel</span>
              </button>
              <button class="btn btn-default  pull-right "
                      ng-disabled="addNewExpressLoan.nextButtonPressed || (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !addNewExpressLoan.iAmAware)"
                      ng-click="addNewExpressLoan.validateCreditPossibilityOrder()">
                <span translate="orders.next">Next</span>
              </button>
          </div>
        </div>
      </ng-form>
    </div>

      <!-- ================= -->
      <!-- ====  STEP 2 ==== -->
      <!-- ================= -->
      <div class="clearfix bg-white" ng-if="addNewExpressLoan.steps.step2">
        <div class="intp-form-container bottom-border">
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.account"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.creditTransferAccount | formatIban}}
              </label>
            </div>
          </div>
       <!-- <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.paymentAccount"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.paymentAccount}}
              </label>
            </div>
          </div>-->
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.amount"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.amount | currency:""}} {{addNewExpressLoan.selectedAccount.currencySymbol}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.cardType"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.period}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.paymentDay"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.orderTearDate.id }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="addNewExpressLoan.approvalDate"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{addNewExpressLoan.responseModel.approvalDate | date: 'shortDate' : '+0200'}}
              </label>
            </div>
          </div>
        </div>
        <div class="intp-form-container">
          <div class="row">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary"
                      translate="orders.back"
                      ng-click="addNewExpressLoan.back()">
              </button>
            </div>
            <div class="col-xs-6 t-a-r">
              <button class="btn btn-default"
                      translate="addNewExpressLoan.confirm"
                      ng-disabled="addNewExpressLoan.confirmButtonPressed"
                      ng-click="addNewExpressLoan.confirmCreditPossibilityOrder();">
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- ================= -->
      <!-- ====  STEP 3 ==== -->
      <!-- ================= -->
      <div ng-if="addNewExpressLoan.steps.step3">
        <div class="payment-successful-wrapper">
          <div class="row">
            <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
              <div class="currency-check-icon msg-pad">
                <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
              </div>
            </div>
            <div class="col-xs-10 m-t-30 m-b-30">
              <h2 class="c-white f-28" translate="orders.successOrder"></h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <a href="" data-ng-click="addNewExpressLoan.addNewExpressLoan()">
              <span class="money-exchange-icon"><i class="icon icon-einvoice zmdi-hc-2x"></i></span>
              <span class="f-18 money-exchange-text" translate="addNewExpressLoan.addNewExpressLoan"></span>
            </a>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
