<div class="col-md-12">
  <h3 translate="accounts.connectedDebitCards"></h3>
</div>
<div class="col-md-12" ng-repeat="card in accounts.selectedAccount.cardList">
  <div class="debit-card-group clearfix"
       ng-class="{'debit-card-group-blocked': (card.cardStatus !== 'Active')}">
    <div class="col-xs-12 col-md-3">
      <div class="connected-debit-card-widget"></div>
    </div>
    <div class="col-xs-12" ng-class="{'col-md-6': (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'), 'col-md-3': (APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'bh')}">
      <h3 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'bh'">
        <span ng-if="card.hasOwnProperty('friendlyName')">{{card.friendlyName}}</span>
        <span ng-if="!card.hasOwnProperty('friendlyName')">{{accounts.selectedAccount.friendlyName}}</span>
      </h3>
      <h3 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'"><span>{{accounts.selectedAccount.ownerName}}</span></h3>

      <h5 ng-show="card.cardNumber && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"><b translate="accounts.creditCardNumber"></b>: {{accounts.formatCardNumber(card.cardNumber)}}</h5>
      <h5 ng-show="card.cardNumber && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">{{accounts.formatCardNumber(card.cardNumber)}}</h5>
      <!--<h5 ng-show="card.cardNumber">{{accounts.formatIban(card.cardNumber)}}</h5>-->
      <!--<h5 ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">{{accounts.selectedAccount.ownerName}}</h5>-->
      <h5 ng-show="card.cardType && accountsOverview.countryCode == 'hr'">{{card.cardType}}</h5>
      <h5 ng-show="card.cardTypeDescription && accountsOverview.countryCode != 'hr'">{{card.cardTypeDescription}}</h5>
      <h5 ng-show="card.validToDate" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
         <span  ng-switch-default><span translate="accounts.expDate">Expiry date: </span>{{card.validToDate | date: "dd.MM.yyyy"}}</span>
         <span ng-switch-when="sl" ><span translate="accounts.expDate_SLO">Expiry date: </span>{{card.validToDate | date: "MM.yyyy"}}</span>
      </h5>
      <h5 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'bh'" ng-show="card.cardStatus"><b>Status:</b> {{'accounts.'+card.cardStatus | translate }}</h5>
      <h5 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'" ng-show="card.cardStatus"><b>Status:</b> {{'accounts.cardStatus.'+card.cardStatus | translate }}</h5>
      <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <h5>
          <b translate="accounts.accApprovedLimit"></b>:
          {{card.limitAmount | currency: ""}} {{card.currency}}
        </h5>
        <h5>
          <b translate="accounts.limitForATM"></b>:
          {{card.limitAmountATM | currency: ""}}
        </h5>
      </span>
    </div>
    <div class="col-xs-12 col-md-3" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <h3 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'bh'" ng-show="accounts.selectedAccount.ownerName">{{accounts.selectedAccount.ownerName}}</h3>
    </div>
    <!--<p>Account: <a href="" ng-click="">Current account</a></p></div>-->
    <!--<div class="col-xs-1"></div>-->
    <div class="col-xs-12 col-md-3">

      <button class="btn btn-info btn-block"
              ng-hide="accounts.countryCode == 'hr' || accounts.countryCode == 'sl' || accounts.countryCode == 'sr'"
              ng-disabled="card.selected"
              ng-class="{'btn-card-active': (card.cardStatus === 'Active'),
                          'btn-card-blocked': (card.cardStatus !== 'Active')}"
              ng-click="accounts.updateCardStatus(accounts.selectedAccount.accountId, card)">
        <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentUnblockRequest'" class="f-12" translate="accounts.unblockCardRequest">Unblock request sent</span>
        <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentBlockRequest'" class="f-12" translate="accounts.blockCardRequest">Block request sent</span>
        <span ng-show="!card.temporaryStatus &&  card.cardStatus === 'Active'" translate="accounts.blockCard"></span>
        <span ng-show="!card.temporaryStatus && card.cardStatus != 'Active'" translate="accounts.unblockCard"></span>
      </button>

      <button class="btn btn-info btn-block"
              ng-hide="accounts.countryCode != 'sr'"
              ng-disabled="card.selected"
              ng-class="{'btn-card-active': (card.cardStatus === 'Active'),
                          'btn-card-blocked': (card.cardStatus !== 'Active')}"
              ng-click="!(!card.temporaryStatus && card.cardStatus === 'Inactive') ? accounts.updateCardStatus(accounts.selectedAccount.accountId, card) : accounts.openCardActivationModal(card)">
        <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentUnblockRequest'" class="f-12" translate="accounts.unblockCardRequest">Unblock request sent</span>
        <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentBlockRequest'" class="f-12" translate="accounts.blockCardRequest">Block request sent</span>
        <span ng-show="card.temporaryStatus && card.temporaryStatus == 'SentActivationRequest'" class="f-12" translate="accounts.activationCardRequest">Activation request sent</span>
        <span ng-show="!card.temporaryStatus && card.cardStatus === 'Inactive'" translate="accounts.activateCard"></span>
        <span ng-show="!card.temporaryStatus && card.cardStatus === 'Active'" translate="accounts.blockCard"></span>
        <span ng-show="!card.temporaryStatus && card.cardStatus != 'Active' && card.cardStatus != 'Inactive'" translate="accounts.unblockCard"></span>
      </button>

      <button class="btn btn-info btn-block"
              ng-show="accounts.countryCode == 'hr'"
              ng-disabled="card.selected"
              ng-class="{'btn-card-active': (card.cardStatus === 'Active' && !card.temporaryStatus),
                         'btn-card-blocked': (card.cardStatus !== 'Active' || card.temporaryStatus)}"
              ng-click="accounts.updateCardStatusCro()">
        <span ng-show="card.cardStatus === 'Active'" translate="accounts.blockCard"></span>
        <span ng-hide="card.cardStatus === 'Active'" translate="accounts.unblockCard"></span>
      </button>
      <button class="btn btn-info btn-block"
              ng-if="accounts.countryCode == 'sl' && (card.cardStatus === 'Active' || card.cardStatus === 'Blocked')"
              ng-disabled="card.selected"
              ng-class="{'btn-card-active': (card.cardStatus === 'Active' && !card.temporaryStatus),
                         'btn-card-blocked': (card.cardStatus === 'Blocked' || card.temporaryStatus)}"
              ng-click="accounts.updateCardStatusCro()">
        <span ng-show="card.cardStatus === 'Active'" translate="accounts.blockCard"></span>
        <span ng-show="card.cardStatus === 'Blocked'" translate="accounts.unblockCard"></span>
      </button>

      <button class="btn btn-info btn-block"
              ng-hide="!accounts.links.transactions || accounts.countryCode == 'hr'"
              ng-class="{'btn-card-active': (card.cardStatus === 'Active'),
                         'btn-card-blocked': (card.cardStatus !== 'Active')}"
              ng-click="accounts.openCardTransactions(accounts.selectedAccount.accountId, card, $event)">
        <span ng-show="!card.collapsed" translate="accounts.accTransactions">Transactions</span>
        <span ng-show="card.collapsed" translate="accounts.accHideTransactions">Hide transactions</span>
      </button>

      <button class="btn btn-info btn-block" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                       ng-show="accounts.links.cardReservation"
                       ng-class="{'btn-card-active': (card.cardStatus === 'Active'),
                         'btn-card-blocked': (card.cardStatus !== 'Active')}"
                       ng-click="accounts.openCardReservations(accounts.selectedAccount.accountId, card, $event)">
      <span ng-show="!card.collapsedRes" translate="accounts.accReservations">Reservations</span>
      <span ng-show="card.collapsedRes" translate="accounts.accHideReservations">Hide reservations</span>
    </button>
      <button class="btn btn-info btn-block btn-card-active"
              ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && accounts.selectedAccount.accountType.group == 'CardAccountTypes'
              && accounts.selectedAccount.isOwn && card.cardStatus === 'Active'"
              data-ui-sref="masterCardOrder({accountId: accounts.selectedAccount.accountId, cardId: card.cardId })">
        <span translate="masterCardOrder.orderLimit">Order limit</span>
      </button>
      <!-- SHOW FOR NOW - PIN OVER SMS - START -->
      <button class="btn btn-info btn-block btn-card-active"
              ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && accounts.selectedAccount.accountType.group == 'CardAccountTypes'
              && accounts.selectedAccount.isOwn && card.cardStatus === 'Active'"
              data-ui-sref="reorderPinMasterCard({accountId: accounts.selectedAccount.accountId, cardId: card.cardId })">
        <span translate="masterCardOrder.reorderPIN">Reorder PIN</span>
      </button>
      <!-- SHOW FOR NOW - PIN OVER SMS - START -->
    </div>
    <div class="col-xs-12 col-md-3 m-t-10" ng-hide="accounts.selectedAccount.ownerName"></div>
    <!--<div class="col-xs-12 col-md-3 m-t-10">-->
      <!--<button class="btn btn-info btn-block"-->
              <!--ng-hide="!accounts.links.transactions || accounts.countryCode == 'hr'"-->
              <!--ng-class="{'btn-card-active': (card.cardStatus === 'Active'),-->
                         <!--'btn-card-blocked': (card.cardStatus !== 'Active')}"-->
              <!--ng-click="accounts.openCardTransactions(accounts.selectedAccount.accountId, card, $event)">-->
        <!--<span ng-show="!card.collapsed" translate="accounts.accTransactions">Transactions</span>-->
        <!--<span ng-show="card.collapsed" translate="accounts.accHideTransactions">Hide transactions</span>-->
      <!--</button>-->
      <!--<button class="btn btn-info btn-block" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"-->
              <!--ng-show="accounts.links.cardReservation"-->
              <!--ng-class="{'btn-card-active': (card.cardStatus === 'Active'),-->
                         <!--'btn-card-blocked': (card.cardStatus !== 'Active')}"-->
              <!--ng-click="accounts.openCardReservations(accounts.selectedAccount.accountId, card, $event)">-->
        <!--<span ng-show="!card.collapsedRes" translate="accounts.accReservations">Reservations</span>-->
        <!--<span ng-show="card.collapsedRes" translate="accounts.accHideReservations">Hide reservations</span>-->
      <!--</button>-->
    <!--</div>-->
  </div>
  <div ng-show="card.collapsed">
    <div ng-include="'accounts/connected-cards-transactions.tpl.html'"></div>
  </div>
  <div ng-show="card.collapsedRes">
    <div ng-include="'accounts/connected-cards-reservations.tpl.html'"></div>
  </div>
</div>
