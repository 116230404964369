<section id="main" data-ng-controller="MaterialAdminCtrl as mactrl">
  <div class="container" ng-init="cddMenuSelected=1">
    <div class="row">
      <!--LEFT COLUMN-->

      <!--CENTER COLUMN-->
      <div class="col-md-12 frontPage">


        <div class=" slide" data-ride="carousel">
          <!--  <div id="timelineViewSelector" ng-init="timelineViewSelectorSelected=1">
  <div class="timeline-view-select-label" ng-click="timelineViewSelectorSelected=1" ng-class="{selected: timelineViewSelectorSelected == 1}">Transactions</div>
  <div class="timeline-view-select-label" ng-click="timelineViewSelectorSelected=2" ng-class="{selected: timelineViewSelectorSelected == 2}">Pdf statements</div>
</div>-->
          <!-- Wrapper for slides -->
          <div class="carousel-inner" role="listbox">

            <div class="item active">
              <div class="slide-padding">
                <div class="col-md-12">
                  <h2 class="boldBlueHeader">New mobile banking app</h2>
                  <p class="blueFont">Secure, quick payments, instant products and more</p>
                  <button type="submit" style="width:25%;font-size:0.9em;" class="downloadTodayBtn btn btn-default app-btn-blue">Download today</button>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="slide-padding">
                <div class="col-md-12">
                  <h1 class="boldBlueHeader">New mobile banking app</h1>
                  <h4>Secure, quick payments, instant products and more</h4>
                  <br/>
                  <button type="submit" style="width:20%;" class="downloadToday2Btn btn btn-default app-btn-blue">Download today</button>
                </div>
              </div>
            </div>
          </div>


          <!--  <div id="periodViewSelector" ng-init="timelineViewSelectorSelected=1">
  <div class="period-view-select-div left" ng-click="timelineViewSelectorSelected=1" ng-class="{selected: timelineViewSelectorSelected == 1}">Period:</div>
  <div class="period-view-select-div right" ng-click="timelineViewSelectorSelected=2" ng-class="{selected: timelineViewSelectorSelected == 2}">
    <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        {{periodSelectorLabel}}
        <span class="caret" style="color:#FF4D5A;"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li>
          <a ng-click="periodSelectorLabel='Yesterday'">Yesterday</a>
        </li>
        <li>
          <a ng-click="periodSelectorLabel='This Month'">This Month</a>
        </li>
        <li>
          <a ng-click="periodSelectorLabel='This year'">This year</a>
        </li>
      </ul>
    </div>
  </div>
</div>-->

          <!-- Left and right controls -->
          <a ng-non-bindable class="left carousel-control" href="personalFirstRow" role="button" data-slide="prev" style="background-image: none;width:10%;">
            <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a ng-non-bindable class="right carousel-control" href="personalFirstRow" role="button" data-slide="next" style="background-image: none;width:10%;">
            <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!--SECOND ROW-->
        <div class="personalHome">
          <div class="personalSecondRow">
            <h2 class="boldBlueHeader">Hypo rewards</h2>
            <!--  <p class="lowerText">The more you use the app the more points you will earn which will let you claim some great rewards.</p>-->
          </div>
        </div>
        <!--THIRD ROW-->
        <div class="personalHome">
          <div class="personalThirdRow">
            <h2 class="boldBlueHeader">Housing loans</h2>
            <br/>
            <p class="lowerText2">We have some great loans that could help you purchase the property of your dreams.</p>
            <br/> <br/> <br/> <br/>
            <p class="lowerText2">*Low rates</p><br/>
            <p class="lowerText2">*Quick approval</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer id="footer" data-ng-include="'template/footer.html'"></footer>
