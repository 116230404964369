<div ng-controller="DomesticPaymentBhCtrl as domesticPaymentBh">
  <div class="domp-header-wrapper col-sm-12 p-0" ng-show="domesticPaymentBh.currentStep!=3">
    <div class="domp-header-row">
      <h2 class="f-28" translate="domesticPayment.header" ng-if="!domesticPaymentBh.tempDompObj.isInternal">Domestic Payment</h2>
      <h2 class="f-28" translate="internalTransfer.transferToAccountLink" ng-if="domesticPaymentBh.tempDompObj.isInternal">Transfer to my account</h2>
    </div>
  </div>
  <div class="domp-body-wrapper">
    <div ng-if="domesticPaymentBh.currentStep === 1" class="domp-step-1">
      <form novalidate name="domesticPaymentBh.domesticPaymentForm">
        <div class="domp-select-section col-sm-12 p-0" ng-if="!domesticPaymentBh.tempDompObj.isInternal">
          <!--SELECT TEMPLATE CUSTOM -->
          <div class="domp-template-filter-container">
            <div class="domp-select-template" ng-click="domesticPaymentBh.toggleTemplateFilterSelection()" ng-show="domesticPaymentBh.templateName==''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>

            <div class="domp-selected-template" ng-show="domesticPaymentBh.templateName!=''">
              <div class="select-template label-template-selected p-l-10 p-t-5" ng-show="domesticPaymentBh.templateName!=''">
                <img ng-if="domesticPaymentBh.selectedTemplate.imageId" ng-src="{{domesticPaymentBh.selectedTemplate.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img-40"/>
                <div ng-if="!domesticPaymentBh.selectedTemplate.imageId" class="invoice-icon tpl-rounded-img-40 inline-block-display" >ico</div>
                <label class="p-l-10 selected-template-label top-2"><b>{{domesticPaymentBh.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5" ng-click="domesticPaymentBh.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection" ng-class="{'opened': domesticPaymentBh.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width: 50%;">
                  <input id="searchTemplatesInput" type="text" class="search-input" ng-model="domesticPaymentBh.tmpSearchText.name" ng-change="domesticPaymentBh.searchTemplate(domesticPaymentBh.tmpSearchText.name)" placeholder="{{'dashboard.searchTemplatesPlaceholder' | translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item" data-ng-repeat="template in domesticPaymentBh.templateList.result | filter:domesticPaymentBh.tmpSearchText" ng-click="domesticPaymentBh.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
                          <!--                            <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                          <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                          <div ng-if="!template.imageId" class="invoice-icon tpl-rounded-img">ico</div>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15" style="padding-left: 50px !important;">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
        </div>


        <div class="col-sm-12 domp-from-section">
          <!-- CUSTOM ACCOUNTS DROPDOWN -->
          <div class="col-sm-6 domp-acc-dropdown">
            <label class="domp-form-label" translate="payments.fromAccount"></label>
            <div class="accounts-dropdown">
              <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="domesticPaymentBh.toggleFromAccount();"  ng-style="domesticPaymentBh.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + domesticPaymentBh.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}" >
                <h4 class="no-wrap-text bold-font m-b-0">
                  <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{domesticPaymentBh.tempDompObj.fromAccountSelect.friendlyName}}</b>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':domesticPaymentBh.tempDompObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!domesticPaymentBh.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                </h4>
                <p class="m-b-0 m-t-0 iban-style">{{domesticPaymentBh.tempDompObj.fromAccountSelect.iban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{domesticPaymentBh.tempDompObj.fromAccountSelect.availableBalance | number:2}} {{domesticPaymentBh.tempDompObj.fromAccountSelect.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                <span class="caret-icon" ng-hide="domesticPaymentBh.isRetrieval"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
              </div>
              <div class="from-accounts-list" ng-show="domesticPaymentBh.fromAccountDropdown" scrollable>
                <div class="accounts-list-item p-0" data-ng-repeat="item in domesticPaymentBh.paymentTypeGroupDomestic.result[0].debitAccountList" data-ng-click="domesticPaymentBh.setAccountFrom(item);">
                  <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{item.availableBalance | number:2}} {{item.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row m-r-0">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput" translate="domesticPayment.amountInputLabel">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask maxlength="12" ng-model="domesticPaymentBh.tempDompObj.amountInput" required ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.amountInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}" ng-disabled="domesticPaymentBh.isRetrieval">
                    <span class="input-group-btn">
                                              <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">{{domesticPaymentBh.defaultCurrency}}</button>
                                         </span>
                  </div>
                  <span class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.amountInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                </div>
              </div>
            </div>

            <div class="row m-r-0">
              <div class="col-sm-8">
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                  <div class="input-group ">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="shortDate"
                           data-ng-model="domesticPaymentBh.tempDompObj.dateInput"
                           show-weeks="false"
                           is-open="domesticPaymentBh.datePickerOpened"
                           datepicker-options="domesticPaymentBh.dateOptions"
                           readonly
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           >
                    <span class="input-group-btn">
                            <button data-ng-click="domesticPaymentBh.openDatePicker()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-sm-12 receiver-section">
          <div class="row m-r-0">
            <!-- Start Account number - input -->
            <div class="col-sm-6 col-xs-12 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabelSRB">
                  Account number
                </label>
                <input type="text" class="form-control input-field" name="toAccountInput"
                       ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.onlyNumbers}}" id="toAccountInput"
                       ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}"
                       ng-model="domesticPaymentBh.tempDompObj.toAccountInput"
                       ng-blur="domesticPaymentBh.checkIsRevenueAccount(); domesticPaymentBh.checkToAccountInputLength();"
                       ng-change="domesticPaymentBh.checkToAccountInputLength();"
                       maxlength="16"
                       required ng-disabled="domesticPaymentBh.isRetrieval || !domesticPaymentBh.revenueAccountsLoaded">
                <div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                     translate="domesticPayment.receiverAccountError">
                </div>
                <!--<div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.toAccountInput.$error.minlength && domesticPaymentBh.tempDompObj.formSubmitted"-->
                     <!--translate="domesticPayment.receiverAccountMinLengthError">-->
                <!--</div>-->
                <div ng-if="domesticPaymentBh.tempDompObj.isInternal">
                  <div class="c-red"
                       ng-show="domesticPaymentBh.domesticPaymentForm.toAccountInput.$error.properLength && domesticPaymentBh.tempDompObj.formSubmitted"
                       translate="domesticPayment.receiverAccountLengthError">
                  </div>
                </div>
                <div ng-if="!domesticPaymentBh.tempDompObj.isInternal">
                  <div class="c-red"
                       ng-show="domesticPaymentBh.domesticPaymentForm.toAccountInput.$error.properLength && domesticPaymentBh.tempDompObj.formSubmitted"
                       translate="domesticPayment.domesticReceiverAccountLengthError">
                  </div>
                </div>
              </div>
            </div>
            <!-- END Account number - input -->
            <!-- /////////// SELECT CUSTOM ///////////// -->
            <div click-outside="domesticPaymentBh.searchFilterExpanded = false;" outside-if-not="expandPayeeSearchFilterSRB" class="col-sm-6 col-xs-12 left-section"
            ng-if="!domesticPaymentBh.tempDompObj.isInternal">
              <div  class="form-group" ng-hide="domesticPaymentBh.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentBh.domesticPaymentForm.payeeSearchFilter.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}">
                  <div  class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i  class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.allowedCharsDPS}}"
                           class="search-input" ng-model="domesticPaymentBh.tempDompObj.receiversNameInput" ng-change="domesticPaymentBh.searchPayee()"
                           required maxlength="35" ng-maxlength="35" autocomplete="off">
                  </div>
                  <div id="expandPayeeSearchFilterSRB" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right" ng-click="domesticPaymentBh.toggleFilterSelection()">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentBh.tempDompObj.receiversNameInput!=''" ng-click="domesticPaymentBh.removeSearch()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right" ng-show="domesticPaymentBh.tempDompObj.receiversNameInput==''"></i>
                  </div>
                  <div class="domp-filter-selection search-row" ng-class="{'opened': domesticPaymentBh.searchFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper" ng-repeat="payee in domesticPaymentBh.payeeList | filter:domesticPaymentBh.searchPayeeBy" ng-click="domesticPaymentBh.selectPayee(payee);">
                      <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <i class="zmdi zmdi-account-circle"></i>
                      </div>
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                        <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentBh.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentBh.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></span>
              </div>
              <div class="form-group" ng-hide="!domesticPaymentBh.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}" name="payeeSearchFilter" id="payeeSearchFilter" ng-model="domesticPaymentBh.tempDompObj.receiversNameInput" ng-blur="domesticPaymentBh.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentBh.tempDompObj.receiversAddressInput)" maxlength="35" ng-maxlength="35" required ng-disabled="domesticPaymentBh.isRetrieval">
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentBh.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentBh.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></div>
              </div>
            </div>
            <!--//////////////SELECT CUSTOM END/////////////////////// -->
            <div class="col-sm-6 col-xs-12 left-section" ng-if="domesticPaymentBh.tempDompObj.isInternal">
              <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput"
                     translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
              <div class="p-0">
                <input type="text" class="form-control input-field"
                       ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.purposeOfPaymentValidation}}"
                       name="purposeOfPaymentInput" id="purposeOfPaymentInput" maxlength="35"
                       ng-model="domesticPaymentBh.tempDompObj.purposeOfPaymentInput" required
                       ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}"
                       ng-disabled="domesticPaymentBh.isRetrieval">
                <span class="c-red"
                      ng-show="domesticPaymentBh.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                      translate="internalTransfer.paymentPurposeInputError"></span>
              </div>
            </div>
          </div>

          <div class="row m-r-0" ng-if="!domesticPaymentBh.tempDompObj.isInternal">
            <div class="col-lg-6 col-md-6 col-sm-6 left-section"> </div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="receiversAddressInput" translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label>
                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}" name="receiversAddressInput" id="receiversAddressInput" ng-model="domesticPaymentBh.tempDompObj.receiversAddressInput" maxlength="35" ng-maxlength="35" required ng-disabled="domesticPaymentBh.isRetrieval">
                <span class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.receiversAddressInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted" translate="domesticPayment.receiverAddressError"></span>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section" ng-if="!domesticPaymentBh.tempDompObj.isInternal">
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
              <div class="form-group">
                <div class="col-lg-12 col-md-4 no-padding-on-phone p-r-0 p-l-0">
                  <label class="domp-form-label" for="purposeCodeSelect" translate="domesticPayment.purposeCodeSelectLabel">Purpose code</label>
                  <div class="p-l-0">
                    <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect" ng-options="option.optionValue for option in domesticPaymentBh.purposeCodesList.result" ng-model="domesticPaymentBh.tempDompObj.purposeCodeSelect" ng-change="domesticPaymentBh.changePurpose(domesticPaymentBh.tempDompObj.purposeCodeSelect)" ng-disabled="domesticPaymentBh.isRetrieval"></select>
                  </div>
                </div>
                <div class="col-lg-12 col-md-8 no-padding-on-phone p-r-0 p-l-0 p-t-10">
                  <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
                  <div class="p-0">
                    <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.purposeOfPaymentValidation}}" name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="105" maxlength="105" ng-model="domesticPaymentBh.tempDompObj.purposeOfPaymentInput" required ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}" ng-blur="domesticPaymentBh.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentBh.tempDompObj.purposeOfPaymentInput)" ng-disabled="domesticPaymentBh.isRetrieval">
                    <span class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted" translate="internalTransfer.paymentPurposeInputError"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- START Reference number - input -->
            <div class="col-lg-6 col-md-6 col-sm-6 left-section">
              <div class="col-xs-12 p-l-0">
                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabel">Reference number:</label>
              </div>
              <div class="col-sm-12 col-xs-12 p-l-0 p-r-0">
                <div class="form-group m-b-0">
                  <input type="text" id="referenceNumberInput" class="form-control input-field" name="referenceNumberInput"
                         ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}"
                         ng-maxlength="{{domesticPaymentBh.referenceMaxLength}}" ng-pattern-restrict="{{domesticPaymentBh.referencePattern}}"
                         ng-model="domesticPaymentBh.tempDompObj.referenceNumberInput" ng-blur="domesticPaymentBh.completeReferenceNumber()"
                         ng-change="domesticPaymentBh.validateReferenceNumber(); domesticPaymentBh.checkReferenceNumber();"/>
                  <div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.referenceNumberInput.$error.referenceNumberRequired && domesticPaymentBh.tempDompObj.formSubmitted"
                       translate="domesticPayment.referenceNumberRequired">
                  </div>
                  <div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.referenceNumberInput.$error.maxlength && domesticPaymentBh.tempDompObj.formSubmitted"
                       translate="domesticPayment.referenceNumberMaxLength">
                  </div>
                  <div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.referenceNumberInput.$error.validReferenceNumber && domesticPaymentBh.tempDompObj.formSubmitted"
                       translate="domesticPayment.referenceNumberError">
                  </div>
                </div>
              </div>
            </div>
            <!-- END Reference number input -->
          </div>
        </div>

        <!-- Payee field in case of Internal Transfer -->
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section" ng-if="domesticPaymentBh.tempDompObj.isInternal">
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
              <label class="domp-form-label" for="purposeCodeSelect" translate="domesticPayment.toPayee">To payee</label>
              <input type="text" class="form-control input-field" name="receiverNameInput"
                     maxlength="35"
                     ng-model="domesticPaymentBh.tempDompObj.receiversNameInput">
            </div>
          </div>
        </div>
        <!-- End Payee field in case of Internal Transfer -->

        <!-- Internal transfer BiH checkbox -->
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section" ng-if="!domesticPaymentBh.tempDompObj.selectedRevenueAccount">
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
              <label class="checkbox checkbox-inline">
                <input type="checkbox" data-ng-model="domesticPaymentBh.tempDompObj.isInternal" data-ng-click="domesticPaymentBh.toggleInternalPayment();">
                <span class="input-helper" translate="internalTransfer.transferToAccountLink">Internal transfer</span>
              </label>
            </div>
          </div>
        </div>
        <!-- End Internal transfer BiH checkbox -->

        <!-- PUBLIC REVENUE SECTION -->
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section revenue-section" ng-if="domesticPaymentBh.tempDompObj.selectedRevenueAccount && !domesticPayment.tempDompObj.isInternal">
          <div class="row m-r-0">
            <div class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label" translate="domesticPayment.taxPayerNumber"></label>
                <input type="text" class="form-control input-field" name="taxCollectorInput" data-ng-model="domesticPaymentBh.tempDompObj.taxPayerId"
                       ng-class="{'domp-error': domesticPaymentBh.domesticPaymentForm.taxCollectorInput.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}"
                       maxlength="13" ng-maxlength="13" ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.onlyNumbers}}" required>
                <div class="c-red" ng-show="domesticPaymentBh.domesticPaymentForm.taxCollectorInput.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                     translate="domesticPayment.taxPayerError">
                </div>
              </div>
            </div>

            <div class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label" translate="domesticPayment.paymentType"></label>
                <select class="form-control input-field" data-ng-model="domesticPaymentBh.tempDompObj.paymentType">
                  <option ng-repeat="paymentType in domesticPaymentBh.tempDompObj.paymentTypes">{{paymentType}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row m-r-0">
            <div click-outside="domesticPaymentBh.revenueCodeFilterExpanded = false;" outside-if-not="expandRevenueCodeFilter" class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label" translate="domesticPayment.revenueCode"></label>
                <!-- REVENUE TYPE AUTOCOMPLETE -->
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentBh.domesticPaymentForm.revenueCodeFilter.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="revenueCodeFilter" class="search-input"
                           ng-model="domesticPaymentBh.tempDompObj.revenueCode"
                           ng-change="domesticPaymentBh.openRevenueCodes()" ng-maxlength="6" maxlength="6"
                           ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.onlyNumbers}}"
                           autocomplete="off" required>
                  </div>
                  <div id="expandRevenueCodeFilter" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                       ng-click="domesticPaymentBh.toggleRevenueCodes();">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentBh.tempDompObj.revenueCode!=''"
                       ng-click="domesticPaymentBh.closeRevenueCodes()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
                       ng-show="domesticPaymentBh.tempDompObj.revenueCode==''"></i>
                  </div>
                  <div class="domp-filter-selection search-row"
                       ng-class="{'opened': domesticPaymentBh.revenueCodeFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper"
                         ng-repeat="revenueCode in domesticPaymentBh.tempDompObj.revenueCodes | filter: domesticPaymentBh.filterRevenueCodes"
                         ng-click="domesticPaymentBh.selectRevenueCode(revenueCode)">
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{revenueCode.description}}</p>
                        <p class="domp-list-number m-0">{{revenueCode.code}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="domesticPaymentBh.domesticPaymentForm.revenueCodeFilter.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                      translate="domesticPayment.revenueCodeError">
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="domesticPaymentBh.domesticPaymentForm.revenueCodeFilter.$error.revenueCodeExistance && domesticPaymentBh.tempDompObj.formSubmitted"
                      translate="domesticPayment.revenueCodeExistanceError">
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="domesticPaymentBh.domesticPaymentForm.revenueCodeFilter.$error.revenueCodeType && domesticPaymentBh.tempDompObj.formSubmitted"
                      translate="domesticPayment.revenueCodeTypeError">
                </div>
                <!-- END REVENUE TYPE AUTOCOMPLETE -->
              </div>
            </div>

            <div click-outside="domesticPaymentBh.municipalityFilterExpanded = false;" outside-if-not="expandMunicipalityFilter" class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label" translate="domesticPayment.municipality"></label>
                <!-- MUNICIPALITY AUTOCOMPLETE -->
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentBh.domesticPaymentForm.municipalityFilter.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="municipalityFilter" class="search-input"
                           ng-model="domesticPaymentBh.tempDompObj.municipality"
                           ng-change="domesticPaymentBh.openMunicipalities()"
                           ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.onlyNumbers}}"
                           ng-maxlength="3" maxlength="3" autocomplete="off" required>
                  </div>
                  <div id="expandMunicipalityFilter" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                       ng-click="domesticPaymentBh.toggleMunicipalities()">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentBh.tempDompObj.municipality!=''"
                       ng-click="domesticPaymentBh.removeMunicipalities()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
                       ng-show="domesticPaymentBh.tempDompObj.municipality==''"></i>
                  </div>
                  <div class="domp-filter-selection search-row"
                       ng-class="{'opened': domesticPaymentBh.municipalityFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper"
                         ng-repeat="municipality in domesticPaymentBh.tempDompObj.municipalities |filter: domesticPaymentBh.filterMunicipalities"
                         ng-click="domesticPaymentBh.selectMunicipality(municipality)">
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{municipality.description}}</p>
                        <p class="domp-list-number m-0">{{municipality.codeString}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                     ng-show="domesticPaymentBh.domesticPaymentForm.municipalityFilter.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                     translate="domesticPayment.municipalityError">
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="domesticPaymentBh.domesticPaymentForm.municipalityFilter.$error.municipalityExistance && domesticPaymentBh.tempDompObj.formSubmitted"
                      translate="domesticPayment.municipalityExistanceError">
                </div>
                <!-- END MUNICIPALITY AUTOCOMPLETE -->
              </div>
            </div>
          </div>

          <div class="row m-r-0">
            <div class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label m-t-15" translate="domesticPayment.taxPeriodFrom"></label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field" name="taxPeriodFrom"
                         uib-datepicker-popup="shortDate" data-ng-model="domesticPaymentBh.tempDompObj.taxPeriodFrom"
                         show-weeks="false" is-open="domesticPaymentBh.taxPeriodFromOpened"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         data-ng-change="domesticPaymentBh.validateTaxPeriod()" readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="domesticPaymentBh.openTaxPeriodFrom()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                     ng-show="domesticPaymentBh.domesticPaymentForm.taxPeriodFrom.$error.taxPeriodRange && domesticPaymentBh.tempDompObj.formSubmitted"
                     translate="domesticPayment.taxPeriodRangeError">
                </div>
              </div>
            </div>

            <div class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label m-t-15" translate="domesticPayment.taxPeriodTo"></label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field" name="taxPeriodTo"
                         uib-datepicker-popup="shortDate" data-ng-model="domesticPaymentBh.tempDompObj.taxPeriodTo"
                         show-weeks="false" is-open="domesticPaymentBh.taxPeriodToOpened"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         data-ng-change="domesticPaymentBh.validateTaxPeriod()" readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="domesticPaymentBh.openTaxPeriodTo()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-r-0">
            <div click-outside="domesticPaymentBh.budgetOrganizationFilterExpanded = false;" outside-if-not="expandBudgFilterOrg" class="col-sm-6 left-section">
              <div class="form-group">
                <label class="domp-form-label" translate="domesticPayment.budgetOrganisation"></label>
                <!-- BUDGET ORGANIZATIONS AUTOCOMPLETE -->
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentBh.domesticPaymentForm.budgetOrganizationFilter.$invalid && domesticPaymentBh.tempDompObj.formSubmitted}">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="budgetOrganizationFilter" class="search-input"
                           ng-model="domesticPaymentBh.tempDompObj.budgetOrganization"
                           ng-change="domesticPaymentBh.openBudgetOrganizations()" maxlength="7"
                           ng-pattern-restrict="{{domesticPaymentBh.regexPatterns.onlyNumbers}}"
                           ng-maxlength="7" autocomplete="off" required>
                  </div>
                  <div id="expandBudgFilterOrg" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                       ng-click="domesticPaymentBh.toggleBudgetOrganizations()">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentBh.tempDompObj.budgetOrganization!=''"
                       ng-click="domesticPaymentBh.closeBudgetOrganization()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
                       ng-show="domesticPaymentBh.tempDompObj.budgetOrganization==''"></i>
                  </div>
                  <div class="domp-filter-selection search-row"
                       ng-class="{'opened': domesticPaymentBh.budgetOrganizationFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper"
                         ng-repeat="organization in domesticPaymentBh.tempDompObj.budgetOrganizations |filter: domesticPaymentBh.filterBudgetOrganizations"
                         ng-click="domesticPaymentBh.selectBudgetOrganization(organization)">
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{organization.description}}</p>
                        <p class="domp-list-number m-0">{{organization.budgetOrganisationNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                     ng-show="domesticPaymentBh.domesticPaymentForm.budgetOrganizationFilter.$error.required && domesticPaymentBh.tempDompObj.formSubmitted"
                     translate="domesticPayment.budgetOrganizationError">
                </div>
                <!-- END BUDGET ORGANIZATIONS -->
              </div>
            </div>
          </div>
        </div>
        <!-- END PUBLIC REVENUE SECTION -->

        <div class="domp-footer-wrapper">
          <div class="row m-r-0">
            <div ng-if="!domesticPaymentBh.tempDompObj.isInternal" class="col-sm-6 col-lg-6 col-md-6 p-l-30">
              <div class="col-xs-12 p-l-10">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                    <input type="checkbox" ng-model="domesticPaymentBh.tempDompObj.isUrgentPaymentCheckbox" ng-click="domesticPaymentBh.pushGtmUrgentPaymentEvent();">
                  <i class="input-helper"></i> {{'domesticPayment.selectUrgentLabelSRB' | translate}} <i uib-tooltip="{{'domesticPayment.urgentPaymentInfo' | translate}}" tooltip-append-to-body="true" tooltip-trigger="'mouseenter'" tooltip-placement="top" class="icon icon-question" style="font-weight: bolder; color:#ff4d5a;"></i>
                </label>
              </div>
            </div>
            <!--<div class="col-sm-6 col-lg-6 col-md-6 pull-right">-->
              <!--<div class="col-lg-7 col-md-6 col-sm-5"></div>-->
              <!--<div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 p-r-5 p-t-10 charges-label">-->
                <!--<a ng-href="{{domesticPaymentBh.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" class="pull-right">Check the bank charges</a>-->
              <!--</div>-->
            <!--</div>-->
          </div>
          <div class="row m-r-0">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary" type="button" translate="payments.cancel"
                      data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
            <div class="col-xs-6 t-a-r">
              <button class="btn btn-default" type="submit"
                      translate="domesticPayment.confirmBtn"
                      ng-click="domesticPaymentBh.submitPayment();"></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div ng-if="domesticPaymentBh.currentStep === 2" class="domp-step-2" ng-include="'payments/domestic-payment-resume.tpl.html'"></div>
  </div>
</div>
