(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name payments.controller:CurrencyConversionCtrl
     *
     * @description
     *
     */
    angular
        .module('payments')
        .controller('CurrencyConversionCtrl', CurrencyConversionCtrl);

    function CurrencyConversionCtrl($rootScope, $sce, $log, $location, CTLocalizationService, ExchangeRatesService, $state, HelperService, PaymentTypesService,
        ConfigEndpointService, $localStorage, $filter, $timeout, AccountsService,
        CurrencyService, PaymentsService, PaymentTemplatesService, $translate, PaymentFilterService, $q, Notification, AlertService, GoogleTagManagerHelperService, NotificationService) {
        var vm = this;
        var noToAccount = false;
        var currencyExchangeInProgress;

        vm.htmlTooltip = '<div>' + $translate.instant('currencyConversion.provisionToolTip1') +'</div><br>';
        vm.htmlTooltip = vm.htmlTooltip + '<ul><li>' + $translate.instant('currencyConversion.provisionToolTip2') +'</li>';
        vm.htmlTooltip = vm.htmlTooltip + '<li>' + $translate.instant('currencyConversion.provisionToolTip3') +'</li>';
        vm.htmlTooltip = vm.htmlTooltip + '<li>' + $translate.instant('currencyConversion.provisionToolTip4') +'</li></ul><br>';
        vm.htmlTooltip = vm.htmlTooltip + '<div>' + $translate.instant('currencyConversion.provisionToolTip5') +'</div>';

        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.localCurrency = 'EUR';// $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;

        vm.paymentResponse = {};
        vm.exchangeRatesParams = {
            page: 0,
            pageSize: 1000,
            date: '',
            includeList: ['currency']
        };
        vm.showPaymentId = false;
        vm.currencyExchangeObject = {};
        vm.exchangeRatesList = {};
        vm.currentDate = HelperService.getYesterday().firstDay;
        vm.fromAccountsList = [];
        vm.toAccountsList = [];
        vm.domesticCurrencyAccounts = [];
        vm.foreignCurrencyAccounts = [];
        vm.currencyList = [];

        vm.disableInputFields = true;

        vm.accountChanged = false;

        vm.tempExchangeObj = {
            sellCurrencyInput: '',
            buyCurrencyInput: {},
            currentDate: HelperService.getYesterday().firstDay,
            fromAccountSelect: {},
            toAccountSelect: {},
            fromAccountAmountInput: '',
            toAccountAmountInput: '',
            fixedAmount: 'D',
            formSubmitted: false
        };

        vm.currentStep = 1;
        vm.calculationRate = '';
        vm.foreignCurrency = '';
        vm.fromAccountDropdown = false;
        vm.toAccountDropdown = false;
        vm.bankCharges = '';
        vm.exchangeType = {
            from: true,
            to: false
        };

        /*        vm.smsAuthentificate = false;
         vm.smsPasswordInput = '';*/

        vm.currencyExchangeFilter = {
            page: 0,
            paymentTypeGroup: 'CurrencyExchange',
            orderBy: 'dateCreated DESC',
            pageSize: 10
        };

        vm.currencyExchangeListGrouped = {};

        // date picker
        vm.datePickerOpened1 = false;
        vm.datePickerOpened2 = false;
        vm.forUpdate = false;

        vm.openDatePicker1 = function () {
            vm.datePickerOpened1 = !vm.datePickerOpened1;
        };
        vm.dateOptions = {
            minDate: new Date(),
            maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };

        vm.disabled = function (date, mode) {
            return (mode === 'day' && (date.getDay() === 0));
        };

        vm.bankCharges = '';
        var tempToAccountNumber;
        vm.toAccountForInsert = '';

        vm.openDatePicker1 = function () {
            vm.datePickerOpened1 = true;
        };
        vm.openDatePicker2 = function () {
            vm.datePickerOpened2 = true;
        };


        vm.periodList = [
            {
                item: {
                    key: 'today',
                    description: 'core.period.today'
                }
      }
      , {
                item: {
                    key: 'yesterday',
                    description: 'core.period.yesterday'
                }
      }
      , {
                item: {
                    key: 'thisWeek',
                    description: 'core.period.thisWeek'
                }
      }
      , {
                item: {
                    key: 'thisMonth',
                    description: 'core.period.thisMonth'
                }
      }
      , {
                item: {
                    key: 'thisYear',
                    description: 'core.period.thisYear'
                }
      }
      , {
                item: {
                    key: $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" ? "all2y" : 'all',
                    description: 'core.period.all'
                }
      }
    ];

        vm.selectedPeriod = vm.periodList[3];

        //set time filter to current month
        var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
        vm.currencyExchangeFilter.dueDateGTE = timeRange.valueDateGTE;
        vm.currencyExchangeFilter.dueDateLTE = timeRange.valueDateLTE;

        vm.init = function () {


            $rootScope.$on('$translateChangeSuccess', function () {
                vm.htmlTooltip = '<div>' + $translate.instant('currencyConversion.provisionToolTip1') +'</div><br>';
                vm.htmlTooltip = vm.htmlTooltip + '<ul><li>' + $translate.instant('currencyConversion.provisionToolTip2') +'</li>';
                vm.htmlTooltip = vm.htmlTooltip + '<li>' + $translate.instant('currencyConversion.provisionToolTip3') +'</li>';
                vm.htmlTooltip = vm.htmlTooltip + '<li>' + $translate.instant('currencyConversion.provisionToolTip4') +'</li></ul><br>';
                vm.htmlTooltip = vm.htmlTooltip + '<div>' + $translate.instant('currencyConversion.provisionToolTip5') +'</div>';
              });

            getCurrencyExchangePayments(true);
            vm.loadExchangeRatesList();
            setBankCharges();
        };

        vm.loadExchangeRatesList = function () {
            ExchangeRatesService.getExchangeRates(vm.exchangeRatesParams)
                .then(function (response) {
                    vm.exchangeRatesList = response;
                    loadCurrencies()
                        .then(function () {
                            vm.getDebitAccountList();
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                        });
                })
                .catch(function () {
                    $log.error('Error loading exchange rates!');
                });
        };

        vm.getDebitAccountList = function () {

            var params = {
                fromAccountsList: [],
                localCurrency: vm.localCurrency,
                currencyList: vm.currencyList,
                toAccountsList: []
            };

            if ($state.current && $state.current.name) {
                switch ($state.current.name) {
                case "payments.currencyConversion.fromTemplate":
                    PaymentTemplatesService.loadCurrencyConversionData($state.params.templateId, vm.tempExchangeObj, params)
                        .then(function () {
                            vm.fromAccountsList = params.fromAccountsList;
                            vm.toAccountsList = params.toAccountsList;
                            if (vm.countryCode == 'sl') {
                                vm.tempExchangeObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.fromAccountsList);
                                vm.accountChanged = true;
                                setToAccountList(vm.tempExchangeObj.fromAccountSelect.accountId);
                            }
                            getCurrencyRatio();
                            vm.exchangeCurrency();
                        })
                        .catch(function (error) {
                            $log.error('loadCurrencyConversionDataFromTemplate()', error);
                        });
                    break;
                case "payments.currencyConversion.fromPayment":
                    PaymentsService.loadCurrencyConversionData($state.params.paymentId, vm.tempExchangeObj, params)
                        .then(function () {

                            vm.fromAccountsList = params.fromAccountsList;
                            vm.toAccountsList = params.toAccountsList;
                            vm.exchangeType = params.exchangeType;

                            if (vm.countryCode == 'sl') {
                                // vm.tempExchangeObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.fromAccountsList);
                                // vm.accountChanged = true;
                                // setToAccountList(vm.tempExchangeObj.fromAccountSelect.accountId);
                            } else {
                                vm.disableInputFields = false;
                            }

                            getCurrencyRatio();
                            vm.exchangeCurrency();

                        })
                        .catch(function (error) {
                            $log.error('loadCurrencyConversionDataFromPayment()', error);
                        });
                    break;
                case "payments.currencyConversion":
                    PaymentTypesService.getByGroup({
                            paymentTypeGroup: 'CurrencyExchange'
                        })
                        .then(function (response) {
                            vm.fromAccountsList = response.result[0].debitAccountList;
                            vm.toAccountsList = response.result[0].creditAccountList;

                            _.forEach(vm.fromAccountsList, function (item) {
                                if (item.accountDescription) {
                                    item.optionValue = item.iban + ' - ' + item.currencySymbol;
                                }
                            });

                            _.forEach(vm.toAccountsList, function (item) {
                                if (item.accountDescription) {
                                    item.optionValue = item.accountDescription + ' - ' + item.iban;
                                }
                            });

                            if (_.isArray(vm.fromAccountsList) && vm.fromAccountsList.length > 0) {
                                vm.tempExchangeObj.fromAccountSelect = vm.fromAccountsList[0];
                                vm.tempExchangeObj.sellCurrencyInput = vm.fromAccountsList[0].currencySymbol;
                                if (vm.countryCode == 'sl') {
                                    vm.tempExchangeObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.fromAccountsList);
                                    vm.tempExchangeObj.sellCurrencyInput = vm.tempExchangeObj.fromAccountSelect.currencySymbol;
                                    vm.accountChanged = true;
                                    setToAccountList(vm.tempExchangeObj.fromAccountSelect.accountId);
                                }
                                filterBuyCurrency();
                                getCurrencyRatio();
                            }

                        })
                }
            }
            //}
            //})
        };


        var fromAccountChanged = function () {
            vm.tempExchangeObj.sellCurrencyInput = vm.tempExchangeObj.fromAccountSelect.currencySymbol;
            setToAccountList(vm.tempExchangeObj.fromAccountSelect.accountId)
                .then(function () {
                    filterBuyCurrency();
                });
        };

        vm.exchangeCurrency = function (event) {
            $timeout(function () {
                if (vm.tempExchangeObj.fromAccountAmountInput || vm.tempExchangeObj.toAccountAmountInput) {

                    var exchangeRateParams = prepareCurrencyExchangeObject();
                    var params = [];
                    params.push(ExchangeRatesService.calculateExchangeRate(exchangeRateParams));
                    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                        currencyExchangeInProgress = true;
                        var paramsToEur = prepareConversionToEur();
                        params.push(ExchangeRatesService.calculateExchangeRate(paramsToEur));
                    }
                    $q.all(params)
                        .then(function (responses) {

                            if (vm.exchangeType.from) {
                                vm.tempExchangeObj.toAccountAmountInput = responses[0].calculatedAmount;
                            } else if (vm.exchangeType.to) {
                                vm.tempExchangeObj.fromAccountAmountInput = responses[0].calculatedAmount;
                            }
                            lastFromCurrency = angular.copy(vm.tempExchangeObj.fromAccountAmountInput);
                            lastToCurrency = angular.copy(vm.tempExchangeObj.toAccountAmountInput);

                            if (responses[1]) {
                                var calculatedAmount = responses[1].calculatedAmount;
                                validateAmount(calculatedAmount);
                                currencyExchangeInProgress = false;
                            }

                          if(event && event.type == 'keyup'){
                            vm.submitCurrencyConversion();
                          }
                        })
                }
            }, 0);
        };

        function validateAmount(calculatedAmount) {
            if (!vm.tempExchangeObj.toAccountAmountInput || !vm.tempExchangeObj.fromAccountAmountInput) {
                vm.currencyForm.amountInput.$setValidity("maximalAmount", true);
                vm.currencyForm.toAmountInput.$setValidity("maximalAmount", true);
                vm.currencyForm.amountInput.$setValidity("minimalAmount", true);
                vm.currencyForm.toAmountInput.$setValidity("minimalAmount", true);
                return;
            }

            if (calculatedAmount > 99999999999.999) {
                vm.currencyForm.amountInput.$setValidity("maximalAmount", false);
                vm.currencyForm.toAmountInput.$setValidity("maximalAmount", false);
            } else {
                vm.currencyForm.amountInput.$setValidity("maximalAmount", true);
                vm.currencyForm.toAmountInput.$setValidity("maximalAmount", true);
            }

            if (calculatedAmount < 10) {
                vm.currencyForm.amountInput.$setValidity("minimalAmount", false);
                vm.currencyForm.toAmountInput.$setValidity("minimalAmount", false);
            } else {
                vm.currencyForm.amountInput.$setValidity("minimalAmount", true);
                vm.currencyForm.toAmountInput.$setValidity("minimalAmount", true);
            }
        }

        function prepareConversionToEur() {
            var tempObj = {};
            if (vm.exchangeType.from) {
                tempObj.amount = vm.tempExchangeObj.fromAccountAmountInput;
                tempObj.currencySymbolFrom = "EUR";
                tempObj.currencySymbolTo = vm.tempExchangeObj.sellCurrencyInput;
                tempObj.fixedAmount = 'C';

            } else if (vm.exchangeType.to) {
                tempObj.amount = vm.tempExchangeObj.toAccountAmountInput;
                tempObj.currencySymbolFrom = "EUR";
                tempObj.currencySymbolTo = vm.tempExchangeObj.buyCurrencyInput.symbol;
                tempObj.fixedAmount = 'C';
            }
            return tempObj;
        }

        vm.goBack = function () {
            $state.go('payments.paymentsOverview');
        };

        var prepareCurrencyExchangeObject = function () {
            var tempObj = {
                currencySymbolFrom: vm.tempExchangeObj.sellCurrencyInput,
                currencySymbolTo: vm.tempExchangeObj.buyCurrencyInput.symbol
            };
            if (vm.exchangeType.from) {
                tempObj.amount = vm.tempExchangeObj.fromAccountAmountInput;
                tempObj.fixedAmount = 'D';
            } else if (vm.exchangeType.to) {
                tempObj.amount = vm.tempExchangeObj.toAccountAmountInput;
                tempObj.fixedAmount = 'C';
            }
            return tempObj;
        };

        // var filterRecipientAccounts = function () {
        //     vm.domesticCurrencyAccounts = _.filter(vm.fromAccountsList, function (item) {
        //         return item.currencySymbol == vm.localCurrency;
        //     });
        //     vm.foreignCurrencyAccounts = _.filter(vm.fromAccountsList, function (item) {
        //         return item.currencySymbol != vm.localCurrency;
        //     });
        // };

        vm.toggleFromAccount = function () {
            vm.fromAccountDropdown = !vm.fromAccountDropdown;
        };

        vm.toggleToAccount = function () {
            vm.toAccountDropdown = !vm.toAccountDropdown;
        };

        vm.setAccountFrom = function (item) {
            if (item.accountId !== vm.tempExchangeObj.fromAccountSelect.accountId) {
                vm.accountChanged = true;
            } else {
                vm.accountChanged = false;
            }
            vm.tempExchangeObj.fromAccountSelect = item;
            vm.fromAccountDropdown = false;
            vm.toAccountDropdown = false;
            fromAccountChanged();
            if (vm.tempExchangeObj.fromAccountAmountInput) {
                vm.exchangeCurrency();
            }
            getCurrencyRatio();
        };

        vm.setAccountTo = function (item) {
            vm.tempExchangeObj.toAccountSelect = item;
            vm.fromAccountDropdown = false;
            vm.toAccountDropdown = false;
            if (vm.tempExchangeObj.buyCurrencyInput.symbol != vm.localCurrency && vm.localCurrency === "EUR" && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr') {
                var toAccountCurrencyIndex = _.findIndex(vm.currencyList, function (item) {
                    return vm.tempExchangeObj.toAccountSelect.currencySymbol == item.symbol;
                });
                if (toAccountCurrencyIndex != -1) {
                    vm.tempExchangeObj.buyCurrencyInput = vm.currencyList[toAccountCurrencyIndex];
                }
            } else if (vm.localCurrency === "RSD" || (vm.localCurrency === "EUR" && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr') 
                    || $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh') {
                if (vm.tempExchangeObj.buyCurrencyInput && vm.tempExchangeObj.toAccountSelect) {
                    // quickfix - sometimes empty symbol variable
                    vm.tempExchangeObj.buyCurrencyInput = {
                        symbol: ""
                    };
                    vm.tempExchangeObj.buyCurrencyInput.symbol = vm.tempExchangeObj.toAccountSelect.currencySymbol;
                }
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
                vm.tempExchangeObj.buyCurrencyInput = _.find(vm.currencyList, {
                    symbol: vm.tempExchangeObj.toAccountSelect.currencySymbol
                });
            }
            if (vm.tempExchangeObj.fromAccountAmountInput) {
                vm.exchangeCurrency();
            }
            getCurrencyRatio();
        };


        if ($state.params.editable) {
            vm.paymentResponse.id = $state.params.paymentId;
            vm.forUpdate = true;
        }


        vm.submitCurrencyConversion = function () {
            if (vm.nextButtonPressed) return;
            if (!vm.currencyForm.$valid || ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl" && currencyExchangeInProgress)) {
                if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl" && currencyExchangeInProgress)
                  setTimeout(vm.submitCurrencyConversion, 50);//wait 50 millisecnds then recheck
                return;
            }

            if (vm.countryCode != 'hr' && vm.toAccountsList.length == 0) {
                return;
            }

            if (!vm.formSubmitted) {
                vm.tempExchangeObj.formSubmitted = true;
                vm.formSubmitted = true;


                var tempPaymentObj = createPaymentObject();
                vm.nextButtonPressed = true;
                if (vm.forUpdate) {
                    tempPaymentObj.id = vm.paymentResponse.id;
                    PaymentsService.updatePayment(vm.paymentResponse.id, tempPaymentObj)
                        .then(function (response) {
                            vm.paymentResponse = response;
                            Notification.clearAll();
                            vm.currentStep = 2;
                            vm.forUpdate = false;
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                            vm.forUpdate = false;
                            // vm.currencyForm.$valid = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                            vm.nextButtonPressed = false;
                        });
                } else {
                    PaymentsService.insertPayment(tempPaymentObj)
                        .then(function (response) {
                            vm.paymentResponse = response;
                            $log.debug('Insert payment object', response);
                            Notification.clearAll();
                            vm.currentStep = 2;

                            GoogleTagManagerHelperService.pushVirtualPageView('payments/currency-conversion/toconfirm', 'currency-conversion toconfirm', $location.host() + 'payments/currency-conversion/toconfirm');
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                            // vm.currencyForm.$valid = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                            vm.nextButtonPressed = false;
                        });
                }
            }

        };

        var loadCurrencies = function () {
            var deferred = $q.defer();
            var countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
            if (countryCode == 'hr' || countryCode == 'sl') {
                return CurrencyService.getCurrencyPage({
                        isCurrencyForPayment: true,
                        pageSize: 1000
                    })
                    .then(function (response) {
                        vm.currencyList = response.result;
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
            deferred.resolve();
            return deferred.promise;
        };

        var createPaymentObject = function () {
            var paymentForInsert = {
                accountId: vm.tempExchangeObj.fromAccountSelect.accountId,
                fromAccountNumber: HelperService.parseAccountNumber(vm.tempExchangeObj.fromAccountSelect.accountNumber),
                //toAccountNumber: HelperService.parseAccountNumber(vm.tempExchangeObj.toAccountSelect.accountNumber),
                dueDate: new Date(),
                currencySymbol: vm.tempExchangeObj.fromAccountSelect.currencySymbol,
                paymentTypeGroup: 'CurrencyExchange',
                currencyExchange: {
                    destinationCurrencySymbol: vm.tempExchangeObj.buyCurrencyInput.symbol
                }
            };
            // check in case user choose currency with no related to account
            if (vm.tempExchangeObj.toAccountSelect) {
                paymentForInsert.toAccountNumber = HelperService.parseAccountNumber(vm.tempExchangeObj.toAccountSelect.accountNumber);
            } else {
                paymentForInsert.toAccountNumber = vm.toAccountForInsert.accountNumber;
            }

            if (vm.exchangeType.from) {
                paymentForInsert.amount = vm.tempExchangeObj.fromAccountAmountInput;
            } else if (vm.exchangeType.to) {
                paymentForInsert.currencyExchange.transactionAmount2 = vm.tempExchangeObj.toAccountAmountInput;
            }
            return paymentForInsert;
        };

        var filterBuyCurrency = function () {
            vm.tempExchangeObj.buyCurrencyInput = {};
            if (vm.toAccountsList && vm.toAccountsList[0]) {
               vm.tempExchangeObj.toAccountSelect = vm.toAccountsList[0];
            }
            if (vm.tempExchangeObj.sellCurrencyInput != vm.localCurrency) {
                vm.tempExchangeObj.buyCurrencyInput.symbol = vm.localCurrency;
            // && vm.tempExchangeObj.toAccountSelect.accountId - we need to make sure that account is selected in order to execute this logic
            } else if (vm.currencyList.length > 0 && (vm.tempExchangeObj.toAccountSelect && vm.tempExchangeObj.toAccountSelect.accountId)) {
                vm.tempExchangeObj.buyCurrencyInput = _.find(vm.currencyList, function (item) {
                    return item.symbol == vm.tempExchangeObj.toAccountSelect.currencySymbol;
                });
            } else if (vm.currencyList.length > 0) {
                var tempCurrencySymbol = _.find(vm.currencyList, function (item) {
                    return vm.localCurrency != item.symbol;
                });
                vm.tempExchangeObj.buyCurrencyInput = tempCurrencySymbol;
            }

            if (vm.localCurrency === 'RSD' || (vm.localCurrency === 'EUR' && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr') || $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh') {
                vm.tempExchangeObj.buyCurrencyInput.symbol = vm.tempExchangeObj.toAccountSelect.currencySymbol;
            }
            //variable for displaying on GUI
            //vm.buyCurrencyToAccountSelect = vm.tempExchangeObj.toAccountSelect;
        };

        var clearInput = function () {
            // vm.smsAuthentificate = false;
            // vm.smsPasswordInput = '';
            vm.tempExchangeObj.fromAccountAmountInput = '';
            vm.tempExchangeObj.toAccountAmountInput = '';
            vm.tempExchangeObj.fromAccountSelect = vm.fromAccountsList[0];
            vm.tempExchangeObj.sellCurrencyInput = vm.fromAccountsList[0].currencySymbol;
            vm.tempExchangeObj.formSubmitted = false;
            vm.fromAccountDropdown = false;
            vm.toAccountDropdown = false;
            vm.selectedPeriod = vm.periodList[3];
            var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
            vm.currencyExchangeListGrouped = {};
            vm.currencyExchangeFilter.dueDateGTE = timeRange.valueDateGTE;
            vm.currencyExchangeFilter.dueDateLTE = timeRange.valueDateLTE;
            getCurrencyExchangePayments(true);
            filterBuyCurrency();
        };

        var mapWithPaymentResponse = function () {
            vm.tempExchangeObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(vm.fromAccountsList, vm.paymentResponse.accountId);
            vm.tempExchangeObj.sellCurrencyInput = vm.tempExchangeObj.fromAccountSelect.currencySymbol;
            if (vm.paymentResponse.amount) {
                vm.tempExchangeObj.fromAccountAmountInput = angular.copy(vm.paymentResponse.amount);
            }
            if (vm.paymentResponse.transactionAmount2) {
                vm.tempExchangeObj.toAccountAmountInput = angular.copy(vm.paymentResponse.transactionAmount2);
            }

            setToAccountList(vm.paymentResponse.accountId);
            if (!noToAccount)
                vm.tempExchangeObj.toAccountSelect = PaymentFilterService.filterToAccountSelect(vm.toAccountsList, vm.paymentResponse.toAccountNumber.split(" ")[0]);
            var params = {
                sellCurrency: vm.tempExchangeObj.sellCurrencyInput,
                localCurrency: vm.localCurrency,
                currencyList: vm.currencyList,
                //destinationCurrency: vm.tempExchangeObj.toAccountSelect.currencySymbol
                destinationCurrency: vm.paymentResponse.destinationCurrencySymbol
            };

            if (!vm.paymentResponse.destinationCurrencySymbol && vm.paymentResponse.currencyExchange.destinationCurrencySymbol)
                params.destinationCurrency = vm.paymentResponse.currencyExchange.destinationCurrencySymbol;

            vm.tempExchangeObj.buyCurrencyInput = PaymentFilterService.filterBuyCurrencyInput(params);
        };

        var setToAccountList = function (accountId) {
            var deferred = $q.defer();
            if (vm.accountChanged) {
                return PaymentTypesService.getByGroup({
                        paymentTypeGroup: 'CurrencyExchange',
                        masterAccountId: accountId
                    })
                    .then(function (response) {
                        if (_.isArray(response.result) && response.result.length > 0) {
                            vm.toAccountsList = response.result[0].creditAccountList;
                            //vm.toAccountsList = [];
                            vm.setAccountTo(vm.toAccountsList[0]);
                            _.forEach(vm.accountsTo, function (item) {
                                if (item.accountDescription) {
                                    item.optionValue = item.accountDescription + ' - ' + item.iban;
                                }
                            });

                            vm.disableInputFields = false;

                        }
                        vm.accountChanged = false;
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });
            } else {
                deferred.resolve(vm.setAccountTo(vm.tempExchangeObj.fromAccountSelect));
            }
            return deferred.promise;
        };

        vm.editPayment = function () {
            vm.forUpdate = true;
            // mapWithPaymentResponse();
            vm.currentStep = 1;
        };


        vm.backToCurrencyConversion = function () {
            clearInput();
            $state.go('payments.currencyConversion', {}, {reload: true})

        };

        var getCurrencyRatio = function (takeBuyCurrencyFirst) {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
              getCurrencyRatioSlovenia();
            } else {
              getCurrencyRatioOther(takeBuyCurrencyFirst);
            }
        };

        function getCurrencyRatioOther(takeBuyCurrencyFirst) {
          var fromAccountExchangeRate = _.find(vm.exchangeRatesList.result, {
            currencySymbol: vm.tempExchangeObj.fromAccountSelect.currencySymbol
          });
          if (fromAccountExchangeRate) vm.fromAccountCalculationRate = fromAccountExchangeRate.sellRate;

          var toAccountExchangeRate = _.find(vm.exchangeRatesList.result, {
            currencySymbol: takeBuyCurrencyFirst ? vm.tempExchangeObj.buyCurrencyInput.symbol : vm.tempExchangeObj.toAccountSelect.currencySymbol
          });
          if (vm.countryCode == 'hr' && !toAccountExchangeRate) {
            toAccountExchangeRate = _.find(vm.exchangeRatesList.result, {
                currencySymbol: vm.tempExchangeObj.buyCurrencyInput.symbol
              });
          }
          if (toAccountExchangeRate) vm.toAccountCalculationRate = toAccountExchangeRate.buyRate;

          if (fromAccountExchangeRate && (fromAccountExchangeRate.currencySymbol != vm.localCurrency)) {
            vm.foreignCurrency = fromAccountExchangeRate.currencySymbol;
            vm.fixedAmount = (checkForCountry()) ? fromAccountExchangeRate.buyRate : fromAccountExchangeRate.calculationRate;
          } else if (toAccountExchangeRate && (toAccountExchangeRate.currencySymbol != vm.localCurrency)) {
            vm.foreignCurrency = toAccountExchangeRate.currencySymbol;
            vm.fixedAmount = (checkForCountry()) ? toAccountExchangeRate.sellRate : toAccountExchangeRate.calculationRate;
          }
        }

        function getCurrencyRatioSlovenia () {
          var fromAccountExchangeRate = _.find(vm.exchangeRatesList.result, {
            currencySymbol: vm.tempExchangeObj.fromAccountSelect.currencySymbol
          });
          if (fromAccountExchangeRate) {
            vm.foreignCurrency = fromAccountExchangeRate.currencySymbol;
            vm.fromAccountCalculationRate = fromAccountExchangeRate.buyRate;
          }

          var toAccountExchangeRate = _.find(vm.exchangeRatesList.result, {
            currencySymbol: vm.tempExchangeObj.toAccountSelect.currencySymbol

          });
          if (toAccountExchangeRate) {
            vm.foreignCurrency = toAccountExchangeRate.currencySymbol;
            vm.toAccountCalculationRate = toAccountExchangeRate.sellRate;

          }

          //vm.fixedAmount = vm.toAccountCalculationRate;


          if (fromAccountExchangeRate && (fromAccountExchangeRate.currencySymbol != vm.localCurrency)) {
            vm.fixedAmount =  toAccountExchangeRate.sellRate;
            //TSK-10085 SLOW 485 - Currency exchange - tecaj valute kupnje nije ispravan - promjenjeno na SELL RATE

          }
          else if (toAccountExchangeRate && (toAccountExchangeRate.currencySymbol != vm.localCurrency)) {
            vm.fixedAmount = toAccountExchangeRate.sellRate;

          }
        }

        var checkForCountry = function () {
          var c = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
          return (c == 'hr' || c == 'mne' || c == 'sr' || c == 'bh');

        };

        var getCurrencyExchangePayments = function (reset) {
            if (reset) {
                vm.currencyExchangeFilter.page = 0;
            } else {
                vm.currencyExchangeFilter.page += 1;
            }

            PaymentsService.getPage(vm.currencyExchangeFilter)
                .then(function (response) {

                    vm.currencyExchangeListGrouped = HelperService.mergeRowsForTimeline({
                        existingData: vm.currencyExchangeListGrouped,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.dueDate);
                            date.setHours(0, 0, 0, 0);
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                date: new Date(e),
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.changeSellCurrency = function () {
            if (vm.tempExchangeObj.fromAccountAmountInput) {
                vm.exchangeCurrency();
            }
            getCurrencyRatio(true);
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' || $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                displayToAccountRelatedToBuyCurrency();
            }
        };

        vm.loadMoreTimelineItems = function () {
            getCurrencyExchangePayments(false);
        };

        vm.getDefaultData = function () {
            return ConfigEndpointService.getDefaultData()
                .then(function (response) {
                    if (response.APILocation) {
                        $localStorage.code = response.APILocation;
                        vm.bankCharges = response.BankCharges;
                        vm.localCurrency = response.DefaultCurrency;
                    }
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        var setBankCharges = function () {
            return ConfigEndpointService.getDefaultData()
                .then(function (response) {
                    if (response.BankCharges) {
                        vm.bankCharges = response.BankCharges;
                    }
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.timelineTimeFilter = function (item) {
            vm.selectedPeriod = item;
            var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
            vm.currencyExchangeFilter.dueDateGTE = timeRange.valueDateGTE;
            vm.currencyExchangeFilter.dueDateLTE = timeRange.valueDateLTE;
            getCurrencyExchangePayments(true);

          GoogleTagManagerHelperService.pushEvent('CurrencyConversion', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
        };

        // Actions for payments in timeline

        var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        $rootScope.$on('appLanguageChangedHeader', function () {
            sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        });


        vm.deletePaymentAction = function (paymentId, type) {
            var paymentForDelete = paymentId || vm.paymentResponse.id;

            //  swal({
            //   title: sweetAlertOptions.areYouSure,
            //   type: "info",
            //   showCancelButton: true,
            //   confirmButtonClass: "btn-default",
            //   confirmButtonText: sweetAlertOptions.yes,
            //   cancelButtonText: sweetAlertOptions.no,
            //   closeOnConfirm: true,
            //   closeOnCancel: true
            // }, function (isConfirm) {
            //   if (isConfirm) {
            //PaymentsService.deletePayment(paymentForDelete)
            //  .then(function (response) {
            //    HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], paymentForDelete);
            //    if(type === 'timeline'){
            //      getCurrencyExchangePayments(true);
            //    } else if (type === 'step2'){
            //      vm.backToCurrencyConversion();
            //      vm.currentStep = 1;
            //    }
            //  })
            //  .catch(function (error) {
            //    NotificationService.showMessage(error, 'error');
            //  });
            //   }
            // });

            AlertService.confirmationAlert()
                .then(function (result) {
                    if (result) {
                        PaymentsService.deletePayment(paymentForDelete)
                            .then(function (response) {
                                AlertService.deletedPaymentAlert({
                                        paymentName: paymentForDelete,
                                        type: 'payment'
                                    })
                                    .then(function () {
                                        if (type === 'timeline') {
                                            getCurrencyExchangePayments(true);
                                        } else if (type === 'step2') {
                                            vm.backToCurrencyConversion();
                                            vm.currentStep = 1;
                                        }
                                    });

                            })
                            .catch(function (error) {
                                NotificationService.showMessage(error, 'error');
                            });
                    }
                })
                .catch(function () {
                    $log.debug('Example failed!');
                });
        };

        //       Old method for sign
        /*      vm.signPaymentAction = function (paymentId) {
         PaymentsService.openSignModal(paymentId)
         .then(function (signed) {
         if (signed) {
         getCurrencyExchangePayments(true);
         }
         })
         .catch(function (error) {
         NotificationService.showMessage(error, 'error');
         });
         };*/


        vm.signPaymentAction = function (payment) {
            if (vm.confirmButtonPressed) return;
            if (PaymentsService.isVerificationRequiredForSign(payment)) {
                // If verification is required, go to payment sign state
                $state.go('payments.sign', {
                    paymentId: payment.id,
                    type: 'exchange'
                });
            } else {

                if (payment.allowedActionList) {
                    var updateObject = _.find(payment.allowedActionList.itemList, function (item) {
                        return item.methodName === 'UpdateOldPaymentDateAllowed';
                    });

                    if (updateObject) {
                        vm.dueDateUpdateAllowed = updateObject.isEnabled;
                    }
                }


                // Use only date without hours for compare
                var currentTime = new Date();
                currentTime.setHours(0, 0, 0, 0);
                // Check if all payments have valid due date
                var isPaymentDueDateValid = new Date(payment.dueDate) >= currentTime;


                // If verification is not required, then sign payment and go to final step
                var params = {
                    id: payment.id,
                    payment: payment
                };

                vm.confirmButtonPressed = true;
                if (isPaymentDueDateValid) {
                    return PaymentsService.signPayment(params).then(function (signedPayment) {
                        // TODO: Use signed payment on response
                        var id = (signedPayment.id) ? signedPayment.id : vm.paymentResponse.id;
                        $state.go('payments.finalStep', {
                            paymentId: id,
                            type: 'exchange'
                        });
                    }).catch(function (error) {
                        $log.error(error);
                        NotificationService.showMessage(error, 'error');
                    }).finally(function() {
                      vm.confirmButtonPressed = false;
                    });
                } else {
                    PaymentsService.openUpdatePaymentDueDateModal()
                        .result.then(function (result) {
                            if (result == "confirm") {
                                params.useCurrentDate = true;
                                return PaymentsService.signPayment(params).then(function (signedPayment) {

                                  // TODO: Use signed payment on response
                                    $state.go('payments.finalStep', {
                                        paymentId: payment.id,
                                        type: 'exchange'
                                    });
                                  vm.confirmButtonPressed = false;
                                }).catch(function (error) {
                                    $log.error(error);
                                    NotificationService.showMessage(error, 'error');
                                  vm.confirmButtonPressed = false;
                                })
                            }
                        });
                }
            }
        };

        vm.recallPaymentAction = function (paymentId) {
            var params = {
                id: paymentId
            };
            PaymentsService.recallPayment(params)
                .then(function (response) {
                    getCurrencyExchangePayments(true);
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                })
        };

        vm.openCurrencyCalculate = function () {
            GoogleTagManagerHelperService.pushEvent('ExchangeRatesCalculator', 'OpenExhangeRatesCalculator', $translate.instant("currencyConversion.exchangeRatesCalculator"));
            PaymentsService.openCurrencyCalculatorModal();
        };



        //from account activated
        var lastFromCurrency;
        var lastToCurrency;
        vm.setFromCurrency = function (event) {
            vm.exchangeType = {
                from: true,
                to: false
            };

            if (vm.countryCode === 'sl') {
              vm.exchangeCurrency(event);
            }
            else if (lastFromCurrency !== vm.tempExchangeObj.fromAccountAmountInput) {
              vm.exchangeCurrency(event);
            }
        };


        //to account activated
        vm.setToCurrency = function (event) {

            vm.exchangeType = {
                from: false,
                to: true
            };

            if (vm.countryCode === 'sl') {
              vm.exchangeCurrency(event);
            }
            else if (lastToCurrency !== vm.tempExchangeObj.toAccountAmountInput) {
              vm.exchangeCurrency(event);
            }
        };

        var displayToAccountRelatedToBuyCurrency = function () {
            //vm.buyCurrencyToAccountSelect = _.find(vm.toAccountsList, function (item) {
            //  return item.currencySymbol == vm.tempExchangeObj.buyCurrencyInput.symbol;
            //});
            //if (vm.buyCurrencyToAccountSelect) {
            //  vm.tempExchangeObj.toAccountSelect = vm.buyCurrencyToAccountSelect;
            //}
            console.log(vm.tempExchangeObj.buyCurrencyInput)
            tempToAccountNumber = _.find(vm.toAccountsList, function (item) {
                return item.currencySymbol == vm.tempExchangeObj.buyCurrencyInput.symbol;
            });
            //if(tempToAccount){
            //  vm.tempExchangeObj.toAccountSelect = tempToAccount;
            //}
            if (!tempToAccountNumber) {
                noToAccount = true;
                vm.toAccountForInsert = vm.tempExchangeObj.fromAccountSelect;
            } else {
                noToAccount = false;
            }
            vm.tempExchangeObj.toAccountSelect = tempToAccountNumber;
        };

        vm.init();
    }
}());
