<div class="campaign-header-container" ng-if="dashboard.selectedCampaign || dashboard.selectedCampaignHr">

  <div data-ng-show="dashboard.campaignsForCarousel.length > 1 || dashboard.campaignsForCarouselHr.length > 1">
    <ol class="custom-campaign-carousel-indicators pull-right">
      <li ng-if="dashboard.currentLang == 'en'" data-ng-repeat="campaign in dashboard.campaignsForCarousel" data-ng-class="{'active': dashboard.currentIndex === campaign.index}" data-ng-click="dashboard.chooseFromCarousel(campaign.id);"></li>
      <li ng-if="dashboard.currentLang == 'hr'" data-ng-repeat="campaignHr in dashboard.campaignsForCarouselHr" data-ng-class="{'active': dashboard.currentIndexHr === campaignHr.index}" data-ng-click="dashboard.chooseFromCarousel(campaignHr.id);"></li>
    </ol>
  </div>

  <div class="pull-right pull-up arrow-containter m-r-15" ng-if="dashboard.isNextButtonVisible" data-ng-click="dashboard.goToNextItem()" style="position: absolute;right: 20px;">
    <a class="account-navigation-icons">
      <i class="zmdi zmdi-chevron-right zmdi-hc-4x"></i>
    </a>
  </div>

  <div class="pull-left pull-up arrow-containter m-l-15" ng-if="dashboard.isPreviousButtonVisible" data-ng-click="dashboard.goToPreviousItem()">
    <a class="account-navigation-icons">
      <i class="zmdi zmdi-chevron-left zmdi-hc-4x"></i>
    </a>
  </div>

  <div class="row account-carousel-row">
    <div class="account-header-inner" ng-switch on="accounts.selectedAccount.accountType.group">
      <div ng-if="dashboard.currentLang == 'en'" class="banner-image-url" ng-style="{'background-image': 'url(' + dashboard.selectedCampaign.mainImage + ')'}" style="position:sticky">
        <label style="white-space: pre-wrap; background-color: transparent; font-family: Charlie, sans-serif; font-size: large" class="campaign-text">{{dashboard.selectedCampaign.subjectText}}</label>
        <div class="t-a-l padding-buttons">
          <div class="banner-buttons">
            <button ng-if="dashboard.selectedCampaign.button1Label && dashboard.selectedCampaign.button1Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaign, dashboard.selectedCampaign.button1, 'button1', 'carousel')" target="_blank"
                    class="btn btn-default button-campaign-offer " style="background-color: #062a42; border: 1px solid #062a42;">
              <span class="round-marketing-text padding-buttons">{{dashboard.selectedCampaign.button1Label}}</span>
            </button>
            <button ng-if="dashboard.selectedCampaign.button2Label && dashboard.selectedCampaign.button2Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaign, dashboard.selectedCampaign.button2, 'button2', 'carousel')" target="_blank"
                    class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; ">
              <span class="round-marketing-text padding-buttons">{{dashboard.selectedCampaign.button2Label}}</span>
            </button>
            <button ng-if="dashboard.selectedCampaign.button3Label && dashboard.selectedCampaign.button3Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaign, dashboard.selectedCampaign.button3, 'button3', 'carousel')" target="_blank"
                    class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; margin-right: 0%; ">
              <span class="round-marketing-text padding-buttons">{{dashboard.selectedCampaign.button3Label}}</span>
            </button>

          </div>
        </div>
      </div>


      <div ng-if="dashboard.currentLang == 'hr'" class="banner-image-url" ng-style="{'background-image': 'url(' + dashboard.selectedCampaignHr.mainImage + ')'}" style="position:sticky">
        <label style="white-space: pre-wrap; background-color: transparent; font-family: Charlie, sans-serif; font-size: large" class="campaign-text">{{dashboard.selectedCampaignHr.subjectText}}</label>

        <div class="t-a-l padding-buttons">
          <div class="banner-buttons">
            <button ng-if="dashboard.selectedCampaignHr.button1Label && dashboard.selectedCampaignHr.button1Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaignHr,dashboard.selectedCampaignHr.button1, 'button1', 'carousel' )" target="_blank"
                    class="btn btn-default button-campaign-offer " style="background-color: #062a42; border: 1px solid #062a42;">
              <span class="round-marketing-text padding-buttons" >{{dashboard.selectedCampaignHr.button1Label}}</span>
            </button>
            <button ng-if="dashboard.selectedCampaignHr.button2Label && dashboard.selectedCampaignHr.button2Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaignHr, dashboard.selectedCampaignHr.button2, 'button2', 'carousel')" target="_blank"
                    class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; ">
              <span class="round-marketing-text padding-buttons">{{dashboard.selectedCampaignHr.button2Label}}</span>
            </button>
            <button ng-if="dashboard.selectedCampaignHr.button3Label && dashboard.selectedCampaignHr.button3Label!==''" data-ng-click="dashboard.goToBannerLink(dashboard.selectedCampaignHr, dashboard.selectedCampaignHr.button3, 'button3', 'carousel')" target="_blank"
                    class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; margin-right: 0%;">
              <span  class="round-marketing-text padding-buttons">{{dashboard.selectedCampaignHr.button3Label}}</span>
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-4">
      <!-- TODO: Add image based on account type -->
    </div>
  </div>


</div>

