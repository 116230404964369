<section id="main" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <div class="container" ng-switch-default>
    <div class="exchange-rate-header m-b-10">
      <div class="row">
        <div class="col-sm-12 f-20" translate="reclamation.title"></div>
      </div>
    </div>

    <div class="trans-complain-container">
      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.number"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::vm.model.id}}</label>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.paymentReference"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::vm.model.referenceNumber}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.bookingDate"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::vm.model.bookingDate | date:
              'shortDate' : '+0200'}}</label>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.valueDate"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::vm.model.valueDate | date:
              'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.amount"></label>
            <div class="row">
              <div class="col-sm-9 p-r-0">
                <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field t-a-r">{{::vm.model.amount| number:
                  2}}</label>
              </div>
              <div class="col-sm-3 p-l-0">
                <label class="tr-input-lbl wrap-text p-l-10 p-r-10 tr-amount-ctn input-field">{{::vm.model.currencySymbol}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.purpose"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::vm.model.purpose}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field"
                   translate="reclamation.wantComplain"></label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label translate="reclamation.description"></label>
            <textarea ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPStextArea}}"
                      ng-model="vm.input.comment"
                      class="form-control"
                      id="reclamationInput"
                      cols="30"
                      rows="5"
                      maxlength="250"
                      style="resize: none"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" ng-switch-when="sl">
    <div class="exchange-rate-header m-b-10">
      <div class="row">
        <div class="col-sm-12 f-20" translate="reclamation.title"></div>
      </div>
    </div>

    <div class="trans-complain-container">
      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label"><b>{{::vm.model.purpose}}</b></label>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlId"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label">{{::vm.model.id}}</label>
        </div>
      </div>

      <div class="row m-t-10" ng-if="vm.model.referenceNumber">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.referenceNumber"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label">{{::vm.model.referenceNumber}}</label>
        </div>
      </div>

      <div class="row m-t-10" ng-if="vm.model.receiverName">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlReceiver"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label">{{::vm.model.receiverName}}</label>
        </div>
      </div>

      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlFrom"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label">{{::vm.model.fromAccountNumber}}</label>
        </div>
      </div>

      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlTo"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label">{{::vm.model.toAccountNumber}}</label>
        </div>
      </div>

      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlAmount"></label>
        </div>
        <div class="col-md-6">
          <label class="po-value-label" ng-if="::vm.model.transactionAmount2">{{::vm.model.transactionAmount2|number:2}}
            {{::vm.model.destinationCurrencySymbol}}</label>
          <label class="po-value-label" ng-if="::!vm.model.transactionAmount2">{{::vm.model.amount|number:2}}
            {{::vm.model.currencySymbol}}</label>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="signPayments.dateCreated"></label>
        </div>
        <div class="col-md-6">
          <span class="po-value-label">{{vm.model.dateCreated| date:'shortDate' : '+0200'}}</span>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-md-6">
          <label class="po-desc-label" translate="paymentsOverview.povlDueDate"></label>
        </div>
        <div class="col-md-6">
          <span class="po-value-label">{{vm.model.dueDate| date:'shortDate' : '+0200'}}</span>
        </div>
      </div>
      <div class="row m-t-10">
        <div class="col-md-12">
          <label class="po-desc-label" translate="reclamation.description"></label>
          <textarea ng-pattern-restrict="{{vm.regexPatterns.allowedCharsText}}"
                    ng-model="vm.input.comment"
                    class="form-control"
                    id="reclamationInput"
                    cols="30"
                    rows="5"
                    maxlength="250"
                    style="resize: none">
            </textarea>
        </div>
      </div>
    </div>


    <div class="row m-t-10">
      <div class="col-sm-12 c-red bold-font">
        <div class="col-lg-3 col-sm-6 ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0"
                  translate="reclamation.cancel"
                  data-ng-click="vm.cancel()">

          </button>
        </div>
        <div class="col-lg-3 col-sm-6 pull-right ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0 pull-right"
                  translate="reclamation.confirm"
                  ng-disabled="vm.confirmButtonDisabled"
                  data-ng-click="vm.confirm();">

          </button>
        </div>
      </div>
    </div>
  </div>
</section>
