<div class="dw-container container">
    <h1>&nbsp;<span class="pull-right"><i class="zmdi zmdi-close pointer" id="pointerModal" ng-click="dwModalCtrl.closeDashboardWidgetModal(true);" ng-hide="dwModalCtrl.markAsFavoriteInProgress"></i></span></h1>
    <div class="dw-scroll-wrapper" ng-class="{'disabled-overlay': dwModalCtrl.markAsFavoriteInProgress}" scrollable>
        <div class="row">
            <div class="col-sm-12" ng-if="dwModalCtrl.currentAndCreditAccounts.length !== 0">
                <div class="widgetDiv">
                    <img src="img/hypo/addWidget/current_account_h200.png" class="widgetIcon" style="width:50px; height:50px;">
                    <h5 class="c-white f-18 widgetHeader" translate="dashboardWidgets.current"></h5>
                    <hr/>
                </div>


                <div class="col-sm-6 col-md-4 col-xs-12 p-l-5 p-r-5 m-b-5" ng-repeat="currentAndCreditAccount in dwModalCtrl.currentAndCreditAccounts">
                    <div class="dw-account-card" ng-click="currentAndCreditAccount.isFavorite = !currentAndCreditAccount.isFavorite; dwModalCtrl.markAccountAsFavorite(currentAndCreditAccount);">
                        <div class="col-xs-11 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0  no-wrap-text">{{currentAndCreditAccount.friendlyName}}</h4>
                            <!--<h6 class="m-t-0 m-b-0  no-wrap-text fw-cm">{{currentAndCreditAccount.iban | formatIban}} {{currentAndCreditAccount.currencySymbol}}</h6>-->
                            <h6 ng-if="currentAndCreditAccount.accountType.group !== 'CardAccountTypes' || APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                                class="m-t-0 m-b-0  no-wrap-text fw-cm">{{currentAndCreditAccount.iban | formatIban}} {{currentAndCreditAccount.currencySymbol}}</h6>
                          <h6 ng-if="currentAndCreditAccount.accountType.group === 'CardAccountTypes' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
                              class="m-t-0 m-b-0  no-wrap-text fw-cm">{{currentAndCreditAccount.accountNumber }} {{currentAndCreditAccount.currencySymbol}}</h6>
                        </div>
                        <div class="col-xs-1 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0"><span><i class="zmdi" style="padding:6px" ng-class="{'zmdi-star favorite-account widget':currentAndCreditAccount.isFavorite,'zmdi-star-outline':!currentAndCreditAccount.isFavorite}"></i></span></h4></div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12" ng-if="dwModalCtrl.savingAccounts.length !== 0">

            <div class="widgetDiv">
              <img src="img/hypo/addWidget/saving_account_h200.png" class="widgetIcon" style="width:50px; height:50px;">
              <h5 class="c-white f-18 widgetHeader" translate="dashboardWidgets.savingsTransactions"></h5>
              <hr/>
            </div>

            <div class="col-sm-6 col-md-4 col-xs-12 p-l-5 p-r-5 m-b-5" ng-repeat="savingAccount in dwModalCtrl.savingAccounts">
              <div class="dw-account-card" ng-click="savingAccount.isFavorite = !savingAccount.isFavorite; dwModalCtrl.markAccountAsFavorite(savingAccount);">
                <div class="col-xs-11 p-l-0 p-r-0">
                  <h4 class="m-t-0 m-b-0  no-wrap-text">{{savingAccount.friendlyName}}</h4>
                  <h6 class="m-t-0 m-b-0  no-wrap-text fw-cm">{{savingAccount.iban}} {{savingAccount.currencySymbol}}</h6>
                </div>
                <div class="col-xs-1 p-l-0 p-r-0">
                  <h4 class="m-t-0 m-b-0"><span><i class="zmdi" style="padding:6px" ng-class="{'zmdi-star favorite-account widget':savingAccount.isFavorite,'zmdi-star-outline':!savingAccount.isFavorite}"></i></span></h4></div>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-12" ng-if="dwModalCtrl.depositAccounts.length !== 0">
                <div class="widgetDiv">
                    <img src="img/hypo/addWidget/term_deposit_h200.png" class="widgetIcon" style="width:53px; height:50px;">
                    <h5 class="c-white f-18 widgetHeader" translate="dashboardWidgets.deposit"></h5>
                    <hr/>
                </div>
                <div class="col-sm-6 col-md-4 col-xs-12 p-l-5 p-r-5 m-b-5" ng-repeat="depositAccount in dwModalCtrl.depositAccounts">
                    <div class="dw-account-card" ng-click="depositAccount.isFavorite = !depositAccount.isFavorite; dwModalCtrl.markAccountAsFavorite(depositAccount);">
                        <div class="col-xs-11 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0  no-wrap-text">{{depositAccount.friendlyName}}</h4>
                            <h6 class="m-t-0 m-b-0  no-wrap-text fw-cm">{{depositAccount.iban}} {{depositAccount.currencySymbol}}</h6>
                        </div>
                        <div class="col-xs-1 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0"><span><i class="zmdi" style="padding:6px" ng-class="{'zmdi-star favorite-account widget':depositAccount.isFavorite,'zmdi-star-outline':!depositAccount.isFavorite}"></i></span></h4></div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12" ng-if="dwModalCtrl.loanAccounts.length !== 0">
            <div class="widgetDiv">
              <img src="img/hypo/addWidget/loan_account_h200.png" class="widgetIcon" style="width:50px; height:50px;">
              <h5 class="c-white f-18 widgetHeader" translate="dashboardWidgets.loan"></h5>
              <hr/>
            </div>
            <div class="col-sm-6 col-md-4 col-xs-12 p-l-5 p-r-5 m-b-5" ng-repeat="loanAccount in dwModalCtrl.loanAccounts">
              <div class="dw-account-card" ng-click="loanAccount.isFavorite = !loanAccount.isFavorite; dwModalCtrl.markAccountAsFavorite(loanAccount);">
                <div class="col-xs-11 p-l-0 p-r-0">
                  <h4 class="m-t-0 m-b-0  no-wrap-text">{{loanAccount.friendlyName}}</h4>
                  <h6 class="m-t-0 m-b-0  no-wrap-text fw-cm">
                    {{loanAccount.iban}}
                    <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                      <span ng-switch-default>{{loanAccount.currencySymbol}}</span>
                      <span ng-switch-when="sl">{{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}</span>
                    </span>
                  </h6>
                </div>
                <div class="col-xs-1 p-l-0 p-r-0">
                  <h4 class="m-t-0 m-b-0"><span><i class="zmdi" style="padding:6px" ng-class="{'zmdi-star favorite-account widget':loanAccount.isFavorite,'zmdi-star-outline':!loanAccount.isFavorite}"></i></span></h4></div>

              </div>
            </div>
          </div>
        </div>

        <div class="row">
            <div class="col-sm-12" ng-if="dwModalCtrl.giroAccounts.length !== 0">
                <div class="widgetDiv">
                    <img src="img/hypo/addWidget/giro_account_h200.png" class="widgetIcon" style="width:50px; height:50px;">
                    <h5 class="c-white f-18 widgetHeader" translate="dashboardWidgets.giro"></h5>
                    <hr/>
                </div>
                <div class="col-sm-6 col-md-4 col-xs-12 p-l-5 p-r-5 m-b-5" ng-repeat="giroAccount in dwModalCtrl.giroAccounts">
                    <div class="dw-account-card" ng-click="giroAccount.isFavorite = !giroAccount.isFavorite; dwModalCtrl.markAccountAsFavorite(giroAccount);">
                        <div class="col-xs-11 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0  no-wrap-text">{{giroAccount.friendlyName}}</h4>
                            <h6 class="m-t-0 m-b-0  no-wrap-text fw-cm">{{giroAccount.iban}}        {{giroAccount.currencySymbol}}</h6>
                        </div>
                        <div class="col-xs-1 p-l-0 p-r-0">
                            <h4 class="m-t-0 m-b-0"><span><i class="zmdi" style="padding:6px" ng-class="{'zmdi-star favorite-account widget':giroAccount.isFavorite,'zmdi-star-outline':!giroAccount.isFavorite}"></i></span></h4></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-xs-12 col-sm-4 col-sm-offset-8">
            <button class="btn btn-primary-red app-btn-blue w-100 f-uppercase" ng-click="dwModalCtrl.closeDashboardWidgetModal(true);" translate="dashboardWidgets.addBtn"
            ng-disabled="dwModalCtrl.markAsFavoriteInProgress"></button>

        </div>
    </div>
</div>
