<div class="container-fluid hidden-xs">
  <div class="row footer-row">
<div class="row">
  <div class="col-md-3 hidden-xs hidden-sm">
    <span class="footer-app-name"><span ><img data-ng-src="img/logo/AB_Logo.svg" style="width: 90%;" alt="Addiko Bank"></span></span><br>
  </div>
  <div class="footer-item col-sm-4 col-md-3 p-b-15" ng-if="footer.countryCode === 'hr'">
    <div class="pull-left">
      <i class="icon icon-chat footer-icon"></i>
    </div>
    <div class="pull-left">
      <p class="footer-item-text pointer" translate="footer.notificationDepth" ng-click="footer.openModal()"></p>
    </div>
  </div>

  <div ng-if="footer.countryCode === 'mne'" class="footer-item col-sm-4 col-md-3">
    <div class="pull-left">

    </div>
    <div class="pull-left">

    </div>
  </div>

  <div class="footer-item col-sm-4 col-md-3">
    <div class="pull-left">
      <i class="icon icon-freecall footer-icon"></i>
    </div>
    <div class="pull-left">
      <p ng-if="footer.countryCode != 'bh'" class="footer-item-text" translate="footer.freeCall"></p>
      <p ng-if="footer.countryCode === 'bh'" class="footer-item-text" translate="footer.freeCallBh"></p>
      <p class="footer-item-number">{{::footer.configData.LocalCall}}</p>
    </div>
  </div>
  <div ng-if="footer.countryCode === 'hr'" class="footer-item col-sm-4 col-md-3">
    <div class="pull-left">
      <i class="icon icon-contact footer-icon"></i>
    </div>
    <div  class="pull-left">
      <p class="footer-item-text" translate="footer.internationalCall"></p>
      <p class="footer-item-number">{{::footer.configData.InternationalCall}}</p>
    </div>
  </div>
  <div class="footer-item col-sm-4 col-md-3">
    <div class="pull-left">
      <i class="icon icon-message footer-icon"></i>
    </div>
    <div class="pull-left">
      <p class="footer-item-text" translate="footer.email"></p>
      <p class="footer-item-number" ng-click="footer.pushGtmEmailEvent();"><a href="mailto:{{::footer.configData.infoEmail}}">{{::footer.configData.infoEmail}}</a></p>
    </div>
  </div>
  <div class="footer-item col-sm-8 col-md-6" ng-if="footer.countryCode !== 'hr'"></div>
  <div class="footer-item col-sm-8 col-md-9" ng-if="footer.countryCode === 'hr'"></div>

</div>

    <div class="row">
      <div class="footer-item col-xs-6 pull-right m-r-30">
        <span ng-if="footer.appVersion" class="footer-item-number pull-right f-13 p-l-30"><span ng-if="APP_GLOBAL_SETTINGS.defaultData.IsTest">TEST </span><span translate="footer.version">Version:</span> {{footer.appVersion}}<span>.</span>{{footer.serverVersion}}</span>

      </div>
    </div>
  </div>
</div>


<div class="clearfix visible-xs footer-container">
  <div class="col-xs-12 footer-item-wrapper" ng-if="footer.countryCode === 'hr'">
    <div class="pull-left col-xs-2">
      <i class="icon icon-chat footer-icon"></i>
    </div>
    <div class="pull-left col-xs-10">
      <p class="footer-item-text pointer" translate="footer.notificationDepth" ng-click="footer.openModal()"></p>
    </div>
  </div>

  <div class="col-xs-12 footer-item-wrapper">
    <div class="pull-left col-xs-2">
      <i class="icon icon-freecall footer-icon"></i>
    </div>
    <div class="pull-left col-xs-10">
      <p ng-if="footer.countryCode != 'bh'" class="footer-item-text" translate="footer.freeCall"></p>
      <p ng-if="footer.countryCode === 'bh'" class="footer-item-text" translate="footer.freeCallBh"></p>
      <p class="footer-item-number">{{::footer.configData.LocalCall}}</p>
    </div>
  </div>
  <div ng-if="footer.countryCode === 'hr'" class="col-xs-12 footer-item-wrapper">
    <div class="pull-left col-xs-2">
      <i class="icon icon-contact footer-icon"></i>
    </div>
    <div  class="pull-left col-xs-10">
      <p class="footer-item-text" translate="footer.internationalCall"></p>
      <p class="footer-item-number">{{::footer.configData.InternationalCall}}</p>
    </div>
  </div>
  <div class="col-xs-12 footer-item-wrapper">
    <div class="pull-left col-xs-2">
      <i class="icon icon-message footer-icon"></i>
    </div>
    <div class="pull-left col-xs-10">
      <p class="footer-item-text" translate="footer.email"></p>
      <p class="footer-item-number"><a href="mailto:{{::footer.configData.infoEmail}}">{{::footer.configData.infoEmail}}</a></p>
    </div>
  </div>
  <div class="col-xs-12 footer-item-wrapper">
    <div class="pull-left col-xs-2">
    </div>
    <div class="pull-left col-xs-10">
      <p class="footer-item-text" ng-if="APP_GLOBAL_SETTINGS.defaultData.IsTest">TEST</p>
      <p class="footer-item-text" translate="footer.version"></p>
      <p class="footer-item-number">{{footer.appVersion}}</p>
      <p class="footer-item-text" translate="footer.server"></p>
      <p class="footer-item-number">{{footer.serverVersion}}</p>
    </div>
  </div>
</div>

<div ng-if="footer.loaded && footer.countryCode !== 'sl'" class="hidden">

  <iframe #addikoWidgetIframe ng-src="{{footer.safeiFrameUrL}}" frameborder="0">
  </iframe>
</div>
