<section id="main">
    <div class="container-fluid p-b-20">

        <div class="exchange-rate-header" ng-show="loanPayment.currentStep != 3">
            <div class="row">
                <div class="col-sm-12 title-style" translate="loanPayment.loanPayment"></div>
            </div>
        </div>

        <div class="internal-transfer-body bg-white p-b-0" ng-if="loanPayment.currentStep == 1">
            <form name="loanPayment.loanPaymentForm" novalidate>
                <div class="exchange-rates-body m-b-10 p-b-10">
                    <div class="row m-b-30">
                        <div class="col-sm-6">
                            <!-- CUSTOM FROM Accounts dropdown-->
                            <label class=" f-18" translate="payments.fromAccount"></label>
                            <div class="accounts-dropdown">
                                <!--<div class="debit-account-widget dashboard-widget-item account-drop-height" data-ng-click="loanPayment.toggleFromAccount();">
                                    <h4 class="no-wrap-text bold-font">
                                      {{loanPayment.tempLoanObj.fromAccountSelect.accountDescription}}
                                    <span class="favorite-star-container">
                                    <i class="zmdi "
                                       ng-class="{'zmdi-star favorite-account':loanPayment.tempLoanObj.fromAccountSelect.isFavorite,
                                       'zmdi-star-outline':!loanPayment.tempLoanObj.fromAccountSelect.isFavorite}"></i>
                                    </span>
                                    </h4>
                                                        <p class="no-bottom-margin">{{loanPayment.tempLoanObj.fromAccountSelect.iban}}</p>
                                                        <p class="m-b-0 bold-font f-18">
                                                            {{loanPayment.tempLoanObj.fromAccountSelect.availableBalance | number:2}} {{loanPayment.tempLoanObj.fromAccountSelect.currencySymbol}}
                                                        </p>
                                                        <span class="d-block m-b-9" translate="currencyConversion.availableBalance"></span>
                                                        <span class="caret-icon">
                                    <i class="zmdi zmdi-caret-down zmdi-hc-2x"></i>
                                  </span>
                                </div>-->

                                <div class="debit-account-widget dashboard-widget-item" data-ng-click="loanPayment.toggleFromAccount();"  ng-style="loanPayment.tempLoanObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + loanPayment.tempLoanObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                    <h4 class="no-wrap-text bold-font m-b-0">
                                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{loanPayment.tempLoanObj.fromAccountSelect.friendlyName}}</b>
                                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':loanPayment.tempLoanObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!loanPayment.tempLoanObj.fromAccountSelect.isFavorite}"></i></span>
                                    </h4>
                                    <p class="m-b-0 m-t-0 iban-style">{{loanPayment.tempLoanObj.fromAccountSelect.iban | formatIban}}</p>
                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                        {{loanPayment.tempLoanObj.fromAccountSelect.availableBalance | number:2}} {{loanPayment.tempLoanObj.fromAccountSelect.currencySymbol}}
                                    </p>
                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                    <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                                </div>

                                <!--<div class="accounts-list m-l-15 m-r-15" ng-show="loanPayment.fromAccountDropdown" scrollable>
                                    <div class="accounts-list-item" data-ng-repeat="item in loanPayment.accountsFrom" data-ng-click="loanPayment.setAccountFrom(item);">
                                        <span class="d-block f-16 bold-font">{{item.accountDescription}}</span>
                                        <span class="d-block">{{item.iban}}</span>
                                        <span class="d-block bold-font f-18">{{item.availableBalance | number:2}} {{item.currencySymbol}}</span>
                                        <span class="d-block" translate="currencyConversion.availableBalance"></span>
                                    </div>
                                </div>-->

                                <div class="from-accounts-list m-l-15" ng-show="loanPayment.fromAccountDropdown" scrollable>
                                    <div class="accounts-list-item p-0" data-ng-repeat="item in loanPayment.accountsFrom" data-ng-click="loanPayment.setAccountFrom(item);">

                                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                            <h4 class="no-wrap-text bold-font m-b-0">
                                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                            </h4>
                                            <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                            </p>
                                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label class=" f-18" translate="internalTransfer.amount"></label>
                            <div class="input-group">
                              <input type="text" class="form-control input-field t-a-r"
                                     placeholder="{{'eInvoicesModal.amountPlaceholder' | translate}}"
                                     amount-input-mask
                                     max-amount="{{loanPayment.maxAmount}}"
                                     maxlength="{{loanPayment.maxLength}}"
                                     data-ng-model="loanPayment.tempLoanObj.amountInput"
                                     ng-class="{'domp-error': loanPayment.loanPaymentForm.amountInput.$invalid && loanPayment.tempLoanObj.formSubmitted}" required>
                                <span class="input-group-btn">
            <button type="button" class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;" disabled>
              {{loanPayment.tempLoanObj.fromAccountSelect.currencySymbol}}
            </button>
            </span>
                            </div>

                            <span class="c-red" ng-show="loanPayment.loanPaymentForm.amountInput.$error.required && loanPayment.tempLoanObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                            <span class="c-red" ng-show="loanPayment.loanPaymentForm.amountInput.$error.maxlength && loanPayment.tempLoanObj.formSubmitted" translate="internalTransfer.amountLengthError"></span>

                          <label class="p-t-15 f-18" translate="internalTransfer.dueDate"></label>
                            <div class="input-group col-xs-12">

                                <input uib-datepicker-popup="shortDate" ng-if="loanPayment.countryCode != 'sl'" type="text" class="form-control input-field" name="dueDateInput" data-ng-model="loanPayment.tempLoanObj.dueDateInput" readonly>
                                <input ng-if="loanPayment.countryCode === 'sl'" type="text" class="form-control input-field" name="dueDateInput"
                                       uib-datepicker-popup="shortDate" data-ng-model="loanPayment.tempLoanObj.dueDateInput"
                                       show-weeks="false" is-open="loanPayment.datePickerOpened"
                                       datepicker-options="loanPayment.dateOptions"
                                       current-text="{{'core.period.today' | translate}}"
                                       clear-text="{{'core.datePicker.clearBtn' | translate}}"
                                       close-text="{{'core.datePicker.doneBtn' | translate}}"
                                       ng-class="{'domp-error': loanPayment.loanPaymentForm.dueDateInput.$invalid && loanPayment.tempLoanObj.formSubmitted}"
                                       ng-readonly="true"
                                       required>

                                <span ng-if="loanPayment.countryCode === 'sl'" class="input-group-btn">
                                  <button data-ng-click="loanPayment.openDatePicker()" type="button"
                                          class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;"><i
                                    class="icon icon-calendar" style="font-size: 21px;" ></i>
                                  </button>
                                </span>
                            </div>

                        </div>

                    </div>
                </div>

              <div class="exchange-rates-body m-b-10 p-b-10">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 ">
                    <div class="row">
                      <div class="col-xs-12">
                        <label class=" f-18" translate="payments.toAccount"></label>
                      </div>
                    </div>
                    <div class="row p-b-20">
                      <div class="col-xs-12">
                      <input type="text" class="form-control input-field"
                             data-ng-model="loanPayment.tempLoanObj.toAccountInput" disabled>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-6">
                    <div class="row">
                      <div class="col-xs-12">
                        <label class=" f-18" translate="loanPayment.recieverNumber"></label>
                      </div>
                    </div>
                    <div class="row p-b-15">
                      <div class="col-xs-6 col-sm-2 p-r-5 p-b-5">
                        <input type="text" data-ng-model="loanPayment.tempLoanObj.receiverNumberCountryCode"
                               class="form-control input-field t-a-c" readonly>
                      </div>
                      <div class="col-xs-6 col-sm-2 p-l-5 p-r-5 p-b-5 hidden-xs">
                        <input type="text" data-ng-model="loanPayment.tempLoanObj.receiverNumber1"
                               class="form-control input-field t-a-c" readonly>
                      </div>
                      <div class="col-xs-6 col-sm-2 p-l-5 p-b-5 visible-xs">
                        <input type="text" data-ng-model="loanPayment.tempLoanObj.receiverNumber1"
                               class="form-control input-field t-a-c" readonly>
                      </div>
                      <div class="col-xs-12 col-sm-8 p-b-5">
                        <input type="text" data-ng-model="loanPayment.tempLoanObj.receiverNumber2"
                               class="form-control input-field" readonly>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row m-b-10" >
                  <div class="col-sm-6">
                    <label class=" f-18" translate="internationalPayment.paymentPurpose"></label>
                    <input type="text" class="form-control input-field" name="paymentPurposeInput"
                           data-ng-model="loanPayment.tempLoanObj.paymentPurposeInput"
                           ng-class="{'domp-error': loanPayment.loanPaymentForm.paymentPurposeInput.$invalid && loanPayment.tempLoanObj.formSubmitted}"
                           required>
                    <span class="c-red"
                          ng-show="loanPayment.loanPaymentForm.paymentPurposeInput.$error.required && loanPayment.tempLoanObj.formSubmitted"
                          translate="internalTransfer.paymentPurposeInputError">
                    </span>
                  </div>
                </div>
              </div>



            </form>
        </div>
        <div class="internal-transfer-body p-b-0" ng-if="loanPayment.currentStep == 1">
          <div class="row p-b-10">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary" translate="payments.cancel"
                      data-ui-sref="loansOverview">
              </button>
            </div>
            <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="col-xs-6 t-a-r">
              <button ng-switch-default class="btn btn-default domp-review-and-submit-btn" translate="currencyConversion.confirm" data-ng-click="loanPayment.submitLoanPayment();">
              </button>
              <button ng-switch-when="sl" class="btn btn-default domp-review-and-submit-btn" ng-disabled="(loanPayment.nextButtonPressed || loanPayment.secondValidateInProgress) || !loanPayment.tempLoanObj.amountInput" translate="domesticPayment.confirmBtnSlo" data-ng-click="loanPayment.submitLoanPayment();">
              </button>
            </div>
          </div>
        </div>

        <!-- Internal transfer STEP 2 -->
        <div ng-if="loanPayment.currentStep == 2">
            <div data-ng-include="'payments/loan-payment-resume.tpl.html'">
            </div>
        </div>

        <!-- Internal transfer STEP 3 -->
        <div ng-if="loanPayment.currentStep == 3">
            <div data-ng-include="'payments/internal-transfer-final.tpl.html'">
            </div>
        </div>
    </div>
</section>
