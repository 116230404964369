<section id="main">
    <div class="container-fluid">
        <!-- START Title -->
        <div class="row">
            <div class="col-md-12" style="z-index:1;">
                <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
                    <h2 class="title-style" translate="changeWithdrawal.header">Change overdraft limit</h2>
                </div>
            </div>
        </div>
        <!-- END Title -->

        <!-- START Note -->
        <div ng-if="changeWithdrawal.step == 1" class="row">
            <div class="col-md-12">
                <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
                    <span translate="changeWithdrawal.infoNote1"></span>
                    <a href="https://www.addiko.si/ceniki" target="blank" class="underline">{{ 'changeWithdrawal.infoNote3' | translate}}</a></p>
                </div>
            </div>
        </div>
        <!-- END Note -->

        <div ng-if="changeWithdrawal.step == 1" class="p-b-30">
            <ng-form name="changeWithdrawal.changeWithdrawalForm">
                <div class="cash-withdrawing-section-frame">
                    <div class="row">
                        <div class="col-md-6">

                            <label class="domp-form-label" translate="changeWithdrawal.fromAccount">Form account</label>
                            <!-- START Dropdown account -->
                            <div class="accounts-dropdown">
                                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="changeWithdrawal.showAccountToDropdown = !changeWithdrawal.showAccountToDropdown" ng-style="changeWithdrawal.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + changeWithdrawal.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                    <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                      {{changeWithdrawal.selectedAccount.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                </span>
                  </h4>
                                    <p class="m-b-0 m-t-0 iban-style">{{changeWithdrawal.selectedAccount.iban}}</p>
                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                        {{changeWithdrawal.selectedAccount.availableBalance | number:2}} {{changeWithdrawal.selectedAccount.currencySymbol}}
                                    </p>
                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>

                                </div>
                            </div>
                            <!-- END Dropdown account -->

                        </div>
                        <div class="col-md-6">

                            <div class="col-xs-12 p-b-95 hidden-xs">

                            </div>

                            <!-- START Amount -->
                            <div class="col-xs-12 p-0">
                                <div class="form-group">
                                    <label class="domp-form-label" translate="changeWithdrawal.amount">Amount</label>
                                    <div class="input-group">
                                        <input id="amount" name="amount" type="text" class="form-control input-field t-a-r" amount-input-mask placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" ng-model="changeWithdrawal.tempObj.amount" ng-class="{'domp-error': changeWithdrawal.changeWithdrawalForm.amount.$invalid && changeWithdrawal.formSubmitted}" maxlength="17" autocomplete="off" required>
                                        <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                        style="border:1px solid #062a42;">
                  {{changeWithdrawal.currencySymbol}}
                </button>
              </span>
                                    </div>
                                    <label class="c-red" ng-show="changeWithdrawal.changeWithdrawalForm.amount.$error.required && changeWithdrawal.formSubmitted" translate="changeWithdrawal.amountRequired">
                                    </label>
                                </div>
                            </div>
                            <!-- END Amount-->

                        </div>

                    </div>


                    <div class="row p-t-30">

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="domp-form-label" translate="changeWithdrawal.time">
                                    Time...
                                </label>
                                <select name="branch" class="form-control input-field p-l-15 p-r-10"
                                        ng-options="option.description for option in changeWithdrawal.timeLimits"
                                        ng-model="changeWithdrawal.tempObj.timeLimit" required>
                                </select>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="domp-form-label" translate="changeWithdrawal.spremembo">Spremembo</label>
                                <select name="branch" class="form-control input-field p-l-15 p-r-10"
                                        ng-options="option.description for option in changeWithdrawal.typeOptions "
                                        ng-model="changeWithdrawal.tempObj.typeOption" required>
                                </select>

                            </div>
                        </div>

                      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12">
                        <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                          <input type="checkbox" ng-model="changeWithdrawal.iAmAware">
                          <i class="input-helper"></i>
                          {{'cashWithdrawalTranslate.info' | translate}}
                        </label>
                      </div>

                    </div>


                    <div class="row p-b-20 p-t-20">
                        <div class="col-md-12">
                            <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left" ng-click="changeWithdrawal.goBackToAccount()">
                                <span translate="payments.cancel">Cancel</span>
                            </button>
                            <button class="btn btn-default  pull-right"
                                    ng-click="changeWithdrawal.validateCashWithdrawing();"
                                    ng-disabled="changeWithdrawal.nextButtonPressed || (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !changeWithdrawal.iAmAware)">
                                <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-form>
        </div>

        <div ng-if="changeWithdrawal.step == 2">
          <div class="domp-to-section m-10">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label-review" translate="changeWithdrawal.fromAccount"></label>
                  <label class="d-block domp-form-label-resume">{{changeWithdrawal.insertedOrderObject.account}}</label>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label-review" translate="changeWithdrawal.amount">Amount</label>
                  <label class="d-block domp-form-label-resume">
                    {{changeWithdrawal.insertedOrderObject.amount | number:2 }}
                    {{changeWithdrawal.selectedAccount.currencySymbol}}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label class="domp-form-label-review" translate="changeWithdrawal.time"></label>
                <label class="d-block domp-form-label-resume">{{changeWithdrawal.tempObj.timeLimit.description}}</label>
              </div>
              <div class="col-xs-12 col-sm-6">
                <label class="domp-form-label-review" translate="changeWithdrawal.spremembo"></label>
                <label class="d-block domp-form-label-resume">{{changeWithdrawal.tempObj.typeOption.description}}</label>
              </div>
            </div>
          </div>

          <div class="col-xs-12">
            <button class="btn btn-primary " translate="domesticPayment.back"
                    data-ng-click="changeWithdrawal.editCashWithdrawing();">
            </button>
            <button class="btn btn-default pull-right" data-ng-click="changeWithdrawal.confirmCashWithdrawing()" ng-disabled="changeWithdrawal.confirmButtonPressed">
              <span translate="domesticPayment.confirmBtn"></span>
            </button>
          </div>
        </div>

    </div>
</section>
