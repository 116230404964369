(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name sepa.controller:SepaNewCtrl
     *
     * @description
     *
     */
    angular
        .module('sepa')
        .controller('SepaNewCtrl', SepaNewCtrl);

    function SepaNewCtrl($rootScope, $scope, $state, HelperService, sepaSettingTypes, currentAccounts, AlertService, sepaSetting, SepaService, PaymentTypesService, NotificationService) {
        var vm = this;

        var isEdit = false;
        var sepaSettingId;
        vm.steps = {};
        vm.steps.step1 = true;

        vm.sepaTempObj = {
          formSubmitted: false
        };

        init();

        function init() {
            vm.model = {};
            vm.accountsFrom = [];

            vm.settingType = sepaSettingTypes.result;
            // vm.settingType.shift();
          if (vm.settingType.length) {
            vm.model.settingTypeSelected = vm.settingType[0];
          }
            vm.accountsFrom = currentAccounts.result;


            if (sepaSetting) {
                isEdit = true;
                sepaSettingId = sepaSetting.id;
                vm.model = initModelForEdit();
            } else {
                vm.model.fromAccountSelect = currentAccounts.result[0];
            }
        }

        var cleanLanguageChangeListener = $rootScope.$on('appLanguageChangedHeader', function (evt, data) {
          SepaService.getSepaSettingsTypes({pageSize: 1000})
            .then(function (response) {
              angular.extend(vm.settingType, response.result);
              vm.model.settingTypeSelected = _.find(response.result, {id: vm.model.settingTypeSelected.id})
            }, function (error) {
              NotificationService.showMessage(error, 'error');
            })
        });

        $scope.$on('$destroy', function() {
          cleanLanguageChangeListener();
        });

        function initModelForEdit() {
            return {
                amountInput: sepaSetting.maxAmount,
                fromAccountSelect: _.find(currentAccounts.result, {
                    accountId: sepaSetting.accountId
                }),
                settingTypeSelected: _.find(sepaSettingTypes.result, {
                    id: sepaSetting.settingType.id
                })
            }
        }

        function remapForPost() {
            return {
                maxAmount: vm.model.amountInput,
                accountId: vm.model.fromAccountSelect.accountId,
                settingType: vm.model.settingTypeSelected
            }
        }

        function addSepaSetting(data) {

            data.activated = true;
            SepaService.addSepaSetting(data)
                .then(function (response) {
                    AlertService.infoAlert({
                            text: "sepa.sepaSuccessCreate"
                        })
                        .then(function () {
                            $state.go("sepa.settings");
                        })
                }, function (error) {
                    vm.okButtonDisabled = false;
                  vm.sepaTempObj.formSubmitted = false;

                  NotificationService.showMessage(error, 'error');
                });
        }

        function updateSepaSetting(data) {
            data.activated = true;
            SepaService.updateSepaSetting(sepaSettingId, data)
                .then(function (response) {
                    AlertService.infoAlert({
                            text: "sepa.sepaSuccessCreate"
                        })
                        .then(function () {
                            $state.go("sepa.settings");
                        })
                }, function (error) {
                    vm.okButtonDisabled = false;
                    vm.sepaTempObj.formSubmitted = false;
                    NotificationService.showMessage(error, 'error');
                });
        }

        vm.setAccountFrom = function (item) {
            vm.model.fromAccountSelect = item;
            vm.fromAccountDropdown = false;
        };

        vm.onSettingTypeChange = function () {
          if(vm.model.settingTypeSelected.id == '4') {
            vm.model.amountInput = "";
          }
        };

        vm.confirm = function (sepaForm) {
            vm.sepaTempObj.formSubmitted = true;
            if (!vm.sepaForm.$valid || vm.model.settingTypeSelected.id == '0') {
                return;
            }
            vm.steps = {};
            vm.steps.step2 = true;
        };

        vm.ok = function () {

            vm.okButtonDisabled = true;
            var sepaSetting = remapForPost();
            if (isEdit) {
                updateSepaSetting(sepaSetting);
            } else {
                addSepaSetting(sepaSetting);
            }
        };

        vm.cancel = function () {
          if (vm.cancelInProgress) return;
          vm.cancelInProgress = true;
          $state.go("sepa.settings")
            .finally(function () {
              vm.cancelInProgress = false;
            })
        }

    }
}());
