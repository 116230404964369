<div class="statementModal alert-msg-frame">
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div style="color: white; padding-top: 43px;">
          <!--  <i class="icon icon-add pull-right close-icon-x" ng-click="$close(false)"  style="font-size: 40px;"></i> -->
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-2 col-sm-3 col-xs-2">
        <i class="icon alert-icon icon-warning alert-icon-f"></i>
      </div>
      <div class="col-md-10 col-sm-9 col-xs-10">
        <span ng-if="vm.data.text" class="logout-modal-header-2">{{vm.data.text | translate}}</span>
        <span ng-if="!vm.data.text" class="c-white alert-msg" translate="alert.areYouSure"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close(true)"
            translate="alert.yes">Yes
    </button>
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close(false)"
            translate="alert.no">No
    </button>
  </div>
</div>
