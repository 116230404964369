(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accounts.factory:ESavingBookRequestsService
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .factory('ESavingBookRequestsService', ESavingBookRequestsService);

  function ESavingBookRequestsService($q, $translate, EndpointsService, $http, SessionService, HelperService) {
    var ESavingBookRequestsServiceBase = {};

    function insert(eBook) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          SessionService.saveStorageObject(eBook, 'savingBook');
          $http.post(response.links.eSavingBookRequests, eBook)
            .then(function (data) {
              SessionService.removeStorageObject();
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    function getFileName(documentType) {
      if (documentType === 'PreContractForm') {
        return $translate.instant('ebook.doc2');
      } else if (documentType === 'DocumentRequest') {
        return $translate.instant('ebook.doc1');
      } else if (documentType === 'PreContractInfo') {
        return $translate.instant('ebook.doc3');
      } else {
        return 'file';
      }
    }

    ESavingBookRequestsServiceBase.insertEbook = function (eBook) {
      return insert(eBook);
    };

    ESavingBookRequestsServiceBase.getESavingBookRequests = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.eSavingBookRequests, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    ESavingBookRequestsServiceBase.getESavingBookRequests_downloadByFilter = function (id, documentType, accountId) {
      var deferred = $q.defer();
      var documentName = getFileName(documentType);
      EndpointsService.getLinks().then(function (response) {
          var tempLink = response.links['eSavingBookRequests_downloadByFilter?AccountId={AccountId}&Id={Id}&DocumentType={DocumentType}'].replace('{Id}', id || '').replace('{DocumentType}', documentType || '').replace('{AccountId}', accountId || '');
          HelperService.manageDownload(tempLink, documentName, '');
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    ESavingBookRequestsServiceBase.signESavingBookSign = function (id, challengeResponse, challenge2) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.eSavingBookRequests_sign, {
            id: id,
            signData: challengeResponse,
            additionalSignData: challenge2
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    ESavingBookRequestsServiceBase.deleteESavingBookRequest = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.delete(response.links.eSavingBookRequests_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    ESavingBookRequestsServiceBase.updateESavingBookRequest = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.eSavingBookRequests_id.replace('{id}', id), params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    ESavingBookRequestsServiceBase.signESavingBookRequest = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.eSavingBookRequests_sign, {id: id})
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };



    return ESavingBookRequestsServiceBase;
  }
}());
