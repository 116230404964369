(function () {
  'use strict';

  angular
    .module('statements')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('statements', {
        url: '/statements',
        templateUrl: 'statements/statements.tpl.html',
        controller: 'StatementsCtrl',
        controllerAs: 'statements'
      });
  }
}());
