<div class="container qp-modal-container p-20">
    <div class="row qp-modal-header m-b-30">
        <div class="col-sm-12">
            <h1>Quick payment or transfer<span class="pull-right zmdi zmdi-close qp-close-btn" ng-click="qpModalCtrl.closeQPModal()"></span></h1>
        </div>
    </div>
    <div class="row qp-modal-body">
        <div class="qpmb-from-section col-sm-6">
            <form role="form">
                <div class="form-group">
                    <label class="qpm-form--label" for="">From:</label>
                    <div class="fg-line">
                        <select class="form-control no-bottom-radius">
                            <option value="Select an Option">My current account</option>
                            <option value="Option 1">Option 1</option>
                        </select>
                        <p class="available-info-wrapper">available <span class="pull-right">789654</span></p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="qpm-form--label" for="qpAmount">How much?</label>
                    <div class="input-group">
                        <input type="text" class="form-control" amount-input-mask placeholder="" maxlength="12" autocomplete="off" style="text-align: right;">
                        <div class="input-group-addon qp-amount">HRK</div>
                    </div>

                </div>
            </form>
        </div>
        <div class="qpmb-to-section col-sm-6">
            <form role="form">
                <div class="form-group">
                    <label class="qpm-form--label" for="">To:</label>
                    <div class="fg-line">
                        <div ng-show="qpModalCtrl.toAccountType==1">
                            <input type="text" class="form-control">
                        </div>
                        <select ng-show="qpModalCtrl.toAccountType==2" class="form-control no-bottom-radius">
                            <option value="Select an Option">My current account</option>
                            <option value="Option 1">Option 1</option>
                        </select>
                        <div class="btn-group btn-group-justified" role="group" aria-label="...">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-default qpm-btn-group" ng-class="{'not-selected':qpModalCtrl.toAccountType==2}" ng-click="qpModalCtrl.toAccountType=1">Other account</button>
                            </div>
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-default qpm-btn-group" ng-class="{'not-selected':qpModalCtrl.toAccountType==1}" ng-click="qpModalCtrl.toAccountType=2">My account</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="qpm-form--label" for="qpAmount">What for?</label>
                    <div class="">
                        <input type="text" class="form-control">
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="row qp-modal-footer m-t-20">
        <div class="col-sm-9">
            <h4 class="m-t-30">Transfer will be made today, {{qpModalCtrl.qpModalDate | date:'shortDate' : '+0200'}}
              <span class="clickable-link" type="text" ng-model="qpModalCtrl.qpModalDate"
                    uib-datepicker-popup="shortDate"
                    datepicker-options="qpModalCtrl.dateOptions"
                    current-text="{{'core.period.today' | translate}}"
                    clear-text="{{'core.datePicker.clearBtn' | translate}}"
                    close-text="{{'core.datePicker.doneBtn' | translate}}"
                    ng-required="true" close-text="Close" is-open="qpModalCtrl.datepickerPopup.opened"
                    ng-click="qpModalCtrl.openDatepicker()">(change date)</span></h4>
        </div>
        <div class="col-sm-3">
            <button type="submit" class="btn btn-primary m-t-10 app-btn-blue qpmf-btn-transfer">Transfer</button>
            <p class="m-t-10 m-b-0 t-a-c">Processing fee: 0,00 HRK</p>
        </div>
    </div>
</div>
