(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name payments.factory:PaymentTypesService
     *
     * @description
     *
     */
    angular
        .module('payments')
        .factory('PaymentTypesService', PaymentTypesService);

    function PaymentTypesService($rootScope, HelperService, EndpointsService, $q, $http, $log) {
        var PaymentTypesServiceBase = {};

        PaymentTypesServiceBase.getById = function (id) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.paymentTypes_id.replace('{id}', id))
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        PaymentTypesServiceBase.getByGroup = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.paymentTypes, {
                        params: params
                    })
                    .then(function (data) {
                        if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'){
                          if(data.result && data.result[0].debitAccountList)
                            data.result[0].debitAccountList = HelperService.sortAccounts(data.result[0].debitAccountList);
                          if(data.result && data.result[0].creditAccountList)
                            data.result[0].creditAccountList = HelperService.sortAccounts(data.result[0].creditAccountList);
                        }
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
        return PaymentTypesServiceBase;
    }
}());
