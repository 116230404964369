(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:SavingsPayInCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('SavingsPayInCtrl', SavingsPayInCtrl);

  function SavingsPayInCtrl($stateParams, $state, PaymentsService, $log, HelperService, AccountsService, PaymentTypesService,
                            PaymentTemplatesService, $timeout, $filter, CurrencyService, ExchangeRatesService,
                            PaymentFilterService, Notification, NotificationService) {
    var vm = this;
    vm.ctrlName = 'SavingsPayInCtrl';

    vm.currentStep = 1;

    vm.paymentObject = {};
    vm.tempObj = {
      fromAccountSelect: {},
      toAccountSelect: {},
      fromAccountAmountInput: '',
      toAccountAmountInput: '',
      dueDateInput: new Date(),
      formSubmitted: false,
      fromCurrencySymbol: '',
      toCurrencySymbol: {},
      purposeOfPaymentInput: '',
      currentDate: HelperService.getYesterday().firstDay
    };

    vm.paymentResponse = {};
    vm.forUpdate = false;
    vm.accountsFrom = [];
    vm.accountTo = [];
    vm.paymentFilter = {};
    vm.currencyList = [];
    vm.fromAccountDropdown = false;
    vm.toAccountDropdown = false;

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.toggleFromAccount = function () {
      if (vm.accountsFrom.length > 1) {
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
      }
    };

    vm.toggleToAccount = function () {
      if (vm.accountTo.length > 1) {
        vm.toAccountDropdown = !vm.toAccountDropdown;
      }
    };

    var setToAccountList = function (accountId) {
      vm.accountTo = _.filter(vm.accountsFrom, function (item) {
        return item.accountId != accountId;
      });
    };

    vm.setAccountFrom = function (item) {
      vm.tempObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    vm.setAccountTo = function (item) {
      vm.tempObj.toAccountSelect = item;
      vm.toAccountDropdown = false;
    };

    var setToAccount = function () {
      setToAccountList(vm.tempObj.fromAccountSelect.accountId);
      vm.tempObj.toAccountSelect = vm.accountTo[0];
    };

    /*    var loadAccountById = function (accountId) {
      AccountsService.getAccountById(accountId)
        .then(function (response) {
          vm.tempObj.toAccountSelect = response;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };*/

    vm.tmpSearchText = {
      name: ''
    };
    vm.templateName = '';

    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;
      if (vm.searchTemplateFilterExpanded === true) {
        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.getPaymentTemplatesPage = function () {
      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
        vm.templateList = response;
      })
        .catch(function (error) {
        $log.error(error);
      });
    };

    vm.toggleTemplateFilterSelection = function () {
      vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
      if (vm.searchTemplateFilterExpanded) {
        vm.getPaymentTemplatesPage();
      }
    };

    vm.selectTemplate = function (id) {
      var index = _.findIndex(vm.templateList.result, function (item) {
        return item.id == id;
      });

      // load payment form from choosen template
      var currentTemplate = vm.templateList.result[index];
      var fromAccountIndex = _.findIndex(vm.accountsFrom, function (account) {
        return account.accountId == currentTemplate.accountId;
      });

      if (fromAccountIndex != -1) {
        vm.tempObj.fromAccountSelect = vm.accountsFrom[fromAccountIndex];
        setToAccountList(vm.tempObj.fromAccountSelect.accountId);
      };

      var toAccountIndex = _.findIndex(vm.accountTo, function (account) {
        return account.iban == currentTemplate.toAccountNumber;
      });

      if (toAccountIndex != -1) {
        vm.tempObj.toAccountSelect = vm.accountTo[toAccountIndex];
      }
      vm.tempObj.fromAccountAmountInput = $filter('number')(currentTemplate.amount, 2);
      vm.templateName = currentTemplate.name;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;
    };

    var clearInput = function () {
      vm.templateName = '';
      vm.tempObj.fromAccountAmountInput = '';
      vm.tempObj.dueDateInput = new Date();
   //   vm.tempObj.purposeOfPaymentInput = '';
      vm.tempObj.toAccountAmountInput = '';
      vm.tempObj.formSubmitted = false;
      /*      vm.smsPasswordInput = '';
      vm.smsAuthentificate = false;*/
    };

    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      clearInput();
    };

    var loadCurrencies = function () {
      return CurrencyService.getCurrencyPage({
        pageSize: 1000
      })
        .then(function (response) {
        vm.currencyList = response.result;
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.loadExchangeRatesList = function () {
      ExchangeRatesService.getExchangeRates(vm.exchangeRatesParams)
        .then(function (response) {
        vm.exchangeRatesList = response;
        loadCurrencies();
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    /*    vm.showSMSAuthentification = function () {
      vm.smsAuthentificate = !vm.smsAuthentificate;
    };*/

    ////SUBMIT/////

    var createPaymentObject = function () {
      return {
        accountId: vm.tempObj.fromAccountSelect.accountId,
        //fromAccountNumber: HelperService.parseAccountNumber(vm.tempObj.fromAccountSelect.accountNumber),
        toAccountNumber: HelperService.parseAccountNumber(vm.tempObj.toAccountSelect.accountNumber),
        amount: vm.tempObj.fromAccountAmountInput,
        dueDate: vm.tempObj.dueDateInput,
        currencySymbol: vm.tempObj.fromAccountSelect.currencySymbol,
        paymentTypeGroup: 'ForeignCurrencyTransfer',
       // purpose: vm.tempObj.purposeOfPaymentInput,
        domesticPayment: {
          destinationCurrencySymbol: 'EUR',
          purposeCode: 'I',
          ModalityCode: "1"
        }
      };
    };

    var postPaymentAction = function (response) {
      vm.paymentResponse = response;
      Notification.clearAll();
      vm.currentStep = 2;
    };

    vm.savingsPayInSubmit = function () {
      vm.tempObj.formSubmitted = true;
      if (!vm.savingsPayInForm.$valid) {
        return;
      }
      if ($state.params.editable) {
        vm.paymentResponse.id = $state.params.paymentId;
        vm.forUpdate = true;
      }
      vm.temPaymentObj = createPaymentObject();
      if (vm.forUpdate) {
        PaymentsService.updatePayment(vm.paymentResponse.id, vm.temPaymentObj)
          .then(function (response) {
          postPaymentAction(response);
          vm.forUpdate = false;
        })
          .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          vm.forUpdate = false;
        });
      } else {
        PaymentsService.insertPayment(vm.temPaymentObj)
          .then(function (response) {
          postPaymentAction(response);
        })
          .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }
    };

    vm.getDebitAccountList = function () {
      return PaymentTypesService.getByGroup(vm.paymentFilter)
        .then(function (response) {
        if (_.isArray(response.result) && response.result.length > 0) {
          var creditList = response.result[0].creditAccountList;
          _.forEach(creditList, function (item) {
            if (item.accountId === $stateParams.accountId) {
              return vm.accountsFrom = response.result[0].debitAccountList;
            }
          })
          if (vm.accountsFrom.length > 0) {
            vm.tempObj.fromAccountSelect = vm.accountsFrom[0];
            _.forEach(vm.accountsFrom, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.accountDescription + ' - ' + item.iban;
              }
            });
          }
        }
      })
        .catch(function (error) {
        $log.error(error);
      });
    };

    vm.getCreditAccountList = function () {
      var tempDebitList = [];
      var tempCreditList = [];
      vm.paymentFilter.currencySymbol = vm.tempObj.fromAccountSelect.currencySymbol;
      return PaymentTypesService.getByGroup(vm.paymentFilter)
        .then(function (response) {
        switch($state.current.name) {
          case 'payments.savingsPayIn':
            vm.accountTo = response.result[0].creditAccountList;
            vm.tempObj.toAccountSelect = vm.accountTo[0];
            break;
          default:
            break;
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.getAccounts = function () {
      AccountsService.getAccountById($stateParams.accountId)
        .then(function (response) {
        switch (response.accountType.group) {
          case 'CurrentAccount':
            vm.tempObj.fromAccountSelect = response;
            vm.tempObj.fromCurrencySymbol = response.currencySymbol;
            vm.accountsFrom.push(vm.tempObj.fromAccountSelect);
            vm.paymentFilter = {
              paymentTypeGroup: 'ForeignCurrencyTransfer',
              currencySymbol: response.currencySymbol,
              masterAccountId: vm.tempObj.fromAccountSelect.accountId
            };
            vm.getCreditAccountList();
            break;
          case 'SavingAccountTypes':
            vm.tempObj.toAccountSelect = response;
            vm.tempObj.toCurrencySymbol = response.currencySymbol;
            vm.accountTo.push(vm.tempObj.toAccountSelect);

            vm.paymentFilter = {
              paymentTypeGroup: 'ForeignCurrencyTransfer',
              currencySymbol: response.currencySymbol
            };
            vm.getDebitAccountList();
            break;
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    var mapWithPaymentResponse = function () {
      vm.tempObj.fromAccountAmountInput = vm.paymentResponse.amount;
     // vm.tempObj.purposeOfPaymentInput = vm.paymentResponse.purpose;
      vm.tempObj.dueDateInput = new Date(vm.paymentResponse.dueDate);
    };

    vm.backTosavingsPayIn = function () {
      clearInput();
      vm.currentStep = 1;
    };

    vm.editPayment = function () {
      mapWithPaymentResponse();
      vm.forUpdate = true;
      vm.currentStep = 1;
    };

    vm.deletePayment = function () {
      PaymentsService.deletePayment(vm.paymentResponse.id)
        .then(function (response) {
        HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], vm.paymentResponse.id);
        $state.go('payments.paymentsOverview');
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    var getDataForAccounts = function (accountId, toAccountNumber, currencySymbol) {
      PaymentTypesService.getByGroup({paymentTypeGroup: 'ForeignCurrencyTransfer', currencySymbol: currencySymbol, masterAccountId: accountId})
        .then(function(paymentTypeResponse) {
        if(paymentTypeResponse.result.length > 0) {
          vm.accountsFrom = paymentTypeResponse.result[0].debitAccountList;
          vm.tempObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(vm.accountsFrom, accountId);
          vm.accountTo = paymentTypeResponse.result[0].creditAccountList;
          var tempAccountNumber = toAccountNumber.replace(/\D+$/g, "");
          vm.tempObj.toAccountSelect = PaymentFilterService.filterToAccountSelect(vm.accountTo, tempAccountNumber);
        }
      })
        .catch(function(error) {
        NotificationService.showMessage(error, 'error');
      })
    };

    var populateFieldsWithValues = function () {
      PaymentsService.getById($stateParams.paymentId)
        .then(function (response) {
        vm.paymentObject = response;
        vm.tempObj.fromAccountAmountInput = $filter('number')(response.amount, 2);
     //   vm.tempObj.purposeOfPaymentInput = response.purpose;
        getDataForAccounts(response.accountId, response.toAccountNumber, response.currencySymbol);
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.init = function () {
      if ($state.current.name === 'payments.savingsPayIn.fromPayment') {
        populateFieldsWithValues();
      } else {
        vm.getAccounts();
      }
      vm.loadExchangeRatesList();
    };

    vm.init();
  }
}());
