(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name viber.controller:ViberActivateCtrl
   *
   * @description
   *
   */
  angular
    .module('viber')
    .controller('ViberActivateCtrl', ViberActivateCtrl);

  function ViberActivateCtrl($rootScope, $localStorage, ViberService, HelperService, userCountry, NotificationService) {
    var vm = this;
    vm.qrCodeUrl = "";
    vm.text = "";

    if(userCountry != 'hr') {
      vm.scanQrCodeConditions = ["viber.scanQrCodeC1", "viber.scanQrCodeC2", "viber.scanQrCodeC3", "viber.scanQrCodeC4", "viber.scanQrCodeC5", "viber.scanQrCodeC6"];
      vm.activationCodeConditions = ["viber.activationCodeC1", "viber.activationCodeC2", "viber.activationCodeC3", "viber.activationCodeC4", "viber.activationCodeC5", "viber.activationCodeC6", "viber.activationCodeC7", "viber.activationCodeC8"];
    } else {
      vm.scanQrCodeConditions = ["viber.scanQrCodeC7", "viber.scanQrCodeC8"];
      vm.activationCodeConditions = ["viber.activationCodeC9", "viber.activationCodeC10"];
    }

    vm.additionalActivationConditions = ["viber.additionalActivation1", "viber.additionalActivation2", "viber.additionalActivation3"];

    vm.currentLanguage = $localStorage.appLanguage;
    $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
      vm.currentLanguage = lang.langCode;
    });

    function init () {
      console.log("Viber activate init controller...");
      ViberService.activate({
        phoneNumber: "string"
      })
        .then(function (response) {
          $rootScope.APP_GLOBAL_SETTINGS.isViberActivated = true;
          vm.qrCodeUrl = response.qrCode;
          vm.text = response.text;
        })
        .catch(function (err){
          NotificationService.showMessage(err, 'error');
        });
    };

    init();
  }
}());
