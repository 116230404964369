(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentComplaintCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentReclamationCtrl', PaymentReclamationCtrl);

  function PaymentReclamationCtrl($state, payment, PaymentsService, HelperService, ValidationService, AlertService, NotificationService) {
    var vm = this;
    vm.model = payment;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    vm.input = {
      comment: ""
    };

    vm.confirm = function () {
      vm.confirmButtonDisabled = true;

      var data = {
        id: payment.id,
        note: vm.input.comment
      };

      PaymentsService.paymentsAppeal(data)
        .then(function (response) {
          console.log(response);

          AlertService.infoAlert({
            text: 'paymentsOverview.reclamationSuccess'
          })
            .then(function () {
              $state.go('payments.paymentsOverview');
            });

          vm.confirmButtonDisabled = false;
        }, function (error) {
          vm.confirmButtonDisabled = false;
          NotificationService.showTranslatedMessage('error', error);
        })
    };

    vm.cancel = function () {
      $state.go('payments.paymentsOverview');
    }

  }
}());
