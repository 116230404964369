(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name eInvoices.factory:EGeneralDocumentsService
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .factory('EGeneralDocumentsService', EGeneralDocumentsService);

  function EGeneralDocumentsService(EndpointsService,$q, $http, AlertService, HelperService) {
    var EGeneralDocumentsServiceBase = {};

    EGeneralDocumentsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eGeneralDocuments){
          $http.get(response.links.eGeneralDocuments, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    EGeneralDocumentsServiceBase.getById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eGeneralDocuments_id){
          $http.get(response.links.eGeneralDocuments_id.replace('{id}', id), {params: params})
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EGeneralDocumentsServiceBase.rejectEDocument = function (id, comment) {
      var deferred = $q.defer();
      var params = {
        acceptEGeneralDocument: false,
        rejectEGeneralDocument: true,
        userComment: comment
      };
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eGeneralDocuments_id){
          $http.put(response.links.eGeneralDocuments_id.replace('{id}', id), params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    EGeneralDocumentsServiceBase.archiveEDocument = function (id, comment) {
      var deferred = $q.defer();
      var params = {
        acceptEGeneralDocument: true,
        rejectEGeneralDocument: false,
        userComment: comment
      };
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eGeneralDocuments_id){
          $http.put(response.links.eGeneralDocuments_id.replace('{id}', id), params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    EGeneralDocumentsServiceBase.downloadDocumentPdf = function (id, childId) {
      var nonBlockingWindow = window.open('', '_self');
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eGeneralDocuments){
          var fullUrl = response.links.eGeneralDocuments + '/downloadLink/' + id + '/attachment/' + childId;
          $http.get(fullUrl)
            .then(function (data) {
              data = data.data;
              if(data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              }
              deferred.resolve();
            }).catch(function (msg, code) {
            AlertService.infoAlert({
              text: 'eInvoiceDetail.noDocAttachments'
            });
            nonBlockingWindow.close();
            deferred.reject();
          });
        } else {
          nonBlockingWindow.close();
          deferred.resolve(null);
        }
      }, function (error) {
        nonBlockingWindow.close();
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    return EGeneralDocumentsServiceBase;
  }
}());
