(function () {
  'use strict';

  angular
    .module('employeePayroll')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('employeePayroll', {
        url: '/employee-payroll',
        templateUrl: 'employee-payroll/employee-payroll.tpl.html',
        controller: 'EmployeePayrollCtrl',
        controllerAs: 'employeePayroll'
      });
  }
}());
