(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name accounts.controller:DepositAccountsOverviewCtrl
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .controller('DepositAccountsOverviewCtrl', DepositAccountsOverviewCtrl);

    function DepositAccountsOverviewCtrl($rootScope, $state, AccountsService, GoogleTagManagerHelperService, $translate) {
        var vm = this;
        var accounts;
        vm.depositAccounts = []; // object array

        init();

        function init() {
            vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
            getAllAccountsAndCards();
        }

        // region Private methods

        function getAllAccountsAndCards() {
            AccountsService.getPage({
                    pageSize: 1000,
                    page: 0,
                    accountTypeGroupList: ["DepositAccountTypes", "SavingAccountTypes"],
                    orderby: "accountType"
                })
                .then(function (response) {
                    vm.accounts = response.result;
                })
        }

        vm.goToAccount = function (accountId) {
            $state.go('accounts', {
                accountId: accountId
            });
        };

        vm.pushGtmNavEvent = function (type) {
            if (type == 'termDeposit') {
                GoogleTagManagerHelperService.pushEvent('DepositAccountsOverview', 'NewTermDeposit', $translate.instant("accountsOverview.newTermDepositButtonLabel"));
            } else {
                if (vm.countryCode == 'sl') {
                    GoogleTagManagerHelperService.pushEvent('DepositAccountsOverview', 'NewEbook', $translate.instant("accountsOverview.newSavingButtonLabel_SLO"));
                } else {
                    GoogleTagManagerHelperService.pushEvent('DepositAccountsOverview', 'NewEbook', $translate.instant("accountsOverview.newESavingAccountButtonLabel"));
                }

            }
        };
    }
}());
