(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      transactions:{
        transactionTypes:{
          credit: "Prejemki"
          ,debit: "Izdatki"
          ,all: "Vse"
        }
      },
      reclamation: {
        title: "Reklamacija",
        number: "Št. reklamacije",
        paymentReference: "Referenca plačila",
        bookingDate: "Datum knjiženja",
        valueDate: "Datum valute",
        amount: "Znesek",
        purpose: "Namen plačila",
        description: "Razlog reklamacije",
        wantComplain: "Želim reklamirati",
        confirm: "Potrdi",
        cancel: "Prekliči"
      }
    };

    $translateProvider
      .translations('sl', data);

  }
}());
