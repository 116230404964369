(function () {
  'use strict';
  angular
    .module('dashboard')
    .controller('CampaignOfferPopupCtrl', CampaignOfferPopupCtrl);

  function CampaignOfferPopupCtrl($rootScope,
                                  $http,
                                  $state,
                                  $uibModalInstance,
                                  campaignData,
                                  CampaignOfferService,
                                  HelperService,
                                  $window,
                                  $uibModal,
                                  $translate,
                                  NotificationService) {

    var vm = this;
    vm.campaignData = {};
    vm.dontShowModalAgain = false;
    vm.campaign = {};
    vm.currentLang = '';
    vm.answeredPopUpCampaignsLocal = [];
    vm.answeredPopUpCampaignsEng = [];

    init();

    function init() {
      vm.currentLang = $translate.proposedLanguage() || $translate.use();
      loadData();
    }

    function loadData() {
      vm.campaignData = campaignData;
      vm.campaignData.subjectText = vm.campaignData.subjectText.replace(/\r/g, '\n');
      vm.campaignData.subjectText = vm.campaignData.subjectText.replace(/\\n/g, '\n');
      vm.campaignData.subjectText = vm.campaignData.subjectText.replace(/\n/g, ' \n');
      vm.campaign = {
        link1: vm.campaignData.button1,
        link2: vm.campaignData.button2,
        link3: vm.campaignData.button3,
        code: vm.campaignData.campaignCode,
        id: vm.campaignData.id
      };
    }

    vm.formatText = function (text) {
      text.replace(/\r/g, '\n');
      text.replace(/\\n/g, '\n');
      text.replace(/\n/g, ' \n');
      return text;
    };
    vm.openThankYouPopup = function (buttonClicked, campaign) {
      var params = {
        buttonClicked: buttonClicked,
        text1: campaign.additionalDescription1 ? campaign.additionalDescription1 : '',
        text2: campaign.additionalDescription2 ? campaign.additionalDescription2 : '',
        text3: campaign.additionalDescription3 ? campaign.additionalDescription3 : ''
      };
      params.text1 = vm.formatText(params.text1);
      params.text2 = vm.formatText(params.text2);
      params.text3 = vm.formatText(params.text3);
      return $uibModal.open({
        templateUrl: 'dashboard/thank-you-popup.tpl.html',
        size: 'md',
        controller: 'ThankYouPopupCtrl',
        controllerAs: 'thankYouPopup',
        resolve: {
          data: function () {
            return params;
          }
        }
      });
    };

    vm.goToBannerLink = function (campaign, link, buttonClicked) {
      if (link === 'TYD') {
        vm.openThankYouPopup(buttonClicked, campaign);
      }
      if (link && link !== '' && link !== 'TYD') {
        $window.open(link, '_blank');
      }
      $uibModalInstance.close();
      CampaignOfferService.putCampaignResponse(campaign, buttonClicked).then(function () {
        if (buttonClicked === 'button2') {
          if (vm.currentLang === 'en') {
            vm.answeredPopUpCampaignsEng.push(campaign.campaignId);
            sessionStorage.setItem('answeredPopUpCampaignsEng', JSON.stringify(vm.answeredPopUpCampaignsEng));
          } else {
            vm.answeredPopUpCampaignsLocal.push(campaign.campaignId);
            sessionStorage.setItem('answeredPopUpCampaignsLocal', JSON.stringify(vm.answeredPopUpCampaignsLocal));
          }
        }
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.closeModal = function (dontShowModalAgain, campaign, link) {
      if (dontShowModalAgain) {
        $window.open(link, '_blank');
        $uibModalInstance.close();
        CampaignOfferService.putCampaignResponse(campaign, 'button3').catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      } else {
        $uibModalInstance.close();
      }
    };

  }

}());
