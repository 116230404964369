<div class="modal-header statement-confirm-header p-10">
    <div class="row">
      <div class="col-md-12">
        <button ng-if="!vm.isMobile" class="btn btn-default float-right" ng-click="$close()"><span
          translate="alert.closeModalInfo"></span></button>
        <i ng-if="vm.isMobile" class="float-right pointer zmdi zmdi-close zmdi-hc-2x" ng-click="$close()"></i>
      </div>
    </div>
</div>
<div class="modal-body text-center">
<!--  <h3 translate="accounts.title">Information</h3>-->
  <p align="left" translate="alert.eBookWarningMessageText1"></p>
  <p align="left">
    <span translate="alert.eBookWarningMessageText2"></span>
    <!--<span>{{vm.currentDate | date:'shortDate' : '+0200'}}</span>-->
  </p>
  <p align="left">
    <span translate="alert.eBookWarningMessageText31"></span>
    <span><a class="alert-link-e-book" href="https://www.addiko.hr/gradanstvo/online-druge-usluge/e-knjizica/" target="_blank" translate="alert.eBookWarningMessageText32"></a></span>
    <span translate="alert.eBookWarningMessageText33"></span>
    <span><a class="alert-link-e-book" href="https://www.addiko.hr/gradanstvo/online-druge-usluge/e-knjizica/" target="_blank" translate="alert.eBookWarningMessageText34"></a></span>
  </p>
  <p align="left" translate="alert.eBookWarningMessageText4"></p>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-4 col-sm-6 col-xs-6">
        <div class="form-group">
          <div class="wrap-text f-w-bold ng-binding" translate="signPayments.challengeFirstCode"
               style="font-weight:500;">
          </div>
          <input type="text" class="form-control input-field ng-pristine ng-untouched ng-valid ng-not-empty" disabled=""
                 data-ng-model="vm.challenge">
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-6">
        <div class="form-group">
          <div class="wrap-text f-w-bold ng-binding" translate="signPayments.challengeSecondCode"
               style="font-weight:500;">
          </div>
          <input type="text"
                 class="form-control input-field ng-pristine ng-valid ng-isolate-scope ng-empty ng-touched"
                 data-ng-model="vm.object.signData" ng-pattern-restrict="^[0-9]*$"
                 placeholder="Zaporka 2 (iz tokena)" autocomplete="off" auto-focus="">
        </div>
      </div>
      <div class="col-sm-4 col-xs-12 m-t-20 pull-right">
        <button ng-disabled="!vm.object.signData"
                ng-click="vm.eSavingBookContractWarningContractSign(true);" class="btn btn-default"><span
          translate="alert.acceptEBook"></span></button>
        <button ng-disabled="!vm.object.signData"
                ng-click="vm.eSavingBookContractWarningContractSign(false);" class="btn btn-default"><span
          translate="alert.rejectEBook"></span></button>
      </div>


    </div>
  </div>
</div>
<div  class="modal-body text-center">
  <p align="left" translate="alert.eBookWarningMessage"></p>
</div>
