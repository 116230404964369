<!-- TIMELINE CONTAINER -->
<!--
<div class="row p-15">
   <div class="col-xs-4 dropdown acc-period-select " style="display: inline-block">
    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      {{termDeposit.selectedPeriod.item.description | translate}}
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
      <li data-ng-repeat="period in termDeposit.periodList">
        <a href="" class="f-13" data-ng-click="termDeposit.paymentsPeriodFilter(period)">
          {{period.item.description | translate}}
        </a>
      </li>
    </ul>
  </div>
   <div class="col-xs-8 pull-right">
    <label class="checkbox checkbox-inline m-l-15 pull-right">
      <input type="checkbox" data-ng-model="termDeposit.allRequests" data-ng-click="termDeposit.loadAllAccountsTimeline()">
      <span class="input-helper" translate="ebook.showAllRequests">Prikaži zahtjeve za sve račune</span>
    </label>
  </div>
</div>
-->
<div class="timeline-row col-sm-12 no-padding">
  <div class="t-a-c p-10 no-data-image" ng-if="sepa.debitAccountList.length == 0">
    <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
    <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
  </div>
  <div class="hypo-timeline-wrapper app-white-card" ng-hide="sepa.debitAccountList.length == 0">
    <ul class="hypo-timeline sepa-timeline" kcd-recompile="sepa.timelineListGrouped" >
      <li ng-class="{'time-label': item.isVirtual}" ng-repeat="item in sepa.debitAccountList">
        <h4 class="timeline-separator-title bold-font" style="background: none; border: none; font-size: none" ng-if="item.isVirtual === true">{{item.date | date: 'shortDate' : '+0200'}}</h4>
        <i class="zmdi zmdi-sepa icon i-p24 icon-settings" ng-if="!item.isVirtual"></i>
        <div ng-if="!item.isVirtual" class="hypo-timeline-item payment-item"><!-- if clickable ng-click="item.selected = !item.selected;" ng-class="{'opened': item.selected}"> -->
          <div class="hypo-timeline-body">
            <div class="col-xs-7 hidden-xs">
              <h4 class="m-b-0 m-t-10">
                <b>{{item.settingType.description}}</b>
              </h4>
            </div>

            <div class="col-xs-5 hidden-xs p-r-10">
              <span class="pull-right t-a-r f-18">
                <b class="m-r-10" ng-if="item.hasOwnProperty('maxAmount')">{{item.maxAmount|number:2}} {{ sepa.model.fromAccountSelect.currencySymbol}}</b>
                  <b class="m-r-10" ng-click="sepa.openSwitchItemActiveStateModal(item);"><span style="text-decoration: underline;" ng-if="item.activated" translate="sepa.isActive"></span><span style="text-decoration: underline;" ng-if="!item.activated" translate="sepa.notActive"></span></b>
                  <button ng-disabled="item.settingType.id == 4 || item.editInProgress" class="btn btn-default domp-review-and-submit-btn " type="button" translate="sepa.edit" data-ng-click="sepa.edit(item);" >Edit</button>
              </span>
            </div>

            <div class="col-xs-6 visible-xs p-l-0">
              <h4 class="m-b-0 m-t-10 f-12">
                <b>{{item.settingType.description}}</b>
              </h4>
            </div>
            <div class="col-xs-6 visible-xs p-r-0">
              <span class="pull-right t-a-r f-12">
                <b class="m-r-10" ng-if="item.hasOwnProperty('maxAmount')">{{item.maxAmount|number:2}} {{ sepa.model.fromAccountSelect.currencySymbol}}</b>
                  <b class="m-r-10" ng-click="sepa.openSwitchItemActiveStateModal(item);"><span style="text-decoration: underline;" ng-if="item.activated" translate="sepa.isActive"></span><span style="text-decoration: underline;" ng-if="!item.activated" translate="sepa.notActive"></span></b>
                  <button ng-if="item.settingType.id != 4 || item.editInProgress" class="btn btn-default domp-review-and-submit-btn " type="button" translate="sepa.edit" data-ng-click="sepa.edit(item);" >Edit</button>
              </span>
            </div>

            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class=" col-xs-12 col-sm-7">
              <p class="m-b-0 m-t-0 iban-style-paym wrap-text">
                <span ng-if="item.receiverName">{{item.receiverName}}<br></span>
                <span ng-if="item.receiverId">{{item.receiverId}}<br></span>
                <span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>
              </p>
            </div>
            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class="col-xs-12 col-sm-4 t-a-r">
              <span ng-if="item.dateFrom" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                <span translate="sepa.dateFrom"></span>: <span>{{item.dateFrom |date: 'shortDate' : '+0200'}}</span>
              </span>
              <br>
              <span ng-if="item.dateTo" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                <span translate="sepa.dateTo"></span>: <span>{{item.dateTo | date: 'shortDate' : '+0200'}}</span>
              </span>
            </div>
            <div class="clear"></div>
          </div>
          <!-- <div ng-if="item.selected" class="transaction-details">
            <div data-ng-include="'sepa/sepa-timeline-details.tpl.html'"></div>
          </div> -->
        </div>
      </li>
    </ul>
  </div>
<!--    <div class="show-more-btn ">
      <button ng-hide="sepa.timelineListGrouped.hasMore == false" data-ng-click="sepa.loadMoreTimelineItemsGrouped(false)" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">Load older events</button>
    </div>-->
</div>


<!-- END TIMELINE -->
