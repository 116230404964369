<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="viber-ctn" style="position: relative;">

        <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" class="img-ctn" src="img/hypo/addikochat.png"/>
        <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" class="img-ctn" ng-src="img/hypo/addikochat-{{viberActivate.currentLanguage}}.png"/>

        <p  class="viber-title" translate="viber.activationWay" style="position:absolute; bottom:0;right:0;left:0;">Izaberite način aktivacije</p>

      </div>
    </div>

    <div class="row">
      <div class="col-md-12 p-0">
        <div class="msg-tab-container">
          <div class="msg-tab" translate="viber.scanQrCode">Skeniranje QR code</div>
          <div class="msg-tab" style="border-left: 1px solid white;" translate="viber.activationCode">Aktivacijski kod
          </div>
        </div>
      </div>
    </div>

    <!-- environment for countries different from CRO-->
    <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
        <div class="col-md-6">

          <div class="m-t-10 t-a-c">
            <image width="150" height="150" class="m-l-30"
                   data-ng-src="{{'data:image/bmp;base64,' + viberActivate.qrCodeUrl}}"></image>
          </div>

          <div class="p-10 f-14" data-ng-repeat="item in viberActivate.scanQrCodeConditions">
            <div class="row">
              <div class="col-sm-1 col-xs-3 viber-bullet">{{$index + 1}}</div>
              <div class="col-sm-11 col-xs-9" translate="{{item}}"></div>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <div class="m-t-10 t-a-c">
            <p class="f-14" translate="viber.yourActivationCode">Vaš kod za aktivaciju: </p>
            <p class="viber-code-wrap">{{viberActivate.text}}</p>
          </div>

          <div class="activationCodeWrapper">
            <div class="p-10 f-14" data-ng-repeat="item in viberActivate.activationCodeConditions">
              <div class="row">
                <div class="col-sm-1 col-xs-3 viber-bullet">{{$index + 1}}</div>
                <div class="col-sm-11 col-xs-9" translate="{{item}}"></div>
              </div>
            </div>
          </div>

        </div>
    </div>

    <!-- code related to CRO environment-->

    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">

      <div class="row">
        <div class="col-md-6">
          <div class="m-t-10 t-a-c">
            <image width="150" height="150" class="m-l-30"
                   data-ng-src="{{'data:image/bmp;base64,' + viberActivate.qrCodeUrl}}"></image>
          </div>

          <div class="p-10 f-14" data-ng-repeat="item in viberActivate.scanQrCodeConditions">
            <div class="row">
              <div class="col-sm-1 col-xs-3 viber-bullet">{{$index + 1}}</div>
              <div class="col-sm-11 col-xs-9" translate="{{item}}"></div>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <div class="m-t-10 t-a-c">
            <p class="f-14" translate="viber.yourActivationCode">Vaš kod za aktivaciju: </p>
            <p class="viber-code-wrap">{{viberActivate.text}}</p>
          </div>

          <div class="activationCodeWrapper">
            <div class="p-10 f-14" data-ng-repeat="item in viberActivate.activationCodeConditions">
              <div class="row">
                <div class="col-sm-1 col-xs-3 viber-bullet">{{$index + 1}}</div>
                <div class="col-sm-11 col-xs-9" translate="{{item}}"></div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 t-a-c">
          <img src="img/hypo/activation steps.png" alt="" width="85%">
        </div>
      </div>

      <div class="row f-14">
        <div class="col-xs-4" data-ng-repeat="item in viberActivate.additionalActivationConditions">
          <div class="col-xs-2 col-sm-2 viber-bullet">{{$index + 3}}</div>
          <div class="col-xs-10 col-sm-10 p-r-0" translate="{{item}}"></div>
        </div>
      </div>

    </div>

  </div>
</section>
