<section ng-controller="InternationalPaymentMneCtrl as internationalPayment">
  <div>
    <span ng-if="internationalPayment.steps.step1">
      <!-- START Title -->
      <div class="domp-header-wrapper col-sm-12 p-0 pb-3">
        <div class="domp-header-row">
          <h2 class="intp-title f-28" translate="internationalPayment.international"></h2>
        </div>
        <div ng-if="internationalPayment.guideURL" class="domp-header-row text-right">
          <span ng-click="internationalPayment.downloadInoPaymentGuide()" class="text-underline cursor-pointer"
            translate="internationalPayment.guide"></span>
        </div>
      </div>
      <!-- END Title-->
      <div class="domp-select-section col-sm-12 p-0">
        <!-- ADD SELECT FROM TEMPLATE HERE ! -->
        <!-- FIRST BOX -->
        <form name="internationalPayment.paymentForm" novalidate>

          <!--SELECT TEMPLATE CUSTOM -->

          <div class="domp-template-filter-container">
            <div class="domp-select-template" ng-click="internationalPayment.toggleTemplateFilterSelection()"
              ng-show="internationalPayment.templateName == ''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from
                  template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>
            <div class="domp-selected-template" ng-show="internationalPayment.templateName != ''">
              <div class="select-template label-template-selected p-l-10 p-t-5"
                ng-show="internationalPayment.templateName != ''">
                <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>
                <label class="p-l-10 selected-template-label"><b>{{internationalPayment.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5"
                  ng-click="internationalPayment.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection"
              ng-class="{'opened': internationalPayment.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width: 50%">
                  <input id="searchTemplatesInput" type="text" class="search-input"
                    ng-model="internationalPayment.tmpSearchText.name"
                    ng-change="internationalPayment.searchTemplate(internationalPayment.tmpSearchText.name)"
                    placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item"
                      data-ng-repeat="template in internationalPayment.templateList.result| filter:internationalPayment.tmpSearchText"
                      ng-click="internationalPayment.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-r-0 domp-select-label"><i
                            class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i></div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
          <!-- END FIRST ROW-->

          <!-- START SECOND BOX-->
          <div class="receiver-section " style="padding: 10px;background-color:#fff;">
            <div class="row">
              <!-- START From Account - Custom Dropdown -->
              <div class="col-md-6">
                <label class="domp-form-label " translate="payments.fromAccount"></label>
                <div class="accounts-dropdown m-b-10">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                    data-ng-click="internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown"
                    ng-style="internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                        {{internationalPayment.tempForpObj.fromAccountSelect.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                        <i class="zmdi"
                          ng-class="{'zmdi-star favorite-account':account.isFavorite, 'zmdi-star-outline':!account.isFavorite}"></i>
                      </span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{internationalPayment.tempForpObj.fromAccountSelect.iban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{internationalPayment.tempForpObj.fromAccountSelect.availableBalance| number:2}}
                      {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    <span class="caret-icon">
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                    </span>
                  </div>
                  <div class="from-accounts-list" ng-show="internationalPayment.showAccountToDropdown" scrollable>
                    <div class="accounts-list-item p-0" data-ng-repeat="item in internationalPayment.accountsFrom"
                      data-ng-click="internationalPayment.setChargeAccount(item); internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown">

                      <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                        ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                              ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{item.availableBalance| number:2}} {{item.currencySymbol}}
                        </p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- END From Account - CUSTOM DROPDOWN-->
              <!-- START Amount and Date -->
              <div class="col-md-6">
                <!--<div class="row">-->
                <!-- START Amount -->
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput"
                    translate="internationalPayment.totalAmount">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                      autocomplete="off" placeholder="{{'eInvoicesModal.amountPlaceholder'| translate}}"
                      amount-input-mask maxlength="12" ng-blur="internationalPayment.calculateTotalAmountWithFee()"
                      ng-model="internationalPayment.tempForpObj.amountInput" required
                      ng-class="{'domp-error': internationalPayment.paymentForm.amountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <span class="input-group-btn">
                      <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                        style="border:1px solid #062a42;">
                        {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                      </button>
                    </span>
                  </div>
                  <span class="c-red"
                    ng-show="internationalPayment.paymentForm.amountInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                    translate="internalTransfer.amountInputError">
                  </span>
                  <!--</div>-->
                </div>
                <div class="form-group">
                  <label class="domp-form-label" translate="payments.bicBankCharges">Troškovna opcija: </label>
                  <select class="form-control input-field"
                    ng-model="internationalPayment.tempForpObj.bankChargesRadiobutton.type"
                    ng-change="internationalPayment.calculateTotalAmountWithFee()"
                    required name="bicBankCharges" id="bicBankCharges"
                    ng-class="{'domp-error': internationalPayment.paymentForm.bicBankCharges.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                    ng-disabled="internationalPayment.EUCountrySelected || internationalPayment.isEUCountry">
                    <option value=3>SHA</option>
                    <option value=2>OUR</option>
                  </select>
                  <span class="c-red"
                    ng-show="internationalPayment.paymentForm.bicBankCharges.$error.required && internationalPayment.tempForpObj.formSubmitted"
                    translate="internalTransfer.chargesError">
                  </span>
                  
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <!-- End Amount-->
                <!-- START Date-->
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput"
                    translate="internationalPayment.paymentDate">Date:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                      uib-datepicker-popup="shortDate" show-weeks="false"
                      ng-model="internationalPayment.tempForpObj.dueDateInput" show-weeks="false"
                      is-open="internationalPayment.datePickerOpened" current-text="{{'core.period.today' | translate}}"
                      clear-text="{{'core.datePicker.clearBtn' | translate}}"
                      close-text="{{'core.datePicker.doneBtn' | translate}}"
                      datepicker-options="internationalPayment.dateOptions"
                      date-disabled="internationalPayment.disabled(date, mode)" readonly>
                    <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="input-group-btn">
                      <button ng-switch-default data-ng-click="internationalPayment.openDatePicker()" type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                        style="border:1px solid #062a42;">
                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                      </button>
                      <button ng-switch-when="sl" class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                        style="border:1px solid #062a42;">
                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="domp-form-label"
                    translate="internationalPayment.totalAmountWithFee">totalAmountWithFee</label>
                  <div class="input-group w-100">
                    <input type="text" class="form-control input-field" name="totalAmountWithFee"
                      ng-model="internationalPayment.totalAmountWithFee" disabled amount-input-mask>
                    <span class="input-group-btn">
                      <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                        style="border:1px solid #062a42;">
                        {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- END Date-->
          </div>

          <!-- END Amount and Date -->
          <!-- START  Real Debitor -->
          <div class="row p-t-20">
            <div class="col-sm-12">
              <div class="form-group" style="padding: 10px;">
                <label class="domp-form-label" for="toAccountInput"
                  translate="internationalPayment.paymentPurposeReceiverInfo">
                  Payment purpose - Receiver Info
                </label>

                <input type="text" class="form-control input-field" id="paymentPurposeReceiverInfoInput"
                  data-ng-model="internationalPayment.tempForpObj.paymentPurposeReceiverInfoInput"
                  name="paymentPurposeReceiverInfoInput" ng-maxlength="140" maxlength="140"
                  ng-pattern-restrict="{{internationalPayment.regexPatterns.purposeOfPaymentValidation}}"
                  ng-class="{'domp-error': internationalPayment.paymentForm.paymentPurposeReceiverInfoInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                  required>
                  <span class="c-red"
                    ng-show="internationalPayment.paymentForm.paymentPurposeReceiverInfoInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                    translate="internalTransfer.purposeError">
                  </span>
              </div>
            </div>
          </div>
          <!-- END  Real Debitor -->

      </div>

      <div class="receiver-section" style="padding:10px;background-color:#fff;">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label" for="toAccountInput" translate="internationalPayment.toAccountInputLabel">
                Account number
              </label>
              <input type="text" class="form-control input-field" capitalize
                ng-pattern-restrict="{{internationalPayment.regexPatterns.allLettersNumbersSpaces}}"
                ui-options="internationalPayment.uiMaskOptions"
                ng-model="internationalPayment.tempForpObj.toAccountInput"
                ng-class="{'domp-error': !internationalPayment.tempForpObj.toAccountInput && internationalPayment.tempForpObj.formSubmitted}"
                name="toAccountInput" id="toAccountInput" ng-trim="false" ng-keyup="
                             internationalPayment.fillReceiver(internationalPayment.tempForpObj.toAccountInput);
                             internationalPayment.getBicByIban(internationalPayment.tempForpObj.toAccountInput)"
                             required>
              <span class="c-red"
                ng-show="!internationalPayment.tempForpObj.toAccountInput && internationalPayment.tempForpObj.formSubmitted"
                translate="domesticPayment.receiverAccountErrorHR">
              </span>
              <span class="c-red"
                ng-show="internationalPayment.ibanAndSwiftDontMatch && internationalPayment.tempForpObj.formSubmitted"
                translate="internationalPayment.ibanSwiftNotMatchError">
              </span>
            </div>
          </div>
          <!-- /////////// SELECT CUSTOM ///////////// -->
          <div class="col-sm-6 col-xs-12">
            <div class="form-group clearfix">
              <label class="domp-form-label" for="toAccountInput" translate="internationalPayment.toPayee2">To payee
              </label>
              <div class="col-sm-12 col-xs-12 domp-filter-container"
                ng-class="{'domp-error-custom-payee': !internationalPayment.tempForpObj.receiversNameInput && internationalPayment.tempForpObj.formSubmitted}"
                style="margin-bottom:16px;">
                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                  <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input"
                    ng-model="internationalPayment.tempForpObj.receiversNameInput"
                    ng-change="internationalPayment.searchPayee()"
                    ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}" autocomplete="off"
                    required maxlength="70" ng-maxlength="70">
                </div>
                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                  ng-click="internationalPayment.toggleFilterSelection()">
                  <i class="zmdi zmdi-close domp-close-sign"
                    ng-show="internationalPayment.tempForpObj.receiversNameInput"
                    ng-click="internationalPayment.removeSearch()"></i>
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                    ng-show="!internationalPayment.tempForpObj.receiversNameInput"></i>
                </div>
                <div class="domp-filter-selection search-row"
                  ng-class="{'opened': internationalPayment.searchFilterExpanded}">
                  <div class="domp-filter-type-selection-wrapper"
                    ng-repeat="payee in internationalPayment.payeeList| filter:internationalPayment.searchPayeeBy"
                    ng-click="internationalPayment.selectPayee(payee.id)">
                    <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <i class="zmdi zmdi-account-circle"></i>
                    </div>
                    <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                      <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                      <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="!internationalPayment.tempForpObj.receiversNameInput && internationalPayment.tempForpObj.formSubmitted"
                  translate="domesticPayment.toPayeeInputError"></span>
              </div>
            </div>

          </div>
          <!--//////////////SELECT CUSTOM END/////////////////////// -->
        </div>
        <div class="row">
          <div class="col-md-offset-6 col-md-6">
            <div class="form-group">
              <!-- <label class="domp-form-label" for="receiversAddressInput"
                         translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label> -->
              <input type="text" class="form-control input-field"
                ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                ng-class="{'domp-error': !internationalPayment.tempForpObj.receiversAddressInput && internationalPayment.tempForpObj.formSubmitted}"
                name="receiversAddressInput" id="receiversAddressInput"
                ng-model="internationalPayment.tempForpObj.receiversAddressInput"
                ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversAddressInput', internationalPayment.tempForpObj.receiversAddressInput)"
                maxlength="35" ng-maxlength="35" required>
              <span class="c-red"
                ng-show="!internationalPayment.tempForpObj.receiversAddressInput && internationalPayment.tempForpObj.formSubmitted"
                translate="domesticPayment.receiverAddressError"></span>
            </div>
          </div>

        </div>
        <div class="row">

          <div class="col-md-offset-6 col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <!-- <label class="domp-form-label" for="receiversPostalCodeAndCityInput"
                         translate="domesticPayment.receiversPostalCodeAndCityInputLabel">Receiver's postal code and
                    city:</label> -->
              <input type="text" class="form-control input-field"
                ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                ng-class="{'domp-error': internationalPayment.paymentForm.receiversPostalCodeAndCityInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput"
                ng-model="internationalPayment.tempForpObj.receiversPostalCodeAndCityInput"
                ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', internationalPayment.tempForpObj.receiversPostalCodeAndCityInput)"
                maxlength="35" ng-maxlength="35">
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <div class="form-group clearfix">
              <label class="domp-form-label" translate="internationalPayment.recipientBank">
                Country
              </label>
              <div class="col-xs-12 domp-filter-container h-55"
                ng-class="{'domp-error-custom-payee': !internationalPayment.countrySearchFilter && internationalPayment.tempForpObj.formSubmitted}">
                <div class="col-xs-1 inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="col-xs-9 col-sm-10 inner-section input-div searchrow">
                  <input type="text" class="search-input" name="countrySearchFilter"
                    ng-model="internationalPayment.countrySearchFilter"
                    ng-change="internationalPayment.countrySearchFilterExpanded = true;"
                    ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                    required autocomplete="off">
                </div>

                <div class="col-xs-1 inner-section right"
                  ng-click="internationalPayment.countrySearchFilterExpanded = !internationalPayment.countrySearchFilterExpanded">
                  <i class="zmdi zmdi-close domp-close-sign" ng-show="internationalPayment.countrySearchFilter"
                    ng-click="internationalPayment.removeSelectedCountry()"></i>
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                    ng-show="!internationalPayment.countrySearchFilter"></i>
                </div>
                <!--Dropdown menu -->
                <div class="domp-filter-selection search-row"
                  ng-class="{'opened': internationalPayment.countrySearchFilterExpanded}">
                  <div class="domp-filter-type-selection-wrapper"
                    ng-repeat="country in internationalPayment.countriesOptions| filter: internationalPayment.countrySearchFilter"
                    ng-click="internationalPayment.onCountrySelect(country)">
                    <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <!--<i class="zmdi zmdi-account-circle"></i>-->
                    </div>
                    <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                      <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-red"
                ng-show="!internationalPayment.countrySearchFilter && internationalPayment.tempForpObj.formSubmitted"
                translate="payments.bicCountryRequired"></div>
            </div>
          </div>
          <div class="col-sm-6 p-t-30">
          </div>
        </div>
      </div>
      <div class="receiver-section" style="padding: 10px; background-color:#fff;">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <div class="no-padding-on-phone">
                <label class="domp-form-label" for="purposeCodeSelect"
                  translate="internationalPayment.purposeCode">Purpose code</label>
                <div class="p-l-0">
                  <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect"
                    ng-options="item.optionValue for item in internationalPayment.purposeCodes"
                    ng-model="internationalPayment.tempForpObj.purposeCodeSelect"
                    ng-class="{'domp-error': !internationalPayment.tempForpObj.purposeCodeSelect && internationalPayment.tempForpObj.formSubmitted}"
                    ng-change="internationalPayment.purposeCodeChange(internationalPayment.tempForpObj.purposeCodeSelect)"
                    required></select>
                    <span class="c-red"
                          ng-show="!internationalPayment.tempForpObj.purposeCodeSelect && internationalPayment.tempForpObj.formSubmitted"
                          translate="internalTransfer.purposeCodeInputError"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 form-group">
            <div class="no-padding-on-phone">
              <label class="domp-form-label domp-purpose-label" for="paymentPurposeInput"
                translate="internationalPayment.paymentPurpose">Purpose of payment</label>
              <div class="p-0">
                <input type="text" class="form-control input-field" id="paymentPurposeInput"
                  data-ng-model="internationalPayment.tempForpObj.paymentPurposeInput" name="paymentPurposeInput"
                  ng-maxlength="140" maxlength="140"
                  ng-pattern-restrict="{{internationalPayment.regexPatterns.purposeOfPaymentValidation}}"
                  ng-class="{'domp-error': !internationalPayment.tempForpObj.paymentPurposeInput && internationalPayment.tempForpObj.formSubmitted}"
                  required>
                <span class="c-red"
                  ng-show="!internationalPayment.tempForpObj.paymentPurposeInput && internationalPayment.tempForpObj.formSubmitted"
                  translate="internalTransfer.paymentPurposeInputError"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START BIC -->
      <div class="purpose-section" style="padding: 10px">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group clearfix">
              <label class="domp-form-label" translate="payments.bicAddress">
                BIC Address
              </label>
              <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 domp-filter-container h-45"
                ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.bicCodeSearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                <div class="col-xs-1 col-lg-1  inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="col-xs-9 col-lg-10  inner-section input-div searchrow">
                  <input type="text" class="search-input" name="bicCodeSearchFilter"
                    ng-model="internationalPayment.bicCodeSearchFilter"
                    ng-change="internationalPayment.searchBICNumber(internationalPayment.bicCodeSearchFilter)"
                    ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLetters}}"
                    autocomplete="off" capitalize="">

                </div>

                <div class="col-xs-1 col-lg-1 inner-section right"
                  ng-click="internationalPayment.bicCodeSearchFilterExpanded = !internationalPayment.bicCodeSearchFilterExpanded">
                  <i class="zmdi zmdi-close domp-close-sign" ng-show="internationalPayment.bicCodeSearchFilter"
                    ng-click="internationalPayment.removeBicCodeSearch()"></i>
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                    ng-show="!internationalPayment.bicCodeSearchFilter"></i>
                </div>
                <!--Dropdown menu -->
                <div class="domp-filter-selection search-row"
                  ng-class="{'opened': internationalPayment.bicCodeSearchFilterExpanded}">
                  <div class="domp-filter-type-selection-wrapper"
                    ng-repeat="bicBank in internationalPayment.bicBankOptions| filter:internationalPayment.bicCodeSearchFilter"
                    ng-click="internationalPayment.onBicBankSelected(bicBank)">
                    <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <!--<i class="zmdi zmdi-account-circle"></i>-->
                    </div>
                    <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                      <p class="domp-list-name m-0">{{bicBank.bicCode}}{{bicBank.branchCode}}</p>
                    </div>
                  </div>
                </div>
                <span class="c-red" translate="payments.bicNumberRequired"
                  ng-show="internationalPayment.paymentForm.bicCodeSearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted">
                </span>
              </div>
            </div>
            <span class="c-red"
              ng-show="internationalPayment.ibanAndSwiftDontMatch && internationalPayment.tempForpObj.formSubmitted"
              translate="internationalPayment.ibanSwiftNotMatchError"></span>
            <span class="c-red"
              ng-show="internationalPayment.swiftCharectersCount && internationalPayment.tempForpObj.formSubmitted"
              translate="internationalPayment.swiftCharectersCount">
            </span>
          </div>
          <div class="col-xs-12 col-sm-6 ">
            <div class="row">
              <div class="col-xs-12">

                <!-- Required if Bic number is empty -->
                <label class="domp-form-label" translate="payments.bicBankName">Bank Name</label>
                <!--<input type="text" class="form-control input-field"-->
                <!--name="bankNameInput"-->
                <!--ng-model="internationalPayment.tempForpObj.bankNameInput"-->
                <!--ng-required="!internationalPayment.bicCodeSearchFilter">     -->
                <input type="text" class="form-control input-field" name="bankNameInput" maxlength="35"
                  ng-class="{'domp-error': !internationalPayment.tempForpObj.bankNameInput && internationalPayment.tempForpObj.formSubmitted}"
                  ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}" replace-specific-chars
                  ng-model="internationalPayment.tempForpObj.bankNameInput" autocomplete="off" required>
                <span class="c-red"
                  ng-show="!internationalPayment.tempForpObj.bankNameInput && internationalPayment.tempForpObj.formSubmitted"
                  translate="payments.bicBankNameRequired"></span>

              </div>
              <div class="col-xs-12">

                <label class="domp-form-label"></label>
                <input type="text" class="form-control input-field"
                  ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}" replace-specific-chars
                  maxlength="35" autocomplete="off" ng-model="internationalPayment.tempForpObj.bankAddressInput">

              </div>
              <div class="col-xs-12">

                <label class="domp-form-label"></label>
                <input type="text" class="form-control input-field" name="bicCity"
                  ng-model="internationalPayment.tempForpObj.bankCityInput"
                  ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}" replace-specific-chars
                  maxlength="35"
                  ng-class="{'domp-error': !internationalPayment.tempForpObj.bankCityInput && internationalPayment.tempForpObj.formSubmitted}"
                  required>
                <span class="c-red"
                  ng-show="!internationalPayment.tempForpObj.bankCityInput && internationalPayment.tempForpObj.formSubmitted"
                  translate="payments.bicCityRequired"></span>

              </div>
              <div class="col-xs-12 p-t-20">
                <div class="form-group clearfix">
                  <label class="domp-form-label" translate="internationalPayment.bicBankCountry">
                    Country
                  </label>
                  <div class="col-xs-12 domp-filter-container h-55"
                    ng-class="{'domp-error-custom-payee': !internationalPayment.countryBankSearchFilter && internationalPayment.tempForpObj.formSubmitted}">
                    <div class="col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-xs-9 col-sm-10 inner-section input-div searchrow">
                      <input type="text" class="search-input" name="countryBankSearchFilter"
                        ng-model="internationalPayment.countryBankSearchFilter"
                        ng-change="internationalPayment.countryBankSearchFilterExpanded = true;"
                        ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                        required autocomplete="off">
                    </div>

                    <div class="col-xs-1 inner-section right"
                      ng-click="internationalPayment.countryBankSearchFilterExpanded = !internationalPayment.countryBankSearchFilterExpanded">
                      <i class="zmdi zmdi-close domp-close-sign" ng-show="internationalPayment.countryBankSearchFilter"
                        ng-click="internationalPayment.removeBankSelectedCountry()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                        ng-show="!internationalPayment.countryBankSearchFilter"></i>
                    </div>
                    <!--Dropdown menu -->
                    <div class="domp-filter-selection search-row"
                      ng-class="{'opened': internationalPayment.countryBankSearchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                        ng-repeat="country in internationalPayment.countriesOptions| filter: internationalPayment.countryBankSearchFilter"
                        ng-click="internationalPayment.onBankCountrySelect(country)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <!--<i class="zmdi zmdi-account-circle"></i>-->
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="c-red"
                    ng-show="!internationalPayment.countryBankSearchFilter && internationalPayment.tempForpObj.formSubmitted"
                    translate="payments.bicCountryRequired"></div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row p-t-20">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="domp-form-label" translate="internationalPayment.email">email</label>
              <input type="email" class="form-control input-field" name="email"
                ng-model="internationalPayment.tempForpObj.email"
                ng-class="{'domp-error': !nternationalPayment.tempForpObj.email && internationalPayment.tempForpObj.formSubmitted}">
                <span class="c-red"
                      ng-show="!nternationalPayment.tempForpObj.email && internationalPayment.tempForpObj.formSubmitted"
                      translate="internalTransfer.emailError">
              </span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="domp-form-label" translate="internationalPayment.phone">phone</label>
              <input type="phone" class="form-control input-field" name="phone"
                ng-model="internationalPayment.tempForpObj.phone"
                ng-class="{'domp-error':!nternationalPayment.tempForpObj.phone && internationalPayment.tempForpObj.formSubmitted}">
                <span class="c-red"
                      ng-show="!nternationalPayment.tempForpObj.phone && internationalPayment.tempForpObj.formSubmitted"
                      translate="internalTransfer.phoneError">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- END BIC -->

      <div class="intp-form-container m-b-30">
        <div class="row">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary" translate="payments.cancel" type="button"
              data-ui-sref="payments.paymentsOverview">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button type="submit" ng-if="!internationalPayment.tempForpObj.bankCountrySelect.amlControl"
              class="btn btn-default domp-review-and-submit-btn" translate="domesticPayment.confirmBtn"
              data-ng-click="internationalPayment.submitInternationalPayment($event);">
            </button>
            <button type="submit" ng-if="internationalPayment.tempForpObj.bankCountrySelect.amlControl"
              class="btn btn-default domp-review-and-submit-btn" translate="domesticPayment.confirmBtn"
              data-ng-click="internationalPayment.submitInternationalPayment($event);">
            </button>
          </div>
        </div>
      </div>
      </form>
      <!-- END NEW SECOND BOX -->

  </div>
  </span>
  <!-- START STEP NUMBER 2 -->
  <div ng-if="internationalPayment.steps.step2">
    <div data-ng-include="'payments/international-payment-resume.tpl.html'"></div>
  </div>
  <!-- END STEP NUMBER 2-->
  <!-- START STEP NUMBER 3 -->
  <div ng-if="internationalPayment.steps.step3">
    <payment-final-step payment="internationalPayment.paymentResponse" is-modal="false"></payment-final-step>
  </div>
  <!-- END STEP NUMBER 3 -->
  </div>
</section>