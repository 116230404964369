(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            payments: {
                "quickPaymentTransfer": "Hitro plačilo ali prenos",
                "domesticPayments": "Univerzalni plačilni nalog (UPN)",
                "currencyConversion": "Menjava valut",
                "internationalPayment": "Plačilo v tujino",
                "rejectedPayments": "Zavrnjena plačila",
                "pendingPayments": "Čakalna vrsta plačil",
                "templates": "Predloge",
                "searchTemplateName": "Išči po imenu predloge",
                "topUsed": "Najpogosteje uporabljene",
                "alphabetically": "Po abecedi",
                "to": "za",
                "showMoreTemplates": "Prikaži več predlog",
                "eInvoices": "eRačuni",
                "showMoreInvoices": "Prikaži vse eRačune",
                "sepaDirectDebit": "SEPA direktne obremenitve",
                "viewAllTransactions": "Pregled vseh transakcij",
                "due": "Rok plačila",
                "allPastPayments": "Pregled vseh izvršenih plačil",
                "exchangeRatesList": "Tečajna lista",
                "pay": "Plačaj",
                "internalTransfer": "Interni prenos",
                "noEinvoices": "Ni eRačunov za prikaz",
                "noTemplates": "Ni shranjenih predlog",
                "forCurrencyConversion": "menjava valut",
                "midrate": "srednji tečaj",
                "forForeignNotes": "tuji čeki",
                "onDate": "dni ",
                "buy": "Kupi",
                "goBackToPayments": "Nazaj na plačila",
                "currencyName": "Naziv valute",
                "code": "Koda",
                "unit": "Enota",
                "buyRate": "Nakupni tečaj",
                "middleRate": "Srednji tečaj",
                "sellRate": "Prodajni tečaj",
                "action": "Akcija",
                "noRejectedPayments": "V izbranem obdobju ni bilo zavrnjenih plačil.",
                "noPendingPayments": "Za izbrano obdobje ni plačil v čakalni vrsti.",
                "donationToCharity": "Plačilo v humanitarne namene",
                "subscribe": "Prijava",
                "settings": "Nastavitve",
                "moreRejected": "Prikaz večih zavrnjenih plačil",
                "morePending": "Prikaz večih plačil v čakalni vrsti",
                "currencyExchange": "Menjalnica",
                "exchange": "Zamenjaj",
                "fromAccount": "Z računa",
                "toAccount": "Na račun",
                "willSell": "Prodaja",
                "willBuy": "Nakup",
                "available": "Na voljo",
                "delete": "Izbriši",
                "edit": "Spremeni",
                "loanPayment": "Plačilo kredita",
                "toAcc": "na račun",
                "paymentMode": "Namen plačila",
                "fromAcc": "z računa",
                "bicNumber": "SWIFT oznaka",
                "bicNumber_SLO": "SWIFT oznaka",
                "bicBankName": "Banka prejemnika",
                "bicAddress": "Naslov banke prejemnika",
                "bicBankAddress": "Naslov banke prejemnika",
                "bicCity": "Kraj banke prejemnika",
                "bicCountry": "Država banke prejemnika",
                "bicCountry_SLO": "Država banke prejemnika",
                "bicRecipientStatus": "Status prejemnika",
                "bicRecipientStatusOptionLegal": "Pravna oseba",
                "bicRecipientStatusOptionPhysical": "Fizična oseba",
                "bicBankCharges": "Stroški plačila",
                "bicNumberRequired": "Vnos BIC kode je obvezen!",
                "bicNumberMaxLength": "Vnos BIC kode mora imeti 8 ali 11 znakov!",
                "bicBankNameRequired": "Vnos banke prejemnika je obvezen!",
                "receiversPostalCodeAndCityInputRequired": "Vnos kraja je obvezen!",
                "bicCityRequired": "Vnos kraja banke je obvezen!",
                "bicCountryRequired": "Vnos države je obvezen!",
                "resend": "Pošlji ponovno",
                "minimalAccountNumber": "Številka računa ni pravilna!",
                "cancel": "Prekliči",
                "templateSavedSuccessfully": "Predloga je uspešno shranjena!",
                "templateUpdatedSuccessfully": "Predloga je uspešno spremenjena!",
                "bankAddressInputRequired": "Vnos naslova banke je obvezen.",
                "paymentDueDateChangedMsg": "Datum izvedbe plačila je spremenjen skladno z Urnikom plačilnih storitev. Prosimo, potrdite spremembo.",
                "cancelledPaymentMsg": 'Plačilo je bilo preklicano in ga banka ne bo izvršila. Preklicano plačilo bo ostalo evidentirano v Pregledu plačil/Izvršena plačila. Lahko ga ponovno pošljete banki v izvršitev, če v Pregledu plačil kliknete na gumb Izvedba.'
            },
            paymentsOverview: {
              "notSupportedPaymentMsg": "Plačilo z izbrano predlogo žal ni več mogoče.",
              "pohReciver": " za ",
                "pohAmount": " znesek od ",
                "pohDate": " na dan ",
                "povlStatus": "Status",
                "povlNote": "Razlog zavrnitve",
                "povlNote_SLO": "Razlog zavrnitve",
                "povlId": "ID naloga",
                "povlReceiver": "Ime prejemnika",
                "povlFrom": "Z računa",
                "povlTo": "Na račun",
                "povlAmount": "Znesek",
                "povlAmountSlo": "Prejeti znesek",
                "povlDueDate": "Datum plačila",
                "povlValueDate": "Datum valutacije",
                "filterStatus": "Filtriraj po statusu",
                "loadMoreBtn": "Prikaži več",
                "referenceTo": "Referenca",
                "titleLabel": "Pregled plačil",
                "signatoryName": "Podpis",
                "signDate": "Datum oddaje",
                "recallerName": "Zahteval/a",
                "recepientInfo": "Podatki o prejemniku",
                "orderPaymentConfirmation": "Naroči potrdilo o plačilu",
                "receiveConfirmation": "Potrdilo bi rad prejel na sledeči naslov",
                "orderComment": "Komentar",
                "orderPaymentConfMsg1": "Banki dovoljujem bremenitev računa za stroške izdelave in pošiljanja potrdila v skladu ",
                "orderPaymentConfMsg2": "s trenutno veljavnim cenikom Addiko Bank d.d.",
                "payerAccNumber": "Št. računa plačnika",
                "recAccNumber": "Št. računa prejemnika",
                "uniMandateRef": "Enolična referenca mandata",
                "dueDate": "Rok plačila",
                "creditorInd": "Identifikator prejemnika",
                "recReference": "Referenca prejemnika",
                "messageAboutError": "Razlog zavrnitve",
                "minLength5": "Minimum length is 5!",
                "orderSuccessAlert":"Naročilo je bilo uspešno oddano.",
                "orderConfirmationBtn":"Naročilo potrdila",
                "reclamationSuccess": "Reklamacija na plačilo je bila uspešno oddana.",
                "paymentsType": {
                    "all": "Vsi",
                    "opened": "Odprti",
                    "partiallysigned": "Delno podpisani",
                    "cancelled": "Preklicani",
                    "processed": "Odobreni",
                    "rejected": "Zavrnjeni",
                    "closed": "Izvršeni",
                    "signed": "Podpisani",
                    "notSigned": "Nepodpisani",
                    "inprocessing": "V izvedbi",
                    "forcancelling": "Čakajo na izvedbo",
                    "storned": "Stornirani",
                    "rejectedaftercomplaint": "Zavrnjeni po pritožbi",
                    "acceptedaftercomplaint": "Izvršeni po pritožbi",
                    "allTab": "Vsa plačila",
                    "realizedTab": "Izvršena plačila",
                    "pendingTab": "Čakalna vrsta plačil",
                    "rejectedTab": "Zavrnjena plačila",
                    "waitingQueque": "Čakalna vrsta"
                },
                "status":{
                    "Opened":"V obdelavi",
                    "Rejected":"Zavrnjen",
                    "WaitingQueue":"Oddan",
                    "Processed":"Izvršen"
                },
                dateRange: "Filtriraj po obdobju",
                "dateFrom": "od",
                "dateTo": "do",
                "filterByPurpose": "Išči po namenu",
                "noPaymentData": "Noben nalog ne ustreza danim pogojem.",
                "selectAll": "Označi vse",
                "selectNone": "Odznači vse",
                "signSelectedPayments": "Podpiši izbrana plačila",
                "referenceNumber": "Referenca",
                "cancelled": "Preklican",
                "processed": "Izvršen",
                "rejected": "Zavrnjen",
                "open": "Odprt",
                "inProcessing": "V obdelavi",
                "signed": "Podpisan",
                "waitingQueque":"Oddan",
                "Cancelled": "Preklican",
                "Processed": "Izvršen",
                "Rejected": "Zavrnjen",
                "Opened": "Odprt",
                "InProcessing": "V obdelavi",
                "Signed": "Podpisan",
                "WaitingQueue":"Oddan",
                "totalPendingSum":"Skupaj v čakalni vrsti:"
            },
            domesticPayment: {
                "header": "Univerzalni plačilni nalog (UPN)",
                "headerSl": "Univerzalni plačilni nalog (UPN)",
                "fromAccountSelectLabel": "Z računa",
                "availableAmountLabel": "Razpoložljivo",
                "purposeCodeSelectLabel": "Koda namena",
                "purposeOfPaymentInputLabel": "Namen plačila",
                "amountInputLabel": "Znesek",
                "dateInputLabel": "Datum",
                "bicCodeInputLabel": "BIC koda",
                "toAccountInputLabel": "IBAN prejemnika",
                "toAccountInputLabelSLO": "IBAN prejemnika",
                "toAccountInputLabelSRB": "Račun prejemnika",
                "toPayee": "Prejemnik",
                "toAccountInputBtn": "Preveri prejemnika",
                "referenceNumberLabel": "Referenca",
                "referenceModelLabel": "Model reference",
                "receiversNameInputLabel": "Ime prejemnika",
                "receiversAddressInputLabel": "Naslov prejemnika",
                "receiversPostalCodeAndCityInputLabel": "Poštna št. in kraj prejemnika",
                "receiversPostalCodeAndCityInputLabelSlo": "Kraj prejemnika",
                "receiversCountrySelectLabel": "Država prejemnika",
                "payersNameInputLabel": "Naziv plačnika",
                "payersAddressInputLabel": "Naslov plačnika",
                "payersCityInputLabel": "Poštna št. in kraj plačnika",
                "amountPlaceholder": "npr. 1.000,00",
                "reviewAndSubmitBtn": "Preverite in potrdite",
                "selectUrgentLabel": "Nujno",
                "selectUrgentLabelSLO": "Nujno",
                "selectInstantLabelSLO": "Takojšnje plačilo",
                "instantDateError": "Za takojšnja plačila je dovoljen samo trenutni datum.",
                "urgentPaymentInfo": "V primeru nujnega naloga se vam bo zaračunalo višje nadomestilo v skladu s Cenikom banke. Prosimo, preverite, ali želite, da se nalog izvrši kot nujni.",
                "processingFeeLabel": "Predviden znesek provizije",
                "receiverAccountError": "Vnos računa prejemnika je obvezen!",
                "receiverAccountErrorHR": "Vnos IBAN računa prejemnika je obvezen!",
                "receiverNameError": "Vnos naslova prejemnika je obvezen!",
                "receiverCityError": "Vnos kraja prejemnika je obvezen!",
                "receiverAddressError": "Vnos naslova prejemnika je obvezen!",
                "receiversPostalCodeAndCityInputError": "Vnos kraja prejemnika je obvezen!",
                "sepaDebitorLabel": "Dolžnik",
                "sepaReceiverLabel": "Upnik",
                "confirmBtn": "Potrdi",
                "selectFromTemplate": "Izberi predlogo",
                "listOfTemplates": "Seznam predlog",
                "bankCharges": "Predviden znesek provizije",
                "urgentPayment": "Plačilo je nujno",
                "instantPayment": "Plačilo je takojšnje ",
                "checkBankCharges": "Cenik storitev Addiko Bank d.d.",
                "referenceModelInputError": "Vnos reference je obvezen!",
                "referenceModelInputRFError": 'Vsebina "RF" reference ni določena.',
                "referenceModelInputSIError": 'Številka modela "SI" reference ne sme biti prazna.',
                "referenceModelInputInvalid": "Referenca ni pravilna!",
                "toPayeeInputError": "Vnos naziva prejemnika je obvezen!",
                "toPayee2": "Prejemnik (naziv, naslov in kraj)",
                "newPayment": "Novo plačilo",
                "delete": "Izbriši",
                "edit": "Uredi",
                "sign": "Podpiši",
                "ibanStructError": "IBAN ni pravilen.",
                "SEPAcodesLink": "Razlaga SEPA kod namena",
                "timetableForProvisionLink": "Urnik za opravljanje plačilnih storitev",
                "selectHumanitarianOrganisationLabel": "Donacija humanitarni organizaciji (brez provizije)",
                "listOfOrganisations": "Seznam humanitarnih organizacij",
                "bicBankPrejemnika":"BIC banke prejemnika",
                "sepaRegister":"SEPA register",
                "nonWorkingDay":"Izbrani datum ni delavni dan! Prosimo, izberite drug datum.",
                "confirmBtnSlo":"Naprej",
                "toAccountMinlength19": "Dovoljena dolžina je 19 !",
                 "back":"Nazaj",
                changePaymentModal : {
                  "yes":"Da",
                  "no":"Ne",
                  "confirm":"Potrdi",
                  "cancel":"Prekliči",
                  "close":"Zapri",
                  "ok": "V redu"
                },
              "accountEqualityError": "Račun v breme in račun v dobro morata biti različna!",
              "paymentPurposeLengthError": "Vnesen je predolg namen.",
              "paymentPurposeLengthError1": "Najdaljša dovoljena dolžina je",
              "paymentPurposeLengthError2": "znakov.",
              "urgentInfo":"Nalog ste označili kot nujni. V tem primeru se vam bo zaračunalo višje nadomestilo v skladu s Cenikom banke. Prosimo preverite, ali želite, da se nalog izvrši kot nujni.",
              "secondNotInSEPAMessage":"Želite izpolniti nalog za plačilo v tujino?"
            },
            internationalPayment: {
              "maxlength34": "Dovoljen je vnos največ 34 znakov.",
              international: "Nalog za plačilo v tujino",
                "fromAccount": "Z računa",
                "chargeAccount": "Račun za kritje",
                "totalAmount": "Skupni znesek in valuta",
                "paymentDate": "Datum plačila",
                "paymentPurpose": "Namen plačila",
                "recipient": "Prejemnik",
                "purposeCode": "Koda namena",
                "recipientStatus": "Status prejemnika",
                "companyName": "Naziv prejemnika",
                "address": "Naslov",
                "city": "Kraj",
                "country": "Država",
                "recipientAccount": "Račun prejemnika",
                "recipientBank": "Banka prejemnika",
                "bankName": "Naziv banke",
                "swiftCode": "SWIFT koda",
                "bankCharges": "Stroški plačila",
                "sharedCharges": "SHA Deljeni stroški",
                "payersPaysCharges": "OUR Stroške nosim sam",
                "beneficiaryPaysCharges": "Stroške nosi prejemnik",
                "naturalPerson": "Fizična oseba",
                "legalPerson": "Pravna oseba",
                "tooltipInfo": "Potrebujete pomoč?",
                "fillData": "Izpolnite podatke o banki",
                "reviewandSubmit": "Pregled in potrditev",
                "companyNameInputError": "Ime in priimek sta obvezna!",
                "recipientAddressInputError": "Vnos naslova prejemnika je obvezen!",
                "recipientCityInputError": "Vnos kraja prejemnika je obvezen!",
                "recipientAccountInputError": "Vnos računa prejemnika je obvezen!",
                "bankNameInputError": "Vnos naziva banke je obvezen!",
                "bankAddressInputError": "Vnos naslova banke je obvezen!",
                "bankCityInputError": "Vnos kraja banke je obvezen!",
                "swiftCodeInputError": "Vnos SWIFT kode je obvezen!",
                "payersNameInputLabel": "Ime in priimek plačnika",
                "payersAddressInputLabel": "Naslov plačnika",
                "payersCityInputLabel": "Kraj plačnika",
                "sepaDebitorLabel": "Dolžnik",
                "sepaReceiverLabel": "Upnik",
                "paymentMode": "Namen plačila",
                "toPayee2": "Prejemnik (naziv, naslov in kraj)",
                "toAccountInputLabel": "IBAN račun prejemnika",
                "toAccountInputLabel_SLO": "Račun prejemnika (IBAN koda)",
                "bicBankName": "Banka prejemnika",
                "noBicBankInfo": "Opomba",
                "chooseCountry":"Izberite državo",
                "paymentChargesAmount": "Provizija",

                "SLOconversionChargeAmount": "Ocenjeni skupni znesek v EUR",
                "SLOamountWithCharges": "Nadomestilo za konverzijo",
                "SLOconversionRate": "Informativni tečaj za konverzijo",
                "SLOpaymentAmount": "Ocenjeni znesek za prejemnika plačila",
                "SLOpaymentDisclaimer": "Prikaz zneskov v domači valuti vključuje obračunana nadomestila in je informativne narave. Banka izvede konverzijo v skladu s Splošnimi pogoji za vodenje transakcijskega računa in opravljanja plačilnih storitev, upoštevajoč stanje sredstev na vašem transakcijskem računu ter veljavno tečajnico na dan valute plačila."
                
            },
            internalTransfer: {
                "transferToAccount": "Prenos na moj račun",
                "fromAccount": "Račun plačnika (IBAN)",
                "toAccount": "Račun prejemnika (IBAN)",
                "dueDate": "Datum izvedbe",
                "valueDate": "Datum kreiranja",
                "amount": "Znesek",
                "sign": "Podpiši",
                "saveAsTemplate": "Shrani kot predlogo",
                "templateName": "Naziv predloge",
                "newPayment": "Novo plačilo ali prenos",
                "toPaymentOverview": "Pregled vseh plačil",
                "signSuccess": "Plačilo je bilo uspešno podpisano.",
                "toAccountInputError": "Vnos računa prejemnika je obvezen!",
                "paymentPurposeInputError": "Vnos namena je obezen!",
                "amountInputError": "Vnos zneska je obvezen!",
                "dueDateError": "Vnos datuma izvedbe je obvezen!",
                "templateNameError": "vnos naziva predloge je obvezen!",
                "when": "Datum",
                "transferComplete": "Prijenos je izvršen",
                "makeAnotherPayment": "Novo plačilo ali prenos",
                "successfullyTransfer": "Prenos je bil uspešno izvršen",
                "amountLengthError": "Najvišji dovoljen znesek je 99.999.999.999,99",
                "nodata": "Ni podatkov za prikaz",
                "minAmount10Error": "Najnižji dovoljeni znesek je ",
                "toAccountNoData": 'Prenos med računi ni mogoč ker imate prijavljen samo en račun. Za menjavo valut v meniju "Plačila" izberite "Menjava valut".'
            },
            paymentSignModal: {
                title: "Podpisovanje plačila",
                challengeResponse: "Vnesite kodo",
                ok: "V redu"
            },
            loanPayment: {
                "loanPayment": "Plačilo kredita",
                "recieverNumber": "Referenca",
                "purpose": "Namen plačila",
                "when": "Datum",
                "exchangeRate": "Tečaj",
                "referenceNumber": "Referenca",
                "defaultPurpose":"Odplačilo posojila"
            },
            currencyConversion: {
                "exchangeOffice": "Menjava valut",
                "exchangeRatesCalculator": "Tečajna lista",
                "sellingAmount": "Prodaja",
                "buyingAmount": "Nakup",
                "availableBalance": "Razpoložljivo stanje",
                "fromAccount": "Z računa",
                "toAccount": "Na račun",
                "amount": "Znesek",
                "amountTo": "Znesek",
                "sign": "Podpiši",
                "confirm": "Potrdi",
                "back": "Novo plačilo",
                "smsAuthentification": "SMS potrditev plačila",
                "sendSms": "Pošlji SMS sporočilo",
                "smsPassword": "Potrditvena koda",
                "moneyExchangeComplete": "Menjava valut je izvedena",
                "successfullyExchange": "Menjava je bila uspešno izvršena",
                "makeAnotherExchange": "Nova menjava valut",
                "goToPayments": "Pregled vseh plačil",
                "exchangeRateRatio": "Tečajna lista",
                "exchangeOrder": "Zahteva za menjavo valut",
                "signPayment": "Podpiši plačilo",
                "deletePayment": "Izbriši plačilo",
                "recallPayment": "Prekliči plačilo",
                "balance": "Stanje na računu",
                "exchangeRate": "Informativni tečaj",
                "bankCharges": "Predviden znesek provizije",
                "when": "Datum",
                "buying": "Nakupni tečaj",
                "selling": "Prodajni tečaj",
                "calculatedAmount":"Preračunan znesek",
                "agreedRate":"Tečaj",
                "bankCurrencyInfoMsg11": "Banka menjavo valut izvaja po menjalniških deviznih tečajih Addiko Bank d.d. za občane v času izvedbe nakupa ali prodaje, ne glede na menjalniški tečaj, veljaven v času oddaje naročila. Trenutno veljavno Tečajno listo Addiko Bank d.d. si lahko ogledate na",
                "bankCurrencyInfoMsg12": "spletnih straneh banke."
            },
            paymentsAndTransferOverview: {
                rejectedLabel: "Zadnji neplačani nalogi",
                eInvoicesLabel: "Neplačani eRačuni",
                groupedPaymentsLabel: "Plačila za podpis",
                repeatAction: "Ponovi",
                payAction: "Plačaj",
                signAllAction: "Podpiši vse",
                domPayToSign: "UPN nalogov za podpis",
                paymentsToSign: "nalogov za podpis",
                "confirm": "Potrdi"
            },
            ebook: {
                "ebookPayIn": "Plačilo na e-knjižico",
                "ebookPayOut": "Plačilo z e-knjižice"
            },
            savings: {
                "savingsPayIn": "Vplačilo na varčevanje"
            },
            signPayments: {
                "paymentsInvalidDueDate": "N/A",
                "singlePaymentInvalidDueDate": "N/A",
                "updatePaymentsDueDate": "N/A",
                "updatePaymentDueDate": "N/A",
                "dateCreated": "Datum kreiranja",
                "dueDate": "Datum izvedbe",
                "confirm": "Potrdi",
                "challengeResponse": "Koda potrditve",
                "challenge": "Validacijska koda",
                "challengeFirstCode": "Geslo 1 (vnese se v generator gesla)",
                "challengeSecondCode": "Geslo 2 (iz generatorja gesel)",
                "challengeDescription": "Vključite generator gesel - vnesite PIN i takoj na prazen ekran (piše samo CHAL na vrhu) vnesite številko, ki je izpisana na ekranu 'Geslo 1' in kliknite na gumb 'V redu'. Generator bo izpisal geslo, ki ga je potrebno prepisati v polje 'Geslo 2' Ko ste zaključili z vnosom, pritisnite gumb 'Potrdi'.",
                "finalStepSuccessfullySigned": "Plačilo je bilo uspešno oddano.",
                "finalStepPaymentServices": "Storitve plačilnega prometa bodo zaračunane skladno s ",
                "finalStepBankTariff": "cenikom Banke.",
                "finalStepStatus": "Status: ",
                "finalStepPaymentID": "Številka plačila: ",
                "finalStepNumberOfPayments": "Številka plačila: ",
                "finalStepSentForProcessing": "Plačilo je poslano v izvedbo.",
                "finalStepPaymentSloHeader": "Plačilo je bilo uspešno oddano.",
                "finalStepPaymentRejectedSloHeader": "Plačilo je bilo zavrnjeno.",
                "finalStepPaymentSloSubHeader":"Uspešno obdelavo plačila preverite na strani ",
                "finalStepPaymentRejectedSloSubHeader":"",
                "finalStepPaymentSloText": "Če ste oddali plačilo z datumom izvršitve kasnejšim od današnjega datuma, se je plačilo uvrstilo v čakalno vrsto plačil. Na dan plačila sprememba ali preklic plačila nista možna.",
                "finalStepPaymentRejectedSloText": "Zavrnjena plačila lahko preverite na strani ",
                "cancel": "Prekliči",
                "resendSms": "Ponovno pošlji SMS",
                "resendSmsSlo": "Ponovno pošlji SMS (velja za kodo preko SMS)",
                "SmsCode": "Potrditvena koda",
                "SmsDescription": "SMS sporočilo je poslano na vašo številko. Vnesite geslo iz sporočila v polje 'SMS koda' in kliknite 'Potrdi'.",
                "SmsDescriptionSLO": "Prosimo, vnesite potrditveno kodo, ki ste jo prejeli preko SMS sporočila ali generirali s pomočjo generatorja gesel. Izbrani način potrjevanja transakcij lahko preverite v Nastavitvah.",
                "SmsSentSuccessfully": "SMS sporčilo je uspešno poslano!",
                "paymentExecutionDateTitle": "Zamenjava datuma valute",
                "paymentExecutionDateBody": "Plačilni promet je za danes že zaključen. Vaš nalog bo izvršen naslednji delovni dan banke. Želite nadaljevati s podpisovanjem?"
            },
            paymentActions: {
                "repeat": "Ponovi plačilo",
                "copy": "Kopiraj plačilo",
                "delete": "Izbriši plačilo",
                "printSlo": "Natisni potrdilo",
                "print": "Natisni potrdilo",
                "recall": "Preklic izvedbe",
                "cancelPayment": "Preklic izvedbe",
                "sign": "Podpiši nalog",
                "cancel": "Prekliči",
                "rejectRecall": "Prekliči zahtevo",
                "approveRecall": "Potrdi zahtevo",
                "edit": "Spremeni podatke plačila",
                "complaint": "Reklamacija",
                "confirmation": "Naroči potrdilo",
                "execute":"Izvedba",
                "changePayment": "Sprememba plačila"
            },
            statementConfirmModalTranslations: {
                title: "Izjava",
                p1: "N/A",
                p2: "N/A",
                p2_2: "N/A",
                p2_0: "N/A",
                p2_1: "N/A",
                p4: "N/A",
                radioMerchandise: "N/A",
                radioPresent: "N/A",
                radioOther: "N/A",
                confirmButton: "Potrdi",
                cancelButton: "Prekliči"
            },
            "currencyCalculator": {
                "exchangeCalculator": "Tečajni kalkulator",
                "exchangeRates": "Tečajna lista",
                "msg1": "N/A",
                "msg2": "N/A " +
                    "tel.: N/A",
                "updated":"Spremenjeno"
            },
            paymentsReturn: {
                openedTab: "Odprta plačila",
                archivedTab: "Arhivirana plačila",
                titleLabel: "Strinjanje z vračilom sredstev",
                modalTitle: "Izberite razlog za vračilo sredstev",
                recallReason: "Razlog za vračilo",
                additionalInfo: "Dodatne informacije",
                checkAdditionalInfo: "Ukloni dodatne informacije",
                additionalInfoError: "To polje je obvezno",
                reasonTech: "N/A",
                reasonFrad: "N/A",
                reasonDupl: "N/A"
            },
            paymentsFinal: {
                "InProcessing": "V izvedbi",
                "Signed": "Podpisano",
                "WaitingQueue":"Plačilo je uvrščeno na čakalno vrsto",
                "Opened":"Čaka na izvedbo"
            },
            paymentsTypeGroup: {
                "CurrencyExchange":"Menjava valut",
                "LocalCurrencyTransfer":"Prenos v domači valuti",
            },
            sepa: {
              "header": "Nastavitve SEPA direktnih obremenitev",
              "addNew": "Dodaj nastavitev"
            }
        };

        $translateProvider
            .translations('sl', data);
    }
}());
