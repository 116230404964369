(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name core.filter:formatDecimal
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @param {Number} fractionSize The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('core')
    .filter('formatDecimal', formatDecimal);

  function formatDecimal($filter) {
    return function (input, fractionSize, escapeZero, minSize) {
      if (!minSize) {
        minSize = 2;
      }
      var value = $filter('number')(input, fractionSize);
      var outputValue = '';
      if (escapeZero) {
        for(var i = (value.length - 1); i > 0; i--) {
          if (value[i] == '0') {
            outputValue = value.slice(0, i);
          } else {
            break;
          }
        }
      }
      var splitValue = outputValue.includes(',') ? ',' : '.';
      var missingZerosCount = minSize - outputValue.split(splitValue)[1].length;
      if (missingZerosCount > 0) {
        for (var i = 0; i < missingZerosCount; i++) {
          outputValue += '0';
        }
      }
      return outputValue;
    };
  }
}());
