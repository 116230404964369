(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      employee:{
        "header":"Salary statement",
        "noTimelineData":"No data",
        "downloadPdf":"Download PDF"
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
