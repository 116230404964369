<div class="notificationModal">
  <div class="row">
    <div class="col-md-12">
      <div class="depth-modal-body text-left">
        <p translate="footer.dearClients" class="p-t-20 t-a-l"></p>
        <p translate="footer.modalText1"></p>
        <p translate="footer.modalText2"></p>
        <p translate="footer.modalText3"></p>
        <p translate="footer.regards" class="m-b-10"></p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary-red statementBtnClose" ng-click="$close()"><span translate="footer.closeButton"></span></button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
