(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name viber.controller:ViberActivateNewCtrl
   *
   * @description
   *
   */
  angular
    .module('viber')
    .controller('ViberActivateNewCtrl', ViberActivateNewCtrl);

  function ViberActivateNewCtrl($rootScope, $translate, $localStorage, $state, ViberService, HelperService, AlertService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'ViberActivateNewCtrl';

    vm.activate = function () {
      ViberService.checkActivation()
        .then(function (response) {
          ViberService.activate({phoneNumber: "string"})
            .then(function (response) {
              var message = $translate.instant('viber.smsSent');
              NotificationService.showTranslatedMessage('success', message, '', 3000);
            })
            .catch(function (err) {
              NotificationService.showMessage(err, 'error');
            });
        }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.becomeFollower = function () {
      AlertService.becomeFollowerAlert({})
        .then(function (accept) {
          if (!accept) return;
          becomeFollowerBase();
        });
    };

    function becomeFollowerBase() {
      ViberService.becomeFollower()
        .then(function (response) {
          if (response.result) {
            var message = $translate.instant('viber.successFollower');
            NotificationService.showTranslatedMessage('success', message, '', 3000);
          }
        })
        .catch(function (err) {
          NotificationService.showMessage(err, 'error');
        });
    }

    vm.currentLanguage = $localStorage.appLanguage;
    $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
      vm.currentLanguage = lang.langCode;
    });

  }
}());
