<div class="row m-t-20">
  <div class="col-sm-12">
    <h4 translate="orders.standingOrders"></h4>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 m-b-5">
    <div class="dropdown acc-period-select pull-right">
      <button class="btn btn-default dropdown-toggle p-l-30 p-r-30" type="button" data-toggle="dropdown">
        {{vm.selectedPeriod.item.description| translate}}
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li data-ng-repeat="period in vm.periodList">
          <a href="" class="f-13" data-ng-click="vm.timelineTimeFilter(period);">
            {{period.item.description| translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="row">
  <div class="timeline-row col-sm-12">
    <div class="t-a-c p-10 no-data-image" ng-if="vm.ordersListGrouped.result.length == 0">
      <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
      <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
    </div>
    <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0" ng-hide="vm.ordersListGrouped.result.length == 0">
      <ul class="hypo-timeline" kcd-recompile="vm.ordersListGrouped">
        <li ng-repeat="order in vm.ordersListGrouped.result">
          <i class="icon icon-insurance currency-icon-change icon-lh"></i>
          <div class="hypo-timeline-item payment-item"
               ng-click=" vm.expandDetails(order); order.selected = !order.selected"
               ng-class="{'opened': order.selected}">
            <div class="hypo-timeline-body">
              <div class="col-xs-6">

                <h4>
                  {{order.orderType.description}}
                </h4>

                <span class="text-muted text-uppercase">
                        {{order.date}}
                      </span>

              </div>
              <!--<div class="col-xs-6"><span class="pull-right t-a-r m-t-10"><b>{{order.statusId| orderStatusString | translate}}</b></span></div>-->
              <div class="col-xs-6">
                <span class="pull-right t-a-r m-t-10"><b>{{'orders.'+order.status | translate}}</b></span>
              </div>
              <div class="col-xs-12">
                      <span class="text-muted">
                        {{order.dateCreated| date:'short'}}
                      </span>
              </div>
              <div class="clear"></div>
            </div>

            <div ng-if="order.selected" class="transaction-details">
              <div>
                <table class="table table-transparent table-responsive">
                  <tbody>
                  <tr ng-if="order.id">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.orderId"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.id }}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.payeeName">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.name"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.payeeName }}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.payeeName">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.payeeAccount"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.payeeAccount}}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.amount">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.amount"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.amount | number:2}} {{order.currencySymbol}}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.purpose">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.purpose"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.purpose}}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.dateCreated">
                    <td>
                      <span class="po-desc-label" translate="orderDetails.dateCreated"></span>
                    </td>
                    <td>
                      <span class="po-value-label">{{order.dateCreated | date: 'medium'}}</span>
                    </td>
                  </tr>
                  <tr ng-if="order.status != 'Signed' && vm.countryCode != 'sl'">
                    <td colspan="2">
                      <div>
                        <span>
                            <button
                              ng-click="vm.closeOrder(order.id);"
                              class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                              translate="orderDetails.close">Close order</button>
                          </span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="show-more-btn ">
    <button ng-hide="vm.ordersListGrouped.hasMore == false" data-ng-click="vm.loadMoreTimelineItems()" type="input"
            class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
            translate="dashboard.loadEvents"></button>
  </div>
</div>
