(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentConfirmationModalSloCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentConfirmationModalSloCtrl', PaymentConfirmationModalSloCtrl);

  function PaymentConfirmationModalSloCtrl($rootScope, $uibModalInstance, AlertService, payment, PaymentsService) {

    var vm = this;
    var userInfo = $rootScope.APP_GLOBAL_SETTINGS.userSettings;

    vm.model = {};
    vm.model.address = userInfo.firstName + " " + userInfo.lastName + '\n' + userInfo.address1 + "\n" + userInfo.postalCode + " " + userInfo.city;
    vm.model.note = "";
    vm.payment = payment;

    vm.ok = function (form) {
      if (form.$invalid) return;

      vm.confirmBtnDisabled = true;
      var data = {
        paymentId: payment.id,
        destinationAddress: vm.model.address,
        note: vm.model.note
      };

      PaymentsService.paymentOrderConfirm(data)
        .then(function (response) {

          vm.confirmBtnDisabled = false;
          AlertService.infoAlert({text: "paymentsOverview.orderSuccessAlert"});
          $uibModalInstance.close(payment);
        }, function (error) {
          vm.confirmBtnDisabled = false;
        });

    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };


  }
}());
