(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name core.factory:GoogleTagManagerHelperService
     *
     * @description
     *
     */
    angular
        .module('core')
        .factory('GoogleTagManagerHelperService', GoogleTagManagerHelperService);

    function GoogleTagManagerHelperService($rootScope, $translate) {
        var GoogleTagManagerHelperServiceBase = {};
        GoogleTagManagerHelperServiceBase.pushEvent = function (eventCategory, eventAction, eventLabel) {
            //gtm analytics
            if ($rootScope.APP_GLOBAL_SETTINGS.userSettings !== undefined) {
              if (!window.dataLayer) return;
                //dataLayer.push({
                //    'event': 'ctebevent',
                //    'userId': $rootScope.APP_GLOBAL_SETTINGS.userSettings.authenticationUserId,
                //    'eventCategory': eventCategory,
                //    'eventAction': eventAction,
                //    'eventLabel': eventLabel
                //});
            }
        };
        GoogleTagManagerHelperServiceBase.pushVirtualPageView = function (page, title, url) {
            //gtm analytics
            if ($rootScope.APP_GLOBAL_SETTINGS.userSettings !== undefined) {
              if (!window.dataLayer) return;
              //dataLayer.push({
              //      'event': 'virtualPageview',
              //      'userId': $rootScope.APP_GLOBAL_SETTINGS.userSettings.authenticationUserId,
              //      'page': page,
              //      'title': title,
              //      'url': url
              //  });
            }
        };
        return GoogleTagManagerHelperServiceBase;
    }
}());
