(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name blockade.controller:BlockadeCtrl
   *
   * @description
   *
   */
  angular
    .module('blockade')
    .controller('BlockadeCtrl', BlockadeCtrl);

  function BlockadeCtrl(SessionService, HelperService, BlockadeService, NotificationService) {
    var vm = this;

    vm.steps = {
      input: true,
      resume: false,
      finish: false
    }

    vm.model = {
      reason: "",
      temporary: false,
      blockWeb: false,
      blockMobile: false,
      dateFrom: "",
      dateTo: "",
      dateCreated: ""
    };

    vm.dateToOptions = {
      minDate: new Date()
    }

    vm.dateFromOptions = {
      minDate: new Date()
    }

    init();
    function init() {
    }

    function createInsertObject() {
      return {
        reason: vm.model.reason,
        temporary: vm.model.temporary,
        blockWeb: vm.model.blockWeb,
        blockMobile: vm.model.blockMobile,
        dateFrom: (vm.model.temporary) ? vm.model.dateFrom : undefined,
        dateTo: (vm.model.temporary) ? vm.model.dateTo : undefined,
        dateCreated: vm.model.dateCreated
      }
    }

    vm.onDateFromChange = function () {
      vm.dateToOptions.minDate = vm.model.dateFrom;
      // if dateTo empty - set dateFrom as default value
      if (!vm.model.dateTo || (vm.model.dateTo.getTime() < vm.model.dateFrom.getTime())) {
        vm.model.dateTo = angular.copy(vm.model.dateFrom);
      }
    }

    vm.next = function () {
      vm.formSubmitted = true;
      if (vm.blockadeForm.$invalid) return;

      vm.model.dateCreated = new Date();
      BlockadeService.validateBlockadeOrder(createInsertObject())
        .then(function (response) {
          vm.steps = {
            input: false,
            resume: true,
            finish: false
          }
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
    }

    vm.confirm = function () {
      if (vm.blockadeForm.$invalid) return;

      BlockadeService.createAddikoEBankOrMobileBlockade(createInsertObject())
        .then(function (response) {
          vm.steps = {
            input: false,
            resume: false,
            finish: true
          }
        }, function (error) {
          // NotificationService.showMessage(error, 'error');
          if (error.resultList.length) {
            if (error.resultList[0].description.search("E_LOGIN_BLOCKED") > -1) {
              SessionService.logout()
            }
          }
        })
    }
  }

}());
