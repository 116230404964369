<section id="main" ng-show="savingsAccount.isPageLoaded">
  <div ng-show="savingsAccount.isPageLoaded" class="container-fluid clearfix f-14 m-t-25 m-b-20">
    <div ng-show="!savingsAccount.steps.step3">
      <div class="row">
        <div class="col-md-12">
          <h2 class="title-style m-t-0 m-b-20" translate="savingsOrder.title"></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info" style="background: white; color: black; border: 1px solid lightgray"
               role="alert">
            <p class="bold-font" translate="termDeposit.taxationOfInterestTitle"></p>
            <p translate="savingsOrder.taxationOfInterestMsg">
            <div class="row p-t-10 p-l-15 p-r-15">
              <div class="col-xs-6 p-0"><a href="https://www.addiko.si/obcani/varcevanja/addiko-varcevalni-racun/" target="_blank"
                                           class="underline">{{ 'savingsOrder.headerLink1' | translate}}</a></div>
              <div class="col-xs-6 p-0"><a href="https://www.addiko.si/obcani/varcevanja/addiko-plus-fix/" target="_blank"
                                           class="underline">{{ 'savingsOrder.headerLink2' | translate}}</a></div>
            </div>
            <div class="row p-t-10 p-l-15 p-r-15">
              <div class="col-xs-6 p-0"><a href="https://www.addiko.si/addiko-obrestne-mere" target="_blank" class="underline">{{
                'savingsOrder.headerLink3' | translate}}</a></div>
              <div class="col-xs-6 p-0"><a href="https://www.bsi.si/financna-stabilnost/jamstvo-za-vloge-v-bankah" target="_blank"
                                           class="underline">{{ 'savingsOrder.headerLink4' | translate}}</a></div>
            </div>
            </p>
            <!--<p><a class="underline p-r-5" href="">Test 1</a> <a class="underline p-r-5" href="">Test 2</a> <a-->
            <!--class="underline" href="">Test 3</a></p>-->
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 1 ==== -->
    <!-- ================= -->
    <div ng-form="savingsAccount.savingsOrderForm" class="clearfix bg-white" ng-show="savingsAccount.steps.step1"
         novalidate>
      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-md-5">
            <label class="ntd-form-label compact"
                   translate="savingsOrder.savingsType">
            </label>
            <select name="savingTypesGroup"
                    ng-options="key for (key, value) in savingsAccount.savingOrderTypesGroupOptions"
                    value="{{key}}"
                    ng-model="savingsAccount.savingTypesGroup"
                    ng-change="savingsAccount.onSavingOrderTypesGroupChange(savingsAccount.savingTypesGroup)"
                    class="form-control h-45"
                    required>
            </select>

            <span class="c-red"
                  ng-show="savingsAccount.savingsOrderForm.savingTypesGroup.$error.required && savingsAccount.formSubmitted"
                  translate="core.requiredField"></span>
          </div>
          <div class="col-md-7" ng-if="savingsAccount.selectedSavingTypesGroupId != 1 && savingsAccount.selectedSavingTypesGroupId != 2">
            <div ng-show="!savingsAccount.durationInputHidden" class="col-sm-6 col-xs-12">
              <div class="col-md-12 p-0">
                <label class="ntd-form-label compact" translate="savingsOrder.duration"></label>
              </div>

              <div class="col-md-5 col-xs-5 p-0">
                <input class="form-control t-a-r h-45" maxlength="6"
                       name="maturityInDays"
                       ng-pattern-restrict="{{savingsAccount.regexPatterns.onlyNumbers}}"
                       ng-blur="savingsAccount.calculateMaturityDateWithDays(savingsAccount.model.maturityInDays)"
                       ng-model="savingsAccount.model.maturityInDays"
                       required/>
                <label class="ntd-form-label compact pull-right"
                       translate="termDeposit.durationInDays">
                </label>
              </div>
              <div class="col-md-2 col-xs-2 p-10 text-center">
                <label class="ntd-form-label compact"
                       translate="termDeposit.durationOr">
                </label>
              </div>
              <div class="col-md-5 col-xs-5 p-0">
                <input class="form-control t-a-r h-45" maxlength="3"
                       name="maturityInMonths"
                       ng-pattern-restrict="{{savingsAccount.regexPatterns.onlyNumbers}}"
                       ng-blur="savingsAccount.calculateMaturityDate(savingsAccount.model.maturityInMonths)"
                       ng-model="savingsAccount.model.maturityInMonths"/>
                <label class="ntd-form-label compact pull-right"
                       translate="termDeposit.durationInMonths">
                </label>
              </div>
              <!-- Error message -->
              <div class="col-md-12 col-xs-12 p-0">
                  <span class="c-red" ng-show="savingsAccount.savingsOrderForm.maturityInMonths.$error.minvalue">
                  <span translate="savingsOrder.minSavingDuration"></span>
                  {{savingsAccount.minimalDurationForSelectedGroup}}
                  <span translate="termDeposit.days"></span>
                </span>
                <span class="c-red" ng-show="savingsAccount.savingsOrderForm.maturityInMonths.$error.maxvalue">
                  <span translate="savingsOrder.maxSavingDuration"></span>
                  {{savingsAccount.maxSavingDuration}}
                  <span translate="termDeposit.days"></span>
                </span>
                <!--<span class="c-red" ng-show="savingsAccount.savingsOrderForm.maturityInMonths.$error.maxvalue">-->
                <!--<span translate="termDeposit.maxDepDurationErr"></span>-->
                <!--{{savingsAccount.minimalDurationForSelectedGroup}}-->
                <!--<span translate="termDeposit.days"></span>-->
                <!--</span>-->
              </div>
            </div>

            <div class="col-sm-6 col-xs-12" ng-if="savingsAccount.selectedSavingTypesGroupId != 2">
              <label class="ntd-form-label compact"
                     translate="termDeposit.maturityDate">
              </label>
              <div class="input-group">
                <input type="text" class="form-control t-a-r h-45" readonly
                       name="maturityDate"
                       ng-model="savingsAccount.model.maturityDate"
                       uib-datepicker-popup="shortDate"
                       current-text="{{'core.period.today' | translate}}"
                       clear-text="{{'core.datePicker.clearBtn' | translate}}"
                       close-text="{{'core.datePicker.doneBtn' | translate}}"
                       datepicker-options="savingsAccount.dateOptions"
                       show-weeks="false" date-disabled="savingsAccount.disabled(date, mode)"
                       is-open="savingsAccount.isDatepickerOpen"
                       ng-change="savingsAccount.calculateMaturityDays(savingsAccount.model.maturityDate)"
                       readonly/>

                <span class="input-group-btn">
                    <button data-ng-click="savingsAccount.isDatepickerOpen = !savingsAccount.isDatepickerOpen"
                            type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                            style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
              </div>
              <span class="c-red"
                    ng-show="savingsAccount.savingsOrderForm.$error.dateDisabled">
                  <span translate="orders.maturityDateNonWorkingErr"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-5">
            <label class="ntd-form-label compact" translate="savingsOrder.fromAccount"></label>
            <div class="accounts-dropdown m-b-10" ng-if="savingsAccount.accountsFrom.length > 0">
              <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                   data-ng-click="savingsAccount.toggleFromAccount();"
                   ng-style="savingsAccount.model.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + savingsAccount.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <h4 class="no-wrap-text bold-font m-b-0">
                  <b
                    class="col-xs-10 p-0 m-t-10  no-wrap-text">{{savingsAccount.model.fromAccountSelect.friendlyName}}</b>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                    <i class="zmdi"
                       ng-class="{'zmdi-star favorite-account':savingsAccount.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!savingsAccount.model.fromAccountSelect.isFavorite}"></i></span>
                </h4>
                <p class="m-b-0 m-t-0 iban-style">
                  {{savingsAccount.model.fromAccountSelect.iban | formatIban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{savingsAccount.model.fromAccountSelect.availableBalance | number:2}}
                  {{savingsAccount.model.fromAccountSelect.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
              </div>
              <div class="from-accounts-list m-l-15" ng-show="savingsAccount.fromAccountDropdown" scrollable>
                <div class="accounts-list-item p-0" data-ng-repeat="item in savingsAccount.accountsFrom"
                     data-ng-click="savingsAccount.setAccountFrom(item);">

                  <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                       ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                          ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{item.availableBalance | number:2}} {{item.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accounts-dropdown m-b-10"
                 ng-if="!savingsAccount.accountsFrom.length || savingsAccount.accountsFrom.length === 0">
              <!--<div class="debit-account-widget dashboard-widget-item" style="height: 114px; line-height: 114px" translate="internalTransfer.noData"  ng-style="savingsAccount.model.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + savingsAccount.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  No available accounts
              </div>-->
              <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                   ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <div class="m-t-30 m-b-30">
                  <p translate="internalTransfer.nodata"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="form-group clearfix m-t-10">
              <div class="col-md-6 col-xs-12" ng-if="savingsAccount.selectedSavingTypesGroupId != 2">
                <span ng-switch="savingsAccount.model.savingsType.periodType">
                      <label class="ntd-form-label compact" ng-switch-when="K"
                             translate="savingsOrder.initialDepositAmount"></label>
                       <label class="ntd-form-label compact" ng-switch-when="D"
                             translate="savingsOrder.monthlyDepositAmount"></label>
                  <label class="ntd-form-label compact" ng-switch-default
                             translate="savingsOrder.monthlyDepositAmount"></label>
                </span>

                <div class="input-group">
                  <input required type="text" class="form-control h-45 t-a-r"
                         name="amount"
                         amount-input-mask
                         placeholder="{{'accounts.accEg' | translate}}: 000.000,00"
                         maxlength="14" max-amount="99999999999.99"
                         ng-model="savingsAccount.model.amount"
                         ng-blur="savingsAccount.amountChanged(savingsAccount.model.amount)"
                         required/>
                  <span class="input-group-btn">
                       <button
                         class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero p-5 dark-border"
                         style="pointer-events: none;">
                        {{savingsAccount.model.currency}}
                      </button>
                    </span>
                </div>
                <!--<span class="c-red"-->
                <!--ng-show="savingsAccount.savingsOrderForm.amount.$error.required">-->
                <!--<span translate="internalTransfer.amountInputError"></span>-->
                <!--</span>-->
                <span class="c-red"
                      ng-show="
                      savingsAccount.savingsOrderForm.amount.$touched &&
                      savingsAccount.savingsOrderForm.amount.$error.minamount &&
                      savingsAccount.model.savingsType.minInstalment">
                  <span translate="termDeposit.minAmountLabel"></span>
                  {{savingsAccount.model.savingsType.minInstalment | currency: ""}} {{savingsAccount.model.currency}}.
                </span>
                <span class="c-red"
                      ng-show="savingsAccount.savingsOrderForm.amount.$error.maxAmount && savingsAccount.savingsOrderForm.amount.$dirty">
                            <span translate="termDeposit.wrongMaxDepositAmount"></span>
                            {{ 99999999999.99 | currency: ""}} {{savingsAccount.model.currency}}.
                </span>
              </div>

              <div class="col-md-6 col-xs-12">
                <label class="ntd-form-label compact"
                       translate="termDeposit.totalInterestRateInputLabel">
                </label>
                <input type="text" class="form-control t-a-r  h-45" readonly
                       ng-value="(savingsAccount.model.totalInterestRate | currency:'': 3) + ' %'">
              </div>
            </div>

            <div class="form-group m-t-10" ng-if="savingsAccount.selectedSavingTypesGroupId != 2">
              <div class="col-md-6 col-xs-12"
                   ng-if="savingsAccount.model.savingsType.savingType != 5 && savingsAccount.assignmentOfInterestisShown">
                <label class="ntd-form-label compact"
                       translate="termDeposit.assignmentOfInterest">
                </label>
                <select class="form-control h-45"
                        ng-options="option.id as option.value | translate for option in savingsAccount.assignmentOfInterestOptions"
                        ng-model="savingsAccount.assignmentOfInterestId"
                        ng-disabled="savingsAccount.assignemntOfInterestIsDisabled">
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-10" ng-hide="savingsAccount.savingsPeriodType == 'K'">
          <div class="col-md-5 col-xs-12">
            <label class="ntd-form-label compact"
                   translate="savingsOrder.orderPaymentDay">
            </label>
            <select class="form-control h-45" ng-model="savingsAccount.model.standingOrderPayment"
                    ng-options="option.name for option in savingsAccount.standingOrderPaymentDayOptions"
                    ng-change="savingsAccount.onStndOrderPaymentDayChange(savingsAccount.model.standingOrderPayment.value)">
            </select>
          </div>
          <div class="col-md-7 col-xs-md-12">
            <div class="form-group col-md-6 col-xs-12">
              <label class="ntd-form-label compact"
                     translate="savingsOrder.firstPayment">
              </label>
              <input type="text" class="form-control h-45"
                     ng-value="savingsAccount.model.firstPaymentDate | date: 'shortDate' : '+0200'" readonly>
            </div>
            <div class="form-group col-md-6 col-xs-12">
              <label class="ntd-form-label compact"
                     translate="savingsOrder.lastPayment">
              </label>
              <input type="text" class="form-control h-45"
                     ng-value="savingsAccount.model.lastPaymentDate | date: 'shortDate' : '+0200'" readonly>
            </div>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-12">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
              <input id="userApproves" type="checkbox" ng-model="savingsAccount.userApproves" required>
              <i class="input-helper"></i>
              <span translate="savingsOrder.termsAndConditions"></span>
              <a class="underline"
                 href="
                 {{(savingsAccount.savingTypesGroup[0].savingGroup == 1) ? 'https://www.addiko.si/splosni-pogoji-addiko-varcevalnega-racuna/' :
                   (savingsAccount.savingTypesGroup[0].savingGroup == 2 || savingsAccount.savingTypesGroup[0].savingGroup == 3) ? 'https://www.addiko.si/splosni-pogoji-addiko-varcevanj/' :''
                  }}"
                 target="_blank"
                 translate="savingsOrder.termsAndConditions1"></a>
              <!--<span ng-if="savingsAccount.savingTypesGroup[0].savingGroup == 3" translate="savingsOrder.termsAndConditions1"></span>-->
              <span translate="savingsOrder.termsAndConditions2"></span>
              <span ng-if="savingsAccount.selectedSavingTypesGroupId != 1"
                    translate="savingsOrder.termsAndConditions3AddikoPlus"></span>
            </label>

          </div>
        </div>
      </div>


      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-xs-6 t-a-l">
            <button type="button" class="btn btn-primary"
                    translate="termDeposit.cancelBtnLabel"
                    data-ui-sref="depositAccountsOverview">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button type="button" class="btn btn-default"
                    translate="orders.next"
                    ng-click="savingsAccount.next();"
                    ng-disabled="savingsAccount.nextButtonPressed || (!savingsAccount.savingsOrderForm.$valid || !savingsAccount.userApproves)">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 2 ==== -->
    <!-- ================= -->
    <div class="clearfix bg-white" ng-if="savingsAccount.steps.step2">
      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="savingsOrder.savingsType"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.model.savingsType.savingDescription}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="savingsOrder.dateOfOpening"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.model.dateOfOpening | date:'shortDate' : '+0200'}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="savingsOrder.fromAccount"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.responseModel.fromAccountSelect.iban | formatIban}}
            </label>
          </div>
        </div>
        <div class="row" ng-if="savingsAccount.selectedSavingTypesGroupId != 1 && savingsAccount.selectedSavingTypesGroupId != 2">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.maturityDate"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.responseModel.maturityDate | date: 'shortDate' : '+0200'}}
            </label>
          </div>
        </div>
        <div class="row" ng-if="savingsAccount.selectedSavingTypesGroupId != 2">
          <div class="col-md-4">
             <span ng-switch="savingsAccount.model.savingsType.periodType">
                  <label class="ntd-form-label compact" ng-switch-when="K"
                         translate="savingsOrder.initialDepositAmount"></label>
                   <label class="ntd-form-label compact" ng-switch-when="D"
                          translate="savingsOrder.monthlyDepositAmount"></label>
              <label class="ntd-form-label compact" ng-switch-default
                     translate="savingsOrder.monthlyDepositAmount"></label>
            </span>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.responseModel.amount | currency: ""}} {{savingsAccount.responseModel.currency}}
            </label>
          </div>
        </div>
        <div class="row" ng-if="savingsAccount.model.savingsType.savingOrderTypeCalculationList.length && savingsAccount.selectedSavingTypesGroupId != 1 
                                                                                                       && savingsAccount.selectedSavingTypesGroupId != 2">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.realRate"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.model.savingsType.savingOrderTypeCalculationList[0].realRate | currency: "":3}} %
            </label>
          </div>
        </div>
        <div class="row" ng-if="savingsAccount.model.savingsType.savingOrderTypeCalculationList.length && savingsAccount.selectedSavingTypesGroupId != 1
                                                                                                       && savingsAccount.selectedSavingTypesGroupId != 2">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.referenceRate"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.model.savingsType.savingOrderTypeCalculationList[0].referenceRateAmount | currency: "":3}} %
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.totalInterestRateInputLabel"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{savingsAccount.model.totalInterestRate | currency: "":3}} %
            </label>
          </div>
        </div>
        <div ng-if="savingsAccount.model.savingsType.savingType != 1 && savingsAccount.model.savingsType.savingType != 5 && savingsAccount.selectedSavingTypesGroupId != 2">
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="termDeposit.assignmentOfInterest"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{savingsAccount.model.interestRange.value | translate}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="savingsOrder.firstPayment"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{savingsAccount.model.firstPaymentDate | date: 'shortDate' : '+0200'}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="domp-form-label" translate="savingsOrder.lastPayment"></label>
            </div>
            <div class="col-md-8">
              <label class="domp-form-label-resume">
                {{savingsAccount.model.lastPaymentDate | date: 'shortDate' : '+0200'}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="intp-form-container">
        <div class="row">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary"
                    translate="orders.back"
                    ng-click="savingsAccount.back()">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button class="btn btn-default"
                    translate="termDeposit.confirmBtnLabel"
                    ng-disabled="savingsAccount.confirmButtonPressed"
                    ng-click="savingsAccount.confirm();">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 3 ==== -->
    <!-- ================= -->
    <div ng-if="savingsAccount.steps.step3">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-10 m-t-30 m-b-30">
            <h2 class="c-white f-28" translate="orders.successOrder"></h2>
            <!--<div class="f-16">-->
            <!--<span translate="eInvoicesSubscribe.applicationCompleteText" translate-values="vm.issuer"></span>-->
            <!--</div>-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a href="" data-ng-click="savingsAccount.newSavingsOrder()">
            <span class="money-exchange-icon"><i class="icon icon-einvoice zmdi-hc-2x"></i></span>
            <span class="f-18 money-exchange-text" translate="savingsOrder.newSavingsOrder"></span>
          </a>
        </div>
      </div>
    </div>

    <div class="m-t-30" ng-if="savingsAccount.steps.step1"
         data-ng-include="'orders/savings-order-overview-timeline.tpl.html'">
    </div>
  </div>
</section>
