<div class="statementModal" style="padding: 50px">
  <div class="modal-body p-0">
    <!--    X
        <div class="row">
          <div class="col-md-12">
            <div  style="color: white; padding-top: 50px;">
              <i class="icon icon-add pull-right close-icon-x" ng-click="$close()"></i>
            </div>
          </div>
        </div>
        END X-->
    <!--    <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="logout-modal-header" translate="invest.buyInvestmentPopup">Do you really want to log out?</span>
            </div>

        </div>-->
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <h4 class="investment-confirm-modal-header">
          {{ 'invest.buyInvestmentPopup1' | translate : {invFund : investmentFundName} }}
        </h4>
        <h4 class="investment-confirm-modal-header" translate="invest.buyInvestmentPopup2"></h4>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary-red pull-right statementBtnClose" ng-click="$close(true)" translate="core.sweetAlertTranslation.yes"></button>
    <button class="btn btn-primary-red pull-left  statementBtnClose" ng-click="$close()" translate="core.sweetAlertTranslation.no"></button>
  </div>
</div>
