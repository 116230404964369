(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:CashWithdrawalCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('CashWithdrawalCtrl', CashWithdrawalCtrl);

  function CashWithdrawalCtrl($rootScope, $q, $localStorage, $state, $stateParams, $translate, AccountsService, HelperService, ValidationService,
                              PaymentTypesService, BanksService, AlertService, NotificationService) {

    var vm = this;
    var editMode = false;
    var signMode = false;
    var sweetAlertOptions = {};
    var allowedCurrenciesSlo = ["EUR", "HRK", "CHF", "GBP", "USD"];
    var tomorrowDate;
    vm.amountInputSettings = {
      maxLength: 12,
      maxAmount: null
    };


    var withdrawalTimesEnglish = [
      { id: 1, value: "cashWithdrawalTranslate.until", name: "Until 13:00" },
      { id: 2, value: "cashWithdrawalTranslate.after", name: "After 13:00" }
    ];

    var withdrawalTimesSlovenian = [
      { id: 1, value: "cashWithdrawalTranslate.until", name: "Do 13. ure" },
      { id: 2, value: "cashWithdrawalTranslate.after", name: "Po 13. uri" }
    ];

    vm.withdrawalTimes = [
      { id: 1, value: "cashWithdrawalTranslate.until"},
      { id: 2, value: "cashWithdrawalTranslate.after"}
    ];
    vm.withdrawalTimeId = vm.withdrawalTimes[0].id;

    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

    if(vm.countryCode == 'sl'){
      vm.amountInputSettings.maxAmount = 99999999999.99;
      vm.amountInputSettings.maxLength = 14;
    }

    var setLimitForSLO = function () {
      if(vm.countryCode == 'sl'){
        if(vm.model.currencySymbol == "EUR") {
          vm.minAmount = 3000;
        } else {
          vm.minAmount = 0.01;
        }
      }
    };

    vm.formatIban = HelperService.formatIban;

    init();
    function init() {
      vm.minAmount = 100.00;
      vm.maxlength = null;


      tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 3));

      // if(vm.countryCode == 'sl') {
      //     tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 2));
      // }

      if (tomorrowDate.getDay() == 0) {
        tomorrowDate = tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      }
      else if (tomorrowDate.getDay() == 6) {
        tomorrowDate = tomorrowDate.setDate(tomorrowDate.getDate() + 2);
      }


      vm.model = {
        fromAccountNumber: "",
        amount: "",
        currencySymbol: "",
        withdrawalNotes: "",
        withdrawalDate: new Date(tomorrowDate),
        withdrawalTime: "",
        branchId: "",
        note: " ",
        phoneNumber: ""
      };

      // Response that will be returned after update
      vm.updateResponse = {};

      vm.datePickerOptions = {
        minDate: new Date(tomorrowDate),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
      };
      // Regex for input validation
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
      translateSweetAlert();
      initAllData();
    }

    var selectedLanguage = $localStorage.appLanguage;
    $rootScope.$on('$translateChangeEnd', function (evt, data) {
      selectedLanguage = data.language;
      // Change if language is change on Withdrawal resume!
      if(selectedLanguage == "en") {
        vm.updateResponse.withdrawalTime = _.find(withdrawalTimesEnglish , {id: vm.withdrawalTimeId}).name;
      } else if (selectedLanguage == "sl") {
        vm.updateResponse.withdrawalTime = _.find(withdrawalTimesSlovenian , {id: vm.withdrawalTimeId}).name;
      }
    });

    vm.onTimeOfPayoutChange = function () {
      if (vm.withdrawalTimeId == 1) {
        tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 3));
      } else {
        tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 2));
      }

      if (tomorrowDate.getDay() == 0) {
        tomorrowDate = tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      }
      else if (tomorrowDate.getDay() == 6) {
        tomorrowDate = tomorrowDate.setDate(tomorrowDate.getDate() + 2);
      }

      vm.datePickerOptions.minDate = new Date(tomorrowDate);

      // if(vm.model.withdrawalDate.getTime() <= vm.datePickerOptions.minDate.getTime()) {
        vm.model.withdrawalDate = new Date(tomorrowDate);
      // }



    };

    function initAllData() {
      var promises = [];
      promises.push(AccountsService.getPage({accountTypeGroupList: 'CurrentAccount'}));
      promises.push(BanksService.getPage({pageSize: 10000, isValidForCashWithdrawal: "Y"}));
      // Init after "resume" refresh
      // if ($state.params.cashWithdrawalId) promises.push(AccountsService.getCashWithdrawalOrder($state.params.cashWithdrawalId));

      $q.all(promises)
        .then(function (responses) {

          // 1. response
          vm.accountList = responses[0].result;
          if (vm.accountList.length) {
            _.each(vm.accountList, function (account) {
              account.optionValue = account.accountDescription + ' - ' + account.iban;
            });

            if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
              vm.accountList = _.filter(vm.accountList, function (account) {
                return _.includes(allowedCurrenciesSlo, account.currencySymbol);
              });
            }

            vm.selectedAccount = _.find(vm.accountList, {accountId: $stateParams.accountId});
            if (!vm.selectedAccount) {
              vm.selectedAccount = vm.accountList[0];
            }
            vm.selectAccount(vm.selectedAccount);
          }

          //  2. response
          vm.branchList = responses[1].result;


        }, function (error) {
          // HANDLE ERROR
        })
    }

    function translateSweetAlert() {
      $translate(["core.sweetAlertTranslation.yes", "core.sweetAlertTranslation.no", "core.sweetAlertTranslation.areYouSure"]).then(function (translations) {
        sweetAlertOptions.yes = translations["core.sweetAlertTranslation.yes"];
        sweetAlertOptions.no = translations["core.sweetAlertTranslation.no"];
        sweetAlertOptions.areYouSure = translations["core.sweetAlertTranslation.areYouSure"];
      });
    }

    /*
     * Disable weekends in datepicker
     * */
    vm.dateDisabled = function (obj) {
      return ( obj.mode === 'day' && ( obj.date.getDay() === 0 || obj.date.getDay() === 6 ) );
    };


    vm.selectAccount = function (account) {
      if (!account) return;
      vm.selectedAccount = account;
      vm.model.fromAccountNumber = account.accountNumber;
      vm.model.currencySymbol = account.currencySymbol;
//      vm.validateAmount();

        if(vm.model.currencySymbol == "EUR") {
          vm.minAmount = 5001.00;
        } else if (vm.model.currencySymbol == "HRK") {
          vm.minAmount = 35001.00;
        } else {
          vm.minAmount = 100.00;
        }
        setLimitForSLO();
    };

    vm.confirmCashWithdrawing = function () {
      vm.formSubmitted = true;
      if (!vm.cashWithdrawalForm.$valid) {
        return;
      }
      vm.confirmCashWithdrawalBtnDisabled = true;

      if(selectedLanguage == "en") {
        vm.model.withdrawalTime = _.find(withdrawalTimesEnglish , {id: vm.withdrawalTimeId}).name;
      } else if (selectedLanguage == "sl") {
        vm.model.withdrawalTime = _.find(withdrawalTimesSlovenian , {id: vm.withdrawalTimeId}).name;
      }


      if(vm.countryCode != 'sl'){
        vm.model.withdrawalTime = '';
      }
      if (!editMode) {
        AccountsService.confirmCashWithdrawalOrder(vm.model)
          .then(function (response) {
            vm.updateResponse = response;
            // Remap iban (because it's not returned from response)
            vm.updateResponse.iban = vm.selectedAccount.iban;
            vm.confirmCashWithdrawalBtnDisabled = false;

            editMode = true;
            if(!vm.updateResponse.branchName){
              // var branchName  = vm.branchList.find(function (item) {
              //   return item.id == vm.updateResponse.branchId;
              // });

              var branchName = _.find(vm.branchList, function (item) {
                return item.id == vm.updateResponse.branchId;
              });

              vm.updateResponse.branchName = branchName.name;
            }
            $state.go('cashWithdrawalPage.resume', {editMode: editMode});

          }, function (error) {
            vm.confirmCashWithdrawalBtnDisabled = false;
            NotificationService.showMessage(error, 'error');
          });
      } else {
        AccountsService.updateCashWithdrawalOrder(vm.updateResponse.id, vm.model)
          .then(function (response) {
            vm.updateResponse = response;
            vm.updateResponse.iban = vm.selectedAccount.iban;
            vm.confirmCashWithdrawalBtnDisabled = false;
            var branchName  = vm.branchList.find(function (item) {
              return item.id == vm.updateResponse.branchId;
            });
            vm.updateResponse.branchName = branchName.name;

            $state.go('cashWithdrawalPage.resume', {editMode: editMode});
          }, function (error) {
            vm.confirmCashWithdrawalBtnDisabled = false;
            NotificationService.showMessage(error, 'error');
          })
      }
    };


    vm.editCashWithdrawing = function () {
      $state.go('cashWithdrawalPage.form');
    };

    vm.deleteCashWithdrawing = function () {
      //swal({
      //    title: sweetAlertOptions.areYouSure,
      //    type: "info",
      //    showCancelButton: true,
      //    confirmButtonClass: "btn-default",
      //    confirmButtonText: sweetAlertOptions.yes,
      //    cancelButtonText: sweetAlertOptions.no,
      //    closeOnConfirm: true,
      //    closeOnCancel: true
      //  },
      //  function (isConfirm) {
      //    if (isConfirm) {
      //      vm.deleteCashWithdrawingBtnDisabled = true;
      //      AccountsService.deleteCashWithdrawalOrder(vm.updateResponse.id)
      //        .then(function (response) {
      //          vm.deleteCashWithdrawingBtnDisabled = false;
      //          $state.go("accounts", {accountId: vm.selectedAccount.accountId});
      //        }, function (error) {
      //          vm.deleteCashWithdrawingBtnDisabled = false;
      //        })
      //    }
      //  });

      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            vm.deleteCashWithdrawingBtnDisabled = true;
            AccountsService.deleteCashWithdrawalOrder(vm.updateResponse.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({paymentName: vm.updateResponse.id, type: 'payment'})
                  .then(function () {
                    vm.deleteCashWithdrawingBtnDisabled = false;
                    $state.go("accounts", {accountId: vm.selectedAccount.accountId});
                  });
              }, function (error) {
                vm.deleteCashWithdrawingBtnDisabled = false;
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    vm.cancelCashWithdrawing = function () {
      $state.go("accounts", {accountId: vm.selectedAccount.accountId})
    };

    vm.sendCashWithdrawing = function () {

      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        vm.signCashWithdrawingBtnDisabled = true;
        AccountsService.signCashWithdrawalOrder({id: vm.updateResponse.id},vm.updateResponse)
          .then(function (response) {
            if(vm.countryCode == 'sl'){
              AlertService.infoAlert({
                text: "alert.successSignSlo"
              })
                .then(function (){
                  vm.signCashWithdrawingBtnDisabled = false;
                  $state.go("accounts", {accountId: vm.selectedAccount.accountId})
                });
            }else{
              AlertService.infoAlert({
                text: "alert.successSign"
              })
                .then(function (){
                  vm.signCashWithdrawingBtnDisabled = false;
                  $state.go("accounts", {accountId: vm.selectedAccount.accountId})
                });
            }

          }, function (error) {
            vm.signCashWithdrawingBtnDisabled = false;
            NotificationService.showMessage(error, 'error');
          });
        return;
      }

      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            vm.signCashWithdrawingBtnDisabled = true;
            AccountsService.signCashWithdrawalOrder({id: vm.updateResponse.id},vm.updateResponse)
              .then(function (response) {
                if(vm.countryCode == 'sl'){
                  AlertService.infoAlert({
                    text: "alert.successSignSlo"
                  })
                    .then(function (){
                      vm.signCashWithdrawingBtnDisabled = false;
                      $state.go("accounts", {accountId: vm.selectedAccount.accountId})
                    });
                }else{
                  AlertService.infoAlert({
                    text: "alert.successSign"
                  })
                    .then(function (){
                      vm.signCashWithdrawingBtnDisabled = false;
                      $state.go("accounts", {accountId: vm.selectedAccount.accountId})
                    });
                }

              }, function (error) {
                vm.signCashWithdrawingBtnDisabled = false;
                NotificationService.showMessage(error, 'error');
              })
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });

    }
  }
}());
