(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "With Viber application you can activate Addiko Chat Banking service",
        activationWay: "Choose the method of activation",
        scanQrCode: "Scan QR code",
        activationCode: "Activation code",
        agree: "I agree with Terms and Conditions of the Addiko Ebank Service",
        activate: "Activate",
        deactivateText: "Your account is active",
        deactivate: "Deactivate",
        deactivateModalText: "You are going to deactivate Addiko Chat Banking service. Are you sure?",
        cancel: "Cancel",
        activationSuccess: "Viber service deactivated successfully!",
        conditionHeader: "Straightforward and agile banking on Viber",
        conditionHeader2: "Addiko Chat Banking enables:",
        viberCondition1: "The User understands and confirms that the Addiko EBank General Terms and Conditions of Addiko Bank",
        viberCondition2: "are applicable also on the  Viber payments service. The Viber order, activated by the User with the purpose of accessing the service, shall be used in the manner envisaged by Terms and Conditions and ",
        viberCondition3: "policy of the Viber application ",
        viberCondition4: "with which the User has already agreed during registration to the Viber service.",
        scanQrCodeC1: "Open Viber (choose options 'More', 'QR code scanner') and scan presented QR code.",
        scanQrCodeC2: "QR scanning will automatically open chat on public Viber account of Addiko bank and fulfill Viber message with activation code that you have scanned. Send Viber message with activation code.",
        scanQrCodeC3: "You will receive SMS with verification code on the phone number registered at the Bank for ebanking/mbanking service.",
        scanQrCodeC4: "Enter (or copy) verification code from SMS message into Viber message and send it.",
        scanQrCodeC5: "Create your 4-digit PIN and repeat entering to confirm it. Entering PIN number should be done one by one digit.",
        scanQrCodeC6: "Once created, this PIN will be used to access Addiko Chat Banking service and confirm transactions initiated from this channel.",
        activationCodeC1: "Open your Viber application, go to Public Accounts option and search for Addiko bank public account. Choose option to become follower.",
        activationCodeC2: "Run Chat option with Addiko bank Viber account.",
        activationCodeC3: "In the bottom of the Viber screen, you will find Addiko Chat Banking menu options. Choose “Aktiviraj” (Activate).",
        activationCodeC4: "Enter activation code from Addiko Ebank activation page into Viber message; send Viber message with activation code.",
        activationCodeC5: "You will receive SMS with verification code on the phone number registered at the Bank for ebanking/mbanking service.",
        activationCodeC6: "Enter (or copy) verification code from SMS message into Viber message and send it.",
        activationCodeC7: "Create your 4-digit PIN and repeat entering to confirm it. Entering PIN number should be done one by one digit.",
        activationCodeC8: "Once created, this PIN will be used to access Addiko Chat Banking service and confirm transactions initiated from this channel.",
        yourActivationCode: "Your activation code:",
        scanQrCodeC7: "Find the Addiko Bank's public account (Addiko Bank Hrvatska) in the Public Accounts area on Viber and follow us!",
        scanQrCodeC8: "Scan this QR code using your Viber app ('More – QR Code Scanner' available in the Viber menu). After successfully scanning the code, you will be redirected to the Addiko Bank's public account on Viber and the activation code is automatically going to be inserted in the Viber message area. Send the message containing the activation code. ",
        activationCodeC9: "Find the Addiko Bank's public account (Addiko Bank Hrvatska) in the Public Accounts area on Viber and follow us!",
        activationCodeC10: "Initiate the chat by tapping the option available in the upper right corner of the Viber app. In the bottom of the device on which you use Viber, there are options available for the service of Addiko Chat Banking.  Tap on the 'Aktiviraj' option (Activate). Enter the activation code shown above and send the message.",
        additionalActivation1: "After sending the activation code, you are going to receive a verification code via SMS. The message is going to be sent to the mobile phone number that you have most recently provided to the Bank. Copy the verification code from the SMS into the Viber chat message and send it.",
        additionalActivation2: "Create your 4-digit PIN. For successful completion of the activation process, you are going to be prompted to enter this PIN twice in order to confirm it. ",
        additionalActivation3: "MEMORIZE this PIN since you are going to use it to access the Addiko Chat Banking service on Viber as well as for approving the transactions conducted via Addiko Chat Banking.      ",
        step1: 'Become a follower by clicking the „Follow“ button. You will be redirected to the Bank’s Public Account on Viber.',
        step2: 'Go back to EBank. Start the service by clicking the "Activate" button.',
        step3: 'You have received a sms message. Click the activation link and you will be redirected to the Bank’s Public Account',
        step4: 'Set up your PIN on Addiko Chat Banking. You will use it for signup and payment confirmations.',
        step1othBL: "Please select the 'Follow' button. You are going to receive an SMS with a link that will direct you to the Addiko Bank's Banja Lika public account on Viber. When in Viber application, choose the option 'Follow' to become a follower of our public account.",
        step1othSA: "Please select the 'Follow' button. You are going to receive an SMS with a link that will direct you to the Addiko Bank's Sarajevo public account on Viber. When in Viber application, choose the option 'Follow' to become a follower of our public account.",
        step1oth: 'Please select the "Follow" button. You are going to receive an SMS with a link that will direct you to the Addiko Bank\'s public account on Viber. When in Viber application, choose the option "Follow" to become a follower of our public account.',
        step2oth: "Return to EBank and proceed with the activation process by selecting the 'Activate' button. You are going to receive an SMS with an activation link containing activation code directing you to the Addiko Chat Banking on Viber. Send the activation code through the Viber application.",
        step3oth: 'Upon successful confirmation of the activation code, you are going to receive another SMS with a one-time password. Copy the password in the message field within Addiko Chat Banking and send it through the Viber application.',
        step4oth: 'After successfully validating your one-time password, create your PIN within Addiko Chat Banking. You are going to use this PIN for login and confirmation of payments initiated  via Addiko Chat Banking.',

        step1hr: 'Please select the "Follow" button. You are going to receive an SMS with a link that will direct you to the Addiko Bank\'s public account on Viber. When in Viber application, choose the option "Follow" to become a follower of our public account.',
        step2hr: "Return to EBank and proceed with the activation process by selecting the 'Activate' button. You are going to receive an SMS with an activation link containing activation code directing you to the Addiko Chat Banking on Viber. Send the activation code through the Viber application.",
        step3hr: 'Upon successful confirmation of the activation code, you are going to receive another SMS with a one-time password. Copy the password in the message field within Addiko Chat Banking and send it through the Viber application.',
        step4hr: 'After successfully validating your one-time password, create your PIN within Addiko Chat Banking. You are going to use this PIN for login and confirmation of payments initiated  via Addiko Chat Banking.',
        step1BL:"Become a follower by clicking the „Follow“ button. You will be redirected to the Bank’s Public Account on Viber.",
        step1SA:"Become a follower by clicking the „Follow“ button. You will be redirected to the Bank’s Public Account on Viber.",
        step1sr: "Please select the 'Follow' button. You are going to receive an SMS with a link that will direct you to the Addiko Bank's public account on Viber. When in Viber application, choose the option 'Follow' to become a follower of our public account.",
        step2sr: "Return to EBank and proceed with the activation process by selecting the 'Activate' button. You are going to receive an SMS with an activation link containing activation code directing you to the Addiko Chat Banking on Viber. Send the activation code through the Viber application.",
        step3sr: 'Upon successful confirmation of the activation code, you are going to receive another SMS with a one-time password. Copy the password in the message field within Addiko Chat Banking and send it through the Viber application.',
        step4sr: 'After successfully validating your one-time password, create your PIN within Addiko Chat Banking. You are going to use this PIN for login and confirmation of payments initiated  via Addiko Chat Banking.',
        step1BLbh: "Please select the 'Follow' button. You are going to receive an SMS with a link that will direct you to the Addiko Bank's Banja Lika public account on Viber. When in Viber application, choose the option 'Follow' to become a follower of our public account.",
        step1SAbh: "Please select the 'Follow' button. You are going to receive an SMS with a link that will direct you to the Addiko Bank's Sarajevo public account on Viber. When in Viber application, choose the option 'Follow' to become a follower of our public account.",
        step2bh: 'Return to EBank and proceed with the activation process by selecting the "Activate" button. You are going to receive an SMS with an activation link containing activation code directing you to the Addiko Chat Banking on Viber. Send the activation code through the Viber application.',
        step3bh: 'Upon successful confirmation of the activation code, you are going to receive another SMS with a one-time password. Copy the password in the message field within Addiko Chat Banking and send it through the Viber application.',
        step4bh: 'After successfully validating your one-time password, create your PIN within Addiko Chat Banking. You are going to use this PIN for login and confirmation of payments initiated  via Addiko Chat Banking.',
        text1: 'payments and balance insight',
        text2: 'payments overview',
        text3: 'locating nearest branch or ATM',
        text4: 'information about the current offer',
        activeMessage: "Your Addiko Chat Banking account is active.",
        mobileNumber: 'Phone number which is linked to service:',
        activationDate: 'Service Activation Date:',
        becomeFollower: 'Become a follower',
        successfulActivation: 'Addiko Chat Banking successfully activated',
        unsuccessfulActivation: 'Bezuspješna aktivacija',
        smsSent: 'Sms message successfully sent. Please click on link we sent in message.',
        successFollower: 'You just became a follower of our Addiko Viber Account. Welcome!',
        becomeFollowerAlert: "If you already are a follower of Addiko public account on Viber, this step is not necessary. Would you like to skip this step and continue the activation process?",
        becomeFollowerAlertBL: "If you already are a follower of Addiko public account on Viber, this step is not necessary. Would you like to skip this step and continue the activation process?",
        becomeFollowerAlertSA: "If you already are a follower of Addiko public account on Viber, this step is not necessary. Would you like to skip this step and continue the activation process?",
        yes: "Yes",
        no: "No",
        agree2: "I have read and agree with the Statement on personal data protection",
        personalData: "I hereby confirm that by accepting this Statement I authorise Addiko Bank d.d. to use my personal data, which I have already provided to the Bank when applying for the Addiko EBank Internet Banking service, within the scope of the Viber Addiko Chat Banking service. My Viber identification data (Viber ID, Viber Username) provided to the Bank at the moment of me becoming the follower of the Viber Addiko public account, may also be used by the Bank for the same purpose. I hereby confirm that I have voluntarily made the aforementioned data available to the Bank and that these data may be used solely for the purpose for which they have been provided, i.e. for the usage of the Viber Addiko Chat Banking service.",
        personalData1: "The Bank does not sell, rent or lend the personal data of its clients to any third party and it protects my personal data from an unauthorized access. The Bank may forward my personal data to its trusted business partners for specific purposes such as various statistical analyses, enabling client support, review of client satisfaction with the Bank's products or similar purposes. In such cases, the Bank shall forbid its business partner to use my personal data for any purpose other than the contracted one and it shall oblige its business partner to keep the personal data confidential."
      }
    };

    $translateProvider.translations('en', data);

  }
}());

