<div class="intp-form-container light-bcg bottom-border">
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.debitAccountSelectLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume">
            {{termDeposit.tempTermDepositObj.fromAccountSelect.friendlyName}} <br/> {{termDeposit.tempTermDepositObj.fromAccountSelect.iban}}
        </div>
    </div>
</div>
<div class="intp-form-container bottom-border">
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.amountInputLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume">
            {{termDeposit.insertDepositOrderResponse.amount | number:2}} {{termDeposit.tempTermDepositObj.fromAccountSelect.currencySymbol}}
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.stimulativeInterestRateInputLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume">
            {{termDeposit.tempTermDepositObj.stimulativeInterestRate}} %
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.totalInterestRateInputLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            {{termDeposit.tempTermDepositObj.totalInterestRate | formatDecimal : 4 : true : 3}} %
        </div>
        <div class="col-sm-8 domp-form-label-resume" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
          {{termDeposit.tempTermDepositObj.totalInterestRate}} %
      </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.maturityInMonthsSelectLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume">
            {{termDeposit.tempTermDepositObj.maturityInMonths.periodFrom}}
        </div>
    </div>
</div>
<div class="intp-form-container light-bcg bottom-border">
    <div class="row">
        <div class="col-sm-4">
          <label class="domp-form-label-review" translate="termDeposit.interestRatePayoutTypeSelectLabel"></label>
        </div>
      <div class="col-sm-8 domp-form-label-resume">
            {{termDeposit.selectedTermDepositPayoutType.description|translate}}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="m-t-20 t-a-r">
                <button ng-if="vm.contryCode != 'sl'" class="btn btn-primary m-r-5" ng-click="termDeposit.editAction()" translate="termDeposit.editActionLabel">Edit</button>
                <button class="btn btn-primary m-r-30" ng-click="termDeposit.cancelAction()" translate="termDeposit.cancelActionLabel">Cancel</button>
                <button class="btn btn-default" ng-click="termDeposit.signAction()" translate="termDeposit.signActionLabel">Sign</button>
            </div>
        </div>
    </div>
</div>
