<section id="main">
  <div class="container payments">
    <div class="row">
      <div class="col-sm-4 no-padding-on-phone">
        <div class="app-white-card m-b-15">
          <div class="row no-margin">
            <div class="col-xs-6 text-center min-h-108 bottom-border r-border payment-link p-b-10 p-t-10">
              <a data-ui-sref="payments.internalTransfer">
                <div>
                  <i class="zmdi zmdi-flash zmdi-hc-2x"></i>
                </div>
                <div translate="payments.internalTransfer">
                </div>
              </a>
            </div>

            <div class="col-xs-6 text-center min-h-108 bottom-border payment-link p-b-10 p-t-10">
              <a data-ui-sref="payments.currencyConversion">
                <div>
                  <i class="zmdi zmdi-refresh zmdi-hc-2x"></i>
                </div>
                <div translate="payments.currencyConversion">
                </div>
              </a>
            </div>

            <div class="col-xs-6 text-center min-h-108 r-border payment-link p-b-10 p-t-10">
                <a data-ui-sref="payments.domesticPayment">
                <div>
                  <i class="zmdi zmdi-book zmdi-hc-2x"></i>
                </div>
                <div translate="payments.domesticPayments">
                </div>
              </a>
            </div>

            <div class="col-xs-6 text-center min-h-108 payment-link p-b-10 p-t-10" ng-if="payments.countryCode!='sr'">
              <a data-ui-sref="payments.internationalPayment">
                <div>
                  <i class="zmdi zmdi-globe zmdi-hc-2x"></i>
                </div>
                <div translate="payments.internationalPayment">
                </div>
              </a>
            </div>
            <div class="col-xs-6 text-center min-h-108 payment-link p-b-10 p-t-10" ng-if="payments.countryCode=='sr'">
              <a href="">
                <div>
                  <i class="zmdi zmdi-globe zmdi-hc-2x"></i>
                </div>
                <div translate="payments.internationalPayment">
                </div>
              </a>
            </div>

          </div>
        </div>

        <!-- E-Invoice list -->
        <div class="templates-list app-white-card m-b-15" ng-show="payments.countryCode!='sr' && payments.countryCode!=undefined">
          <div class="header-card row no-margin">
            <i class="zmdi zmdi-file-text zmdi-hc-2x p-r-10 col-sm-1 p-l-0"></i>
            <span class="col-sm-6 p-l-10" translate="payments.eInvoices"></span>
            <h5 class="col-sm-4 pull-right eim-sub-label" data-ui-sref="eInvoices.einvoicesIssuersOverview" translate="payments.subscribe"></h5>
            <!--<a href="" class="col-sm-9 pull-right c-white l-h-25 f-14" translate="payments.subscribe"></a>-->
          </div>

          <div class="list" scrollable>
            <div class="p-b-25" ng-show="payments.eInvoiceDocumentsList.result.length > 0">
              <div kcd-recompile="payments.eInvoiceDocumentsList.result">
                <div class="list-item inner-list-el"
                     data-ng-repeat="eInvoice in ::payments.eInvoiceDocumentsList.result"
                     data-ng-class="{'last-list-item': $last, 'list-colored-el': $odd}">
                  <div class="m-b-10">
                    <b>{{::eInvoice.creditorName}}</b>
                  </div>
                  <div class="m-b-10 wrap-text">
                    <a data-ui-sref="eInvoices.detail({eInvoiceId: eInvoice.id})">
                      {{::eInvoice.purpose}}
                    </a>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-xs-6 col-md-3">{{'payments.due' | translate}}{{::eInvoice.valueDate|
                      date:'shortDate' : '+0200' }}
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-5">
                      <b class="pull-right bold-font">{{::eInvoice.amount|number:2}} {{::eInvoice.currencySymbol}}</b>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-4 margin-top-btn">
                      <button class="btn btn-default app-btn-blue pull-right"
                              data-ng-click="payments.openEInvoicesModal(eInvoice)"
                              translate="payments.pay">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="t-a-c p-10 no-data-image" ng-hide="payments.eInvoiceDocumentsList.result.length > 0">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h5 class="no-data-label" translate="payments.noEinvoices"></h5>
            </div>

          </div>

         <!-- <div ng-show="payments.eInvoiceDocumentsList.hasMore"> -->
          <div ng-hide="payments.eInvoiceDocumentsList.result.length == 0">
             <a data-ui-sref="eInvoices.einvoicesOverview" class="lv-footer f-14" translate="payments.showMoreInvoices"></a>
          </div>

        </div>
      </div>

      <!-- Templates list -->
      <div class="col-sm-4 no-padding-on-phone">
        <div class="app-white-card templates-list m-b-15">
          <div class="header-card">
            <i class="zmdi zmdi-star zmdi-hc-2x cell-display"></i>
            <span class="cell-display align-vertical-middle p-l-10"
                  translate="payments.templates"></span>
          </div>
          <div class="form-group no-margin">
            <div class="input-group">
              <div class="input-group-btn srch-icon" style="border:1px solid lightgray; border-right:none;">
                <button class="btn btn-default" type="submit" style="box-shadow:none;">
                  <i class="icon icon-search"></i>
                </button>
              </div>
              <input type="text" class="form-control srch-input"
                     placeholder="{{'payments.searchTemplateName' | translate}}" name="srch-term" id="srch-term"
                     data-ng-model="searchTemplateText.name">
            </div>
          </div>
          <!--<div class="text-center">-->
          <!--<span class="pointer-cst" data-ng-click="payments.getTopUsedTemplates()"-->
          <!--data-ng-class="{'active-link': payments.topUsed}"-->
          <!--translate="payments.topUsed"></span>-->
          <!--<span class="pointer-cst" data-ng-click="payments.getTemplatesAlphabetically()"-->
          <!--data-ng-class="{'active-link': payments.templateAlphabetically}"-->
          <!--translate="payments.alphabetically">-->
          <!--</span>-->
          <!--</div>-->
          <div class="list" scrollable>
            <div class="p-b-25" ng-show="payments.templateList.result.length > 0">
              <div>
                <div class="list-item inner-list-el"
                     data-ng-repeat="templateItem in payments.templateList.result | filter: searchTemplateText"
                     data-ng-class="{'last-list-item': $last, 'list-colored-el': $odd}">
                  <div class="row m-b-10">
                    <div class="col-md-10 col-sm-10 col-xs-11">
                      <div class="wrap-text">
                        <a href="">
                          {{::templateItem.name}}
                        </a>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-2 col-xs-1">
                      <div class="dropdown">
                        <a href="" class="dropdown-toggle" data-toggle="dropdown">
                          <i class="zmdi zmdi-more-vert more-opt-hover"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right">
                          <li>
                            <a href="" class="f-13" data-ng-click="payments.deleteTemplate(templateItem.id);">
                              {{'payments.delete' | translate}}
                              <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                            </a>
                          </li>
                          <li>
                            <a href="" class="f-13" data-ng-click="payments.editTemplate(templateItem);">
                              {{'payments.edit' | translate}}
                              <i class="zmdi zmdi-edit zmdi-hc-lg m-l-15 pull-right"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-8">
                      <span>{{::templateItem.formType}}</span>
                  <span ng-show="templateItem.payeeName">
                    <span translate="payments.to"></span>
                    <b>{{::templateItem.payeeName}}</b>
                  </span>
                    </div>
                    <div class="col-sm-12 col-md-4 margin-top-btn">
                      <button class="btn btn-default app-btn-blue pull-right"
                              data-ng-click="payments.openTemplateModal(templateItem);"
                              translate="payments.pay">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="t-a-c p-10 no-data-image" ng-hide="payments.templateList.result.length > 0">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h5 class="no-data-label" translate="payments.noTemplates"></h5>
            </div>

          </div>

          <div ng-show="payments.templateList.hasMore">
            <a href="" class="lv-footer f-14" translate="payments.showMoreTemplates"
               data-ng-click="payments.loadMoreTemplates()">
            </a>
          </div>

        </div>

        <!-- SEPA direct debit -->
        <div class="templates-list app-white-card">
          <div class="header-card row no-margin">
            <i class="zmdi zmdi-folder zmdi-hc-2x col-sm-3 l-h-25"></i>
            <span class="col-sm-9 l-h-25"
                  translate="payments.sepaDirectDebit"></span>
            <!--<a href="" class="col-xs-3 col-sm-12 pull-right c-white l-h-25 f-14" translate="payments.settings"></a>-->
          </div>
          <div style="padding: 10px 15px;">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer scelerisque pretium porta.
            Curabitur blandit nibh sed nulla consectetur, porttitor placerat urna commodo. Vestibulum finibus mi risus,
            vitae tempor massa viverra sit amet. Fusce efficitur bibendum augue.
            Aenean lobortis laoreet massa, a lacinia nibh egestas quis. Nam id justo eget ex consequat iaculis.
            Sed quis nisi ante. Sed a dignissim ipsum.
          </div>

          <div ng-show="payments.templateList.hasMore">
            <a href="" class="lv-footer f-14" translate="payments.viewAllTransactions">
            </a>
          </div>

        </div>

      </div>

      <div class="col-sm-4 no-padding-on-phone">

        <div class="app-white-card payment-blue-box m-b-15">
          <div>
            <div class="cell-display">
                <a data-ui-sref="payments.paymentsOverview" translate="payments.allPastPayments">
              </a>
            </div>
            <div class="cell-display align-vertical-middle chavron-icon">
              <i class="zmdi zmdi-chevron-right zmdi-hc-2x"></i>
            </div>
          </div>

          <div>
            <div class="cell-display">
              <a data-ui-sref="payments.exchangeRates" translate="payments.exchangeRatesList">
              </a>
            </div>
            <div class="cell-display align-vertical-middle chavron-icon">
              <i class="zmdi zmdi-chevron-right zmdi-hc-2x"></i>
            </div>
          </div>

          <div>
            <div class="cell-display">
              <a href="" translate="payments.donationToCharity">
              </a>
            </div>
            <div class="cell-display align-vertical-middle chavron-icon">
              <i class="zmdi zmdi-chevron-right zmdi-hc-2x"></i>
            </div>
          </div>

        </div>

        <!--<div class="wb-list-wrapper app-white-card m-b-15">-->
          <!--<a href="">-->
            <!--<div class="wb-list-item-lg">-->
              <!--<span class="m-l-15 f-14" translate="payments.allPastPayments">-->
              <!--</span>-->
              <!--<i class="zmdi zmdi-chevron-right pull-right"></i>-->
            <!--</div>-->
          <!--</a>-->
          <!--<hr class="m-b-5 m-t-5">-->
          <!--<a data-ui-sref="payments.exchangeRates">-->
            <!--<div class="wb-list-item-lg">-->
              <!--<span class="m-l-15 f-14" translate="payments.exchangeRatesList">-->
              <!--</span>-->
              <!--<i class="zmdi zmdi-chevron-right pull-right"></i>-->
            <!--</div>-->
          <!--</a>-->
          <!--<hr class="m-b-5 m-t-5">-->
          <!--<a href="">-->
            <!--<div class="wb-list-item-lg">-->
              <!--<span class="m-l-15  f-14" translate="payments.donationToCharity">-->
              <!--</span>-->
              <!--<i class="zmdi zmdi-chevron-right pull-right"></i>-->
            <!--</div>-->
          <!--</a>-->

        <!--</div>-->

        <!--Rejected payments list-->
        <div class="app-white-card rejected-payment-box m-b-15">
          <div class="row no-margin header-card bg-danger">
            <div class="col-sm-7 col-xs-6">
              <i class="zmdi zmdi-alert-triangle zmdi-hc-2x cell-display c-white"></i>
            <span class="cell-display align-vertical-middle p-l-10 f-14 c-white"
                  translate="payments.rejectedPayments"></span>
            </div>

            <div class="col-sm-5 col-xs-6">
              <div class="dropdown acc-period-select pull-right">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                  {{payments.rejectedPeriod.item.description}}
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li data-ng-repeat="period in payments.periodList">
                    <a href="" class="f-13" data-ng-click="payments.rejectedPaymentsTimeFilter(period);">
                      {{period.item.description}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="list" scrollable>
            <div class="p-b-25" ng-show="payments.rejectedPaymentsList.result.length > 0">
              <div kcd-recompile="payments.rejectedPaymentsList.result">
                <div class="list-item inner-list-el"
                     data-ng-repeat="rejectedItem in ::payments.rejectedPaymentsList.result"
                     data-ng-class="{'last-list-item': $last, 'list-colored-el': $odd}">

                  <div class="row m-b-10">
                    <div class="col-md-10 col-sm-10 col-xs-11">
                      <div class="wrap-text">
                        <a href="">
                          {{::rejectedItem.purpose}}
                        </a>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-1">
                      <div class="dropdown">
                        <a href="" class="dropdown-toggle" data-toggle="dropdown">
                          <i class="zmdi zmdi-more-vert more-opt-hover"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right">
                          <li data-ng-repeat="actionObject in rejectedItem.allowedActionList.itemList">
                            <div ng-switch on="actionObject.methodName">
                              <a ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed" class="f-13" data-ng-click="payments.signPayment(rejectedItem.id);">
                              {{'payments.delete' | translate}}
                              <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                            </a>
                              <a ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed" class="f-13" data-ng-click="payments.deletePayment(rejectedItem.id);">
                                {{'payments.delete' | translate}}
                                <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                              </a>
                              <a ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed" class="f-13" data-ng-click="payments.recallPayment(rejectedItem.id);">
                                {{'payments.delete' | translate}}
                                <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="m-b-10">
                  <span>
                    {{::rejectedItem.valueDate | date: 'shortDate' : '+0200'}}
                  </span>
                  <span ng-show="rejectedItem.formType">
                    {{::rejectedItem.formType}}
                  </span>
                  <span ng-show="rejectedItem.amount">
                     of <b>{{::rejectedItem.amount | number:2}} {{::rejectedItem.currencySymbol}}</b>
                  </span>
                  <span ng-show="rejectedItem.receiverName">
                     to <b>{{::rejectedItem.receiverName}}</b>
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="t-a-c p-10 no-data-image" ng-if="payments.rejectedPaymentsList.result.length == 0">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h5 class="no-data-label" translate="payments.noRejectedPayments"></h5>
            </div>

          </div>

          <div ng-show="payments.rejectedPaymentsList.result.length > 0">
            <a href="" class="lv-footer f-14" translate="payments.moreRejected"
               data-ng-click="payments.loadMoreRejectedPayments();">
            </a>
          </div>

        </div>

        <!--Pending payments-->
        <div class="app-white-card pending-payment-box">
          <div class="row no-margin header-card bg-warning">
            <div class="col-sm-12">
              <i class="zmdi zmdi-hourglass-alt zmdi-hc-2x cell-display c-white"></i>
              <span class="cell-display align-vertical-middle p-l-10 f-14 c-white"
                    translate="payments.pendingPayments"></span>
            </div>
          </div>

          <div class="list" scrollable>
            <div class="p-b-25" ng-show="payments.pendingPaymentsList.result.length > 0">
              <div kcd-recompile="payments.pendingPaymentsList.result">
                <div class="list-item inner-list-el"
                     data-ng-repeat="pendingItem in ::payments.pendingPaymentsList.result"
                     data-ng-class="{'last-list-item': $last, 'list-colored-el': $odd}">

                  <div class="row m-b-10">
                    <div class="col-md-10 col-sm-10 col-xs-11">
                      <div class="wrap-text">
                        <a href="">
                          {{::pendingItem.purpose}}
                        </a>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-1">
                      <div class="dropdown">
                        <a href="" class="dropdown-toggle" data-toggle="dropdown">
                          <i class="zmdi zmdi-more-vert more-opt-hover"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right">
                          <li data-ng-repeat="actionObject in pendingItem.allowedActionList.itemList">
                            <div ng-switch on="actionObject.methodName">
                              <a ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed" class="f-13" data-ng-click="payments.signPayment(pendingItem.id);">
                                {{'payments.delete' | translate}}
                                <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                              </a>
                              <a ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed" class="f-13" data-ng-click="payments.deletePayment(pendingItem.id);">
                                {{'payments.delete' | translate}}
                                <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                              </a>
                              <a ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed" class="f-13" data-ng-click="payments.recallPayment(pendingItem.id);">
                                {{'payments.delete' | translate}}
                                <i class="zmdi zmdi-delete zmdi-hc-lg m-l-15 pull-right"></i>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                  <span>
                    {{::pendingItem.valueDate | date: 'shortDate' : '+0200'}}
                  </span> -
                  <span ng-show="pendingItem.formType">
                    {{::pendingItem.formType}}
                  </span>
                  <span ng-show="pendingItem.amount">
                     of <b>{{::pendingItem.amount | number:2}} {{::pendingItem.currencySymbol}}</b>
                  </span>
                  <span ng-show="pendingItem.receiverName">
                     to <b>{{::pendingItem.receiverName}}</b>
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="t-a-c p-10 no-data-image" ng-if="payments.pendingPaymentsList.result.length == 0">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h5 class="no-data-label" translate="payments.noPendingPayments"></h5>
            </div>
          </div>

          <div ng-show="payments.pendingPaymentsList.result.length > 0">
            <a href="" class="lv-footer f-14" translate="payments.morePending"
               data-ng-click="payments.loadMorePendingPayments()">
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
