<section id="main">
  <div class="container-fluid">
    <div class="row main-row p-b-10">
      <div class="main-column col-xs-12">
        <!-- START Title -->
        <div class="row">
          <div class="col-md-12">
            <h2 class="f-28" translate="laonsOverview.title"></h2>
          </div>
        </div>
        <div class="row">
          <div class="m-b-15 col-md-6"
               ng-repeat="account in loansOverview.accounts | orderBy:['-isFavorite','-iban']"
               data-ng-click="loansOverview.goToAccount(account.accountId)"
               ng-class="{'col-md-6': !accountsOverview.multiAccounts.length}">
            <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
          </div>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && loansOverview.accounts && loansOverview.accounts.length == 0" class="row">
          <div class="col-xs-12">
            <h4 class="acc-name c-red m-t-0 m-b-0"><b class="col-xs-10 p-0  no-wrap-text" translate="laonsOverview.noData"></b></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
