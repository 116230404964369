<!-- PUBLIC REVENUE SECTION -->
<div class="col-lg-12 col-md-12 col-sm-12 purpose-section revenue-section">
  <div class="row m-r-0">
    <div class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label" translate="domesticPayment.taxPayerNumber"></label>
        <input type="text" class="form-control input-field" name="taxCollectorInput" data-ng-model="templateListOverview.tempObj.taxPayerId"
               ng-class="{'domp-error': templateListOverview.templatesListForm.taxCollectorInput.$invalid && templateListOverview.tempObj.formSubmitted}"
               maxlength="13" ng-maxlength="13" ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbers}}" required>
        <div class="c-red" ng-show="templateListOverview.templatesListForm.taxCollectorInput.$error.required && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.taxPayerError">
        </div>
      </div>
    </div>

    <div class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label" translate="domesticPayment.paymentType"></label>
        <select class="form-control input-field" data-ng-model="templateListOverview.tempObj.paymentType">
          <option ng-repeat="paymentType in templateListOverview.tempObj.paymentTypes">{{paymentType}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row m-r-0">
    <div click-outside="templateListOverview.revenueCodeFilterExpanded = false;" outside-if-not="expandRevenueCodeFilter" class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label" translate="domesticPayment.revenueCode"></label>
        <!-- REVENUE TYPE AUTOCOMPLETE -->
        <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.revenueCodeFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
          <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
            <i class="icon icon-search domp-search-sign m-l-5"></i>
          </div>
          <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
            <input type="text" name="revenueCodeFilter" class="search-input"
                   ng-model="templateListOverview.tempObj.revenueCode"
                   ng-change="templateListOverview.openRevenueCodes()" ng-maxlength="6" maxlength="6"
                   ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbers}}"
                   autocomplete="off" required>
          </div>
          <div id="expandRevenueCodeFilter" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
               ng-click="templateListOverview.toggleRevenueCodes();">
            <i class="zmdi zmdi-close domp-close-sign" ng-show="templateListOverview.tempObj.revenueCode!=''"
               ng-click="templateListOverview.closeRevenueCodes()"></i>
            <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
               ng-show="templateListOverview.tempObj.revenueCode==''"></i>
          </div>
          <div class="domp-filter-selection search-row"
               ng-class="{'opened': templateListOverview.revenueCodeFilterExpanded}">
            <div class="domp-filter-type-selection-wrapper"
                 ng-repeat="revenueCode in templateListOverview.tempObj.revenueCodes | filter: templateListOverview.filterRevenueCodes"
                 ng-click="templateListOverview.selectRevenueCode(revenueCode)">
              <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <p class="domp-list-name m-0">{{revenueCode.description}}</p>
                <p class="domp-list-number m-0">{{revenueCode.code}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.revenueCodeFilter.$error.required && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.revenueCodeError">
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.revenueCodeFilter.$error.revenueCodeExistance && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.revenueCodeExistanceError">
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.revenueCodeFilter.$error.revenueCodeType && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.revenueCodeTypeError">
        </div>
        <!-- END REVENUE TYPE AUTOCOMPLETE -->
      </div>
    </div>

    <div click-outside="templateListOverview.municipalityFilterExpanded = false;" outside-if-not="expandMunicipalityFilter" class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label" translate="domesticPayment.municipality"></label>
        <!-- MUNICIPALITY AUTOCOMPLETE -->
        <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.municipalityFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
          <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
            <i class="icon icon-search domp-search-sign m-l-5"></i>
          </div>
          <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
            <input type="text" name="municipalityFilter" class="search-input"
                   ng-model="templateListOverview.tempObj.municipality"
                   ng-change="templateListOverview.openMunicipalities()"
                   ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbers}}"
                   ng-maxlength="3" maxlength="3" autocomplete="off" required>
          </div>
          <div id="expandMunicipalityFilter" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
               ng-click="templateListOverview.toggleMunicipalities()">
            <i class="zmdi zmdi-close domp-close-sign" ng-show="templateListOverview.tempObj.municipality!=''"
               ng-click="templateListOverview.removeMunicipalities()"></i>
            <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
               ng-show="templateListOverview.tempObj.municipality==''"></i>
          </div>
          <div class="domp-filter-selection search-row"
               ng-class="{'opened': templateListOverview.municipalityFilterExpanded}">
            <div class="domp-filter-type-selection-wrapper"
                 ng-repeat="municipality in templateListOverview.tempObj.municipalities |filter: templateListOverview.filterMunicipalities"
                 ng-click="templateListOverview.selectMunicipality(municipality)">
              <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <p class="domp-list-name m-0">{{municipality.description}}</p>
                <p class="domp-list-number m-0">{{municipality.codeString}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.municipalityFilter.$error.required && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.municipalityError">
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.municipalityFilter.$error.municipalityExistance && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.municipalityExistanceError">
        </div>
        <!-- END MUNICIPALITY AUTOCOMPLETE -->
      </div>
    </div>
  </div>

  <div class="row m-r-0">
    <div class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label m-t-15" translate="domesticPayment.taxPeriodFrom"></label>
        <div class="input-group ">
          <input type="text" class="form-control input-field" name="taxPeriodFrom"
                 uib-datepicker-popup="shortDate" data-ng-model="templateListOverview.tempObj.taxPeriodFrom"
                 show-weeks="false" is-open="templateListOverview.taxPeriodFromOpened"
                 current-text="{{'core.period.today' | translate}}"
                 clear-text="{{'core.datePicker.clearBtn' | translate}}"
                 close-text="{{'core.datePicker.doneBtn' | translate}}"
                 datepicker-options="templateListOverview.dateOptions"
                 data-ng-change="templateListOverview.validateTaxPeriod()" readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="templateListOverview.openTaxPeriodFrom()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.taxPeriodFrom.$error.taxPeriodRange && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.taxPeriodRangeError">
        </div>
      </div>
    </div>

    <div class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label m-t-15" translate="domesticPayment.taxPeriodTo"></label>
        <div class="input-group ">
          <input type="text" class="form-control input-field" name="taxPeriodTo"
                 uib-datepicker-popup="shortDate" data-ng-model="templateListOverview.tempObj.taxPeriodTo"
                 show-weeks="false" is-open="templateListOverview.taxPeriodToOpened"
                 current-text="{{'core.period.today' | translate}}"
                 clear-text="{{'core.datePicker.clearBtn' | translate}}"
                 close-text="{{'core.datePicker.doneBtn' | translate}}"
                 datepicker-options="templateListOverview.dateOptions"
                 data-ng-change="templateListOverview.validateTaxPeriod()" readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="templateListOverview.openTaxPeriodTo()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-r-0">
    <div click-outside="templateListOverview.budgetOrganizationFilterExpanded = false;" outside-if-not="expandBudgFilterOrg" class="col-sm-6 left-section">
      <div class="form-group">
        <label class="domp-form-label" translate="domesticPayment.budgetOrganisation"></label>
        <!-- BUDGET ORGANIZATIONS AUTOCOMPLETE -->
        <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.budgetOrganizationFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
          <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
            <i class="icon icon-search domp-search-sign m-l-5"></i>
          </div>
          <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
            <input type="text" name="budgetOrganizationFilter" class="search-input"
                   ng-model="templateListOverview.tempObj.budgetOrganization"
                   ng-change="templateListOverview.openBudgetOrganizations()" maxlength="7"
                   ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbers}}"
                   ng-maxlength="7" autocomplete="off" required>
          </div>
          <div id="expandBudgFilterOrg" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
               ng-click="templateListOverview.toggleBudgetOrganizations()">
            <i class="zmdi zmdi-close domp-close-sign" ng-show="templateListOverview.tempObj.budgetOrganization!=''"
               ng-click="templateListOverview.closeBudgetOrganization()"></i>
            <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right ng-hide"
               ng-show="templateListOverview.tempObj.budgetOrganization==''"></i>
          </div>
          <div class="domp-filter-selection search-row"
               ng-class="{'opened': templateListOverview.budgetOrganizationFilterExpanded}">
            <div class="domp-filter-type-selection-wrapper"
                 ng-repeat="organization in templateListOverview.tempObj.budgetOrganizations |filter: templateListOverview.filterBudgetOrganizations"
                 ng-click="templateListOverview.selectBudgetOrganization(organization)">
              <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <p class="domp-list-name m-0">{{organization.description}}</p>
                <p class="domp-list-number m-0">{{organization.budgetOrganisationNumber}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
             ng-show="templateListOverview.templatesListForm.budgetOrganizationFilter.$error.required && templateListOverview.tempObj.formSubmitted"
             translate="domesticPayment.budgetOrganizationError">
        </div>
        <!-- END BUDGET ORGANIZATIONS -->
      </div>
    </div>
  </div>
</div>
<!-- END PUBLIC REVENUE SECTION -->
