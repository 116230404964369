(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "Putem Viber aplikacije možete aktivirati Addiko Chat Banking uslugu",
        activationWay: "Izaberite način aktivacije",
        scanQrCode: "Skeniranje QR koda",
        activationCode: "Aktivacijski kod",
        agree: "Prihvatam Opšte uslove korištenja Addiko EBank usluge",
        activate: "Aktiviraj",
        deactivateText: "Vaš nalog je trenutno aktivan",
        deactivate: "Deaktiviraj",
        deactivateModalText: "Da li ste sigurni da želite deaktivirati uslugu Addiko Chat Banking?",
        cancel: "Odustani",
        activationSuccess: "Usluga Addiko Chat Banking je uspješno deaktivirana.",
        viberCondition1: "Opšti uslovi korištenja za Addiko EBank usluge ",
        viberCondition2: "primjenjuju se i na usluge koje Korisnik obavlja korišćenjem usluge Addiko Chat Banking. Viber nalog, koji će Korisnik koristiti za pristup ovoj usluzi Banke, biće korišćen u skladu sa ",
        viberCondition3: "uslovima i politikom Viber aplikacije ",
        viberCondition4: ",za koju je Korisnik već dao saglasnost tokom prijave na Viber servis.",
        scanQrCodeC1: "Skenirajte prikazani QR kod putem Viber aplikacije (opcija 'More', 'QR code scanner').",
        scanQrCodeC2: "Nakon skeniranja, automatski ćete biti preusmjereni na javni Viber nalog Addiko banke a aktivacioni kod će biti popunjen u poruci. Pošaljite poruku sa popunjenim aktivacionim kodom.",
        scanQrCodeC3: "Primićete SMS sa verifikacionim kodom na broj telefona koji ste ostavili u Banci za ebanking/mbanking uslugu.",
        scanQrCodeC4: "Unesite jednokratnu lozinku iz SMS poruke u Viber poruku i pošaljite je.",
        scanQrCodeC5: "Izaberite 4-cifreni PIN a zatim ponovite njegov unos kao potvrdu. Aplikacija prihvata unos PINa broj po broj (ne odjednom 4 broja), uz obavijest o isporuci svakog pojedinačnog.",
        scanQrCodeC6: "Ovaj PIN ćete ubuduće koristiti za pristup aplikaciji Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala.",
        activationCodeC1: "Pronadjite javni Viber nalog Addiko banke na svojoj Viber aplikaciji i izaberite opciju da postanete pratilac tog naloga.",
        activationCodeC2: "Pokrenite chat opciju.",
        activationCodeC3: "U dnu ekrana uredjaja koji koristite za Viber, nalaze se komande Addiko Chat Banking aplikacije. Pokrenite komandu 'Aktiviraj'.",
        activationCodeC4: "Unesite aktivacioni kod sa Addiko Ebank stranice, u Viber poruku. Pošaljite poruku sa popunjenim aktivacionim kodom.",
        activationCodeC5: "Primićete SMS sa verifikacionim kodom na broj telefona koji ste ostavili u Banci za ebanking/mbanking uslugu.",
        activationCodeC6: "Unesite jednokratnu lozinku iz SMS poruke u Viber poruku i pošaljite je.",
        activationCodeC7: "Izaberite 4-cifreni PIN a zatim ponovite njegov unos kao potvrdu. Aplikacija prihvata unos PINa broj po broj (ne odjednom 4 broja), uz obavijest o isporuci svakog pojedinačnog.",
        activationCodeC8: "Ovaj PIN ćete ubuduće koristiti za pristup aplikaciji Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala.",
        yourActivationCode: "Vaš kod za aktivaciju:",
        step1: 'Klikom na dugme "Postani pratilac" bićete preusmjereni na javni nalog Banke na Viber-u. Postanite pratilac klikom na dugme "Follow"',
        step2: 'Vratite se u Ebank. Pokrenite servis klikom na dugme "Aktiviraj"',
        step3: 'Dobili ste sms poruku. Kliknite na aktivacioni link i bićete preusmjereni na javni nalog banke',
        step4: 'Kreirajte vaš PIN na Addiko Chat Banking. Koristićete ga prilikom svih prijava i potvrda plaćanja',
        becomeFollower: 'Postani pratilac',
        conditionHeader: "Obavljajte bankarske poslove putem Viber aplikacije jednostavno i brzo",
        conditionHeader2: 'Sa novim servisom "Addiko Chat Banking" možete:',
        text1: 'Pregledati stanja i vršiti plaćanje',
        text2: 'Pregledati naloge za plaćanje',
        text3: 'Pronaći najbližu ekspozituru ili bankomat',
        text4: 'Informisati se o aktuelnoj ponudi banke',
        activeMessage: "Vaš Addiko Chat Banking je aktivan.",
        successfulActivation: 'Uspješna aktivacija',
        unsuccessfulActivation: 'Neuspješna aktivacija',
        successFollower: 'SMS poruka uspješno poslata.Zapratite nas klikom na link iz SMS poruke.',
        smsSent: 'SMS poruka je uspješno poslata. Molimo Vas da kliknete na link koji smo Vam poslali u poruci.',
        mobileNumber: 'Broj telefona za koji je vezan servis:',
        activationDate: 'Datum aktivacije servisa:',
        becomeFollowerAlert: "Ako ste već pratilac Addiko javnog profila na Viberu, ovaj korak nije potreban. Želite li preskočiti ovaj korak i nastaviti s opcijom 'Aktiviraj'?",
        yes: "Da",
        no: "Ne"


      }
    };

    $translateProvider.translations('mne', data);

  }
} ());

