<table class="table table-transparent table-responsive">
  <tbody>
    <tr>
      <td>
        <span class="po-desc-label"  translate="investOverview.shareAccount">Share account:</span>
      </td>
      <td>
        <span class="tridm-value-label">{{transaction.shareAccount}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="investOverview.dateOfEntry">Date of entry:</span>
      </td>
      <td>
        <span class="tridm-value-label">{{transaction.dateOfEntry | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label"  translate="investOverview.shareQuant">Share quantity:</span>
      </td>
      <td>
        <span class="tridm-value-label">{{transaction.shareQuantity | currency: ''}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="investOverview.shareValue">Share value:</span>
      </td>
      <td>
        <span class="tridm-value-label">{{transaction.shareValue | currency: ''}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="investOverview.chargeValue">Charge value:</span>
      </td>
      <td>
        <span class="tridm-value-label">{{transaction.chargeValue | currency: ''}}</span>
      </td>
    </tr>
  </tbody>
</table>
