(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orders.controller:OrdersSloCtrl
   *
   * @description
   *
   */
  angular
    .module('orders')
    .controller('OrdersSloCtrl', OrdersSloCtrl);

  function OrdersSloCtrl($state, $rootScope, $location, $translate, HelperService, AccountsService, PaymentTypesService, ConfigEndpointService, ValidationService, ReferenceModelService,
                          PermanentOrdersService, PermanentOrderTypesService, $log, $q, $timeout, $filter, GoogleTagManagerHelperService, NotificationService) {

    var vm = this;
    vm.ctrlName = 'OrdersCtrl';
    vm.orderType = {};
    vm.fromAccountDropdown = false;
    vm.amountInput = '';
    vm.dayOfBeginning = 8;
    vm.dateBegin = new Date().setDate(vm.dayOfBeginning);
    vm.dateCancel = new Date(vm.dateBegin);
    vm.formSubmitted = false;
    vm.referenceModelList = [];
    vm.orderTypes = [];
    vm.referenceModelSelect = {};
    vm.currentOrder = null;
    vm.step = 'order';
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;
    vm.uiMask = ValidationService.getUiMask().sl.orders;
    vm.ordersListGrouped = {};
    vm.referenceNumber = '';
    vm.descriptionMaxLength = 50;

    vm.forUpdate = false;

    var timeRange = HelperService.getDateRange('thisMonth');

    vm.ordersFilter = {
      page: 0,
      orderBy: 'dateCreated DESC',
      pageSize: 10,
      dateCreatedGTE: timeRange.valueDateGTE,
      dateCreatedLTE: timeRange.valueDateLTE
    };

    vm.authorizationTypeOptions = [
      {id: 1, description: "orders.permanentAthorisation"},
      {id: 0, description: "orders.cancelAuthorisation"}
    ];
    vm.authorisationTypeId = 1;

    vm.disabled = function (date, mode) {
      if (vm.authorisationTypeId == 1) return false;
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    vm.dateOfBeginningChange = function () {
      //vm.dateCancel = new Date(vm.dateBegin);
      //vm.dateOptions.minDate = new Date(vm.dateBegin);
      //vm.dateOptions.maxDate = HelperService.setCustomMonthPeriod(new Date(vm.dateBegin), 5);
      vm.dateCancel = new Date(new Date(vm.dateBegin).setMonth(new Date(vm.dateBegin).getMonth() + 5));
      vm.dateOfCancellationOptions = {
        minDate: new Date(vm.dateCancel)
      }
    };

    vm.modifyDate = function () {
      var minDate;
      var today = new Date();
      today.setDate(today.getDate() + 5);
      var fiveDaysFromToday = today.getDate();
      if (fiveDaysFromToday < vm.dayOfBeginning) {
        vm.dateBegin = new Date(new Date().setDate(vm.dayOfBeginning));
      } else {
        minDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
        vm.dateBegin = new Date(new Date(minDate).setDate(vm.dayOfBeginning));
      }

      vm.dateOfBeginningOptions = {
        minDate: new Date(vm.dateBegin)
        //maxDate: HelperService.setCustomMonthPeriod(new Date(vm.dateBegin), 5)
      };
      vm.dateOfBeginningChange();
    };

    vm.onAuthorisationTypeChange = function () {
      if (vm.authorisationTypeId == 1) {
        vm.dateOfBeginningOptions = {
          minDate: new Date()
        };
        vm.dateOfCancellationOptions = {
          minDate: new Date(),
          maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };
        vm.dayOfBeginning = 8;
        vm.dateBegin = new Date().setDate(vm.dayOfBeginning);
        vm.dateCancel = new Date(vm.dateBegin);
        vm.modifyDate();
      } else {
        vm.dateOfCancellationOptions = {
          minDate: new Date()
        };
        vm.dateCancel = new Date();
      }

      getOrders(true);
    }
    vm.onAuthorisationTypeChange();

    vm.inputOptions = {
      receviersAddress: {
        maxLength: -1
      },
      beneficiaryName: {
        maxLength: -1
      }
    };


    vm.timelineTimeFilter = timelineTimeFilter;
    vm.loadMoreTimelineItems = loadMoreTimelineItems;


    vm.defaultCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.bankAccount = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BankAccount;
    vm.bankName = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BankName;

    vm.beneficiaryIBANPrefix = 'HR';
    if (vm.countryCode == 'sl') {
      vm.beneficiaryIBAN = 'SI56';
      vm.inputOptions = {
        receviersAddress: {
          maxLength: 50
        },
        beneficiaryName: {
          maxLength: 50
        }
      };
    }


    vm.dateDisabled = function (date, mode) {
      if (date.getDate() == vm.dayOfBeginning) {
        //Sunday
        if (date.getDay() == 0) {
          return false;
        }
        if (date.getDay() == 6) {
          return true;
        }
        return false;
      }
      return true;
    };


    vm.dayOfBeginningSelectOptions = [
      {
        text: "8.",
        value: 8
      },
      {
        text: "18.",
        value: 18
      },
      {
        text: "28.",
        value: 28
      }
    ];

    vm.setAccountFrom = setAccountFrom;


    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: 'all',
          description: 'core.period.all'
        }
      }
    ];
    vm.selectedPeriod = vm.periodList[3];

    function getPaymentTypeGroup() {
      var paymentTypesGroupFilter = {
        paymentTypeGroup: 'DomesticPayment'
      };
      return PaymentTypesService.getByGroup(paymentTypesGroupFilter)
        .then(function (response) {
          vm.accountList = response.result[0].debitAccountList;
          vm.ordersFilter.accountId = response.result[0].debitAccountList[0].accountId;
          _.forEach(vm.accountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          if (vm.accountList.length > 0) {
            vm.fromAccountSelect = vm.accountList[0];
          }
        })
        .catch(function () {
          $log.error('Error loading paymentTypeGroupDomestic!');
        });
    }

    function getReferenceModels() {
      var referenceModelFilter = {
        pageSize: 1000
      };
      return ReferenceModelService.getPage(referenceModelFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
        })
        .catch(function () {
          $log.error('Error loading bic banks codes!');
        });
    }

    vm.setOrderType = function () {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
        //getFromAccountList(vm.orderType.detailsList);

        if (vm.orderType.typeId == 'trr') {
          vm.beneficiaryIBAN = 'SI56';
        }
      }
      setDefaultIBAN();
    };

    function loadSavingAccounts() {
      AccountsService.getPage({
        accountTypeGroup: 'SavingAccountTypes',
        currencySymbol: $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency
      }) //12.11.2016 - filter by currencySymbol doesnt work
        .then(function (response) {
          vm.savingAccounts = response.result;

          // _.forEach(vm.accountList, function (value) {
          //   value.optionValue = value.accountDescription + ' - ' + value.iban;
          // });

          if (vm.savingAccounts) {
            var tempList = _.filter(vm.savingAccounts, function (item) {
              return item.currencySymbol == $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
            });
            if (tempList)
              vm.savingAccounts = tempList;
          }

          if (vm.savingAccounts.length > 0) {
            vm.selectedSavingAccount = vm.savingAccounts[0];
          }
        }, function (error) {
          console.log(error);
        })
    }

    function setDefaultIBAN() {
      if (vm.orderType.typeId == "JO_HRK_10") {
        vm.beneficiaryIBAN = vm.bankAccount;
        vm.beneficiaryName = vm.bankName;
      } else if (vm.beneficiaryIBAN == vm.bankAccount) {
        vm.beneficiaryIBAN = '';
        vm.beneficiaryName = '';
      }
    }

    function getOrderTypes() {
      var orderTypeFilter = {
        pageSize: 1000
      };
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
        orderTypeFilter.includeList = ['DetailsList']
      }
      return PermanentOrderTypesService.getPage(orderTypeFilter)
        .then(function (response) {
          vm.orderTypes = response.result;
          vm.orderType = vm.orderTypes[0];
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
            getFromAccountList(vm.orderType.detailsList);
            loadSavingAccounts();
          }
          setDefaultIBAN();
        })
        .catch(function () {
          $log.error('Error loading order types');
        });
    }

    function getFromAccountList(accountIdList) {
      AccountsService.getPage({
        accountTypeGroup: 'CurrentAccount',
        isOwn: true,
        currencySymbol: $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency
      }) //12.11.2016 - filter by currencySymbol doesnt work
        .then(function (response) {
          vm.accountList = response.result;
          vm.ordersFilter.accountId = vm.accountList.accountId;
          _.forEach(vm.accountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          if (vm.countryCode == 'sl') {
            filterAccountsToEurAccount(); //12.11.2016 - filter by currencySymbol doesnt work
          }

          if (vm.accountList.length > 0) {
            vm.fromAccountSelect = vm.accountList[0];
          }
        }, function (error) {
          console.log(error);
        })
    }

    function filterAccountsToEurAccount() {
      if (vm.accountList) {
        var tempList = _.filter(vm.accountList, function (item) {
          return item.currencySymbol == $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
        });
        if (tempList)
          vm.accountList = tempList;
      }
    }

    function getOrders(reset) {
      if (reset) {
        vm.ordersFilter.page = 0;
        vm.ordersListGrouped = {};
      } else {
        vm.ordersFilter.page += 1;
      }

      if (vm.authorisationTypeId == 1) {
        PermanentOrdersService.getPage(vm.ordersFilter)
          .then(function (response) {
            if (response) {
              if (reset) {
                vm.ordersListGrouped = response;
              } else {
                vm.ordersListGrouped.result = vm.ordersListGrouped.result.concat(response.result);
                vm.ordersListGrouped.hasMore = response.hasMore;
              }
            }

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      } else {
        PermanentOrdersService.getClosingPermanentOrders(vm.ordersFilter)
          .then(function (response) {
            if (response) {
              if (reset) {
                vm.ordersListGrouped = response;
              } else {
                vm.ordersListGrouped.result = vm.ordersListGrouped.result.concat(response.result);
                vm.ordersListGrouped.hasMore = response.hasMore;
              }
            }

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    }

    function loadMoreTimelineItems() {
      getOrders(false);
    }

    function timelineTimeFilter(item) {
      vm.selectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      vm.ordersFilter.dateCreatedGTE = timeRange.valueDateGTE;
      vm.ordersFilter.dateCreatedLTE = timeRange.valueDateLTE;
      getOrders(true);

      GoogleTagManagerHelperService.pushEvent('Orders', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
    }

    function setAccountFrom(item) {
      vm.fromAccountSelect = item;
      vm.ordersFilter.accountId = vm.fromAccountSelect.accountId;
      getOrders(true);
      vm.fromAccountDropdown = false;
    }

    vm.openPermanentOrder = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if (!vm.orderForm.$valid) {
        return;
      }

      vm.nextButtonPressed = true;
      if (vm.formSubmitted) {
        var order = createOrderObject();

        if (vm.forUpdate) {
          order.id = vm.insertedOrderObject.id;
          PermanentOrdersService.update(vm.insertedOrderObject.id, order)
            .then(function (response) {
              postOrderAction(response);
              vm.forUpdate = false;
              vm.step = 'confirm';
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              // vm.orderForm.$valid = false;
              vm.forUpdate = false;
            })
            .finally(function () {
              vm.formSubmitted = false;
              vm.nextButtonPressed = false;
            });
        } else {
          PermanentOrdersService.create(order)
            .then(function (response) {
              postOrderAction(response);
              vm.step = 'confirm';

              GoogleTagManagerHelperService.pushVirtualPageView('orders/toconfirm', 'orders toconfirm', $location.host() + 'orders/toconfirm');

            })
            .catch(function (error) {
              if (error && error.resultList && error.resultList.length > 0) {
                NotificationService.showMessage(error, 'error');
                // vm.orderForm.$valid = false;
              }
            })
            .finally(function () {
              vm.formSubmitted = false;
              vm.nextButtonPressed = false;
            });
        }
      }
    };


    function createCancelAuthorisationInputObject() {
      var paymentObject = {
        fromAccountNumber: vm.fromAccountSelect.iban,
        orderId: 0,
        payeeAccount: (vm.beneficiaryIBAN) ? vm.beneficiaryIBAN.replace(/\s/g, '') : "",
        payeeAccountType: vm.orderType.typeId,
        payeeName: vm.beneficiaryName,
        payeeAddress: vm.receviersAddress,
        amount: HelperService.parseAmountInput(vm.amountInput),
        currencySymbol: vm.fromAccountSelect.currencySymbol,
        lastPaymentDate: new Date(vm.dateCancel),
        note: " "
      };
      if (vm.orderType.typeId == 'hypo') {
        paymentObject.payeeAccount = vm.selectedSavingAccount.iban;
        // Must be uppercase
        paymentObject.payeeAccountType = vm.orderType.typeId.toUpperCase();
      }
      return paymentObject;
    }

    vm.validateClosePermanentOrder = function () {

      vm.formSubmitted = true;
      if (!vm.orderForm.$valid) {
        return;
      }

      PermanentOrdersService.validateClose(createCancelAuthorisationInputObject())
        .then(function (response) {
          vm.step = 'confirm';
          postOrderAction(response);

          GoogleTagManagerHelperService.pushVirtualPageView('orders/toconfirm', 'orders toconfirm', $location.host() + 'orders/toconfirm');
        }, function (error) {
          if (error && error.resultList && error.resultList.length > 0) {
            NotificationService.showMessage(error, 'error');
          }
        })
    };

    vm.confirmClosePermanentOrder = function () {
      PermanentOrdersService.close(createCancelAuthorisationInputObject())
        .then(function (response) {
          vm.step = 'complete';
          GoogleTagManagerHelperService.pushVirtualPageView('orders/toconfirm', 'orders toconfirm', $location.host() + 'orders/toconfirm');
        })
        .catch(function (error) {
          if (error && error.resultList && error.resultList.length > 0) {
            NotificationService.showMessage(error, 'error');
          }
        })
    };

    vm.insertedOrderObject = {};

    var postOrderAction = function (response) {
      vm.insertedOrderObject = response;
      //vm.insertedOrderObject.orderTypeId = vm.orderType.typeId;
      vm.insertedOrderObject.fromAccountNumber = response.fromAccountNumber || vm.fromAccountSelect.iban; //SLO or HR
      vm.insertedOrderObject.payeeName = vm.beneficiaryName;
      vm.insertedOrderObject.amount = HelperService.parseAmountInput(vm.amountInput);
      vm.insertedOrderObject.currencySymbol = vm.defaultCurrency;
      vm.insertedOrderObject.firstPaymentDate = vm.dateBegin;
      vm.insertedOrderObject.lastPaymentDate = vm.dateCancel;
      vm.insertedOrderObject.purpose = vm.description;
      vm.insertedOrderObject.note = vm.description;
    };

    function createOrderObject() {
      var paymentObject = {
        orderTypeId: vm.orderType.typeId,
        payeeAccount: (vm.beneficiaryIBAN) ? vm.beneficiaryIBAN.replace(/\s/g, '') : "",
        payeeName: vm.beneficiaryName,
        amount: HelperService.parseAmountInput(vm.amountInput),
        currencySymbol: vm.defaultCurrency,
        firstPaymentDate: new Date(vm.dateBegin),
        purpose: vm.description,
        note: vm.description
      };

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        paymentObject.fromAccountNumber = vm.fromAccountSelect.iban;
        paymentObject.lastPaymentDate = new Date(vm.dateCancel);
        paymentObject.payeeAddress = vm.receviersAddress;

        if (vm.orderType.typeId != 'trr')
          paymentObject.payeeAccount = vm.selectedSavingAccount.iban;
      } else {
        paymentObject.fromAccountNumber = vm.fromAccountSelect.accountId;
      }

      return paymentObject;
    }

    vm.back = function () {
      vm.step = 'order';
      vm.formSubmitted = false;
    };

    vm.editOrder = function () {
      vm.forUpdate = true;
      vm.step = 'order';
      vm.agreedWithTerms = false;
      mapWithOrderResponse();
      //setDefaultIBAN();
    };

    var mapWithOrderResponse = function () {
      vm.orderType.typeId = vm.insertedOrderObject.orderTypeId || vm.insertedOrderObject.payeeAccountType;
      vm.beneficiaryName = vm.insertedOrderObject.payeeName;
      vm.amountInput = vm.insertedOrderObject.amount;
      vm.defaultCurrency = vm.insertedOrderObject.currencySymbol;
      vm.dateBegin = new Date(vm.insertedOrderObject.firstPaymentDate);
      vm.dateCancel = new Date(vm.insertedOrderObject.lastPaymentDate);
      vm.description = vm.insertedOrderObject.purpose;
      vm.note = vm.insertedOrderObject.note;
      vm.beneficiaryIBAN = vm.insertedOrderObject.payeeAccount;

      var accountIndex = _.findIndex(vm.accountList, function (item) {
        return item.accountId == vm.insertedOrderObject.accountId;
      });

      vm.fromAccountSelect = vm.accountList[accountIndex];
    };

    vm.sign = function () {
      if (vm.confirmButtonPressed) return;
      vm.insertedOrderObject.statusId = 6;

      vm.signOrder = vm.insertedOrderObject;
      if (vm.countryCode != 'sl') {
        vm.signOrder = {};
        vm.signOrder.id = vm.insertedOrderObject.id;
        vm.signOrder.signData = vm.model.password2;
      }

      vm.confirmSignedButtonDisabled = true;
      vm.confirmButtonPressed = true;
      PermanentOrdersService.sign(vm.signOrder)
        .then(function (response) {
          vm.insertedOrderObject = response;
          vm.step = 'complete';
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function (error) {
          vm.confirmButtonPressed = false;
        });
    };

    /**
     * Resets order form and shows first step.
     */
    vm.newOrder = function () {
      $state.go("orders", {}, {reload: true})
      // vm.step = 'order';
      // vm.amountInput = '';
      // vm.description = '';
      // vm.beneficiaryIBAN = '';
      // vm.beneficiaryName = '';
      // vm.dateBegin = new Date();
      // vm.dateCancel = new Date();
      // vm.formSubmitted = false;
      // vm.forUpdate = false;
      // vm.orderSigned = false;
      // vm.receviersAddress = '';
      // vm.note = '';
      // setDefaultIBAN();
    };


    vm.deleteOrder = function () {
      PermanentOrdersService.remove(vm.insertedOrderObject.id)
        .then(function (response) {
          vm.newOrder();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.closeOrder = function (orderId) {
      if (orderId) {
        var order = {};
        order.orderId = orderId;
        PermanentOrdersService.close(order)
          .then(function (response) {
            getOrders(true);
            var text = $translate.instant('orderDetails.closeMsg');
            NotificationService.showTranslatedMessage('info', text, '');
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.init = function () {
      var promises = [getReferenceModels()]; //getDefaultDetails(),
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') {
        promises.push(getPaymentTypeGroup());
      }
      $q.all(promises)
        .then(function () {
          getOrders(true);
          getOrderTypes();

          if (vm.referenceModelList.length > 0) {
            var index = _.findIndex(vm.referenceModelList, function (o) {
              return o === '99';
            });
            vm.referenceModelSelect = vm.referenceModelList[index];
          }
        });
    };

    vm.init();


    vm.addSpaceForIban = function (toAccountInput) {
      if (!toAccountInput)
        return;
      vm.beneficiaryIBAN = toAccountInput.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    };


    vm.expandDetails = function (order) {
      if (!order.selected) {
        if (vm.authorisationTypeId == 1) {
          PermanentOrdersService.getById(order.id)
            .then(function (response) {
              angular.extend(order, response);
            }, function (error) {
              console.log(error);
            });
        } else {
          PermanentOrdersService.getClosingPermanentOrdersById(order.id)
            .then(function (response) {
              angular.extend(order, response);
            }, function (error) {
              console.log(error);
            });
        }
      }
    };
  }
}());
