<header id="header" data-current-skin={{mactrl.currentSkin}} data-ng-include="'template/header.html'" data-ng-controller="HeaderCtrl as hctrl"></header>

<section id="main">
  <div class="container" ng-init="cddMenuSelected=1">
    <div class="row">
      <div class="col-md-3">
        <div id="changeDashboardDisplay" class="app-white-card">
          <div class="col-md-6 cdd-menu cdd-menu-left" ng-click="cddMenuSelected=1" ng-class="{selected: cddMenuSelected == 1}">Accounts</div>
          <div class="col-md-6 cdd-menu cdd-menu-right" ng-click="cddMenuSelected=2" ng-class="{selected: cddMenuSelected == 2}">Timeline</div>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-3"></div>
    </div>

    <br/>

    <div class="row">
      <!--LEFT COLUMN-->
      <div class="col-md-3">
        <div id="quickPaymentFormDiv" class="app-white-card">
          <h4>
            Make a quick payment/transfer
          </h4>
          <br/>
          <form id="quickPaymentForm">
            <div class="form-group">
              <label for="qpAccountFrom">From</label>
              <select id="qpAccountFrom" class="form-control">
                <option value="" disabled selected hidden style="color:lightgray;">Select account</option>
                <option value="0">54693456333 RSD</option>
                <option value="1">54693456333 RSD</option>
              </select>
            </div>
            <div class="form-group">
              <label for="qpAccountTo">To</label>
              <select id="qpAccountTo" class="form-control">
                <option value="" disabled selected hidden style="color:lightgray;">Select account</option>
                <option value="0">54693456333 RSD</option>
                <option value="1">54693456333 RSD</option>
              </select>

            </div>
            <div class="form-group">
              <label for="qpAmount">Amount</label>
              <div class="input-group">
                <input type="text" class="form-control" id="qpAmount" placeholder="Enter amount">
                <div class="input-group-addon qp-amount">RSD</div>
              </div>
            </div>
            <button id="qpSubmitBtn" type="submit" style="width:100%;" class="btn btn-default app-btn-blue">Make payment</button>
          </form>
        </div>
        <br/>
        <div id="paymentMenu" class="app-white-card">
          <div class="pm-item">Currency exchange</div>
          <div class="pm-item">Loan</div>
          <div class="pm-item">Savings account</div>
          <div class="pm-item">Credit card</div>
          <div class="pm-item pm-item-last">Travel insurance</div>
        </div>
      </div>
      <div class="visible-xs-block visible-sm-block" style="height:10px;"></div>
      <!--CENTER COLUMN-->
      <div class="col-md-6">
        <div id="currentAccountWidget" class="p10 app-white-card">
          <div class="col-md-8 wc-right-div">
            <h4>Euro current</h4>
            <h2 class="no-bottom-margin">1.200,32 RSD</h2>
            <p>989,23 RSD available</p>
          </div>
          <div class="col-md-4 wc-left-div">
            <div class="wc-left-div-wrapper">
              <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">View account</button>
            </div>
          </div>

        </div>
        <br/>
        <div id="creditAccountWidget" class="p10 app-white-card">
          <div class="col-md-8 wc-right-div">
            <h4>Credit card</h4>
            <h2 class="no-bottom-margin">2.100,32 RSD</h2>
            <p>1989,23 RSD available</p>
            <p class="no-bottom-margin">
              <b>Payment due date: 12//11/15</b>
            </p>
            <p>
              <b>Minimum payment: 20.000 RSD</b>
            </p>
          </div>
          <div class="col-md-4 wc-left-div">
            <div class="wc-left-div-wrapper">
              <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">View account</button>
              <div style="height:5px;"></div>
              <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">Pay card</button>
            </div>
          </div>
        </div>
        <br/>
        <div id="savingsAccountWidget" class="p10 app-white-card">
          <div class="col-md-8 wc-right-div">
            <h4>Car savings fund</h4>
            <h2 class="no-bottom-margin">35.000,000,00 RSD</h2>
            <p>reached of 50.000,000,00</p>
          </div>
          <div class="col-md-4 wc-left-div">
            <div class="wc-left-div-wrapper">
              <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">View savings</button>
            </div>
          </div>
        </div>
      </div>
      <!--RIGHT COLUMN-->
      <div class="col-md-3">
        <div id="curentStatusView" class="app-white-card">
          <h4>Since pay day you have spent</h4>
          <h2>1.200,00 RSD</h2>
          <hr/>
          <p>
            At this spend rate you will save
            <b>1.002,92 RSD</b>
            by the end of the month.
          </p>
        </div>
        <br/>
        <div id="didYouKnowView" class="app-white-card">
          <h4>Did you know?</h4>
          <br/>
          <p>
            George Washington, Abraham Lincoln, Andrew Jackson – most of our paper currency features U.S. presidents. There are only two exceptions: Alexander Hamilton is on the $10 bill and Benjamin Franklin is on the $100.
          </p>
          <br/>
          <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">Find out more</button>
        </div>
      </div>
    </div>
  </div>
</section>

<footer id="footer" data-ng-include="'template/footer.html'"></footer>
<!--<footer id="footer-hypo" data-ng-include="'template/footer-hypo.html'"></footer>-->
