<!-- START Domestic payment Croatia - Resume -->
<div ng-show="domesticPayment.location == 'hr'">
  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.tempDompObj.fromAccountSelect.accountDescription}}</label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.fromAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentCro.insertedPaymentObject.amount| currency:""}}
          {{domesticPaymentCro.insertedPaymentObject.currencySymbol}}
        </label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
        <a ng-href="{{domesticPaymentCro.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges"
           class="domp-form-label-resume">Check the bank charges</a>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-4 col-lg-offset-4 col-md-offset-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.dueDate|
          date:'shortDate' : '+0200'}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.sepaDebitorLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.debtorName}}</label>
        <label class="domp-form-label-resume"
               ng-if="!domesticPaymentCro.insertedPaymentObject.debtorName2 && !domesticPaymentCro.insertedPaymentObject.debtorName">-</label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.debtorName2}}</label>
      </div>
    </section>
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.toAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.receiverName}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.receiverAddress1}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversPostalCodeAndCityInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.receiverCity}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.sepaReceiverLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.creditorName}}</label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.creditorName2}}</label>
        <label class="domp-form-label-resume"
               ng-if="!domesticPaymentCro.insertedPaymentObject.creditorName2 && !domesticPaymentCro.insertedPaymentObject.creditorName">-</label>
      </div>
    </section>
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.purposeCodeSelectLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.purposeCodeDPS}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.insertedPaymentObject.purpose}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review domp-reference-number-label"
               translate="domesticPayment.referenceNumberLabel">Reference number</label>
        <label class="domp-form-label-resume">{{domesticPaymentCro.referenceHR}}{{domesticPaymentCro.split(domesticPaymentCro.insertedPaymentObjectDomp.referenceTo)}}</label>
        <label class="domp-form-label-resume"
               ng-if="domesticPaymentCro.insertedPaymentObject.referenceTo == ' ' || domesticPaymentCro.insertedPaymentObject.referenceTo == undefined">-</label>
      </div>
    </section>
  </div>

  <section class="row p-20" ng-if="domesticPaymentCro.tempDompObj.isUrgentPaymentCheckbox == true">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <i class="zmdi zmdi-check urgent-label"></i>
      <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
    </div>
  </section>

  <!-- Buttons -->
  <div class="p-20 clearfix">
    <section class="row">
      <button class="btn btn-primary " ng-click="domesticPaymentCro.newPayment();"
              translate="domesticPayment.newPayment">New payment
      </button>
      <button class="btn btn-primary " ng-click="domesticPaymentCro.editPayment();" translate="domesticPayment.edit">
        Edit
      </button>
      <button class="btn btn-primary-warning" ng-click="domesticPaymentCro.deletePayment();"
              translate="domesticPayment.delete">Delete
      </button>
      <button class="btn btn-default app-btn-blue domp-sign-btn pull-right"
              data-ui-sref="payments.sign({paymentId: domesticPaymentCro.insertedPaymentObject.id, type: 'domestic'})"
              translate="domesticPayment.sign">Sign
      </button>
    </section>
  </div>
</div>
<!-- END Domestic payment Croatia - Resume -->

<!-- MNE -->

<div ng-show="domesticPayment.location == 'mne'">
  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentMne.tempDompObj.fromAccountSelect.accountDescription}}</label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.fromAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentMne.insertedPaymentObject.amount| currency:""}}
          {{domesticPaymentMne.insertedPaymentObject.currencySymbol}}
        </label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
        <a ng-href="{{domesticPaymentMne.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges"
           class="domp-form-label-resume">Check the bank charges</a>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-4 col-lg-offset-4 col-md-offset-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.dueDate|
          date:'shortDate' : '+0200'}}</label>
      </div>
      <div class="form-group col-lg-4">
        <label class="domp-form-label-review domp-reference-number-label"
               translate="domesticPayment.referenceNumberLabel">Reference number</label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.split(domesticPaymentMne.insertedPaymentObject.referenceFrom)}}</label>
      </div>
    </section>
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabelSRB"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.toAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.receiverName}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.receiverAddress1}}</label>
      </div>
    </section>
    <section class="row">
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversPostalCodeAndCityInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.receiverCity}}</label>
      </div>
    </section>
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.purposeCodeSelectLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.purposeCodeDPS}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.insertedPaymentObject.purpose}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review domp-reference-number-label"
               translate="domesticPayment.referenceNumberLabel">Reference number</label>
        <label class="domp-form-label-resume">{{domesticPaymentMne.referenceHR}}{{domesticPaymentMne.split(domesticPaymentMne.insertedPaymentObjectDomp.referenceTo)}}</label>
        <label class="domp-form-label-resume"
               ng-if="domesticPaymentMne.insertedPaymentObject.referenceTo == ' ' || domesticPaymentMne.insertedPaymentObject.referenceTo == undefined">-</label>
      </div>
    </section>
  </div>

  <section class="row p-20" ng-if="domesticPaymentMne.tempDompObj.isUrgentPaymentCheckbox == true">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <i class="zmdi zmdi-check urgent-label"></i>
      <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
    </div>
  </section>

  <!-- Buttons -->
  <div class="p-20 clearfix">
    <section class="row">
      <button class="btn btn-primary " ng-click="domesticPaymentMne.newPayment();"
              translate="domesticPayment.newPayment">New payment
      </button>
      <button class="btn btn-primary " ng-click="domesticPaymentMne.editPayment();" translate="domesticPayment.edit">
        Edit
      </button>
      <button class="btn btn-primary-warning" ng-click="domesticPaymentMne.deletePayment();"
              translate="domesticPayment.delete">Delete
      </button>
      <button class="btn btn-default app-btn-blue domp-sign-btn pull-right"
              data-ui-sref="payments.sign({paymentId: domesticPaymentMne.insertedPaymentObject.id, type: 'domestic'})"
              translate="domesticPayment.sign">Sign
      </button>
    </section>
  </div>
</div>

<!-- SRB -->

<div ng-show="domesticPayment.location == 'sr'">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 domp-from-section">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="form-group">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentSrb.tempDompObj.fromAccountSelect.accountDescription}}</label>
        <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.fromAccountNumber}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
            <label class="domp-form-label-resume">
              {{domesticPaymentSrb.insertedPaymentObject.amount| currency:""}}
              {{domesticPaymentSrb.insertedPaymentObject.currencySymbol}}
            </label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7">
            <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
            <a ng-href="{{domesticPaymentSrb.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges"
               class="domp-form-label-resume">Check the bank charges</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-5">
          <div class="m-t-25 m-b-0">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.dueDate|
              date:'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 receiver-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabelSRB"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.toAccountNumber}}</label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
          <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.receiverName}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 p-l-0"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-15 m-b-0">
          <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
          <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.receiverAddress1}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-25 m-b-0">
          <label class="domp-form-label-review"
                 translate="domesticPayment.receiversPostalCodeAndCityInputLabel"></label>
          <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.receiverCity}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 purpose-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 no-padding-on-phone p-l-0">
        <div class="col-lg-4 col-md-4 col-sm-6 p-r-0">
          <div class="form-group">
            <label class="domp-form-label-review" translate="domesticPayment.purposeCodeSelectLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.purposeCodeDPS}}</label>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8">
          <div class="form-group">
            <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentSrb.insertedPaymentObject.purpose}}</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone p-l-0">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.referenceNumberLabel">Reference number</label>
          <label class="domp-form-label-resume" ng-if="domesticPaymentSrb.insertedPaymentObject.referenceTo != '00'">{{domesticPaymentSrb.split(domesticPaymentSrb.insertedPaymentObjectDomp.referenceTo)}}</label>
          <label class="domp-form-label-resume" ng-if="!domesticPaymentSrb.insertedPaymentObject.referenceTo">-</label>
        </div>
      </div>
    </div>
    <div class="row" ng-if="domesticPaymentSrb.tempDompObj.isUrgentPaymentCheckbox == true">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <i class="zmdi zmdi-check urgent-label"></i>
        <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
      </div>
    </div>
  </div>
  <!-- SMS auth old -->
  <!--<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 purpose-section">
    <div class="row">
      <div class="col-sm-6 col-xs-12" ng-if="domesticPaymentSrb.smsAuthentificate != true">
        <div class="form-group">
          <label class="exchange-resume-label" translate="currencyConversion.smsAuthentification"></label>
          <button class="btn btn-default confirm-btn d-block" translate="currencyConversion.sendSms" data-ng-click="domesticPaymentSrb.showSMSAuthentification();"></button>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12" ng-if="domesticPaymentSrb.smsAuthentificate == true">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control input-field" data-ng-model="domesticPaymentSrb.smsPasswordInput" placeholder="{{'currencyConversion.smsPassword' | translate}}">
            <span class="input-group-btn">
              <button class="btn btn-default input-group-app-btn domp-btn-height">
                <i class="zmdi zmdi-lock"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div ng-if="domesticPayment.location == 'hr'" class="domp-footer-wrapper">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0">
      <button class="col-lg-2 col-md-2 col-sm-2 col-xs-3 m-r-10 btn btn-primary-warning "
              ng-click="domesticPaymentSrb.deletePayment();" translate="domesticPayment.delete">Delete
      </button>

      <button class="col-lg-2 col-md-2 col-sm-2 col-xs-3 btn btn-primary" ng-click="domesticPaymentSrb.editPayment();"
              translate="domesticPayment.edit">Edit
      </button>

      <!--<button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-primary app-btn-blue domp-sign-btn pull-right" ng-click="domesticPaymentSrb.signPayment();" ng-disabled="!domesticPaymentSrb.$dirty && domesticPaymentSrb.smsAuthentificate == false">Sign</button>-->
      <button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
              data-ui-sref="payments.sign({paymentId: domesticPaymentSrb.insertedPaymentObject.id, type: 'domestic'})"
              translate="domesticPayment.sign">Sign
      </button>

    </div>
  </div>
  <div ng-if="domesticPayment.location != 'hr'" class="domp-footer-wrapper">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0 p-t-20">

      <button class="btn btn-primary " ng-click="domesticPaymentSrb.newPayment();"
              translate="domesticPayment.newPayment">New payment
      </button>
      <button class="btn btn-primary" ng-click="domesticPaymentSrb.editPayment();" translate="domesticPayment.edit">
        Edit
      </button>
      <button class="btn btn-primary-warning " ng-click="domesticPaymentSrb.deletePayment();"
              translate="domesticPayment.delete">Delete
      </button>
      <!--<button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-primary app-btn-blue domp-sign-btn pull-right" ng-click="domesticPaymentSrb.signPayment();" ng-disabled="!domesticPaymentSrb.$dirty && domesticPaymentSrb.smsAuthentificate == false">Sign</button>-->
      <button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
              data-ui-sref="payments.sign({paymentId: domesticPaymentSrb.insertedPaymentObject.id, type: 'domestic'})"
              translate="domesticPayment.sign">Sign
      </button>

    </div>
  </div>
</div>


<!-- START Domestic payment Slovenia - Resume -->
<div ng-show="domesticPayment.location == 'sl'">
  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label ng-if="domesticPaymentSlo.tempDompObj.fromAccountSelect.friendlyName" class="domp-form-label-resume">
          {{domesticPaymentSlo.tempDompObj.fromAccountSelect.friendlyName}}</label>
        <label ng-if="!domesticPaymentSlo.tempDompObj.fromAccountSelect.friendlyName" class="domp-form-label-resume">
          {{domesticPaymentSlo.tempDompObj.fromAccountSelect.accountDescription}}
        </label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.fromAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentSlo.insertedPaymentObject.amount| currency:""}}
          {{domesticPaymentSlo.insertedPaymentObject.currencySymbol}}
        </label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentSlo.insertedPaymentObject.paymentChargesAmount | currency: ""}}
          {{domesticPaymentSlo.insertedPaymentObject.currencySymbol}}
        </label>
        <a ng-href="{{domesticPaymentSlo.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume">

        </a>

      </div>
    </section>
    <section class="row">
      <div class="form-group col-xs-12 col-sm-4" ng-if="!domesticPaymentSlo.naturalPerson && domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName">
        <label class="domp-form-label-review" translate="domesticPayment.sepaDebitorLabel"></label>
        <label id="payersSepaInput1" class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName}}</label>
        <!--<label class="domp-form-label-resume"-->
        <!--ng-if="!domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName2 && !domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName">-</label>-->
        <!--<label id="payersSepaInput2" class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName2}}</label>-->
      </div>
      <div class="form-group col-lg-4 col-md-4 col-xs-12" ng-class="{'col-md-offset-4': domesticPaymentSlo.naturalPerson || !domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName}">
        <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.dueDate|
          date:'shortDate' : '+0200'}}</label>
      </div>
    </section>
    <!--<section class="row" ng-if="!domesticPaymentSlo.naturalPerson && domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName" >-->
      <!--<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
        <!--<label class="domp-form-label-review" translate="domesticPayment.sepaDebitorLabel"></label>-->
        <!--<label id="payersSepaInput1" class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName}}</label>-->
        <!--&lt;!&ndash;<label class="domp-form-label-resume"&ndash;&gt;-->
               <!--&lt;!&ndash;ng-if="!domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName2 && !domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName">-</label>&ndash;&gt;-->
        <!--&lt;!&ndash;<label id="payersSepaInput2" class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.debtorName2}}</label>&ndash;&gt;-->
      <!--</div>-->
    <!--</section>-->
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.toAccountNumber}}</label>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" ng-if="!domesticPaymentSlo.naturalPerson">
        <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.domesticPayment.receiverName}}</label>
      </div>
    </section>
    <section class="row" ng-if="!domesticPaymentSlo.naturalPerson" >
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.domesticPayment.receiverAddress1}}</label>
      </div>
    </section>
    <section class="row" ng-if="!domesticPaymentSlo.naturalPerson" >
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.receiversPostalCodeAndCityInputLabelSlo"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.domesticPayment.receiverCity}}</label>
      </div>
    </section>
    <section class="row" ng-if="!domesticPaymentSlo.naturalPerson && domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.creditorName" >
      <div class="form-group col-lg-6 col-md-offset-6 col-md-6 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.sepaReceiverLabel"></label>
        <label id="receiversSepaInput1" class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.sepaUpnIdentificators.creditorName}}</label>
      </div>
    </section>
  </div>

  <div class="domp-from-section p-20 clearfix">
    <section class="row">
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12" ng-if="!domesticPaymentSlo.naturalPerson" >
        <label class="domp-form-label-review" translate="domesticPayment.purposeCodeSelectLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.purposeCodeDPS}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
        <label class="domp-form-label-resume">{{domesticPaymentSlo.insertedPaymentObject.purpose}}</label>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12" ng-if="!domesticPaymentSlo.naturalPerson" >
        <label class="domp-form-label-review domp-reference-number-label"
               translate="domesticPayment.referenceNumberLabel">Reference number</label>
        <label class="domp-form-label-resume" ng-if="domesticPaymentSlo.insertedPaymentObject.domesticPayment.referenceTo">{{domesticPaymentSlo.formatReferenceNumber(domesticPaymentSlo.insertedPaymentObject.domesticPayment.referenceTo)}}</label>
        <label class="domp-form-label-resume"
               ng-if="domesticPaymentSlo.insertedPaymentObject.domesticPayment.referenceTo == ' ' || domesticPaymentSlo.insertedPaymentObject.domesticPayment.referenceTo == undefined">-</label>
      </div>
    </section>
  </div>

  <section class="row p-20" ng-if="domesticPaymentSlo.tempDompObj.isUrgentPaymentCheckbox === true">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <i class="zmdi zmdi-check urgent-label"></i>
      <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
    </div>
  </section>

  <section class="row p-20" ng-if="domesticPaymentSlo.tempDompObj.isInstantPaymentCheckBox === true">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <i class="zmdi zmdi-check urgent-label"></i>
      <label class="urgent-label" translate="domesticPayment.instantPayment"></label>
    </div>
  </section>

  <!-- Buttons -->
  <div class="p-20 clearfix">
    <section class="row">
      <div class="col-xs-6 col-sm-2">
        <button class="btn btn-primary " ng-click="domesticPaymentSlo.editPayment();" translate="domesticPayment.back">
          Edit
        </button>
      </div>
      <div class="col-xs-6 col-sm-2" ng-if="domesticPaymentSlo.actionLinks.delete">
        <button class="btn btn-primary-warning" ng-click="domesticPaymentSlo.deletePayment();"
                translate="domesticPayment.delete">Delete
        </button>
      </div>
      <!--<button class="btn btn-default app-btn-blue domp-sign-btn pull-right" ng-if="domesticPaymentSlo.signLink" -->
      <!--data-ui-sref="payments.sign({paymentId: domesticPaymentSlo.insertedPaymentObject.id, type: 'domestic'})" translate="domesticPayment.sign">Sign</button>-->
      <div class="col-xs-12 col-sm-3 pull-right" ng-if="domesticPaymentSlo.insertedPaymentObject.linkList.itemList.sign">
        <!--<button class="btn btn-default app-btn-blue domp-sign-btn pull-right"-->
                <!--ng-click="domesticPaymentSlo.sign();" translate="domesticPayment.sign">Sign-->
        <!--</button>-->
        <button class="btn btn-default app-btn-blue domp-sign-btn pull-right"
                ng-click="domesticPaymentSlo.signOrFinishPayment()"
                ng-disabled="domesticPaymentSlo.confirmButtonPressed"
                translate="domesticPayment.confirmBtn">Sign
        </button>
      </div>
      <!--<div class="col-xs-6 col-sm-2 pull-right" ng-if="domesticPaymentSlo.actionLinks.requestOTP">-->
        <!--<input type="text" class="form-control input-field" data-ng-model="domesticPaymentSlo.requestOtpInput"-->
               <!--ng-pattern-restrict="{{paymentsSign.regexPatterns.onlyNumbers}}"-->
               <!--placeholder="{{'signPayments.challengeSecondCode' | translate}}" autocomplete="off" auto-focus>-->
      <!--</div>-->

    </section>
  </div>
</div>
<!-- END Domestic payment Slovenia - Resume -->

<!-- START Domestic payment BiH - Resume -->
<div ng-show="domesticPayment.location == 'bh'">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 domp-from-section">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="form-group">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">
          {{domesticPaymentBh.tempDompObj.fromAccountSelect.accountDescription}}</label>
        <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.fromAccountNumber}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
            <label class="domp-form-label-resume">
              {{domesticPaymentBh.insertedPaymentObject.amount| currency:""}}
              {{domesticPaymentBh.insertedPaymentObject.currencySymbol}}
            </label>
          </div>
          <div ng-if="!domesticPaymentBh.insertedPaymentObject.paymentSubTypeGroup" class="col-lg-6 col-md-6 col-sm-6 col-xs-7" ng-if="domesticPaymentBh.insertedPaymentObject.bankCharges">
            <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
            <label class="domp-form-label-resume">
              {{domesticPaymentBh.insertedPaymentObject.bankCharges.feeAmount | currency:""}} BAM
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-5">
          <div class="m-t-25 m-b-0">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.dueDate|
              date:'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 receiver-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabelSRB"></label>
        <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.toAccountNumber}}</label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0"
           ng-if="domesticPaymentBh.insertedPaymentObject.receiverName">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.receiverName}}</label>
        </div>
      </div>
    </div>
    <div class="row" ng-if="domesticPaymentBh.insertedPaymentObject.receiverAddress1">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 p-l-0"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-15 m-b-0">
          <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.receiverAddress1}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 purpose-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 no-padding-on-phone p-l-0">
        <div class="col-lg-4 col-md-4 col-sm-6 p-r-0" ng-if="domesticPaymentBh.insertedPaymentObject.purposeCodeDPS">
          <div class="form-group">
            <label class="domp-form-label-review" translate="domesticPayment.purposeCodeSelectLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.purposeCodeDPS}}</label>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8" ng-if="domesticPaymentBh.insertedPaymentObject.purpose">
          <div class="form-group">
            <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
            <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.purpose}}</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone p-l-0"
           ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.reference">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.referenceNumberLabel">Reference number</label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.reference}}</label>
        </div>
      </div>
    </div>
    <div class="row" ng-if="domesticPaymentBh.tempDompObj.isUrgentPaymentCheckbox == true">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <i class="zmdi zmdi-check urgent-label"></i>
        <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
      </div>
    </div>
  </div>

  <!-- PUBLIC REVENUE DETAIL SECTION -->
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 domp-from-section revenue-section"
       ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPayerId">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.taxPayerNumber"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPayerId}}</label>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.revenuePaymentType">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.paymentType"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.revenuePaymentType}}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.revenueCode">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.revenueCode"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.revenueCode}}</label>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.municipality">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.municipality"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.municipality}}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPeriodFrom">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.taxPeriodFrom"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPeriodFrom
            | date: 'shortDate' : '+0200'}}</label>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPeriodTo">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.taxPeriodTo"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.taxPeriodTo
            | date: 'shortDate' : '+0200'}}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone" ng-if="domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.budgetOrganization">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.budgetOrganisation"></label>
          <label class="domp-form-label-resume">{{domesticPaymentBh.insertedPaymentObject.revenuePaymentDetails.budgetOrganization}}</label>
        </div>
      </div>
    </div>

  </div>
  <!-- END REVENUE DETAIL SECTION -->
  <div class="domp-footer-wrapper">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0">

      <button class="btn btn-primary " ng-click="domesticPaymentBh.newPayment();"
              translate="domesticPayment.newPayment">New payment
      </button>
      <button class="btn btn-primary" ng-click="domesticPaymentBh.editPayment();" translate="domesticPayment.edit">
        Edit
      </button>
      <button class="btn btn-primary-warning " ng-click="domesticPaymentBh.deletePayment();"
              translate="domesticPayment.delete">Delete
      </button>
      <!--<button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-primary app-btn-blue domp-sign-btn pull-right" ng-click="domesticPaymentSrb.signPayment();" ng-disabled="!domesticPaymentSrb.$dirty && domesticPaymentSrb.smsAuthentificate == false">Sign</button>-->
      <button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
              data-ui-sref="payments.sign({paymentId: domesticPaymentBh.insertedPaymentObject.id, type: 'domestic'})"
              translate="domesticPayment.sign">Sign
      </button>

    </div>
  </div>
</div>
<!-- END Domestic payment BiH - Resume -->
