
(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name core.controller:addikoDropdown
     *
     * @description
     *
     */
    angular
        .module('core')
        .directive('addikoDatepicker', addikoDatepicker);

    function addikoDatepicker(HelperService) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                minDate: '=min',
                maxDate: '=max',
                isDisabled: '=disabled'
            },
            templateUrl: 'core/components/datepicker/datepicker.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;
                vm.datePickerOpened = false;
                vm.dateOptions = {
                    maxDate: $scope.maxDate || new Date(),
                    minDate: $scope.minDate || null
                };
                vm.openDatePicker = function () {
                    if ($scope.isDisabled) {
                        return;
                    }
                    vm.datePickerOpened = true;
                };

                if ($scope.model) {
                    vm.dateInput = $scope.model;
                } else {
                    vm.dateInput = '';
                }

            }
        };
    }
}());
