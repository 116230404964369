<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div style="padding: 15px">
      <div class="row">
        <div class="domp-header-wrapper col-sm-12 p-o">
          <div class="domp-header-row">
            <h2 translate="cashWithdrawalTranslate.title">Announcement for cash withdrawing</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Title-->
    <div class="row">
      <div class="col-md-12">
        <div ng-if="cashWithdrawal.countryCode != 'sl'" class="alert alert-info"
             style="background: white; color: black; border: 1px solid lightgray" role="alert">

          <p translate="cashWithdrawalTranslate.infoNote1"></p>
          <p translate="cashWithdrawalTranslate.infoNote2"></p>
        </div>
        <div ng-if="cashWithdrawal.countryCode == 'sl'" class="alert alert-info"
             style="background: white; color: black; border: 1px solid lightgray" role="alert">
          <p translate="cashWithdrawalTranslate.infoNote1sl"></p>
          <p translate="cashWithdrawalTranslate.infoNote2sl"></p>
        </div>
      </div>
    </div>
    <div class="p-b-30">
      <ui-view/>
    </div>
  </div>
</section>
