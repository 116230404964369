(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name messages.controller:MessagesCtrl
   *
   * @description
   *
   */
  angular
    .module('messages')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl($rootScope, $q, MessagesService, PaymentsService, $log, $scope, $state, HelperService, $window, GoogleTagManagerHelperService, $translate) {
    var vm = this;
    vm.expandMessage = expandMessage;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

    vm.messageSelectedTab = 1;

    vm.init = function () {
      loadMessages();
    };


    vm.messageSearchInput = {
      subject: ""
    };

    // $scope.$watch('messages.messageSearchInput', function (newValue) {
    //   $log.debug(newValue);
    // }, true);


    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      },
      {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }, {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }, {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }, {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }, {
        item: {
          key: $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" ? "all2y" : 'all',
          description: 'core.period.allMessages'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[5];
    vm.numberOfMessagesToShow = 10;
    vm.messageToShow = {};
    vm.openReplySection = false;
    vm.messageSuccessfullySent = false;


    vm.timeFilter = function (item) {
      vm.selectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      if (vm.messageSelectedTab == 1) {
        vm.unreadMessagesFilter.creationDateGTE = timeRange.valueDateGTE;
        vm.unreadMessagesFilter.creationDateLTE = timeRange.valueDateLTE;
        vm.readMessagesFilter.creationDateGTE = timeRange.valueDateGTE;
        vm.readMessagesFilter.creationDateLTE = timeRange.valueDateLTE;
        vm.getUnreadMessagesPage(true);
        vm.getReadMessagesPage(true);
      }

      if (vm.messageSelectedTab == 2) {
        vm.sentMessagesFilter.creationDateGTE = timeRange.valueDateGTE;
        vm.sentMessagesFilter.creationDateLTE = timeRange.valueDateLTE;
        vm.getSentMessagesPage(true);
      }

      if (vm.messageSelectedTab == 3) {
        vm.globalMessagesFilter.creationDateGTE = timeRange.valueDateGTE;
        vm.globalMessagesFilter.creationDateLTE = timeRange.valueDateLTE;
        vm.getGlobalMessagesPage(true);
      }
      GoogleTagManagerHelperService.pushEvent('Messages', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

      //loadMessages();
    };

    vm.selectTab = function (tabIndex, translateLabel) {
      if (tabIndex === vm.messageSelectedTab) return;
      vm.messageSearchInput.subject = '';
      vm.messageSelectedTab = tabIndex;
      if (tabIndex === 1) {
        vm.getUnreadMessagesPage(true);
        vm.getReadMessagesPage(true);
      } else if (tabIndex === 2) {
        vm.getSentMessagesPage(true);
      }

      GoogleTagManagerHelperService.pushEvent('Messages', 'Tab', $translate.instant(translateLabel));
    };


    vm.replyToMessage = function (messageId) {
      $state.go('newMessage', {
        messageId: messageId
      });
    };


    vm.deleteMessage = function (messageId, reloadList) {

      MessagesService.deleteMessageById(messageId)
        .then(function (response) {
          if (response === true) {
            $log.debug("deleteMessage", response);
            if (response) {
              switch (reloadList) {
                case "unread":
                  vm.getUnreadMessagesPage();
                  break;
                case "read":
                  vm.getReadMessagesPage();
                  break;
                case "sent":
                  vm.getSentMessagesPage();
                  break;
                default:
                  vm.getUnreadMessagesPage();
                  vm.getReadMessagesPage();
                  vm.getSentMessagesPage();
                  break;
              }
            }
          }

        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    function getMessage(message, section) {
      var promises = [];
      promises.push(MessagesService.getById(message.id));
      // if(message.messageType == "Order" && message.objectId) {
      //   promises.push(MessagesService.getOrderById(message.objectId));
      // }
      $q.all(promises)
        .then(function (response) {
          angular.extend(message, response[0]);
          message.selected = !message.selected;

          if (section === 'unread' && message.selected) {
            markMessageAsRead(message);
          }
        }, function (error) {
          $log.info(error);
        });
    }
    function markMessageAsRead(message) {

      MessagesService.markAsRead(message.id)
        .then(function (response) {
          if (response === true) {
            $log.debug("markAsRead Response ", response);
          }
        })
        .catch(function (error) {
          $log.error(error);
        });

      vm.openReplySection = false;
      vm.messageToShow = {};
    }

    vm.unreadMessagesFilter = {
      page: 0,
      unreadMessages: true,
      messageStatus: "Received",
      creationDateGTE: null,
      creationDateLTE: null,
      orderBy: "creationDate DESC",
      pageSize: 10
    };
    vm.readMessagesFilter = {
      page: 0,
      unreadMessages: false,
      messageStatus: "Received",
      creationDateGTE: null,
      creationDateLTE: null,
      orderBy: "creationDate DESC",
      pageSize: 10
    };
    vm.sentMessagesFilter = {
      page: 0,
      messageStatus: "Sent",
      creationDateGTE: null,
      creationDateLTE: null,
      orderBy: "creationDate DESC",
      pageSize: 10
    };

    vm.globalMessagesFilter = {
      page: 0,
      pageSize: 10,
      messageType: "Global",
      messageStatus: "Received",
      creationDateGTE: null,
      creationDateLTE: null,
      orderBy: "creationDate DESC"

    };

    if (vm.countryCode === 'bh') {
      vm.unreadMessagesFilter.includeList = 'attachmentList';
      vm.readMessagesFilter.includeList = 'attachmentList';
    }

    vm.unreadMessagesList = {};
    vm.readMessagesList = {};
    vm.sentMessagesList = {};
    vm.globalMessagesList = {};

    vm.getUnreadMessagesPage = function (reset) {

      if (reset) {
        vm.unreadMessagesFilter.page = 0;
      } else {
        vm.unreadMessagesFilter.page += 1;
      }
      if (vm.unreadMessagesList.hasMore || vm.unreadMessagesFilter.page === 0) {
        MessagesService.getPage(vm.unreadMessagesFilter)
          .then(function (response) {
            if (reset) {
              vm.unreadMessagesList = response;
              $log.debug("vm.unreadMessagesList", vm.unreadMessagesList);
            } else {
              vm.unreadMessagesList.hasMore = response.hasMore;
              vm.unreadMessagesList.result = vm.unreadMessagesList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };
    vm.getReadMessagesPage = function (reset) {

      if (reset) {
        vm.readMessagesFilter.page = 0;
      } else {
        vm.readMessagesFilter.page += 1;
      }
      if (vm.readMessagesList.hasMore || vm.readMessagesFilter.page === 0) {
        MessagesService.getPage(vm.readMessagesFilter)
          .then(function (response) {
            if (reset) {
              vm.readMessagesList = response;
              $log.debug("vm.readMessagesList", vm.readMessagesList);
            } else {
              vm.readMessagesList.hasMore = response.hasMore;
              vm.readMessagesList.result = vm.readMessagesList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }

    };
    vm.getSentMessagesPage = function (reset) {
      if (reset) {
        vm.sentMessagesFilter.page = 0;
      } else {
        vm.sentMessagesFilter.page += 1;
      }
      if (vm.sentMessagesList.hasMore || vm.sentMessagesFilter.page === 0) {
        MessagesService.getPage(vm.sentMessagesFilter)
          .then(function (response) {
            if (reset) {
              vm.sentMessagesList = response;
              $log.debug("vm.sentMessagesList", vm.sentMessagesList);
            } else {
              vm.sentMessagesList.hasMore = response.hasMore;
              vm.sentMessagesList.result = vm.sentMessagesList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };
    vm.getGlobalMessagesPage = function (reset) {
      if (reset) {
        vm.globalMessagesFilter.page = 0;
      } else {
        vm.globalMessagesFilter.page += 1;
      }
      if (vm.globalMessagesList.hasMore || vm.globalMessagesFilter.page === 0) {
        MessagesService.getPage(vm.globalMessagesFilter)
          .then(function (response) {
            if (reset) {

              vm.globalMessagesList = response;
              $log.debug("vm.globalMessagesList", vm.globalMessagesList);
            } else {
              vm.globalMessagesList.hasMore = response.hasMore;
              vm.globalMessagesList.result = vm.globalMessagesList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };
    vm.openMessagesNotificationLink = function () {
      vm.messageToShow = {};
      vm.openReplySection = false;
      vm.messageSuccessfullySent = false;
    };

    vm.openReplySectionAction = function (e, message) {
      vm.openReplySection = true;
      e.preventDefault();
      vm.messageToShow = message;
      vm.sendMessageObject.subject = "RE: " + message.subject;
    };
    vm.cancelReply = function () {
      vm.messageSuccessfullySent = false;
      vm.openReplySection = false;
      vm.messageToShow = {};
      vm.resetSendMessageObject();

    };

    vm.loadMoreMessages = function (type) {
      switch (type) {
        case "u":
          vm.getUnreadMessagesPage(false);
          break;
        case "r":
          vm.getReadMessagesPage(false);
          break;
        case "s":
          vm.getSentMessagesPage(false);
          break;
        case "global":
          vm.getGlobalMessagesPage(false);
          break;
      }
    };

    vm.printMessage = function (messageId) {
      //MessagesService.printMessage(messageId)
      //  .then(function (link) {
      //    if (link) {
      //      $window.open(link, '_blank');
      //    }
      //  })
      //  .catch(function (error) {
      //    NotificationService.showMessage(error, 'error');
      //  })
      MessagesService.printMessage(messageId);
    };


    function expandMessage(message, section) {

      // SLO Api does not return message content, so it is required to get it separatedly
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl" && !message.selected) {
        getMessage(message, section);
      } else {
        message.selected = !message.selected;

        // Mark unread messages as read when expanded
        if (section === 'unread' && message.selected) {
          markMessageAsRead(message);
        }
      }
    }

    vm.openPayment = function (data) {
      if (data.messageType === "Payment") {
        PaymentsService.downloadPaymentPdf(data.objectId);
      } else {
        // Order
      }
    };


    vm.downloadMessageAttachment = function (link) {
      if (link.itemList) {
        MessagesService.downloadMessageAttachment(link.itemList.linkAttachment);
      }
    };

    vm.downloadAttachment = function(attachment) {
      MessagesService.downloadAttachment(attachment);
    }


    function loadMessages() {
      vm.getUnreadMessagesPage(true);
      vm.getReadMessagesPage(true);

      // if(vm.countryCode == 'sl'){
      //   vm.selectedPeriod = vm.periodList[5];
      //   var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      //   vm.sentMessagesFilter.creationDateGTE = timeRange.valueDateGTE;
      //   vm.sentMessagesFilter.creationDateLTE = timeRange.valueDateLTE;
      //
      //   // vm.getGlobalMessagesPage(true);
      // }

      //vm.getSentMessagesPage(true);
    }


    vm.init();
  }
}());
