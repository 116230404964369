(function () {
  'use strict';
  angular
    .module('settings')
    .controller('SettingsAccountCtrl', SettingsAccountCtrl);

  function SettingsAccountCtrl($rootScope,
                               $http,
                               $state,
                               $uibModalInstance,
                               userSettingsData,
                               UserSettingsService,
                               HelperService,
                               AlertService, NotificationService) {

    var vm = this;
    vm.userGSM = $rootScope.APP_GLOBAL_SETTINGS.userSettings.gsm;
    vm.step = 1;
    vm.contactDataAcquisition = 0;

    vm.formSubmitedGsm = false;

    init();

    function init() {
      vm.model = {};
      vm.model.userSettings = {};
      loadData();
    }

    function loadData() {
      vm.contactDataAcquisition = $rootScope.APP_GLOBAL_SETTINGS.defaultData.contactDataAcquisition;
      // $rootScope.APP_GLOBAL_SETTINGS.userSettings = userSettingsData;
      var userSettings = userSettingsData;
      vm.model.userSettings.gsmCountryCode = userSettings.gsmCountryCode;
      vm.model.userSettings.gsmOperator = userSettings.gsmOperator;
      vm.model.userSettings.gsm = userSettings.notificationGsm;
      vm.model.userSettings.email = userSettings.notificationEmail;
      vm.model.userSettings.linkList = {};
      vm.model.userSettings.signData = '';
      // vm.initialModel = angular.copy(userSettings);
    }

    function prepareDataForUpdate(data, type, linkList, gsmPhoneFormated, oderDate, signData) {
      var object;
      if (type === 'email') {
        object = {
          'email': data,
          'dataConfirmation' : true
        };
      }
      if (type === 'gsm') {
        object = {
          'email': '',
          'gsm': data,
          'dataConfirmation' : true
        };
      }
      if (type === 'confirm') {
        object = {
          'email': '',
          'gsm': data,
          'linkList': linkList,
          'gsmPhoneFormated': gsmPhoneFormated,
          'oderDate': oderDate,
          'signData': signData,
          'dataConfirmation' : true
        };
      }
      return object;
    }

    vm.confirmUserEmailChange = function () {
      vm.formSubmitedGsm = true;
      if(!vm.model.userSettings.gsm || (vm.model.userSettings.gsm && vm.model.userSettings.gsm.trim() === '')) {
        return;
      }
      var updateData = {};
      // PROMJENA EMAIL
      if (userSettingsData.notificationEmail !== vm.model.userSettings.email && userSettingsData.notificationGsm === vm.model.userSettings.gsm) {
        updateData = prepareDataForUpdate(vm.model.userSettings.email, 'email');
        UserSettingsService.updateUserInfoOrders(updateData)
          .then(function (response) {
            if (response) {
              vm.model.userSettings.email = response.email;
              $rootScope.APP_GLOBAL_SETTINGS.userSettings.email = response.email;
              $uibModalInstance.close(null);
            }}).catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
      }
      // PROMJENA EMAIL I GSM
      else if (userSettingsData.notificationEmail !== vm.model.userSettings.email
        && userSettingsData.notificationGsm !== vm.model.userSettings.gsm) {

        if (vm.model.userSettings.email.trim() === '') {
          vm.sendSMS();
        } else {
          updateData = prepareDataForUpdate(vm.model.userSettings.email, 'email');
          UserSettingsService.updateUserInfoOrders(updateData)
            .then(function (response) {
              if (response) {
                vm.model.userSettings.email = response.email;
                $rootScope.APP_GLOBAL_SETTINGS.userSettings.email = response.email;
                vm.sendSMS();
              }
            }).catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        }
      }
      // PROMJENA GSM
      else if (userSettingsData.notificationEmail === vm.model.userSettings.email
        && userSettingsData.notificationGsm !== vm.model.userSettings.gsm) {
        vm.sendSMS();
      }
      // korisnik ima unesene podatke i samo ih potvrdjuje
      else {
        vm.sendSMS();
      }
    };

    vm.sendSMS = function (callback) {
      var updateData = {};
      // ako postoji stari br telefona, sms se salje na stari broj telefona, ako ne postoji salje se na novi br
      if (userSettingsData.notificationGsm !== null && userSettingsData.notificationGsm !== '') {
        updateData = prepareDataForUpdate(userSettingsData.notificationGsm, 'gsm');
      } else {
        updateData = prepareDataForUpdate(vm.model.userSettings.gsm, 'gsm');
      }
      UserSettingsService.validateUserInfoOrders(updateData)
        .then(function (response) {
          vm.step = 2;
          vm.model.orderDate = new Date();
          vm.model.userSettings.gsmPhoneFormatted = vm.model.userSettings.gsm.replace(/(.{3})/g, '$1 ').trim();
          vm.model.userSettings.linkList = response.linkList;
          vm.model.userSettings.gsm = response.gsm;
          $rootScope.APP_GLOBAL_SETTINGS.userSettings.gsm = response.gsm;
          if (callback) {
            callback();
          }
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.confirm = function () {
      var updateData = prepareDataForUpdate(vm.model.userSettings.gsm, 'confirm', vm.model.userSettings.linkList,
        vm.model.userSettings.gsmPhoneFormatted, vm.model.orderDate, vm.model.userSettings.signData);
      $http.post(vm.model.userSettings.linkList.itemList.sign, updateData)
        .then(function () {
          AlertService.infoAlert({
            text: 'mobilePhoneSettings.numberChangeSuccess'
          }).then(function () {
            $state.go('settings', {}, {reload: true});
            $uibModalInstance.close(null);
          });
        }, function (error) {
          NotificationService.showMessage(error.data, 'error');
        }).finally(function () {
          vm.confirmationInProgress = false;
        });
    };
  }
}());
