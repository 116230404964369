<section id="main">
  <div class="container-fluid">
    <h3 translate="paymentDocumentation.title"></h3>
    <div translate="paymentDocumentation.user" class="file-upload-help-text" style="display: inline !important;">
    </div>
    <br><br>
    <div translate="paymentDocumentation.info">
    </div>
    <div class="file-upload-help-text">
      <ol>
        <li translate="paymentDocumentation.list1"></li>
        <li translate="paymentDocumentation.list2"></li>
        <li translate="paymentDocumentation.list3"></li>
      </ol>
    </div>

    <div class="row" ng-if="documentationPaymentMne.totalItems>0">
      <table class="width-100 table table-striped" style="margin: 18px;">

        <tr ng-repeat="file in documentationPaymentMne.files" class="file-row">

          <td class="col-sm-6">
            <div>
              {{file.fileName}}
            </div>
            <div>
              <span translate="paymentDocumentation.lastUpdated"></span> {{file.modificationDate | date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
          </td>

          <td class="col-sm-1">
            <button class="btn btn-info" style="float:right"
                    data-ng-click="documentationPaymentMne.downloadFile(file.id)">
              <span translate="paymentDocumentation.downloadText"></span>
            </button>
          </td>

          <td class="col-sm-1">
            <button class="btn btn-info" data-ng-click="documentationPaymentMne.deleteFile(file.id)">
              <span translate="paymentDocumentation.removeText"></span>
            </button>
          </td>

        </tr>

      </table>
    </div>

    <div class="row" ng-if="documentationPaymentMne.totalItems>0">
      <div class="col-lg-6">
        <span translate="paymentDocumentation.numberOfRowsPerPage"></span>
        <span>{{documentationPaymentMne.itemsPerPage}}</span>
      </div>
      <div class="col-lg-6">
        <span class="float-right" translate="paymentDocumentation.pageNumber"></span>
      </div>
    </div>

    <div class="row" ng-if="documentationPaymentMne.totalItems>0">
      <div class="col-lg-6">
        <div class="btn-group" uib-dropdown style="margin: 18px; margin-left: 0px;">
          <button id="split-button" type="button" class="btn btn-danger">{{documentationPaymentMne.itemsPerPage}}
          </button>
          <button type="button" class="btn btn-danger" uib-dropdown-toggle>
            <span class="caret"></span>
            <span class="sr-only"></span>
          </button>
          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="split-button">
            <li role="menuitem"><a ng-click="documentationPaymentMne.changeItemsPerPage(5)">5</a></li>
            <li role="menuitem"><a ng-click="documentationPaymentMne.changeItemsPerPage(10)">10</a></li>
            <li role="menuitem"><a ng-click="documentationPaymentMne.changeItemsPerPage(15)">15</a></li>
            <li role="menuitem"><a ng-click="documentationPaymentMne.changeItemsPerPage(20)">20</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6">
        <ul uib-pagination
            boundary-links="true"
            total-items="documentationPaymentMne.totalItems"
            items-per-page="documentationPaymentMne.itemsPerPage"
            ng-model="documentationPaymentMne.currentPage"
            class="pagination-sm float-right"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
            first-text="&laquo;"
            last-text="&raquo;"
            ng-change="documentationPaymentMne.pageChanged()"></ul>
      </div>

    </div>

    <div class="row" ng-if="documentationPaymentMne.totalItems>0">
      <div class="col-lg-12">
        <span translate="paymentDocumentation.numberDocuments"></span>
        <span>{{documentationPaymentMne.totalItems}}</span>
      </div>
    </div>

    <div ng-if="documentationPaymentMne.totalItems>0" class="height-30 width-100" >
    </div>
    <input
          type="file"
          id="fileElem"
          multiple
          accept="application/pdf, image/jpeg, image/png"
          style="display:none" />
    <div class="dropzone" upload-files="[application/pdf, image/jpeg, image/png]"
         file="documentationPaymentMne.file" file-name="documentationPaymentMne.fileName"
         file-object="documentationPaymentMne.fileObject" data-max-file-size="3">
      <span ng-if="documentationPaymentMne.file==null" style="color: #cdcfdb;"
            translate="paymentDocumentation.uploadText"></span>
      <span ng-if="documentationPaymentMne.file!==null" class="file-name">{{documentationPaymentMne.fileName}}</span>
    </div>

    <div class="height-30 width-100" >
    </div>

    <button data-ng-click="documentationPaymentMne.confirmFile()"
            ng-disabled="documentationPaymentMne.file==null"
            class="btn btn-default confirm-btn">
      <span translate="paymentDocumentation.confirmText"></span>
    </button>
    <button data-ng-click="documentationPaymentMne.cancelFile()"
            class="btn btn-info">
      <span translate="paymentDocumentation.cancelText"></span>
    </button>

    <div class="height-30 width-100" >
    </div>
    <hr>

    <button data-ui-sref="payments.paymentsOverview"
            class="btn btn-primary">
      <span translate="newMessage.backBtn">Back</span>
    </button>

    <button class="btn btn-default confirm-btn float-right" translate="paymentActions.sign"
            ng-if="documentationPaymentMne.signedAllowed"
            data-ng-click="documentationPaymentMne.signPayment(documentationPaymentMne.payment)">
      Sign payment
    </button>
  </div>
</section>


