<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="security.title">Security</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->
    <div class="row">
      <div class="col-md-12">
        <div class="po-type-container">
          <div class="no-wrap-text cursor-pointer"
          ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
               translate="security.tabAddikoEbank"
               ng-click="settings.selectTab('eBank', 'settings.tabAccount')"
               ng-class="{'selected': settings.selectedTab == 'eBank',
                          'w-100': APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr',
                          'settings-tab cro': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl',
                          'settings-tab width-100 text-left': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && !APP_GLOBAL_SETTINGS.defaultData.ShowUserSettingsNotification,
                          'settings-tab': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && APP_GLOBAL_SETTINGS.defaultData.ShowUserSettingsNotification }">
          </div>
          <!-- Sakriti Notifications tab -->
          <div class="no-wrap-text cursor-pointer"
              ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
              translate="security.tabAddikoMobile"
              ng-class="{'selected': settings.selectedTab == 'mBank',
                        'settings-tab': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' }"
              ng-click="settings.selectTab('mBank', 'settings.tabNotifications')">
               AddikoMobile
          </div>
        </div>
      </div>
    </div>

    <!-- <div ng-switch="settings.selectedTab"></div> -->
    <div>
      <!-- ======================  -->
      <!-- START: ACCOUNT TAB      -->
      <!-- ======================  -->

      <!-- <div ng-switch-when="eBank" -->

      <div class="settings-content m-b-30">
      <!-- ====================== -->
      <!-- CORPCOPY START         -->
      <!-- ====================== -->
          <div class="container">

              <div class="row m-b-30">
                <div class="col-sm-12">
                  <div class="info-message m-t-20 m-b-20 w-100">
                    <i class="fa fa-info-circle info-icon"></i>
                    <div id="info_text" class="pull-left">
                        <span class="domp-form-label-15" data-ng-bind-html="settings.infoText"></span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-12 text-right">
                  <button class="btn btn-default" 
                          ng-click="settings.logout()"
                          translate="security.buttons.logout" >
                  </button>
                </div>
              </div>

              <div ng-if="settings.securitySettingsLoaded">
                <!-- =========================== -->
                <!-- START: Iznosi plaćanja -->
                <!-- =========================== -->
                <div class="no-bg-gray m-b-30">
                  <div class="row">
                    <div class="col-md-4">
                      <!-- START Title and toggle button -->
                      <div class="p-b-15">
                        <div class="custom-switch-addiko p-r-15">
                          <label class="text-left section-title" 
                                 translate="security.paymentRestrictions.paymentAmounts">
                            Iznosi plaćanja
                          </label>
                          <label class="switch customClass d-block float-right">
                            <input type="checkbox" name="paymentsAmountToggle"
                                   ng-model="settings.model.paymentAmounts.checked"
                                   ng-change="settings.onPaymentAmountsCheckboxToggle($event)"
                                   ng-disabled="settings.togglePaymentAmountsLoading">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <!-- END Title and toggle button -->
                    </div>
                  </div>
                  <div ng-if="settings.model.paymentAmounts.checked">
                    <div class="row m-b-15">
                      <div class="col-md-12">
                        <label class="domp-form-label-15" translate="security.paymentRestrictions.individualAmount"></label>
                      </div>
                      <div class="col-md-6">
                          <input type="text" class="form-control input-field t-a-r" name="individualAmount" id="individualAmount" 
                          amount-input-mask maxlength="12" ng-model="settings.model.paymentAmounts.individualAmount" 
                          required>
                      </div>
                      <div class="col-md-6">
                        <span class="domp-form-label-15" translate="security.paymentRestrictions.individualAmountDesc"></span>
                      </div>
                    </div>
                    <div class="row m-b-15">
                      <div class="col-md-12">
                        <label class="domp-form-label-15" translate="security.paymentRestrictions.dailyAmount"></label>
                      </div>
                      <div class="col-md-6">
                          <input type="text" class="form-control input-field t-a-r" name="totalDailyAmount" id="totalDailyAmount" 
                          amount-input-mask ng-model="settings.model.paymentAmounts.totalDailyAmount" 
                          required>
                      </div>
                      <div class="col-md-6">
                        <span class="domp-form-label-15" translate="security.paymentRestrictions.dailyAmountDesc"></span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                          <button class="btn btn-default" translate="security.paymentRestrictions.saveChanges"
                          ng-click="settings.savePaymentAmountsInput()"
                          ng-disabled="settings.savePaymentAmountsChangesLoading">
                            </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- =========================== -->
                <!--    END: Iznosi plaćanja     -->
                <!-- =========================== -->

                <!-- =========================== -->
                <!-- START: Lokacije i valuta -->
                <!-- =========================== -->
                <div class="py-3 bg-gray m-b-30">
                  <div class="row">
                    <div class="col-md-5">
                      <!-- START Title and toggle button -->
                      <div class="p-b-15">
                        <div class="custom-switch-addiko p-r-15">
                          <label class="text-left section-title"
                            translate="security.paymentRestrictions.locationAndCurrency">
                            Lokacije i valuta
                          </label>
                          <label class="switch customClass d-block float-right">
                            <input type="checkbox" name="locationAndCurrencyToggle" 
                              ng-model="settings.model.locationAndCurrency.checked"
                              ng-change="settings.onLocationAndCurrencyCheckboxToggle($event)"
                              ng-disabled="settings.toggleLocationAndCurrencyLoading">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <!-- END Title and toggle button -->
                    </div>
                  </div>
                
                  <div ng-if="settings.model.locationAndCurrency.checked">
                    <div class="row m-b-15">
                      <div class="col-md-12">
                        <div class="p-b-10">
                          <div class="pretty d-block p-default p-round p-thick">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="forbidAllPayments" type="radio" name="locationAndCurrency" value="ALL_FORBIDDEN"
                              ng-model="settings.model.locationAndCurrency.subTypeId" />
                              <i class="input-helper p-l-5"></i> 
                              <label class="domp-form-label-15" for="forbidAllPayments" 
                                    translate="security.paymentRestrictions.forbidAll">
                                Zabrani sva plaćanja
                              </label>
                            </label>

                          </div>
                        </div>
                
                        <div class="p-b-10">
                          <div class="pretty d-block p-default p-round p-thick">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="forbidAllPaymentsOutsideOfCroatia" type="radio" name="locationAndCurrency" value="RH_ONLY"
                                ng-model="settings.model.locationAndCurrency.subTypeId" />
                              <i class="input-helper p-l-5"></i>
                              <label class="domp-form-label-15" for="forbidAllPaymentsOutsideOfCroatia" 
                                    translate="security.paymentRestrictions.forbidAllOutsideCro">
                                Zabrani plaćanja izvan granica Hrvatske
                              </label>
                            </label>
                          </div>
                        </div>
                
                        <div class="p-b-10">
                          <div class="pretty d-block p-default p-round p-thick">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="forbidAllPaymentsOutsideOfEU" type="radio" name="locationAndCurrency" value="EU_ONLY"
                                ng-model="settings.model.locationAndCurrency.subTypeId" />
                              <i class="input-helper p-l-5"></i>
                              <label class="domp-form-label-15" for="forbidAllPaymentsOutsideOfEU" 
                                    translate="security.paymentRestrictions.forbidAllOutsideEU">
                                Zabrani plaćanja izvan granica EU
                              </label>
                            </label>

                          </div>
                        </div>
                
                        <div class="p-b-10">
                          <div class="pretty d-block p-default p-round p-thick">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="onlyAllowPaymentsInCroatia" type="radio" name="locationAndCurrency" value="HRK_ONLY"
                                ng-model="settings.model.locationAndCurrency.subTypeId" />
                              <i class="input-helper p-l-5"></i>
                              <label  class="domp-form-label-15" for="onlyAllowPaymentsInCroatia" 
                                    translate="security.paymentRestrictions.allowOnlyInCro">
                                Dozvoli plaćanja samo u kunama unutar RH
                              </label>
                            </label>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row m-t-10">
                      <div class="col-md-12">
                        <button class="btn btn-default"
                          translate="security.paymentRestrictions.saveChanges" 
                          ng-click="settings.saveLocationAndCurrencySelection()"
                          ng-disabled="settings.saveLocationAndCurrencyChangesLoading">
                          Spremi izmjene
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- =========================== -->
                <!-- END: Lokacije i valuta -->
                <!-- =========================== -->

                <!-- =========================== -->
                <!-- START: Dodatna ograničenja po računu -->
                <!-- =========================== -->
                <div class="no-bg-gray m-b-30">
                  <div class="row m-b-15">
                    <div class="col-md-12">
                      <label class="text-left section-title" translate="security.paymentRestrictions.additionalLimit">
                        Dodatna ograničenja po računu
                      </label>
                    </div>
                  </div>
                
                  <div class="row">
                    <div class="col-md-4">
                      <div class="p-b-15">
                        <div class="custom-switch-addiko p-r-15">
                          <label class="text-left p-r-15 domp-form-label-15" translate="security.paymentRestrictions.whiteList">
                            Bijela lista
                          </label>
                          <label class="switch customClass d-block float-right" ng-click="settings.showWhiteListBlackListErrorMessage('white')">
                            <input type="checkbox" name="whiteListToggle" data-ng-model="settings.model.whiteList.checked"
                              ng-change="settings.onWhiteListCheckboxToggle($event)" ng-disabled="settings.model.blackList.checked || settings.toggleWhiteListLoading">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="p-b-15">
                        <div class="custom-switch-addiko p-r-15">
                          <label class="text-left p-r-15 domp-form-label-15" translate="security.paymentRestrictions.blackList">
                            Crna lista
                          </label>
                          <label class="switch customClass d-block float-right" ng-click="settings.showWhiteListBlackListErrorMessage('black')">
                            <input type="checkbox" name="blackListToggle" data-ng-model="settings.model.blackList.checked"
                              ng-change="settings.onBlackListCheckboxToggle($event)" ng-disabled="settings.model.whiteList.checked || settings.toggleBlackListLoading">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- =============================================== -->
                  <!-- START: Add to white/black list by this filter -->
                  <!-- =============================================== -->
                  <form name="ngForm" ng-if="settings.model.whiteList.checked || settings.model.blackList.checked" class="m-b-30">
                    <!-- START Select filter by-->
                    <div class="row m-b-30">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-4 pretty p-default p-round p-thick p-r-40">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="addByIban" type="radio" name="addByIban" value="IBAN" 
                                ng-model="settings.model.whiteAndBlackListAddType"
                                ng-change="settings.whiteAndBlackListAddTypeChange($event)" />
                              <i class="input-helper p-l-5"></i> 
                              <label class="domp-form-label-15" for="addByIban" 
                                    translate="security.paymentRestrictions.ibanOfBen">
                                IBAN
                              </label>
                            </label>

                          </div>
                          <div class="col-md-4 pretty p-default p-round p-thick p-r-40">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input type="radio" id="addByAccountNumber" name="addByAccountNumber" value="ACCOUNT_NUMBER" 
                              ng-model="settings.model.whiteAndBlackListAddType" ng-change="settings.whiteAndBlackListAddTypeChange($event)" />
                              <i class="input-helper p-l-5"></i> 
                              <label class="domp-form-label-15" for="addByAccountNumber" 
                                    translate="security.paymentRestrictions.accountNumber">
                                  Account number
                                </label>
                            </label>

                          </div>
                          <div class="col-md-4 pretty p-default p-round p-thick p-r-40">

                            <label class="radio radio-inline m-r-20 radio-value">
                              <input id="addCountryToList" type="radio" name="addCountryToList" value="COUNTRY"
                              ng-model="settings.model.whiteAndBlackListAddType" ng-change="settings.whiteAndBlackListAddTypeChange($event)" />
                              <i class="input-helper p-l-5"></i> 
                              <label class="domp-form-label-15" for="addCountryToList" 
                                    translate="security.paymentRestrictions.country">
                                Country
                              </label>
                            </label>

                          </div>
                        </div>
                    </div>
                    </div>
                    <!-- END Select filter by-->
                    <!-- START Input filter by-->
                    <div class="row" ng-if="settings.model.whiteAndBlackListAddType === 'IBAN'">
                      <div class="col-md-5">
                        <input name="ibanOfBen" type="text" class="form-control custom-addiko-input" ng-model="settings.model.ibanOfBen"
                          mask="SS00 9999 9999 9999 9999 9999 9999 9999 99"
                          ng-blur="settings.getReceiverNameByIBAN(settings.model.ibanOfBen);"
                          placeholder="{{'security.paymentRestrictions.ibanOfBen' | translate}}" maxlength="34" required>
                      </div>
                      <div class="col-md-5">
                        <input name="nameOfBen" type="text" class="form-control custom-addiko-input"
                          placeholder="{{'security.paymentRestrictions.nameOfBen' | translate}}" ng-model="settings.model.nameOfBen">
                      </div>
                      <div class="col-md-2">
                        <div class="circle-button"
                             ng-click="settings.addSettingToList(ngForm)">
                          +
                        </div>
                      </div>
                    </div>
                    <div class="row" ng-if="settings.model.whiteAndBlackListAddType === 'ACCOUNT_NUMBER'">
                      <div class="col-md-5">
                        <input name="accountNumber" type="text" class="form-control custom-addiko-input"
                          ng-model="settings.model.accountNumber" 
                          placeholder="{{ settings.accountOfReceiverTranslate }}"
                          maxlength="20" required>
                      </div>
                      <div class="col-md-5">
                        <input name="nameOfBen" type="text" class="form-control custom-addiko-input"
                          placeholder="{{'security.paymentRestrictions.nameOfBen' | translate}}" ng-model="settings.model.nameOfBen">
                      </div>
                      <div class="col-md-2">
                        <div class="circle-button" 
                             ng-click="settings.addSettingToList(ngForm)">
                          +
                        </div>
                      </div>
                    </div>
                    <div class="row" ng-if="settings.model.whiteAndBlackListAddType === 'COUNTRY'">
                      <div class="col-md-6">
                    
                        <div class="form-group clearfix">
                          <div class="col-xs-12 domp-filter-container h-55">
                            <div class="col-xs-1 inner-section left" style="margin-left: -5px !important;">
                              <i class="icon icon-search domp-search-sign m-l-5"></i>
                            </div>
                            <div class="col-xs-9 col-sm-10 inner-section input-div searchrow">
                              <input type="text" class="search-input" name="countrySearchFilter" ng-model="settings.countrySearchFilter"
                                ng-change="settings.countrySearchFilterExpanded = true;"
                                ng-pattern-restrict="{{settings.regexPatterns.onlyNumbersAndLettersWithSuskavci}}" required
                                autocomplete="off">
                            </div>
                    
                            <div class="col-xs-1 inner-section right"
                              ng-click="settings.countrySearchFilterExpanded = !settings.countrySearchFilterExpanded">
                              <i class="zmdi zmdi-close domp-close-sign" ng-show="settings.countrySearchFilter"
                                ng-click="settings.removeSelectedCountry()"></i>
                              <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right" ng-show="!settings.countrySearchFilter"></i>
                            </div>
                            <!--Dropdown menu -->
                            <div class="domp-filter-selection search-row" ng-class="{'opened': settings.countrySearchFilterExpanded}">
                              <div class="domp-filter-type-selection-wrapper"
                                ng-repeat="country in settings.countriesOptions| filter: settings.countrySearchFilter"
                                ng-click="settings.onCountrySelect(country)">
                                <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                  <!--<i class="zmdi zmdi-account-circle"></i>-->
                                </div>
                                <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                  <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="c-red" ng-show="!settings.countrySearchFilter && settings.formSubmitted"
                            translate="payments.bicCountryRequired"></div>
                    
                        </div>

                      </div>

                      <div class="col-md-2 col-md-offset-4">
                        <div class="circle-button" 
                             ng-click="settings.addSettingToList(ngForm)">
                          +
                        </div>
                      </div>
                    </div>
                      <!-- END Input filter by-->
                  </form>
                  <!-- =============================================== -->
                  <!-- END: Add to white/black list by this filter -->
                  <!-- =============================================== -->
                  <!-- =========================== -->
                  <!-- START: White or black list -->
                  <!-- =========================== -->
                  <div class="row bg-gray bg-gray-padding-fix border-top-custom-addiko" ng-if="(settings.model.whiteList.checked || settings.model.blackList.checked) && 
                  (settings.model.whiteList.list.length > 0 || settings.model.blackList.list.length > 0)">
                    <div class="col-12">

                      <!-- Large screens start -->
                      <!-- Header for payments sign -->
                      <div class="row group-sign-header hidden-xs m-b-17-addiko">
                        <b>
                          <span class="col-sm-4" translate="security.paymentRestrictions.settingType">
                            Vrsta pravila
                          </span>
                        </b>
                        <b>
                          <span class="col-sm-6" translate="security.paymentRestrictions.settingDefinition">
                            Definicija pravila
                          </span>
                        </b>
                        <span class="col-sm-2">
                        </span>
                      </div>

                      <!-- Details for group of payments -->
                      <div class="row hidden-xs word-break table-like-look" ng-if="settings.model.whiteList.checked && settings.model.whiteList && settings.model.whiteList.list && settings.model.whiteList.list.length"
                        data-ng-repeat="restriction in settings.model.whiteList.list">
                        <span class="col-sm-4">
                          <div class="row">
                            <span ng-if="restriction.iban" class="col-sm-12"
                              translate="security.paymentRestrictions.ibanOfBen">
                              IBAN primatelja
                            </span>
                            <span ng-if="restriction.accountNumber" class="col-sm-12"
                              translate="security.paymentRestrictions.accountNumber">
                              Račun primatelja
                            </span>
                            <span ng-if="restriction.countryCode" class="col-sm-12" translate="security.paymentRestrictions.country">
                              Država  
                            </span>
                          </div>
                        </span>
                        <span class="col-sm-4">
                          <div class="row">
                            <span ng-if="restriction.iban" class="col-sm-12">
                              {{ restriction.iban }}
                            </span>
                            <span ng-if="restriction.accountNumber" class="col-sm-12">
                              {{ restriction.accountNumber }}
                            </span>
                            <span ng-if="restriction.iban || restriction.accountNumber" class="col-sm-12">
                              {{ restriction.payeeName }}
                            </span>
                            <span ng-if="restriction.countryCode" class="col-sm-12">
                              {{ restriction.countryCode }}<span ng-if="restriction.countryName"> - {{ restriction.countryName }}</span>
                            </span>
                          </div>
                        </span>
                      
                        <span class="col-sm-2">
                          <a class="pointer-custom-addiko" ng-click="settings.removeRestriction(restriction.id);" translate="security.paymentRestrictions.remove">
                            Remove
                          </a>
                        </span>
                      </div>
                      
                      <!-- Details for group of payments -->
                      <div class="row hidden-xs word-break table-like-look" ng-if="settings.model.blackList.checked && settings.model.blackList && settings.model.blackList.list && settings.model.blackList.list.length"
                        data-ng-repeat="restriction in settings.model.blackList.list">
                        <span class="col-sm-4">
                          <div class="row">
                            <span ng-if="restriction.iban" class="col-sm-12"
                              translate="security.paymentRestrictions.ibanOfBen">
                              IBAN primatelja
                            </span>
                            <span ng-if="restriction.accountNumber" class="col-sm-12"
                              translate="security.paymentRestrictions.accountNumber">
                              Račun primatelja
                            </span>
                            <span ng-if="restriction.countryCode" class="col-sm-12" translate="security.paymentRestrictions.country">
                              Država  
                            </span>
                          </div>
                        </span>
                        <span class="col-sm-4">
                          <div class="row">
                            <span ng-if="restriction.iban" class="col-sm-12">
                              {{ restriction.iban }}
                            </span>
                            <span ng-if="restriction.accountNumber" class="col-sm-12">
                              {{ restriction.accountNumber }}
                            </span>
                            <span ng-if="restriction.iban || restriction.accountNumber" class="col-sm-12">
                              {{ restriction.payeeName }}
                            </span>
                            <span ng-if="restriction.countryCode" class="col-sm-12">
                              {{ restriction.countryCode }}<span ng-if="restriction.countryName"> - {{ restriction.countryName }}</span>
                            </span>
                          </div>
                        </span>
                      
                        <span class="col-sm-2">
                          <a class="pointer-custom-addiko"  ng-click="settings.removeRestriction(restriction.id);" translate="security.paymentRestrictions.remove">
                            Remove
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- =========================== -->
                  <!-- END: White or black list -->
                  <!-- =========================== -->
                </div>
                <!-- =========================== -->
                <!-- END: Dodatna ograničenja po računu -->
                <!-- =========================== -->
              </div>
              <!-- =========================================================== -->
              <!-- END: Restrictions settings -->
              <!-- =========================================================== -->
            </div>
      </div>
      <!-- ======================  -->
      <!-- END: ACCOUNT TAB        -->
      <!-- ======================  -->

      <!-- ======================  -->
      <!-- START: NOTIFICATIONS TAB  -->
      <!-- ======================  -->

      <!-- <div ng-switch-when="mBank" class="settings-content">

      </div> -->
      
      <!-- ======================  -->
      <!-- END: NOTIFICATIONS TAB  -->
      <!-- ======================  -->

    </div>
  </div>
</section>
