(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EinvoicesPayCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EinvoicesPayCtrl', EinvoicesPayCtrl);

  function EinvoicesPayCtrl($rootScope, $http, CTLocalizationService, $state, EInvoiceDocumentsService, PaymentTypesService, PaymentsService, HelperService,
                             ConfigEndpointService, $log, $filter, $q, AlertService, $translate, Notification, NotificationService) {
    var vm = this;
    vm.ctrlName = 'EinvoicesPayCtrl';
    vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
    vm.countryCode = '';
    vm.invoice = {};
    vm.step = 'pay';
    // vm.smsAuthentificate = false;
    vm.fromAccountDropdown = false;
    vm.formSubmitted = false;
    vm.setAccountFrom = setAccountFrom;
    vm.goBack = goBack;
    vm.bankCharges = '';
    var forUpdate = false;
    vm.isUrgentPaymentCheckbox = false;
    vm.isInstantPaymentCheckbox = false;
    vm.hideUrgency = false;
    vm.hideInstantPayment = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
      vm.disabled = function (date, mode) {
        return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
      };
    }

    function init() {
      $log.debug('EinvoicesPayCtrl init()');
      if ($state.params.eInvoiceId) {
        // Pay invoice
        getEInvoiceById($state.params.eInvoiceId);
        getPaymentForEInvoice($state.params.eInvoiceId);
        getDefaultDetails();
      }
    };

    vm.showInfo = function () {
      var message = $translate.instant('domesticPayment.urgentInfo');
      var temp = {"resultList":[{"key":"Info","description":message}]};
      if(vm.isUrgentPaymentCheckbox){
        PaymentsService.showModalWithActions(temp,null);
      }
    };

    function getEInvoiceById(id) {
     return EInvoiceDocumentsService.getById(id)
        .then(function (response) {
          if(response){
            vm.invoice = response;
            vm.amountInput = vm.invoice.amount;
            vm.invoice.creditorAccount = vm.invoice.creditorAccount.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            vm.dateInput = new Date(); // vm.invoice.dueDate
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function getPaymentForEInvoice(invoiceId) {
     return EInvoiceDocumentsService.getEinvoiceForPaymentById(invoiceId)
        .then(function (response) {
          vm.invoicePayment = response;
          getPaymentTypeGroup();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function getDefaultDetails() {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response) {
            vm.defaultCurrency = response.DefaultCurrency;
            if (response.APILocation) {
              vm.countryCode = response.APILocation;
                vm.bankCharges = response.BankCharges;
            }
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function getPaymentTypeGroup() {
      var paymentTypesGroupFilter = {
        paymentTypeGroup: 'DomesticPayment',
        currencySymbol: ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") ? "EUR" : undefined
      };
      return PaymentTypesService.getByGroup(paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function(value){
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);
          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.fromAccountSelect = _.find(vm.paymentTypeGroupDomestic.result[0].debitAccountList,
              {iban: vm.invoicePayment.fromAccountNumber ? vm.invoicePayment.fromAccountNumber.replace(/ /g,'') : ''});
          }

          if(!vm.fromAccountSelect && vm.paymentTypeGroupDomestic.result[0].debitAccountList)
            vm.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          $log.error('Error loading paymentTypeGroupDomestic!');
        });
    }

    function setAccountFrom(item) {
      vm.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    function postPaymentAction(response) {
      vm.paymentResponse = response;
      if(!vm.paymentResponse.domesticPayment){
        vm.paymentResponse.domesticPayment = {};
        angular.extend(vm.paymentResponse.domesticPayment, vm.paymentResponse);
      }
      Notification.clearAll();
      vm.step = 'verify';
    }

    function postPaymentActionUpdate(response) {
      vm.paymentResponse = response;
      if(!vm.paymentResponse.domesticPayment){

        vm.paymentResponse.domesticPayment = {};
        angular.extend(vm.paymentResponse.domesticPayment, vm.paymentResponse);
      }
      vm.step = 'verify';
    }

    vm.confirmPayment = function () {
      if (vm.validateInProgress) return;

      if (!vm.paymentForm.$valid) {
        return;
      } else {
        if (vm.instantDateCheck()) {
          return;
        }
      }

      if (!vm.formSubmitted) {
        vm.formSubmitted = true;
        var paymentObj = createPaymentObject();

        vm.validateInProgress = true;
        if (forUpdate) {
          paymentObj.id = vm.paymentResponse.id;
          PaymentsService.updatePayment(vm.paymentResponse.id, paymentObj)
            .then(function (response) {
              if (response.dueDateChanged) {
                AlertService.confirmationAlert({
                  text: "payments.paymentDueDateChangedMsg"
                })
                  .then(function (accept) {
                    if (!accept) return;
                    secondValidateUpdate(response);
                  })
              } else {
                postPaymentActionUpdate(response);
                forUpdate = false;
              }
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              forUpdate = false;
            })
            .finally(function(){
              vm.formSubmitted = false;
              vm.validateInProgress = false;
            });
        } else {
          PaymentsService.insertPayment(paymentObj)
            .then(function (response) {
            if (response.dueDateChanged) {
              AlertService.confirmationAlert({
                text: "payments.paymentDueDateChangedMsg"
              })
                .then(function (accept) {
                if (!accept) return;
                secondValidate(response);
              })
            } else {
              postPaymentAction(response);
            }
            })
            .catch(function (error) {
                NotificationService.showMessage(error, 'error');
            })
            .finally(function(){
              vm.formSubmitted = false;
              vm.validateInProgress = false;
            });
        }
      }
    };

    function secondValidate(validatedUPOrder) {
      if (vm.secondValidationInProgress) return;
      var paymentObj = createPaymentObject();
      vm.secondValidationInProgress = true;
      PaymentsService.insertPayment(validatedUPOrder)
        .then(function (response) {
        postPaymentAction(response);
      })
        .catch(function (response, error) {
        if (response && response.hasWarnings) {
          //show action message modal
          PaymentsService.showModalWithActions(response, paymentObj);
        } else {
          NotificationService.showMessage(response, 'error');
        }
      })
        .finally(function () {
        vm.formSubmitted = false;
        vm.forUpdate = false;
          vm.secondValidationInProgress = false;
        });
    }

    function secondValidateUpdate(validatedUPOrder) {
      if (vm.secondValidationInProgress) return;
      var paymentObj = createPaymentObject();
      vm.secondValidationInProgress = true;
      PaymentsService.updatePayment(validatedUPOrder.id, validatedUPOrder)
        .then(function (response) {
          postPaymentActionUpdate(response);
        })
        .catch(function (response, error) {
          if (response && response.hasWarnings) {
            //show action message modal
            PaymentsService.showModalWithActions(response, paymentObj);
          } else {
            NotificationService.showMessage(response, 'error');
          }
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.secondValidationInProgress = false;
        });
    }

    function goBack() {
      $state.go('eInvoices.einvoicesOverview');
    }

    function createPaymentObject() {
      var paymentObject = {
        domesticPayment: {
          receiverName: vm.invoice.creditorName,
          receiverAddress1: vm.invoice.creditorAddress,
          receiverCity: vm.invoice.creditorCity,
          beneficiarySWIFTCode: vm.invoice.ultimateCreditorBIC,
          referenceTo: vm.invoicePayment.referenceTo, // 'SI006666666', // for testing
          urgency: vm.isUrgentPaymentCheckbox ? '1' : '0',
          instant: vm.isInstantPaymentCheckbox ? '1' : '0'
        },
        accountId: vm.fromAccountSelect.accountId,
        fromAccountNumber: vm.fromAccountSelect.iban,
        toAccountNumber: vm.invoicePayment.toAccountNumber, //'HR2325000091501135368'
        currencySymbol: vm.fromAccountSelect.currencySymbol,
        purpose: vm.invoice.purpose,
        amount: vm.amountInput,
        paymentTypeGroup: 'DomesticPayment',
        paymentSourceType: vm.invoicePayment.paymentSourceType,
        relatedSourceId: vm.invoicePayment.relatedSourceId,
        relatedSourceType: vm.invoicePayment.relatedSourceType
          //purposeCodeDPS:  vm.invoicePayment.purposeCodeSelect || '' //'ACCT'
      };

      paymentObject.dueDate = vm.dateInput;
      /*if(vm.paymentDateOption.val==1 || vm.paymentDateOption.val==3) {
       } else {
       paymentObject.dueDate = vm.eInvoiceObject.valueDate;
       }*/
      return paymentObject;
    }

      function mapWithPaymentResponse() {
        vm.amountInput = vm.paymentResponse.amount;
        vm.dateInput = new Date(vm.paymentResponse.dueDate);
        // filter account number
        var fromAccountIndex = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
          return item.accountNumber == vm.paymentResponse.fromAccountNumber.split(" ")[0];
        });
        if (fromAccountIndex != -1) {
          vm.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[fromAccountIndex];
        }
      };

      var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
       $rootScope.$on('appLanguageChangedHeader', function () {
         sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
       });

       vm.deletePayment = function () {
         AlertService.confirmationAlert()
           .then(function (result) {
             if (result) {
               PaymentsService.deletePayment(vm.paymentResponse.id)
                 .then(function (response) {
                   AlertService.deletedPaymentAlert({paymentName: vm.paymentResponse.id, type: 'payment'})
                     .then(function () {
                       $state.go('eInvoices.einvoicesOverview');
                     });
                 })
                 .catch(function (error) {
                   NotificationService.showMessage(error, 'error');
                 });
             }
           })
           .catch(function () {
             $log.debug('Example failed!');
           });


       };

    vm.editPayment = function () {
      mapWithPaymentResponse();
      forUpdate = true;
      vm.step = "pay";
    };

    vm.instantDateCheck = function () {
      return (vm.dateInput.toDateString() !== new Date().toDateString() && vm.isInstantPaymentCheckbox);
    };

   vm.signPayment = function (){
     if (vm.confirmationInProgress) return;
     vm.confirmationInProgress = true;
      $http.post(vm.paymentResponse.linkList.itemList.sign, vm.paymentResponse)
        .then(function(response){
          $state.go("payments.finalStep", {paymentObject: response.data, paymentId: response.data.id, type: 'einvoice'});
         })
        .catch(function (error) {
          NotificationService.showMessage(error.data, 'error');
      }).finally(function () {
        vm.confirmationInProgress = false;
      });

       // PaymentsService.signPayment(vm.paymentResponse.id)
       // .then(function (response) {
       //   vm.step = "final";
       // })
       // .catch(function (error) {
       // NotificationService.showMessage(error, 'error');
       // });
     };

    /*    vm.showSMSAuthentification = function () {
     vm.smsAuthentificate = !vm.smsAuthentificate;
     };*/

    init();
  }
}());
