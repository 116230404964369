(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name accounts.controller:AccountsTransactionsCtrl
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .controller('AccountsTransactionsCtrl', AccountsTransactionsCtrl);

    function AccountsTransactionsCtrl($scope, $translate, $log, $stateParams, AccountsService, TransactionsService, HelperService) {

        var vm = this;

        vm.numberOfAccountsToShow = 4;

        vm.selectedAccount = {};
        vm.selectedAccount = {
            transactions: {
                hasMore: false
            }
        };
        vm.accountList = [];
        vm.accountTransactionsFilter = {
            accountId: undefined,
            orderBy: 'ValueDate DESC',
            page: 0,
            pageSize: 100
        };

        vm.busy = false; //marks is request already fired

        vm.defaultAccountTransactionsTempFilter = {
            selectedTransactionType: 'all',
            amountGTE: null,
            amountLTE: null,
            genericSearch: null
        };


        vm.accountTransactionsTempFilter = {

        };

        vm.accountTransactionsTempFilter = _.extend(vm.accountTransactionsTempFilter, vm.defaultAccountTransactionsTempFilter);


        $translate(['transactions.transactionTypes.credit', 'transactions.transactionTypes.debit', 'transactions.transactionTypes.all']).then(function (translations) {

            vm.transactionTypeFilterList = [
                {
                    id: 'all',
                    description: translations['transactions.transactionTypes.all']
                }
        , {
                    id: 'credit',
                    description: translations['transactions.transactionTypes.credit']
                }
        , {
                    id: 'debit',
                    description: translations['transactions.transactionTypes.debit']
                }
      ];
        });



        vm.periodList = [
            {
                item: {
                    key: 'today',
                    description: 'core.period.today'
                }
            }
        , {
                item: {
                    key: 'yesterday',
                    description: 'core.period.yesterday'
                }
            }
        , {
                item: {
                    key: 'thisWeek',
                    description: 'core.period.thisWeek'
                }
            }
        , {
                item: {
                    key: 'thisMonth',
                    description: 'core.period.thisMonth'
                }
            }
        , {
                item: {
                    key: 'thisYear',
                    description: 'core.period.thisYear'
                }
            }
        , {
                item: {
                    key: 'all',
                    description: 'core.period.all'
                }
            }
      ];

        vm.selectedPeriod = vm.periodList[2];





        vm.init = function () {
            var index = 0;
            var selectedAccountIndex = 0;
            $log.debug($stateParams);
            AccountsService.getAll()
                .then(function (response) {
                    $(response.result).each(function (i, e) {
                        e.index = index++;
                        if ($stateParams.accountId !== undefined) {
                            if ($stateParams.accountId == e.accountId) {
                                selectedAccountIndex = e.index;

                            }
                        }
                    });
                    vm.accountList = response.result;
                    vm.selectAccount(selectedAccountIndex);

                    vm.getTransactions(true);
                })
                .catch(function (error) {
                    $log.error(error);
                    //alert('last catch');
                });
        };

        vm.selectAccount = function (index) {
            vm.selectedAccount = vm.accountList[index];
            vm.accountList[index].active = true;

            var selectedAccountIndex = vm.selectedAccount.index + 1;
            //vm.numberOfAccountsToShow = vm.selectedAccount.index + 1;
            if (vm.numberOfAccountsToShow < selectedAccountIndex) {
                vm.numberOfAccountsToShow = vm.selectedAccount.index + 1;
            }
            vm.getTransactions(true);
            console.log("DEJAN    " + JSON.stringify(vm.selectedAccount));
        };


        $scope.$watch('accountsTransactions.selectedPeriod', function (newValue) {
            // do something here
            vm.busy = false;
            vm.getTransactions(true);
        }, true);

        /*$scope.$watch('accountsTransactions.accountList', function(newValue) {
          // do something here
          $(newValue).each(function(i, e){
            if(e.active == true && e.accountId != vm.selectedAccount.accountId){
              vm.busy = false;
              vm.selectedAccount = e;
              vm.getTransactions(true);
            }
          });
        }, true);*/

        vm.getTransactions = function (reset) {

            if (vm.selectedAccount.accountId == undefined) {
                $log.debug("return", vm.selectedAccount)
                return;
            }

            if (!reset && vm.selectedAccount.transactions != undefined && vm.selectedAccount.transactions.hasMore != undefined && vm.selectedAccount.transactions.hasMore == false) {
                return;
            }

            if (vm.busy) return;
            vm.busy = true;

            if (reset) {
                vm.accountTransactionsFilter.page = 0;
            } else {
                vm.accountTransactionsFilter.page += 1;
            }
            //convert period to dates

            if (vm.selectedPeriod.item.key == 'today') {
                var range = HelperService.getToday();
                vm.accountTransactionsFilter.valueDateGTE = range.firstDay;
                vm.accountTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.item.key == 'yesterday') {
                var range = HelperService.getYesterday();
                vm.accountTransactionsFilter.valueDateGTE = range.firstDay;
                vm.accountTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.item.key == 'thisWeek') {
                var range = HelperService.getThisWeek();
                vm.accountTransactionsFilter.valueDateGTE = range.firstDay;
                vm.accountTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.item.key == 'thisMonth') {
                var range = HelperService.getThisMonth();
                vm.accountTransactionsFilter.valueDateGTE = range.firstDay;
                vm.accountTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.item.key == 'thisYear') {
                var range = HelperService.getThisYear();
                vm.accountTransactionsFilter.valueDateGTE = range.firstDay;
                vm.accountTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.item.key == 'all') {
                vm.accountTransactionsFilter.valueDateGTE = null;
                vm.accountTransactionsFilter.valueDateLTE = null;
            }

            if (vm.accountTransactionsTempFilter.selectedTransactionType == "all") {
                vm.accountTransactionsFilter.transactionType = null;
            } else if (vm.accountTransactionsTempFilter.selectedTransactionType == "credit") {
                vm.accountTransactionsFilter.transactionType = "Credit";
            } else if (vm.accountTransactionsTempFilter.selectedTransactionType == "debit") {
                vm.accountTransactionsFilter.transactionType = "Debit";
            } else {
                throw "Unhandled transaction type";
            }

            vm.accountTransactionsFilter.amountGTE = HelperService.getAmountClean(vm.accountTransactionsTempFilter.amountGTE);
            vm.accountTransactionsFilter.amountLTE = HelperService.getAmountClean(vm.accountTransactionsTempFilter.amountLTE);
            vm.accountTransactionsFilter.accountId = vm.selectedAccount.accountId;
            vm.accountTransactionsFilter.genericSearch = vm.accountTransactionsTempFilter.genericSearch;

            $log.debug("before group", vm.accountTransactionsTempFilter, vm.accountTransactionsFilter);

            vm.groupByTransactions(reset);
        };


        vm.groupByTransactions = function (reset) {

            TransactionsService.getPage(vm.accountTransactionsFilter)
                .then(function (response) {
                    vm.busy = false;

                    $(response.result).each(function (i, e) {
                        if (e.transactionType == "Credit") {
                            e.credit = e.amount;
                            e.debit = null;
                        } else {
                            e.credit = null;
                            e.debit = e.amount;
                        }
                    });

                    vm.selectedAccount.transactions = HelperService.mergeRowsForTimeline({
                        existingData: vm.selectedAccount.transactions,
                        newData: response,
                        groupFunction: function (item) {
                            var valueDate = new Date(item.valueDate);
                            valueDate.setHours(0,0,0,0);
                            return valueDate;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: e,
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });

                    //$log.debug("transactions", vm.selectedAccount.transactions);
                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.getSelectedAccountId = function () {
            return {
                accountId: vm.selectedAccount.accountId
            }
        };

        vm.loadMoreTransactions = function (reset) {
            vm.getTransactions(reset);
        };

        vm.resetTransactionsFilter = function () {
            vm.accountTransactionsTempFilter = _.extend(vm.accountTransactionsTempFilter, vm.defaultAccountTransactionsTempFilter);
        };


        vm.init();
    }
}());
