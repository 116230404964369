<section id="main">
  <div class="container-fluid">

    <div class="internal-transfer-header" ng-show="internalTransfer.currentStep != 3">
      <div class="row">
        <div class="col-sm-12 f-28" translate="internalTransfer.transferToAccount"></div>
      </div>
    </div>

    <div ng-if="internalTransfer.currentStep == 1">
      <form name="internalTransfer.internalTransferForm" novalidate>
        <div class="internal-transfer-body col-sm-12">
          <div class="row m-b-30">
            <div class="col-sm-5">
              <!-- CUSTOM FROM Accounts dropdown-->
              <label class="bold-font f-18" translate="payments.fromAccount"></label>
              <div class="accounts-dropdown m-b-10" ng-if="internalTransfer.accountsFrom.length > 0">
                <div class="debit-account-widget dashboard-widget-item"
                     data-ng-click="internalTransfer.toggleFromAccount();"
                     ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{internalTransfer.tempIntObj.fromAccountSelect.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                        ng-class="{'zmdi-star favorite-account':internalTransfer.tempIntObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!internalTransfer.tempIntObj.fromAccountSelect.isFavorite}"></i></span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{internalTransfer.tempIntObj.fromAccountSelect.iban | formatIban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{internalTransfer.tempIntObj.fromAccountSelect.availableBalance | number:2}}
                    {{internalTransfer.tempIntObj.fromAccountSelect.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                    <span ng-switch-when="sl" class="caret-icon"
                          ng-if="internalTransfer.accountsFrom.length > 1 && !internalTransfer.fromTemplate"><i
                    class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                    <span ng-switch-default class="caret-icon"
                          ng-if="internalTransfer.accountsFrom.length > 1"><i
                    class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                  </span>

                </div>
                <div class="from-accounts-list" ng-show="internalTransfer.fromAccountDropdown" scrollable>
                  <div class="accounts-list-item p-0" data-ng-repeat="item in internalTransfer.accountsFrom"
                       data-ng-click="internalTransfer.setAccountFrom(item);">
                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                         ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                            ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{item.availableBalance | number:2}} {{item.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accounts-dropdown m-b-10" ng-if="internalTransfer.accountsFrom.length == 0">
                <div class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px"
                     translate="internalTransfer.nodata"
                     ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  No available accounts
                </div>
              </div>

              <label class="bold-font m-t-20 f-18" translate="internalTransfer.amount"></label>
              <div class="input-group">
                <input type="text" class="form-control input-field t-a-r"
                       name="amountInput"
                       placeholder="{{'domesticPayment.amountPlaceholder' | translate}}"
                       amount-input-mask max-amount="99999999999.99"
                       data-ng-model="internalTransfer.tempIntObj.amountInput"
                       ng-class="{'domp-error': internalTransfer.internalTransferForm.amountInput.$invalid &&
                       internalTransfer.tempIntObj.formSubmitted}"
                       maxlength="17"
                       required
                       ng-readonly="internalTransfer.disableAllFields && internalTransfer.disableAllFieldsForProcessedPayment">
                <span class="input-group-btn">
                 <button type="button" class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;"
                         disabled>
                   {{internalTransfer.tempIntObj.fromAccountSelect.currencySymbol}}
                 </button>
            </span>
              </div>
              <span class="c-red" ng-show="internalTransfer.internalTransferForm.amountInput.$error.required &&
              internalTransfer.tempIntObj.formSubmitted" translate="internalTransfer.amountInputError">
              </span>
              <!--                            <span class="c-red" ng-show="internalTransfer.internalTransferForm.amountInput.$error.maxlength &&
                            internalTransfer.tempIntObj.formSubmitted" translate="internalTransfer.amountLengthError">
                            </span>-->
              <span ng-if="internalTransfer.countryCode != 'bh'" class="c-red" ng-show="internalTransfer.internalTransferForm.amountInput.$error.maxAmount &&
              internalTransfer.tempIntObj.formSubmitted" translate="internalTransfer.amountLengthError">
              </span>
              <span ng-if="internalTransfer.countryCode === 'bh'" class="c-red" ng-show="internalTransfer.internalTransferForm.amountInput.$error.maxAmount &&
              internalTransfer.tempIntObj.formSubmitted" translate="internalTransfer.amountLengthErrorBh">
              </span>
            </div>

            <div class="col-sm-2">
              <div class="t-a-c exchange-office-middle">
                <i class="zmdi zmdi-long-arrow-right zmdi-hc-2x"></i>
              </div>
            </div>

            <div class="col-sm-5">
              <!-- CUSTOM TO Accounts dropdown -->
              <div ng-if="!internalTransfer.fromLoan">
                <label class="bold-font f-18" translate="payments.toAccount"></label>
                <div class="accounts-dropdown m-b-10" ng-if="internalTransfer.accountsTo.length > 0">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                       data-ng-click="internalTransfer.toggleToAccount();"
                       ng-style="internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{internalTransfer.tempIntObj.toAccountSelect.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                          ng-class="{'zmdi-star favorite-account':internalTransfer.tempIntObj.toAccountSelect.isFavorite,'zmdi-star-outline':!internalTransfer.tempIntObj.toAccountSelect.isFavorite}"></i></span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{internalTransfer.tempIntObj.toAccountSelect.iban | formatIban}}</p>
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                         ng-if="internalTransfer.tempIntObj.toAccountSelect.hasOwnProperty('availableBalance')">
                        {{internalTransfer.tempIntObj.toAccountSelect.availableBalance | number:2}}
                        {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                            ng-if="internalTransfer.tempIntObj.toAccountSelect.hasOwnProperty('availableBalance')"></span>
                      <!--na kreditima nema available balance, pa ispisuje balance -->
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                         ng-if="!internalTransfer.tempIntObj.toAccountSelect.hasOwnProperty('availableBalance')">
                        {{internalTransfer.tempIntObj.toAccountSelect.balance | number:2}}
                        {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                            ng-if="!internalTransfer.tempIntObj.toAccountSelect.hasOwnProperty('availableBalance')"></span>
                    </span>
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                         ng-if="internalTransfer.tempIntObj.toAccountSelect.availableBalance">
                        {{internalTransfer.tempIntObj.toAccountSelect.availableBalance | number:2}}
                        {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                            ng-if="internalTransfer.tempIntObj.toAccountSelect.availableBalance"></span>
                      <!--na kreditima nema available balance, pa ispisuje balance -->
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                         ng-if="!internalTransfer.tempIntObj.toAccountSelect.availableBalance">
                        {{internalTransfer.tempIntObj.toAccountSelect.balance | number:2}}
                        {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                            ng-if="!internalTransfer.tempIntObj.toAccountSelect.availableBalance"></span>
                    </span>

                    <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                    <span ng-switch-when="sl" class="caret-icon"
                          ng-if="internalTransfer.accountsTo.length > 1 && !internalTransfer.fromTemplate"><i
                      class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                    <span ng-switch-default class="caret-icon"
                          ng-if="internalTransfer.accountsTo.length > 1"><i
                      class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                  </span>

                  </div>
                  <div class="from-accounts-list" ng-show="internalTransfer.toAccountDropdown" scrollable>
                    <div class="accounts-list-item p-0" data-ng-repeat="item in internalTransfer.accountsTo"
                         data-ng-click="internalTransfer.setAccountTo(item);">
                      <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                           ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                              ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                             ng-if="item.hasOwnProperty('availableBalance')">
                            {{item.availableBalance | number:2}} {{item.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                                ng-if="item.hasOwnProperty('availableBalance')"></span>
                          <!--na kreditima nema available balance, pa ispisuje balance -->
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                             ng-if="!item.hasOwnProperty('availableBalance')">
                            {{item.balance | number:2}} {{item.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                                ng-if="!item.hasOwnProperty('availableBalance')"></span>
                        </span>
                        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                             ng-if="item.availableBalance">
                            {{item.availableBalance | number:2}} {{item.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                                ng-if="item.availableBalance"></span>
                          <!--na kreditima nema available balance, pa ispisuje balance -->
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                             ng-if="!item.availableBalance">
                            {{item.balance | number:2}} {{item.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"
                                ng-if="!item.availableBalance"></span>
                        </span>

                      </div>
                    </div>
                  </div>
                </div>

                <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="accounts-dropdown m-b-10" ng-if="!internalTransfer.accountsTo.length">
                  <div ng-switch-default class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px"
                       translate="internalTransfer.nodata"
                       ng-style="internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    No available accounts
                  </div>

                  <div ng-switch-when="sl" class="debit-account-widget-empty" style="height: 133px;">
                    <span translate="internalTransfer.toAccountNoData"></span>
                  </div>
                </div>

              </div>

              <!-- TO ACCOUNT CONTROL SPECIFIC FOR SERBIA LOAN -->
              <div class="accounts-dropdown m-b-10" ng-if="internalTransfer.fromLoan">
                <label class="bold-font f-18" translate="payments.toAccount"></label>
                <div class="debit-account-widget dashboard-widget-item"
                     ng-style="internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-12 p-0 m-t-10  no-wrap-text">{{internalTransfer.tempIntObj.toAccountSelect.friendlyName}}</b>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{internalTransfer.tempIntObj.toAccountSelect.iban | formatIban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                     ng-if="internalTransfer.tempIntObj.toAccountSelect.availableBalance">
                    {{internalTransfer.tempIntObj.toAccountSelect.availableBalance | number:2}}
                    {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                  </p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text"
                     ng-if="!internalTransfer.tempIntObj.toAccountSelect.availableBalance">
                    {{internalTransfer.tempIntObj.toAccountSelect.balance | number:2}}
                    {{internalTransfer.tempIntObj.toAccountSelect.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                </div>

              </div>

              <!-- END TO ACCOUNT CONTROL -->

              <label ng-if="internalTransfer.countryCode != 'sr' && internalTransfer.countryCode != 'mne'"
                     class="bold-font m-t-20 f-18" translate="internalTransfer.when"></label>
              <div ng-if="internalTransfer.countryCode != 'sr' && internalTransfer.countryCode != 'mne'"
                   class="input-group">
                <input type="text" class="form-control input-field"
                       name="dueDateInput"
                       uib-datepicker-popup="shortDate"
                       data-ng-model="internalTransfer.tempIntObj.dueDateInput"
                       show-weeks="false" is-open="internalTransfer.datePickerOpened"
                       datepicker-options="internalTransfer.dateOptions"
                       current-text="{{'core.period.today' | translate}}"
                       clear-text="{{'core.datePicker.clearBtn' | translate}}"
                       close-text="{{'core.datePicker.doneBtn' | translate}}"
                       ng-class="{'domp-error': internalTransfer.internalTransferForm.dueDateInput.$invalid && internalTransfer.tempIntObj.formSubmitted}"
                       ng-readonly="true" required>

                <span class="input-group-btn">
                        <button ng-disabled="internalTransfer.disableAllFields && internalTransfer.disableAllFieldsForProcessedPayment"
                                data-ng-click="internalTransfer.openDatePicker()" type="button"
                                class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;"><i
                          class="icon icon-calendar" style="font-size: 21px;"></i>
                        </button>
                  </span>
              </div>

            </div>
          </div>
          <hr>

          <div class="row">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary" type="button" translate="payments.cancel"
                      data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
            <div class="col-xs-6 t-a-r">
              <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-default domp-review-and-submit-btn"
                      type="submit"
                      translate="currencyConversion.confirm"
                      data-ng-click="internalTransfer.internalTransferSubmit();"
                      ng-disabled="internalTransfer.accountsFrom.length == 0 || internalTransfer.accountsTo.length == 0"
                      is-same-account="{{internalTransfer.isSameAccounts}}">
              </button>
              <button ng-if="internalTransfer.countryCode === 'sl'" class="btn btn-default domp-review-and-submit-btn"
                      type="submit"
                      translate="domesticPayment.confirmBtnSlo"
                      data-ng-click="internalTransfer.internalTransferSubmit();"
                      ng-disabled="internalTransfer.nextButtonPressed || (internalTransfer.accountsFrom.length == 0 || internalTransfer.accountsTo.length == 0)"
                      is-same-account="{{internalTransfer.isSameAccounts}}">
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>

    <!-- Internal transfer STEP 2 -->
    <div ng-if="internalTransfer.currentStep == 2">
      <div data-ng-include="'payments/internal-transfer-resume.tpl.html'">
      </div>
    </div>

    <!-- Internal transfer STEP 3 -->
    <div ng-if="internalTransfer.currentStep == 3">
      <div data-ng-include="'payments/internal-transfer-final.tpl.html'"></div>
    </div>
  </div>
</section>
