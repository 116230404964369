(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      accounts:{
        "accAllAccountsOverview":"Pregled svih računa",
        "accShowAccounts":"Prikaži još računa",
        "accFilter":"Filtriraj po tipu",
        "accAmountRange":"Raspon iznosa",
        "accTo":"Do",
        "accApply":"Primjeni",
        "accReset":"Poništi",
        "accPeriod":"Period",
        "accDescription":"Opis",
        "accAmount":"Iznos",
        "accBalance":"Stanje po računu",
        "accShowOnDashboard":"Prikaži na kontrolnoj ploči",
        "accTransactions":"Transakcije",
        "accPdfStatements":"Pdf izvodi",
        "accDownloadPdf":"Preuzmi PDF",
        "accChangeName":"Promijeni naziv računa",
        "accResetName":"Postavi originalni/izvorni naziv",
        "accQuickPayment":"Brzo plaćanje",
        "accRequestLimit":"Zahtjevaj promjenu limita",
        "accAvailableToSpend":"Raspoloživi iznos",
        "accReservations":"Rezervacije",
        "accOverdraftLimit":"Iznos prekoračenja",
        "accOverdraftLimitDue":"Datum isteka prekoračenja",
        "accPayIn":"Uplata",
        "accRepaymentPlan":"Prikaži plan otplate",
        "accLoanAmount":"Odobreni iznos",
        "accLoanBalance":"Stanje kredita",
        "accInterestRate":"Kamatna stopa",
        "accAnnuity":"Iznos anuiteta/rate",
        "accStartDate":"Datum početka",
        "accEndDate":"Datum završetka",
        "accDisbursementDate":"Datum plasmana",
        "accDuration":"Trajanje",
        "accViewMore":"Prikaži više informacija",
        "accViewLess":"Prikaži manje informacija",
        "accTypeOfRate":"Vrsta kamatne stope",
        "accPenaltyInterests":"Zatezna kamata",
        "accOverpayments":"Preplata",
        "accOtherFees":"Druge naknade",
        "accChangeDate":"Datum zadnje promjene",
        "accAlreadySpent":"Dospjeli troškovi",
        "accPayCard":"Kartično plaćanje",
        "accApprovedLimit":"Odobreni limit",
        "accDueDate":"Rok korištenja",
        "accClosingDate":"Datum zatvaranja",
        "accMonthlyDeposit amount":"Iznos mjesečnog depozita",
        "accStatementDeliveryMethod":"Način isporuke obračuna",
        "accAccountForRelease":"Račun za isplatu",
        "accAnnualAssignment":"Godišnja dodjela kamata",
        "accPayOut":"Isplata",
        "accCloseAccount":"Zatvori račun",
        "accOpenNewSavingsAccount":"Otvori novi štedni račun",
        "accEg":"npr",
        "accSearchTransactionsPlaceholder":"Pretraži transakcije",
        "accCancelDeposit":"Otkaži oročenje",
        "accCancelAutoExtension":"Otkaži automatsko produženje",
        "accNewDeposit":"Novo oročenje",
        "accPrincipal":"Glavnica",
        "accInterest":"Kamata",
        "accMaturityDate":"Datum dospjeća",
        "accCurrentBalance":"Trenutno stanje",
        "accAtmWithdrawalLimit":"Limit za preuzimanje na bankomatu",
        "accPosPaymentLimit":"Limit za preuzimanje u poslovnici",
        "accDateLastChanged":"Datum zadnje promjene",
        "accBalanceDateLastChanged":"Datum zadnje promjene",
        "accAvailableBalance":"Raspoloživo stanje",
        "accSearchAccountsPlaceholder":"Pretraži račune",
        "accShowMatured":"Prikaži zatvorene račune",
        "accAuthorizedPersons":"Ovlaštene osobe/lica",
        "accCardStatus":"Status kartice",
        "accContractNumber":"Broj ugovora",
        "accDepositStartDate":"Datum oročenja",
        "accDepositEndDate":"Datum isteka oročenja",
        "rename": "Preimenuj",
        "accAccountAndCardsMoreDetails": "Detaljni prikaz",
        "accAccountAndCardsMoreDetails1": "Detalji i platne kartice",
        "accAccountAndCardsLessDetails": "Manje detalja",
        "accInstallmentsPayed":"Rata plaćeno",
        "accInstallmentNumber": "Ukupan broj rata",
        "accInstallmentsRemain":"Preostali broj anuiteta",
        "accNextInstallmentDate":"Datum sljedeće rate",
        "accNextInstallmentAmount":"Iznos sljedeće rate",
        "connectedDebitCards": "Povezane kartice",
        "blockCard": "Blokiraj karticu",
        "unblockCard": "Odblokiraj karticu",
        "blockCardRequest": "Zahtjev za blokadu poslan",
        "unblockCardRequest": "Zahtjev za deblokadu poslan",
        "accDueAmount": "Iznos dospjelog duga",
        "accMatureInterest":"Kamata",
        "accNewEbook": "Nova eKnjižica",
        "closeEbook": "Zatvaranje eKnjižice",
        "accPaymInQue": "Broj nerealizovanih čekova",
        "accReservedAmount": "Rezervirani/Rezervisani iznos",
        "accPlanedDebit": "Rezervacija",
        "accDepositInterestRate": "Kamatna stopa na depozit",
        "accDepositTypeExternal": "Deposit Type External",
        "accDepositPrincipal": "Ugovoreni iznos depozita",
        "accLimitValidUntilDate": "Datum isteka kartice",
        "accObligationAmount": "Tekući troškovi",
        "accOutstandingAmount": "Nedospjele obaveze na rate",
        "accCardNumber": "Broj kartice",
        "accInstallmentPaidNumber": "Broj obračunatih anuiteta",
        "accNotMatureCapital": "Nedospjela glavnica",
        "accNewDebitDate": "Datum iduće obveze/obaveze",
        "title": "Informacija",
        "message_BH":"Kartica je blokirana u Banci. Molimo obratite se Kontakt centru.",
        "close": "Zatvori",
        "accHideTransactions": "Sakrij transakcije",
        "balance": "Stanje računa",
        "accParentAccountIban": "Glavni račun",
        "accAnnuityConverted": "Iznos anuiteta u ",
        "accNotMatureCapitalConverted": "Nedospjela glavnica u ",
        "closeRepaymentPlan":"Zatvori otplatni plan",
        "accHideReservations": "Sakrij rezervacije",
        "depositCantBeCanceledError": "Prijevremeni prekid oročenja moguć je samo za oročenja ugovorena putem usluga direktnog bankarstva. ",
        "cardStatus": {
          "Active":"Aktivna",
          "BlockedFromEBank":"Blokirano iz E-banke",
          "Closed":"Zatvorena",
          "Blocked":"Blokirana"
        },
        "Active":"Aktivan",
        "BlockedFromEBank":"Blokirano iz E-banke",
        "expDate":"Datum isteka kartice: ",
        "Closed":"Zatvoren",
        "eBookPayIn": "Uplata na eKnjižicu",
        "Blocked":"Blokiran",
        "transDivisionBtn1": "Podjela",
        "transDivisionBtn2": "transakcija",
      },
      merchant: {
        'address': 'Adresa',
        'contact': 'Kontakt',
        'website': 'Website',
        'location': 'Lokacija',
      },
      repaymentPlan: {
        "instalment": "Rata",
        "loanBalance": "Iznos duga",
        "date": "Datum",
        "dateFrom":"Datum od",
        "dateTo":"Datum do",
        "principalAmount": "Glavnica",
        "paymentAmount": "Anuitet",
        "interestAmount":"Kamata",
        "totalAnnuity":"Ukupan iznos za otplatu ",
        "balance": "Stanje"
      },
      transactions: {
        "description":"Opis",
        "amount":"Iznos",
        "availableAmount":"Raspoloživi iznos",
        "accountNumber":"Broj računa",
        "bookingDate":"Datum knjiženja",
        "valueDate":"Datum valute",
        "cardTransactionDetails":"Detalji transakcije",
        "transactionDetails":"Detalji transakcije",
        "close":"Zatvori",
        "card":"Kartica",
        "iban":"IBAN",
        "noData":"Za odabrani period nema podataka za prikaz",
        "noStatementData":"Za odabrani račun nema podataka za prikaz",
        "retrieval": "Povrat",
        "ibanFromBh": "BBAN/IBAN platitelja/platioca",
        "accountFrom": "Račun platitelja/platioca",
        "accountTo": "Račun primatelja/primaoca",
        "referenceNumber":"Referentni broj",
        "creditorName": "Platitelj/Uplatilac",
        "debitorName": "Primatelj/Primalac",
        "creditorAddress": "Adresa platitelja/uplatioca",
        "debitorAddress": "Adresa primatelja/primaoca",
        "connCardReferenceAccount":"Referentni broj",
        "transactionAndOriginalCurrency":"Iznos u originalnoj valuti"
      },
      ebook: {
        "header": "Zahtjev za otvaranje eKnjižice",
        "date": "Datum",
        "fullName": "Ime i prezime",
        "firstName": "Ime",
        "lastName": "Prezime",
        "fromAccount": "Račun",
        "eSavingsBookName": "Naziv eKnjižice",
        "termsAndConditions": "Saglasan sa općim/opštim uvjetima/uslovima poslovanja",
        "confirm": "Potvrdi",
        "cancel": "Odustani",
        "dateOfCreation":"Datum kreiranja",
        "mainAccount":"Glavni račun",
        "delete":"Obriši",
        "sign":"Potpiši",
        "name":"Naziv",
        "edit":"Promijeni",
        "enterName": "Unesite naziv",
        "nameError": "Naziv eKnjižice je obavezan!",
        "createEbook": "Kreiraj eKnjižicu",
        "successfullRequest": "Uspješno ste kreirali eKnjižicu",
        "completeRequest": "Zahtjev uspješan!",
        "eBookRequests": "Zahtjevi za eKnjižicu",
        "createdBy": "Kreirao",
        "iban": "IBAN",
        "requestType": "Tip zahtjeva",
        "status": "Status",
        "timelineHeader":"Vremenski prikaz zahtjeva za Eknjižice",
        "showAllRequests": "Prikaži zahtjeve za sve račune",
        "ebookNumber": "Račun eKnjižice"
      },
      closeEBookPageTranslation: {
        title: "Zahtjev za zatvaranje eKnjižice",
        account: "Broj eKnjižice",
        accountName: "Naziv eKnjižice",
        currency: "Valuta",
        balance: "Stanje",
        recognisedInterestRate: "Priznata kamatna stopa",
        recognisedInterestAmount: "Iznos priznate kamatne stope",
        fee: "Naknada",
        transferAmount: "Iznos za prijenos/prenos",
        transferAccount: "Račun za prijenos/prenos",
        confirmButton: "Potvrdi",
        cancelButton: "Otkaži"
      },
      accountsOverview: {
        "title": "Pregled svih računa i kartica",
        "newTermDepositButtonLabel": "Dodaj novo oročenje",
        "newESavingAccountButtonLabel": "Dodaj novu eKnjižicu"
      },
      depositAccountsOverview: {
        "title": "Pregled svih oročenja",
        "titleForSlo": "Pregled depozitov in varčevanj",
        "titleForBh": "Pregled štednih računa"
      },
      laonsOverview: {
        title: "Pregled kredita"
      },
      cashWithdrawalTranslate: {
        title: "Najava podizanja gotovine",
        infoNote1: "",
        infoNote2: "",
        fromAccount: "Na teret IBAN-a",
        amount: "Iznos",
        amountRequired: "Iznos je obavezan!",
        dateOfPayout: "Datum isplate",
        phoneNumber: "Telefonski broj",
        placeOfCashTakeover: "Mjesto preuzimanja gotovine",
        placeOfCashTakeoverRequiredMsg: "Mjesto preuzimanja gotovine je obavezno!",
        note: "Napomena",
        confirmBtnLabel: "Potvrdi",
        cancelBtnLabel: "Odustani",
        amountMinAmountError: "Minimalni iznos je "
      },
      reservations: {
        "reservationDate":"Datum rezervacije",
        "transactionType":"Tip transakcije",
        "noData": "Nema dostupnih rezervacija",
        "type": "Type",
        "originAmount": "Iznos u originalnoj valuti"
      },
      transactionDivision: {
        "title": "Podjela transakcija",
        "creationDate": "Datum kreiranja zahtjeva",
        "fullName": "Ime i prezime",
        "jmbg": "JMBG",
        "transactionId":"ID transakcije",
        "reservationNumber":"Broj rezervacije",
        "transactionDate": "Datum transakcije",
        "amount": "Iznos",
        "numOfInstallment": "Izaberite broj rata",
        "sellingPlace": "Naziv prodajnog mjesta",
        "lastDigits": "Zadnja 4 broja sa kartice",
        "sellingPlaceError": "Naziv prodajnog mjesta je obavezno!",
        "lastDigitsError": "Zadnja 4 broja sa kartice su obavezna!",
        "lastDigitsLengthError": "Broj mora imati 4 znamenke/cifre!",
        "yourTransDiv": "Zahtjevi za podjelu transakcija",
        "transLocation": "Prodajno mjesto",
        "cardNumber": "Broj kartice",
        "status": "Status",
        "creatorName": "Kreator",
        "installmentNumber": "Broj rata",
        "cancel":"Odustani",
        "confirm":"Potvrdi",
        "successMessage":"Zahtjev je uspješno podnešen.",
        "finalStepStatus":"Status:",
        "finalStepId":"Broj transakcije:",
        "finalStepSentForProcessing":"Transakcija je poslana u Banku na obradu.",
        "makeAnotherPayment":"Kreiraj novu transakciju",
        "Opened":"Otvoren",
        "Rejected":"Odbijen",
        "WaitingQueue":"Na čekanju",
        "Processed":"Uspješno obrađen",
        "InProcessing":"U obradi",
        "installmentInfo": "Broj rata"
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());
