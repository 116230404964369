<div class="modal-background">
  <div class="modal-header p-10 t-a-c">
    <h3 class="white-text-block" translate="signPayments.updatePaymentDueDate"></h3>
  </div>
  <div class="modal-body m-t-10 m-b-30 f-18">
    <span class="col-xs-12 m-t-20 white-text-block"
          translate="signPayments.singlePaymentInvalidDueDate"></span>
  </div>
  <div class="modal-footer m-t-30">
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-primary" ng-click="$close()"><span
          translate="signPayments.cancel"></span></button>
        <button class="btn btn-default" ng-click="$close('confirm')"><span
          translate="signPayments.confirm"></span></button>
      </div>
    </div>
  </div>
</div>
