(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            orders: {
                "accounts": "Računi",
                "cards": "Kartice",
                "savingsDeposits": "Štednja i depoziti",
                "security": "Sigurnost",
                "loans": "Krediti",
                "payments": "Uplate",
                accountOverdraft: "Promjena dopuštenog prekoračenja računa",
                largeAmount: "Podizanje velikog iznosa gotovine",
                atmWithdrawal: "Promjena limita podizanja na bankomatu",
                "orderMastercard": "Narudžba Mastercard kartičnog plaćanja",
                "changeMastercard": "Promjenite limit Mastercard",
                "makeTermDeposit": "Napravite oročenje",
                "cancelAutomatic": "Odustanite od automatskom produženja oročenja",
                "openSavings": "Otvorite štedni račun",
                "orderSoftToken": "Naručite soft token",
                "getExpressLoan": "Dobavite informacije o mogućnosti kreditiranja",
                "authorizationOrders": "Odobrenje trajnog naloga",
                "cancelAuthorizationOrders": "Odustanite od odobrenja trajnog naloga",
                "goBackToOrders": "Povratak na narudžbe",
                "subscribeEInvoice": "Pretplatite se na novi eRačun ili otkažite postojeću pretplatu",
                "title": "Trajni nalog",
                "dateBegin": "Datum početka",
                "dateCancel": "Datum prestanka",
                "note":"Napomena",
                "note1":"Trajni nalog možete zadati najmanje 8 dana prije dospijeća za izvršenje.",
                "note2":"Ako na dan izvršenja naloga na računu nema raspoloživih sredstava, nalog se neće izvršiti, te Banka za isto ne snosi odgovornosti.",
                "note3":"Naknade pri izvršenju se naplaćuju prema Odluci Banke o naknadama za usluge u poslovanju s građanstvom, na teret nalogodavatelja.",
                "beneficiaryName": "Naziv primatelja",
                "beneficiaryNameRequired": "Naziv primatelja je obavezan!",
                "beneficiaryIBAN": "IBAN primatelja",
                "description": "Opis",
                "timeline":"Vremenski prikaz zahtjeva",
                "standingOrders": "Vremenski prikaz zahtjeva",
                "cancel":"Odustani",
                "descriptionRequired":"Opis je obavezan!",
                "titleConfirmation": "Potvrda trajnog naloga",
                "edit":"Uredi",
                "completeText": "Zahtjev za otvaranje trajnog naloga je uspješno poslan u Banku.",
                "makeNew":"Novi trajni nalog",
                "Opened":"Otvoren",
                "Signed":"Potpisan"
            },
            termDeposit: {
                "termDepositHeader": "Oročenje",
                "nameInputLabel": "Ime i prezime:",
                "addressInputLabel": "Adresa:",
                "cityInputLabel": "Grad:",
                "debitAccountSelectLabel": "Račun terećenja:",
                "typeSelectLabel": "Vrsta oročenja:",
                "interestRateOverviewLabel": "Prikaz kamatnih stopa",
                "amountInputLabel": "Iznos:",
                "baseInterestRateInputLabel": "Bazna kamatna stopa:",
                "marginInputLabel": "Marža:",
                "stimulativeInterestRateInputLabel": "Stimulativna kamatna stopa:",
                "totalInterestRateInputLabel": "Ukupna kamatna stopa:",
                "maturityInMonthsSelectLabel": "Rok u mjesecima:",
                "expiryDateInputLabel": "Datum isteka:",
                "interestRatePayoutTypeSelectLabel": "Način isplate kamate:",
                "informativeCalculationLinkLabel": "Izračun efektivne kamatne stope",
                "automaticProlongationRadioOptionLabel": "Automatsko produženje:",
                "automaticProlongationYesOptionLabel": "Da",
                "automaticProlongationNoOptionLabel": "Ne",
                "accountForMaturityInputLabel": "Račun za dospijeće kamate i glavnice:",
                "contractDateInputLabel": "Datum ugovora:",
                "contractDraftLinkLabel": "Nacrt ugovora oročenog štednog uloga - depozita",
                "generalConditionsLinkLabel1": "Prihvaćam opće uvjete poslovanja za oročene štedne uloge - depozite",
                "generalConditionsLinkLabel2": "Prihvaćam opće uvjete poslovanja za zaštićene depozite",
                "confirmBtnLabel": "Potvrdi",
                "cancelBtnLabel": "Odustani",
                "editActionLabel": "Popravi",
                "cancelActionLabel": "Odustani",
                "signActionLabel": "Potpiši",
                "successMsg": "Uspješno!",
                "errorMsg": "Greška!",
                "minAmountLabel": "Minimalan iznos je",
                "month":"mjeseci",
                "oneMonth":"mjesec",
                "signTitle": "Potpis oročenja",
                "request": "zahtjev",
                "newDepositOrder": "Novo oročenje",
                "earlyTermination": "Prijevremeni prekid oročenja"
            },
            cancelDeposit: {
                "cancelDepositHeader": "Oročenje",
                "cancelDepositSubheader": "Zahtjev za prijevremeni raskid oročenog štednog uloga - depozita",
                "capitalAmountLabel": "Iznos glavnice:",
                "approvedInteresLabel": "Priznata kamata:",
                "approvedInteresAmountLabel": "Priznata kamata u iznosu:",
                "chargesLabel": "Naknada za prijevremeni raskid ugovora:",
                "totalAmountLabel": "Ukupan iznos za prijenos:",
                "accountForFundLabel": "Račun za isplatu sredstava:",
                "confirmBtn": "Potvrdi",
                "cancelBtn": "Odustani",
                "successInfoLabel1": "Zahtjev za prijevremeni raskid oročenog štednog uloga - depozita",
                "successInfoLabel2": "je uspješno potpisan i poslan u Banku.",
                "requestBtn": "Pregled zahtjeva",
            },
            orderDetails:{
                "name":"Naziv primatelja",
                "payeeAccount":"IBAN primatelja",
                "amount":"Iznos",
                "orderId":"ID naloga",
                "orderTypeId":"ID tipa naloga",
                "orderTypeDesc":"Opis naloga",
                "dateCreated":"Datum kreiranja",
                "close":"Odustani",
              "purpose":"Svrha"
            },
            addNewExpressLoan: {
                "header": "Information on the possibility to obtain express loan",
                "cardType": "Duration (in months):",
                "account": "Account:",
                 "amount": "Account for loan disbursement:",
                 "paymentDay": "Settlement day:",
                 "cancel": "Cancel",
                 "confirm": "Confirm"
          }
        };

        $translateProvider
            .translations('mne', data);
    }
}());
