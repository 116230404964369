<!-- TIMELINE CONTAINER -->
<div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <div class="row p-15">
    <div class="col-xs-4 dropdown acc-period-select " style="display: inline-block">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="true">
        {{termDeposit.selectedPeriod.item.description | translate}}
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li data-ng-repeat="period in termDeposit.periodList">
          <a href="" class="f-13" data-ng-click="termDeposit.paymentsPeriodFilter(period)">
            {{period.item.description | translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-xs-8 pull-right"  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <label class="checkbox checkbox-inline m-l-15 pull-right">
        <input type="checkbox" data-ng-model="termDeposit.allRequests"
              data-ng-click="termDeposit.loadAllAccountsTimeline(termDeposit.allRequests)">
        <span class="input-helper" translate="ebook.showAllRequests">Prikaži zahtjeve za sve račune</span>
      </label>
    </div>
  </div>
  <!-- ================== -->
  <!-- Other -->
  <!-- ================== -->
  <div ng-switch-default>
    <div class="timeline-row col-sm-12 no-padding">
      <div class="t-a-c p-10 no-data-image" ng-if="termDeposit.timelineListGrouped.result.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card" ng-hide="termDeposit.timelineListGrouped.result.length == 0">
        <ul class="hypo-timeline" kcd-recompile="termDeposit.timelineListGrouped">
          <li ng-class="{'time-label': term.isVirtual}" ng-repeat="term in termDeposit.timelineListGrouped.result">
          <span class="currency-time-frame"
                ng-if="term.isVirtual === true">{{(term.valueDate) | date:'shortDate' : '+0200'}}</span>
            <i class="zmdi zmdi-timer" ng-if="!term.isVirtual"></i>
            <div ng-if="!term.isVirtual" class="hypo-timeline-item payment-item"
                ng-click="termDeposit.showOrderDetails(term)" ng-class="{'opened': term.selected}">
              <div class="hypo-timeline-body">
                <div class="col-xs-8 hidden-xs">
                  <h4 class="m-b-0 m-t-10 c-gray">
                    <b ng-if="term.depositType == 'CREATE'" translate="termDeposit.newDepositOrder">}</b>
                    <b ng-if="term.depositType != 'CREATE'" translate="termDeposit.earlyTermination"></b>
                    <b>, {{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}</b>
                    <b ng-if="term.termPeriod && term.termPeriod > 1">, {{term.termPeriod}} {{ 'termDeposit.month' |
                      translate }}</b>
                    <b ng-if="term.termPeriod && term.termPeriod === 1"> , {{term.termPeriod}} {{ 'termDeposit.oneMonth'
                      |
                      translate }}</b>
                  </h4>
                </div>

                <div class="col-xs-8 visible-xs p-l-0">
                  <h4 class="m-b-0 m-t-10 f-12 c-gray">
                    <b ng-if="term.depositType == 'CREATE'" translate="termDeposit.newDepositOrder">}</b>
                    <b ng-if="term.depositType != 'CREATE'" translate="termDeposit.earlyTermination"></b>
                    <b>, {{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}</b>
                    <b ng-if="term.termPeriod && term.termPeriod > 1">, {{term.termPeriod}} {{ 'termDeposit.month' |
                      translate }}</b>
                    <b ng-if="term.termPeriod && term.termPeriod === 1"> , {{term.termPeriod}} {{ 'termDeposit.oneMonth'
                      |
                      translate }}</b>
                  </h4>
                </div>


                <!-- Other term types -->
                <div class="col-xs-4 hidden-xs">
                            <span class="pull-right t-a-r m-t-5 f-18">
                            <b>{{term.amount|number:2}} {{term.currencySymbol}}</b><br/>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>

                <div class="col-xs-4 visible-xs p-r-0">
                            <span class="pull-right t-a-r m-t-5 f-12">
                            <b>{{term.amount|number:2}} {{::term.currencySymbol}}</b><br/>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>
                <div class="clear"></div>
              </div>
              <div ng-if="term.selected" class="transaction-details">
                <div data-ng-include="'orders/deposit-overview-timeline-details.tpl.html'"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show-more-btn ">
        <button ng-if="termDeposit.timelineListGrouped.hasMore"
                data-ng-click="termDeposit.loadMoreTimelineItemsGrouped()"
                type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                translate="dashboard.loadEvents">Load older events
        </button>
      </div>
    </div>
  </div>

  <!-- ================== -->
  <!-- Slovenia -->
  <!-- ================== -->
  <div ng-switch-when="sl">
    <div class="timeline-row col-sm-12 no-padding">
      <div class="t-a-c p-10 no-data-image" ng-if="termDeposit.timelineListGrouped.result.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card" ng-hide="termDeposit.timelineListGrouped.result.length == 0">
        <ul class="hypo-timeline" kcd-recompile="termDeposit.timelineListGrouped">
          <li ng-class="{'time-label': term.isVirtual}" ng-repeat="term in termDeposit.timelineListGrouped.result">
          <span class="currency-time-frame"
                ng-if="term.isVirtual === true">{{(term.valueDate) | date:'shortDate' : '+0200'}}</span>
            <i class="zmdi zmdi-timer" ng-if="!term.isVirtual"></i>
            <div ng-if="!term.isVirtual" class="hypo-timeline-item payment-item"
                 ng-click="termDeposit.showOrderDetails(term)" ng-class="{'opened': term.selected}">
              <div class="hypo-timeline-body">
                <div class="col-xs-8 hidden-xs">
                  <h4 class="m-b-0 m-t-10 c-gray">
                    <b translate="termDeposit.makeTermDepositTimelineTitle"></b>
                    <b>, {{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}, {{term.termPeriod}} {{ 'termDeposit.durationInDays' | translate }}</b>
                  </h4>
                </div>

                <div class="col-xs-8 visible-xs p-l-0">
                  <h4 class="m-b-0 m-t-10 f-12 c-gray">
                    <b translate="termDeposit.makeTermDepositTimelineTitle"></b>
                    <b>, {{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}, {{term.termPeriod}} {{ 'termDeposit.durationInDays' | translate }}</b>

                  </h4>
                </div>
                <!-- Other term types -->
                <div class="col-xs-4 hidden-xs">
                            <span class="pull-right t-a-r m-t-5 f-18">
                            <b>{{term.amount|number:2}} {{term.currencySymbol}}</b><br/>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>
                <div class="col-xs-4 visible-xs p-r-0">
                            <span class="pull-right t-a-r m-t-5 f-12">
                            <b>{{term.amount|number:2}} {{::term.currencySymbol}}</b><br/>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>
                <div class="clear"></div>
              </div>

              <div ng-if="term.selected" class="transaction-details">
                <div data-ng-include="'orders/deposit-overview-timeline-details.tpl.html'"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show-more-btn ">
        <button ng-if="termDeposit.timelineListGrouped.hasMore"
                data-ng-click="termDeposit.loadMoreTimelineItemsGrouped()"
                type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                translate="dashboard.loadEvents">Load older events
        </button>
      </div>
    </div>

  </div>
</div>

<!-- END TIMELINE -->
