(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name navigation.controller:NavigationCtrl
   *
   * @description
   *
   */
  angular
    .module('navigation')
    .controller('NavigationCtrl', NavigationCtrl);

  function NavigationCtrl($log, $window, UserSettingsService, AlertService, AccountsService, ConfigEndpointService,
    $localStorage, HelperService, $rootScope, CTLocalizationService, $filter,
    $q, $uibModal, SessionService, FundsService, $state, ConfigService, NotificationCountService,
    EndpointsService, ViberService, GoogleTagManagerHelperService, $translate, NotificationService) {
    var vm = this;
    vm.eInvoices = '';
    vm.countryCode = '';
    vm.eInvoiceNotifications = 0;
    vm.rejectedPaymentsNotifications = 0;
    vm.unsignedPaymentsNotifications = 0;
    vm.unreadDocumentsNotifications = 0;
    vm.totalNotificationsCount = 0;
    // vm.groupPaymentsNotifications = 0;
    vm.changeLanguage = '';
    // Controller init function
    vm.selectedLanguage = '';
    vm.isNuN = HelperService.isNuN;
    vm.init = function () {
      vm.userSettings = {};
      vm.userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
      vm.userSettingsFilter = {
        page: 0,
        pageSize: 1000
      };
      vm.generalTerms = '';
      vm.isSecondaryNavigationOpen = false;
      vm.isPrimaryNavigationOpen = false;
      vm.isMobilePrimaryNavigationOpen = false;
      vm.isMobileSecondaryNavigationOpen = false;
      vm.navigationSubItems = [];
      vm.currentNavigationItem = '';

      /*      getDefaultData();
       getUserSettingsPage();*/

      if ($localStorage.appLanguage) {
        vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
          return language.code === $localStorage.appLanguage;
        });
      }
      EndpointsService.getLinks()
        .then(function (data) {
          vm.links = data.links;
        });
      getUserSettingsPage();
      getDefaultData();
      getNotificationsCount();

      // var promises = [getDefaultData(), getUserSettingsPage()]; //
      // $q.all(promises)
      //   .then(function (data) {
      //     getNotificationsCount();
      //   })
      //   .catch(function (error) {
      //     NotificationService.showMessage(error, 'error');
      //   });

      vm.allAccounts = [];
      // getNotificationsCount();


    };

    var getUserSettingsPage = function () {
      // return UserSettingsService.getPage(vm.userSettingsFilter)
      //   .then(function (response) {
      //     vm.userSettings = response;
      //   })
      //   .catch(function (error) {
      //     $log.error(error);
      //   });
    };

    vm.pushGtmNavigationEvent = function (navigationItem, isSecondaryNavigation) {
      var navItemLabel = '';
      if (isSecondaryNavigation) {
        switch (navigationItem) {
          case "AccountsOverview":
            navItemLabel = $translate.instant("navigation.allAccountsOverview");
            break;
          case "DepositAccountsOverview":
            if (vm.countryCode != 'sr' && vm.countryCode != 'mne') {
              navItemLabel = $translate.instant("navigation.allDepositOverview");
            } else {
              navItemLabel = $translate.instant("navigation.allDepositOverviewSerbMne");
            }
            break;
          case "PaymentsOverview":
            navItemLabel = $translate.instant("navigation.paymentsList");
            break;
          case "DomesticPayment":
            if (vm.countryCode != 'sl') {
              navItemLabel = $translate.instant("navigation.domesticPayment");
            } else {
              navItemLabel = $translate.instant("navigation.domesticPaymentSl");
            }
            break;
          case "TransferToMyAccount":
            navItemLabel = $translate.instant("navigation.transferToMyAccount");
            break;
          case "InternationalPayment":
            navItemLabel = $translate.instant("navigation.internationalPayment");
            break;
          case "CurrencyConversion":
            navItemLabel = $translate.instant("navigation.exchangeOffice");
            break;
          case "ExchangeRates":
            navItemLabel = $translate.instant("navigation.exchangeRates");
            break;
          case "eInvoicesOverview":
            navItemLabel = $translate.instant("navigation.eInvoices");
            break;
          case "TemplateListOverview":
            navItemLabel = $translate.instant("navigation.myTemplatesList");
            break;
          case "StandingOrders":
            navItemLabel = $translate.instant("navigation.standingOrders");
            break;
          case "PaymentsReturn":
            navItemLabel = $translate.instant("navigation.paymentsReturn");
            break;
          case "SepaTransactions":
            navItemLabel = $translate.instant("navigation.sepaTransactions");
            break;
          case "SepaSettings":
            navItemLabel = $translate.instant("navigation.sepa");
            break;
          case "LoansOverview":
            navItemLabel = $translate.instant("navigation.loansOverview");
            break;
          case "InvestmentsOverview":
            navItemLabel = $translate.instant("navigation.investmentsOverview");
            break;
          case "Settings":
            navItemLabel = $translate.instant("navigation.settings");
            break;
          case "SettingsOverview":
            navItemLabel = $translate.instant("navigation.settings");
            break;
          case "SecurityOverview":
            navItemLabel = $translate.instant("navigation.security");
            break;
          case "NewTermDeposit":
            navItemLabel = $translate.instant("accountsOverview.newTermDepositButtonLabel");
            break;
          case "eSavingBookRequests":
            if (vm.countryCode == 'sl') {
              navItemLabel = $translate.instant("accountsOverview.newSavingButtonLabel_SLO");
            } else {
              navItemLabel = $translate.instant("accountsOverview.newESavingAccountButtonLabel");
            }
            break;
          default:
            navItemLabel = navigationItem;
        }

        GoogleTagManagerHelperService.pushEvent(navigationItem, 'SecondaryNavigationItem', navItemLabel);
      } else {
        switch (navigationItem) {
          case "Dashboard":
            navItemLabel = $translate.instant("navigation.dashboard");
            break;
          case "Accounts":
            navItemLabel = $translate.instant("navigation.accountsAndCards");
            break;
          case "Payments":
            navItemLabel = $translate.instant("navigation.payments");
            break;
          case "Deposit":
            navItemLabel = $translate.instant("navigation.deposit");
            break;
          case "Loans":
            navItemLabel = $translate.instant("navigation.loans");
            break;
          case "Settings":
            navItemLabel = $translate.instant("navigation.settings");
            break;
          case "Messages":
            navItemLabel = $translate.instant("navigation.messages");
            break;
          case "Investments":
            navItemLabel = $translate.instant("navigation.investments");
            break;
          case "Viber":
            navItemLabel = navigationItem;
            break;
          case "EmployeePayroll":
            navItemLabel = $translate.instant("navigation.employeePayroll");
            break;
          case "Documents":
            navItemLabel = $translate.instant("navigation.documents");
            break;
          default:
            navItemLabel = navigationItem;
        }

        GoogleTagManagerHelperService.pushEvent(navigationItem, 'PrimaryNavigationItem', navItemLabel);
      }


    };

    vm.toggleSecondaryNavigation = function (mainItem, mobileNavigation) {

      if (!HelperService.isValidAccordingToPasswordConfig()) {
        return;
      }

      if (mobileNavigation) {
        vm.isMobilePrimaryNavigationOpen = false;
        vm.isMobileSecondaryNavigationOpen = true;
      } else {

        // Close primary navigation if same item is clicked, or if navigation is opening first time
        if (mainItem && mainItem === vm.currentNavigationItem || vm.currentNavigationItem === '') {
          vm.isSecondaryNavigationOpen = !vm.isSecondaryNavigationOpen;
        }
      }

      // Populate secondary navigation items, or reset variables if secondary navigation is closing
      if (mainItem && (vm.isSecondaryNavigationOpen || vm.isMobileSecondaryNavigationOpen)) {
        populateSubItems(mainItem);
      } else {
        clearSecondaryNavigationItems();
      }

      vm.pushGtmNavigationEvent(mainItem);
    };

    var clearSecondaryNavigationItems = function () {
      vm.navigationSubItems = [];
      vm.currentNavigationItem = '';
    };

    vm.toggleContactSubMenu = function() {
      vm.contactSubMenuShown = !vm.contactSubMenuShown;
    }

    vm.closeSecondaryNavigation = function () {
      vm.isSecondaryNavigationOpen = false;
      clearSecondaryNavigationItems();
    };

    vm.closeMobileNavigation = function () {
      vm.isMobilePrimaryNavigationOpen = false;
      vm.isMobileSecondaryNavigationOpen = false;
      clearSecondaryNavigationItems();
    };

    vm.openPrimaryNavigation = function () {
      vm.isMobilePrimaryNavigationOpen = true;
      vm.isMobileSecondaryNavigationOpen = false;
    };

    vm.closeMobilePrimaryNavigation = function () {
      vm.isMobilePrimaryNavigationOpen = false;
    };

    vm.backToPrimaryNavigation = function () {
      vm.isMobilePrimaryNavigationOpen = true;
      vm.isMobileSecondaryNavigationOpen = false;
      clearSecondaryNavigationItems();
    };


    var populateSubItems = function (name) {

      vm.currentNavigationItem = name;
      vm.navigationSubItems = [];


      // Only if some of these items is opened, populate secondary navigation items
      if (name === 'Accounts' || name === 'Deposit' || name === 'Loans') {

        var accountsFilter = {
          page: 0,
          pageSize: 100,
          orderBy: 'isFavorite desc,accountType,iban,currencySymbol'
        };

        switch (name) {
          case "Accounts":
            accountsFilter.notInAccountTypeGroupList = ['LoanAccountType', 'SavingAccountTypes', 'DepositAccountTypes'];
            break;
          case "Deposit":
            accountsFilter.includeList = ['depositAccount'];
            accountsFilter.accountTypeGroupList = ['SavingAccountTypes', 'DepositAccountTypes'];
            break;
          case "Loans":
            accountsFilter.includeList = ['loanAccount'];
            accountsFilter.accountTypeGroupList = ['LoanAccountType'];
            break;
        }
        // Load accounts
        AccountsService.getPage(accountsFilter)
          .then(function (response) {
            if (vm.defaultData.APILocation == "sl") {
              switch (name) {
                case "Deposit":
                  response.result = $filter('orderBy')(response.result, ['-isFavorite', '-isOwn', '-accountType.group'])
                  break;
                case "Accounts":
                  response.result = $filter('orderBy')(response.result, ['-accountType.group', '-isOwn', 'sortOrderId'])
                  break;
              }
            }
            vm.navigationSubItems = response.result;
          })
          .catch(function (error) {
            $log.error(error);
          });
      }

      // Load investments
      if (name === 'Investments') {
        getFunds();
      }

    };

    var setAppLanguage = function () {
      var lang = $localStorage.appLanguage;

      if (lang == undefined) {
        if (ConfigService.defaultLocale == undefined || ConfigService.defaultLocale == 'undefined') {
          $localStorage.appLanguage = angular.copy($localStorage.code);
        } else {
          //                    $localStorage.appLanguage = ConfigService.defaultLocale;
          $localStorage.appLanguage = angular.copy($localStorage.code);
        }
        lang = $localStorage.appLanguage;
      }

      vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
        return language.code === lang;
      });


      if (!vm.selectedLanguage)
        vm.selectedLanguage = vm.languageOptions[0];

      vm.changeLanguage(lang);
    };

    $rootScope.$on("markAsRead", function (evt, data) {
      getUnreadMessagesNotification();
    });

    var getDefaultData = function () {

      var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
      if (defaultData.APILocation) {
        $localStorage.code = defaultData.APILocation;
        vm.countryCode = defaultData.APILocation;
        vm.clubLink = defaultData.AddikoClub;
        vm.eTradeLink = defaultData.eTrade;
        vm.generalTerms = defaultData.GeneralTerms;
        vm.defaultData = defaultData;
        vm.languageOptions = CTLocalizationService.getLocalLanguageOptions(vm.countryCode);
        setAppLanguage();
      } else {
        vm.languageOptions = CTLocalizationService.getLanguageOptions();
        setAppLanguage();
      }

      // return ConfigEndpointService.getDefaultData()
      //   .then(function (response) {
      //     if (response.APILocation) {
      //       $localStorage.code = response.APILocation;
      //       vm.countryCode = $localStorage.code;
      //       vm.clubLink = response.AddikoClub;
      //       vm.eTradeLink = response.eTrade;
      //       vm.generalTerms = response.GeneralTerms;
      //
      //       vm.languageOptions = CTLocalizationService.getLocalLanguageOptions(vm.countryCode);
      //       setAppLanguage();
      //     } else {
      //       vm.languageOptions = CTLocalizationService.getLanguageOptions();
      //       setAppLanguage();
      //     }
      //   })
      //   .catch(function (error) {
      //     NotificationService.showMessage(error, 'error');
      //   });
    };

    // List of available languages
    //vm.languageOptions = CTLocalizationService.getLanguageOptions();

    // Listen to language changes from header
    $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
      if (lang && lang.langCode) {
        vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
          return language.code === lang.langCode;
        });
      }
    });

    $rootScope.$on('complaints.read', function (evt, lang) {
      getComplaintsNotifications();
    });
    

    // If user changes language from navigation
    vm.changeLanguage = function () {
      CTLocalizationService.setLanguage(vm.selectedLanguage.code);
      $rootScope.$broadcast('appLanguageChangedNavigation', {
        langCode: vm.selectedLanguage.code
      });
    };


    // Notifications
    var getNotificationsCount = function () {

      var promises = [
        getRejectedPaymentsNotifications(),
        getUnsignedPaymentsNotifications()
        // getGroupPaymentsNotifications()
      ];
      if (vm.countryCode === 'sl') {
        promises.push(getDocumentsNotifications());
      }

      //if (vm.countryCode !== 'sr' && vm.countryCode !== 'mne') {
      //  promises.push(getInvoiceDocumentNotifications());
      //}
      promises.push(getInvoiceDocumentNotifications());
      if (vm.countryCode == 'hr') {
        promises.push(getComplaintsNotifications());
      }
      $q.all(promises)
        .then(function () {
          vm.totalNotificationsCount = vm.eInvoiceNotifications + vm.rejectedPaymentsNotifications + vm.unsignedPaymentsNotifications;
          $rootScope.APP_GLOBAL_SETTINGS.counters.eInvoiceNotifications = angular.copy(vm.eInvoiceNotifications);
          $rootScope.APP_GLOBAL_SETTINGS.counters.rejectedPaymentsNotifications = angular.copy(vm.rejectedPaymentsNotifications);
          $rootScope.APP_GLOBAL_SETTINGS.counters.unsignedPaymentsNotifications = angular.copy(vm.unsignedPaymentsNotifications);
          $rootScope.APP_GLOBAL_SETTINGS.counters.unreadDocumentsNotifications = angular.copy(vm.unreadDocumentsNotifications);
          $rootScope.APP_GLOBAL_SETTINGS.counters.complaintsHasUnreadMessages = angular.copy(vm.complaintsHasUnreadMessages);
          $rootScope.APP_GLOBAL_SETTINGS.counters.countersLoaded = true;


        }).catch(function (error) {
          $log.debug("Total notification error: ", error);
        });
      getUnreadMessagesNotification();
    };

    $rootScope.$on("documentDownloaded", function () {
      getNotificationsCount();
    })

    $rootScope.$on("paymentInserted", function (evt, data) {
      // GET REJECTED PAYMENTS
      // GET PENDING PAYMETNS NUMBER
      if (vm.defaultData.APILocation == "sl") {
        getUnsignedPaymentsNotifications();
      } else {
        getNotificationsCount();
      }
    });


    var getInvoiceDocumentNotifications = function () {
      var notifyParams = {
        type: 'eInvoiceDocuments',
        query: {
          status: 'Inserted'
        }
      };

      if (vm.userSettings.lastLoginDate) {
        notifyParams.query.valueDateGTE = new Date(vm.userSettings.lastLoginDate);
      }

      if (vm.countryCode == 'sl') { //temporarly put first day of the current year
        var date = new Date();
        date.setDate(new Date().getDate() - 365);
        notifyParams.query.valueDateGTE = new Date(date);
      }

      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.eInvoiceNotifications = +response;
        })
        .catch(function (error) {
          vm.eInvoiceNotifications = 0;
          $log.error(error);
        });
    };

    var getRejectedPaymentsNotifications = function () {
      var notifyParams = {
        type: 'Payments',
        query: {
          status: 'Rejected'
        }
      };

      if (vm.userSettings.lastLoginDate) {

        if (vm.countryCode == 'sl') {
          notifyParams.query.dueDateGTE = new Date(vm.userSettings.lastLoginDate);
        } else {
          notifyParams.query.dateCreatedGTE = new Date(vm.userSettings.lastLoginDate);
        }
      }



      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.rejectedPaymentsNotifications = +response;
        })
        .catch(function (error) {
          vm.rejectedPaymentsNotifications = 0;
          $log.error(error);
        });
    };

    var getUnsignedPaymentsNotifications = function () {
      var notifyParams = {
        type: "Payments",
        query: {
          status: "Opened"
        }
      };

      if (vm.countryCode == 'sl') {
        notifyParams.query.status = '';
        notifyParams.query.statusList = ['InProcessing', 'WaitingQueue', 'Opened'];
      }

      if (vm.userSettings.lastLoginDate && vm.countryCode != 'sl') {
        notifyParams.query.dateCreatedGTE = new Date(vm.userSettings.lastLoginDate);
      }


      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.unsignedPaymentsNotifications = +response;

          if (vm.defaultData.APILocation == "sl") {
            vm.totalNotificationsCount = vm.eInvoiceNotifications + vm.rejectedPaymentsNotifications + vm.unsignedPaymentsNotifications;
            $rootScope.APP_GLOBAL_SETTINGS.counters.eInvoiceNotifications = angular.copy(vm.eInvoiceNotifications);
            $rootScope.APP_GLOBAL_SETTINGS.counters.rejectedPaymentsNotifications = angular.copy(vm.rejectedPaymentsNotifications);
            $rootScope.APP_GLOBAL_SETTINGS.counters.unsignedPaymentsNotifications = angular.copy(vm.unsignedPaymentsNotifications);
          }
        })
        .catch(function (error) {
          vm.unsignedPaymentsNotifications = 0;
          $log.error(error);
        });
    };

    var getUnreadMessagesNotification = function () {
      var notifyParams = {
        type: 'Messages',
        query: {
          unreadMessages: true,
          messageStatus: "Received"
        }
      };
      // Filter only unread messages from last login
      if (vm.userSettings.lastLoginDate) {
        notifyParams.query.creationDateGTE = new Date(vm.userSettings.lastLoginDate);
      }
      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.unreadMessagesNotification = +response;
        })
        .catch(function (error) {
          vm.unreadMessagesNotification = 0;
          $log.error(error);
        });
    };

    var getDocumentsNotifications = function () {
      var notifyParams = {
        type: 'Documents'
      };

      return NotificationCountService.getNotificationCount(notifyParams)
        .then(function (response) {
          vm.unreadDocumentsNotifications = +response;
        })
        .catch(function (error) {
          vm.unreadDocumentsNotifications = 0;
          $log.error(error);
        });
    };

    var getComplaintsNotifications = function() {
      var notifyParams = {
        type: 'Complaints'
      };

      return NotificationCountService.getNotificationCount(notifyParams)
      .then(function (response) {
        vm.complaintsHasUnreadMessages = response == 'true' ? 1: 0;
      })
      .catch(function (error) {
        vm.complaintsHasUnreadMessages = 0;
        $log.error(error);
      }); 
    }

    var getFunds = function () {
      FundsService.getPage()
        .then(function (response) {
          if (response.result && response.result.length > 0) {
            vm.navigationSubItems = response.result;
          }
        })
        .catch(function (error) {
          //          NotificationService.showMessage(error, 'error');
          $log.debug("Funds:", error);
        })
    };

    vm.gotoNewOrder = function () {
      GoogleTagManagerHelperService.pushEvent('BuyStocks', 'SecondaryNavigationItem', $translate.instant('navigation.newOrder'));
      $state.go('investments.form.new', {
        orderType: 'new',
        fundId: undefined
      }, {
        reload: true
      });
      vm.closeSecondaryNavigation();
    };

    vm.logOut = function () {

      var modalInstance = $uibModal.open({
        templateUrl: 'core/components/logout-confirm-modal.tpl.html',
        backdrop: 'static',
        size: 'lg'
      })
        .result.then(function (result) {
          console.log(result);
          if (result == "logout") SessionService.logout();
        });
    };

    vm.notYetImplemented = function () {
      AlertService.infoAlert({
        text: "Not yet implemented!"
      });
    };

    vm.openPayLifeBalancePage = function () {
      $window.open(vm.defaultData.PayLifeBalanceInquiry, '_blank');
    };

    vm.deactivateViber = function () {
      var userId = $rootScope.APP_GLOBAL_SETTINGS.userSettings.id;
      ViberService.deactivate(userId, {
        deactivateViberUser: true,
        isWebCall: true
      })
        .then(function (res) {
          AlertService.infoAlert({
            text: "Viber service deactivated successfully!"
          });
        })
        .catch(function (err) {
          NotificationService.showMessage(err, 'error');
        })
    };

    // Viber functionality is just for BiH environment
    vm.checkUserViberActivation = function () {

      ViberService.checkActivation()
        .then(function (response) {

          $rootScope.APP_GLOBAL_SETTINGS.viberData = {
            phoneNumber: response.phoneNumber,
            activationDate: response.activationDate
          };

          var stateToGo = response.isViberUser ? 'viber.deactivate' : 'viber';
          $state.go(stateToGo);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });

      //OLD CODE

      // var stateToGo = $rootScope.APP_GLOBAL_SETTINGS.isViberActivated ? 'viber.deactivate' : 'viber';
      // console.log("State to go");
      // $state.go(stateToGo);
    };


    vm.init();
  }
}());
