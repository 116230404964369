(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name paymentTemplates.factory:PaymentTemplatesService
     *
     * @description
     *
     */
    angular
        .module('paymentTemplates')
        .factory('PaymentTemplatesService', PaymentTemplatesService);

    function PaymentTemplatesService(EndpointsService, $q, $http, $log, $uibModal, $filter, PaymentFilterService, PaymentTypesService, $rootScope) {
        var PaymentTemplatesServiceBase = {};

        PaymentTemplatesServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            var predefinedParams = {
              orderBy: 'name ASC'
            };
            var extended = _.extend(predefinedParams, params);
            EndpointsService.getLinks().then(function (response) {
                $log.debug("Params:", extended);
                $http.get(response.links.paymentTemplates, {
                        params: extended
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        PaymentTemplatesServiceBase.getAll = function(params){
            var predefinedParams =
                {
                    page: 0
                    ,pageSize: 1000
                };
            var extended = _.extend(predefinedParams, params);
            return PaymentTemplatesServiceBase.getPage(extended);
        };

        PaymentTemplatesServiceBase.getById = function (id){
          var deferred = $q.defer();
          EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.paymentTemplates_id.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          }, function (error) {
            deferred.reject(error.data);
          });
          return deferred.promise;
        };

        PaymentTemplatesServiceBase.openModal = function (paymentTemplate) {
          var modalInstance = $uibModal.open({
            templateUrl: 'payment-templates/payment-templates-modal.tpl.html',
            controller: 'PaymentTemplatesModalCtrl',
            controllerAs: 'paymentTemplatesModal',
            resolve: {
              paymentTemplate: function () {
                return paymentTemplate;
              }
            },
            backdrop: 'static'
          });
        };

      PaymentTemplatesServiceBase.insertTemplate = function (templateObject) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.post(response.links.paymentTemplates, templateObject)
              .then(function (data) {
                deferred.resolve(data.data);
              })
              .catch(function (msg, code) {
                deferred.reject(msg.data)
              });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      PaymentTemplatesServiceBase.updateTemplate = function (id, updateObject) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.put(response.links.paymentTemplates_id.replace('{id}', id), updateObject)
              .then(function (data) {
                deferred.resolve(data.data);
              })
              .catch(function (msg, code) {
                deferred.reject(msg.data)
              });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      PaymentTemplatesServiceBase.deleteTemplate = function (id) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.delete(response.links.paymentTemplates_id.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      PaymentTemplatesServiceBase.loadInternalTransferData = function (id, paymentObject, params) {
        return this.getById(id)
          .then(function (response) {
            paymentObject.amountInput = response.amount;
            //filterFromAccountSelect(response.accountId);
            //filterToAccountSelect(response.toAccountNumber);
            paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.accountsFrom, response.accountId);
            params.toAccountList = PaymentFilterService.filterToAccountList(params.accountsFrom, paymentObject.fromAccountSelect.accountId);
            paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelect(params.toAccountList, response.toAccountNumber);
          })
          .catch(function (error) {
            $log.error("loadInternaTransferData()", error);
          })
      };

      PaymentTemplatesServiceBase.loadCurrencyConversionData = function (id, paymentObject, params) {
        //var filterToAccountSelect = function (accountNumber) {
        //  if (paymentObject.fromAccountSelect.currencySymbol != params.localCurrency) {
        //    params.accountsList = params.domesticCurrencyAccounts;
        //  } else {
        //    params.accountsList = params.foreignCurrencyAccounts;
        //  }
        //  paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelect(params.accountsList, accountNumber);
        //};
        function loadAccountsLists(accountId) {
          return PaymentTypesService.getByGroup({
              paymentTypeGroup: 'CurrencyExchange',
              masterAccountId: accountId
            })
            .then(function (response) {
              params.fromAccountsList = response.result[0].debitAccountList;
              params.toAccountsList = response.result[0].creditAccountList;
              _.forEach(params.fromAccountsList, function (item) {
                if (item.accountDescription) {
                  item.optionValue = item.iban + ' - ' + item.currencySymbol;
                }
              });

              _.forEach(params.toAccountsList, function (item) {
                if (item.accountDescription) {
                  item.optionValue = item.accountDescription + ' - ' + item.iban;
                }
              });
            })
            .catch(function (error) {
              $log.debug("Error loading accounts: " + error);
            })
        };
        return this.getById(id)
          .then(function (response) {
            return loadAccountsLists()
              .then(function () {
                paymentObject.sellCurrencyInput = response.currencySymbol;
                paymentObject.fromAccountAmountInput = response.amount;
                //filterFromAccountSelect(response.accountId);
                paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountsList, response.accountId);
                paymentObject.sellCurrencyInput = paymentObject.fromAccountSelect.currencySymbol;
                //filterToAccountSelect(response.toAccountNumber.split(" ")[0]);
                paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelect(params.toAccountsList, response.toAccountNumber.split(" ")[0]);
                //filterBuyCurrencyInput();
                paymentObject.buyCurrencyInput = PaymentFilterService.filterBuyCurrencyInput(paymentObject.sellCurrencyInput, params.localCurrency, params.currencyList, paymentObject.toAccountSelect.currencySymbol);
              })
              .catch(function (error) {
                $log.error("Error: " + error);
              });
          })
          .catch(function (error) {

          });
      };

      PaymentTemplatesServiceBase.loadDomesticPaymentData = function (id, paymentObject, params) {
        var countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        return this.getById(id)
          .then(function (response) {
            //filterFromAccountSelect(response.accountId);
            paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, response.accountId);
            paymentObject.purposeOfPaymentInput = response.purpose;
            if (countryCode == 'hr') {
              paymentObject.amountInput = response.amount;
            } else {
              paymentObject.amountInput = $filter('number')(response.amount, 2);
            }
            paymentObject.receiversAddressInput = response.receiverAddress1;
            response.urgency == 1 ? params.urgency = true : params.urgency = false;

            if(response.receiverCity){
              paymentObject.receiversPostalCodeAndCityInput = response.receiverCity;
            }
            paymentObject.toAccountInput = response.toAccountNumber;
            paymentObject.referenceNumberInput = response.referenceNumber || '';
            params.payeeSearchFilter = response.receiverName;
            if (response.purposeCodeDPS) {
              //filterPurposeCodeSelect(response.purposeCodeDPS);
              paymentObject.purposeCodeSelect = PaymentFilterService.filterPurposeCodeSelect(params.purposeCodeList, response.purposeCodeDPS);
            }
            if (response.referenceTo) {
              //filterReferenceModelSelect(response.referenceTo);
              paymentObject.referenceModelSelect = PaymentFilterService.filterReferenceModelSelect(params.referenceModelList, response.referenceTo);
              // in case of longer referenceTo load
              if(response.referenceTo.length > 2) {
                paymentObject.referenceNumberInput = response.referenceTo.substring(2);
              }
            } else if (response.reference) {
              paymentObject.referenceNumberInput = response.reference;
            }
            // in case of public revenue payment
            if(response.paymentTypeGroup == "RevenuePayment"){
              var paymentTypes = ["0", "1", "2", "3", "5", "6", "7", "8", "9"];
              paymentObject.budgetOrganization = response.budgetOrganization;
              paymentObject.municipality = response.municipality;
              paymentObject.paymentType = _.find(paymentTypes, function (item){
                return response.revenuePaymentType == item;
              });
              paymentObject.revenueCode = response.revenueCode;
              paymentObject.taxPayerId = response.taxPayerId;
              paymentObject.taxPeriodFrom = new Date(response.taxPeriodFrom);
              paymentObject.taxPeriodTo = new Date(response.taxPeriodTo);
            }
          })
          .catch(function (error) {
            $log.error('loadDomesticPaymentData()', error)
          })
      };

        return PaymentTemplatesServiceBase;
    }
}());
