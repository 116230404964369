<section id="main">
  <div class="container">
    <div>
      <div class="exchange-rate-header">
        <div class="row">
          <div class="col-sm-12 col-xs-6 f-20" translate="paymentsAndTransferOverview.groupedPaymentsLabel"></div>
        </div>
      </div>

      <!-- Large screens start -->
      <!-- Header for payments sign -->
      <div class="row group-sign-header p-t-20 p-b-10 m-t-15 hidden-xs">
        <div class="col-sm-5">
            <span class="col-sm-6" translate="payments.fromAccount">
              From account
            </span>
            <span class="col-sm-6" translate="payments.toAccount">
              To account
            </span>
        </div>
          <span class="col-sm-2" translate="loanPayment.purpose">
            Purpose
          </span>
          <span class="col-sm-2 t-a-r" translate="domesticPayment.amountInputLabel">
            Amount
          </span>
        <div class="col-sm-3">
            <span class="col-sm-6" translate="signPayments.dateCreated">
            Date created
            </span>
            <span class="col-sm-6" translate="signPayments.dueDate">
              Due date
            </span>
        </div>

      </div>

      <!-- Details for group of payments -->
      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" class="row hidden-xs word-break"
        data-ng-repeat="payments in paymentsSign._groupByCurrencySymbol">
      
        <div class="row hidden-xs word-break" data-ng-repeat="payment in payments">
          <div class="col-sm-5">
            <span class="col-sm-6">
              {{payment.fromAccountNumber}}
            </span>
            <span class="col-sm-6">
              {{payment.toAccountNumber}}
            </span>
          </div>
          <span class="col-sm-2" title="{{payment.purpose}}">
            {{ payment.purpose | limitTo: 40 }}{{payment.purpose.length > 40 ? '...' : ''}}
          </span>
          <span class="col-sm-2 t-a-r">
            {{payment.amount|number:2}} {{::payment.currencySymbol}}
          </span>
          <div class="col-sm-3">
            <span class="col-sm-6">
              {{payment.dateCreated| date:'shortDate' : '+0200'}}
            </span>
            <span class="col-sm-6">
              {{payment.dueDate| date:'shortDate' : '+0200'}}
            </span>
          </div>
        </div>

        <!-- Sum of payments grouped by currencySymbol -->

        <div class="row group-sign-content hidden-xs word-break">
          <div class="col-sm-5">
            <span class="col-sm-6">
              <!-- {{payment.fromAccountNumber}} -->
            </span>
            <span class="col-sm-6">
              <!-- {{payment.toAccountNumber}} -->
            </span>
          </div>
          <span class="col-sm-2" translate="signPayments.totalAmount">
          </span>
          <span class="col-sm-2 t-a-r">
            {{payments[0].totalAmount|number:2}} {{::payments[0].currencySymbol}}
          </span>
          <div class="col-sm-3">
            <span class="col-sm-6">
              <!-- {{payment.dateCreated| date:'shortDate' : '+0200'}} -->
            </span>
            <span class="col-sm-6">
              <!-- {{payment.dueDate| date:'shortDate' : '+0200'}} -->
            </span>
          </div>
        </div>

        <div class="row"></div>

      </div>

      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" class="row group-sign-content hidden-xs word-break"
        data-ng-repeat="payment in paymentsSign.paymentsGroupList.paymentList">
        <div class="col-sm-5">
          <span class="col-sm-6">
            {{payment.fromAccountNumber}}
          </span>
          <span class="col-sm-6">
            {{payment.toAccountNumber}}
          </span>
        </div>
        <span class="col-sm-2" title="{{payment.purpose}}">
          {{ payment.purpose | limitTo: 40 }}{{payment.purpose.length > 40 ? '...' : ''}}
        </span>
        <span class="col-sm-2 t-a-r">
          {{payment.amount|number:2}} {{::payment.currencySymbol}}
        </span>
        <div class="col-sm-3">
          <span class="col-sm-6">
            {{payment.dateCreated| date:'shortDate' : '+0200'}}
          </span>
          <span class="col-sm-6">
            {{payment.dueDate| date:'shortDate' : '+0200'}}
          </span>
        </div>
      </div>
      <!-- Large screens end -->

      <!-- Small screens start -->

      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'"
        class="row visible-xs sign-payment-mobile m-t-5 m-b-5 group-sign-container"
        data-ng-repeat="payments in paymentsSign._groupByCurrencySymbol">
      
        <div class="row margin-left-right-custom no-bottom-border-custom visible-xs sign-payment-mobile m-t-5 m-b-5 group-sign-container" data-ng-repeat="payment in payments">
      
          <div class="first-property col-xs-12">
            <span class="col-xs-6 f-w-bold" translate="payments.fromAccount">
              From account
            </span>
            <span class="col-xs-6">
              {{payment.fromAccountNumber}}
            </span>
          </div>
          <div class="col-xs-12 ">
            <span class="col-xs-6 f-w-bold" translate="payments.toAccount">
              To account
            </span>
            <span class="col-xs-6">
              {{payment.toAccountNumber}}
            </span>
          </div>
          <div class="col-xs-12 ">
            <span class="col-xs-6 f-w-bold" translate="loanPayment.purpose">
              Purpose
            </span>
            <span class="col-xs-6" title="{{payment.purpose}}">
              {{ payment.purpose | limitTo: 40 }}{{payment.purpose.length > 40 ? '...' : ''}}
            </span>
          </div>
          <div class="col-xs-12">
            <span class="col-xs-6 f-w-bold" translate="domesticPayment.amountInputLabel">
              Amount
            </span>
            <span class="col-xs-6">
              {{payment.amount|number:2}} {{::payment.currencySymbol}}
            </span>
          </div>
          <div class="col-xs-12">
            <span class="col-xs-6 f-w-bold" translate="signPayments.dateCreated">
              Date created
            </span>
            <span class="col-xs-6">
              {{payment.dateCreated| date:'shortDate' : '+0200'}}
            </span>
          </div>
          <div class="col-xs-12">
            <span class="col-xs-6 f-w-bold" translate="signPayments.dueDate">
              Due date
            </span>
            <span class="col-xs-6">
              {{payment.dueDate| date:'shortDate' : '+0200'}}
            </span>
          </div>
        </div>
    
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" style="text-align: right; font-weight: bold;" translate="signPayments.totalAmount">
            Amount
          </span>
          <span class="col-xs-6">
            {{payments[0].totalAmount|number:2}} {{::payment[0].currencySymbol}}
          </span>
        </div>
      </div>

      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" class="row visible-xs sign-payment-mobile m-t-5 m-b-5 group-sign-container"
        data-ng-repeat="payment in paymentsSign.paymentsGroupList.paymentList">
      
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="payments.fromAccount">
            From account
          </span>
          <span class="col-xs-6">
            {{payment.fromAccountNumber}}
          </span>
        </div>
        <div class="col-xs-12 ">
          <span class="col-xs-6 f-w-bold" translate="payments.toAccount">
            To account
          </span>
          <span class="col-xs-6">
            {{payment.toAccountNumber}}
          </span>
        </div>
        <div class="col-xs-12 ">
          <span class="col-xs-6 f-w-bold" translate="loanPayment.purpose">
            Purpose
          </span>
          <span class="col-xs-6" title="{{payment.purpose}}">
            {{ payment.purpose | limitTo: 40 }}{{payment.purpose.length > 40 ? '...' : ''}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="domesticPayment.amountInputLabel">
            Amount
          </span>
          <span class="col-xs-6">
            {{payment.amount|number:2}} {{::payment.currencySymbol}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="signPayments.dateCreated">
            Date created
          </span>
          <span class="col-xs-6">
            {{payment.dateCreated| date:'shortDate' : '+0200'}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="signPayments.dueDate">
            Due date
          </span>
          <span class="col-xs-6">
            {{payment.dueDate| date:'shortDate' : '+0200'}}
          </span>
        </div>
      </div>

      <!-- Small screens end -->

      <!-- Actions for payments group -->
      <div class="row m-b-10">
          <span class="col-xs-12 m-t-20" ng-show="!paymentsSign.areAllPaymentsDueDateValid"
                translate="signPayments.paymentsInvalidDueDate">
          </span>
          <span class="col-xs-12 payments-filter-active"
                ng-show="paymentsSign.dueDateUpdateAllowed && !paymentsSign.areAllPaymentsDueDateValid"
                data-ng-click="paymentsSign.updateGroupPaymentDates()" translate="signPayments.updatePaymentsDueDate">
          </span>

        <!-- SMS Validation -->
        <div class="col-xs-12 m-t-30" ng-if="paymentsSign.paymentsGroupList.linkList.itemList.requestOTP">
          <div class="col-xs-12 m-b-20">
              <span ng-if="paymentsSign.countryCode != 'bh' && paymentsSign.countryCode != 'sl'" class="f-w-bold">
                {{'signPayments.SmsDescription' | translate}}
              </span>
            <span ng-if="paymentsSign.countryCode === 'bh'" class="f-w-bold">
                {{'signPayments.SmsDescriptionBh' | translate}}
              </span>
            <span ng-if="paymentsSign.countryCode === 'sl'" class="f-w-bold">
                {{'signPayments.SmsDescriptionSLO' | translate}}
            </span>
          </div>
            <div class="row p-10">
                <div ng-if="paymentsSign.countryCode === 'mne'" class="col-xs-12 m-5">
                    <p> <span translate="signPayments.infoDomMne0"></span>
                            <span><a href="{{'signPayments.infoDomMneLink' | translate}}"
                                     translate="signPayments.infoDomMne1"></a></span></p>
                </div>
                <div ng-if="paymentsSign.countryCode === 'sr'" class="col-xs-12 m-5">
                    <p> <span translate="signPayments.infoDomSrb0"></span>
                            <span><a href="{{'signPayments.infoDomSrbLink' | translate}}" translate="signPayments.infoDomSrb1"></a></span></p>
                </div>
            </div>
          <div class="col-xs-8 col-sm-6">
            <div class="form-group">
              <span class="f-w-bold">{{'signPayments.SmsCode' | translate}}</span>
              <div class="input-group">
                <input id="smsInput" type="text" class="form-control input-field text-uppercase"
                       data-ng-model="paymentsSign.smsPasswordInput"
                       placeholder="{{'currencyConversion.smsPassword' | translate}}"
                       auto-focus>
                <span class="input-group-btn">
                  <!--<button class="btn btn-default input-group-app-btn domp-btn-height"-->
                          <!--data-ng-click="paymentsSign.toggleInputVisibility()">-->
                    <!--<i data-ng-show="paymentsSign.showInput" class="zmdi zmdi-lock"></i>-->
                    <!--<i data-ng-show="!paymentsSign.showInput" class="zmdi zmdi-lock-open"></i>-->
                  <!--</button>-->
                </span>
                <span class="input-group-btn" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                  <button class="btn btn-default input-group-app-btn domp-btn-height"
                          data-ng-click="paymentsSign.sendSMS()"
                          ng-disabled="paymentsSign.signButtonDisabled"
                          uib-tooltip="{{'signPayments.resendSms' | translate}}">
                    <i class="zmdi zmdi-refresh-sync"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-2 col-xs-6 m-t-20">
            <button class="btn btn-primary" data-ng-click="paymentsSign.cancelGroupPayments()"
                    translate="signPayments.cancel"></button>
          </div>
          <div class="col-sm-2 col-xs-6 m-t-20 pull-right">
            <button class="btn btn-default"
                    data-ng-click="paymentsSign.signGroupPayments()"
                    ng-disabled="paymentsSign.formSubmitted || !paymentsSign.areAllPaymentsDueDateValid || paymentsSign.smsPasswordInput === ''"
                    translate="paymentsAndTransferOverview.confirm"></button>
          </div>
        </div>

        <!-- Challenge response validation -->
        <div class="col-xs-12 m-t-30" ng-if="!paymentsSign.paymentsGroupList.linkList.itemList.requestOTP && !paymentsSign.paymentsGroupList.linkList.itemList.requestMiniToken" >
          <addiko-mtoken-sign
            show-cancel="true"
            placeholder="paymentsSign.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
            qr-challenge="paymentsSign.qrChallenge" show-challenge="paymentsSign.showChallenge" 
            challenge1="paymentsSign.challengeFirstCode" challenge2="paymentsSign.challengeSecondCode"
            action-disabled="!paymentsSign.areAllPaymentsDueDateValid" challenge-input="paymentsSign.challengeResponse"
            second-challenge-input="paymentsSign.last8digitsFromAccountResponse"></addiko-mtoken-sign>
        </div>

        <!-- MiniToken validation (FOR NOW ONLY BIH ENVIRONMENT) -->
        <div class="col-xs-12 m-t-30" ng-if="paymentsSign.paymentsGroupList.linkList.itemList.requestMiniToken" >
          <form id="miniTokenForm" autocomplete="off" novalidate>
            <div class="col-xs-12 m-b-20">
              <span class="f-w-bold">{{'signPayments.OtpDescriptionBh' | translate}}</span>
            </div>
            <div class="col-xs-6 col-md-6">
              <div class="form-group">
                <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.otpLabel' |
                  translate}}
                </div>
                <input id="spahaMT" name="spahaMT" type="text" class="form-control input-field"
                       data-ng-model="paymentsSign.challengeResponse"
                       ng-pattern-restrict="{{paymentsSign.regexPatterns.onlyNumbers}}"
                       placeholder="{{'signPayments.challengeSecondCodeBh' | translate}}" autocomplete="off" auto-focus>
              </div>
            </div>
            <div class="col-sm-2 col-xs-6 m-t-20">
              <button class="btn btn-primary col-xs-12 " data-ng-click="paymentsSign.cancelGroupPayments()"
                      translate="signPayments.cancel"></button>
            </div>
            <div class="col-sm-4 col-xs-12 m-t-20 pull-right">
              <button class="btn btn-default col-xs-12 " data-ng-click="paymentsSign.signGroupPayments()"
                      ng-disabled="paymentsSign.formSubmitted || !paymentsSign.areAllPaymentsDueDateValid"
                      translate="paymentsAndTransferOverview.confirm"></button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</section>
