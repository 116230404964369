(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:PublicRevenueService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('PublicRevenueService', PublicRevenueService);

  function PublicRevenueService($q, $http, EndpointsService) {
    var PublicRevenueServiceBase = {};
    PublicRevenueServiceBase.getMunicipalities = function (params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.municipalities, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PublicRevenueServiceBase.getRevenueCodes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.revenueCodes, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PublicRevenueServiceBase.getBudgetOrganizations = function (params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.budgetOrganisations, {
              params: params
            })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PublicRevenueServiceBase.getRevenueAccounts = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.revenueAccounts, {
              params: params
            })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    return PublicRevenueServiceBase;
  }
}());
