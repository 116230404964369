<form name="internalTransferForm" novalidate>
  <div ng-if="!transactionDivision.isSignFirstStep" 
       class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.amount"></label>
          <label class="d-block domp-form-label-resume">{{transactionDivision.postResponseObj.amount | number: 2}}
            {{transactionDivision.tempTransDivObj.currency}}</label>
        </div>
      </div>
      <div ng-if="transactionDivision.apiLocation !== 'hr'" class="col-sm-6">
        <label class="domp-form-label-review" translate="transactionDivision.cardNumber">Card number</label>
        <label class="d-block domp-form-label-resume">
          {{transactionDivision.postResponseObj.cardNumber}}
        </label>
      </div>
    </div>
    <div ng-if="transactionDivision.apiLocation !== 'hr'" class="row">
      <div class="col-sm-6">
        <label class="domp-form-label-review" translate="transactionDivision.creatorName">Creator name</label>
        <label class="d-block domp-form-label-resume">
          {{transactionDivision.postResponseObj.creatorName}}
        </label>
      </div>
      <div class="col-sm-6">
        <label class="domp-form-label-review" translate="transactionDivision.transLocation">Transaction
          location</label>
        <label
          class="d-block domp-form-label-resume">{{transactionDivision.postResponseObj.transactionLocation}}</label>
      </div>
    </div>
    <div class="row">
      <div ng-if="transactionDivision.apiLocation !== 'hr'" class="col-sm-6">
        <label class="domp-form-label-review" translate="transactionDivision.status">Status</label>
        <label class="d-block domp-form-label-resume">{{'transactionDivision.'+transactionDivision.postResponseObj.status | translate}}</label>
      </div>
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.installmentNumber"></label>
          <label
            class="d-block domp-form-label-resume">{{transactionDivision.postResponseObj.installmentNumber}}</label>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="!transactionDivision.isSignFirstStep" class="domp-to-section m-10">
    <div ng-if="transactionDivision.apiLocation !== 'hr'" class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.creationDate"></label>
          <label class="d-block domp-form-label-resume">{{transactionDivision.postResponseObj.creationDate | date:
            'shortDate' : '+0200'}}</label>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.transactionDate"></label>
          <label class="d-block domp-form-label-resume">{{transactionDivision.postResponseObj.valueDate | date:
            'shortDate' : '+0200'}}</label>
        </div>
      </div>
    </div>
    <div ng-if="transactionDivision.apiLocation === 'hr'" class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.installmentAmount"></label>
          <label class="d-block domp-form-label-resume">{{transactionDivision.tempTransDivObj.installmentRateAmount | number: 2}}
            {{transactionDivision.tempTransDivObj.currency}}</label>
        </div>
      </div>
    </div>
    <div ng-if="transactionDivision.apiLocation === 'hr'" class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="transactionDivision.fee"></label>
          <label class="d-block domp-form-label-resume">{{transactionDivision.tempTransDivObj.transactionDivisionFeeAmount | number: 2}}
            {{transactionDivision.tempTransDivObj.currency}}</label>
        </div>
      </div>
    </div>
    
  </div>
  <div ng-if="!transactionDivision.isSignFirstStep && transactionDivision.apiLocation == 'hr'" class="domp-to-section m-10">
    <div ng-if="transactionDivision.apiLocation === 'hr'" class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label style="font-size: 14px;font-style: italic;" translate="transactionDivision.feeInfo"></label>
        </div>
      </div>
    </div>
  </div>
  <div ng-if="transactionDivision.isSignFirstStep"
      class="container domp-from-section">
      <addiko-mtoken-sign
              ng-if="transactionDivision.countryData == 'hr'"
              show-cancel="false"
              placeholder="transactionDivision.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
              qr-challenge="transactionDivision.qrChallenge" show-challenge="true" 
              challenge1="transactionDivision.challengeFirstCode" challenge2="transactionDivision.challengeSecondCode"></addiko-mtoken-sign>
    <div ng-if="transactionDivision.countryData != 'hr'" class="row p-15">
      <div class="col-xs-12 m-t-30 m-b-30">
        <div class="col-xs-12 m-b-20">
            <span class="f-w-bold">{{'signPayments.challengeDescription' | translate}}</span>
        </div>
        <div class="col-sm-6 col-xs-12">
            <span class="f-w-bold">{{'signPayments.challengeFirstCode' | translate}}</span>
            <input type="text" class="form-control input-field" disabled data-ng-model="transactionDivision.postResponseObj.challenge">
        </div>
        <div class="col-sm-6 col-xs-12">
            <span class="f-w-bold">{{'signPayments.challengeSecondCode' | translate}}</span>
            <input type="text" class="form-control input-field" data-ng-model="transactionDivision.challengeResponse" 
                    ng-pattern-restrict="{{transactionDivision.regexPatterns.onlyNumbers}}" 
                    placeholder="{{'signPayments.challengeSecondCode' | translate}}" autofocus>
        </div>
    </div>
    </div>
  </div>

  <div class="m-10">
    <div class="row">
      <div ng-if="!transactionDivision.isSignFirstStep"
           class="col-sm-9 p-b-10 col-xs-12 hidden-xs">
        <button ng-if="transactionDivision.apiLocation != 'hr'" class="btn btn-primary " translate="currencyConversion.back"
                data-ng-click="transactionDivision.new();">
        </button>
        <button class="btn btn-primary" translate="payments.edit"
                data-ng-click="transactionDivision.edit();">
        </button>
        <button ng-if="transactionDivision.apiLocation != 'hr'" class="btn btn-primary-warning " translate="payments.delete"
                data-ng-click="transactionDivision.delete();">
        </button>
      </div>

      <div ng-if="transactionDivision.isSignFirstStep"
          class="col-sm-9 p-b-10 col-xs-12 hidden-xs">
          <button class="btn btn-primary" 
                  data-ng-click="transactionDivision.isSignFirstStep = false;"
                  translate="signPayments.cancel">Odustani</button>
      </div>

      <div class="col-sm-9 p-b-10 col-xs-12 visible-xs-block">
       
        <button ng-if="transactionDivision.apiLocation != 'hr' " class="btn btn-default btn-block"
                translate="currencyConversion.sign"
                ng-disabled="transactionDivision.isSignFirstStep"
                data-ng-click="transactionDivision.sign()">
        </button>
        <button ng-if="transactionDivision.apiLocation !== 'hr'"
                class="btn btn-primary btn-block" translate="currencyConversion.back"
                data-ng-click="transactionDivision.new();">
        </button>
        <button class="btn btn-primary btn-block" translate="payments.edit"
                data-ng-click="transactionDivision.edit();">
        </button>
        <button class="btn btn-primary-warning btn-block" translate="payments.delete"
                data-ng-click="transactionDivision.delete();">
        </button>
      </div>

      <div  class="col-sm-3 col-xs-12 hidden-xs">

        <button ng-if="!transactionDivision.isSignFirstStep"
                class="btn btn-default col-xs-12"
                translate="currencyConversion.sign"
                ng-disabled="transactionDivision.isSignFirstStep"
                data-ng-click="transactionDivision.sign()">
        </button>
        <button ng-if="transactionDivision.isSignFirstStep && !transactionDivision.qrChallenge && transactionDivision.apiLocation !== 'hr'"
                class="btn btn-default col-sm-8 col-xs-4 pull-right" 
                data-ng-click="transactionDivision.signTransactionDivision()" 
                ng-disabled="transactionDivision.challengeResponse === ''"
                translate="signPayments.confirm">Confirm</button>

      </div>
    </div>
  </div>

</form>


