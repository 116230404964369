<div class="col-sm-12 col-md-6 m-b-15" data-ng-click="marketingBanner.onBannerClicked($event);">  <!--data-ng-click="marketingBanner.checkViber();">-->
  <div ng-if="marketingBanner.countryCode != 'hr' && marketingBanner.countryCode != 'sl'" class="banner-image-url cursor-pointer response-img"
       style="height: 161px;"
       ng-style="{'background-image': 'url(' + marketingBanner.viberImageUrl + ')'}">
  </div>

  <div ng-if="marketingBanner.countryCode == 'hr' || marketingBanner.countryCode == 'sl'" class="banner-image-url"
       ng-style="{'background-image': 'url(' + marketingBanner.viberImageUrl + ')'}">
    <div class="t-a-l" style="position:absolute; bottom: 0px; top:0px; left: 35px;">
      <h3 class="c-white custom-underline f-20" ng-if="marketingBanner.currentLang == 'hr'" style="">
        {{marketingBanner.ViberMarketingInfo.text1}}</h3>
      <h3 class="c-white custom-underline f-20" ng-if="marketingBanner.currentLang == 'en'" style="">
        {{marketingBanner.ViberMarketingInfo.textEng1}}</h3>
    </div>

    <div class="t-a-l" style="position:absolute; bottom: 0px; top:40px; left: 35px;">
      <h3 class="c-white fw-cl f-20" ng-if="marketingBanner.currentLang == 'hr' || marketingBanner.currentLang == 'sl'" style="margin-top: 15px;">
        {{marketingBanner.ViberMarketingInfo.text2}}</h3>
      <h3 class="c-white fw-cl f-20" ng-if="marketingBanner.currentLang == 'en'" style="margin-top: 15px;">
        {{marketingBanner.ViberMarketingInfo.textEng2}}</h3>
      <h3 class="c-white fw-cl pull-left m-0 f-20" ng-if="(marketingBanner.currentLang == 'hr' || marketingBanner.currentLang == 'sl') && marketingBanner.ViberMarketingInfo.text3">{{marketingBanner.ViberMarketingInfo.text3}}</h3>
      <h3 class="c-white fw-cl pull-left m-0 f-20" ng-if="marketingBanner.currentLang == 'en' && marketingBanner.ViberMarketingInfo.textEng3">{{marketingBanner.ViberMarketingInfo.textEng3}} </h3>
    </div>

    <!--<div class="t-a-l">-->
    <div style="position: absolute; bottom: 3px; left: 35px;">
      <button type="button" class="btn btn-default text-uppercase round-marketing-button" ng-if="marketingBanner.ViberMarketingInfo.buttonText">
        <span class="glyphicon glyphicon-chevron-right round-marketing-icon" aria-hidden="true"></span>
        <span class="round-marketing-text" ng-if="marketingBanner.currentLang == 'hr'">{{marketingBanner.ViberMarketingInfo.buttonText}}</span>
        <span class="round-marketing-text" ng-if="marketingBanner.currentLang == 'en'">{{marketingBanner.ViberMarketingInfo.buttonTextEng}}</span>
      </button>
    </div>
    <!--</div>-->


  </div>

</div>
