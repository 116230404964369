<section id="main">
  <div class="container-fluid">
    <div class="m-b-30">
      <!-- START Title -->
      <div class="row">
        <div class="col-md-12">
          <h2>
            <h2 class="f-28" translate="investmentsList.invOverview"></h2>
          </h2>
        </div>
      </div>
      <!-- END Title -->

      <!--START: DISCLAIMER-->
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info" style="background: white; color: black; border: 1px solid lightgray" role="alert">
            <p translate="invest.info"></p>
            <p translate="invest.info1"></p>
          </div>
        </div>
      </div>
      <!--END: DISCLAIMER-->

      <!-- START Investments View-->
      <div class="row m-b-15">
        <div class="col-md-6 col-sm-6 m-b-15" data-ng-repeat="fundItem in investmentsList.fundsList">
          <div class="account-item-widget app-red-card cursor-pointer" style="height: 180px;" data-ui-sref="investments.overview({fundId: fundItem.id})">
            <h4 class="acc-name c-white m-t-0 m-b-0">
              <b class="col-xs-10 p-0  no-wrap-text ng-binding">{{::fundItem.fundName}}</b>
            </h4>
            <span class="c-white f-14 no-wrap-text">{{::fundItem.isin}}</span>
            <br/>
            <span class="c-white f-14 no-wrap-text">{{::fundItem.shareAccount}}</span>
            <div ng-if="fundItem.shareQuantity">
              <amount-formatter amount="{{fundItem.shareQuantity|number:2}}"
                                custom-classes="c-white" currency="{{fundItem.currency}}" from-type="dashboard" aditional-val=""
                                has-underline="true">
              </amount-formatter>
              <p class="c-white f-14 m-t-5 m-b-0" translate="investOverview.shareQuantity"></p>
            </div>
          </div>
        </div>
      </div>
      <!--END Investments View -->
    </div>
  </div>
</section>
