<a href="" data-ng-click="dashboard.gotoAccount(account.accountId)">
    <div class="account-item-widget app-red-card"
        ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}"
        ng-if="account.accountType.group != 'LoanAccountType'">
        <h4 class="acc-name c-white m-t-0 m-b-0"><b class="col-xs-10 p-0  no-wrap-text">{{::account.friendlyName}}</b>
            <span class="col-xs-2 p-0 t-a-r c-white" style="height: 30px;"><i class="c-white"
                    ng-class="{'icon icon-favourite favorite-account':account.isFavorite,'f-24 zmdi zmdi-star-outline':!account.isFavorite}"></i></span>
        </h4>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
            <span ng-if="::account.iban" class="c-white f-14  no-wrap-text fw-cm">{{::account.iban | formatIban}}</span>
            <span ng-if="::!account.iban" class="c-white f-14  no-wrap-text fw-cm">{{::account.accountNumber}}</span>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            <span
                ng-if="account.accountNumber && account.accountType.group == 'CardAccountTypes' || !account.iban && account.accountType.group != 'CardAccountTypes'"
                class="c-white f-14  no-wrap-text fw-cm">{{::account.accountNumber}}</span>
            <span ng-if="account.iban && account.accountType.group != 'CardAccountTypes'"
                class="c-white f-14  no-wrap-text fw-cm">{{::account.iban | formatIban}}</span>
        </div>

        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
            <div ng-if="::account.accountType.group !== 'SavingAccountTypes' && account.accountType.group !== 'CardAccountTypes' 
                        && account.accountType.group !== 'DepositAccountTypes'">
                 <div class="d-inline-block">
                    <amount-formatter amount="{{account.balance|number:2}}"
                        class="float-left"
                        custom-classes="{{'c-white'}}" currency="{{::account.currencySymbol}}"
                        from-type="dashboard" aditional-val="{{account.availableBalance|number:2}}">
                    </amount-formatter>
                    <amount-formatter ng-if="dashboard.isNuN(account.balance2 )" amount="{{account.balance2|number:2}}"
                        is-secondary-value="true"
                        class="float-left m-t-12"
                        currency="{{::account.currencySymbol2}}" custom-classes="{{'c-white'}}" from-type="dashboard"
                        aditional-val="{{account.availableBalance2|number:2}}" has-bracket="true">
                    </amount-formatter>
                </div>
                <div class="underline"></div>
                <p translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Available balance</p>
            </div>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
            <div
                ng-if="::account.accountType.group !== 'SavingAccountTypes' && account.accountType.group !== 'CardAccountTypes' && account.accountType.group !== 'DepositAccountTypes'">
                <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                    currency="{{::account.currencySymbol}}" from-type="dashboard"
                    aditional-val="{{account.availableBalance|number:2}}" has-underline="true">
                </amount-formatter>
                <p translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Available balance</p>
            </div>
        </div>

        <div
            ng-if="::(account.accountType.group === 'SavingAccountTypes' || account.accountType.group === 'DepositAccountTypes') && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
            <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                currency="{{::account.currencySymbol}}" from-type="dashboard"
                aditional-val="{{account.balance|number:2}}" has-underline="true">
            </amount-formatter>
            <p translate="navigation.balance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
        </div>

        <div ng-if="::(account.accountType.group === 'SavingAccountTypes' || account.accountType.group === 'DepositAccountTypes') 
                    && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
             <div class="d-inline-block">
                <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                    class="float-left"
                    currency="{{::account.currencySymbol}}" from-type="dashboard"
                    aditional-val="{{account.availableBalance|number:2}}">
                </amount-formatter>
                <amount-formatter ng-if="dashboard.isNuN(account.balance2 )" amount="{{account.balance2|number:2}}"
                    currency="{{::account.currencySymbol2}}" custom-classes="{{'c-white'}}" from-type="dashboard"
                    class="float-left m-t-12"
                    is-secondary-value="true"
                    aditional-val="{{account.availableBalance2|number:2}}" has-bracket="true">
                </amount-formatter>
            </div>
            <div class="underline"></div>
            <p translate="navigation.balance" class="c-white balance-label m-t-5 m-b-0">Balance</p>
        </div>

        <div ng-if="::account.accountType.group === 'CardAccountTypes'">
            <amount-formatter ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
                amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                currency="{{::account.currencySymbol}}" from-type="dashboard"
                aditional-val="{{account.availableBalance|number:2}}" has-underline="true">
            </amount-formatter>

            <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" translate="accounts.accBalance"
                class="c-white balance-label m-t-5 m-b-0">Available balance</p>
            <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'"
                translate="accounts.balance" class="c-white balance-label m-t-5 m-b-0">Available balance</p>
        </div>

        <div ng-if="::account.accountType.group === 'CardAccountTypes' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
            <div class="d-inline-block">
                <amount-formatter amount="{{account.availableBalance|number:2}}"
                    class="float-left"
                    custom-classes="{{'c-white'}}" currency="{{::account.currencySymbol}}"
                    from-type="dashboard" aditional-val="{{account.availableBalance|number:2}}">
                </amount-formatter>
                <amount-formatter ng-if="dashboard.isNuN(account.availableBalance2 )" amount="{{account.availableBalance2|number:2}}"
                    class="float-left m-t-12"
                    currency="{{::account.currencySymbol2}}" custom-classes="{{'c-white'}}" from-type="dashboard"
                    is-secondary-value="true"
                    aditional-val="{{account.availableBalance2|number:2}}" has-bracket="true">
                </amount-formatter>
            </div>
            <div class="underline"></div>

            <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" translate="accounts.accAvailableBalance"
                class="c-white balance-label m-t-5 m-b-0">Available balance</p>
        </div>

    </div>

    <div class="account-item-widget app-red-card" ng-if="::account.accountType.group == 'LoanAccountType'"
        ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">

        <h4 class="acc-name c-white m-t-0 m-b-0"><b class="col-xs-10 p-0  no-wrap-text">{{::account.friendlyName}} </b>
            <span class="col-xs-2 p-0 t-a-r c-white" style="height: 30px;"><i class="c-white"
                    ng-class="{'icon icon-favourite favorite-account':account.isFavorite,'zmdi zmdi-star-outline':!account.isFavorite}"></i></span>
        </h4>
        <span ng-if="::account.iban" class="c-white f-14  no-wrap-text" fw-cm>{{::account.iban | formatIban}}</span>
        <span ng-if="::!account.iban" class="c-white f-14  no-wrap-text" fw-cm>{{::account.accountNumber}}</span>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}" currency="EUR"
                from-type="dashboard" aditional-val="{{account.loanAccount.loanAmount|number:2}}" has-underline="true">
            </amount-formatter>
            <p translate="navigation.loanBalance" class="c-white balance-label m-t-5 m-b-0">Loan amount</p>
        </div>
        <!-- ZA SRB I MNE -->
        <div
            ng-if="::(dashboard.countryCode !== 'hr' && loansOverview.countryCode !== 'hr') && (dashboard.countryCode !== 'sl' && loansOverview.countryCode !== 'sl')">
            <amount-formatter amount="{{account.balance|number:2}}" custom-classes="{{'c-white'}}"
                currency="{{::account.currencySymbol}}" from-type="dashboard"
                aditional-val="{{account.loanAccount.loanAmount|number:2}}" has-underline="true">
            </amount-formatter>
            <p translate="navigation.loanBalance" class="c-white balance-label m-t-5 m-b-0">Loan amount</p>
        </div>
        <!-- ZA CRO -->
        <div ng-if="::(dashboard.countryCode === 'hr' || loansOverview.countryCode === 'hr')">
            <div class="d-inline-block">
                    
                <amount-formatter ng-if="::!account.loanAccount.loanAmountCurrencySymbol"
                    class="float-left"
                    amount="{{account.loanAccount.loanAmount|number:2}}" custom-classes="{{'c-white'}}"
                    currency="{{::account.currencySymbol}}" from-type="dashboard"
                    aditional-val="{{account.balance|number:2}}">
                </amount-formatter>
                <amount-formatter ng-if="!account.loanAccount.loanAmountCurrencySymbol && account.balance2  >= 0"
                    class="float-left m-t-12"
                    amount="{{account.loanAccount.loanAmount|number:2}}" custom-classes="{{'c-white'}}"
                    currency="{{::account.currencySymbol2}}" from-type="dashboard" is-secondary-value="true"
                    aditional-val="{{account.balance2|number:2}}" has-bracket="true">
                </amount-formatter>
            </div>
            
            <div class="underline"></div>
            <div class="d-inline-block">
                <amount-formatter ng-if="account.loanAccount.loanAmountCurrencySymbol"
                    class="float-left"
                    amount="{{account.loanAccount.loanAmount|number:2}}" custom-classes="{{'c-white'}}"
                    currency="{{::account.loanAccount.loanAmountCurrencySymbol}}" from-type="dashboard"
                    aditional-val="{{account.balance|number:2}}">
                </amount-formatter>
                <amount-formatter ng-if="account.loanAccount.loanAmountCurrencySymbol && dashboard.isNuN(account.balance2 )"
                    class="float-left m-t-12"
                    amount="{{account.loanAccount.loanAmount|number:2}}" custom-classes="{{'c-white'}}"
                    currency="{{::account.loanAccount.loanAmountCurrencySymbol}}" is-secondary-value="true" from-type="dashboard"
                    aditional-val="{{account.balance2|number:2}}" has-bracket="true">
                </amount-formatter>
            </div>
            <div ng-if="account.balance2 === undefined" class="underline"></div>
            <p translate="dashboard.loanAmount" class="c-white balance-label m-t-5 m-b-0">Loan amount</p>
        </div>
    </div>
</a>