(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            // http://www.xe.com/ibancalculator/sample/?ibancountry=croatia
          session: {
            'expired': {
              title: '',
              subtitle: '',
              message: 'Seja je bila prekinjena zaradi ponovne prijave v Addiko Ebank. V kolikor to niste bili vi, nas nemudoma obvestite.',
              contact1: 'V Addiko EBank lahko ',
              contact2: 'vstopite le kot pooblaščeni uporabnik',
              contact3: '',
              contact1SLO: 'V Addiko EBank lahko',
              contact2SLO: 'vstopite le kot pooblaščeni uporabnik',
              wrongBrowser: 'Uporaba Addiko EBank in Addiko Business EBank je podprta v spletnih brskalnikih, ki so navedeni na naslednji povezavi:'
            },
            inactiveUser: 'Zaradi neaktivnosti vam je potekla seja. Potrebna je ponovna prijava'
          },
          common: {
            callCenter: '(klicni center)',
            callCenterWorkingHours1: '(klicni center)',
            callCenterWorkingHours2: ''
          },
          retail: {
            title: 'Občani'
          },
          corporate: {
            title: 'Podjetja'
          },
            core: {
                "filter": {
                  maxDateRange: "Maksimalna dolžina obdobja je eno leto."
                },
                "inputFields": {
                    IBAN: "9999 9999 9999 9999 9"
                },
                "moneyFormat": {
                    mask: "#.###.##0,00",
                    placeholder: "eg: 000.000,00"
                },
                "period": {
                    today: "Danes",
                    yesterday: "Včeraj",
                    thisWeek: "Teden dni",
                    thisMonth: "Mesec dni",
                    thisYear: "Leto dni",
                    all: "Vse",
                    allMessages: "Vsa sporočila",
                    last6Months: "Zadnjih 6 mesecev"
                },
              "requiredField": "Polje je obavezno.",
              "paymentOrder": "Nalog številka",
                "investOrder": "Investicija številka",
                "ebookOrder": "eKnjižica številka",
                "successDelete": "je uspešno izbrisan.",
                "investSuccessDelete": "je uspešno izbrisana.",
                "termDepositPayoutType": {
                    "2": "Poročanje po poteku",
                    "2e": "(obresti in glavnica hkrati)",
                    "16": "Poročanje po poteku",
                    "16e": "(pojasnilo: za depozit sklenjen npr. 15. v mesecu, je izplačilo obresti vsakega 15. v mesecu)",
                    "17": "Četrtletno izplačilo obresti",
                    "17e": "(pojasnilo: za depozit sklenjen npr. 15. v mesecu, je izplačilo obresti vsake tri mesece 15. v mesecu)",
                    "18": "Polletno izplačilo obresti",
                    "18e": "(pojasnilo: za depozit sklenjen npr. 15. v mesecu, je izplačilo obresti vsakih šest mesecev 15. v mesecu)",
                    "19": "Letno izplačilo obresti",
                    "19e": "(pojasnilo: za depozit sklenjen npr. 15. v mesecu, je izplačilo obresti vsako leto 15. v mesecu sklenitve)",
                    "3": "Izplačilo obresti po dospetju",
                    "3e": "(samo pri avtomatskem podaljševanju)"

                },
              "termDepositStatus": {
                "entered": "Oddan",
                "realised": "Izveden",
                "unrealised": "Neizveden",
                "cancelled": "Preklican",
                "receivednotexecuted":"V izvedbi"
              },
              "confirmation": "Reklamacija na transakcijo je bila oddana.",
              "confirmationSlo": "Reklamacija na transakcijo je bila oddana.",
              "error": "Napaka",
              sweetAlertTranslation: {
                yes: "Da",
                no: "Ne",
                areYouSure: "Ste prepričani?"
              },
              logoutModalTranslation: {
                logoutBtn: "Da",
                logoutMsg: "Ste prepričani, da se želite odjaviti?",
                "cancel":"Ne"
              },
              "sameAccounts": "Napaka: Ista računa!",
              paymentSignedStatus:{
                  "InProcessing":"V obdelavi"
              },
              "today":"Danes",
              "noAccounts": "Ni podatkov o računih",
              "notImplemented":"Not implemented yet!",
              datePicker: {
                "doneBtn":"Potrdi",
                "clearBtn":"Počisti"
              }
            }
        };

        $translateProvider
            .translations('sl', data);

    }
}());
