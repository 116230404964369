<!-- CONNECTED CARDS TRANSACTION FILTER html START -->
<div class="tml-filter-container tmlf-wide"
     stlye="overflow: hidden; margin: 15px">
  <!-- START Search text input -->
  <div class="inner-section left">
    <i class="icon icon-search icon-hc-2x m-l-5"></i>
  </div>
  <div class="inner-section center">
    <input type="text" class="search-input"
           placeholder="{{'timelineFilter.placeholder'|translate}}"
           ng-model="accounts.transactionCardsFilterOptions.genericSearch" ng-model-options="{ debounce: 300 }"
           ng-change="accounts.searchCards()">
  </div>
  <div  class="inner-section right pull-right">
    <i class="zmdi zmdi-tag-close pull-left zmdi-hc-2x m-r-0"
       ng-show="accounts.transactionCardsFilterOptions.genericSearch"
       ng-click="accounts.clearCardsSearch();">
    </i>
    <i class="icon icon-filter icon-hc-2x i-r-0 pull-right" style="z-index: 100000"
       ng-click="accounts.showCardsFilterOptionsWrapper = !accounts.showCardsFilterOptionsWrapper">
    </i>
  </div>
  <div class="tml-filter-selection"
       ng-class="{'opened': accounts.showCardsFilterOptionsWrapper,
       'h-430': (accounts.transactionCards.dateFromOpen || accounts.transactionCards.dateToOpen) && !(accounts.selectedAccount.accountType.group == 'CardAccountTypes' && accounts.countryCode === 'sl'),
       'h-510': ((accounts.transactionCards.dateFromOpen || accounts.transactionCards.dateToOpen) && accounts.selectedAccount.accountType.group == 'CardAccountTypes' && accounts.countryCode === 'sl')}"
       style="z-index: 6">
    <div class="tml-filter-type-selection-wrapper">
      <div ng-class="{'selected': accounts.transactionCardsFilterOptions.transactionType === undefined}" class="tmlf-type-item"
           ng-click="accounts.transactionCardsFilterOptions.transactionType = undefined"
           translate="timelineFilter.all"
           style="width: 33.333%">
      </div>
      <div ng-class="{'selected': accounts.transactionCardsFilterOptions.transactionType == 'Credit'}" class="tmlf-type-item"
           style="width: 33.333%"
           ng-click="accounts.transactionCardsFilterOptions.transactionType = 'Credit'"
           translate="timelineFilter.credit"></div>
      <div ng-class="{'selected': accounts.transactionCardsFilterOptions.transactionType == 'Debit'}" class="tmlf-type-item"
           style="width: 33.333%"
           ng-click="accounts.transactionCardsFilterOptions.transactionType = 'Debit'"
           translate="timelineFilter.debit"></div>
    </div>

    <span ng-if="(accounts.selectedAccount.accountType.group == 'CardAccountTypes' && accounts.countryCode === 'sl')">
    <div class="m-t-15" style="overflow:auto">
      <div class="col-xs-6 p-l-5 p-r-5">
        <div class="form-group m-b-0">
          <label class="f-12-s" translate="transactions.statementsFilter"></label>
          <select class="form-control-db"
                  ng-options="option | date:'shortDate' : '+0200' for option in accounts.statementDates"
                  ng-change="accounts.transactionCardsFilterOptions.dateFrom = undefined; accounts.transactionCardsFilterOptions.dateTo = undefined; accounts.transactionCards.transactionFilterYearDateRangeValid = true"
                  ng-model="accounts.transactionCardsFilterOptions.datePeriodStart">
          </select>
        </div>
      </div>
    </div>

    <!-- START: Row Date from and date to -->
    <div class="m-t-15 clearfix">
      <div class="col-xs-6 p-l-5 p-r-5">
        <div class="form-group m-b-0">
          <label class="f-12-s" translate="repaymentPlan.dateFrom">Date from</label>
          <div class="input-group">
            <input type="text" class="form-control-db"
                   ng-class="{'domp-error': (!accounts.transactionCardsFilterOptions.datePeriodStart && !accounts.transactionCardsFilterOptions.dateFrom)}"
                   ng-value="accounts.transactionCardsFilterOptions.dateFrom | date: 'shortDate' : '+0200'"
                   readonly>
            <span class="input-group-btn">
                  <button data-ng-click="accounts.transactionCards.dateFromOpen = !accounts.transactionCards.dateFromOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
          </div>
        </div>
      </div>
      <div class="col-xs-6 p-l-0 p-r-5">
        <div class="form-group m-b-0">
          <label class="f-12-s" translate="repaymentPlan.dateTo">Date to</label>
          <div class="input-group ">
            <input type="text" class="form-control-db"
                   ng-class="{'domp-error': (!accounts.transactionCardsFilterOptions.datePeriodStart && !accounts.transactionCardsFilterOptions.dateTo)}"
                   ng-value="accounts.transactionCardsFilterOptions.dateTo | date: 'shortDate' : '+0200'"
                   readonly>
            <span class="input-group-btn">
                  <button data-ng-click="accounts.transactionCards.dateToOpen = !accounts.transactionCards.dateToOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
          </div>
        </div>
      </div>
      <!-- Datepickers -->
      <div class="col-xs-6 p-l-0 p-r-5 transactions-date-pickers">
        <!-- Date from datepicker-->
        <div data-ng-model="accounts.transactionCardsFilterOptions.dateFrom"
             show-weeks="false"
             is-open="accounts.transactionCards.dateFromOpen"
             ng-change="accounts.transactionCards.onDateFromChange();accounts.transactionCards.clearStatementDate();"
             uib-datepicker-popup="shortDate"
             show-button-bar="false"
             required="">
        </div>
        <!-- Date to datepicker-->
        <div data-ng-model="accounts.transactionCardsFilterOptions.dateTo"
             show-weeks="false"
             is-open="accounts.transactionCards.dateToOpen"
             ng-change="accounts.transactionCards.onDateToChange();accounts.transactionCards.clearStatementDate();"
             date-disabled="accounts.transactionCards.datesToDisabled(date, mode)"
             show-button-bar="false"
             uib-datepicker-popup="shortDate">
        </div>
      </div>
    </div>
      <span class="p-l-5 p-t-5 c-red" ng-if="!accounts.transactionCards.transactionFilterYearDateRangeValid">
        <span translate="core.filter.maxDateRange"></span>
      </span>
    <!-- END: Row Date from and date to -->
    </span>
    <div class="m-t-15" style="overflow:auto">
      <div class="col-xs-5 p-l-5 p-r-5">
        <div class="form-group">
          <label class="f-12-s" translate="timelineFilter.amountFrom"></label>
          <input ng-keyup="$event.keyCode == 13 && accounts.applyFilter()" type="text" class="form-control-db"
                 ng-model="accounts.transactionCardsFilterOptions.amountGTE"
                 amount-input-mask maxlength="12"
                 placeholder="{{'accounts.accEg' | translate}}: 000.000,00" autocomplete="off">
        </div>
      </div>
      <div class="col-xs-4 p-l-0 p-r-5">
        <div class="form-group">
          <label class="f-12-s" translate="timelineFilter.amountTo"></label>
          <input ng-keyup="$event.keyCode == 13 && accounts.applyFilter()" type="text" class="form-control-db"
                 ng-model="accounts.transactionCardsFilterOptions.amountLTE"
                 amount-input-mask maxlength="12"
                 placeholder="{{'accounts.accEg' | translate}}: 000.000,00" autocomplete="off">
        </div>
      </div>
      <div class="col-xs-3 p-l-0 p-r-5 p-t-25">
        <select class="form-control-db"
                ng-options="option.symbol as option.symbol for option in accounts.currenciesList"
                ng-model="accounts.transactionCardsFilterOptions.currencySymbol">
        </select>
      </div>
    </div>
    <div class="m-b-15" style="overflow:auto">
      <div class="col-xs-6 t-a-l p-l-5">
        <button class="btn btn-primary app-btn-gray ng-scope" ng-click="accounts.resetCardsFilter();"
                translate="timelineFilter.reset">
        </button>
      </div>
      <div class="col-xs-6 t-a-r p-r-5">
        <button class="btn btn-default app-btn-blue w-100" ng-click="accounts.applyCardsFilter();"
                ng-disabled="!accounts.transactionCards.transactionFilterYearDateRangeValid"
                translate="timelineFilter.apply">
        </button>
      </div>
    </div>
  </div>
  <!-- END Detail filter expanded -->

</div>
<!-- CONNECTED CARDS TRANSACTION FILTER html END -->
