<div class="tml-filter-container tmlf-wide" id="searchFilterExpandedDiv">
  <div id="searchBtnLeftDiv" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="inner-section left">
    <i ng-switch-default class="icon icon-search i-p24 m-l-5"></i>
    <i ng-switch-when="sl" ng-click="sepaTrans.applyPaymentsFilter();" class="icon icon-search i-p24 m-l-5"></i>
  </div>
  <div id="searchBtnCenterDiv" class="inner-section center">
    <input id="searchTimelineInput" type="text" class="search-input"
           placeholder="{{'timelineFilter.placeholder'|translate}}"
           ng-model="sepaTrans.paymentSearch" ng-model-options="{debounce: 300}">
  </div>
  <div id="searchBtnRightDiv" class="inner-section right" ng-init="sepaTrans.showDeleteInputBtn = false">
    <i ng-show="!sepaTrans.showDeleteInputBtn" class="icon icon-filter i-p24 i-lh12 m-r-0" ng-click="sepaTrans.toggleFilterSelection();"></i>
    <i ng-show="sepaTrans.showDeleteInputBtn" class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0" ng-click="sepaTrans.paymentsFilter.purposeFTS = ''; sepaTrans.showDeleteInputBtn = false"></i>
  </div>
  <div class="tml-filter-selection" ng-class="{'opened': sepaTrans.searchFilterExpanded}">
    <div class="m-t-15" style="overflow:auto">
      <div class="col-xs-6 p-l-5 p-r-5">
        <div class="form-group">
          <label class="f-12-s" translate="timelineFilter.amountFrom"></label>
          <input ng-keyup="$event.keyCode == 13 && sepaTrans.applyPaymentsFilter()" type="text"
                 class="form-control-db"
                 ng-model="sepaTrans.sepaFilter.amountGTE"
                 amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00"
                 maxlength="12" autocomplete="off">
        </div>
      </div>
      <div class="col-xs-6 p-l-0 p-r-5">
        <div class="form-group">
          <label class="f-12-s" translate="timelineFilter.amountTo"></label>
          <input ng-keyup="$event.keyCode == 13 && sepaTrans.applyPaymentsFilter()" type="text"
                 class="form-control-db" ng-model="sepaTrans.sepaFilter.amountLTE"
                 amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" autocomplete="off">
        </div>
      </div>
      <!--<div class="col-xs-2 p-0 p-t-25">-->
        <!--<select name="" id="" class="form-control-db"-->
                <!--ng-options="option.symbol for option in sepaTrans.currenciesList"-->
                <!--ng-model="sepaTrans.currencySelectObject">-->
        <!--</select>-->
      <!--</div>-->
    </div>
    <div class="m-b-15" style="overflow:auto">
      <div class="col-xs-6 t-a-l p-l-5">
        <button class="btn btn-primary app-btn-gray" ng-click="sepaTrans.resetPaymentsFilter();" translate="timelineFilter.reset"></button>
      </div>
      <div class="col-xs-6 t-a-r p-r-5">
        <button class="btn btn-default app-btn-blue w-100" ng-click="sepaTrans.applyPaymentsFilter();" translate="timelineFilter.apply"></button>
      </div>
    </div>
  </div>
</div>
