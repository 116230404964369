(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name blockade.factory:Blockade
   *
   * @description
   *
   */
  angular
    .module('blockade')
    .factory('BlockadeService', BlockadeService);

  function BlockadeService(EndpointsService, $http, $q) {
    var BlockadeServiceBase = {};

    BlockadeServiceBase.validateBlockadeOrder = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.blockadeOrder_validate, data)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    BlockadeServiceBase.createAddikoEBankOrMobileBlockade = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.blockadeOrder, data)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    return BlockadeServiceBase;
  }
}());
