(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:CurrencyService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('CurrencyService', CurrencyService);

  function CurrencyService(EndpointsService, $q, $http, $log) {
    var CurrencyServiceBase = {};

    CurrencyServiceBase.getCurrencyPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.currencies, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          })
          .catch(function (msg, code) {
            deferred.reject(msg.data);
          })
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return CurrencyServiceBase;
  }
}());
