(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name viber.header.directive:viberHeader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="viber.header">
       <file name="index.html">
        <viber-header></viber-header>
       </file>
     </example>
   *
   */
  angular
    .module('viber')
    .directive('viberHeader', viberHeader);

  function viberHeader() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'viber/header/viber-header-directive.tpl.html',
      replace: false,
      controllerAs: 'viberHeader',
      controller: function ($rootScope, $localStorage) {
        var vm = this;
        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.currentLanguage = $localStorage.appLanguage;
        $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
          console.log("currentLang VIB direct: ", lang);
          vm.currentLanguage = lang.langCode;
        });
      }
    };
  }
}());
