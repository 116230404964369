(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EInvoicesModalCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EInvoicesModalCtrl', EInvoicesModalCtrl);

  function EInvoicesModalCtrl(eInvoice, $uibModalInstance, $log, $state, HelperService, PaymentsService, PaymentTypesService, $filter, NotificationService) {
    var vm = this;
    vm.paymentDateOption = {};
    vm.eInvoiceObject = eInvoice;
    vm.datePickerOpened = false;
    vm.signSuccess = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.closeModal = function () {
      $uibModalInstance.close();
    };
    vm.goBack = function () {
      vm.signSuccess = false;
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.goToEInvoiceDetail = function () {
      vm.closeModal();
      $state.go('eInvoices.detail', {
        eInvoiceId: vm.eInvoiceObject.id
      });
    };


    vm.tempDompObj = {
      fromAccountSelect: {},
      //fromAccountSelect: 'HR6725000093200408415',
      amount: vm.eInvoiceObject.amount,
      dateInput: new Date(),
      purposeCodeSelect: 'ACCT',
      toAccountInput: 'HR2325000091501135368',
      referenceCodeInput: 'HRK',
      referenceModel: 'HR',
      referenceNumber: '99',
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };
    vm.fromAccountNumber = {};

    vm.getPaymentTypeGroup = function () {
      PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
        vm.paymentTypeGroupDomestic = response;
          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);

          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {

            _.forEach(response.result[0].debitAccountList, function(value){
              value.optionValue = value.accountDescription + ' - ' + value.accountNumber;
            });

            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.error('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.init = function () {
      vm.getPaymentTypeGroup();

    };
    vm.init();

    vm.paymentObject = {};
    var createPaymentObject = function () {

      vm.paymentObject.domesticPayment = {};
      vm.paymentObject.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      vm.paymentObject.fromAccountNumber = vm.tempDompObj.fromAccountSelect.iban;
      vm.paymentObject.toAccountNumber = vm.tempDompObj.toAccountInput;
      vm.paymentObject.currencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
      vm.paymentObject.purpose = vm.eInvoiceObject.purpose;
      vm.paymentObject.amount = parseFloat(vm.tempDompObj.amount);
      vm.paymentObject.paymentTypeGroup = 'DomesticPayment';
      vm.paymentObject.purposeCodeDPS = vm.tempDompObj.purposeCodeSelect;
      vm.paymentObject.domesticPayment.receiverName = vm.eInvoiceObject.creditorName;
      vm.paymentObject.domesticPayment.receiverAddress1 = vm.eInvoiceObject.creditorAddress;
      vm.paymentObject.domesticPayment.receiverCity = vm.eInvoiceObject.creditorCity;
      vm.paymentObject.domesticPayment.referenceTo = vm.tempDompObj.referenceModel;
      vm.paymentObject.domesticPayment.referenceFrom = vm.tempDompObj.referenceNumber;

      if(vm.paymentDateOption.val==1 || vm.paymentDateOption.val==3) {
        vm.paymentObject.dueDate = vm.tempDompObj.dateInput;
      } else {
        vm.paymentObject.dueDate = vm.eInvoiceObject.valueDate;
      }

      return vm.paymentObject;

    };

    vm.paymentResponse = {};
    vm.executePayment = function () {

      PaymentsService.insertPayment(createPaymentObject())
        .then(function (response) {
        vm.paymentResponse = response;
        vm.signSuccess = true;
      })
        .catch(function (error) {
        errorFcn(error);
      });

      var errorFcn = function (error) {
        if (error && error.resultList && error.resultList.length > 0) {
          NotificationService.showMessage(error, 'error');
        }
      };

      $log.debug("Temporary Domestic payment object: ", vm.tempDompObj);
    };

    vm.signPayment = function () {
      PaymentsService.signPayment(vm.paymentResponse.id)
        .then(function (response) {
       vm.closeModal();
        swal("Success!");
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };
  }
}());
