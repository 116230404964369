<div class="p-l-15 p-r-15 f-14" ng-hide="paymentTemplatesModal.paymentResponse">
  <div class="row ptm-header p-10 bottom-border">
    <b class="f-18">{{paymentTemplatesModal.paymentObject.name}}</b>
    <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst" data-ng-click="paymentTemplatesModal.closeModal();"></i>
  </div>
  <div class="row p-10 bottom-border">
    <div class="col-sm-4 ptm-left-section">

      <div class="m-b-5">
        <label class="ptm-label" translate="paymentTemplates.payingFrom"></label>
        <p class="ptm-acc-info wrap-text" title="{{paymentTemplatesModal.paymentObject.fromAccount}}">
          {{paymentTemplatesModal.paymentObject.fromAccount}}
        </p>
      </div>

      <div class="m-b-5">
        <label class="ptm-label" translate="paymentTemplates.payingTo"></label>
        <p class="ptm-acc-info wrap-text" title="{{paymentTemplatesModal.paymentObject.toAccountNumber}}">
          {{paymentTemplatesModal.paymentObject.toAccountNumber}}
        </p>
      </div>

    </div>
    <div class="col-sm-8 ptm-right-section">
      <form name="paymentTemplatesModal.templateForm">
        <div class="m-b-10">
          <span class="m-b-5 ptm-label" translate="paymentTemplates.howMuch">How much?</span>
          <div class="input-group">
            <input type="text" class="form-control" data-input-mask="'core.moneyFormat.mask'" data-input-mask-options="{reverse: true,optional: true}" placeholder="" maxlength="22" autocomplete="off" style="text-align: right;" data-ng-model="paymentTemplatesModal.paymentObject.amount">
            <div class="input-group-addon qp-amount">{{paymentTemplatesModal.paymentObject.accountCurrency}}</div>
          </div>
        </div>
        <div class="m-b-10">
          <span class="m-b-5 ptm-label" translate="paymentTemplates.description"></span>
          <input name="description" class="form-control" rows="3" data-ng-model="paymentTemplatesModal.paymentObject.purpose" maxlength="134" required>
          <span class="error c-red" ng-show="paymentTemplatesModal.templateForm.description.$error.required" translate="paymentTemplates.descriptionError"></span>
        </div>
        <div class="m-b-10">
          <span class="m-b-5 ptm-label" translate="paymentTemplates.when"></span>
          <div>
            <input class="form-control" type="text" data-ng-click="paymentTemplatesModal.openDatePicker()"
                   uib-datepicker-popup="shortDate"
                   data-ng-model="paymentTemplatesModal.paymentObject.dueDate"
                   show-weeks="false" is-open="paymentTemplatesModal.datePickerOpened"
                   current-text="{{'core.period.today' | translate}}"
                   clear-text="{{'core.datePicker.clearBtn' | translate}}"
                   close-text="{{'core.datePicker.doneBtn' | translate}}"
                   datepicker-options="paymentTemplatesModal.dateOptions">
            <span ng-hide="paymentTemplatesModal.validDate" class="c-red" translate="paymentTemplates.invalidDate"></span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row p-10 ptm-footer">
    <a href="" class="clickable-link">
      <span translate="paymentTemplates.showFullPaymentForm" data-ng-click="paymentTemplatesModal.navigateToFullPaymentsForm();"></span>
    </a>
    <button ng-hide="paymentTemplatesModal.paymentObject.forEdit == true" class="btn btn-default app-btn-blue pull-right ptm-pay-btn m-l-10" data-ng-click="paymentTemplatesModal.executePayment();" translate="paymentTemplates.pay"></button>
    <button class="btn btn-default app-btn-blue ptm-pay-btn pull-right m-l-10" data-ng-click="paymentTemplatesModal.saveTemplate();" translate="paymentTemplates.save"></button>
    <button class="btn btn-default app-btn-blue ptm-pay-btn pull-right" data-ng-click="paymentTemplatesModal.deleteTemplate();" translate="paymentTemplates.delete"></button>
  </div>
</div>

<!-- Signed document info -->
<div ng-if="paymentTemplatesModal.paymentResponse && !paymentTemplatesModal.signSuccess" class="f-14">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="row ptm-header p-l-10" colspan="2">
          <b class="f-18" translate="paymentTemplates.signMsg"></b>
          <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst eim-header-btn-close" data-ng-click="paymentTemplatesModal.closeModal();"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr ng-if="paymentTemplatesModal.paymentResponse.fromAccountNumber">
        <td class="bold-font" translate="paymentTemplates.fromAccount"></td>
        <td>{{paymentTemplatesModal.paymentObject.fromAccountNumber}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.toAccountNumber">
        <td class="bold-font" translate="paymentTemplates.toAccount"></td>
        <td>{{paymentTemplatesModal.paymentResponse.toAccountNumber}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.receiverName">
        <td class="bold-font" translate="paymentTemplates.receiver"></td>
        <td>{{paymentTemplatesModal.paymentResponse.receiverName}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.receiverAddress1 && paymentTemplatesModal.paymentResponse.receiverCity">
        <td class="bold-font" translate="paymentTemplates.receiverAddress"></td>
        <td>{{paymentTemplatesModal.paymentResponse.receiverAddress1 + ', ' + paymentTemplatesModal.paymentResponse.receiverCity}}
        </td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.amount">
        <td class="bold-font" translate="paymentTemplates.amount"></td>
        <td>{{paymentTemplatesModal.paymentResponse.amount + ' ' + paymentTemplatesModal.paymentResponse.currencySymbol}}
        </td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.purpose">
        <td class="bold-font" translate="paymentTemplates.description"></td>
        <td>{{paymentTemplatesModal.paymentResponse.purpose}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.status">
        <td class="bold-font" translate="paymentTemplates.status"></td>
        <td>{{paymentTemplatesModal.paymentResponse.status}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.intermediateBankName">
        <td class="bold-font" translate="paymentTemplates.bankName"></td>
        <td>{{paymentTemplatesModal.paymentResponse.intermediateBankName}}</td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.intermediateBankAddress">
        <td class="bold-font" translate="paymentTemplates.bankAddress"></td>
        <td>{{paymentTemplatesModal.paymentResponse.intermediateBankAddress + ', ' + paymentTemplatesModal.paymentResponse.intermediateBankAddress2}}
        </td>
      </tr>
      <tr ng-if="paymentTemplatesModal.paymentResponse.dueDate">
        <td class="bold-font" translate="paymentTemplates.dueDate"></td>
        <td>{{paymentTemplatesModal.paymentResponse.dueDate | date:'shortDate' : '+0200'}}</td>
      </tr>
      <tr class="row p-10 ptm-footer">
        <td class="ptm-btn">
          <!--<button class="btn btn-default app-btn-blue pull-right ptm-pay-btn" data-ng-click="paymentTemplatesModal.signPayment();" translate="paymentTemplates.signPayment"></button>-->
          <button class="btn btn-default app-btn-blue pull-right ptm-pay-btn" data-ui-sref="payments.sign({paymentId: paymentTemplatesModal.paymentResponse.id})" translate="paymentTemplates.signPayment"></button>
          <button class="btn btn-default app-btn-blue pull-right ptm-pay-btn m-r-15" data-ng-click="paymentTemplatesModal.closeModal();" translate="paymentTemplates.cancel"></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>


<!-- Save as template -->
<div ng-if="paymentTemplatesModal.signSuccess">
  <div class="row ptm-header p-10 bottom-border">
    <b class="f-18" translate="paymentTemplates.saveTemplate"></b>
    <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst eim-header-btn-close" data-ng-click="paymentTemplatesModal.closeModal();"></i>
  </div>
  <div class="row">
    <payment-final-step payment="paymentTemplatesModal.paymentResponse"
                        close="paymentTemplatesModal.closeModal();"
                        is-modal="true"
                        template-name="paymentTemplatesModal.paymentObject.name"
                        template-id="paymentTemplatesModal.paymentObject.id">
    </payment-final-step>
  </div>
</div>
