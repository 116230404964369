(function () {
  'use strict';

  angular
    .module('accounts')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('accounts', {
        url: '/accounts/{accountId}',
        templateUrl: 'accounts/accounts.tpl.html',
        controller: 'AccountsCtrl',
        controllerAs: 'accounts'
      })
      .state('accountsOverview', {
        url: '/accounts-overview',
        views: {
        '@': {
          templateUrl: 'accounts/accounts-overview.tpl.html',
          controller: 'AccountsOverviewCtrl',
          controllerAs: 'accountsOverview'
          // resolve: {
          //   currentCountry: function (ConfigEndpointService) {
          //     return ConfigEndpointService.getDefaultData();
          //   }
          // }
        }
      }
      })
      .state('depositAccountsOverview', {
        url: '/deposit-accounts-overview',
        views: {
        '@': {
          templateUrl: 'accounts/deposit-accounts-overview.tpl.html',
          controller: 'DepositAccountsOverviewCtrl',
          controllerAs: 'depositAccountsOverview'
          // resolve: {
          //   currentCountry: function (ConfigEndpointService) {
          //     return ConfigEndpointService.getDefaultData();
          //   }
          // }
        }
      }
      })
      .state('loansOverview', {
        url: '/loans-overview',
        views: {
        '@': {
          templateUrl: 'accounts/loans-overview.tpl.html',
          controller: 'LoansOverviewCtrl',
          controllerAs: 'loansOverview'
        }
      }
      })
      .state('newEbookNew', {
        url: '/new-ebook/',
        params: {
        myParam:null
      },
        views: {
        '@': {
          templateUrl: 'accounts/new-e-book.tpl.html',
          //controller: 'NewEBookCtrl',
          //controllerAs: 'newEBook'
          controller: 'NewEBookSavingsDividerCtrl',
          controllerAs: 'newEBookSavingsDivider'
        }
      }
      }).state('newEbook', {
        url: '/new-ebook/:accountId',
        params: {
        myParam:null
      },
        views: {
        '@': {
          templateUrl: 'accounts/new-e-book.tpl.html',
          //controller: 'NewEBookCtrl',
          //controllerAs: 'newEBook'
          controller: 'NewEBookSavingsDividerCtrl',
          controllerAs: 'newEBookSavingsDivider'
        }
      }
      })
      .state('closeEbook', {
        url: '/close-ebook',
        templateUrl: 'accounts/close-e-book.tpl.html',
        controller: 'CloseEBookCtrl',
        controllerAs: 'ebookAccount',
        params: {
        accountId: null
      },
        resolve: {
        authorize: function ($q, $location, $state, $stateParams) {
          console.log($state);
          console.log($stateParams);
          if (!$stateParams.accountId) {
            $location.url('/deposit-accounts-overview');
            // return $q.reject("Not Authorized");
          }
        }
      }
      })
      .state('cashWithdrawalPage', {
        abstract: true,
        url: '/cash-withdrawal/:accountId',
        controller: 'CashWithdrawalCtrl',
        controllerAs: 'cashWithdrawal',
        templateUrl: 'accounts/cash-withdrawal.tpl.html',
        resolve: {
        isCroatia: function ($q, $rootScope, $location) {
          // This is not safe! Validate this from a call to the server!
          var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          if (defaultData.APILocation !== 'hr' && defaultData.APILocation !== 'sl') {
            $location.url('/accounts-overview');
          }
        }
      }
      })
      .state('cashWithdrawalPage.form', {
        url: '/form',
        templateUrl: 'accounts/cash-withdrawal-form.tpl.html'
      })
      .state('cashWithdrawalPage.resume', {
        url: '/resume',
        templateUrl: 'accounts/cash-withdrawal-resume.tpl.html',
        params: {
        editMode: false
      },
        resolve: {
        authorize: function ($location, $stateParams) {
          // Forbid user navigation from "URL"
          if (!$stateParams.editMode) {
            $location.url('/cash-withdrawal/' + $stateParams.accountId + '/form');
          }
        }
      }
      })
      // related to accounts transaction division (for BiH environment)
      .state('transactionDivison', {
        abstract: true,
        url: '/division',
        controller: 'TransactionDivisionCtrl',
        controllerAs: 'transactionDivision',
        templateUrl: 'accounts/transaction-division-base.tpl.html',
        resolve: {
          checkCountry: function ($q, $rootScope, $location, ConfigEndpointService) {
            // transaction divisions are relevant only for BiH, SRB, MNE environmentS
            ConfigEndpointService.getDefaultData()
              .then(function (response) {
                if (response && response.APILocation === 'sl') {
                  $location.url('/dashboard');
                  return $q.reject();
                }
              });
          }
        }
      })
      .state('transactionDivison.create', {
        url: '/create',
        params: {
          transactionId: null,
          reservationId: null,
          description: null,
          card: null,
          accountId: null,
          accountTypeId: null,
          currency: null
        },
        templateUrl: 'accounts/transaction-division.tpl.html'
      })
      .state('transactionDivison.createId', {
        url: '/create',
        params: {
          transactionId: null,
          reservationId: null,
          description: null,
          card: null,
          accountId: null,
          accountTypeId: null,
          currency: null
        },
        templateUrl: 'accounts/transaction-division.tpl.html'
      })
      .state('transactionDivison.resumeId', {
        url: '/{transactionDivisionId}/resume',
        templateUrl: 'accounts/transaction-division-resume.tpl.html',
        params: {
          accountId: null,
          transactionDivisionId: null,
          resume: false
        },
        resolve: {
          authorize: function ($location, $stateParams) {
            if (!$stateParams.transactionDivisionId) {
              $location.url('/accounts/overview');
            }
          }
        }
      })
      .state('transactionDivison.resume', {
        url: '/resume',
        templateUrl: 'accounts/transaction-division-resume.tpl.html',
        params: {
          accountId: null,
          resume: false
        },
        resolve: {
          authorize: function ($location, $stateParams) {
            if (!$stateParams.resume) {
              $location.url('/division/create');
            }
          }
        }
      })
      
      .state('transactionDivison.finalId', {
        url: '/{transactionDivisionId}/final',
        templateUrl: 'accounts/transaction-division-final.tpl.html',
        params: {
          accountId: null,
          transactionDivisionId: null,
          final: false
        },
        resolve: {
          authorize: function ($location, $stateParams) {
            if (!$stateParams.transactionDivisionId) {
              $location.url('/accounts/overview');
            }
          }
        }
      })
      .state('transactionDivison.final', {
        url: '/final',
        templateUrl: 'accounts/transaction-division-final.tpl.html',
        params: {
          accountId: null,
          final: false
        },
        resolve: {
          authorize: function ($location, $stateParams) {
            if (!$stateParams.final) {
              $location.url('/division/create');
            }
          }
        }
      })
      .state('changeWithdrawal', {
        url: '/change-withdrawal/:accountId',
        controller: 'ChangeWithdrawalLimitCtrl',
        controllerAs: 'changeWithdrawal',
        templateUrl: 'accounts/change-withdrawal-limit.tpl.html'
      })
      .state('masterCardNew', {
        url: '/master-card-new',
        controller: 'MasterCardNewCtrl',
        controllerAs: 'masterCard',
        templateUrl: 'accounts/master-card-new.tpl.html'
      })
      .state('changeATMWithdrawal', {
        url: '/change-atm-withdrawal/:accountId',
        controller: 'ChangeAtmWithdrawalLimitCtrl',
        controllerAs: 'changeATM',
        templateUrl: 'accounts/change-atm-withdrawal-limit.tpl.html'
      })
      .state('reorderPinAccounts', {
        url: '/reorder-pin-accounts/:accountId',
        controller: 'ReorderPinAccountsCtrl',
        controllerAs: 'reorderPinAccounts',
        templateUrl: 'accounts/reorder-pin-accounts.tpl.html'
      })
      .state('masterCardOrder', {
        url: '/master-card-order/:accountId',
        params: {
          cardId: null
        },
        controller: 'MasterCardOrderLimitCtrl',
        controllerAs: 'masterCardOrder',
        templateUrl: 'accounts/master-card-order-limit.tpl.html'
      })
      .state('reorderPinMasterCard', {
        url: '/reorder-pin-master-card/:accountId',
        params: {
          cardId: null
        },
        controller: 'ReorderPinMasterCardCtrl',
        controllerAs: 'reorderPinMasterCard',
        templateUrl: 'accounts/reorder-pin-master-card.tpl.html'
      });

  }
}());
