(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name employeePayroll.controller:EmployeePayrollCtrl
   *
   * @description
   *
   */
  angular
    .module('employeePayroll')
    .controller('EmployeePayrollCtrl', EmployeePayrollCtrl);

  function EmployeePayrollCtrl($translate,HelperService, $log, EmployeePayrollService) {
    var vm = this;
    vm.ctrlName = 'EmployeePayrollCtrl';

    vm.timelineFilter = {
      page: 0,
      amountGTE: null,
      amountLTE: null,
      orderBy: 'dateCreated desc',
      pageSize: 10
    };
    vm.recompile = {
      timeline: true
    };
    vm.timelineListGrouped = {};


    vm.loadMoreTimelineItemsGrouped = function () {
      vm.getTimelineItemsGrouped(false);

    };

    vm.getTimelineItemsGrouped = function (reset) {

      if (reset) {
        vm.timelineFilter.page = 0;
      } else {
        vm.timelineFilter.page += 1;
      }


      EmployeePayrollService.getEmployeePayrolls(vm.timelineFilter)
        .then(function (response) {
          vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.timelineListGrouped,
            newData: response,
            groupFunction: function (item) {

              var dateYear = item.fileYear;
              item.customDate = new Date(item.fileMonth+"-01-"+item.fileYear);
              // If we want to group only by date component, without hours
              //date.setHours(0, 0, 0, 0);
              return dateYear;
            },
            virtualRowFunction: function (e) {
              var year = new Date(e).getFullYear();
              var virtualRow = {
                isVirtual: true,
                date: e,
                year: year
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: true
          });

          /*
          var todayDateRange = HelperService.getTodayWithoutOffset();
          var yesterdayDateRange = HelperService.getYesterdayWithoutOffset();


          _.forEach(vm.timelineListGrouped.result, function (item) {
            item.dateValue = new Date(item.date);
            if (item.dateValue >= todayDateRange.firstDay && item.dateValue <= todayDateRange.lastDay) {
              item.showText = true;
              item.dateLabel = 'core.period.today';
            }
            else if (item.dateValue >= yesterdayDateRange.firstDay && item.dateValue < yesterdayDateRange.lastDay) {
              item.showText = true;
              item.dateLabel = 'core.period.yesterday';
            }
            else {
              item.showDate = true;
            }
          });
          */
          $log.debug("groupedEmployeePayrollTimeline", vm.timelineListGrouped);
          //alert(2);
          vm.recompile.timeline = true;
        }).catch(function (error) {
          $log.error(error);
        });
    };

    vm.downloadPdf= function (id) {
      EmployeePayrollService.downloadEmployeePdf(id);
    };
    vm.init = function () {
      vm.getTimelineItemsGrouped(true);
    };

    vm.init();
  }
} ());
