(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      navigation: {
        "dashboard": "Početna strana",
        "accountsAndCards": "Računi i kartice",
        "accounts": "Računi",
        "payments": "Plaćanja",
        "deposit": "Štednja",
        "loans": "Krediti",
        "investments": "Investicije",
        "settings": "Postavke",
        "messages": "Poruke",
        "hypoClub": "Addiko Club",
        "eTrade": "Addiko ETrade",
        "paymentsAndTransfers" :"Pregled plaćanja i prijenosa/prenosa",
        "domesticPayment": "Opći/opšti nalog",
        "transferToMyAccount": "Prijenos/Prenos na moj račun",
        "internationalPayment": "Devizni nalog",
        "exchangeOffice" :"Mjenjačnica",
        "exchangeRates" :"Tečajna/kursna lista",
        "standingOrders": "Trajni nalog",
        "eInvoices": "eRačuni",
        "paymentsList": "Pregled naloga",
        "paymentsReturn": "Povrat priljeva/priliva",
        "directDebitsList": "Lista SEPA izravnih dugovanja",
        "myTemplatesList": "Uzorci",
        "allAccountsOverview": "Pregled računa i kartica",
        "allDepositOverview": "Sve štednje",
        "allDeposits": "Štednja",
        "allDepositOverviewSerbMne": "Oročenja",
        "loansOverview": "Pregled kredita",
        "investmentsOverview": "Pregled investicija",
        "availableBalance": "Raspoloživi iznos",
        "loanBalance": "Stanje kredita",
        "newMessages": "Nove poruke: ",
        "viberActivationTxt": "Potvrdite aktivaciju Vibera?",
        "allowedLoan":"Odobreni iznos",
        "balance": "Stanje računa",
        "newOrder":"Kupnja/Kupovina udjela",
        "viberBanking":"Addiko Chat Banking"
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());
