(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:replaceSuskavciWithNormal
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="core">
   <file name="index.html">
   <replace-suskavci-with-normal></replace-suskavci-with-normal>
   </file>
   </example>
   *
   */
  angular
    .module('core')
    .directive('replaceSpecificChars', replaceSuskavciWithNormal);

  function replaceSuskavciWithNormal() {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, modelCtrl) {
        var replaceSuskavci = function (inputValue) {
          if (inputValue == undefined) inputValue = '';
          var capitalized = inputValue.replace(/Č|č|Ć|ć|Đ|đ|Ž|ž|Š|š/gi, function (x) {
            var replaced;
            if (x == "Č" || x == "Ć") {
              replaced = "C";
            } else if (x == "č" || x == "ć") {
              replaced = "c"
            } else if (x == "Š") {
              replaced = "S"
            } else if (x == "š") {
              replaced = "s";
            } else if (x == "Đ") {
              replaced = "D"
            } else if (x == "đ") {
              replaced = "d"
            } else if (x == "Ž") {
              replaced = "Z"
            } else if (x == "ž") {
              replaced = "z"
            }
            return replaced;
          });
          // var capitalized = inputValue.toUpperCase();
          if (capitalized !== inputValue) {
            modelCtrl.$setViewValue(capitalized);
            modelCtrl.$render();
          }
          return capitalized;
        };
        modelCtrl.$parsers.push(replaceSuskavci);
        replaceSuskavci(scope[attrs.ngModel]); // capitalize initial value
      }
    };
  }
}());
