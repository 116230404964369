(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:SavingsSloCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('SavingsSloCtrl', SavingsSloCtrl);

  function SavingsSloCtrl($filter) {
    var vm = this;

    vm.currencySymbol = "EUR";

    vm.vrstaVracevanja = [
      {value: "0", name:"ADDIKO VARČEVALNI RAČUN 5 DNI" },
      {value: "1", name:"ADDIKO VARČEVANJE PLUS"}
    ];
    vm.durationInMonths = [
      {value: "12", name:"12" },
      {value: "24", name:"24"}
    ];
    vm.standingOrderDays = [
      {value: "4", name:"4" },
      {value: "8", name:"8."}
    ];


    vm.vrstaVracevanjaSelect = vm.vrstaVracevanja[0];
    vm.durationInMonthsSelect = vm.durationInMonths[0];
    vm.standingOrderDaysSelect = vm.standingOrderDays[0];
    vm.maturityDate = $filter('date')(new Date(), 'shortDate');

    vm.realInterestRate = "2.33%";
    vm.totalInterestRate = "2.33%";



  }
}());
