(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accounts.factory:TransactionDivisionService
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .factory('TransactionDivisionService', TransactionDivisionService);

  function TransactionDivisionService($http, $q, EndpointsService) {
    var TransactionDivisionServiceBase = {};
    TransactionDivisionServiceBase.getById = function (id){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.transactionDivisions_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
    TransactionDivisionServiceBase.getPage = function (params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.transactionDivisions, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
    TransactionDivisionServiceBase.isTransactionDivisionAllowed = function (params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.transactionDivisions_isTransactionDivisionAllowed, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
  
    TransactionDivisionServiceBase.insertTransaction = function (transactionObj) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.transactionDivisions, transactionObj)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
    TransactionDivisionServiceBase.updateTransaction = function (id, transactionObj) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.transactionDivisions_id.replace('{id}', id), transactionObj)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
    TransactionDivisionServiceBase.deleteTransaction = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.delete(response.links.transactionDivisions_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };
    TransactionDivisionServiceBase.signTransaction = function (transactionDivisionObj) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.transactionDivisions_action_sign, transactionDivisionObj)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    return TransactionDivisionServiceBase;
  }
}());
