<section id="main">
  <div class="container-fluid">
    <div class="row">
      <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">

        <h2 translate="sepa.header_CRO" class="f-28"></h2>

      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-lg-6 m-b-20">
        <div class="account-item-add-message app-dblue-card" ng-click="vm.addNewSetting()">
          <div class="round-btn-container2">
            <div class="round-btn-m">
              <i class="icon icon-add icon-add-msg bold-font f-24"></i>
            </div>
            <div class="round-btn-label-msg">
              <h3 class="round-btn-label-msg f-20">{{'sepa.addNew'| translate}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NEED NEW TIMELINE !!!!!???????  -->

    <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
      <div class="po-type-container" ng-switch-default>
        <div ng-class="{'selected': vm.overviewTabType === 'overview'}"
             ng-click="vm.overviewTabType = 'overview'"
             class="po-type-tab no-wrap-text"
             style="width: 33.33%"
             translate="sepa.sepaSettingsRestrictions">
          Pregled ograničenja i zabrana
        </div>
        <div ng-class="{'selected': vm.overviewTabType === 'history'}"
             ng-click="vm.overviewTabType = 'history'"
             class="po-type-tab no-wrap-text"
             style="width: 33.33%"
             translate="sepa.sepaSettingsHistory">
          Povijest ograničenja i zabrana
        </div>
        <div ng-class="{'selected': vm.overviewTabType === 'requests'}"
             ng-click="vm.overviewTabType = 'requests'"
             class="po-type-tab no-wrap-text"
             style="width: 33.33%"
             translate="sepa.sepaRequestsOverview">
          Pregled zahtjeva
        </div>
      </div>
    </div>

    <!-- =================== -->
    <!--Pregled ograničenja-->
    <!-- =================== -->
    <div class="timeline-row col-sm-12 no-padding" ng-if="vm.overviewTabType == 'overview'">
      <div class="t-a-c p-10 no-data-image" ng-if="vm.sepaDDSettings.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card m-b-30" ng-hide="vm.sepaDDSettings.length == 0">
        <ul class="hypo-timeline sepa-timeline" kcd-recompile="vm.timelineListGrouped">
          <li ng-class="{'time-label': item.isVirtual}" ng-repeat="item in vm.sepaDDSettings">
            <h4 class="timeline-separator-title bold-font" style="background: none; border: none; font-size: none"
                ng-if="item.isVirtual === true">{{item.description}}</h4>
            <i class="zmdi zmdi-sepa icon i-p24 icon-settings"
               ng-class="{'sepa-cro-timeline-icon-empty' : item.typeId === 'BLOCK_ALL', 'sepa-cro-timeline-icon': item.typeId != 'BLOCK_ALL'}"
               ng-if="!item.isVirtual"></i>
            <div ng-if="!item.isVirtual" class="hypo-timeline-item payment-item" style="height: 100px">
              <!-- if clickable ng-click="item.selected = !item.selected;" ng-class="{'opened': item.selected}"> -->
              <div class="hypo-timeline-body">
                <div class="col-md-6 col-sm-12 m-b-5 clearfix">
                  <!-- Hide receiver name and id for BLOCK_ALL (not exist) -->
                  <h4 class="m-b-0 m-t-10">
                    <div class="m-t-5">
                      <b>{{item.accountIBAN}}</b>
                    </div>
                    <div ng-if="item.typeId != 'BLOCK_ALL'" class="m-t-5">
                      <label class="m-b-0">{{item.receiverName}}</label>
                    </div>
                    <div ng-if="item.typeId != 'BLOCK_ALL'" class="m-t-5">
                      <label class="m-b-0">ID: {{item.receiverId}}</label>
                    </div>
                  </h4>
                </div>
                <div class="col-md-3 col-sm-12">
                  <h4 class="m-b-0 m-t-10">
                    <div class="m-r-10" ng-if="item.hasOwnProperty('maxAmount')">{{item.maxAmount|number:2}} {{
                      vm.model.fromAccountSelect.currencySymbol}}
                    </div>
                  </h4>
                </div>
                <div class="col-md-3 col-sm-12 m-b-5 clearfix">
                  <b class="m-r-10 t-a-r f-18">
                    <span ng-if="item.activated" class="underline"
                          ng-click="vm.deactivate(item)"
                          translate="sepa.isActive">
                    </span>
                    <span ng-if="!item.activated"
                          translate="sepa.notActive">
                    </span>
                  </b>
                  <br>
                  <div ng-if="item.dateFrom" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateFrom"></span>: <span>{{item.dateFrom |date: 'shortDate' : '+0200'}}</span>
                  </div>
                  <div ng-if="item.dateTo" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateTo"></span>: <span>{{item.dateTo | date: 'shortDate' : '+0200'}}</span>
                  </div>
                  <div class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>
                  </div>
                </div>
                <!--<div class=" col-md-12">-->
                <!--<p class="m-b-0 m-t-0 iban-style-paym wrap-text">-->
                <!--&lt;!&ndash;<span ng-if="item.receiverName">{{item.receiverName}}<br></span>&ndash;&gt;-->
                <!--&lt;!&ndash;<span ng-if="item.receiverId">{{item.receiverId}}<br></span>&ndash;&gt;-->
                <!--<span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>-->
                <!--</p>-->
                <!--</div>-->
                <!--<div class="col-md-12 m-t-10">-->
                <!--&lt;!&ndash;<button ng-disabled="item.settingType.id == 4 || item.editInProgress"&ndash;&gt;-->
                <!--&lt;!&ndash;class="btn btn-default domp-review-and-submit-btn m-b-5" type="button" translate="sepa.edit"&ndash;&gt;-->
                <!--&lt;!&ndash;data-ng-click="vm.edit(item);">&ndash;&gt;-->
                <!--&lt;!&ndash;Edit&ndash;&gt;-->
                <!--&lt;!&ndash;</button>&ndash;&gt;-->
                <!--<button class="btn btn-default domp-review-and-submit-btn"-->
                <!--ng-if="item.activated"-->
                <!--type="button"-->
                <!--ng-disabled="vm.deactivateBtnDisabled"-->
                <!--translate="sepa.deactivate"-->
                <!--data-ng-click="vm.deactivate(item);">-->
                <!--Deactivate-->
                <!--</button>-->
                <!--</div>-->
              </div>
              <div class="clear"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- =================== -->
    <!--Povijest ogranicenja-->
    <!-- =================== -->
    <div class="timeline-row col-sm-12 no-padding m-b-30" ng-if="vm.overviewTabType == 'history'">
      <div class="t-a-c p-10 no-data-image" ng-if="vm.sepaDDSettingsHistory.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card" ng-hide="vm.sepaDDSettingsHistory.length == 0">
        <ul class="hypo-timeline sepa-timeline" kcd-recompile="vm.timelineListGrouped">
          <li ng-class="{'time-label': item.isVirtual}" ng-repeat="item in vm.sepaDDSettingsHistory">
            <h4 class="timeline-separator-title bold-font" style="background: none; border: none; font-size: none"
                ng-if="item.isVirtual === true">{{item.date | date: 'shortDate' : '+0200'}}</h4>
            <i class="zmdi zmdi-sepa icon i-p24 icon-settings"
               ng-class="{'sepa-cro-timeline-icon-empty' : item.typeId === 'BLOCK_ALL', 'sepa-cro-timeline-icon': item.typeId != 'BLOCK_ALL'}"
               ng-if="!item.isVirtual"></i>
            <div ng-if="!item.isVirtual" class="hypo-timeline-item payment-item" style="height: 100px">
              <!-- if clickable ng-click="item.selected = !item.selected;" ng-class="{'opened': item.selected}"> -->
              <div class="hypo-timeline-body">
                <div class="col-md-6 col-sm-12 m-b-5 clearfix">
                  <!--<h4 class="m-b-0 m-t-10">-->
                  <!--<div><b>{{item.settingType.description}}</b></div>-->
                  <!--<span ng-if="item.typeId != 'BLOCK_ALL'">-->
                  <!--<div class="m-t-5"><b>{{item.receiverName}}</b></div>-->
                  <!--<div class="m-t-5"><b>ID: {{item.receiverId}}</b></div>-->
                  <!--</span>-->
                  <!--</h4>-->

                  <h4 class="m-b-0 m-t-10">
                    <div><b>{{item.accountIBAN}}</b></div>
                    <div><label class="m-b-0 m-t-5">{{item.settingType.description}}</label></div>
                    <span ng-if="item.typeId != 'BLOCK_ALL'">
                      <div class="m-t-5"><label class="m-b-0">{{item.receiverName}}</label></div>
                      <div class="m-t-5"><label class="m-b-0">ID: {{item.receiverId}}</label></div>
                    </span>
                  </h4>
                </div>
                <div class="col-md-3 col-sm-12">
                  <h4 class="m-b-0 m-t-10">
                    <div class="m-r-10" ng-if="item.hasOwnProperty('maxAmount')">{{item.maxAmount|number:2}} {{
                      vm.model.fromAccountSelect.currencySymbol}}
                    </div>
                  </h4>
                </div>
                <div class="col-md-3 col-sm-12 m-b-5 clearfix">
                  <b class="m-r-10 t-a-r f-18">
                    <span ng-if="item.activated"
                          translate="sepa.isActive">
                    </span>
                    <span ng-if="!item.activated"
                          translate="sepa.notActive">
                    </span>
                  </b>
                  <br>
                  <span ng-if="item.dateFrom" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateFrom"></span>: <span>{{item.dateFrom |date: 'shortDate' : '+0200'}}</span>
                  </span>
                  <br>
                  <span ng-if="item.dateTo" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateTo"></span>: <span>{{item.dateTo | date: 'shortDate' : '+0200'}}</span>
                  </span>
                  <span class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>
                  </span>
                </div>
                <!--<div class=" col-md-12">-->
                <!--<p class="m-b-0 m-t-0 iban-style-paym wrap-text">-->
                <!--&lt;!&ndash;<span ng-if="item.receiverName">{{item.receiverName}}<br></span>&ndash;&gt;-->
                <!--&lt;!&ndash;<span ng-if="item.receiverId">{{item.receiverId}}<br></span>&ndash;&gt;-->
                <!--<span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>-->
                <!--</p>-->
                <!--</div>-->
                <div class="clear"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- =================== -->
    <!-- Pregled zahtjeva -->
    <!-- =================== -->
    <div class="timeline-row col-sm-12 no-padding m-b-30" ng-if="vm.overviewTabType == 'requests'">
      <div class="t-a-c p-10 no-data-image" ng-if="vm.sepaDDRequests.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card" ng-hide="vm.sepaDDRequests.length == 0">
        <ul class="hypo-timeline sepa-timeline" kcd-recompile="vm.timelineListGrouped">
          <li ng-class="{'time-label': item.isVirtual}" ng-repeat="item in vm.sepaDDRequests">
            <h4 class="timeline-separator-title bold-font" style="background: none; border: none; font-size: none"
                ng-if="item.isVirtual === true">{{item.date | date: 'shortDate' : '+0200'}}</h4>
            <i class="zmdi zmdi-sepa icon i-p24 icon-settings"
               ng-class="{'sepa-cro-timeline-icon-empty' : item.typeId === 'BLOCK_ALL', 'sepa-cro-timeline-icon': item.typeId != 'BLOCK_ALL'}"
               ng-if="!item.isVirtual"></i>
            <div ng-if="!item.isVirtual" class="hypo-timeline-item payment-item" style="height: 100px">
              <!-- if clickable ng-click="item.selected = !item.selected;" ng-class="{'opened': item.selected}"> -->
              <div class="hypo-timeline-body">
                <div class="col-md-6 col-sm-12 m-b-5 clearfix">
                  <!--<h4 class="m-b-0 m-t-10">-->
                    <!--<div><b>{{item.settingType.description}}</b></div>-->
                    <!--<span ng-if="item.typeId != 'BLOCK_ALL'">-->
                      <!--<div class="m-t-5"><b>{{item.receiverName}}</b></div>-->
                      <!--<div class="m-t-5"><b>ID: {{item.receiverId}}</b></div>-->
                    <!--</span>-->
                  <!--</h4>-->

                  <h4 class="m-b-0 m-t-10">
                    <div><b>{{item.accountIBAN}}</b></div>
                    <div><label class="m-b-0 m-t-5">{{item.settingType.description}}</label></div>
                    <span ng-if="item.typeId != 'BLOCK_ALL'">
                      <div class="m-t-5"><label class="m-b-0">{{item.receiverName}}</label></div>
                      <div class="m-t-5"><label class="m-b-0">ID: {{item.receiverId}}</label></div>
                    </span>
                  </h4>

                </div>
                <div class="col-md-3 col-sm-12">
                  <div class="m-r-10 f-18" ng-switch="item.typeId">
                    <span ng-switch-default>
                      <b translate="sepa.activation"></b>
                    </span>
                    <span ng-switch-when="DEACTIVATE_REQ">
                      <b translate="sepa.deactivation">DEACTIVATION</b>
                    </span>
                  </div>
                  <div class="m-b-0 m-t-10 m-r-10" ng-if="item.hasOwnProperty('maxAmount')">{{item.maxAmount|number:2}}
                    {{ vm.model.fromAccountSelect.currencySymbol}}
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 m-b-5 clearfix">
                  <b class="m-r-10 t-a-r f-18">
                    <span ng-switch="item.status">
                      <span ng-switch-when="0" translate="sepa.status_created"></span>
                      <span ng-switch-when="2" translate="sepa.status_recalled"></span>
                      <span ng-switch-when="3" translate="sepa.status_processed"></span>
                      <span ng-switch-when="4" translate="sepa.status_rejected"></span>
                    </span>
                  </b>
                  <br>
                  <span ng-if="item.dateFrom" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateFrom"></span>: <span>{{item.dateFrom |date: 'shortDate' : '+0200'}}</span>
                  </span>
                  <br>
                  <span ng-if="item.dateTo" class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span translate="sepa.dateTo"></span>: <span>{{item.dateTo | date: 'shortDate' : '+0200'}}</span>
                  </span>
                  <span class="m-b-0 m-t-0 iban-style-paym wrap-text">
                    <span ng-if="item.executionPeriod">{{'sepa.executionPeriodLabel' | translate}}{{'sepa.executionPeriod.'+item.executionPeriod | translate}}</span>
                  </span>

                </div>
                <!--<div class=" col-md-12">-->
                  <!--<p class="m-b-0 m-t-0 iban-style-paym wrap-text">-->
                    <!--&lt;!&ndash;<span ng-if="item.receiverName">{{item.receiverName}}<br></span>&ndash;&gt;-->
                    <!--&lt;!&ndash;<span ng-if="item.receiverId">{{item.receiverId}}<br></span>&ndash;&gt;-->
                  <!--</p>-->
                <!--</div>-->
                <div class="clear"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</section>
