(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name user.factory:UserSettingsService
   *
   * @description
   *
   */
  angular.module("user").factory("UserSettingsService", UserSettingsService);

  function UserSettingsService(
    EndpointsService,
    $q,
    $http,
    $log,
    HelperService,
    NotificationService
  ) {
    var UserSettingsServiceBase = {};

    UserSettingsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $log.debug("Params:", params);
          $http
            .get(response.links.userSettings, {
              params: params,
            })
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.validateUserInfoOrders = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .put(response.links.userInfoOrders_validate, data)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.updateUserInfoOrders = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .post(response.links.userInfoOrders, data)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.getAll = function (params) {
      var predefinedParams = {
        page: 0,
        pageSize: 1000,
      };
      var extended = _.extend(predefinedParams, params);
      return UserSettingsServiceBase.getPage(extended);
    };

    UserSettingsServiceBase.updateInvoiceEmail = function (email, userId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .put(response.links.userSettings_id.replace("{id}", userId), email)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.userHaveFunds = function () {
      return this.getPage()
        .then(function (response) {
          if (_.isArray(response.result) && response.result[0].fundUserId) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
          return false;
        });
    };

    UserSettingsServiceBase.insertUserSettings = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .post(
              response.links.userSettings_insertUserNotificationSettings,
              params
            )
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };
    UserSettingsServiceBase.updateUserSettings = function (userId, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .put(
              response.links.userSettings_updateUserNotificationSettings.replace(
                "{id}",
                userId
              ),
              params
            )
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.updateNotifications = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .put(response.links.notificationSettings_update, params)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.getNotifications = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .get(response.links.notificationSettings)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    UserSettingsServiceBase.updateUserMobileSetting = function (param) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(
        function (response) {
          $http
            .put(
              response.links.userSettings
                .concat("/updateUserMobileSetting?enabled={p}")
                .replace("{p}", param)
            )
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        }
      );
      return deferred.promise;
    };

    return UserSettingsServiceBase;
  }
})();
