(function () {
  'use strict';

  angular
    .module('complaints')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('complaints', {
        url: '/complaints',
        templateUrl: 'complaints/complaints.tpl.html',
        controller: 'ComplaintsCtrl',
        controllerAs: 'complaints'
      })
      .state('complaints-new', {
        url: '/complaint',
        params: {
          transactionId: null,
          accountId: null
        },
        templateUrl: 'complaints/new-complaint/new-complaint.tpl.html',
        controller: 'ComplaintCtrl',
        controllerAs: 'complaint'
      });;
  }
}());
