<!-- Loan details -->
<div class="m-b-10">
  <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
    <span ng-switch-when="hr" class="account-header-value white-text-block"
      ng-bind="(accounts.selectedAccount.loanAccount.loanAmount | number:2) + ' ' + (accounts.selectedAccount.loanAccount.installmentCurrencySymbol)"></span>
    <span ng-switch-when="sl" class="account-header-value white-text-block"
      ng-bind="(accounts.selectedAccount.balance | number:2) + ' EUR'"></span>
    <span ng-switch-default ng-if="accounts.selectedAccount.balance" class="account-header-value white-text"
      ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>
    <span ng-switch-when="hr">
      <span ng-switch-default ng-if="accounts.selectedAccount.balance" class="account-header-value white-text"
        ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol)">
        <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 )" class="account-header-value-eur"
          ng-bind="' / ' + (accounts.selectedAccount.balance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
      </span>
    </span>
  </span>

  <hr
    ng-if="(!accounts.countryCode==='hr' && accounts.selectedAccount.loanAccount.loanAmount) || (accounts.countryCode != 'hr' && accounts.selectedAccount.balance)"
    class="account-header-line" />
  <span ng-if="accounts.countryCode==='hr'" class="m-t-neg-15 white-text-block"
    translate="accounts.accLoanAmount"></span>
  <span ng-if="accounts.countryCode!='hr' && accounts.selectedAccount.balance" class="m-t-neg-15 white-text-block"
    translate="accounts.accLoanBalance"></span>
  <!-- provjeriti da li treba ovaj podatak
<span class="account-header-value white-text-block"
ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.installmentCurrencySymbol)"></span>
<hr class="account-header-line"/>
<span class="m-t-neg-15 white-text-block" translate="accounts.accLoanBalance"></span> -->

  <div class="account-header-bottom">
    <div class="row" ng-class="{'p-t-50': !accounts.selectedAccount.loanAccount.installmentAmount }">
      <div ng-if="accounts.selectedAccount.loanAccount.feeAmount" class="col-xs-12 col-md-4 m-t-10">
        <span class="f-20 white-text-block" ng-if="accounts.countryCode === 'hr' || accounts.countryCode === 'sl'"
          ng-bind="(accounts.selectedAccount.loanAccount.feeAmount | number:2) + ' ' + (accounts.selectedAccount.loanAccount.currencySymbol)">
        </span>
        <span class="f-20 white-text-block" ng-if="accounts.countryCode != 'hr' && accounts.countryCode != 'sl'"
          ng-bind="(accounts.selectedAccount.loanAccount.feeAmount | number:2) + ' ' + (accounts.selectedAccount.currencySymbol)">
        </span>
        <span class="white-text-block" translate="accounts.accAnnuity"></span>
      </div>
      <!-- show installementAmount for SLO environment -->
      <div ng-if="accounts.selectedAccount.loanAccount.installmentAmount && accounts.countryCode === 'sl'"
        class="col-xs-12 col-md-4 m-t-10">
        <span class="f-20 white-text-block"
          ng-bind="(accounts.selectedAccount.loanAccount.installmentAmount | number:2) + ' ' + (accounts.selectedAccount.loanAccount.installmentCurrencySymbol)">
        </span>
        <span class="white-text-block" translate="accounts.accAnnuity"></span>
      </div>


      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 m-t-10"
        ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.interestRegularString}}</span>
        <span class="white-text-block"><span translate="accounts.accAnnuityConverted"></span>
          {{accounts.interestRate}}</span>
      </div>
      <div class="col-xs-12 col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' && accounts.selectedAccount.loanAccount">
        <div ng-if="accounts.selectedAccount.loanAccount.installmentInLocalCurrency">
          <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentInLocalCurrency |
            number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
          <span class="white-text-block"><span translate="accounts.accAnnuityConverted"></span>
            {{accounts.defaultCurrency}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="p-t-20" ng-show="accounts.isAccountDetailsOpen">

    <div class="row" ng-show="accounts.countryCode==='hr'">
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.totalMatureDebit != undefind">
        <span class="f-20  white-text-block"
          ng-if="accounts.countryCode==='hr'">{{accounts.selectedAccount.loanAccount.totalMatureDebit | number:2}}
          {{accounts.selectedAccount.loanAccount.installmentCurrencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accDueAmount"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showConvertedLoansFields">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.loanAccount.totalMatureInLocalCurrency |
          number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block"><span
            translate="accounts.accTotalMatureConverted"></span>{{accounts.defaultCurrency}}</span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.notMatureCapital">
        <span class="f-20 white-text-block"
          ng-if="accounts.countryCode==='hr'">{{accounts.selectedAccount.loanAccount.notMatureCapital | number:2}}
          {{accounts.selectedAccount.loanAccount.installmentCurrencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.countryCode!='hr'">{{accounts.selectedAccount.loanAccount.notMatureCapital | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accNotMatureCapital"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showConvertedLoansFields">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.loanAccount.notMatureCapitalInLocalCurrency |
          number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block"><span
            translate="accounts.accNotMatureCapitalConverted"></span>{{accounts.defaultCurrency}}</span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.loanContractNumber">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.loanContractNumber}}</span>
        <span class="white-text-block" translate="accounts.accContractNumber"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.nextInstallmentDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.nextInstallmentDate |
          date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accNewDebitDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.dateApproved">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.dateApproved | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accDisbursementDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.dueDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.dueDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accMaturityDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span ng-if="accounts.selectedAccount.balanceLastChangedDate"
          class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.interestRegular">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.interestRegular | number:2}} %
          <!--          <span ng-if="accounts.countryCode==='hr'">{{accounts.selectedAccount.loanAccount.installmentCurrencySymbol}}</span>
          <span ng-if="accounts.countryCode!='hr'">%</span></span>-->
        </span>
        <span class="white-text-block" translate="accounts.accMatureInterest"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.installmentsNumber">
        <span ng-if="accounts.selectedAccount.loanAccount.installmentsNumber"
          class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentsNumber}}</span>
        <span ng-if="!accounts.selectedAccount.loanAccount.installmentsNumber" class="f-20 white-text-block">0</span>
        <span class="white-text-block" translate="accounts.accInstallmentNumber"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.installmentsPaidNumber">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentsPaidNumber}}</span>
        <span class="white-text-block" translate="accounts.accInstallmentPaidNumber"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" data-ng-if="accounts.selectedAccount.loanAccount.installmentRemain">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentRemain}}</span>
        <span class="white-text-block" translate="accounts.accInstallmentsRemain"></span>
      </div>
    </div>
    <!-- SRB and MNE-->
    <div ng-show="accounts.countryCode != 'hr'" class="row">
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.dateApproved">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.dateApproved | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accDisbursementDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.loanAmount">
        <span class="f-20 white-text-block"
          ng-if="accounts.countryCode != 'sl'">{{accounts.selectedAccount.loanAccount.loanAmount | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.countryCode == 'sl'">{{accounts.selectedAccount.loanAccount.loanAmount | number:2}}
          {{accounts.selectedAccount.loanAccount.loanAmountCurrencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accLoanAmount"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.nextInstallmentDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.nextInstallmentDate |
          date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accNewDebitDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.interestRegular">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.interestRegular | number:2}}
          <span
            ng-if="accounts.countryCode==='hr'">{{accounts.selectedAccount.loanAccount.installmentCurrencySymbol}}</span>
          <span ng-if="accounts.countryCode!='hr'">%</span></span>
        <span class="white-text-block" translate="accounts.accMatureInterest"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.totalMatureDebit">
        <span class="f-20  white-text-block"
          ng-if="accounts.countryCode != 'bh'">{{accounts.selectedAccount.loanAccount.totalMatureDebit | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20  white-text-block"
          ng-if="accounts.countryCode == 'bh'">{{accounts.selectedAccount.loanAccount.depositAmount | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accDueAmount"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span ng-if="accounts.selectedAccount.balanceLastChangedDate"
          class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" data-ng-if="accounts.selectedAccount.loanAccount.installmentRemain">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentRemain }}</span>
        <span class="white-text-block" translate="accounts.accInstallmentsRemain"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.loanContractNumber">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.loanContractNumber}}</span>
        <span class="white-text-block" translate="accounts.accContractNumber"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.dueDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.dueDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accMaturityDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.installmentsNumber">
        <span ng-if="accounts.selectedAccount.loanAccount.installmentsNumber"
          class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentsNumber}}</span>
        <span class="white-text-block" translate="accounts.accInstallmentNumber"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.loanAccount.installmentsPaidNumber">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.loanAccount.installmentsPaidNumber}}</span>
        <span class="white-text-block" translate="accounts.accInstallmentPaidNumber"></span>
      </div>

    </div>

  </div>
</div>