<div ng-controller="DomesticPaymentSloCtrl as domesticPaymentSlo">
  <div class="domp-header-wrapper" ng-show="domesticPaymentSlo.currentStep != 3">
    <div class="row">
      <div class="col-md-12">
        <h2 class="f-28" translate="domesticPayment.headerSl">Domestic Payment</h2>
      </div>
    </div>
  </div>
  <div class="domp-body-wrapper">
    <div ng-if="domesticPaymentSlo.currentStep === 1" class="domp-step-1">
      <form novalidate name="domesticPaymentSlo.domesticPaymentForm">

        <div>
          <!--SELECT TEMPLATE CUSTOM-->
          <div ng-if="!domesticPaymentSlo.disableAllFields" class="domp-template-filter-container"
            style="position: relative">
            <div class="domp-select-template"
              ng-click="domesticPaymentSlo.toggleTemplateFilterSelection(); domesticPaymentSlo.donationTemplateListExpanded = false;"
              ng-show="domesticPaymentSlo.templateName == ''">
              <div class="select-template label-select">
                <label class="col-xs-10 p-t-15 f-18 hidden-xs" translate="domesticPayment.selectFromTemplate">Select
                  from template</label>
                <label class="col-xs-10 p-t-15 f-10 visible-xs" translate="domesticPayment.selectFromTemplate">Select
                  from template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>
            <div class="domp-selected-template" ng-show="domesticPaymentSlo.templateName != ''">
              <div class="select-template label-template-selected p-l-10 p-t-5"
                ng-show="domesticPaymentSlo.templateName != ''">
                <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->

                <img ng-if="domesticPaymentSlo.selectedTemplate.imageId"
                  ng-src="{{domesticPaymentSlo.selectedTemplate.imageDownloadLink}}" alt="Ico"
                  class="tpl-rounded-img-40" />
                <img ng-if="!domesticPaymentSlo.selectedTemplate.imageId" class="tpl-rounded-img-40" alt="Ico" />

                <label class="p-l-10 selected-template-label top-2"><b>{{domesticPaymentSlo.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5"
                  ng-click="domesticPaymentSlo.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection"
              ng-class="{'opened': domesticPaymentSlo.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width:50%;">
                  <input id="searchTemplatesInput" type="text" class="search-input"
                    ng-model="domesticPaymentSlo.tmpSearchText.name"
                    ng-change="domesticPaymentSlo.searchTemplate(domesticPaymentSlo.tmpSearchText.name)"
                    placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item"
                      data-ng-repeat="template in domesticPaymentSlo.templateList.result| filter:domesticPaymentSlo.tmpSearchText"
                      ng-click="domesticPaymentSlo.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
                          <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                          <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico"
                            class="tpl-rounded-img" />
                          <img ng-if="!template.imageId" class="tpl-rounded-img" alt="Ico" />
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15"
                          style="padding-left: 50px !important;">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
        </div>

        <!--SELECT HUMANITARIAN TEMPLATE CUSTOM-->
        <div ng-if="!domesticPaymentSlo.disableAllFields" class="domp-template-filter-container"
          style="position: relative">
          <div class="domp-select-template"
            ng-click="domesticPaymentSlo.donationTemplateListExpanded = !domesticPaymentSlo.donationTemplateListExpanded; domesticPaymentSlo.searchTemplateFilterExpanded = false;"
            ng-show="domesticPaymentSlo.humanitarianName == ''">
            <div class="select-template label-select">
              <label class="col-xs-10 p-t-15 f-18 hidden-xs"
                translate="domesticPayment.selectHumanitarianOrganisationLabel"></label>
              <label class="col-xs-10 p-t-15 f-10 visible-xs"
                translate="domesticPayment.selectHumanitarianOrganisationLabel"></label>
              <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
            </div>
          </div>
          <div class="domp-selected-template" ng-show="domesticPaymentSlo.humanitarianName != ''">
            <div class="select-template label-template-selected p-l-10 p-t-5"
              ng-show="domesticPaymentSlo.humanitarianName != ''">
              <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->

              <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>

              <label class="p-l-10 selected-template-label"><b>{{domesticPaymentSlo.humanitarianName}}</b></label>
              <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5"
                ng-click="domesticPaymentSlo.removeHumanitarian()"></i>
            </div>
          </div>
          <div class="domp-template-filter-selection"
            ng-class="{'opened': domesticPaymentSlo.donationTemplateListExpanded}">
            <div class="search-template">
              <div class="inner-section left">
                <i class="icon icon-search domp-search-sign m-l-5"></i>
              </div>
              <div class="inner-section center" style="width:50%;">
                <input id="searchTemplatesInput" type="text" class="search-input"
                  ng-model="domesticPaymentSlo.hOrganisationNameSearch" placeholder="Search organisation">
              </div>
            </div>
            <div class="domp-template-filter">
              <div class="m-b-10">
                <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfOrganisations"></h4>
                <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                  <a href="" class="list-group-item"
                    data-ng-repeat="template in domesticPaymentSlo.humaniOrgList | filter:domesticPaymentSlo.hOrganisationNameSearch"
                    ng-click="domesticPaymentSlo.selectHumanitarian(template)">
                    <div class="row m-r-0">
                      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
                        <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>
                        <!--                          <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                                                    <img ng-if="!template.imageId" class="tpl-rounded-img" alt="Ico"/>-->
                      </div>
                      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15"
                        style="padding-left: 50px !important;">
                        <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                        <!--<p class="list-group-item-text">{{template.toAccountNumber}}</p>-->
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--SELECT HUMANITARIAN TEMPLATE CUSTOM END-->

        <div class="domp-from-section p-20">
          <section class="row">
            <!-- START CUSTOM ACCOUNTS DROPDOWN -->
            <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <label class="domp-form-label" translate="payments.fromAccount"></label>
              <div class="accounts-dropdown">
                <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                  data-ng-click="domesticPaymentSlo.toggleFromAccount();"
                  ng-style="domesticPaymentSlo.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + domesticPaymentSlo.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b
                      class="col-xs-10 p-0 m-t-10  no-wrap-text">{{domesticPaymentSlo.tempDompObj.fromAccountSelect.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                        ng-class="{'zmdi-star favorite-account':domesticPaymentSlo.tempDompObj.fromAccountSelect.isFavorite, 'zmdi-star-outline':!domesticPaymentSlo.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">
                    {{domesticPaymentSlo.tempDompObj.fromAccountSelect.iban | formatIban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{domesticPaymentSlo.tempDompObj.fromAccountSelect.availableBalance| number:2}}
                    {{domesticPaymentSlo.tempDompObj.fromAccountSelect.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                </div>
                <div class="from-accounts-list " ng-show="domesticPaymentSlo.fromAccountDropdown" scrollable>
                  <div class="accounts-list-item p-0"
                    data-ng-repeat="item in domesticPaymentSlo.paymentTypeGroupDomestic.result[0].debitAccountList"
                    data-ng-click="domesticPaymentSlo.setAccountFrom(item);">

                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                      ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                            ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{item.availableBalance| number:2}} {{item.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END CUSTOM ACCOUNTS DROPDOWN -->

            <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form-group">
                <label class="domp-form-label" for="amountInput"
                  translate="domesticPayment.amountInputLabel">Amount:</label>
                <div class="input-group">
                  <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                    placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask
                    max-amount="99999999999.99" maxlength="17" ng-model="domesticPaymentSlo.tempDompObj.amountInput"
                    required
                    ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.amountInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                    ng-readonly="domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                      ng-if="domesticPayment.countryCode != 'sr'"
                      style="border:1px solid #062a42;">{{domesticPaymentSlo.defaultCurrency}}</button>
                  </span>
                </div>
                <span class="c-red"
                  ng-show="domesticPaymentSlo.domesticPaymentForm.amountInput.$error.maxAmount && domesticPaymentSlo.tempDompObj.formSubmitted"
                  translate="internalTransfer.amountLengthError"></span>
                <span class="c-red"
                  ng-show="domesticPaymentSlo.domesticPaymentForm.amountInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                  translate="internalTransfer.amountInputError"></span>
              </div>

              <div class="form-group">
                <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field" name="dateInput"
                    ng-class="{'domp-error': domesticPaymentSlo.tempDompObj.formSubmitted && (domesticPaymentSlo.domesticPaymentForm.dateInput.$invalid || domesticPaymentSlo.instantDateCheck())}"
                    id="dateInput" uib-datepicker-popup="shortDate"
                    data-ng-model="domesticPaymentSlo.tempDompObj.dateInput" show-weeks="false"
                    is-open="domesticPaymentSlo.datePickerOpened" current-text="{{'core.period.today' | translate}}"
                    clear-text="{{'core.datePicker.clearBtn' | translate}}"
                    close-text="{{'core.datePicker.doneBtn' | translate}}"
                    datepicker-options="domesticPaymentSlo.dateOptions"
                    date-disabled="domesticPaymentSlo.disabled(date, mode)" readonly required
                    ng-if="domesticPaymentSlo.disabledSunday">
                  <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                    uib-datepicker-popup="shortDate" data-ng-model="domesticPaymentSlo.tempDompObj.dateInput"
                    ng-class="{'domp-error': domesticPaymentSlo.tempDompObj.formSubmitted && domesticPaymentSlo.instantDateCheck()}"
                    show-weeks="false" is-open="domesticPaymentSlo.datePickerOpened"
                    current-text="{{'core.period.today' | translate}}"
                    clear-text="{{'core.datePicker.clearBtn' | translate}}"
                    close-text="{{'core.datePicker.doneBtn' | translate}}"
                    datepicker-options="domesticPaymentSlo.dateOptions" readonly
                    ng-if="!domesticPaymentSlo.disabledSunday">
                  <span class="input-group-btn">
                    <button
                      ng-disabled="domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment"
                      data-ng-click="domesticPaymentSlo.openDatePicker()" type="button"
                      class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                      style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                </div>
                <div class="c-red"
                     ng-show="domesticPaymentSlo.tempDompObj.formSubmitted && domesticPaymentSlo.instantDateCheck()"
                     translate="domesticPayment.instantDateError"></div>
              </div>
            </div>
          </section>

          <section class="row" ng-if="!domesticPaymentSlo.naturalPerson">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <uib-accordion close-others="true" class="domp-sepa-accordion">
                <div uib-accordion-group>
                  <uib-accordion-heading>
                    <div class="sepa-heading input-field">
                      <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                        <label class="domp-form-label-sepa" translate="domesticPayment.sepaDebitorLabel"></label>
                      </div>
                      <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                          class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                    </div>
                  </uib-accordion-heading>
                  <input id="payersSepaInput1" ng-readonly="domesticPaymentSlo.disableAllFields" type="text"
                    class="form-control input-field" ng-model="domesticPaymentSlo.tempDompObj.payersSepaInput1"
                    ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.standardInput}}" style="margin-bottom:10px;"
                    maxlength="70">
                  <!--<input id="payersSepaInput2" ng-readonly="domesticPaymentSlo.disableAllFields" type="text" class="form-control input-field" -->
                  <!--ng-model="domesticPaymentSlo.tempDompObj.payersSepaInput2" ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.standardInput}}" -->
                  <!--maxlength="70">-->
                </div>
              </uib-accordion>
            </div>
          </section>
        </div>


        <div class="domp-from-section clearfix">

          <div class="col-md-6 p-0">
            <!-- START: IBAN PREJEMNIKA-->
            <div class="form-group col-xs-12">
              <div>
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabelSLO">
                  Account number
                </label>
                <input
                  ng-show="!domesticPaymentSlo.isFromTemplate || domesticPaymentSlo.isSecondaryTemplateResponseEmpty"
                  ng-readonly="domesticPaymentSlo.disableAllFields" type="text" class="form-control input-field"
                  style="text-transform: uppercase;" ng-blur="
                       domesticPaymentSlo.getBicByIban(domesticPaymentSlo.tempDompObj.toAccountInput);
                       domesticPaymentSlo.checkIban(domesticPaymentSlo.tempDompObj.toAccountInput);
                       domesticPaymentSlo.checkAccountsEquality();"
                  ng-change="domesticPaymentSlo.addSpaceForIban(domesticPaymentSlo.tempDompObj.toAccountInput);"
                  ui-mask="{{domesticPaymentSlo.uiMask}}" ui-mask-placeholder ui-mask-placeholder-char="space"
                  maxlength="{{domesticPaymentSlo.ibanMaxlength}}"
                  ng-keyup="$event.keyCode == 13 && domesticPaymentSlo.checkIfNaturalPerson(domesticPaymentSlo.tempDompObj.toAccountInput);"
                  ng-model="domesticPaymentSlo.tempDompObj.toAccountInput"
                  ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                  name="toAccountInput" id="toAccountInput" required ng-disabled="domesticPaymentSlo.toAccountDisabled">
                <label name="toAccountInput"
                  ng-show="domesticPaymentSlo.isFromTemplate && !domesticPaymentSlo.isSecondaryTemplateResponseEmpty"
                  class="domp-form-label-resume">
                  {{domesticPaymentSlo.tempDompObj.toAccountInput}}
                </label>
                <!--<div class="c-red" ng-show="domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted" -->
                <!--translate="domesticPayment.receiverAccountError"></div>-->
                <!--<div class="c-red"-->
                <!--ng-show="!domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.required -->
                <!--&& !domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.accountEquality -->
                <!--&& domesticPaymentSlo.domesticPaymentForm.toAccountInput.$invalid -->
                <!--&& domesticPaymentSlo.tempDompObj.formSubmitted" -->
                <!--translate="domesticPayment.ibanStructError"></div>-->
                <div class="c-red" ng-show="!domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.accountEquality
                     && (domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.required ||
                     domesticPaymentSlo.domesticPaymentForm.toAccountInput.$invalid ||
                     domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.minlength ) &&
                      domesticPaymentSlo.tempDompObj.formSubmitted" translate="domesticPayment.ibanStructError"></div>
                <!--<div class="c-red" ng-show="domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.accountEquality && domesticPaymentSlo.tempDompObj.formSubmitted" translate="domesticPayment.accountEqualityError"></div>-->
                <!--<div class="c-red" ng-show="domesticPaymentSlo.domesticPaymentForm.toAccountInput.$error.minlength && domesticPaymentSlo.tempDompObj.formSubmitted" translate="domesticPayment.toAccountMinlength19"></div>-->
              </div>
              <div ng-hide="true" class="form-group">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.bicBankPrejemnika">
                  BIC banke prejemnika
                </label>
                <input ng-readonly="domesticPaymentSlo.disableAllFields" type="text" class="form-control input-field"
                  ng-model="domesticPaymentSlo.tempDompObj.beneficiarySWIFTCode" name="beneficiarySWIFTCode"
                  id="beneficiarySWIFTCode" disabled>
                <a ng-href="{{domesticPaymentSlo.defaultData.SepaRegisterLink}}" target="_blank"
                  translate="domesticPayment.sepaRegister" class="underline">SEPA register</a>
              </div>
            </div>
            <!-- END: IBAN PREJEMNIKA-->
            <!-- START: COUNTRIES -->
            <div class="form-group col-xs-12" ng-if="!domesticPaymentSlo.naturalPerson">
              <div class="p-l-0">
                <select class="form-control input-field p-l-15 p-r-10" name="receiversCountry" id="receiversCountry"
                  ng-options="option.countryCode as option.description | translate for option in domesticPaymentSlo.countriesCodeList.result"
                  ng-model="domesticPaymentSlo.tempDompObj.receiversCountry"
                  ng-disabled="domesticPaymentSlo.naturalPerson || domesticPaymentSlo.disableAllFields">
                </select>
              </div>
            </div>
            <!-- END: COUNTRIES -->
          </div>

          <div ng-if="!domesticPaymentSlo.naturalPerson" class="col-md-6 p-0">
            <!-- START: NAZIV-->
            <div click-outside="domesticPaymentSlo.searchFilterExpanded = false;"
              outside-if-not="expandPayeeSearchFilterSLO">
              <div class="form-group col-xs-12">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee2">To
                  payee</label>
                <label ng-if="domesticPaymentSlo.isFromTemplate && !domesticPaymentSlo.isSecondaryTemplateResponseEmpty"
                  class="domp-form-label-resume">
                  {{domesticPaymentSlo.tempDompObj.receiversNameInput}}
                </label>

                <div ng-if="!domesticPaymentSlo.isFromTemplate || domesticPaymentSlo.isSecondaryTemplateResponseEmpty"
                  class="col-sm-12 col-xs-12 domp-filter-container input-field"
                  ng-class="{'domp-error-custom-payee': domesticPaymentSlo.domesticPaymentForm.payeeSearchFilter.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input ng-readonly="domesticPaymentSlo.disableAllFields" type="text" name="payeeSearchFilter"
                      id="payeeSearchFilter" class="search-input"
                      ng-model="domesticPaymentSlo.tempDompObj.receiversNameInput"
                      ng-change="domesticPaymentSlo.searchPayee()"
                      ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.receiverNameInput}}" maxlength="70"
                      autocomplete="off"
                      ng-disabled="domesticPaymentSlo.toAccountDisabled || domesticPaymentSlo.naturalPerson"
                      ng-class="{'bcg-white': domesticPaymentSlo.toAccountDisabled }" style="background: none;"
                      ng-required="!domesticPaymentSlo.naturalPerson && !domesticPaymentSlo.toAccountDisabled">
                  </div>
                  <div ng-hide="domesticPaymentSlo.disableAllFields" id="expandPayeeSearchFilterSLO"
                    class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                    ng-click="domesticPaymentSlo.toggleFilterSelection()">
                    <i class="zmdi zmdi-close domp-close-sign"
                      ng-show="domesticPaymentSlo.tempDompObj.receiversNameInput != ''"
                      ng-click="domesticPaymentSlo.removeSearch()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                      ng-show="domesticPaymentSlo.tempDompObj.receiversNameInput == ''"></i>
                  </div>
                  <div class="domp-filter-selection search-row"
                    ng-class="{'opened': domesticPaymentSlo.searchFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper"
                      ng-repeat="payee in  domesticPaymentSlo.payeeList| filter:domesticPaymentSlo.searchPayeeBy"
                      ng-click="domesticPaymentSlo.selectPayee(payee.id)">
                      <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <i class="zmdi zmdi-account-circle"></i>
                      </div>
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                        <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="domesticPaymentSlo.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                  translate="domesticPayment.toPayeeInputError"></span>
              </div>
            </div>
            <!-- END: NAZIV-->

            <!-- START: NASLOV -->
            <div class="form-group col-xs-12">
              <!-- <label class="domp-form-label" for="receiversAddressInput" translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label> -->
              <input ng-readonly="domesticPaymentSlo.disableAllFields" type="text" class="form-control input-field"
                name="receiversAddressInput"
                ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.receiverNameInput}}"
                ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                ng-model="domesticPaymentSlo.tempDompObj.receiversAddressInput"
                ng-blur="domesticPaymentSlo.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentSlo.tempDompObj.receiversAddressInput)"
                maxlength="70" ng-maxlength="70" ng-required="!domesticPaymentSlo.naturalPerson">
              <span class="c-red"
                ng-show="domesticPaymentSlo.domesticPaymentForm.receiversAddressInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                translate="domesticPayment.receiverAddressError"></span>
            </div>
            <!-- END: NASLOV -->
            <!-- START: THIRD -->
            <div class="form-group col-xs-12">
              <input ng-readonly="domesticPaymentSlo.disableAllFields" type="text" class="form-control input-field"
                name="receiversPostalCodeAndCityInput"
                ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.receiverNameInput}}"
                ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.receiversPostalCodeAndCityInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                ng-model="domesticPaymentSlo.tempDompObj.receiversPostalCodeAndCityInput"
                ng-blur="domesticPaymentSlo.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', domesticPaymentSlo.tempDompObj.receiversPostalCodeAndCityInput)"
                maxlength="70" ng-required="!domesticPaymentSlo.naturalPerson">
              <span class="c-red"
                ng-show="domesticPaymentSlo.domesticPaymentForm.receiversPostalCodeAndCityInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                translate="domesticPayment.receiversPostalCodeAndCityInputError"></span>
            </div>
            <!-- END: THIRD-->
            <!-- START: UPNIK -->
            <div class="form-group col-xs-12">
              <uib-accordion close-others="true" class="domp-sepa-accordion">
                <div uib-accordion-group>
                  <uib-accordion-heading>
                    <div class="sepa-heading input-field">
                      <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                        <label class="domp-form-label-sepa" translate="domesticPayment.sepaReceiverLabel"></label>
                      </div>
                      <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                          class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                    </div>
                  </uib-accordion-heading>
                  <input ng-readonly="domesticPaymentSlo.disableAllFields" id="receiversSepaInput1" type="text"
                    class="form-control input-field"
                    ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.allowedCharsDPS}}"
                    ng-model="domesticPaymentSlo.tempDompObj.receiversSepaInput1" style="margin-bottom:10px;"
                    maxlength="70">
                  <!--<input ng-readonly="domesticPaymentSlo.disableAllFields" id="receiversSepaInput2" type="text" class="form-control input-field" -->
                  <!--ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.allowedCharsDPS}}" ng-model="domesticPaymentSlo.tempDompObj.receiversSepaInput2" maxlength="35">-->
                </div>
              </uib-accordion>
            </div>
            <!-- END: UPNIK -->
          </div>

        </div>

        <div class="domp-from-section p-20">
          <section class="row" ng-if="!domesticPaymentSlo.naturalPerson || domesticPaymentSlo.paymentTypeIsLoar">
            <div ng-if="!domesticPaymentSlo.naturalPerson" class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
              <div class="form-group">
                <label class="domp-form-label" for="purposeCodeSelect"
                  translate="domesticPayment.purposeCodeSelectLabel">Purpose code</label>
                <div class="p-l-0">
                  <select
                    ng-disabled="(domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment) || domesticPaymentSlo.disableAllFieldsForChange"
                    class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect"
                    ng-options="option.optionValue for option in domesticPaymentSlo.purposeCodesList.result"
                    ng-model="domesticPaymentSlo.tempDompObj.purposeCodeSelect"></select>
                </div>
              </div>
            </div>

            <div ng-if="!domesticPaymentSlo.naturalPerson || domesticPaymentSlo.paymentTypeIsLoar"
              class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12 pull-right">
              <div class="col-xs-12 p-l-0">
                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabel">Reference number:
                </label>
              </div>
              <div class="col-sm-3 col-xs-3 p-l-0 p-r-5">
                <select
                  ng-disabled="(domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment) || domesticPaymentSlo.disableAllFieldsForChange"
                  type="text" class="form-control input-field p-l-15 p-r-10"
                  ng-options="option.name as option.value for option in domesticPaymentSlo.referenceList "
                  name="referenceModel" id="referenceModel" ng-model="domesticPaymentSlo.referenceHR"
                  ng-change="domesticPaymentSlo.setPurposeMaxlengthValue(); domesticPaymentSlo.setRefModelToDefault(); domesticPaymentSlo.tempDompObj.referenceNumberInput = '';"
                  required>
                </select>
              </div>
              <div ng-if="domesticPaymentSlo.referenceHR == 'SI'" class="col-sm-3 col-xs-3 p-l-0 p-r-5">
                <div class="form-group m-b-0">
                  <select
                    ng-disabled="(domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment) || domesticPaymentSlo.disableAllFieldsForChange"
                    class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelSelect"
                    ng-class="{'domp-error': domesticPaymentSlo.tempDompObj.referenceModelSelect.split(' ').join('') === '' && domesticPaymentSlo.tempDompObj.formSubmitted}"
                    ng-model="domesticPaymentSlo.tempDompObj.referenceModelSelect"
                    ng-options="item as item for item in domesticPaymentSlo.referenceModelList"
                    ng-change="domesticPaymentSlo.clearReferenceNumber();domesticPaymentSlo.setPurposeMaxlengthValue();"
                    required>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 col-xs-6 p-l-0 p-r-0"
                ng-if="domesticPaymentSlo.tempDompObj.referenceModelSelect != '99' && domesticPaymentSlo.referenceHR != 'NRC'">
                <div class="form-group m-b-0">
                  <input
                    ng-readonly="(domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment) || domesticPaymentSlo.disableAllFieldsForChange"
                    ng-class="{'domp-error': domesticPaymentSlo.referenceHR == 'RF' && domesticPaymentSlo.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                    type="text" id="referenceNumberInput" name="referenceNumberInput" class="form-control input-field"
                    ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.allLettersNumbersDashes}}"
                    ng-model="domesticPaymentSlo.tempDompObj.referenceNumberInput"
                    maxlength="{{(domesticPaymentSlo.referenceHR == 'RF') ? 23 : 22 }}"
                    ng-required="domesticPaymentSlo.referenceHR == 'RF'">
                </div>
              </div>
              <span ng-if="domesticPaymentSlo.referenceHR === 'NRC'"
                class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-t-5"
                ng-show="domesticPaymentSlo.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                translate="domesticPayment.referenceModelInputError"></span>
              <span ng-if="domesticPaymentSlo.referenceHR === 'RF'"
                class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-t-5"
                ng-show="domesticPaymentSlo.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                translate="domesticPayment.referenceModelInputRFError"></span>
              <span ng-if="domesticPaymentSlo.referenceHR === 'SI'"
                class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-t-5"
                ng-show="domesticPaymentSlo.tempDompObj.referenceModelSelect.split(' ').join('') === '' && domesticPaymentSlo.tempDompObj.formSubmitted"
                translate="domesticPayment.referenceModelInputSIError"></span>

            </div>
          </section>



          <div class="row ">
            <div class="col-md-12">
              <div class="form-group">
                <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput"
                  translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
                <!--<div class="p-0" ng-switch="domesticPaymentSlo.referenceHR">-->
                <input
                  ng-readonly="domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment"
                  type="text" class="form-control input-field" ng-if="domesticPaymentSlo.referenceHR == 'NRC'"
                  ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.standardInput}}" name="purposeOfPaymentInput"
                  id="purposeOfPaymentInput" maxlength="140" ng-maxlength="140"
                  ng-model="domesticPaymentSlo.tempDompObj.purposeOfPaymentInput" required
                  ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                  ng-blur="domesticPaymentSlo.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentSlo.tempDompObj.purposeOfPaymentInput)">
                <input
                  ng-readonly="domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment"
                  type="text" class="form-control input-field"
                  ng-if="domesticPaymentSlo.referenceHR == 'SI' && domesticPaymentSlo.tempDompObj.referenceModelSelect == '99'"
                  ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.standardInput}}" name="purposeOfPaymentInput"
                  id="purposeOfPaymentInput" maxlength="140" ng-maxlength="140"
                  ng-model="domesticPaymentSlo.tempDompObj.purposeOfPaymentInput" required
                  ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                  ng-blur="domesticPaymentSlo.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentSlo.tempDompObj.purposeOfPaymentInput)">
                <input
                  ng-readonly="domesticPaymentSlo.disableAllFields && domesticPaymentSlo.disableAllFieldsForProcessedPayment"
                  type="text" class="form-control input-field"
                  ng-if="domesticPaymentSlo.referenceHR != 'NRC' && domesticPaymentSlo.tempDompObj.referenceModelSelect != '99'"
                  ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.standardInput}}" name="purposeOfPaymentInput"
                  id="purposeOfPaymentInput" maxlength="140" ng-maxlength="140"
                  ng-model="domesticPaymentSlo.tempDompObj.purposeOfPaymentInput" required
                  ng-class="{'domp-error': domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentSlo.tempDompObj.formSubmitted}"
                  ng-blur="domesticPaymentSlo.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentSlo.tempDompObj.purposeOfPaymentInput)">
                <span class="c-red"
                  ng-show="domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                  translate="internalTransfer.paymentPurposeInputError"></span>
                <span class="c-red"
                  ng-show="domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$error.maxlength && domesticPaymentSlo.tempDompObj.formSubmitted">{{'domesticPayment.paymentPurposeLengthError1'|translate}}
                  {{domesticPaymentSlo.purposeMaxlengthValue}}
                  {{'domesticPayment.paymentPurposeLengthError2'|translate}}</span>

                <!--</div>-->
              </div>
            </div>
          </div>
        </div>




        <section class="row flex-row flex-column-xs p-t-15">
          <div class="p-l-30"
            ng-if="APP_GLOBAL_SETTINGS.defaultData.InstantPayment === 'Enabled' && domesticPaymentSlo.tempDompObj.amountInput < 15000">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
              <input ng-readonly="domesticPaymentSlo.disableAllFields" type="checkbox"
                ng-model="domesticPaymentSlo.tempDompObj.isInstantPaymentCheckBox"
                ng-click="domesticPaymentSlo.tempDompObj.isUrgentPaymentCheckbox=false">
              <i class="input-helper"></i>
              <!-- <span uib-tooltip="{{'domesticPayment.urgentPaymentInfo'| translate}}" tooltip-append-to-body="true"
                tooltip-trigger="'mouseenter'"
                tooltip-placement="top">{{'domesticPayment.selectInstantLabelSLO'| translate}}
              </span> -->
              <span>{{'domesticPayment.selectInstantLabelSLO'| translate}}
              </span>
            </label>
          </div>

          <div class="p-l-30">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value"
              ng-if="domesticPaymentSlo.hideUrgency === false">
              <input ng-readonly="domesticPaymentSlo.disableAllFields" type="checkbox"
                ng-change="domesticPaymentSlo.showInfo();"
                ng-model="domesticPaymentSlo.tempDompObj.isUrgentPaymentCheckbox"
                ng-click="domesticPaymentSlo.tempDompObj.isInstantPaymentCheckBox=false">
              <i class="input-helper"></i>
              <span uib-tooltip="{{'domesticPayment.urgentPaymentInfo'| translate}}" tooltip-append-to-body="true"
                tooltip-trigger="'mouseenter'"
                tooltip-placement="top">{{'domesticPayment.selectUrgentLabelSLO'| translate}}
              </span>
            </label>
          </div>


          <div class="p-l-30" style="line-height: 39px; text-decoration: underline">
            <a ng-href="{{domesticPaymentSlo.defaultData.ProvisionLink}}" target="_blank"
              translate="domesticPayment.timetableForProvisionLink">
              Check the bank charges
            </a>
          </div>
          <div class="p-l-30 " style="line-height: 39px; text-decoration: underline">
            <a ng-href="{{domesticPaymentSlo.defaultData.SepaCodesLink}}" target="_blank"
              translate="domesticPayment.SEPAcodesLink">
            </a>
          </div>
          <div class="p-l-30 " style="line-height: 39px; text-decoration: underline">
            <a ng-href="{{domesticPaymentSlo.defaultData.BankChargesLink}}" target="_blank"
              translate="domesticPayment.checkBankCharges">
            </a>
          </div>
        </section>
        <section class="row clearfix p-15">
          <div class="form-group">
            <div class="col-md-6">
              <button class="btn btn-primary pull-left" type="button" translate="payments.cancel"
                data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-default pull-right" type="button" translate="domesticPayment.confirmBtnSlo"
                ng-click="domesticPaymentSlo.submitPayment();"
                ng-disabled="domesticPaymentSlo.nextButtonPressed || domesticPaymentSlo.secondValidateInProgress || !domesticPaymentSlo.dataLoaded"></button>
            </div>
          </div>
        </section>

      </form>
    </div>
  </div>
  <!-- Step 2 and 3 -->
  <div ng-if="domesticPaymentSlo.currentStep === 2" class="domp-step-2"
    ng-include="'payments/domestic-payment-resume.tpl.html'"></div>
  <div ng-if="domesticPaymentSlo.currentStep == 3" class="domp-step-2"
    ng-include="'payments/domestic-payment-final.tpl.html'">
  </div>
</div>
