(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name settings.factory:SettingsService
   *
   * @description
   *
   */
  angular
    .module('settings')
    .factory('SettingsService', SettingsService);

  function SettingsService($q, $http,EndpointsService) {
    var SettingsServiceBase = {};

    SettingsServiceBase.changePassword = function (object) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.put(response.links.authenticationAdmin_changePassword,object)
                .then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (msg, code) {
                    deferred.reject(msg.data);
                });
        }, function (error) {
            deferred.reject(error.data);
        });
        return deferred.promise;
    };

    SettingsServiceBase.getSmsAndNotifications = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.smsAndEmailNotification, {params: params})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.changeUserBasedNotifications = function (object) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.userBasedNotificationOrder,object)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.changeTrrBasedNotifications = function (object) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.trrBasedNotificationOrder,object)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.getAdditionalTransactionConfirmation = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.additionalTransactionConfirmation)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.changeAdditionalTransactionConfirmation = function (object) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.additionalTransactionConfirmation,object)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.getPeriodTypes = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.notificationOrderPeriodType)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.getTimeTypes = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.notificationOrderTimeType)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.getPeriodOfWeekTypes = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.notificationOrderPeriodOfWeekType)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.getPeriodOfMonthTypes = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.notificationOrderPeriodOfMonthType)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    // ---------------------------- ## Security settings ## ----------------------------//
    // ---------------------------- ##       START       ## ----------------------------//
    // ---------------------------- ## Security settings ## ----------------------------//

    SettingsServiceBase.securitySettingsAll = function (pageSize, token, serviceId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

        var link = response.links.securitySettings + "?pagesize={pageSize}&token={token}&serviceId={serviceId}".replace("{pageSize}", pageSize).replace("{token}", token).replace("{serviceId}", serviceId);

        $http.get(link)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsPost = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.securitySettings, params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsGetChallenge = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.securitySettings_getChallenge)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsGetChallenges = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.securitySettings_getChallenges)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsCheckTransactionVerificationResponse = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_checkTransactionVerificationResponse, params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsDelete = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_delete, params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsGetCompanies = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.securitySettings_getCompanies, {params: params})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    
    SettingsServiceBase.securitySettingsId = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.securitySettings_id, {params: params})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsIsSecurityAccepted = function (token) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

        var property = "securitySettings_isSecurityAccepted?token={token}&";

        var url = response.links[property].replace("{token}&", token);

        $http.get(url)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsSetSecurityAccepted = function (token) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_setSecurityAccepted, {token: token})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsIsSecurityMessageSeen = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.securitySettings_isSecurityMessageSeen)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsSetSecurityMessageSeen = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_setSecurityMessageSeen, {on: true})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsLogout = function (token) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_logout, {token: token})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SettingsServiceBase.securitySettingsSettingsOnOff = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.securitySettings_settingsOnOff, params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    // ---------------------------- ## Security settings ## ----------------------------//
    // ---------------------------- ##       START       ## ----------------------------//
    // ---------------------------- ## Security settings ## ----------------------------//


    return SettingsServiceBase;
  }
}());
