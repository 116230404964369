<section id="main" ng-if="newEBookSavingsDivider.countryCode == 'hr'">
  <div class="container" ng-controller="NewEBookCtrl as newEBook">
    <div class="row exchange-rates">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding-on-phone">
        <div class="domp-header-wrapper p-r-20 p-l-30" ng-show="newEBook.currentStep != 3">
          <div class="row domp-header-row">

            <h2 class="f-28" translate="ebook.header">Create new eSaving book</h2>

          </div>
        </div>

        <div class="domp-body-wrapper">
          <div ng-show="newEBook.currentStep === 1" class="domp-step-1">
            <form novalidate name="newEBook.newEBookForm">
              <div class="ebook-section col-lg-12 col-md-12 col-sm-12">


                <div class="row">
                  <!-- FORM LEFT SIDE -->
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label class="domp-form-label" translate="ebook.name">Name</label>

                    <input type="text" class="form-control input-field " name="nameInput" id="nameInput"
                      data-ng-model="newEBook.tempObj.nameInput" required
                      ng-class="{'domp-error': newEBook.newEBookForm.nameInput.$invalid && newEBook.tempObj.formSubmitted}">
                    <span class="c-red"
                      ng-show="newEBook.newEBookForm.nameInput.$error.required && newEBook.tempObj.formSubmitted"
                      translate="ebook.nameError"></span>

                    <div class="row p-t-20">
                      <label class="checkbox checkbox-inline">
                        <input type="checkbox" data-ng-model="newEBook.tempObj.checked">
                        <span class="input-helper">
                          <span translate="ebook.iAccept"></span>
                          <a ng-click="newEBook.manageDocumentDownload('DocumentRequest', null, newEBook.tempObj.fromAccountSelect.id)"
                            translate="ebook.requestForEBook"
                            style="font-size: 14px;font-weight:500; text-decoration: underline;">
                            Zahtjev za otvaranjem eknjižice
                          </a>
                        </span>
                      </label>
                    </div>

                    <div class="row p-t-20">
                      <label class="checkbox checkbox-inline">
                        <input type="checkbox" data-ng-model="newEBook.tempObj.checked2">
                        <span class="input-helper">
                          <span translate="ebook.iAccept"></span>
                          <a ng-click="newEBook.pushGtmLinkEvent(newEBook.additionalLinks.link5)"
                            ng-href="{{newEBook.additionalLinks.link5}}" target="_blank" translate="ebook.informationFormOnDeposits"
                            style="font-size: 14px;font-weight:500; text-decoration: underline;">
                            Obrazac informacija o sustavu osiguranja depozita
                          </a>
                        </span>
                      </label>
                    </div>

                    <div class="row p-t-20">
                      <label class="checkbox checkbox-inline">
                        <input type="checkbox" data-ng-model="newEBook.tempObj.checked3">
                        <span class="input-helper">
                          <span translate="ebook.iAccept"></span>
                          <a ng-click="newEBook.pushGtmLinkEvent(newEBook.additionalLinks.link4)"
                            ng-href="{{newEBook.additionalLinks.link4}}" target="_blank" translate="ebook.termsAndConditions"
                            style="font-size: 14px;font-weight:500; text-decoration: underline;">
                            I accept General terms and conditions
                          </a>
                        </span>
                      </label>
                    </div>

                    <div class="row p-t-20">
                      <label class="checkbox checkbox-inline">
                        <input type="checkbox" data-ng-model="newEBook.tempObj.checked4">
                        <span class="input-helper">
                          <span translate="ebook.iAccept"></span>
                          <a ng-click="newEBook.manageDocumentDownload('PreContractForm', null)"
                            translate="ebook.termsAndConditions2"
                            style="font-size: 14px;font-weight:500; text-decoration: underline;">
                            I accept General terms and conditions
                          </a>
                        </span>
                      </label>
                    </div>
                    <div class="row p-t-20">
                      <label class="checkbox checkbox-inline">
                        <input type="checkbox" data-ng-model="newEBook.tempObj.checked5">
                        <span class="input-helper">
                          <span translate="ebook.iAccept"></span>
                          <a ng-click="newEBook.manageDocumentDownload('PreContractInfo', null)"
                            translate="ebook.preContractInfo"
                            style="font-size: 14px;font-weight:500; text-decoration: underline;">
                            I accept General terms and conditions
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <!-- FORM RIGHT SIDE -->
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

                    <!-- CUSTOM FROM Accounts dropdown-->
                    <label class="domp-form-label" translate="ebook.fromAccount"></label>
                    <div class="accounts-dropdown m-b-10" ng-if="newEBook.accountsFrom.length > 0">
                      <div class="debit-account-widget dashboard-widget-item"
                        data-ng-click="newEBook.toggleFromAccount();"
                        ng-style="newEBook.tempObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + newEBook.tempObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                            {{newEBook.tempObj.fromAccountSelect.friendlyName}} </b>
                          <span class=" col-xs-2 p-0 t-a-r c-white favorite-star-container">
                            <i class="zmdi " ng-class="{'zmdi-star favorite-account':newEBook.tempObj.fromAccountSelect.isFavorite,
                                      'zmdi-star-outline':!newEBook.tempObj.fromAccountSelect.isFavorite}"></i>
                          </span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{newEBook.tempObj.fromAccountSelect.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{newEBook.tempObj.fromAccountSelect.availableBalance | number:2}}
                          {{newEBook.tempObj.fromAccountSelect.currencySymbol}}
                        </p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                        <span class="caret-icon">
                          <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                        </span>
                      </div>
                      <div class="from-accounts-list m-l-15" ng-show="newEBook.fromAccountDropdown" scrollable>
                        <div class="accounts-list-item p-0" data-ng-repeat="item in newEBook.accountsFrom"
                          data-ng-click="newEBook.setAccountFrom(item);">
                          <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                            ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                            <h4 class="no-wrap-text bold-font m-b-0">
                              <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                              <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                  ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                            </h4>
                            <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                              {{item.availableBalance | number:2}} {{item.currencySymbol}}
                            </p>
                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accounts-dropdown m-b-10" ng-if="newEBook.accountsFrom.length == 0">
                      <div class="debit-account-widget dashboard-widget-item" style="height: 114px; line-height: 114px"
                        ng-style="newEBook.tempObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + newEBook.tempObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        No available accounts
                      </div>
                    </div>

                  </div>
                </div>

                <!-- LINKS -->

                <div class="row p-t-20">
                  <label class="m-l-20">
                    <span class="input-helper" style="font-size: 14px;font-weight:bold;" translate="ebook.linksHeader">
                      Links header
                    </span>
                    <span class="input-helper" style="font-size: 14px;font-weight:bold;" />
                      <a ng-click="newEBook.pushGtmLinkEvent('https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo')"
                      ng-href="{{'https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo'}}" target="_blank" translate="ebook.linksHeaderLink"
                        style="font-size: 14px;font-weight:bold; text-decoration: underline;">
                        Links header link
                      </a>
                    </span>
                  </label>
                </div>

                <!-- <div class="row p-t-20">
                  <label class="checkbox-inline">
                    <span class="input-helper"><a ng-click="newEBook.pushGtmLinkEvent(newEBook.additionalLinks.link1)"
                        ng-href="{{newEBook.additionalLinks.link1}}" target="_blank" translate="ebook.link1"
                        style="font-size: 14px;font-weight:500; text-decoration: underline;">I accept General terms and
                        conditions</a></span>
                  </label>
                </div>
                <div class="row p-t-20">
                  <label class="checkbox-inline">
                    <span class="input-helper"><a ng-click="newEBook.pushGtmLinkEvent(newEBook.additionalLinks.link2)"
                        ng-href="{{newEBook.additionalLinks.link2}}" target="_blank" translate="ebook.link2"
                        style="font-size: 14px;font-weight:500; text-decoration: underline;">I accept General terms and
                        conditions</a></span>
                  </label>
                </div>

                <div class="row p-t-20">
                  <label class="checkbox-inline">
                    <span class="input-helper"><a ng-click="newEBook.pushGtmLinkEvent(newEBook.additionalLinks.link3)"
                        ng-href="{{newEBook.additionalLinks.link3}}" target="_blank" translate="ebook.link3"
                        style="font-size: 14px;font-weight:500; text-decoration: underline;">I accept General terms and
                        conditions</a></span>
                  </label>
                </div> -->

                <!-- BUTTON -->
                <div class="row p-t-20">
                  <div class="col-sm-12 bold-font">
                    <div class="pull-right" style="width: 250px;">
                      <button class="btn btn-default app-btn-blue w-100 domp-review-and-submit-btn"
                        translate="ebook.createEbook" data-ng-click="newEBook.newRequestSubmit();"
                        ng-disabled="(newEBook.tempObj.checked===false || newEBook.tempObj.checked2===false || newEBook.tempObj.checked3===false || newEBook.tempObj.checked4===false)">
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
            <div class="col-sm-12 p-0" ng-if="newEBook.displayeBookSavingRequest">
              <div data-ng-include="'accounts/tl-ebook-savings.tpl.html'">
              </div>
            </div>
          </div>
          <div ng-show="newEBook.currentStep === 2">
            <div data-ng-include="'accounts/new-e-book-resume.tpl.html'">
            </div>
          </div>
          <div ng-show="newEBook.currentStep === 4">
              <div class="exchange-rate-header m-l-25">
                <div class="row">
                  <div class="col-sm-6 col-xs-12 f-20" translate="ebook.signTitle"></div>
                </div>
              </div>
              <div class="col-xs-12 m-t-30 m-b-30">
                <addiko-mtoken-sign
                  show-cancel="false"
                  placeholder="newEBook.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
                  qr-challenge="newEBook.qrChallenge" show-challenge="newEBook.showChallenge" 
                  challenge1="newEBook.challengeFirstCode" challenge2="newEBook.challengeSecondCode"></addiko-mtoken-sign>
              </div>
            </div>

          <div ng-show="newEBook.currentStep === 3">
            <div data-ng-include="'accounts/new-e-book-final.tpl.html'">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<div ng-if="newEBookSavingsDivider.countryCode == 'sl'" data-ng-include="'accounts/savings-slo.tpl.html'"></div>
