<section id="main" ng-controller="OrdersSloCtrl as vm">
  <div class="container-fluid">
    <div class="domp-header-wrapper" ng-hide="vm.step === 'complete'">
      <div class="domp-header-row">
        <h2 class="f-28" ng-if="vm.step === 'order'" translate="orders.title"></h2>
        <span ng-if="vm.step === 'confirm'">
            <h2 ng-if="vm.authorisationTypeId == 1" class="f-28" translate="orders.permanentAthorisation"></h2>
            <h2 ng-if="vm.authorisationTypeId == 0" class="f-28" translate="orders.cancelAuthorisation"></h2>
        </span>
      </div>
    </div>

    <!-- ================  -->
    <!-- START: First step -->
    <!-- ================  -->
    <div ng-if="vm.step === 'order'">
      <!-- START: Poblastilo select -->
      <div class="row p-l-15 p-r-15 m-b-20">
        <select class="form-control input-field p-l-15 p-r-10" name="orderSelect" id="orderSelect"
                ng-model="vm.authorisationTypeId"
                ng-options="option.id as option.description | translate for option in vm.authorizationTypeOptions"
                ng-change="vm.onAuthorisationTypeChange()">
        </select>
      </div>
      <!-- END: Poblastilo select -->

      <!-- START: Note -->
      <div class="row" ng-if="vm.authorisationTypeId == 1">
        <div class="col-md-12">
          <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
            <div class="row">
              <div class="col-xs-12"></div>
              <div class="row">
                <div class="col-xs-12">
                  <span translate="orders.note1Slo"></span>
                  <a href="https://www.addiko.si/ceniki" target="_blank" class="underline"><span
                    translate="orders.note2SloLink"></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Note -->

      <form novalidate name="vm.orderForm">
        <!-- START: Select order type -->
        <div class="row p-l-15 p-r-15">
          <select class="form-control input-field p-l-15 p-r-10" name="orderSelect" id="orderSelect"
                  ng-model="vm.orderType" ng-change="vm.setOrderType()"
                  ng-options="'orders.types.'+orderType.typeId | translate for orderType in vm.orderTypes track by orderType.typeId">
          </select>
        </div>
        <!-- END: Select order type -->

        <div class="row">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row p-15">
                <!-- CUSTOM ACCOUNTS DROPDOWN -->
                <div class="col-sm-6 domp-acc-dropdown">
                  <label class="domp-form-label" translate="payments.fromAccount"></label>

                  <div class="accounts-dropdown">
                    <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                         data-ng-click="vm.fromAccountDropdown = !vm.fromAccountDropdown"
                         ng-style="vm.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + vm.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                          {{vm.fromAccountSelect.friendlyName}} </b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                          <i class="zmdi" ng-class="{
                              'zmdi-star favorite-account'
                                :account.isFavorite, 'zmdi-star-outline':!account.isFavorite}"></i>
                        </span>
                      </h4>
                      <p ng-if="vm.countryCode == 'sl'" class="m-b-0 m-t-0 iban-style">{{vm.fromAccountSelect.iban |
                        formatIban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{vm.fromAccountSelect.availableBalance | currency: ""}} {{vm.fromAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon">
                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                      </span>
                    </div>
                    <div class="from-accounts-list m-l-0" ng-show="vm.fromAccountDropdown" scrollable>
                      <div class="accounts-list-item p-0"
                           data-ng-repeat="item in vm.accountList"
                           data-ng-click="vm.setAccountFrom(item);">
                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                             ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                          <h4 class="no-wrap-text bold-font m-b-0">
                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                                ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                          </h4>
                          <p ng-if="vm.countryCode == 'sl'" class="m-b-0 m-t-0 iban-style">{{item.iban |
                            formatIban}}</p>
                          <span class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">{{item.availableBalance | currency: ""}} {{item.currencySymbol}}</span>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="domp-form-label-review" for="amountInput"
                               translate="domesticPayment.amountInputLabel"></label>
                        <div class="input-group">

                          <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" max-amount="999999999.99"
                                 type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                                 placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask
                                 maxlength="12" ng-model="vm.amountInput" required
                                 ng-class="{'domp-error': vm.orderForm.amountInput.$invalid && vm.formSubmitted}"
                                 ng-maxlength="12"/>
                          <span class="input-group-btn">
                            <button type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                                    ng-if="vm.countryCode != 'sr'" style="border:1px solid #062a42;">
                              {{vm.defaultCurrency}}
                            </button>
                          </span>
                        </div>
                        <span class="c-red"
                              ng-show="vm.orderForm.amountInput.$error.maxAmount && vm.formSubmitted">
                            <span translate="termDeposit.wrongMaxDepositAmount"></span>
                            {{ 999999999.99 | currency: ""}} {{termDeposit.model.currency}}.
                        </span>
                        <span class="c-red" ng-if="vm.orderForm.amountInput.$error.required && vm.formSubmitted"
                              translate="internalTransfer.amountInputError"></span>
                      </div>
                    </div>
                  </div>

                  <div class="row" ng-if="vm.authorisationTypeId == 1">
                      <span class="p-t-15">
                      <div class="col-md-12">
                       <label class="domp-form-label" for="dateBegin" translate="orders.paymentDayOfEachMonth"></label>
                      </div>
                      <div class="col-xs-5">
                        <div class="form-group">
                          <select ng-model="vm.dayOfBeginning" ng-change="vm.modifyDate();"
                                  class="form-control input-group"
                                  ng-options="day.value as day.text for day in vm.dayOfBeginningSelectOptions">
                          </select>
                        </div>
                      </div>
                        <div class="col-xs-1 p-0">
                          <i class="zmdi zmdi-alert-circle-o zmdi-hc-2x p-t-5"
                             uib-tooltip="{{'orders.nonWorkingDayMsg'| translate}}" tooltip-append-to-body="true"
                             tooltip-trigger="'mouseenter'"
                             tooltip-placement="top"></i>
                        </div>

                      </span>
                  </div>
                  <div class="row">
                    <div class="col-sm-6" ng-if="vm.authorisationTypeId == 1">
                      <div class="form-group m-0">
                        <label class="domp-form-label" for="dateBegin" translate="orders.dateBegin"></label>
                        <div class="input-group">
                          <input type="text" class="form-control input-field" name="dateBegin" id="dateBegin"
                                 ng-change="vm.dateOfBeginningChange()"
                                 show-button-bar="false"
                                 uib-datepicker-popup="shortDate" data-ng-model="vm.dateBegin" show-weeks="false"
                                 date-disabled="vm.dateDisabled(date, mode)"
                                 is-open="datePickerBeginOpened" datepicker-options="vm.dateOfBeginningOptions"
                                 ng-class="{ 'domp-error' : vm.orderForm.dateBegin.$invalid && vm.formSubmitted}"
                                 required readonly>
                          <span class="input-group-btn">
                              <button data-ng-click="datePickerBeginOpened = !datePickerBeginOpened" type="button"
                                      class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                      style="border:1px solid #062a42;">
                                <i class="icon icon-calendar" style="font-size: 21px;"></i>
                              </button>
                            </span>
                        </div>
                      </div>
                      <span class="c-red" ng-show="vm.orderForm.dateBegin.$error.required && vm.formSubmitted"
                            translate="orders.dateRequired"></span>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group m-0">
                        <label class="domp-form-label" for="dateCancel" translate="orders.dateCancel"></label>
                        <div class="input-group date-to">
                          <input type="text" class="form-control input-field" name="dateCancel" id="dateCancel"
                                 uib-datepicker-popup="shortDate"
                                 data-ng-model="vm.dateCancel"
                                 show-weeks="false"
                                 is-open="datePickerEndOpened"
                                 date-disabled="vm.disabled(date, mode)"
                                 current-text="{{'core.period.today' | translate}}"
                                 clear-text="{{'core.datePicker.clearBtn' | translate}}"
                                 close-text="{{'core.datePicker.doneBtn' | translate}}"
                                 datepicker-options="vm.dateOfCancellationOptions"
                                 ng-class="{ 'domp-error' : vm.orderForm.dateCancel.$invalid && vm.formSubmitted}"
                                 required
                                 readonly
                                 popup-placement="bottom-right"/>
                          <span class="input-group-btn">
                              <button data-ng-click="datePickerEndOpened = !datePickerEndOpened" type="button"
                                      class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                      style="border:1px solid #062a42;">
                                <i class="icon icon-calendar" style="font-size: 21px;"></i>
                              </button>
                            </span>
                        </div>
                      </div>
                      <span class="c-red" ng-show="vm.orderForm.dateCancel.$error.required && vm.formSubmitted"
                            translate="orders.dateRequired"></span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!--RECIPIENT IBAN  / RECIPIENT (company/name and address) START -->

        <div class="row">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row  p-15">
                <div class="col-sm-6">
                  <label ng-if="vm.countryCode == 'sl' && vm.orderType.typeId !== 'trr'" class="domp-form-label"
                         for="beneficiaryIBAN" translate="orders.beneficiaryIBANforSavings"></label>
                  <label ng-if="vm.countryCode != 'sl' || vm.orderType.typeId === 'trr'" class="domp-form-label"
                         for="beneficiaryIBAN" translate="orders.beneficiaryIBAN"></label>
                  <input ng-if="vm.countryCode == 'sl' && vm.orderType.typeId === 'trr'" type="text"
                         class="form-control input-field"
                         capitalize
                         ui-mask="{{vm.uiMask}}"
                         ui-mask-placeholder
                         ui-mask-placeholder-char="space"
                         ui-options="vm.uiMaskOptions"
                         maxlength="34"
                         ng-model="vm.beneficiaryIBAN"
                         ng-class="{ 'domp-error' : vm.orderForm.beneficiaryIBAN.$invalid && vm.formSubmitted}"
                         name="beneficiaryIBAN" id="beneficiaryIBAN"
                         required/>

                  <select ng-if="vm.countryCode == 'sl' && vm.orderType.typeId !== 'trr'"
                          ng-model="vm.selectedSavingAccount"
                          ng-change="vm.modifyDate();"
                          class=" h-45 form-control input-gro1up"
                          ng-options="(account.iban + ' ' + account.friendlyName) for account in vm.savingAccounts">
                  </select>

                  <span class="c-red" ng-show="vm.orderForm.beneficiaryIBAN.$error.required && vm.formSubmitted"
                        translate="domesticPayment.receiverAccountError"></span>
                </div>
                <div ng-if="vm.countryCode != 'sl' || vm.orderType.typeId === 'trr'" class="col-sm-6">
                  <label ng-if="vm.countryCode != 'sl'" class="domp-form-label" for="beneficiaryName"
                         translate="orders.beneficiaryName"></label>
                  <label ng-if="vm.countryCode === 'sl'" class="domp-form-label" for="beneficiaryName"
                         translate="domesticPayment.toPayee2"></label>
                  <input type="text" class="form-control input-field" name="beneficiaryName" id="beneficiaryName"
                         required ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPS}}"
                         ng-class="{ 'domp-error' : vm.orderForm.beneficiaryName.$invalid && vm.formSubmitted}"
                         maxlength="{{vm.inputOptions.beneficiaryName.maxLength}}"
                         ng-model="vm.beneficiaryName">
                  <span class="c-red" ng-show="vm.orderForm.beneficiaryName.$error.required && vm.formSubmitted"
                        translate="orders.beneficiaryNameRequired"></span>
                </div>
              </div>
              <div class="row  p-15"
                   ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && vm.orderType.typeId === 'trr'">
                <div class="col-xs-12 col-sm-6 col-sm-offset-6">
                  <input type="text" class="form-control input-field" name="receviersAddress" id="receviersAddress"
                         required ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPS}}"
                         ng-class="{ 'domp-error' : vm.orderForm.receviersAddress.$invalid && vm.formSubmitted}"
                         maxlength="{{vm.inputOptions.receviersAddress.maxLength}}"
                         ng-model="vm.receviersAddress">
                  <span class="c-red" ng-show="vm.orderForm.receviersAddress.$error.required && vm.formSubmitted"
                        translate="orders.receviersAddressRequired"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Show if Authorisation -->
        <div class="row" ng-if="vm.authorisationTypeId == 1">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row  p-15">
                <div class="col-sm-12 p-t-10">
                  <div class="form-group m-b-0">
                    <label ng-if="vm.countryCode == 'sl'" class="domp-form-label" for="description"
                           translate="orders.purpose"></label>
                    <input type="text" class="form-control input-field" name="description" id="description" required
                           ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPS}}"
                           maxlength="{{vm.descriptionMaxLength}}"
                           ng-class="{ 'domp-error' : vm.orderForm.description.$invalid && vm.formSubmitted}"
                           ng-model="vm.description"/>
                  </div>
                  <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                        ng-show="vm.orderForm.description.$error.required && vm.formSubmitted"
                        translate="orders.descriptionRequired"></span>
                </div>
              </div>

              <!--<div class="row  p-15">-->
              <!--<div class="col-sm-6">-->
              <!--<span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"-->
              <!--ng-show="vm.orderForm.referenceNumber.$error.required && vm.formSubmitted"-->
              <!--translate="domesticPayment.referenceModelInputError"></span>-->
              <!--</div>-->
              <!--</div>-->

              <div class="row p-15">
                <div class="col-xs-12">
                  <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                    <input type="checkbox"
                           ng-model="vm.agreedWithTerms">
                    <i class="input-helper"></i>
                    <span>{{'orders.agreeTerms'| translate}}
                    </span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row p-10">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary" type="button" translate="orders.cancel" data-ui-sref="dashboard">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button ng-if="vm.authorisationTypeId == 1" class="btn btn-default pull-right" type="submit"
                    ng-disabled="!vm.agreedWithTerms || vm.nextButtonPressed"
                    ng-click="vm.openPermanentOrder()">
              <span translate="domesticPayment.confirmBtnSlo"></span>
            </button>
            <button ng-if="vm.authorisationTypeId == 0" class="btn btn-default pull-right" type="submit"
                    ng-click="vm.validateClosePermanentOrder()">
              <span translate="domesticPayment.confirmBtnSlo"></span>
            </button>
          </div>
        </div>
      </form>
      <!-- TIMELINE CONTAINER -->
      <div data-ng-include="'orders/orders-overview-timeline.tpl.html'"></div>
      <!--<div ng-if="vm.authorisationTypeId == 0" data-ng-include="'orders/closing-orders-overview-timeline.tpl.html'"></div>-->
      <!-- END TIMELINE -->
    </div>
    <!-- ================  -->
    <!-- END: First step -->
    <!-- ================  -->

    <!-- =================== -->
    <!-- START: Confirm step -->
    <!-- =================== -->
    <div ng-if="vm.step === 'confirm'">
      <div class="row">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                    <label class="domp-form-label-review" translate="payments.fromAccount"></label>
                </span>
                <p>
                  <!--<label ng-if="vm.countryCode != 'sl'" class="domp-form-label-resume">{{vm.fromAccountSelect.iban}}</label>-->
                  <label class="domp-form-label-resume">{{vm.insertedOrderObject.fromAccountNumber}}</label>
                </p>
              </div>
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>

                </span>
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.amount | number:2}} {{vm.insertedOrderObject.currencySymbol}}
                </label>
              </div>
            </div>
            <div ng-if="vm.authorisationTypeId == 1" class="row">
              <div class="col-sm-6 col-xs-offset-6">
                <span class="invoice-inline-title text-muted">
                 <label class="domp-form-label-review" translate="orders.paymentDayOfEachMonth"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.dayOfBeginning}}.
                </label>
              </div>
            </div>
            <div class="row p-t-10">
              <div class="col-md-6"></div>
              <div ng-if="vm.authorisationTypeId == 1" class="col-sm-3">
                <span class="invoice-inline-title text-muted">
                 <label class="domp-form-label-review" translate="orders.dateBegin"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.dateBegin| date: 'shortDate' : '+0200'}}
                </label>
              </div>
              <div class="col-sm-3">
                <span class="invoice-inline-title text-muted">
                     <label class="domp-form-label-review" translate="orders.dateCancel"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.dateCancel| date: 'shortDate' : '+0200'}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                  <label ng-if="vm.countryCode == 'sl' && vm.orderType.typeId !== 'trr'" class="domp-form-label"
                         for="beneficiaryIBAN" translate="orders.beneficiaryIBANforSavings"></label>
                  <label ng-if="vm.countryCode != 'sl' || vm.orderType.typeId === 'trr'" class="domp-form-label"
                         for="beneficiaryIBAN" translate="orders.beneficiaryIBAN"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.payeeAccount}}
                </label>
              </div>
              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' || vm.orderType.typeId === 'trr'"
                   class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                     <label ng-if="vm.countryCode != 'sl'" class="domp-form-label-review"
                            translate="orders.beneficiaryName"></label>
                     <label ng-if="vm.countryCode == 'sl'" class="domp-form-label-review"
                            translate="domesticPayment.toPayee2"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.payeeName}}
                </label>
              </div>
            </div>
            <div ng-if="vm.countryCode == 'sl' && vm.orderType.typeId === 'trr'" class="row">
              <div class="col-sm-6 col-xs-offset-6">
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.payeeAddress}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" ng-if="vm.authorisationTypeId == 1">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                  <label class="domp-form-label-review" translate="orders.purpose"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.description}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" ng-if="!vm.orderSigned">
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 m-t-10">

          <button ng-if="vm.authorisationTypeId == 0" class="col-lg-3 col-md-3 col-sm-4 col-xs-4 btn btn-primary"
                  translate="domesticPayment.back" ng-click="vm.step = 'order'">
          </button>
          <button ng-if="vm.authorisationTypeId == 1" class="col-lg-3 col-md-3 col-sm-4 col-xs-4 btn btn-primary"
                  translate="domesticPayment.back" ng-click="vm.editOrder()">
          </button>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 m-t-10">
          <button ng-if="vm.authorisationTypeId == 1"
                  class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
                  ng-disabled="vm.confirmButtonPressed"
                  translate="domesticPayment.confirmBtn" ng-click="vm.sign()">
          </button>
          <button ng-if="vm.authorisationTypeId == 0"
                  class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
                  translate="domesticPayment.confirmBtn" ng-click="vm.confirmClosePermanentOrder()">
          </button>
        </div>
      </div>
      <!-- Container for signing form -->
      <div class="row m-b-10" ng-if="vm.orderSigned">
        <div class="col-xs-12 m-t-30">
          <form name="vm.challengeForm" novalidate>
            <div class="col-xs-12 m-b-20">
              <span class="f-w-bold">
          {{'signPayments.challengeDescription'| translate}}
        </span>
            </div>
            <div class="col-sm-4 col-xs-6">
              <span class="f-w-bold">{{'signPayments.challengeFirstCode'| translate}}</span>
              <input type="text" class="form-control input-field" disabled
                     data-ng-model="vm.insertedOrderObject.challenge">
            </div>
            <div class="col-sm-4 col-xs-6">
              <span class="f-w-bold">{{'signPayments.challengeSecondCode'| translate}}</span>
              <div class="input-group">
                <input type="{{vm.showTokenInput ? 'text' : 'password'}}" class="form-control input-field"
                       data-ng-model="vm.model.password2" ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
                       placeholder="{{'signPayments.challengeSecondCode'| translate}}" autofocus>
                <span class="input-group-btn">
            <button class="btn btn-default input-group-app-btn domp-btn-height"
                    data-ng-click="vm.showTokenInput = !vm.showTokenInput" style="border:1px solid #062a42;">
              <i class="icon icon-lock"></i>
            </button>
          </span>
              </div>
            </div>
            <div class="col-sm-4 col-xs-12 m-t-20">
              <button class="btn btn-default col-sm-8 col-xs-4 pull-right" ng-disabled="vm.confirmButtonPressed"
                      data-ng-click="vm.sign()" translate="signPayments.confirm">
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- =================== -->
    <!-- END: Confirm step -->
    <!-- =================== -->

    <!-- =================== -->
    <!-- START: Complete step -->
    <!-- =================== -->
    <div ng-if="vm.step === 'complete'" class="currency-conversion-msg">
      <div class="payment-successful-wrapper">
        <div class="row m-t-20">
          <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-9 m-t-30 m-b-30" style="max-width: 688px;">
            <h2 ng-if="vm.authorisationTypeId == 1" class="c-white f-28" translate="orders.completeText"></h2>
            <h2 ng-if="vm.authorisationTypeId == 0" class="c-white f-28" translate="orders.completeCancelText"></h2>
          </div>
        </div>
      </div>
      <div class="row m-t-20 p-l-10">
        <!-- Save template only for Domestic or foreign payments-->
        <div class="col-xs-12 col-sm-4">
          <a ng-click="vm.newOrder()">
            <span class="money-exchange-icon"><i class="icon icon-einvoiceadd zmdi-hc-2x"></i></span>
            <span class="f-18 bold-font" translate="orders.makeNew"></span>
          </a>
        </div>
      </div>
    </div>
    <!-- =================== -->
    <!-- END: Complete step -->
    <!-- =================== -->
  </div>
</section>
