<div class="ui-notification error-alert-container">
  <div class="error-alert-close-btn">
    <i class="icon icon-close"></i>
  </div>
  <div class="error-alert-mark">
    <i class="icon icon-warning"></i>
  </div>
  <div class="error-alert-content">
    <h3 ng-show="title" ng-bind-html="title" class="error-alert-title"></h3>
    <div class="message error-alert-body" ng-bind-html="message"></div>
  </div>
</div>
