(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name investments.controller:SalesReplacementFundsCtrl
   *
   * @description
   *
   */
  angular
      .module('investments')
      .controller('InvestmentCtrl', InvestmentCtrl);
  function InvestmentCtrl($rootScope, $scope, $timeout, $uibModal, $q, $filter, $state, orderType, configData,
      AccountsService, CTLocalizationService, InvestmentOrdersService, ValidationService,
                           HelperService, FundsService, AlertService, userHaveFunds, GoogleTagManagerHelperService, $location, NotificationService) {

    var vm = this;
    var editMode;
    var FundId;
    var action = $state.params.action;

    init();
    function init() {
      editMode = false;
      vm.orderId = $state.params.orderId;
      vm.fundId = $state.params.fundId;
      vm.orderTypeText = orderType;
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
      vm.dateOptions = {
        minDate: new Date(),
        maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
      };
      vm.amountTypes = [
        {id: 1, name: "invest.amountTypeAllShares"},
        {id: 2, name: "invest.amountTypeShareValue"},
        {id: 3, name: "invest.amountTypeShareQuantity"}
      ];

      vm.investmentAccountList = [];

      vm.model = {
        orderType: "",
        fromAccountSelect: "",
        fundSelect: "",
        fundSelectReplace: "",
        toAccountInput: "",
        dueDateInput: new Date(),
        amountInput: "",
        accountType: "1",
        comment: "",
        amountType: 2,
        invQuantity: null,
        investmentAccount: ""
      };

      switch (orderType) {
        case "new":
          vm.model.orderType = 1;
          break;
        case "sell":
          vm.model.orderType = 2;
          break;
        case "replace":
          vm.model.orderType = 3;
          break;
      }

      loadAllData();
    }

    function loadAllData() {
      var promises = [];
      promises.push(AccountsService.getOrderInvAccounts());
      if (vm.fundId) {
        promises.push(FundsService.getFundById(vm.fundId));
      }
      if (vm.orderId) {
        promises.push(InvestmentOrdersService.getInvestmentById(vm.orderId));
      }
      $q.all(promises)
          .then(function (responses) {
            // 1. response
            var investmentAccounts = responses[0].result;
            if (_.isArray(investmentAccounts) && investmentAccounts.length > 0) {
              vm.accountsFromList = investmentAccounts;
              vm.model.fromAccountSelect = investmentAccounts[0];
              // 2. response
              if (responses[1]) {
                var fund = responses[1];
                FundId = fund.fundId.toString();
                vm.model.accountType = (fund.accountType == "Investitor") ? "1" : "2"
              }

              // 3. response
              if (responses[2]) {

                editMode = (action == "copy") ? false : true;
                var data = responses[2];
                if (data.accountId) {
                  vm.model.fromAccountSelect = _.find(investmentAccounts, {accountId: data.accountId.toString()})
                } else {
                  vm.model.fromAccountSelect = investmentAccounts[0];
                }
                vm.model.orderType = data.orderType;
                vm.model.toAccountInput = data.toAccountInput;
                vm.model.dueDateInput = new Date(data.invAmountDate);
                vm.model.amountInput = data.invAmount;
                vm.model.comment = data.note;
                vm.model.amountType = data.amountType;
                vm.model.invQuantity = data.invAmount;


                if (data.amountType == 1) {
                  vm.model.invQuantity = null;
                  vm.model.amountInput = "";
                } else if (data.amountType == 2) {
                  vm.model.invQuantity = null;
                } else if (data.amountType == 3) {
                  vm.model.amountInput = "";
                }
              }
              /*
               * If buy/replace - sale: "N"
               * If sell - sale - "Y"
               */
              getFundAccounts({
                "request.sale": (vm.model.orderType == 2) ? "Y" : "N",
                "request.currencySymbol": vm.model.fromAccountSelect.currencySymbol
              }).then(function(){
                if (vm.model.orderType == 3 && editMode) {
                    vm.model.fundSelectReplace = _.find(vm.fundReplacementList, {accountId: data.invSellAccId});
                    vm.fundSelectReplaceChange(vm.model.fundSelectReplace);
                }
              });
            }
          }, function (error) {
            NotificationService.showMessage(error, 'error');
          });
    }

    function getFundAccounts(params) {
     return FundsService.getFundAccounts(params)
          .then(function (response) {

            vm.fundList = response.result;
            //Init funds
            if (FundId && !vm.model.fundSelect) {
              vm.model.fundSelect = _.find(response.result, {id: FundId});
            } else if (vm.model.fundSelect) {
              vm.model.fundSelect = _.find(response.result, {id: vm.model.fundSelect.id});
            }

            if (vm.model.fundSelect) {
//              vm.model.fundSelect = _.find(response.result, {id: vm.model.fundSelect.id});
              vm.onFundSelect(vm.model.fundSelect);
              vm.fundReplacementList = _.without(response.result, vm.model.fundSelect);
              // Init fundSelectReplace after refresh
              if (vm.model.orderType == 3 && vm.model.fundSelectReplace) {
                vm.model.fundSelectReplace = _.find(vm.fundList, {id: vm.model.fundSelectReplace.id, accountId: vm.model.fundSelectReplace.accountId})
              }
              vm.submitButtonDisabled = false;

            } else if (vm.model.orderType == 2 && !vm.model.fundSelect) {
              vm.submitButtonDisabled = true;
              vm.model.toAccountInput = "";

              AlertService.infoAlert({
                text: "invest.alertInfoNoFund"
              });
            }
          }, function (err) {
            NotificationService.showMessage(error, 'error');
          });
    }

    function remapDataForUpdate() {
      var data = {
        accountId: vm.model.fromAccountSelect.accountId,
        amountCurCode: vm.model.fromAccountSelect.currencySymbol,
        invAmountDate: vm.model.dueDateInput,
        note: vm.model.comment,
        invBuyAccId: "",
        invSellAccId: "",
        orderType: +vm.model.orderType,
        invAmount: vm.model.amountInput,
        amountType: vm.model.amountType,
        accType: vm.model.accountType,
        invQuantity: vm.model.invQuantity
      };
      if (vm.model.orderType == 1) {
        //data.invBuyAccId = (vm.model.fundSelect.accountId) ? vm.model.fundSelect.accountId : null;
        data.invBuyAccId = vm.model.investmentAccount ? vm.model.investmentAccount.accountId : null;
        data.invSellAccId = null;
      } else if (vm.model.orderType == 2) {
        data.invBuyAccId = null; // servis ne podrzava null
        //data.invSellAccId = vm.model.fundSelect.accountId;
        data.invSellAccId = vm.investmentAccount ? vm.model.investmentAccount.accountId : null;
        data.payeeTo = vm.model.fundSelect.id;
      } else if (vm.model.orderType == 3) {
        //data.invBuyAccId = vm.model.fundSelectReplace.accountId;
        data.invBuyAccId = vm.model.investmentAccount ? vm.model.investmentAccount.accountId : null;
        data.invSellAccId = vm.model.fundSelect.accountId;
        data.payeeFrom = vm.model.fundSelect.id;
      }

      if (vm.model.accountType != "1") {
        if (vm.model.orderType == 1) {
          //data.selectedAccId = (vm.model.fundSelect.accountId) ? vm.model.fundSelect.accountId : null;
          data.selectedAccId = vm.model.investmentAccount ? vm.model.investmentAccount.accountId : null;
        } else if (vm.model.orderType == 3) {
          //data.selectedAccId = vm.model.fundSelectReplace.accountId;
          data.selectedAccId = vm.model.investmentAccount ? vm.model.investmentAccount.accountId : null;
        }
      }
      return data;
    }

    function insertInvestment(updateData) {
      InvestmentOrdersService.insertInvestment(updateData)
          .then(function (response) {
            editMode = true;
            vm.submitButtonDisabled = false;
            vm.responseModel = response;
            $state.go('investments.form.resume', {orderType: $state.params.orderType, editMode: editMode});
          })
          .catch(function (error) {
            vm.submitButtonDisabled = false;
            NotificationService.showMessage(error, 'error');
          });
    }

    vm.onAmountTypeChange = function (amountType) {
      if (amountType == 1) {
        vm.model.invQuantity = null;
        vm.model.amountInput = "";
      } else if (amountType == 2) {
        vm.model.invQuantity = null;
      } else if (amountType == 3) {
        vm.model.amountInput = "";
      }
      vm.formSubmitted = false;
    };

    vm.dateDisabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    vm.setAccountFrom = function (account) {
      vm.model.fromAccountSelect = account;
      getFundAccounts({
        "request.sale": (vm.model.orderType == 2) ? "Y" : "N",
        "request.currencySymbol": vm.model.fromAccountSelect.currencySymbol
      });
    };

    vm.onFundSelect = function (fund) {
      //vm.model.toAccountInput = (vm.model.orderType != 3) ? fund.iban.replace(/-/g, '') : null;
      if (fund) {
        vm.model.toAccountInput = fund.iban.replace(/-/g, '');
//        vm.submitButtonDisabled = false;
      } else {
        vm.model.toAccountInput = "";
//        vm.submitButtonDisabled = true;
      }
      vm.getUserInvestmentAccounts();
    };

//    vm.checkAmountValue = function () {
//      var amount = document.getElementById('amountInput').value;
//      if (HelperService.parseAmountInput(amount) < 100) {
//        vm.fundsForm.amountInput.$setValidity("min", false);
//      } else {
//        vm.fundsForm.amountInput.$setValidity("min", true);
//      }
//    };

    vm.openBrochure = function () {
      window.open(configData.InvestmentGeneralTerms, '_blank');
    };

    vm.openBrochure2 = function () {
      window.open(configData.InvestmentRules, '_blank');
    };

    vm.submit = function () {
      vm.formSubmitted = true;
      if (vm.fundsForm.$invalid)
        return;
      vm.submitButtonDisabled = true;
      var updateData = remapDataForUpdate();
      if (orderType != 'sell') {
        checkUsersFund(updateData);
      } else {
        if (!editMode) {
          insertInvestment(updateData);
        } else {
          updateInvestmentData(updateData);
        }

      }
    };

    function insertInvestment(updateData) {
      return  InvestmentOrdersService.insertInvestment(updateData)
          .then(function (response) {
            editMode = true;
            vm.submitButtonDisabled = false;
            vm.responseModel = response;
            $state.go('investments.form.resume', {orderType: $state.params.orderType, editMode: editMode});
          var gtmObject = HelperService.processUrlForGTM('payment');
          GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
          })
          .catch(function (error) {
            vm.submitButtonDisabled = false;
            NotificationService.showMessage(error, 'error');
          });
    }


    function updateInvestmentData(updateData) {
      return  InvestmentOrdersService.updateInvestment((vm.responseModel) ? vm.responseModel.orderId : vm.orderId, updateData)
          .then(function (response) {
            vm.responseModel = response;
            vm.submitButtonDisabled = false;
            $state.go('investments.form.resume', {orderType: $state.params.orderType, editMode: true});
          })
          .catch(function (error) {
            vm.submitButtonDisabled = false;
            NotificationService.showMessage(error, 'error');
          });
    }


    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.delete = function () {
      AlertService.confirmationAlert()
          .then(function (result) {
            if (result) {
              vm.deleteButtonDisabled = true;
              InvestmentOrdersService.deleteInvestment(vm.responseModel.orderId)
                  .then(function (response) {
                    AlertService.deletedPaymentAlert({paymentName: vm.responseModel.orderId, type: 'investment'})
                        .then(function () {
                          $state.go("investments.form.new", {orderType: $state.params.orderType, fundId: vm.fundId}, {reload: true});
                          vm.deleteButtonDisabled = false;
                        });
                  })
                  .catch(function (error) {
                    vm.deleteButtonDisabled = false;
                    NotificationService.showMessage(error, 'error');
                  });
            }
          })
          .catch(function () {
            $log.debug('Example failed!');
          });
    };

    vm.confirmSignedInvestment = function () {
      vm.confirmSignedButtonDisabled = true;
      InvestmentOrdersService.confirmSignInvestment(vm.responseModel.orderId, vm.model.password2)
          .then(function (response) {
            vm.confirmSignedButtonDisabled = false;
            var customFundId = '';
            if(response.investmentOrder.invBuyAccId && response.investmentOrder.fundId)
              customFundId = response.investmentOrder.invBuyAccId + '_' + response.investmentOrder.fundId;

            $state.go("investments.overview", {fundId: vm.fundId ? vm.fundId : customFundId});
          }, function (error) {
            NotificationService.showMessage(error, "error");
            vm.confirmSignedButtonDisabled = false;
          });
    };

    vm.fundSelectReplaceChange = function (fundSelect) {
      if(fundSelect) {
        vm.model.fundSelectReplaceInput = fundSelect.iban.replace(/-/g, '');
      }
      vm.getUserInvestmentAccounts();
    };

    //var loadUserFunds = function () {
    //  return FundsService.getPage()
    //      .then(function (response) {
    //        if (response.result && response.result.length > 0) {
    //          vm.fundList = response.result;
    //          vm.model.fundSelect = _.find(vm.fundList, {id: $state.params.fundId});
    //          if (vm.model.fundSelect) {
    //            vm.onFundSelect(vm.model.fundSelect);
    //            vm.fundReplacementList = _.without(vm.fundList, vm.model.fundSelect);
    //          }
    //        }
    //      })
    //      .catch(function (error) {
    //        NotificationService.showMessage(error, 'error');
    //      });
    //};

    var checkUsersFund = function (data) {
      var tempFundObj = {};
      if (orderType == "replace") {
        tempFundObj = vm.model.fundSelectReplace;
      } else {
        tempFundObj = vm.model.fundSelect;
      }

      var fundRelatedAccount = vm.model.investmentAccount ? vm.model.investmentAccount.accountId : null;

      if (fundRelatedAccount || vm.model.accountType === '2') {
        if (!editMode) {
          insertInvestment(data)
        } else
          updateInvestmentData(data);

      } else {
        $rootScope.investmentFundName = vm.model.fundSelect.fundName;
        $uibModal.open({
          templateUrl: 'investments/investment-confirm-modal.tpl.html',
          backdrop: 'static',
          size: 'md'
        }).result.then(function (result) {
          if (!result) {
            vm.submitButtonDisabled = false;
            return;
          }
          data.payeeTo = tempFundObj.id;
          data.invBuyAccId = null;
          if (editMode) {
            updateInvestmentData(data)
                .then(function () {
                  getFundAccounts({
                    "request.sale": (vm.model.orderType == 2) ? "Y" : "N",
                    "request.currencySymbol": vm.model.fromAccountSelect.currencySymbol
                  });
                })
          } else {
            insertInvestment(data)
                .then(function () {
                  getFundAccounts({
                    "request.sale": (vm.model.orderType == 2) ? "Y" : "N",
                    "request.currencySymbol": vm.model.fromAccountSelect.currencySymbol
                  });
                })
          }
        });
      }
    };

    vm.getUserInvestmentAccounts = function () {
      var requestObj = {
        accountType: vm.model.accountType
      };
      if (vm.model.orderType == 3) {
        requestObj.fundId = vm.model.fundSelectReplace.id;
      } else {
        requestObj.fundId = vm.model.fundSelect.id;
      }

      FundsService.getUserInvestmentAccountsByFundId(requestObj)
        .then(function (response) {
          if (response && response.result) {
            vm.investmentAccountList = response.result;
            vm.model.investmentAccount = vm.investmentAccountList[0];
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

  }
}());
