<table class="table table-striped table-responsive">
  <!--<thead>-->
    <!--<tr>-->
      <!--<th class="tlidm-header" colspan="2">-->
        <!--<span class="tlidm-header-label" translate="timeline.einvoiceDetails"></span><i class="zmdi zmdi-close pull-right pointer-cst tlidm-header-btn-close" data-ng-click="tlItemDetailsModal.closeModal();"></i>-->
      <!--</th>-->
    <!--</tr>-->
  <!--</thead>-->
  <tbody>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.purpose"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.einvoiceDetails.purpose }}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.creditorName"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.einvoiceDetails.creditorName }}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.amount"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.einvoiceDetails.amount | number:2}}</span></td>
    </tr>
    <!--<tr>-->
      <!--<td><span class="tlidm-key-label" translate="timeline.referenceType"></span></td>-->
      <!--<td><span class="tlidm-value-label">{{dashboard.timelineItem.referenceType}}</span></td>-->
    <!--</tr>-->
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.valueDate"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.einvoiceDetails.valueDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
  </tbody>
</table>
