<ul class="header-inner clearfix">
    <li id="menu-trigger" data-trigger=".ha-menu" class="visible-xs">
        <div class="line-wrap">
            <div class="line top"></div>
            <div class="line center"></div>
            <div class="line bottom"></div>
        </div>
    </li>

    <li class="logo hidden-xs">
        <a data-ui-sref="home">Material Admin</a>
    </li>

    <li class="pull-right">
        <ul class="top-menu">
            <li class="dropdown" uib-dropdown>
                <a uib-dropdown-toggle href="">
                    <i class="tmn-counts">6</i>
                    <i class="tm-icon zmdi zmdi-email"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-lg pull-right">
                    <div class="listview">
                        <div class="lv-header">
                            Messages
                        </div>
                        <div class="lv-body">
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/1.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">David Belle</div>
                                        <small class="lv-small">Cum sociis natoque penatibus et magnis dis parturient montes</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/2.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Jonathan Morris</div>
                                        <small class="lv-small">Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/3.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Fredric Mitchell Jr.</div>
                                        <small class="lv-small">Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/4.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Glenn Jecobs</div>
                                        <small class="lv-small">Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/4.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Bill Phillips</div>
                                        <small class="lv-small">Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat</small>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <a class="lv-footer" href="">View All</a>
                    </div>
                </div>
            </li>
            <li class="dropdown" uib-dropdown>
                <a uib-dropdown-toggle>
                    <i class="tmn-counts">9</i>
                    <i class="tm-icon zmdi zmdi-notifications"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-lg pull-right">
                    <div class="listview" id="notifications">
                        <div class="lv-header">
                            Notification

                            <ul class="actions">
                                <li class="dropdown">
                                    <a href="" data-clear="notification">
                                        <i class="zmdi zmdi-check-all"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="lv-body">
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/1.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">David Belle</div>
                                        <small class="lv-small">Cum sociis natoque penatibus et magnis dis parturient montes</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/2.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Jonathan Morris</div>
                                        <small class="lv-small">Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/3.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Fredric Mitchell Jr.</div>
                                        <small class="lv-small">Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/4.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Glenn Jecobs</div>
                                        <small class="lv-small">Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque</small>
                                    </div>
                                </div>
                            </a>
                            <a class="lv-item" href="">
                                <div class="media">
                                    <div class="pull-left">
                                        <img class="lv-img-sm" src="img/profile-pics/4.jpg" alt="">
                                    </div>
                                    <div class="media-body">
                                        <div class="lv-title">Bill Phillips</div>
                                        <small class="lv-small">Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat</small>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <a class="lv-footer" href="">View Previous</a>
                    </div>

                </div>
            </li>
            <li class="dropdown" uib-dropdown>
                <a uib-dropdown-toggle href="">
                    <i class="tmn-counts">2</i>
                    <i class="tm-icon zmdi zmdi-view-list-alt"></i>
                </a>
                <div class="dropdown-menu pull-right dropdown-menu-lg">
                    <div class="listview">
                        <div class="lv-header">
                            Tasks
                        </div>
                        <div class="lv-body">
                            <div class="lv-item">
                                <div class="lv-title m-b-5">HTML5 Validation Report</div>

                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style="width: 95%">
                                        <span class="sr-only">95% Complete (success)</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lv-item">
                                <div class="lv-title m-b-5">Google Chrome Extension</div>

                                <div class="progress">
                                    <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%">
                                        <span class="sr-only">80% Complete (success)</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lv-item">
                                <div class="lv-title m-b-5">Social Intranet Projects</div>

                                <div class="progress">
                                    <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style="width: 20%">
                                        <span class="sr-only">20% Complete</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lv-item">
                                <div class="lv-title m-b-5">Bootstrap Admin Template</div>

                                <div class="progress">
                                    <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 60%">
                                        <span class="sr-only">60% Complete (warning)</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lv-item">
                                <div class="lv-title m-b-5">Youtube Client App</div>

                                <div class="progress">
                                    <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%">
                                        <span class="sr-only">80% Complete (danger)</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a class="lv-footer" href="">View All</a>
                    </div>
                </div>
            </li>
            <li class="dropdown" uib-dropdown>
                <a uib-dropdown-toggle href=""><i class="tm-icon zmdi zmdi-more-vert"></i></a>
                <ul class="dropdown-menu dm-icon pull-right">
                    <li class="hidden-xs">
                        <a data-ng-click="hctrl.fullScreen()" href=""><i class="zmdi zmdi-fullscreen"></i> Toggle Fullscreen</a>
                    </li>
                    <li>
                        <a data-ng-click="hctrl.clearLocalStorage()" href=""><i class="zmdi zmdi-delete"></i> Clear Local Storage</a>
                    </li>
                    <li>
                        <a href=""><i class="zmdi zmdi-face"></i> Privacy Settings</a>
                    </li>
                    <li>
                        <a href=""><i class="zmdi zmdi-settings"></i> Other Settings</a>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ul>

<div class="search">
    <div class="fg-line">
        <input type="text" class="form-control" placeholder="Search...">
    </div>
</div>

<nav class="ha-menu">
    <ul>
        <li class="waves-effect" data-ui-sref-active="active">
            <a data-ui-sref="home" data-ng-click="mactrl.sidebarStat($event)">Home</a>
        </li>

        <li class="dropdown" uib-dropdown data-ng-class="{ 'active': mactrl.$state.includes('headers') }">
            <div class="waves-effect" uib-dropdown-toggle>Headers</div>

            <ul class="dropdown-menu">
                <li data-ui-sref-active="active" ><a data-ui-sref="headers.textual-menu">Textual menu</a></li>
                <li data-ui-sref-active="active" ><a data-ui-sref="headers.image-logo">Image logo</a></li>
                <li data-ui-sref-active="active" ><a data-ui-sref="headers.mainmenu-on-top">Mainmenu on top</a></li>
            </ul>
        </li>

        <li class="waves-effect" data-ui-sref-active="active">
            <a data-ui-sref="typography">Typography</a>
        </li>

        <li class="dropdown" uib-dropdown>
            <div class="waves-effect" uib-dropdown-toggle>Widgets</div>
            <ul class="dropdown-menu">
                <li data-ui-sref-active="active"><a data-ui-sref="widgets.widget-templates">Templates</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="widgets.widgets">Widgets</a></li>
            </ul>
        </li>

        <li class="dropdown" uib-dropdown>
            <div class="waves-effect" uib-dropdown-toggle>Tables</div>
            <ul class="dropdown-menu">
                <li data-ui-sref-active="active"><a data-ui-sref="tables.tables">Normal Tables</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="tables.data-table">Data Tables</a></li>
            </ul>
        </li>

        <li class="dropdown" uib-dropdown>
            <div class="waves-effect" uib-dropdown-toggle>Forms</div>
            <ul class="dropdown-menu">
                <li data-ui-sref-active="active"><a data-ui-sref="form.basic-form-elements">Basic Form Elements</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="form.form-components">Form Components</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="form.form-examples">Form Examples</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="form.form-validations">Form Validation</a></li>
            </ul>
        </li>
        <li class="dropdown" uib-dropdown>
            <div class="waves-effect" uib-dropdown-toggle>User Interface</div>
            <ul class="dropdown-menu">
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.ui-bootstrap">UI Bootstrap</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.colors">Colors</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.animations">Animations</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.box-shadow">Box Shadow</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.buttons">Buttons</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.icons">Icons</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.alerts">Alerts</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.preloaders">Preloaders</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.notifications-dialogs">Notifications & Dialogs</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.media">Media</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="user-interface.other-components">Others</a></li>
            </ul>
        </li>
        <li class="dropdown" uib-dropdown>
            <div class="waves-effect" uib-dropdown-toggle>Charts</div>
            <ul class="dropdown-menu">
                <li data-ui-sref-active="active"><a data-ui-sref="charts.flot-charts">Flot Charts</a></li>
                <li data-ui-sref-active="active"><a data-ui-sref="charts.other-charts">Other Charts</a></li>
            </ul>
        </li>
        <li class="waves-effect" data-ui-sref-active="active"><a data-ui-sref="calendar">Calendar</a></li>
    </ul>
</nav>

<div class="skin-switch dropdown hidden-xs" uib-dropdown>
    <button uib-dropdown-toggle class="btn ss-icon"><i class="zmdi zmdi-palette"></i></button>
    <div class="dropdown-menu">
        <span ng-repeat="w in mactrl.skinList" class="ss-skin bgm-{{ w }}" data-ng-click="mactrl.skinSwitch(w)"></span>
    </div>
</div>
