(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:LoansOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('LoansOverviewCtrl', LoansOverviewCtrl);

  function LoansOverviewCtrl(AccountsService, $state, $localStorage) {

    var vm = this;
    var accounts;
    vm.countryCode = $localStorage.code;

    init();
    function init() {

      loadData();
    }

    // region Private methods

    function loadData() {
      AccountsService.getPage({
        pageSize: 1000,
        page: 0,
        accountTypeGroupList: ["LoanAccountType"],
        orderby: "iban DESC",
        includeList: ['loanAccount']
      })
        .then(function (response) {
          vm.accounts = response.result;
        })
    }

    vm.goToAccount = function (accountId) {
      $state.go('accounts', {
        accountId: accountId
      });
    };

  }
}());
