(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name localization.factory:CtlocalizationService
   *
   * @description
   *
   */
  angular
      .module('localization')
      .factory('CTLocalizationService', CTLocalizationService);

    function CTLocalizationService($translate, tmhDynamicLocale, $localStorage,$http, ConfigService) {
    var CtlocalizationServiceBase = {};
    var sweetAlertOptions = {};
    var languageOptions = [
      {
        translate: 'header.langEn',
        code: 'en',
        flag: 'en.png'
      },
      {
        translate: 'header.langHr',
        code: 'hr',
        flag: 'hr.png'
      },
      {
        translate: 'header.langSr',
        code: 'sr',
        flag: 'sr.png'
      },
      {
        translate: 'header.langMtr',
        code: 'mne',
        flag: 'mtr.png'
      },
      {
        translate: 'header.langSl',
        code: 'sl',
        flag: 'mtr.png'
      },
      {
        translate: 'header.langDe',
        code: 'de',
        flag: 'de.png'
      },
      {
        translate: 'header.langRu',
        code: 'ru',
        flag: 'ru.png'
      },
      {
        translate: 'header.langBa',
        code: 'bh',
        flag: 'ba.png'
      }
    ];

      // console.log("LocalCityCode: " + ConfigService.localCityCode);
      // if(ConfigService.localCityCode) {
      //     languageOptions.push({
      //       translate: 'header.langBa',
      //       code: ConfigService.localCityCode,
      //       flag: 'ba.png'
      //     })
      // }

    CtlocalizationServiceBase.translateSweetAlertOptions = function() {
      $translate(["core.sweetAlertTranslation.yes",
        "core.sweetAlertTranslation.no",
        "core.sweetAlertTranslation.areYouSure"])
          .then(function (translations) {
            sweetAlertOptions.yes = translations["core.sweetAlertTranslation.yes"];
            sweetAlertOptions.no = translations["core.sweetAlertTranslation.no"];
            sweetAlertOptions.areYouSure = translations["core.sweetAlertTranslation.areYouSure"];
          });
    };

    CtlocalizationServiceBase.getSweetAlertOptions = function() {
      return sweetAlertOptions;
    };

    CtlocalizationServiceBase.setLanguage = function (langKey) {
      $translate.use(langKey);
      tmhDynamicLocale.set(langKey);
      $localStorage.appLanguage = langKey;
        $http.defaults.headers.common["Accept-Language"] = $localStorage.appLanguage;
    };

    CtlocalizationServiceBase.getLanguageOptions = function(){
      return languageOptions;
    };


    CtlocalizationServiceBase.getLocalLanguageOptions = function(countryCode){
      var languages = [];

      for(var i in languageOptions) {
        // Map location for Montenegro mne to language code mn
        if(languageOptions[i].code === countryCode || languageOptions[i].code === 'en' || (countryCode === 'mne' && languageOptions[i].code === 'mn')) {
          languages.push(languageOptions[i]);
        }
      }
      return languages;
    };


    return CtlocalizationServiceBase;


  }
}());
