(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name orders.factory:OrdersService
   *
   * @description
   *
   */
  angular
    .module('orders')
    .factory('PermanentOrdersService', PermanentOrdersService);

  function PermanentOrdersService($q, $http, $rootScope, EndpointsService, SessionService) {
    var PermanentOrdersService = {};

    PermanentOrdersService.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.openingPermanentOrders, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.getClosingPermanentOrders = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.closingPermanentOrders, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.getById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.openingPermanentOrders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.getClosingPermanentOrdersById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.closingPermanentOrders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.getAll = function (params) {
      var predefinedParams = {
        page: 0,
        pageSize: 1000
      };
      var extended = _.extend(predefinedParams, params);
      return PermanentOrdersService.getPage(extended);
    };


    function insert(order) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        SessionService.saveStorageObject(order, 'permanentOrder');
        $http.post(response.links.openingPermanentOrders, order)
          .then(function (data) {
            SessionService.removeStorageObject();
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    }

    function validate(order) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.openingPermanentOrders_validate, order)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PermanentOrdersService.create = function (order) {
      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'){
        return validate(order);
      }

      return insert(order);
    };


    PermanentOrdersService.additionalChallangeRequired = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links['openingPermanentOrders_additionalChallangeRequired?Id={Id}'].replace('{Id}', params.id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    }

    PermanentOrdersService.close = function (order) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.closingPermanentOrders, order)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.validateClose = function (order) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.closingPermanentOrders_validate, order)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    PermanentOrdersService.update = function (id, order) {
      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        return validate(order);
      }

      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.openingPermanentOrders_id.replace('{id}', id), order)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PermanentOrdersService.sign = function (order) {
      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        return insert(order);
      }
      return sign(order);
    };

    function sign(order) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.openingPermanentOrders_sign, order)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    }

    PermanentOrdersService.remove = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.delete(response.links.orders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return PermanentOrdersService;
  }
}());
