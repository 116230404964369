<section id="main" ng-controller="OrdersOtherCtrl as vm">
  <div class="container-fluid">
    <div class="domp-header-wrapper" ng-hide="vm.step === 'complete'">
      <div class="domp-header-row">
        <h2 class="f-28" ng-if="vm.step === 'order'" translate="orders.title"></h2>
        <h2 class="f-28" ng-if="vm.step === 'confirm'" translate="orders.titleConfirmation"></h2>
      </div>
    </div>
    <div ng-if="vm.step === 'order'">

      <form novalidate name="vm.orderForm">
        <div class="row">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row p-15">
                <!-- CUSTOM ACCOUNTS DROPDOWN -->
                <div class="col-sm-6 domp-acc-dropdown">
                  <label class="domp-form-label" translate="payments.fromAccount"></label>

                  <div class="accounts-dropdown">
                    <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                         data-ng-click="vm.fromAccountDropdown = !vm.fromAccountDropdown"
                         ng-style="vm.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + vm.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                          {{vm.fromAccountSelect.friendlyName}} </b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                          <i class="zmdi" ng-class="{
                              'zmdi-star favorite-account'
                                :account.isFavorite, 'zmdi-star-outline':!account.isFavorite}"></i>
                        </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{vm.fromAccountSelect.iban }}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{vm.fromAccountSelect.availableBalance | currency: ""}} {{vm.fromAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon">
                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                      </span>
                    </div>
                    <div class="from-accounts-list m-l-0" ng-show="vm.fromAccountDropdown" scrollable>
                      <div class="accounts-list-item p-0"
                           data-ng-repeat="item in vm.accountList"
                           data-ng-click="vm.setAccountFrom(item);">
                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                             ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                          <h4 class="no-wrap-text bold-font m-b-0">
                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                                ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                          </h4>
                          <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                          <span class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">{{item.availableBalance | currency: ""}} {{item.currencySymbol}}</span>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="domp-form-label-review" for="amountInput"
                               translate="domesticPayment.amountInputLabel"></label>
                        <div class="input-group">
                          <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                                 placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask
                                 maxlength="12" ng-model="vm.amountInput" required
                                 ng-class="{'domp-error': vm.orderForm.amountInput.$invalid && vm.formSubmitted}"
                                 ng-maxlength="11"/>
                          <span class="input-group-btn">
                            <button type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                                    ng-if="vm.countryCode != 'sr'" style="border:1px solid #062a42;">
                              {{vm.defaultCurrency}}
                            </button>
                          </span>
                        </div>
                        <span class="c-red" ng-if="vm.orderForm.amountInput.$error.required && vm.formSubmitted"
                              translate="internalTransfer.amountInputError"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="domp-form-label" for="dateBegin" translate="orders.dateBegin"></label>
                        <div class="input-group">
                          <input type="text" class="form-control input-field" name="dateBegin" id="dateBegin"
                                 uib-datepicker-popup="shortDate" data-ng-model="vm.dateBegin" show-weeks="false"
                                 is-open="datePickerBeginOpened" datepicker-options="vm.dateOptions"
                                 show-button-bar="false"
                                 ng-class="{ 'domp-error' : vm.orderForm.dateBegin.$invalid && vm.formSubmitted}"
                                 required readonly>
                          <span class="input-group-btn">
                            <button data-ng-click="datePickerBeginOpened = !datePickerBeginOpened" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;">
                              <i class="icon icon-calendar" style="font-size: 21px;"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                      <span class="c-red" ng-show="vm.orderForm.dateBegin.$error.required && vm.formSubmitted"
                            translate="orders.dateRequired"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--RECIPIENT IBAN  / RECIPIENT (company/name and address) START -->

        <div class="row">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row  p-15">
                <div class="col-sm-6">
                  <label class="domp-form-label" for="beneficiaryIBAN" translate="orders.beneficiaryIBAN"></label>
                  <input ng-if="vm.countryCode == 'hr'" type="text" class="form-control input-field" capitalize
                         ui-mask="HR 99 9999 9999 9999 9999 9"
                         ui-mask-placeholder
                         ui-mask-placeholder-char="_"
                         ui-options="vm.uiMaskOptions"
                         minlength="21"
                         ng-model="vm.beneficiaryIBAN"
                         ng-class="{'domp-error': vm.orderForm.beneficiaryIBAN.$invalid && vm.formSubmitted}"
                         name="beneficiaryIBAN" id="beneficiaryIBAN"
                         required />

                  <span class="c-red" ng-show="vm.orderForm.beneficiaryIBAN.$error.required && vm.formSubmitted"
                        translate="domesticPayment.receiverAccountError"></span>
                </div>
                <div class="col-sm-6">
                  <label  class="domp-form-label" for="beneficiaryName" translate="orders.beneficiaryName"></label>
                  <input type="text" class="form-control input-field" name="beneficiaryName" id="beneficiaryName"
                         required ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPS}}"
                         ng-class="{ 'domp-error' : vm.orderForm.beneficiaryName.$invalid && vm.formSubmitted}"
                         ng-model="vm.beneficiaryName">
                  <span class="c-red" ng-show="vm.orderForm.beneficiaryName.$error.required && vm.formSubmitted"
                        translate="orders.beneficiaryNameRequired"></span>
                </div>
              </div>
              <div class="row  p-15">
                <div class="col-sm-6">
                    <div class="col-xs-12 p-l-0">
                      <label class="domp-form-label" for="referenceNumber"
                             translate="domesticPayment.referenceNumberLabel"></label>
                    </div>
                    <div class="col-sm-2 col-xs-2 p-l-0 p-r-5">
                      <input type="text" class="form-control input-field" value="HR"
                             readonly>
                    </div>
                    <div class="col-sm-2 col-xs-2 p-l-0 p-r-5">
                      <div class="form-group m-b-0">
                        <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"
                                id="referenceModelSelect" ng-model="vm.referenceModelSelect">
                          <option ng-repeat="item in vm.referenceModelList">{{item}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-8 col-xs-8 p-l-0 p-r-0" ng-if="vm.referenceModelSelect != '99'">
                      <div class="form-group m-b-0">
                        <input type="text" id="referenceNumber" name="referenceNumber"
                               ng-if="vm.referenceModelSelect != '99'" maxlength="22" class="form-control input-field"
                               ng-pattern-restrict="{{vm.regexPatterns.allNumbersDashes}}"
                               ng-model="vm.referenceNumber"/>
                      </div>
                    </div>
                    <div class="col-sm-8 col-xs-8 p-l-0 p-r-0" ng-if="vm.referenceModelSelect == '99'">
                      <div class="form-group m-b-0">
                        <input type="text" class="form-control input-field" name="referenceNumber" id="referenceNumber"
                               ng-model="vm.referenceNumber"
                               readonly/>
                      </div>
                    </div>
                  <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                        ng-show="vm.orderForm.referenceNumber.$error.required && vm.formSubmitted"
                        translate="domesticPayment.referenceModelInputError"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="container domp-from-section">
              <div class="row  p-15">
                <div class="col-sm-12 p-t-10">
                  <div class="form-group m-b-0">
                    <label class="domp-form-label" for="description" translate="orders.description"></label>
                    <input type="text" class="form-control input-field" name="description" id="description" required
                           ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPS}}"
                           maxlength="{{vm.descriptionMaxLength}}"
                           ng-class="{ 'domp-error' : vm.orderForm.description.$invalid && vm.formSubmitted}"
                           ng-model="vm.description"/>
                  </div>
                  <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                        ng-show="vm.orderForm.description.$error.required && vm.formSubmitted"
                        translate="orders.descriptionRequired"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row  p-15">
          <div class="col-sm-12">
            <div class="container">
              <div class="controls readonly">
                  <label class="domp-form-label" translate="orders.note"></label>
                  <br>
                  <span translate="orders.note1"></span>
                  <br>
                  <span translate="orders.note2"></span>
                  <br>
                  <span translate="orders.note3"></span>
              </div>
            </div>
          </div>
        </div>


        <div class="row p-10">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary" type="button" translate="orders.cancel" data-ui-sref="dashboard">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button class="btn btn-default pull-right" type="submit"
                    ng-click="vm.confirm()">
              <span translate="domesticPayment.confirmBtn"></span>
            </button>
          </div>
        </div>
      </form>
      <!-- TIMELINE CONTAINER -->
      <div data-ng-include="'orders/orders-overview-timeline.tpl.html'"></div>
      <!-- END TIMELINE -->
    </div>
    <div ng-if="vm.step === 'confirm'">
      <div class="row">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                    <label class="domp-form-label-review" translate="payments.fromAccount"></label>
                </span>
                <p>
                  <!--<label ng-if="vm.countryCode != 'sl'" class="domp-form-label-resume">{{vm.fromAccountSelect.iban}}</label>-->
                  <label class="domp-form-label-resume">{{vm.insertedOrderObject.fromAccountNumber}}</label>
                </p>
              </div>
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>

                </span>
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.amount | number:2}} {{vm.insertedOrderObject.currencySymbol}}
                </label>
              </div>
            </div>

            <div class="row p-t-10">
              <div class="col-sm-3 col-xs-offset-6">
                <span class="invoice-inline-title text-muted">
                 <label class="domp-form-label-review" translate="orders.dateBegin"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.dateBegin| date: 'shortDate' : '+0200'}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                  <label class="domp-form-label" for="beneficiaryIBAN" translate="orders.beneficiaryIBAN"></label>
                </span>
                <label class="domp-form-label-resume">
                  <p ng-if="vm.additionalChallangeRequired">{{vm.insertedOrderObject.payeeAccount.substr(0,13)}}<span style="background-color: lightskyblue">{{vm.insertedOrderObject.payeeAccount.substr(13)}}</span></p>
                  <p ng-if="!vm.additionalChallangeRequired">{{vm.insertedOrderObject.payeeAccount}}</p>
                </label>

              </div>
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                     <label class="domp-form-label-review" translate="orders.beneficiaryName"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.insertedOrderObject.payeeName}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="container domp-from-section">
            <div class="row">
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                  <label class="domp-form-label-review" translate="orders.description"></label>
                </span>
                <label class="domp-form-label-resume">
                  {{vm.description}}
                </label>
              </div>
              <div class="col-sm-6">
                <span class="invoice-inline-title text-muted">
                    <label class="domp-form-label-review" translate="domesticPayment.referenceNumberLabel"></label>
                </span>
                <label class="domp-form-label-resume">
                  <b>HR{{vm.referenceModelSelect}}{{vm.referenceNumber}}</b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" ng-if="!vm.orderSigned">
        <div  class="col-lg-6 col-md-6 col-xs-6 col-sm-6 m-t-10">
          <button  class="col-lg-3 col-md-3 col-sm-4 col-xs-4 m-r-10 btn btn-primary-warning" translate="payments.delete"
                  ng-click="vm.deleteOrder()">
          </button>
          <button  class="col-lg-3 col-md-3 col-sm-4 col-xs-4 btn btn-primary"
                  translate="orders.edit" ng-click="vm.editOrder()">
          </button>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 m-t-10">
          <button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
                  translate="internalTransfer.sign" ng-click="vm.orderSigned = true">
          </button>
        </div>
      </div>
      <!-- Container for signing form -->

      <div class="row m-b-10" ng-if="vm.orderSigned">
      <div class="col-xs-12 m-t-30">
        <form name="vm.challengeForm" novalidate>
          <div class="col-xs-12 m-b-20">
              <span class="f-w-bold">
          {{'signPayments.challengeDescription'| translate}}
        </span>
          </div>
          <div class="col-sm-4 col-xs-6">
            <span class="f-w-bold">{{'signPayments.challengeFirstCode'| translate}}</span>
            <input type="text" class="form-control input-field" disabled
                   data-ng-model="vm.insertedOrderObject.challenge">
          </div>
          <div class="col-sm-4 col-xs-6">
            <span class="f-w-bold">{{'signPayments.challengeSecondCode'| translate}}</span>
            <div class="input-group">
              <input type="{{vm.showTokenInput ? 'text' : 'password'}}" class="form-control input-field"
                     data-ng-model="vm.model.password2" ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
                     placeholder="{{'signPayments.challengeSecondCode'| translate}}" autofocus>
              <span class="input-group-btn">
            <button class="btn btn-default input-group-app-btn domp-btn-height"
                    data-ng-click="vm.showTokenInput = !vm.showTokenInput" style="border:1px solid #062a42;">
              <i class="icon icon-lock"></i>
            </button>
          </span>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12 m-t-20">
            <button ng-if="!vm.additionalChallangeRequired" class="btn btn-default col-sm-8 col-xs-4 pull-right" ng-disabled="vm.confirmSignedButtonDisabled"
                    data-ng-click="vm.sign()" translate="signPayments.confirm">
            </button>
          </div>
        </form>
      </div>
    </div>


      <div class="row m-b-10" ng-if="vm.orderSigned && vm.additionalChallangeRequired">
        <div class="col-xs-12 m-t-30">
          <form name="vm.challengeForm" novalidate>
            <div class="col-sm-4 col-xs-6">
              <span class="f-w-bold">{{'signPayments.challengeFirstCode'| translate}}</span>
              <input type="text" class="form-control input-field" disabled
                     data-ng-model="vm.last8digitsFromAccount">
            </div>
            <div class="col-sm-4 col-xs-6">
              <span class="f-w-bold">{{'signPayments.challengeSecondCode'| translate}}</span>
              <div class="input-group">
                <input type="{{vm.showTokenInput ? 'text' : 'password'}}" class="form-control input-field"
                       data-ng-model="vm.last8digitsFromAccountResponse" ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
                       placeholder="{{'signPayments.challengeSecondCode'| translate}}" autofocus>
                <span class="input-group-btn">
            <button class="btn btn-default input-group-app-btn domp-btn-height"
                    data-ng-click="vm.showTokenInput = !vm.showTokenInput" style="border:1px solid #062a42;">
              <i class="icon icon-lock"></i>
            </button>
          </span>
              </div>
            </div>
            <div class="col-sm-4 col-xs-12 m-t-20">
              <button class="btn btn-default col-sm-8 col-xs-4 pull-right" ng-disabled="vm.confirmSignedButtonDisabled"
                      data-ng-click="vm.sign()" translate="signPayments.confirm">
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div ng-if="vm.step === 'complete'" class="currency-conversion-msg">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-9 m-t-30 m-b-30" style="max-width: 688px;">
            <h2 class="c-white f-28" translate="orders.completeText"></h2>
          </div>
        </div>
      </div>
      <div class="row m-t-20 p-l-10">
        <!-- Save template only for Domestic or foreign payments-->
        <div class="col-xs-12 col-sm-4">
          <a href="javascript:void(0)" ng-click="vm.newOrder()">
            <span class="money-exchange-icon"><i class="icon icon-einvoiceadd zmdi-hc-2x"></i></span>
            <span class="f-18 bold-font" translate="orders.makeNew"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
