<div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <div ng-switch-default>
    <div class="modal-header statement-confirm-header p-10">
      <h3 translate="paymentsReturn.modalTitle"></h3>
    </div>
    <div class="modal-body m-t-10">
      <ng-form name="paymentsRecallModal.recallForm">

        <div class="form-group" class="col-xs-12">
          <label class="domp-form-label pull-left" translate="paymentsReturn.recallReason">Razlog za povrat</label>
          <select class="form-control input-field" ng-model="paymentsRecallModal.recallReasonObject.reasonCode"
                  ng-change="paymentsRecallModal.reasonChange(paymentsRecallModal.recallReasonObject);"
                  ng-options="reason.value as reason.key | translate for reason in paymentsRecallModal.recallReasonsList">
          </select>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="domp-form-label pull-left" translate="paymentsReturn.additionalInfo">Razlog za povrat</label>
            <textarea name="recallInfo" id="recallInfo" class="form-control statement-confirm-textarea"
                      ng-readonly="paymentsRecallModal.recallReasonObject && paymentsRecallModal.recallReasonObject.reasonCode=='AC03' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
                      ng-class="{'statement-confirm-information-error': paymentsRecallModal.recallForm.$error.required && paymentsRecallModal.formSubmitted}"
                      ng-model="paymentsRecallModal.recallReasonObject.additionalInfo"
                      ng-required="paymentsRecallModal.recallReasonObject.reasonCode === 'FRAD'"
                      ng-maxlength="(paymentsRecallModal.recallReasonObject && paymentsRecallModal.recallReasonObject.reasonCode=='AC03') ? 106 : 105"
                       value="one">
            </textarea>
            <span class="c-red"
                  ng-show="paymentsRecallModal.recallForm.recallInfo.$error.required && paymentsRecallModal.formSubmitted"
                  translate="paymentsReturn.additionalInfoError"></span>
          </div>
        </div>

        <div class="row" ng-if="paymentsRecallModal.countryCode === 'hr'">
         <div class="col-md-12">
            <!--<input id="checkboxAgreement"
                   class="domp-form-label pull-left"
                   type="checkbox"
                   ng-model="paymentsRecallModal.recallReasonObject.checkAdditionalInfo"
                   ng-change="paymentsRecallModal.removeReason(paymentsRecallModal.recallReasonObject.checkAdditionalInfo, paymentsRecallModal.recallReasonObject )" >
            <label for="checkboxAgreement" class="domp-form-label pull-left" translate="paymentsReturn.checkAdditionalInfo">Ukloni dodatne informacije</label>-->
          <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value ng-binding">
            <label class="p-0 ng-scope" translate="paymentsReturn.checkAdditionalInfo">Ukloni dodatne informacije</label>
            <input type="checkbox"
                   ng-model="paymentsRecallModal.recallReasonObject.checkAdditionalInfo"
                   ng-change="paymentsRecallModal.removeReason(paymentsRecallModal.recallReasonObject.checkAdditionalInfo, paymentsRecallModal.recallReasonObject )"
                   class="ng-valid ng-touched ng-not-empty ng-dirty ng-valid-parse">
            <i class="input-helper"></i>
              </label>
        </div>
        </div>


      </ng-form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-default" ng-click="$close()"><span
            translate="statementConfirmModalTranslations.cancelButton"></span></button>
          <button class="btn btn-primary" ng-click="paymentsRecallModal.confirm()"><span
            translate="statementConfirmModalTranslations.confirmButton"></span></button>
        </div>
      </div>
    </div>
  </div>

  <div ng-switch-when="sl">
    <div class="modal-header statement-confirm-header p-10">
      <h3 translate="paymentActions.cancelPayment"></h3>
    </div>
    <div class="modal-body m-t-10">
      <table class="table table-transparent table-responsive">
        <tbody>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlStatus">Status:</span>
          </td>
          <td>
            <span class="po-value-label" ng-if="paymentsRecallModal.payment.status === 'WaitingQueue'" translate="paymentsOverview.waitingQueque"></span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlId">Payment id:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.id}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlReceiver">Receiver name:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.receiverName}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlFrom">From account:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.fromAccountNumber}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlTo">To account:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.toAccountNumber}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlAmount">Amount:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.amount|number:2}} {{::paymentsRecallModal.payment.currencySymbol}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="signPayments.dateCreated">Create date:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.dateCreated| date:'shortDate' : '+0200'}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="po-desc-label" translate="paymentsOverview.povlDueDate">Due date:</span>
          </td>
          <td>
            <span class="po-value-label">{{::paymentsRecallModal.payment.dueDate| date:'shortDate' : '+0200'}}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" ng-click="$close()"><span
            translate="statementConfirmModalTranslations.cancelButton"></span></button>
          <button class="btn btn-default" ng-click="paymentsRecallModal.confirm()"><span
            translate="statementConfirmModalTranslations.confirmButton"></span></button>
        </div>
      </div>
    </div>

  </div>

</div>
