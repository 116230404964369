(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:ReorderPinAccountsCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('ReorderPinAccountsCtrl', ReorderPinAccountsCtrl);

  function ReorderPinAccountsCtrl($rootScope, $state, AlertService, AccountsService, HelperService, AccountLimitOrderService, NotificationService) {
    var vm = this;
    //TODO: load this info from API
    vm.selectedNewPinOrderDeliveryType = {};
    vm.step = 1;
    vm.type = {};
    vm.selectedUser = '';
    vm.formSubmitted = false;
    vm.cardTypes = [];

    function loadAccount() {
      AccountsService.getAccountById($state.params.accountId)
        .then(function (response) {
          vm.selectedAccount = response;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function createPostObj() {
      return {
        account: vm.selectedAccount.iban,
        name: vm.user,
        personType: {
          id: vm.type.id,
          description: vm.type.description
        },
        deliveryType: {
          id: vm.selectedNewPinOrderDeliveryType.id,
          description: vm.selectedNewPinOrderDeliveryType.description
        },
        note: " ",
        cardType: vm.selectedCardType
      };

    }

    vm.validate = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if (!vm.reorderPinAccountsForm.$valid)
        return;

      vm.insertedOrderObject = createPostObj();
      vm.nextButtonPressed = true;
      AccountsService.validateAccountNewPinOrder(vm.insertedOrderObject)
        .then(function (response) {
          vm.insertedOrderObject = angular.extend(response);
          vm.formSubmitted = false;
          vm.step = 2;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function () {
        vm.nextButtonPressed = false;
      })
    };

    vm.edit = function () {
      vm.step = 1;
    };

    vm.submit = function () {
      if (vm.submitButtonPressed) return;
      vm.submitButtonPressed = true;
      AccountsService.accountNewPinOrder(vm.insertedOrderObject)
        .then(function (response) {
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              vm.goBackToAccount();
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function () {
        vm.submitButtonPressed = false;
      })
    };

    vm.goBackToAccount = function () {
      $state.go('accounts', {
        accountId: $state.params.accountId
      });
    };

    function loadDebitCardType() {
      return AccountLimitOrderService.accountLimitPosTerminalOrderCardType()
        .then(function (response) {
          vm.cardTypes = response.result;
          vm.selectedCardType = vm.cardTypes[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function loadNewPinOrderDeliveryTypeTypes() {
      var params = null;
      return AccountsService.getNewPinOrderDeliveryType(params)
        .then(function (response) {
          vm.newPinOrderDeliveryTypes = response.result;
          vm.selectedNewPinOrderDeliveryType = vm.newPinOrderDeliveryTypes[0];
          vm.tempObj.newPinOrderDeliveryType = vm.newPinOrderDeliveryTypes[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function loadUsers() {
      var params = null;
      return AccountLimitOrderService.accountLimitPosTerminalOrderPersonType()
        .then(function (response) {
          vm.forOptions = response.result;
          setDefaultForOption();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });

    }

    vm.selectForOption = function () {
      if (vm.type.id == 'uporabnik') {
        vm.user = vm.selectedUser;
      } else {
        vm.user = '';
      }
    };

    function setDefaultForOption() {
      if (vm.forOptions) {
        var temp = _.find(vm.forOptions, function (item) {
          return item.id == 'uporabnik';
        });
        if (temp) {
          vm.type = temp;
          vm.user = vm.selectedUser;
        }

      }
    }


    function init() {

      var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
      vm.selectedUser = userSettings.firstName + " " + userSettings.lastName;

      loadAccount();
      loadDebitCardType();
      loadNewPinOrderDeliveryTypeTypes();
      loadUsers();

    }

    init();
  }
}());
