(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      documents: {
        "header": "Received documents",
        "loadEvents": "Load more",
        "noItemData": "No data",
        "download": "Download",
        "titleModalInfo":"Document is currently un available",
        "messageModalInfo":"This document is currently unavailable. Please try later or contact our customer helpdesk.",
        "closeModalInfo":"Close",
        "documentDownloadFailed": "This document is currently unavailable. Please try later or contact our customer helpdesk."
      }

    };

    $translateProvider
      .translations('en', data);
  }
}());
