(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name investments.factory:InvestmentOrdersService
   *
   * @description
   *
   */
  angular
      .module('investments')
      .factory('InvestmentOrdersService', InvestmentOrdersService);

  function InvestmentOrdersService($http, $q, EndpointsService, SessionService) {
    var InvestmentOrdersServiceBase = {};

    InvestmentOrdersServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.investmentOrders, {
          params: params
        })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };


    InvestmentOrdersServiceBase.getInvestmentById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.investmentOrders_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    function insert(investment) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          SessionService.saveStorageObject(investment, 'investmentOrder');
          $http.post(response.links.investmentOrders, investment)
            .then(function (data) {
              SessionService.removeStorageObject();
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    InvestmentOrdersServiceBase.insertInvestment = function (investment) {
      return insert(investment);
    };

    InvestmentOrdersServiceBase.deleteInvestment = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.delete(response.links.investmentOrders_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    InvestmentOrdersServiceBase.updateInvestment = function (id, investment) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.investmentOrders_id.replace('{id}', id), investment)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    InvestmentOrdersServiceBase.confirmSignInvestment = function (id, signData) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.investmentOrders_sign, {id: id, signData: signData})
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    InvestmentOrdersServiceBase.getInvestmentTransactions = function (params) {
      return $q(function (resolve, reject) {
        EndpointsService.getLinks()
          .then(function (response) {
            $http.get(response.links.investmentTransactions, {
                params: params
              })
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (error) {
                reject(error.data);
              });
          }, function (error) {
            reject(error);
          });
      });
    };

    return InvestmentOrdersServiceBase;
  }
}());
