(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:inputMask
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="core">
       <file name="index.html">
        <input-mask></input-mask>
       </file>
     </example>
   *
   */
  angular
    .module('core')
    .directive('inputMask', inputMask);

  function inputMask($log, $translate) {
    return {
      restrict: 'A',
      scope: {
        inputMask: '='
        ,inputMaskOptions: '=',
        maskVal: '='
      },
      controllerAs: 'inputMask',
      controller: function () {

      },
      link: function (scope, element, attrs) {
        //var im = new Inputmask(scope.inputMask);

        function translateInputMask() {
          $translate([scope.inputMask]).then(function (translations) {
            element.mask(translations['core.moneyFormat.mask'], scope.inputMaskOptions);
            if(scope.maskVal) {
              scope.maskVal = element.val();
            }
          });
        };

        if(scope.inputMaskOptions !== undefined && scope.inputMaskOptions.reverse === true){
          $( element ).css( "text-align", "right" );
        }
        var inputMask = undefined;
        var mask = String(scope.inputMask);
        //str.match("^Hello")
        // translation of input mask example
        if(mask.match("^core.")){
          //$translate([scope.inputMask]).then(function (translations) {
          //  element.mask(translations['core.moneyFormat.mask'], scope.inputMaskOptions);
          //});
          translateInputMask();
        }
        else {
          inputMask = scope.inputMask.mask;
          element.mask(scope.inputMask.mask, scope.inputMaskOptions);
        }

        scope.$root.$on('appLanguageChangedHeader', function (evt, data){
            translateInputMask();
        });

      }
    };
  }
}());

