(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orders.controller:NewSavingsOrderCtrlSlo
   *
   * @description
   *
   */
  angular
    .module('orders')
    .controller('NewSavingsOrderCtrlSlo', NewSavingsOrderCtrlSlo);

  function NewSavingsOrderCtrlSlo($state, $rootScope, $q, $translate, $filter, $log, $localStorage, $timeout,
                                  AlertService, AccountsService, SavingsOrdersService, CurrencyService, DepositOrdersService, HelperService, ValidationService, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;
    var selectedLanguage = $localStorage.appLanguage;
    var savingTypesGroup;
    vm.accountsFrom = [];
    vm.assignmentOfInterestisShown = true;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    vm.model = {
      savingsType: {},
      fromAccountSelect: {},
      totalInterestRate: 0,
      maturityDate: new Date(),
      amount: "",
      currency: "",
      assignmentOfInterest: "",
      interestPayout: "",
      standingOrderPayment: "",
      maturityInDays: "",
      maturityInMonths: "",
      dateChanged: false
    };

    vm.responseModel = {};

    vm.standingOrderPaymentDayOptions = [
      {id: 1, name: "8.", value: 8},
      {id: 2, name: "18.", value: 18},
      {id: 3, name: "28.", value: 28}
    ];

    // For interestRange property
    var assignmentOfInterestOptionsEnglish = [
      {id: 1, value: "termDeposit.assignmentOfInterestAnnual", name: "Annual reporting"},
      {id: 2, value: "termDeposit.assignmentOfInterestAfter", name: "Report after maturity"}
    ];

    var assignmentOfInterestOptionsSlovenian = [
      {id: 1, value: "termDeposit.assignmentOfInterestAnnual", name: "Letno poročanje"},
      {id: 2, value: "termDeposit.assignmentOfInterestAfter", name: "Poročanje po poteku"}
    ];

    vm.assignmentOfInterestOptions = [
      {id: 1, value: "termDeposit.assignmentOfInterestAnnual"},
      {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
    ];

    vm.assignmentOfInterestId = vm.assignmentOfInterestOptions[0].id;


    vm.steps = {
      step1: true,
      step2: false,
      step3: false
    };

    vm.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    vm.dateOptions = {
      minDate: new Date()
    };

    $rootScope.$on('$translateChangeEnd', function (evt, data) {
      selectedLanguage = data.language;
    });

    init();
    function init() {
      loadData();
    }

    function loadData() {
      var promises = [];
      promises.push(SavingsOrdersService.getSavingsOrderTypes({
        pageSize: 1000,
        orderBy: "sortOrder"
      }));
      promises.push(AccountsService.getDepositOrderAccounts({pageSize: 1000}));

      $q.all(promises)
        .then(function (responses) {
          // ==========
          // Request 1
          // ==========
          // Group array by "savingGroupDescription
          vm.savingOrderTypesGroupOptions = _.groupBy(responses[0].result, 'savingGroupDescription');
          // Select first group by default
          for (var key in vm.savingOrderTypesGroupOptions) {
            vm.savingTypesGroup = vm.savingOrderTypesGroupOptions[key];
            break;
          }

          // ==========
          // Request 2
          // ==========
          // Only owned accounts and EUR currency should be possible!!
          vm.accountsFrom = _.filter(responses[1].result, function (account) {
            return (account.currencySymbol == "EUR" && account.isOwn);
          });
          // Set first account as default
          vm.setAccountFrom(vm.accountsFrom[0]);

          vm.getTimelineItemsGrouped(true);

          vm.onSavingOrderTypesGroupChange(vm.savingTypesGroup);
          vm.isPageLoaded = true;
        }, function (error) {
          console.log(error);
        })
    }


    // ===
    // MAIN
    // ====
    vm.onSavingOrderTypesGroupChange = function (savingOrderTypesGroup) {
      // savingTypesGroup = angular.copy(savingOrderTypesGroup);
      // Set group Id
      vm.selectedSavingTypesGroupId = savingOrderTypesGroup[0].savingGroup;

      // Find and set minimal duration of selected group
      var minDurations = [];
      var maxDurations = [];
      _.each(savingOrderTypesGroup, function (savingType) {
        minDurations.push(parseInt(savingType.minSavingDays));
        maxDurations.push(parseInt(savingType.maxSavingDays))
      });
      vm.maxSavingDuration = _.max(maxDurations);
      var min = _.min(minDurations);
      vm.model.maturityInDays = min;
      vm.minimalDurationForSelectedGroup = min;
      findSavingsType();
    };

    function findSavingsType() {
      // Find saving type by duration !!!
      var savingsType = _.filter(vm.savingTypesGroup, function (savingType) {
        var minDuration = parseInt(savingType.minSavingDays);
        var maxDuration = parseInt(savingType.maxSavingDays);
        if (savingType.minSavingDays == "") {
          minDuration = 0;
        }
        if (savingType.maxSavingDays == "") {
          maxDuration = Infinity;
        }
        return (minDuration <= parseInt(vm.model.maturityInDays) && parseInt(vm.model.maturityInDays) <= maxDuration)
      });

      if (savingsType.length) {
        vm.onSavingsTypeChange(savingsType[0]);
      } else {
        vm.model.savingsType = {};
        vm.model.totalInterestRate = 0;
      }
    }


    vm.setAccountFrom = function (item) {
      vm.model.fromAccountSelect = item;
      vm.model.currency = vm.model.fromAccountSelect.currencySymbol;
      // vm.depositsFilter.accountId = item.accountId;
      vm.fromAccountDropdown = false;
    };

    vm.toggleFromAccount = function () {
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.onSavingsTypeChange = function (savingsType) {
      vm.model.savingsType = savingsType;
      if (savingsType.savingOrderTypeCalculationList[0]) {
        vm.model.totalInterestRate = savingsType.savingOrderTypeCalculationList[0].totalRate;
      } else {
        vm.model.totalInterestRate = 0;
      }

      // If one of fields “minSavingDays” and “maxSavingDays“ is empty, then this field is hidden.
      if (savingsType.minSavingDays != "" && savingsType.maxSavingDays != "") {
        // vm.minDurationInDays = savingsType.minSavingDays;
        // vm.maxDurationInDays = savingsType.maxSavingDays;
        // vm.model.maturityInDays = savingsType.minSavingDays;
        // vm.calculateMaturityDateWithDays(savingsType.minSavingDays);
        vm.durationInputHidden = false;
      } else {
        vm.model.maturityInDays = "";
        vm.model.maturityInMonths = "";
        vm.model.maturityDate = new Date();
        vm.durationInputHidden = true;
      }

      // If all savings of the selected Savings type have the same period and all have “minSavingDays” = “maxSavingDays“ ± 12 days
      // then this is READ-ONLY with value of “minSavingDays” converted to months (too hard to convert days in months ?)
      // if ((savingsType.minSavingDays != "" && savingsType.maxSavingDays != "") && savingsType.maxSavingDays - savingsType.minSavingDays <= 12) {
      //   vm.model.maturityInDays = savingsType.minSavingDays;
      //   vm.calculateMaturityDateWithDays(savingsType.minSavingDays);
      //   vm.maturityInputDisabled = true;
      // } else {
      //   vm.maturityInputDisabled = false;
      // }

      vm.savingsPeriodType = savingsType.periodType;

      if (savingsType.periodType == "K") {
        vm.assignmentOfInterestOptions = [
          {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
        ];
        vm.assignmentOfInterestId = vm.assignmentOfInterestOptions[0].id;
        vm.assignmentOfInterestisShown = true;
        vm.assignemntOfInterestIsDisabled = true;
      }
      else if (savingsType.periodType == "D") {
        vm.assignmentOfInterestOptions = [
          {id: 1, value: "termDeposit.assignmentOfInterestAnnual"},
          {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
        ];
        vm.model.assignmentOfInterest = vm.assignmentOfInterestOptions[0].id;
        vm.assignmentOfInterestisShown = true;
        vm.assignemntOfInterestIsDisabled = false;
      } else if (!savingsType.periodType) {
        vm.model.assignmentOfInterest = "";
        vm.assignmentOfInterestisShown = false;
        vm.assignemntOfInterestIsDisabled = false;
      }

      vm.onStndOrderPaymentDayChange(vm.standingOrderPaymentDayOptions[0].value);

      if (savingsType.periodType == "K") {
        vm.model.standingOrderPayment = "";
      } else {
        vm.model.standingOrderPayment = vm.standingOrderPaymentDayOptions[0];
      }

      if (savingsType.savingType == 1) {
        vm.model.assignmentOfInterest = "";
        vm.assignmentOfInterestisShown = false;
        vm.model.maturityDate = "";
        vm.model.maturityInDays = "";
      }

      // Init date values
      vm.model.maturityDate = HelperService.setCustomMonthPeriodByDays(new Date, parseInt(vm.model.maturityInDays));
      vm.onStndOrderPaymentDayChange(vm.model.standingOrderPayment.value);
      validateMaturityDate(vm.model.maturityInDays);
      vm.amountChanged(vm.model.amount);
    };

    var daysBetweenMonths;
    vm.calculateMaturityDate = function (durationInMonths) {
      if (!durationInMonths) {
        return;
      }
      if (durationInMonths.length > 3) return;

      // resetMaturityDateValidation();
      vm.model.maturityDate = HelperService.setCustomMonthPeriod(new Date(), parseInt(durationInMonths));
      daysBetweenMonths = Math.round(( vm.model.maturityDate - new Date()) / 86400000);
      vm.model.maturityInDays = daysBetweenMonths;
      vm.calculateMaturityDateWithDays(daysBetweenMonths);

      // vm.onStndOrderPaymentDayChange(vm.model.standingOrderPayment.value);
      // vm.model.maturityInDays = daysBetweenMonths;
      // vm.model.maturityInDays = daysBetweenMonths;
      // validateMaturityDate(daysBetweenMonths);
    };

    vm.calculateMaturityDays = function (date) {
      vm.model.maturityInMonths = "";
      daysBetweenMonths = vm.model.maturityInDays = Math.round(( date - new Date()) / 86400000);
      findSavingsType();
      validateMaturityDate(daysBetweenMonths);
    };

    vm.calculateMaturityDateWithDays = function (durationInDays) {
      if (!durationInDays) {
        durationInDays = 0;
      }

      if (daysBetweenMonths != durationInDays) {
        vm.model.maturityInMonths = "";
      }

      if (durationInDays.length > 6) return;

      // resetMaturityDateValidation();
      vm.model.maturityDate = HelperService.setCustomMonthPeriodByDays(new Date, parseInt(durationInDays));
      vm.onStndOrderPaymentDayChange(vm.model.standingOrderPayment.value);
      findSavingsType();
      validateMaturityDate(durationInDays);
    };

    function validateMaturityDate(daysBetweenMonths) {
      if (vm.selectedSavingTypesGroupId == 1) return;
      $timeout(function () {
        if (vm.minimalDurationForSelectedGroup > parseInt(daysBetweenMonths)) {
          vm.savingsOrderForm.maturityInMonths.$setValidity("minvalue", false);
        } else {
          vm.savingsOrderForm.maturityInMonths.$setValidity("minvalue", true);
        }

        if (vm.maxSavingDuration < parseInt(daysBetweenMonths)) {
          vm.savingsOrderForm.maturityInMonths.$setValidity("maxvalue", false);
        } else {
          vm.savingsOrderForm.maturityInMonths.$setValidity("maxvalue", true);
        }

        // if (daysBetweenMonths > parseInt(vm.maxDurationInDays)) {
        //   vm.savingsOrderForm.maturityInMonths.$setValidity("maxvalue", false);
        // } else {
        //   vm.savingsOrderForm.maturityInMonths.$setValidity("maxvalue", true);
        // }
      })
    }

    // function resetMaturityDateValidation() {
    //   vm.savingsOrderForm.maturityInMonths.$setValidity("minvalue", true);
    //   vm.savingsOrderForm.maturityInMonths.$setValidity("maxvalue", true);
    // }


    vm.amountChanged = function (amount) {
      if (!amount) amount = 0;
      if (vm.model.savingsType && (amount < parseInt(vm.model.savingsType.minInstalment))) {
        vm.savingsOrderForm.amount.$setValidity("minamount", false);
      } else {
        vm.savingsOrderForm.amount.$setValidity("minamount", true);
      }
    };
    //
    // function calculateInterestRate() {
    //   if (!vm.model.depositType) {
    //     vm.model.totalInterestRate = 0;
    //     return;
    //   }
    //   var dl = angular.copy(vm.model.depositType.depositOrderTypeCalculationList);
    //   for (var i = 0; i < dl.length; i++) {
    //     var item = dl[i];
    //     // First one that is bigger than amount
    //     if (vm.model.amount > item.fromAmount) {
    //       vm.model.totalInterestRate = item.interestRate;
    //       break;
    //     } else {
    //       vm.model.totalInterestRate = 0;
    //     }
    //   }
    // }


    vm.showOrderDetails = function (saving) {
      if (!saving.selected) {
        SavingsOrdersService.getSavingsOrderById(saving.id)
          .then(function (response) {
            angular.extend(saving, response);
            saving.translatedStatus = "orders." + saving.status;
            saving.payoutTypeTranslate = 'core.termDepositPayoutType.' + saving.payoutType;
            saving.endDate = (saving.endDate && new Date(saving.endDate).getFullYear() > 100) ? new Date (saving.endDate) : undefined;
            saving.selected = !saving.selected;
          }, function (error) {
            console.log(error);
          });
      } else {
        saving.selected = false;
      }
    };


    vm.loadAllAccountsTimeline = function () {
      vm.getTimelineItemsGrouped(true);
    };

    var createInsertObject = function () {
      if (!vm.model.savingsType) return;

      // if (selectedLanguage == "en") {
      //   vm.model.interestRange = _.find(assignmentOfInterestOptionsEnglish, {id: vm.assignmentOfInterestId});
      // } else if (selectedLanguage == "sl") {
      //   vm.model.interestRange = _.find(assignmentOfInterestOptionsSlovenian, {id: vm.assignmentOfInterestId});
      // }

      vm.model.interestRange = _.find(assignmentOfInterestOptionsSlovenian, {id: vm.assignmentOfInterestId});


      var refInterestRate = vm.model.savingsType.savingOrderTypeCalculationList[0].referenceRateAmount;
      var savingRate = vm.model.savingsType.savingOrderTypeCalculationList[0].realRate;
      var data = {
        savingTypeId: vm.model.savingsType.savingType,
        destAccount: vm.model.fromAccountSelect.iban,
        durationInMonths: Math.round(vm.model.maturityInDays / 30),
        demarcationOfInterest: {
          id: vm.model.interestRange.name,
          description: vm.model.interestRange.name
        },
        savingRate: (savingRate) ? savingRate : 0,
        refInterestRate: (refInterestRate) ? refInterestRate : 0,
        endDate: vm.model.maturityDate,
        accountID: vm.model.fromAccountSelect.accountId,
        termsAndConditions: vm.userApproves,
        endDateChanged: vm.model.dateChanged,
        stOrdFirstPaymentMonth: vm.model.firstPaymentDate.getMonth() + 1,
        stOrdFirstPaymentYear: vm.model.firstPaymentDate.getFullYear(),
        stOrdFirstPaymentDate: vm.model.firstPaymentDate,
        stOrdLastPaymentDate: vm.model.lastPaymentDate,
        // stOrdAccount: vm.model.fromAccountSelect.iban,
        stOrdTearDate: vm.model.standingOrderPayment.value,
        // "depositPurchaseDate": "2016-11-16T09:16:38.108Z"
      };

      if(data.savingTypeId === 2) {
        delete data.endDate;
        delete data.durationInMonths;
      }
      if (vm.model.savingsType.periodType == "K") {
        data.firstDepositAmount = vm.model.amount;
        data.monthlyDepositAmount = undefined;
        data.stOrdAmount = 0;
        data.stOrdIsRequested = undefined;
      } else if (vm.model.savingsType.periodType == "D") {
        data.firstDepositAmount = undefined;
        data.monthlyDepositAmount = vm.model.amount;
        data.stOrdAmount = vm.model.amount;
        data.stOrdIsRequested = true;
      }
      return data;
    };

    vm.next = function () {
      if (vm.nextButtonPressed) return;

      if (vm.savingsOrderForm.$invalid) {
        return;
      }
      vm.formSubmitted = true;
      vm.nextButtonPressed = true;
      SavingsOrdersService.validateSavingsOrder(createInsertObject()).then(function (response) {
        if (response.endDateChanged == "true") {
          AlertService.confirmationAlert({text: "orders.maturityDateChangedErr"})
            .then(function (accept) {
              if (!accept) return;
              vm.model.maturityDate = new Date(response.endDate);
              vm.model.firstPaymentDate = new Date(response.stOrdFirstPaymentDate);
              vm.model.lastPaymentDate = new Date(response.stOrdLastPaymentDate);
              vm.calculateMaturityDays(vm.model.maturityDate);
              vm.responseModel = angular.copy(vm.model);

              vm.steps = {
                step1: false,
                step2: true,
                step3: false
              };
            })
        } else {
          vm.responseModel = angular.copy(vm.model);
          vm.steps = {
            step1: false,
            step2: true,
            step3: false
          };
          vm.model.dateOfOpening = new Date();
          vm.model.firstPaymentDate = new Date(response.stOrdFirstPaymentDate);
          vm.model.lastPaymentDate = new Date(response.stOrdLastPaymentDate);
        }
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      }).finally(function () {
        vm.nextButtonPressed = false;
      });
    };

    vm.confirm = function () {
      if (vm.confirmButtonPressed) return;
      vm.confirmButtonPressed = true;
      SavingsOrdersService.insertSavingOrder(createInsertObject()).then(function (response) {
        vm.steps = {
          step1: false,
          step2: false,
          step3: true
        };
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      }).finally(function() {
        vm.confirmButtonPressed = false;
      });
    };

    vm.back = function () {
      vm.steps = {
        step1: true,
        step2: false,
        step3: false
      };
    };

    vm.newSavingsOrder = function () {
      $state.go("orders.newSavingsOrder", {}, {reload: true});
    };

    vm.onStndOrderPaymentDayChange = function (paymentDay) {
      var today = new Date();
      var maturityDate = vm.model.maturityDate;
      // Uzme se izabrani dan (8/18/28) u sljedečem mjesecu od trenutnog
      var firstPaymentDate = new Date(today.getFullYear(), today.getMonth() + 1, paymentDay);
      // Ne može biti preko maturity date, tako da se uzme zadnji moguči dan (8/18/28) prije maturity date
      var lastPaymentDate = new Date(maturityDate.getFullYear(), maturityDate.getMonth(), paymentDay);
      if (lastPaymentDate.getTime() > maturityDate.getTime()) {
        lastPaymentDate = new Date(maturityDate.getFullYear(), maturityDate.getMonth() - 1, paymentDay);
      }

      vm.model.firstPaymentDate = firstPaymentDate;
      vm.model.lastPaymentDate = lastPaymentDate;
      // vm.model.lastPaymentDate =
      console.log(paymentDay);
    };

    // =========================
    // START Timeline settings
    // =========================
    vm.recompile = {
      timeline: true
    };
    vm.timelineListGrouped = {};

    vm.loadMoreTimelineItemsGrouped = function () {
      vm.getTimelineItemsGrouped(false);
    };

    var timeRange = HelperService.getDateRange('thisMonth');
    vm.selectedAccountId = '';
    vm.depositsFilter = {
      page: 0,
      orderBy: 'savingEnteredDate DESC', //signDate
      pageSize: 15,
      signDateGTE: null,
      signDateLTE: null,
      accountId: null,
      userId: null,
      includeList: ["SavingOrderType"]
    };

    vm.userSettingsFilter = {
      page: 0,
      pageSize: 1000
    };

    // var getUserSettingsPage = function () {
    //   return UserSettingsService.getPage(vm.userSettingsFilter)
    //     .then(function (response) {
    //       vm.userSettings = response.result;
    //     })
    //     .catch(function (error) {
    //       $log.error(error);
    //     });
    // };

    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: 'all',
          description: 'core.period.all'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[5];

    vm.paymentsPeriodFilter = function (item) {
      vm.selectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      vm.depositsFilter.signDateGTE = timeRange.valueDateGTE;
      vm.depositsFilter.signDateLTE = timeRange.valueDateLTE;
      vm.getTimelineItemsGrouped(true);

      GoogleTagManagerHelperService.pushEvent('SavingsOrder', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
    }

    vm.togglePaymentDetails = function (payment) {
      payment.selected = !payment.selected;
    };
    vm.getTimelineItemsGrouped = function (reset) {

      if (reset) {
        vm.depositsFilter.page = 0;
      } else {
        vm.depositsFilter.page += 1;
      }


      // if (vm.allRequests) {
      //   vm.depositsFilter.accountId = null;
      // } else {
        // if (vm.model.fromAccountSelect)
        //   vm.depositsFilter.accountId = vm.model.fromAccountSelect.accountId;

      // }

      var translatePaymentProperties = function (orders) {
        _.forEach(orders, function (item) {
          item.translatedStatus = "orders." + item.status;
          item.payoutTypeTranslate = 'core.termDepositPayoutType.' + item.payoutType;
        });
      };
      //vm.depositsFilter.userId = vm.userSettings.id;

      SavingsOrdersService.getSavingsOrders(vm.depositsFilter)
        .then(function (response) {
          translatePaymentProperties(response.result);
          vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.timelineListGrouped,
            newData: response,
            groupFunction: function (item) {
              var date = new Date(item.savingEnteredDate);
              // If we want to group only by date component, without hours
              date.setHours(0, 0, 0, 0);
              return date;
            },
            virtualRowFunction: function (e) {
              var month = new Date(e).getMonth() + 1;
              var day = new Date(e).getDate();
              var virtualRow = {
                isVirtual: true,
                valueDate: new Date(e),
                month: month,
                day: day
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          });

          vm.recompile.timeline = true;
        }).catch(function (error) {
        $log.error(error);
      });
    };
    // =========================
    // END Timeline settings
    // =========================


    vm.downloadContract = function (id) {
      SavingsOrdersService.downloadContract(id)
        .catch(function(error){
          NotificationService.showMessage(error, 'error');
        });
    }

    vm.downloadTermsAndConditions = function (id) {
      SavingsOrdersService.downloadTermsAndConditions(id)
        .catch(function(error){
          NotificationService.showMessage(error, 'error');
        });
    }
  }
}());
