<div class="receiver-section" style="padding: 15px">
  <ng-form name="investment.fundsForm">
    <div class="row form-group">
      <!-- START From Account - Custom Dropdown -->
      <div class="col-md-6">
        <label class="domp-form-label" translate="{{investment.model.orderType == 2 ? 'invest.accountTo':'invest.accountFrom'}}"></label>
        <div class="accounts-dropdown">
          <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
               data-ng-click="investment.showAccountToDropdown = !investment.showAccountToDropdown"  ng-style="investment.model.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + investment.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
            <h4 class="no-wrap-text bold-font m-b-0">
              <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                {{investment.model.fromAccountSelect.friendlyName}}</b>
              <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                <i class="zmdi"
                   ng-class="{'zmdi-star favorite-account':account.isFavorite, 'zmdi-star-outline':!account.isFavorite}"></i>
              </span>
            </h4>
            <p class="m-b-0 m-t-0 iban-style">{{investment.model.fromAccountSelect.iban}}</p>
            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
              {{investment.model.fromAccountSelect.availableBalance| number:2}}
              {{investment.model.fromAccountSelect.currencySymbol}}
            </p>
            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
            <span class="caret-icon">
              <i class="zmdi zmdi-caret-down zmdi-hc-2x"></i>
            </span>
          </div>
          <div class="from-accounts-list " ng-show="investment.showAccountToDropdown" scrollable>
            <div class="accounts-list-item p-0" data-ng-repeat="item in investment.accountsFromList"
                 data-ng-click="investment.setAccountFrom(item); investment.showAccountToDropdown = !investment.showAccountToDropdown">
              <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <h4 class="no-wrap-text bold-font m-b-0">
                  <span class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</span>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                </h4>
                <span class="m-b-0 m-t-0 iban-style">{{item.iban}}</span>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">{{item.availableBalance| number: 2}} {{item.currencySymbol}}</p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END From Account - CUSTOM DROPDOWN-->
      <!-- START Amount and Date -->
      <div class="col-md-6">
        <!-- START: SELL FUNDS -->
        <div class="clearfix" ng-if="investment.model.orderType != 1">
          <!-- Label -->
          <div style="margin-top: 29px">
            <!-- Type -->
            <div class="form-group col-md-6" style="padding: 2px">
              <select  class="form-control input-field"
                       data-ng-model="investment.model.amountType"
                       ng-options="amountType.id as amountType.name |  translate for amountType in investment.amountTypes"
                       ng-change="investment.onAmountTypeChange(investment.model.amountType)">
              </select>
            </div>
            <!-- Label -->
            <div class="col-md-6" style="padding: 2px">
              <div>
                <div class="input-group" ng-if="investment.model.amountType == 2">
                  <input type="text"  class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                         autocomplete="off" placeholder="{{'invest.amountPlaceholder'| translate}}"
                         amount-input-mask
                         ng-model="investment.model.amountInput"
                         required
                         ng-class="{'domp-error': investment.fundsForm.amountInput.$invalid && investment.formSubmitted}"
                         maxlength="12">
                  <span class="input-group-btn">
                    <button type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">
                      {{investment.model.fromAccountSelect.currencySymbol}}
                    </button>
                  </span>
                </div>
                <div ng-if="investment.model.amountType == 3" >
                  <input
                    type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                    autocomplete="off"  placeholder="eg. 10"
                    amount-input-mask fraction="4"
                    ng-model="investment.model.invQuantity"
                    ng-class="{'domp-error': investment.fundsForm.amountInput.$invalid && investment.formSubmitted}"
                    maxlength="12"
                    required>
                </div>

                <span class="c-red"
                      ng-show="investment.fundsForm.amountInput.$error.min && investment.formSubmitted || investment.fundsForm.amountInput.$error.required && investment.formSubmitted"
                      translate="invest.amountMinAmountError">
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: SELL FUNDS -->

        <!--<div class="row">-->
        <!-- START Amount -->
        <div class="form-group" ng-if="investment.model.orderType == 1">
          <label class="domp-form-label" for="amountInput" translate="invest.amountInputLabel">Amount:</label>
          <div class="input-group">
            <input id="amountInput" type="text" ng-if="investment.model.orderType == 1" class="form-control input-field t-a-r" name="amountInput"
                   autocomplete="off" placeholder="{{'invest.amountPlaceholder'| translate}}"
                   amount-input-mask
                   ng-model="investment.model.amountInput"
                   required
                   ng-class="{'domp-error': investment.fundsForm.amountInput.$invalid && investment.formSubmitted}"
                   maxlength="12">
            <input type="text" ng-if="investment.model.orderType != 1" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                   autocomplete="off" placeholder="{{'invest.amountPlaceholder'| translate}}"
                   amount-input-mask
                   ng-model="investment.model.amountInput"
                   required
                   ng-class="{'domp-error': investment.fundsForm.amountInput.$invalid && investment.formSubmitted}"
                   maxlength="12">
            <span class="input-group-btn">
              <button type="button"
                      class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">
                {{investment.model.fromAccountSelect.currencySymbol}}
              </button>
            </span>
          </div>
          <span class="c-red"
                ng-show="investment.fundsForm.amountInput.$error.min && investment.formSubmitted || investment.fundsForm.amountInput.$error.required && investment.formSubmitted"
                translate="invest.amountMinAmountError">
          </span>
          <br>
          <!--<span class="c-red"-->
                <!--ng-show="investment.fundsForm.amountInput.$error.min && investment.formSubmitted"-->
                <!--translate="invest.amountMinAmountError2">-->
          <!--</span>-->
        </div>
        <!-- End Amount-->
        <!-- START Date-->
        <div class="form-group">
          <label ng-show="investment.orderTypeText != 'new'" class="domp-form-label" for="dateInput" translate="invest.dueDate">Date</label>
          <label ng-show="investment.orderTypeText == 'new'" class="domp-form-label" for="dateInput" translate="invest.dateNewOrder">Date</label>
          <div class="input-group">
            <input type="text" name="dateInput" class="form-control input-field"
                   ng-model="investment.model.dueDateInput"
                   uib-datepicker-popup="shortDate"
                   current-text="{{'core.period.today' | translate}}"
                   clear-text="{{'core.datePicker.clearBtn' | translate}}"
                   close-text="{{'core.datePicker.doneBtn' | translate}}"
                   datepicker-options="investment.dateOptions"
                   date-disabled="investment.dateDisabled(date, mode)"
                   is-open="investment.datePickerOpened"
                   readonly>
            <span class="input-group-btn">
              <button data-ng-click="investment.datePickerOpened = !investment.datePickerOpened"
                      class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                <i class="icon icon-calendar" style="font-size: 21px;"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <!-- END Date-->
      <!-- END Amount and Date -->
    </div>
    <hr>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label class="domp-form-label" translate="invest.fundName"></label>
          <select class="form-control input-field"
                  data-ng-model="investment.model.fundSelect"
                  ng-options="item.fundName for item in investment.fundList"
                  ng-change="investment.onFundSelect(investment.model.fundSelect);"
                  ng-disabled="investment.fundId">
          </select>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" ng-if="investment.model.orderType !== 3">
        <div class="form-group">
          <label class="domp-form-label" for="toAccountInput" translate="{{investment.model.orderType != 1 ? 'invest.accountFrom':'invest.accountTo'}}">
            Account number
          </label>
          <input type="text" class="form-control input-field" capitalize
                 ng-pattern-restrict="{{investment.regexPatterns.allLettersNumbersSpaces}}"
                 ng-model="investment.model.toAccountInput"
                 ng-class="{'domp-error': investment.fundsForm.toAccountInput.$invalid && investment.formSubmitted}"
                 name="toAccountInput" id="toAccountInput" required maxlength="39" ng-trim="false"
                 readonly>
          <span class="c-red"
                ng-show="investment.fundsForm.toAccountInput.$error.required && investment.formSubmitted"
                translate="domesticPayment.receiverAccountError"></span>
        </div>
      </div>
    </div>

    <div class="row" ng-if="investment.model.orderType == 3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label" translate="invest.fundNameReplace"></label>
          <select class="form-control input-field"
                  data-ng-model="investment.model.fundSelectReplace"
                  data-ng-change="investment.fundSelectReplaceChange(investment.model.fundSelectReplace);"
                  ng-options="item.fundName for item in investment.fundReplacementList">
          </select>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label class="domp-form-label" for="fundSelectReplaceInput" translate="invest.accountTo">
            Account number
          </label>
          <input type="text" class="form-control input-field" capitalize
                 ng-pattern-restrict="{{investment.regexPatterns.allLettersNumbersSpaces}}"
                 id="fundSelectReplaceInput"
                 ng-model="investment.model.fundSelectReplaceInput"
                 ng-class="{'domp-error': investment.fundsForm.fundSelectReplaceInput.$invalid && investment.formSubmitted}"
                 name="fundSelectReplaceInput" required maxlength="39" ng-trim="false"
                 readonly>
          <span class="c-red"
                ng-show="investment.fundsForm.fundSelectReplaceInput.$error.required && investment.formSubmitted"
                translate="domesticPayment.receiverAccountError"></span>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label class="domp-form-label" translate="invest.accountType">
            Account type</label>
          <select class="form-control input-field p-l-15 p-r-10"
                  ng-disabled="investment.fundId"
                  ng-model="investment.model.accountType" ng-change="investment.getUserInvestmentAccounts();">
            <option value="1">{{"invest.investor"| translate}}</option>
            <option value="2">{{"invest.loan"| translate}}</option>
          </select>
        </div>
      </div>
      <!-- Shared accounts related to selected fund -->
      <div class="col-lg-6 col-md-6 col-sm-6" ng-if="investment.model.investmentAccount.shareAccount">
        <div class="form-group">
          <label class="domp-form-label" translate="invest.accounts">Accounts</label>
          <select class="form-control input-field p-l-15 p-r-10" ng-model="investment.model.investmentAccount" ng-options="item.shareAccount for item in investment.investmentAccountList">
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 form-group">
        <div class="no-padding-on-phone">
          <label class="domp-form-label domp-purpose-label"
                 translate="invest.comment">Comment</label>
          <div class="p-0">
            <input type="text" class="form-control input-field"
                   data-ng-model="investment.model.comment"
                   name="comment"
                   ng-pattern-restrict="{{investment.regexPatterns.allowedCharsDPStextArea}}"
                   ng-class="{'domp-error': investment.fundsForm.paymentPurposeInput.$invalid && investment.formSubmitted}">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div>
          <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value pointer">
            <input type="checkbox" ng-model="investment.isCondition1Checked" required>
            <i class="input-helper"></i>
          </label><a class="template-fav m-l-25 pointer" ng-click="investment.openBrochure()">{{'invest.rulesCheckbox' | translate}}</a>
        </div>
      </div>
    </div>

  </ng-form>

  <div class="intp-form-container">
    <div class="row">
      <div class="col-sm-12 col-lg-12 col-md-12 p-l-0">
        <div class="col-xs-12">
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.bellowComment'| translate}}
            <b><a class="template-fav" href ng-click="investment.openBrochure()">{{'invest.bellowComment2_link1'| translate}}</a></b>
            {{'invest.bellowComment2_text'| translate}}
            <b><a class="template-fav" href ng-click="investment.openBrochure2()">{{'invest.bellowComment2_link2'| translate}}</a></b>
            {{'invest.bellowComment3'| translate}}
          </label>

          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText1'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            <b>{{'invest.disclaimerText2'| translate}}</b>
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText3'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText4'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText5'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText6'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText7'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText8'| translate}}
          </label>
          <label class="checkbox checkbox-inline-inline checkbox-value">
            {{'invest.disclaimerText9'| translate}}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-6 col-sm-6">
        <button class="btn btn-primary pull-left" ng-if="investment.fundId" translate="invest.back"
                data-ui-sref="investments.overview({fundId: investment.fundId})">
        </button>
        <button class="btn btn-primary pull-left" ng-if="!investment.fundId" translate="invest.back"
                data-ui-sref="investments.list">
        </button>
      </div>
      <div class="col-md-6 col-xs-6 col-sm-6">
        <button class="btn btn-default pull-right main-form-btn"
                translate="domesticPayment.confirmBtn"
                ng-disabled="investment.submitButtonDisabled || !investment.isCondition1Checked"
                data-ng-click="investment.submit()">
        </button>
      </div>
    </div>
  </div>
</div>
