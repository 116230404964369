(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:InstantPaymentSrbCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('InstantPaymentSrbCtrl', InstantPaymentSrbCtrl);

  function InstantPaymentSrbCtrl($rootScope, $location, CTLocalizationService, UserSettingsService, $state, $log, HelperService,
                                  ValidationService, PurposeCodesService, BanksService, CountriesService,
                                  PaymentTypesService, PaymentsService, CreditAccountRegisterService, $q,
                                  PaymentTemplatesService, ConfigEndpointService, $timeout, ReferenceModelService,
                                  $stateParams, PaymentFilterService, Notification, AlertService, ImagesService, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'InstantPaymentSrbCtrl';
    vm.images = {};
    vm.isRetrieval = false;
    vm.isCancelation = true;
    vm._cancelationId = 0;
    vm.CancelationData = {};
    vm.cancelationStep=1;

    //if ($stateParams.myParam == 'cancelation') {
    //vm.isCancelation = true;
    //}

    vm.currentStep = 1;

    vm.paymentObject = {};
    vm.tempDompObj = {
      fromAccountSelect: {},
      purposeCodeSelect: {},
      purposeOfPaymentInput: '',
      amountInput: '',
      dateInput: new Date(),
      bicCodeInput: '',
      toAccountInput: '',
      referenceCodeInput: '',
      referenceModelSelect: {},
      referenceNumberInput: '',
      payersNameInput: '',
      payersAddressInput: '',
      payersCityInput: '',
      payersSepaInput1: '',
      payersSepaInput2: '',
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      receiversCountrySelect: {},
      receiversSepaInput1: '',
      receiversSepaInput2: '',
      isUrgentPaymentCheckbox: false,
      formSubmitted: false
    };

    vm.split = function (input) {
      return HelperService.splitReferenceNumber(input);
    }

    vm.formSubmittedFlag = false;
    vm.defaultCurrency = '';
    vm.forUpdate = false;
    vm.smsAuthentificate = false;
    vm.smsPasswordInput = '';
    vm.bankCharges = '';

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000,
      isRequestCode: true
    };

    vm.banksList = {};
    vm.banksFilter = {
      pageSize: 1000
    };

    vm.countriesCodeList = {};
    vm.countriesCodeFilter = {
      pageSize: 1000
    };

    vm.referenceModelList = [];
    vm.referenceModelFilter = {
      pageSize: 1000
    };

    vm.payeeFilter = {
      paymentTypeGroup: 'DomesticPayment',
      orderBy: 'receiverName ASC',
      page: 0,
      pageSize: 1000
    };

    vm.templatesFilter = {
      paymentTypeGroup: 'DomesticPayment',
      page: 0,
      pageSize: 1000
    };

    vm.templateList = {};

    vm.tmpSearchText = {
      name: ''
    };

    vm.templateName = '';

    vm.fromAccountDropdown = false;
    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.searchFilterExpanded = false;

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    vm.checkModul97Reference = function (reference) {
      var isValid = ValidationService.checkModul97Reference(reference);
      vm.domesticPaymentForm.referenceNumberInput.$setValidity('modul97reference', isValid)
    };

    vm.getPaymentTypeGroup = function () {
      return PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);
          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.getPurposeCodesPage = function () {
      return PaymentsService.getCancelationCodes(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            value.optionValue = value.code + ' - ' + value.description;

          });
          vm.purposeCodesList = response;

          if (vm.purposeCodesList.result.length > 0) {
            var index = _.findIndex(vm.purposeCodesList.result, function (item) {
              return item.code === 'AC03';
            });
            console.log("Ovdje", index)
            vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[index];
            vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[index].description;
          }
          $log.debug("purposeCodesList", vm.purposeCodesList);
        })
        .catch(function () {
          $log.catch('Error loading purpose codes!');
        });
    };

    vm.getCancelationById= function () {
      if(vm._cancelationId>0)
      {
        return PaymentsService.getCancelationById(vm._cancelationId,null)
          .then(function (response) {
            vm.CancelationData = response;
          })
          .catch(function () {
            $log.catch('Error loading purpose codes!');
          });
      }
    }

    vm.getBanksPage = function () {
      return BanksService.getPage(vm.banksFilter)
        .then(function (response) {
          vm.banksList = response;
          $log.debug("banksList", vm.banksList);
        })
        .catch(function () {
          $log.catch('Error loading banks codes!');
        });
    };

    vm.changePurpose = function (purposeChange) {
      // Check if input is coming from purpose code list
      var purpose = _.find(vm.purposeCodesList.result, {
        code:vm.tempDompObj.purposeCodeSelect.code
      });
      if (purpose) {
        vm.tempDompObj.purposeOfPaymentInput = purposeChange.description;
      }
      // var purposeCode = _.findIndex(vm.purposeCodesList.result, function (item) {
      //     vm.tempDompObj.purposeOfPaymentInput = item.description;
      //     return item.id == code.id;
      // });
    };

    vm.getCountriesPage = function () {
      return CountriesService.getPage(vm.countriesCodeFilter)
        .then(function (response) {
          vm.countriesCodeList = response;
          var countryIndex = _.findIndex(vm.countriesCodeList.result, function (o) {
            return o.code == '191';
          });
          vm.tempDompObj.receiversCountrySelect = vm.countriesCodeList.result[countryIndex];

          $log.debug("countriesCodeList", vm.countriesCodeList);
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.catch(error);
        });
    };

    var createCancelationObject = function () {
      var cancelationObject = {};
      cancelationObject.paymentId =vm.CancelationData.paymentId;
      cancelationObject.cancelationReasonCode =vm.tempDompObj.purposeCodeSelect.code;
      cancelationObject.cancelationReasonDescription =vm.tempDompObj.purposeOfPaymentInput;

      vm.CancelationData.cancelationReasonCode=cancelationObject.cancelationReasonCode;
      vm.CancelationData.cancelationReasonDescription=cancelationObject.cancelationReasonDescription;
      return cancelationObject;
      //CancelationData.cancelationReasonCode
      //CancelationData.cancelationReasonDescription
    };

    vm.insertedPaymentObject = {};
    vm.insertedCancelationObject = {};
    vm.insertedPaymentObjectDomp = {};

    vm.submitPayment = function () {
      vm.tempDompObj.formSubmitted = true;

      if (!vm.instantPaymentForm.$valid) {
        return;
      }

      if (!vm.formSubmittedFlag) {
        vm.formSubmittedFlag = true;
        var objectForInsert = createCancelationObject();
        if (vm.forUpdate) {
          objectForInsert.id = vm.CancelationData.id;
          PaymentsService.updateCancelationForPayment(vm.CancelationData.id, objectForInsert)
            .then(function (response) {
              //postPaymentAction(response);
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;
              vm.cancelationStep=2;
              vm.CancelationData = response;
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;
              // vm.domesticPaymentForm.$valid = false;
            })
            .finally(function () {

            });
        }
        else {
          PaymentsService.insertCancelationForPayment(objectForInsert)
            .then(function (response) {
              //postPaymentAction(response);
              vm.formSubmittedFlag = false;
              vm.cancelationStep=2;
              vm.CancelationData = response;
          })

            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.formSubmittedFlag = false;
              // vm.domesticPaymentForm.$valid = false;
            })
            .finally(function () {

            });
        }
      }
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deleteCancelation = function () {
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deleteCancelation(vm.CancelationData.id)
              .then(function (response) {
                  $state.go('payments.paymentsOverview');
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.cancelationStep=1;

      if (vm.tempDompObj.referenceModelSelect === "00") {
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      } else {
        var index = _.findIndex(vm.referenceModelList, function (item) {
          return item == vm.tempDompObj.referenceModelSelect;
        });
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
      }

      // mapWithPaymentResponse();
    };

    vm.signPayment = function () {
      PaymentsService.signPayment(vm.insertedPaymentObject.id)
        .then(function (response) {
          vm.currentStep = 3;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var loadAccountData = function (accountId) {
      var index = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[index];
    };

    //////////////////////////CUSTOM SELECT//////////////////////
    vm.searchFilterExpanded = false;

    vm.toggleFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchFilterExpanded = !vm.searchFilterExpanded;
      }
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.getPayees = function () {
      PaymentTemplatesService.getPage(vm.payeeFilter)
        .then(function (response) {
          vm.payeeList = response.result;
        }).catch(function (error) {
        $log.catch(error);
      });
    };

    vm.selectPayee = function (id) {
      var index = _.findIndex(vm.payeeList, function (item) {
        vm.tempDompObj.toAccountInput = item.toAccountNumber;
        vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
        vm.tempDompObj.receiversNameInput = item.receiverName;
        vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
        return item.id == id;
      });

      vm.formatAccountNumber(vm.payeeList[index].toAccountNumber);
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.removeSearch = function () {
      vm.tempDompObj.receiversNameInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1 ||
        angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1);
    };

    ///select template component

    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;

      if (vm.searchTemplateFilterExpanded === true) {

        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
        if (vm.searchTemplateFilterExpanded && (!vm.templateList.result || vm.templateList.result.length === 0)) {
          vm.getPaymentTemplatesPage();
        }
      }
    };

    vm.getPaymentTemplatesPage = function () {
      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
          vm.templateList = response;
          if (vm.templateList)
            populateTemplatesWithImages();
          $log.debug(vm.templateList);
        }).catch(function (error) {
        $log.catch(error);
      });
    };
    vm.selectedTemplate = {};
    vm.selectTemplate = function (id) {
      GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentTemplateSelection', 'Template with id ' + id + ' selected');
      var item = _.find(vm.payeeList, {
        id: id
      });
      vm.templateName = item.name;
      vm.tempDompObj.toAccountInput = item.toAccountNumber;
      vm.formatAccountNumber(item.toAccountNumber);
      vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
      //vm.tempDompObj.amountInput = $filter('number')(item.amount, 2);

      if (item.referenceTo) {
        if (item.referenceTo.length > 2) {
          vm.tempDompObj.referenceModelSelect = item.referenceTo.substring(0, 2);
          vm.tempDompObj.referenceNumberInput = item.referenceTo.substring(2);
        } else {
          vm.tempDompObj.referenceModelSelect = item.referenceTo;
          vm.tempDompObj.referenceNumberInput = "";
        }
      }
      if (item.purposeCodeDPS && vm.purposeCodesList.result) {

        // Remove leading character from string
        var leading_char = item.purposeCodeDPS.substring(0, 1);
        if (item.purposeCodeDPS.length > 2 && leading_char === '2' || leading_char === '9') {
          item.purposeCodeDPS = item.purposeCodeDPS.substring(1);
        }
      }

      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;

      vm.selectedTemplate.imageId = item.imageId;
      setImage(vm.selectedTemplate);
    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    }
    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
      vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      vm.tempDompObj.referenceNumberInput = '';
      vm.selectedTemplate = {};

    };

    //from account component
    vm.toggleFromAccount = function () {
      if (!vm.isRetrieval) {
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
      }
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    vm.showSMSAuthentification = function () {
      vm.smsAuthentificate = !vm.smsAuthentificate;
    };

    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.getReferenceModels = function () {
      return ReferenceModelService.getPage(vm.referenceModelFilter)
        .then(function (response) {
          vm.referenceModelList.push(""); //First item in refModelList is empty string
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
          vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.formatAccountNumber = function (accountNumber) {
      if (!accountNumber) return;
      var accountNumber = accountNumber.replace(/-/g, ""); // remove dashes
      if (accountNumber.length < 6) {
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", false);
        return;
      } else {
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", true);
      }
      var str1 = accountNumber.substring(0, 3);
      var str2 = ("0000000000000" + accountNumber.substring(3, accountNumber.length - 2)).slice(-13); // if 5 returns 0000..005
      var str3 = accountNumber.substring(accountNumber.length - 2);

      vm.tempDompObj.toAccountInput = str1 + "-" + str2 + "-" + str3;
    };

    vm.fillReceiver = function (toAccountInput) {
      if (!toAccountInput) return;
      if (vm.domesticPaymentForm.toAccountInput.$valid) {
        vm.formatAccountNumber(vm.tempDompObj.toAccountInput);
        //CreditAccountRegisterService.getPage({accountNumber: toAccountInput.replace(/-/g, '')})
        CreditAccountRegisterService.getPage({
          accountNumber: vm.tempDompObj.toAccountInput.replace(/-/g, '')
        })
          .then(function (response) {

            if (response.result.length > 0) {
              var rName = response.result[0].receiverName;
              var rAddressInput = response.result[0].receiverAddress1;
              var rPostalCodeAndCityInput = response.result[0].receiverCity;
              vm.tempDompObj.receiversNameInput = rName.substring(0, 35);
              vm.tempDompObj.receiversAddressInput = rAddressInput.substring(0, 35);
              vm.tempDompObj.receiversPostalCodeAndCityInput = rPostalCodeAndCityInput.substring(0, 35);
            } else {
              vm.tempDompObj.receiversNameInput = '';
              vm.tempDompObj.receiversAddressInput = '';
              vm.tempDompObj.receiversPostalCodeAndCityInput = '';
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.getDefaultDetails = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response) {
            vm.defaultCurrency = response.DefaultCurrency;
            vm.bankCharges = response.BankCharges;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.newPayment = function () {
      clearInput();
      vm.tempDompObj.formSubmitted = false;
      vm.forUpdate = false;
      vm.currentStep = 1;
    }

    var clearInput = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.dateInput = new Date();
      vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      vm.tempDompObj.referenceNumberInput = '';

      var index = _.findIndex(vm.purposeCodesList.result, function (item) {
        return item.id === '89';
      });

      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[index];
      vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[index].description;

      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
    }

    vm.init = function () {
      if ($state.params.editable) {
        vm.insertedPaymentObject.id = $state.params.paymentId;
        vm.forUpdate = true;
      }

      if(vm.isCancelation)
      {
        vm.CancelationData.paymentId = $state.params.paymentId;
        vm._cancelationId = $state.params.cancelationId;
      }

      if(vm._cancelationId)
      {
        vm.getCancelationById(vm._cancelationId);
      }

      vm.getPayees();
      // vm.getUserSettingsPage();
      // , vm.getDefaultDetails()

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
        vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        vm.defaultCurrency = vm.defaultData.DefaultCurrency;
        vm.bankCharges = vm.defaultData.BankCharges;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
        var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
        vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
        vm.tempDompObj.payersAddressInput = userSettings.address1;
        vm.tempDompObj.payersCityInput = userSettings.city;
      }

      var promises = [
        vm.getPurposeCodesPage(),
        vm.getBanksPage(),
        vm.getCountriesPage(),
        vm.getPaymentTypeGroup(),
        vm.getReferenceModels(),
        loadImages(),
        vm.getCancelationById()];
      $q.all(promises)
        .then(function () {
          //set default form params

          var params = {
            fromAccountList: vm.paymentTypeGroupDomestic.result[0].debitAccountList,
            purposeCodeList: vm.purposeCodesList.result,
            referenceModelList: vm.referenceModelList
          };

          switch ($state.current && $state.current.name) {
            case 'payments.instantPayment.fromPayment':
              PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                .then(function () {
                  vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  vm.changePurpose(vm.tempDompObj.purposeCodeSelect);
                })
                .catch(function (error) {
                  $log.catch('loadDomesticPaymentData()', error);
                });
              break;
            case 'payments.instantPayment.edit':
              PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                .then(function () {
                  vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  vm.changePurpose(vm.tempDompObj.purposeCodeSelect);
                })
                .catch(function (error) {
                  $log.catch('loadDomesticPaymentData()', error);
                });
              break;
          }

        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    vm.templateImagesFilter = {
      category: "Templates"
    };

    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        console.log(JSON.stringify(images));
        vm.images = images.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    vm.pushGtmUrgentPaymentEvent = function () {
      GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentIsUrgentSelection', (vm.tempDompObj.isUrgentPaymentCheckbox) ? 'Urgent payment checked' : 'Urgent payment unchecked');
    };
    vm.init();

  }
}());
