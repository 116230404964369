(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
          sepa: {
            "header": "Nastavitve SEPA direktnih obremenitev",
            "addNew": "Dodaj nastavitev",
            "newSepaHeader":"Dodaj nastavitev",
            "debitAccountSelectLabel":"Za račun",
            "noData":"Ni podatkov",
            "settingType":"Vrsta nastavitve",
            "amountInputLabel":"Znesek",
            "cancelBtnLabel":"Prekliči",
            "confirmBtnLabel":"Potrdi",
            "edit":"Spremeni",
            "transactionsHeader":"Transakcije SEPA direktnih obremenitev",
            "transactionsSetLimits":"Nastavi limite in omejitve",
            "sepaSuccessCreate": "Naročilo je bilo uspešno oddano",
            "complaint":"Ugovor",
            "refund":"Povračilo",
            limitType: "Vrsta nastavitve",
            "back": "Nazaj",
            "isActive": "Vključena",
            "notActive": "Izključena",
            "changeToActivated": "Ali res želite vključiti nastavitev?",
            "changeToDeactivated": "Ali res želite izključiti nastavitev?",
            "activateSuccess": "Nastavitev je uspešno vključena!",
            "deactivateSuccess": "Nastavitev je uspešno izključena!",
            "requiredSettingTypeError":"Vrsta nastavitve ne sme biti prazna.",
            paymentsType: {
              "rejectedTab":"Zavrnjene transakcije",
              "executedTab":"Izvršene transakcije",
              "pendingTab":"Čakajoče transakcije"
            },
            refundComplaint: {
              "paymentStatus": "Status transakcije",
              "executionDate": "Datum izvršitve plačila",
              "valuteDate": "Datum valute",
              "payersAccNumber": "Številka računa plačnika",
              "recipient" :"Naziv prejemnika",
              "uniqueMandateReference": "Enolična referenca mandata",
              "paymentPurpose": "Namen obremenitve",
              "recIdentifier": "Identifikator prejemnika",
              "recAccNumber": "Račun prejemnika",
              "recRefNumber": "Referenca prejemnika",
              "reasonForComplaint": "Razlog za ugovor",
              "additionalExplanation": "Dodatna obrazložitev",
              "refundTitle": "Ugovor na izvršeno transakcijo SEPA DD",
              "complaintTitle": "Ugovor na transakcijo SEPA DD v čakalni vrsti",
              "next": "Naprej",
              "cancel": "Prekliči",
              "orderComment": "Opomba naročila",
              "reasonForRefund": "Razlog za ugovor"
            },
            "loadMore": "Prikaži več transakcij",
            sepaStatus: {
              "Waiting":"V čakalni vrsti",
              "Processed":"Izvršen",
              "Rejected":"Zavrnjen",
              "ProcessedOrRejected":"Izvršen ili Zavrnjen",
              "Opened":"V obdelavi"
            }
          }
        };

        $translateProvider
            .translations('sl', data);
    }
}());
