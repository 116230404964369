(function () {
    'use strict';
  
    /* @ngdoc object
     * @name location
     * @description
     *
     */
    angular
      .module('location', [
        'ui.router'
      ]);
  }());
  