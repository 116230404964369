<section ng-controller="InternationalPaymentSloCtrl as internationalPayment">
  <div>
    <span ng-if="internationalPayment.steps.step1">
      <!-- START Title -->
      <div class="domp-header-wrapper col-sm-12 p-0">
        <div class="domp-header-row">
          <h2 class="intp-title f-28" translate="internationalPayment.international"></h2>
        </div>
      </div>
      <!-- END Title-->
      <div class="domp-select-section col-sm-12 p-0">
        <!-- ADD SELECT FROM TEMPLATE HERE ! -->
        <!-- FIRST BOX -->
        <form name="internationalPayment.paymentForm" novalidate>

          <!--SELECT TEMPLATE CUSTOM -->

          <div class="domp-template-filter-container">
            <div class="domp-select-template" ng-click="internationalPayment.toggleTemplateFilterSelection()"
                 ng-show="internationalPayment.templateName == ''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>
            <div class="domp-selected-template" ng-show="internationalPayment.templateName != ''">
              <div class="select-template label-template-selected p-l-10 p-t-5" ng-show="internationalPayment.templateName != ''">
                <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                <img ng-if="internationalPayment.selectedTemplate.imageId" ng-src="{{internationalPayment.selectedTemplate.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img-40"/>
                <img ng-if="!internationalPayment.selectedTemplate.imageId" class="tpl-rounded-img-40" alt="Ico"/>

                <label class="p-l-10 selected-template-label"><b>{{internationalPayment.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5"
                   ng-click="internationalPayment.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection" ng-class="{'opened': internationalPayment.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width: 50%">
                  <input id="searchTemplatesInput" type="text" class="search-input"
                         ng-model="internationalPayment.tmpSearchText.name"
                         ng-change="internationalPayment.searchTemplate(internationalPayment.tmpSearchText.name)"
                         placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item"  data-ng-repeat="template in internationalPayment.templateList.result| filter:internationalPayment.tmpSearchText"
                       ng-click="internationalPayment.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-r-0 domp-select-label">
                          <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                          <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                          <img ng-if="!template.imageId" class="tpl-rounded-img" alt="Ico"/>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15"  style="padding-left: 50px !important;">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
          <!-- END FIRST ROW-->

          <!-- START SECOND BOX-->
          <div class="receiver-section " style="padding: 10px;background-color:#fff;">
            <div class="row">
              <!-- START From Account - Custom Dropdown -->
              <div class="col-md-6">
                <label class="domp-form-label " translate="payments.fromAccount"></label>
                <div class="accounts-dropdown m-b-10">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                       data-ng-click="internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown"
                       ng-style="internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                        {{internationalPayment.tempForpObj.fromAccountSelect.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                        <i class="zmdi"
                           ng-class="{'zmdi-star favorite-account':internationalPayment.tempForpObj.fromAccountSelect.isFavorite,
                           'zmdi-star-outline': !internationalPayment.tempForpObj.fromAccountSelect.isFavorite}"></i>
                      </span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{internationalPayment.tempForpObj.fromAccountSelect.iban | formatIban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{internationalPayment.tempForpObj.fromAccountSelect.availableBalance| number:2}}
                      {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    <span class="caret-icon">
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                    </span>
                  </div>
                  <div class="from-accounts-list" ng-show="internationalPayment.showAccountToDropdown"
                       scrollable>
                    <div class="accounts-list-item p-0"
                         data-ng-repeat="item in internationalPayment.accountsFrom"
                         data-ng-click="internationalPayment.setChargeAccount(item); internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown">

                      <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{item.availableBalance| number:2}} {{item.currencySymbol}}
                        </p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- END From Account - CUSTOM DROPDOWN-->
              <!-- START Amount and Date -->
              <div class="col-md-6">
                <!--<div class="row">-->
                <!-- START Amount -->
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput"
                         translate="internationalPayment.totalAmount">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput"
                           id="amountInput"
                           autocomplete="off"
                           placeholder="{{'eInvoicesModal.amountPlaceholder'| translate}}"
                           amount-input-mask
                           maxlength="14"
                           max-amount="99999999999.99"
                           ng-model="internationalPayment.tempForpObj.amountInput" required
                           ng-class="{'domp-error': internationalPayment.paymentForm.amountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                           ng-readonly="internationalPayment.disableAllFields && internationalPayment.disableAllFieldsForProcessedPayment">
                    <span class="input-group-btn">
                      <select type="button"
                              class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;"
                              ng-model="internationalPayment.tempForpObj.totalAmountCurrency"
                              ng-change="internationalPayment.checkCountryAndSymbol();"
                              ng-options="item.symbol as item.symbol for item in internationalPayment.currenciesOptions"
                              ng-disabled="internationalPayment.disableAllFields && internationalPayment.disableAllFieldsForProcessedPayment">
                        <!--{{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}-->
                        <!--                        <select class="form-control input-field p-l-15 p-r-10"
                                                       >
                                                </select>-->

                      </select>
                    </span>
                  </div>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.amountInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="internalTransfer.amountInputError">
                  </span>
                  <span class="c-red" ng-show="internationalPayment.paymentForm.amountInput.$error.maxAmount && internationalPayment.tempForpObj.formSubmitted"
                        translate="internalTransfer.amountLengthError">
                  </span>

                  <!--</div>-->
                </div>
                <!-- End Amount-->
                <!-- START Date-->
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput"
                         translate="internationalPayment.paymentDate">Date:</label>
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="dd.MM.yyyy"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           ng-model="internationalPayment.tempForpObj.dueDateInput"
                           readonly>
                </div>
              </div>
              <!-- END Date-->
            </div>
          </div>

          <div class="receiver-section" style="padding:10px;background-color:#fff;">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label" for="toAccountInput" translate="internationalPayment.toAccountInputLabel_SLO">
                    Account number
                  </label>
                  <input ng-show="!internationalPayment.isFromTemplate" type="text" class="form-control input-field"
                         capitalize
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.allLettersNumbersDashesSlo}}"
                         ui-mask="{{internationalPayment.uiMask}}"
                         ui-mask-placeholder
                         ui-mask-placeholder-char="space"
                         ui-options="internationalPayment.uiMaskOptions"
                         ng-model="internationalPayment.tempForpObj.toAccountInput"
                         ng-class="{'domp-error': internationalPayment.paymentForm.toAccountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="toAccountInput" id="toAccountInput"
                         ng-trim="false"
                         maxlength="{{domesticPaymentSlo.ibanMaxlength}}"
                         ng-change="internationalPayment.addSpaceForIban(internationalPayment.tempForpObj.toAccountInput); internationalPayment.selectCountry(internationalPayment.tempForpObj.toAccountInput);"
                         ng-blur="
                                 internationalPayment.fillReceiver(internationalPayment.tempForpObj.toAccountInput);
                                 internationalPayment.getBicByIban(internationalPayment.tempForpObj.toAccountInput)"
                         ng-readonly="internationalPayment.disableAllFields"
                          required>
                  <label ng-show="internationalPayment.isFromTemplate" class="domp-form-label-resume">{{internationalPayment.tempForpObj.toAccountInput}}</label>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.toAccountInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="domesticPayment.receiverAccountErrorHR">
                  </span>
                </div>

              </div>
              <!-- /////////// SELECT CUSTOM ///////////// -->
              <div class="col-sm-6 col-xs-12">
                <div class="form-group clearfix">
                  <label class="domp-form-label" for="toAccountInput" translate="internationalPayment.toPayee2">To payee </label>
                  <label ng-if="internationalPayment.isFromTemplate" class="domp-form-label-resume">
                    {{internationalPayment.tempForpObj.receiversNameInput}}
                  </label>

                  <div ng-if="!internationalPayment.isFromTemplate" class="col-sm-12 col-xs-12 domp-filter-container"
                       ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.payeeSearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                      <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input"
                             ng-model="internationalPayment.tempForpObj.receiversNameInput"
                             ng-change="internationalPayment.searchPayee()"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                             autocomplete="off"
                             required maxlength="34" ng-maxlength="34"
                             ng-readonly="internationalPayment.disableAllFields">
                    </div>
                    <div ng-hide="internationalPayment.disableAllFields" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                         ng-click="internationalPayment.toggleFilterSelection()">
                      <i class="zmdi zmdi-close domp-close-sign" ng-show="internationalPayment.tempForpObj.receiversNameInput"
                         ng-click="internationalPayment.removeSearch()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!internationalPayment.tempForpObj.receiversNameInput"></i>
                    </div>
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': internationalPayment.searchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="payee in internationalPayment.payeeList| filter:internationalPayment.searchPayeeBy"
                           ng-click="internationalPayment.selectPayee(payee.id)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <i class="zmdi zmdi-account-circle"></i>
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                          <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                        </div>
                      </div>
                    </div>
                    <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                          ng-show="internationalPayment.paymentForm.payeeSearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted"
                          translate="domesticPayment.toPayeeInputError">
                    </span>
                    <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                          ng-show="internationalPayment.paymentForm.payeeSearchFilter.$error.maxlength"
                          translate="internationalPayment.maxlength34">
                    </span>
                  </div>
                </div>

              </div>
              <!--//////////////SELECT CUSTOM END/////////////////////// -->
            </div>
            <div class="row">
                <div class="col-md-6"><div class="p-l-0">
                    <select class="form-control input-field p-l-15 p-r-10" name="receiversCountry" id="receiversCountry"
                            ng-options="option as option.description | translate for option in internationalPayment.bankCountries"
                            ng-model="internationalPayment.tempForpObj.receiversCountry"
                            ng-class="{'domp-error-custom-payee': internationalPayment.tempForpObj.receiversCountry.code == ' ' && internationalPayment.tempForpObj.formSubmitted}"
                            ng-required="internationalPayment.tempForpObj.receiversCountry.code == ' '">
                    </select>

                    </div>
                </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label class="domp-form-label" for="receiversAddressInput"
                         translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label> -->

                  <!--ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversAddressInput', internationalPayment.tempForpObj.receiversAddressInput)"-->
                  <input type="text" class="form-control input-field"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.receiversAddressInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="receiversAddressInput" id="receiversAddressInput"
                         ng-model="internationalPayment.tempForpObj.receiversAddressInput"
                         maxlength="34" ng-maxlength="34" required
                         ng-readonly="internationalPayment.disableAllFields">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.receiversAddressInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="domesticPayment.receiverAddressError"></span>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.receiversAddressInput.$error.maxlength"
                        translate="internationalPayment.maxlength34"></span>
                </div>
              </div>

            </div>
            <div class="row">

              <div class="col-md-offset-6 col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <!-- <label class="domp-form-label" for="receiversPostalCodeAndCityInput"
                         translate="domesticPayment.receiversPostalCodeAndCityInputLabel">Receiver's postal code and
                    city:</label> -->
                  <!--ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversAddressInput', internationalPayment.tempForpObj.receiversAddressInput)"-->
                  <input type="text" class="form-control input-field"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.receiversPostalCodeAndCityInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput"
                         ng-model="internationalPayment.tempForpObj.receiversPostalCodeAndCityInput"
                         maxlength="34" ng-maxlength="34" required
                         ng-readonly="internationalPayment.disableAllFields">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.receiversPostalCodeAndCityInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="payments.receiversPostalCodeAndCityInputRequired"></span>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.receiversPostalCodeAndCityInput.$error.maxlength"
                        translate="internationalPayment.maxlength34"></span>
                </div>
              </div>

            </div>
          </div>
          <div class="receiver-section" style="padding: 10px; background-color:#fff;">
            <div class="row">
              <div class="col-md-12 form-group">
                <div class="no-padding-on-phone">
                  <label class="domp-form-label domp-purpose-label" for="paymentPurposeInput"
                         translate="internationalPayment.paymentPurpose">Purpose of payment</label>
                  <div class="p-0">
                    <input type="text" class="form-control input-field"
                           data-ng-model="internationalPayment.tempForpObj.paymentPurposeInput"
                           id="paymentPurposeInput"
                           name="paymentPurposeInput"
                           ng-pattern-restrict="{{internationalPayment.regexPatterns.standardInput}}"
                           ng-class="{'domp-error': internationalPayment.paymentForm.paymentPurposeInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                           required
                           maxlength="136"
                           ng-maxlength="136"
                           ng-readonly="internationalPayment.disableAllFields && internationalPayment.disableAllFieldsForProcessedPayment">

                    <span class="c-red"
                          ng-show="internationalPayment.paymentForm.paymentPurposeInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                          translate="internalTransfer.paymentPurposeInputError"></span>
                    <span class="c-red"
                          ng-show="internationalPayment.paymentForm.paymentPurposeInput.$error.maxlength"
                          translate="internationalPayment.maxlength34"></span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- START BIC -->
          <div class="purpose-section" style="padding: 10px">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group clearfix">
                  <label class="domp-form-label" translate="payments.bicNumber_SLO">
                    BIC Address
                  </label>
                  <input type="text" name="bicAddress" class="form-control input-field"
                         ng-if="!internationalPayment.bicCodeDisabled"
                         ng-model="internationalPayment.tempForpObj.beneficiarySWIFTCode"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLetters}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.bicAddress.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         maxlength="11"
                         ng-change="internationalPayment.checkToAccountInputLength();"
                         capitalize
                         ng-readonly="internationalPayment.disableAllFields">
                  <label ng-if="internationalPayment.bicCodeDisabled" class="domp-form-label-resume">
                    {{internationalPayment.tempForpObj.beneficiarySWIFTCode}}
                  </label>

                  <span class="c-red" translate="payments.bicNumberMaxLength"
                        ng-show="internationalPayment.paymentForm.bicAddress.$error.properLength && internationalPayment.tempForpObj.formSubmitted">
                  </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group clearfix">
                  <label class="domp-form-label" translate="payments.bicCountry_SLO">
                    Country
                  </label>
                  <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 domp-filter-container"
                       ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.countrySearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">
                      <input type="text" class="search-input" name="countrySearchFilter"
                             ng-model="internationalPayment.countrySearchFilter"
                             ng-change="internationalPayment.countrySearchFilterExpanded = true;"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                             required
                             autocomplete="off"
                             ng-readonly="internationalPayment.disableAllFields">
                    </div>

                    <div ng-hide="internationalPayment.disableAllFields" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                         ng-click="internationalPayment.countrySearchFilterExpanded = !internationalPayment.countrySearchFilterExpanded">
                      <i class="zmdi zmdi-close domp-close-sign"
                         ng-show="internationalPayment.countrySearchFilter"
                         ng-click="internationalPayment.removeSelectedCountry()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!internationalPayment.countrySearchFilter"></i>
                    </div>
                    <!--Dropdown menu -->
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': internationalPayment.countrySearchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="country in internationalPayment.countriesOptions| filter: internationalPayment.countrySearchFilter"
                           ng-click="internationalPayment.onCountrySelect(country)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <!--<i class="zmdi zmdi-account-circle"></i>-->
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="c-red"
                       ng-show="internationalPayment.paymentForm.countrySearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted"
                       translate="payments.bicCountryRequired"></div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 col-xs-12 col-sm-12">
                <div class="form-group">
                  <!-- Required if Bic number is empty -->
                  <label class="domp-form-label" translate="payments.bicBankName">Bank Name</label>
                  <!--<input type="text" class="form-control input-field"-->
                  <!--name="bankNameInput"-->
                  <!--ng-model="internationalPayment.tempForpObj.bankNameInput"-->
                  <!--ng-required="!internationalPayment.bicCodeSearchFilter">     -->
                  <input type="text" class="form-control input-field"
                         name="bankNameInput" maxlength="34"
                         ng-class="{'domp-error': internationalPayment.paymentForm.bankNameInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                         replace-specific-chars
                         ng-model="internationalPayment.tempForpObj.bankNameInput"
                         autocomplete="off"
                         required
                         ng-readonly="internationalPayment.disableAllFields">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.bankNameInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="payments.bicBankNameRequired"></span>
                </div>
              </div>
              <!--              <div class="col-md-4">
                              <div class="form-group">
                                <label class="domp-form-label" translate="payments.bicRecipientStatus">Primatelj (osoba)</label>
                                <select class="form-control input-field"
                                        ng-model="internationalPayment.tempForpObj.recipientStatusRadiobutton.type">
                                  <option value=2>{{"payments.bicRecipientStatusOptionPhysical"| translate}}</option>
                                  <option value=1>{{"payments.bicRecipientStatusOptionLegal"| translate}}</option>
                                </select>
                              </div>
                            </div>-->
            </div>

            <div class="row">
              <div class="col-md-4 m-t-15">
                <div class="form-group">
                  <label class="domp-form-label" translate="payments.bicAddress">Bank Address</label>
                  <input name="bankAddressInput" type="text" class="form-control input-field"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.bankAddressInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         ng-model="internationalPayment.tempForpObj.bankAddressInput"
                         replace-specific-chars maxlength="34"
                         autocomplete="off"
                         required
                         ng-readonly="internationalPayment.disableAllFields">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.bankAddressInput.$invalid && internationalPayment.tempForpObj.formSubmitted"
                        translate="payments.bankAddressInputRequired"></span>
                </div>
              </div>
              <div class="col-md-4 m-t-15">
                <div class="form-group">
                  <label class="domp-form-label" translate="payments.bicCity">Bank City</label>
                  <input type="text" class="form-control input-field" name="bicCity"
                         ng-model="internationalPayment.tempForpObj.bankCityInput"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.receiverNameInput}}"
                         replace-specific-chars maxlength="34"
                         ng-class="{'domp-error': internationalPayment.paymentForm.bicCity.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         required
                         ng-readonly="internationalPayment.disableAllFields">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.bicCity.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="payments.bicCityRequired"></span>
                </div>
              </div>
              <div class="col-md-4">

                <div class="form-group m-t-15">
                  <label class="domp-form-label" translate="payments.bicBankCharges">Troškovna opcija:</label>
                  <select class="form-control input-field"
                          ng-model="internationalPayment.tempForpObj.bankChargesRadiobutton.type"
                          ng-disabled="internationalPayment.tempForpObj.countryByIban.countryIsEgp">
                    <option value=0>SHA</option>
                    <option value=1>OUR</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- END BIC -->

          <section class="row p-t-15">
          <div class="col-xs-12 col-sm-3 p-l-30 pull-right" style="line-height: 39px; text-decoration: underline">
            <a class="pull-right" ng-href="{{internationalPayment.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges">
            </a>
          </div>
        </section>

          <div class="intp-form-container m-b-30">
            <div class="row">
              <div class="col-xs-6 t-a-l">
                <button  class="btn btn-primary" translate="payments.cancel"
                        type="button"
                        data-ui-sref="payments.paymentsOverview">
                </button>
              </div>
              <div class="col-xs-6 t-a-r">
                <button
                        ng-if="!internationalPayment.tempForpObj.bankCountrySelect.amlControl"
                        class="btn btn-default domp-review-and-submit-btn"
                        translate="domesticPayment.confirmBtnSlo"
                        ng-disabled="internationalPayment.nextButtonPressed || internationalPayment.secondValidateInProgress || !internationalPayment.dataLoaded"
                        data-ng-click="internationalPayment.submitInternationalPayment($event);">
                </button>
                <button
                        ng-if="internationalPayment.tempForpObj.bankCountrySelect.amlControl"
                        class="btn btn-default domp-review-and-submit-btn"
                        translate="domesticPayment.confirmBtnSlo"
                        ng-disabled="internationalPayment.nextButtonPressed || internationalPayment.secondValidateInProgress || !internationalPayment.dataLoaded"
                        data-ng-click="internationalPayment.submitInternationalPayment($event);">
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- END NEW SECOND BOX -->

      </div>
    </span>
    <!-- START STEP NUMBER 2 -->
    <div ng-if="internationalPayment.steps.step2">
      <div data-ng-include="'payments/international-payment-resume.tpl.html'"></div>
    </div>
    <!-- END STEP NUMBER 2-->
    <!-- START STEP NUMBER 3 -->
    <div ng-if="internationalPayment.steps.step3">
      <payment-final-step payment="internationalPayment.paymentResponse" is-modal="false"></payment-final-step>
    </div>
    <!-- END STEP NUMBER 3 -->
  </div>
</section>
