<section id="main">
    <div class="container-fluid">
        <div class="row hidden-xs d-flex align-items-center">
            <div class="tmplt-list-title col-md-8 col-sm-10 col-xs-12 p-r-0 m-b-15">
                <h2 translate="complaints.header" class="f-28"></h2>
            </div>
            <div ng-if="complaints.hasAny" class="col-md-4 col-sm-2 col-xs-12 ">
                <button translate="complaints.new-complaint"
                    ng-click="complaints.createNewComplaint()"
                    class="addiko-btn-new create-new-btn addiko-btn-new--primary pull-right"></button>
            </div>
        </div>
        <div class="m-header w-100 text-center">
            <h4 translate="complaints.mHeader"></h4>
        </div>
        <div ng-show="complaints.dataLoaded" class="row m-0">
            <div ng-if="!complaints.hasAny" class="col-md-12 bg-white text-center no-data-wrapper">
                <div class="no-data-banner"></div>
                <h2 translate="complaints.title" class="f-28 text-center"></h2>
                <h4 translate="complaints.subtitle" class="text-center"></h4>
                <button translate="complaints.new-complaint" ng-click="complaints.createNewComplaint()"
                    class="addiko-btn-new addiko-btn-new--primary"></button>
            </div>
            <div ng-show="complaints.hasAny" class="col-md-12 pt-5 bg-white">
                <div class="row complaints-filter m-0">
                    <div class="col-md-6">
                        <addiko-dropdown list="complaints.complaintCategories"
                            placeholder="complaints.categories" model="categoryId" name="categoryName"
                            code="categoryId"></addiko-dropdown>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex chip-select-wrap">
                            <span ng-class="{'active': status == ''}" 
                                  ng-click="complaints.toggleStatus('')"
                                  class="chip-select-item"
                                  translate="complaints.all">Svi</span>
                            <span ng-class="{'active': status == 'Initial'}"
                                    ng-click="complaints.toggleStatus('Initial')"
                                    class="chip-select-item"
                                    translate="complaints.sent">Poslati</span>
                            <span ng-class="{'active': status == 'Processed'}"
                                    ng-click="complaints.toggleStatus('Processed')"
                                    class="chip-select-item"
                                    translate="complaints.processed"></span>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3 pb-3">
                    <div ng-repeat="complaint in complaints.complaintsList" 
                         class="timeline-wrapper">
                        <div class="complaint-item">
                            <div class="row m-0">
                                <div class="col-md-12 p-0 d-flex">
                                    <span class="date-label">{{complaint.creationDate | date}}</span>
                                    <span class="ml-3 status-label">
                                        <span ng-class="'mr-1 icon-complaint-status icon-complaint-status-' + complaint.status"></span>
                                        <span ng-class="'complaint-status complaint-status-' + complaint.status">
                                            {{('complaints.statuses.'+ complaint.status) | translate}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="complaint-item-header">
                                <div ng-click="complaints.toggleComplaint(complaint.complaintId)" class="icon-wrap">
                                    <span class="message-icon"></span>
                                </div>

                                <div class="desc-wrap flex-5">
                                    <h4 class="m-0">{{complaint.categoryName}} / {{complaint.subCategoryName}}</h4>
                                    <p class="m-0 mb-1">{{complaint.complaintDesc}}</p>
                                </div>
                                <div class="actions-wrap flex-2 ">
                                    <div class="update-complaint-wrap position-relative">
                                        <button translate="complaints.communication"
                                        ng-click="complaints.updateComplaint(complaint)"
                                        class="addiko-btn-new addiko-btn-new--outline update-complaint"></button>
                                        <span ng-if="complaint.unreadMessages" class="badge-dot"></span>
                                    </div>
                                 

                                    <span ng-click="complaints.toggleComplaint(complaint.complaintId)" class="cursor-pointer details-label">
                                        {{'complaints.details' | translate}}
                                    </span>
                                    <span ng-click="complaints.toggleComplaint(complaint.complaintId)" class="cursor-pointer details-indicator" style="width: 20px;">
                                        <i ng-if="complaints.selectedComplaint == complaint.complaintId"
                                            style="font-size: 30px;"
                                            class="zmdi zmdi-chevron-up zmdi-hc-2x"></i>
                                        <i ng-if="complaints.selectedComplaint != complaint.complaintId"
                                            class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="complaint-item-footer">
                                <div class="actions-wrap flex-2 ">
                                    <button translate="complaints.communication"
                                        ng-click="complaints.updateComplaint(complaint)"
                                        class="addiko-btn-new addiko-btn-new--outline update-complaint"></button>
                                    <span ng-click="complaints.toggleComplaint(complaint.complaintId)" class="cursor-pointer details-label">
                                        {{'complaints.details' | translate}}
                                    </span>
                                    <span ng-click="complaints.toggleComplaint(complaint.complaintId)" class="cursor-pointer details-indicator" style="width: 20px;">
                                        <i ng-if="complaints.selectedComplaint == complaint.complaintId"
                                            style="font-size: 30px;"
                                            class="zmdi zmdi-chevron-up zmdi-hc-2x"></i>
                                        <i ng-if="complaints.selectedComplaint != complaint.complaintId"
                                            class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                                    </span>
                                </div>
                            </div>
                            <div ng-if="complaints.selectedComplaint == complaint.complaintId" class="complaint-details">
                                <div class="details-item">
                                    <span class="detail-label" translate="complaints.category"></span> <span
                                        class="detail-value">{{complaint.categoryName}}</span>
                                </div>
                                <div class="details-item">
                                    <span class="detail-label" translate="complaints.subcategory"></span> <span
                                        class="detail-value">{{complaint.subCategoryName}}</span>
                                </div>
                                <div ng-if="complaint.cardTypeName" class="details-item">
                                    <span class="detail-label" translate="complaints.cardType"></span> <span
                                        class="detail-value">{{complaint.cardTypeName}}</span>
                                </div>
                                <div ng-if="complaint.cardNumber" class="details-item">
                                    <span class="detail-label" translate="complaints.cardNumber"></span> <span
                                        class="detail-value">{{complaint.cardNumber}}</span>
                                </div>
                                <div ng-if="complaint.transactionAmount" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionAmount"></span> <span
                                        class="detail-value">{{complaint.transactionAmount |number:2}} {{complaint.transactionAmountCurrency}}</span>
                                </div>
                                <div ng-if="complaint.transactionNumber" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionNumber"></span> <span
                                        class="detail-value">{{complaint.transactionNumber}}</span>
                                </div>
                                <div ng-if="complaint.atmLocation" class="details-item">
                                    <span class="detail-label" translate="complaints.atmLocation"></span> <span
                                        class="detail-value">{{complaint.atmLocation}}</span>
                                </div>
                                <div ng-if="complaint.transactionDate" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionDate"></span> <span
                                        class="detail-value">{{complaint.transactionDate | date}}</span>
                                </div>
                                <div ng-if="complaint.transactionDueDate" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionDueDate"></span> <span
                                        class="detail-value">{{complaint.transactionDueDate | date}}</span>
                                </div>
                                <div ng-if="complaint.transactionPayerName" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionPayerName"></span> <span
                                        class="detail-value">{{complaint.transactionPayerName}}</span>
                                </div>
                                <div ng-if="complaint.transactionPayeeName" class="details-item">
                                    <span class="detail-label" translate="complaints.transactionPayeeName"></span> <span
                                        class="detail-value">{{complaint.transactionPayeeName}}</span>
                                </div>
                                <div ng-if="complaint.merchantName" class="details-item">
                                    <span class="detail-label" translate="complaints.merchantName"></span> <span
                                        class="detail-value">{{complaint.merchantName}}</span>
                                </div>

                                <div class="details-item b-0">
                                    <span class="detail-label" translate="complaints.description"></span> 
                                </div>

                                <div class="details-item">
                                    <pre class="detail-value" load-more="complaint.complaintDesc"></pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ng-if="!complaints.complaintsList.length" class="col-md-12 bg-white text-center no-data-wrapper">
                        <div class="no-data-banner"></div>
                        <h2 translate="complaints.noDataTitle" class="f-28 text-center"></h2>
                        <h4 translate="complaints.noDataDesc" class="text-center"></h4>
                    </div>
                    <div ng-if="complaints.hasMore == true" class="col-xs-12 t-a-c m-t-15 m-b-15">
                        <button data-ng-click="complaints.loadMore()"
                                type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
                                translate="complaints.loadMore"></button>
                      </div>
                      
                </div>
            </div>
        </div>
    </div>
    <div class="m-footer">
        <button translate="complaints.new-complaint"
                    ng-click="complaints.createNewComplaint()"
                    class="addiko-btn-new create-new-btn addiko-btn-new--primary pull-right"></button>
    </div>
</section>