<section id="main">
  <div class="container-fluid">
    <h1 translate="invest.signInvestment"></h1>
    <div class="m-b-30">
      <div class="receiver-section" style="padding: 15px">
        <div class="domp-to-section m-t-10 m-b-10">
          <div class="row">
            <div class="col-sm-6 col-xs-6">
              <div class="form-group">
                <label class="domp-form-label-review" ng-if="investmentSign.investment.accountNumberBuy" translate="domesticPayment.fromAccountSelectLabel"></label>
                <label class="d-block domp-form-label-resume">{{investmentSign.investment.accountNumberBuy}}</label>
              </div>
            </div>
            <div class="col-sm-6 col-xs-6">
              <div class="row">
                <div class="col-sm-6" ng-if="investmentSign.investment.invAmount && investmentSign.investment.amountType == 2">
                  <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel">Amount</label>
                  <label class="d-block domp-form-label-resume">
                    {{investmentSign.investment.invAmount|number:2}}
                    {{investmentSign.investment.amountCurCode|| investment.responseModel.sellingCurSym}}
                  </label>
                </div>
                <div class="col-sm-6" ng-if="investmentSign.investment.invAmount && investmentSign.investment.amountType == 3">
                  <label class="domp-form-label-review" translate="invest.quantityInputLabel">Quantity</label>
                  <label class="d-block domp-form-label-resume">
                    {{investmentSign.investment.invAmount | number: 4}}
                  </label>
                </div>

                <div class="col-sm-6">
                  <!--<label class="exchange-resume-label" translate="domesticPayment.dateInputLabel">When</label>-->
                  <label ng-show="investmentSign.orderTypeText != 'new'" class="domp-form-label-review" for="dateInput" translate="invest.dueDate">Date</label>
                  <label ng-show="investmentSign.orderTypeText == 'new'" class="domp-form-label-review" for="dateInput" translate="invest.dateNewOrder">Date</label>
                  <label class="d-block domp-form-label-resume">
                    {{investmentSign.investment.invAmountDate| date:'shortDate' : '+0200'}}
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <label class="domp-form-label-review" translate="invest.buyingFond"></label>
                  <label class="d-block domp-form-label-resume">{{investmentSign.investment.fundName}}</label>
                </div>
                <div class="col-sm-6 domp-form-label-review" ng-show="investmentSign.investment.accountType">
                  <label class="domp-form-label-review" translate="invest.accountType"></label>
                  <label class="d-block domp-form-label-resume">no info</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="domp-to-section m-b-10">
          <div class="row">
            <div class="col-sm-6 col-xs-6">
              <div class="form-group">
                <label class="domp-form-label-review" translate="invest.note"></label>
                <label class="d-block domp-form-label-resume">{{investmentSign.investment.note}}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- END Buttons -->
        <div class="row m-b-10">
          <div class="col-xs-12 m-t-30">
            <form name="investmentSign.challengeForm" novalidate>
              <div class="col-xs-12 m-b-20">
                <span class="f-w-bold">
                  {{'signPayments.challengeDescription'| translate}}
                </span>
              </div>
              <div class="col-sm-4 col-xs-6">
                <div class="f-w-bold wrap-text">{{'signPayments.challengeFirstCode'| translate}}</div>
                <input type="text" class="form-control input-field" disabled
                       data-ng-model="investmentSign.investment.challenge">
              </div>
              <div class="col-sm-4 col-xs-6">
                <div class="f-w-bold wrap-text">{{'signPayments.challengeSecondCode'| translate}}</div>
                <div class="input-group">
                  <input type="{{investmentSign.showTokenInput ? 'text' : 'password'}}" class="form-control input-field"
                         data-ng-model="investmentSign.model.password2"
                         ng-pattern-restrict="{{investmentSign.regexPatterns.onlyNumbers}}"
                         placeholder="{{'signPayments.challengeSecondCode'| translate}}">
                  <span class="input-group-btn">
                    <button class="btn btn-default input-group-app-btn domp-btn-height" data-ng-click="investmentSign.showTokenInput = !investmentSign.showTokenInput" style="border:1px solid #062a42;">
                      <i class="zmdi zmdi-lock"></i>
                    </button>
                  </span>
                </div>

              </div>
              <div class="col-sm-4 col-xs-12 m-t-20">
                <button class="btn btn-default col-sm-8 col-xs-4 pull-right"
                        ng-disabled="investmentSign.confirmSignedButtonDisabled"
                        data-ng-click="investmentSign.confirmSignedInvestment()"
                        translate="signPayments.confirm">
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>








