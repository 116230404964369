(function () {
  'use strict';

  /* @ngdoc object
   * @name documents
   * @description
   *
   */
  angular
    .module('documents', [
      'ui.router'
    ]);
}());
