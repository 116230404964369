(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name core.directive:amountFormatter
     * @restrict EA
     * @element
     *
     * @description
     *
     * @example
     <example module="core">
     <file name="index.html">
     <amount-formatter></amount-formatter>
     </file>
     </example>
     *
     */
    angular
        .module('core')
        .directive('amountFormatter', amountFormatter);

    function amountFormatter(HelperService, $filter, $log) {
        return {
            restrict: 'EA',
            scope: {
                amountInput: '@amount',
                customClasses: '@',
                currency: '@',
                fromType: '@',
                hasUnderline: '@',
                aditionalVal: '@',
                isSecondaryValue: '@',
                hasBracket: '@'
            },
            templateUrl: 'core/directives/amount-formatter-directive.tpl.html',
            replace: false,
            controllerAs: 'amountFormatter',
            controller: function ($scope) {
                var vm = this;
                vm.amountObj = {};
                vm.customCss = '';
                vm.fontClasses = [];
                vm.bracketClasses = 'f-18 m-l-10';
                vm.hasUnderline = false;
                vm.hasBracket = false;
                vm.secondaryValue = false;
                var fontClasses = {
                    navigation: ['f-18 bold-font', 'f-14'],
                    dashboard: ['f-size-35 bold-font', 'f-size-25'],
                    addikoKlub: ['f-20 bold-font', 'f-15']
                };

                if ($scope.isSecondaryValue) {
                    fontClasses = {
                        navigation: ['f-15', 'f-13'],
                        dashboard: ['f-size-25', 'f-size-20'],
                        addikoKlub: ['f-18', 'f-14']
                    };
                }


                // use $watch, format needs to be changed on language change!
                $scope.$watchGroup(['amountInput', 'aditionalVal'], function (newValues) {
                    if (!$scope.amountInput) {
                        $scope.amountInput = $filter('currency')(0, '');
                    }
                    var numbers = HelperService.separateDecimals($scope.amountInput || $scope.aditionalVal);
                    if (_.isArray(numbers) && numbers.length > 2) {
                        vm.amountObj.intPart = numbers[0];
                        vm.amountObj.decimal = numbers[2] + numbers[1] + ' ' + $scope.currency;
                        vm.customCss = $scope.customClasses;
                        if ($scope.fromType == 'navigation') {
                            vm.bracketClasses = 'f-15 m-l-5'
                        }
                        vm.fontClasses = fontClasses[$scope.fromType];
                        
                    }
                    if ($scope.hasUnderline === 'true') {
                        vm.hasUnderline = true;
                    } else {
                        vm.hasUnderline = false;
                    }
                    if ($scope.hasBracket === 'true') {
                        vm.hasBracket = true;
                    } else {
                        vm.hasBracket = false;
                    }
                });
            },
            link: function (scope, element, attrs) {
                /* jshint unused:false */
                /* eslint "no-unused-vars": [2, {"args": "none"}] */
            }
        };
    }
}());
