(function () {
  "use strict";
  /**
   * @ngdoc service
   * @name core.factory:ConfigService
   *
   * @description
   *
   */
  angular.module("core").provider("ConfigService", ConfigServiceProvider);

  function ConfigServiceProvider($sessionStorageProvider) {
    //TODO: Move all constants that can be configurable here
    var defaultLocale = "sl";
    var serverId = "1";

    var baseAPIUrl = "https://ebank.addiko.si/web/webAPI/"; //SLO

    var baseOAuthUrl = "https://ebank.addiko.si/OAuthServer/";

    return {
      defaultLocale: defaultLocale,
      serverId: serverId,
      $get: function ($window) {
        var temp = $window.sessionStorage.getItem("ngStorage-state");
        var _tempState = temp ? temp : undefined;

        var ConfigServiceBase = {};
        ConfigServiceBase.addQueryString = function addQueryString(
          uri,
          parameters
        ) {
          var delimiter = uri.indexOf("?") == -1 ? "?" : "&";
          for (var parameterName in parameters) {
            var parameterValue = parameters[parameterName];
            uri +=
              delimiter +
              encodeURIComponent(parameterName) +
              "=" +
              encodeURIComponent(parameterValue);
            delimiter = "&";
          }
          return uri;
        };

        if (_tempState == undefined) {
          _tempState = Math.random();
        }

        ConfigServiceBase.defaultLocale = defaultLocale;
        ConfigServiceBase.serverId = serverId;
        ConfigServiceBase.country = "sl";
        ConfigServiceBase.url = baseAPIUrl;
        ConfigServiceBase.logoutTokenTimeout = 300000;
        ConfigServiceBase.refreshTokenTimeout = 230000;
        ConfigServiceBase.onBeforeLoadOn = true;
        ConfigServiceBase.googleMapsKey = 'AIzaSyAKnzZp351FQT6DN1CXYs72ZGDB9Diq7e4';
        ConfigServiceBase.clientId = "SloProd";
        ConfigServiceBase.baseUrl = "https://ebank.addiko.si/web/";
        ConfigServiceBase.baseHref = "web/";
        ConfigServiceBase.defaultRedirectUrl =
          ConfigServiceBase.baseUrl + "login.html";
        ConfigServiceBase.authorizationUrl = ConfigServiceBase.addQueryString(
          baseOAuthUrl + "oauth/authorize",
          {
            client_id: ConfigServiceBase.clientId,
            redirect_uri: ConfigServiceBase.defaultRedirectUrl,
            state: _tempState,
            scope: "BAO2000",
            login_hint: "general",
            response_type: "code",
          }
        );
        ConfigServiceBase.authorizationUrlParams = {
          grant_type: "authorization_code",
          redirect_uri: ConfigServiceBase.defaultRedirectUrl,
          client_id: ConfigServiceBase.clientId,
        };
        ConfigServiceBase.tokenAuthorizationUrl = baseOAuthUrl + "oauth/token";
        ConfigServiceBase.refreshUrlParams = {
          grant_type: "refresh_token",
          client_id: ConfigServiceBase.clientId,
        };

        ConfigServiceBase.logoutUrl = ConfigServiceBase.addQueryString(
          baseOAuthUrl + "oauth/logout",
          {
            client_id: ConfigServiceBase.clientId,
            redirect_uri: ConfigServiceBase.defaultRedirectUrl,
            state: _tempState,
            scope: "BAO2000",
            login_hint: "general",
            response_type: "code",
          }
        );

        return ConfigServiceBase;
      },
    };
  }
})();
