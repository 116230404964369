<div class="container invoice-card" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
  <div class="row invoice-card-top vertical-align pointer-cst"
       ng-click="isCollapsed1 = !isCollapsed1; einvoicesOverview.archiveObj.archiveCtnOpened = false;">

    <div class="col-sm-6 col-xs-11 p-15">
      <div class="invoice-type">
        <b>{{eInvoice.creditorName}}</b>
        <br/>
        <span class="text-muted">{{eInvoice.valueDate| date:'shortDate' : '+0200'}}</span>
      </div>
    </div>

    <div ng-class="{'col-sm-6 hidden-xs': (eInvoice.status == 'Inserted' || eInvoice.status == 'InProgress'),
                   'col-sm-7 hidden-xs': (eInvoice.status == 'Archived' || eInvoice.status == 'Processed' || eInvoice.status == 'Rejected')}">
      <div class="row vertical-align">
        <div ng-class="{'col-sm-6 hidden-xs': (eInvoice.status == 'Inserted' || eInvoice.status == 'InProgress'),
                       'col-sm-5 hidden-xs': (eInvoice.status == 'Archived' || eInvoice.status == 'Processed' || eInvoice.status == 'Rejected')}">

          <span class="einvoice-value pull-right">{{eInvoice.amount| number:2}} <span>{{eInvoice.currencySymbol}}</span></span>
        </div>


        <div ng-class="{'col-sm-3 col-xs-5': (eInvoice.status == 'Inserted' || eInvoice.status == 'InProgress'),
                       'col-sm-6 col-xs-5' :(eInvoice.status == 'Archived' || eInvoice.status == 'Processed' || eInvoice.status == 'Rejected')}">
          <button ng-hide="eInvoice.amount == 0" class="btn btn-default p-5 pull-right" ng-if="eInvoice.status == 'Inserted'"
                  ng-click="einvoicesOverview.pay(eInvoice, $event)"
                  translate="eInvoiceOverview.pay">
          </button>
          <label class="f-16 blue-label" ng-if="eInvoice.status == 'InProgress'"
                 translate="eInvoiceOverview.inProgress">In progress</label>

          <button
            ng-if="eInvoice.status == 'Archived' || eInvoice.status == 'Processed' || eInvoice.status == 'Rejected'"
            class="btn btn-default p-5 pull-right" ng-click="einvoicesOverview.pay(eInvoice, $event)"
            translate="eInvoiceOverview.copy"></button>
        </div>

        <div class="col-sm-1 col-xs-2">
          <span class="glyphicon glyphicon-chevron-down"></span>
        </div>
      </div>
    </div>
    <div class="hidden-sm hidden-md hidden-lg col-xs-1">
      <span class="glyphicon glyphicon-chevron-down"></span>
    </div>

  </div>

  <div class="row invoice-card-detail" ng-class="{collapse: !isCollapsed1}">

    <div class="invoice-content container">
      <div class="row-md-sm-lg">
        <div class="col-sm-6 row-xs">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuer"></span></span>
          <p>
            <b>
              {{eInvoice.creditorName}}<br/>
              {{eInvoice.creditorAddress}}<br/>
              {{eInvoice.creditorCity}}
            </b>
          </p>
        </div>
        <div class="col-sm-6 row-xs">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipient"></span></span>
          <p>
            <b>
              {{eInvoice.creditorName}}<br/>
              {{eInvoice.creditorAddress}}<br/>
              {{eInvoice.creditorCity}}
            </b>
          </p>
        </div>
      </div>
      <div class="row-md-sm-lg">
        <div class="col-sm-6 row-xs">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipientAccount"></span></span>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">{{eInvoice.creditorAccount}}</b>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">{{eInvoice.iban}}</b>
          </p>
        </div>

        <div class="col-sm-6 row-xs">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuerAccount"></span></span>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">{{eInvoice.creditorAccount}}</b>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">{{eInvoice.iban}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.purpose"></span></span>
          <p>
            <b>
              {{eInvoice.purpose}}
            </b>
          </p>
        </div>

        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issueDateOfEinvoice"></span></span>
          <p>
            <b>
              {{eInvoice.valueDate| date:'shortDate' : '+0200'}}
            </b>
          </p>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.refNumber"></span></span>
          <p>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
              {{eInvoice.paymentReferenceNumber}}
            </b>
            <b ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
              {{eInvoice.paymentReferenceNumber.substring(0, 2)}}&nbsp;&nbsp;{{eInvoice.paymentReferenceNumber.substring(2) }}
            </b>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.amount"></span></span>
          <p>
            <b>
              {{eInvoice.amount| number:2}} {{eInvoice.currencySymbol}}
            </b>
          </p>
        </div>
      </div>

    </div>

    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-12" ng-if="eInvoice.status == 'Rejected' || eInvoice.status == 'Archived'">
          <span class="invoice-inline-title">
            <span translate="eInvoiceDetail.noteRejected" ng-if="eInvoice.status == 'Rejected'"></span>
            <span translate="eInvoiceDetail.noteArchived" ng-if="eInvoice.status == 'Archived'"></span>
          </span>
          <p class="payment-description">
            {{eInvoice.userComment}}
          </p>
        </div>
      </div>
      <div class="row">
        <!-- Archive input container -->
        <div ng-if="einvoicesOverview.archiveObj.archiveCtnOpened">
          <div class="col-sm-6 col-xs-10">
            <input type="text" class="form-control" placeholder="{{'eInvoicesModal.enterComment'| translate}}"
                   data-ng-model="einvoicesOverview.archiveObj.userComment">
          </div>
          <div class="col-sm-3 col-xs-2">
            <i class="zmdi zmdi-check zmdi-hc-2x edit-check-btn"
               ng-disabled="eInvoice.archiveEInvoiceInProgress"
               data-ng-click="einvoicesOverview.archiveEInvoice(eInvoice);"
               style="background-color: #ff4d5a; color:white;"></i>
          </div>
        </div>
        <div class="hidden-sm hidden-md hidden-lg col-xs-12 m-b-10"></div>
      </div>

      <div class="row">
        <div class="text-right pull-right p-r-5 p-l-0 col-xs-12 col-sm-2" ng-if="eInvoice.status == 'Inserted'">
          <button ng-hide="eInvoice.amount == 0" class="btn btn-default col-xs-12" ng-click="einvoicesOverview.pay(eInvoice, $event)"
                  translate="eInvoiceOverview.pay">
          </button>
        </div>
        <div class="text-right pull-right p-r-5 p-l-0 col-xs-12 col-sm-4"
             ng-if="eInvoice.status == 'Archived' || eInvoice.status == 'Processed' || eInvoice.status == 'Rejected'">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.pay(eInvoice, $event)"
                  translate="eInvoiceOverview.copy">
          </button>
        </div>
        <div class="pull-right text-right p-r-5 p-l-0 col-xs-12 col-sm-2"
             ng-if="eInvoice.status == 'Inserted' || eInvoice.status == 'InProgress'">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.toggleArchiveContainer()"
                  translate="eInvoiceDetail.archive">Archive
          </button>
        </div>
        <div class="pull-right text-right p-r-5 p-l-0  col-xs-12 col-sm-2">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.downloadPdf(eInvoice.id)"
                  ng-disabled="einvoicesOverview.downloadButtonPressed"
                  translate="eInvoiceDetail.download">Download
          </button>
        </div>
      </div>

      <div ng-class="{collapse: !collapsedSignature}">
        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title" translate="eInvoiceDetail.digitalCertNumber"></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureId"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureName"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.certificateIssuer"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

      </div>
    </div>


  </div>
</div>


<! ------------------------------ ->
<! -- SLO -- >
<! ------------------------------ ->


<div class="container invoice-card" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
  <div class="row invoice-card-top vertical-align pointer-cst"
       ng-click="einvoicesOverview.showEInvoiceDetails(eInvoice)">

    <div class="col-lg-5 col-md-4 col-sm-12 col-xs-12 p-15">
      <div class="invoice-type">
        <div><b>{{eInvoice.creditorName}}</b></div>
        <div class="text-muted">{{eInvoice.valueDate| date:'shortDate' : '+0200'}} <span ng-if="eInvoice.documentType">- {{'eInvoicesModal.eDocumentType.' + eInvoice.documentType | translate }}</span></div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
      <span class="einvoice-value pull-right">{{eInvoice.amount| number:2}} <span>{{eInvoice.currencySymbol}}</span></span>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6">
      <label class="f-16 blue-label m-t-10" translate="eInvoiceStatus.{{eInvoice.status}}">Status</label>
      <button
        ng-if="eInvoice.status == 'Reviewed' || eInvoice.status == 'Inserted'"
        class="btn btn-default p-5 pull-right" ng-click="einvoicesOverview.pay(eInvoice, $event)"
        translate="eInvoiceOverview.pay">
      </button>
    </div>
    <div class="col-lg-1 col-md-1 hidden-sm hidden-xs t-a-r">
      <span class="glyphicon glyphicon-chevron-down"></span>
    </div>

  </div>

  <div class="row invoice-card-detail" ng-class="{collapse: !eInvoice.isCollapsed1}">

    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuer"></span></span>
          <p>
            <b>
              {{eInvoice.creditorName}}<br/>
              {{eInvoice.creditorAddress}}<br/>
              {{eInvoice.creditorCity}}
            </b>
          </p>
        </div>
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipient"></span></span>
          <p>
            <b>
              {{eInvoice.debtorName}}<br/>
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 ">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuerAccount"></span></span>
            <b>{{eInvoice.creditorAccount | formatIban}}</b>          
          </p>
        </div>
        <div class="col-sm-6">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipientAccount"></span></span>
            <b>{{eInvoice.debtorAccount}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.purpose"></span></span>
          <p>
            <b>
              {{eInvoice.purpose}}
            </b>
          </p>
        </div>

        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issueDateOfEinvoice"></span></span>
          <p>
            <b>
              {{eInvoice.valueDate| date:'shortDate' : '+0200'}}
            </b>
          </p>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.refNumber"></span></span>
          <p>
            <b>
              {{eInvoice.paymentReferenceNumber}}
            </b>
          </p>
        </div>
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.dueDate"></span></span>
          <p>
            <b>
              {{eInvoice.dueDate | date:'shortDate' : '+0200'}}
            </b>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.amount"></span></span>
          <p>
            <b>
              {{eInvoice.amount| number:2}} {{eInvoice.currencySymbol}}
            </b>
          </p>
        </div>
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.purposeCode"></span></span>
          <p>
            <b>
              {{eInvoice.purposeCode}}
            </b>
          </p>
        </div>
      </div>

    </div>

    <div class="invoice-content container">
      <h3 translate="eInvoiceDetail.otherInfo">OSTALI PODATKI</h3>
      <h4 translate="eInvoiceDetail.digitalSignature"></h4>
      <br>
      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title" translate="eInvoiceDetail.signatureId">Oznaka podpisa</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b>
              {{eInvoice.eInvoiceDocumentDigitalSignatures[0].signatureId}}
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title" translate="eInvoiceDetail.signatureName">Ime digitalnog potrdila</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b>
              {{eInvoice.eInvoiceDocumentDigitalSignatures[0].certificateName}}
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title"
                translate="eInvoiceDetail.digitalCertNumber">Številka digitalnega potrdila</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b>
              {{eInvoice.eInvoiceDocumentDigitalSignatures[0].certificateNumber}}
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title" translate="eInvoiceDetail.certificateIssuer">Izdajatelj digitalnega potrdila</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b>
              {{eInvoice.eInvoiceDocumentDigitalSignatures[0].certificateIssuer}}
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title" translate="eInvoiceDetail.digitalSignature">Status elektronskega podpisa</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b ng-show="eInvoice.eInvoiceDocumentDigitalSignatures[0].signatureValid" translate="eInvoiceDetail.valid">

            </b>
            <b ng-show="!eInvoice.eInvoiceDocumentDigitalSignatures[0].signatureValid"
               translate="eInvoiceDetail.invalid">

            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <span ng-if="eInvoice.documentType !== 'eOpomina' && eInvoice.documentType !== 'ePredacun'"  class="invoice-inline-title" translate="eInvoiceDetail.statusOfEApplication">Status E-računa</span>
          <span ng-if="eInvoice.documentType === 'eOpomina'" class="invoice-inline-title" translate="eInvoiceDetail.statusOfEApplicationOpomina">Status E-računa</span>
          <span ng-if="eInvoice.documentType === 'ePredacun'"  class="invoice-inline-title" translate="eInvoiceDetail.statusOfEApplicationPredracun">Status E-računa</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b translate="eInvoiceStatus.{{eInvoice.status}}">
            </b>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <span class="invoice-inline-title" translate="eInvoiceDetail.paymentId">Id plačila</span>
        </div>
        <div class="col-sm-9">
          <p>
            <b>
              /
            </b>
          </p>
        </div>
      </div>


    </div>

    <div class="invoice-content container">

      <div class="row">

        <div class="text-right pull-right p-r-5 p-l-0 " ng-if="eInvoice.status == 'Reviewed' || eInvoice.status == 'Inserted'">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.pay(eInvoice, $event)"
                  translate="eInvoiceOverview.pay">
          </button>
        </div>


        <!--<div class="text-right pull-right p-r-5 p-l-0 " ng-if="eInvoice.status == 'Reviewed' || eInvoice.status == 'Inserted'">-->
          <!--<button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.reject(eInvoice, $event)"-->
                  <!--translate="eInvoiceOverview.reject">-->
          <!--</button>-->
        <!--</div>-->

        <div class="pull-right text-right p-r-5 p-l-0"
             ng-if="eInvoice.status == 'Inserted' || eInvoice.status == 'Reviewed'">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.archiveEInvoice(eInvoice)"
                  ng-disabled="eInvoice.archiveEInvoiceInProgress"
                  translate="eInvoiceDetail.withdraw">Archive
          </button>
        </div>
        <div class="pull-right text-right p-r-5 p-l-0">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.downloadPdf(eInvoice.id)"
                  ng-disabled="einvoicesOverview.downloadButtonPressed"
                  translate="eInvoiceDetail.download">Download
          </button>
        </div>
      </div>

      <div ng-class="{collapse: !collapsedSignature}">
        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title" translate="eInvoiceDetail.digitalCertNumber"></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureId"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureName"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.certificateIssuer"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

      </div>
    </div>


  </div>
</div>
