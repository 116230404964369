(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name complaints.controller:CardWithIconCtrl
     *
     * @description
     *
     */
    angular
      .module('core')
      .directive('navigationCardItem', CardWithIconCtrl);
  
    function CardWithIconCtrl($rootScope) {
        return {
            restrict: 'E',
            scope: {
                label: '=',
                link: '=',
                icon: '=',
                description: '=',
                prefix: '@',
                disable: '='
            },
            templateUrl: 'core/components/card-with-icon/card-with-icon.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;

                vm.onCardClicked = function() {
                    if ($scope.disable) {
                        return;
                    }
                    var item = $scope.link || $scope.label;
                    if ($scope.prefix) {
                        item = $scope.prefix + ':' + item;
                    }
                    window.open(item, '_blank');
                }
            }
        }
    }
}());