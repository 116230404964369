(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name payments.controller:ExchangeRatesCtrl
     *
     * @description
     *
     */
    angular
        .module('payments')
        .controller('ExchangeRatesCtrl', ExchangeRatesCtrl);

  function ExchangeRatesCtrl($state, $log, ExchangeRatesService, HelperService, $localStorage) {
        var vm = this;
        vm.ctrlName = 'ExchangeRatesCtrl';
        var dateString = HelperService.getDateTimeAsString(HelperService.getYesterday().firstDay);
        vm.exchangeRatesParams = {
            page: 0,
            pageSize: 1000,
            date: '',
            includeList: ['currency']
        };
        vm.currentDate = HelperService.getYesterday().firstDay;
        vm.exchangeRatesList = {};

        vm.init = function () {
            ExchangeRatesService.getExchangeRates(vm.exchangeRatesParams)
                .then(function (response) {
                    vm.exchangeRatesList = response;
                })
                .catch(function () {
                    $log.error('Error loading exchange rates!');
                });
        };

        vm.goBack = function () {
            $state.go('payments.paymentsOverview');
        };

        vm.buy = function () {
            $log.debug("Buy method not implemented.");
        }

        vm.init();
    }
}());
