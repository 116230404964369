(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:AccountsOverviewCtrl
   *
   * @description
   *
   */
  angular
      .module('accounts')
      .controller('AccountsOverviewCtrl', AccountsOverviewCtrl);

  function AccountsOverviewCtrl($rootScope, $q, $state, $window, AlertService, AccountsService, HelperService, $translate, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;

    var accounts;
    var cards;
    vm.singleAccounts; // object array
    vm.multiAccounts;

    init();
    function init() {
      vm.currentCountry = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      getAllAccountsAndCards();
    }

    // region Private methods

    function getAllAccountsAndCards() {
      var promises = [];
      var defaultOrder = "isFavorite desc,accountType,iban,currencySymbol";
      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl")
        defaultOrder = 'isFavorite desc,isParentAccount desc';
      promises.push(AccountsService.getPage({
        pageSize: 1000,
        page: 0,
        // { notInAccountTypeGroupList: "LoanAccountType" } - Isključi račune koji su krediti
        notInAccountTypeGroupList: ["LoanAccountType", "SavingAccountTypes", "DepositAccountTypes"],
        orderby: defaultOrder
      }));
      if (vm.currentCountry == 'sr' || vm.currentCountry == 'mne' || vm.currentCountry == 'sl' || vm.currentCountry == 'bh') {
        promises.push(AccountsService.getAllCards({
          pageSize: 1000,
          page: 0
        }));
      }

      $q.all(promises)
          .then(function (responses) {
            // 1. response
            if (responses[0]) {
              accounts = responses[0].result;
              if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
                accounts = _.each(accounts, function(account) {
                  account.iban = account.iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
                });

                vm.cardAccounts = _.filter(accounts, function(account) {
                  return account.accountType.group == "CardAccountTypes";
                });
                vm.multiAccounts = _.filter(accounts, function (account) {
                  return !account.parentAccountId && account.accountType.group == "CurrentAccount";
                });

              } else {
                var sepAccounts = HelperService.groupAccounts(responses[0].result);
                vm.singleAccounts = sepAccounts.singleAccounts;
                vm.multiAccounts = sepAccounts.multiAccounts;
              }
            }

            // 2. response
            if (responses[1]) {
              cards = responses[1].result;
              vm.cards = cards;
              addAccountNameToCard();
            }
          }, function (error) {
            console.log(error);
          });
    }

    function refreshCards() {
      AccountsService.getAllCards({
        pageSize: 1000,
        page: 0
      }).then(function (response) {
        vm.cards = response.result;
        addAccountNameToCard();
      });
    }

    function addAccountNameToCard() {
      _.map(vm.cards, function (card) {
        var account = _.find(accounts, {accountId: card.accountId.toString()});
        if (account) {
          card.accountName = account.friendlyName;
        }
      });
    }

    // endregion

    // region Public methods

    vm.goToAccount = function (accountId) {
      $state.go('accounts', {
        accountId: accountId
      });
    };

    vm.updateCardStatus = function (card) {

      if(vm.currentCountry == 'sl' || card.cardStatusStr == 2){
        AccountsService.openBlockModal();
        return;
      }

      card.selected = true;
      console.log(card);
      AccountsService.updateCardStatus(card.accountId, card)
          .then(function (response) {

            var BlockCard;
            if(card.temporaryStatus) {
              BlockCard = (card.temporaryStatus == 'SentUnblockRequest') ? true : false;
            } else {
              BlockCard = (card.cardStatus === 'Active') ? true : false;
            }
            GoogleTagManagerHelperService.pushEvent('AccountsOverview', 'BlockCardSelection', (BlockCard) ? "Block card" : "Unblock card");
            refreshCards();
            card.selected = false;
          })
          .catch(function (error) {
            card.selected = false;
            NotificationService.showMessage(error, 'error');
          });
    };

    vm.openNewSavingOrMasterCard = function (){
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        $state.go("masterCardNew");
      } else {
        $state.go("newEbook", {accountId:null});
      }
    };

    vm.openPayLifeBalancePage = function() {
      $window.open($rootScope.APP_GLOBAL_SETTINGS.defaultData.PayLifeBalanceInquiry, '_blank');
    };
    // endregion

  }
}());
