<div class="intp-form-container light-bcg bottom-border">
  <div class="row">
    <div class="col-sm-6 text-center int-ctn-h">
      <div class="int-b-bottom">
        <div class="int-l-ctn-height">
          <img src="img/hypo/check-circle-success.png" height="100px" alt=""/>
        </div>
        <div class="f-18 m-b-10" translate="internalTransfer.signSuccess"></div>
      </div>
    </div>
    <div class="col-sm-6 int-l-border">
      <p class="int-header" translate="internalTransfer.saveAsTemplate"></p>
      <form name="paymentFinalStep.templateForm" novalidate>
        <div class="form-group">
          <input type="text" class="form-control"
                 ng-class="{'domp-error': paymentFinalStep.templateForm.templateName.$invalid && paymentFinalStep.formSubmitted}"
                 name="templateName" data-ng-model="paymentFinalStep.templateNameInput"
                 placeholder="{{'internalTransfer.templateName' | translate}}" required>
          <span class="c-red"
                ng-show="paymentFinalStep.templateForm.templateName.$error.required && paymentFinalStep.formSubmitted"
                translate="internalTransfer.templateNameError"></span>

        </div>
        <button class="btn btn-primary app-btn-blue int-btn-save w-100 m-b-5"
                data-ng-click="paymentFinalStep.saveAsTemplate();">
          Save
        </button>
      </form>

      <hr class="int-h-line">

      <div class="text-center">
        <button class="btn btn-primary app-btn-blue int-btn-save w-100 m-b-5"
                translate="internalTransfer.newPayment"
                data-ng-click="paymentFinalStep.navigateToPaymentsPage();">
        </button>
        <button class="btn btn-primary app-btn-blue int-btn-save w-100 m-b-5"
                translate="internalTransfer.toPaymentOverview"
                data-ng-click="paymentFinalStep.navigateToAllPaymentsPage();">
        </button>
      </div>

    </div>
  </div>
</div>



