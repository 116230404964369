(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
          documents: {
            "header": "Prejeti dokumenti",
            "loadEvents": "Prikaži več dokumentov",
            "noItemData": "Ni podatkov za prikaz",
            "download": "Prenesi",
            "titleModalInfo":"Dokument trenutno ni na voljo",
            "messageModalInfo":"Izbrani dokument trenutno ni na voljo. Prosimo, poskusite kasneje ali pa se obrnite na našo pomoč uporabnikom.",
            "closeModalInfo":"Zapri",
            "documentDownloadFailed": "Izbrani dokument trenutno ni na voljo. Prosimo, poskusite kasneje ali pa se obrnite na našo pomoč uporabnikom."
          }
        };

        $translateProvider
            .translations('sl', data);
    }
}());
