(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            settings: {
                "title": "Nastavitve",
                "tabAccount": "Račun",
                "tabNotifications": "Obveščanje",
                "tabSecurity": "Varnost",
                "contactInfo": "Osebni podatki",
                "contactInfoBtn": "Spremeni",
                "contactInfoConfirmBtn": "Potrdi",
                "contactInfoCancelBtn": "Prekliči",
                "contactInfoName": "Ime in priimek",
                "contactInfoAddress": "Naslov",
                "contactInfoPhone": "Telefon",
                "blocades": "Blokade",
                "blockLogin": "Blokada vstopa v spletno banko",
                "blockMobile": "Blokada vstopa v mobilno banko",
                'smsNotAvailable': 'Prijava SMS ni mogoča. ',
                'smsNotAvailableInfo': "V mobilni banki imate aktivirano obveščanje s potisnimi sporočili. Sočasna uporaba obveščanja s potisnimi sporočili in SMS-i ni omogočena. Prijava na prejemanje SMS sporočil bo omogočena, če se v mobilni banki odjavite od prejemanja potisnih sporočil.",
                "blockPayment": "Blokada plačilne kartice",
                "accountSettings": "Nastavitve računa",
                "defaultAccount": "Privzeti račun",
                "statementsDelivery": "Način dostave izpiskov",
                "statementsDeliveryPaper": "Po pošti na domači naslov",
                "statementsDeliveryElectronic": "V spletno banko",
                "messagesSettings": "Nastavitve sporočil",
                "messagesSettingsLabel": "Označite, če želite vsa sporočila v spletno banko prejeti tudi na email naslov.",
                "securitySettings": "Varnostne nastavitve",
                "transactionsConfirmation": "Način dodatne potrditve plačil",
                "transactionsConfirmationSMS": "Prejem gesla s SMS sporočilom na mobilno številko",
                "transactionsConfirmationOTP": "Geslo z generatorja gesel",
                "transactionsConfirmationText": "Več informacij na temo potrjevanja transakcij je na voljo na naši ",
                "transactionsConfirmationLink": "spletni strani.",
                "notificationSettings": "Nastavitve obveščanja",
                "gsm": "Številka mobilnega telefona",
                "gsmNewSL": "Številka mobilnega telefona *",
                "oldPass": "Trenutno geslo",
                "newPass": "Novo geslo",
                "againPass": "Ponovite novo geslo",
                "noMatch": "Ni zadetkov",
                "change": "Spremeni",
                "street": "Ulica",
                "houseNumber": "Hišna št.",
                "postalCode": "Poštna št.",
                "city": "Kraj",
                "streetRequiredErrMsg": "Vnos ulice je obvezen!",
                "houseNumRequiredErrMsg": "Vnos hišne št. je obvezen!",
                "cityOrPostalCodeRequiredErrMsg": "Vnos kraja ali poštne št. je obvezen!",
                "emailRequiredErroMsg": "Vnos emaila je obvezen!",
                "invalidEmailRequiredErroMsg": "Email ni v pravilni obliki!",
                "orderSoftToken": "Naročilo programskega generatorja gesel",
                "setBlockade": "Blokada poslovanja",
                "passwordFromSMS": "Prejem gesla s SMS sporočilom na mobilno številko",
                "passwordFromOTP": "Geslo z generatorja gesel",
                "additionalTransactionsInfo": "Izbira načina dodatnega potrjevanja transakcij",
                "blockadeOfAddikoEbank": "Blokada poslovanja v Addiko EBank/Addiko Mobile",
                "blockadeOfAddikoEBankText": "Za določen ali nedoločen čas lahko onemogočite uporabo Addiko EBanke/Addiko Mobile.",
                "changeUserBasedNotifications": "Obvestila vezana na uporabnika",
                "accountBasedNotifications": "Obvestila vezana na račune",
                "userBasedNotifications": "Obvestila vezana na uporabnika",
                "forAccount": "Za račun",
                "ebankLoginNotice": "Obvestilo o vstopu v Addiko EBanko",
                "maturedTermDeposit": "Obvestilo ob zapadlosti depozitov (5 dni pred zapadlostjo)",
                "maturedSavings": "Obvestilo ob zapadlosti varčevanja (5 dni pred zapadlostjo)",
                "periodicNotice": "Periodično obvestilo o razpoložljivem stanju na računu",
                "accountBalanceUnder": "Obvestilo o razpoložljivem stanju pod izbranim zneskom",
                "accountBalanceAbove": "Obvestilo o razpoložljivem stanju nad izbranim zneskom",
                "accountInflow": "Obvestilo o prilivu na račun",
                "accountOutflow": "Obvestilo o odlivu z računa",
                "ebankSuccessfull": "Obvestilo o uspešni izvedbi plačila prek Addiko EBanke",
                "ebankUnSuccessfull": "Obvestilo o neuspešni izvedbi plačila prek Addiko EBanke",
                "rejectedSEPA": "Obvestilo o zavrnjeni SEPA direktni obremenitvi",
                "newInvoiceNotice": "Obvestilo o prejemu e-računa v Addiko EBanko",
                "enabled": "Vklopljeno",
                "disabled": "Izklopljeno",
                "enableMail": "Prijavi E-pošto",
                "enableSms": "Prijavi SMS",
                "disableSms": "Odjavi SMS",
                "disableMail": "Odjavi E-pošto",
                "successOrder": "Naročilo je bilo uspešno oddano.",
                "notificationsNote": "Preko Addiko EBanke se lahko naročite na prejemanje obvestil in alarmov ob izbranih dogodkih. Obvestila lahko prejemate na mobilni telefon ali e-poštni naslov. Posredovanje SMS sporočil je plačljiva storitev mobilnega operaterja Telekom Slovenije d.d., ustrezno provizijo za posredovanje SMS sporočil in alarmov vam zaračunava Addiko Bank d.d. v skladu z",
                "notificationsNoteLink": "vsakokrat veljavnim cenikom.",
                "changeEMail": "Spremeni E-poštni naslov",
                "emailAddress": "E-poštni naslov",
                "noNumber": "Addiko Bank d.d. v svojih evidencah ne razpolaga z vašo številko mobilnega telefona. Za vnos številke se oglasite v eni od poslovalnic.",
                smsModal: {
                    "user": "Uporabnik",
                    "forAccount": "Za račun",
                    "orderDesc": "Želim naročiti",
                    "email": "E-poštni naslov",
                    "orderNoticeStart": "Z vnosom podatkov potrjujem, da se strinjam s prejemanjem naročenih obvestil.",
                    "orderNoticeStop": "Z vnosom podatkov potrjujem, da se strinjam z odjavo prejemanja naročenih obvestil.",
                    "orderSmsStart1": "Z vnosom podatkov potrjujem, da se strinjam s prejemanjem naročenih obvestil. Izjavljam, da sem prebral/a in v celoti sprejemam ter se strinjam s",
                    "orderSmsStartLink": "Splošnimi pogoji za vodenje TRR in opravljanje plačilnih storitev",
                    "orderSmsStart2": ", katerih del je tudi opis SMS storitve.",
                    "next":"Naprej",
                    "cancel":"Prekliči",
                    "back":"Nazaj",
                    "gsm":"Številka mobilnega telefona",
                    "receivingSmsStart":"Prejemanje SMS obvestil",
                    "receivingEmailStart":"Prejemanje e-poštnih obvestil",
                    "receivingSmsStop":"Odjavo prejemanja SMS obvestil",
                    "receivingEmailStop":"Odjavo prejemanja e-poštnih obvestil",
                    "dailyTimePeriod":"Termin dneva",
                    "noticeAmount":"Znesek obveščanja (V EUR)",
                    "noticeAmountUnder":"Znesek obveščanja pod (V EUR)",
                    "noticeAmountAbove":"Znesek obveščanja nad (V EUR)",
                    "agreedToReceiveNotices":"Strinjanje s prejemanjem obvestil",
                    "agreedToGeneralTerms":"Strinjanje s splošnimi pogoji",
                    "agreedToStopTerms":"Strinjanje s prenehanjem prejemanja obvestil",
                    "yes":"Da",
                    "no":"Ne",
                    "orderDate":"Datum naročila",
                    "orderType":"Vrsta naročila",
                    "daily":"Dnevno",
                    "weekly":"Tedensko",
                    "monthly":"Mesečno",
                    "period":"Perioda",
                    "dailyPeriod":"Termin dneva",
                    weekDays:{
                        "monday":"ponedeljek",
                        "tuesday":"torek",
                        "wednesday":"sreda",
                        "thursday":"četrtek",
                        "friday":"petek",
                        "saturday":"sobota",
                        "sunday":"nedelja"
                    },
                  "newOrder":"Novo naročilo",
                  "archive":"Arhiv",
                  "successMsg":"Naročilo je bilo uspešno oddano."
                },
              phoneAndEmailNotificationModal:{
                "notificationHeader" : "Nastavitev številke mobilnega telefona",
                "email" : "Elektronski naslov",
                "cancelModal" : "Prekliči",
                "confirmModal" : "Potrdi"
              },
              "unsubscribeHeader":"Odjava od poslovanja z mobilno banko",
              "unsubscribeText":"Odjava od poslovanja z mobilno banko Addiko Mobile bo stopila v veljavo s prvim dnem naslednjega meseca po oddaji vloge. Za ponovno prijavo na poslovanje z mobilno banko Addiko Mobile izvedite prijavo v Addiko Mobile aplikacijo.",
              "unsubscribeButton":"Odjava",
              "unsubscribeAlertHeader":"Ste prepričani, da se želite odjaviti od uporabe Addiko Mobile banke?"

            },
            orderSoftToken: {
                title: "Naročilo programskega generatorja gesel",
                infoTxt1: "Oddate lahko naročilo za programski generator gesel, ki ga lahko uporabljate namesto strojnega generatorja gesel. Programski generator je aplikacija na vašem mobilnem telefonu, ki omogoča generiranje enkratnih gesel za vstop v Addiko EBanko ter Addiko Mobile in lahko v celoti nadomešča strojni generator gesel.",
                infoTxt2: "Po uspešni izvedbi naročila boste v poštni predal Addiko EBanke ter na številko vašega mobilnega telefona prejeli 2 ločena aktivacijska ključa za aktiviranje programskega generatorja gesel (Soft tokena), ki služi generiranju enkratnih gesel. Več informacij o programskem generatorju gesel lahko dobite",
                linkTxt: "na naši spletni strani.",
                emailLbl: "E-poštni naslov:",
                telephoneLbl: "Številka mobilnega telefona:",
                okBtn: "Potrdi",
                nextBtn: "Naprej",
                cancelBtn: "Prekliči",
                successTxt: "Vaše naročilo je bilo uspešno poslano!",
                changeEmail: "Sprememba e-naslova",
                changeGsm: "Sprememba št. mobilnega telefona",
                backBtn:"Nazaj",
                nameAndSurmane: "Ime in priimek"
            },
            mobilePhoneSettings: {
              setMobilePhoneNumber: "Spremeni številko mobilnega telefona",
              title: "Spremeni mobilno telefonsko številko",
              headerInfoText: 'V spodnje polje vneseno mobilno telefonsko številko bo po zaključenem postopku potrjevanja Banka uporabljala kot vašo glavno mobilno telefonsko številko. V kolikor želite spremeniti svojo obstoječo mobilno telefonsko številko v tujo mobilno telefonsko številko se oglasite v poslovalnici.',
              mobilePhoneLabel: "Mobilna telefonska številka",
              confirmMobilePhone: "Potrjujem pravilnost podatka o mobilni telefonski številki. S tem se tudi strinjam, da mi Banka na to mobilno telefonsko številko pošilja obvestila, vezana na spletno banko Addiko Ebank, aktivacijska gesla in varnostne kode za potrjevanje transakcij. Obenem se strinjam, da Banka posodobi mojo mobilno telefonsko številko tudi v Flik Pay in jo uporablja tudi za obveščanje povezano z Mastercard kartico.",
              firstConfirmationTitle: "Prva potrditev obstoječe številke mobilnega telefona",
              firstConfirmationText: "Če Addiko Bank d.d. razpolaga z vašo številko mobilnega telefona, je slednja že vnešena v polje za vnos. Po potrditvi te številke boste preko SMS sporočila prejeli potrditveno kodo, ki jo vnesite v polje za vnos potrditvene kode v naslednjem koraku. S tem boste veljavnost številke mobilnega telefona tudi formalno potrdili.",
              changingExistingNumberTitle: "Sprememba obstoječe številke mobilnega telefona",
              changingExistingNumberText: "Če Addiko Bank d.d. razpolaga z vašo številko mobilnega telefona, je slednja že vnešena v polje za vnos. Če želite številko mobilnega telefona spremeniti, že navedeno številko v polju zbrišite ter vnesite novo. Po potrditvi te številke boste preko SMS sporočila na vašo DOSEDANJO številko mobilnega telefona prejeli sporočilo s potrditveno kodo, ki jo vnesete v polje za vnos potrditvene kode v naslednjem koraku. S tem boste veljavnost NOVE številke mobilnega telefona tudi formalno potrdili. V kolikor dostopa do vaše DOSEDANJE številke mobilnega telefona nimate več, se morate za vnos oziroma spremembo številke mobilnega telefona oglasiti v eni od Addiko poslovalnic. Po podpisu ustreznega obrazca bomo poskrbeli za formalno potrditev številke vašega mobilnega telefona.",
              newPhoneNumberTitle: "Vnos nove številke mobilnega telefona",
              newPhoneNumberText: "Če Addiko Bank d.d. z vašo številko mobilnega telefona še ne razpolaga, se morate za vnos oziroma spremembo številke mobilnega telefona oglasiti v eni od Addiko poslovalnic. Po podpisu ustreznega obrazca bomo poskrbeli za formalno potrditev številke vašega mobilnega telefona.  Po formalni potrditvi številke mobilnega telefona boste lahko preko SMS sporočil prejemali obvestila in kode za potrjevanje transakcij.",

              orderDate: "Datum naročila",
              orderType: "Vrsta naročila",
              orderTypeInfo: "Sprememba mobilne telefonske številke",
              dataConfirmation: "Potrditev podatkov",
              additionalPaymentConfirmationTitle: "Dodatna potrditev",
              additionalPaymentConfirmationText: "Prosimo, v spodnje polje vnesite potrditveno kodo, ki jo generirate s pomočjo generatorja gesel, enako kot  pridobite „Geslo za spletno banko“.",
              SMSCode: "Vnesite potrditveno kodo, ki ste jo prejeli v SMS sporočilu:",

              gsmPhoneError: "GSM številka ni v pravilnem formatu.",
              requiredField: "Polje je obvezno.",
              SMSCodeRequired: "Potrditvena koda je obvezna.",
              numberChangeSuccess: "Na vašo mobilno telefonsko številko je bilo poslano sporočilo, ki vsebuje povezavo s potrditveno kodo. Vaša mobilna telefonska številka bo spremenjena, ko jo boste potrdili.",
              notificationSettingUpMobile1: "Spoštovani",
              notificationSettingUpMobile2: "zaradi uvajanja novih varnostnih zahtev, ki jih prinaša Direktiva o plačilnih storitvah (PSD2), je potrebno odslej določene postopke potrjevati z enkratno kodo, povezano s točno to aktivnostjo. V primeru Addiko EBank to velja za vsa plačila, ki niso shranjena med predlogami.  Poleg plačil preko Predlog so izjeme tudi interni prenosi ter plačilo e-računov, kjer dodatno potrjevanje ni potrebno. Koda bo ob potrditvi plačila poslana preko sporočila SMS na registrirano številko vašega mobilnega telefona, ki jo bo potrebno vpisati v za to namenjeno polje v spletni banki Addiko EBank. Zaradi navedenega vas prosimo, da v nadaljevanju vnesete oziroma potrdite vaše kontaktne podatke. Z vnosom/potrditvijo kontaktnih podatkov se avtomatsko spremeni tudi način potrjevanja transakcij (preko enkratne kode SMS). V primeru, da podatkov ne vnesete/potrdite, bodo funkcionalnosti, kjer se koda zahteva, po poteku prehodnega obdobja onemogočene.",
              notificationSettingUpMobile3: "Za več informacij smo na voljo na e-poštnem naslovu "
            },
            'emailVerification': {
              emailLabel: "E-poštni naslov",
              title: "Spremeni e-poštni naslov",
              headerInfoText: "V spodnje polje vnesen e-poštni naslov bo po zaključenem postopku potrjevanja Banka uporabljala kot vaš glavni e-poštni naslov.",
              orderTypeInfo: "Sprememba e-poštnega naslova",
              emailChangeSuccess: "Na vaš e-poštni naslov je bilo poslano sporočilo, ki vsebuje povezavo s potrditveno kodo. Vaš e-poštni naslov bo spremenjen, ko ga boste potrdili. ",
              confirmEmail: 'Potrjujem pravilnost podatka o e-poštnem naslovu. S tem se tudi strinjam, da mi Banka na ta e-poštni naslov pošilja obvestila in aktivacijska gesla, vezana na spletno banko Addiko Ebank, različna obvestila in dokumentacijo vezana na pogodbe, ki jih imam z Banko (npr. obvestila o spremembah obrestnih mer, pogodbeno dokumentacijo, dvižne listke…), obenem se strinjam, da Banka posodobi moj e-poštni naslov tudi v Flik Pay.',
            }
        };

        $translateProvider.translations('sl', data);

    }
}());
