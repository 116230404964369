(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            dashboard: {
                "dashboardHeader": "Prva stran",
                "addNewWidget1": "Dodaj",
                "addNewWidget2": "Priljubljeni računi",
                "pendingPayments": "Čakalna vrsta plačil",
                "show": "Prikaži",
                "hide": "Skrij",
                "domesticPaymentBtn1": "UPN",
                "domesticPaymentBtn2": "nalog",
                "transferBtn1": "Prenos na",
                "transferBtn2": "moj račun",
                "templateBtn1": "Plačilo iz",
                "templateBtn2": "predloge",
                "quickPaymentTransfer": "Prenos med računi",
                "domesticPayment": "Nov UPN nalog",
                "internalTransfer": "Nov prenos",
                "payTemplate": "Plačilo po predlogi",
                "searchTemplatesPlaceholder": "Išči predlogo",
                "showAccounts": "Prikaži več računov",
                "instantLoan": "Hitri kredit",
                "instantLoanDescription": "Kredit je lahko enostaven. Dali vam bomo jasna navodila o mesečnih obrokih.",
                "apply": "Info",
                "contact": "Kontaktirajte nas",
                "hypoClub": "Addiko Klub",
                "etrade": "Addiko Broker",
                "branch": "Iskanje poslovalnice",
                "bankTimeline": "Časovnica",
                "loadEvents": "Prikaži več",
                "noTimelineData": "Za izbrano obdobje ni podatkov za prikaz",
                "availableBalance": "Razpoložljivo stanje",
                "loanAmount": "Znesek kredita",
                "confirmation": "Potrdilo",
                "complaint": "Reklamacija",
                "cashLoan": "Gotovinski kredit",
                "approval": "odobrenje za 1 leto",
                "loyaltyPointsLbl": "Addiko klub",
                "loyaltyCurrentPoints": "Točke tekočega obdobja",
                "loyaltyPreviousPoints": "Točke preteklega obdobja",
                "tydUnsubscribe": "V kolikor ne želite več prejemati marketinških sporočil v Addiko EBank, nam preko funkcionalnosti \"Sporočila\" na glavnem meniju pošljite sporočilo z zadevo/tekstom \"odjava\" ali nas pokličite na telefonsko številko 01/580-42-28."
               },
            timelineFilter: {
                "placeholder": "Iskanje ali filtriranje",
                "all": "Vse",
                "credit": "Prejemki",
                "debit": "Izdatki",
                "others": "Ostalo",
                "amountFrom": "Znesek od",
                "amountTo": "Do",
                "reset": "Počisti",
                "apply": "Potrdi"
            },
            header: {
                "dashboard": "Prva stran",
                "accounts": "Računi in kartice",
                "payments": "Plačila",
                "orders": "Naročila",
                "branches": "Poslovalnice in bankomati",
                "settings": "Nastavitve",
                "notificationMessages": "Sporočila",
                "notificationMessagesPlaceholder": "Napišite vaš odgovor",
                "notificationMessagesPlaceholderSubject": "Naslov sporočila",
                "notificationMessagesUnread": "Neprebrana",
                "notificationMessagesMarkAsRead": "Označi kot prebrano",
                "notificationMessagesReply": "Odgovori",
                "notificationMessagesSend": "Pošlji",
                "notificationMessagesCancelSend": "Prekliči",
                "notificationMessagesViewAll": "Pregled vseh sporočil",
                "notificationEInvoices": "Novi eRačuni",
                "notificationEInvoicesDueDate": "datum plačila",
                "notificationEInvoicesPay": "Plačaj",
                "notificationEInvoicesViewAll": "Pregled vseh eRačunov",
                "notificationPendingPayments": "Plačila v izvedbi",
                "notificationPendingPaymentsViewAll": "Pregled vseh plačil",
                "notificationPendingPaymentsDateLabel": "datum",
                "notificationRejectedPayments": "Zavrnjena plačila",
                "notificationRejectedPaymentsViewAll": "Pregled vseh plačil",
                "notificationRejectedPaymentsDateLabel": "datum",
                "notificationSettingsLangLabel": "Izberite jezik:",
                "notificationSettingsViewAllLabel": "Prikaži vse",
                "signedInAs": "Prijavljeni kot",
                "lastLogin": "Datum zadnje prijave: ",
                "logout": "Odjava",
                "langEn": "English",
                "langHr": "Hrvatski",
                "langSr": "Srpski",
                "langMtr": "Crnogorski",
                "langBa": "Lokalni",
                "langSl": "Slovensko",
                "langDe": "Deutsch",
                "langRu": "Ruski",
                "noMessages": "Ni sporočil",
                "noeInvoices": "Ni eRačunov",
                "noPendingPayments": "Ni čakajočih plačil",
                "noRejectedPayments": "Ni zavrnjenih plačil"
            },
            footer: {
                "freeCall": "Telefonska številka",
                "internationalCall": "Mednarodni klic",
                "email": "Pošljite nam e-pošto",
                "notificationDepth": "Poglobljena analiza",
                "dearClients": "",
                "modalText1": "",
                "modalText2": "",
                "modalText3": "",
                "regards": "",
                "closeButton": "Zapri",
                "version": "Verzija",
                "server": "Server"
            },
            timeline: {
                msgWith: "Sporočilo z naslovom ",
                msgWithout: "Sporočilo brez naslova ",
                msgReceived: " je bilo prejeto ",
                msgSent: " je bilo poslano "
            },
            templateSearch: {
                favorites: "Priljubljene predloge",
                templatesList: "Seznam predlog"
            },
            dashboardWidgets: {
                current: "Osebni računi in kartice",
                loan: "Krediti",
                deposit: "Depoziti",
                savings: "Varčevanja",
                savingsTransactions: "Varčevanja",
                giro: "Žiro računi",
                addBtn: "Potrdi"
            },
            dashboardNotification: {
                rejected: "novih zavrnjenih plačil",
                einvoiceSlo: "novih prejetih eRačunov",
                group: "skupinskih nalogov za podpis",
                goBtn: "Pojdi na plačila",
                unsigned: "nalogov v čakalni vrsti",
                pending: "nalogov v čakalni vrsti",
                documents: "neprebranih dokumentov"
            }
        };

        $translateProvider
            .translations('sl', data);
    }
}());
