(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EinvoiceSubscribeCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EinvoiceSubscribeCtrl', EinvoiceSubscribeCtrl);

  function EinvoiceSubscribeCtrl($state, $log, EinvoicesIssuersOverviewService, UserSettingsService, HelperService, EinvoiceSubscriptionsService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'EinvoiceSubscribeCtrl';
    vm.issuerObject = {};
    vm.userSettings = {};
    vm.subscriptionObjectList = {};
    vm.subscriptionObject = {};

    vm.tempObj = {
      einvoiceSubscribeEmailInput: '',
      einvoiceReferenceFromIssuerInput: null,
      userApproves: false,
      formSubmitted: false
    }
    vm.originalEmail = '';
    vm.changeEmailButton = true;
    vm.goBack = function () {
      $state.go('eInvoices.einvoicesIssuersOverview');
    };

    vm.specialConditionsApprovement = false;
    vm.specialConditionsApprovementVisible = false;

    vm.customErrorLabels = [];
    vm.steps = {
      step1: true,
      step2: false,
      step3: false
    };

    vm.init = function () {
      $log.debug('Issuer detail init()');
      if ($state.params.issuerId) {
        vm.getUserSettingsPage();
        vm.getIssuerById($state.params.issuerId);
        vm.getSubscriptionsPage();
      }
    };

    vm.getIssuerById = function (id) {
      EinvoicesIssuersOverviewService.getById(id)
        .then(function (response) {
          vm.issuerObject = response;
        })
        .catch(function (error) {
          $log.debug("error", error);
        });
    };

    vm.subscriptionsFilter = {
      page: 0,
      pageSize: 1000
    };

    vm.getSubscriptionsPage = function () {
      EinvoiceSubscriptionsService.getPage(vm.subscriptionsFilter)
        .then(function (response) {
          vm.subscriptionObjectList = response;
          if (vm.subscriptionObjectList.result.length > 0) {
            for (var i = 0; i < vm.subscriptionObjectList.result.length; i++) {
              if (vm.subscriptionObjectList.result[i].issuerId == vm.issuerObject.id)
                vm.subscriptionObject = vm.subscriptionObjectList.result[i];
              $log.debug("subscribe object", JSON.stringify(vm.subscriptionObject));
            }
          }
        })
        .catch(function (error) {
          $log.debug("error", error);
        });
    };

    var createRequestObject = function () {
      return {
        requestType: vm.subscriptionObject.settingType,
        paymentReferenceNumber: vm.tempObj.einvoiceReferenceFromIssuerInput,
        issuerId: vm.issuerObject.id,
        userServiceId: vm.tempObj.einvoiceReferenceFromIssuerInput,
        serviceType: vm.subscriptionObject.serviceType,
        serviceState: vm.subscriptionObject.serviceState,
        services: null,
        userApproves: vm.tempObj.userApproves
      }
    };

    vm.einvoiceSubscribeSubmit = function () {
      vm.tempObj.formSubmitted = true;

      if (!vm.einvoiceSubscribeForm.$valid) {
        return;
      }
      var eInvoiceSubscriptionInsertRequestObject = createRequestObject();
      EinvoiceSubscriptionsService.insertSubscriptionRequest(eInvoiceSubscriptionInsertRequestObject)
        .then(function (response) {
          vm.subscriptionResponse = response;
          //swal("Success!");
          $log.debug("Subscription response object:", response);
          vm.steps = {
            step1: false,
            step2: true,
            step3: false
          };
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          $log.error("Subscription error:", error);
        });
    };

    vm.userSettingsFilter = {
      page: 0,
      pageSize: 1
    };

    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          vm.userSettings = response;
          vm.tempObj.einvoiceSubscribeEmailInput = response.result[0].eInvoiceEmail;
          vm.originalEmail = response.result[0].eInvoiceEmail;
          $log.debug("userSettings", vm.userSettings);
        }).catch(function (error) {
          $log.error(error);
        });
    };

    vm.changeEmail = function () {
      vm.changeEmailButton = false;
    };
    vm.changeEmailOk = function () {
      if (vm.tempObj.einvoiceSubscribeEmailInput != vm.originalEmail) {
        var email = {
          invoicingEmail: vm.tempObj.einvoiceSubscribeEmailInput
        };

        EinvoiceSubscriptionsService.updateInvoiceEmail(email)
        .then(function (response) {
          $log.debug("einvoiceMail", response);
          $log.debug("mail", email);

        }).catch(function (error) {
          $log.error(error);
        });
      }
      vm.changeEmailButton = true;
    };
    vm.changeEmailCancel = function () {
      vm.changeEmailButton = true;
      vm.tempObj.einvoiceSubscribeEmailInput = vm.originalEmail;
    }


    vm.init();
  }
}());
