(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      accounts:{
        "installmentDivided": "Podijeljeno na rate",
        "accAllAccountsOverview":"All accounts overview",
        "accShowAccounts":"Show more accounts",
        "accFilter":"Filter by type",
        "accAmountRange":"Amount range",
        "accTo":"To",
        "accApply":"Apply",
        "accReset":"Reset",
        "accPeriod":"Period",
        "accDescription":"Description",
        "accAmount":"Amount",
        "accBalance":"Balance",
        "accBalanceSlo":"Approved limit",
        "accShowOnDashboard":"Show on dashboard",
        "accTransactions":"Transactions",
        "accPdfStatements":"Pdf statements",
        "accDownloadPdf":"Download PDF",
        "accChangeName":"Change account name",
        "accResetName":"Reset to original name",
        "accQuickPayment":"Quick payment",
        "accRequestLimit":"Request limit change",
        "accAvailableToSpend":"Available to spend",
        "accReservations":"Reservations",
        "accReservationsCardReservationAmount":"Reserved by card business",
        "accOverdraftLimit":"Overdraft limit",
        "accOverdraftLimitDue":"Overdraft expiration date",
        "accPayIn":"Pay in",
        "accRepaymentPlan":"View repayment plan",
        "accLoanAmount":"Approved amount",
        "accLoanBalance":"Loan balance",
        "accInterestRate":"Interest rate p.a.",
        "accAnnuity":"Annuity",
        "accStartDate":"Start date",
        "accEndDate":"End date",
        "accDisbursementDate":"Date of approval",
        "accDuration":"Duration",
        "accViewMore":"View more information",
        "accViewLess":"View less information",
        "accTypeOfRate":"Type of interest rates",
        "accPenaltyInterests":"Penalty interests",
        "accOverpayments":"Overpayments",
        "accOtherFees":"Other fees",
        "accChangeDate":"Change date",
        "accAlreadySpent":"You have already spent",
        "accPayCard":"Pay card",
        "accApprovedLimit":"Approved limit",
        "accDueDate":"Due date",
        "accClosingDate":"Closing date",
        "accMonthlyDepositAmount":"Monthly deposit amount",
        "accStatementDeliveryMethod":"Statement delivery method",
        "accAccountForRelease":"Account for release",
        "accAnnualAssignment":"Annual assignment of interest",
        "accPayOut":"Pay out",
        "accCloseAccount":"Close account",
        "accOpenNewSavingsAccount":"Open new savings account",
        "accEg":"eg",
        "accSearchTransactionsPlaceholder":"Search transactions",
        "accCancelDeposit":"Cancel deposit",
        "accCancelAutoExtension":"Cancel auto extension",
        "accNewDeposit":"New deposit",
        "accPrincipal":"Principal",
        "accInterest":"Interest",
        "accMaturityDate":"Maturity date",
        "accCurrentBalance":"Current balance",
        "accCurrentBalance_SLO":"Available balance",
        "accAtmWithdrawalLimit":"ATM withdrawal limit",
        "accPosPaymentLimit":"Available balance",
        "accDateLastChanged":"Closing balance on",
        "accBalanceDateLastChanged":"Last balance change",
        "accAutorization":"Authorisation",
        "accProvedena":"Executed",
        "accAvailableBalance":"Available balance",
        "accSearchAccountsPlaceholder":"Search accounts",
        "accShowMatured":"Show matured accounts",
        "accAuthorizedPersons":"Authorized persons",
        "accCardStatus":"Card status",
        "accContractNumber":"Contract number",
        "accDepositStartDate":"Deposit start date",
        "accDepositStartDate_SLO":"Deposit start date",
        "accDepositEndDate":"Deposit end date",
        "accDepositEndDate_SLO":"Maturity date",
        "rename": "Rename",
        "accAccountAndCardsMoreDetails": "Show additional details",
        "accAccountAndCardsMoreDetails1": "Show additional details and cards",
        "accAccountAndCardsLessDetails": "Less details",
        "accInstallmentsPayed":"Installments payed",
        "accInstallmentNumber": "Installments number",
        "accInstallmentsRemain":"Installments remain",
        "accNextInstallmentDate":"Next installment date",
        "accNextInstallmentAmount":"Next installment amount",
        "connectedDebitCards": "Connected cards",
        "blockCard": "Block card",
        "unblockCard": "Unblock card",
        "activateCard": "Activate card",
        "blockCardRequest": "Block request sent",
        "unblockCardRequest": "Unblock request sent",
        "activationCardRequest": "Activation request sent",
        "accDueAmount": "Total mature debt",
        "accMatureInterest":"Mature interest",
        "accNewEbook": "New e-Book",
        "closeEbook": "Close e-Book",
        "accPaymInQue": "Unprocessed cheques",
        "accReservedAmount": "Reserved amount",
        "accPlanedDebit": "Planed debit",
        "accDepositInterestRate": "Deposit interest rate",
        "accDepositTypeExternal": "Deposit Type External",
        "accDepositPrincipal": "Deposit principal",
        "accLimitValidUntilDate": "Limit valid until date",
        "accObligationAmount": "Obligation amount",
        "accOutstandingAmount": "Outstanding amount",
        "accCardNumber": "Card number",
        "accInstallmentPaidNumber": "Installments paid number",
        "accNotMatureCapital": "Not mature capital",
        "accNewDebitDate": "New debit date",
        "title": "Information",
        "message": "Please call our free Contact center number 0800 14 14 or +385 1 6030 000 for international calls.",
        "message_SLO": "In case of fraud suspicion, loss or theft of the card you must immediately inform processing centre on telephone number +386 (0)1 58 34 183 or nearest branch of Addiko bank or any bank with Mastercard sign.",
        "message_BH": "Card is blocked by Bank. More information at Contact Center.",
        "close": "Close",
        "accHideTransactions": "Hide transactions",
        "balance": "Balance",
        "accParentAccountIban": "Main account",
        "accAnnuityConverted": "Interest rate",
        "accNotMatureCapitalConverted": "Not mature capital in ",
        "closeRepaymentPlan":"Close repayment plan",
        "accHideReservations": "Hide reservations",
        "depositCantBeCanceledError": "Early termination of term deposits is possible only for term contracted through direct banking services.",
        cardStatus: {
          "Active":"Active",
          "BlockedFromEBank":"Blocked From EBank",
          "Closed":"Closed",
          "Blocked":"Blocked"
        },
        "Active":"Active",
         "BlockedFromEBank":"Blocked From EBank",
         "expDate":"Expiry date: ",
         "expDate_SLO":"Valid until: ",
        "accTotalMatureConverted": "Total mature debt in ",
         "Closed":"Closed",
        "eBookPayIn": "Pay in",
        "eBookPayOut": "Pay out",
        "Blocked":"Blocked",
        "dataNotAvailable": "Data not available",
        "approvedLimit": "Approved limit",
        "transDivisionBtn1": "Transaction",
        "transDivisionBtn2": "division",
        "limitForATM": "Limit for ATM cash withdrawal",
        "destinationAccount": "Destination account",
        "interestRate": "Interest rate",
        "interestToDate": "Interests to date",
        "extensionType": "Extension type",
        "depositProlongationNumLeft": "Prolongation left number",
        "bindingSpan": "Binding span",
        "accountNumber": "Account number",
        "untilRevocation": "Until revocation",
        "limitAmountATM": "Limit for daily ATM withdrawals",
        "limitAmountPOS": "Limit for daily POS payments",
        "limitAmountNET": "Limit for daily internet payments",
        "creditCardNumber": "Credit Card number",
        "BlockedTemporary":"Temporary block",
        "openingDate":"Date of opening",
        "closingDate":"Closing date",
        "minSavingBalance":"Monthly deposit amount",
        "minSavingBalance2":"Minimum deposit amount",
        "statementDeliveryMethod":"Statement delivery method",
        "annualAssignmentOfInterests":"Annual assignment of interests",
        "days": "days",
        "destinationAccount_SLO": "Destination account",
        "savedFunds": "Saved funds",
        "PaidOut":"Paid Out",
        "Extended": "Extended",

        "obligationAmountCard": "Obligation amount",
        "outstandingAmountCard": "Outstanding amount",
        "reservedAmount": "Reserved amount",
        "outstandingDebitAmountCard": "Outstanding debit amount",
        "statementDateCard": "Statement date",
        "usedAmountCard": "Used amount",
        "minimalDebitCard": "Minimal debit amount",
        "minimalDebitUntilDateCard": "Minimal debit until date",
        "cardExpieryDateCard": "Expiry date",
        "interestRateAmountCard": "Interest rate amount"
      },
      merchant: {
        'address': 'Address',
        'contact': 'Contact',
        'website': 'Website',
        'location': 'Location',
      },
      repaymentPlan: {
        "instalment": "Instalment",
        "loanBalance": "Loan balance",
        "date": "Date",
        "dateFrom":"Date from",
        "dateTo":"Date to",
        "principalAmount": "Principal amount",
        "paymentAmount": "Payment amount",
        "interestAmount":"Interest amount",
        "totalAnnuity":"Total annuity",
        "balance": "Balance"
      },
      transactions: {
        "description":"Description",
        "amount":"Amount",
        "statusTransaction":"Transaction status",
        "availableAmount":"Available amount",
        "accountNumber":"Account number",
        "bookingDate":"Booking date",
        "valueDate":"Value date",
        "cardTransactionDetails":"Card transaction details",
        "transactionDetails":"Transaction details",
        "close":"Close",
        "card":"Card",
        "iban":"IBAN",
        "noData":"No transaction data for selected period",
        "noStatementData":"No statement data for selected account",
        "retrieval": "Retrieval",
        "ibanFrom": "From IBAN",
        "ibanFromBh": "From BBAN/IBAN",
        "accountFrom": "From account",
        "accountTo": "To account",
        "referenceNumber":"Reference number",
        "creditorName": "Payer",
        "debitorName": "Recipient",
        "creditorAddress": "Payer address",
        "debitorAddress": "Recipient address",
        "transactionAndOriginalCurrency": "Transaction in original currency",
        "statementDate": "Statement date",
        "creditCardNumber": "Credit Card number",
        "connCardReferenceAccount": "Reference number",
        "date":"Transaction date",
        "payeeCity": "Recipient city",
        "statementPeriod": "Statement period",
        "balanceSumCredit": "Total credit amount:",
        "balanceSumDebit": "Total debit amount:",
        "statementsFilter":"Transactions from statement",
        "MCC": "MCC",
        "POSReference": "Referenca transakcije na prodajnom mjestu",
        "POSIPS": "POS_IPS"
      },
      ebook: {
        "header": "Create new eSaving book",
        "date": "Date",
        "dateOfCreation":"Date of creation",
        "iAccept": "I accept",
        "documents": "Documents",
        "fullName": "Name and Surname",
        "mainAccount":"Main account",
        "firstName": "First name",
        "lastName": "Last name",
        "fromAccount": "From account",
        "eSavingsBookName": "e-Savings book name",
        "termsAndConditions": "General terms and conditions for opening and managing a ebook",
        "termsAndConditions2": "Informations before concluding the deposit agreement",
        "requestForEBook": "ebook request",
        "informationFormOnDeposits": "Deposit insurance information",
        "linksHeader": "See the other documentation related to contracting the booklet ",
        "linksHeaderLink": "here",
        "otherDocs1": "See the other documentation related to contracting the booklet ",
        "otherDocs2": "here",
        "link1": "General terms and conditions",
        "link2": "Excerpt from the decision on the amount of deposit interest rates for individuals",
        "link3": "Decision on fees for bank services",
        "link4": "Opći uvjeti otvaranja i vođenja e-knjižice za potrošace",
        "doc1": "ebook request",
        "doc2": "Informations before concluding the deposit agreement",
        "doc3": "Informacije prije sklapanja ugovora o e-knjižici",
        "doc4": "General terms and conditions for opening and managing a ebook",
        "preContractInfo": "Information before concluding the eBook contract",
        "confirm": "Confirm",
        "cancel": "Cancel",
        "edit":"Edit",
        "delete":"Delete",
        "ebookOrder":"e-Saving book",
        "sign":"Sign",
        "name":"Name",
        "enterName": "Enter name",
        "nameError": "E-Book name is required!",
        "createEbook": "Create e-Saving book",
        "successfullRequest": "You have successfully created e-Savings book",
        "completeRequest": "Request completed!",
        "eBookRequests": "E-Book requests",
        "createdBy": "Created by",
        "iban": "IBAN",
        "requestType": "Request type",
        "status": "Status",
        "timelineHeader":"E-book requests timeline",
        "showAllRequests": "Show requests for all accounts",
        "ebookNumber": "E-book number"
      },
      closeEBookPageTranslation: {
        title: "Request for closing",
        account: "Account",
        accountName: "Account name",
        currency: "Currency",
        balance: "Balance",
        recognisedInterestRate: "Recognised interest rate",
        recognisedInterestAmount: "Recognised interest amount",
        fee: "Fee",
        transferAmount: "Transfer amount",
        transferAccount: "Transfer account",
        confirmButton: "Confirm",
        cancelButton: "Cancel"
      },
      accountsOverview: {
        "title": "Accounts and Cards overview",
        "newTermDepositButtonLabel": "Add new Term Deposit",
        "newESavingAccountButtonLabel": "Add new eSaving book",
        "newESavingAccountButtonLabel_SLO": "Add new Saving",
        "newSavingButtonLabel_SLO": "New Mastercard order",
        "masterCardStateButtonLabel_SLO": "Prepaid Mastercard balance"
      },
      depositAccountsOverview: {
        "title": "Deposits and e-books overview",
        "titleForSlo": "Term deposits and Savings overview",
        "titleForSerbAndMne": "Deposits overview",
        "titleForBh": "Savings overview"
      },
      laonsOverview: {
        title: "Loans overview",
        noData: "No data is available."
      },
      cashWithdrawalTranslate: {
        title: "Order of large cash withdrawal",
        infoNote1: "Cash withdrawals in foreign exchange/kuna exceeding 5.001,00 EUR / 35.001,00 EUR must be announced 3 days in advance.",
        infoNote2: "In case you do not make the announced withdrawal your account will be charged with a corresponding fee ammount in accordance with the Bank Tariff.",
        infoNote1sl: "For withdrawals of cash in euros (EUR), you need to inform us if the amount is equal or higher than 3.000,00 EUR. For Swiss franc (CHF), British pound (GBP), American dollar (USD) and Croatian kuna (EUR) you need to inform us regardless of the amount. The order needs to be submitted at least two working days before the intended cash withdrawal by 10.00 am.",
        infoNote2sl: "",
        fromAccount: "From account",
        amount: "Amount",
        amountRequired: "Amount is required!",
        dateOfPayout: "Date of payout",
        phoneNumber: "Telephone number",
        placeOfCashTakeover: "Branch office",
        placeOfCashTakeoverRequiredMsg: "Branch office is required!",
        note: "Note",
        note_SLO: "I would like to receive cash in notes of the following denominations:",
        confirmBtnLabel: "Confirm",
        cancelBtnLabel: "Cancel",
        amountMinAmountError: "Minimal amount is ",
        until: "Until 13:00",
        after: "After 13:00",
        timeOfPayout: "Time of payout",
        info: "I am aware and confirm that in order to assess my creditworthiness and credit risk assessment, the bank will conduct an inquiry in the SISBON system."
      },
      reservations: {
        "reservationDate":"Reservation date",
        "transactionType":"Transaction type",
        "noData": "No reservations data",
        "type": "Type",
        "originAmount": "Original amount"
      },
      transactionDivision: {
        "Cancelled": "Cancelled",
        "Processed": "Succesfully processed",
        "Rejected": "Rejected",
        "Opened": "Open",
        "InProcessing": "In process",
        "Signed": "Signed",
        "WaitingQueue": "Waiting to be processed",
        "title": "Transaction division",
        "creationDate": "Creation date",
        "fullName": "Full name",
        "successMessageCro":"Uspješno ste zadali nalog za podjelu transakcije „opis transakcije“ u iznosu od {{value1}} EUR na {{value2}} rata. Podjela na rate bit će izvršena u roku od 48h.",
        "jmbg": "JMBG",
        "transactionId":"Transaction ID",
        "reservationNumber":"Reservation number",
        "transactionDate": "Transaction date",
        "amount": "Amount",
        "numOfInstallment": "Select number of installment",
        "sellingPlace": "Name of selling place",
        "lastDigits": "Last 4 digits of the card",
        "sellingPlaceError": "Selling place is required!",
        "lastDigitsError": "Last 4 digits of the card are required!",
        "lastDigitsLengthError": "Number must have 4 digits!",
        "yourTransDiv": "Your transaction division",
        "transLocation": "Transaction location",
        "cardNumber": "Card number",
        "status": "Status",
        "creatorName": "Creator name",
        "installmentNumber": "Installment number",
        "installmentAmount": "Installment amount",
        "fee": "Transaction division fee",
        "cancel":"Cancel",
        "confirm":"Confirm",
        "successMessage":"Request successfully submitted.",
        "finalStepStatus":"Status:",
        "finalStepId":"TransactionID:",
        "finalStepSentForProcessing":"Transaction was sent to bank for processing.",
        "finalStepSentForProcessingV2":"Podela na rate se tarifira u skladu sa Tarifnikom.",
        "makeAnotherPayment":"Make another transaction",
        "backToTransactions":"Back to transactions",
        "Opened":"Opened",
        "Rejected":"Rejected",
        "WaitingQueue":"WaitingQueue",
        "Processed":"Successfully processed",
        "InProcessing":"In process",
        "installmentInfo": "Number of installment",
        "feeInfo": "Naknada za obročnu otplatu transakcija kupovine ili podizanja gotovine podijeljenih na rate naplaćuje se sukladno važećim Naknadama za usluge Addiko Bank. Ukoliko ste navedenu transakciju već podijelili na rate pozivom u Kontakt Centar ili putem Addiko internetskog ili mobilnog bankarstva, molimo da ju ne dijelite ponovno.",
        "infoIfLastDay": "Podelu na rate poslednjeg dana u mesecu možete izvršiti pozivom na broj telefona 0800 303 303, radnim danom i subotom od 08 do 20 i nedeljom od 09 do 14 časova. Vaša Addiko Banka"
      },
      changeWithdrawal: {
        "header":"Change personal account overdraft",
        "infoNote1":" The Bank will process your order in the next few days. You will be notified of the progress by your client manager within eight working days. Overdraft approval costs shall be charged according to the valid ",
        "infoNote2":"",
        "infoNote3":"Pricelist of Addiko Bank d.d.",
        "fromAccount":"For account",
        "amount":"Desired overdraft amount",
        "time":"For period",
        "spremembo":"I want the change to take effect",
        "cancelBtnLabel":"Cancel",
        "confirmBtnLabel":"Submit",
        "amountRequired": "Amount required",
        "6months":"6 Months",
        "12months":"12 Months",
        "option1":"Immediately",
        "option2":"After the expiry of the existing authorized overdraft"
      },
      reorderPinAccounts: {
        "header":"PIN Reorder for current account card",
        "infoNote1": "PIN reorder for selected current account card will be done no later than the next business day.",
        "infoNote2" : "PIN reorder costs shall be charged according to the valid ",
        "infoLink" : "Pricelist of Addiko Bank d.d.",
        "additionalText" : "If you decide to receive PIN via SMS, send a text consisting of the keyword ABSPIN, your tax number and the last 4 digits of your card to phone number +386 30 400 405: ABSPIN XXXXXXXX XXXX. In response you will receive a 4-digit PIN number for your card from the sender \"Addiko Bank\".",
        "nameSurname": "Name and surname",
        "desiredAmount": "Desired amount (in EUR)",
        "pinReceiptMethod": "PIN receipt method",
        "for": "For",
        "fromAccount":"For account",
        "nameSurnameRequired":"Name and surname are required!",
        "cardType": "Debit card type"
      },
      changeATMWithdrawal: {
        "header": "Change of daily limit",
        "infoNote1": "The Bank will process your order in the next few days. You will be notified of the progress of your order by your client manager.",
        "nameSurname": "Name and surname",
        "desiredAmount": "Desired amount (in EUR)",
        "for": "For",
        "nameSurnameRequired":"Name and surname are required!",
        "cardType": "Debit card type"
      },
      savingsSlo: {
        "title":"Open Savings account",
        "infoNote1":"Info 1",
        "infoNote2":"Info 2",
        "infoNote3":"Info 3",
        "vrstaVracevanja":"Vrsta varcevanja",
        "durationInMonths":"Duration in months",
        "maturityDate":"Matrurity date",
        "transfersFromAndToAccount":"Transfers from and to account",
        "initialAmountFromAccount":"Initial amount from account",
        "initialAmount":"Initial account",
        "montlyDepositAmount":"Monthly deposit amount",
        "standingOrderDay":"Standing order day",
        "realInterestRate":"Real interest day",
        "totalInterestRate":"Total interest day",
        "termsAndCondtionsInfo":"I certify that I am aware of Gerenal term and conditions of savings and I agree with them.",
        "info":"Stringam se, da v primeru, če na dogovrjeni....",
        "cancel":"Cancel",
        "confirm":"Confirm"

      },
      masterCard: {
        "header":"Information on the possibility to obtain Mastercard",
        "info":"The bank will process your order to obtain a Mastercard in the next few days. You will be notified of the progress of your order by your client manager. Costs shall be charged according to the valid ",
        "infoPrice":"Addiko Bank d.d. Pricelist",
        "infoLink0":"More information about Addiko Instalment Mastercard",
        "infoLink1":"More information about Addiko Charge Mastercard",
        "infoLink2":"More information about Addiko Gold Mastercard",
        "infoLink3":"More information about Addiko Revolving Mastercard",
        "cardType":"Credit card type",
        "creditLimit":"Desired limit amount",
        "account":"Account",
        "paymentDay":"Payment day",
        "cancel":"Cancel",
        "confirm":"Confirm",
        "amount":"Desired limit amount",
        cardTypes: {
          "charge":"Addiko Charge Mastercard",
          "gold":"Addiko Gold Mastercard",
          "revolving":"Addiko Revolving Mastercard"
        },
        "percentType":"Percentage of used limit to be paid monthly"
      },
      masterCardOrder: {
        "orderLimit":"Change limit",
        "reorderPIN":"PIN Reorder",
        "header":"Information on the possibility to change Mastercard limit",
        "info1": "The limit on your Mastercard can be granted for an unlimited duration or for a specific time period. You can only change the limit on your own card account. You will be notified of the progress of your order by your client manager within eight working days. Approval costs shall be charged according to the valid",
        "infoLink": "Pricelist of Addiko Bank d.d.",
        "additionalText" : "If you decide to receive PIN via SMS, send a text consisting of the keyword ABSPIN, your tax number and the last 4 digits of your card to phone number +386 30 400 405: ABSPIN XXXXXXXX XXXX. In response you will receive a 4-digit PIN number for your card from the sender \"Addiko Bank\".",
        "info2":"",
        "cardAccount":"Card account",
        "cardType":"Card type",
        "cardNumber":"Credit card number",
        "desiredLimit":"Desired limit amount",
        "cancel":"Cancel",
        "confirm":"Confirm",
        "amount":"Amount",
        "limitType":"Limit type",
        "dateFrom":"From",
        "dateTo":"To",
        "percentage":"Precentage of the limit of the general card",
        "additional":"Additional",
        "basic":"General"
      },
      reorderPinMasterCard: {
        "orderLimit":"Change limit",
        "reorderPIN":"PIN Reorder",
        "header":"PIN Reorder for Mastercard card",
        "info1": "PIN reorder for selected Mastercard card will be done no later than the next business day.",
        "infoLink": "Pricelist of Addiko Bank d.d.",
        "info2":"PIN reorder costs shall be charged according to the valid",
        "additionalText": "If you decide to receive PIN via SMS, send a text consisting of the keyword ABSPIN, your tax number and the last 4 digits of your card to phone number +386 30 400 405: ABSPIN XXXXXXXX XXXX. In response you will receive a 4-digit PIN number for your card from the sender \"Addiko Bank\".",
        "cardAccount":"Card account",
        "cardType":"Card type",
        "cardNumber":"Credit card number",
        "desiredLimit":"Desired limit amount",
        "pinReceiptMode":"PIN receipt method",
        "cancel":"Cancel",
        "confirm":"Confirm",
        "amount":"Amount",
        "limitType":"Limit type",
        "dateFrom":"From",
        "dateTo":"To",
        "percentage":"Precentage of the limit of the general card",
        "additional":"Additional",
        "basic":"General"
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
