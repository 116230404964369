(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name paymentTemplates.controller:PaymentTemplatesCtrl
   *
   * @description
   *
   */
  angular
    .module('paymentTemplates')
    .controller('PaymentTemplatesCtrl', PaymentTemplatesCtrl);

  function PaymentTemplatesCtrl() {
    var vm = this;
    vm.ctrlName = 'PaymentTemplatesCtrl';
  }
}());
