(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            settings: {
                "againPassError": "Molimo Vas da potvrdite unos nove lozinke.",
                "title": "Podešavanja",
                "tabAccount": "NA",
                "tabNotifications": "NA",
                "tabSecurity": "NA",
                "contactInfo": "Vaši kontaktni podaci",
                "contactInfoBtn": "Promijeni",
                "contactInfoConfirmBtn": "Potvrdi",
                "contactInfoCancelBtn": "Otkaži",
                "contactInfoName": "Ime",
                "contactInfoAddress": "Adresa",
                "contactInfoPhone": "Telefon",
                "blocades": "Blokade",
                "blockLogin": "Blokiranje logiranja u internet bankarstvo",
                "blockMobile": "Blokiranje logiranja u mobilno bankarstvo",
                "blockPayment": "Blokiranje kartice za plaćanje",
                "accountSettings": "Postavke računa",
                "defaultAccount": "Podrazumijevani račun",
                "statementsDelivery": "Dostava izvoda",
                "statementsDeliveryPaper": "Dostava izvoda na kućnu adresu",
                "statementsDeliveryElectronic": "Dostava izvoda u putem internet bankarstva",
                "messagesSettings": "Postavke poruka",
                "messagesSettingsLabel": "Označite ako želite da sve poruke od banke primate i na email",
                "securitySettings": "Sigurnosne postavke",
                "transactionsConfirmation": "Potvrda o transakcijama",
                "transactionsConfirmationSMS": "Slanjem SMS-a na mobitel",
                "transactionsConfirmationOTP": "Generisanjem jednokratne zaporke",
                'forceNewPassword': 'Vaša lozinka je istekla, molimo da unesete novu lozinku.',
                'passwordWillExpire': 'Vaša lozinka uskoro ističe. Potrebno je da obnovite/unesete novu lozinku.',
                "requiredOldPassword": "Potrebno je da unesete staru lozinku!",
                "notificationSettings": "Postavke obaviještenja",
                "gsm": "GSM",
                "required": "Obavezan unos",
                "oldPass": "Stara lozinka",
                "newPass": "Nova lozinka",
                "requiredNewPassword": "Molimo Vas da unesete novu lozinku.",
                "againPass": "Potvrdi novu lozinku",
                "noMatch": "Nova lozinka i Potvrda nove lozinke ne sadrže iste vrijednosti.",
                "change": "Promijeni",
                "lengthError": " Morate unijeti najmanje {{value}} znakova u polje Nova lozinka!",
                "lengthMaxError": "Lozinka može da sadrži najviše {{value}} znakova.",
                "lengthOldError": "Morate unijeti najmanje {{value}} znakova u polje Stara lozinka!",

            }
        };

        $translateProvider.translations('mne', data);

    }
}());
