<section id="main">
    <div class="container">
        <div class="row main-row">
            <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
                <h2 translate="employee.header" class="title-style"></h2>
            </div>
            <div class="timeline-row col-sm-12">

                <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">

                    <ul class="hypo-timeline" kcd-recompile="employeePayroll.timelineListGrouped">

                        <div class="t-a-c p-10 no-data-image" ng-if="employeePayroll.timelineListGrouped.result.length == 0">
                            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                            <h3 class="no-data-label" translate="employee.noTimelineData"></h3>
                        </div>

                        <li data-ng-repeat="tlItem in ::employeePayroll.timelineListGrouped.result" ng-class="{'time-label': tlItem.isVirtual === true}">

                            <span ng-if="tlItem.isVirtual === true" class="salary-date">{{tlItem.date}}</span>


                            <i ng-if="tlItem.isVirtual != true" class="icon icon-salary salary-right"></i>

                            <div ng-if="tlItem.isVirtual == undefined" class="hypo-timeline-item msg-item">
                                <div class="bubble message">
                                    <div class="msg-label no-bottom-margin"><span>{{tlItem.customDate | date: 'MMM' }} - </span><span>{{::tlItem.id}}</span>
                                        <i class="icon icon-acrobat pdf-icon"></i>
                                        <a type="text" translate="employee.downloadPdf" class="pull-right " style="padding: 2px 5px;" data-ng-click="employeePayroll.downloadPdf(tlItem.id);"><i class="icon icon-acrobat" ></i>Download PDF</a>

                                    </div>

                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                <div class="show-more-btn ">
                    <button ng-hide="employeePayroll.timelineListGrouped.hasMore == false" data-ng-click="employeePayroll.loadMoreTimelineItemsGrouped()" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">Load older events</button>
                </div>
            </div>
        </div>
    </div>
</section>