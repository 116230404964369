(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name statements.factory:StatementsService
   *
   * @description
   *
   */
  angular
    .module('statements')
    .factory('StatementsService', StatementsService);

  function StatementsService($localStorage, EndpointsService,$rootScope, $q, $http, $log, $window, HelperService, NotificationService) {
    var StatementsServiceBase = {};
    StatementsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.statements, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    // Old method for download statements
/*    StatementsServiceBase.downloadPdf = function (statementId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        var link = response.links.statements_id_download.replace("{id}", statementId);
        $window.location.assign(link, {
          statementId: statementId
        });
      });
    };*/

    // New method for statements download...
    StatementsServiceBase.getStatementPdfLink = function (statementId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.statements_downloadLink_id.replace('{id}', statementId))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    StatementsServiceBase.downloadPdf = function(statementId){
      var nonBlockingWindow = window.open('','_self');
      return StatementsServiceBase.getStatementPdfLink(statementId)
        .then(function (pdfLink) {
          //pdfLink = pdfLink.data;
          if(pdfLink){
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        })
        .catch(function (error) {
          // nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    // API provides link for accessing pdf file
    StatementsServiceBase.downloadPdfStatement = function (url) {
      var nonBlockingWindow = window.open('', '_self');
      return $http.get(url)
        .then(function (pdfLink) {
          pdfLink = pdfLink.data;

          if (pdfLink) {
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        }).catch(function (msg, code) {
        nonBlockingWindow.close();
      });
    };

    StatementsServiceBase.getStatementDates = function (accountId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.statements_getStatementDates_id.replace('{id}', accountId))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return StatementsServiceBase;
  }
}());
