<div class="statementModal alert-msg-frame">
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div style="color: white; padding-top: 43px;">
          <!--  <i class="icon icon-add pull-right close-icon-x" ng-click="$close(false)"  style="font-size: 40px;"></i> -->
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-12 col-sm-9 col-xs-10">
        <span class="c-white alert-msg-viber" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'bh'" translate="viber.becomeFollowerAlert"></span>
        <span class="c-white alert-msg-viber" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh'" translate="{{APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka' ? 'viber.becomeFollowerAlertBL' : 'viber.becomeFollowerAlertSA'}}"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close(true)"
            translate="viber.no"></button>
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close(false)"
            translate="viber.yes"></button>
  </div>
</div>
