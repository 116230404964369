(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name viber.controller:ViberDeactivatedCtrl
   *
   * @description
   *
   */
  angular
    .module('viber')
    .controller('ViberDeactivatedCtrl', ViberDeactivatedCtrl);

  function ViberDeactivatedCtrl($rootScope, $localStorage, $state,ViberService, AlertService, HelperService, NotificationService) {
    var vm = this;

    vm.gsm = $rootScope.APP_GLOBAL_SETTINGS.viberData.phoneNumber;
    vm.date = $rootScope.APP_GLOBAL_SETTINGS.viberData.activationDate;

    function deactivate () {
      var userId = $rootScope.APP_GLOBAL_SETTINGS.userSettings.id;
      ViberService.deactivate(userId, {
        deactivateViberUser: true,
        isWebCall: true
      })
        .then(function (res) {
          AlertService.infoAlert({
            text: "viber.activationSuccess"
          })
            .then(function (){
              //after successful deactivation go to dashboard page
              $rootScope.APP_GLOBAL_SETTINGS.isViberActivated = false;
              $state.go('dashboard');
            });
        })
        .catch(function (err) {
          NotificationService.showMessage(err, 'error');
        })
    }

    vm.currentLanguage = $localStorage.appLanguage;
    $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
      vm.currentLanguage = lang.langCode;
    });

    vm.deactivateViber = function () {
      AlertService.viberDeactivateAlert()
        .then(function (response) {
          if(response) {
            if(response) {
              deactivate();
            }
          }
        })
        .catch(function () {

        });
    };

  }
}());
