(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:PaymentFilterService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('PaymentFilterService', PaymentFilterService);

  function PaymentFilterService() {
    var PaymentFilterServiceBase = {};
    PaymentFilterServiceBase.filterFromAccountSelect = function (accountList, accountId){
      var accountIndex = _.findIndex(accountList, function (item){
        return accountId == item.accountId;
      })
      if(accountIndex == -1){
        return accountList[0];
      }
      return accountList[accountIndex];
    };

    PaymentFilterServiceBase.filterToAccountSelect = function (accountList, accountNumber){
      var toAccountIndex = _.findIndex(accountList, function (item){
        return accountNumber == item.accountNumber
      });
      if(toAccountIndex == -1){
        return accountList[0];
      }
      return accountList[toAccountIndex];
    };

    PaymentFilterServiceBase.filterToAccountSelectByAccountAndCurrency = function (accountList, accountNumber, currency){
      var toAccountIndex = _.findIndex(accountList, function (item){
        return accountNumber == item.iban && currency == item.currencySymbol;
      });
      if(toAccountIndex == -1){
        return accountList[0];
      }
      return accountList[toAccountIndex];
    };

    PaymentFilterServiceBase.filterToAccountSelectByIBAN = function (accountList, ibanNumber){
      var toAccountIndex = _.findIndex(accountList, function (item){
        return ibanNumber == item.iban
      });
      if(toAccountIndex == -1){
        return accountList[0];
      }
      return accountList[toAccountIndex];
    };

   /* PaymentFilterServiceBase.filterToAccountList = function (accountsFromList, accountId) {
      var toAccountList = _.filter(accountsFromList, function (item) {
        return item.accountId != accountId;
      });
      return toAccountList;
    };*/

    PaymentFilterServiceBase.filterBuyCurrencyInput = function (params) {
      var buyCurrency = {};
      if (params.sellCurrency != params.localCurrency) {
        buyCurrency.symbol = params.localCurrency;
      } else if (params.currencyList.length > 0) {
        buyCurrency = _.find(params.currencyList, function (item) {
          return item.symbol == params.destinationCurrency;
        });
      }
      if (params.localCurrency == 'RSD' || params.localCurrency == 'EUR' || params.localCurrency == 'BAM') {
        buyCurrency.symbol = params.destinationCurrency;
      }
      return buyCurrency;
    };

    PaymentFilterServiceBase.filterPurposeCodeSelect = function (purposeCodeList, purposeCode){
        var purposeCodeIndex = _.findIndex(purposeCodeList, function (item) {
          return purposeCode == item.id;
        });
      if(purposeCodeIndex == -1) {
        return purposeCodeList[0];
      }
      return purposeCodeList[purposeCodeIndex];
    };

    PaymentFilterServiceBase.filterReferenceModelSelect = function (referenceModelList, modelNumber) {
      var subNumber = modelNumber.substring(0, 2);
      var referenceModelIndex = _.findIndex(referenceModelList, function (item) {
        return subNumber == item;
      });
      if (referenceModelIndex == -1) {
        return referenceModelList[0];
      }
      return referenceModelList[referenceModelIndex];
    };

    return PaymentFilterServiceBase;
  }
}());
