(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name paymentTemplates.controller:PaymentNewTemplateCtrl
   *
   * @description
   *
   */
  angular
    .module('paymentTemplates')
    .controller('PaymentNewTemplateCtrl', PaymentNewTemplateCtrl);

  function PaymentNewTemplateCtrl($rootScope, $state, $log, PaymentsService, PaymentTemplatesService, ImagesService, HelperService,
                                   $translate, AlertService, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;

    vm.init = function () {

      vm.paymentType = $state.params.type;
      vm.templateNameInput = '';
      vm.selectedImage = {};
      vm.templateImagesFilter = {
        category: "Templates"
      };
      vm.countryCode =  $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

      loadImages();

      if ($state.params.paymentObject) {
        vm.paymentObject = $state.params.paymentObject;
      } else {
        if ($state.params.paymentId) {
          loadPayment($state.params.paymentId);
        }
      }

    };

    var loadImages = function () {
      ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        console.log(JSON.stringify(images));
        if(images){
          vm.images = images.result;

          // Set selected image as default image (first from list)
          vm.selectedImage = vm.images[0];

        }
      }).catch(function (err) {
        $log.error(err);
      });
    };

    var loadPayment = function (paymentId) {
      if (paymentId) {
        if(vm.countryCode === 'bh') {
          PaymentsService.getById(paymentId, {includeList: ['RevenuePaymentDetails']}).then(function (payment) {
            vm.paymentObject = payment;
          }).catch(function (error) {
            $log.error(error);
          });
        } else {
          PaymentsService.getById(paymentId).then(function (payment) {
            vm.paymentObject = payment;
          }).catch(function (error) {
            $log.error(error);
          });
        }
      }
    };

    vm.selectImage = function (image) {
      if (image) {
        vm.selectedImage = image;
      }
    };

    vm.saveAsTemplate = function () {
      /*      if (!vm.templateForm.$valid) {
       return;
       }*/

      if(!vm.formSubmitted){
        vm.formSubmitted = true;
        var objectForInsert = prepareObjectForInsert();

        PaymentTemplatesService.insertTemplate(objectForInsert)
          .then(function (response) {
            vm.formSubmitted = false;
            $log.debug("Insert payment template response", response);
            //$state.go('paymentTemplates.templateListOverview');
            //var title = $translate.instant('payments.templateSavedSuccessfully');
            //swal(title);
            AlertService.infoAlert({
              text: 'alert.saveTemplate'
            })
              .then(function (){
                $state.go('paymentTemplates.templateListOverview');
              })

            GoogleTagManagerHelperService.pushEvent('NewTemplate', 'SaveNewTemplate', $translate.instant("alert.saveTemplate"));

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
            vm.formSubmitted = false;
          });
      }
    };

    var prepareObjectForInsert = function () {
      var objForInsert = {
        name: vm.templateNameInput,
        accountId: vm.paymentObject.accountId,
        fromAccountNumber: vm.paymentObject.fromAccountNumber,
        toAccountNumber: vm.paymentObject.toAccountNumber,
        amount: vm.paymentObject.amount,
        currencySymbol: vm.paymentObject.currencySymbol,
        purpose: vm.paymentObject.purpose,
        paymentTypeGroup: vm.paymentObject.paymentTypeGroup,
        purposeCodeDPS: vm.paymentObject.purposeCodeDPS
      };

      if(!_.isEmpty(vm.selectedImage)){
        objForInsert.imageId = vm.selectedImage.id;
      }

      var subProperties = {
        receiverName: vm.paymentObject.receiverName,
        receiverAddress1: vm.paymentObject.receiverAddress1,
        receiverCity: vm.paymentObject.receiverCity,
        destinationCurrencySymbol: vm.paymentObject.destinationCurrencySymbol
      };

      if(vm.countryCode != 'bh') {
        subProperties.referenceFrom = vm.paymentObject.referenceFrom || '99';
      }

      var sepaProperties = {};
      if (vm.paymentObject.debtorName || vm.paymentObject.sepaUpnIdentificators) sepaProperties.debtorName = vm.paymentObject.debtorName || vm.paymentObject.sepaUpnIdentificators.debtorName;
      if (vm.paymentObject.debtorName2 || vm.paymentObject.sepaUpnIdentificators) sepaProperties.debtorName2 = vm.paymentObject.debtorName2 || vm.paymentObject.sepaUpnIdentificators.debtorName2;
      if (vm.paymentObject.creditorName || vm.paymentObject.sepaUpnIdentificators) sepaProperties.creditorName = vm.paymentObject.creditorName || vm.paymentObject.sepaUpnIdentificators.creditorName;
      if (vm.paymentObject.creditorName2 || vm.paymentObject.sepaUpnIdentificators) sepaProperties.creditorName2 = vm.paymentObject.creditorName2 || vm.paymentObject.sepaUpnIdentificators.creditorName2;

      var toAccount = vm.paymentObject.toAccountNumber.split(" ");

      if (vm.paymentObject.referenceTo) {
        subProperties.referenceTo = vm.paymentObject.referenceTo || '99';
      }

      if (vm.paymentObject.senderInfo) {
        subProperties.senderName = vm.paymentObject.senderInfo.senderName;
        subProperties.senderAddress1 = vm.paymentObject.senderInfo.senderAddress1;
        subProperties.senderCity = vm.paymentObject.senderInfo.senderCity;
      }
      switch (vm.paymentObject.paymentTypeGroup) {
        case "DomesticPayment":
        case "SEPAPayment":

            objForInsert.domesticPayment = subProperties;
            if(vm.countryCode != 'bh') {
              objForInsert.sepaUpnIdentificators = sepaProperties;
            }


          break;
        case "ForeignPayment":
          objForInsert.foreignPayment = subProperties;
          objForInsert.foreignPayment.phone = vm.paymentObject.phone;
          objForInsert.foreignPayment.email = vm.paymentObject.email;
          objForInsert.foreignPayment.beneficiaryBankName = vm.paymentObject.beneficiaryBankName;
          objForInsert.foreignPayment.beneficiaryBankAddress = vm.paymentObject.beneficiaryBankAddress;
          objForInsert.foreignPayment.beneficiaryBankCity = vm.paymentObject.beneficiaryBankCity;
          objForInsert.foreignPayment.beneficiarySWIFTCode = vm.paymentObject.beneficiarySWIFTCode;
          objForInsert.foreignPayment.beneficiaryBankCountryCode = vm.paymentObject.beneficiaryBankCountryCode;
          objForInsert.foreignPayment.receiverCountryCode = vm.paymentObject.receiverCountryCode;
          objForInsert.foreignPayment.paymentCharges = vm.paymentObject.paymentCharges;
          objForInsert.foreignPayment.payeeLegalForm = vm.paymentObject.payeeLegalForm;
          objForInsert.foreignPayment.chargeAccount = vm.paymentObject.chargeAccount;
          objForInsert.foreignPayment.reference = vm.paymentObject.referenceNumber;
          objForInsert.foreignPayment.modalityCode = vm.paymentObject.modalityCode;
          //   objForInsert.foreignPayment.paymentType = vm.paymentObject.formType;
          break;
        case "CurrencyExchange":
          objForInsert.currencyExchange = subProperties;
          if(toAccount[0]){
            objForInsert.toAccountNumber = toAccount[0];
          }
              break;
        case "RevenuePayment":
          subProperties.budgetOrganization = vm.paymentObject.revenuePaymentDetails.budgetOrganization;
          subProperties.municipality = vm.paymentObject.revenuePaymentDetails.municipality;
          subProperties.priority = vm.paymentObject.revenuePaymentDetails.priority;
          subProperties.revenueCode = vm.paymentObject.revenuePaymentDetails.revenueCode;
          subProperties.taxPayerId = vm.paymentObject.revenuePaymentDetails.taxPayerId;
          subProperties.taxPeriodFrom = new Date(vm.paymentObject.revenuePaymentDetails.taxPeriodFrom);
          subProperties.taxPeriodTo = new Date(vm.paymentObject.revenuePaymentDetails.taxPeriodTo);
          if(vm.paymentObject.revenuePaymentDetails.reference) {
            subProperties.reference = vm.paymentObject.revenuePaymentDetails.reference;
          }
          objForInsert.domesticPayment = subProperties;
          break;
      }
      return objForInsert;
    };

    vm.init();
  }
}());
