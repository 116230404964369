<div class="modal-header">
  <h3 class="modal-title" id="modal-title" translate="paymentsOverview.orderPaymentConfirmation"></h3>
</div>
<ng-form name="orderPaymentForm">
  <div class="modal-body" id="modal-body">

    <div class="row">
      <div class="col-md-12">
        <table class="table table-transparent table-responsive">
          <tbody>
          <tr>
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlId"></span>
            </td>
            <td>
              <span class="po-value-label">{{::vm.payment.id}}</span>
            </td>
          </tr>
          <tr ng-if="::vm.payment.referenceNumber">
            <td>
              <span class="po-desc-label" translate="paymentsOverview.referenceNumber"></span>
            </td>
            <td>
              <span class="po-value-label">{{::vm.payment.referenceNumber}}</span>
            </td>
          </tr>
          <tr ng-if="::vm.payment.receiverName">
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlReceiver"></span>
            </td>
            <td>
              <span class="po-value-label">{{::vm.payment.receiverName}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlFrom"></span>
            </td>
            <td>
              <span class="po-value-label">{{::vm.payment.fromAccountNumber}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlTo"></span>
            </td>
            <td>
              <span class="po-value-label">{{::vm.payment.toAccountNumber}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlAmount"></span>
            </td>
            <td>
              <span class="po-value-label" ng-if="::vm.payment.transactionAmount2">{{::vm.payment.transactionAmount2|number:2}} {{::vm.payment.destinationCurrencySymbol}}</span>
              <span class="po-value-label" ng-if="::!vm.payment.transactionAmount2">{{::vm.payment.amount|number:2}} {{::vm.payment.currencySymbol}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="po-desc-label" translate="signPayments.dateCreated"></span>
            </td>
            <td>
              <span class="po-value-label">{{vm.payment.dateCreated| date:'shortDate' : '+0200'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="po-desc-label" translate="paymentsOverview.povlDueDate"></span>
            </td>
            <td>
              <span class="po-value-label">{{vm.payment.dueDate| date:'shortDate' : '+0200'}}</span>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12 p-m-15">
        <h3 class="capitalize" translate="paymentsOverview.recepientInfo">RECIPIENT INFORMATION</h3>
      </div>
    </div>

    <div class="row m-b-20">
      <div class="col-md-4">
        <span class="capitalize" translate="paymentsOverview.receiveConfirmation">I would like to receive the confirmation to the following address</span>:
      </div>
      <div class="col-md-6">
        <textarea name="address" ng-model="vm.model.address" class="form-control statement-confirm-textarea"
                  minlength="5" ng-min="5" maxlength="720" required
                  style="border-radius: 7px;border-style:none; border-style: inset !important;">
        </textarea>
        <span class="c-red" ng-show="orderPaymentForm.address.$error.minlength" translate="paymentsOverview.minLength5"></span>
      </div>
    </div>
    <div class="row m-b-20">
      <div class="col-md-4">
        <span class="capitalize" translate="paymentsOverview.orderComment"></span>:
      </div>
      <div class="col-md-6">
        <input type="text" name="note" ng-model="vm.model.note" maxlength="50" class="form-control"/>
      </div>
    </div>
    <div class="row m-b-20">
      <div class="col-md-12">
        <span translate="paymentsOverview.orderPaymentConfMsg1"></span>
        <a href="https://www.addiko.si/ceniki " target="_blank" class="underline" translate="paymentsOverview.orderPaymentConfMsg2"></a>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button class="btn btn-default pull-right" type="button" ng-click="vm.ok(orderPaymentForm)" translate="paymentsOverview.orderConfirmationBtn"
            ng-disabled="orderPaymentForm.$invalid || vm.confirmBtnDisabled">NEXT
    </button>
    <button class="btn btn-warning pull-left" type="button" ng-click="vm.cancel()" translate="payments.cancel">CANCEL
    </button>
  </div>
</ng-form>
