<div id="primaryNavigationLarge" class="hidden-xs primary-navigation">

    <ul class="primary-navigation-list p-t-10">
        <li data-ui-sref="dashboard" data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Dashboard')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon i-p24 icon-home"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.dashboard"></p>
                    </div>
                </div>
            </a>
        </li>
        <li ng-class="{'selected': navigation.currentNavigationItem === 'Accounts'}" data-ng-click="navigation.toggleSecondaryNavigation('Accounts')">
            <a class="visible-md visible-lg">
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon i-p24 icon-card"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.accountsAndCards"></p>
                    </div>
                </div>
            </a>
            <a class="visible-sm">
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon i-p24 icon-payment"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.accounts"></p>
                    </div>
                </div>
            </a>
        </li>
        <li ng-class="{'selected': navigation.currentNavigationItem === 'Payments'}" data-ng-click="navigation.toggleSecondaryNavigation('Payments')">
            <a>
                <div class="nav-item-wrapper payments-nav-wrapper">
                    <div class="ni-icon">
                        <i class="icon i-p24 icon-movem"></i>
                    </div>
                    <div class="ni-label notifications-label">
                        <p translate="navigation.payments"></p>
                    </div>
                    <div class="pull-right visible-md visible-lg">
                      <div class="dn-item-bubble"
                           ng-class="{'dn-item-bubble-active': navigation.isSecondaryNavigationOpen && navigation.currentNavigationItem === 'Payments'}"
                           popover-append-to-body="true"
                           uib-popover-template="'navigation/notifications-popover-template.tpl.html'"
                           popover-trigger="'mouseenter'">{{navigation.totalNotificationsCount}}</div>
                    </div>
                </div>
            </a>
        </li>

      <li ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mn'"
          ng-class="{'selected': navigation.currentNavigationItem === 'Viber'}"
          data-ng-click="navigation.checkUserViberActivation()">
        <a>
          <div class="nav-item-wrapper payments-nav-wrapper">
            <div class="ni-icon">
              <i class="icon i-p24 icon-chat"></i>
            </div>
            <div class="ni-label notifications-label">
              <p translate="navigation.viberBanking"></p>
            </div>
          </div>
        </a>
      </li>


      <li ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'N'"
          ng-class="{'selected': navigation.currentNavigationItem === 'Deposit'}" data-ng-click="navigation.toggleSecondaryNavigation('Deposit')">
        <a>
          <div class="nav-item-wrapper payments-nav-wrapper">
            <div class="ni-icon">
              <i class="icon i-p24 icon-savings"></i>
            </div>
            <div class="ni-label notifications-label">
              <p ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'" translate="navigation.allDeposits"></p>
              <p ng-if="navigation.countryCode == 'sr' || navigation.countryCode == 'mne'" translate="navigation.allDepositOverviewSerbMne"></p>
            </div>
          </div>
        </a>
      </li>
        <li ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'N'"
            ng-class="{'selected': navigation.currentNavigationItem === 'Loans'}" data-ng-click="navigation.toggleSecondaryNavigation('Loans')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon i-p24 icon-loan"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.loans"></p>
                    </div>
                </div>
            </a>
        </li>
        <!--<li ng-class="{'selected': navigation.currentNavigationItem === 'Settings'}" data-ng-click="navigation.toggleSecondaryNavigation('Settings')">-->
        <li ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'"
                data-ng-click="navigation.toggleSecondaryNavigation('SettingsHR')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon i-p24 icon-settings"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.settings"></p>
                    </div>
                </div>
            </a>
        </li>
        <li ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'"
                data-ui-sref="settings"
                data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Settings')">
                <a>
                    <div class="nav-item-wrapper">
                        <div class="ni-icon"><i class="icon i-p24 icon-settings"></i></div>
                        <div class="ni-label">
                            <p translate="navigation.settings"></p>
                        </div>
                    </div>
                </a>
            </li>
        <li data-ui-sref="messages" data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Messages')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon i-p24 icon-message"></i>
                    </div>
                    <div class="ni-label notifications-label">
                        <p translate="navigation.messages"></p>
                    </div>
                    <div class="pull-right visible-md visible-lg">
                      <div class="dn-item-bubble"
                           popover-append-to-body="true"
                           uib-popover="{{'navigation.newMessages' | translate}}{{navigation.unreadMessagesNotification}}"
                           popover-trigger="'mouseenter'">{{navigation.unreadMessagesNotification}}
                      </div>
                    </div>
                </div>
            </a>
        </li>
        <li data-ui-sref="employeePayroll" data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('EmployeePayroll')" ng-if="navigation.links.employeePayrolls && (APP_GLOBAL_SETTINGS.userSettings.hasOwnProperty('employee') && APP_GLOBAL_SETTINGS.userSettings.employee != '0')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon i-p24 icon-employee"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.employeePayroll"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ui-sref="documents" data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Documents')" 
            ng-if="navigation.countryCode === 'sl' || navigation.countryCode == 'hr'">
        <a>
          <div class="nav-item-wrapper">
            <div class="ni-icon">
              <i class="icon i-p24 icon-employee"></i>
            </div>
            <div class="ni-label notifications-label">
              <p translate="navigation.documents"></p>
            </div>
            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && navigation.unreadDocumentsNotifications > 0" class="pull-right visible-md visible-lg">
              <div class="dn-item-bubble"
                   popover-append-to-body="true"
                   uib-popover="{{'navigation.unreadDocuments' | translate}}{{navigation.unreadDocumentsNotifications}}"
                   popover-trigger="'mouseenter'">{{navigation.unreadDocumentsNotifications}}
              </div>
            </div>
          </div>
        </a>
      </li>
        <li data-ui-sref="secure3d"  data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('3DSecure')"
            ng-if="navigation.countryCode === 'hr' && APP_GLOBAL_SETTINGS.defaultData.Show3DSecure === 'true'">
          <a>
            <div class="nav-item-wrapper">
              <div class="ni-icon" style="padding: 1px;"><i class="icon i-p24 icon-info" ></i></div>
              <div class="ni-label">
                <p translate="navigation.secure3d"></p>
              </div>
            </div>
          </a>
        </li>
        <!-- <li data-ui-sref="complaints" data-ng-click="navigation.closeSecondaryNavigation();"
            ng-if="navigation.countryCode === 'hr'">
            <a>
                <div class="nav-item-wrapper position-relative">
                  <span ng-if="navigation.complaintsHasUnreadMessages" class="badge-dot"></span>
                  <div class="ni-icon icon-complaint" style="padding: 1px;">
                  </div>
                  <div class="ni-label">
                    <p translate="navigation.complaints"></p>
                  </div>
                </div>
              </a>
        </li> -->
        <li data-ng-click="navigation.closeSecondaryNavigation();"
            ng-if="navigation.countryCode === 'sr'">
            <a class="cursor-pointer" ng-click="navigation.toggleContactSubMenu()">
                <div class="nav-item-wrapper position-relative flex-row-laptop flex-column-tablet" >
                  <div class="icon-contactbook mr-3-laptop mb-3-tablet"></div>
                  <div class="d-flex align-items-center flex-column-xs">
                    <div class="ni-label">
                      <p class="m-0 mr-1" translate="navigation.contact"></p>
                    </div>
                    <i ng-hide="!navigation.contactSubMenuShown"style="font-size: 28px;" class="zmdi zmdi-chevron-up zmdi-hc m-r-0"></i>
                    <i ng-hide="navigation.contactSubMenuShown" style="font-size: 28px;" class="zmdi zmdi-chevron-down zmdi-hc m-r-0"></i>
                  </div>
                </div>
              </a>
              <div ng-if="navigation.contactSubMenuShown">
                <navigation-card-item data-ui-sref="location" label="navigation.defaultData.BankName" icon="'icon-location'" 
                disable="true" description="navigation.defaultData.BankAddressDetail.replace(',', ', <br>')"></navigation-card-item>
                <navigation-card-item label="navigation.defaultData.LocalCall" prefix="tel" icon="'icon-mobilephone'"></navigation-card-item>
                <navigation-card-item label="navigation.defaultData.InternationalCall" prefix="tel" icon="'icon-phone'"></navigation-card-item>
                <navigation-card-item label="navigation.defaultData.infoEmail" prefix="mailto" icon="'icon-email'"></navigation-card-item>
                <navigation-card-item label="navigation.defaultData.WebSite" icon="'icon-website'"></navigation-card-item>
                <navigation-card-item label="'navigation.complaintsLink'" link="navigation.defaultData.ComplaintsLink" icon="'icon-complaints-link'"></navigation-card-item>
              </div>
        </li>

    </ul>
</div>

<div id="smallNavigationHeader" class="visible-xs">

    <div class="small-navigation-header" ng-show="navigation.isMobilePrimaryNavigationOpen">
        <a class="pointer" data-ng-click="navigation.closeMobilePrimaryNavigation()">
            <i class="zmdi zmdi-close zmdi-hc-2x" style="font-size: 26px"></i>
        </a>
        <a class="pointer" ng-click="navigation.logOut()">
            <i class="icon icon-logout pull-right"></i>
        </a>
        <span class="user-info-white pull-right">
      {{navigation.userSettings.result[0].firstName}} {{navigation.userSettings.result[0].lastName}}
    </span>
    </div>

    <div class="small-navigation-header" data-ng-click="navigation.backToPrimaryNavigation()" ng-show="navigation.isMobileSecondaryNavigationOpen">
        <a class=" pointer">
            <i class="small-navigation-header-chevron zmdi zmdi-chevron-left zmdi-hc-2x"></i>
        </a>
    </div>

    <div ng-show="!navigation.isMobilePrimaryNavigationOpen && !navigation.isMobileSecondaryNavigationOpen">
        <a class="navigation-header-icon pointer" data-ng-click="navigation.openPrimaryNavigation()">
            <i class="zmdi zmdi-menu zmdi-hc-2x"></i>
        </a>
    </div>
</div>

<div id="primaryNavigationMobile" class="visible-xs primary-navigation" scrollable ng-if="navigation.isMobilePrimaryNavigationOpen && !navigation.isMobileSecondaryNavigationOpen"
     click-outside="navigation.closeMobileNavigation()" outside-if-not="smallNavigationHeader">
    <ul class="primary-navigation-list p-t-10">
        <li data-ui-sref="dashboard" data-ng-click="navigation.closeMobilePrimaryNavigation(); navigation.pushGtmNavigationEvent('Dashboard')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon icon-home"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.dashboard"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ng-click="navigation.toggleSecondaryNavigation('Accounts', true)">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon-pn icon-account"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.accountsAndCards"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ng-click="navigation.toggleSecondaryNavigation('Payments', true)">
            <a>
                <div class="nav-item-wrapper payments-nav-wrapper">
                    <div class="ni-icon">
                        <i class="icon icon-movem"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.payments"></p>
                    </div>
                </div>
                <!--<button class="badge badge-warning visible-lg pull-right" uib-popover="Test 123" popover-title="Title" popover-trigger="'mouseenter'">5</button>-->
                <!--<span class="badge badge-warning visible-lg pull-right" uib-popover="Test 123" popover-title="Title" popover-trigger="'mouseenter'">5</span>-->
            </a>
        </li>

      <li ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mn'"
          ng-class="{'selected': navigation.currentNavigationItem === 'Viber'}"
          data-ng-click="navigation.checkUserViberActivation()">
        <a>
          <div class="nav-item-wrapper payments-nav-wrapper">
            <div class="ni-icon">
              <i class="icon i-p24 icon-chat"></i>
            </div>
            <div class="ni-label notifications-label">
              <p translate="navigation.viberBanking"></p>
            </div>
          </div>
        </a>
      </li>


      <li data-ng-click="navigation.toggleSecondaryNavigation('Deposit', true)">
        <a>
          <div class="nav-item-wrapper">
            <div class="ni-icon">
              <i class="icon icon-savings"></i>
            </div>
            <div class="ni-label">
             <!-- <p translate="navigation.deposit"></p>-->
               <p ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'" translate="navigation.allDeposits"></p>
              <p ng-if="navigation.countryCode == 'sr' || navigation.countryCode == 'mne'" translate="navigation.allDepositOverviewSerbMne"></p>
            </div>
          </div>
        </a>
      </li>
        <li data-ng-click="navigation.toggleSecondaryNavigation('Loans', true)">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon icon-loan"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.loans"></p>
                    </div>
                </div>
            </a>
        </li>
        
        <!--<li data-ng-click="navigation.toggleSecondaryNavigation('Settings', true)">-->
        <li data-ui-sref="settings"  data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('Settings')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon icon-settings"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.settings"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ui-sref="messages" data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('Messages')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon"><i class="icon icon-message"></i></div>
                    <div class="ni-label">
                        <p translate="navigation.messages"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ui-sref="employeePayroll" data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('EmployeePayroll')" ng-if="navigation.links.employeePayrolls && (APP_GLOBAL_SETTINGS.userSettings.hasOwnProperty('employee') && APP_GLOBAL_SETTINGS.userSettings.employee != '0')">
            <a>
                <div class="nav-item-wrapper">
                    <div class="ni-icon">
                        <i class="icon icon-employee"></i>
                    </div>
                    <div class="ni-label">
                        <p translate="navigation.employeePayroll"></p>
                    </div>
                </div>
            </a>
        </li>
        <li data-ui-sref="documents" data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Documents')" 
                ng-if="navigation.countryCode === 'sl' || navigation.countryCode == 'hr'">
        <a>
          <div class="nav-item-wrapper">
            <div class="ni-icon">
              <i class="icon icon-employee"></i>
            </div>
            <div class="ni-label">
              <p translate="navigation.documents"></p>
            </div>
          </div>
        </a>
      </li>
      <li data-ui-sref="secure3d"  data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('3DSecure')"
          ng-if="navigation.countryCode === 'hr' && APP_GLOBAL_SETTINGS.defaultData.Show3DSecure === 'true'">
        <a>
          <div class="nav-item-wrapper">
            <div class="ni-icon">
              <i class="icon icon-info"></i>
            </div>
            <div class="ni-label">
              <p translate="navigation.secure3d"></p>
            </div>
          </div>
        </a>
      </li>
      <!-- Change language -->
      <li>
        <select class="form-control nav-language-selector" ng-model="navigation.selectedLanguage"
                ng-options="language as language.translate | translate for language in navigation.languageOptions"
                ng-change="navigation.changeLanguage()">
        </select>

      </li>
    </ul>
</div>

