(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EinvoicesIssuersOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EinvoicesIssuersOverviewCtrl', EinvoicesIssuersOverviewCtrl);

  function EinvoicesIssuersOverviewCtrl($state, EinvoicesIssuersOverviewService, $log) {
    var vm = this;
    vm.ctrlName = 'EinvoicesIssuersOverviewCtrl';

    vm.goBack = function () {
      $state.go('eInvoices.einvoicesOverview');
    };

    vm.einvoicesIssuersSearchFilter = {
      searchByName: '',
      searchById: ''
    }


    vm.einvoicesIssuersList = {};
    vm.einvoicesFilter = {
      page: 0,
      pageSize: 1000
    };

    vm.loadMoreIssuers = function () {
      vm.getEinvoicesIssuers(false);
    };

    vm.init = function () {
      vm.getEinvoicesIssuers(true);
    };

    vm.getEinvoicesIssuers = function (reset) {
      if (reset) {
        vm.einvoicesFilter.page = 0;
      } else {
        vm.einvoicesFilter.page += 1;
      }
      EinvoicesIssuersOverviewService.getPage(vm.einvoicesFilter)
        .then(function (response) {
        if (!reset) {
          vm.einvoicesIssuersList.hasMore = response.hasMore;
          vm.einvoicesIssuersList.result = vm.einvoicesIssuersList.result.concat(response.result);
        } else {
          vm.einvoicesIssuersList = response;
        }
        $log.debug("list", vm.einvoicesIssuersList);
      })
    };



    vm.init();
  }
}());
