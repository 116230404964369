<div class="container invoice-card m-b-0">
  <div class="row invoice-card-top pointer-cst" ng-click="isCollapsed1 = !isCollapsed1">
    <div class="col-sm-9 col-xs-7 p-10">
      <div class="invoice-type">
        <b ng-if="registration.issuerName">{{registration.issuerName}}</b>
        <b ng-if="!registration.issuerName">{{registration.issuerServiceName}}</b>
        <span ng-show="false" class="text-muted">{{registration.id}}</span>
      </div>
    </div>
    <div class="col-sm-3 col-xs-4">
      <div class="row">
        <div class="col-sm-9 col-xs-9" ng-class="{'text-danger': registration.serviceState === 'Rejected'}" >
          <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" class="einvoice-status">{{'eInvoiceStatus.'+registration.serviceState | translate}}</span>
          <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="einvoice-status">{{'eInvoiceRegistration.'+registration.serviceState | translate}}</span>
        </div>
        <div class="col-sm-3 col-xs-3">
          <span class="icon icon-arrdown i-c31 cursor-pointer"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row invoice-card-detail" ng-class="{collapse: !isCollapsed1}">
    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuerIBAN"></span></span>
        <label ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="domp-template-text">
             <span ng-switch-default>{{registration.iban}}</span>
             <span ng-switch-when="sl">{{registration.eInvoiceIssuer.iban | formatIban}}</span>
        </label>
        </div>

        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.refNumber"></span></span>
         <label class="domp-template-text">
              {{registration.paymentReferenceNumber}}
            </label>

        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.dateOfReg"></span></span>
              <label class="domp-template-text" ng-if="registration.acceptedDate">{{registration.acceptedDate | date:'shortDate' : '+0200'}}</label>
              <label class="domp-template-text" ng-if="!registration.acceptedDate">-</label>
          </p>
        </div>
        <div class="col-sm-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.serviceName"></span></span>
            <label class="domp-template-text">{{registration.issuerServiceName}}</label>
          </p>
        </div>
        <div class="col-sm-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.orderType"></span></span>
            <label class="domp-template-text" ng-show="registration.serviceType.toLowerCase() == 'registration'" translate="eInvoiceDetail.registration"></label>
            <label class="domp-template-text" ng-show="registration.serviceType.toLowerCase() == 'deregistration'" translate="eInvoiceDetail.deregistration"></label>
          </p>
        </div>
      </div>
      <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <div class="col-sm-6">
          <p ng-if="registration.serviceState === 'Rejected'">
            <span class="invoice-inline-title">
              <span translate="eInvoiceDetail.noteRejected"></span>
            </span>
              <label class="domp-template-text" ng-if="registration.comment">{{registration.comment}}</label>
              <label class="domp-template-text" ng-if="!registration.comment">-</label>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 pull-right text-right">

          <button class="btn btn-primary" ng-if="registration.serviceState === 'Registered'"
                  ng-click="einvoicesOverview.cancelRegistration(registration)" translate="eInvoiceDetail.cancelReg"></button>

          <button class="btn btn-default" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && registration.serviceState === 'Rejected' || registration.serviceState === 'Unregistered'"
                  translate="eInvoiceDetail.regAgain"
                  ng-click="einvoicesOverview.registerAgain(registration)"></button>

          <button class="btn btn-default" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && registration.serviceState === 'Rejected' || registration.serviceState === 'Unregistered'"
                  translate="eInvoiceDetail.regAgainSlo"
                  ng-click="einvoicesOverview.registerAgain(registration)"></button>
        </div>
      </div>
    </div>
  </div>
</div>
