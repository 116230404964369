(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "Storitev 'Addiko Chat Banking' lahko aktivirate v Viber aplikaciji",
        activationWay: "Izberite način aktivacije",
        scanQrCode: "Branje QR kode",
        activationCode: "Aktivacijsko geslo",
        agree: "Sprejemam in soglašam s Splošnimi pogoji za uporabo storitve spletne banke Addiko EBank. S klikom na gumb »Aktiviraj« soglašate, da ste seznanjeni s ",
        agreeLink: "Splošnimi informacijami o varstvu osebnih podatkov Addiko Bank d.d.",
        activate: "Aktiviraj",
        deactivateText: "Vaš uporabniški račun je aktivan",
        deactivate: "Deaktiviraj",
        deactivateModalText: "Ste prepričani, da želite deaktivirati storitev 'Addiko Chat Banking'?",
        cancel: "Prekliči",
        activationSuccess: "Storitev 'Addiko Chat Banking' je uspešno deaktivirana.",
        viberCondition1: "Splošni pogoji za uporabo storitve spletne banke Addiko EBank ",
        viberCondition2: " se nanašajo tudi na storitve, ki se izvajajo preko 'Addiko Chat Bankinga'. Viber uporabniški račun, ki ga boste uporabljali za pristop k tej storitvi Addiko banke, se bo uporabljal v skladu s",
        viberCondition3: "pogoji in politiko aplikacije Viber, ",
        viberCondition4: "za uporabo katere ste že dali soglasje ob prijavi na Viber storitev.",
        scanQrCodeC1: "Z napravo preberite prikazano QR kodo v aplikaciji Viber (možnost 'More', 'QR code scanner').",
        scanQrCodeC2: "Po branju QR kode boste samodejno preusmerjeni na javni Viber uporabniški račun Addiko banke, kjer vam bo na voljo aplikacija 'Addiko chat bot', ki bo od vas zahtevala, da potrdite oziroma pošljete aktivacijsko geslo. Geslo pošljite z Viber chat sporočilom.",
        scanQrCodeC3: "SMS sporočilo z enkratnim geslom boste prejeli na mobilno številko, ki ste jo navedli ob pristopu k spletni oziroma mobilni banki.",
        scanQrCodeC4: "Enkratno geslo iz SMS sporočila vpišite v 'Viber chat' in ga pošljite.",
        scanQrCodeC5: "Določite osebno 4-mestno PIN kodo. Za zaključek procesa aktivacije 'Addiko Chat Bankinga', bo 'Addiko chat bot' od vas zahteval 2 ponovitvi vnosa PIN kode.",
        scanQrCodeC6: "OPOZORILO: PIN kodo boste v prihodnje potrebovali za vstop v storitev 'Addiko Chat Banking' ter za potrjevanje plačilnih nalogov, oddanih preko storitve Viber, zato je pomembno, da si jo zapomnite!",
        activationCodeC1: "Poiščite javni uporabniški račun Addiko banke v storitvi Viber in nas spremljajte! ('Follow' / 'Sledilec')",
        activationCodeC2: "Zaženite chat možnost, ki se nahaja v zgornjem desnem kotu aplikacije Viber.",
        activationCodeC3: "Na dnu zaslona naprave, na kateri uporabljate Viber (mobilni telefon, tablica, računalnik) se nahajajo možnosti za storitev 'Addiko Chat Banking'. Izberite možnost 'Aktiviraj'.",
        activationCodeC4: "Prepišite aktivacijko geslo iz spletne banke Addiko EBank v Viber chat. Pošljite sporočilo z vpisanim aktivacijskim geslom.",
        activationCodeC5: "SMS sporočilo z enkratnim geslom boste prejeli na mobilno številko, ki ste jo navedli ob pristopu k spletni oziroma mobilni banki.",
        activationCodeC6: "Prepišite enkratno geslo iz SMS sporočila v Viber chat in jo pošljite.",
        activationCodeC7: "Določite svojo 4-mestno PIN kodo. Za zaključek procesa aktivacije Addiko Chat Bankinga, bo 'Addiko chat bot' od vas zahteval 2 ponovitvi vnosa PIN kode.",
        activationCodeC8: "OPOZORILO: PIN kodo boste v prihodnje potrebovali za vstop v storitev 'Addiko Chat Banking' ter za potrjevanje plačilnih nalogov, oddanih preko storitve Viber, zato je pomembno, da si jo zapomnite!",
        yourActivationCode: "Vaše aktivacijsko geslo:",
        scanQrCodeC7: "Poiščite javni račun Addiko banke v aplikaciji Viber (Addiko Bank Slovenija), v delu 'Public Accounts' in nam sledite! ('Follow' / Sledi)",
        scanQrCodeC8: "Z napravo preberite prikazano QR kodo v aplikaciji Viber (možnost 'More', 'QR code scanner'). Po branju boste samodejno preusmerjeni na javni Viber uporabniški račun Addiko banke, kjer vam bo na voljo avtomatska 'chat' aplikacija 'Addiko chat bot', ki bo od vas zahtevala, da potrdite oziroma pošljete aktivacijsko geslo. Aktivacijsko geslo pošljite s pomočjo 'Viber chat' sporočila.",
        activationCodeC9: "Poiščite javni račun Addiko banke v aplikaciji Viber (Addiko Bank Slovenija), v delu 'Public Accounts' in nam sledite! ('Follow' / 'Sledi')",
        activationCodeC10: "Zaženite 'chat' možnost, ki se nahaja v zgornjem desnem kotu aplikacije Viber. Na dnu zaslona naprave, na kateri uporabljate Viber (mobilni telefon, tablica, računalnik) se nahajajo možnosti za storitev 'Addiko Chat Banking'. Izberite možnost 'Aktiviraj'. Vnesite aktivacijsko geslo v Viber sporočilo in ga pošljite.",
        additionalActivation1: "Po poslanem aktivacijskem geslu boste prejeli SMS sporočilo z enkratnim geslom. Sporočilo boste prejeli na mobilno številko, prijavljeno v spletni banki Addiko EBank. Prepišite enkratno geslo iz SMS sporočila v 'Viber chat' in ga pošljite.",
        additionalActivation2: "Določite osebno 4-mestno PIN kodo. Za zaključek procesa aktivacije 'Addiko Chat Viber Bankinga', bo 'Addiko chat bot' od vas zahteval dva vnosa PIN kode.",
        additionalActivation3: "OPOZORILO: PIN kodo boste v prihodnje potrebovali za vstop v storitev 'Addiko Viber Chat Banking' ter za potrjevanje plačilnih nalogov, oddanih preko storitve Viber, zato je pomembno, da si jo zapomnite!",
        step1oth: "Na dnu strani izberite možnost 'Sledi'. Prejeli boste SMS sporočilo z neposredno povezavo na javni profil Addiko banke v aplikaciji Viber. V aplikaciji nato izberite možnost 'Sledi/Follow', s čimer boste  postali sledilec našega javnega računa.",
        step2oth: "Vrnite se v spletno banko Addiko EBank in začnite postopek aktivacije z izbiro možnosti 'Aktiviraj'. Prejeli boste SMS sporočilo z neposredno povezavo na javni profil Addiko banke v aplikaciji Viber. S pomočjo aplikacije Viber pošljite aktivacijsko kodo, ki bo samodejno vpisana v polju Viber sporočila.",
        step3oth: 'Po uspešni potrditvi aktivacijske kode, boste prejeli SMS sporočilo z enkratnim geslom. To geslo vpišite v polje za sporočila v "Addiko Chat Banking-u" ter ga pošljite preko aplikacije Viber.',
        step4oth: 'Po uspešni potrditvi vnesenega gesla, določite Vašo osebno PIN kodo za potrebe "Addiko Chat Bankinga". To PIN kodo boste v bodoče potrebovali ob prijavi v "Addiko Chat Banking" ter ob potrjevanju plačil, zato je pomembno, da si jo zapomnite!',
        step1hr: "Izberite možnost „Sledi“. Prejeli boste SMS sporočilo z neposredno povezavo na javni profil Addiko banke v aplikaciji Viber. V aplikaciji nato izberite možnost „Sledi/Follow“, s čimer boste  postali sledilec našega javnega računa.",
        step2hr: "Vrnite se v spletno banko Addiko EBank in začnite postopek aktivacije z izbiro možnosti 'Aktiviraj'. Prejeli boste SMS sporočilo z neposredno povezavo na javni profil Addiko banke v aplikaciji Viber. Preko Viber aplikacije pošljite aktivacijski ključ, ki bo avtomatsko vpisan v polje Viber sporočila.",
        step3hr: 'Po uspešni potrditvi aktivacijskega gesla, boste prejeli SMS sporočilo z enkratnim geslom. To geslo vpišite v polje za sporočila v Addiko Chat Bankingu ter pošljite preko aplikacije Viber.',
        step4hr: 'Po uspešni potrditvi vnesenega gesla, določite Vaš osebni PIN znotraj Addiko Chat Bankinga. Ta PIN boste v bodoče uporabljali ob prijavi v Addiko Chat Banking ter ob potrjevanju plačil.',
        becomeFollower: 'Sledi',
        conditionHeader: "Izvajajte bančne storitve s pomočjo aplikacije Viber hitro in enostavno.",
        conditionHeader2: 'Storitev Addiko Chat Banking Vam omogoča:',
        text1: 'Pregled stanja Vaših računov',
        text2: 'Izvajanje plačil iz obstoječih predlog, pregled plačil',
        text3: 'Prikaz lokacij najbližjih Addiko bankomatov in poslovalnic',
        text4: 'Informacije o trenutno aktualni Addiko ponudbi',
        activeMessage: 'Vaš Addiko Chat Banking je aktiven.',
        mobileNumber: 'Mobilna številka, s katero je povezana storitev Addiko Chat Banking:',
        activationDate: 'Datum aktivacije storitve:',
        successfulActivation: 'Uspešna aktivacija.',
        unsuccessfulActivation: 'Neuspešna aktivacija.',
        smsSent: 'SMS sporočilo je bilo uspešno poslano. Prosimo kliknite na povezavo, ki ste jo prejeli v SMS sporočilu.',
        successFollower: 'SMS sporočilo je bilo uspešno poslano. Prosimo kliknite na povezavo, ki ste jo prejeli v SMS sporočilu.',
        becomeFollowerAlert: "Če ste že sledilec Addiko javnega računa v aplikaciji Viber, ta korak ni potreben. Želite preskočiti ta korak in nadaljevati z možnostjo 'Aktiviraj'?",
        yes: "Da",
        no: "Ne",
        agree2: "Soglašam s Splošnimi informacijami o varstvu osebnih podatkov Addiko Bank d.d.",
        personalData: "Potrjujem, da s soglašanjem s temi Splošnimi informacijami dovoljujem Addiko Bank d.d., da v sklopu storitve Addiko Chat Banking v aplikaciji Viber uporabi moje osebne podatke, ki sem jih že predhodno dovolil uporabljati pri pristopu k storitvi spletne banke Addiko EBank. Ravno tako dovoljujem, da lahko Banka z enakim namenom uporabi tudi moje identifikacijske podatke na platformi Viber (Viber ID, Viber Username), kar potrjujem takrat, ko postanem ‘sledilec’ javnega računa Addiko banke v aplikaciji Viber. Potrjujem, da sem uporabo zgoraj navedenih podatkov dovolil prostovoljno ter da dopuščam uporabo teh podatkov za namen, za katerega so dani oziroma za koriščenje storitve Addiko Chat Banking.",
        personalData1: "Banka ne prodaja, posoja ali posreduje osebne podatke uporabnikov tretjim osebam ter moje osebne podatke ščiti pred nepooblaščenimi dostopi. Soglašam, da lahko Banka posreduje moje osebne podatke zaupanja vrednim poslovnim partnerjem za določene namene, kot so različne statistične analize, izvajanje uporabniške podpore, preverjanje zadovoljstva strank s storitvami Banke ali za podobne namene. V takšnih primerih bo Banka svojemu poslovnemu partnerju prepovedala uporabo mojih osebnih podatkov v druge namene, razen za dogovorjeno ter bo zavezala poslovnega partnerja k varovanju zaupnosti osebnih podatkov."
      }
    };

    $translateProvider.translations('sl', data);

  }
} ());
