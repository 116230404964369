(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:DomesticPaymentCroCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('DomesticPaymentCroCtrl', DomesticPaymentCroCtrl);

  function DomesticPaymentCroCtrl($rootScope, CTLocalizationService, UserSettingsService, $state, $log, HelperService,
                                  PurposeCodesService, BanksService, CountriesService, $timeout,
                                  PaymentTypesService, PaymentsService, ValidationService, PaymentFilterService,
                                  CreditAccountRegisterService, $q,PaymentTemplatesService, ReferenceModelService, ImagesService,
                                   Notification, AlertService,GoogleTagManagerHelperService,$location, 
                                   NotificationService) {
    var vm = this;
    vm.images = {};

    vm.currentStep = 1;
    vm.paymentObject = {};
    vm.tempDompObj = {
      fromAccountSelect: {},
      purposeCodeSelect: {},
      purposeOfPaymentInput: '',
      amountInput: '',
      dateInput: new Date(),
      bicCodeInput: '',
      toAccountInput: '',
      referenceCodeInput: '',
      referenceModelSelect: {},
      referenceNumberInput: '',
      payersNameInput: '',
      payersAddressInput: '',
      payersCityInput: '',
      payersSepaInput1: '',
      payersSepaInput2: '',
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      receiversCountrySelect: {},
      receiversSepaInput1: '',
      receiversSepaInput2: '',
      isUrgentPaymentCheckbox: false,
      formSubmitted: false
    };
    vm.referenceHR = "HR";
    vm.formSubmittedFlag = false;
    vm.defaultCurrency = '';
    vm.forUpdate = false;
    /*    vm.smsAuthentificate = false;
     vm.smsPasswordInput = '';*/
    vm.hideUrgency = false;

    vm.split = function (input) {
      return HelperService.splitReferenceNumber(input);
    };

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.disabledSunday = false;
    vm.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0));
    };

    vm.bankCharges = '';
    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000
    };

    vm.banksList = {};
    vm.banksFilter = {
      pageSize: 1000
    };

    vm.countriesCodeList = {};
    vm.countriesCodeFilter = {
      pageSize: 1000
    };

    vm.referenceModelList = [];
    vm.referenceModelFilter = {
      pageSize: 1000
    };

    vm.payeeFilter = {
      paymentTypeGroup: 'DomesticPayment',
      orderBy: 'receiverName ASC',
      page: 0,
      pageSize: 1000
    };

    vm.templatesFilter = {
      paymentTypeGroup: 'DomesticPayment',
      page: 0,
      pageSize: 1000
    };

    vm.templateList = {};

    vm.tmpSearchText = {
      name: ''
    };

    vm.templateName = '';

    vm.fromAccountDropdown = false;
    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.searchFilterExpanded = false;

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;

    vm.instantPaymentEnabled = false;
    vm.isBankInSCTInstScheme = false;

    var loadAccountData = function (accountId) {
      var index = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[index];
    };

    //var loadPaymentDataFromTemplate = function (templateId) {
    //  if (templateId) {
    //    PaymentTemplatesService.getById(templateId)
    //      .then(function (response) {
    //        vm.tempDompObj.amountInput = $filter('number')(response.amount, 2);
    //        vm.tempDompObj.toAccountInput = response.toAccountNumber;
    //        vm.tempDompObj.purposeOfPaymentInput = response.purpose;
    //        vm.tempDompObj.receiversNameInput = response.receiverName || '';
    //        vm.tempDompObj.receiversAddressInput = response.receiverAddress1 || '';
    //        vm.tempDompObj.receiversPostalCodeAndCityInput = response.receiverCity || '';
    //        loadAccountData(response.accountId);
    //      })
    //      .catch(function (error) {
    //        NotificationService.showMessage(error, 'error');
    //      });
    //  }
    //};

    vm.getPaymentTypeGroup = function () {
      return PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);
          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.getPurposeCodesPage = function () {
      return PurposeCodesService.getPage(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            value.optionValue = value.id + ' - ' + value.description;
            //  value.optionValue = value.id;
            vm.tempDompObj.purposeCodeSelect = value.id;
            vm.tempDompObj.purposeOfPaymentInput = value.description
          });
          vm.purposeCodesList = response;
          if (vm.purposeCodesList.result.length > 0) {
            vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
            vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
          }
          $log.debug("purposeCodesList", vm.purposeCodesList);
        })
        .catch(function () {
          $log.catch('Error loading purpose codes!');
        });
    };

    vm.getBanksPage = function () {
      return BanksService.getPage(vm.banksFilter)
        .then(function (response) {
          vm.banksList = response;
          $log.debug("banksList", vm.banksList);
        })
        .catch(function () {
          $log.catch('Error loading banks codes!');
        });
    };

    vm.changePurpose = function (purposeChange) {
      var purpose = _.find(vm.purposeCodesList.result, {
        description: vm.tempDompObj.purposeOfPaymentInput
      });
      if (purpose) {
        vm.tempDompObj.purposeOfPaymentInput = purposeChange.description;
      }
      // var purposeCode = _.findIndex(vm.purposeCodesList.result, function (item) {
      //   vm.tempDompObj.purposeOfPaymentInput = item.description;
      //   return item.id == code.id;
      // });
    };

    vm.getCountriesPage = function () {
      return CountriesService.getPage(vm.countriesCodeFilter)
        .then(function (response) {
          vm.countriesCodeList = response;

          $log.debug("countriesCodeList", vm.countriesCodeList);
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.catch(error);
        });
    };

    vm.insertedPaymentObjectDomp = {};
    var createPaymentObject = function () {

      if (vm.tempDompObj.toAccountInput.substring(0, 2) !== 'HR') {
        vm.tempDompObj.toAccountInput = "HR" + vm.tempDompObj.toAccountInput;
      }
      var paymentObject = {};
      paymentObject.domesticPayment = {};
      paymentObject.sepaUpnIdentificators = {};
      paymentObject.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      paymentObject.fromAccountNumber = vm.tempDompObj.fromAccountSelect.iban;
      paymentObject.toAccountNumber = vm.tempDompObj.toAccountInput;
      paymentObject.currencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
      paymentObject.purpose = vm.tempDompObj.purposeOfPaymentInput;
      paymentObject.dueDate = vm.tempDompObj.dateInput;
      paymentObject.amount = vm.tempDompObj.amountInput;
      paymentObject.paymentTypeGroup = 'DomesticPayment';
      paymentObject.purposeCodeDPS = vm.tempDompObj.purposeCodeSelect.id;
      paymentObject.domesticPayment.receiverName = vm.tempDompObj.receiversNameInput;
      paymentObject.domesticPayment.receiverAddress1 = vm.tempDompObj.receiversAddressInput;
      paymentObject.domesticPayment.receiverCity = vm.tempDompObj.receiversPostalCodeAndCityInput;
      paymentObject.domesticPayment.urgency = vm.tempDompObj.isInstantPaymentCheckBox ? '2' : vm.tempDompObj.isUrgentPaymentCheckbox ? '1' : '0'
      paymentObject.domesticPayment.referenceFrom = '99';

      paymentObject.sepaUpnIdentificators.debtorName = vm.tempDompObj.payersSepaInput1;
      paymentObject.sepaUpnIdentificators.debtorName2 = vm.tempDompObj.payersSepaInput2;
      paymentObject.sepaUpnIdentificators.creditorName = vm.tempDompObj.receiversSepaInput1;
      paymentObject.sepaUpnIdentificators.creditorName2 = vm.tempDompObj.receiversSepaInput2;

      if (vm.tempDompObj.referenceModelSelect) {
        if (vm.tempDompObj.referenceModelSelect === "  ") {
          paymentObject.domesticPayment.referenceTo = '00' + vm.tempDompObj.referenceNumberInput;
        } else
          paymentObject.domesticPayment.referenceTo = vm.tempDompObj.referenceModelSelect.concat(vm.tempDompObj.referenceNumberInput);
      } else if ((!vm.tempDompObj.referenceModelSelect) && (vm.tempDompObj.referenceNumberInput)) {
        vm.tempDompObj.referenceModelSelect = '00';
        paymentObject.domesticPayment.referenceTo = vm.tempDompObj.referenceModelSelect.concat(vm.tempDompObj.referenceNumberInput);
      } else {
        paymentObject.domesticPayment.referenceTo = '00';
      }


      return paymentObject;
    };

    vm.insertedPaymentObject = {};

    var postPaymentAction = function (response) {
      vm.insertedPaymentObject = response;
      vm.insertedPaymentObject.paymentTypeGroup = "DomesticPayment";
      vm.insertedPaymentObject.senderInfo = {
        senderName: vm.tempDompObj.fromAccountSelect.ownerName,
        senderAddress1: vm.tempDompObj.fromAccountSelect.ownerAddress,
        senderCity: vm.tempDompObj.fromAccountSelect.ownerCity
      };

      vm.insertedPaymentObjectDomp.referenceTo = vm.insertedPaymentObject.referenceTo;
      Notification.clearAll();
      vm.currentStep = 2;
    };

    vm.submitPayment = function () {
      vm.tempDompObj.formSubmitted = true;
      if (!vm.domesticPaymentForm.$valid) {
        return;
      }

      if (!vm.formSubmittedFlag) {
        vm.formSubmittedFlag = true;
        var objectForInsert = createPaymentObject();

        if (vm.forUpdate) {
          objectForInsert.id = vm.insertedPaymentObject.id;
          PaymentsService.updatePayment(vm.insertedPaymentObject.id, objectForInsert)
            .then(function (response) {
              postPaymentAction(response);
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;

            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              // vm.domesticPaymentForm.$valid = false;
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;

            })
            .finally(function () {
            });
        } else {
          PaymentsService.insertPayment(objectForInsert)
            .then(function (response) {
              postPaymentAction(response);
              vm.formSubmittedFlag = false;
              var gtmObject = HelperService.processUrlForGTM('payment');
              GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              // vm.domesticPaymentForm.$valid = false;
              vm.formSubmittedFlag = false;

            })
            .finally(function () {

            });
        }
      }
    };


    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePayment = function () {
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deletePayment(vm.insertedPaymentObject.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({
                  paymentName: vm.insertedPaymentObject.id,
                  type: 'payment'
                })
                  .then(function () {
                    $state.go('payments.paymentsOverview');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    var mapWithPaymentResponse = function () {
      vm.tempDompObj.amountInput = vm.insertedPaymentObject.amount;
      vm.tempDompObj.receiversNameInput = vm.insertedPaymentObject.receiverName;
      vm.tempDompObj.receiversAddressInput = vm.insertedPaymentObject.receiverAddress1;
      vm.tempDompObj.receiversPostalCodeAndCityInput = vm.insertedPaymentObject.receiverCity;
      vm.tempDompObj.toAccountInput = vm.insertedPaymentObject.toAccountNumber;
      vm.tempDompObj.dateInput = new Date(vm.insertedPaymentObject.dueDate);
      vm.tempDompObj.purposeOfPaymentInput = vm.insertedPaymentObject.purpose;

      var accountIndex = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == vm.insertedPaymentObject.accountId;
      });

      var purposeCodeIndex = _.findIndex(vm.purposeCodesList.result, function (item) {
        return item.id == vm.insertedPaymentObject.purposeCodeDPS;
      });

      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[accountIndex];
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[purposeCodeIndex];
      vm.tempDompObj.payersSepaInput1 = vm.insertedPaymentObject.debtorName;
      vm.tempDompObj.payersSepaInput2 = vm.insertedPaymentObject.debtorName2;
      vm.tempDompObj.receiversSepaInput1 = vm.insertedPaymentObject.creditorName;
      vm.tempDompObj.receiversSepaInput2 = vm.insertedPaymentObject.creditorName2;
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.currentStep = 1;
      mapWithPaymentResponse();
    };

    var fillReceiversInput = function (response) {
      if (response.result.length > 0) {
        var rName = response.result[0].receiverName;
        var rAddressInput = response.result[0].receiverAddress1;
        var rPostalCodeAndCityInput = response.result[0].receiverCity;
        vm.tempDompObj.receiversNameInput = rName.substring(0, 70);
        vm.tempDompObj.receiversAddressInput = rAddressInput.substring(0, 35);
        vm.tempDompObj.receiversPostalCodeAndCityInput = rPostalCodeAndCityInput.substring(0, 35);
      } else {
        vm.tempDompObj.receiversNameInput = '';
        vm.tempDompObj.receiversAddressInput = '';
        vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      }
    };

    vm.toggleUrgencyField = function(checkboxName) {
      var clickedCheckbox = checkboxName;
      var otherCheckbox = checkboxName === 'isUrgentPaymentCheckbox' ? 'isInstantPaymentCheckBox' : 'isUrgentPaymentCheckbox';
      if (vm.tempDompObj[clickedCheckbox]) {
        vm.tempDompObj[otherCheckbox] = false;
      }
    }

    vm.instantPaymentValidation = function () {
      const date = vm.tempDompObj.dateInput;
      const todayDate = new Date();
      const today = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), 0, 0, 0, 0).toISOString();
      const dateInput =  new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate(), 0, 0, 0, 0).toISOString();
      if (vm.tempDompObj.amountInput <= 100000 && vm.isBankInSCTInstScheme && today == dateInput) { // ako je danasnji datum
        vm.instantPaymentEnabled = true;
        vm.tempDompObj.isUrgentPaymentCheckbox = false;
        if (vm.instantPaymentAutoFillData) {
          vm.tempDompObj.isInstantPaymentCheckBox = false;
        }
      } else {
        vm.instantPaymentEnabled = false;
        vm.tempDompObj.isInstantPaymentCheckBox = false;
      }
    };

    vm.HSVPValidation = function () {

      var toAccountInput = vm.tempDompObj.toAccountInput;

      if (toAccountInput && toAccountInput.length >= 11 ) {
        //dodano zbog disable-anja hitnog plaćanja i omogucene nedjelje na internim nalozima
        var temp = toAccountInput.substring(4, 11);
        // 2021-01-20
          // ako je racun primatelja u formatu HR..1001005.......... 
          // i ako je iznos naloga veci ili jednak od 1.000.000 (milion) onda je dozvoljna HSVP opcija
        if (temp === '2500009' || (temp === '1001005' && vm.tempDompObj.amountInput < 1000000)) {
          vm.hideUrgency = true;
          vm.disabledSunday = false;
        } else {
          vm.hideUrgency = false;
          vm.disabledSunday = true;
          vm.disabled(vm.tempDompObj.dateInput, 'day');
          if (vm.tempDompObj.dateInput.getDay() === 0) {
            vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
          }
        }
      }     

    };

    vm.fillReceiver = function (toAccountInput) {
      if (!toAccountInput) return;
      if (toAccountInput.substring(0, 2) != 'HR') {
        toAccountInput = "HR" + toAccountInput;
      }
      //dodano zbog disable-anja hitnog plaćanja i omogucene nedjelje na internim nalozima
      var temp = toAccountInput.substring(4, 11);
      // 2021-01-20
        // ako je racun primatelja u formatu HR..1001005.......... 
        // i ako je iznos naloga veci ili jednak od 1.000.000 (milion) onda je dozvoljna HSVP opcija
      if (temp === '2500009' || (temp === '1001005' && vm.tempDompObj.amountInput < 1000000)) {
        vm.hideUrgency = true;
        vm.disabledSunday = false;
      } else {
        vm.hideUrgency = false;
        vm.disabledSunday = true;
        vm.disabled(vm.tempDompObj.dateInput, 'day');
        if (vm.tempDompObj.dateInput.getDay() === 0) {
          vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
        }
      }

      vm.tempDompObj.toAccountInput = toAccountInput;
      if (toAccountInput.length == 21) {
        if (toAccountInput.substring(0, 2) === 'HR') {
          $q.all([CreditAccountRegisterService.getByIban(toAccountInput), BanksService.instantCategoryByIbanCheck(toAccountInput)])
            .then(function (responses) {
              fillReceiversInput(responses[0]);
              if (responses[1]) {
                vm.checkBankSCTInst(responses[1]);
              }
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        } else {
          $q.all([CreditAccountRegisterService.getByIban({accountNumber: toAccountInput}), BanksService.instantCategoryByIbanCheck(toAccountInput)])
            .then(function (responses) {
              fillReceiversInput(responses[0]);
              if (responses[1]) {
                vm.checkBankSCTInst(responses[1]);
              }
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        }
      } else {
        return false;
      }
    };

    vm.checkBankSCTInst = function (instantCategory) {
      vm.isBankInSCTInstScheme = instantCategory;
      vm.instantPaymentValidation();
    }

    vm.clearSepaReceiver = function () {
      vm.tempDompObj.receiversSepaInput1 = '';
      vm.tempDompObj.receiversSepaInput2 = '';
    };

    vm.clearSepaPayer = function () {
      vm.tempDompObj.payersSepaInput1 = '';
      vm.tempDompObj.payersSepaInput2 = '';
    };

    /////////////////////////////CUSTOM SELECT////////////////////////////

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.getPayees = function () {
      PaymentTemplatesService.getPage(vm.payeeFilter)
        .then(function (response) {
          vm.payeeList = response.result;
        }).catch(function (error) {
        $log.catch(error);
      });
    };

    vm.selectPayee = function (id) {
      var index = _.findIndex(vm.payeeList, function (item) {
        vm.tempDompObj.toAccountInput = item.toAccountNumber;
        vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
        vm.tempDompObj.receiversNameInput = item.receiverName;
        vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
        return item.id == id;
      });
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.removeSearch = function () {
      vm.tempDompObj.receiversNameInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1 ||
      angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1);
    };

    ////////////////select template component//////////////////////
    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;
      if (vm.searchTemplateFilterExpanded === true) {
        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
      if (vm.searchTemplateFilterExpanded && (!vm.templateList.result || vm.templateList.result.length === 0)) {
        vm.getPaymentTemplatesPage();
      }
    };

    vm.getPaymentTemplatesPage = function () {
      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
          vm.templateList = response;
          if (vm.templateList)
            populateTemplatesWithImages();
          $log.debug(vm.templateList);
        }).catch(function (error) {
        $log.catch(error);
      });
    };
    vm.selectedTemplate = {};
    vm.selectTemplate = function (id) {
        GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentTemplateSelection', 'Template with id ' + id + ' selected');
      var item = _.find(vm.payeeList, {
        id: id
      });
      vm.templateName = item.name;
      vm.tempDompObj.toAccountInput = item.toAccountNumber;
      vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
      vm.tempDompObj.payersSepaInput1 = item.debtorName;
      vm.tempDompObj.payersSepaInput2 = item.debtorName2;
      vm.tempDompObj.receiversSepaInput1 = item.creditorName;
      vm.tempDompObj.receiversSepaInput2 = item.creditorName2;
      vm.tempDompObj.amountInput = item.amount;

      if (item.referenceTo) {
        if (item.referenceTo.length > 2) {
          vm.tempDompObj.referenceModelSelect = item.referenceTo.substring(0, 2);
          vm.tempDompObj.referenceNumberInput = item.referenceTo.substring(2);
        } else {
          vm.tempDompObj.referenceModelSelect = item.referenceTo;
          vm.tempDompObj.referenceNumberInput = "";
        }
      }

      if (item.purposeCodeDPS && vm.purposeCodesList.result) {

        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          return purpose.id == item.purposeCodeDPS;
        });
        if (purpose) {
          vm.tempDompObj.purposeCodeSelect = purpose;
          vm.tempDompObj.purposeOfPaymentInput = purpose.description;
        }
      }

      if (vm.tempDompObj.purposeOfPaymentInput === '' || item.purpose !== vm.tempDompObj.purposeOfPaymentInput) {
        vm.tempDompObj.purposeOfPaymentInput = item.purpose;
      }

      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;

      if (vm.tempDompObj.toAccountInput) {
        //dodano zbog disable-anja hitnog plaćanja
        var temp = vm.tempDompObj.toAccountInput.substring(4, 11);

        // 2021-01-20
        // ako je racun primatelja u formatu HR..1001005.......... 
        // i ako je iznos naloga veci ili jednak od 1.000.000 (milion) onda je dozvoljna HSVP opcija
        if (temp === '2500009' || (temp === '1001005' && vm.tempDompObj.amountInput < 1000000)) {
          vm.hideUrgency = true;
          vm.disabledSunday = false;
        } else {
          vm.hideUrgency = false;
          vm.disabledSunday = true;
          vm.disabled(vm.tempDompObj.dateInput, 'day');
          if (vm.tempDompObj.dateInput.getDay() === 0) {
            vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
          }
        }
        BanksService.instantCategoryByIbanCheck(vm.tempDompObj.toAccountInput).then(function(response) {
          vm.checkBankSCTInst(response);
        })
      }
    
      vm.selectedTemplate.imageId = item.imageId;
      setImage(vm.selectedTemplate);
    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    }

    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
      vm.tempDompObj.referenceNumberInput = '';

      var index = _.findIndex(vm.referenceModelList, function (item) {
        return item === '99';
      });

      if (index) {
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
      } else {
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      }

      vm.selectedTemplate = {};
    };

    /////////////////from account component///////////////////////////
    vm.toggleFromAccount = function () {
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    /*
     vm.showSMSAuthentification = function () {
     vm.smsAuthentificate = !vm.smsAuthentificate;
     };
     */

    vm.getReferenceModels = function () {
      return ReferenceModelService.getPage(vm.referenceModelFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
          var index = _.findIndex(vm.referenceModelList, function (item) {
            return item.model === '99';
          });

          if (index) {
            vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
          } else {
            vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.newPayment = function () {
      $state.go('payments.domesticPayment', {}, {reload: true})
    };

    vm.init = function () {

      /* defaultno omogucena nedjelja (disable-a se ako nalog nema 2500009)
       if (vm.tempDompObj.dateInput.getDay() == 0) {
       vm.tempDompObj.dateInput = new Date(vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1));
       }*/

      if ($state.params.editable) {
        vm.insertedPaymentObject.id = $state.params.paymentId;
        vm.forUpdate = true;
      }

      vm.getPayees();
      // vm.getUserSettingsPage();

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
        vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        vm.defaultCurrency = vm.defaultData.DefaultCurrency;
        vm.bankCharges = vm.defaultData.BankCharges;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
        var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
        vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
        vm.tempDompObj.payersAddressInput = userSettings.address1;
        vm.tempDompObj.payersCityInput = userSettings.city;
      }

      var promises = [vm.getPurposeCodesPage(), vm.getBanksPage(), vm.getCountriesPage(), vm.getPaymentTypeGroup(), vm.getReferenceModels(),loadImages()];
      $q.all(promises)
        .then(function () {
          //set default form params
          var params = {
            fromAccountList: vm.paymentTypeGroupDomestic.result[0].debitAccountList,
            purposeCodeList: vm.purposeCodesList.result,
            referenceModelList: vm.referenceModelList
          };

          switch ($state.current && $state.current.name) {
            case 'payments.domesticPayment.fromTemplate':
              PaymentTemplatesService.loadDomesticPaymentData($state.params.templateId, vm.tempDompObj, params)
                .then(function () {
                  vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                })
                .catch(function () {
                  $log.catch('loadDomesticPaymentData', error);
                });
              break;
            case 'payments.domesticPayment.fromPayment':
              PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                .then(function () {
                  vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  BanksService.instantCategoryByIbanCheck(vm.tempDompObj.toAccountInput).then(function(response) {
                    vm.checkBankSCTInst(response);
                  })
                })
                .catch(function (error) {
                  $log.catch('loadDomesticPaymentData()', error);
                });
              break;
            case 'payments.domesticPayment':
              if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
                vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
              }
              if (vm.purposeCodesList.result.length > 0) {
                vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
                vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
              }
              if (vm.referenceModelList.length > 0) {
                var index = _.findIndex(vm.referenceModelList, function (o) {
                  return o === '99';
                });
                vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
              }
              break;
            case 'payments.domesticPayment.fromAccount':
              if ($state.params.accountId) {
                vm.tempDompObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, $state.params.accountId);
              }
              break;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.clearReferenceNumber = function () {
      if (vm.tempDompObj.referenceModelSelect === '99') {
        vm.tempDompObj.referenceNumberInput = '';
      }
      ;
    };


    vm.templateImagesFilter = {
      category: "Templates"
    };
    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        vm.images = images.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

      vm.pushGtmUrgentPaymentEvent = function () {
          GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentIsUrgentSelection', (vm.tempDompObj.isUrgentPaymentCheckbox)?'Urgent payment checked':'Urgent payment unchecked');
      };
    vm.init();

  }
}());
