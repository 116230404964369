(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:dnCustomLength
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="core">
   <file name="index.html">
   <input type="text" class="form-control" ng-model="vm.input" dn-custom-maxlength="5" dn-exclude="-">
   </file>
   </example>
   *
   */
  angular
    .module('core')
    .directive('dnCustomMaxlength', dnCustomMaxlength);

  function dnCustomMaxlength() {
    return {
      scope: {
        model: "=ngModel"
      },
      link: function (scope, element, attrs) {
        var maxlength = +attrs["dnCustomMaxlength"];
        var exclude;
        if (attrs["dnExclude"]) {
          exclude = new RegExp(attrs["dnExclude"], 'g');
        } else {
          exclude = "";
        }
        scope.$watch("model", function (newVal) {
          if (newVal) {
            if (newVal.replace(exclude, "").length == maxlength) {
              console.log(element);
              element.attr('maxlength', maxlength);
            } else {
              element.attr('maxlength', "");
            }
          }
        });
      }
    }
  }
}());
