(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name dashboard.factory:DashboardWidgetModalService
     *
     * @description
     *
     */
    angular
        .module('dashboard')
        .factory('DashboardWidgetModalService', DashboardWidgetModalService);

    function DashboardWidgetModalService($uibModal, $log) {
        var DashboardWidgetModalServiceBase = {};
        DashboardWidgetModalServiceBase.openDashboardWidgetModal = function (accountsList) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'dashboard/dashboard-widget-modal.tpl.html',
                controller: 'DashboardWidgetModalCtrl',
                controllerAs: 'dwModalCtrl',
                size: 'lg',
                resolve: {
                    accountsList: function () {
                        return accountsList
                    }
                },
                backdrop: 'static'
            });
            return modalInstance.result;
        };
        return DashboardWidgetModalServiceBase;
    }
}());