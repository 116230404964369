(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name payments.controller:PaymentsAndTransferOverviewCtrl
     *
     * @description
     *
     */
    angular
        .module('payments')
        .controller('PaymentsAndTransferOverviewCtrl', PaymentsAndTransferOverviewCtrl);

    function PaymentsAndTransferOverviewCtrl($state, $rootScope, $log, HelperService, PaymentsService, $translate, $scope, EInvoiceDocumentsService, ConfigEndpointService, $localStorage,
                                             PaymentSignListService) {
        var vm = this;
        vm.ctrlName = 'PaymentsAndTransferOverviewCtrl';

        vm.getDefaultData = function () {
          var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          $localStorage.code = defaultData.APILocation;
          vm.countryCode = $localStorage.code;
            // return ConfigEndpointService.getDefaultData()
            //     .then(function (response) {
            //         if (response.APILocation) {
            //             $localStorage.code = response.APILocation;
            //             vm.countryCode = $localStorage.code;
            //         }
            //     })
            //     .catch(function (error) {
            //         NotificationService.showMessage(error, 'error');
            //     });
        };

        vm.init = function () {
            vm.getRejectedPayments(true);
            vm.geteInvoiceDocumentsPage();
            vm.getDefaultData();
        };

        vm.isRejectedPaymentsPanelOpen = true;
        vm.isEInvoicesPanelOpen = true;
        vm.isGroupedPaymentsPanelOpen = true;

        vm.rejectedPaymentsFilter = {
            page: 0,
            orderBy: 'dateCreated DESC',
            status: 'Rejected',
            pageSize: 10
        };
        vm.rejectedPaymentsList = {};
        vm.getRejectedPayments = function (reset) {

            if (reset) {
                vm.rejectedPaymentsFilter.page = 0;
            } else {
                vm.rejectedPaymentsFilter.page += 1;
            }

            PaymentsService.getPage(vm.rejectedPaymentsFilter)
                .then(function (response) {
                    if (reset) {
                        vm.rejectedPaymentsList = response;
                    } else {
                        var tmpList = {};
                        tmpList.hasMore = response.hasMore;
                        tmpList.result = vm.rejectedPaymentsList.result.concat(response.result);
                        vm.rejectedPaymentsList = tmpList;
                    }
                    $log.debug("rejectedPaymentsList", vm.rejectedPaymentsList);

                })
                .catch(function (error) {
                    $log.error(error);
                });

        };

        //GET E-INVOICES
        vm.eInvoiceDocumentsFilter = {
            page: 0,
            orderBy: "valueDate DESC",
            pageSize: 10
        };
        vm.eInvoiceDocumentsList = {};
        vm.geteInvoiceDocumentsPage = function () {

            EInvoiceDocumentsService.getPage(vm.eInvoiceDocumentsFilter)
                .then(function (response) {
                  if(response){
                    vm.eInvoiceDocumentsList = response;
                    $log.debug("eInvoiceDocumentsList",vm.eInvoiceDocumentsList);
                  }
                })
                .catch(function (error) {
                    $log.error(error);
                });

        };

      vm.groupedPaymentsFilter = {
        page: 0,
        pageSize: 20,
        statusId: 'NotSigned',
        includeList: ['paymentList']
      };

      vm.groupedPayments = {
        payments: [],
        hasMore: false
      };

      vm.getGroupedPayments = function(reset){
        if (reset) {
          vm.groupedPaymentsFilter.page = 0;
        } else {
          vm.groupedPaymentsFilter.page += 1;
        }

        PaymentSignListService.getPage(vm.groupedPaymentsFilter)
          .then(function (response) {

            vm.groupedPayments.hasMore = response.hasMore;

            // NOTE: Uncomment block bwelow to get only payments for one month ago
            // Get date one month ago
            var daysOneMonthAgo = new Date();
            daysOneMonthAgo.setMonth(daysOneMonthAgo.getMonth()-1);
            // Remove old payments (older than one month from current date)

/*            _.forEach(response.result, function(item){
              item.paymentList = item.paymentList.filter(function(payment){
                return payment.dateCreated > daysOneMonthAgo;
              });
            });*/


            // Count total payments amount for every group
            _.forEach(response.result, function(item){
              item.totalPaymentsAmount = 0;
              if(item.paymentList && item.paymentList.length > 0){

                _.forEach(item.paymentList, function(singlePayment){
                  item.totalPaymentsAmount += singlePayment.amount;
                });
              }
            });

            // Logic for loading more payments
            if(reset){
              vm.groupedPayments.payments = response.result;
            }else{
              vm.groupedPayments.payments = vm.groupedPayments.payments.concat(response.result);
            }

          })
          .catch(function (error) {
            $log.error(error);
          });
      };

        vm.getToday = function () {
          return new Date();
        };

      vm.navigateToPaymentForm = function (paymentObj) {
        var stateLink;
        var params = {paymentId: paymentObj.id};
        switch (paymentObj.paymentTypeGroup) {
          case "DomesticPayment":
            //stateLink = 'payments.domesticPayment';
            stateLink = 'payments.domesticPayment.fromPayment';
            break;
          case "LocalCurrencyTransfer":
            stateLink = 'payments.internalTransfer.fromPayment';
            //var strUrl = JSON.stringify({
            //  type: 'payment',
            //  id: paymentObj.id
            //});
            //params = {templateId: strUrl};
            break;
          case "CurrencyExchange":
            stateLink = 'payments.currencyConversion.fromPayment'
            break;
          case "ForeignPayment":
            console.log('Not implemented');
            break;
        }
        if (stateLink) {
          $state.go(stateLink, params);
        }
      };

      vm.navigatoEInvoicePayment = function (eInvoiceId) {
        $state.go('eInvoices.pay', {eInvoiceId: eInvoiceId});
      };

        vm.init();
    }
}());
