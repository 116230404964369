(function () {
  'use strict';

  angular
    .module('secure3d')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('secure3d', {
        url: '/secure3d',
        views: {
          '@': {
            templateUrl: 'secure3d/secure3d.tpl.html',
            controller: 'Secure3dCtrl',
            controllerAs: 'secure3d'
          }
        },
        resolve:{
          authenticationGuard: function ($location, $rootScope, $q, $state, ConfigEndpointService) {
            ConfigEndpointService.getDefaultData()
              .then(function (response) {
                if (response.APILocation !== 'hr') {
                  //redirect to dashboard
                  $state.go('dashboard');
                  return $q.reject('Forbidden');
                }
              })
              .catch(function (error) {
                //redirect to dashboard page
                $state.go('dashboard');
                return $q.reject('Forbidden');
              });
          }
        }
      });
  }
}());
