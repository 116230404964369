(function () {
  'use strict';

  angular
    .module('core')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('core', {
        url: '/core',
        templateUrl: 'core/core.tpl.html',
        controller: 'CoreCtrl',
        controllerAs: 'core'
      })
      .state('sessionexpired', {
        url: '/session-expired',
        templateUrl: 'core/components/session-expired/session-expired.tpl.html',
        controller: 'SessionExpiredCtrl',
        controllerAs: 'core'
      })
      .state('coreHiddenAuth', {
        url: '/hidden-auth-component',
        templateUrl: 'core/components/hidden-auth/hidden-auth.tpl.html',
        controller: 'HiddenAuthCtrl',
        controllerAs: 'HiddenAuthCtrl'
      })
  }
}());
