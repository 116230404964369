<!DOCTYPE html>
<html lang='en' data-ng-app='comtradeBankingWeb'>
<!--<base href="/">-->
<head>
  <title>Login handler</title>
  <meta name='viewport' content='width=device-width, minimum-scale=1.0, initial-scale=1.0, user-scalable=yes'>
  <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
  <meta http-equiv="Pragma" content="no-cache">
  <meta http-equiv="Expires" content="0"> 
  <meta charset="UTF-8">
  <!-- inject:head:js -->
  <!-- endinject -->
  <!-- inject:html -->
  <!-- endinject -->
  <!-- bower:css -->
  <!-- endbower -->
  <!-- inject:css -->
  <!-- endinject -->
</head>
<body>
<div data-ng-controller="LoginCtrl as login">
<!--  <div ng-cloak>{{login.errorKey}}</div>
  <div ng-cloak>{{login.errorDescription}}</div>-->
</div>
<!-- Older IE warning message -->
<!--[if lt IE 9]>
<div class="ie-warning">
  <h1 class="c-white">Warning!!</h1>
  <p>You are using an outdated version of Internet Explorer, please upgrade <br/>to any of the following web browsers to access this website.</p>
  <div class="iew-container">
    <ul class="iew-download">
      <li>
        <a href="http://www.google.com/chrome/">
          <img src="img/browsers/chrome.png" alt="">
          <div>Chrome</div>
        </a>
      </li>
      <li>
        <a href="https://www.mozilla.org/en-US/firefox/new/">
          <img src="img/browsers/firefox.png" alt="">
          <div>Firefox</div>
        </a>
      </li>
      <li>
        <a href="http://www.opera.com">
          <img src="img/browsers/opera.png" alt="">
          <div>Opera</div>
        </a>
      </li>
      <li>
        <a href="https://www.apple.com/safari/">
          <img src="img/browsers/safari.png" alt="">
          <div>Safari</div>
        </a>
      </li>
      <li>
        <a href="http://windows.microsoft.com/en-us/internet-explorer/download-ie">
          <img src="img/browsers/ie.png" alt="">
          <div>IE (New)</div>
        </a>
      </li>
    </ul>
  </div>
  <p>Sorry for the inconvenience!</p>
</div>
<![endif]-->

<!-- bower:js -->
<!-- endbower -->
<!-- inject:js -->
<!-- endinject -->
</body>
</html>
