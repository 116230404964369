<!--TODO: Do we have all these fields? -->
<div id="creditAccountWidget" class="p10 app-white-card">
  <div class="col-md-8 wc-right-div">
    <h4>{{account.accountDescription}}</h4>
    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
      <h2 class="no-bottom-margin">{{account.balance}} {{account.currencySymbol}}</h2>
      <p>{{account.availableBalance}} {{account.currencySymbol}} available</p>
    </span>

    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <span>
        <h2 class="no-bottom-margin">{{account.balance}} {{account.currencySymbol}}</h2>
        <p>{{account.availableBalance}} {{account.currencySymbol}} available</p>
      </span>

      <span ng-if="dashboard.isNuN(account.balance2 )">
        <h2 class="no-bottom-margin">{{account.balance2}} {{account.currencySymbol2}}</h2>
        <p>{{account.availableBalance2}} {{account.currencySymbol2}} available</p>
      </span>

    </span>
    <p class="no-bottom-margin">
      <b>Payment due date: 12//11/15</b>
    </p>
    <p>
      <b>Minimum payment: 20.000 RSD</b>
    </p>
  </div>
  <div class="col-md-4 wc-left-div">
    <div class="wc-left-div-wrapper">
      <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">View account</button>
      <div style="height:5px;"></div>
      <button id="dykBtn" type="input" style="width:100%;" class="btn btn-default app-btn-blue">Pay card</button>
    </div>
  </div>
</div>