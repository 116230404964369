(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name sepa.controller:SepaCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaCroCtrl', SepaCroCtrl);

  function SepaCroCtrl($q, $rootScope, $scope, $state, AccountsService, SepaService, $log, HelperService, AlertService, NotificationService) {
    var vm = this;

    init();

    function init() {
      vm.overviewTabType = 'overview';
      vm.model = {};

      getSepaSettings(true);
    }

    function getSepaSettings(reset) {
      $q.all([
        SepaService.getSepaSettings({
          pageSize: 10000,
          isActivated: true
        }),
        SepaService.getSepaSettings({
          pageSize: 10000,
          // showAll: true,
          isActivated: false,
          orderBy: "dateCreated DESC"
        }),
        SepaService.getSepaSettings({
          pageSize: 10000,
          // showAll: true,
          isActivated: null,
          orderBy: "dateCreated DESC"
        })
      ])
        .then(function (responses) {

          vm.sepaDDSettings = [];

          vm.sepaDDSettings = HelperService.mergeRowsForTimeline({
            existingData: vm.sepaDDSettings,
            newData: {
              result: responses[0].result
            },
            groupFunction: function (item) {
              // var valueDate = new Date(item.dateCreated);
              // valueDate.setHours(0, 0, 0, 0);
              return item.settingType.description;
            },
            virtualRowFunction: function (e) {
              var virtualRow = {
                isVirtual: true,
                description: e
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          }).result;

          vm.sepaDDSettingsHistory = HelperService.mergeRowsForTimeline({
            existingData: vm.sepaDDSettingsHistory,
            newData: {
              result: responses[1].result
            },
            groupFunction: function (item) {
              var valueDate = new Date(item.dateCreated);
              valueDate.setHours(0, 0, 0, 0);
              return valueDate;
            },
            virtualRowFunction: function (e) {
              var virtualRow = {
                isVirtual: true,
                date: new Date(e)
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          }).result;

          vm.sepaDDRequests = HelperService.mergeRowsForTimeline({
            existingData: vm.sepaDDRequests,
            newData: {
              result: responses[2].result
            },
            groupFunction: function (item) {
              var valueDate = new Date(item.dateCreated);
              valueDate.setHours(0, 0, 0, 0);
              return valueDate;
            },
            virtualRowFunction: function (e) {
              var virtualRow = {
                isVirtual: true,
                date: new Date(e)
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          }).result;

        }, function (error) {
          vm.sepaDDSettings = [];
          vm.sepaDDSettingsHistory = [];
          vm.sepaDDRequests = [];
        });

    }

    function getSepaDDRequests() {
      SepaService.getSepaSettings({
        AccountId: vm.model.fromAccountSelect.accountId,
        pageSize: 10000,
        showAll: true,
        isActivated: null,
        orderBy: "dateCreated+DESC"
      }).then(function (response) {
        vm.sepaDDRequests = HelperService.mergeRowsForTimeline({
          existingData: vm.sepaDDRequests,
          newData: {
            result: response.result
          },
          groupFunction: function (item) {
            var valueDate = new Date(item.dateCreated);
            valueDate.setHours(0, 0, 0, 0);
            return valueDate;
          },
          virtualRowFunction: function (e) {
            var virtualRow = {
              isVirtual: true,
              date: new Date(e)
            };
            $log.debug("virtualRow", e, virtualRow);
            return virtualRow;
          },
          reset: reset,
          reverse: false
        }).result;

      }, function (error) {
        vm.sepaDDSettings = [];
        vm.sepaDDSettingsHistory = [];
        vm.sepaDDRequests = [];
      });
    }


    var cleanLanguageChangeListener = $rootScope.$on('appLanguageChangedHeader', function (evt, data) {
      getSepaSettings(true);
    });

    $scope.$on('$destroy', function () {
      cleanLanguageChangeListener();
    });


    vm.setAccountFrom = function (account) {
      vm.model.fromAccountSelect = account;
      vm.fromAccountDropdown = false;
      getSepaSettings(true);
    };

    var selectedToEdit = {};
    vm.edit = function (data) {
      selectedToEdit.editInProgress = false;
      if (data.editInProgress) return;
      selectedToEdit = data;
      data.editInProgress = true;
      $state.go("sepa.new-cro", {
        id: data.accountId
      }).then(function () {
        data.editInProgress = false;
      })
    };

    vm.addNewSetting = function () {
      if (vm.addNewSepaSettingLoading) return;
      vm.addNewSepaSettingLoading = true;
      $state.go("sepa.new-cro")
        .finally(function () {
          vm.addNewSepaSettingLoading = false;
        });
    };

    vm.openSwitchItemActiveStateModal = function (item) {
      var confirmationText = "sepa.changeToActivated";
      var confirmationSuccessText;
      if (item.activated) {
        confirmationText = "sepa.changeToDeactivated";
        confirmationSuccessText = "sepa.deactivateSuccess";
      } else {
        confirmationText = "sepa.changeToActivated";
        confirmationSuccessText = "sepa.activateSuccess";
      }


      AlertService.confirmationAlert({
        text: confirmationText
      })
        .then(function (accept) {
          if (!accept) return;
          var putObject = {
            "id": item.id,
            "accountId": item.accountId,
            "maxAmount": item.maxAmount,
            "settingType": {
              "id": item.settingType.id,
              "description": item.settingType.description
            },
            "note": item.note,
            "activated": !item.activated,
            "changeDate": item.changeDate
          };
          SepaService.updateSepaSetting(item.id, putObject)
            .then(function (response) {
              AlertService.infoAlert({
                text: confirmationSuccessText
              })
                .then(function () {
                  getSepaSettings(true);
                });
            }, function (error) {
              NotificationService.showMessage(error, 'error');
            });
        });
    };

    vm.deactivate = function (sepa) {
      AlertService.confirmationAlert({
        text: "sepa.deactivateAlert"
      })
        .then(function (accept) {
          if (!accept) return;

          vm.deactivateBtnDisabled = true;
          SepaService.deactivateSepaSetting(sepa.id)
            .then(function (response) {
              getSepaSettings(true);
            }, function (error) {
              NotificationService.showMessage(error, 'error');
              vm.deactivateBtnDisabled = false;
              getSepaDDRequests();
            })
            .finally(function () {
              vm.deactivateBtnDisabled = false;
            })


        }, function (error) {

        })

    }

  }
}());
