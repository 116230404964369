(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      secure3d: {
        text1: 'Addiko sigurna internetska kupnja je usluga koja Vam, ovisno o kartici koju koristite, pruža najviše sigurnosne standarde Mastercard Secure Code odnosno Verified by Visa, kojima ste zaštićeni od neautorizirane upotrebe Vaših kartica na internetu.',
        text12: 'Uslugu je prije korištenja potrebno aktivirati, a kada ju aktivirate ona vrijedi za sve Addiko kartice. Nije potrebno aktivirati uslugu za svaku pojedinu karticu.',
        text2: 'Aktivacija je obavezna i provodi se na ',
        text3link: 'internetskoj stranici Addiko banke',
        text4: ' gdje možete pročitati sve detalje o usluzi i načinu aktivacije.',
        text5: 'Aktivirajte odmah uslugu sigurne internetske kupnje za sve svoje Addiko kartice i kupujte sigurno!',
        buttonText: 'Želim aktivirati uslugu',
        buttonTitle: 'Sigurna internetska kupnja'
      }
    };

    $translateProvider.translations('hr', data);

  }
}());

