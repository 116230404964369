<section id="main">
  <div class="container-fluid">
    <div class="exchange-rates">
      <div class="no-padding-on-phone">

        <div ng-if="instantPayment.location=='sr'" data-ng-include="'payments/instant-payment-srb.tpl.html'"></div>

      </div>
    </div>
  </div>
</section>
