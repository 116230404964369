(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:ChangeWithdrawalLimitCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('ChangeWithdrawalLimitCtrl', ChangeWithdrawalLimitCtrl);

  function ChangeWithdrawalLimitCtrl($log, $state, $rootScope, HelperService, AccountsService, AccountLimitOrderService, AccountLimitOrderPeriodTypeService, AccountLimitOrderNotificationTypeService, AccountLimitOrderEffectiveTypeService, UserSettingsService, $window, AlertService, $translate, $localStorage, NotificationService) {
    var vm = this;
    vm.ctrlName = 'ChangeWithdrawalLimitCtrl';
    vm.selectedAccount = {};
    vm.tempObj = {};
    vm.insertedOrderObject = {};

    vm.formSubmitted = false;
    vm.currencySymbol = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
    vm.confirmchangeWithdrawalBtnDisabled = true;

    vm.timeLimits = [];
    vm.typeOptions = [];
    vm.notificationTypeOptions = [];

    vm.imAware = false;
    vm.tempObj.amount = "";
    vm.step = 1;
    vm.goBackToAccount = function () {
      $state.go('accounts', {
        accountId: $state.params.accountId
      });
    };

    vm.loadAccount = function () {
      AccountsService.getAccountById($state.params.accountId)
        .then(function (response) {
          vm.selectedAccount = response;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.validateCashWithdrawing = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if (vm.changeWithdrawalForm.$valid) {
        vm.insertedOrderObject = {
          "account": vm.selectedAccount.iban.split(" ").join(""),
          "amount": vm.tempObj.amount,
          "effective": {
            "id": vm.tempObj.typeOption.id,
            "description": vm.tempObj.typeOption.description
          },
          "period": {
            "id": vm.tempObj.timeLimit.id,
            "description": vm.tempObj.timeLimit.description
          },
          "emailAddress": vm.userSettings.notificationEmail,
          "notificationType": {
            "id": vm.tempObj.notificationTypeOption.id,
            "description": vm.tempObj.notificationTypeOption.description
          },
          "note": " "
        };
        vm.nextButtonPressed = true;
        AccountLimitOrderService.validateOrder(vm.insertedOrderObject)
          .then(function (response) {
            vm.insertedOrderObject = angular.extend(response);
            vm.step = 2;
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          })
          .finally(function () {
            vm.nextButtonPressed = false;
          });
      }

    };
    vm.confirmCashWithdrawing = function () {
      if (vm.confirmButtonPressed) return;
      vm.confirmButtonPressed = true;
      AccountLimitOrderService.insertOrder(vm.insertedOrderObject)
        .then(function (response) {
          $log.debug("AccountLimitOrderService.insertOrder inserted object ", response);
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              $window.history.back();
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function (){
        vm.confirmButtonPressed = false;
      });
    };
    vm.editCashWithdrawing = function () {
      vm.step = 1;
      vm.imAware = false;
    };
    vm.goBackToAccount = function () {
      $window.history.back();
    };

    vm.loadAccountLimitOrderTypes = function () {
      AccountLimitOrderEffectiveTypeService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          $log.debug("AccountLimitOrderEffectiveTypeService.getPage result", response);
          vm.typeOptions = response.result;
          vm.tempObj.typeOption = vm.typeOptions[1];
        })
        .catch(function (error) {
          $log.error(error);
        });

      AccountLimitOrderNotificationTypeService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          $log.debug("AccountLimitOrderNotificationTypeService.getPage result", response);
          vm.notificationTypeOptions = response.result;
          vm.tempObj.notificationTypeOption = vm.notificationTypeOptions[0];
        })
        .catch(function (error) {
          $log.error(error);
        });

      AccountLimitOrderPeriodTypeService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          $log.debug("AccountLimitOrderPeriodTypeService.getPage result", response);
          // vm.timeLimits = response.result;
          vm.timeLimits = _.sortBy(response.result, function(time) {
            return parseInt(time.id.replace(/\D+/g, ''));
          });
          vm.tempObj.timeLimit = vm.timeLimits[0];
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    vm.userSettings = {};


    var init = function () {
      vm.loadAccount();
      vm.loadAccountLimitOrderTypes();
      UserSettingsService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          vm.userSettings = response.result[0];
          $log.debug("userSettings", vm.userSettings);
        })
        .catch(function (error) {
          $log.error(error);
        });

    };
    var lastSelectedLanguage = $localStorage.appLanguage;
    $rootScope.$on('$translateChangeEnd', function (evt, data) {
      if (lastSelectedLanguage != data.language) {
        vm.loadAccountLimitOrderTypes();
      }
      lastSelectedLanguage = data.language;
    });

    init();
  }
}());
