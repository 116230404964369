(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name secure3d.controller:Secure3dCtrl
   *
   * @description
   *
   */
  angular
    .module('secure3d')
    .controller('Secure3dCtrl', Secure3dCtrl);

  function Secure3dCtrl($window) {
    var vm = this;
    vm.ctrlName = 'Secure3dCtrl';

    vm.goToLink = function () {
      $window.open('https://sigurna-kupnja.addiko.hr/card-management/', '_blank');
    }
  }
}());
