(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "Putem Viber aplikacije možete aktivirati Addiko Chat Banking uslugu",
        activationWay: "Izaberite način aktivacije",
        scanQrCode: "Skeniranje QR koda",
        activationCode: "Aktivacijski kod",
        agree: "Prihvatam Opšte uslove/Opće uvjete korištenja Addiko EBank usluge",
        activate: "Aktiviraj",
        deactivateText: "Vaš nalog je trenutno aktivan",
        deactivate: "Deaktiviraj",
        deactivateModalText: "Da li ste sigurni da želite deaktivirati uslugu Addiko Chat Banking?",
        cancel: "Odustani",
        activationSuccess: "Usluga Addiko Chat Banking je uspješno deaktivirana.",
        viberCondition1: "Opšti uslovi korištenja za Addiko EBank Addiko Bank ",
        viberCondition2: "primjenjuju se i na usluge koje Korisnik obavlja korištenjem usluge Addiko Chat Banking. Viber nalog, koji će Korisnik koristiti za pristup ovoj usluzi Banke, biće korišten u skladu sa ",
        viberCondition3: "uslovima i politikom Viber aplikacije",
        viberCondition4: ", za koju je Korisnik već dao saglasnost tokom prijave na Viber servis.",
        scanQrCodeC1: "Skenirajte prikazani QR kod putem Viber aplikacije (opcija 'More', 'QR code scanner').",
        scanQrCodeC2: "Nakon skeniranja, automatski ćete biti preusmjereni na javni Viber nalog Addiko banke a aktivacioni kod će biti popunjen u poruci. Pošaljite poruku sa popunjenim aktivacionim kodom.",
        scanQrCodeC3: "Primićete SMS sa verifikacijskim kodom na broj telefona koji ste ostavili u Banci za ebanking/mbanking uslugu.",
        scanQrCodeC4: "Unesite jednokratnu lozinku iz SMS poruke u Viber poruku i pošaljite je.",
        scanQrCodeC5: "Izaberite 4-cifreni PIN a zatim ponovite njegov unos kao potvrdu. Aplikacija prihvata unos PINa broj po broj (ne odjednom 4 broja), uz obavijest o isporuci svakog pojedinačnog.",
        scanQrCodeC6: "Ovaj PIN ćete ubuduće koristiti za pristup aplikaciji Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala.",
        activationCodeC1: "Pronadjite javni Viber nalog Addiko banke na svojoj Viber aplikaciji i izaberite opciju da postanete pratilac tog naloga.",
        activationCodeC2: "Pokrenite chat opciju.",
        activationCodeC3: "U dnu ekrana uredjaja koji koristite za Viber, nalaze se komande Addiko Chat Banking aplikacije. Pokrenite komandu 'Aktiviraj'.",
        activationCodeC4: "Unesite aktivacioni kod sa Addiko Ebank stranice, u Viber poruku. Pošaljite poruku sa popunjenim aktivacionim kodom.",
        activationCodeC5: "Primićete SMS sa verifikacijskim kodom na broj telefona koji ste ostavili u Banci za ebanking/mbanking uslugu.",
        activationCodeC6: "Unesite jednokratnu lozinku iz SMS poruke u Viber poruku i pošaljite je.",
        activationCodeC7: "Izaberite 4-cifreni PIN a zatim ponovite njegov unos kao potvrdu. Aplikacija prihvata unos PINa broj po broj (ne odjednom 4 broja), uz obavijest o isporuci svakog pojedinačnog.",
        activationCodeC8: "Ovaj PIN ćete ubuduće koristiti za pristup aplikaciji Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala.",
        yourActivationCode: "Vaš kod za aktivaciju:",
        activeMessage: 'Vaš Addiko Chat Banking nalog je aktivan.',
        mobileNumber: 'Broj telefona za koji je vezana usluga',
        activationDate: 'Datum aktivacije usluge',
        step1othBL: 'Odaberite opciju „Postani pratilac“. Nakon toga ćete primiti SMS poruku sa linkom, koji će Vas preusmjeriti direktno na javni nalog Addiko Bank Banja Luka na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime postajete pratilac našeg Addiko Bank javnog naloga.',
        step1othSA: 'Odaberite opciju „Postani pratilac“. Nakon toga ćete primiti SMS poruku sa linkom, koji će Vas preusmjeriti direktno na javni nalog Addiko Bank Sarajevo na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime postajete pratilac našeg Addiko Bank javnog naloga.',
        step2oth: 'Vratite se u Addiko EBank i započnite aktivaciju usluge, odabirom opcije „Aktiviraj“. Nakon toga ćete primiti SMS poruku sa aktivacionim linkom, koji će Vas direktno preusmjeriti  na Addiko Chat Banking na Viberu. Kroz Viber aplikaciju pošaljite aktivacioni kod, koji će automatski biti upisan u polje Viber poruke.',
        step3oth: 'Nakon uspješne potvrde aktivacionog koda, primićete SMS sa verifikacionim kodom. Verifikacioni kod potom upišite u polje za poruke u Addiko Chat Bankingu i pošaljite kroz Viber aplikaciju.',
        step4oth: 'Nakon uspješne potvrde verifikacionog koda kreirajte Vaš lični PIN, unutar Addiko Chat Bankinga. Ovaj PIN ćete ubuduće koristiti prilikom svake prijave i potvrde plaćanja, realizovane putem usluge Addiko Chat Banking.',
        becomeFollower: 'Postani pratilac',
        conditionHeader: "Bankarite putem Viber aplikacije i chata, jednostavno i brzo.",
        conditionHeader2: 'Sa uslugom Addiko Chat Banking možete:',
        text1: 'Pregledati stanja i vršiti plaćanje',
        text2: 'Pregledati naloge za plaćanje',
        text3: 'Pronaći najbližu Addiko poslovnicu i bankomat',
        text4: 'Najbrže saznati sve Addiko novosti',
        successfulActivation: 'Uspješna aktivacija',
        unsuccessfulActivation: 'Neuspješna aktivacija',
        smsSent: 'Sms poruka je uspešno poslana. Molimo Vas da kliknete na link koji smo Vam poslali u poruci.',
        becomeFollowerAlert: "Ako ste već pratilac Addiko javnog profila na Viberu, ovaj korak nije potreban. Želite li preskočiti ovaj korak i nastaviti s opcijom 'Aktiviraj'?",
        becomeFollowerAlertBL: "Ako ste već pratilac javnog naloga Addiko Bank Banja Luka na Viberu, ovaj korak nije potreban. Želite li preskočiti ovaj korak i nastaviti sa opcijom 'Aktiviraj'?",
        becomeFollowerAlertSA: "Ako ste već pratilac javnog naloga Addiko Bank Sarajevo na Viberu, ovaj korak nije potreban. Želite li preskočiti ovaj korak i nastaviti sa opcijom 'Aktiviraj'?",
        yes: "Da",
        no: "Ne"

      }
    };

    $translateProvider.translations('bh', data);

  }
} ());
