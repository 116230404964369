(function () {
  'use strict';

  angular
    .module('template')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('template', {
        url: '/template',
        templateUrl: 'template/template.tpl.html',
        controller: 'TemplateCtrl',
        controllerAs: 'template'
      });
  }
}());
