(function () {
    'use strict';

    angular
        .module('orders')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('orders', {
                url: '/orders',
                templateUrl: 'orders/orders.tpl.html',
                controller: 'OrdersCtrl',
                controllerAs: 'vm'
            })
            .state('orders.termDeposit', {
                url: '/term-deposit',
                views: {
                    '@': {
                        templateUrl: 'orders/term-deposit.tpl.html',
                        controller: 'TermDepositCtrl',
                        controllerAs: 'termDeposit'
                    }
                }
            })
          .state('orders.newSavingsOrder', {
                url: '/savings-order',
                views: {
                    '@': {
                        templateUrl: 'orders/new-savings-order-slo.tpl.html',
                        controller: 'NewSavingsOrderCtrlSlo',
                        controllerAs: 'savingsAccount'
                    }
                }
            })
            .state('orders.cancelDeposit', {
                url: '/cancel-deposit/:accountId',
                views: {
                    '@': {
                        templateUrl: 'orders/cancel-deposit.tpl.html',
                        controller: 'CancelDepositCtrl',
                        controllerAs: 'cancelDeposit'
                    }
                }
            })
            .state('orders.addNewExpressLoan', {
                url: '/express-loan',
                views: {
                    '@': {
                        templateUrl: 'orders/add-new-express-loan.tpl.html',
                        controller: 'AddNewExpressLoanCtrl',
                        controllerAs: 'addNewExpressLoan'
                    }
                }
            });
    }
}());
