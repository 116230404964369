(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:HumanitarianService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('HumanitarianService', HumanitarianService);

  function HumanitarianService($q, EndpointsService, $log, $http) {
    var HumanitarianServiceBase = {};

    HumanitarianServiceBase.getAll = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $log.debug("Params:", params);
        $http.get(response.links.payments_humanitarianPayments)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    HumanitarianServiceBase.getById = function (id){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.payments_forHumanitarianPayment_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    return HumanitarianServiceBase;
  }
}());
