(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      navigation: {
        "dashboard": "Početna strana",
        "accountsAndCards": "Računi i Kartice",
        "accounts": "Računi",
        "payments": "Plaćanja",
        "deposit": "Štednja",
        "loans": "Krediti",
        "investments": "Investicije",
        "settings": "Podešavanja",
        "messages": "Poruke",
        "hypoClub": "Addiko Club",
        "eTrade": "Addiko ETrade",
        "paymentsAndTransfers" :"Pregled plaćanja i prenosa",
        "domesticPayment": "Nalog za plaćanje",
        "transferToMyAccount": "Prenos na moj račun",
        "internationalPayment": "Internacionalno plaćanje",
        "exchangeOffice" :"Menjačnica",
        "exchangeRates" :"Valutni kurs",
        "standingOrders": "Trajni nalog",
        "eInvoices": "eRačuni",
        "paymentsList": "Poslati nalozi",
        "paymentsReturn": "Povrat plaćanja",
        "directDebitsList": "Lista SEPA izravnih dugovanja",
        "myTemplatesList": "Moji šabloni",
        "allAccountsOverview": "Pregled računa i kartica",
        "allDepositOverview": "Sve štednje",
        "allDeposits": "Štednje",
        "allDepositOverviewSerbMne": "Štednja",
        "loansOverview": "Pregled kredita",
        "investmentsOverview": "Pregled investicija",
        "availableBalance": "Raspoloživi iznos",
        "loanBalance": "Stanje kredita",
        "newMessages": "Nove poruke: ",
        "contact": "Kontakt",
        "allowedLoan":"Odobreni iznos",
        "balance": "Stanje računa",
        "newOrder":"Kupnja udjela",
        "viberBanking":"Addiko Chat Banking",
	      "complaintsLink": "Predlozi, prigovori i sugestije",
      }
    };

    $translateProvider
      .translations('sr', data);

  }
}());

