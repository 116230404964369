(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      eInvoicesModal: {
        "headerLabel": "Plaćanje eRačuna",
        "instructionLabel": "Odaberite datum naloga i potvrdite plaćanje",
        "issuerLabel": "Nalogodavac:",
        "purposeLabel": "Opis plaćanja:",
        "amountLabel": "Iznos:",
        "refNumberLabel": "Poziv na broj primatelja:",
        "paymentDateLabel": "Datum plaćanja",
        "today": "Današnji datum",
        "dueDate": "Rok za plaćanje",
        "otherDate": "Drugi datum",
        "btnCancel": "Odustani",
        "btnPay": "Plati eRačun",
        "subscribeOrCancel": "Pretplati se na novi eRačun ili otkaži postojeću pretplatu",
        "submitted": "Podneseni zahtjevi",
        "showIssued": "Prikaži eRačune izdane ",
        "showAuthorizedEinvoices": "Prikaži eRačune na ovlaštenim računima",
        "waitingForPayment": "Neplaćeni eRačuni",
        "payedAndRemoved": "Plaćeni i uklonjeni eRačuni",
        "loadMoreEinvoices":"Prikaži još eRačuna",
        "einvoices": "eRačuni",
        "view": "Prikaži",
        "pay": "Plati",
        "noData": "Za odabrani period nema podataka za prikaz",
        "fromAccountPayLabel": "Uplati s računa: ",
        "availableAmountLabel": "Raspoloživi iznos: ",
        "viewEinvoice": "Prikaži eRačun",
        "amountPlaceholder": "npr. 1.000,00",
        "btnSign": "Potpiši",
        "btnEdit": "Uredi",
        "signHeaderLabel": "Provjerite i potpišite eRačun",
        "fromAccount": "Račun pošiljatelja",
        "toAccount": "Račun primatelja",
        "receiver": "Primalac",
        "receiverAddress": "Adresa primatelja",
        "amount": "Iznos",
        "description": "Opis",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Adresa banke",
        "enterComment": "Unesite komentar",
        "delete": "Obriši",
        "edit": "Promijeni",
        "sign": "Potpiši"
      },
      eInvoiceDetail: {
        "attachments": "Privici",
        "eInvoicePDF": "E-invoice u PDF formatu",
        "eInvoiceXML": "E-invoice u XML formatu",
        "export": "Preuzimanje",
        "unsubscribeEInvoices": "Otkažite pretplatu na eRačun",
        "detail": "Detaljan pregled eRačuna",
        "unpaidInvoice": "Neplaćena faktura",
        "pay": "Plati",
        "markAsPayed": "Označi kao plaćeno/arhiviraj",
        "issuer": "Izdavatelj",
        "issuerAccount": "Račun izdavatelja",
        "purpose": "Svrha",
        "refNumber": "Poziv na broj primatelja",
        "amount": "Iznos",
        "issueDate": "Datum izdavanja",
        "purposeCode": "Šifra namjene",
        "dueDate": "Datum dospijeća",
        "digitalSignature": "Status digitalnog potpisa",
        "visibilityDigitalSignature": "Informacije o digitalnom potpisu",
        "signatureId": "ID potpisa",
        "digitalCertNumber": "Broj digitalnog certifikata",
        "signatureName": "Naziv potpisa",
        "certificateIssuer": "Izdavatelj digitalnog certifikata",
        "showInfo": "Prikaži ",
        "hideInfo": "Sakrij ",
        "recipient": "Primalac",
        "recipientAccount": "Račun primatelja",
        "noAttachments": "eRačun nema priloga.",
        "goBack": "Povratak na eRačune",
        "note": "Napomena",
        "download": "Preuzmi PDF"
      },
      eInvoicesIssuersOverview: {
        "submittedApplications": "Aplikacije na koje ste pretplaćeni",
        "header": "Odaberi izdavatelja eRačuna",
        "issuerName": "Naziv izdavatelja",
        "issuerTaxId": "Porezni broj izdavatelja",
        "searchByNamePlaceholder": "Pretraži po nazivu",
        "searchByTaxIdPlaceholder": "Pretraži po poreznom broju",
        "showMore": "Prikaži još izdavatelja",
        "subscribe": "Pretplata",
        "issuerTax": "Porezni broj / Račun",
        "issuerAddress": "Adresa izdavatelja",
        "noData": "Za odabrani period nema podataka za prikaz"
      },
      eInvoicesSubscribe: {
        "goBack": "Povratak na prethodnu stranicu",
        "subscribeToEinvoice": "Pretplati se na eRačun",
        "einvoiceIssuer": "Izdavatelj eRačuna",
        "issuerAddress": "Adresa izdavatelja",
        "issuerAccount": "Račun izdavatelja",
        "referenceFromIssuer": "Poziv na broj",
        "einvoiceReceiver": "E-Invoice receiver",
        "receiversName": "Receivers name",
        "receiversAddress": "Receivers address",
        "receiversTaxId": "Receivers Tax ID",
        "yourContactEmail": "Your contact email",
        "tooltipInfo": "Trebate pomoć?",
        "tooltipInfoEmail": "Ukoliko radite promjenu emaila, ona se odnosi samo na registraciju usluge eRačun.",
        "einvoiceReferenceFromIssuerInputError": "Poziv na broj je obavezan!",
        "einvoiceSubscribeEmailInputError": "Vaš kontakt email je obavezan!",
        "reviewandSubmit": "Pregledajte i pošaljite",
        "changeEmail": "Promijeni email",
        "cancel": "Odustani",
        "ok": "OK",
        "termsAndConditions": "Suglasan sam da računi više ne dolaze u papirnatom obliku",
        "specialConditions": "Prihvaćam posebne uvjete izdavatelja"
      },
      eInvoiceStatus:{
        "InProcess":"U obradi",
        "Unregistered":"Neregistrirano",
        "Rejected":"Odbijeni"
      }
    };

    $translateProvider
      .translations('mne', data);
  }
}());
