<div class="modal-header">
  <h3 translate="settings.unsubscribeAlertHeader"></h3>
</div>
<div class="modal-body text-center">
  <p translate="settings.unsubscribeText"></p>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-default" ng-click="$close(false)"><span
        translate="alert.no"></span></button>
      <button class="btn btn-default" ng-click="$close(true)"><span
        translate="alert.yes"></span></button>
    </div>
  </div>
</div>
