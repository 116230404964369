<section id="main">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <h2 class="addiko-label f-28 mt-1" translate="common.addikoBankLocation"></h2>
                <br>
                <span class="addiko-label f-16">{{vm.configData.BankCity}}</span>
                <span class="addiko-label f-16">{{vm.configData.BankAddressDetail}}</span>
                <span class="addiko-label f-16 mb-3">Tel. {{vm.configData.LocalCall}}</span>
                <div class="map-container">
                    <div map-lazy-load="https://maps.google.com/maps/api/js" map-lazy-load-params="{{vm.googleMapsUrl}}">
                        <ng-map disable-default-u-i="true" disable-double-click-zoom="true" scroll-wheel="false"
                            style="height: 400px; width: 600px;" center="{{vm.center.lat}},{{vm.center.lng}}" zoom="12">
                            <marker id="foo" position="{{vm.center.lat}},{{vm.center.lng}}" on-click="vm.openInfoWindow()">
                            </marker>
                            <info-window id="info-window" header-content="Addiko Bank">
                                <div ng-non-bindable>
                                    <span class="addiko-label w-60">{{vm.configData.BankAddressDetail}}</span><br>
                                    <a class="color-link" target="_blank" href="{{vm.configData.BankMapsLink}}"
                                        translate="common.openInGoogleMap"></a>
                                </div>
                            </info-window>
                        </ng-map>
                    </div>
                </div>


            </div>

        </div>
    </div>
</section>