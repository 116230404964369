<section id="main" ng-controller="TermDepositCtrlSlo as termDeposit">
  <div ng-show="termDeposit.isPageLoaded" class="container-fluid clearfix f-14 m-t-25 m-b-20">
    <div ng-show="!termDeposit.steps.step3">
      <div class="row">
        <div class="col-md-12">
          <h2 class="title-style m-t-0 m-b-20" translate="termDeposit.termDepositHeader"></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info" style="background: white; color: black; border: 1px solid lightgray"
               role="alert">
            <p class="bold-font" translate="termDeposit.taxationOfInterestTitle"></p>
            <p translate="termDeposit.taxationOfInterestMsg">
            <div class="row p-t-10 p-l-15 p-r-15">
              <div class="col-xs-4 p-0"><a href="https://www.addiko.si/varcevanja" target="_blank" class="underline">{{
                'termDeposit.headerLink1' | translate}}</a></div>
              <div class="col-xs-4 p-0"><a href="https://www.addiko.si/addiko-obrestne-mere" target="_blank" class="underline">{{
                'termDeposit.headerLink2' | translate}}</a></div>
              <div class="col-xs-4 p-0"><a href="https://www.bsi.si/financna-stabilnost/jamstvo-za-vloge-v-bankah" target="_blank"
                                           class="underline">{{ 'termDeposit.headerLink3' | translate}}</a></div>
            </div>
            </p>
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 1 ==== -->
    <!-- ================= -->
    <div ng-form="termDeposit.termDepositForm" class="clearfix bg-white" ng-show="termDeposit.steps.step1" novalidate>
      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-md-5">
            <label class="ntd-form-label compact"
                   translate="termDeposit.depositType">
            </label>
            <select name="depositType"
                    ng-options="key for (key, value) in termDeposit.depositOrderGroupOptions"
                    value="{{key}}"
                    ng-model="termDeposit.depositGroup"
                    ng-change="termDeposit.onDepositGroupChange(termDeposit.depositGroup)"
                    class="form-control h-45"
                    required>
            </select>
            <span class="c-red"
                  ng-show="termDeposit.termDepositForm.depositType.$error.required && termDeposit.formSubmitted"
                  translate="core.requiredField"></span>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-5">
            <label class="ntd-form-label compact" translate="termDeposit.debitAccountSelectLabel"></label>
            <div class="accounts-dropdown m-b-10" ng-if="termDeposit.accountsFrom.length > 0">
              <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                   data-ng-click="termDeposit.toggleFromAccount();"
                   ng-style="termDeposit.model.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + termDeposit.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <h4 class="no-wrap-text bold-font m-b-0">
                  <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{termDeposit.model.fromAccountSelect.friendlyName}}</b>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                    <i class="zmdi"
                       ng-class="{'zmdi-star favorite-account':termDeposit.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!termDeposit.model.fromAccountSelect.isFavorite}"></i></span>
                </h4>
                <p class="m-b-0 m-t-0 iban-style">
                  {{termDeposit.model.fromAccountSelect.iban | formatIban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{termDeposit.model.fromAccountSelect.availableBalance | number:2}}
                  {{termDeposit.model.fromAccountSelect.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
              </div>
              <div class="from-accounts-list m-l-15" ng-show="termDeposit.fromAccountDropdown" scrollable>
                <div class="accounts-list-item p-0" data-ng-repeat="item in termDeposit.accountsFrom"
                     data-ng-click="termDeposit.setAccountFrom(item);">

                  <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                       ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                          ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{item.availableBalance | number:2}} {{item.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accounts-dropdown m-b-10"
                 ng-if="!termDeposit.accountsFrom.length || termDeposit.accountsFrom.length === 0">
              <!--<div class="debit-account-widget dashboard-widget-item" style="height: 114px; line-height: 114px" translate="internalTransfer.noData"  ng-style="termDeposit.model.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + termDeposit.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  No available accounts
              </div>-->
              <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                   ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <div class="m-t-30 m-b-30">
                  <p translate="internalTransfer.nodata"></p>
                </div>
              </div>
            </div>

            <div>
              <span class="c-red" ng-show="termDeposit.termDepositForm.amount.$error.currency">
                    <span translate="termDeposit.wrongDepositCurrency"></span>
              </span>
            </div>
          </div>

          <div class="col-md-7">
            <div class="form-group clearfix m-t-10">
              <div class="col-sm-6 col-xs-12">
                <div class="col-md-12 p-0">
                  <label class="ntd-form-label compact" translate="termDeposit.termDepositDuration"></label>
                </div>
                <div class="col-md-5 col-xs-5 p-0">
                  <input class="form-control t-a-r h-45" maxlength="6"
                         name="maturityInDays"
                         ng-show="!termDeposit.durationReadonly"
                         ng-pattern-restrict="{{termDeposit.regexPatterns.onlyNumbers}}"
                         ng-blur="termDeposit.calculateMaturityDateWithDays(termDeposit.model.maturityInDays)"
                         ng-model="termDeposit.model.maturityInDays"
                         required/>
                  <input class="form-control t-a-r h-45" maxlength="6"
                         name="maturityInDays"
                         ng-show="termDeposit.durationReadonly"
                         ng-value="termDeposit.model.maturityInDays"
                         readonly
                         required/>
                  <label class="ntd-form-label compact pull-right"
                         translate="termDeposit.durationInDays">
                  </label>
                </div>
                <div class="col-md-2 col-xs-2 p-10 text-center">
                  <label class="ntd-form-label compact"
                         translate="termDeposit.durationOr">
                  </label>
                </div>
                <div class="col-md-5 col-xs-5 p-0">

                  <input class="form-control t-a-r h-45" maxlength="3"
                         name="maturityInMonths"
                         ng-show="!termDeposit.durationReadonly"
                         ng-pattern-restrict="{{termDeposit.regexPatterns.onlyNumbers}}"
                         ng-blur="termDeposit.calculateMaturityDate(termDeposit.model.maturityInMonths)"
                         ng-model="termDeposit.model.maturityInMonths"
                         ng-readonly="termDeposit.durationReadonly"/>
                  <input class="form-control t-a-r h-45" maxlength="3"
                         name="maturityInMonths"
                         ng-show="termDeposit.durationReadonly"
                         ng-value="termDeposit.model.maturityInMonths"
                         readonly/>
                  <label class="ntd-form-label compact pull-right"
                         translate="termDeposit.durationInMonths">
                  </label>
                </div>
                <div class="col-md-12 col-xs-12 p-0">

                <span class="c-red"
                      ng-show="termDeposit.depositTypeGroup == 1 &&
                                termDeposit.termDepositForm.maturityInDays.$error.duration &&
                                (termDeposit.termDepositForm.maturityInDays.$dirty || termDeposit.termDepositForm.maturityInMonths.$dirty) &&
                                termDeposit.termDepositDurationMessageShown">
                      <span translate="termDeposit.wrongDepositDuration"></span>
                </span>
                  <span class="c-red"
                        ng-show="termDeposit.depositTypeGroup == 2 &&
                termDeposit.termDepositForm.maturityInDays.$error.duration &&
                (termDeposit.termDepositForm.maturityInDays.$dirty || termDeposit.termDepositForm.maturityInMonths.$dirty) &&
                termDeposit.termDepositDurationMessageShown">
                <div>
                  <!--<span translate="termDeposit.durationRastociDepozitMsg"></span>-->
                  <span translate="">{{'termDeposit.durationZlataJesenMsg' | translate : {min: termDeposit.periods.min, max: termDeposit.periods.max} }}</span>

                </div>
                </span>
                  <span class="c-red"
                        ng-show="termDeposit.depositTypeGroup == 3 &&
                                termDeposit.termDepositForm.maturityInDays.$error.duration &&
                                (termDeposit.termDepositForm.maturityInDays.$dirty || termDeposit.termDepositForm.maturityInMonths.$dirty) &&
                                termDeposit.termDepositDurationMessageShown">
                    <span translate="">{{'termDeposit.durationZlataJesenMsg' | translate : {min: termDeposit.periods.min, max: termDeposit.periods.max} }}</span>
                </span>

                  <!--<span class="c-red" ng-show="termDeposit.termDepositForm.maturityInMonths.$error.minvalue">-->
                  <!--<span translate="termDeposit.minDepDurationErr"></span>-->
                  <!--{{termDeposit.minDurationInDays}}-->
                  <!--<span translate="termDeposit.days"></span>-->
                  <!--</span>-->
                  <!--<span class="c-red" ng-show="termDeposit.termDepositForm.maturityInMonths.$error.maxvalue">-->
                  <!--<span translate="termDeposit.maxDepDurationErr"></span>-->
                  <!--{{termDeposit.maxDurationInDays}}-->
                  <!--<span translate="termDeposit.days"></span>-->
                  <!--</span>-->
                </div>
              </div>

              <div class="col-sm-6 col-xs-12">
                <label class="ntd-form-label compact"
                       translate="termDeposit.maturityDate">
                </label>
                <div class="input-group">
                  <input type="text" class="form-control t-a-r h-45"
                         name="maturityDate"
                         ng-model="termDeposit.model.maturityDate"
                         uib-datepicker-popup="shortDate"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         datepicker-options="termDeposit.dateOptions"
                         show-weeks="false" date-disabled="termDeposit.disabled(date, mode)"
                         is-open="termDeposit.isDatepickerOpen"
                         ng-change="termDeposit.calculateMaturityDays(termDeposit.model.maturityDate)"
                         readonly>
                  <span class="input-group-btn">
                    <button data-ng-click="termDeposit.isDatepickerOpen = !termDeposit.isDatepickerOpen" type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                            style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                </div>

                <span class="c-red"
                      ng-show="termDeposit.termDepositForm.$error.dateDisabled">
                  <span translate="orders.maturityDateNonWorkingErr"></span>
              </span>
              </div>
            </div>
            <div class="form-group clearfix m-t-10">
              <div class="col-md-6 col-xs-12">
                <label class="ntd-form-label compact" translate="termDeposit.amountInputLabel"></label>
                <div class="input-group">
                  <input required type="text" class="form-control h-45 t-a-r"
                         name="amount"
                         amount-input-mask
                         placeholder="{{'accounts.accEg' | translate}}: 000.000,00"
                         maxlength="14" max-amount="99999999999.99"
                         ng-model="termDeposit.model.amount"
                         ng-blur="termDeposit.amountChanged(termDeposit.model.amount)"
                         required/>
                  <span class="input-group-btn">
                      <!--<select type="button"-->
                    <!--class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero p-5"-->
                    <!--style="border:1px solid #062a42;"-->
                    <!--ng-model="termDeposit.model.currency"-->
                    <!--ng-options="item.symbol as item.symbol for item in termDeposit.currenciesList">-->
                    <!--</select>-->
                      <button
                        class="btn btn-default input-group-app-btn dark-border domp-btn-height disabled z-zero p-5"
                        style="pointer-events: none; border: 1px solid">
                        {{termDeposit.model.currency}}
                      </button>
                    </span>
                </div>
                <div>
                  <span class="c-red"
                        ng-show="termDeposit.termDepositForm.amount.$invalid && termDeposit.termDepositForm.maturityInDays.$valid">
                        <!--<span translate="termDeposit.wrongDepositAmount"></span>-->
                        <span class="c-red"
                              ng-show="
                              termDeposit.termDepositForm.amount.$touched &&
                              termDeposit.termDepositForm.amount.$error.minamount &&
                              termDeposit.termDepositForm.maturityInDays.$valid">
                            <span translate="termDeposit.wrongMinDepositAmount"></span>
                            {{termDeposit.model.depositType.minAmount | currency: ""}} {{termDeposit.model.currency}}.
                        </span>
                        <span class="c-red"
                              ng-show="termDeposit.termDepositForm.amount.$error.maxAmount && termDeposit.termDepositForm.maturityInDays.$valid">
                            <span translate="termDeposit.wrongMaxDepositAmount"></span>
                            {{ 99999999999.99 | currency: ""}} {{termDeposit.model.currency}}.
                        </span>
                  </span>

                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <label class="ntd-form-label compact"
                       translate="termDeposit.totalInterestRateInputLabel">
                </label>
                <input type="text" class="form-control t-a-r  h-45" readonly
                       ng-value="(termDeposit.model.totalInterestRate | formatDecimal : 4 : true : 3) + ' %'">
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-7 col-xs-md-12 col-md-offset-5 col-xs-offset-0 ">
            <div ng-if="termDeposit.assignmentOfInterestisShown" class="form-group col-md-6 col-xs-12">
              <label class="ntd-form-label compact"
                     translate="termDeposit.assignmentOfInterest">
              </label>
              <select class="form-control h-45"
                      ng-options="option.id as option.value | translate for option in termDeposit.assignmentOfInterestOptions"
                      ng-model="termDeposit.assignmentOfInterestId"
                      style="cursor: auto !important"
                      ng-disabled="termDeposit.assignemntOfInterestIsDisabled || termDeposit.depositTypeGroup == 2">
              </select>
            </div>
            <div class="form-group col-md-6 col-xs-12">
              <label class="ntd-form-label compact"
                     translate="termDeposit.interestPayout">
              </label>
              <select class="form-control h-45"
                      ng-model="termDeposit.model.interestPayout"
                      ng-options="option as option.value | translate for option in termDeposit.interestPayoutOptions"
                      ng-change="termDeposit.onInterestPayoutChange(termDeposit.model.interestPayout.id)"
                      ng-disabled="termDeposit.interestPayoutDisabled || termDeposit.depositTypeGroup == 2"
                      style="cursor: auto !important"
                      required>
              </select>
            </div>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-12">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
              <input id="userApproves" type="checkbox" ng-model="termDeposit.userApproves" required>
              <i class="input-helper"></i>
              <a translate="termDeposit.termsAndConditions"></a>
              <!--<a ng-class="{'underline': termDeposit.model.depositType.periodType}"-->
                 <!--ng-href="{{termDeposit.model.depositType.depositTermsAndConditionsLink}}" target="_blank"-->
                 <!--translate="termDeposit.termsAndConditions1"></a>  -->
              <a class="underline"
                 href="
                 {{(termDeposit.depositGroup[0].group == 2) ? 'https://www.addiko.si/splosni-pogoji-addiko-rastoci-depozit/' :
                   (termDeposit.depositGroup[0].group == 1 || termDeposit.depositGroup[0].group == 3) ? 'https://www.addiko.si/splosni-pogoji-addiko-depozitov-fizicnih-oseb/' :
                  ''
                  }}"
                 target="_blank"
                 translate="termDeposit.termsAndConditions1"></a>
              <!--<span ng-if="termDeposit.depositGroup[0].group == 3" translate="termDeposit.termsAndConditions1"></span>-->
              <a translate="termDeposit.termsAndConditions2"></a>
            </label>
          </div>
        </div>
        <!--<div class="row m-t-10" ng-if="termDeposit.confirmChangedMaturityDateShow">-->
        <!--<div class="col-md-12">-->
        <!--<label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">-->
        <!--<input id="userApproves" type="checkbox" ng-model="termDeposit.userApprovesMaturityChange" required>-->
        <!--<i class="input-helper"></i><a translate="orders.maturityDateChangedConfirm"></a>-->
        <!--</label>-->
        <!--</div>-->
        <!--</div>-->
      </div>

      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary"
                    translate="termDeposit.cancelBtnLabel"
                    data-ui-sref="depositAccountsOverview">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button class="btn btn-default"
                    translate="orders.next"
                    ng-click="termDeposit.next();"
                    ng-disabled="termDeposit.nextButtonPressed || (termDeposit.termDepositForm.$invalid || !termDeposit.userApproves || !termDeposit.model.depositType)">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 2 ==== -->
    <!-- ================= -->
    <div class="clearfix bg-white" ng-if="termDeposit.steps.step2">
      <div class="intp-form-container bottom-border">
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.vrstaDepozita"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.responseModel.depositType.typeName}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.debitAccountSelectLabel"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.responseModel.fromAccountSelect.iban | formatIban}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.maturityDate"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.responseModel.maturityDate | date: 'shortDate' : '+0200'}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.termDepositDuration"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.model.maturityInDays}} <label translate="termDeposit.durationInDays"></label>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.amountInputLabel"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.responseModel.amount | currency:""}} {{termDeposit.responseModel.currency}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.totalInterestRateInputLabel"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
              {{termDeposit.model.totalInterestRate | formatDecimal : 4 : true : 3}} %
            </label>
            <label class="domp-form-label-resume" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
              {{termDeposit.model.totalInterestRate | currency: ''}} %
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.assignmentOfInterest"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.model.interestRange.value | translate}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="termDeposit.interestPayout"></label>
          </div>
          <div class="col-md-8">
            <label class="domp-form-label-resume">
              {{termDeposit.model.interestPayout.value | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="intp-form-container">
        <div class="row">
          <div class="col-xs-6 t-a-l">
            <button class="btn btn-primary"
                    translate="orders.back"
                    ng-click="termDeposit.back()">
            </button>
          </div>
          <div class="col-xs-6 t-a-r">
            <button class="btn btn-default"
                    translate="termDeposit.confirmBtnLabel"
                    ng-disabled="termDeposit.confirmButtonPressed"
                    ng-click="termDeposit.confirm();">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  STEP 3 ==== -->
    <!-- ================= -->
    <div ng-if="termDeposit.steps.step3">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-10 m-t-30 m-b-30">
            <h2 class="c-white f-28" translate="orders.successOrder"></h2>
            <!--<div class="f-16">-->
            <!--<span translate="eInvoicesSubscribe.applicationCompleteText" translate-values="vm.issuer"></span>-->
            <!--</div>-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a href="" data-ng-click="termDeposit.newTermDeposit()">
            <span class="money-exchange-icon"><i class="icon icon-einvoice zmdi-hc-2x"></i></span>
            <span class="f-18 money-exchange-text" translate="termDeposit.newDepositOrder"></span>
          </a>
        </div>
      </div>
    </div>

    <!-- ================= -->
    <!-- ====  TIMELINE ==== -->
    <!-- ================= -->
    <div class="m-t-30" ng-if="!termDeposit.steps.step2 && !termDeposit.steps.step3" ng-show="termDeposit.steps.step1"
         data-ng-include="'orders/deposit-overview-timeline.tpl.html'"></div>
  </div>
</section>
