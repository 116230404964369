<div class="datepicker">
    <span class="datepicker-value">{{model | date}}</span>
    <input type="text" class="cursor-pointer addiko-new-input" name="dateInput" id="dateInput" uib-datepicker-popup="shortDate"
        ng-model="model" show-weeks="false" is-open="vm.datePickerOpened"
        ng-disabled="isDisabled"
        current-text="{{'core.period.today' | translate}}" clear-text="{{'core.datePicker.clearBtn' | translate}}"
        close-text="{{'core.datePicker.doneBtn' | translate}}" datepicker-options="vm.dateOptions" readonly>
    <button data-ng-click="vm.openDatePicker()" type="button"
        class="icon-btn"
        ng-disabled="isDisabled">
        <i class="icon-calendar-lines"></i>
    </button>
</div>