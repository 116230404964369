<div class="p-l-15 p-r-15 f-14" ng-hide="eInvoicesModal.signSuccess">
  <form name="paymentSignModal.signPaymentForm" novalidate>
    <div class="row eim-header p-10 bottom-border">
      <b class="f-18 p-l-10" translate="paymentSignModal.title"></b>
      <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst eim-header-btn-close" data-ng-click="paymentSignModal.closeModal();"></i>
    </div>
    <div class="row p-10 bottom-border">
      <div class="eim-details col-md-12 col-sm-12 no-padding-on-phone">
        <label translate="paymentSignModal.challengeResponse"></label>
        <input type="text" name="challengeResponseInput"
               data-ng-class="{'domp-error': paymentSignModal.signPaymentForm.challengeResponseInput.$invalid && paymentSignModal.formSubmitted}"
               class="form-control" data-ng-model="paymentSignModal.challengeResponseInput" required>
        <span class="c-red"
              ng-show="paymentSignModal.signPaymentForm.challengeResponseInput.$error.required && paymentSignModal.formSubmitted"
              translate="internalTransfer.paymentPurposeInputError"></span>
      </div>
    </div>
    <div class="row p-10 eim-footer">
      <div class="eim-btn p-r-5 t-a-c">
        <button class="btn btn-default app-btn-blue ng-scope eim-action-button w-100"
                translate="paymentSignModal.ok"
                data-ng-click="paymentSignModal.signPayment();">
        </button>
      </div>
    </div>
  </form>
</div>
