(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name statements.controller:StatementsCtrl
   *
   * @description
   *
   */
  angular
    .module('statements')
    .controller('StatementsCtrl', StatementsCtrl);

  function StatementsCtrl() {
    var vm = this;
    vm.ctrlName = 'StatementsCtrl';
  }
}());
