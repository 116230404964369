<!--suppress ALL, XmlHighlighting -->



























<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="masterCardOrder.header">Change overdraft limit</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <!-- START Note -->
    <div ng-if="masterCardOrder.step == 1" class="row">
      <div class="col-md-12">
        <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
          <div class="row">
            <div class="col-xs-12">
              <div>
                <span translate="masterCardOrder.info1"></span>
                <a href="https://www.addiko.si/ceniki" target="blank" class="underline" translate="masterCardOrder.infoLink"></a>
              <div>
              <span translate="masterCardOrder.info2"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <!-- END Note -->

    <div ng-if="masterCardOrder.step == 1"  class="p-20 bg-white">
      <ng-form name="masterCardOrder.masterCardOrderForm">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardAccount" class="domp-form-label" translate="masterCardOrder.cardAccount"></label>
                <input id="cardAccount" name="cardAccount" type="text" class="form-control input-field"
                       ng-model="masterCardOrder.tempObj.cardAccount"
                       disabled>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardType" class="domp-form-label" translate="masterCardOrder.cardType"></label>
                <select id="cardType" name="cardType" class="form-control input-field"
                       disabled
                       ng-options="option.description | translate for option in masterCardOrder.cardTypes"
                       ng-model="masterCardOrder.selectedAccount.cardType"
                       >
                  </select>
              </div>
            </div>
          </div>
          <div class="row p-t-15">

            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardNumber" class="domp-form-label" translate="masterCardOrder.cardNumber"></label>
                <select id="cardNumber" name="cardNumber"
                        class="form-control input-field p-l-15 p-r-10"
                        disabled
                        ng-options="option.cardNumber + ' '+ option.cardHolder for option in masterCardOrder.selectedAccount.cardList"
                        ng-model="masterCardOrder.selectedAccount.selectedCard">
                </select>

              </div>
            </div>
            <div class="col-xs-12 col-sm-6" ng-if="masterCardOrder.selectedAccount.cardType.id == 'basic'">
              <!-- START Amount -->
              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="masterCardOrder.desiredLimit"></label>
                  <div class="input-group">
                    <input id="amount" name="amount" type="text"
                           class="form-control input-field t-a-r"
                           amount-input-mask
                           placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                           ng-model="masterCardOrder.tempObj.amount"
                           ng-class="{'domp-error': masterCardOrder.masterCardOrderForm.amount.$invalid && masterCardOrder.formSubmitted}"
                           maxlength="12"
                           autocomplete="off"
                           required>
                    <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                        style="border:1px solid #062a42;">
                  {{masterCardOrder.selectedAccount.currencySymbol}}
                </button>
              </span>
                  </div>
                  <label class="c-red"
                         ng-show="masterCardOrder.masterCardOrderForm.amount.$error.required && masterCardOrder.formSubmitted"
                         translate="cashWithdrawalTranslate.amountRequired">
                  </label>
                </div>
              </div>
              <!-- END Amount-->
            </div>
            <div class="col-xs-12 col-sm-6" ng-if="masterCardOrder.selectedAccount.cardType.id != 'basic'">
              <div class="form-group">
                <label for="limit" class="domp-form-label" translate="masterCardOrder.percentage"></label>
                <select id="limit" name="limit"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in masterCardOrder.limits"
                        ng-model="masterCardOrder.tempObj.limit">
                </select>

              </div>
            </div>
          </div>

          <div class="row p-t-15">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="limitType" class="domp-form-label" translate="masterCardOrder.limitType"></label>
                <select name="limitType" id="limitType"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in masterCardOrder.limitTypes"
                        ng-model="masterCardOrder.tempObj.limitType">
                </select>
              </div>
            </div>

            <div class="col-sm-3" ng-if="masterCardOrder.tempObj.limitType.id == 'Temporary'">
              <div class="form-group">
                <label class="domp-form-label" for="dateFrom" translate="masterCardOrder.dateFrom"></label>
                <div class="input-group">
                  <input type="text" class="form-control input-field" name="dateFrom" id="dateFrom"
                         uib-datepicker-popup="shortDate" data-ng-model="masterCardOrder.tempObj.dateFrom"
                         show-weeks="false"
                         is-open="dateFromOpened"
                         datepicker-options="masterCardOrder.dateOptions.dateFrom"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         ng-class="{ 'domp-error' : masterCardOrder.masterCardOrderForm.dateFrom.$invalid && masterCardOrder.formSubmitted}"
                         required readonly>
                  <span class="input-group-btn">
                            <button data-ng-click="dateFromOpened = !dateFromOpened" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;">
                              <i class="icon icon-calendar" style="font-size: 21px;"></i>
                            </button>
                          </span>
                </div>
              </div>
              <span class="c-red" ng-show="masterCardOrder.masterCardOrderForm.dateBegin.$error.required && masterCardOrder.formSubmitted"
                    translate="orders.dateRequired"></span>
            </div>
            <div class="col-sm-3" ng-if="masterCardOrder.tempObj.limitType.id == 'Temporary'">
              <div class="form-group">
                <label class="domp-form-label" for="dateTo" translate="masterCardOrder.dateTo"></label>
                <div class="input-group date-to">
                  <input type="text" class="form-control input-field" name="dateTo" id="dateTo"
                         uib-datepicker-popup="shortDate"
                         data-ng-model="masterCardOrder.tempObj.dateTo"
                         show-weeks="false"
                         is-open="dateToOpened"
                         datepicker-options="masterCardOrder.dateOptions.dateTo"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         ng-class="{ 'domp-error' : masterCardOrder.masterCardOrderForm.dateTo.$invalid && masterCardOrder.formSubmitted}"
                         required readonly>
                  <span class="input-group-btn">
                            <button data-ng-click="dateToOpened = !dateToOpened" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;">
                              <i class="icon icon-calendar" style="font-size: 21px;"></i>
                            </button>
                          </span>
                </div>
              </div>
              <span class="c-red" ng-show="masterCardOrder.masterCardOrderForm.dateBegin.$error.required && masterCardOrder.formSubmitted"
                    translate="orders.dateRequired"></span>
            </div>
          </div>
          <div class="row p-t-15">
            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12">
              <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                <input type="checkbox" ng-model="masterCardOrder.iAmAware">
                <i class="input-helper"></i>
                {{'cashWithdrawalTranslate.info' | translate}}
              </label>
            </div>
          </div>


          <div class="row p-b-20 p-t-20">
            <div class="col-md-12">
              <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left"
                      ng-click="masterCardOrder.goBackToAccount()">
                <span translate="payments.cancel">Cancel</span>
              </button>
              <button class="btn btn-default  pull-right "
                      ng-click="masterCardOrder.validate()"
                      ng-disabled="masterCardOrder.nextButtonPressed || (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !masterCardOrder.iAmAware)">
                <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
              </button>
            </div>
          </div>
      </ng-form>
    </div>

    <div ng-if="masterCardOrder.step == 2">
      <div class="domp-to-section m-10">
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="masterCardOrder.cardAccount"></label>
              <label class="d-block domp-form-label-resume">{{masterCardOrder.tempObj.cardAccount}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="masterCardOrder.cardType"></label>
              <label class="d-block domp-form-label-resume">{{masterCardOrder.selectedAccount.cardType.description | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCardOrder.cardNumber"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.selectedAccount.selectedCard.cardNumber + ' '+masterCardOrder.selectedAccount.selectedCard.cardHolder }}</label>
          </div>
          <div ng-if="masterCardOrder.selectedAccount.cardType.id == 'basic'" class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCardOrder.desiredLimit"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.tempObj.amount | number:2}} {{masterCardOrder.selectedAccount.currencySymbol}}</label>
          </div>
          <div ng-if="masterCardOrder.selectedAccount.cardType.id == 'additional'" class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCardOrder.percentage"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.tempObj.limit.description}}</label>
          </div>
        </div>

        <div  class="row">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCardOrder.limitType"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.tempObj.limitType.description}}</label>
          </div>
          <div ng-if="masterCardOrder.tempObj.limitType.id == 'Temporary'" class="col-xs-6 col-sm-3">
            <label class="domp-form-label-review" translate="masterCardOrder.dateFrom"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.insertedOrderObject.limitTypeTempDateFrom | date:'shortDate' : '+0200'}}</label>
          </div>
          <div ng-if="masterCardOrder.tempObj.limitType.id == 'Temporary'" class="col-xs-6 col-sm-3">
            <label class="domp-form-label-review" translate="masterCardOrder.dateTo"></label>
            <label class="d-block domp-form-label-resume">{{masterCardOrder.insertedOrderObject.limitTypeTempDateTo | date:'shortDate' : '+0200'}}</label>
          </div>

        </div>
      </div>

      <div class="col-xs-12">
        <button class="btn btn-primary " translate="domesticPayment.back"
                data-ng-click="masterCardOrder.edit();">
        </button>
        <button class="btn btn-default pull-right" data-ng-click="masterCardOrder.confirm()" ng-disabled="masterCardOrder.confirmButtonPressed">
          <span translate="domesticPayment.confirmBtn"></span>
        </button>
      </div>
    </div>
  </div>
</section>
