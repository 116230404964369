<form name="internalTransferForm" novalidate>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
          <label class="d-block domp-form-label-resume">{{internalTransfer.paymentResponse.fromAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6">
        <div class="row">
          <div class="col-sm-6">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel">Amount</label>
            <label class="d-block domp-form-label-resume">
              {{internalTransfer.paymentResponse.amount | currency: ""}}
              {{internalTransfer.paymentResponse.currencySymbol}}
            </label>
          </div>
          <div class="col-sm-6" ng-if="internalTransfer.countryCode !== 'bh'">
            <label class="domp-form-label-review" translate="domesticPayment.bankCharges">Bank charges</label>
              <a ng-if="!internalTransfer.paymentResponse.hasOwnProperty('paymentChargesAmount')" ng-href="{{internalTransfer.bankCharges}}"
                 target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume">Check the bank charges</a>
            <label ng-if="internalTransfer.paymentResponse.hasOwnProperty('paymentChargesAmount')" class="d-block domp-form-label-resume">
              {{internalTransfer.paymentResponse.paymentChargesAmount | currency:""}}
              {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}
            </label>

          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel">When</label>
            <label class="d-block domp-form-label-resume">{{internalTransfer.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.toAccount">To account</label>
          <label class="d-block domp-form-label-resume">{{internalTransfer.paymentResponse.toAccountNumber}}</label>
        </div>
      </div>
    </div>

  </div>
    <div class="row p-10">
        <div ng-if="internalTransfer.countryCode === 'mne'" class="col-xs-12">
            <dl>
                <dt><span translate="internalTransfer.infoMne0"></span></dt>
                <dd>- <span translate="internalTransfer.infoMne1"></span></dd>
                <dd>- <span translate="internalTransfer.infoMne2"></span></dd>
            </dl>
        </div>
    </div>
  <div class="m-10">
    <div class="row">
      <div class="col-sm-9 p-b-10 col-xs-12 hidden-xs">
        <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-primary " translate="currencyConversion.back"
                data-ng-click="internalTransfer.backToInternalTransfer();">
        </button>
        <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-primary" translate="payments.edit"
                data-ng-click="internalTransfer.editPayment();">
        </button>
        <button ng-if="internalTransfer.countryCode == 'sl'" class="btn btn-primary" translate="domesticPayment.back"
                data-ng-click="internalTransfer.editPayment();">
        </button>
        <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-primary-warning " translate="payments.delete"
                data-ng-click="internalTransfer.deletePayment();">
        </button>
      </div>

      <div class="col-sm-9 p-b-10 col-xs-12 visible-xs-block">
        <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-default btn-block"

                data-ng-click="internalTransfer.signPayment()">

          <span  translate="currencyConversion.sign"></span>
        </button>
        <button ng-if="internalTransfer.countryCode === 'sl'" class="btn btn-default btn-block"
                ng-disabled="internalTransfer.confirmButtonPressed"
                data-ng-click="internalTransfer.signPayment()">

          <span  translate="domesticPayment.confirmBtn"></span>
        </button>
        <button class="btn btn-primary btn-block" translate="currencyConversion.back"
                data-ng-click="internalTransfer.backToInternalTransfer();">
        </button>
        <button class="btn btn-primary btn-block" translate="payments.edit"
                data-ng-click="internalTransfer.editPayment();">
        </button>
        <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-primary-warning btn-block" translate="payments.delete"
                data-ng-click="internalTransfer.deletePayment();">
        </button>
      </div>

      <div class="col-sm-3 col-xs-12 hidden-xs">

          <button ng-if="internalTransfer.countryCode != 'sl'" class="btn btn-default col-xs-12"

                  data-ng-click="internalTransfer.signPayment()">

            <span translate="currencyConversion.sign"></span>
          </button>

        <button ng-if="internalTransfer.countryCode === 'sl'" class="btn btn-default col-xs-12"
                ng-disabled="internalTransfer.confirmButtonPressed"
                data-ng-click="internalTransfer.signPayment()">

          <span translate="domesticPayment.confirmBtn"></span>
        </button>


      </div>
    </div>
  </div>

</form>

