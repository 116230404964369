(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      navigation: {
        "dashboard": "Prva stran",
        "accountsAndCards": "Računi in kartice",
        "accounts": "Računi",
        "payments": "Plačila",
        "deposit": "Varčevanja in depoziti",
        "loans": "Krediti",
        "investments": "Naložbe",
        "settings": "Nastavitve",
        "messages": "Sporočila",
        "hypoClub": "Addiko Klub",
        "eTrade": "Addiko Broker",
        "paymentsAndTransfers" :"Pregled plačil in prenosov",
        "domesticPaymentSl": "UPN nalog",
        "transferToMyAccount": "Prenos na moj račun",
        "internationalPayment": "Plačilo v tujino",
        "exchangeOffice" :"Menjava valut",
        "exchangeRates" :"Tečajna lista",
        "standingOrders": "Trajni nalog",
        "eInvoicesSlo": "eRačuni / eDokumenti",
        "paymentsList": "Pregled plačil",
        "paymentsReturn": "Vračilo",
        "directDebitsList": "SEPA direktne obremenitve",
        "sepa": "Nastavitve SEPA DD",
        "myTemplatesList": "Predloge",
        "allAccountsOverview": "Pregled vseh računov",
        "allDepositOverview": "Vsa varčevanja",
        "allDeposits": "Varčevanja",
        "allDepositOverviewSerbMne": "Vsi depoziti",
        "loansOverview": "Pregled kreditov",
        "investmentsOverview": "Pregled naložb",
        "availableBalance": "Razpoložljivo stanje",
        "loanBalance": "Stanje kredita",
        "newMessages": "Nova sporočila: ",
        "newMessagesSlo": "Nova osebna sporočila: ",
        "employeePayroll": "Plačilne liste",
        "allowedLoan":"Odobreno posojilo",
        "balance": "Stanje na računu",
        "newOrder":"Nakup delnic",
        "documents":"Dokumenti",
        "sepaTransactions":"Pregled SEPA DD",
        "withdrawal1":"Sprememba limita",
        "withdrawal2":"na računu",
        "changeATMWithdrawal1":"Sprememba dnevnega limita",
        "changeATMWithdrawal2":"kartice računa",
        "reorderPin":"Ponovno naročilo PIN-a",
        "masterCardOrder":"Mastercard limit",
        "unreadDocuments": "Neprebrani dokumenti: ",
        "viberBanking":"Addiko Chat Banking"
      }
    };

    $translateProvider
      .translations('sl', data);

  }
}());
