<!-- Current account details -->
<div class="m-b-10">
  <span class="account-header-value white-text"
    ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '">
  </span>
  <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
    class="account-header-value-eur"
    ng-bind="'/ ' + (accounts.selectedAccount.balance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)">
  </span>
  <hr class="account-header-line" />
  <span class="m-t-neg-15 white-text-block" translate="accounts.accCurrentBalance"></span>

  <div class="row account-header-bottom">
    <div class="col-xs-12 col-md-5">
      <span class="f-18 white-text"
        ng-bind="(accounts.selectedAccount.availableBalance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>

      <span ng-if="accounts.isNuN(accounts.selectedAccount.availableBalance2) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
        class="f-16 white-text"
        ng-bind="'/ ' + (accounts.selectedAccount.availableBalance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
      <span class="white-text-block" translate="accounts.accAvailableBalance"></span>
    </div>
  </div>

  <div class="row p-t-20" ng-show="accounts.isAccountDetailsOpen">

    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.limit">{{accounts.selectedAccount.limit|number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block" ng-if="!accounts.selectedAccount.limit">0,00
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accOverdraftLimit"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.limitValidUntilDate">{{accounts.selectedAccount.limitValidUntilDate |
          date:'shortDate' : '+0200'}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">-</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
          translate="accounts.untilRevocation"></span>
        <span class="white-text-block" translate="accounts.accOverdraftLimitDue"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.limitAmountATM  && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountATM | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountATM"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.limitAmountPOS && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountPOS | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountPOS"></span>
      </div>

      <!--TSK-117916-->
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="(accounts.selectedAccount.limitCardType == 'DMC') && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountNET | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountNET"></span>
      </div>
      <!-- <div class="col-xs-12 col-md-4 p-b-20">
        <span class="f-20 white-text-block"  ng-if="accounts.selectedAccount.cardNumber">{{accounts.selectedAccount.cardNumber}}</span>
        <span class="f-20 white-text-block"  ng-if="!accounts.selectedAccount.cardNumber">-</span>
        <span class="white-text-block" translate="accounts.accCardNumber"></span>
     </div>-->
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField && accounts.accountStatus !== 'Blocked'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.reservedAmount
          | number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.planedDebit | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">0,00
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accReservations"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.numberOfPaymentsInQueue">
        <span
          class="f-20 white-text-block ng-binding ng-scope">{{accounts.selectedAccount.numberOfPaymentsInQueue}}</span>
        <span class="white-text-block ng-scope" translate="accounts.accPaymInQue">Payments in queue</span>
      </div>

    </div>


    <!--TEKUCI RACUN HR-->

    <div
      ng-if="accounts.selectedAccount.accountType.group === 'CurrentAccount' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <!--Iznos prekoracenja-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
          <span class="f-20 white-text-block">
            {{(accounts.selectedAccount.limit || 0 )|number:2}} {{accounts.selectedAccount.currencySymbol}}
            <span ng-if="accounts.selectedAccount.limit2 >= 0">/</span>
          </span>
          <span ng-if="accounts.selectedAccount.limit2 >= 0" class="f-18 white-text-block pt-1">
            {{accounts.selectedAccount.limit2|number:2}} {{accounts.selectedAccount.currencySymbol2}}
          </span>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
          <span class="f-20 white-text-block"
            ng-if="accounts.selectedAccount.limit">
            {{accounts.selectedAccount.limit|number:2}} {{accounts.selectedAccount.currencySymbol}}
          </span>
          <span class="f-20 white-text-block" ng-if="!accounts.selectedAccount.limit">0,00
            {{accounts.selectedAccount.currencySymbol}}</span>
        </div>
        <span class="white-text-block" translate="accounts.accOverdraftLimit"></span>
      </div>

      <!--Rezervacije-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField && accounts.accountStatus !== 'Blocked'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.hasOwnProperty('reservedAmount') && !accounts.selectedAccount.hasOwnProperty('planedDebit')">{{accounts.selectedAccount.reservedAmount
          | number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.hasOwnProperty('planedDebit')">{{accounts.selectedAccount.planedDebit |
          number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.hasOwnProperty('reservedAmount') && !accounts.selectedAccount.hasOwnProperty('planedDebit')">0,00
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accReservations"></span>
      </div>

      <!-- Rezervisano karticnim placanjem-->

      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="accounts.showField && accounts.accountStatus !== 'Blocked' && accounts.selectedAccount.cardReservationAmount"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.cardReservationAmount">{{accounts.selectedAccount.cardReservationAmount |
          number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accReservationsCardReservationAmount"></span>
      </div>

      <!--Datum isteka prekoracenja-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.limitValidUntilDate">{{accounts.selectedAccount.limitValidUntilDate |
          date:'shortDate' : '+0200'}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">-</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
          translate="accounts.untilRevocation"></span>
        <span class="white-text-block" translate="accounts.accOverdraftLimitDue"></span>
      </div>

      <!--Status-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>

      <!--Datum zadnje promjene-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
    </div>

    <!--SVE OSTALO HR-->

    <div
      ng-if="accounts.selectedAccount.accountType.group !== 'CurrentAccount' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.limit">{{accounts.selectedAccount.limit|number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block" ng-if="!accounts.selectedAccount.limit">0,00
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accOverdraftLimit"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.limitValidUntilDate">{{accounts.selectedAccount.limitValidUntilDate |
          date:'shortDate' : '+0200'}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">-</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.limitValidUntilDate && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
          translate="accounts.untilRevocation"></span>
        <span class="white-text-block" translate="accounts.accOverdraftLimitDue"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.limitAmountATM  && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountATM | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountATM"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.limitAmountPOS && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountPOS | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountPOS"></span>
      </div>

      <!--TSK-117916-->
      <div class="col-xs-6 col-md-4 p-b-20"
        ng-if="(accounts.selectedAccount.limitCardType == 'DMC') && accounts.selectedAccount.currencySymbol == 'EUR'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.limitAmountNET | currency:""}}</span>
        <span class="white-text-block" translate="accounts.limitAmountNET"></span>
      </div>
      <!-- <div class="col-xs-12 col-md-4 p-b-20">
        <span class="f-20 white-text-block"  ng-if="accounts.selectedAccount.cardNumber">{{accounts.selectedAccount.cardNumber}}</span>
        <span class="f-20 white-text-block"  ng-if="!accounts.selectedAccount.cardNumber">-</span>
        <span class="white-text-block" translate="accounts.accCardNumber"></span>
     </div>-->
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.showField && accounts.accountStatus !== 'Blocked'"
        ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.reservedAmount
          | number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.planedDebit | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">0,00
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accReservations"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.numberOfPaymentsInQueue">
        <span
          class="f-20 white-text-block ng-binding ng-scope">{{accounts.selectedAccount.numberOfPaymentsInQueue}}</span>
        <span class="white-text-block ng-scope" translate="accounts.accPaymInQue">Payments in queue</span>
      </div>
    </div>


  </div>
</div>