<a href="" data-ng-click="dashboard.gotoAccount(account.accountId)">
    <div class="credit-master-account-widget p-15 app-white-card  dashboard-widget-item">
        <h4>{{account.accountDescription}}</h4>
        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
            <h2 class="no-bottom-margin">{{account.balance}} {{account.currencySymbol}}</h2>
            <p class="m-b-0">{{account.availableBalance}} {{account.currencySymbol}} available</p>
        </span>

        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
            <span>
                <h2 class="no-bottom-margin">{{account.balance}} {{account.currencySymbol}}</h2>
                <p class="m-b-0">{{account.availableBalance}} {{account.currencySymbol}} available</p>
            </span>

            <span ng-if="dashboard.isNuN(account.balance2 )">
                <h2 class="no-bottom-margin">{{account.balance2}} {{account.currencySymbol2}}</h2>
                <p class="m-b-0">{{account.availableBalance2}} {{account.currencySymbol2}} available</p>
            </span>

        </span>
    </div>
</a>
<uib-progressbar style="height:10px;" value="55"></uib-progressbar>