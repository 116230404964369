<div class="timeline-row p-r-15 p-l-15">


  <div class="col-sm-12 l-h-50 p-0 bgm-white" style="z-index:1;">
    <span translate="dashboard.bankTimeline" class="f-17 m-l-20 c-appblue"></span>
  </div>
  <div class="col-sm-12 p-l-20 p-r-20 bgm-white" style="z-index:1;">
    <div class="col-sm-6 col-xs-12 p-0">
      <div ng-include="'timeline/timeline-filter.tpl.html'"></div>
    </div>
    <div class="col-sm-6 col-xs-12 p-0">
      <div class="dropdown acc-period-select pull-right" style="display: inline-block">
        <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          {{dashboard.selectedPeriod.item.description | translate}}
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
          <li data-ng-repeat="period in dashboard.periodList">
            <a href="" class="f-13" data-ng-click="dashboard.timelineTimeFilter(period)">
              {{period.item.description | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">

    <ul class="hypo-timeline" kcd-recompile="dashboard.timelineListGrouped">
      <li class="time-label">
        <span>{{'dashboard.pendingPayments'|translate}} |
          <span data-ng-click="dashboard.hidePendingPayments()" ng-show="dashboard.pendingPaymentsOpened" class="c-blue pointer">
            <b>{{'dashboard.hide'|translate}}</b>
          </span>
          <span data-ng-click="dashboard.showPendingPayments()" ng-show="!dashboard.pendingPaymentsOpened" class="c-blue pointer">
            <b>{{'dashboard.show'|translate}}</b>
          </span>
        </span>
      </li>
      <li ng-show="dashboard.pendingPaymentsOpened" ng-repeat="payment in dashboard.pendingPaymentsList.result">
        <i class="icon icon-clock-o currency-icon-change-tl"></i>
        <div class="hypo-timeline-item payment-item" ng-click="tlItem.selected = !tlItem.selected;" ng-class="{'opened': tlItem.selected}">
          <div class="hypo-timeline-body payment-tl-height">

            <div class="col-xs-6">
              <h4 class="m-b-0 m-t-0 c-gray"><div class="bold-font wrap-text">{{::payment.purpose}}</div></h4>
              <p class="m-b-0 m-t-0 iban-style">{{::payment.toAccountNumber}} {{::payment.receiverName}}</p>
              <p class="m-b-0 bold-font">{{payment.dueDate | date: 'shortDate' : '+0200'}}</p>
            </div>
            <div class="col-xs-6"><span class="pull-right t-a-r f-18"><b>{{::payment.amount|number:2}} {{::payment.currencySymbol}}</b></span></div>
          </div>
          <div ng-if="tlItem.selected" class="transaction-details">
            <div data-ng-include="'dashboard/tl-payment-details.tpl.html'"></div>
          </div>
        </div>
      </li>

      <div class="t-a-c p-10 no-data-image" ng-if="dashboard.timelineListGrouped.result.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>

      <li data-ng-repeat="tlItem in ::dashboard.timelineListGrouped.result" ng-class="{'time-label': tlItem.isVirtual === true}">

        <span ng-if="tlItem.isVirtual === true && tlItem.showDate">{{tlItem.dateValue | date:'shortDate' : '+0200'}}</span>
        <span ng-if="tlItem.isVirtual === true && tlItem.showText">{{tlItem.dateLabel | translate}}</span>

        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='TransactionCredit' && !tlItem.transactionLogo" class="icon icon-priliv"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='TransactionDebit' && !tlItem.transactionLogo" class="icon icon-odliv"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='MessageIn'" class="icon icon-messagein currency-icon-change-tl"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='MessageOut'" class="icon icon-messageout currency-icon-change-tl"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='LoginSuccessful'" class="icon icon-login currency-icon-change-tl"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='LoginFailed'" class="icon icon-close currency-icon-change-tl"></i>
        <i ng-if="tlItem.isVirtual == undefined && tlItem.transactionLogo" 
           data-ng-style="tlItem.transactionLogo ?{'background-image': 'url(' + tlItem.transactionLogo +')'}: {}"
           ng-class="'icon icon-' + tlItem.icon"></i>

        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='TransactionCredit'" class="hypo-timeline-item transaction-item" ng-click="tlItem.selected = !tlItem.selected; dashboard.getTransactionDetails(tlItem);" ng-class="{'opened': tlItem.selected}">
          <div class="hypo-timeline-body">
            <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
              <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{::tlItem.description}}</b></h4>
              <p class="m-b-0 m-t-0 no-wrap-text" ng-if="tlItem.payerName">{{::tlItem.payerName}}</p>
              <p class="m-b-0 m-t-0 no-wrap-text" ng-if="tlItem.payeeName">{{::tlItem.payeeName}}</p>
            </div>
            <div class="col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::tlItem.credit | number:2}} {{::tlItem.currency}}</b></span></div>
          </div>
          <div ng-if="tlItem.selected" class="m-l-0 transaction-details">
            <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
          </div>
        </div>
        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='TransactionDebit'" class="hypo-timeline-item transaction-item" ng-click="tlItem.selected = !tlItem.selected; dashboard.getTransactionDetails(tlItem);" ng-class="{'opened': tlItem.selected}">
          <div class="hypo-timeline-body">
            <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
              <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{::tlItem.description}}</b></h4>
              <p class="m-b-0 m-t-0 no-wrap-text" ng-if="tlItem.payeeName">{{::tlItem.payeeName}}</p>
              <p class="m-b-0 m-t-0 no-wrap-text" ng-if="tlItem.payerName">{{::tlItem.payerName}}</p>
            </div>
            <div class="col-xs-6 col-sm-4 p-l-0 p-r-0">
              <span class="pull-right t-a-r m-t-5 f-18"><b>{{::tlItem.debit | number:2}} {{::tlItem.currency}}</b></span>
              <span
                ng-if="!tlItem.selected && tlItem.bookingType && tlItem.bookingType == '1' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
                style ="float: right; color: red;" translate="accounts.accAutorization">
              </span>
              <span
                ng-if="!tlItem.selected && tlItem.bookingType && tlItem.bookingType == '2' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
                style ="float: right; color: blue;" translate="accounts.accProvedena">
              </span>
            </div>
          </div>
          <div ng-if="tlItem.selected" class="m-l-0 transaction-details">
            <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
          </div>
        </div>
        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='EInvoice'" class="hypo-timeline-item msg-item" ng-click="tlItem.selected = !tlItem.selected; dashboard.getEinvoiceDetails(tlItem);">
          <div class="bubble einvoice" ng-class="{'m-b-0': tlItem.selected}">
            <p class="no-bottom-margin c-white"><b>{{::tlItem.description}}
              <span class="msg-action pull-right hidden-xs"><a href="">{{'timeline.itemTypeStatus.'+tlItem.itemType | translate}}</a></span>
              </b></p>
          </div>
          <div ng-if="tlItem.selected" class="einvoice-details">
            <div data-ng-include="'dashboard/tl-einvoice-details.tpl.html'"></div>
          </div>
        </div>
        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='MessageOut'" class="hypo-timeline-item msg-item" ng-click="tlItem.selected = !tlItem.selected; dashboard.getMessageDetails(tlItem);">
          <div class="bubble message" ng-class="{'m-b-0': tlItem.selected}">
            <p class="msg-label no-bottom-margin"><span ng-if="tlItem.description">{{::'timeline.msgWith'| translate}}</span><span ng-if="!tlItem.description">{{::'timeline.msgWithout'| translate}}</span><b>{{::tlItem.description}}</b>{{::'timeline.msgSent'| translate}}<b>{{dashboard.getDateFromDatestring(tlItem.date)|date:'medium'}}</b>
            </p>
          </div>
          <div ng-if="tlItem.selected" class="timeline-details">
            <div data-ng-include="'dashboard/tl-message-details.tpl.html'"></div>
          </div>
        </div>
        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='MessageIn'" class="hypo-timeline-item msg-item" ng-click="tlItem.selected = !tlItem.selected; dashboard.getMessageDetails(tlItem);">
          <div class="bubble message" ng-class="{'m-b-0': tlItem.selected}">
            <p class="msg-label no-bottom-margin"><span ng-if="tlItem.description">{{::'timeline.msgWith'| translate}}</span><span ng-if="!tlItem.description">{{::'timeline.msgWithout'| translate}}</span><b>{{::tlItem.description}}</b>{{::'timeline.msgReceived'| translate}}<b>{{dashboard.getDateFromDatestring(tlItem.date)|date:'medium'}}</b>
            </p>
          </div>
          <div ng-if="tlItem.selected" class="timeline-details">
            <div data-ng-include="'dashboard/tl-message-details.tpl.html'"></div>
          </div>
        </div>

        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='LoginSuccessful'" class="hypo-timeline-item msg-item">
          <div class="bubble">
            <p ng-if="!tlItem.lastLogin" class="msg-label no-bottom-margin no-wrap-text f-16">{{::tlItem.group}} <b>{{::tlItem.date | date:'medium'}}</b></p>
            <p ng-if="tlItem.lastLogin" class="msg-label no-bottom-margin no-wrap-text f-16">{{'header.lastLogin' | translate}} <b>{{::tlItem.date | date:'medium'}}</b></p>
          </div>
        </div>
        <div ng-if="tlItem.isVirtual == undefined && tlItem.itemType =='LoginFailed'" class="hypo-timeline-item msg-item">
          <div class="bubble">
            <p class="msg-label no-bottom-margin no-wrap-text f-16"><b>{{::tlItem.itemType}}, {{::tlItem.date | date:'medium'}}</b></p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="col-md-12 text-center p-t-15">
  <button class="btn btn-default" ng-hide="dashboard.timelineListGrouped.hasMore == false" data-ng-click="dashboard.loadMoreTimelineItemsGrouped()" type="input" translate="dashboard.loadEvents">Load older events</button>
</div>
