<div ng-if="accounts.eBookRequests">
  <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0" ng-hide="accounts.eBookSavingsGrouped.result.length == 0">
    <div class="m-b-15 m-t-10">
      <span class="f-17 m-l-20" translate="ebook.timelineHeader" style="font-weight:400;color:#062a42;">
       Vremenski prikaz zahtjeva za eKnjižice
     </span>
    </div>
    <ul class="hypo-timeline" kcd-recompile="accounts.eBookSavingsGrouped">
      <li ng-class="{'time-label': eBookSavings.isVirtual}" ng-repeat="eBookSavings in accounts.eBookSavingsGrouped.result">
        <span class="currency-time-frame " ng-if="eBookSavings.isVirtual === true">{{eBookSavings.date | date:'shortDate' : '+0200' }}</span>
        <i class="zmdi zmdi-money" ng-if="!eBookSavings.isVirtual"></i>
        <div ng-if="!eBookSavings.isVirtual" class="hypo-timeline-item payment-item" data-ng-click="eBookSavings.selected = !eBookSavings.selected" ng-class="{'opened': eBookSavings.selected}">
          <div class="hypo-timeline-body">
            <div class="col-xs-6">
              <h4 class="m-b-0 m-t-0 c-darkgray">
              <p style="font-weight:500;font-size:18px;margin-bottom: 3px;">{{::eBookSavings.eSavingBookName}}</p>
            </h4>
              <div>
                <p style="font-weight:500;font-size:14px;color:#444;margin-bottom: 3px;">{{::eBookSavings.requestType}}</p>
              </div>
            </div>
            <div class="col-xs-6"><span class="pull-right t-a-r m-t-10"><p style="font-weight:600;font-size:18px;color:#444;">{{::eBookSavings.status}}</p></span></div>
          </div>
          <div ng-if="eBookSavings.selected" class="transaction-details">
            <div data-ng-include="'accounts/tl-ebook-savings-details.tpl.html'"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="hypo-timeline-wrapper app-white-card t-a-c p-10 no-data-image" ng-if="accounts.eBookSavingsRequests.result.length == 0">
    <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
    <h3 class="no-data-label ng-scope" translate="internalTransfer.nodata"></h3>
  </div>

  <div class="show-more-btn ">
    <button ng-show="accounts.eBookSavingsRequests.hasMore" data-ng-click="accounts.loadMoreSavingBookRequests();" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">Load older events</button>
  </div>
</div>

<!-- from navigation or from open new eBook button -->
<div ng-if="newEBook.eBookRequests" class="col-sm-12 p-0">
  <div class="col-sm-12 p-0 app-white-card">
    <div class="col-sm-6 p-0 m-t-15">
      <span class="f-17 m-l-20" translate="ebook.timelineHeader" style="font-weight:400;color:#062a42;">
      Vremenski prikaz zahtjeva za eKnjižice
    </span>
    </div>
    <div class="col-sm-6 m-t-15 p-r-25">
      <label class="checkbox checkbox-inline m-l-15 pull-right">
        <input type="checkbox" data-ng-model="newEBook.allRequests" data-ng-click="newEBook.loadRequestsOnCheckbox()">
        <span class="input-helper" translate="ebook.showAllRequests" style="color:#ff4d5a;font-size:14px;font-weight:500;">Prikaži zahtjeve za sve račune</span>
      </label>
    </div>
  </div>
  <div class="col-sm-12 hypo-timeline-wrapper app-white-card p-tbl-10-r-0" ng-hide="newEBook.eBookSavingsGrouped.result.length == 0">
    <ul class="hypo-timeline" kcd-recompile="newEBook.eBookSavingsGrouped">
      <li ng-class="{'time-label': eBookSavings.isVirtual}" ng-repeat="eBookSavings in newEBook.eBookSavingsGrouped.result">
        <span class="currency-time-frame salary-date" ng-if="eBookSavings.isVirtual === true">{{eBookSavings.date | date:'shortDate' : '+0200' }}</span>
        <i class="icon icon-change currency-icon-change icon-lh" ng-if="!eBookSavings.isVirtual"></i>
        <div ng-if="!eBookSavings.isVirtual" class="hypo-timeline-item payment-item" data-ng-click="eBookSavings.selected = !eBookSavings.selected" ng-class="{'opened': eBookSavings.selected}">
          <div class="hypo-timeline-body">
            <div class="col-xs-6">
              <h4 class="m-b-0 m-t-0 c-darkgray">
              <p style="font-weight:500;font-size:18px;margin-bottom: 3px;">{{::eBookSavings.eSavingBookName}}</p>
            </h4>
              <div>
                <p style="font-weight:500;font-size:14px;color:#444;margin-bottom: 3px;">{{::eBookSavings.requestType}}</p>
              </div>
            </div>
            <div class="col-xs-6"><span class="pull-right t-a-r m-t-10"><p style="font-weight:600;font-size:18px;color:#444;">{{::eBookSavings.status}}</p></span></div>
          </div>
          <div ng-if="eBookSavings.selected" class="transaction-details">
            <div data-ng-include="'accounts/tl-ebook-savings-details.tpl.html'"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="show-more-btn ">
    <button ng-show="newEBook.eBookSavingsRequests.hasMore" data-ng-click="newEBook.loadMoreSavingBookRequests();" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">Load older events</button>
  </div>
</div>
