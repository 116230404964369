(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name core.controller:SessionExpiredCtrl
   *
   * @description
   *
   */
  angular
    .module('core')
    .controller('SessionExpiredCtrl', SessionExpiredCtrl);

  function SessionExpiredCtrl($rootScope, CTLocalizationService, $localStorage, ConfigService, HelperService) {
    var vm = this;
    vm.ctrlName = 'SessionExpiredCtrl';

    vm.selectedLanguage = '';
    vm.selectedCountry = '';
    vm.supportedLanguages = [];
    vm.SLOVENIA = false;
    vm.isIE = HelperService.detectIEEdge();

    vm.contactInfo = 'session.expired.contact';

    var setAppLanguage = function () {
      var lang = $localStorage.appLanguage;

      if (lang == undefined) {
        if (ConfigService.defaultLocale == undefined || ConfigService.defaultLocale == 'undefined') {
          $localStorage.appLanguage = angular.copy($localStorage.code);

        } else {
          $localStorage.appLanguage = angular.copy($localStorage.code);
        }
        lang = $localStorage.appLanguage;
      }


      vm.changeLanguage(lang);
      vm.selectedLanguage = getLanguageToggleSet(vm.selectedLanguage.code).toggleLanguage
    };

    vm.changeLanguage = function (lang) {
      CTLocalizationService.setLanguage(lang);
      vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
        return language.code === lang;
      });
      CTLocalizationService.translateSweetAlertOptions();
      $rootScope.$broadcast('appLanguageChangedHeader', { langCode: lang });
    };

    vm.toggleLanguage = function () {
      const toggleSet = getLanguageToggleSet(vm.selectedLanguage.code);
      vm.changeLanguage(toggleSet.currentLanguage.code);
      vm.selectedLanguage = toggleSet.toggleLanguage;
    }

    var getLanguageToggleSet = function(currentLanguage) {
      const toggleSet = { currentLanguage: '', toggleLanguage: '' };
      vm.languageOptions.forEach(function (language) {
        if (currentLanguage !== language.code) {
          toggleSet.toggleLanguage = language;
        } else {
          toggleSet.currentLanguage = language;
        }
      });
      return toggleSet;
    }

    vm.$onInit = function () {
      $rootScope.$on('appLanguageChangedNavigation', function (evt, lang) {
        if (lang && lang.langCode) {
          vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
            return language.code === lang.langCode;
          });
        }
      });

      var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData || {};

      if (!defaultData.APILocation) {
        defaultData.APILocation = ConfigService.defaultLocale;
      }
      vm.SLOVENIA = defaultData.APILocation === 'sl';
      if (defaultData.APILocation) {
        $localStorage.code = defaultData.APILocation;
        vm.countryCode = defaultData.APILocation;
        vm.languageOptions = CTLocalizationService.getLocalLanguageOptions(vm.countryCode);
        setAppLanguage();
      } else {
        vm.languageOptions = CTLocalizationService.getLanguageOptions();
        setAppLanguage();
      }
    }
  }
}());
