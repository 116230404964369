(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name core.controller:HiddenAuthCtrl
   *
   * @description
   *
   */
  angular
    .module('core')
    .controller('HiddenAuthCtrl', HiddenAuthCtrl);

  function HiddenAuthCtrl($location, $log, $window, $rootScope, ConfigService, SessionService, CTLocalizationService, $localStorage, $translate, $sce, $sceDelegate) {
    var vm = this;
    vm.ctrlName = 'HiddenAuthCtrl';
    vm.isSessionExpired = false;

    var _test = ConfigService.testLocal;

    vm.toggleLanguage = function () {
      const toggleSet = getLanguageToggleSet(vm.selectedLanguage.code);
      vm.changeLanguage(toggleSet.currentLanguage.code);
      vm.selectedLanguage = toggleSet.toggleLanguage;
    }

    var getLanguageToggleSet = function(currentLanguage) {
      const toggleSet = { currentLanguage: '', toggleLanguage: '' };
      vm.languageOptions.forEach(function (language) {
        if (currentLanguage !== language.code) {
          toggleSet.toggleLanguage = language;
        } else {
          toggleSet.currentLanguage = language;
        }
      });
      return toggleSet;
    }

    vm.$onInit = function () {
      $rootScope.$on('onSessionExpired', function (evt) {
        AlertService.infoAlert({
          text: 'session.expired.message'
        })
          .then(function () {
            vm.isSessionExpired = true;
          });
      });

      vm.setNewToken();

      const expDate = SessionService.getTokenProperty('exp');
      const timeout = (((expDate * 1000) - Date.now()) - 10000); // $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "hr" ? ((expDate * 1000) - Date.now() - 10000) : _refreshTokenTimeout;
      var timeLeft = timeout;

      $log.debug("### timings:");
      $log.debug(expDate);
      $log.debug(timeout);

      setTimeout(function() {
        vm.initiateRefreshToken();
      }, timeout);
      if (_test) {
        console.log('Time left: ' + timeLeft + 's');
        setInterval(function(){ timeLeft -= 1;
        console.log('Time left: ' + timeLeft + 's'); }, 3000);
      }
    }

    vm.initiateRefreshToken = function() {
      if (vm.isSessionExpired) {
        return;
      }
      // this.authService.clearSessions();
      // window.location.href = this.authService.prepareRefreshTokenUrl();

      $log.debug("InitiateRefreshToken --> TIMING: " + new Date().toDateString());
      // SessionService.saveCurrentState();
      SessionService.refreshUrl();
    }

    vm.setNewToken = function() {
      $log.debug("Hidden Auth - access_token search start");
      var accessTokenData = ''
      var data = window.location.hash;
      if (data != "") {
        var dataList = data.split('&');
        var accessTokenData = dataList[0].split('=');
        if (accessTokenData) {
          $window.sessionStorage.setItem('ngStorage-ct_accessToken', accessTokenData[1])
       }
      } else {
        $log.debug("Hidden Auth - has no data");
      }
    }
  }
}());
