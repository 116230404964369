<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="reorderPinMasterCard.header">Change overdraft limit</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <!-- START Note -->
    <div ng-if="reorderPinMasterCard.step == 1" class="row">
      <div class="col-md-12">
        <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
          <div class="row">
            <div class="col-xs-12">
              <div>
                <p translate="reorderPinMasterCard.info1" style="margin-bottom: 0"></p>
                <p translate="reorderPinMasterCard.info2" style="display: inline"></p>
                <a href="https://www.addiko.si/ceniki" target="blank" class="underline"
                   translate="reorderPinMasterCard.infoLink"></a>
                <div>
                </div>
                <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && reorderPinMasterCard.selectedAccount.selectedCard.smsDeliveryType"
                  translate="reorderPinMasterCard.additionalText" style="margin-bottom: 0; margin-top: 20px;"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <!-- END Note -->

    <div ng-if="reorderPinMasterCard.step == 1"  class="p-20 bg-white">
      <ng-form name="reorderPinMasterCard.reorderPinMasterCardForm">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardAccount" class="domp-form-label" translate="reorderPinMasterCard.cardAccount"></label>
                <input id="cardAccount" name="cardAccount" type="text" class="form-control input-field"
                       ng-model="reorderPinMasterCard.tempObj.cardAccount"
                       disabled>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardType" class="domp-form-label" translate="reorderPinMasterCard.cardType"></label>
                <select id="cardType" name="cardType" class="form-control input-field"
                       disabled
                       ng-options="option.description | translate for option in reorderPinMasterCard.cardTypes"
                       ng-model="reorderPinMasterCard.selectedAccount.cardType"
                       >
                  </select>
              </div>
            </div>
          </div>
          <div class="row p-t-15">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="cardNumber" class="domp-form-label" translate="reorderPinMasterCard.cardNumber"></label>
                <select id="cardNumber" name="cardNumber"
                        class="form-control input-field p-l-15 p-r-10"
                        disabled
                        ng-options="option.cardNumber + ' '+ option.cardHolder for option in reorderPinMasterCard.selectedAccount.cardList"
                        ng-model="reorderPinMasterCard.selectedAccount.selectedCard">
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="deliveryType" class="domp-form-label" translate="reorderPinMasterCard.pinReceiptMode"></label>
                <select name="deliveryType" id="deliveryType"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in reorderPinMasterCard.newPinOrderDeliveryTypes"
                        ng-model="reorderPinMasterCard.tempObj.newPinOrderDeliveryType">
                </select>
              </div>
            </div>

          </div>

        <!--CHECKBOX-->
          <div class="row p-t-15">
            <!-- <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12">
              <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                <input type="checkbox" ng-model="reorderPinMasterCard.iAmAware">
                <i class="input-helper"></i>
                {{'cashWithdrawalTranslate.info' | translate}}
              </label>
            </div> -->
          </div>


          <div class="row p-b-20 p-t-20">
            <div class="col-md-12">
              <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left"
                      ng-click="reorderPinMasterCard.goBackToAccount()">
                <span translate="payments.cancel">Cancel</span>
              </button>
              <button class="btn btn-default  pull-right "
                      ng-click="reorderPinMasterCard.validate()"
                      ng-disabled="reorderPinMasterCard.nextButtonPressed">
                <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
              </button>
            </div>
          </div>
      </ng-form>
    </div>

    <div ng-if="reorderPinMasterCard.step == 2">
      <div class="domp-to-section m-10">
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="reorderPinMasterCard.cardAccount"></label>
              <label class="d-block domp-form-label-resume">{{reorderPinMasterCard.tempObj.cardAccount}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="reorderPinMasterCard.cardType"></label>
              <label class="d-block domp-form-label-resume">{{reorderPinMasterCard.selectedAccount.cardType.description | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="reorderPinMasterCard.cardNumber"></label>
            <label class="d-block domp-form-label-resume">{{reorderPinMasterCard.selectedAccount.selectedCard.cardNumber + ' '+reorderPinMasterCard.selectedAccount.selectedCard.cardHolder }}</label>
          </div>
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="reorderPinMasterCard.pinReceiptMode"></label>
            <label class="d-block domp-form-label-resume">{{reorderPinMasterCard.tempObj.newPinOrderDeliveryType.description}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button class="btn btn-primary " translate="domesticPayment.back"
                data-ng-click="reorderPinMasterCard.edit();">
        </button>
        <button class="btn btn-default pull-right" data-ng-click="reorderPinMasterCard.confirm()" ng-disabled="reorderPinMasterCard.confirmButtonPressed">
          <span translate="domesticPayment.confirmBtn"></span>
        </button>
      </div>
    </div>
  </div>
</section>
