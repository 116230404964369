(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name complaints.controller:LocationCtrl
     *
     * @description
     *
     */
    angular
      .module('location')
      .controller('LocationCtrl', LocationCtrl);
  
    function LocationCtrl($rootScope, ConfigService, NgMap,$sce) {
        var vm = this;
        vm.configData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        var header = document.getElementById('header');
        vm.options = {
            headingContent: header
        };
        vm.headerContent = $sce.trustAsHtml('<div><strong>Custom Header</strong></div>');
        vm.center = {lat: +vm.configData.BankLat, lng: +vm.configData.BankLong};
        vm.googleMapsUrl="https://maps.googleapis.com/maps/api/js?key=" + ConfigService.googleMapsKey;
        NgMap.getMap().then(function(map) {
            vm.map = map;
        });
        vm.openInfoWindow = function() {
            vm.map.showInfoWindow('info-window', 'foo')
        }

    }
}());