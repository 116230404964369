(function () {
  'use strict';

  angular
    .module('viber')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('viber', {
        url: '/viber',
        templateUrl: 'viber/viber.tpl.html',
        controller: 'ViberCtrl',
        controllerAs: 'viber',
        resolve: {
          checkViberCountry: function ($q, $state, ConfigEndpointService, UserSettingsService) {
            return ConfigEndpointService.getDefaultData()
              .then(function (response) {
                return UserSettingsService.getPage()
                  .then(function (userData) {
                    if (!userData.result[0].isViberUser) {
                      $state.go('dashboard');
                      return $q.reject('Unknown Viber service');
                    }
                  })
              })
          }
        }
      })
      .state('viber.activate', {
        url: '/activate',
        resolve: {
          userIsViberActivated: function ($q, $state, ViberService) {
            return ViberService.checkActivation()
              .then(function (response) {
                //in case user is activated navigate to deactivate page
                if (response.isViberUser) {
                  $state.go('viber.deactivate');
                  return $q.reject('User is activated!');
                }
              })
              .catch(function (err) {
                return $q.reject('checkActivation error: ', err);
              });
          },
          userCountry: function (ConfigEndpointService, UserSettingsService) {
            return ConfigEndpointService.getDefaultData()
              .then(function (response) {
                return response.APILocation;
              })
          }
        },
        views: {
          '@': {
            templateUrl: 'viber/viber-activate.tpl.html',
            controller: 'ViberActivateCtrl',
            controllerAs: 'viberActivate'
          }
        }
      })
      .state('viber.deactivate', {
        url: '/deactivated',
        resolve: {
          userIsViberActivated: function ($q, $state, ViberService) {
            return ViberService.checkActivation()
              .then(function (response) {
                //in case user is deactivated navigate to eactivate page
                if (!response.isViberUser) {
                  $state.go('viber.activate');
                  return $q.reject('User is deactivated!');
                }
              })
              .catch(function (err) {
                return $q.reject('checkActivation error: ', err);
              });
          }
        },
        views: {
          '@': {
            templateUrl: 'viber/viber-deactivated.tpl.html',
            controller: 'ViberDeactivatedCtrl',
            controllerAs: 'viberDeactivate'
          }
        }
      })
      .state('viber.activate-new', {
        url: '/activate-new',
        resolve: {
          userIsViberActivated: function ($q, $state, ViberService) {
            return ViberService.checkActivation()
              .then(function (response) {
                //in case user is activated navigate to deactivate page
                if (response.isViberUser) {
                  $state.go('viber.deactivate');
                  return $q.reject('User is activated!');
                }
              })
              .catch(function (err) {
                return $q.reject('checkActivation error: ', err);
              });
          }
        },
        views: {
          '@': {
            templateUrl: 'viber/viber-activate-new.tpl.html',
            controller: 'ViberActivateNewCtrl',
            controllerAs: 'viberActivateNew'
          }
        }
      });
  }
}());
