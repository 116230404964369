(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:ValidationService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('ValidationService', ValidationService);

  function ValidationService($rootScope) {
    var ValidationServiceBase = {};

    ValidationServiceBase.getUiMaskOptions = function () {
      return {
        "croatia": {
          "iban": {
//            "maskDefinitions": {'H': /H|h/, 'R': /R|r/},
            "escChar": "*",
            "allowInvalidValue": true,
            "clearOnBlur": false
          }
        },
        "international": {
          "iban": {
            "allowInvalidValue": true,
            "clearOnBlur": false
          }
        }
      }
    };

    ValidationServiceBase.getUiMask = function () {
      return {
        sl: {
          orders: "?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?*",
          domesticSI: "?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*",
          domestic: "?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ",
          internationalSI: "?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*",
          international: "?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*?*?* ?*?*"
        }
      }

    };

    /*
     * Return Regex pattern for validation with ng-pattern-require directive
     * */
    ValidationServiceBase.getRegexPatternsForValidation = function () {
      // ^$|^[^-| ]
      // ^$ allows empty space input
      // [^-| ] dissallows first character of input as - or space

      return {
        srb: {
          "allowedModule11Chars": "^([0-9]*)(-{0,1}([(0-9)]){0,}){0,2}$",
          "allowedModule97Chars": "^([0-9A-Za-z]*)(-{0,1}([(0-9A-Za-z)]){0,}){0,3}$",
          "allowedModuleEmptyChars": "^([0-9A-Za-z]*)(-{0,1}([(0-9A-Za-z)]){0,}){0,}$",
        },
        "onlyNumbers": "^[0-9]*$",
        "onlyNumbersAndDashes": "^$|^[0-9][- 0-9]*$",
        "onlyNumbersAndLetters": "^[A-Za-z0-9]*$",
        "onlyNumbersAndLettersWithSuskavci": "^$|^[A-Za-z0-9ČčĆćžĐđŽžŠš][ A-Za-z0-9ČčĆćžĐđŽžŠš]*$",
        "allLettersNumbersSpaces": "^[A-Za-z0-9 ]*$",
        "allLettersNumbersSpacesIntlCro": "^(?!HR$|hr$)([A-Za-z0-9 ])*$", //HRxxx construction should be forbidden on Intl payment TSK-114339
        "allLettersNumbersDashes": "^[A-Za-z0-9-]*$", // Allowed all letters and numbers and dashes like (Chars DPS Serbia method)
        "allLettersNumbersDashesMne": "^[A-Za-z0-9-/]*$", // Allowed all letters and numbers and dashes like (Chars DPS Serbia method)
        "allLettersNumbersDashesSlo": "^[A-Za-z0-9-/ ]*$", // Allowed all letters and numbers and dashes like (Chars DPS Serbia method)
        "allNumbersDashes": "^[0-9-]*$",  // Allowed numbers and dashes like (Chars DPS method)
        "phoneValidation": "^$|^[0-9][0-9-]*$", // Allow numbers 0-9, dashes spaces but disallow - and spaces as first entry
        "allowedCharsDPS": "^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš][- A-Za-z0-9.ČčĆćžĐđŽžŠš]*$",  // Allowed letters (A-Za-z) and numbers (0-9) and signs )(?'-/:ČćČćĐđŽž and space ( )
        "allowedCharsDPStextArea": "^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš][- A-Za-z0-9.ČčĆćžĐđŽžŠš\n]*$", // Allowed enter for textareas
        "allowedCharsDPSwithoutSuskavci": "^$|^[A-Za-z0-9.][- A-Za-z0-9.]*$", // [^-| ] EXCLUDE IF - is first character and
        "purposeOfPaymentValidation": ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr') ?  "^$|^[A-Za-z0-9.,ČčĆćžĐđŽžŠš][- A-Za-z0-9)(/;=@.,ČčĆćžĐđŽžŠš]*$" : "^$|^[A-Za-z0-9.,ČčĆćžĐđŽžŠš][- A)(/-Za-z0-9.,ČčĆćžĐđŽžŠš]*$",
        "serbiaIbanValidation": "^[A-Za-z][A-Za-z][0-9-]*$",
        "phoneValidationWithPlus": "^$|^[+0-9][0-9-]*$",
        "disallowStarQutoesEtc": ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') ? "^[^\"*\';#$%]*$" : "^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš][- A-Za-z0-9.ČčĆćžĐđŽžŠš:,!_-?:\n]*$",

        // Slovenia Specific
        "onlyNumbersWithoutFirstZero": "^$|^[1-9][0-9]*$",
        "standardInput": "^$|^[a-zA-Z0-9čČćĆžŽšŠđĐäÄëËïÏöÖüÜÿŸÁáéÉíÍóÓúÚýÝàÀèÈìÌòÒùÙâÂêÊîÎôÔûÛőŐűŰñÑõÕåÅůŮøØæÆœŒăĂşŞţŢżŻąĄßřŘŁłĽľŚśŤťŹźŔŕĹĺĘęĚěĎďŃńŇňçÇ£¥/_€?:().,'+&][-\\\\ a-zA-Z0-9čČćĆžŽšŠđĐäÄëËïÏöÖüÜÿŸÁáéÉíÍóÓúÚýÝàÀèÈìÌòÒùÙâÂêÊîÎôÔûÛőŐűŰñÑõÕåÅůŮøØæÆœŒăĂşŞţŢżŻąĄßřŘŁłĽľŚśŤťŹźŔŕĹĺĘęĚěĎďŃńŇňçÇ£¥/_€?:().,'+&]*$",
        "receiverNameInput": "^$|^[a-zA-Z0-9ĄŁĽŚŠŞŤŹŽŻąłľśšşťźžżŔÁÂĂÄĹĆÇČÉĘËĚÍÎĎĐŃŇÓÔŐÖŘŮÚŰÜÝŢßŕáâăäĺćçčéęëěíîďđńňóôőöřůúűüýţ$/_€?:().,'+&][ a-zA-Z0-9ĄŁĽŚŠŞŤŹŽŻąłľśšşťźžżŔÁÂĂÄĹĆÇČÉĘËĚÍÎĎĐŃŇÓÔŐÖŘŮÚŰÜÝŢßŕáâăäĺćçčéęëěíîďđńňóôőöřůúűüýţ$/_€?:().,'+&]*$",
        "receiverIbanInput":"^$|^[a-zA-Z0-9čČćĆžŽšŠđĐ/_€?:().,'+&][ a-zA-Z0-9čČćĆžŽšŠđĐ/_€?:().,'+&]*$",
        "allowedCharsText": "^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš!?][- A-Za-z0-9.ČčĆćžĐđŽžŠš!?]*$",
        "standardInputFriendlyName": "^$|^[a-zA-Z0-9čČćĆžŽšŠđĐäÄëËïÏöÖüÜÿŸÁáéÉíÍóÓúÚýÝàÀèÈìÌòÒùÙâÂêÊîÎôÔûÛőŐűŰñÑõÕåÅůŮøØæÆœŒăĂşŞţŢżŻąĄßřŘŁłĽľŚśŤťŹźŔŕĹĺĘęĚěĎďŃńŇňçÇ£¥/_€?:().,'\\\"+&%][-\\\\ a-zA-Z0-9čČćĆžŽšŠđĐäÄëËïÏöÖüÜÿŸÁáéÉíÍóÓúÚýÝàÀèÈìÌòÒùÙâÂêÊîÎôÔûÛőŐűŰñÑõÕåÅůŮøØæÆœŒăĂşŞţŢżŻąĄßřŘŁłĽľŚśŤťŹźŔŕĹĺĘęĚěĎďŃńŇňçÇ£¥/_€?:().,'+&\\\"%]*$",

      }
    };

    ValidationServiceBase.checkAllowedCharsDPS = function (e) {
      if (/Firefox/.test(navigator.userAgent)) {
        var charCode = e.charCode;
        if (e.keyCode != 0) return true;
      }
      else
        var charCode = e.keyCode;

      if (!(charCode == 32 || charCode == 40 || charCode == 41 || charCode == 63 ||
        (charCode >= 43 && charCode <= 58) || (charCode >= 65 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122) ||
        (charCode == 353 || charCode == 352 || charCode == 272 ||
        charCode == 273 || charCode == 267 || charCode == 268 || charCode == 269 || charCode == 262 ||
        charCode == 263 || charCode == 381 || charCode == 382))) {
        e.preventDefault();
      }
    };

    /*
     * This method validates only numbers (0-9) and (-) charactes
     * */
    ValidationServiceBase.checkAllowedCharsForPoziv = function (e) {
      if (/Firefox/.test(navigator.userAgent)) {
        var charCode = e.charCode;
        if (e.keyCode != 0) return true;
      }
      else
        var charCode = e.keyCode;

      if ((charCode >= 48 && charCode <= 57 || charCode == 45)) {
        return true;
      }
      e.preventDefault();
    };

    /*
     * This method validates only numbers (0-9) and (-) and letters charactes
     * */
    ValidationServiceBase.checkAllowedCharsForSerbiaPoziv = function (e) {
      if (/Firefox/.test(navigator.userAgent)) {
        var charCode = e.charCode;
        if (e.keyCode != 0) return true;
      }
      else
        var charCode = e.keyCode;

      if ((charCode >= 48 && charCode <= 57 || charCode == 45 ||
        (charCode >= 65 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122))) {
        return true;
      }
      e.preventDefault();
    };

    /*
     * Returns iban modul 97 validation
     * */
    ValidationServiceBase.checkModul97Reference = function (reference) {
      if (reference.substring(2).length > 26) {
        return false;
      }

      //if (!char.IsLetter(reference[0]) || !char.IsLetter(reference[1]))
      //{
      //    return false;
      //}

      //if (!char.IsNumber(reference[2]) || !char.IsNumber(reference[3]))
      //{
      //    return false;
      //}
      reference = reference.toUpperCase();//we only process big letters
      var model = reference.substring(0, 2);//javascript original include char[0],char[1] -> iban.substring(0, 2);
      var checksum = reference.substring(2, 4).replace(/^0+/, "");//javascript original include char[2],char[3] -> iban.substring(2, 4);  >> trim leading zeros ex. "08" to "8"
      var modul97Check = reference.substring(4).replace(/\-/g, "");//javascript original include from char[4] afterwards -> iban.substring(4);

      var digits = "";

      for (var i = 0; i < modul97Check.length; i++) {
        var ch = modul97Check[i];

        if ('0' <= ch && ch <= '9') {
          digits = digits + ch;
        }
        else {
          digits = digits + Capital2Digits(ch);
        }
      }

      digits = digits.toString() + "00";

      var bigDigit = bigInt(digits).mod(97).toString();
      var checksum1 = bigInt(98).minus(bigDigit).toString();

      if (checksum1 == checksum) {
        return true;
      }
      else {
        return false;
      }
    };

    ValidationServiceBase.DontAllowCharsToRepeat = function (text, repeating) {
      if (!text.length) {
        return '';
      }
      var sTmp = text;
      var len = sTmp.length;

      // HSLhyc12612 - nisu dozvoljena 3 interpunkcijska znaka zaredom
      var sRegExp = /[\/\-\?\:\(\)\.\,\+]/gi;
      var sTmpNew = "";
      var chrNo = 0;
      for (var i = 0; i < sTmp.length; i++) {
        if (sTmp[i].search(sRegExp) === 0) {
          chrNo++;
        } else {
          chrNo = 0;
        }
        if (i === sTmp.length - 1 && sTmp[i] === '/') {
          // TSK-116378 -- last character shouldnt be "/"
          if (chrNo < 1) {
            sTmpNew = sTmpNew + sTmp[i];
          }
        } else {
          if (chrNo < repeating) {
            sTmpNew = sTmpNew + sTmp[i];
          }
        }
      }

      return sTmpNew;

    }

    ValidationServiceBase.DontAllowCharsToRepeatNumeric = function (text, repeating) {
      if (!text.length) {
        return '';
      }
      var sTmp = text;
      var len = sTmp.length;

      // HSLhyc12612 - nije dozvoljeno više od 23 numerička znaka
      var sRegExp = /[0-9]/gi;
      var sTmpNew = "";
      var chrNo = 0;
      for (var i = 0; i < sTmp.length; i++) {
        if (sTmp[i].search(sRegExp) === 0) {
          chrNo++;
        }
        if (i === sTmp.length - 1 && sTmp[i] === '/') {
          // TSK-116378 -- last character shouldnt be "/"
          if (chrNo < 1) {
            sTmpNew = sTmpNew + sTmp[i];
          }
        } else {
          if (chrNo < repeating) {
            sTmpNew = sTmpNew + sTmp[i];
          }
        }
      }

      return sTmpNew;

    }

    function Capital2Digits(ch) {
      var capitals = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var value = 0;
      for (var i = 0; i < capitals.length; i++) {
        value = i;
        if (ch == capitals[i]) {
          break;
        }
      }
      value = value + 10;
      return value;
    }

    return ValidationServiceBase;
  }
}());
