<div class="statementModal logout-modal-frame"> 
  <div class="modal-body p-0">
    <!--X--> 
    <div class="row">
      <div class="col-md-12">
        <div  style="color: white; padding-top: 50px;">
         
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-2 col-sm-3 col-xs-3">
        <i class="icon icon-circle icon-logout"></i>
      </div>
      <div class="col-md-10 col-sm-9 col-xs-9">
          <div class="logout-modal-header" translate="core.logoutModalTranslation.logoutMsg">Do you really want to log out?</div>
        </div>
      </div>
    </div>

  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close('logout')" translate="core.logoutModalTranslation.logoutBtn">Log out</button>
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="core.logoutModalTranslation.cancel">Cancel</button>
  </div>
</div>