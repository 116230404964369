(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaDirectDebitCtrl', SepaDirectDebitCtrl);

  function SepaDirectDebitCtrl($scope, $q, $rootScope, $state, $translate,AlertService, ValidationService, HelperService, CurrencyService, PaymentsService, NotificationService) {

    var vm = this;

    vm.currenciesList = [{"symbol":"EUR"}];

    vm.model = {
      currency: vm.currenciesList[0],
      fromAccountNumber: '',
      amount: '',
      toAccountName: '',
      toAccountNumber: '',
      dateOfExecution: new Date()
    };

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;

    vm.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0));
    };

    vm.openDatePicker = function ($event) {
      this.datePickerOpened = !this.datePickerOpened;
    };

    vm.formSubmitted = false;
    vm.currentStep = 1;

    vm.submit = function () {

      vm.formSubmitted = true;
      if (!vm.directDebitForm.$valid) {
        return;
      }

      var submitObject = {
        toAccountNumber: vm.model.toAccountNumber,
        receiverName: vm.model.toAccountName,
        amount: vm.model.amount,
        currencySymbol: vm.model.currency.symbol,
        dueDate: vm.model.dateOfExecution,
        fromAccountNumber: vm.model.fromAccountNumber
      };

      PaymentsService.sendRefusalOrderMail(submitObject)
        .then(function (response) {
          vm.formSubmitted = false;
          clearForm();
          //var message = $translate.instant('settings.notificationInfo');
          //NotificationService.showTranslatedMessage('success', message, '', 3000);

          AlertService.infoAlert({
            text: "settings.notificationInfo"
          })
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          vm.formSubmitted = false;
        })
    };

    function clearForm() {
      vm.model = {
        currency: vm.currenciesList[0],
        fromAccountNumber: '',
        amount: '',
        toAccountName: '',
        toAccountNumber: '',
        dateOfExecution: new Date()
      }
    }
  }
}());
