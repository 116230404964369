<div class="table-responsive">
  <table class="table table-transparent">
    <tbody>

    <tr ng-if="sepaComplaint.transactionId">
      <td>
        <span class="po-desc-label" translate="sepa.transactionId"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.transactionId}}</span>
      </td>
    </tr>

    <tr>
      <td>
        <span class="po-desc-label" translate="sepa.payerAccount"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.accountIBAN}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="sepa.receiverId"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.receiverIdentificator}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="sepa.receiverAccount"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.receiverAccount}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="sepa.receiverNumber"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.receiverReference}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="sepa.executionDate"></span>
      </td>
      <td>
        <span class="po-value-label">{{sepaComplaint.inputValueDate | date: "shortDate" }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!-- MOBILE -->
<!--<div class="table-responsive visible-xs">-->
  <!--<table class="table table-transparent f-10">-->
    <!--<tbody>-->
    <!--<tr>-->
      <!--<td>-->
        <!--<span class="po-desc-label-xs" translate="sepa.receiverId"></span>-->
      <!--</td>-->
      <!--<td>-->
        <!--<span class="po-value-label-xs">{{sepaComplaint.receiverIdentificator | date: 'shortDate' : '+0200'}}</span>-->
      <!--</td>-->
    <!--</tr>-->
    <!--<tr>-->
      <!--<td>-->
        <!--<span class="po-desc-label-xs" translate="sepa.receiverAccount"></span>-->
      <!--</td>-->
      <!--<td>-->
        <!--<span class="po-value-label-xs">{{sepaComplaint.receiverAccount}}</span>-->
      <!--</td>-->
    <!--</tr>-->
    <!--<tr>-->
      <!--<td>-->
        <!--<span class="po-desc-label-xs" translate="sepa.receiverNumber"></span>-->
      <!--</td>-->
      <!--<td>-->
        <!--<span class="po-value-label-xs">{{sepaComplaint.receiverReference}}</span>-->
      <!--</td>-->
    <!--</tr>-->
    <!--<tr>-->
      <!--<td>-->
        <!--<span class="po-desc-label-xs" translate="sepa.executionDate"></span>-->
      <!--</td>-->
      <!--<td>-->
        <!--<span class="po-value-label-xs">{{sepaComplaint.inputValueDate | date: "shortDate" }}</span>-->
      <!--</td>-->
    <!--</tr>-->
    <!--</tbody>-->
  <!--</table>-->
<!--</div>-->
