<section id="main">
  <div class="container">
    <div class="row">
      <!-- LEFT -->
      <div class="col-sm-4 no-padding-on-phone">
        <div class="wb-list-wrapper app-white-card m-b-15">
          <div>
            <div class="cell-display">
              <i class="zmdi zmdi-chevron-left zmdi-hc-2x"></i>
            </div>
            <div class="cell-display p-l-20 align-vertical-middle">
              <a href="" class="custom-link" data-ng-click="einvoicesIssuersOverview.goBack();" translate="eInvoiceDetail.goBack"></a>
            </div>
          </div>
        </div>
        <div class="app-white-card m-b-15 white-card-einvoice">
          <div class="cell-display align-vertical-middle p-l-15 p-r-15">
            <a href="" class="custom-link-16 custom-link" translate="eInvoicesIssuersOverview.submittedApplications"></a>
          </div>
        </div>
        <div class="app-white-card m-b-15 white-card-einvoice">
          <p class="p-t-10 p-r-10 p-l-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit, ab! Sunt dolores est ullam modi accusamus, iusto esse molestiae, cumque nihil doloremque? Modi quae, necessitatibus explicabo autem error sunt iusto. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit, ab! Sunt dolores est ullam modi accusamus, iusto esse molestiae, cumque nihil doloremque? Modi quae, necessitatibus explicabo autem error sunt iusto
          </p>
        </div>
      </div>
      <!--CENTER-->
      <div class="col-md-8 col-sm-8 col-xs-12 white-card p-l-0 p-r-0">
        <div class="header-card row no-margin header-issuer">
          <b translate="eInvoicesIssuersOverview.header"></b>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 issue-div">
          <span class="col-md-12 col-sm-4 col-xs-6 issue-label" translate="eInvoicesIssuersOverview.issuerName"><b></b></span>

          <div class="col-md-10 col-sm-12 col-xs-12 input-group name-search">
            <div class="input-group-btn srch-icon">
              <button class="btn btn-default btn-search" type="submit">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
            <input type="text" class="form-control srch-input acc-search-input" ng-model="einvoicesIssuersOverview.einvoicesIssuersSearchFilter.searchByName.name" placeholder="{{'eInvoicesIssuersOverview.searchByNamePlaceholder' | translate}}" name="srch-term" id="srch-term">
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 issue-div">
          <span class="col-md-12 col-sm-4 col-xs-6 issue-label" translate="eInvoicesIssuersOverview.issuerTaxId"><b></b></span>

          <div class="col-md-10 col-sm-12 col-xs-12 input-group name-search m-b-5 ">
            <div class="input-group-btn srch-icon">
              <button class="btn btn-default btn-search" type="submit">
                <i class="glyphicon glyphicon-search"></i>
              </button>
            </div>
            <input type="text" class="form-control srch-input acc-search-input" ng-model="einvoicesIssuersOverview.einvoicesIssuersSearchFilter.searchById.identificationNumber" placeholder="{{'eInvoicesIssuersOverview.searchByTaxIdPlaceholder' | translate}}" name="srch-term" id="srch-term">
          </div>
        </div>
        <div ng-hide="einvoicesIssuersOverview.einvoicesIssuersList.result.length == 0">
          <div class="hidden-xs col-md-12">
            <div class="col-md-5 col-sm-4 col-xs-12 issuer-name-label" translate="eInvoicesIssuersOverview.issuerName">Issuer name</div>
            <div class="col-md-5 col-sm-5 col-xs-12 issuer-taxID-label" translate="eInvoicesIssuersOverview.issuerTax">Tax ID / Account</div>
            <div class="col-md-2 col-sm-3 col-xs-12 issuer-button-label"></div>
            <div class="col-md-12">
              <hr/> </div>
          </div>
          <div kcd-recompile="einvoicesIssuersOverview.einvoicesIssuersList.result">
            <div class="col-md-12 " ng-repeat="issuer in ::einvoicesIssuersOverview.einvoicesIssuersList.result | filter:einvoicesIssuersOverview.einvoicesIssuersSearchFilter.searchByName | filter:einvoicesIssuersOverview.einvoicesIssuersSearchFilter.searchById">
              <div class="col-md-5 col-xs-12 issuer-name-value"><b>{{::issuer.name}}</b></div>
              <div class="col-md-5 col-xs-12 issuer-taxID-value">{{::issuer.identificationNumber}}
                <br/>{{::issuer.iban}}</div>
              <div class="col-md-2 col-xs-12 issuer-button-value">
                <button class="btn btn-default app-btn-blue pull-right btn-sm" translate="eInvoicesIssuersOverview.subscribe" data-ui-sref="eInvoices.einvoiceSubscribe({issuerId: issuer.id})">Subscribe</button>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <hr/>
              </div>
            </div>
          </div>
        </div>
        <div class="t-a-c p-10 m-t-20 no-data-image" ng-show="einvoicesIssuersOverview.einvoicesIssuersList.result.length == 0">
          <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
          <h3 class="no-data-label" translate="eInvoicesIssuersOverview.noData"></h3>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-3"></div>
        <div class="col-md-4 col-sm-4 col-xs-5 load-more-issuers">
          <button ng-hide="einvoicesIssuersOverview.einvoicesIssuersList.hasMore == false" data-ng-click="einvoicesIssuersOverview.loadMoreIssuers()" type="input" class="btn btn-default app-btn-blue-inverse " translate="eInvoicesIssuersOverview.showMore">Show more issuers</button>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4"></div>
      </div>
    </div>
  </div>
</section>
