(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name timeline.controller:TimelineCtrl
   *
   * @description
   *
   */
  angular
    .module('timeline')
    .controller('TimelineCtrl', TimelineCtrl);

  function TimelineCtrl() {
    var vm = this;
    vm.ctrlName = 'TimelineCtrl';
  }
}());
