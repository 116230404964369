(function () {
  'use strict';

  angular
      .module('localization')
      .config(config);

  function config($translateProvider) {

    var data = {
      alert: {
        information: "Obvestilo",
        areYouSure: "Ste prepričani?",
        blockCardInfo: "Ste prepričani, da želite blokirati kartico?",
        blockCardCost: "To bo stalo",
        blockCard: "Blokiraj",
        payment: "Plačilo številka",
        template: "Predloga številka",
        termDeposit: "Depozit",
        investment: "Investicija številka",
        subscriptionRequest: "Uspešno ste vnesli zahtevo za prijavo",
        successSign: "Plačilo je bilo uspešno oddano.",
        successSignSlo: "Vaše naročilo je bilo uspešno oddano.",
        closeEBook: "Uspešno ste zaprli eKnjižico",
        cancelRegistration: "Uspešno ste preklicali naročnino",
        ebookOrder: "eKnjižica številka",
        archiveEInvoice: "Uspešno ste arhivirali eRačun",
        saveTemplate: "Uspešno ste shranili predlogo",
        updateTemplate: "Uspešno ste spremenili predlogo",
        deleteAction: "Brisanje",
        cancel: "Zapri",
        yes: "Da",
        no: "Ne",
        success: "Uspeh!"
      }
    };

    $translateProvider
        .translations('sl', data);

  }
}());
