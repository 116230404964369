<!-- Deposit account details -->
<div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">


  <!-- Deposit account details - OTHER-->
  <div class="m-b-10" ng-switch-default>
    <span class="account-header-value white-text"
      ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>

    <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 ) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
      class="account-header-value white-text"
      ng-bind="'\ ' + (accounts.selectedAccount.balance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
    <hr class="account-header-line" />
    <span class="m-t-neg-15 white-text-block size-balance" translate="accounts.accCurrentBalance"></span>

    <div class="row account-header-bottom">
      <div class="col-xs-12 col-md-4">
        <span class="f-20 white-text"
          ng-bind="(accounts.selectedAccount.depositAccount.depositPrincipal |number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>

        <span class="white-text-block size-balance" translate="accounts.accDepositPrincipal"></span>
      </div>
    </div>

    <div class="row p-t-20" ng-show="accounts.isAccountDetailsOpen">

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositInterest">
        <span class="f-20 white-text-block">
          {{accounts.selectedAccount.depositAccount.depositInterest | number:2}}%
        </span>
        <span class="white-text-block" translate="accounts.accDepositInterestRate"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositStartDate">
        <span class="f-20 white-text-block">
          {{accounts.selectedAccount.depositAccount.depositStartDate | date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accDepositStartDate"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositEndDate">
        <span class="f-20 white-text-block">
          {{accounts.selectedAccount.depositAccount.depositEndDate | date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accDepositEndDate"></span>
      </div>

      <!--<div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositTypeExternal">
        <span class="f-20 white-text-block">
          {{accounts.selectedAccount.depositAccount.depositTypeExternal}}</span>
        <span class="white-text-block" translate="accounts.accDepositTypeExternal"></span>
      </div>-->

      <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositIdExternal">
        <span class="f-20 white-text-block cursor-pointer" data-ng-click="accounts.downloadContract()">
          {{accounts.selectedAccount.depositAccount.depositIdExternal}}</span>
        <span class="white-text-block" translate="accounts.accContractNumber"></span>
      </div>
    </div>
  </div>

  <!-- ================================ -->
  <!-- Deposit account details - SLOVENIA-->
  <!-- ================================ -->

  <div class="m-b-10" ng-switch-when="sl">
    <span class="account-header-value white-text-block"
      ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>
    <hr class="account-header-line" />
    <span class="m-t-neg-15 white-text-block size-balance" translate="accounts.accCurrentBalance"></span>

    <div class="row account-header-bottom">
      <div class="col-xs-12 col-md-4">
        <span class="f-20 white-text-block"
          ng-bind="(accounts.selectedAccount.depositAccount.depositPrincipal |number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>
        <span class="white-text-block size-balance" translate="accounts.accDepositPrincipal"></span>
      </div>
    </div>
    <div ng-show="accounts.isAccountDetailsOpen">
      <div class="row p-t-20">
        <div class="col-xs-12 col-md-4 p-b-20"
          ng-if="accounts.selectedAccount.depositAccount.hasOwnProperty('depositInterestAmount')">
          <span class="f-20 white-text-block cursor-pointer">
            {{accounts.selectedAccount.depositAccount.depositInterestAmount | number:2}}
            {{accounts.selectedAccount.currencySymbol}}
          </span>
          <span class="white-text-block" translate="accounts.interestToDate"></span>
        </div>
        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
          <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
            '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
        </div>

        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositInterest">
          <span class="f-20 white-text-block">
            {{accounts.selectedAccount.depositAccount.depositInterest | formatDecimal : 4 : true : 3}}%
          </span>
          <span class="white-text-block" translate="accounts.accDepositInterestRate"></span>
        </div>

        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositStartDate">
          <span class="f-20 white-text-block">
            {{accounts.selectedAccount.depositAccount.depositStartDate | date:'shortDate' : '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accDepositStartDate"></span>
        </div>

        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositEndDate">
          <span class="f-20 white-text-block">
            {{accounts.selectedAccount.depositAccount.depositEndDate | date:'shortDate' : '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accDepositEndDate_SLO"></span>
        </div>

        <div>

          <!--<div class="col-xs-12 col-md-4 p-b-20"-->
          <!--ng-if="accounts.selectedAccount.depositAccount.depositIdExternalExtension ">-->
          <!--<span class="f-20 white-text-block cursor-pointer">-->
          <!--{{accounts.selectedAccount.depositAccount.depositIdExternalExtension }}</span>-->
          <!--<span class="white-text-block" translate="accounts.destinationAccount"></span>-->
          <!--</div>-->
          <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
            <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
            <span class="white-text-block" translate="ebook.status"></span>
          </div>

          <div class="col-xs-12 col-md-4 p-b-20"
            ng-if="accounts.selectedAccount.depositAccount.depositProlongationNumLeft">
            <span class="f-20 white-text-block cursor-pointer">
              {{accounts.selectedAccount.depositAccount.depositProlongationNumLeft}}</span>
            <span class="white-text-block" translate="accounts.depositProlongationNumLeft"></span>
          </div>
          <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositPeriod">
            <span class="f-20 white-text-block cursor-pointer">
              {{accounts.selectedAccount.depositAccount.depositPeriod}} <span translate="accounts.days"></span>
            </span>
            <span class="white-text-block" translate="accounts.bindingSpan"></span>
          </div>
          <div class="col-xs-12 p-b-20" ng-if="accounts.selectedAccount.depositAccount.destinationAccount">
            <span class="f-20 white-text-block">
              {{accounts.selectedAccount.depositAccount.destinationAccount | formatIban}}
            </span>
            <span class="white-text-block" translate="accounts.destinationAccount_SLO"></span>
          </div>
        </div>
      </div>
      <div class="row p-t-20">
        <div class="col-xs-12 col-md-12 p-b-20" ng-if="accounts.selectedAccount.depositAccount.extensionType">
          <span class="f-20 white-text-block cursor-pointer">
            {{accounts.selectedAccount.depositAccount.extensionType}}</span>
          <span class="white-text-block" translate="accounts.extensionType"></span>
        </div>
      </div>
    </div>
  </div>


</div>