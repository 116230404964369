(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:LoanPaymentCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('LoanPaymentCtrl', LoanPaymentCtrl);

  function LoanPaymentCtrl($log, $state, $rootScope, $translate, PaymentsService, PaymentTypesService, HelperService, $filter, AccountsService,
                            ConfigEndpointService, Notification, AlertService, GoogleTagManagerHelperService,$location, NotificationService) {
    var vm = this;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

    vm.tempLoanObj = {
      fromAccountSelect: {},
      toAccountInput: '',
      amountInput: '',
      purposeCodeSelect: {},
      paymentPurposeInput: '',
      //dueDateInput: $filter('date')(new Date(), 'shortDate'),
      dueDateInput: new Date(),
      receiverNumberCountryCode: '',
      receiverNumber1: '',
      receiverNumber2: '',
      receiverName: '',
      receiverAddress1: '',
      receiverCity: '',
      formSubmitted: false
    };
    vm.purposeCodesList = [];
    vm.datePickerOpened = false;
    vm.referenceNumber = '';
    vm.purposeCodeDPS = null;
    vm.bankCharges = '';
    vm.currentStep = 1;
    vm.forUpdate = false;

    vm.fromAccountDropdown = false;
    vm.maxLength = 12;
    vm.maxAmount = 999999999999.99;

    if(vm.countryCode == 'sl'){
      vm.maxAmount = 99999999999.99;
      vm.maxLength = 14;
    }


    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };
    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };


    // vm.smsAuthentificate = false;

    vm.goBackToPayments = function (){
      $state.go('payments.paymentsOverview');
    };

    vm.init = function () {
      getReceiverDetails()
        .then(function () {
        vm.getDebitAccountList();
        if ($state.params && $state.params.accountId) {
          vm.getLoanTypeCreditInfo($state.params.accountId);
        }
      })
        .catch(function (error) {
        $log.debug('getReceiverDetails()', error);
      });
    };


    vm.getDebitAccountList = function () {
      PaymentTypesService.getByGroup({paymentTypeGroup: 'DomesticPayment'})
        .then(function (response) {
        if (_.isArray(response.result) && response.result.length > 0) {
          vm.accountsFrom = response.result[0].debitAccountList;

          _.forEach(vm.accountsFrom, function (item) {
            if (item.accountDescription) {
              item.optionValue = item.accountDescription + ' - ' + item.iban;
            }
          });

          if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
            vm.tempLoanObj.fromAccountSelect = vm.accountsFrom[0];
          }
        }
      })
        .catch(function (error) {
        $log.error(error);
      });
    };

    vm.getLoanTypeCreditInfo = function (accountId) {
      if (accountId) {
        AccountsService.getPage({accountId: accountId, includeList:['loanAccount']})
          .then(function (response) {
          if (response.result[0].accountNumber && vm.countryCode != 'sl') {
            var letters = response.result[0].accountNumber.split(' ');
            vm.tempLoanObj.receiverNumber1 = letters[0];
            vm.tempLoanObj.receiverNumber2 = letters[1];
            if (response.result[0].ownerName) {
              var numberParts = vm.tempLoanObj.receiverNumber2.split('-');
              vm.tempLoanObj.paymentPurposeInput = numberParts[1] + ' - ' + response.result[0].ownerName;
            }
            vm.tempLoanObj.receiverNumberCountryCode = 'HR';
          }else if(vm.countryCode === 'sl' && response.result[0].loanAccount){
            vm.tempLoanObj.receiverNumberCountryCode = response.result[0].loanAccount.reference.substring(0,2);
            vm.tempLoanObj.receiverNumber1 = response.result[0].loanAccount.reference.substring(2,4);
            vm.tempLoanObj.receiverNumber2 = response.result[0].loanAccount.reference.substring(4);
            vm.purposeCodeDPS = response.result[0].loanAccount.purposeCodeDPS;
          }
          if(response.result[0].loanAccount.totalMatureInLocalCurrency) {
            vm.tempLoanObj.amountInput = response.result[0].loanAccount.totalMatureInLocalCurrency;
          }
        })
          .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }
    };

    vm.submitLoanPayment = function () {
      if (vm.nextButtonPressed) return;
      vm.tempLoanObj.formSubmitted = true;
      if (!vm.loanPaymentForm.$valid) {
        return;
      }
      var paymentObject = {};
      vm.referenceNumber =  vm.tempLoanObj.receiverNumber1 + vm.tempLoanObj.receiverNumber2;
      paymentObject = createPaymentObject();
      if(vm.purposeCodeDPS){
        paymentObject.purposeCodeDPS = vm.purposeCodeDPS;
      }
      vm.nextButtonPressed = true;
      if (vm.forUpdate) {
        paymentObject.id = vm.paymentResponse.id;
        PaymentsService.updatePayment(vm.paymentResponse.id, paymentObject)
          .then(function (response) {

            if (response.dueDateChanged) {
              AlertService.confirmationAlert({
                text: "payments.paymentDueDateChangedMsg"
              })
                .then(function (accept) {
                  if (!accept) return;
                  secondValidate(response);
                })
            } else {
              postPaymentAction(response);
              vm.forUpdate = false;
            }
        })
          .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          vm.forUpdate = false;
        }).finally(function(){
          vm.nextButtonPressed = false;
        });
      } else {
        PaymentsService.insertPayment(paymentObject)
          .then(function (response) {
            var gtmObject = HelperService.processUrlForGTM('payment');
            GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
            if (response.dueDateChanged) {
              AlertService.confirmationAlert({
                text: "payments.paymentDueDateChangedMsg"
              })
                .then(function (accept) {
                  if (!accept) return;
                  secondValidate(response);
                })
            } else {
              postPaymentAction(response);
            }
        })
          .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function(){
          vm.nextButtonPressed = false;
        });
      }
    };

    function secondValidate(validatedUPOrder) {
      if (vm.secondValidateInProgress) return;
      vm.secondValidateInProgress = true;
      PaymentsService.insertPayment(validatedUPOrder)
        .then(function (response) {
          postPaymentAction(response);
        })
        .catch(function (response, error) {
            NotificationService.showMessage(response, 'error');
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.secondValidateInProgress = false;
        });
    }

    var createPaymentObject = function () {
      var reference = '';
      var referenceFrom = '99';
      var date = new Date();
      if(vm.countryCode == 'sl'){
        reference = 'SI';
        referenceFrom = 'NRC';
        date = vm.tempLoanObj.dueDateInput;
      }

      return {
        accountId: vm.tempLoanObj.fromAccountSelect.accountId,
        fromAccountNumber: vm.tempLoanObj.fromAccountSelect.iban,
        toAccountNumber: vm.tempLoanObj.toAccountInput,
        currencySymbol: vm.tempLoanObj.fromAccountSelect.currencySymbol,
        purpose: vm.tempLoanObj.paymentPurposeInput,
        amount: vm.tempLoanObj.amountInput,
        paymentTypeGroup: 'DomesticPayment',
        dueDate: date,
        domesticPayment: {
          referenceTo: reference+vm.tempLoanObj.receiverNumber1 + vm.tempLoanObj.receiverNumber2,
          //referenceFrom: vm.tempLoanObj.receiverNumberCountryCode + vm.tempLoanObj.receiverNumber1 + vm.tempLoanObj.receiverNumber2,
          referenceFrom: referenceFrom,
          senderName: vm.tempLoanObj.fromAccountSelect.ownerName,
          senderAddress1: vm.tempLoanObj.fromAccountSelect.ownerAddress,
          senderCity: vm.tempLoanObj.fromAccountSelect.ownerCity,
          receiverName: vm.tempLoanObj.receiverName,
          receiverAddress1: vm.tempLoanObj.receiverAddress1,
          receiverCity: vm.tempLoanObj.receiverCity,
          priority: 'LA'
        }
      }
    };

    vm.editPayment = function (){
      mapWithPaymentResponse();
      vm.forUpdate = true;
      vm.currentStep = 1;
    };

    vm.deletePayment = function () {
      PaymentsService.deletePayment(vm.paymentResponse.id)
        .then(function (response) {
        HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], vm.paymentResponse.id);
        $state.go('payments.paymentsOverview');
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    var mapWithPaymentResponse = function (){
      vm.tempLoanObj.toAccountInput = vm.paymentResponse.toAccountNumber;
      vm.tempLoanObj.amountInput = vm.paymentResponse.amount;
      vm.tempLoanObj.paymentPurposeInput = vm.paymentResponse.purpose;
      vm.tempLoanObj.dueDateInput = new Date(vm.paymentResponse.dueDate);

      var accountIndex = _.findIndex(vm.accountsFrom, function (item){
        return vm.paymentResponse.accountId == item.accountId;
      });
      vm.tempLoanObj.fromAccountSelect = vm.accountsFrom[accountIndex];
    };

    /*    vm.signPayment = function () {
      PaymentsService.signPayment(vm.paymentResponse.id)
        .then(function (response) {
          vm.currentStep = 3;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };*/

    vm.toggleFromAccount = function (){
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.setAccountFrom = function (item){
      vm.tempLoanObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    /*    vm.showSMSAuthentification = function () {
      vm.smsAuthentificate = !vm.smsAuthentificate;
    };*/

    var clearInput = function (){
      vm.tempLoanObj.fromAccountSelect = vm.accountsFrom[0];
      vm.tempLoanObj.amountInput = '';
      vm.tempLoanObj.paymentPurposeInput = '';
      vm.tempLoanObj.formSubmitted = false;
    };

    var postPaymentAction = function (response) {
      vm.paymentResponse = response;
      vm.paymentResponse.paymentTypeGroup = "DomesticPayment";
      vm.paymentResponse.senderInfo = {
        senderName: vm.tempLoanObj.fromAccountSelect.ownerName,
        senderAddress1: vm.tempLoanObj.fromAccountSelect.ownerAddress,
        senderCity: vm.tempLoanObj.fromAccountSelect.ownerCity
      };
      Notification.clearAll();
      vm.currentStep = 2;
    };

    vm.backToLoanPayment = function (){
      clearInput();
      vm.currentStep = 1;
    };

    vm.sign = function () {
      if (vm.confirmButtonPressed) return;
      var params = {
        id: vm.paymentResponse.id,
        payment: vm.paymentResponse
      };
      vm.confirmButtonPressed = true;
      return PaymentsService.signPayment(params)
        .then(function (signedPayment) {
          var id = (signedPayment.id) ? signedPayment.id : vm.paymentResponse.id;
          // TODO: Use signed payment on response
          $state.go('payments.finalStep', {paymentObject:signedPayment, paymentId: id, type: 'loan'});
        }).catch(function (error) {
          $log.error(error);
          NotificationService.showMessage(error, 'error');
        }).finally(function() {
          vm.confirmButtonPressed = false;
        });
    };

    var getReceiverDetails = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
        if (response) {
          vm.tempLoanObj.toAccountInput = response.BankAccount;
          vm.tempLoanObj.receiverName = response.BankName,
            vm.tempLoanObj.receiverAddress1 = response.BankAddress;
          vm.tempLoanObj.receiverCity = response.BankCity;
          vm.bankCharges = response.BankCharges;
          vm.countryCode = response.APILocation;
          if(vm.countryCode == 'sl'){
            setDefaultPurpose();
            vm.tempLoanObj.paymentPurposeInput = $translate.instant('loanPayment.defaultPurpose');
          }
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    var setDefaultPurpose = function () {
      $rootScope.$on('$translateChangeSuccess', function () {
        $translate('loanPayment.defaultPurpose').then(function (translations) {
            vm.tempLoanObj.paymentPurposeInput = $translate.instant('loanPayment.defaultPurpose');
        });
      });
    }


    vm.init();
  }
}());
