(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      sepa: {
        "header": "SEPA Direct Debit Settings",
        "addNew": "Add a new setting",
        "newSepaHeader": "Add new SEPA direct debit setting",
        "debitAccountSelectLabel": "For account",
        "noData": "No data",
        "settingType": "Setting type",
        "amountInputLabel": "Amount",
        "cancelBtnLabel": "Cancel",
        "confirmBtnLabel": "Confirm",
        "edit": "Edit",
        "transactionsHeader": "SEPA Direct Debit Transactions",
        "transactionsSetLimits": "Set limits and restrictions",
        "sepaSuccessCreate": "Order was successfully submitted",
        "complaint": "Refusal",
        "refund": "Refund",
        "back": "Back",
        "isActive": "Activated",
        "notActive": "Deactivated",
        "changeToActivated": "Do you really want to activate the setting?",
        "changeToDeactivated": "Do you really want to deactivate the setting?",
        "activateSuccess": "Setting is successfully activated!",
        "deactivateSuccess": "Setting is successfully deactivated!",
        "requiredSettingTypeError": "Settings type should not be left empty.",
        // CROATIA
        header_CRO: "SEPA direct debit limit",
        toAccount: "Account",
        title: "Creation and change of limit",
        name: "Name",
        address: "address",
        place: "Place",
        inputDate: "Input date",
        restrictSEPA: "Zabrana izvršenja svih SEPA izravnih terećenja (račun u potpunosti nedostupan za SDD uslugu)",
        newSepaHint1: "Note: Bank is charging entry/change of SEPA direct debit limitations in accordance with the",
        newSepaHint2: "Decision on Bank service charges.",
        dateFrom: "Date from",
        dateTo: "Date to",
        limitType: "Limit type",
        whiteList: "White list",
        blackList: "Black list",
        amountRestriction: "Restrictions on amount and period",
        lastChangeDate: "Date of last change",
        operater: "Operater",
        delete: "Delete",
        confirm: "Confirm",
        executionConditions: "Execution conditions",
        receiverCalculation: "Calculating an identifier of the recipient",
        receiverId: "Receiver identifier",
        receiverName: "Receiver name",
        identifierApproval: "Identifier approval",
        singleChargeMaxAmount: "The maximum amount of single charge",
        dynamicExecution: "Dynamic execution",
        signSuccessfull: "SEPA form is successfully signed!",
        deactivate: "Deactivate",
        deactivateAlert: "Do you really want to deactivate the setting ?",
        sepaSettingsRestrictions: "Overview of execution limit",
        sepaSettingsHistory: "History of execution limits",
        sepaRequestsOverview: "Overview of requests",
        refusalOrderBtn: "Reject order",
        refusalTitle: "Rejection of not accepted SDD orders",
        refusalAcceptedTitle: "Rejection of accepted SDD orders",
        executionDate: "Execution date",
        amount: "Amount",
        refusalReceiverName: "Receiver name and address",
        receiverAccount: "Receiver account",
        receiverNumber: "Receiver number",
        status_created: "Created",
        status_recalled: "Recalled",
        status_processed: "Processed",
        status_rejected: "Rejected",
        activation: "Activation",
        deactivation: "Deactivation",
        payerAccount: "Payer account",
        transactionId: "Transaction ID",
        transactionDetails: {
          payerAccount: "Payer account number",
          receiverAccount: "Receiver account number",
          creditorId: "Creditor identifier"
        },
        sddPaymentReturn: "Refund SDD order",
        sddPaymentReturnInfo: "You have filed a claim for the repayment of SDD money order funds. Want to continue with the transaction ?",
        paymentsType: {
          "rejectedTab": "Rejected transactions",
          "executedTab": "Executed transactions",
          "pendingTab": "Pending transactions"
        },
        directDebitTabs: {
          pending: "Accepted SDD orders",
          processed: "Processed SDD orders",
          rejected: "Rejected SDD orders"
        },
        refundComplaint: {
          "paymentStatus": "Payment status",
          "executionDate": "Date of execution",
          "valuteDate": "Execution date",
          "payersAccNumber": "Payer's account number",
          "uniqueMandateReference": "Unique mandate reference",
          "recipient": "Recipient",
          "recAccNumber": "Recipient's account number",
          "recIdentifier": "Recipient's identifier",
          "paymentPurpose": "Payment purpose",
          "recRefNumber": "Recipient's reference number",
          "reasonForRefund": "Reason for Refund",
          "reasonForComplaint": "Reason for Refusal",
          "additionalExplanation": "Additional explanation",
          "refundTitle": "Refund of SEPA DD transaction",
          "complaintTitle": "Refusal of SEPA DD transaction",
          "next": "Next",
          "cancel": "Cancel",
          "orderComment": "Order comment"
        },
        "loadMore": "Load more payments",
        sepaStatus: {
          "Waiting": "Pending",
          "Processed": "Successfully processed",
          "Rejected": "Rejected",
          "ProcessedOrRejected": "Processed or rejected"
        },
        "executionPeriod": {
          "": "",
          "WEEKLY": "Weekly",
          "MONTHLY": "Monthly",
          "QUARTERLY": "Quarterly",
          "HALFYEAR": "Semi annually",
          "YEARLY": "Annually"
        },
        "executionType": {
          "BLOCK_ALL": "Refuse all SEPA direct debits",
          "WHITE_LIST": "White List",
          "BLACK_LIST": "Black List",
          "DYNAMIC_LIST": "Amount and period limitation",
          "AMOUNT": "Limit by amount and frequency"
        },
        "executionPeriodLabel": "Execution period:",
        "additionalInfo":"Additional info",
        "payeeName": "Payee name",
        "payeeIban": "Payee IBAN",
        "payerIban": "Payer IBAN",
        "date": "Execution date",
        "amountAndCurrency": "Transaction amount and currency",
        "sendMsgButton": "Send message",
        "heading": "Direct debit – refuse payment order prior to execution",
        "subheading1": "Refusal of payment order of accepted direct debit can be executed prior to the execution date of payment transaction by sending a message in a template attached below.",
        "subheading2": "Respective message should be sent on working days (Monday - Friday) in the period between 8:00 a.m. and 4:00 p.m.",
        "subheading3": "I would like to refuse following direct debit transaction"
      }

    };

    $translateProvider
      .translations('en', data);
  }
}());
