<div class="receiver-section" style="padding: 15px">
  <div class="domp-to-section m-t-10 m-b-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" ng-if="investment.responseModel.accountIBAN"
                 translate="{{investment.model.orderType == 2 ? 'invest.accountTo':'invest.accountFrom'}}"></label>
          <label class="d-block domp-form-label-resume">{{investment.responseModel.accountIBAN}}</label>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6">
        <div class="row">
          <div class="col-sm-6" ng-if="investment.responseModel.invAmount && investment.model.amountType == 2">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel">Amount</label>
            <label class="d-block domp-form-label-resume">
              {{investment.responseModel.invAmount|number:2}}
              {{investment.responseModel.amountCurCode|| investment.responseModel.sellingCurSym}}
            </label>
          </div>
          <div class="col-sm-6" ng-if="investment.responseModel.invAmount && investment.model.amountType == 3">
            <label class="domp-form-label-review" translate="investOverview.shareQuantity">Quantity</label>
            <label class="d-block domp-form-label-resume">
              {{investment.responseModel.invAmount|number:4}}
            </label>
          </div>

          <div class="col-sm-6">
            <!--<label class="exchange-resume-label" translate="domesticPayment.dateInputLabel">When</label>-->
            <label ng-show="investment.orderTypeText != 'new'" class="domp-form-label-review" for="dateInput" translate="invest.dueDate">Date</label>
            <label ng-show="investment.orderTypeText == 'new'" class="domp-form-label-review" for="dateInput" translate="invest.dateNewOrder">Date</label>
            <label class="d-block domp-form-label-resume">
              {{investment.responseModel.invAmountDate| date:'shortDate' : '+0200'}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <span ng-switch="investment.responseModel.orderType">
              <label ng-switch-when="1" class="domp-form-label-review" translate="invest.buyingFond"></label>
              <label ng-switch-when="2" class="domp-form-label-review" translate="invest.sellingFond"></label>
              <label ng-switch-when="3" class="domp-form-label-review" translate="invest.replacementFond"></label>
            </span>
            <label class="d-block domp-form-label-resume">{{investment.responseModel.fundName}}</label>
          </div>
          <div class="col-sm-6 domp-form-label-review" ng-show="investment.responseModel.accountType">
            <label class="domp-form-label-review" translate="invest.accountType"></label>
            <label class="d-block domp-form-label-resume">no info</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="domp-to-section m-b-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="invest.note"></label>
          <label class="d-block domp-form-label-resume">{{investment.responseModel.note}}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- START Buttons -->
  <div class="row" ng-if="!investment.investmentSigned">
    <div class="col-md-10 p-b-10">
      <button class="btn btn-primary " translate="invest.back"
              data-ui-sref="investments.form.new({orderType: investment.orderTypeText, fundId: investment.fundId})"  ui-sref-opts="{reload:true}">
      </button>
      <button class="btn btn-primary" translate="payments.edit"
              data-ui-sref="investments.form.new({orderType: investment.orderTypeText, fundId: investment.fundId})">
      </button>
      <button class="btn btn-primary-warning " translate="payments.delete"
              ng-disabled="investment.deleteButtonDisabled"
              data-ng-click="investment.delete();">
      </button>
    </div>

    <div class="col-md-2 p-b-10">
        <button class="btn btn-default"
                translate="currencyConversion.sign"
                ng-disabled="investment.signButtonDisabled"
                ng-click="investment.investmentSigned = true">
        </button>
    </div>
  </div>
  <!-- END Buttons -->
  <div class="row m-b-10" ng-if="investment.investmentSigned">
    <div class="col-xs-12 m-t-30">
      <form name="investment.challengeForm" novalidate>
        <div class="col-xs-12 m-b-20">
          <span class="f-w-bold">
            {{'signPayments.challengeDescription'| translate}}
          </span>
        </div>
        <div class="col-sm-4 col-xs-6">
          <div class="f-w-bold wrap-text">{{'signPayments.challengeFirstCode'| translate}}</div>
          <input type="text" class="form-control input-field" disabled
                 data-ng-model="investment.responseModel.challenge">
        </div>
        <div class="col-sm-4 col-xs-6">
          <div class="f-w-bold wrap-text">{{'signPayments.challengeSecondCode'| translate}}</div>
          <div class="input-group">
            <input type="{{investment.showTokenInput ? 'text' : 'password'}}" class="form-control input-field"
                   data-ng-model="investment.model.password2"
                   ng-pattern-restrict="{{investment.regexPatterns.onlyNumbers}}"
                   placeholder="{{'signPayments.challengeSecondCode'| translate}}">
            <span class="input-group-btn">
              <button class="btn btn-default input-group-app-btn domp-btn-height" data-ng-click="investment.showTokenInput = !investment.showTokenInput" style="border:1px solid #062a42;">
                <i class="zmdi zmdi-lock"></i>
              </button>
            </span>
          </div>

        </div>
        <div class="col-sm-4 col-xs-12 m-t-20">
          <button class="btn btn-default col-sm-8 col-xs-4 pull-right"
                  ng-disabled="investment.confirmSignedButtonDisabled"
                  data-ng-click="investment.confirmSignedInvestment()"
                  translate="signPayments.confirm">
          </button>
        </div>
      </form>
    </div>

  </div>
</div>




