<!--<section id="main">-->
  <!--<div class="container">-->
    <span>
        <!-- START Title -->
        <!--<div class="domp-header-wrapper col-sm-12 p-o">-->
          <!--<div class="domp-header-row">-->
            <!--<h2 class="intp-title" translate="invest.header"></h2>-->
          <!--</div>-->
        <!--</div>-->
      <!-- END Title-->
      <div class="domp-select-section col-sm-12 p-0">
        <!-- ADD SELECT FROM TEMPLATE HERE ! -->
        <!-- FIRST BOX -->
        <ng-form name="investments.paymentForm" novalidate>
          <!-- END FIRST ROW-->

          <!-- START SECOND BOX-->
          <div class="receiver-section" style="padding: 10px">
            <div class="row">
              <!-- START From Account - Custom Dropdown -->
              <div class="col-md-6">
                <label class="domp-form-label" translate="invest.accountFrom"></label>
                <div class="accounts-dropdown">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                       data-ng-click="investments.showAccountToDropdown = !investments.showAccountToDropdown" ng-style="investments.tempInvestObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + investments.tempInvestObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font">
                      {{investments.tempInvestObj.fromAccountSelect.friendlyName}}
                                    <span class="favorite-star-container">
                                      <i class="zmdi"
                                         ng-class="{'zmdi-star favorite-account':account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                                    </span>
                    </h4>
                    <p class="no-bottom-margin">{{investments.tempInvestObj.fromAccountSelect.iban}}</p>
                    <p class="m-b-0 bold-font domp-available-balance">
                      {{investments.tempInvestObj.fromAccountSelect.availableBalance | number:2}}
                      {{investments.tempInvestObj.fromAccountSelect.currencySymbol}}
                    </p>
                    <span class="d-block m-b-9" translate="currencyConversion.availableBalance"></span>
    <span class="caret-icon">
                                    <i class="zmdi zmdi-caret-down zmdi-hc-2x"></i>
                                  </span>
                  </div>
                  <div class="from-accounts-list" ng-show="investments.showAccountToDropdown" scrollable>
                    <div class="accounts-list-item" data-ng-repeat="item in investments.accountsFrom"
                         data-ng-click="investments.setAccountFrom(item); investments.showAccountToDropdown = !investments.showAccountToDropdown">
                      <span class="d-block f-16 bold-font">{{item.friendlyName}}</span>
                      <span class="d-block">{{item.iban}}</span>
                      <span
                        class="d-block bold-font">{{item.availableBalance | number: 2}} {{item.currencySymbol}}</span>
                      <span class="d-block" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END From Account - CUSTOM DROPDOWN-->
              <!-- START Amount and Date -->
              <div class="col-md-6">
                <!--<div class="row">-->
                <!-- START Amount -->
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput" translate="invest.amountInputLabel">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                           autocomplete="off" placeholder="{{'invest.amountPlaceholder'| translate}}"
                           data-input-mask="'core.moneyFormat.mask'"
                           data-input-mask-options="{reverse: true,optional: true}"
                           mask-val='investments.tempInvestObj.amountInput'
                           ng-model="investments.tempInvestObj.amountInput"
                           required
                           ng-class="{'domp-error': investments.paymentForm.amountInput.$invalid && investments.tempInvestObj.formSubmitted}"
                           maxlength="11">
        <span class="input-group-btn">
                                      <button type="button"
                                              class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">
                                        {{investments.tempInvestObj.fromAccountSelect.currencySymbol}}
                                      </button>
                                    </span>
                  </div>
      <span class="c-red"
            ng-show="investments.paymentForm.amountInput.$error.required && investments.tempInvestObj.formSubmitted"
            translate="internalTransfer.amountInputError">
                                </span>
                  <!--</div>-->
                </div>
                <!-- End Amount-->
                <!-- START Date-->
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput" translate="invest.date">Date</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="shortDate" show-weeks="false"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           ng-model="investments.tempInvestObj.dueDateInput" show-weeks="false"
                           is-open="investments.datePickerOpened" datepicker-options="investments.dateOptions"
                           readonly>
        <span class="input-group-btn">
                                      <button data-ng-click="investments.openDatePicker()" type="button"
                                              class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                                      </button>
                                    </span>
                  </div>
                </div>
              </div>
              <!-- END Date-->


              <!-- END Amount and Date -->
            </div>
          </div>

          <div class="receiver-section" style="padding: 10px">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label" for="toAccountInput" translate="invest.accountTo">
                    Account number
                  </label>
                  <input type="text" class="form-control input-field" capitalize
                         ng-pattern-restrict="{{investments.regexPatterns.allLettersNumbersSpaces}}"
                         ui-options="investments.uiMaskOptions"
                         ng-model="investments.tempInvestObj.toAccountInput"
                         ng-class="{'domp-error': investments.paymentForm.toAccountInput.$invalid && investments.tempInvestObj.formSubmitted}"
                         name="toAccountInput" id="toAccountInput" required maxlength="39" ng-trim="false">
          <span class="c-red"
                ng-show="investments.paymentForm.toAccountInput.$error.required && investments.tempInvestObj.formSubmitted"
                translate="domesticPayment.receiverAccountError"></span>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label" translate="invest.fundName"></label>
                  <select class="form-control input-field" data-ng-model="investments.tempInvestObj.fundSelect"
                          ng-options="item.fundName for item in investments.fundList"
                  ng-change="investments.fundChange();">
                  </select>

                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-offset-6 col-sm-offset-6 col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label" translate="invest.accountType">Account
                    type</label>
                  <select class="form-control input-field p-l-15 p-r-10"
                          ng-model="investments.tempInvestObj.accountType"
                          ng-options="item.key| translate for item in investments.accountTypes">
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <div class="no-padding-on-phone">
                  <label class="domp-form-label domp-purpose-label"
                         translate="invest.comment">Comment</label>
                  <div class="p-0">
                    <input type="text" class="form-control input-field"
                           data-ng-model="investments.tempInvestObj.comment"
                           name="comment"
                           ng-class="{'domp-error': investments.paymentForm.paymentPurposeInput.$invalid && investments.tempInvestObj.formSubmitted}">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-form>
        <!-- END NEW SECOND BOX -->

        <div class="intp-form-container">
          <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 p-l-0">
              <div class="col-xs-12">
                <label class="checkbox checkbox-inline-inline checkbox-value">
                  {{'invest.belowComment' | translate}}
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-default "
                    translate="domesticPayment.confirmBtn"
                    ng-disabled="investments.submitButtonDisabled"
                    data-ng-click="investments.submitInvestment($event);">
            </button>
          </div>
        </div>
      </div>
  </span>


    <!-- START STEP NUMBER 2 -->
    <!--<div ng-if="investments.steps.step2">-->
    <!--<div data-ng-include="'payments/international-payment-resume.tpl.html'"></div>-->
    <!--</div>-->
    <!--&lt;!&ndash; END STEP NUMBER 2&ndash;&gt;-->
    <!--&lt;!&ndash; START STEP NUMBER 3 &ndash;&gt;-->
    <!--<div ng-if="investments.steps.step3">-->
    <!--<payment-final-step payment="investments.paymentResponse" is-modal="false"></payment-final-step>-->
    <!--</div>-->
    <!-- END STEP NUMBER 3 -->

  <!--</div>-->
<!--</section>-->

