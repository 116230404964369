<section id="main">
  <div class="container">
    <!-- START Title -->
    <div class="row">
      <div class="col-sm-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
          <h3>
            <b translate="accessInvestment.accession"></b>
          </h3>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.nameSurname"></label>
        <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.nameSurnameInput" disabled>
      </div>
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.address"></label>
        <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.addressInput" disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.identificationNumber"></label>
        <input type="text" class="form-control input-field"
               data-ng-model="accessInv.tempAccessionObj.identificationNumberInput">
      </div>

      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.issuer"></label>
        <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.issuerInput">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.phone"></label>
        <div class="input-group">
          <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.personalHomePhoneInput">
                 <span class="input-group-btn">
                        <button type="button"
                                class="btn input-group-app-btn domp-btn-height"><i
                          class="glyphicon glyphicon-phone"></i>
                        </button>
                  </span>
        </div>
      </div>

      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.emailNotification"></label>
        <div class="input-group">
          <input type="text" class="form-control input-field"
                 data-ng-model="accessInv.tempAccessionObj.personalEmailInput">
                 <span class="input-group-btn">
                        <button type="button"
                                class="btn input-group-app-btn domp-btn-height"
                                ng-class="{'red-email': accessInv.tempAccessionObj.sendViaEmail}"
                        data-ng-click="accessInv.tempAccessionObj.sendViaEmail = !accessInv.tempAccessionObj.sendViaEmail;"><i
                          class="glyphicon glyphicon-envelope"></i>
                        </button>
                  </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.bank"></label>
          <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.bankInput" disabled>
      </div>

      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.accountNumberInput"></label>
        <div class="row">
          <div class="col-xs-12">
            <input type="text" class="form-control input-field" capitalize
                   ui-mask="HR 99 9999 9999 9999 9999 9"
                   ui-mask-placeholder
                   ui-mask-placeholder-char="_"
                   ui-options="accessInv.uiMaskOptions"
                   minlength="21"
                   ng-model="accessInv.tempAccessionObj.toAccount2Input"
                   ng-class="{'domp-error': (!accessInv.tempAccessionObj.toAccount2Input || accessInv.tempAccessionObj.toAccount2Input.length < 19) && accessInv.formSubmitted}"
                   name="toAccount2Input" id="toAccount2Input"
                   required />
          </div>
          <!--<div class="col-sm-4">-->
            <!--<input type="text" class="form-control input-field"-->
                   <!--data-ng-model="accessInv.tempAccessionObj.toAccount1Input" disabled>-->
          <!--</div>-->
          <!--<div class="col-sm-8">-->
            <!--<input type="text" class="form-control input-field"-->
                   <!--data-ng-model="accessInv.tempAccessionObj.toAccount2Input">-->
          <!--</div>-->
        </div>
      </div>
    </div>

<!-- employment data -->
    <div>
      <div class="row">
        <div class="col-sm-6">
          <label class="bold-font m-t-20 f-18" translate="accessInvestment.personalStatus"></label>
          <select class="form-control" ng-model="accessInv.tempAccessionObj.personalStatus" ng-options="eData.key | translate for eData in accessInv.personalStatusData">
          </select>
        </div>

        <div class="col-sm-6">
          <label class="bold-font m-t-20 f-18" translate="accessInvestment.employer"></label>
          <select class="form-control" ng-model="accessInv.tempAccessionObj.employerType" ng-options="eData.key | translate for eData in accessInv.employerTypeData">
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <label class="bold-font m-t-20 f-18" translate="accessInvestment.vocation"></label>
          <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.professionInput">
        </div>
        <div class="col-sm-6">
          <label class="bold-font m-t-20 f-18" translate="accessInvestment.profession"></label>
          <input type="text" class="form-control input-field" data-ng-model="accessInv.tempAccessionObj.occupationInput">
        </div>
      </div>
    </div>
<!-- end employment data -->

    <!-- Investment data -->
    <div class="row">
      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.plannedYearInvestment"></label>
        <select class="form-control" ng-model="accessInv.tempAccessionObj.plannedInvestments" ng-options="plannedInv.key | translate for plannedInv in accessInv.plannedInvestmentsType">
        </select>
      </div>

      <div class="col-sm-6">
        <label class="bold-font m-t-20 f-18" translate="accessInvestment.fundsInvestment"></label>
        <select class="form-control" ng-model="accessInv.tempAccessionObj.investFrom" ng-options="invFrom.key | translate for invFrom in accessInv.investFromType">
        </select>
      </div>
    </div>

    <!-- end investment data  -->

    <div class="row">
      <div class="col-sm-12 msg-container">
        <div translate="accessInvestment.msg1" class="m-b-10"></div>
        <div translate="accessInvestment.msg2"></div>
      </div>
    </div>

    <div class="row m-b-20">
      <div class="col-sm-12">
        <hr/>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6 t-a-l">
        <button class="btn btn-primary" type="button" translate="payments.cancel" data-ui-sref="dashboard">
        </button>
      </div>
      <div class="col-xs-6 t-a-r">
        <button class="btn btn-default domp-review-and-submit-btn" type="submit"
                data-ng-click="accessInv.submit();"
                translate="invest.add">
        </button>
      </div>
    </div>

  </div>
</section>

