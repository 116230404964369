(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name orders.factory:OrdersService
     *
     * @description
     *
     */
    angular
        .module('orders')
        .factory('PermanentOrderTypesService', PermanentOrderTypesService);

    function PermanentOrderTypesService(EndpointsService, $q, $http) {
        var PermanentOrderTypesService = {};

        PermanentOrderTypesService.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.permanentOrderTypes, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        return PermanentOrderTypesService;
    }
}());

