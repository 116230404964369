(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name payments.directive:paymentFinalStep
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="payments">
   <file name="index.html">
   <payment-final-step></payment-final-step>
   </file>
   </example>
   *
   */
  angular
    .module('payments')
    .directive('paymentFinalStep', paymentFinalStep);

  function paymentFinalStep($state, $log, PaymentTemplatesService, HelperService, NotificationService) {
    return {
      restrict: 'EA',
      scope: {
        paymentObject: '=payment',
        closeModal: '&close',
        isModal: '=isModal',
        templateName: '=',
        templateId: '='
      },
      templateUrl: 'payments/payment-final-step-directive.tpl.html',
      replace: false,
      controllerAs: 'paymentFinalStep',
      controller: function ($scope) {
        var vm = this;
        vm.formSubmitted = false;
        vm.tempVal = $scope.templateName || '';

        if ($scope.templateName) {
          vm.templateNameInput = $scope.templateName;
        }
        vm.saveAsTemplate = function () {
          vm.formSubmitted = true;
          if (!vm.templateForm.$valid) {
            return;
          }

          if ($scope.paymentObject) {
            var objectForInsert = prepareObjectForInsert();
            if (vm.tempVal === vm.templateNameInput) {
              PaymentTemplatesService.updateTemplate($scope.templateId, objectForInsert)
                .then(function (response) {
                  $log.debug("Update payment template response", response);
                  swal("Success!", "Template updated successfully!");
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            } else {
              PaymentTemplatesService.insertTemplate(objectForInsert)
                .then(function (response) {
                  $log.debug("Insert payment template response", response);
                  swal("Success!", "Template saved successfully!");
                  vm.navigateToPaymentsPage();
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            }
          }
        };

        vm.navigateToPaymentsPage = function () {
          if ($state.current.name === "payments") {
            $state.reload();
          } else {
            $state.go('payments.paymentsOverview');
          }
          if ($scope.isModal) {
            $scope.closeModal();
          }
        };

        vm.navigateToAllPaymentsPage = function () {
          $state.go('payments.paymentsOverview');
          if ($scope.isModal) {
            $scope.closeModal();
          }
        };

        var prepareObjectForInsert = function () {
          var objForInsert = {
            accountId: $scope.paymentObject.accountId,
            name: vm.templateNameInput,
            fromAccountNumber: $scope.paymentObject.fromAccountNumber,
            toAccountNumber: $scope.paymentObject.toAccountNumber,
            amount: $scope.paymentObject.amount,
            currencySymbol: $scope.paymentObject.currencySymbol,
            purpose: $scope.paymentObject.purpose,
            paymentTypeGroup: $scope.paymentObject.paymentTypeGroup,
            purposeCodeDPS: $scope.paymentObject.purposeCodeDPS
          };
          var subProperties = {
            receiverName: $scope.paymentObject.receiverName,
            receiverAddress1: $scope.paymentObject.receiverAddress1,
            receiverCity: $scope.paymentObject.receiverCity
          };

          subProperties.referenceTo =  $scope.paymentObject.referenceTo || '99';
          subProperties.referenceFrom = $scope.paymentObject.referenceFrom || '99';

          if ($scope.paymentObject.senderInfo) {
            subProperties.senderName = $scope.paymentObject.senderInfo.senderName;
            subProperties.senderAddress1 = $scope.paymentObject.senderInfo.senderAddress1;
            subProperties.senderCity = $scope.paymentObject.senderInfo.senderCity;
          }
          switch($scope.paymentObject.paymentTypeGroup){
            case "DomesticPayment":
              objForInsert.domesticPayment = subProperties;
                  break;
            case "ForeignPayment":
              objForInsert.foreignPayment = subProperties;
              objForInsert.foreignPayment.beneficiaryBankName = $scope.paymentObject.beneficiaryBankName;
              objForInsert.foreignPayment.beneficiaryBankAddress = $scope.paymentObject.beneficiaryBankAddress;
              objForInsert.foreignPayment.beneficiaryBankCity = $scope.paymentObject.beneficiaryBankCity;
              objForInsert.foreignPayment.beneficiarySWIFTCode = $scope.paymentObject.beneficiarySWIFTCode;
              objForInsert.foreignPayment.beneficiaryBankCountryCode = $scope.paymentObject.beneficiaryBankCountryCode;
              objForInsert.foreignPayment.receiverCountryCode = $scope.paymentObject.receiverCountryCode;
              objForInsert.foreignPayment.paymentCharges = $scope.paymentObject.paymentCharges;
              objForInsert.foreignPayment.payeeLegalForm = $scope.paymentObject.payeeListId;
           //   objForInsert.foreignPayment.paymentType = $scope.paymentObject.formType;
                  break;
          };
          return objForInsert;
        };
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
