<!-- Saving account details -->
<div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <!-- =============== -->
  <!-- SLOVENIA -->
  <!-- =============== -->
  <div ng-switch-when="sl">
    <div class="m-b-10">
      <span ng-if="accounts.selectedAccount.hasOwnProperty('availableBalance')">
        <span class="account-header-value white-text-block">
          {{accounts.selectedAccount.balance | currency:""}} {{accounts.selectedAccount.currencySymbol}}
        </span>
        <hr class="account-header-line" />
        <span class="m-t-neg-15 white-text-block" translate="accounts.savedFunds"></span>
      </span>
      <!-- Details -->
      <div ng-show="accounts.isAccountDetailsOpen">
        <div class="row p-t-20">
          <div class="col-xs-6 col-md-4 p-b-20">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.availableBalance|currency:""}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.accCurrentBalance_SLO"></span>
          </div>
        </div>
        <div class="row p-t-20">
          <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
              '+0200'}}</span>
            <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
          </div>
          <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositStartDate">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.depositStartDate |
              date:'shortDate' : '+0200'}}</span>
            <span class="white-text-block" translate="accounts.openingDate"></span>
          </div>
          <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositDueDate">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.depositDueDate |
              date:'shortDate' : '+0200'}}</span>
            <span class="white-text-block" translate="accounts.accDepositEndDate_SLO"></span>
          </div>
        </div>
        <div class="row p-t-20">
          <div class="col-xs-6 col-md-4 p-b-20"
            ng-if="accounts.selectedAccount.depositAccount.contractAmount && !accounts.selectedAccount.depositAccount.isShortTermSaving">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.contractAmount | currency:""}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.minSavingBalance"></span>
          </div>

          <div class="col-xs-6 col-md-4 p-b-20"
            ng-if="accounts.selectedAccount.depositAccount.minimunBalance && accounts.selectedAccount.depositAccount.isShortTermSaving">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.minimunBalance | currency:""}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.minSavingBalance2"></span>
          </div>

          <div class="col-xs-6 col-md-4 p-b-20" ng-if="!accounts.selectedAccount.depositAccount.isShortTermSaving">
            <span class="f-20 white-text-block"
              ng-if="accounts.selectedAccount.depositAccount.depositInterestAmount">{{accounts.selectedAccount.depositAccount.depositInterestAmount
              | currency:""}} {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="f-20 white-text-block"
              ng-if="!accounts.selectedAccount.depositAccount.depositInterestAmount">{{0|number:2}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.interestToDate"></span>
          </div>
          <!--<div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositEndDate">-->
          <!--<span class="f-20 white-text-block">-->
          <!--{{accounts.selectedAccount.depositAccount.depositEndDate | date:'shortDate' : '+0200'}}</span>-->
          <!--<span class="white-text-block" translate="accounts.accDepositEndDate_SLO"></span>-->
          <!--</div>-->
          <div class="col-xs-12 p-b-20"
            ng-if="accounts.selectedAccount.depositAccount.destinationAccount && !accounts.selectedAccount.depositAccount.isShortTermSaving">
            <span class="f-20 white-text-block cursor-pointer">
              {{accounts.selectedAccount.depositAccount.destinationAccount | formatIban}}
            </span>
            <span class="white-text-block" translate="accounts.destinationAccount_SLO"></span>
          </div>
          <!--<div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.sendingType">-->
          <!--<span-->
          <!--class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.sendingType }}</span>-->
          <!--<span class="white-text-block" translate="accounts.statementDeliveryMethod"></span>-->
          <!--</div>-->

          <div ng-if="accounts.selectedAccount.depositAccount.isShortTermSaving == false"
            class="col-xs-6 col-md-4 p-b-20">
            <span class="f-20 white-text-block">
              <span ng-if="accounts.selectedAccount.depositAccount.demarcationOfInterests" translate="alert.yes"></span>
              <span ng-if="!accounts.selectedAccount.depositAccount.demarcationOfInterests" translate="alert.no"></span>
            </span>
            <span class="white-text-block" translate="accounts.annualAssignmentOfInterests"></span>
          </div>
        </div>
        <div class="row p-t-20">
          <div class="col-xs-12 col-md-12 p-b-20" ng-if="accounts.selectedAccount.depositAccount.interestDescription">
            <span class="f-20 white-text-block">{{accounts.selectedAccount.depositAccount.interestDescription }}
              %</span>
            <span class="white-text-block" translate="accounts.interestRate"></span>
          </div>
        </div>
      </div>

    </div>
  </div>


  <!-- =============== -->
  <!-- Other countries -->
  <!-- =============== -->
  <div ng-switch-default>
    <div class="m-b-10">
      <span class="account-header-value white-text"
        ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>

      <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 ) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
        class="account-header-value white-text"
        ng-bind="'\ ' + (accounts.selectedAccount.balance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
      <hr class="account-header-line" />
      <span class="m-t-neg-15 white-text-block" translate="accounts.accCurrentBalance"></span>

      <div class="row account-header-bottom">
        <div class="col-xs-12 col-md-4">
          <span class="f-18 white-text-block"
            ng-bind="(accounts.selectedAccount.availableBalance |number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>
          <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 ) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
            class="f-16 white-text-block"
            ng-bind="'\ ' + (accounts.selectedAccount.availableBalance2 |number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
          <span class="white-text-block" translate="accounts.accCurrentBalance"></span>
        </div>
      </div>

      <div class="row p-t-20" ng-show="accounts.isAccountDetailsOpen">
        <!-- <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositStartDate">
          <span class="f-20 white-text-block">
            {{accounts.selectedAccount.depositAccount.depositStartDate | date:'shortDate' : '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accStartDate"></span>
        </div>

        <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.depositAccount.depositEndDate">
          <span class="f-20 white-text-block">
            {{accounts.selectedAccount.depositAccount.depositEndDate | date:'shortDate' : '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accMaturityDate"></span>
        </div>-->

        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
          <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
            '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
        </div>
        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
          <span class="f-20 white-text-block">{{'accounts.'+accounts.selectedAccount.status | translate}}</span>
          <span class="white-text-block" translate="ebook.status"></span>
        </div>
        <div class="col-xs-12 col-md-4 p-b-20"
          ng-if="accounts.selectedAccount.parentAccount.iban && accounts.countryCode != 'bh'">
          <span class="f-20 white-text-block">{{accounts.selectedAccount.parentAccount.iban}}</span>
          <span class="white-text-block" translate="accounts.accParentAccountIban"></span>
        </div>
      </div>
    </div>
  </div>
</div>