(function () {
    'use strict';
    angular.module('core').directive('customOnChange', function() {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
              var onChangeFunc = scope.$eval(attrs.customOnChange);
              element.bind('change', onChangeFunc);
            }
          };
      });

  }).call(this);
