(function () {
  'use strict';
  angular
    .module('core')
    .directive('doubleSubmitBlocker', doubleSubmitBlocker);
  function doubleSubmitBlocker($log) {
    return {
      require: '^form',
      priority: -1,
      restrict: 'A',
      link: function (scope, element, attrs, form) {
        $log.debug('Initiating double submit blocker');
        // adds disabled class while form is in submitting state
        scope.$watch(
          function () {
            return element.data('submitting');
          },
          function (submitting) {
            if (submitting) {
              element.addClass('disabled');
              $log.debug('Added class disabled');
            } else {
              if (!attrs.ngDisabled || !scope.$eval(attrs.ngDisabled)) {
                element.removeClass('disabled');
                $log.debug('Removed class disabled');
              }
            }
          }
        );
        // blocks additional clicks and sets submitting data when element is clicked
        element.bind('click', function (e) {
          scope.$apply(function () {
            if (element.data('submitting')) {
              e.stopImmediatePropagation();
              e.preventDefault();
              $log.debug('Submit blocked');
              return false;
            }
            element.data('submitting', true);
            $log.debug('Submit passed');
            return true;
          });
        });
        // enables form resubmit in case of errors
        scope.$watch(
          function () {
            return form.$valid;
          },
          function (value) {
            if (value) {
              $log.debug('Form errors cleared submit enabled');
              element.data('submitting', false);
            }
          }
        );
        //specific case for internal transfer same account validation
        scope.$watch(function (){
          return (element.attr('is-same-account')==='true');
        }, function(value){
          if(value){
            element.data('submitting', false);
          }
        });

      }
    };
  }
})();
