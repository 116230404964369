(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('navigation', {
        url: '/navigation',
        templateUrl: 'navigation/navigation.tpl.html',
        controller: 'NavigationCtrl',
        controllerAs: 'navigation'
      });
  }
}());
