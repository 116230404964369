<div id="main">
  <div class="container-fluid p-t-15">
    <h2 class="m-l-0 f-28 m-t-0 m-b-0 title-style" translate="{{vm.isPhoneVerification ? 'mobilePhoneSettings.title': 'emailVerification.title'}}"></h2>

    <div ng-show="vm.steps.input" class="alert alert-info m-t-10"
         style="background: white; color: black; border: 1px solid lightgray">
      <p translate="{{vm.isPhoneVerification ? 'mobilePhoneSettings.headerInfoText' : 'emailVerification.headerInfoText'}}"></p>
    </div>
    <!-- ================= -->
    <!-- START: Step Input-->
    <!-- ================= -->
    <ng-form ng-show="vm.steps.input" name="vm.contactDataForm">
      <div class="domp-from-section p-15 m-t-25">
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label">{{(vm.isPhoneVerification ? "mobilePhoneSettings.mobilePhoneLabel" : 'emailVerification.emailLabel') | translate }}</label>
          </div>
          <div ng-if="vm.isPhoneVerification" class="col-md-7">
            <input type="text" class="form-control" ng-model="vm.model.gsm"
                   name="gsm"
                   ui-mask="999 999 999"
                   ui-mask-placeholder
                   ui-mask-placeholder-char="_"
                   required/>
            <div class="c-red"
                 ng-show="vm.contactDataForm.gsm.$error.required && vm.formSubmitted"
                 translate="mobilePhoneSettings.gsmPhoneError">
            </div>
          </div>
          <div ng-if="!vm.isPhoneVerification" class="col-md-7">
            <input type="email" class="form-control" name="mail"
            ng-model="vm.model.email" required />
            <div class="c-red" ng-show="vm.contactDataForm.mail.$error.required"
              translate="settings.emailRequiredErroMsg"></div>
            <div class="c-red" ng-show="vm.contactDataForm.mail.$error.email"
              translate="settings.invalidEmailRequiredErroMsg"></div>
          </div>
        </div>

        <div class="row m-t-10">
          <div class="col-xs-12">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
              <input name="isConfirmed" type="checkbox" ng-model="vm.model.isConfirmed" required>
              <i class="input-helper"></i>
              <span translate="{{vm.isPhoneVerification ? 'mobilePhoneSettings.confirmMobilePhone' : 'emailVerification.confirmEmail'}}"></span>
            </label>
            <span class="c-red"
                  ng-show="vm.contactDataForm.isConfirmed.$error.required && vm.formSubmitted"
                  translate="mobilePhoneSettings.requiredField">
          </span>
          </div>
        </div>
      
      </div>
      <div class="row  m-t-25">
        <div class="form-group clearfix">
          <div class="col-md-12">
            <button class="btn btn-primary pull-left" ui-sref="settings" translate="payments.cancel"></button>
            <button class="btn btn-default pull-right" translate="orders.next" ng-click="vm.next()"
                    ng-disabled="vm.validationInProgress"></button>
          </div>
        </div>
      </div>
    </ng-form>
    <!-- ================= -->
    <!-- END: Step Input-->
    <!-- ================= -->
    <!-- ================= -->
    <!-- START: Step Resume -->
    <!-- ================= -->
    <ng-form ng-show="vm.steps.resume" name="vm.phoneNumberResumeForm">
      <div class="domp-from-section p-15 m-t-25">
        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="mobilePhoneSettings.orderDate"></label>
          </div>
          <div class="col-md-5">
            <p><b>{{vm.model.orderDate | date: 'dd.MM.yyyy HH:mm:ss'}}</b></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="mobilePhoneSettings.orderType"></label>
          </div>
          <div class="col-md-5">
            <p>
              <b ng-if="vm.isPhoneVerification" translate="mobilePhoneSettings.orderTypeInfo"></b>
              <b ng-if="!vm.isPhoneVerification" translate="emailVerification.orderTypeInfo"></b>
            </p>
          </div>
        </div>

        <div ng-if="vm.isPhoneVerification" class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="mobilePhoneSettings.mobilePhoneLabel"></label>
          </div>
          <div class="col-md-5">
            <p><b>{{ vm.model.gsmPhoneFormatted }}</b></p>
          </div>
        </div>

        <div ng-if="!vm.isPhoneVerification" class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="emailVerification.emailLabel"></label>
          </div>
          <div class="col-md-5">
            <p><b>{{ vm.model.email }}</b></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="domp-form-label" translate="mobilePhoneSettings.dataConfirmation"></label>
          </div>
          <div class="col-md-5">
            <p>
              <b ng-if="vm.model.isConfirmed" translate="alert.yes"></b>
              <b ng-if="!vm.model.isConfirmed" translate="alert.no"></b>
            </p>
          </div>
        </div>

        <div class="row p-t-20">
          <div class="col-md-12">
            <p class="f-16" translate="mobilePhoneSettings.additionalPaymentConfirmationText"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input type="text" name="signData"
                     class="form-control input-field text-uppercase"
                     maxlength="8"
                     ng-model="vm.model.signData" required>
            <div class="c-red"
                 ng-show="vm.phoneNumberResumeForm.signData.$error.required && vm.resumeFormSubmitted"
                 translate="mobilePhoneSettings.SMSCodeRequired">
            </div>
          </div>
        </div>

        <div class="row  m-t-25">
          <div class="form-group clearfix">
            <div class="col-md-12">
              <button class="btn btn-primary pull-left" translate="domesticPayment.back"
                      ng-click="vm.steps.resume = false; vm.steps.input = true;"></button>
              <button class="btn btn-default pull-right" translate="termDeposit.confirmBtnLabel"
                      ng-disabled="vm.confirmationInProgress"
                      ng-click="vm.confirm()"></button>
            </div>
          </div>
        </div>
      </div>
    </ng-form>
    <!-- ================= -->
    <!-- END: Step Resume -->
    <!-- ================= -->
  </div>
</div>
