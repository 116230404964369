(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name materialAdmin.controller:FooterCtrl
   *
   * @description
   *
   */
  angular
    .module('materialAdmin')
    .controller('FooterCtrl', FooterCtrl);

    function FooterCtrl($rootScope, SessionService, $localStorage, ConfigEndpointService, HelperService, FooterService, version, ConfigService, GoogleTagManagerHelperService) {
    var vm = this;
    //vm.getConfigData = function () {
    //  ConfigEndpointService.getDefaultData()
    //    .then(function (response) {
    //      vm.configData = response;
    //      vm.countryCode = response.APILocation;
    //      vm.clubLink = response.AddikoClub;
    //      vm.eTradeLink = response.eTrade;
    //    })
    //    .catch(function (error) {
    //      NotificationService.showMessage(error, 'error');
    //    });
    //};

    vm.init = function () {
      //vm.getConfigData();
      vm.configData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
      vm.countryCode = vm.configData.APILocation;
      vm.clubLink = vm.configData.AddikoClub;
      vm.eTradeLink = vm.configData.eTrade;
      vm.appVersion = version;
      vm.serverVersion = ConfigService.serverId;
      vm.safeiFrameUrL = ConfigService.iFrameUrl;
      vm.loaded = true;
    };

    vm.openModal = function () {
      FooterService.openModal();
    };

    vm.pushGtmEmailEvent = function () {
        GoogleTagManagerHelperService.pushEvent('Dashboard', 'MailTo', 'Open');
    };

    vm.init();

  }
}());
