<div ng-if="settingsAccount.step===1">
  <div class="modal-header smsModalHeader">
    <div class="row">
      <div class="col-xs-12">
        <span translate="settings.phoneAndEmailNotificationModal.notificationHeader"></span>
      </div>
    </div>

  </div>
  <div class="p-t-20">
    <div class="modal-body">
        <div class="settings-content m-b-30">
          <div class="row">
            <label translate="mobilePhoneSettings.notificationSettingUpMobile1"></label>
            <label translate="mobilePhoneSettings.notificationSettingUpMobile2"></label>
          </div>
    <!--      text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text
          text text text text text text text text text text text text text text text text text text text text text-->
          <br>
          <br>
          <hr>
          <div class="row">
              <div class="form-group clearfix">
                <div class="col-md-4">
                  <label class="sett-conc-info" translate="settings.gsmNewSL">GSM</label>
                </div>

                <form name="settingsAccount.gsmForm" class="col-md-8">
                  <input ng-model="settingsAccount.model.userSettings.gsm"
                  ng-class="{'gsm-error-div': (settingsAccount.formSubmitedGsm && settingsAccount.gsmForm.gsm.$error.required)}"
                         title= "{{'mobilePhoneSettings.requiredField' | translate }}"       
                         type="text"
                         required
                         name="gsm"
                         class="form-control"
                         ui-mask="999 999 999"
                         ui-mask-placeholder
                         ui-mask-placeholder-char="_">
                </form>
              </div>
            </div>

            <div class="row m-t-15">
              <div class="form-group">
                <div class="col-md-4">
                  <label translate="settings.phoneAndEmailNotificationModal.email" class="sett-conc-info">Email</label>
                  <p class="settings-details">{{settingsAccount.model.userSettings.notificationEmail}}</p>
                </div>
                <form name="settingsAccount.emailForm" class="col-md-8">
                  <input ng-model="settingsAccount.model.userSettings.email"
                         name="mail"
                         type="email"
                         class="form-control">
                  <div class="c-red" ng-show="settingsAccount.emailForm.mail.$error.required"
                       translate="settings.emailRequiredErroMsg"></div>
                  <div class="c-red" ng-show="settingsAccount.emailForm.mail.$error.email"
                       translate="settings.invalidEmailRequiredErroMsg"></div>
                </form>
              </div>
            </div>
          <div class="row">
            <label translate="mobilePhoneSettings.notificationSettingUpMobile3"></label><a href="ebank.si@addiko.com" target="_blank" class="underline"> ebank.si@addiko.com.</a>
          </div>

        </div>


      </div>
      <div class="modal-footer">
        <div class="col-xs-12">

          <button
            ng-if="settingsAccount.contactDataAcquisition!==2"
            class="btn btn-primary pull-left"
            translate="settings.phoneAndEmailNotificationModal.cancelModal"
            ng-click="$close()">
          </button>

          <button
            class="btn btn-default pull-right"
            ng-click="settingsAccount.confirmUserEmailChange();"
            translate="settings.phoneAndEmailNotificationModal.confirmModal">
          </button>

        </div>
      </div>
  </div>
</div>

<!--

STEP 2

-->
<div ng-if="settingsAccount.step===2">
  <div class="modal-header smsModalHeader">
    <div class="row">
      <div class="col-xs-12">
        <span translate="settings.phoneAndEmailNotificationModal.notificationHeader"></span>
      </div>
    </div>

  </div>
  <div class="p-t-20">
    <div class="modal-body">
      <div class="settings-content m-b-30">
        <ng-form name="settingsAccount.phoneNumberResumeForm">
          <div class="domp-from-section">
            <div class="row">
              <div class="col-md-4">
                <label class="domp-form-label" translate="mobilePhoneSettings.orderDate"></label>
              </div>
              <div class="col-md-5">
                <p><b>{{settingsAccount.model.orderDate | date: 'dd.MM.yyyy HH:mm:ss'}}</b></p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="domp-form-label" translate="mobilePhoneSettings.orderType"></label>
              </div>
              <div class="col-md-5">
                <p><b translate="mobilePhoneSettings.orderTypeInfo"></b></p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="domp-form-label" translate="mobilePhoneSettings.mobilePhoneLabel"></label>
              </div>
              <div class="col-md-5">
                <p><b>{{ settingsAccount.model.userSettings.gsmPhoneFormatted }}</b></p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="domp-form-label" translate="mobilePhoneSettings.dataConfirmation"></label>
              </div>
              <div class="col-md-5">
                <p>
                  <b translate="alert.yes"></b><!--
                  <b ng-if="!settingsAccount.model.isConfirmed" translate="alert.no"></b>-->
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="domp-form-label" translate="mobilePhoneSettings.additionalPaymentConfirmationTitle"></label>
                <p translate="mobilePhoneSettings.additionalPaymentConfirmationText"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="m-b-5" translate="mobilePhoneSettings.SMSCode"></p>
              </div>
              <div class="col-md-4">
                <div class="input-group">
                  <input type="text" name="signData"
                         class="form-control input-field text-uppercase"
                         maxlength="8"
                         ng-model="settingsAccount.model.userSettings.signData" required>

                  <span class="input-group-btn">
              <button class="btn btn-default input-group-app-btn domp-btn-height"
                      data-ng-click="settingsAccount.sendSMS()"
                      ng-disabled="settingsAccount.sendSMSDisabled"
                      uib-tooltip="{{'signPayments.resendSms' | translate}}">
                <i class="zmdi zmdi-refresh-sync"></i>
              </button>
            </span>
                </div>

                <div class="c-red"
                     ng-show="settingsAccount.phoneNumberResumeForm.signData.$error.required && settingsAccount.resumeFormSubmitted"
                     translate="mobilePhoneSettings.SMSCodeRequired">
                </div>
              </div>
            </div>

            <div class="row  m-t-25">
              <div class="form-group clearfix">
                <div class="col-md-12">
                  <button class="btn btn-primary pull-left" translate="domesticPayment.back"
                          ng-click="settingsAccount.step = 1"></button>
                  <button class="btn btn-default pull-right" translate="termDeposit.confirmBtnLabel"
                          ng-disabled="settingsAccount.confirmationInProgress"
                          ng-click="settingsAccount.confirm()"></button>
                </div>
              </div>
            </div>
          </div>
        </ng-form>
      </div>


    </div>
    <div class="modal-footer" ng-if="settingsAccount.step===1">
      <div class="col-xs-12">

        <button class="btn btn-primary pull-left"
                ng-if="settingsAccount.contactDataAcquisition===1"
                translate="settings.phoneAndEmailNotificationModal.cancelModal"
                ng-click="$close()">
        </button>
        <!--ng-disabled="!smsAndNotificationModal.tempOrderObj.agreeTerms"-->
        <button
          class="btn btn-default pull-right"
          ng-click="settingsAccount.confirmUserEmailChange();"
          translate="settings.phoneAndEmailNotificationModal.confirmModal">
        </button>

      </div>
    </div>
  </div>


</div>

