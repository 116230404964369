(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name investments.controller:InvestmentsOverviewCtrl
     *
     * @description
     *
     */
    angular
        .module('investments')
        .controller('InvestmentsOverviewCtrl', InvestmentsOverviewCtrl);

    function InvestmentsOverviewCtrl($stateParams, $state, $log, FundsService, AlertService, InvestmentOrdersService, HelperService, $translate, GoogleTagManagerHelperService, $rootScope, NotificationService) {
        var vm = this;
        var fundId = $stateParams.fundId;
        vm.currentIndex = 0;
        var investmentOrderFilter = {
            page: 0,
            pageSize: 10,
            orderBy: "invAmountDate desc"
        };

        var investmentTransactionFilter = {
            page: 0,
            pageSize: 10,
            orderBy: "valueDate desc"
        };

        vm.selectedFund = null;
        vm.fundList = [];
        vm.investmentOrderListGrouped = [];
        vm.selectedTab = 2;

        //TODO: load transactions from API
        vm.investmentTransactionListGrouped = {};
        vm.investmentTransactionListGrouped.result = [];

        var init = function () {

          vm.configData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          vm.FundExchangeOff = vm.configData.FundExchangeOff ? vm.configData.FundExchangeOff === 'true' : false;

            if ($stateParams.fundId) {
                getSelectedFund()
                    .then(function () {
                        getAllFunds()
                            .then(function () {
                                findCurrentFundIndex();
                            })
                            .catch(function (error) {
                                NotificationService.showMessage(error, 'error');
                            });
                        getInvestmentOrders(true);
                        getInvestmentTransactions(true);
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
        };

        var getSelectedFund = function () {
            return FundsService.getFundById($stateParams.fundId)
                .then(function (response) {
                    vm.selectedFund = response;
                })
                .catch(function (error) {
                    vm.selectedFund = null;
                    NotificationService.showMessage(error, 'error');
                });
        };

        var getAllFunds = function () {
            return FundsService.getPage()
                .then(function (response) {
                    if (_.isArray(response.result) && response.result.length > 0) {
                        vm.fundList = response.result;
                    }
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }


        var findCurrentFundIndex = function () {

            var currIndex = 0;
            _.forEach(vm.fundList, function (item) {
                item.index = currIndex++;
            });

            vm.currentIndex = _.findIndex(vm.fundList, function (item) {
                return vm.selectedFund.id === item.id;
            });

            checkCarouselIndex();
        };

        var setSelectedFund = function () {
            vm.selectedFund = _.find(vm.fundList)
        };

        vm.chooseFromCarousel = function (fundId) {
            vm.currentIndex = _.findIndex(vm.fundList, function (item) {
                return item.id === fundId;
            });
            checkCarouselIndex();
            navigateToFund();
        };

        var checkCarouselIndex = function () {
            vm.isNextButtonVisible = vm.currentIndex < vm.fundList.length - 1;
            vm.isPreviousButtonVisible = vm.currentIndex > 0;
        };

        var navigateToFund = function () {
            $state.go('investments.overview', {
                fundId: vm.fundList[vm.currentIndex].id
            }, {
                notify: false
            });
            vm.selectedFund = vm.fundList[vm.currentIndex];
            getInvestmentOrders(true);
            getInvestmentTransactions(true);
        };

        vm.goToNextItem = function () {
            GoogleTagManagerHelperService.pushEvent('Investments', 'Carousel', 'NextSlide');
            vm.currentIndex++;
            checkCarouselIndex();
            if (vm.currentIndex <= vm.fundList.length - 1) {
                navigateToFund();
            }
        };

        vm.goToPreviousItem = function () {
            GoogleTagManagerHelperService.pushEvent('Investments', 'Carousel', 'PreviousSlide');
            vm.currentIndex--;
            checkCarouselIndex();
            if (vm.currentIndex >= 0) {
                navigateToFund();
            }
        };

        vm.editOrder = function (fundId, order) {
            var orderType = getOrderType(order);
            $state.go("investments.form.edit", {
                action: "edit",
                fundId: fundId,
                orderType: orderType,
                orderId: order.orderId
            });
        };


        vm.copyOrder = function (fundId, order) {
            var orderType = getOrderType(order);
            $state.go("investments.form.edit", {
                action: "copy",
                fundId: fundId,
                orderType: orderType,
                orderId: order.orderId
            });
        };

        function getOrderType(order) {
            var orderType;
            switch (order.orderType) {
            case 1:
                orderType = "new";
                break;
            case 2:
                orderType = "sell";
                break;
            case 3:
                orderType = "replace"
                break;
            }
            return orderType;
        }

        vm.signOrder = function (order) {
            $state.go("investments.sign", {
                orderId: order.orderId,
                fundId: fundId
            });
        };

        var getInvestmentOrders = function (reset) {
            if (reset) {
                investmentOrderFilter.page = 0;
            } else {
                investmentOrderFilter.page++;
            }
            investmentOrderFilter.fundId = vm.selectedFund.fundId;
            InvestmentOrdersService.getPage(investmentOrderFilter)
                .then(function (response) {
                    vm.investmentOrderListGrouped = HelperService.mergeRowsForTimeline({
                        existingData: vm.investmentOrderListGrouped,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.invAmountDate);
                            date.setHours(0, 0, 0, 0);
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                date: new Date(e),
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });

                    $log.debug("Investment order list grouped")

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        var getInvestmentTransactions = function (reset) {
            if (reset) {
                investmentTransactionFilter.page = 0;
            } else {
                investmentTransactionFilter.page++;
            }
            investmentTransactionFilter.fundId = vm.selectedFund.fundId;
            InvestmentOrdersService.getInvestmentTransactions(investmentTransactionFilter)
                .then(function (response) {
                    vm.investmentTransactionListGrouped = HelperService.mergeRowsForTimeline({
                        existingData: vm.investmentTransactionListGrouped.result,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.valueDate);
                            date.setHours(0, 0, 0, 0);
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                date: new Date(e),
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });
                    $log.debug("Investment transaction list grouped")

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.selectTab = function (tab, translateLabel) {
            if (tab == vm.selectedTab) return;
            vm.selectedTab = tab;
            GoogleTagManagerHelperService.pushEvent('InvestmentsOverview', 'Tab', $translate.instant(translateLabel));
        }

        vm.loadMoreInvestmentTransaction = function () {
            getInvestmentTransactions(false);
        };

        vm.loadMoreInvestmentOrders = function () {
            getInvestmentOrders(false);
        };

        vm.deleteInvestment = function (id) {
            AlertService.confirmationAlert()
                .then(function (result) {
                    if (result) {
                        vm.deleteButtonDisabled = true;
                        InvestmentOrdersService.deleteInvestment(id)
                            .then(function (response) {
                                vm.investmentOrderListGrouped.result = _.without(vm.investmentOrderListGrouped.result, _.find(vm.investmentOrderListGrouped.result, {
                                    orderId: id
                                }));
                                // Clean virtual
                                for (var i = 0; i < vm.investmentOrderListGrouped.result.length; i++) {
                                    if (vm.investmentOrderListGrouped.result[i].isVirtual && (!vm.investmentOrderListGrouped.result[i + 1] || vm.investmentOrderListGrouped.result[i + 1].isVirtual)) {
                                        vm.investmentOrderListGrouped.result = _.without(vm.investmentOrderListGrouped.result, vm.investmentOrderListGrouped.result[i]);
                                    }
                                }
                                vm.deleteButtonDisabled = false;
                                AlertService.deletedPaymentAlert({
                                    paymentName: id,
                                    type: 'investment'
                                });

                            }, function (error) {
                                vm.deleteButtonDisabled = false;
                            });
                    }
                });
        };
        vm.pushGTMevent = function (type) {
            GoogleTagManagerHelperService.pushEvent('InvestmentsOverview', type, type + 'button selected');
        };
        vm.toggleGtmDetailsOpenEvent = function (condition) {
            GoogleTagManagerHelperService.pushEvent('InvestmentsOverview', 'InvestmentDetails', (condition) ? 'Show additional details' : 'Hide additional details');
        };

        init();
    }
}());
