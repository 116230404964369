<div>
  <div class="popover-notifications-wrapper">
    <span ng-bind="navigation.rejectedPaymentsNotifications"></span>
    <span translate="dashboardNotification.rejected"></span>
  </div>
  <div class="popover-notifications-wrapper" ng-if="navigation.countryCode === 'hr' || navigation.countryCode === 'sl'">
    <span ng-bind="navigation.eInvoiceNotifications"></span>
    <span translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'dashboardNotification.einvoiceSlo' : 'dashboardNotification.einvoice'}}"></span>
  </div>
  <div class="popover-notifications-wrapper">
    <span ng-bind="navigation.unsignedPaymentsNotifications"></span>
    <span ng-if="navigation.countryCode != 'sl'" translate="dashboardNotification.unsigned"></span>
    <span ng-if="navigation.countryCode === 'sl'" translate="dashboardNotification.pending"></span>
  </div>
</div>
