(function () {
    'use strict';

    angular
        .module('navigation')
        .config(config);

    function config($translateProvider) {

        var data = {
            messages: {
                "createBtn": "Pošlji sporočilo",
                "receivedTab": "Prejeta",
                "receivedGlobalTab": "Prejeta globalna",
                "sentTab": "Poslana",
                "unreadLabel": "Neprebrana sporočila",
                "globalLabel": "Globalna sporočila",
                "readLabel": "Prebrana sporočila",
                "replyBtn": "Odgovori",
                "printBtn": "Natisni",
                "deleteBtn": "Izbriši",
                "loadMore":"Prikaži več",
                "noData": "Za izbrano obdobje ni sporočil",
              "paymentCode": "ID plačila:",
                "orderCode":"Številka naročila:",
                "downloadAttachment": "Prenesi priponko"
            },
            "newMessage": {
                "titleLabel": "Pošlji sporočilo",
                "titleReplyLabel": "Odgovori na sporočilo",
                "subjectLabel": "Naslov",
                "subjectPlaceholder": "Vnesite naslov sporočila",
                "bodyLabel": "Vsebina",
                "header": "Sporočila",
                "backBtn": "Nazaj",
                "sendBtn": "Pošlji",
                "successLabel1": "Sporočilo poslano",
                "successLabel2": "Sporočilo ",
                "successLabel3": " je uspešno poslano.",
                "newLabel": "Pošlji sporočilo",
                "goToMsgsLabel": "Pojdi na sporočila"
            }
        };

        $translateProvider
            .translations('sl', data);

    }
}());
