<div ng-switch="tlItemDetailsModal.timelineItem.referenceType">
  <div ng-switch-when="Transaction">
    <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
  </div>
  <div ng-switch-when="EInvoice">
    <div data-ng-include="'dashboard/tl-einvoice-details.tpl.html'"></div>
  </div>
  <div ng-switch-when="Message">
    <div data-ng-include="'dashboard/tl-message-details.tpl.html'"></div>
  </div>
    <!--<div ng-switch-when="Message">
    <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
  </div>
  <div ng-switch-when="Statement">
    <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
  </div>
  <div ng-switch-when="Account">
    <div data-ng-include="'dashboard/tl-transaction-details.tpl.html'"></div>
  </div>-->

</div>
