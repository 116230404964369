(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name investments.controller:InvestmentSignCtrl
   *
   * @description
   *
   */
  angular
      .module('investments')
      .controller('InvestmentSignCtrl', InvestmentSignCtrl);

  function InvestmentSignCtrl($state, data, InvestmentOrdersService, HelperService, NotificationService) {
    var vm = this;
    vm.model = {};
    vm.model.password2 = "";
    vm.investment = data[0];



    vm.confirmSignedInvestment = function () {
      vm.confirmSignedButtonDisabled = true;
      InvestmentOrdersService.confirmSignInvestment(vm.investment.orderId, vm.model.password2)
          .then(function (response) {
            vm.confirmSignedButtonDisabled = false;
            $state.go("investments.overview", {fundId: $state.params.fundId});
          }, function (error) {
            NotificationService.showMessage(error, "error");
            vm.confirmSignedButtonDisabled = false;
          });
    };
  }
}());
