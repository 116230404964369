<section id="main">
    <div class="container">
        <div class="row f-14">

            <div class="col-sm-12">
                <div class="app-white-card m-b-15">


                    <div ng-show="cancelDeposit.steps.step1">
                        <div class="intp-header-wrapper bottom-border">
                            <div class="row intp-header-row">
                                <div class="col-xs-12">
                                    <h2 class="intp-title" translate="cancelDeposit.cancelDepositHeader"></h2>
                                    <h4 translate="cancelDeposit.cancelDepositSubheader"></h4>
                                </div>
                            </div>
                        </div>
                        <div class="intp-form-container bottom-border">

                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.capitalAmountLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly ng-value="cancelDeposit.depositAccountCloseOverViewResponse.capital + ' ' + cancelDeposit.depositAccount.currencySymbol">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.approvedInteresLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly ng-value="cancelDeposit.depositAccountCloseOverViewResponse.approvedInterest + ' %'">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.approvedInteresAmountLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly ng-value="cancelDeposit.depositAccountCloseOverViewResponse.approvedInterestInAmountOf + ' ' + cancelDeposit.depositAccount.currencySymbol">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.chargesLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly
                                           ng-value="cancelDeposit.depositAccountCloseOverViewResponse.prematureCharges + ' '
                                           + (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') ? 'EUR' : cancelDeposit.depositAccount.currencySymbol">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.totalAmountLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly ng-value="cancelDeposit.depositAccountCloseOverViewResponse.totalTransferAmount + ' ' + cancelDeposit.depositAccount.currencySymbol">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="ntd-form-label" translate="cancelDeposit.accountForFundLabel"></label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control t-a-r" readonly ng-value="cancelDeposit.depositAccountCloseOverViewResponse.payoutAccount">

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-3 col-xs-6 t-a-c m-t-10 m-b-10">
                                <button class="btn btn-primary" translate="cancelDeposit.cancelBtn" data-ng-click="cancelDeposit.backToAccount()">Cancel</button>
                            </div>
                            <div class="col-sm-3 col-sm-offset-6 col-xs-6 t-a-c m-t-10 m-b-10">
                                <button class="btn btn-default" translate="cancelDeposit.confirmBtn" ng-click="cancelDeposit.confirmCancelDeposit();">Confirm</button>
                            </div>
                        </div>
                    </div>
                    <div class="p-20" ng-show="cancelDeposit.steps.step2">
                       <div class="row">
                           <div class="col-xs-12">
                               <h4><span translate="cancelDeposit.successInfoLabel1"></span> {{cancelDeposit.canceledDepositOrder.id}} <span translate="cancelDeposit.successInfoLabel2"></span></h4>
                           </div>
                       </div>
                       <br>
                        <div class="row">
                            <div class="col-sm-3 col-xs-6 t-a-l m-t-10 m-b-10">
                                <button class="btn btn-default" translate="cancelDeposit.requestBtn">Cancel</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
