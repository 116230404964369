<div class="w-1000 dropdown-box"
    click-outside="vm.onOutsideClick()"
    ng-class="{'input-error': parentForm[controlName].$invalid && formSubmitted}">
    <div class="col-xs-11 pr-0 input-div">
        <input type="text" ng-name="controlName"
            class="search-input"
            ng-click="vm.searchFilterExpanded = true"
            ng-model="vm.searchInput" ng-change="vm.search()"
            ng-disabled="isDisabled"
            placeholder="{{placeholderKey | translate}}" autocomplete="off">
    </div>
    <div class="col-xs-1 p-0 cursor-pointer h-100 bg-transparent">
        <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0"
         ng-disabled="isDisabled"
         ng-click="vm.toggleFilterSelection()"></i>
    </div>
    <div class="search-row" ng-class="{'opened': vm.searchFilterExpanded}">
        <div class="option-wrapper"
            ng-repeat="item in searchList"
            ng-click="vm.selectItem(item)">
            <div class="option-item cursor-pointer">
                <p class="option-name m-0">{{item[controlName] | translate}}</p>
            </div>
        </div>
    </div>
</div>