<section id="main">
  <div class="container-fluid" ng-show="vm.step == 'register'">
    <!-- START Title -->
    <div class="row">
      <div class="domp-header-wrapper col-sm-12 p-o">
        <div class="domp-header-row">
          <h2 class="intp-title" translate="eInvoicesSubscribe.newRegistration"></h2>
        </div>
      </div>
    </div>
    <!-- END Title-->
    <form name="vm.einvoiceSubscribeForm" novalidate class="p-15 bg-white">
      <div class="row">
        <div class="col-sm-12">
          <div class="container gray-card2">
            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="row">
              <!-- START CUSTOM ACCOUNTS DROPDOWN -->
              <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <label class="domp-form-label" translate="payments.fromAccount"></label>
                <div class="accounts-dropdown">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="vm.toggleFromAccount();" ng-style="vm.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + vm.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{vm.tempDompObj.fromAccountSelect.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':vm.tempDompObj.fromAccountSelect.isFavorite, 'zmdi-star-outline':!vm.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{vm.tempDompObj.fromAccountSelect.iban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{vm.tempDompObj.fromAccountSelect.availableBalance| number:2}} {{vm.tempDompObj.fromAccountSelect.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                  </div>
                  <div class="from-accounts-list " ng-show="vm.fromAccountDropdown" scrollable>
                    <div class="accounts-list-item p-0" data-ng-repeat="item in vm.accounts" data-ng-click="vm.setAccountFrom(item);">

                      <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{item.availableBalance| number:2}} {{item.currencySymbol}}
                        </p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END CUSTOM ACCOUNTS DROPDOWN -->

              <div  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12 col-sm-6">
                <div  class="row p-b-20">
                  <div class="col-xs-6">
                    <label class="domp-form-label" for="requestType" translate="eInvoicesModal.requestType"></label>
                    <select name="requestType"
                            id="requestType"
                            class="form-control input-field p-l-15 p-r-10"
                            ng-options="option.value | translate for option in vm.requestTypes"
                            ng-model="vm.requestType"
                            required>
                    </select>
                  </div>
                  <div class="col-xs-6">
                    <label class="domp-form-label" for="gsm" translate="eInvoicesModal.gsm"></label>
                    <input type="text"
                           class="form-control input-field"
                           ng-model="vm.gsm"
                           name="gsm"
                           id="gsm"
                           data-ng-class="{'domp-error': vm.einvoiceSubscribeForm.gsm.$invalid && vm.formSubmitted}" />
                  </div>
                  </div>
                  <div class="row">
                  <div class="col-xs-12">
                    <label class="domp-form-label" for="email" translate="eInvoicesSubscribe.yourContactEmail"></label>
                    <input type="text"
                           class="form-control input-field"
                           ng-model="vm.email"
                           name="email"
                           id="email"
                           data-ng-class="{'domp-error': vm.einvoiceSubscribeForm.email.$invalid && vm.formSubmitted}" />
                    <span class="c-red"
                          ng-show="vm.einvoiceSubscribeForm.email.$error.required && vm.formSubmitted"
                          translate="eInvoicesSubscribe.einvoiceSubscribeEmailInputError"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-sm-6">

                <div class="form-group clearfix">
                  <div>
                    <label ng-if="vm.countryCode != 'sl'" class="domp-form-label" translate="eInvoicesSubscribe.einvoiceIssuer"></label>
                    <label ng-if="vm.countryCode == 'sl'" class="domp-form-label"  translate="eInvoicesSubscribe.einvoiceIssuerSlo"></label>
                  </div>
                  <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
                       class="col-lg-3 col-sm-3 col-md-3 col-xs-3 p-l-0 p-r-5">
                    <input type="text"
                           ng-model="vm.taxIdSearch"
                           ng-model-options="{ allowInvalid: true, debounce: 500 }"
                           ng-change="vm.searchIssuers('id');"
                           class="form-control input-field"
                           ng-readonly="vm.issuer"
                           required/>
                  </div>

                  <div ng-class="{'col-lg-9 col-sm-9 col-md-9 col-xs-9': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl',
                                  'col-lg-12 col-sm-12 col-md-12': APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl',
                                  'domp-error-custom-payee': vm.paymentForm.issuerSearchInput.$invalid && vm.tempForpObj.formSubmitted}"
                       class="p-t-0 domp-filter-container"
                       style="height: 45px">

                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">


                      <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                             type="text" class="search-input" name="issuerSearchInput"
                             ng-model="vm.issuerSearchInput"
                             ng-change="vm.searchExpanded = true;"
                             placeholder={{eInvoicesSubscribe.selectIssuer}}
                             required
                             ng-readonly="vm.issuer"
                             autocomplete="off">
                      <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
                             type="text" class="search-input" name="issuerSearchInput"
                             ng-model="vm.issuerSearchInput"
                             ng-model-options="{ debounce: 500 }"
                             ng-change="vm.searchIssuers('name')"
                             placeholder={{eInvoicesSubscribe.selectIssuer}}
                             required
                             ng-readonly="vm.issuer"
                             autocomplete="off">
                    </div>


                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                         ng-click="vm.searchExpanded = !vm.searchExpanded; vm.removeSelectedIssuer();">
                      <i class="zmdi zmdi-close domp-close-sign"
                         ng-show="vm.issuerSearchInput"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!vm.issuerSearchInput"></i>
                    </div>

                    <!--Dropdown menu -->
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': vm.searchExpanded, 'domp-error': vm.einvoiceSubscribeForm.issuer.$invalid && vm.formSubmitted}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="issuer in vm.issuers"
                           ng-click="vm.issuerSelected(issuer)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">
                            <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">{{issuer.identificationNumber}} -</span>
                            {{issuer.name}}
                          </p>
                        </div>
                      </div>

                    </div>
                    <span class="c-red"
                          ng-show="vm.einvoiceSubscribeForm.issuer.$error.required && vm.formSubmitted"
                          translate="eInvoicesSubscribe.einvoiceIssuerInputError">
                    </span>
                  </div>

                </div>
              </div>
              <div class="col-sm-6">
                <label class="domp-form-label" for="referenceNumber" translate="eInvoicesSubscribe.referenceFromIssuer"></label>
                <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" type="text"
                       class="form-control input-field"
                       ng-model="vm.referenceNumber"
                       name="referenceNumber"
                       id="referenceNumber"
                       required
                       data-ng-class="{'domp-error': vm.einvoiceSubscribeForm.referenceNumber.$invalid && vm.formSubmitted}" />
                <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" class="c-red"
                      ng-show="vm.einvoiceSubscribeForm.referenceNumber.$error.required && vm.formSubmitted"
                      translate="eInvoicesSubscribe.einvoiceReferenceFromIssuerInputError"></span>

                <input ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" type="text"
                       class="form-control input-field"
                       ng-model="vm.referenceNumber"
                       name="referenceNumber"
                       id="referenceNumber"
                       ng-blur="vm.checkIfRequiredAndValid(vm.referenceNumber);"
                       ng-required = "vm.issuer.referenceRequired"
                       data-ng-class="{'domp-error': vm.einvoiceSubscribeForm.referenceNumber.$invalid && vm.formSubmitted || vm.einvoiceSubscribeForm.referenceNumber.$error.referenceRequired }" />
                <span class="di-block" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && vm.issuer.mask">{{vm.issuer.mask}}</span>
                <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="c-red"
                      ng-show="vm.einvoiceSubscribeForm.referenceNumber.$error.required &&
                      vm.formSubmitted ||
                      vm.einvoiceSubscribeForm.referenceNumber.$error.referenceRequired"
                      translate="eInvoicesSubscribe.einvoiceReferenceFromIssuerInputError"></span>
              </div>
            </div>
            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="row p-t-20">
              <div ng-if="vm.issuer.iban" class="col-xs-12 col-sm-6">
                <label class="domp-form-label"  translate="eInvoicesModal.issuerIban"></label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.iban | formatIban}}
                </label>

              </div>
              <div ng-if="vm.issuer.address || vm.issuer.city || vm.issuer.country" class="col-xs-12 col-sm-6">
                <label class="domp-form-label"  translate="eInvoicesModal.issuerAddress"></label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.address}}
                </label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.city}}
                </label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.country}}
                </label>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="row" ng-if="vm.countryCode != 'sl'">
        <div class="col-sm-12">
          <div class="container gray-card2">
            <div class="row">
              <div class="col-sm-6">
                <label class="domp-form-label" for="email" translate="eInvoicesSubscribe.yourContactEmail"></label>
                <input type="text"
                       class="form-control input-field"
                       ng-model="vm.email"
                       name="email"
                       id="email"
                       ng-disabled="!vm.editingEmail"
                       data-ng-class="{'domp-error': vm.einvoiceSubscribeForm.email.$invalid && vm.formSubmitted}" />
                <span class="c-red"
                      ng-show="vm.einvoiceSubscribeForm.email.$error.required && vm.formSubmitted"
                      translate="eInvoicesSubscribe.einvoiceSubscribeEmailInputError"></span>
              </div>

              <div class="col-sm-6">
                <label class="mock-label domp-form-label">&nbsp;</label>
                <button class="btn btn-default" name="emailChangeButton" id="emailChangeButton"
                        ng-click="vm.editEmail()"
                        ng-if="!vm.editingEmail"
                        translate="eInvoicesSubscribe.changeEmail">
                </button>

                <button class="btn btn-primary" name="emailUpdateButton" id="emailUpdateButton"
                        ng-click="vm.updateEmail()"
                        ng-if="vm.editingEmail"
                        translate="eInvoicesSubscribe.updateEmail">
                </button>

                <button class="btn btn-default" name="emailCancelButton" id="emailCancelButton"
                        ng-click="vm.cancelEmail()"
                        ng-if="vm.editingEmail"
                        translate="eInvoicesModal.btnCancel">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ng-if="vm.countryCode == 'sl'"  class="row p-20">
        <div class="col-xs-12"><label class="domp-form-label" translate="eInvoicesSubscribe.statement"></label></div>
        <div class="col-xs-12"><label class="domp-form-label" translate="eInvoicesSubscribe.statementTitle"></label></div>
        <div class="col-xs-12">
          <span translate="eInvoicesSubscribe.statementText"></span>
          <span class="underline"><a ng-href="https://www.addiko.si/splosni-pogoji" target="_blank" translate="eInvoicesSubscribe.statementTextLink1"></a></span>
          <span translate="eInvoicesSubscribe.statementTextAnd"></span>
          <span class="underline"><a ng-href="https://www.addiko.si/splosni-pogoji" target="_blank" translate="eInvoicesSubscribe.statementTextLink2"></a></span>
          <span translate="eInvoicesSubscribe.statementTextEnd"></span>
        </div>
        <div ng-if="vm.issuer.termsOfServiceLink || vm.issuer.terms">
          <div class="col-xs-12 p-t-10">
            <label class="domp-form-label" translate="eInvoicesSubscribe.statementTitle2"></label>
          </div>
          <div ng-if="vm.issuer.terms" class="col-xs-12"><span>{{vm.issuer.terms}}</span></div>
          <div ng-if="vm.issuer.termsOfServiceLink" class="col-xs-12">
            <a class="underline" ng-href="{{vm.issuer.termsOfServiceLink}}" target="_blank" translate="eInvoicesSubscribe.statementText2"></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="container gray-card2">
            <div class="row">
              <div class="col-sm-12">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                  <input id="userApproves" type="checkbox" class="" ng-model="vm.userApproves" ng-change="vm.checkDisabled()" required>
                  <i class="input-helper"></i>
                  <a ng-if="vm.countryCode != 'sl'" translate="eInvoicesSubscribe.termsAndConditions"></a>
                  <a ng-if="vm.countryCode === 'sl'" translate="eInvoicesSubscribe.termsAndConditionsSlo"></a>
                </label>
              </div>
            </div>
            <div class="row" ng-if="vm.countryCode != 'sl' && vm.issuer.termsOfServiceLink">
              <div class="col-sm-12">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                  <input id="userAgrees" type="checkbox" class="" ng-model="vm.userAgrees" ng-change="vm.checkDisabled()" required>
                  <i class="input-helper"></i>
                  <a ng-href="{{vm.issuer.termsOfServiceLink}}" target="_blank" translate="eInvoicesSubscribe.specialConditions"></a>
                </label>
              </div>
            </div>
            <div class="row" ng-if="vm.issuer.ReferenceLink">
              <div class="col-sm-12">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                  <input id="referenceLink" type="checkbox" class="" ng-model="vm.ReferenceLink" ng-change="vm.checkDisabled()" required>
                  <i class="input-helper"></i>
                  <a ng-href="{{vm.issuer.ReferenceLink}}" target="_blank" translate="eInvoicesSubscribe.referenceExplanation"></a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row m-t-10 m-b-30">
      <div class="col-xs-6 t-a-l">
        <button class="btn btn-primary" data-ui-sref='eInvoices.einvoicesOverview'
                translate="eInvoicesSubscribe.cancel"></button>
      </div>
      <div class="col-xs-6 t-a-r" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <button class="btn btn-default" ng-click="vm.resume()" translate="sepa.refundComplaint.next"
                ng-disabled="!vm.userApproves || !vm.issuer || vm.einvoiceSubscribeForm.referenceNumber.$error.referenceRequired"></button>
      </div>
      <div class="col-xs-6 t-a-r" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <button class="btn btn-default" ng-click="vm.register()"
                translate="eInvoicesSubscribe.register"
                ng-disabled="vm.disabled"></button>
      </div>
    </div>
  </div>


  <div class="container-fluid m-t-20" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && vm.step == 'resume'">
    <div class="p-15 bg-white">
      <div class="row">
        <div class="col-sm-12">
          <div class="container gray-card2">
            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <label class="domp-form-label" translate="payments.fromAccount"></label>
                <label ng-if="vm.countryCode != 'sl'" class="domp-form-label-resume">{{vm.tempDompObj.fromAccountSelect.iban}}</label>
                <label ng-if="vm.countryCode == 'sl'" class="domp-form-label-resume">{{vm.tempDompObj.fromAccountSelect.iban | formatIban}}</label>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div  class="row p-b-20">
                  <div class="col-xs-6">
                    <label class="domp-form-label" for="requestType" translate="eInvoicesModal.requestType"></label>
                    <label class="domp-form-label-resume">{{vm.requestType.value | translate}}</label>
                  </div>
                  <div class="col-xs-6">
                    <label class="domp-form-label" for="gsm" translate="eInvoicesModal.gsm"></label>
                    <label class="domp-form-label-resume">{{vm.gsm}}</label>
                  </div>
                </div>

                <div>
                  <label class="domp-form-label" for="email" translate="eInvoicesSubscribe.yourContactEmail"></label>
                  <label class="domp-form-label-resume">{{vm.email}}</label>
                </div>
              </div>
            </div>

            <div class="row m-t-15">
              <div class="col-sm-6">
                <div class="form-group clearfix">
                    <label ng-if="vm.countryCode != 'sl'" class="domp-form-label" translate="eInvoicesSubscribe.einvoiceIssuer"></label>
                    <label ng-if="vm.countryCode == 'sl'" class="domp-form-label"  translate="eInvoicesSubscribe.einvoiceIssuerSlo"></label>
                    <label class="domp-form-label-resume">{{vm.taxIdSearch}} - {{vm.issuerSearchInput}}</label>
                </div>
              </div>

              <div class="col-sm-6">
                <label class="domp-form-label" for="referenceNumber" translate="eInvoicesSubscribe.referenceFromIssuer"></label>
                <label class="domp-form-label-resume">{{vm.referenceNumber}}</label>
            </div>
          </div>

            <div class="row p-b-20">
              <div ng-if="vm.issuer.iban" class="col-xs-12 col-sm-6">
                <label class="domp-form-label" translate="eInvoicesModal.issuerIban"></label>
                <label ng-if="vm.countryCode != 'sl'" class="domp-form-label-resume">
                  {{vm.issuer.iban }}
                </label>
                <label ng-if="vm.countryCode == 'sl'" class="domp-form-label-resume">
                  {{vm.issuer.iban | formatIban}}
                </label>
              </div>
              <div ng-if="vm.issuer.address || vm.issuer.city || vm.issuer.country" class="col-xs-12 col-sm-6">
                <label class="domp-form-label"  translate="eInvoicesModal.issuerAddress"></label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.address}}
                </label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.city}}
                </label>
                <label class="domp-form-label-resume">
                  {{vm.issuer.country}}
                </label>
              </div>
            </div>

            <div ng-if="vm.countryCode == 'sl'"  class="row">
              <div class="col-xs-12"><label class="domp-form-label" translate="eInvoicesSubscribe.statement"></label></div>
              <div class="col-xs-12"><label class="domp-form-label" translate="eInvoicesSubscribe.statementTitle"></label></div>
              <div class="col-xs-12">
                <span translate="eInvoicesSubscribe.statementText"></span>
                <span class="underline"><a ng-href="https://www.addiko.si/splosni-pogoji" target="_blank" translate="eInvoicesSubscribe.statementTextLink1"></a></span>
                <span translate="eInvoicesSubscribe.statementTextAnd"></span>
                <span class="underline"><a ng-href="https://www.addiko.si/splosni-pogoji" target="_blank" translate="eInvoicesSubscribe.statementTextLink2"></a></span>
                <span translate="eInvoicesSubscribe.statementTextEnd"></span>
              </div>

              <div ng-if="vm.issuer.termsOfServiceLink" >
                <div class="col-xs-12 p-t-10">
                  <label class="domp-form-label" translate="eInvoicesSubscribe.statementTitle2"></label>
                </div>
                <div class="col-xs-12"><span>{{vm.issuer.terms}}</span></div>
                <div class="col-xs-12">
                  <a class="underline" ng-href="{{vm.issuer.termsOfServiceLink}}" target="_blank" translate="eInvoicesSubscribe.statementText2"></a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>

    <div class="row m-t-10 m-b-30">
      <div class="col-xs-6 t-a-l">
        <button class="btn btn-primary"
                ng-click="vm.step = 'register'"
                translate="domesticPayment.back"></button>
      </div>
      <div class="col-xs-6 t-a-r">
        <button class="btn btn-default" ng-click="vm.register()" translate="eInvoicesSubscribe.register" ng-disabled="vm.registerButtonPressed">
        </button>
      </div>
    </div>
  </div>


  <div class="container" ng-if="vm.step == 'done'">
    <div class="payment-successful-wrapper">
      <div class="row">
        <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
          <div class="currency-check-icon msg-pad">
            <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
          </div>
        </div>
        <div class="col-xs-10 m-t-30 m-b-30">
          <h2 class="c-white f-28" translate="eInvoicesSubscribe.applicationComplete"></h2>
          <div class="f-16">
            <span ng-if="vm.countryCode != 'sl'" translate="eInvoicesSubscribe.applicationCompleteText" translate-values="vm.issuer"></span>
            <span ng-if="vm.countryCode === 'sl'" translate="eInvoicesSubscribe.applicationCompleteTextSlo" translate-values="vm.issuer"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-sm-4">
        <a href="" data-ng-click="vm.registerNew()">
          <span class="money-exchange-icon"><i class="icon icon-einvoice zmdi-hc-2x"></i></span>
          <span class="f-18 money-exchange-text"  translate="eInvoicesSubscribe.registrationNew"></span>
        </a>
      </div>

      <div class="col-sm-4">
        <a href="" ng-click="vm.goToEInvoices();">
          <span class="money-exchange-icon"><i class="icon icon-einvoiceadd zmdi-hc-2x"></i></span>
          <span ng-if="vm.countryCode !== 'sl'" class="f-18 money-exchange-text" translate="eInvoicesSubscribe.goToList"> </span>
          <span ng-if="vm.countryCode === 'sl'" class="f-18 money-exchange-text" translate="eInvoicesSubscribe.goToListSlo"> </span>
        </a>
      </div>
    </div>
  </div>
</section>
