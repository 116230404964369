<section id="main">
  <div class="container">
    <div class="domp-header-wrapper p-r-20 p-l-10">
      <div class="row domp-header-row">
        <div class="domp-header-left col-sm-12 col-md-12 col-lg-12 col-xs-12">
          <h2 class="f-28" translate="closeEBookPageTranslation.title" style="margin-left: -22px;">Request for closing</h2>
        </div>
      </div>
    </div>
    <div class="receiver-section" style="padding: 15px" ng-if="ebookAccount.eSavingBookCloseList.length === 0">
      <div class="row">
        <div class="col-md-12">
          <p style="color:#062A42;font-weight:300;font-size:21px;margin-left: -12px;">E Book Savings list is empty!</p>
        </div>
      </div>
    </div>
    <div class="e-receiver-section" ng-if="ebookAccount.eSavingBookCloseList.length >0">
      <div class="row e-table-header p-t-5 p-b-5">
        <div class="col-md-3 e-form-label-review t-a-c" translate="closeEBookPageTranslation.accountName"></div>
        <div class="col-md-1"></div>
        <div class="col-md-3 e-form-label-review t-a-c" translate="closeEBookPageTranslation.account"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2 e-form-label-review t-a-c" translate="closeEBookPageTranslation.transferAccount"></div>
      </div>
      <div class="row e-table-field">
        <div class="col-md-3 domp-form-label-resume" ng-if="ebookAccount.ebookName"><p class="t-a-c m-b-10">{{ebookAccount.ebookName}}</p></div>
        <div class="col-md-1"></div>
        <div class="col-md-3 domp-form-label-resume" ng-if="ebookAccount.ebookNumber"><p class="t-a-c m-b-10">{{ebookAccount.ebookNumber}}</p></div>
        <div class="col-md-2"></div>
        <div class="col-md-2 domp-form-label-resume" ng-if="ebookAccount.transferToAccount"><p class="t-a-c m-b-10">{{ebookAccount.transferToAccount}}</p></div>
      </div>
      <div class="row e-table-header">
        <div class="col-md-2 e-form-label-review t-a-r" translate="closeEBookPageTranslation.balance"></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 e-form-label-review t-a-r" translate="closeEBookPageTranslation.transferAmount"></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 e-form-label-review t-a-c" translate="closeEBookPageTranslation.recognisedInterestRate"></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 e-form-label-review t-a-c p-r-0" translate="closeEBookPageTranslation.recognisedInterestAmount"></div>
        <div class="col-md-1"></div>
      </div>

      <div class="row e-table-field" ng-repeat="eSavingBook in ebookAccount.eSavingBookCloseList">
        <div class="col-md-2 domp-form-label-resume"><p class="pull-right m-b-10">{{eSavingBook.balance | number:2}} {{eSavingBook.currencySymbol}}</p></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 domp-form-label-resume"><p class="pull-right m-b-10">{{eSavingBook.transferAmount | number:2}} {{eSavingBook.currencySymbol}}</p></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 domp-form-label-resume"><p class="t-a-c m-b-10">{{eSavingBook.recognisedInterestRate}}</p></div>
        <div class="col-md-1"></div>
        <div class="col-md-2 domp-form-label-resume"><p class="pull-right m-b-10">{{eSavingBook.recognisedInterestAmount | number:2}} {{eSavingBook.currencySymbol}}</p></div>
        <div class="col-md-1"></div>
        <div class="col-md-12"><div class="col-md-12 e-table-border"></div></div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12" >
        <button type="input" class="btn btn-primary app-btn-blue m-t-5" ng-click="ebookAccount.cancel()" style="width: 160px;">
          <span translate="closeEBookPageTranslation.cancelButton"></span>
        </button>
        <button type="input" class="btn btn-default app-btn-blue m-t-5 pull-right" ng-click="ebookAccount.confirm()" style="width: 160px;">
          <span translate="closeEBookPageTranslation.confirmButton"></span>
        </button>
      </div>
    </div>
  </div>
</section>
