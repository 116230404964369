(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "Putem Viber aplikacije možete aktivirati Addiko Viber Chat Banking uslugu",
        activationWay: "Izaberite način aktivacije",
        scanQrCode: "Skeniranje QR koda",
        activationCode: "Aktivacioni kod",
        agree: "Prihvatam Opšte uslove poslovanja za servise Addiko elektronskog bankarstva - fizička lica i poljoprivrednici",
        activate: "Aktiviraj",
        deactivateText: "Vaš nalog je trenutno aktivan",
        deactivate: "Deaktiviraj",
        deactivateModalText: "Da li ste sigurni da želite deaktivirati uslugu Addiko Chat Banking?",
        cancel: "Odustani",
        activationSuccess: "Usluga Addiko Chat Banking je uspešno deaktivirana.",
        viberCondition1: "Opšti uslovi poslovanja za servise Addiko elektronskog bankarstva – fizička lica i poljoprivrednici ",
        viberCondition2: "primenjuju se i na usluge koje Klijent obavlja putem servisa Addiko Chat Banking. Viber nalog koji će klijent koristiti za pristup ovom servisu banke biće korišćen u skladu sa ",
        viberCondition3: "uslovima i politikom Viber aplikacije, ",
        viberCondition4: "za koju je klijent već dao saglasnost prilikom prijave na Viber servis.",
        scanQrCodeC1: "Skenirajte prikazani QR kod putem Viber aplikacije (opcija 'More', 'QR code scanner').",
        scanQrCodeC2: "Nakon skeniranja, automatski ćete biti preusmereni na javni Viber nalog Addiko banke, gde će vas pozdraviti robotizovana aplikacija „Addiko chat bot“ uz zahtev da potvrdite / pošaljete aktivacioni kod. Pošaljite aktivacioni kod kroz Viber chat.",
        scanQrCodeC3: "Primićete SMS poruku sa verifikacionim kodom na broj telefona koji ste prijavili Banci prilikom ugovaranja ebanking/mbanking usluge.",
        scanQrCodeC4: "Unesite verifikacioni kod iz SMS poruke u Viber chat i pošaljite ga.",
        scanQrCodeC5: "Osmislite svoj  4-cifreni PIN. „Addiko chat bot“ će vam tražiti da 2 puta unesete ovaj PIN kako bi ste završili proces aktivacije Addiko Chat Banking-a.",
        scanQrCodeC6: "NAPOMENA: Ovaj PIN ćete ubuduće koristiti za pristup servisu  Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala tako da ga OBAVEZNO zapamtite!.",
        activationCodeC1: "Pronadjite javni Viber nalog Addiko banke na Viber aplikaciji i izaberite opciju da postanete pratilac tog naloga („Follow“/“Pratilac“).",
        activationCodeC2: "Pokrenite chat opciju koja se nalazi u gornjem desnom uglu Viber aplikacije.",
        activationCodeC3: "U dnu ekrana uredjaja koji koristite za Viber (mobilni telefon, tablet, desktop računar) nalaze se komande Addiko Chat Banking servisa. Pokrenite komandu „Aktiviraj“.",
        activationCodeC4: "Unesite aktivacioni kod sa Addiko EBank stranice, u Viber chat. Pošaljite poruku sa popunjenim aktivacionim kodom.",
        activationCodeC5: "Primićete SMS poruku sa verifikacionim kodom na broj telefona koji ste prijavili Banci prilikom ugovaranja ebanking/mbanking usluge.",
        activationCodeC6: "Unesite verifikacioni kod iz SMS poruke u Viber chat i pošaljite ga.",
        activationCodeC7: "Osmislite svoj  4-cifreni PIN. „Addiko chat bot“ će vam tražiti da 2 puta unesete ovaj PIN kako bi ste završili proces aktivacije Addiko Chat Banking-a.",
        activationCodeC8: "NAPOMENA: Ovaj PIN ćete ubuduće koristiti za pristup servisu  Addiko Chat Banking kao i prilikom potvrde transakcija obavljenih putem ovog kanala tako da ga OBAVEZNO zapamtite!.",
        yourActivationCode: "Vaš kod za aktivaciju:",
        step1oth: 'Odaberite opciju „Postani pratilac“. Primićete SMS poruku sa linkom koji će Vas preusmeriti direktno na javni profil Addiko banke na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime postajete pratilac našeg javnog profila.',
        step2oth: 'Vratite se u EBank i započnite aktivaciju usluge odabirom opcije "Aktiviraj". Primićete SMS poruku sa aktivacionim linkom koji će Vas preusmeriti direktno na Addiko Chat Banking na Viberu. Kroz Viber aplikaciju pošaljite aktivacioni kod koji će automatski biti upisan u polje Viber poruke.',
        step3oth: 'Nakon uspešne potvrde aktivacionog koda, primićete SMS sa verifikacionim kodom. Verifikacioni kod upišite u polje za poruke u Addiko Chat Bankingu i pošaljite kroz Viber aplikaciju.',
        step4oth: 'Nakon uspešne potvrde verifikacionog koda kreirajte Vaš lični PIN unutar Addiko Chat Bankinga. Ovaj PIN ćete ubuduće koristiti prilikom prijave i potvrde plaćanja putem Addiko Chat Bankinga.',
        step1sr: 'Odaberite opciju „Postani pratilac“. Primićete SMS poruku sa linkom koji će Vas preusmeriti direktno na javni profil Addiko banke na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime postajete pratilac našeg javnog profila.',
        step2sr: 'Vratite se u EBank i započnite aktivaciju usluge odabirom opcije "Aktiviraj". Primićete SMS poruku sa aktivacionim linkom koji će Vas preusmeriti direktno na Addiko Chat Banking na Viberu. Kroz Viber aplikaciju pošaljite aktivacioni kod koji će automatski biti upisan u polje Viber poruke.',
        step3sr: 'Nakon uspešne potvrde aktivacionog koda, primićete SMS sa verifikacionim kodom. Verifikacioni kod upišite u polje za poruke u Addiko Chat Bankingu i pošaljite kroz Viber aplikaciju.',
        step4sr: 'Nakon uspešne potvrde verifikacionog koda kreirajte Vaš lični PIN unutar Addiko Chat Bankinga. Ovaj PIN ćete ubuduće koristiti prilikom prijave i potvrde plaćanja putem Addiko Chat Bankinga.',
        becomeFollower: 'Postani pratilac',
        conditionHeader: "Jednostavna i brza banka na Viberu",
        conditionHeader2: 'Addiko Chat Banking vam omogućava:',
        text1: 'plaćanja i uvid u stanje',
        text2: 'pregled naloga za plaćanje',
        text3: 'da nađete najbližu ekspozituru ili bankomat',
        text4: 'informacije o aktuelnoj ponudi',
        activeMessage: 'Vaš Addiko Chat Banking nalog je aktivan.',
        mobileNumber: 'Broj telefona za koji je vezan servis:',
        activationDate: 'Datum aktivacije servisa:',
        successfulActivation: 'Uspešno aktiviran Addiko Chat Banking',
        unsuccessfulActivation: 'Neuspešna aktivacija. Molimo vas pokušajte ponovo. Za dodatnu asitenciju pozovite Kontakt centar na 0800 303 303.',
        successFollower: 'Postanite pratilac Addiko Viber naloga klikom na link koji smo Vam poslali u SMS poruci. Dobro došli.',
        smsSent: 'Aktivirajte Addiko Chat Banking servis klikom na link koji smo Vam poslali u sms poruci.',
        becomeFollowerAlert: "Ukoliko ste već pratilac Addiko javnog profila na Viberu, ovaj korak nije potreban. Želite li da nastavite sa opcijom „Aktiviraj“?",
        yes: "Da",
        no: "Ne"
  }
    };

    $translateProvider.translations('sr', data);

  }
} ());

