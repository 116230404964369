(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      dashboard: {
        "dashboardHeader": "Početna strana",
        "addNewWidget1": "Označite",
        "addNewWidget2": "Favorite",
        "pendingPayments": "Nalozi na čekanju",
        "show": "Prikaži",
        "hide": "Sakrij",
        "domesticPaymentBtn1": "Nalog za",
        "domesticPaymentBtn2": "plaćanje",
        "transferBtn1": "Prijenos/Prenos na",
        "transferBtn2": "moj račun",
        "templateBtn1": "Plaćanje iz",
        "templateBtn2": "uzorka",
        "quickPaymentTransfer": "Brzo plaćanje ili transfer",
        "domesticPayment": "Kreiraj opći/opšti nalog",
        "internalTransfer": "Kreiraj nalog za prijenos/prenos",
        "payTemplate": "Plati iz uzorka",
        "searchTemplatesPlaceholder": "Pretraži uzorke",
        "showAccounts": "Prikaži još računa",
        "instantLoan": "Brzi kredit",
        "instantLoanDescription": "",
        "apply": "Prijava",
        "contact": "Kontaktirajte nas",
        "hypoClub": "Addiko Club",
        "etrade": "E-Trade",
        "branch": "Pronađite poslovnicu",
        "bankTimeline": "Vremenski prikaz Vašeg poslovanja",
        "loadEvents": "Učitaj starije događaje",
        "noTimelineData": "Za odabrani period nema podataka za prikaz",
        "availableBalance": "Raspoloživo stanje",
        "loanAmount":"Iznos kredita",
        "confirmation": "Potvrda",
        "complaint": "Reklamacija/Pritužba",
        "cashLoan": "Gotovinski/Keš kredit",
        "approval": "odobrenje za 1 sat",
        "tydUnsubscribeBL" : "Poštovani/a, ukoliko više ne želite primati naše poruke direktnog marketinga, molimo Vas da, koristeći funkcionalnosti ''Poruke'' u okviru ove aplikacije, pošaljete poruku 'Odjava' ili nas pozovite na +387 51 951 000.",
        "tydUnsubscribeSA" : "Poštovani/a, ukoliko više ne želite primati naše poruke direktnog marketinga, molimo Vas da, koristeći funkcionalnosti ''Poruke'' u okviru ove aplikacije, pošaljete poruku 'Odjava' ili nas pozovite na +387 33 866 666."
        },
      timelineFilter: {
        "placeholder": "Pretraga i filtriranje",
        "all": "Svi",
        "credit": "Uplate",
        "debit": "Isplate",
        "others": "Ostali",
        "amountFrom": "Iznos od",
        "amountTo": "Do",
        "reset": "Poništi",
        "apply": "Primjeni"
      },
      header: {
        "dashboard": "Početna strana",
        "accounts": "Računi i kartice",
        "payments": "Plaćanja",
        "orders": "Narudžbe",
        "branches": "Poslovnice i bankomati",
        "settings": "Postavke",
        "notificationMessages": "Poruke",
        "notificationMessagesPlaceholder": "Napišite svoj odgovor",
        "notificationMessagesPlaceholderSubject": "Naslov poruke",
        "notificationMessagesUnread": "Nepročitana",
        "notificationMessagesMarkAsRead": "Označi kao pročitanu",
        "notificationMessagesReply": "Odgovori",
        "notificationMessagesSend": "Pošalji",
        "notificationMessagesCancelSend": "Odustani",
        "notificationMessagesViewAll": "Pregled svih poruka",
        "notificationEInvoices": "Novi eRačuni",
        "notificationEInvoicesDueDate": "datum naplate",
        "notificationEInvoicesPay": "Plati",
        "notificationEInvoicesViewAll": "Pregled svih eRačuna",
        "notificationPendingPayments": "Plaćanja u obradi",
        "notificationPendingPaymentsViewAll": "Pregled svih plaćanja",
        "notificationPendingPaymentsDateLabel": "datum",
        "notificationRejectedPayments": "Odbijena plaćanja",
        "notificationRejectedPaymentsViewAll": "Pregled svih plaćanja",
        "notificationRejectedPaymentsDateLabel": "datum",
        "notificationSettingsLangLabel": "Odaberite jezik:",
        "notificationSettingsViewAllLabel": "Otvorite postavke aplikacije",
        "signedInAs": "Prijavljeni korisnik",
        "lastLogin": "Datum zadnje prijave: ",
        "logout": "Odjava",
        "langEn": "Engleski",
        "langHr": "Lokalni",
        "langSr": "Srpski",
        "langMtr": "Crnogorski",
        "langBa": "Lokalni",
        "langSl": "Slovenački",
        "langDe": "Njemački",
        "langRu": "Ruski",
        "noMessages": "Nema poruka",
        "noeInvoices": "Nema eRačuna",
        "noPendingPayments": "Nema uplata na čekanju",
        "noRejectedPayments": "Nema odbačenih uplata"
      },
      footer: {
        "freeCall": "Besplatni poziv",
        "freeCallBh": "Kontakt centar",
        "internationalCall": "Internacionalni poziv",
        "email": "Pošaljite nam e-mail",
        "notificationDepth": "Dubinska analiza",
        "dearClients": "Poštovani,",
        "modalText1": "dozvolite da Vas informiramo/informišemo o važnosti ažuriranja Vaših osobnih/ličnih podataka kako bi se poslovni odnos i nadalje uspješno nastavio.",
        "modalText2": "Podaci kao što su broj i datum valjanosti osobnog identifikacijskog/ličnog identifikacionog dokumenta (osobna iskaznica/lična karta, putovnica/pasoš, dozvola boravka u BiH) potrebno je ažurirati u Vama najbližoj poslovnici Banke po isteku njihovog datuma važenja, a sve u skladu s pravilima Addiko Bank",
        "modalText3": "U svrhu ispravne identifikacije te nužnog ažuriranja Vašeg identifikacijskog dokumenta, Banka uzima slobodu da Vas obavijesti o navedenom putem dopisa poslanom na kućnu adresu i/ili porukom proslijeđenom kanalima direktnog bankarstva.",
        "regards": "Srdačan pozdrav",
        "closeButton": "Zatvori",
        "version":"Verzija",
        "server":"Server"
      },
      timeline: {
        msgWith: "Poruka ",
        msgWithout: "Poruka bez naslova ",
        msgReceived: " je primljena ",
        msgSent: " je poslana "
      },
      templateSearch: {
        favorites: "Favoriti",
        templatesList: "Lista uzoraka"
      },
      dashboardWidgets: {
        current: "Tekući računi i kartice",
        loan: "Krediti",
        deposit: "Štednja",
        savings: "Štedni računi",
        giro: "Žiro računi",
        addBtn: "Potvrdi"
      },
      dashboardNotification: {
        rejected: "Odbijenih naloga",
        einvoice: "neplaćenih eRačuna",
        group: "grupnih naloga za potpisati",
        goBtn: "Idi na plaćanja",
        unsigned: "Nepotpisanih naloga"
      }
    };

    $translateProvider
      .translations('bh', data);
  }
}());
