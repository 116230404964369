<table class="table table-striped table-responsive">
  <tbody>
  <tr>
    <td style="width:160px;"><span class="tlidm-key-label" translate="timeline.tldmMessageDate"></span></td>
    <td style="text-align:left;"><span
      class="tlidm-value-label">{{tlItem.messageDetails.creationDate | date:'medium'}}</span></td>
  </tr>
  <tr>
    <td style="width:160px;"><span class="tlidm-key-label" translate="timeline.tldmMessageSubject"></span></td>
    <td style="text-align:left;">
      <div class="row">
      <div class="col-xs-8" ng-class="{'col-xs-12': !tlItem.messageDetails.linkList}">
        <span class="tlidm-value-label">{{tlItem.messageDetails.subject}}</span>
      </div>
      <div ng-if="tlItem.messageDetails.linkList" class="col-xs-4">
        <button class="btn btn-primary pull-right  f-12"
                ng-click="dashboard.downloadMessageAttachment(tlItem.messageDetails.linkList);"
                translate="messages.downloadAttachment">Download attachment</button>
      </div>
      </div>
    </td>
  </tr>
  <tr>
    <td style="width:160px;"><span class="tlidm-key-label" translate="timeline.tldmMessageDetails"></span></td>
    <td style="text-align:left;"><span class="tlidm-value-label pre-line-text"
                                       ng-bind-html="tlItem.messageDetails.body"></span></td>
  </tr>
  <tr>
  </tr>
  <tr ng-if="tlItem.referenceType == 'Payment'">
    <td style="width:160px;"><span class="tlidm-key-label" translate="messages.paymentCode"></span></td>
    <td style="text-align:left;">
              <span class="tlidm-value-label underline cursor-pointer"
                    ng-click="dashboard.openPayment(tlItem.referenceId); $event.stopPropagation()">
                {{::tlItem.referenceId}}
              </span>
    </td>
  </tr>

  <tr ng-if="tlItem.messageDetails.hasOwnProperty('messageType') && tlItem.messageDetails.messageType == 'Order'">
    <td style="width:160px;">
      <span class="tlidm-key-label" translate="messages.orderCode"></span>
    </td>
    <td style="text-align:left;">
      <a class="tlidm-value-label" href="">{{tlItem.messageDetails.objectId}}</a>
    </td>
  </tr>
  </tbody>
</table>
