(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name paymentTemplates.controller:TemplateListOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('paymentTemplates')
    .controller('TemplateListOverviewSloCtrl', TemplateListOverviewSloCtrl);

  function TemplateListOverviewSloCtrl($rootScope, $state, CTLocalizationService, PaymentTemplatesService, $log, AccountsService, HelperService, ValidationService, PaymentsService,
                                       PurposeCodesService, ConfigEndpointService, $localStorage, ReferenceModelService, CountriesService,
                                       CreditAccountRegisterService, PaymentTypesService, ImagesService, $q, AlertService, PublicRevenueService, NotificationService) {
    var vm = this;

    vm.tempObj = {
      toAccountInput: '',
      fromAccountNumber: '',
      currencySymbol: '',
      formSubmitted: false,
      purposeOfPaymentInput: '',
      currentDate: HelperService.getYesterday().firstDay,
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      bankChargesRadiobutton: {
        type: '3'
      },
      recipientStatusRadiobutton: {
        type: '2'
      },
      referenceModelSelect: '',
      referenceNumberInput: '',
      payersSepaInput1: '',
      receiversSepaInput1: '',
      //related to public revenue
      tayPayerId: '',
      revenueCode: '',
      municipality: '',
      taxPeriodFrom: new Date(),
      taxPeriodTo: new Date(),
      budgetOrganization: '',
      paymentTypes: ['0', '1', '2', '3', '5', '6', '7', '8', '9']
    };
    vm.tempObj.paymentType = vm.tempObj.paymentTypes[0];

    vm.selectedImage = {};
    vm.templateList = {};
    vm.account = {};
    vm.searchTemplateList = '';
    vm.searchTemplateListApi = '';
    vm.templatesFilter = {
      page: 0,
      pageSize: 10,
      orderBy: "name asc",
      // paymentTypeGroupList: ['DomesticPayment','LocalCurrencyTransfer','ForeignPayment']
    };
    var pageSize = 10;

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000
    };
    vm.referenceModelList = [];
    vm.referenceModelFilter = {
      pageSize: 1000
    };
    //vm.countryCode = $localStorage.code;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

    vm.isEnabled = false;
    vm.updateResponse = {};
    vm.croIbanMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    // default reference number validation params for BiH environment
    vm.referencePattern = vm.regexPatterns.onlyNumbersAndDashes;
    vm.referenceMaxLength = 22;

    vm.init = function () {

      // related to public revenue in BiH environment
      if (vm.countryCode === 'bh') {
        getMunicipalities();
        getRevenueCodes();
        getBudgetOrganizations();
        getRevenueAccounts();
      }

      var promises = [
        vm.getPaymentTemplatesPage(true),
        getPurposeCodesPage(),
        getReferenceModels(),
        getPaymentModes(),
        getCountries(),
        loadImages()
      ];

      if (vm.countryCode == 'hr' || vm.countryCode == 'sl') {
        promises.push(getDebitAccountList());
      }

      $q.all(promises)
        .then(function () {
          populateTemplatesWithImages();
        }).catch(function (error) {
        $log.error(error);
      });

      //if ($localStorage.code) {
      //  vm.countryCode = $localStorage.code;
      //  setInputMasks();
      //} else {
      //  getDefaultData();
      //}
      setInputMasks();

    };

    vm.referenceList = [
      {
        value: "SI",
        name: "SI"
      },
      {
        value: "RF",
        name: "RF"
      },
      {
        value: "NRC",
        name: "NRC"
      }
    ];
    vm.tempObj.referenceModelSelect = "SI";

    vm.checkIban = function (accountNumber) {
      // If iban starts with SI56 - remove "SI" option from reference number!
      if (accountNumber.substring(0, 2) !== "SI") {
        vm.referenceList = [
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        vm.tempObj.referenceModelSelect = "NRC";
      } else {
        vm.referenceList = [
          {
            value: "SI",
            name: "SI"
          },
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        vm.tempObj.referenceModelSelect = "SI";
      }

      if (!accountNumber) {
        vm.tempObj.referenceModelSelect = 'NRC';
        return;
      }
      // vm.tempObj.referenceModelSelect = "";
      // vm.tempObj.referenceNumberInput= "";
    };

    var setInputMasks = function () {
      vm.uiMask = 'SI ?? ???? ???? ???? ???'
    }

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    var ModifyReferenceModel = function () {
      if (vm.countryCode !== 'hr') {
        _.forEach(vm.templateList.result, function (item) {
          if (!item.referenceTo) return;
          if (item.referenceTo.length == 2 && item.referenceTo == '00') {
            item.referenceTo = "";
          }
          else if (item.referenceTo.length > 2 && item.referenceTo.substr(0, 2) === '00') {
            vm.tempObj.referenceModelSelect = vm.referenceModelList[0];
            item.referenceTo = item.referenceTo.substr(2);
          }
        })
      }
    };

    vm.getPaymentTemplatesPage = function (reset) {
      //var params;


      if (reset) {
        // params = {page: 0, pageSize: pageSize, orderBy: "name asc"};
        vm.templatesFilter.page = 0;

        if (vm.searchTemplateListApi)
          vm.templatesFilter.nameGTE = vm.searchTemplateListApi;
        else
          vm.templatesFilter.nameGTE = null;

      } else {
        vm.templatesFilter.page += 1;

        // pageSize += 10;
        // params = vm.templatesFilter;

        if (vm.searchTemplateListApi)
          vm.templatesFilter.nameGTE = vm.searchTemplateListApi;
        else
          vm.templatesFilter.nameGTE = null;
      }
//      Remove cuz Template will not refresh after update!.
//      if (vm.templateList.hasMore || vm.templatesFilter.page === 0) {
      return PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {

          if (reset) {
            _.forEach(response.result, function (item) {
              item.editable = false;
            });
            vm.templateList = response;
            ModifyReferenceModel();

          } else {
            vm.templateList.hasMore = response.hasMore;
            vm.templateList.result = vm.templateList.result.concat(response.result);
            ModifyReferenceModel();
          }
          populateTemplatesWithImages();
          /*            _.forEach(vm.templateList, function (value) {
           $log.debug(value);
           });*/
        })
        .catch(function (error) {
          $log.error(error);
        });
//      }
    };

    vm.toggleDetails = function (template) {
      var previousState = template.openedDetails;
      // Close all other templates if they were opened
      _.forEach(vm.templateList.result, function (tpl) {
        tpl.openedDetails = false;
        tpl.editable = false;
      });

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        PaymentTemplatesService.getById(template.id)
          .then(function (response) {
            angular.extend(template, response);
            vm.openedTemplate = angular.extend(template, response);
            // Amount cannot be empty and must be 0.01 or bigger!
            template.amount = (!template.amount) ? 0.01 : template.amount;
            template.openedDetails = !previousState;

            var country = _.find(vm.countriesOptions, {code: template.beneficiaryBankCountryCode});
            if (country) {
              template.recipientCountry = country;
            }

            console.log(template);
            if (template.openedDetails) {
              if (template.modalityCode) {
                var modality = _.find(vm.paymentModeOptions, {id: template.modalityCode});
                if (modality) {
                  template.modalityFullName = modality.id + " - " + modality.description;
                }
              }
            }
            if (!vm.account || (template.openedDetails && vm.account.id !== template.accountId)) {
              vm.getAccountById(template.accountId);
            }


          }, function (error) {
            console.log(error);
          });
      } else {
        template.openedDetails = !previousState;
        if (template.openedDetails) {
          if (template.modalityCode) {
            var modality = _.find(vm.paymentModeOptions, {id: template.modalityCode});
            if (modality) {
              template.modalityFullName = modality.id + " - " + modality.description;
            }
          }
        }

        // Load account for this template
        if (!vm.account || (template.openedDetails && vm.account.id !== template.accountId)) {
          vm.getAccountById(template.accountId);
        }
      }

    };

    vm.formatReferenceNumber = function (referenceNumber) {
      var referenceNumberFormatted;
      var referenceNumberP1 = referenceNumber.substring(0, 2);
      var referenceNumberP2;
      var referenceNumberP3;

      if (referenceNumberP1 == 'SI') {
        referenceNumberP2 = referenceNumber.substring(2, 4);
        referenceNumberP3 = referenceNumber.substring(4);
        referenceNumberFormatted = referenceNumberP1 + " " + referenceNumberP2 + " " + referenceNumberP3;
      } else if (referenceNumberP1 == "RF") {
        referenceNumberP2 = referenceNumber.substring(2);
        referenceNumberFormatted = referenceNumberP1 + " " + referenceNumberP2;
      } else {
        referenceNumberFormatted = referenceNumber.substring(0, 3);
      }

      return referenceNumberFormatted;
    }

    var populateRefNumber = function (number) {
      if (!number) return;
      if (number.substring(0, 3) == "NRC") {
        vm.tempObj.referenceModelSelect = number.substring(0, 3);
        vm.referenceModelListSelect = "";
        vm.tempObj.referenceNumberInput = "";
      } else if (number.substring(0, 2) == "SI") {
        vm.tempObj.referenceModelSelect = number.substring(0, 2);
        vm.referenceModelListSelect = number.substring(2, 4);
        vm.tempObj.referenceNumberInput = number.substring(4);
      } else if (number.substring(0, 2) == "RF") {
        vm.tempObj.referenceModelSelect = number.substring(0, 2);
        vm.referenceModelListSelect = "";
        vm.tempObj.referenceNumberInput = number.substring(2);
      }
    };


    vm.getAccountById = function (id) {
      return AccountsService.getAccountById(id)
        .then(function (response) {
          vm.account = response;
          vm.tempObj.fromAccountNumber = response.accountNumber;
          vm.tempObj.currencySymbol = response.currencySymbol;
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
            angular.extend(vm.openedTemplate, response);
          }
        })
        .catch(function (error) {
          $log.error(error);
        })
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deleteTemplate = function (template) {
      if (template.templateDeleteInProgress) return;
      template.templateDeleteInProgress = true;
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentTemplatesService.deleteTemplate(template.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({paymentName: template.name, type: 'template'})
                  // .then(function () {
                  //   vm.getPaymentTemplatesPage(true);
                  //   return;
                  // });
                vm.getPaymentTemplatesPage(true);
              })
              .catch(function (error) {
                template.templateDeleteInProgress = false;
                NotificationService.showMessage(error, 'error');
              }).finally (function (){
          });
          } else {
            template.templateDeleteInProgress = false;
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        })

    };

    vm.searchTemplate = function (searchrow) {
      vm.getPaymentTemplatesPage(true, searchrow);
    };

    vm.loadMoreTemplates = function () {
      vm.getPaymentTemplatesPage(false);
    };

    vm.selectImage = function (image) {
      if (image) {
        vm.selectedImage = image;
      }
    };

    var prepareTemplateForUpdate = function (paymentObject) {

      if (paymentObject.paymentTypeGroup === 'DomesticPayment' || paymentObject.paymentTypeGroup === 'SEPAPayment') {
        vm.tempObj.receiversNameInput = vm.payeeSearchFilter;
        if (vm.tempObj.receiversPostalCodeAndCityInput === '') {
          vm.tempObj.receiversPostalCodeAndCityInput = paymentObject.receiverCity;
        }
        if (vm.tempObj.receiversAddressInput === '') {
          vm.tempObj.receiversAddressInput = paymentObject.receiverAddress1;
        }
      }

      if (vm.tempObj.toAccountInput == '') {
        vm.tempObj.toAccountInput = paymentObject.toAccountNumber;
      }

      if (vm.tempObj.referenceModelSelect == "SI") {
        vm.tempObj.referenceTo = vm.tempObj.referenceModelSelect + vm.referenceModelListSelect + vm.tempObj.referenceNumberInput;
      } else if (vm.tempObj.referenceModelSelect == "RF") {
        vm.tempObj.referenceTo = vm.tempObj.referenceModelSelect + vm.tempObj.referenceNumberInput;
      } else if (vm.tempObj.referenceModelSelect == "NRC") {
        vm.tempObj.referenceTo = vm.tempObj.referenceModelSelect;
      }

      var tempForUpdate = {
        name: vm.tempObj.templateName,
        accountId: paymentObject.accountId,
        fromAccountNumber: vm.tempObj.fromAccountNumber,
        toAccountNumber: vm.tempObj.toAccountInput,
        currencySymbol: vm.tempObj.currencySymbol,
        amount: HelperService.parseAmountInput(paymentObject.amount),
        paymentTypeGroup: paymentObject.paymentTypeGroup,
        purpose: vm.tempObj.purposeOfPaymentInput,
        purposeCodeDPS: vm.tempObj.purposeCodeSelect.id
      };

      if (vm.selectedImage.id) {
        tempForUpdate.imageId = vm.selectedImage.id;
      }

      var subProperties = {
        receiverAddress1: vm.tempObj.receiversAddressInput,
        receiverName: vm.payeeSearchFilter
      };

      if (vm.countryCode !== 'bh') {
        subProperties.receiverCity = vm.tempObj.receiversPostalCodeAndCityInput;
        subProperties.senderName = paymentObject.senderName;
        subProperties.senderAddress1 = paymentObject.senderAddress1;
        subProperties.senderCity = paymentObject.senderCity;
        subProperties.referenceTo = vm.tempObj.referenceTo;
      } else {
        subProperties.reference = vm.tempObj.referenceNumberInput;
      }

      var sepaProperties = {
        debtorName: vm.tempObj.payersSepaInput1,
        creditorName: vm.tempObj.receiversSepaInput1
      };

      tempForUpdate.sepaUpnIdentificators = sepaProperties;
      switch (paymentObject.paymentTypeGroup) {
        case 'DomesticPayment':
        case 'SEPAPayment':
          tempForUpdate.domesticPayment = subProperties;
          if (vm.tempObj.beneficiarySWIFTCode)
            tempForUpdate.beneficiarySWIFTCode = vm.tempObj.beneficiarySWIFTCode;
          break;
        case 'ForeignPayment':
          tempForUpdate.foreignPayment = subProperties;
          tempForUpdate.foreignPayment.beneficiaryBankName = vm.tempObj.bankNameInput;
          tempForUpdate.foreignPayment.beneficiaryBankAddress = vm.tempObj.bankAddressInput;
          tempForUpdate.foreignPayment.beneficiaryBankCity = vm.tempObj.bankCityInput;
          tempForUpdate.foreignPayment.beneficiarySWIFTCode = vm.tempObj.swiftCodeInput;
          tempForUpdate.foreignPayment.paymentCharges = vm.tempObj.bankChargesRadiobutton.type;
          tempForUpdate.foreignPayment.payeeLegalForm = vm.tempObj.recipientStatusRadiobutton.type;
          tempForUpdate.foreignPayment.receiverCountryCode = paymentObject.receiverCountryCode;
          tempForUpdate.foreignPayment.chargeAccount = vm.account.accountNumber + ' ' + vm.account.currencySymbol;
          tempForUpdate.foreignPayment.modalityCode = vm.tempObj.modalityCode;
          if (vm.tempObj.bankCountrySelect) {
            tempForUpdate.foreignPayment.beneficiaryBankCountryCode = vm.tempObj.bankCountrySelect.code;
          }
          break;
        case "RevenuePayment":
          subProperties.taxPayerId = vm.tempObj.taxPayerId;
          subProperties.budgetOrganization = vm.tempObj.budgetOrganization;
          subProperties.municipality = vm.tempObj.municipality;
          subProperties.revenueCode = vm.tempObj.revenueCode;
          subProperties.paymentType = vm.tempObj.paymentType;
          subProperties.taxPeriodFrom = vm.tempObj.taxPeriodFrom;
          subProperties.taxPeriodTo = vm.tempObj.taxPeriodTo;
          subProperties.taxPeriodTo = vm.tempObj.taxPeriodTo;
          tempForUpdate.domesticPayment = subProperties;
          break;
      }
      return tempForUpdate;
    };

    vm.saveTemplate = function (template, index) {
      if (vm.saveButtonPressed) {
        return;
      }

      vm.tempObj.formSubmitted = true;
      if (!vm.templatesListForm.$valid) {
        return;
      }

      var templateForSaveObj = prepareTemplateForUpdate(template);
      vm.saveButtonPressed = true;
      PaymentTemplatesService.updateTemplate(template.id, templateForSaveObj)
        .then(function (response) {
          //swal(templateUpdatedSuccessfully);
          //swal.collapse;
          AlertService.infoAlert({
            text: 'alert.updateTemplate'
          })
            .then(function () {
              template.editable = false;
              vm.getPaymentTemplatesPage(true);
              vm.updateResponse = response;
              clearAllFields();
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function() {
          vm.saveButtonPressed = false;
        });
    };

    vm.editTemplate = function (template) {
      _.forEach(vm.templateList.result, function (template) {
        template.editable = false;
      });
      template.editable = true;
      vm.currentTemplate = _.find(vm.templateList.result, {id: template.id});
      populateEditableFields(vm.currentTemplate);

    };

    vm.cancel = function (template) {
      template.editable = false;
      clearAllFields();
    };

    vm.checkInputChanges = function (group) {
      if (group === 'DomesticPayment') {
        if (!vm.tempObj.toAccountInput && !vm.tempObj.receiversPostalCodeAndCityInput && !vm.tempObj.receiversAddressInput && !vm.tempObj.receiversNameInput)
          vm.isEnabled = false;
        else
          vm.isEnabled = true;
      } else {
        if (!vm.tempObj.toAccountInput)
          vm.isEnabled = false;
        else
          vm.isEnabled = true;
      }
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.removeSearch = function () {
      vm.payeeSearchFilter = '';
      vm.tempObj.toAccountInput = '';
      vm.tempObj.receiversAddressInput = '';
      vm.tempObj.receiversPostalCodeAndCityInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.payeeSearchFilter) || '') !== -1 ||
      angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.payeeSearchFilter) || '') !== -1);
    };

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.selectPayee = function (id) {

      var payee = _.find(vm.payeeList, function (item) {
        return item.id == id;
      });

      if (payee) {
        vm.tempObj.toAccountInput = payee.toAccountNumber;
        vm.tempObj.receiversAddressInput = payee.receiverAddress1;
        vm.tempObj.receiversNameInput = payee.receiverName;
        vm.tempObj.receiversPostalCodeAndCityInput = payee.receiverCity;
        vm.payeeSearchFilter = payee.receiverName;
      }

      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    var fillReceiversInput = function (response) {
      if (response.result.length > 0) {
        vm.tempObj.receiversNameInput = response.result[0].receiverName;
        vm.payeeSearchFilter = response.result[0].receiverName;
        vm.tempObj.receiversAddressInput = response.result[0].receiverAddress1;
        vm.tempObj.receiversPostalCodeAndCityInput = response.result[0].receiverCity;
      }
    };

    vm.templateImagesFilter = {
      category: "Templates"
    };

    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        if (images)
          vm.images = images.result;
      }).catch(function (err) {
        $log.error(err);
      });
    };

    vm.fillReceiver = function (toAccountInput) {
      if (!toAccountInput) return;
      toAccountInput = toAccountInput.replace(/\s/g, '');
      if (toAccountInput.substring(0, 2) === 'HR') {
        CreditAccountRegisterService.getByIban(toAccountInput)
          .then(function (response) {
            fillReceiversInput(response);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      } else {
        CreditAccountRegisterService.getPage({
          accountNumber: toAccountInput
        })
          .then(function (response) {
            fillReceiversInput(response);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    var getPurposeCodesPage = function () {
      return PurposeCodesService.getPage(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (item) {
            item.optionValue = item.id + ' - ' + item.description;
          });
          vm.purposeCodesList = response;

          $log.debug("purposeCodesList", vm.purposeCodesList);
        })
        .catch(function () {
          $log.error('Error loading purpose codes!');
        });
    };

    var getPaymentModes = function () {
      return PaymentsService.getPaymentModes().then(function (response) {
        vm.paymentModeOptions = response.result;
      })
        .catch(function (err) {
          $log.error('Error loading bic banks codes!');
        });
    };

    var getCountries = function () {
      return CountriesService.getPage({pageSize: 1000}).then(function (response) {
        vm.countriesOptions = response.result;
      }).catch(function (err) {
        $log.error(err);
      });
    };

    var emptyReferenceModel = {
      description: " ",
      model: "  "
    };

    var getReferenceModels = function () {
      return ReferenceModelService.getPage(vm.referenceModelFilter)
        .then(function (response) {
          if (vm.countryCode && vm.countryCode !== 'hr' && vm.countryCode !== 'sl') {
            vm.referenceModelList.push(emptyReferenceModel.model);
          }
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
          vm.tempObj.referenceModelSelect = vm.referenceModelList[0];
        })
        .catch(function () {
          $log.error('Error loading bic banks codes!');
        });
    };

    var getDebitAccountList = function () {
      return PaymentTypesService.getByGroup({
        paymentTypeGroup: 'ForeignPayment'
      })
        .then(function (response) {
          if (_.isArray(response.result) && response.result.length > 0) {
            vm.accountsFrom = response.result[0].debitAccountList;
            // vm.chargeAccountOptions = _.where(vm.accountsFrom, {iban: item.iban});
            _.forEach(vm.accountsFrom, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.accountDescription + ' - ' + item.iban;
              }
            });
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    var clearAllFields = function () {
      vm.tempObj.receiversNameInput = '';
      vm.tempObj.receiversPostalCodeAndCityInput = '';
      vm.tempObj.receiversAddressInput = '';
      vm.tempObj.toAccountInput = '';

      // Foreign specific fields
      vm.countrySearchFilter = '';
      vm.bicCodeSearchFilter = '';
      vm.tempObj.swiftCodeInput = "";
      vm.tempObj.bankAddressInput = "";
      vm.tempObj.bankCityInput = "";
      vm.tempObj.bankNameInput = "";
    };

    vm.changePurpose = function (code) {
      var purposeCode = _.find(vm.purposeCodesList.result, function (item) {
        return item.id == code.id;
      });
      if (purposeCode && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') {
        vm.tempObj.purposeOfPaymentInput = purposeCode.description;
      }
    };

    vm.onBicBankSelected = function (bicCode) {
      var bicBank = _.find(vm.bicBankOptions, {bicCode: bicCode});
      vm.tempObj.swiftCodeInput = bicBank.bicCode;
      vm.tempObj.bankAddressInput = bicBank.branchInformation;
      vm.tempObj.bankCityInput = bicBank.city;
      vm.tempObj.bankNameInput = bicBank.institutionName;

      vm.bicCodeSearchFilter = bicBank.bicCode;
      vm.bicCodeSearchFilterExpanded = false;
    };

    vm.addSpaceForIban = function (toAccountInput) {
      if (!vm.tempObj.toAccountInput) return;
      vm.tempObj.toAccountInput = toAccountInput.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    };

    vm.getBicByIban = function (iban) {
      if (!iban) {
        return;
      }
      iban = iban.replace(/\s/g, '');
      PaymentsService.getBicBanks(undefined, {iban: iban})
        .then(function (response) {
          var bicBank = response.result[0];
          if (bicBank) {
            vm.bicCodeSearchFilter = bicBank.bicCode;
            vm.tempObj.swiftCodeInput = bicBank.bicCode;
            vm.tempObj.bankAddressInput = bicBank.branchInformation;
            vm.tempObj.bankCityInput = bicBank.city;
            vm.tempObj.bankNameInput = bicBank.institutionName;
          }
        }, function (error) {

        })
    };

    vm.searchBICNumber = function (bicInput) {
      vm.bicCodeSearchFilterExpanded = true;
      vm.tempObj.swiftCodeInput = bicInput;
      searchBicNumber(bicInput);
    };

    var searchBicNumber = function (bicInput) {
      PaymentsService.getBicBanks(undefined, {bicCode: bicInput})
        .then(function (response) {
          vm.bicBankOptions = response.result;
        }, function (error) {

        })
    };

    vm.removeBicCodeSearch = function () {
      vm.bicCodeSearchFilter = "";
      vm.tempObj.swiftCodeInput = "";
      vm.tempObj.bankAddressInput = "";
      vm.tempObj.bankCityInput = "";
      vm.tempObj.bankNameInput = "";
    };


    // Country selection

    vm.onCountrySelect = function (country) {
      vm.countrySearchFilter = country.code + " " + country.description;
      vm.tempObj.bankCountrySelect = country;
      vm.countrySearchFilterExpanded = false;
    };

    vm.removeSelectedCountry = function () {
      vm.countrySearchFilter = "";
      vm.tempObj.bankCountrySelect = {};
      vm.countrySearchFilterExpanded = true;
    };

    vm.setChargeAccount = function (item) {
      vm.tempObj.fromAccountSelect = item;
      vm.tempObj.chargeAccountInput = item;
      vm.chargeAccountOptions = _.where(vm.accountsFrom, {iban: item.iban});
    };

    var populateEditableFields = function (template) {

      vm.payeeList = _.filter(vm.templateList.result, function (item) {
        return item.paymentTypeGroup === template.paymentTypeGroup;
      });

      if (template.referenceTo) {
        if (template.referenceTo.length > 2) {
          var i = template.referenceTo.substr(2);
          vm.tempObj.referenceModelSelect = template.referenceTo.substr(0, 2);
          vm.tempObj.referenceNumberInput = i;
        } else {
          vm.tempObj.referenceModelSelect = template.referenceTo;
          vm.tempObj.referenceNumberInput = "";
        }
      }

      var index = _.findIndex(vm.referenceModelList, function (item) {
        return item === vm.tempObj.referenceModelSelect;
      });

      if (index != -1) {
        vm.tempObj.referenceModelSelect = vm.referenceModelList[index];
      } else {
        vm.tempObj.referenceModelSelect = vm.referenceModelList[0];
        vm.tempObj.referenceNumberInput = template.referenceTo;
      }

      vm.tempObj.toAccountInput = template.toAccountNumber;
      vm.tempObj.receiversAddressInput = template.receiverAddress1;
      vm.tempObj.receiversNameInput = template.receiverName;
      vm.tempObj.receiversPostalCodeAndCityInput = template.receiverCity;
      vm.tempObj.modalityCode = template.modalityCode;
      vm.tempObj.templateName = template.name;
      vm.tempObj.payersSepaInput1 = template.sepaUpnIdentificators.debtorName;
      vm.tempObj.receiversSepaInput1 = template.sepaUpnIdentificators.creditorName;


      // Populate BIC fields from template
      if (template.paymentTypeGroup === 'ForeignPayment') {
        vm.tempObj.swiftCodeInput = template.beneficiarySWIFTCode;
        vm.bicCodeSearchFilter = template.beneficiarySWIFTCode;
        vm.tempObj.bankAddressInput = template.beneficiaryBankAddress;
        vm.tempObj.bankCityInput = template.beneficiaryBankCity;
        vm.tempObj.bankNameInput = template.beneficiaryBankName;
        vm.tempObj.bankChargesRadiobutton.type = template.paymentCharges || '3';
        vm.tempObj.recipientStatusRadiobutton.type = template.payeeLegalForm || '2';

        // vm.tempObj.chargeAccountInput = template.


        var country = _.find(vm.countriesOptions, {code: template.beneficiaryBankCountryCode});
        if (country) {
          vm.countrySearchFilter = country.code + " " + country.description;
          vm.tempObj.bankCountrySelect = country;
        }

        vm.fieldMaxLength = "34";
      } else {
        vm.fieldMaxLength = "70";
      }

      vm.payeeSearchFilter = template.receiverName;
      vm.searchFilterExpanded = false;
      // vm.searchTemplateFilterExpanded = false;

      if ((template.purposeCode || template.purposeCodeDPS) && vm.purposeCodesList.result) {
        var purposeCode;
        // Remove leading character from string
        if (template.purposeCodeDPS && vm.countryCode != 'bh') {
          var leading_char = template.purposeCodeDPS.substring(0, 1);
          if (template.purposeCodeDPS.length > 2 && leading_char === '2' || leading_char === '9') {
            template.purposeCodeDPS = template.purposeCodeDPS.substring(1);
            purposeCode = template.purposeCodeDPS;
          }
        } else if (template.purposeCodeDPS && vm.countryCode == 'bh') {
          purposeCode = template.purposeCodeDPS;
        } else {
          purposeCode = template.purposeCode;
        }

        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          // In slovenia purpose code is returned in purposeCode property, but in croatia for example purposeCodeDPS is used
          return purpose.id == purposeCode;
        });
        if (purpose) {
          vm.tempObj.purposeCodeSelect = purpose;
          vm.tempObj.purposeOfPaymentInput = purpose.description;
        }
      } else {
        // add empty one
        vm.tempObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      }

      if (vm.tempObj.purposeOfPaymentInput === '' || template.purpose !== vm.tempObj.purposeOfPaymentInput) {
        vm.tempObj.purposeOfPaymentInput = template.purpose;
      }

      // Set selected template image
      if (template.imageId) {
        // Set selected image
        vm.selectedImage = {
          downloadLink: template.imageDownloadLink,
          id: template.imageId
        };
      } else {
        // If there was no image, set it to first from list (default image)
        if (vm.images && vm.images.length > 0) {
          vm.selectedImage = {
            downloadLink: vm.images[0].imageDownloadLink,
            id: vm.images[0].id
          }
        }
      }

      if (template.beneficiarySWIFTCode) {
        vm.tempObj.beneficiarySWIFTCode = template.beneficiarySWIFTCode;

      }

      // populate public revenue fields
      if (template.paymentTypeGroup == "RevenuePayment") {
        vm.tempObj.taxPayerId = template.taxPayerId;
        vm.tempObj.paymentType = template.paymentType || vm.tempObj.paymentTypes[0];
        vm.tempObj.revenueCode = template.revenueCode;
        vm.tempObj.municipality = template.municipality;
        vm.tempObj.taxPeriodFrom = new Date(template.taxPeriodFrom);
        vm.tempObj.taxPeriodTo = new Date(template.taxPeriodTo);
        vm.tempObj.budgetOrganization = template.budgetOrganization;
        vm.tempObj.selectedRevenueAccount = _.find(vm.revenueAccounts, function (item) {
          return vm.tempObj.toAccountInput == item.revenueAccountNumber;
        });
        vm.referenceMaxLength = 10;
        vm.referencePattern = vm.regexPatterns.onlyNumbers;

      }
      vm.getBicByIban2(vm.tempObj.toAccountInput);
      vm.checkIban(vm.tempObj.toAccountInput);
      populateRefNumber(template.referenceTo);

    };

    // related to Public Revenue
    function getMunicipalities() {
      PublicRevenueService.getMunicipalities({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.tempObj.municipalities = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    function getRevenueCodes() {
      PublicRevenueService.getRevenueCodes({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.tempObj.revenueCodes = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    function getBudgetOrganizations() {
      PublicRevenueService.getBudgetOrganizations({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.tempObj.budgetOrganizations = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    // custom municipalities autocomplete
    vm.municipalityFilterExpanded = false;
    vm.openMunicipalities = function () {
      vm.municipalityFilterExpanded = true;
      validateMunicipalityExistance();
    };

    vm.toggleMunicipalities = function () {
      vm.municipalityFilterExpanded = !vm.municipalityFilterExpanded;
    };

    vm.removeMunicipalities = function () {
      vm.tempObj.municipality = '';
    };

    vm.selectMunicipality = function (municipality) {
      vm.tempObj.municipality = municipality.codeString;
      vm.municipalityFilterExpanded = false;
      validateMunicipalityExistance();
    };

    vm.filterMunicipalities = function (item) {
      return (angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempObj.municipality) || '') !== -1 ||
      angular.$$lowercase(item.codeString).indexOf(angular.$$lowercase(vm.tempObj.municipality) || '') !== -1);
    };

    // custom revenue type autocomplete
    vm.revenueCodeFilterExpanded = false;
    vm.openRevenueCodes = function () {
      vm.revenueCodeFilterExpanded = true;
      vm.tempObj.selectedRevenueCode = null;
      validateRevenueCodeExistance();
    };

    vm.toggleRevenueCodes = function () {
      vm.revenueCodeFilterExpanded = !vm.revenueCodeFilterExpanded;
    };

    vm.closeRevenueCodes = function () {
      vm.tempObj.revenueCode = '';
    };

    vm.selectRevenueCode = function (revenueCode) {
      vm.tempObj.revenueCode = revenueCode.code;
      vm.revenueCodeFilterExpanded = false;
      vm.tempObj.selectedRevenueCode = revenueCode;
      validateRevenueCodeExistance();
    };

    // fcn for filtering revenue codes
    vm.filterRevenueCodes = function (item) {
      return (angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempObj.revenueCode) || '') !== -1 ||
      angular.$$lowercase(item.code.toString()).indexOf(angular.$$lowercase(vm.tempObj.revenueCode) || '') !== -1);
    };

    // custom budget organizations autocomplete
    vm.budgetOrganizationFilterExpanded = false;
    vm.openBudgetOrganizations = function () {
      vm.budgetOrganizationFilterExpanded = true;
    };

    vm.toggleBudgetOrganizations = function () {
      vm.budgetOrganizationFilterExpanded = !vm.budgetOrganizationFilterExpanded;
    };

    vm.closeBudgetOrganization = function () {
      vm.tempObj.budgetOrganization = '';
    };

    vm.selectBudgetOrganization = function (budgetOrg) {
      vm.tempObj.budgetOrganization = budgetOrg.budgetOrganisationNumber;
      vm.budgetOrganizationFilterExpanded = false;
    };

    // fcn for filtering revenue codes
    vm.filterBudgetOrganizations = function (item) {
      return (angular.$$lowercase(item.budgetOrganisationNumber).indexOf(angular.$$lowercase(vm.tempObj.budgetOrganization) || '') !== -1 ||
      angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempObj.budgetOrganization) || '') !== -1);
    };

    vm.taxPeriodFromOpened = false;
    vm.taxPeriodToOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.openTaxPeriodFrom = function () {
      vm.taxPeriodFromOpened = true;
    };

    vm.openTaxPeriodTo = function () {
      vm.taxPeriodToOpened = true;
    };

    function getRevenueAccounts() {
      PublicRevenueService.getRevenueAccounts({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.revenueAccounts = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.checkIsRevenueAccount = function () {
      //isRevenueAccount();
      validateRevenueCodeType();
    };

    //custom validation
    vm.validateTaxPeriod = function () {
      var isRangeValid = false;
      if (vm.tempObj.taxPeriodFrom <= vm.tempObj.taxPeriodTo) {
        isRangeValid = true;
      }
      vm.templatesListForm.taxPeriodFrom.$setValidity('taxPeriodRange', isRangeValid);
    };

    function checkIfMunicipalityExists() {
      var exists = _.find(vm.tempObj.municipalities, function (item) {
        return vm.tempObj.municipality == item.codeString;
      });
      if (exists) {
        return true;
      }
      return false;
    };

    function validateMunicipalityExistance() {
      var exist = checkIfMunicipalityExists();
      if (!vm.tempObj.municipality) {
        exist = true;
      }
      vm.templatesListForm.municipalityFilter.$setValidity('municipalityExistance', exist);
    };

    function checkIfRevenueCodeExists() {
      var exists = _.find(vm.tempObj.revenueCodes, function (item) {
        return vm.tempObj.revenueCode == item.code;
      });
      if (exists) {
        return true;
      }
      return false;
    };

    function validateRevenueCodeExistance() {
      var exist = checkIfRevenueCodeExists();
      if (!vm.tempObj.revenueCode) {
        exist = true;
      }
      vm.templatesListForm.revenueCodeFilter.$setValidity('revenueCodeExistance', exist);
    };

    function validateRevenueCodeType() {
      if (vm.tempObj.selectedRevenueAccount) {
        var correctRevenueCode = false;
        if (vm.tempObj.revenueCode === '000000') {
          correctRevenueCode = true;
        } else {
          var revenueCode = _.find(vm.tempObj.revenueCodes, function (item) {
            return vm.tempObj.revenueCode == item.code;
          });
          if (revenueCode) {
            var equalityCriteria = vm.tempObj.selectedRevenueCode ? vm.tempObj.selectedRevenueCode : revenueCode;
            if (vm.tempObj.selectedRevenueAccount.revenueType === equalityCriteria.type) {
              correctRevenueCode = true;
            }
          }
        }
        if (!vm.tempObj.revenueCode) {
          correctRevenueCode = true;
        }
        if (vm.templatesListForm.revenueCodeFilter) {
          vm.templatesListForm.revenueCodeFilter.$setValidity('revenueCodeType', correctRevenueCode);
        }
      }
    };

    // validation of reference number BiH environment

    vm.completeReferenceNumber = function () {
      if (vm.tempObj.selectedRevenueAccount && !vm.templatesListForm.referenceNumberInput.$valid) {
        if (!vm.tempObj.referenceNumberInput) {
          vm.tempObj.referenceNumberInput = '';
        }
        var refLength = vm.tempObj.referenceNumberInput.length;
        var zeros = '';
        if (refLength < 10) {
          for (var i = 0; i < (10 - refLength); i++) {
            zeros += '0';
          }
        }
        vm.tempObj.referenceNumberInput = zeros + vm.tempObj.referenceNumberInput;
      }
    };

    vm.validateReferenceNumber = function () {
      var requiredReference = true;
      if (vm.tempObj.selectedRevenueAccount && (vm.tempObj.selectedRevenueAccount.revenueType == '92' || vm.tempObj.selectedRevenueAccount.revenueType == '91')) {
        requiredReference = vm.tempObj.referenceNumberInput ? true : false;
      }
      vm.templatesListForm.referenceNumberInput.$setValidity('referenceNumberRequired', requiredReference);
    };

    vm.checkReferenceNumber = function () {
      var allZeros = false;
      if (vm.tempObj.referenceNumberInput) {
        allZeros = true;
        for (var i = 0; i < vm.tempObj.referenceNumberInput.length; i++) {
          if (vm.tempObj.referenceNumberInput[i] != '0') {
            allZeros = false;
            break;
          }
        }
      }
      vm.templatesListForm.referenceNumberInput.$setValidity('validReferenceNumber', !allZeros);
    };

    // vm.checkPurposeOfPaymentLength = function () {
    //   if (vm.tempObj.referenceModelSelect == "RF") {
    //     vm.referenceModelListSelect = '';
    //   }
    //   if (vm.tempObj.referenceModelSelect != "NRC") {
    //     vm.tempObj.purposeOfPaymentInput = vm.tempObj.purposeOfPaymentInput.substring(0, 34)
    //   } else {
    //     vm.tempObj.referenceNumberInput = '';
    //     vm.referenceModelListSelect = '';
    //   }
    //
    // };


    vm.getBicByIban2 = function (iban) {
      if (!iban) {
        return;
      }
      iban = iban.replace(/\s/g, '');
      PaymentsService.getBicBanks(undefined, {
        iban: iban
      })
        .then(function (response) {
          var bicBank = response.result[0];
          if (bicBank) {
            vm.tempObj.beneficiarySWIFTCode = bicBank.bicCode;
          }
        }, function (error) {

        })
    };

    vm.goToPayment = function (template) {
      var params = {
        templateId: template.id
      };
      if (template.paymentSubTypeGroup == "NotSupportedPayment") {
        AlertService.infoAlert({
          text: "paymentsOverview.notSupportedPaymentMsg"
        })
        return;
      }
      if (template.paymentTypeGroup == 'DomesticPayment' || template.paymentTypeGroup == 'SEPAPayment' || template.paymentTypeGroup == 'LocalCurrencyTransfer') {
        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {

          if (template.paymentTypeGroup == "LocalCurrencyTransfer" && template.paymentSubTypeGroup == "SEPAPayment") {
              $state.go('payments.domesticPayment.fromTemplatesToPay', params);
          } else if (template.paymentTypeGroup == "LocalCurrencyTransfer"  && template.paymentSubTypeGroup == "LocalCurrencyTransfer") {
              $state.go('payments.internalTransfer.fromTemplate', params);
          } else {
            $state.go('payments.domesticPayment.fromTemplatesToPay', params);
          }

          // if (template.paymentTypeGroup == "LocalCurrencyTransfer" && (template.toAccountNumber.substring(0, 4) != "SI56" || (template.iban && template.iban.substring(0, 4) != "SI56"))) {
          //   $state.go('payments.internalTransfer.fromTemplate', params);
          // } else  if (template.paymentTypeGroup == "LocalCurrencyTransfer" && template.toAccountNumber.substring(0, 4) == "SI56") {
          //   $state.go('payments.domesticPayment.fromTemplatesToPay', params);
          // } else {
          //   $state.go('payments.domesticPayment.fromTemplatesToPay', params);
          // }


          } else {
          $state.go('payments.domesticPayment.fromTemplatesToPay', params);
        }
      }

      if (template.paymentTypeGroup == 'ForeignPayment')
        $state.go('payments.internationalPayment.fromTemplatesToPay', params);
      if(template.paymentTypeGroup ==  "ForeignCurrencyTransfer")
        $state.go('payments.internalTransfer.fromTemplate', params);
    }

    vm.init();
  }
}());

