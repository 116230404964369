
<div ng-show="instantPayment.location == 'sr'">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 domp-from-section">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="form-group">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">
          {{instantPaymentSrb.tempDompObj.fromAccountSelect.accountDescription}}</label>
        <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.fromAccountNumber}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
            <label class="domp-form-label-resume">
              {{instantPaymentSrb.tempDompObj.amountInput| currency:""}}
              {{instantPaymentSrb.tempDompObj.fromAccountSelect.currencySymbol}}
            </label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7">
            <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
            <a ng-href="{{instantPaymentSrb.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges"
               class="domp-form-label-resume">Check the bank charges</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-5">
          <div class="m-t-25 m-b-0">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
            <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.dateInput|
              date:'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 receiver-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabelSRB"></label>
        <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.toAccountInput}}</label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
          <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.receiversNameInput}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 p-l-0"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-15 m-b-0">
          <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
          <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.receiversAddressInput}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-25 m-b-0">
          <label class="domp-form-label-review"
                 translate="domesticPayment.receiversPostalCodeAndCityInputLabel"></label>
          <label class="domp-form-label-resume">{{instantPaymentSrb.tempDompObj.receiversPostalCodeAndCityInput}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 purpose-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 no-padding-on-phone p-l-0">
        <div class="col-lg-4 col-md-4 col-sm-6 p-r-0">
          <div class="form-group">
            <label class="domp-form-label-review" translate="instantPayment.reimbursementCode"></label>
            <label class="domp-form-label-resume">{{instantPaymentSrb.CancelationData.cancelationReasonCode}}</label>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8">
          <div class="form-group">
            <label class="domp-form-label-review" translate="instantPayment.reimbursementReason"></label>
            <label class="domp-form-label-resume">{{instantPaymentSrb.CancelationData.cancelationReasonDescription}}</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone p-l-0">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="instantPayment.modelAndReference">Reference number</label>
          <label class="domp-form-label-resume" ng-if="instantPaymentSrb.tempDompObj.referenceTo != '00'">{{instantPaymentSrb.tempDompObj.referenceModelSelect}} {{instantPaymentSrb.tempDompObj.referenceNumberInput}}</label>
          <label class="domp-form-label-resume" ng-if="!instantPaymentSrb.tempDompObj.referenceTo">-</label>
        </div>
      </div>
    </div>
    <div class="row" ng-if="instantPaymentSrb.tempDompObj.isUrgentPaymentCheckbox == true">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <i class="zmdi zmdi-check urgent-label"></i>
        <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
      </div>
    </div>
  </div>

  <div ng-if="instantPayment.location != 'hr'" class="domp-footer-wrapper">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0">
      <button class="btn btn-primary" ng-click="instantPaymentSrb.editPayment();" translate="domesticPayment.edit">
        Edit
      </button>
      <button class="btn btn-primary-warning " ng-click="instantPaymentSrb.deleteCancelation();"
              translate="domesticPayment.delete">Delete
      </button>

      <button class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default pull-right"
              data-ui-sref="payments.sign-instant({paymentId: instantPaymentSrb.CancelationData.paymentId, cancelationId: instantPaymentSrb.CancelationData.id, cancelation: instantPaymentSrb.CancelationData})"
              translate="domesticPayment.sign">Sign
      </button>

    </div>
  </div>
</div>
