<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <h4>{{cashWithdrawal.updateResponse.orderType.description}}</h4>
    </div>
  </div>
</div>
<div class="cash-withdrawing-section-frame">
  <div class="row">
    <div class="col-md-6">
      <label class="domp-form-label-review" translate="cashWithdrawalTranslate.fromAccount">From account</label>
      <label class="domp-form-label-resume">
        <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="domp-form-label-resume">
          <span ng-switch-default>{{cashWithdrawal.updateResponse.iban}}</span>
          <span ng-switch-when="sl">
            {{cashWithdrawal.formatIban(cashWithdrawal.updateResponse.iban)}}
          </span>
        </div>
      </label>
    </div>
    <div class="col-md-6">
      <!-- START Amount -->
      <div class="form-group">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.amount">Amount</label>
        <div class="domp-form-label-resume">
          <span class="p-r-10">{{cashWithdrawal.updateResponse.amount | currency:""}} </span>{{cashWithdrawal.updateResponse.currencySymbol}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- START Amount and Date -->
    <div class="col-md-offset-6 col-md-3">
      <!-- END Amount-->
      <!-- START Date-->
      <div class="form-group">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.dateOfPayout">Date of payout</label>
        <div class="domp-form-label-resume">
          {{cashWithdrawal.updateResponse.withdrawalDate | date: 'shortDate' : '+0200'}}
        </div>
      </div>
      <!-- END Date-->
    </div>
    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-md-3">
      <!-- END Amount-->
      <!-- START Date-->
      <div class="form-group">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.timeOfPayout">Time of payout</label>
        <div class="domp-form-label-resume">
          {{cashWithdrawal.updateResponse.withdrawalTime}}
        </div>
      </div>
      <!-- END Date-->
    </div>
  </div>

</div>
<div class="cash-withdrawing-section-frame">
  <div class="row">
    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" class="col-md-6">
      <div class="form-group">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.phoneNumber">Phone number</label>
        <div class="domp-form-label-resume">
          {{cashWithdrawal.updateResponse.phoneNumber}}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.placeOfCashTakeover">
          Place of cash takeover
        </label>
        <div class="domp-form-label-resume">
          {{cashWithdrawal.updateResponse.branchName}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
        <label class="domp-form-label-review" translate="cashWithdrawalTranslate.note">Note</label>
        <div class="domp-form-label-resume word-break" ng-switch-default>
          {{cashWithdrawal.updateResponse.note}}
        </div>
        <p class="domp-form-label-resume word-break" ng-switch-when="sl">
          {{cashWithdrawal.updateResponse.withdrawalNotes}}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <!--<button class="btn btn-primary app-btn-blue domp-review-and-submit-btn"-->
      <!--ng-click="cashWithdrawal.cancelCashWithdrawing()">-->
      <!--<span translate="cashWithdrawalTranslate.cancelBtnLabel">Cancel</span>-->
      <!--</button>-->
      <button ng-if="cashWithdrawal.countryCode != 'sl'" class="btn btn-primary-warning"
              ng-click="cashWithdrawal.deleteCashWithdrawing()"
              ng-disabled="cashWithdrawal.deleteCashWithdrawingBtnDisabled">Delete
      </button>
      <button ng-if="cashWithdrawal.countryCode != 'sl'" class="btn btn-primary app-btn-blue domp-review-and-submit-btn"
              ng-click="cashWithdrawal.editCashWithdrawing()">Edit
      </button>
      <button ng-if="cashWithdrawal.countryCode === 'sl'" class="btn btn-primary app-btn-blue domp-review-and-submit-btn"
              ng-click="cashWithdrawal.editCashWithdrawing()" translate="domesticPayment.back">Edit
      </button>
      <button class="btn btn-default pull-right"
              ng-click="cashWithdrawal.sendCashWithdrawing()"
              ng-disabled="cashWithdrawal.signCashWithdrawingBtnDisabled" translate="domesticPayment.confirmBtn">
        Send
      </button>
    </div>
  </div>
</div>
