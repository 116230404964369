(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:ImagesService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('CampaignOfferService', CampaignOfferService);

  function CampaignOfferService(EndpointsService, $q, $http) {
    var CampaignOfferServiceBase = {};

    CampaignOfferServiceBase.getOffer = function () {
      var deferred = $q.defer();
      var params = {
        Language: 'ENG'
      };
      var results = [];
      EndpointsService.getLinks().then(function (response) {
        if (response.links.campaignOffer) {
          // {params: params}
          $http.get(response.links.campaignOffer, {params: params})
            .then(function (data) {
              results.push(data.data);
              $http.get(response.links.campaignOffer)
                .then(function (data) {
                  results.push(data.data);
                    deferred.resolve(results);
                }).catch(function (msg) {
                  deferred.resolve(msg);
                });
        }).catch(function (msg) {
          deferred.resolve(msg);
        });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    CampaignOfferServiceBase.putCampaignResponse = function (campaign, buttonClicked) {
       var deferred = $q.defer();
       var button1 = false;
       var button2 = false;
       var button3 = false;
       if (buttonClicked === 'button1') button1 = true;
       if (buttonClicked === 'button2') button2 = true;
       if (buttonClicked === 'button3') button3 = true;

       var params = {
         campaignCode: campaign.campaignCode,
         responseId: campaign.id,
         button1Clicked: button1,
         button2Clicked: button2,
         button3Clicked: button3,
         close: false
       };
       EndpointsService.getLinks().then(function (response) {
         $http.put(response.links.campaignOffer_id_createResponse.replace('{id}', campaign.id), params)
             .then(function (data) {
               deferred.resolve(data.data);
             }).catch(function (msg) {
               deferred.reject(msg.data);
             });
         },
         function (error) {
           deferred.reject(error.data);
         });
       return deferred.promise;
     };
    return CampaignOfferServiceBase;
  }
}());
