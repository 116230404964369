angular
  .module('payments')
  .controller('DocumentationPaymentMneCtrl',
    ['$rootScope',
      '$scope',
      '$http',
      '$translate',
      '$log',
      '$state',
      'PaymentsService',
      'HelperService',
      'GoogleTagManagerHelperService',
      function ($rootScope, $scope, $http, $translate, $log, $state, PaymentsService, HelperService, GoogleTagManagerHelperService, NotificationService) {
        var vm = this;
        vm.ctrlName = 'DocumentationPaymentMneCtrl';
        vm.location = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.selectedFiles = [];
        vm.file = null;
        vm.fileObject = null;
        vm.fileName = '';
        vm.resultList = [];
        vm.showErrors = false;
        vm.paymentId = $state.router.globals.params.paymentId;
        vm.signedAllowed = null;
        vm.payment = null;
        vm.currentPage = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 5;

        vm.setPage = function (pageNo) {
          vm.currentPage = pageNo;
        };

        vm.pageChanged = function () {
          loadDocuments(vm.currentPage);
        };

        vm.init = function () {
          loadDocuments(1);
          PaymentsService.getById(vm.paymentId).then(function (payment) {
            vm.payment = payment;
            var item = payment.allowedActionList.itemList.filter(function (itemFound) {
              return itemFound.methodName === 'SignAllowed';
            })[0];
            vm.signedAllowed = item.isEnabled;
          }).catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
        };

        vm.init();

        vm.changeItemsPerPage = function (itemsPerPage) {
          vm.itemsPerPage = itemsPerPage;
          loadDocuments(vm.currentPage);

        }

        vm.confirmFile = function () {
          var formData = new FormData();
          formData.append('file', vm.fileObject, vm.fileName);
          formData.append('paymentId', vm.paymentId);
          PaymentsService.uploadDocument(formData).then(function () {
            loadDocuments(vm.currentPage);
            vm.file = null;
          }).catch(function (error) {
            vm.file=null;
            NotificationService.showMessage(error, 'error');
          });
        };


        function loadDocuments(pagenumber) {
          var params = {
            orderBy: 'creationDate',
            paymentId: vm.paymentId,
            pageSize: vm.itemsPerPage,
            page: pagenumber - 1,
            countDatabaseRecords: true
          };
          PaymentsService.getUploadedDocuments(params).then(function (response) {
            vm.files = response.result;
            vm.totalItems = response.count;
          }).catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });

        }

        vm.cancelFile = function () {
          vm.file = null;
        };

        vm.downloadFile = function (id) {
          PaymentsService.downloadDocument(id).then(function (response) {
            var nonBlockingWindow = window.open('', '_blank');
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = response.url;
          }).catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
        };

        vm.deleteFile = function (id) {
          PaymentsService.deleteDocument(id).then(function () {
            loadDocuments(vm.currentPage);
          }).catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
        };

        vm.signPayment = function (payment) {
          GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant('paymentActions.sign'));

          if (PaymentsService.isVerificationRequiredForSign(payment)) {
            // If verification is required, go to payment sign state
            $state.go('payments.sign', {paymentId: payment.id});
          } else {
            var updateObject = _.find(payment.allowedActionList.itemList, function (item) {
              return item.methodName === 'UpdateOldPaymentDateAllowed';
            });

            if (updateObject) {
              vm.dueDateUpdateAllowed = updateObject.isEnabled;
            }

            // Use only date without hours for compare
            var currentTime = new Date();
            currentTime.setHours(0, 0, 0, 0);
            // Check if all payments have valid due date
            var isPaymentDueDateValid = new Date(payment.dueDate) >= currentTime;


            // If verification is not required, then sign payment and go to final step
            var params = {
              id: payment.id
            };

            if (isPaymentDueDateValid) {
              return PaymentsService.signPayment(params).then(function (signedPayment) {
                // TODO: Use signed payment on response
                $state.go('payments.finalStep', {paymentId: payment.id});
              }).catch(function (error) {
                $log.error(error);
                NotificationService.showMessage(error, 'error');
              });
            } else {
              PaymentsService.openUpdatePaymentDueDateModal()
                .result.then(function (result) {
                  if (result === 'confirm') {
                  params.useCurrentDate = true;
                  return PaymentsService.signPayment(params).then(function (signedPayment) {
                    // TODO: Use signed payment on response
                    $state.go('payments.finalStep', {paymentId: payment.id});
                  }).catch(function (error) {
                    $log.error(error);
                    NotificationService.showMessage(error, 'error');
                  });
                }
                });
            }
          }
        };
      }]);
