<section id="main">
  <div class="container-fluid">

    <!--HEADER START-->
    <viber-header></viber-header>
    <!--HEADER END-->

    <div class="row p-t-15">
      <div class="col-xs-12">
        <div class="image-steps">

          <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'mne'" src="img/hypo/viber/Koraci_aktivacije.png" alt=""/>
          <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne'" src="img/hypo/viber/Koraci_aktivacije-hr.png" alt=""/>

          <div class="text-in-picture text-in-picture-1" ng-class="{'cro' : APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne'}">
            <div class="col-xs-3 col-sm-2 viber-bullet">1</div>
            <!--<div class="col-xs-8 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' ? 'viber.step1hr' : 'viber.step1'}}"></div>-->
            <div class="col-xs-8 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'bh'" translate="{{'viber.step1oth'}}"></div>
            <div class="col-xs-8 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh'" translate="{{APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka' ? 'viber.step1othBL' : 'viber.step1othSA'}}"></div>
            <div class="col-xs-8 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'mne'" translate="{{'viber.step1'}}"></div>
          </div>

          <div class="text-in-picture text-in-picture-2" ng-class="{'cro' : APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne'}">
            <div class="col-xs-3 col-sm-2 viber-bullet">2</div>
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' ? 'viber.step2hr' : 'viber.step2'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' ? 'viber.step2sr' : 'viber.step2'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sr' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" translate="{{'viber.step2'}}"></div>-->
            <div class="col-xs-9 col-sm-10" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne' ? 'viber.step2oth' : 'viber.step2'}}"></div>
          </div>

          <div class="text-in-picture text-in-picture-3" ng-class="{'cro' : APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne'}">
            <div class="col-xs-3 col-sm-2 viber-bullet">3</div>
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' ? 'viber.step3hr' : 'viber.step3'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' ? 'viber.step3sr' : 'viber.step3'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sr' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" translate="{{'viber.step3'}}"></div>-->
            <div class="col-xs-9 col-sm-10" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne' ? 'viber.step3oth' : 'viber.step3'}}"></div>
          </div>

          <div class="text-in-picture text-in-picture-4" ng-class="{'cro' : APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne'}">
            <div class="col-xs-3 col-sm-2 viber-bullet">4</div>
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' ? 'viber.step4hr' : 'viber.step4'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr'" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' ? 'viber.step4sr' : 'viber.step4'}}"></div>-->
            <!--<div class="col-xs-9 col-sm-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sr' && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" translate="{{'viber.step4'}}"></div>-->
            <div class="col-xs-9 col-sm-10" translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'mne' ? 'viber.step4oth' : 'viber.step4'}}"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-t-30">
      <div ng-class="{'col-xs-offset-2 col-xs-5' : APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr',
      'col-xs-offset-3 col-xs-4': APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'}">
        <button class="btn btn-default" translate="viber.becomeFollower" ng-click="viberActivateNew.becomeFollower();"></button>
      </div>

      <div class="col-xs-3 t-a-c">
        <button class="btn btn-default" translate="viber.activate" ng-click="viberActivateNew.activate();"></button>
      </div>
    </div>
  </div>
</section>



