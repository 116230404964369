(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            dashboard: {
                "dashboardHeader": "Dashboard",
                "addNewWidget1": "Add new",
                "addNewWidget2": "Widget",
                "pendingPayments": "Pending payments",
                "show": "Show",
                "hide": "Hide",
                "domesticPaymentBtn1": "Universal payment",
                "domesticPaymentBtn2": "order",
                "transferBtn1": "Transfer to",
                "transferBtn2": "my account",
                "templateBtn1": "Payment from",
                "templateBtn2": "template",
                "quickPaymentTransfer": "Quick payment or transfer",
                "domesticPayment": "Make a Universal payment order",
                "internalTransfer": "Make an internal transfer",
                "payTemplate": "Pay template",
                "searchTemplatesPlaceholder": "Search templates",
                "showAccounts": "Show more accounts",
                "instantLoan": "Instant loan",
                "instantLoanDescription": "Did you know you can apply for an instant pre approved cash loan up to 10.000,00 RSD",
                "apply": "Apply now",
                "applyMne": "Upoznajte nas",
                "contact": "Contact us",
                "hypoClub": "HYPO Club",
                "etrade": "E-Trade",
                "branch": "Find a branch",
                "bankTimeline": "Activity across all of your accounts",
                "loadEvents": "Load more",
                "noTimelineData": "No timeline data for selected period",
                "availableBalance": "Available balance",
                "loanAmount": "Loan amount",
                "confirmation": "Confirmation",
                "complaint": "Complaint",
                "cashLoan": "Blic cash loan.",
                "approval": "Just one visit to the bank.",
                "loyaltyPointsLbl": "Addiko club",
                "loyaltyCurrentPoints": "Current points",
                "loyaltyPreviousPoints": "Previous points",
                "info": "INFO",
                "thankYou" : "Thank you",
                "thankYouMessage" : "For more information about this click on the banner",
                "tydUnsubscribemne": "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at +382 20 408 600.",
                "tydUnsubscribehr" : "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at 0800 1414  or +385 1 4898111.",
                "tydUnsubscribebl" : "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at +387 51 951 000.",
                "tydUnsubscribesa" : "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at +387 33 866 666.",
                "tydUnsubscribesl" : "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at 01/580-42-28.",
                "tydUnsubscribesr" : "If you don't want to receive direct marketing messages anymore, please tell us so by using “Messages” functionality and simply say “unsubscribe” or call us at 0800 303 303 or +381 11 222 60 00."
            },
            timelineFilter: {
                "placeholder": "Search and filtration",
                "all": "All",
                "credit": "Credit",
                "debit": "Debit",
                "others": "Others",
                "amountFrom": "Amount range from",
                "amountTo": "To",
                "reset": "Reset",
                "apply": "Apply"
            },
            header: {
                "dashboard": "Dashboard",
                "accounts": "Accounts and cards",
                "payments": "Payments",
                "orders": "Orders",
                "branches": "Branches and ATM's",
                "settings": "Settings",
                "notificationMessages": "Messages",
                "notificationMessagesPlaceholder": "Write your reply here",
                "notificationMessagesPlaceholderSubject": "Message subject",
                "notificationMessagesUnread": "Unread",
                "notificationMessagesMarkAsRead": "Mark as read",
                "notificationMessagesReply": "Reply",
                "notificationMessagesSend": "Send",
                "notificationMessagesCancelSend": "Cancel",
                "notificationMessagesViewAll": "View all messages",
                "notificationEInvoices": "New e-Inovices",
                "notificationEInvoicesDueDate": "due",
                "notificationEInvoicesPay": "Pay",
                "notificationEInvoicesViewAll": "View all eInvoices",
                "notificationPendingPayments": "Pending payments",
                "notificationPendingPaymentsViewAll": "View all payments",
                "notificationPendingPaymentsDateLabel": "for",
                "notificationRejectedPayments": "Rejected payments",
                "notificationRejectedPaymentsDateLabel": "for",
                "notificationRejectedPaymentsViewAll": "View all payments",
                "notificationSettingsLangLabel": "Choose language:",
                "notificationSettingsViewAllLabel": "View all settings",
                "signedInAs": "Signed in as",
                "lastLogin": "Last login: ",
                "logout": "Logout",
                "langEn": "English",
                "langHr": "Croatian",
                "langSr": "Serbian",
                "langMtr": "Montenegro",
                "langBa": "Local",
                "langSl": "Slovenian",
                "langDe": "German",
                "langRu": "Russian",
                "noMessages": "No messages",
                "noeInvoices": "No eInvoices",
                "noPendingPayments": "No pending payments",
                "noRejectedPayments": "No rejected payments"
            },
            footer: {
                "freeCall": "Telephone number",
                "freeCallBh": "Contact center ",
                "internationalCall": "International call",
                "email": "Send us an email",
                "notificationDepth": "In-depth analysis",
                "dearClients": "Dear clients,",
                "modalText1": "Please let us inform you on the importance of updating your personal data with the aim of continuing our successful business relations.",
                "modalText2": "Data, such as ID number and validity date of your ID documents(identity card, passport, residence permit) need to be updated in the nearest branch office after expiry in accordance with Article 7 of the General Terms of Addiko Bank d.d.",
                "modalText3": "With the aim of correct identification we will inform you about the document expiry in a letter (sent to your home address) and/or in a message (sent to you via direct banking channels).",
                "regards": "Best regards",
                "closeButton": "Close",
                "version": "Version",
                "server": "Server"
            },
            timeline: {
                msgWith: "Message with subject ",
                msgWithout: "Message without subject ",
                msgReceived: " was recieved on ",
                msgSent: " was sent on "
            },
            templateSearch: {
                favorites: "My favorite templates",
                templatesList: "List of templates"
            },
            dashboardWidgets: {
                current: "Current accounts and cards",
                loan: "Loan accounts",
                deposit: "Deposit accounts",
                savings: "Savings",
                savingsTransactions: "Savings",
                giro: "Giro accounts",
                addBtn: "Add widgets"
            },
            dashboardNotification: {
                rejected: "new rejected payments",
                einvoice: "new received eInvoices",
                einvoiceSlo: "new received eInvoices",
                group: "group payments to sign",
                goBtn: "Go to payments",
                unsigned: "unsigned payments",
                pending: "pending payments", // Currently: Only for Slovenia
                documents: "unread documents"
            },
          campaignPopUp: {
            checkBoxText: "Do not show anymore"
          }
        };

        $translateProvider
            .translations('en', data);

    }
}());
