<div class="tml-filter-container tmlf-wide" id="searchFilterExpandedDiv">
    <div id="searchBtnLeftDiv" class="inner-section left">
        <i class="icon icon-search  zmdi-hc-2x m-l-5"></i>
    </div>
    <div id="searchBtnCenterDiv" class="inner-section center">
        <input id="searchTimelineInput" type="text" class="search-input" placeholder="{{'timelineFilter.placeholder'|translate}}" ng-model="paymentsReturn.paymentsFilter.purposeFTS" ng-model-options="{ debounce: 300 }">
    </div>
    <div id="searchBtnRightDiv" class="inner-section right" ng-init="paymentsReturn.showDeleteInputBtn = false">
        <i ng-show="!paymentsReturn.showDeleteInputBtn" class="icon icon-filter  zmdi-hc-2x m-r-0" ng-click="paymentsReturn.toggleFilterSelection();"></i>
        <i ng-show="paymentsReturn.showDeleteInputBtn" class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0" ng-click="paymentsReturn.paymentsFilter.purposeFTS = ''; paymentsReturn.showDeleteInputBtn = false"></i>
    </div>
    <div class="tml-filter-selection" ng-class="{'opened': paymentsReturn.searchFilterExpanded}">
        <div class="m-t-15" style="overflow:auto">
            <div class="col-xs-4 p-l-5 p-r-5">
                <div class="form-group">
                    <label class="f-12-s" translate="timelineFilter.amountFrom"></label>
                    <input ng-keyup="$event.keyCode == 13 && paymentsReturn.applyPaymentsFilter()" type="text" class="form-control-db" ng-model="paymentsReturn.paymentsFilter.amountGTE" amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" autocomplete="off">
                </div>
            </div>
            <div class="col-xs-4 p-l-0 p-r-5">
                <div class="form-group">
                    <label class="f-12-s" translate="timelineFilter.amountTo"></label>
                    <input ng-keyup="$event.keyCode == 13 && paymentsReturn.applyPaymentsFilter()" type="text" class="form-control-db" ng-model="paymentsReturn.paymentsFilter.amountLTE" amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" autocomplete="off">
                </div>
            </div>
            <div class="col-xs-4 p-l-0 p-r-5" style="padding-top:23px;">
                <select name="" id="" class="form-control-db" ng-options="option.symbol for option in paymentsReturn.currenciesList" ng-model="paymentsReturn.currencySelectObject">
                </select>
            </div>
        </div>
        <div class="m-b-15" style="overflow:auto">
            <div class="col-xs-6 t-a-l p-l-5">
                <button class="btn btn-primary app-btn-gray" ng-click="paymentsReturn.resetPaymentsFilter();" translate="timelineFilter.reset"></button>
            </div>
            <div class="col-xs-6 t-a-r p-r-5">
                <button class="btn btn-default app-btn-blue w-100" ng-click="paymentsReturn.applyPaymentsFilter();" translate="timelineFilter.apply"></button>
            </div>
        </div>
    </div>
</div>