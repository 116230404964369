(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name eInvoices.controller:EinvoicesArchiveCtrl
     *
     * @description
     *
     */
    angular
        .module('eInvoices')
        .controller('EInvoicesArchiveCtrl', EInvoicesArchiveCtrl);

    function EInvoicesArchiveCtrl($state, EInvoiceDocumentsService, $log, $translate, HelperService, $scope) {
        var vm = this;
        vm.goBack = goBack;

        vm.ctrlName = 'EinvoicesArchiveCtrl';

        init();


        function init() {

        }

        function goBack() {
            $state.go('eInvoices');
        }

    }
}());
