<div id="main">
  <div class="container-fluid p-t-15">
    <h2 class="m-l-0 f-28 m-t-0 m-b-0 title-style" translate="blockade.title">Block Addiko EBank and/or Addiko
      mobile</h2>
    <div ng-show="vm.steps.input" class="alert alert-info m-t-10"
         style="background: white; color: black; border: 1px solid lightgray">
      <p translate="blockade.info1"></p>
      <p translate="blockade.info2"></p>
    </div>
    <!-- ================= -->
    <!-- START: Step Input-->
    <!-- ================= -->
    <ng-form ng-show="vm.steps.input" name="vm.blockadeForm">
      <div class="domp-from-section p-15 m-t-25">
        <div class="row">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.blockadeReason">Blockade reason:</label>
            </div>
            <div class="col-md-6">
              <textarea name="blockadeReason" class="form-control" rows="5"
                        ng-model="vm.model.reason"
                        style="resize: none"
                        maxlength="255"
                        required>
              </textarea>
              <span class="c-red" ng-show="vm.formSubmitted && vm.blockadeForm.blockadeReason.$error.required">
                <span translate="blockade.errors.requiredField"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-t-20">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.blockadeOf">Blockade of:</label>
            </div>
            <div class="col-md-6">
              <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                <input name="blockWeb" type="checkbox" ng-model="vm.model.blockWeb"
                       ng-required="!vm.model.blockMobile">
                <i class="input-helper"></i>
                Addiko EBank
              </label>
              <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                <input name="blockMobile" type="checkbox" ng-model="vm.model.blockMobile"
                       ng-required="!vm.model.blockWeb">
                <i class="input-helper"></i>
                Addiko Mobile
              </label>
              <span class="c-red"
                    ng-show="vm.formSubmitted && (vm.blockadeForm.blockWeb.$error.required || vm.blockadeForm.blockMobile.$error.required)">
                    <span translate="blockade.errors.blockadeOfErr"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-t-20">
          <div class="form-group clearfix">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.timeRestrictedBlockade">
                Time-restricted blockade:
              </label>
            </div>
            <div class="col-md-6 p-0">
              <div class="col-md-12">
                <label class="pointer">
                  <input type="radio" name="optradio" ng-model="vm.model.temporary" ng-value="false">
                  <i class="input-helper"></i>
                  <span translate="alert.no"></span>
                </label>

                <p class="m-t-10" translate="blockade.timeRestrictedBlockadeText"></p>

                <label class="pointer">
                  <input type="radio" name="optradio" ng-model="vm.model.temporary" ng-value="true">
                  <i class="input-helper"></i>
                  <span translate="alert.yes"></span>
                </label>
              </div>



              <div ng-if="vm.model.temporary" class="m-t-15 clearfix">
                <!-- START Date from - datepicker -->
                <div class="col-lg-6 col-md-6 col-sm-6 m-t-10">
                  <label translate="blockade.dateFrom">Date from</label>
                  <div class="input-group ">
                    <input name="dateFrom" type="text" class="form-control input-field"
                           uib-datepicker-popup="shortDate"
                           data-ng-model="vm.model.dateFrom"
                           show-weeks="false" is-open="vm.isFromDateDPOpen"
                           datepicker-options="vm.dateFromOptions"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           ng-required="vm.model.temporary"
                           ng-change="vm.onDateFromChange()"
                           readonly>
                    <span class="input-group-btn">
                    <button ng-readonly="domesticPaymentSlo.disableAllFields"
                            data-ng-click="vm.isFromDateDPOpen = !vm.isFromDateDPOpen" type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                            style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                  </div>
                </div>
                <!-- END Date from - datepicker -->
                <!-- START Date to- datepicker -->
                <div class="col-lg-6 col-md-6 col-sm-6 m-t-10">
                  <label translate="blockade.dateTo">Date to</label>
                  <div class="input-group ">
                    <input name="dateTo" type="text" class="form-control input-field"
                           uib-datepicker-popup="shortDate"
                           data-ng-model="vm.model.dateTo"
                           show-weeks="false" is-open="vm.isToDateDPOpen"
                           datepicker-options="vm.dateToOptions"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           ng-required="vm.model.temporary"
                           readonly>
                    <span class="input-group-btn">
                    <button ng-readonly="domesticPaymentSlo.disableAllFields"
                            data-ng-click="vm.isToDateDPOpen = !vm.isToDateDPOpen" type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                            style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                  </div>
                </div>
                <!-- END Date to- datepicker -->
              </div>
              <span class="c-red"
                    ng-show="vm.formSubmitted && (vm.blockadeForm.datefrom.$error.required || vm.blockadeForm.dateTo.$error.required)">
                <span translate="blockade.errors.dateToAndFromRequired"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row  m-t-25">
        <div class="form-group clearfix">
          <div class="col-md-12">
            <button class="btn btn-primary pull-left" translate="payments.cancel" ui-sref="settings"></button>
            <button class="btn btn-default pull-right" translate="orders.next" ng-click="vm.next()"></button>
          </div>
        </div>
      </div>
    </ng-form>
    <!-- ================= -->
    <!-- END: Step Input-->
    <!-- ================= -->

    <!-- ================= -->
    <!-- START: Step Resume -->
    <!-- ================= -->
    <div ng-if="vm.steps.resume">
      <div class="domp-from-section p-15 m-t-25">

        <div class="row">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.orderDate"></label>
            </div>
            <div class="col-md-6">
              <b>{{vm.model.dateCreated | date: 'dd.MM.yyyy HH:mm:ss'}}</b>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.orderTypeLabel"></label>
            </div>
            <div class="col-md-6">
              <p>
                <b>
                  <span translate="blockade.orderTypeText"></span>
                  <span ng-if="vm.model.blockWeb">Addiko EBank</span>
                  <span ng-if="vm.model.blockWeb && vm.model.blockMobile" translate="blockade.and"></span>
                  <span ng-if="vm.model.blockMobile">Addiko Mobile</span>
                </b>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.blockadeTypeLabel"></label>
            </div>
            <div class="col-md-6">
              <p ng-if="vm.model.temporary">
                <b>
                  <span translate="blockade.blockadeTypeText"></span>
                  <span> {{vm.model.dateFrom | date: 'shortDate' : '+0200'}}</span>
                  <span translate="blockade.and"></span>
                  <span> {{vm.model.dateTo | date: 'shortDate' : '+0200'}}</span>
                </b>
              </p>
              <p ng-if="!vm.model.temporary">
                <b>
                  <span translate="blockade.blockadeUnrestricted"></span>
                </b>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-md-3">
              <label class="domp-form-label" translate="blockade.blockadeReason"></label>
            </div>
            <div class="col-md-6">
              <p style="word-wrap: break-word;">
                <b>{{vm.model.reason}}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row  m-t-25">
        <div class="form-group clearfix">
          <div class="col-md-12">
            <button class="btn btn-primary pull-left" translate="domesticPayment.back"
                    ng-click="vm.steps.resume = false; vm.steps.input = true;"></button>
            <button class="btn btn-default pull-right" translate="termDeposit.confirmBtnLabel"
                    ng-click="vm.confirm()"></button>
          </div>
        </div>
      </div>
    </div>
    <!-- ================= -->
    <!-- END: Step Resume -->
    <!-- ================= -->
    <!-- ================= -->
    <!-- START: Step Finish -->
    <!-- ================= -->
    <div ng-if="vm.steps.finish" class="m-t-25">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-10 m-t-30 m-b-30">
            <h2 class="c-white f-28" translate="blockade.blockadeFinishedTitle"></h2>
            <p ng-if="vm.model.temporary">
              <span translate="blockade.blockadeFinishedMsg1"></span>
              <span> {{vm.model.dateFrom | date: 'shortDate' : '+0200'}}</span>
              <span translate="blockade.and"></span>
              <span> {{vm.model.dateTo | date: 'shortDate' : '+0200'}}</span>
              <span translate="blockade.blockadeFinishedMsg2"></span>
              <span ng-if="vm.model.blockWeb">Addiko EBank</span>
              <span ng-if="vm.model.blockWeb && vm.model.blockMobile" translate="blockade.and"></span>
              <span ng-if="vm.model.blockMobile">Addiko Mobile</span>
              <span translate="blockade.blockadeFinishedMsg3"></span>
            </p>
          </div>
        </div>
      </div>
      <!--<div class="row">-->
      <!--<div class="col-md-4">-->
      <!--<a href="" data-ng-click="termDeposit.newTermDeposit()">-->
      <!--<span class="money-exchange-icon"><i class="icon icon-einvoice zmdi-hc-2x"></i></span>-->
      <!--<span class="f-18 money-exchange-text" translate="termDeposit.newDepositOrder"></span>-->
      <!--</a>-->
      <!--</div>-->
      <!--</div>-->
    </div>
    <!-- ================= -->
    <!-- END: Step Finish -->
    <!-- ================= -->
  </div>
</div>
