(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsCtrl', PaymentsCtrl);

  function PaymentsCtrl($rootScope, $log, $state, CTLocalizationService, PaymentTemplatesService,
    $scope, $uibModal, EInvoiceDocumentsService, PaymentsService, $translate, HelperService, $localStorage, ConfigEndpointService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'PaymentsCtrl';
    vm.templatesFilter = {
      page: 0,
      pageSize: 10
    };
    vm.templateList = {};
    vm.eInvoiceDocumentsList = {};
    vm.pendingPaymentsList = {};
    vm.rejectedPaymentsList = {};
    vm.busy = false;
    vm.templateAlphabetically = false;
    vm.topUsed = false;
    vm.eInvoiceDocumentsFilter = {
      page: 0,
      orderBy: "valueDate DESC",
      pageSize: 10
    };
    vm.rejectedPaymentsFilter = {
      page: 0,
      pageSize: 5
    };
    vm.pendingPaymentsFilter = {
      page: 0,
      pageSize: 5
    };

    $translate(['core.period.today', 'core.period.yesterday', 'core.period.thisWeek', 'core.period.thisMonth', 'core.period.thisYear', 'core.period.all']).then(function (translations) {

      vm.periodList = [
        {
          item: {
            key: 'today',
            description: translations['core.period.today']
          }
        }
        , {
          item: {
            key: 'yesterday',
            description: translations['core.period.yesterday']
          }
        }
        , {
          item: {
            key: 'thisWeek',
            description: translations['core.period.thisWeek']
          }
        }
        , {
          item: {
            key: 'thisMonth',
            description: translations['core.period.thisMonth']
          }
        }
        , {
          item: {
            key: 'thisYear',
            description: translations['core.period.thisYear']
          }
        }
        , {
          item: {
            key: 'all',
            description: translations['core.period.all']
          }
        }
      ];

      vm.rejectedPeriod = vm.periodList[5];
    });

    vm.countryCode = $localStorage.code;

    vm.getDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
        if (response.APILocation) {
          vm.countryCode = $localStorage.code;
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.init = function () {
      vm.getPaymentTemplatesPage(true);
      vm.getPendingPayments(true);
      vm.getRejectedPayments(true);

      if (vm.countryCode == undefined) {
        vm.getDefaultData()
          .then(function () {
            if (vm.countryCode != 'sr' && vm.countryCode != undefined) {
              vm.getEInvoiceDocumentsPage(true);
            }
          })
      } if (vm.countryCode != undefined && vm.countryCode != 'sr') {
        vm.getEInvoiceDocumentsPage(true);
      }
    };


    vm.getPaymentTemplatesPage = function (reset) {
      if (vm.busy)
        return;
      vm.busy = true;
      if (reset) {
        vm.templatesFilter.page = 0;
      } else {
        vm.templatesFilter.page += 1;
      }
      if (vm.templateList.hasMore || vm.templatesFilter.page === 0) {
        PaymentTemplatesService.getPage(vm.templatesFilter)
          .then(function (response) {
            if (reset) {
              vm.templateList = response;
            } else {
              vm.templateList.hasMore = response.hasMore;
              vm.templateList.result = vm.templateList.result.concat(response.result);
            }
            vm.busy = false;
            $log.debug(vm.templateList);
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };

    vm.getTemplatesAlphabetically = function () {
      vm.templateAlphabetically = !vm.templateAlphabetically;
      vm.topUsed = false;
      if (vm.templateAlphabetically) {
        vm.templatesFilter.orderBy = 'name ASC';
      } else {
        delete vm.templatesFilter.orderBy;
      }
      vm.getPaymentTemplatesPage(true);
    };

    vm.getTopUsedTemplates = function () {
      vm.topUsed = !vm.topUsed;
      vm.templateAlphabetically = false;
      $log.debug('Not implemented.');
    };

    vm.loadMoreTemplates = function () {
      vm.getPaymentTemplatesPage(false);
    };

    vm.loadMoreEInvoices = function () {
      vm.getEInvoiceDocumentsPage(false);
    };

    vm.loadMoreRejectedPayments = function () {
      vm.getRejectedPayments(false);
    };

    vm.loadMorePendingPayments = function () {
      vm.getPendingPayments(false);
    };

    vm.openTemplateModal = function (paymentTemplate) {
      var clone = _.clone(paymentTemplate, true);
      PaymentTemplatesService.openModal(clone);
    };

    vm.getEInvoiceDocumentsPage = function (reset) {
      if (reset) {
        vm.eInvoiceDocumentsFilter.page = 0;
      } else {
        vm.eInvoiceDocumentsFilter.page += 1;
      }
      if (vm.templateList.hasMore || vm.templatesFilter.page === 0) {
        EInvoiceDocumentsService.getPage(vm.eInvoiceDocumentsFilter)
          .then(function (response) {
            if (reset) {
              vm.eInvoiceDocumentsList = response;
            } else {
              vm.eInvoiceDocumentsList.hasMore = response.hasMore;
              vm.eInvoiceDocumentsList.result = vm.eInvoiceDocumentsList.result.concat(response.result);
            }
            $log.debug(vm.eInvoiceDocumentsList);
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };

    vm.openEInvoicesModal = function (eInvoice) {
      EInvoiceDocumentsService.openModal(eInvoice);
    };

    vm.getPendingPayments = function (reset) {
      if (reset) {
        vm.pendingPaymentsFilter.page = 0;
      } else {
        vm.pendingPaymentsFilter.page += 1;
      }
      if (vm.pendingPaymentsList.hasMore || vm.pendingPaymentsFilter.page === 0) {
        PaymentsService.getPendingPayments(vm.pendingPaymentsFilter)
          .then(function (response) {
            if (reset) {
              vm.pendingPaymentsList = response;
            } else {
              vm.pendingPaymentsList.hasMore = response.hasMore;
              vm.pendingPaymentsList.result = vm.pendingPaymentsList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };

    vm.getRejectedPayments = function (reset) {
      if (reset) {
        vm.rejectedPaymentsFilter.page = 0;
      } else {
        vm.rejectedPaymentsFilter.page += 1;
      }
      if (vm.rejectedPaymentsList.hasMore || vm.rejectedPaymentsFilter.page === 0) {
        PaymentsService.getRejectedPayments(vm.rejectedPaymentsFilter)
          .then(function (response) {
            if (reset) {
              vm.rejectedPaymentsList = response;
            } else {
              vm.rejectedPaymentsList.hasMore = response.hasMore;
              vm.rejectedPaymentsList.result = vm.rejectedPaymentsList.result.concat(response.result);
            }
          })
          .catch(function (error) {
            $log.error(error);
          });
      }
    };

    vm.rejectedPaymentsTimeFilter = function (item) {
      vm.rejectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.rejectedPeriod.item.key);
      vm.rejectedPaymentsFilter.valueDateGTE = timeRange.valueDateGTE;
      vm.rejectedPaymentsFilter.valueDateLTE = timeRange.valueDateLTE;
      vm.getRejectedPayments(true);
    };

    vm.deleteTemplate = function (id) {
      PaymentTemplatesService.deleteTemplate(id)
        .then(function (response) {
          swal('Success!', 'Payment template deleted succesfully!');
          vm.getPaymentTemplatesPage(true);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.editTemplate = function (paymentTemplate) {
      var clone = _.clone(paymentTemplate, true);
      clone.forEdit = true;
      PaymentTemplatesService.openModal(clone);
    };

    vm.signPayment = function (paymentId) {
      $log.debug("Sign payment id: ", paymentId);
    };

    vm.deletePayment = function (paymentId) {
      $log.debug("Delete payment id: ", paymentId);
    };

    vm.recallPayment = function (paymentId) {
      $log.debug('Recall payment id: ', paymentId);
    };

    vm.init();
  }
}());
