(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name payments.factory:PurposeCodesService
     *
     * @description
     *
     */
    angular
        .module('payments')
        .factory('PurposeCodesService', PurposeCodesService);

    function PurposeCodesService(EndpointsService, $q, $http, $log) {
        var PurposeCodesServiceBase = {};
        PurposeCodesServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                //$log.debug("Params:", params);
                $http.get(response.links.purposeCodes, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

      PurposeCodesServiceBase.getPaymentBasis = function (params) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          //$log.debug("Params:", params);
          $http.get(response.links.paymentBasis, {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };

        PurposeCodesServiceBase.getById = function (id) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.purposeCodes_id.replace('{id}', id))
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
        return PurposeCodesServiceBase;
    }
}());
