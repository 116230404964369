<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="masterCard.header">Change overdraft limit</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <!-- START Note -->
    <div ng-if="masterCard.step == 1" class="row">
      <div class="col-md-12">
        <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
          <div class="row">
            <div class="col-xs-12"></div>
            <div class="row">
              <div class="col-xs-12">
                <span translate="masterCard.info"></span>
                <span><a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.BankChargesLink}}" target="_blank"
                         translate="masterCard.infoPrice" class="underline"></a></span>
              </div>
            </div>
          </div>
          <div class="row p-t-20">
            <div class="col-xs-6 p-0"><a href="https://www.addiko.si/obcani/kartice/addiko-obrocna-mastercard-kartica" target="_blank"
                                         class="underline">{{ 'masterCard.infoLink0' | translate}}</a></div>
            
            <div class="col-xs-6 p-0"><a href="https://www.addiko.si/obcani/kartice/addiko-zlata-obrocna-kartica-mastercard/" target="_blank"
                                         class="underline">{{ 'masterCard.infoLink2' | translate}}</a></div>
          </div>
        </div>
      </div>
    </div>
    <!-- END Note -->

    <div ng-if="masterCard.step == 1" class="p-b-30">
      <ng-form name="masterCard.masterCardForm">
        <div class="cash-withdrawing-section-frame">
          <div class="row p-t-30">

            <div class="col-md-6">
              <div class="form-group">
                <label class="domp-form-label" translate="masterCard.cardType"></label>
                <select name="cardType"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in masterCard.cardTypes"
                        ng-model="masterCard.tempObj.cardType">
                </select>

              </div>
            </div>


          </div>
          <div class="row">
            <div class="col-md-6">

              <label class="domp-form-label" translate="masterCard.account">Account</label>
              <!-- START Dropdown account -->
              <div class="accounts-dropdown">
                <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                     data-ng-click="masterCard.showAccountToDropdown = !masterCard.showAccountToDropdown"
                     ng-style="masterCard.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + masterCard.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                      {{masterCard.selectedAccount.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                </span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{masterCard.selectedAccount.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{masterCard.selectedAccount.availableBalance | number:2}}
                    {{masterCard.selectedAccount.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  <span class="caret-icon">
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
              </span>
                </div>
                <div class="from-accounts-list m-l-15" style="z-index:12343" ng-show="masterCard.showAccountToDropdown"
                     scrollable>
                  <div class="accounts-list-item p-0"
                       data-ng-repeat="account in masterCard.accountList"
                       data-ng-click="masterCard.selectAccount(account); masterCard.showAccountToDropdown = !masterCard.showAccountToDropdown">
                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                         ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{account.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                            ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{account.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{account.availableBalance | number:2}} {{account.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END Dropdown account -->

            </div>

            <div class="col-xs-6">
              <!-- START Amount -->
              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="masterCard.amount">Amount</label>
                  <div class="input-group">
                    <input id="amount" name="amount" type="text" class="form-control input-field t-a-r"
                           amount-input-mask
                           placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                           ng-model="masterCard.tempObj.amount"
                           ng-class="{'domp-error': masterCard.masterCardForm.amount.$invalid && masterCard.formSubmitted}"
                           maxlength="12"
                           autocomplete="off"
                           required>
                    <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                        style="border:1px solid #062a42;">
                  {{masterCard.selectedAccount.currencySymbol}}
                </button>
              </span>
                  </div>
                  <label class="c-red"
                         ng-show="masterCard.masterCardForm.amount.$error.required && masterCard.formSubmitted"
                         translate="cashWithdrawalTranslate.amountRequired">
                  </label>
                </div>
              </div>
              <!-- END Amount-->
              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="masterCard.paymentDay"></label>
                  <select name="paymentDay"
                          class="form-control input-field p-l-15 p-r-10"
                          ng-options="option.id for option in masterCard.paymentDays "
                          ng-model="masterCard.tempObj.paymentDay">
                  </select>
                </div>
              </div>

            </div>

            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-xs-12">
              <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                <input type="checkbox" ng-model="masterCard.iAmAware">
                <i class="input-helper"></i>
                {{'cashWithdrawalTranslate.info' | translate}}
              </label>
            </div>

          </div>


          <div class="row p-b-20 p-t-20">
            <div class="col-md-12">
              <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left"
                      data-ui-sref="accountsOverview">
                <span translate="payments.cancel">Cancel</span>
              </button>
              <button class="btn btn-default  pull-right "
                      ng-disabled="masterCard.nextButtonPressed || (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && !masterCard.iAmAware)"
                      ng-click="masterCard.validateMasterCardNew()">
                <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
              </button>
            </div>
          </div>
        </div>
      </ng-form>
    </div>

    <div ng-if="masterCard.step == 2">
      <div class="domp-to-section m-10">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="masterCard.account"></label>
              <label class="d-block domp-form-label-resume">{{masterCard.insertedOrder.account}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="masterCard.amount">Amount</label>
              <label class="d-block domp-form-label-resume">
                {{masterCard.insertedOrder.limitAmount | number:2 }}
                {{masterCard.selectedAccount.currencySymbol}}
              </label>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCard.cardType"></label>
            <label class="d-block domp-form-label-resume">{{masterCard.tempObj.cardType.description}}</label>
          </div>
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="masterCard.paymentDay"></label>
            <label class="d-block domp-form-label-resume">{{masterCard.insertedOrder.orderTearDate.id }}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button class="btn btn-primary " translate="domesticPayment.back"
                data-ng-click="masterCard.editMasterCardNew();">
        </button>
        <button class="btn btn-default pull-right" data-ng-click="masterCard.confirmMasterCardNew()"
                ng-disabled="masterCard.confirmButtonPressed">
          <span translate="domesticPayment.confirmBtn"></span>
        </button>
      </div>
    </div>

  </div>
</section>
