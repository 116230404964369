<table class="table table-transparent table-responsive">
  <tbody ng-include="'accounts/merchant-info-details/merchant-info-details.tpl.html'"></tbody>
  <tbody>
    <tr ng-if="tlItem.bookingType && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
    <td class="left-col">
      <span class="tridm-key-label" translate="transactions.statusTransaction"></span>
    </td>
    <td class="right-col">
      <span class="tridm-value-label" style="color: red" ng-if="tlItem.bookingType == '1'" translate="accounts.accAutorization"></span>
      <span class="tridm-value-label" style="color: green" ng-if="tlItem.bookingType == '2'" translate="accounts.accProvedena"></span>
    </td>
  </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.description"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.description}}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.amount"></span></td>
      <td>
        <span class="tlidm-value-label  outcome-tr" ng-if="tlItem.itemType=='TransactionDebit'">{{tlItem.amount | number:2}} {{tlItem.currency}}</span>
        <span class="tlidm-value-label  income-tr" ng-if="tlItem.itemType=='TransactionCredit'">{{tlItem.amount | number:2}} {{tlItem.currency}}</span>
      </td>
    </tr>
    <tr ng-if="tlItem.payeeName || tlItem.payerName">
        <td class="left-col" ng-if="tlItem.itemType=='TransactionCredit'"><span class="tridm-key-label" translate="timeline.creditorName"></span></td>
        <td class="left-col" ng-if="tlItem.itemType=='TransactionDebit'"><span class="tridm-key-label" translate="timeline.debitorName"></span></td>
        <td class="right-col">
          <span ng-if="dashboard.countryCode != 'sl'" class="tridm-value-label">
             <span ng-if="tlItem.payerName">{{tlItem.payerName}}</span>
             <span ng-if="tlItem.payeeName">{{tlItem.payeeName}}</span>
          </span>
          <span ng-if="dashboard.countryCode == 'sl'" class="tridm-value-label">{{tlItem.detail.payeeName}}</span></td>
    </tr>
    <tr ng-if="tlItem.payeeAddress1">
      <td class="left-col" ng-if="tlItem.itemType=='TransactionCredit'"><span class="tridm-key-label" translate="timeline.creditorAddress"></span></td>
      <td class="left-col" ng-if="tlItem.itemType=='TransactionDebit'"><span class="tridm-key-label" translate="timeline.debitorAddress"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{tlItem.payeeAddress1}}</span></td>
    </tr>

    <tr ng-if="tlItem.detail.toAccountNumber">
      <td class="left-col" ng-if="tlItem.itemType=='TransactionCredit'"><span class="tridm-key-label" translate="timeline.creditorAccount"></span></td>
      <td class="left-col" ng-if="tlItem.itemType=='TransactionDebit'"><span class="tridm-key-label" translate="timeline.debitorAccount"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{tlItem.detail.toAccountNumber}}</span></td>
    </tr>

    <!--<tr>
      <td><span class="tlidm-key-label" translate="timeline.accountNumber"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.transaction.detail.toAccountNumber}}</span>
        <span class="tlidm-value-label" ng-if="!tlItem.transaction.detail.toAccountNumber">-</span>
      </td>
    </tr>-->
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.group"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.group}}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.referenceType"></span></td>
      <td><span class="tlidm-value-label">{{'timeline.'+tlItem.referenceType | translate}}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.bookingDate"></span></td>
      <!-- <td><span class="tlidm-value-label">{{tlItemDetailsModal.getDateObjectfromDateString(tlItemDetailsModal.timelineItem.date) | date:'shortDate' : '+0200'}}</span></td> -->
      <td><span class="tlidm-value-label">{{tlItem.transaction.bookingDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
    <tr>
      <td><span class="tlidm-key-label" translate="timeline.valueDate"></span></td>
      <td><span class="tlidm-value-label">{{tlItem.transaction.valueDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
  <tr ng-if="dashboard.countryCode == 'hr' && (tlItem.group == 'Tekući račun građana' || tlItem.group == 'Žiro račun građana')">
    <td colspan="2">
      <button type="button" translate="dashboard.confirmation" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
              data-ng-click = "dashboard.downloadTransactionReport(tlItem.transaction);">
      </button>
    </td>
  </tr>
  </tbody>
</table>
