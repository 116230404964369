<section id="main">
  <div class="container">
    <div>
      <div class="exchange-rate-header">
        <div class="row">
          <div class="col-sm-6 col-xs-12 f-28" translate="instantPayment.reimbursementSignHeader"></div>

        </div>
      </div>

      <!-- Larger screens start -->

      <!-- Header for payments sign -->
      <div class="row group-sign-header p-t-20  m-t-15 hidden-xs">
        <div class="col-sm-5">
                    <span class="col-sm-6 group-sign-header" translate="payments.fromAccount">
              From account
            </span>
          <span class="col-sm-6" translate="payments.toAccount">
              To account
            </span>
        </div>
        <span class="col-sm-2" translate="instantPayment.reimbursementReason">
            Purpose
          </span>
        <span class="col-sm-2 " translate="domesticPayment.amountInputLabel">
            Amount
          </span>
        <div class="col-sm-3">
                    <span ng-if="instantPaymentSign.selectedPaymentForSign.dateCreated" class="col-sm-6"
                          translate="signPayments.dateCreated">
            Date created
            </span>
          <span class="col-sm-6" translate="signPayments.dueDate">
              Due date
            </span>
        </div>
      </div>

      <!-- Details for single payment -->
      <div class="row group-sign-content hidden-xs p-b-15 word-break">
        <div class="col-sm-5">
                    <span class="col-sm-6">
            {{instantPaymentSign.selectedPaymentForSign.fromAccountNumber}}
            </span>
          <span class="col-sm-6">
              {{instantPaymentSign.selectedPaymentForSign.toAccountNumber}}
            </span>
        </div>
        <span class="col-sm-2" title="{{payment.purpose}}">
            {{ instantPaymentSign.selectedCancelationForSign.cancelationReasonDescription | limitTo: 40 }}{{instantPaymentSign.selectedCancelationForSign.cancelationReasonDescription > 40 ? '...' : ''}}
          </span>
        <span class="col-sm-2">
            {{instantPaymentSign.selectedPaymentForSign.amount|number:2}} {{::instantPaymentSign.selectedPaymentForSign.currencySymbol}}
          </span>
        <div class="col-sm-3">
                    <span ng-if="instantPaymentSign.selectedPaymentForSign.dateCreated" class="col-sm-6">
            {{instantPaymentSign.selectedPaymentForSign.dateCreated| date:'shortDate' : '+0200'}}
            </span>
          <span class="col-sm-6">
              {{instantPaymentSign.selectedPaymentForSign.dueDate| date:'shortDate' : '+0200'}}
            </span>
        </div>
      </div>
      <!-- Larger screens end -->

      <!-- Small screens start -->
      <div class="row visible-xs sign-payment-mobile">

        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="payments.fromAccount">
              From account
            </span>
          <span class="col-xs-6">
            {{instantPaymentSign.selectedPaymentForSign.fromAccountNumber}}
            </span>
        </div>
        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="payments.toAccount">
              To account
            </span>
          <span class="col-xs-6">
              {{instantPaymentSign.selectedPaymentForSign.toAccountNumber}}
            </span>
        </div>
        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="loanPayment.purpose">
            Purpose
          </span>
          <span class="col-xs-6" title="{{payment.purpose}}">
            {{ instantPaymentSign.selectedCancelationForSign.cancelationReasonDescription | limitTo: 40 }}{{instantPaymentSign.selectedCancelationForSign.cancelationReasonDescription > 40 ? '...' : ''}}
          </span>
        </div>
        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="domesticPayment.amountInputLabel">
            Amount
          </span>
          <span class="col-xs-6">
            {{instantPaymentSign.selectedPaymentForSign.amount|number:2}} {{::instantPaymentSign.selectedPaymentForSign.currencySymbol}}
          </span>
        </div>
        <div class="col-xs-12">
                    <span ng-if="instantPaymentSign.selectedPaymentForSign.dateCreated" class="col-xs-6 f-w-bold"
                          translate="signPayments.dateCreated">
            Date created
            </span>
          <span class="col-xs-6">
              {{instantPaymentSign.selectedPaymentForSign.dateCreated| date:'shortDate' : '+0200'}}
            </span>
        </div>
        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="signPayments.dueDate">
              Due date
            </span>
          <span class="col-xs-6">
              {{instantPaymentSign.selectedPaymentForSign.dueDate| date:'shortDate' : '+0200'}}
            </span>
        </div>
      </div>
      <!-- Small screens end -->

      <!-- Actions for single payment -->
      <div class="row m-b-10">
        <span class="col-xs-12 m-t-20" ng-show="!instantPaymentSign.isPaymentDueDateValid"
              translate="signPayments.singlePaymentInvalidDueDate"></span>
        <span class="col-xs-12 payments-filter-active"
              ng-show="instantPaymentSign.dueDateUpdateAllowed && !instantPaymentSign.isPaymentDueDateValid"
              data-ng-click="instantPaymentSign.updateSinglePaymentDate()"
              translate="signPayments.updatePaymentDueDate">
          </span>

        <!-- SMS Validation -->
        <div class="col-xs-12 m-t-30">
          <!--<form id="nform1" autocomplete="off" novalidate>-->
          <div class="col-xs-12 m-b-20">
              <span ng-if="instantPaymentSign.countryCode != 'bh' && instantPaymentSign.countryCode != 'sl'"
                    class="f-w-bold">
                {{'signPayments.SmsDescription' | translate}}
              </span>
          </div>
          <div class="row p-10">
            <div ng-if="instantPaymentSign.countryCode === 'sr'" class="col-xs-12 m-5">
              <p><span translate="signPayments.infoDomSrb0"></span>
                <span><a href="{{'signPayments.infoDomSrbLink' | translate}}"
                         translate="signPayments.infoDomSrb1"></a></span></p>
            </div>
          </div>
          <div class="col-xs-6 col-sm-4">
            <div class="form-group">
              <span class="f-w-bold">{{'signPayments.SmsCode' | translate}}</span>
              <div class="input-group">
                <input id="nedim1" name="nedim1" type="text"
                       class="form-control input-field text-uppercase"
                       data-ng-model="instantPaymentSign.smsPasswordInput"
                       placeholder="{{'currencyConversion.smsPassword' | translate}}"
                       autocomplete="off"
                       auto-focus>
                <span class="input-group-btn">
                    <!--<button id="smsInput" class="btn btn-default input-group-app-btn domp-btn-height"-->
                  <!--data-ng-click="paymentsSign.toggleInputVisibility()">-->
                  <!--<i data-ng-show="paymentsSign.showInput" class="zmdi zmdi-lock"></i>-->
                  <!--<i data-ng-show="!paymentsSign.showInput" class="zmdi zmdi-lock-open"></i>-->
                  <!--</button>-->
                  </span>
                <span class="input-group-btn" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                    <button class="btn btn-default input-group-app-btn domp-btn-height"
                            data-ng-click="instantPaymentSign.sendSMS()"
                            ng-disabled="instantPaymentSign.signButtonDisabled"
                            uib-tooltip="{{'signPayments.resendSms' | translate}}">
                      <i class="zmdi zmdi-refresh-sync"></i>
                    </button>
                  </span>
                <span class="input-group-btn"
                      ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.additionalTransactionConfirmation.id == 1">
                    <button class="btn btn-default input-group-app-btn domp-btn-height"
                            data-ng-click="instantPaymentSign.requestSMSCodeForSlo()"
                            ng-disabled="instantPaymentSign.resendSMSInProgress || instantPaymentSign.repeatSmsCounter == 3"
                            uib-tooltip="{{'signPayments.resendSmsSlo' | translate}}" tooltip-class="tooltip-fullwidth">
                      <i class="zmdi zmdi-refresh-sync"></i>
                    </button>
                  </span>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-sm-offset-4  m-t-20">
            <!-- TODO: Refactor ng-disabled statement -->
            <button id="signConfirm" class="btn btn-default pull-right"
                    data-ng-click="instantPaymentSign.signSinglePayment()"
                    ng-disabled="instantPaymentSign.formSubmitted ||!instantPaymentSign.isPaymentDueDateValid || instantPaymentSign.smsPasswordInput === ''"
                    translate="signPayments.confirm"></button>
          </div>
          <!--</form>-->
        </div>

      </div>
    </div>
  </div>
</section>
