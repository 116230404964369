<div class="col-sm-12 col-md-6 m-b-15" data-ng-click="marketingInfoBanner.onBannerClicked($event);">  <!--data-ng-click="marketingInfoBanner.checkViber();">-->
  <div ng-if="marketingInfoBanner.countryCode != 'hr' && marketingInfoBanner.countryCode != 'sl'" class="banner-image-url cursor-pointer response-img"
       style="height: 161px;"
       ng-style="{'background-image': 'url(' + marketingInfoBanner.bannerImageUrl + ')'}">
  </div>

  <div ng-if="marketingInfoBanner.countryCode == 'hr' || marketingInfoBanner.countryCode == 'sl'" class="banner-image-url"
       ng-style="{'background-image': 'url(' + marketingInfoBanner.bannerImageUrl + ')'}">
    <div class="t-a-l" style="position:absolute; bottom: 0px; top:0px; left: 35px;">
      <h3 class="c-white custom-underline f-20" ng-if="marketingInfoBanner.currentLang == 'hr'" style="">
        {{marketingInfoBanner.MarketingInfo.text1}}</h3>
      <h3 class="c-white custom-underline f-20" ng-if="marketingInfoBanner.currentLang == 'en'" style="">
        {{marketingInfoBanner.MarketingInfo.textEng1}}</h3>
    </div>

    <div class="t-a-l" style="position:absolute; bottom: 0px; top:40px; left: 35px;">
      <h3 class="c-white fw-cl f-20" ng-if="marketingInfoBanner.currentLang == 'hr' || marketingInfoBanner.currentLang == 'sl'" style="margin-top: 15px;">
        {{marketingInfoBanner.MarketingInfo.text2}}</h3>
      <h3 class="c-white fw-cl f-20" ng-if="marketingInfoBanner.currentLang == 'en'" style="margin-top: 15px;">
        {{marketingInfoBanner.MarketingInfo.textEng2}}</h3>
      <h3 class="c-white fw-cl pull-left m-0 f-20" ng-if="(marketingInfoBanner.currentLang == 'hr' || marketingInfoBanner.currentLang == 'sl') && marketingInfoBanner.MarketingInfo.text3">{{marketingInfoBanner.MarketingInfo.text3}}</h3>
      <h3 class="c-white fw-cl pull-left m-0 f-20" ng-if="marketingInfoBanner.currentLang == 'en' && marketingInfoBanner.MarketingInfo.textEng3">{{marketingInfoBanner.MarketingInfo.textEng3}} </h3>
    </div>

    <!--<div class="t-a-l">-->
    <div style="position: absolute; bottom: 3px; left: 35px;">
      <button type="button" class="btn btn-default text-uppercase round-marketing-button" ng-if="marketingInfoBanner.MarketingInfo.buttonText">
        <span class="glyphicon glyphicon-chevron-right round-marketing-icon" aria-hidden="true"></span>
        <span class="round-marketing-text" ng-if="marketingInfoBanner.currentLang == 'hr'">{{marketingInfoBanner.MarketingInfo.buttonText}}</span>
        <span class="round-marketing-text" ng-if="marketingInfoBanner.currentLang == 'en'">{{marketingInfoBanner.MarketingInfo.buttonTextEng}}</span>
      </button>
    </div>
    <!--</div>-->


  </div>

</div>
