(function () {
  'use strict';

  angular
      .module('investments')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('investments', {
          url: '/investments',
          abstract: true,
          template: '<ui-view/>'
        })
        .state('investments.accession', {
          url: '/accession',
          templateUrl: 'investments/access-investments.tpl.html',
          controllerAs: 'accessInv',
          controller: 'AccessInvestmentsCtrl'
        })
        .state('investments.list', {
          url: '/list',
          templateUrl: 'investments/investments-list.tpl.html',
          controller: 'InvestmentsListCtrl',
          controllerAs: 'investmentsList',
          resolve: {
            // if user has no funds he should be redirected to fund accesssion
            userHaveFunds: function ($q, $state, UserSettingsService) {
              return UserSettingsService.userHaveFunds()
                  .then(function (response) {
                    if (!response) {
                      $state.go('investments.accession');
                      return $q.reject('User has no funds');
                    }
                  });
            }
          }
        })
        .state('investments.overview', {
          url: '/overview/:fundId',
          templateUrl: 'investments/investments-overview.tpl.html',
          controller: 'InvestmentsOverviewCtrl',
          controllerAs: 'investmentsOverview',
          resolve: {
//            selectedFund: function ($stateParams, FundsService) {
//              FundsService.getFundById($stateParams.fundId)
//            }
          }
        })
        .state('investments.form', {
          url: '/:orderType',
          abstract: true,
          controller: 'InvestmentCtrl',
          controllerAs: 'investment',
          templateUrl: 'investments/investment-base.tpl.html',
          resolve: {
            orderType: function ($q, $stateParams) {
              var options = ["new", "sell", "replace"];
              if (!_.includes(options, $stateParams.orderType)) {
                return $q.reject("Invalid");
              }
              return $q.resolve($stateParams.orderType);
            },
            //fundList: function (FundsService) {
            //  return FundsService.getPage();
            //},
            configData: function (ConfigEndpointService) {
              return ConfigEndpointService.getDefaultData();
            },
            // if user has no funds he should be redirected to fund accesssion
            userHaveFunds: function ($q, $state, UserSettingsService) {
              return UserSettingsService.userHaveFunds()
                  .then(function (response) {
                    if (!response) {
                      $state.go('investments.accession');
                      return $q.reject('User has no funds');
                    } else {
                      return response;
                    }
                  })
                  .catch(function () {
                    return $q.reject("Error loading user funds!");
                  })
            }
          }
        })
        .state('investments.form.new', {
          url: '?fundId',
          templateUrl: 'investments/investment-form.tpl.html',
          resolve: {
            validate: function ($q, $stateParams, $location, orderType) {
              // if orderType is sell or replace - fundId is required!
              if (orderType != "new" && !$stateParams.fundId) {
                $location.go("/investments/new");
                return $q.reject("Invalid");
              }
              // if orderType is new $stateParams id is optional

              //else if ($stateParams.fundId) {
              //  var fund = _.find(fundList.result, {id: $stateParams.fundId});
              //  if (!fund) {
              //    return $q.reject("Invalid");
              //  }
              //}
            }
          }
        })
        .state('investments.form.resume', {
          url: '/investment-details',
          templateUrl: 'investments/investment-resume.tpl.html',
          params: {
            editMode: false
          },
          resolve: {
            authorize: function ($location, $stateParams) {
              // Forbid user navigation from "URL"
              if (!$stateParams.editMode) {
                $location.url("/investments/" + $stateParams.orderType);
              }
            }
          }
        })
        // This state is for edit!
        .state('investments.form.edit', {
          url: '/orders/:action?orderId?fundId',
          templateUrl: 'investments/investment-form.tpl.html',
          resolve: {
            orderType: function ($q, $stateParams) {
              var options = ["new", "sell", "replace"];
              if (!_.includes(options, $stateParams.orderType)) {
                return $q.reject("Invalid");
              }
              return $q.resolve($stateParams.orderType);
            },
            validate: function ($q, $stateParams, $location, orderType) {

              console.log($stateParams.orderId);
              // if orderType is sell or replace - fundId is required!
//              if (orderType != "new" && !$stateParams.fundId) {
//                $location.go("/investments/new");
//                return $q.reject("Invalid");
//              }
              // if orderType is new $stateParams id is optional

              //else if ($stateParams.fundId) {
              //  var fund = _.find(fundList.result, {id: $stateParams.fundId});
              //  if (!fund) {
              //    return $q.reject("Invalid");
              //  }
              //}
            }
          }
        })
        .state('investments.sign', {
          url: '/:orderId/sign/:fundId',
          templateUrl: 'investments/investment-sign.tpl.html',
          controller: 'InvestmentSignCtrl',
          controllerAs: 'investmentSign',
          resolve: {
            data: function ($q, $stateParams, FundsService, InvestmentOrdersService) {
              if ($stateParams.orderId) {
                return $q.all([InvestmentOrdersService.getInvestmentById($stateParams.orderId), FundsService.getFundById($stateParams.fundId)]);
              } else {
                return $q.reject("Invalid");
              }
            }
          }
        })
  }
}());
