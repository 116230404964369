(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      alert: {
        information: "Informacije",
        areYouSure: "Jeste li sigurni?",
        blockCardInfo: "Da li zaista želite da blokirate svoju karticu?",
        blockCardCost: "Ova usluga se naplaćuje.",
        blockCard: "Blokiraj",
        payment: "Nalog broj",
        template: "Broj uzorka",
        termDeposit: "Oročenje",
        investment: "Investicija broj",
        subscriptionRequest: "Uspješno ste unijeli zahtjev za pretplatu",
        successSign: "Uspješno ste potpisali nalog",
        closeEBook: "Uspješno ste zatvorili eKnjižicu",
        cancelRegistration: "Uspješno ste otkazali registraciju",
        ebookOrder: "eKnjižica broj",
        archiveEInvoice: "Uspješno ste arhivirali e-račun",
        saveTemplate: "Uspješno ste sačuvali uzorak.",
        updateTemplate: "Uspješno ste ažurirali uzorak.",
        deleteAction: "Brisanje",
        cancel: "Zatvori",
        yes: "Da",
        no: "Ne"
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());

