(function () {
  'use strict';

  angular
    .module('localization'
      )
    .config(config);

  function config($translateProvider, $provide, $localeProvider, tmhDynamicLocaleProvider, ConfigServiceProvider) {
    $translateProvider
      .preferredLanguage(ConfigServiceProvider.defaultLocale)
      .fallbackLanguage(ConfigServiceProvider.defaultLocale); //escape //sanitize
    //tmhDynamicLocaleProvider.defaultLocale(ConfigServiceProvider.defaultLocale);
  }
}());
