(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name accounts.controller:AccountsCtrl
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .controller('AccountsCtrl', AccountsCtrl);

    function AccountsCtrl($rootScope, $translate, $log, $stateParams, AccountsService, TransactionsService,
        TransactionDivisionService,
        HelperService, StatementsService, $state, CurrencyService, ValidationService,
        ESavingBookRequestsService, PaymentTemplatesService, $filter, AlertService,
        ConfigService,
        DepositOrdersService, ReservationsService, EndpointsService, GoogleTagManagerHelperService, NotificationService,
        $uibModal) {

        var vm = this;
        vm.links = {};
        var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        var allowedCurrenciesSlo = ["EUR", "HRK", "CHF", "GBP", "USD"];

        vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
        vm.countryCode = defaultData.APILocation;
        vm.defaultCurrency = defaultData.DefaultCurrency;

        vm.showTemplatesView = false;
        vm.eBookRequests = true;
        vm.collapsed = false;
        vm.apiLocation = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.showRepaymentPlan = false;
        vm.repaymentLoaded = false;
        vm.multiCurrency = false;
        vm.showField = false;
        vm.showConvertedLoansFields = false;
        vm.accountLoaded = false;
        vm.searchFilterExpanded = false;
        vm.defaultDates = {};
        vm.googleMapsUrl="https://maps.googleapis.com/maps/api/js?key=" + ConfigService.googleMapsKey;


        //-------------------------------------------
        //        TRANSACTION FILTER OPTIONS START
        //-------------------------------------------
        vm.showFilterOptionsWrapper = false;
        vm.dateFromOpen = false;
        vm.dateToOpen = false;
        vm.transactionFilterOptions = {
            genericSearch: "",
            transactionType: undefined,
            amountGTE: undefined,
            amountLTE: undefined,
            currencySymbol: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            datePeriodStart: undefined
        };

        vm.preventDefault  = function(event) {
            event.preventDefault()
            event.stopPropagation()
        }

        vm.accountStatus = '';

        vm.datesToDisabled = function (date, mode) {
            // if (!vm.transactionFilterOptions.dateFrom) return true;
            // var yearAfter = new Date(vm.transactionFilterOptions.dateFrom.getFullYear() + 1, vm.transactionFilterOptions.dateFrom.getMonth(), vm.transactionFilterOptions.dateFrom.getDate() + 1);
            // return (mode === 'day' && (date.getTime() < vm.transactionFilterOptions.dateFrom || (date.getTime() >= yearAfter)));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
            return (mode === 'day' && (date.getTime() < vm.transactionFilterOptions.dateFrom));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
        };

        vm.isNuN = HelperService.isNuN;

        vm.transactionFilterYearDateRangeValid = true;
        vm.onDateFromChange = function () {
            if (!vm.transactionFilterOptions.dateTo) return;
            if (vm.transactionFilterOptions.dateTo < vm.transactionFilterOptions.dateFrom) {
                vm.transactionFilterOptions.dateTo = angular.copy(vm.transactionFilterOptions.dateFrom);
            }
            // if (vm.transactionFilterOptions.dateFrom) {
            //   var yearAfter = new Date(vm.transactionFilterOptions.dateFrom.getFullYear() + 1, vm.transactionFilterOptions.dateFrom.getMonth(), vm.transactionFilterOptions.dateFrom.getDate());
            //   if (yearAfter <= (vm.transactionFilterOptions.dateTo && vm.transactionFilterOptions.dateTo.getTime())) {
            //     vm.transactionFilterOptions.dateTo = yearAfter;
            //   }
            // }
            if (vm.transactionFilterOptions.dateFrom && vm.transactionFilterOptions.dateTo) {
                if (daysBetween(vm.transactionFilterOptions.dateFrom, vm.transactionFilterOptions.dateTo) <= 365) {
                    vm.transactionFilterYearDateRangeValid = true;
                } else {
                    vm.transactionFilterYearDateRangeValid = false;
                }
            }
        };
        vm.onDateToChange = function () {
            if (!vm.transactionFilterOptions.dateFrom) return;
            if (daysBetween(vm.transactionFilterOptions.dateFrom, vm.transactionFilterOptions.dateTo) <= 365) {
                vm.transactionFilterYearDateRangeValid = true;
            } else {
                vm.transactionFilterYearDateRangeValid = false;
            }
        }

        function daysBetween(firstDate, secondDate) {
            var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
            return diffDays;
        }

        vm.applyFilter = function () {
            if (!vm.transactionFilterYearDateRangeValid) return;
            if (!vm.transactionFilterOptions.datePeriodStart && (!vm.transactionFilterOptions.dateFrom || !vm.transactionFilterOptions.dateTo)) return;
            vm.showFilterOptionsWrapper = false;
            vm.refreshTransactionsByFilter(vm.transactionFilterOptions);
            //vm.transactionFilterChange({filterParams: vm.transactionFilterOptions});
        };
        vm.clearSearch = function () {
            vm.transactionFilterOptions.genericSearch = '';
            angular.extend(accountsFilter, vm.transactionFilterOptions);

            vm.getTransactions(true);
            //setTransactionFilterDatesAsDropdown();
        };
        vm.resetFilter = function () {
            vm.showFilterOptionsWrapper = false;
            vm.transactionFilterOptions.genericSearch = '';
            vm.transactionFilterOptions.transactionType = undefined;
            vm.transactionFilterOptions.amountGTE = undefined;
            vm.transactionFilterOptions.amountLTE = undefined;
            vm.transactionFilterOptions.currencySymbol = undefined;
            vm.transactionFilterOptions.dateTo = undefined;
            vm.transactionFilterOptions.dateFrom = undefined;
            vm.transactionFilterOptions.datePeriodStart = undefined;
            vm.transactionFilterYearDateRangeValid = true;
            angular.extend(accountsFilter, vm.transactionFilterOptions);

            setTransactionFilterDatesAsDropdown();
            setDefaultCurrencyToFilter();
        };
        vm.search = function () {
            angular.extend(accountsFilter, vm.transactionFilterOptions);
            if (vm.transactionFilterOptions.datePeriodStart) {
                accountsFilter.valueDateGTE = undefined;
                accountsFilter.valueDateLTE = undefined;
            }

            if (vm.transactionFilterOptions.dateFrom && vm.transactionFilterOptions.dateTo) {
                accountsFilter.valueDateGTE = vm.transactionFilterOptions.dateFrom;
                accountsFilter.valueDateLTE = vm.transactionFilterOptions.dateTo;
                // Clean extended properties
                accountsFilter.dateFrom = undefined;
                accountsFilter.dateTo = undefined;
            }

            if (vm.transactionFilterOptions.genericSearch) {
                // For Slo valueDateGTE and valueDateLTE is needed
                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') {
                    accountsFilter.valueDateGTE = undefined;
                    accountsFilter.valueDateLTE = undefined;
                    if (vm.transactionFilterOptions.dateFrom || vm.transactionFilterOptions.dateTo) {
                        accountsFilter.dateFrom = vm.transactionFilterOptions.dateFrom;
                        accountsFilter.dateTo = vm.transactionFilterOptions.dateTo;
                    }
                }
            }



            accountsFilter.page = 0; //TSK-114366

            vm.getTransactions(true);
        };

        function setTransactionFilterDatesAsDropdown() {
            setPeriod();
            vm.transactionFilterOptions.dateTo = accountsFilter.valueDateLTE;
            vm.transactionFilterOptions.dateFrom = accountsFilter.valueDateGTE;
        }

        var setDefaultCurrencyToFilter = function () {
            var currency = _.find(vm.currenciesList, { symbol: vm.defaultCurrency });
            if (currency) {
                vm.transactionFilterOptions.currencySymbol = currency.symbol;
            }
        };

        vm.clearStatementDate = function () {
            if (vm.transactionFilterOptions.dateTo || vm.transactionFilterOptions.dateFrom)
                vm.transactionFilterOptions.datePeriodStart = undefined;
        };

        //-------------------------------------------
        //        TRANSACTION FILTER OPTIONS END
        //-------------------------------------------


        //-------------------------------------------
        //    CARDS TRANSACTION FILTER OPTIONS START
        //-------------------------------------------
        vm.showCardsFilterOptionsWrapper = false;
        vm.transactionCardsFilterOptions = {
            genericSearch: "",
            transactionType: undefined,
            amountGTE: undefined,
            amountLTE: undefined,
            currencySymbol: undefined,
            datePeriodStart: undefined,
            dateFrom: undefined,
            dateTo: undefined,
        };

        vm.transactionCards = {
            dateFromOpen: false,
            dateToOpen: false,
            transactionFilterYearDateRangeValid: true
        };
        vm.transactionCards.datesToDisabled = function (date, mode) {
            // if (!vm.transactionCardsFilterOptions.dateFrom) return true;
            // var yearAfter = new Date(vm.transactionCardsFilterOptions.dateFrom.getFullYear() + 1, vm.transactionCardsFilterOptions.dateFrom.getMonth(), vm.transactionCardsFilterOptions.dateFrom.getDate() + 1);
            return (mode === 'day' && (date.getTime() < vm.transactionCardsFilterOptions.dateFrom));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
        };

        vm.transactionCards.onDateFromChange = function () {
            if (!vm.transactionCardsFilterOptions.dateTo) return;
            if (vm.transactionCardsFilterOptions.dateTo < vm.transactionCardsFilterOptions.dateFrom) {
                vm.transactionCardsFilterOptions.dateTo = angular.copy(vm.transactionCardsFilterOptions.dateFrom);
            }
            // if (vm.transactionCardsFilterOptions.dateFrom) {
            //   var yearAfter = new Date(vm.transactionCardsFilterOptions.dateFrom.getFullYear() + 1, vm.transactionCardsFilterOptions.dateFrom.getMonth(), vm.transactionCardsFilterOptions.dateFrom.getDate());
            //   if (yearAfter <= (vm.transactionCardsFilterOptions.dateTo && vm.transactionCardsFilterOptions.dateTo.getTime())) {
            //     vm.transactionCardsFilterOptions.dateTo = yearAfter;
            //   }
            // }

            if (daysBetween(vm.transactionCardsFilterOptions.dateFrom, vm.transactionCardsFilterOptions.dateTo) < 365) {
                vm.transactionCards.transactionFilterYearDateRangeValid = true;
            } else {
                vm.transactionCards.transactionFilterYearDateRangeValid = false;
            }
        };
        vm.transactionCards.onDateToChange = function () {
            if (!vm.transactionCardsFilterOptions.dateFrom) return;
            if (daysBetween(vm.transactionCardsFilterOptions.dateFrom, vm.transactionCardsFilterOptions.dateTo) < 365) {
                vm.transactionCards.transactionFilterYearDateRangeValid = true;
            } else {
                vm.transactionCards.transactionFilterYearDateRangeValid = false;
            }
        }

        vm.transactionCards.clearStatementDate = function () {
            if (vm.transactionCardsFilterOptions.dateTo || vm.transactionCardsFilterOptions.dateFrom)
                vm.transactionCardsFilterOptions.datePeriodStart = undefined;
        };


        vm.applyCardsFilter = function () {
            if (!vm.transactionCards.transactionFilterYearDateRangeValid) return;
            if (!vm.transactionCardsFilterOptions.datePeriodStart && (!vm.transactionCardsFilterOptions.dateFrom || !vm.transactionCardsFilterOptions.dateTo)) return;
            if (vm.transactionCardsFilterOptions.dateFrom && vm.transactionCardsFilterOptions.dateTo) {
                vm.transactionCardsFilterOptions.valueDateGTE = vm.transactionCardsFilterOptions.dateFrom;
                vm.transactionCardsFilterOptions.valueDateLTE = vm.transactionCardsFilterOptions.dateTo;
            }


            vm.showCardsFilterOptionsWrapper = false;
            if (vm.transactionCardsFilterOptions.datePeriodStart) {
                this.transactionCardsFilterOptions.valueDateGTE = undefined;
                this.transactionCardsFilterOptions.valueDateLTE = undefined;
            }
            if (vm.transactionFilterOptions.genericSearch && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
                accountsFilter.valueDateGTE = undefined;
                accountsFilter.valueDateLTE = undefined;
                if (params.dateFrom || params.dateTo) {
                    accountsFilter.dateFrom = params.dateFrom;
                    accountsFilter.dateTo = params.dateTo;
                }
            }

            var filter = angular.copy(vm.transactionCardsFilterOptions);
            filter.dateTo = undefined;
            filter.dateFrom = undefined;
            vm.refreshCardTransactionsByFilter(filter);
            //vm.transactionFilterChange({filterParams: vm.transactionFilterOptions});
        };
        vm.clearCardsSearch = function () {
            vm.transactionCardsFilterOptions.genericSearch = '';
            angular.extend(vm.connectedCardTransactionsFilter, vm.transactionCardsFilterOptions);
            vm.getConnectedCardsTransactions(true);
        };
        vm.resetCardsFilter = function () {
            vm.showCardsFilterOptionsWrapper = false;
            vm.transactionCardsFilterOptions.genericSearch = '';
            vm.transactionCardsFilterOptions.transactionType = undefined;
            vm.transactionCardsFilterOptions.amountGTE = undefined;
            vm.transactionCardsFilterOptions.amountLTE = undefined;
            vm.transactionCardsFilterOptions.currencySymbol = undefined;
            vm.transactionCardsFilterOptions.datePeriodStart = undefined;
            vm.transactionCards.transactionFilterYearDateRangeValid = true;
            angular.extend(vm.connectedCardTransactionsFilter, vm.transactionCardsFilterOptions);
            // Clear
            vm.connectedCardTransactionsFilter.dateFrom = undefined;
            vm.connectedCardTransactionsFilter.dateTo = undefined;

            setDefaultCurrencyToCardsFilter();
            getDatesFromConnectedCardsSelectedPeriod();

            vm.getConnectedCardsTransactions(true);
        };
        vm.searchCards = function () {
            angular.extend(vm.connectedCardTransactionsFilter, vm.transactionCardsFilterOptions);
            vm.connectedCardTransactionsFilter.accountId = vm.selectedAccount.accountId;

            if (vm.transactionCardsFilterOptions.genericSearch) {
                // For Slo valueDateGTE and valueDateLTE is needed
                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') {
                    vm.connectedCardTransactionsFilter.valueDateLTE = undefined;
                    vm.connectedCardTransactionsFilter.valueDateGTE = undefined;
                } else {
                    vm.connectedCardTransactionsFilter.dateFrom = undefined;
                    vm.connectedCardTransactionsFilter.dateTo = undefined;
                }

            }
            vm.connectedCardTransactionsFilter.accountId = vm.selectedAccount.accountId;
            vm.groupByConnectedCardTransactions(true);
            // vm.getConnectedCardsTransactions(true);
        };

        var setDefaultCurrencyToCardsFilter = function () {
            var currency = _.find(vm.currenciesList, { symbol: vm.defaultCurrency });
            if (currency) {
                vm.transactionCardsFilterOptions.currencySymbol = currency.symbol;
            }
        };

        //-------------------------------------------
        //    CARDS TRANSACTION FILTER OPTIONS END
        //-------------------------------------------

        vm.toggleFilterSelection = function () {
            vm.searchFilterExpanded = !vm.searchFilterExpanded;
        };

        var loadAccountInfo = function () {
            vm.transactionFilterYearDateRangeValid = true;

            if (vm.selectedAccount.accountType.group === 'CurrentAccount' && vm.selectedAccount.currencySymbol == defaultData.DefaultCurrency) {
                vm.showField = true;
            } else {
                vm.showField = false;
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && vm.selectedAccount.accountType.group === 'CurrentAccount') {
                vm.accountStatus = vm.selectedAccount.status;
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                if (_.includes(allowedCurrenciesSlo, vm.selectedAccount.currencySymbol)) {
                    vm.selectedAccount.canCashWithDrawal = true;
                } else {
                    vm.selectedAccount.canCashWithDrawal = false;
                }

                if (vm.selectedAccount.accountType.group === 'CardAccountTypes')
                    loadStatementsDates(vm.selectedAccount.accountId);
            }

            if (vm.selectedAccount.loanAccount && vm.selectedAccount.loanAccount.installmentCurrencySymbol != vm.selectedAccount.currencySymbol && defaultData.APILocation === 'hr') {
                vm.showConvertedLoansFields = true;
            }

            vm.selectedAccount.transactions = {
                hasMore: false
            };
            vm.selectedAccount.statements = {
                hasMore: false
            };



            loadStatementsAndTransactions();
            if (vm.countryCode === 'bh' && vm.selectedAccount.linkList && vm.selectedAccount.linkList.itemList.payInReservations) {
                loadAccountReservations(true);
            } else if (vm.showSubmenuItemType == 'reservations') {
                vm.showSubmenuItemType = 'transactions';
            }
            vm.eSavingBookRequestsFilter = {
                page: 0,
                pageSize: 10,
                accountId: vm.selectedAccount.accountId,
                orderBy: 'dateOfCreation desc'
            };

            vm.eBookSavingsRequests = {};

            if (vm.selectedAccount.cardList) {
                _.forEach(vm.selectedAccount.cardList, function (trn) {
                    trn.collapsed = false;
                });
            }

            // If user account list length is 1, then it is only owner in that list.
            // If there are more users in list, we need to display only authorized persons, without owner

            if (vm.selectedAccount.userAccountList && vm.selectedAccount.userAccountList.length > 1) {
                vm.selectedAccount.userAccountListFiltered = _.filter(vm.selectedAccount.userAccountList, function (item) {
                    return item.userAccountType !== 'Owner';
                });
            } else { //if there are no authorized persons, there should be "-" on GUI
                vm.selectedAccount.userAccountListFiltered = vm.selectedAccount.userAccountList;
                vm.selectedAccount.userAccountListFiltered[0].user.firstName = '-';
                vm.selectedAccount.userAccountListFiltered[0].user.lastName = '';
            }

            checkIfMultiCurrency();
            vm.isFavoriteAccount = vm.selectedAccount.isFavorite;
            vm.selectedAccount.loanAccountPaymentPlanList = _.sortBy(vm.selectedAccount.loanAccountPaymentPlanList, "nextInstallmentDate").reverse();
            vm.displayeBookSavingRequest = false;
        };

        // Load all accounts for user (carousel)
        var loadAccountsForCarousel = function () {
            vm.tempTransactionsList = [];
            vm.accountsForCarousel = [];
            //vm.allAccounts = [];

            var carouselListFilter = function () {
                var filter = {
                    page: 0,
                    pageSize: 100,
                    orderBy: 'isFavorite desc,accountType,iban,currencyCode'
                };
                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' || $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
                    filter.includeList = ['cardList', 'userAccountList', 'cardAccount'];
                } else {
                    filter.includeList = ['cardList', 'userAccountList'];
                }
                if (vm.selectedAccount.accountType.group == 'LoanAccountType') {
                    filter.accountTypeGroup = 'LoanAccountType';
                    filter.includeList.push('loanAccount');
                } else if (vm.selectedAccount.accountType.group == 'DepositAccountTypes' || vm.selectedAccount.accountType.group == 'SavingAccountTypes') {
                    filter.includeList.push('parentAccount');
                    // For every other country filter Deposit and Saving account types
                    if (defaultData.APILocation != 'sr' && defaultData.APILocation != 'mne') {
                        filter.accountTypeGroupList = ['DepositAccountTypes', 'SavingAccountTypes'];
                        filter.includeList.push('depositAccount');
                    }
                    // For serbia and mne show only deposit account types
                    else if (defaultData.APILocation == 'sr' || defaultData.APILocation == 'mne') {
                        filter.accountTypeGroup = 'DepositAccountTypes';
                        filter.includeList.push('depositAccount');
                    }
                } else {
                    // SHOW ALL LEFT
                    filter.notInAccountTypeGroupList = ['LoanAccountType', 'DepositAccountTypes', 'SavingAccountTypes'];
                }
                return filter;
            };

            var findAccountFilter = {};
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                findAccountFilter = {
                    includeList: ['CardAccount', 'LoanAccount', 'depositAccount', 'cardList', 'UserAccountList', 'ParentAccount', 'DepositAccountInterests']
                };

            } else if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
                findAccountFilter = {
                    includeList: ['CardAccount', 'LoanAccount', 'depositAccount', 'cardList']
                };

            } else {
                findAccountFilter = {
                    includeList: ['LoanAccount', 'depositAccount', 'cardList', 'UserAccountList', 'ParentAccount']
                };
            }
            AccountsService.getAccountById($stateParams.accountId, findAccountFilter)
                .then(function (response) {
                    vm.selectedAccount = response;
                    vm.selectedAccount.iban = (vm.selectedAccount.iban, vm.selectedAccount) ? vm.formatIban(response.iban, vm.selectedAccount) : "";
                    vm.accountLoaded = true;
                    //load carousel array accounts
                    AccountsService.getPage(carouselListFilter())
                        .then(function (response) {
                            vm.accountsForCarousel = response.result;
                            _.each(response.result, function (account) {
                                account.iban = (account.iban) ? vm.formatIban(account.iban, account) : "";
                            })
                            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                                vm.accountsForCarousel = $filter('orderBy')(response.result, ['-accountType.group', '-isOwn', 'sortOrderId'])
                            }
                            setCurrentAccount();
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                        });

                    loadAccountInfo();

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        var checkIfMultiCurrency = function () {
            vm.multiCurrency = false;
            if (vm.selectedAccount.accountType.group === 'CurrentAccount' && defaultData.APILocation === 'hr' && defaultData.DefaultCurrency && vm.selectedAccount.currencySymbol && defaultData.DefaultCurrency !== vm.selectedAccount.currencySymbol) {
                vm.multiCurrency = true;
            }
        };

        loadCurrencies();

        function loadCurrencies() {
            CurrencyService.getCurrencyPage({
                pageSize: 1000
            })
                .then(function (response) {
                    vm.currenciesList = response.result;

                    setDefaultCurrencyToFilter();
                    setDefaultCurrencyToCardsFilter();

                    //$log.debug("currenciesList", vm.currenciesList);
                }).catch(function (error) {
                    $log.error(error);
                });
        }

        var setCurrentAccount = function (accountId) {
            var filteredBy = accountId ? accountId : $stateParams.accountId;
            var currIndex = 0;
            _.forEach(vm.accountsForCarousel, function (item) {
                item.index = currIndex++;
            });

            vm.currentIndex = _.findIndex(vm.accountsForCarousel, function (item) {
                return filteredBy === item.accountId;
            });

            vm.isNextButtonVisible = vm.currentIndex < vm.accountsForCarousel.length - 1;
            vm.isPreviousButtonVisible = vm.currentIndex > 0;
        };

        vm.chooseFromCarousel = function (accountId) {
            setCurrentAccount(accountId);
            vm.selectedAccount = vm.accountsForCarousel[vm.currentIndex];
            vm.isEditable = false;
            $state.go('accounts', {
                accountId: vm.selectedAccount.accountId
            }, {
                notify: false
            });
            loadAccountInfo();
        };

        vm.goToNextItem = function () {

            GoogleTagManagerHelperService.pushEvent('Accounts', 'Carousel', 'NextSlide');

            vm.currentIndex++;
            vm.isPreviousButtonVisible = vm.currentIndex > 0;
            vm.isNextButtonVisible = vm.currentIndex < vm.accountsForCarousel.length - 1;
            if (vm.currentIndex <= vm.accountsForCarousel.length - 1) {
                // var nextAccountId = vm.accountsForCarousel[vm.currentIndex].accountId;
                // if (nextAccountId) {
                //     $state.go('accounts', {
                //         accountId: nextAccountId
                //     }, {
                //         notify: false
                //     });
                // }
                vm.selectedAccount = vm.accountsForCarousel[vm.currentIndex];
                vm.isEditable = false;
                //findAccountFromCarousel(nextAccountId);
                loadAccountInfo();
            }
        };

        vm.goToPreviousItem = function () {

            GoogleTagManagerHelperService.pushEvent('Accounts', 'Carousel', 'PreviousSlide');

            vm.currentIndex--;
            vm.isPreviousButtonVisible = vm.currentIndex > 0;
            vm.isNextButtonVisible = vm.currentIndex < vm.accountsForCarousel.length - 1;
            if (vm.currentIndex >= 0) {
                // var previousAccountId = vm.accountsForCarousel[vm.currentIndex].accountId;
                // if (previousAccountId) {
                //     $state.go('accounts', {
                //         accountId: previousAccountId
                //     }, {
                //         notify: false
                //     });
                // }
                vm.selectedAccount = vm.accountsForCarousel[vm.currentIndex];
                vm.isEditable = false;
                //findAccountFromCarousel(previousAccountId);
                loadAccountInfo();
            }
        };

        vm.isAccountDetailsOpen = false;

        vm.toggleAccountDetails = function () {
            vm.isAccountDetailsOpen = !vm.isAccountDetailsOpen;
            GoogleTagManagerHelperService.pushEvent('AccountDetails', 'ShowAccountDetails', (vm.isAccountDetailsOpen) ? "Show additional details" : "Less details");
        };

        vm.goToCashWithdrawal = function () {
            $state.go('cashWithdrawalPage.form', {
                accountId: vm.selectedAccount.accountId
            })
        };

        vm.isAccountDetailsOpen = false;

        vm.numberOfAccountsToShow = 4;

        vm.showSubmenuItemType = 'transactions';

        vm.all = false;
        //vm.countryCode = $localStorage.code;

        vm.showSubmenu = function (show) {
            if (show == vm.showSubmenuItemType) return;

            if (show == 'transactions') {
                vm.showSubmenuItemType = 'transactions';
            } else if (show == 'statements') {
                // for SLO environment load statements only when user selects 'Statements' tab
                if (vm.countryCode === 'sl')
                    vm.getStatements(true);
                vm.showSubmenuItemType = 'statements';
            } else if (show == 'reservations') {
                vm.showSubmenuItemType = 'reservations';
            }

            GoogleTagManagerHelperService.pushEvent('Accounts', 'Tab', vm.showSubmenuItemType);
        };

        vm.tmpSearchText = {
            name: ''
        };

        vm.accountList = [];
        var accountsFilter = {
            accountId: undefined,
            orderBy: 'ValueDate DESC, referenceNumber desc',
            page: 0,
            pageSize: 10,
            isCardTransaction: false
        };

        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl") {
            accountsFilter.orderBy = 'ValueDate DESC, transactionId desc';
        }

        vm.allAccountsFilter = {
            page: 0,
            pageSize: 1000,
            includeList: ['userAccountList']
        };

        vm.busy = false; //marks is request already fired

        vm.defaultaccountsTempFilter = {
            selectedTransactionType: 'all',
            amountGTE: null,
            amountLTE: null,
            genericSearch: null
        };

        vm.accountsTempFilter = {};

        vm.accountsTempFilter = _.extend(vm.accountsTempFilter, vm.defaultaccountsTempFilter);

        vm.accountsSearchFilter = {
            searchAccounts: ''
        };

        vm.isEditable = false;

        $translate(['transactions.transactionTypes.credit', 'transactions.transactionTypes.debit', 'transactions.transactionTypes.all']).then(function (translations) {

            vm.transactionTypeFilterList = [
                {
                    id: 'all',
                    description: translations['transactions.transactionTypes.all']
                }
                , {
                    id: 'credit',
                    description: translations['transactions.transactionTypes.credit']
                }
                , {
                    id: 'debit',
                    description: translations['transactions.transactionTypes.debit']
                }
            ];
        });

        vm.periodList = [
            {
                key: 'today',
                description: 'core.period.today'
            }
            , {
                key: 'yesterday',
                description: 'core.period.yesterday'
            }
            , {
                key: 'thisWeek',
                description: 'core.period.thisWeek'
            }
            , {
                key: 'thisMonth',
                description: 'core.period.thisMonth'
            }
            , {
                key: 'thisYear',
                description: 'core.period.thisYear'
            }
            , {
                key: 'all',
                description: 'core.period.all'
            }
        ];

        vm.reportTypeList = [
            {
                key: 'PDF',
                description: 'PDF'
            }
            , {
                key: 'Excel',
                description: 'Excel'
            }

        ];

        // vm.selectedReportType = vm.reportTypeList[0];
        vm.selectedPeriod = vm.periodList[2];
        vm.connectedCardsSelectedPeriod = vm.periodList[2];

        vm.detailedFilter = {
            statementDates: [],
            valueDateGTE: '',
            valueDateLTE: ''
        };

        function loadStatementsDates(accountId) {
            StatementsService.getStatementDates(accountId)
                .then(function (response) {
                    vm.statementDates = response;
                })
                .catch(function (error) {
                    NotificationService.showMessage('error', error);
                });
        }


        vm.init = function () {
            EndpointsService.getLinks()
                .then(function (data) {
                    vm.links = data.links;
                });



            if ($stateParams.accountId) {
                loadAccountsForCarousel();
            }

            if (vm.countryCode === 'bh') {
                vm.reportTypeList = [
                    {
                        key: 'PDF',
                        description: 'PDF'
                    }
                ];
            }
        };

        var loadStatementsAndTransactions = function () {
            // skip inital loading of statements for SLO environment
            if (vm.countryCode !== 'sl')
                vm.getStatements(true);

            //vm.getTransactions(true);
            //setPeriod();
            vm.resetFilter();
        };


        //next method sets accountsFilter.valueDateGTE & accountsFilter.valueDateLTE based on selected period
        function setPeriod() {

            if (vm.selectedPeriod.key == 'today') {
                var range = HelperService.getToday();
                accountsFilter.valueDateGTE = range.firstDay;
                accountsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.key == 'yesterday') {
                var range = HelperService.getYesterday();
                accountsFilter.valueDateGTE = range.firstDay;
                accountsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.key == 'thisWeek') {
                var range = HelperService.getThisWeek();
                accountsFilter.valueDateGTE = range.firstDay;
                accountsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.key == 'thisMonth') {
                var range = HelperService.getLast30Days();
                accountsFilter.valueDateGTE = range.firstDay;
                accountsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.key == 'thisYear') {
                var range = HelperService.getThisYear();
                accountsFilter.valueDateGTE = range.firstDay;
                accountsFilter.valueDateLTE = range.lastDay;
            } else if (vm.selectedPeriod.key == 'all') {
                accountsFilter.valueDateGTE = null;
                accountsFilter.valueDateLTE = null;

                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                    var range;
                    switch (vm.selectedAccount.accountType.group) {
                        case 'SavingAccountTypes':
                        case 'CardAccountTypes':
                            range = HelperService.getAllFrom2LastYears();
                            break;
                        default:
                            range = HelperService.getThisYear();

                    }

                    accountsFilter.valueDateGTE = range.firstDay;
                    accountsFilter.valueDateLTE = range.lastDay;
                }
            }

            vm.getTransactions(true);
        }

        vm.getTransactions = function (reset) {

            if (!vm.selectedAccount || !vm.selectedAccount.accountId) {
                $log.debug("return", vm.selectedAccount);
                return;
            }

            if (!reset && vm.selectedAccount.transactions != undefined && vm.selectedAccount.transactions.hasMore != undefined && vm.selectedAccount.transactions.hasMore == false) {
                return;
            }

            if (reset) {
                accountsFilter.page = 0;
            } else {
                accountsFilter.page += 1;
            }


            if (vm.transactionFilterOptions.datePeriodStart) {
                accountsFilter.valueDateGTE = undefined;
                accountsFilter.valueDateLTE = undefined;
            }

            // if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
            //   vm.transactionFilterOptions.dateFrom = accountsFilter.valueDateGTE;
            //   vm.transactionFilterOptions.dateTo = accountsFilter.valueDateLTE;
            //   //setTransactionFilterDatesAsDropdown();
            // }
            accountsFilter.accountId = vm.selectedAccount.accountId;
            vm.groupByTransactions(reset);
        };


        vm.changeTransactionPeriod = function (period) {
            if (period) {
                vm.selectedPeriod = period;
                vm.transactionFilterYearDateRangeValid = true;
                vm.busy = false;
                // vm.getTransactions(true);
                // setPeriod();
                vm.resetFilter();
                GoogleTagManagerHelperService.pushEvent('Accounts', 'TabPeriodFilter', $translate.instant(period.description));
            }
        };

        vm.changeReportType = function (accountId, reportType) {

            if (reportType) {
                vm.selectedReportType = reportType;
                vm.busy = false;

                var dateFrom = accountsFilter.valueDateGTE;
                var dateTo = accountsFilter.valueDateLTE;

                if (dateFrom && dateTo) {
                    dateFrom = dateFrom.toISOString();
                    dateTo = dateTo.toISOString();
                }
                else {
                    dateTo = new Date();
                    dateTo = dateTo.toISOString();
                    dateFrom = new Date(2000, 12, 24);
                    dateFrom = dateFrom.toISOString();
                }

                if (reportType.key == "PDF") {
                    reportType = "PrintTurnovers"
                } else {
                    reportType = "DownloadTurnoversExcel"
                }

                AccountsService.downloadTransactionReport(accountId, dateFrom, dateTo, reportType);
            }
        };


        vm.refreshTransactionsByFilter = function (params) {
            angular.extend(accountsFilter, params);
            if (params.dateFrom && params.dateTo) {
                accountsFilter.valueDateGTE = params.dateFrom;
                accountsFilter.valueDateLTE = params.dateTo;
                // Clean extended properties
                accountsFilter.dateFrom = undefined;
                accountsFilter.dateTo = undefined;
            }

            if (vm.transactionFilterOptions.datePeriodStart) {
                accountsFilter.valueDateGTE = undefined;
                accountsFilter.valueDateLTE = undefined;
            }

            // For Slo valueDateGTE and valueDateLTE is needed !!
            if (vm.transactionFilterOptions.genericSearch && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
                accountsFilter.valueDateGTE = undefined;
                accountsFilter.valueDateLTE = undefined;
                if (params.dateFrom || params.dateTo) {
                    accountsFilter.dateFrom = params.dateFrom;
                    accountsFilter.dateTo = params.dateTo;
                }
            }

            accountsFilter.page = 0; //TSK-114366

            var type = (params.transactionType) ? $translate.instant("timelineFilter." + params.transactionType.toLowerCase()) : $translate.instant("timelineFilter.all");
            GoogleTagManagerHelperService.pushEvent('Accounts', 'TimelineFilterCategorySelection', type);
            vm.groupByTransactions(true);
        };
        accountsFilter.includeList = ['Merchant'];

        if (vm.apiLocation == 'hr') {
            accountsFilter.includeList.push('TransactionDivision');
        }

        vm.groupByTransactions = function (reset) {

            TransactionsService.getPage(accountsFilter)
                .then(function (response) {
                    vm.busy = false;
                    $(response.result).each(function (i, e) {
                        if (e.transactionType == "Credit") {
                            e.credit = e.amount;
                            e.debit = null;
                        } else {
                            e.credit = null;
                            e.debit = e.amount;
                        }
                        HelperService.extractMerchantData(e);
                    });

                    vm.selectedAccount.transactions = HelperService.mergeRowsForTimeline({
                        existingData: vm.selectedAccount.transactions,
                        newData: response,
                        groupFunction: function (item) {
                            var valueDate = new Date(item.valueDate);
                            valueDate.setHours(0, 0, 0, 0);
                            return valueDate;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: e,
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });
                    _.each(vm.selectedAccount.transactions.result, function (item) {
                        item.valueDate = new Date(item.valueDate);
                    });


                    if (defaultData.APILocation === 'hr' && vm.selectedAccount.accountType.group === 'SavingAccountTypes' && vm.selectedAccount.accountType.id === 'SVGSUB' && vm.selectedAccount.parentAccountId) {
                        var tempAccNumber = '';
                        AccountsService.getAccountById(vm.selectedAccount.parentAccountId)
                            .then(function (response) {
                                tempAccNumber = response.accountNumber;

                                _.forEach(vm.selectedAccount.transactions.result, function (item) {
                                    if (item.transactionType === 'Credit') {
                                        item.fromAccountNumber = tempAccNumber;
                                        item.toAccountNumber = vm.selectedAccount.accountNumber;
                                    } else if (item.transactionType === 'Debit') {
                                        item.toAccountNumber = tempAccNumber;
                                        item.fromAccountNumber = vm.selectedAccount.accountNumber;
                                    }
                                });

                            })
                            .catch(function (error) {
                                NotificationService.showMessage(error, 'error');
                            });
                    }

                    //$log.debug("transactions", vm.selectedAccount.transactions);

                    ///////////PROVJERA ZA POVRAT SREDSTAVA SRB//////////////
                    if (defaultData.APILocation == 'sr' && vm.selectedAccount.transactions.result.length > 0) {
                        _.forEach(vm.selectedAccount.transactions.result, function (transactionItem) {
                            if (transactionItem.transactionType == 'Credit') {
                                if (transactionItem.detail.toAccountNumber != undefined && transactionItem.detail.toAccountNumber != '') {
                                    var firstNumbers = transactionItem.detail.toAccountNumber.substr(0, 3);
                                    if (firstNumbers !== '165' && firstNumbers !== '700' && firstNumbers !== '908') {
                                        transactionItem.retrieval = true;
                                        vm.tempTransactionsList.push(transactionItem)
                                    } else {
                                        vm.tempTransactionsList.push(transactionItem);
                                    }
                                } else {
                                    vm.tempTransactionsList.push(transactionItem);
                                }

                            } else {
                                vm.tempTransactionsList.push(transactionItem);
                            }
                        });
                        if (vm.tempTransactionsList.length > 0) {
                            vm.selectedAccount.transactions.result = vm.tempTransactionsList;
                            vm.tempTransactionsList = [];
                        }
                    }

                }).catch(function (error) {
                    $log.error(error);
                    $log.debug("Transactions loading error");
                });
        };


        vm.loadMoreTransactions = function (reset) {
            vm.getTransactions(reset);
        };

        vm.resetTransactionsFilter = function () {
            vm.accountsTempFilter = _.extend(vm.accountsTempFilter, vm.defaultaccountsTempFilter);
        };

        //---------------------STATEMENTS-------------------------//

        vm.statementsFilter = {
            accountId: undefined,
            orderBy: 'closeDate DESC',
            page: 0,
            pageSize: 10
        };

        vm.loadMoreStatements = function () {

            if (vm.selectedAccount.statements.hasMore !== false) {
                $log.debug("load");
                vm.statementsFilter.page += 1;
                vm.getStatements(false);
            } else {
                $log.debug("no more data");
            }

        };

        vm.getStatements = function (reset) {
            if (reset) {
                vm.statementsFilter.page = 0;
            }
            vm.statementsFilter.accountId = vm.selectedAccount.accountId;
            vm.groupByStatements(reset);
        };

        vm.groupByStatements = function (reset) {

            StatementsService.getPage(vm.statementsFilter)
                .then(function (response) {
                    vm.busy = false;

                    if (vm.selectedAccount != undefined) {

                        vm.selectedAccount.statements = HelperService.mergeRowsForTimeline({
                            existingData: vm.selectedAccount.statements,
                            newData: response,
                            groupFunction: function (item) {
                                return new Date(item.closeDate).getFullYear();
                            },
                            virtualRowFunction: function (e) {
                                return {
                                    isVirtual: true,
                                    closeDateYear: e
                                }
                            },
                            reset: reset,
                            reverse: true
                        });

                    }

                    //$log.debug("statements", vm.selectedAccount.statements);
                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.getSelectedAccountId = function () {
            return {
                accountId: vm.selectedAccount.accountId
            }
        };

        vm.getTransactionDetails = function (timelineItem) {
            timelineItem.account = vm.selectedAccount;
            timelineItem.countryCode = defaultData.APILocation;
        };

        vm.DownloadPDFStatement = function (params, statement) {
            // link for download statement is provided (BiH environment)
            if (vm.countryCode === "bh") {
                StatementsService.downloadPdfStatement(params)
            } else {
                if (statement.downloadInProgress) return;
                statement.downloadInProgress = true;

                StatementsService.downloadPdf(params)
                    .finally(function () {
                        statement.downloadInProgress = false;
                    });
            }
            GoogleTagManagerHelperService.pushEvent('AccountDetails', 'ExternalLink', $translate.instant("accounts.accDownloadPdf"));
        };

        vm.goToEbookPay = function (payType) {
            if (payType === 'Pay In') {
                $state.go('payments.ebookPay.payIn', {
                    accountId: vm.selectedAccount.accountId,
                    myParam: vm.selectedAccount.accountType.group
                });
                GoogleTagManagerHelperService.pushEvent('Account', 'EbookPayIn', 'Ebook PayIn button clicked');
            }
            if (payType === 'Pay Out') {
                $state.go('payments.ebookPay.payOut', {
                    accountId: vm.selectedAccount.accountId,
                    myParam: vm.selectedAccount.accountType.group
                });
                GoogleTagManagerHelperService.pushEvent('Account', 'EbookPayOut', 'Ebook PayOut button clicked');
            }
        };

        vm.goToNewEbook = function () {
            GoogleTagManagerHelperService.pushEvent('Account', 'NewEbook', 'New Ebook button clicked');
            $state.go('newEbook', {
                accountId: vm.selectedAccount.accountId
            });
        };

        vm.closeEbook = function (accountId) {
            $state.go('closeEbook', {
                accountId: accountId
            });
        };

        vm.goToSavingsPayIn = function () {
            //in case of BiH environment navigate to internal transfer
            if (defaultData.APILocation === 'bh') {
                $state.go('payments.internalTransfer.fromAccounts', {
                    accountId: vm.selectedAccount.accountId
                });
            } else {
                if (vm.selectedAccount.currencySymbol != defaultData.DefaultCurrency) {
                    $state.go('payments.savingsPayIn', {
                        accountId: vm.selectedAccount.accountId
                    });
                } else {
                    $state.go('payments.internalTransfer.fromAccounts', {
                        accountId: vm.selectedAccount.accountId
                    });
                }
            }
        };

        vm.goToLoanPayment = function () {
            switch (defaultData.APILocation) {
                case 'hr':
                    $state.go('payments.loanPayment', {
                        accountId: vm.selectedAccount.accountId
                    });
                    break;
                case 'sr':
                case 'mne':
                case 'bh':
                    $state.go('payments.internalTransfer.loan', {
                        loanId: vm.selectedAccount.accountId
                    });
                    break;

                case 'sl':
                    $state.go('payments.loanPayment', {
                        accountId: vm.selectedAccount.accountId
                    });
                    break;
            }
        };

        vm.goToInternalPayment = function () {
            $state.go('payments.internalTransfer.fromAccounts', {
                accountId: vm.selectedAccount.accountId
            });
        };

        vm.goToChangeWithdrawal = function () {
            $state.go('changeWithdrawal', {
                accountId: vm.selectedAccount.accountId
            });
        }

        vm.cancelDepositAction = function () {
            if (vm.selectedAccount.depositAccount.depositIdExternal) {
                /*
                 Deposit order can be canceled only if it is opened from e-banking system.
                 We don't have information if it is opened from e-banking, or in bank directly (there is currently no flag),
                 so we need to try to load deposit order, and if we get result, go to cancel deposit screen.
                 * */
                DepositOrdersService.getPage({
                    referenceNumber: vm.selectedAccount.depositAccount.depositIdExternal
                }).then(function (response) {
                    if (response && response.result[0]) {
                        // Go to cancel deposit screen
                        $state.go('orders.cancelDeposit', {
                            accountId: vm.selectedAccount.accountId
                        });
                    } else {
                        //  Show message to user if there is no result
                        var message = $translate.instant('accounts.depositCantBeCanceledError');
                        NotificationService.showTranslatedMessage('info', message);
                    }
                }).catch(function (error) {
                    NotificationService.showMessage(error, "error");
                });
            }
        };

        vm.markAsFavorite = function () {
            AccountsService.updateAccount(vm.selectedAccount.accountId, {
                "isFavorite": !vm.isFavoriteAccount
            })
                .then(function (response) {
                    vm.isFavoriteAccount = response.isFavorite;
                    GoogleTagManagerHelperService.pushEvent('Accounts', 'FavoriteAccountSelection', (response.isFavorite) ? "Marked as favorite" : "Unmarked as favorite");
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.resetAccountName = function () {
            vm.newDescription = vm.selectedAccount.accountDescription;
            vm.editAccount();
        };

        vm.showEditAccount = function () {
            vm.isEditable = true;
            vm.newDescription = vm.selectedAccount.friendlyName;
        };

        vm.editAccount = function () {
            AccountsService.updateAccount(vm.selectedAccount.accountId, {
                "accountDescription": vm.newDescription
            })
                .then(function (response) {
                    vm.isEditable = false;
                    vm.selectedAccount.friendlyName = vm.newDescription;
                    GoogleTagManagerHelperService.pushEvent('Accounts', 'RenameAccount', 'Submit');
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.updateCardStatus = function (accountId, card) {
            if (card.cardStatusStr == 2) {
                vm.updateCardStatusCro();
                return;
            }

            // If card is selected disable button until updateCardStatus is resolved

            card.selected = true;
            //dummyUpdate(card);
            if (card.temporaryStatus) {
                return;
            } else {

                AccountsService.updateCardStatus(accountId, card)
                    .then(function (response) {
                        //card.cardStatus = response.data.status;

                        var selectedCard = _.find(response.data.cardList, function (c) {
                            return c.cardId == card.cardId;
                        });

                        if (selectedCard && selectedCard.temporaryStatus) {
                            card.temporaryStatus = selectedCard.temporaryStatus;
                        } else {
                            card.temporaryStatus = null;
                        }

                        var BlockCard;
                        if (card.temporaryStatus) {
                            BlockCard = (card.temporaryStatus == 'SentUnblockRequest') ? true : false;
                        } else {
                            BlockCard = (card.cardStatus === 'Active') ? true : false;
                        }

                        //refreshCards()
                        card.selected = false;
                    })
                    .catch(function (error) {
                        card.selected = false;
                        NotificationService.showMessage(error.data, 'error');
                    })
            };
        };

        vm.openCardActivationModal = function (card) {
            AlertService.cardActivation()
                .then(function (result) {
                    if (result) {
                        card.last4Digits = result;
                        vm.updateCardStatus(vm.selectedAccount.accountId, card);
                    }
                })
                .catch(function () {
                    $log.debug('Failed!');
                });
        }

        vm.updateCardStatusCro = function () {
            AccountsService.openBlockModal();
        };

        vm.downloadTransactionReport = function (transaction) {
            var id = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") ? transaction.transactionId : transaction.id;
            TransactionsService.downloadTransactionPdf(id);
        };

        vm.navigateToReclamationForm = function (transaction) {
            var id = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") ? transaction.transactionId : transaction.id;
            $state.go('transactions.reclamation', {
                transactionId: id,
                accountId: $state.params.accountId,
                url: $state.current.name
            });
        };


        //// RETRIEVAL SRB /////

        vm.retrievalRequest = function (item) {
            $state.go('payments.domesticPayment', {
                myParam: 'retrieval',
                transactionDetails: item
            });
        };

        vm.getPaymentTemplatesPage = function () {
            PaymentTemplatesService.getPage()
                .then(function (response) {
                    vm.templateList = response;
                    $log.debug(vm.templateList);
                }
                    /*, function (error) {
                     console.log(error);
                     }*/
                )
                .catch(function (error) {
                    $log.error(error);
                });
        };

        var filterEBookSavingRequest = function (reset) {
            if (vm.eBookSavingsRequests.result && vm.eBookSavingsRequests.result.length > 0) {
                vm.eBookSavingsGrouped = HelperService.mergeRowsForTimeline({
                    existingData: vm.eBookSavingsGrouped,
                    newData: vm.eBookSavingsRequests,
                    groupFunction: function (item) {
                        var date = new Date(item.dateOfCreation);
                        date.setHours(0, 0, 0, 0);
                        return date;
                    },
                    virtualRowFunction: function (e) {
                        var month = new Date(e).getMonth() + 1;
                        var day = new Date(e).getDate();
                        var virtualRow = {
                            isVirtual: true,
                            date: new Date(e),
                            month: month,
                            day: day
                        };
                        $log.debug("virtualRow", e, virtualRow);
                        return virtualRow;
                    },
                    reset: reset,
                    reverse: false
                });
                $log.debug("eBookSavingsGrouped", vm.eBookSavingsGrouped);
            }
        };


        vm.navigateToPaymentForm = function (templateParams) {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" && templateParams.template.paymentSubTypeGroup == "NotSupportedPayment") {
                AlertService.infoAlert({
                    text: "paymentsOverview.notSupportedPaymentMsg"
                })
                return;
            }

            var stateLink;
            var params = {
                templateId: templateParams.id
            };
            var template = templateParams.template;

            switch (templateParams.type) {
                case "LocalCurrencyTransfer":
                    stateLink = "payments.internalTransfer.fromTemplate";
                    if (vm.countryCode == 'sl') {
                        stateLink = "payments.domesticPayment.fromTemplatesToPay";
                    }
                    break;
                case "SEPAPayment":
                case "DomesticPayment":
                case "RevenuePayment":
                    stateLink = "payments.domesticPayment.fromTemplate";
                    if (vm.countryCode == 'sl') {
                        stateLink = "payments.domesticPayment.fromTemplatesToPay";
                    }
                    break;
                case "CurrencyExchange":
                    stateLink = "payments.currencyConversion.fromTemplate";;
                    break;
                case "ForeignCurrencyTransfer":
                    stateLink = "payments.internalTransfer.fromTemplate";
                    break;
                case "ForeignPayment":
                    stateLink = "payments.internationalPayment.fromTemplate";
                    if (vm.countryCode === 'sl') {
                        stateLink = "payments.internationalPayment.fromTemplatesToPay";
                    }
                    break;
            }


            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                if (template.paymentTypeGroup == "LocalCurrencyTransfer" && template.paymentSubTypeGroup == "SEPAPayment") {
                    stateLink = 'payments.domesticPayment.fromTemplate';
                } else if (template.paymentTypeGroup == "LocalCurrencyTransfer" && template.paymentSubTypeGroup == "LocalCurrencyTransfer") {
                    stateLink = 'payments.internalTransfer.fromTemplate';
                }
                if (stateLink) {
                    $state.go(stateLink, params);
                }

            } else {
                if (stateLink) {
                    $state.go(stateLink, params);
                }
            }
        };


        var loadeSavingBookRequests = function (reset) {
            if (reset) {
                vm.eSavingBookRequestsFilter.page = 0;
            } else {
                vm.eSavingBookRequestsFilter.page += 1;
            }

            ESavingBookRequestsService.getESavingBookRequests(vm.eSavingBookRequestsFilter)
                .then(function (response) {
                    vm.eBookSavingsRequests = response;
                    filterEBookSavingRequest(reset);
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.loadMoreSavingBookRequests = function () {
            loadeSavingBookRequests(false);
        };

        vm.showeSavingBookRequests = function () {
            vm.displayeBookSavingRequest = !vm.displayeBookSavingRequest;
            GoogleTagManagerHelperService.pushEvent('Account', 'EbookRequest', (vm.displayeBookSavingRequest) ? 'Show Ebook Requests button clicked' : 'Hide Ebook Requests button clicked');
            if (!vm.eBookSavingsRequests.result) {
                loadeSavingBookRequests(true);
            }
        };

        /////////////////////connected cards transactions for SRB and MNE///////////////////////

        vm.connectedCardTransactionsList = [];
        vm.connectedCardTransactionsFilter = {};

        vm.refreshCardTransactionsByFilter = function (params) {
            angular.extend(vm.connectedCardTransactionsFilter, params);

            vm.connectedCardTransactionsFilter.page = 0; //TSK-114366

            if (vm.transactionCardsFilterOptions.genericSearch) {
                // For Slo valueDateGTE and valueDateLTE is needed
                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') {
                    vm.connectedCardTransactionsFilter.valueDateLTE = undefined;
                    vm.connectedCardTransactionsFilter.valueDateGTE = undefined;
                } else {
                    vm.connectedCardTransactionsFilter.dateFrom = undefined;
                    vm.connectedCardTransactionsFilter.dateTo = undefined;
                }
            }

            vm.connectedCardTransactionsFilter.accountId = vm.selectedAccount.accountId;

            // if(vm.countryCode === 'sl' && params.selectedStatementDate){
            //   vm.connectedCardTransactionsFilter.datePeriodStart = params.selectedStatementDate;
            //   vm.connectedCardTransactionsFilter.selectedStatementDate = undefined;
            // }

            var type = (params.transactionType) ? $translate.instant("timelineFilter." + params.transactionType.toLowerCase()) : $translate.instant("timelineFilter.all");
            GoogleTagManagerHelperService.pushEvent('Accounts ConnectedCards', 'TimelineFilterCategorySelection', type);
            vm.groupByConnectedCardTransactions(true);
        };

        vm.getConnectedCardsTransactions = function (reset) {

            if (!reset && vm.connectedCardTransactionsList != undefined && vm.connectedCardTransactionsList.hasMore != undefined && vm.connectedCardTransactionsList.hasMore == false) {
                return;
            }

            if (reset) {
                vm.connectedCardTransactionsFilter.page = 0;
            } else {
                vm.connectedCardTransactionsFilter.page += 1;
            }

            //getDatesFromConnectedCardsSelectedPeriod();
            vm.connectedCardTransactionsFilter.accountId = vm.selectedAccount.accountId;

            vm.groupByConnectedCardTransactions(reset);
        };

        function getDatesFromConnectedCardsSelectedPeriod() {
            if (vm.connectedCardsSelectedPeriod.key == 'today') {
                var range = HelperService.getToday();
                vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'yesterday') {
                var range = HelperService.getYesterday();
                vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisWeek') {
                var range = HelperService.getThisWeek();
                vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisMonth') {
                var range = HelperService.getLast30Days();
                vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisYear') {
                var range = HelperService.getThisYear();
                vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'all') {
                vm.connectedCardTransactionsFilter.valueDateGTE = null;
                vm.connectedCardTransactionsFilter.valueDateLTE = null;

                if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                    var range;
                    switch (vm.selectedAccount.accountType.group) {
                        case 'SavingAccountTypes':
                        case 'CardAccountTypes':
                            range = HelperService.getAllFrom2LastYears();
                            break;
                        default:
                            range = HelperService.getThisYear();

                    }
                    vm.connectedCardTransactionsFilter.valueDateGTE = range.firstDay;
                    vm.connectedCardTransactionsFilter.valueDateLTE = range.lastDay;
                }
            }
            if (!vm.transactionCardsFilterOptions.datePeriodStart) {
                vm.transactionCardsFilterOptions.dateFrom = angular.copy(vm.connectedCardTransactionsFilter.valueDateGTE);
                vm.transactionCardsFilterOptions.dateTo = angular.copy(vm.connectedCardTransactionsFilter.valueDateLTE);
            }
        }

        vm.changeConnectedCardsTransactionPeriod = function (period) {
            if (period) {
                vm.connectedCardsSelectedPeriod = period;
                vm.transactionCards.transactionFilterYearDateRangeValid = true;
                vm.busy = false;
                //vm.getConnectedCardsTransactions(true);
                vm.resetCardsFilter();
            }
            GoogleTagManagerHelperService.pushEvent('Accounts ConnectedCards', 'TabPeriodFilter', $translate.instant(period.description));

        };

        vm.groupByConnectedCardTransactions = function (reset) {

            TransactionsService.getPage(vm.connectedCardTransactionsFilter)
                .then(function (response) {
                    vm.busy = false;

                    $(response.result).each(function (i, e) {
                        if (e.transactionType == "Credit") {
                            e.credit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                            e.debit = null;
                        } else {
                            e.credit = null;
                            e.debit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                        }

                        HelperService.extractMerchantData(e);
                    });

                    vm.connectedCardTransactionsList = HelperService.mergeRowsForTimeline({
                        existingData: vm.connectedCardTransactionsList,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.valueDate);
                            date.setHours(0, 0, 0, 0); //sort only by date, not hours
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: e,
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });
                    _.each(vm.connectedCardTransactionsList.result, function (item) {
                        item.valueDate = new Date(item.valueDate);
                    })
                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.openCardTransactions = function (accountId, card, $event) {


            if (vm.selectedAccount.cardList) {
                var previousState = card.collapsed;
                // Close all other transactions if they were opened, and open/close trnsactions for selected card
                _.forEach(vm.selectedAccount.cardList, function (trn) {
                    if (trn.cardId !== card.cardId) {
                        trn.collapsed = false;
                    }
                    if (trn.cardId === card.cardId) {
                        trn.collapsed = !previousState;
                    }
                    vm.connectedCardsSelectedPeriod = vm.periodList[2];
                });
            }

            if (card.collapsedRes)
                card.collapsedRes = false;

            vm.transactionCardsFilterOptions = {
                genericSearch: "",
                transactionType: undefined,
                amountGTE: undefined,
                amountLTE: undefined,
                datePeriodStart: undefined
            };

            vm.connectedCardTransactionsFilter = {
                accountId: accountId,
                cardId: card.cardId,
                page: 0,
                pageSize: 10,
                orderBy: 'ValueDate DESC',
                isCardTransaction: true,
                includeList: ['Merchant']
            };

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl") {
                vm.connectedCardTransactionsFilter.orderBy = 'ValueDate DESC, transactionId desc';
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr') {
                vm.connectedCardTransactionsFilter.includeList.push('transactionDivision');
            }

            setDefaultCurrencyToCardsFilter();

            angular.extend(vm.connectedCardTransactionsFilter, vm.transactionCardsFilterOptions);

            if (vm.countryCode == 'bh')
                vm.connectedCardTransactionsFilter.includeList.push('transactionDivision');


            if (card.collapsed) {
                getDatesFromConnectedCardsSelectedPeriod();
                vm.getConnectedCardsTransactions(true);
                var e = angular.element($event.currentTarget);
                // e[0].scrollIntoView();
                e.scrollTop = 0;
                e.scrollTop += 100;
                /* var viewportOffset = e[0].getBoundingClientRect();
                 // these are relative to the viewport, i.e. the window
                 var top = viewportOffset.top;
                 var left = viewportOffset.left;
                 $window.scrollTo(top-100, left);*/
            }
        };

        vm.loadMoreConnectedCardsTransactions = function (reset) {
            if (vm.connectedCardTransactionsList.result && vm.connectedCardTransactionsList.result.length > 0 && !vm.collapsed) {
                vm.getConnectedCardsTransactions(false);
            }
        };
        /////////////////////connected cards reservations for SRB and MNE ////////////////////////////////////////////////////////////////
        vm.collapsedRes = false;
        vm.connectedCardReservationsList = [];
        vm.connectedCardReservationsFilter = {};

        vm.getConnectedCardsReservations = function (reset) {

            if (!reset && vm.connectedCardReservationsList != undefined && vm.connectedCardReservationsList.hasMore != undefined && vm.connectedCardReservationsList.hasMore == false) {
                return;
            }

            if (reset) {
                vm.connectedCardReservationsFilter.page = 0;
            } else {
                vm.connectedCardReservationsFilter.page += 1;
            }

            if (vm.connectedCardsSelectedPeriod.key == 'today') {
                var range = HelperService.getToday();
                vm.connectedCardReservationsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardReservationsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'yesterday') {
                var range = HelperService.getYesterday();
                vm.connectedCardReservationsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardReservationsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisWeek') {
                var range = HelperService.getThisWeek();
                vm.connectedCardReservationsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardReservationsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisMonth') {
                var range = HelperService.getLast30Days();
                vm.connectedCardReservationsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardReservationsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'thisYear') {
                var range = HelperService.getThisYear();
                vm.connectedCardReservationsFilter.valueDateGTE = range.firstDay;
                vm.connectedCardReservationsFilter.valueDateLTE = range.lastDay;
            } else if (vm.connectedCardsSelectedPeriod.key == 'all') {
                vm.connectedCardReservationsFilter.valueDateGTE = null;
                vm.connectedCardReservationsFilter.valueDateLTE = null;
            }

            //vm.connectedCardReservationsFilter.accountId = vm.selectedAccount.accountId;

            vm.groupByConnectedCardReservations(reset);
        };

        vm.changeConnectedCardsReservationPeriod = function (period) {
            if (period) {
                vm.connectedCardsSelectedPeriod = period;
                vm.busy = false;
                vm.getConnectedCardsReservations(true);
            }
        };

        vm.groupByConnectedCardReservations = function (reset) {
            ReservationsService.getPage(vm.connectedCardReservationsFilter)
                .then(function (response) {
                    vm.busy = false;

                    $(response.result).each(function (i, e) {
                        if (e.transactionType == "C") {
                            e.credit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                            e.debit = null;
                        } else {
                            e.credit = null;
                            e.debit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                        }
                        HelperService.extractMerchantData(e);
                    });

                    vm.connectedCardReservationsList = HelperService.mergeRowsForTimeline({
                        existingData: vm.connectedCardReservationsList,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.valueDate);
                            date.setHours(0, 0, 0, 0); //sort only by date, not hours
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: new Date(e),
                                month: month,
                                day: day
                            };
                            //$log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });

                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.openCardReservations = function (accountId, card, $event) {
            if (vm.selectedAccount.cardList) {
                var previousState = card.collapsedRes;
                vm.collapsedRes = card.collapsedRes;
                // Close all other transactions if they were opened, and open/close trnsactions for selected card
                _.forEach(vm.selectedAccount.cardList, function (trn) {
                    if (trn.cardId !== card.cardId) {
                        trn.collapsedRes = false;
                    }
                    if (trn.cardId === card.cardId) {
                        trn.collapsedRes = !previousState;
                    }
                    vm.connectedCardsSelectedPeriod = vm.periodList[2];
                });
            }

            if (card.collapsed)
                card.collapsed = false;

            vm.connectedCardReservationsFilter = {
                accountId: accountId,
                cardId: card.cardId,
                /*isCardTransaction: true,*/
                page: 0,
                pageSize: 10,
                orderBy: 'ValueDate DESC',
                includeList: ['Merchant']
            };

            if (vm.countryCode == 'bh') {
                vm.connectedCardReservationsFilter.includeList.push('transactionDivision');
            }

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr') {
                vm.connectedCardReservationsFilter.includeList.push('transactionDivision');
            }

            if (card.collapsedRes) {
                vm.getConnectedCardsReservations(true);
                var e = angular.element($event.currentTarget);

                e.scrollTop = 0;
                e.scrollTop += 100;

            }
        };

        vm.loadMoreConnectedCardsReservations = function (reset) {
            if (vm.connectedCardReservationsList.result && vm.connectedCardReservationsList.result.length > 0 && !vm.collapsedRes) {
                vm.getConnectedCardsReservations(reset);
            }
        };

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// not finished yet - api side

        // Method to load only paymentPlanList for selected account. Added for optimization
        vm.toggleRepaymentPlan = function () {

            var gtmDescLabel = (!vm.showRepaymentPlan) ? $translate.instant("accounts.accRepaymentPlan") : $translate.instant("accounts.closeRepaymentPlan");
            GoogleTagManagerHelperService.pushEvent('AccountDetails', 'LoanAccountRepaymentPlanSelection', gtmDescLabel);

            // If we have already loaded repayment plan list, no need to load it again.
            if (vm.repaymentLoaded) {
                vm.showRepaymentPlan = !vm.showRepaymentPlan;
            } else {
                var paymentPlanFilter = {
                    includeList: ['loanAccountPaymentPlanList']
                };
                return AccountsService.getAccountById(vm.selectedAccount.accountId, paymentPlanFilter).then(function (response) {
                    if (response && response.loanAccountPaymentPlanList) {
                        vm.selectedAccount.loanAccountPaymentPlanList = response.loanAccountPaymentPlanList;
                        vm.repaymentLoaded = true;
                        vm.showRepaymentPlan = true;
                    }
                }).catch(function (err) {
                    NotificationService.showMessage(err, 'error');
                });
            }

        };

        vm.goToForeignCurrencyTransfer = function () {
            $state.go('payments.savingsPayIn', {
                accountId: vm.selectedAccount.accountId
            });
        };

        vm.downloadContract = function () {
            DepositOrdersService.downloadContract(vm.selectedAccount.depositAccount.depositIdExternal);
        };

        vm.loadPaymentTemplates = function () {
            vm.showTemplatesView = !vm.showTemplatesView;
            if (vm.showTemplatesView) {
                if (!vm.templateList) {
                    vm.getPaymentTemplatesPage();
                }
            }
        };

        // reservation list related to BiH environment
        var reservationsFilter = {
            page: 0,
            pageSize: 10,
            orderBy: 'reservationDate DESC'
        };

        vm.exportTransactions = function () {
            var dateFrom = accountsFilter.valueDateGTE;
            var dateTo = accountsFilter.valueDateLTE;
            if (dateFrom) {
                dateFrom = dateFrom.toISOString();
            }

            if (dateTo) {
                dateTo = dateTo.toISOString();
            }

            AccountsService.getTransactionReportLinkSlo(vm.selectedAccount.accountId, dateFrom, dateTo).then(function (reportLink) {
                sessionStorage.setItem('downloadInProgress', 'download');
                var nonBlockingWindow = window.open('', '_self')
                nonBlockingWindow.onclose = function () {
                    sessionStorage.setItem('downloadInProgress', '');
                };
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(reportLink.url);
            }).catch(function (error) {
                NotificationService.showMessage(error, 'error');
                $log.debug(error);
            });;
        }


        function loadAccountReservations(reset) {
            if (reset) {
                reservationsFilter.page = 0;
            } else {
                reservationsFilter.page++;
            }
            reservationsFilter.accountId = vm.selectedAccount.accountId;
            AccountsService.getReservations(reservationsFilter)
                .then(function (response) {
                    $(response.result).each(function (i, e) {
                        if (e.transactionType == "C") {
                            e.credit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                            e.debit = null;
                        } else {
                            e.credit = null;
                            e.debit = e.amount;
                            e.amount = $filter('number')(e.amount, 2);
                        }
                        HelperService.extractMerchantData(e);
                    });
                    vm.selectedAccount.reservations = HelperService.mergeRowsForTimeline({
                        existingData: vm.selectedAccount.reservations,
                        newData: response,
                        groupFunction: function (item) {
                            var valueDate = new Date(item.reservationDate);
                            valueDate.setHours(0, 0, 0, 0);
                            return valueDate;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: new Date(e),
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });
                    $log.debug("Accounts reservations: ", vm.selectedAccount.reservations);
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }

        vm.formatIban = function (iban, account) {
            // if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
            if (account && (account.accountType.group === "CardAccountTypes" || account.accountType.group === "CurrentAccount") &&
                (vm.countryCode === 'sl' || vm.countryCode === 'hr')) {
                return iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
            } else {
                return iban
            }
        };

        vm.formatCardNumber = function (cn) {
            return cn.replace(/(.{4})/g, '$1 ').trim()
        };

        vm.loadMoreReservations = function () {
            loadAccountReservations(false);
        };

        //bosnia specific TSK-9380 Pdf tab - za prikaz izvoda na aplikaciji ebank - retail
        vm.checkForAccountTypeId = function (id) {
            return _.includes(['1040', '1041', '1042'], id);
        };

        vm.pushGtmPayInEvent = function (accType) {
            GoogleTagManagerHelperService.pushEvent('AccountDetails', accType + 'PayIn', accType + ' PayIn button clicked');
        };

        vm.additionalCheck = function (transaction, accountTypeId) {
            var thisMonth = HelperService.getThisMonth();

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh') {
                if (new Date(transaction.valueDate) > thisMonth.firstDay && new Date(transaction.valueDate) < thisMonth.lastDay) {
                    return _.includes(['1041', '1042', '1051'], accountTypeId);
                } else {
                    return false;
                }
            } else {
                if (new Date(transaction.valueDate) > thisMonth.firstDay && new Date(transaction.valueDate) < thisMonth.lastDay) {
                    return _.includes(['1041', '1042', '1051'], accountTypeId);
                } else {
                    return false;
                }
            }
        };

        vm.checkIfAllowed = function (isRes, _id, card, currencySymbol) {

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr') {
                var cardNumber = vm.selectedAccount.cardNumber;
                $state.go('transactionDivison.create', {
                    transactionId: _id.id, description: _id.detail.purpose,
                    card: cardNumber, currencySymbol: currencySymbol
                });
            } else {
                TransactionDivisionService.isTransactionDivisionAllowed()
                    .then(function (response) {
                        if (response && response.result) {
                            if (isRes) {
                                $state.go('transactionDivison.create', { reservationId: _id, card: card, currencySymbol: currencySymbol });
                            } else {
                                $state.go('transactionDivison.create', { transactionId: _id, card: card, currencySymbol: currencySymbol });
                            }
                        } else {
                            // call info modal
                            AlertService.infoAlert({
                                text: "transactionDivision.infoIfLastDay"
                            }).then(function () {
                            });
                        }
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
        };

        vm.downloadFile_ESavingBook = function (docType) {
            ESavingBookRequestsService.getESavingBookRequests_downloadByFilter(vm.selectedAccount.id, docType, vm.selectedAccount.accountId)
                .then(function (response) { })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }

        vm.downloadFile_Deposits = function (docType) {
            DepositOrdersService.depositOrders_downloadByFilter(vm.selectedAccount, docType)
                .then(function (response) { })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }


        vm.init();
    }
}());
