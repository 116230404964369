<div class="clearfix">
    <ul class="tab-nav" ng-class="{'tn-vertical': vertical, 'tn-justified': justified, 'tab-nav-right': right}" ng-transclude></ul>
    <div class="tab-content">
        <div class="tab-pane"
             ng-repeat="tab in tabs"
             ng-class="{active: tab.active}"
             tab-content-transclude="tab">
        </div>
    </div>
</div> 
