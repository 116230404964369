(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:SessionInjectorService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('SessionInjectorService', SessionInjectorService);

  function SessionInjectorService($rootScope, $log, $window, SessionService, $q, $transitions, HelperService) {

    $transitions.onStart({}, function (trans) {
      if (!HelperService.isValidAccordingToPasswordConfig()) {
            return false;
      }
    });

    $transitions.onSuccess({}, function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    var SessionInjectorServiceBase = {};
    var numberOfSessionExceptions = 0;
    SessionInjectorServiceBase.request = function (config) {

      var token = $window.sessionStorage.getItem('ngStorage-ct_accessToken');
      if (!config.url.includes('/token')) {
        config.headers.authorization = "Bearer " + token;
      }
      $log.debug("SessionInjectorService; Bearer1= " + config.headers.authorization);

      return config;
    };

    SessionInjectorServiceBase.response = function (response) {
      return response;
    };

    SessionInjectorServiceBase.responseError = function (response) {
      if (response.status === 400) {
        if (response.data.resultList && response.data.resultList.length > 0) {
          var error = response.data.resultList[0];
          if (error.key === 'MULTISESSION_ERR') {
            var sum = numberOfSessionExceptions;
            if (sum === 0) {
              numberOfSessionExceptions += 1;
              $rootScope.$broadcast('onSessionExpired', error.description);
            }
            if (sum === 1) {
              numberOfSessionExceptions += 1;
            }
            return response;
          } else {
            return $q.reject(response);
          }
        }
      } else if (response.status === 401 || response.status === 403) {
        SessionService.logout();
      }
      return $q.reject(response);
    };

    return SessionInjectorServiceBase;
  }
}());
