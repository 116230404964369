<section id="main">
  <div class="container-fluid m-t-30">
    <div class="row f-14">

      <div class="col-sm-12">
        <div class="app-white-card m-b-15">
          <div class="intp-header-wrapper bottom-border" ng-show="!sepa.steps.step3">
            <div class="row p-10">
              <div class="col-xs-12">
                <h2 class="title-style" translate="sepa.newSepaHeader">New SEPA</h2>
              </div>
            </div>
          </div>
          <div ng-show="sepa.steps.step1">
            <form name="sepa.sepaForm" novalidate>
              <div class="intp-form-container bottom-border">
                <div class="row">
                  <div class="col-sm-6 p-0">
                    <div class="col-sm-12">
                      <label class="ntd-form-label m-b-5" translate="sepa.debitAccountSelectLabel"></label>
                    </div>
                    <div class="col-sm-12">

                      <div class="accounts-dropdown m-b-10" ng-if="sepa.accountsFrom.length > 0">

                        <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                             data-ng-click="sepa.fromAccountDropdown = !sepa.fromAccountDropdown"
                             ng-style="sepa.model.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + sepa.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                          <h4 class="no-wrap-text bold-font m-b-0">
                            <b
                              class="col-xs-10 p-0 m-t-10  no-wrap-text">{{sepa.model.fromAccountSelect.friendlyName}}</b>
                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                              <i class="zmdi"
                                 ng-class="{'zmdi-star favorite-account':sepa.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!sepa.model.fromAccountSelect.isFavorite}"></i></span>
                          </h4>
                          <p class="m-b-0 m-t-0 iban-style">{{sepa.model.fromAccountSelect.iban}}</p>
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                            {{sepa.model.fromAccountSelect.availableBalance| number:2}}
                            {{sepa.model.fromAccountSelect.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                          <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                        </div>
                        <div class="from-accounts-list m-l-15" ng-show="sepa.fromAccountDropdown" scrollable>
                          <div class="accounts-list-item p-0" data-ng-repeat="item in sepa.accountsFrom"
                               data-ng-click="sepa.setAccountFrom(item);">

                            <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                                 ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                              <h4 class="no-wrap-text bold-font m-b-0">
                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                                  <i class="zmdi"
                                     ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                              </h4>
                              <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                              <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                {{item.availableBalance| number:2}} {{item.currencySymbol}}
                              </p>
                              <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="accounts-dropdown m-b-10" ng-if="sepa.accountsFrom.length == 0">

                        <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                             ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                          <div class="m-t-30 m-b-30">
                            <p translate="internalTransfer.nodata"></p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 p-0">

                    <div class="col-xs-12">
                      <label class="domp-form-label ng-scope" translate="sepa.limitType">Limit type</label>
                    </div>
                    <div class="col-xs-12 p-t-5">
                      <div class="form-group" ng-class="{'m-b-0': sepa.model.settingTypeSelected.id == '0' && sepa.sepaTempObj.formSubmitted}">
                        <select class="form-control input-field"
                                name="sepaType"
                                ng-class="{'domp-error': sepa.model.settingTypeSelected.id == '0' && sepa.sepaTempObj.formSubmitted}"
                                ng-model="sepa.model.settingTypeSelected"
                                ng-options="option.description for option in sepa.settingType"
                                ng-change="sepa.onSettingTypeChange()"
                                required>
                        </select>
                      </div>
                      <span class="c-red" ng-show="sepa.model.settingTypeSelected.id == '0' && sepa.sepaTempObj.formSubmitted"
                            translate="sepa.requiredSettingTypeError">
                      </span>
                    </div>

                    <div ng-if="sepa.model.settingTypeSelected.id != '4'" class="col-xs-12">
                      <label class="ntd-form-label m-b-5" translate="sepa.amountInputLabel"></label>
                    </div>
                    <div ng-if="sepa.model.settingTypeSelected.id != '4'" class="col-xs-12">
                      <div class="input-group">
                        <input type="text"
                               class="form-control input-field t-a-r ng-empty ng-invalid ng-invalid-required ng-invalid-maxlength ng-valid-min ng-valid-max ng-dirty ng-valid-parse ng-touched"
                               name="amountInput" id="amountInput" autocomplete="off" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                               amount-input-mask="" maxlength="12" ng-model="sepa.model.amountInput"
                               ng-required="sepa.model.settingTypeSelected.id != '4'"
                               ng-class="{'domp-error': sepa.paymentForm.amountInput.$invalid && sepa.model.formSubmitted}">
                        <span class="input-group-btn">
                          <button type="button"
                                  class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero ng-binding"
                                  style="border:1px solid #062a42;">
                            {{sepa.model.fromAccountSelect.currencySymbol}}
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="intp-form-container bottom-border">
                <div class="row">
                  <div class="col-xs-6 t-a-l">
                    <button type="button" class="btn btn-primary" translate="sepa.cancelBtnLabel"
                            ng-click="sepa.cancel()">
                    </button>
                  </div>
                  <div class="col-xs-6 t-a-r">
                    <button type="button" class="btn btn-default" translate="sepa.refundComplaint.next"
                            ng-disabled="!sepa.sepaForm.$valid" ng-click="sepa.confirm(sepaForm);">
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- =============== -->
          <!-- Second step     -->
          <!-- =============== -->
          <div ng-show="sepa.steps.step2">

            <div class="intp-form-container bottom-border">
              <div class="row">
                <div class="col-sm-4">
                  <label class="domp-form-label-review" translate="settings.forAccount"></label>:
                </div>
                <div class="col-sm-8 domp-form-label-resume">
                 {{sepa.model.fromAccountSelect.iban}} - {{sepa.model.fromAccountSelect.accountDescription}}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label class="domp-form-label-review" translate="sepa.limitType"></label>:
                </div>
                <div class="col-sm-8 domp-form-label-resume">
                  {{sepa.model.settingTypeSelected.description}}
                </div>
              </div>
              <div ng-if="sepa.model.settingTypeSelected.id != '4'" class="row">
                <div class="col-sm-4">
                  <label class="domp-form-label-review" translate="termDeposit.amountInputLabel"></label>
                </div>
                <div class="col-sm-8 domp-form-label-resume">
                  {{sepa.model.amountInput | number:2}}
                  {{sepa.model.fromAccountSelect.currencySymbol}}
                </div>
              </div>

            </div>

            <div class="intp-form-container bottom-border">
              <div class="row">
                <div class="col-md-12 m-t-20 t-a-r">
                    <!--<button class="btn btn-primary m-r-5 pull-left"-->
                            <!--data-ui-sref="sepa.settings"-->
                            <!--translate="termDeposit.cancelActionLabel">Cancel-->
                    <!--</button>-->
                    <button class="btn btn-primary pull-left"
                            ng-click="sepa.steps = {}; sepa.steps.step1 = true;"
                            translate="sepa.back">Back
                    </button>
                    <button class="btn btn-default pull-right"
                            ng-disabled="sepa.okButtonDisabled"
                            translate="sepa.confirmBtnLabel"
                            ng-click="sepa.ok()">Confirm
                    </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div ng-show="sepa.steps.step1" data-ng-include="'orders/deposit-overview-timeline.tpl.html'"></div>-->
  </div>
</section>
