<section ng-controller="InternationalPaymentCroCtrl as internationalPayment">
  <div>
    <span ng-if="internationalPayment.steps.step1">
      <!-- START Title -->
      <div class="domp-header-wrapper col-sm-12 p-0">
        <div class="domp-header-row">
          <h2 class="intp-title f-28" translate="internationalPayment.international"></h2>
        </div>
      </div>
      <!-- END Title-->
      <div class="domp-select-section col-sm-12 p-0">
        <!-- ADD SELECT FROM TEMPLATE HERE ! -->
        <!-- FIRST BOX -->
        <form name="internationalPayment.paymentForm" novalidate>

          <!--SELECT TEMPLATE CUSTOM -->

          <div class="domp-template-filter-container">
            <div class="domp-select-template" ng-click="internationalPayment.toggleTemplateFilterSelection()"
                 ng-show="internationalPayment.templateName == ''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5"
                       translate="domesticPayment.selectFromTemplate">Select from template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>
            <div class="domp-selected-template" ng-show="internationalPayment.templateName != ''">
              <div class="select-template label-template-selected p-l-10 p-t-5"
                   ng-show="internationalPayment.templateName != ''">
                <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>
                <label class="p-l-10 selected-template-label"><b>{{internationalPayment.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5"
                   ng-click="internationalPayment.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection"
                 ng-class="{'opened': internationalPayment.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width: 50%">
                  <input id="searchTemplatesInput" type="text" class="search-input"
                         ng-model="internationalPayment.tmpSearchText.name"
                         ng-change="internationalPayment.searchTemplate(internationalPayment.tmpSearchText.name)"
                         placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item"
                       data-ng-repeat="template in internationalPayment.templateList.result| filter:internationalPayment.tmpSearchText"
                       ng-click="internationalPayment.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-r-0 domp-select-label"><i
                          class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i></div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
          <!-- END FIRST ROW-->

          <!-- START SECOND BOX-->
          <div class="receiver-section " style="padding: 10px;background-color:#fff;">
            <div class="row">
              <!-- START From Account - Custom Dropdown -->
              <div class="col-md-6">
                <label class="domp-form-label " translate="payments.fromAccount"></label>
                <div class="accounts-dropdown m-b-10">
                  <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                       data-ng-click="internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown"
                       ng-style="internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internationalPayment.tempForpObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                        {{internationalPayment.tempForpObj.fromAccountSelect.friendlyName}}</b>
                      <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                        <i class="zmdi"
                           ng-class="{'zmdi-star favorite-account':account.isFavorite, 'zmdi-star-outline':!account.isFavorite}"></i>
                      </span>
                    </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{internationalPayment.tempForpObj.fromAccountSelect.iban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{internationalPayment.tempForpObj.fromAccountSelect.availableBalance| number:2}}
                      {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    <span class="caret-icon">
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                    </span>
                  </div>
                  <div class="from-accounts-list" ng-show="internationalPayment.showAccountToDropdown"
                       scrollable>
                    <div class="accounts-list-item p-0"
                         data-ng-repeat="item in internationalPayment.accountsFrom"
                         data-ng-click="internationalPayment.setChargeAccount(item); internationalPayment.showAccountToDropdown = !internationalPayment.showAccountToDropdown">

                      <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                           ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                              ng-class="{'zmdi-star favorite-account':item.isFavorite, 'zmdi-star-outline':!item.isFavorite}"></i></span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{item.availableBalance| number:2}} {{item.currencySymbol}}
                        </p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- END From Account - CUSTOM DROPDOWN-->
              <!-- START Amount and Date -->
              <div class="col-md-6">
                <!--<div class="row">-->
                <!-- START Amount -->
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput"
                         translate="internationalPayment.totalAmount">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput"
                           id="amountInput"
                           autocomplete="off"
                           placeholder="{{'eInvoicesModal.amountPlaceholder'| translate}}"
                           amount-input-mask
                           maxlength="12"
                           ng-change="internationalPayment.instantPaymentValidation()"
                           ng-model="internationalPayment.tempForpObj.amountInput" required
                           ng-class="{'domp-error': internationalPayment.paymentForm.amountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <span class="input-group-btn">
                      <button type="button"
                              class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                              style="border:1px solid #062a42;">
                        {{internationalPayment.tempForpObj.fromAccountSelect.currencySymbol}}
                      </button>
                    </span>
                  </div>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.amountInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="internalTransfer.amountInputError">
                  </span>
                  <!--</div>-->
                </div>
                <!-- End Amount-->
                <!-- START Date-->
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput"
                         translate="internationalPayment.paymentDate">Date:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="shortDate" show-weeks="false"
                           ng-model="internationalPayment.tempForpObj.dueDateInput"
                           show-weeks="false" is-open="internationalPayment.datePickerOpened"
                           current-text="{{'core.period.today' | translate}}"
                           ng-change="internationalPayment.instantPaymentValidation()"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           datepicker-options="internationalPayment.dateOptions"
                           date-disabled="internationalPayment.disabled(date, mode)" readonly>
                    <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="input-group-btn">
                      <button ng-switch-default data-ng-click="internationalPayment.openDatePicker()" type="button"
                              class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                              style="border:1px solid #062a42;">
                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                      </button>
                      <button ng-switch-when="sl" class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                              style="border:1px solid #062a42;">
                        <i class="icon icon-calendar" style="font-size: 21px;"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <!-- END Date-->
            </div>

            <!-- END Amount and Date -->
            <!-- START  Real Debitor -->
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <uib-accordion close-others="true" class="domp-sepa-accordion">
                    <div uib-accordion-group>
                      <uib-accordion-heading>
                        <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                            <label class="domp-form-label-sepa"
                                   translate="internationalPayment.sepaDebitorLabel">
                            </label>
                          </div>
                          <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                            class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                        </div>
                      </uib-accordion-heading>
                      <input type="text" class="form-control input-field"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                             ng-model="internationalPayment.tempForpObj.payersSepaInput1" style="margin-bottom:10px;">
                      <input type="text" class="form-control input-field"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                             ng-model="internationalPayment.tempForpObj.payersSepaInput2">
                    </div>
                  </uib-accordion>
                </div>
              </div>
            </div>
            <!-- END  Real Debitor -->

          </div>

          <div class="receiver-section" style="padding:10px;background-color:#fff;">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="domp-form-label" for="toAccountInput"
                         translate="internationalPayment.toAccountInputLabel">
                    Account number
                  </label>
                  <!--<input type="text" class="form-control input-field"-->
                  <!--capitalize-->
                  <!--ui-mask="AA99 9999 9999 9999 9999 9999 9999 9999 99" ui-mask-placeholder-->
                  <!--ui-mask-placeholder-char="_"-->
                  <!--ui-options="internationalPayment.uiMaskOptions"-->
                  <!--ng-model="internationalPayment.tempForpObj.toAccountInput"-->
                  <!--ng-change="internationalPayment.paymentForm.toAccountInput.$invalid = (internationalPayment.tempForpObj.toAccountInput.length) ? false : true"-->
                  <!--ng-class="{'domp-error': internationalPayment.paymentForm.toAccountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"-->
                  <!--name="toAccountInput" id="toAccountInput"-->
                  <!--required-->
                  <!--ng-blur="-->
                  <!--internationalPayment.fillReceiver(internationalPayment.tempForpObj.toAccountInput);-->
                  <!--internationalPayment.getBicByIban(internationalPayment.tempForpObj.toAccountInput)"> -->
                  <input type="text" class="form-control input-field"
                         capitalize
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.allLettersNumbersSpacesIntlCro}}"
                         ui-options="internationalPayment.uiMaskOptions"
                         ng-model="internationalPayment.tempForpObj.toAccountInput"
                         ng-class="{'domp-error': internationalPayment.paymentForm.toAccountInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="toAccountInput" id="toAccountInput"
                         ng-trim="false"
                         ng-change="internationalPayment.addSpaceForIban(internationalPayment.tempForpObj.toAccountInput)"
                         ng-blur="
                             internationalPayment.fillReceiver(internationalPayment.tempForpObj.toAccountInput);
                             internationalPayment.getBicByIban(internationalPayment.tempForpObj.toAccountInput)">
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.toAccountInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="domesticPayment.receiverAccountErrorHR">
                  </span>
                </div>
              </div>
              <!-- /////////// SELECT CUSTOM ///////////// -->
              <div class="col-sm-6 col-xs-12">
                <div class="form-group clearfix">
                  <label class="domp-form-label" for="toAccountInput"
                         translate="internationalPayment.toPayee2">To payee </label>
                  <div class="col-sm-12 col-xs-12 domp-filter-container"
                       ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.payeeSearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                       style="margin-bottom:16px;">
                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                      <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input"
                             ng-model="internationalPayment.tempForpObj.receiversNameInput"
                             ng-change="internationalPayment.searchPayee()"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                             autocomplete="off"
                             required maxlength="70" ng-maxlength="70">
                    </div>
                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                         ng-click="internationalPayment.toggleFilterSelection()">
                      <i class="zmdi zmdi-close domp-close-sign"
                         ng-show="internationalPayment.tempForpObj.receiversNameInput"
                         ng-click="internationalPayment.removeSearch()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!internationalPayment.tempForpObj.receiversNameInput"></i>
                    </div>
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': internationalPayment.searchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="payee in internationalPayment.payeeList| filter:internationalPayment.searchPayeeBy"
                           ng-click="internationalPayment.selectPayee(payee.id)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <i class="zmdi zmdi-account-circle"></i>
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                          <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                        </div>
                      </div>
                    </div>
                    <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                          ng-show="internationalPayment.paymentForm.payeeSearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted"
                          translate="domesticPayment.toPayeeInputError"></span>
                  </div>
                </div>

              </div>
              <!--//////////////SELECT CUSTOM END/////////////////////// -->
            </div>
            <div class="row">
              <div class="col-md-offset-6 col-md-6">
                <div class="form-group">
                  <!-- <label class="domp-form-label" for="receiversAddressInput"
                         translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label> -->
                  <input type="text" class="form-control input-field"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.receiversAddressInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="receiversAddressInput" id="receiversAddressInput"
                         ng-model="internationalPayment.tempForpObj.receiversAddressInput"
                         ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversAddressInput', internationalPayment.tempForpObj.receiversAddressInput)"
                         maxlength="35" ng-maxlength="35" required>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.receiversAddressInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="domesticPayment.receiverAddressError"></span>
                </div>
              </div>

            </div>
            <div class="row">

              <div class="col-md-offset-6 col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <!-- <label class="domp-form-label" for="receiversPostalCodeAndCityInput"
                         translate="domesticPayment.receiversPostalCodeAndCityInputLabel">Receiver's postal code and
                    city:</label> -->
                  <input type="text" class="form-control input-field"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                         ng-class="{'domp-error': internationalPayment.paymentForm.receiversPostalCodeAndCityInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput"
                         ng-model="internationalPayment.tempForpObj.receiversPostalCodeAndCityInput"
                         ng-blur="internationalPayment.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', internationalPayment.tempForpObj.receiversPostalCodeAndCityInput)"
                         maxlength="35" ng-maxlength="35">
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group clearfix">
                  <label class="domp-form-label" translate="payments.bicCountry">
                    Country
                  </label>
                  <div class="col-xs-12 domp-filter-container h-55"
                       ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.countrySearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <div class="col-xs-1 inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-xs-9 col-sm-10 inner-section input-div searchrow">
                      <input type="text" class="search-input" name="countrySearchFilter"
                             ng-model="internationalPayment.countrySearchFilter"
                             ng-change="internationalPayment.countrySearchFilterExpanded = true;"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                             required
                             autocomplete="off">
                    </div>

                    <div class="col-xs-1 inner-section right"
                         ng-click="internationalPayment.countrySearchFilterExpanded = !internationalPayment.countrySearchFilterExpanded">
                      <i class="zmdi zmdi-close domp-close-sign"
                         ng-show="internationalPayment.countrySearchFilter"
                         ng-click="internationalPayment.removeSelectedCountry()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!internationalPayment.countrySearchFilter"></i>
                    </div>
                    <!--Dropdown menu -->
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': internationalPayment.countrySearchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="country in internationalPayment.countriesOptions| filter: internationalPayment.countrySearchFilter"
                           ng-click="internationalPayment.onCountrySelect(country)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <!--<i class="zmdi zmdi-account-circle"></i>-->
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="c-red"
                       ng-show="internationalPayment.paymentForm.countrySearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted"
                       translate="payments.bicCountryRequired"></div>
                </div>
              </div>
              <div class="col-sm-6 p-t-30">
                <uib-accordion close-others="true" class="domp-sepa-accordion">
                  <div uib-accordion-group style="margin-top: 0px">
                    <uib-accordion-heading>
                      <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaReceiver();">
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                          <label class="domp-form-label-sepa" translate="domesticPayment.sepaReceiverLabel"></label>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                          class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                      </div>
                    </uib-accordion-heading>
                    <input type="text" class="form-control input-field"
                           ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPSwithoutSuskavci}}"
                           ng-model="internationalPayment.tempForpObj.receiversSepaInput1" style="margin-bottom:10px;">
                    <input type="text" class="form-control input-field"
                           ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPSwithoutSuskavci}}"
                           ng-model="internationalPayment.tempForpObj.receiversSepaInput2">
                  </div>
                </uib-accordion>
              </div>
            </div>
          </div>
          <div class="receiver-section" style="padding: 10px; background-color:#fff;">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="no-padding-on-phone">
                    <label class="domp-form-label" for="purposeCodeSelect"
                           translate="internationalPayment.purposeCode">Purpose code</label>
                    <div class="p-l-0">
                      <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect"
                              id="purposeCodeSelect"
                              ng-options="item.optionValue for item in internationalPayment.purposeCodes"
                              ng-model="internationalPayment.tempForpObj.purposeCodeSelect"
                              ng-change="internationalPayment.purposeCodeChange(internationalPayment.tempForpObj.purposeCodeSelect)"></select>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <div class="no-padding-on-phone">
                  <label class="domp-form-label domp-purpose-label" for="paymentPurposeInput"
                         translate="internationalPayment.paymentPurpose">Purpose of payment</label>
                  <div class="p-0">
                    <input type="text" class="form-control input-field"
                           id="paymentPurposeInput"
                           data-ng-model="internationalPayment.tempForpObj.paymentPurposeInput"
                           name="paymentPurposeInput"
                           ng-maxlength="140"
                           maxlength="140"
                           ng-pattern-restrict="{{internationalPayment.regexPatterns.purposeOfPaymentValidation}}"
                           ng-class="{'domp-error': internationalPayment.paymentForm.paymentPurposeInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                           required>
                    <span class="c-red"
                          ng-show="internationalPayment.paymentForm.paymentPurposeInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                          translate="internalTransfer.paymentPurposeInputError"></span>
                    <span class="c-red"
                          ng-show="internationalPayment.paymentForm.purposeOfPaymentInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                          translate="internalTransfer.paymentPurposeInputError"></span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- START BIC -->
          <div class="purpose-section" style="padding: 10px">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="form-group clearfix">
                  <label class="domp-form-label" translate="payments.bicAddress">
                    BIC Address
                  </label>
                  <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 domp-filter-container h-45"
                       ng-class="{'domp-error-custom-payee': internationalPayment.paymentForm.bicCodeSearchFilter.$invalid && internationalPayment.tempForpObj.formSubmitted}">
                    <div class="col-xs-1 col-lg-1  inner-section left">
                      <i class="icon icon-search domp-search-sign m-l-5"></i>
                    </div>
                    <div class="col-xs-9 col-lg-10  inner-section input-div searchrow">
                      <input type="text" class="search-input" name="bicCodeSearchFilter"
                             ng-model="internationalPayment.bicCodeSearchFilter"
                             ng-change="internationalPayment.searchBICNumber(internationalPayment.bicCodeSearchFilter)"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.onlyNumbersAndLetters}}"
                             autocomplete="off"
                             capitalize="">
                    </div>

                    <div class="col-xs-1 col-lg-1 inner-section right"
                         ng-click="internationalPayment.bicCodeSearchFilterExpanded = !internationalPayment.bicCodeSearchFilterExpanded">
                      <i class="zmdi zmdi-close domp-close-sign" ng-show="internationalPayment.bicCodeSearchFilter"
                         ng-click="internationalPayment.removeBicCodeSearch()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                         ng-show="!internationalPayment.bicCodeSearchFilter"></i>
                    </div>
                    <!--Dropdown menu -->
                    <div class="domp-filter-selection search-row"
                         ng-class="{'opened': internationalPayment.bicCodeSearchFilterExpanded}">
                      <div class="domp-filter-type-selection-wrapper"
                           ng-repeat="bicBank in internationalPayment.bicBankOptions| filter:internationalPayment.bicCodeSearchFilter"
                           ng-click="internationalPayment.onBicBankSelected(bicBank)">
                        <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <!--<i class="zmdi zmdi-account-circle"></i>-->
                        </div>
                        <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                          <p class="domp-list-name m-0">{{bicBank.bicCode}}{{bicBank.branchCode}}</p>
                        </div>
                      </div>
                    </div>
                    <span class="c-red" translate="payments.bicNumberRequired"
                          ng-show="internationalPayment.paymentForm.bicCodeSearchFilter.$error.required && internationalPayment.tempForpObj.formSubmitted">
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 ">
                <div class="row">
                  <div class="col-xs-12">

                  <!-- Required if Bic number is empty -->
                  <label class="domp-form-label" translate="payments.bicBankName">Bank Name</label>
                    <!--<input type="text" class="form-control input-field"-->
                    <!--name="bankNameInput"-->
                    <!--ng-model="internationalPayment.tempForpObj.bankNameInput"-->
                    <!--ng-required="!internationalPayment.bicCodeSearchFilter">     -->
                  <input type="text" class="form-control input-field"
                         name="bankNameInput" maxlength="35"
                         ng-class="{'domp-error': internationalPayment.paymentForm.bankNameInput.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                         ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                         replace-specific-chars
                         ng-model="internationalPayment.tempForpObj.bankNameInput"
                         autocomplete="off"
                         required>
                  <span class="c-red"
                        ng-show="internationalPayment.paymentForm.bankNameInput.$error.required && internationalPayment.tempForpObj.formSubmitted"
                        translate="payments.bicBankNameRequired"></span>

                  </div>
                  <div class="col-xs-12">

                      <label class="domp-form-label"></label>
                      <input type="text" class="form-control input-field"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                             replace-specific-chars maxlength="35"
                             autocomplete="off"
                             ng-model="internationalPayment.tempForpObj.bankAddressInput">

                  </div>
                  <div class="col-xs-12">

                      <label class="domp-form-label"></label>
                      <input type="text" class="form-control input-field" name="bicCity"
                             ng-model="internationalPayment.tempForpObj.bankCityInput"
                             ng-pattern-restrict="{{internationalPayment.regexPatterns.allowedCharsDPS}}"
                             replace-specific-chars maxlength="35"
                             ng-class="{'domp-error': internationalPayment.paymentForm.bicCity.$invalid && internationalPayment.tempForpObj.formSubmitted}"
                             required>
                      <span class="c-red"
                            ng-show="internationalPayment.paymentForm.bicCity.$error.required && internationalPayment.tempForpObj.formSubmitted"
                            translate="payments.bicCityRequired"></span>

                  </div>
                </div>
              </div>

            </div>

            <div class="row p-t-20">
              <div class="col-sm-4">
                <div class="form-group">
                <label class="domp-form-label" translate="internationalPayment.chargeAccount">Charge account</label>
                <input type="text" class="form-control input-field" name="chargeAccount"
                       ng-model="internationalPayment.tempForpObj.chargeAccountInput"
                       readonly="true"
                       required>
                  </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="domp-form-label" translate="payments.bicBankCharges">Troškovna opcija: </label>
                  <select class="form-control input-field"
                          ng-model="internationalPayment.tempForpObj.bankChargesRadiobutton.type"
                          ng-disabled="internationalPayment.EUCountrySelected || internationalPayment.isEUCountry">
                    <option value=3>SHA</option>
                    <option value=1>OUR</option>
                  </select>
                </div>
              </div>
            </div>

            <section class="row d-flex p-t-15">
              <div class="col p-l-30">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                  <input ng-readonly="internationalPayment.disableAllFields" type="checkbox"
                    ng-model="internationalPayment.tempForpObj.isInstantPaymentCheckBox"
                    ng-disabled="!internationalPayment.instantPaymentEnabled"
                    ng-change="internationalPayment.toggleUrgencyField('isInstantPaymentCheckBox')">
                  <i class="input-helper"></i>
                  <span uib-tooltip="{{'domesticPayment.instantMsg'| translate}}" tooltip-append-to-body="true"
                  tooltip-trigger="'mouseenter'"
                  tooltip-placement="top" ng-class="{'disabled-label': !internationalPayment.instantPaymentEnabled}">
                  {{'domesticPayment.selectInstantLabel'| translate}}
                  </span>
                </label>
              </div>
              <div class="col-sm-4">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                    <input type="checkbox" ng-model="internationalPayment.tempForpObj.isUrgentPaymentCheckbox" 
                           ng-change="internationalPayment.toggleUrgencyField('isUrgentPaymentCheckbox')">
                  <i class="input-helper"></i>
                  <span>{{'internationalPayment.selectUrgentLabel'| translate}}
                  </span>
                </label>
              </div>
              <!-- <div class="col-sm-12 col-lg-6 col-md-6 p-l-30 t-a-r" style="line-height: 39px;">
                <a ng-href="{{domesticPaymentCro.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges">
                  Check the bank charges
                </a>
              </div> -->
            </section>
          </div>
          <!-- END BIC -->

          <div class="intp-form-container m-b-30">
            <div class="row">
              <div class="col-xs-6 t-a-l">
                <button class="btn btn-primary" translate="payments.cancel"
                        type="button"
                        data-ui-sref="payments.paymentsOverview">
                </button>
              </div>
              <div class="col-xs-6 t-a-r">
                <button type="submit"
                        ng-if="!internationalPayment.tempForpObj.bankCountrySelect.amlControl"
                        class="btn btn-default domp-review-and-submit-btn"
                        translate="domesticPayment.confirmBtn"
                        data-ng-click="internationalPayment.submitInternationalPayment($event);">
                </button>
                <button type="submit"
                        ng-if="internationalPayment.tempForpObj.bankCountrySelect.amlControl"
                        class="btn btn-default domp-review-and-submit-btn"
                        translate="domesticPayment.confirmBtn"
                        data-ng-click="internationalPayment.submitInternationalPayment($event);">
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- END NEW SECOND BOX -->

      </div>
    </span>
    <!-- START STEP NUMBER 2 -->
    <div ng-if="internationalPayment.steps.step2">
      <div data-ng-include="'payments/international-payment-resume.tpl.html'"></div>
    </div>
    <!-- END STEP NUMBER 2-->
    <!-- START STEP NUMBER 3 -->
    <div ng-if="internationalPayment.steps.step3">
      <payment-final-step payment="internationalPayment.paymentResponse" is-modal="false"></payment-final-step>
    </div>
    <!-- END STEP NUMBER 3 -->
  </div>
</section>
