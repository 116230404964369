<div class="row">
  <div class="col-xs-12">
    <div class="t-a-c m-t-10 p-10 no-data-image" ng-show="accounts.selectedAccount.transactions.result.length == 0">
      <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
      <h3 class="no-data-label" translate="transactions.noData"></h3>
    </div>
  </div>
</div>


<!--<div class="hypo-timeline-header-line col-sm-12 no-bottom-radius no-top-radius visible-md-block visible-sm-block visible-lg-block" ng-show="accounts.selectedAccount.transactions.result.length > 0">
    <div class="col-md-6 col-sm-4 t-a-l p-0" translate="accounts.accDescription"></div>
    <div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accAmount"></div>
    <div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accBalance"></div>
</div>-->
<div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="row p-t-15" ng-show="accounts.selectedAccount.transactions.result.length > 0">
  <div class="col-xs-12 col-sm-6 t-a-r hidden-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumCredit' | translate}} {{accounts.selectedAccount.transactions.result[1].balanceSumCredit
      | number:2}} {{accounts.selectedAccount.transactions.result[1].currencySymbol}}
    </label>
  </div>
  <div class="col-xs-12 col-sm-6 t-a-l hidden-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumDebit' | translate}} {{accounts.selectedAccount.transactions.result[1].balanceSumDebit |
      number:2}} {{accounts.selectedAccount.transactions.result[1].currencySymbol}}
    </label>
  </div>

  <div class="col-xs-12 t-a-c visible-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumCredit' | translate}} {{accounts.selectedAccount.transactions.result[1].balanceSumCredit
      | number:2}} {{accounts.selectedAccount.transactions.result[1].currencySymbol}}
    </label>
  </div>
  <div class="col-xs-12 t-a-c visible-xs">
    <label class="balance-sum-label ng-scope">
      {{'transactions.balanceSumDebit' | translate}} {{accounts.selectedAccount.transactions.result[1].balanceSumDebit |
      number:2}} {{accounts.selectedAccount.transactions.result[1].currencySymbol}}
    </label>
  </div>
</div>

<div class="row">

<div class="timeline-row col-sm-12 p-l-0 p-r-0" ng-show="accounts.selectedAccount.transactions.result.length > 0">
    <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0 no-top-radius">
        <ul class="hypo-timeline statements-timeline">
            <li data-ng-repeat="transaction in accounts.selectedAccount.transactions.result" 
                ng-class="{'time-label': transaction.isVirtual === true, 'tl-badge-icon': transaction.isVirtual === undefined}">
                <span ng-if="transaction.isVirtual === true">{{transaction.valueDate | date: 'shortDate' : '+0200'}}</span>
                <i ng-if="transaction.isVirtual == undefined" 
                   data-ng-style="transaction.transactionLogo ?{'background-image': 'url(' + transaction.transactionLogo +')'}: {}"
                   ng-class="'icon icon-' + transaction.icon"></i>

<!--              <span ng-if="!transaction.isVirtual && APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && transaction.paymentType === '2'"-->
<!--                class="ips-account-text" translate="transactions.POSIPS">POS_IPS-->
<!--              </span>-->


                <div ng-if="transaction.isVirtual == undefined" class="hypo-timeline-item transaction-item" 
                      data-ng-click="transaction.selected = !transaction.selected; accounts.getTransactionDetails(transaction);" 
                      ng-class="{'opened': transaction.selected}">
                    <div class="hypo-timeline-body">
                        <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
                            <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{::transaction.detail.purpose}}</b></h4>
                            <p class="m-b-0 m-t-0 no-wrap-text">
                              <span ng-if="transaction.detail.payeeName">{{transaction.merchantName || transaction.detail.payeeName}}</span>
                              <span ng-if="transaction.detail.toAccountNumber && !transaction.toAccountNumber">&nbsp;{{::transaction.detail.toAccountNumber}}</span>
                              <span ng-if="transaction.toAccountNumber">{{::transaction.toAccountNumber}}</span>
                              <span ng-if="accounts.selectedAccount.iban && accounts.countryCode != 'hr'">/ {{::accounts.selectedAccount.iban}}</span>
                              <span ng-if="transaction.detail.fromAccountNumber && accounts.countryCode == 'hr'">/ {{::transaction.detail.fromAccountNumber}}</span>
                            </p>
                        </div>
                        <div style="font-weight:600; font-size:22px;" ng-if="transaction.credit == null" class="price-payment col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.amount | number:2}} {{::transaction.currencySymbol}}</b></span></div>
                        <div style="font-weight:600; font-size:22px;" ng-if="transaction.debit == null" class="price-payment col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.amount | number:2}} {{::transaction.currencySymbol}}</b></span></div>
                      <span ng-if="!transaction.selected && transaction.bookingType && transaction.bookingType == '1' && accounts.countryCode == 'hr'" style ="float: right; color: red;" translate="accounts.accAutorization"></span>
                      <span ng-if="!transaction.selected && transaction.bookingType && transaction.bookingType == '2' && accounts.countryCode == 'hr'" style ="float: right; color: blue;" translate="accounts.accProvedena"></span>
                      <span ng-if="transaction.transactionDivision ? transaction.transactionDivision.status === 'Processed' && accounts.countryCode == 'hr' : false" 
                            style ="float: left; color: blue;" translate="accounts.installmentDivided"></span>
                      <button ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && transaction.isTransactionDivisionAllowed" type="button" 
                              class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                              style="background: #062A42; color: white;"
                              ng-click="accounts.checkIfAllowed(true, transaction, 
                                              card.cardNumber.substr(card.cardNumber.length - 4, 4), 
                                              accounts.transactionCardsFilterOptions.currencySymbol)">
                            {{'accounts.transDivisionBtn1' | translate}} {{'accounts.transDivisionBtn2' | translate}}
                      </button>
                    </div>
                    <div ng-if="transaction.selected" class="m-l-0 transaction-details">
                        <div data-ng-include="'accounts/acc-trans-detail-modal.tpl.html'"></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<div ng-if="accounts.selectedAccount.transactions.hasMore == true" class="col-xs-12 t-a-c m-t-15 m-b-15">
  <button ng-if="accounts.selectedAccount.transactions.hasMore == true" data-ng-click="accounts.loadMoreTransactions()"
          type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
          translate="paymentsOverview.loadMoreBtn">Load more</button>
</div>

</div>
