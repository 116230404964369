<div class="p-t-20 p-b-20 p-l-20 p-r-20" ng-class="{'cust-p-69': currencyCalculator.countryCode == 'mne'}">
  <div class="row">
    <div class="col-sm-9 col-xs-9">
      <h4 ng-if="currencyCalculator.countryCode !== 'mne'" translate="currencyCalculator.exchangeCalculator"></h4>
      <h4 ng-if="currencyCalculator.countryCode === 'mne'" translate="currencyCalculator.exchangeRates"></h4>
      <h4></h4>
    </div>
    <div class="col-sm-3 col-xs-3" ng-if="currencyCalculator.countryCode !== 'mne'">
      <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst" data-ng-click="currencyCalculator.closeModal();"></i>
    </div>
  </div>

  <div class="row msg-ctn" ng-if="currencyCalculator.countryCode === 'hr'">
    <div class="col-sm-12">
      <p translate="currencyCalculator.msg1"></p>
      <p translate="currencyCalculator.msg2"></p>
      <p translate="currencyCalculator.msg3"></p>
    </div>
  </div>

  <div class="row m-t-20" ng-if="currencyCalculator.countryCode !== 'mne'">

    <div class="col-sm-6">
      <div class="form-group">
        <label translate="currencyConversion.selling">Selling amount</label>
        <div class="row">
          <div class="col-sm-8 col-xs-8 p-r-0">
            <input type="text" class="form-control input-field t-a-r"
                   placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                   amount-input-mask
                   data-ng-model="currencyCalculator.fromAmountInput"
                   maxlength="12"
                   ng-blur="currencyCalculator.setFromCurrency();">
          </div>
          <div class="col-sm-4 col-xs-4 p-l-0">
            <select class="form-control input-field" style="margin-left:-1px"
                    ng-options="item.symbol for item in currencyCalculator.fromCurrencyList"
                    ng-model="currencyCalculator.fromCurrencySelect"
                    ng-change="currencyCalculator.fromCurrencyChange();">
            </select>
            <!--<input type="text" class="form-control input-field" data-ng-model="currencyCalculator.localCurrency" readonly>-->
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label translate="currencyConversion.buying">Buying amount</label>
        <div class="row">
          <div class="col-sm-8 col-xs-8 p-r-0">
            <input type="text" class="form-control input-field t-a-r"
                   placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                   amount-input-mask
                   data-ng-model="currencyCalculator.toAmountInput"
                   maxlength="12"
				   ng-blur="currencyCalculator.setToCurrency();">
          </div>
          <div class="col-sm-4 col-xs-4 p-l-0">
            <select class="form-control input-field" style="margin-left:-1px"
                    ng-options="item.symbol for item in currencyCalculator.toCurrencyList"
                    ng-model="currencyCalculator.toCurrencySelect"
            ng-change="currencyCalculator.toCurrencyChange();">
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div>
    <exchange-rates ng-if="currencyCalculator.exchangeRatesList.result.length > 0"
                    exchanges="currencyCalculator.exchangeRatesList"
                    country-code="{{currencyCalculator.countryCode}}"
                    exchange-rates-date="currencyCalculator.ratesDate"></exchange-rates>
  </div>
</div>

<!--<h2>currencyCalculatorModal</h2>-->
<!--<p>{{currencyCalculatorModal.ctrlName}}</p>-->
