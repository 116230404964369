<tr ng-if="transaction.merchantAddress">
    <td class="left-col"><span class="tridm-key-label" translate="merchant.address"></span></td>
    <td class="right-col">
      <span class="tridm-value-label">
       {{transaction.merchantAddress}}
        </span>
      
    </td>
</tr>
<tr ng-if="transaction.merchantContact">
    <td class="left-col"><span class="tridm-key-label" translate="merchant.contact"></span></td>
    <td class="right-col">
      <span class="tridm-value-label">
        {{transaction.merchantContact}} 
        </span>
      
    </td>
</tr>
<tr ng-if="transaction.websiteUrl">
    <td class="left-col"><span class="tridm-key-label" translate="merchant.website"></span></td>
    <td class="right-col">
      <span class="tridm-value-label">
        <a href="{{transaction.websiteUrl}}" target="_blank">
            {{transaction.websiteUrl}}
        </a>
        </span>
    </td>
</tr>
<tr ng-if="transaction.merchantCoordinates" ng-click="accounts.preventDefault($event)">
    <td class="left-col"><span class="tridm-key-label" translate="merchant.location"></span></td>
    <td class="right-col">
        <div ng-if="transaction.merchantCoordinates" 
             style="margin: auto; width: 300px; float: right;"
             map-lazy-load="https://maps.google.com/maps/api/js"
             map-lazy-load-params="{{accounts.googleMapsUrl}}">
            <ng-map style="height: 150px;"  center="{{transaction.merchantCoordinates.lat}},{{transaction.merchantCoordinates.lng}}"
                    height="150px" zoom="15">
                <marker position="{{transaction.merchantCoordinates.lat}},{{transaction.merchantCoordinates.lng}}"></marker>
            </ng-map>
        </div>
    </td>
</tr>