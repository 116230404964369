(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name navigation.factory:NotificationCountService
   *
   * @description
   *
   */
  angular
    .module('navigation')
    .factory('NotificationCountService', NotificationCountService);

  function NotificationCountService($q, $http, $log, EndpointsService) {
    var NotificationCountServiceBase = {};

    function getRequestUrl(type, links) {
      var url = "";
      switch (type) {
        case "Payments":
          url = links.payments_count;
          break;
        case "eInvoiceDocuments":
          url = links.eInvoiceDocuments_count;
          break;
        case "Messages":
          url = links.messages_count;
          break;
        case "Documents":
          url = links.documents_count;
          break;
        case "Complaints":
          url = links.complaints_hasUnreadMessages;
          break;
      }
      return url;
    }

    NotificationCountServiceBase.getNotificationCount = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        var url = getRequestUrl(params.type, response.links);
        if(url) {
          $http.head(url, {
            params: params.query
          }).then(function (response) {
            if (params.type == 'Complaints') {
              deferred.resolve(response.headers('has-unread-messages'));
            } else {
              deferred.resolve(response.headers('x-total-count'));
            }
          }).catch(function (msg) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(0);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return NotificationCountServiceBase;
  }
}());
