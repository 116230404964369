(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name payments.controller:InternalTransferCtrl
     *
     * @description
     *
     */
    angular
        .module('payments')
        .controller('InternalTransferCtrl', InternalTransferCtrl);

    function InternalTransferCtrl($rootScope, $state, CTLocalizationService, $log, PaymentTypesService, HelperService,
        PaymentsService, AccountsService, $stateParams,
        PaymentFilterService, toastr, $translate, Notification, AlertService, $location, PaymentTemplatesService, GoogleTagManagerHelperService, NotificationService) {
        var vm = this;
        var singleToAccount = false;

        vm.paymentResponse = {};
        vm.fromAccounts = false;
        vm.tempIntObj = {
            fromAccountSelect: {},
            toAccountSelect: '',
            amountInput: '',
            dueDateInput: new Date(),
            formSubmitted: false
        };

        vm.accountsFrom = [];
        vm.accountsTo = [];
        vm.isSameAccounts = false;

        vm.disableAllFields = false;

        vm.datePickerOpened = false;
        vm.dateOptions = {
            minDate: new Date(),
            maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };

        //vm.bankCharges = '';
        vm.customErrorLabels = [];
        vm.currentStep = 1;
        vm.forUpdate = false;
        vm.fromAccountDropdown = false;
        vm.toAccountDropdown = false;
        vm.templatesFilter = {
            paymentTypeGroup: 'LocalCurrencyTransfer',
            page: 0,
            pageSize: 1000
        };
        //vm.countryCode = $localStorage.code || '';
        vm.fromLoan = false;
        vm.accountChanged = false;

        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.bankCharges = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BankCharges;


        vm.init = function () {
            //vm.getDefaultData();
            loadAccounts();
        };

        var setAccountTo_ = function () {
            AccountsService.getAccountById($stateParams.accountId)
                .then(function (response) {
                    vm.tempIntObj.toAccountSelect = response;
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        var loadInternalTransferData = function (payment, paymentObject, params) {
            paymentObject.amountInput = payment.amount;
            paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.accountsFrom, payment.accountId);
            filterAccountToSameCurrency();
            paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelectByIBAN(vm.accountsTo, payment.toAccountNumber);
            if (vm.countryCode == 'bh')
                paymentObject.dueDateInput = new Date(payment.dueDate);

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {


                vm.tempIntObj.fromAccountSelect = _.find(params.accountsFrom, function (account) {
                        return (account.iban == payment.fromAccountNumber.replace(/ /g, '') && account.currencySymbol == payment.currencySymbol)
                    })
                    // This is used to make "Account to" readonly and to disable AccountToList from changing
                    // AccountTo is not readonly when "Repeating" the payment - Only Execute and Change

                vm.fromLoan = true;
                if ($state.params.myParam == 'repeat') {
                    vm.fromLoan = false;
                }

                PaymentTypesService.getByGroup({
                        paymentTypeGroup: 'LocalCurrencyTransfer',
                        masterAccountId: vm.tempIntObj.fromAccountSelect.accountId
                    })
                    .then(function (response) {
                        if (_.isArray(response.result) && response.result.length > 0) {
                            vm.accountsTo = response.result[0].creditAccountList;
                            filterAccountToSameCurrency();
                            vm.setAccountTo(_.find(vm.accountsTo, function (account) {
                                return (account.iban == payment.toAccountNumber.replace(/ /g, '') && account.currencySymbol == payment.currencySymbol)
                            }));
                            _.forEach(vm.accountsTo, function (item) {
                                if (item.accountDescription) {
                                    item.optionValue = item.accountDescription + ' - ' + item.iban;
                                }
                            });
                        }
                        vm.accountChanged = false;
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });


            }
            if (vm.countryCode == "sl") {
              paymentObject.dueDateInput = new Date(payment.dueDate);
              vm.disableAllFields = true;
              if ($state.params.myParam == 'repeatCancelled') {
                vm.disableAllFieldsForProcessedPayment = true;
              }

              if ($state.params.myParam == 'changeProcessed') {
                vm.disableAllFieldsForProcessedPayment = false;
              }
            }

        };

        var loadDataForCard = function (payment, paymentObject, params, accountObject) {
            paymentObject.amountInput = payment.amount;
            paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.accountsFrom, payment.accountId);
            paymentObject.toAccountSelect = accountObject.result[0];
            var tempList = [];
            tempList.push(paymentObject.toAccountSelect);
            vm.accountsTo = tempList;
            singleToAccount = true;
        };

        var removeNonDefaultCurrency = function (list) {
            if (!list) return;
            _.remove(list, function (item) {
                return item.currencySymbol != $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
            });

            if (_.isArray(list) && list.length > 0) {
                vm.tempIntObj.fromAccountSelect = list[0];
            }
        };

        var loadAccounts = function () {
            vm.getDebitAccountList()
                .then(function () {
                    var params = {
                        accountsFrom: vm.accountsFrom
                    };
                    if ($state.current && $state.current.name) {
                        switch ($state.current.name) {
                        case "payments.internalTransfer.fromPayment":
                            PaymentsService.getById($state.params.paymentId) ///dodano zbog uplate na karticu u CRO
                                .then(function (paymentResponse) {
                                    AccountsService.getPage({
                                            accountNumber: paymentResponse.toAccountNumber
                                        })
                                        .then(function (accountResponse) {
                                            if (vm.countryCode === 'hr' && accountResponse.result.length > 0) {
                                                if (accountResponse.result[0].accountType.group === 'CardAccountTypes' || accountResponse.result[0].accountType.group === 'SavingAccountTypes') {
                                                    loadDataForCard(paymentResponse, vm.tempIntObj, params, accountResponse);
                                                }

                                            } else {
                                                loadInternalTransferData(paymentResponse, vm.tempIntObj, params);
                                            }
                                        })
                                        .catch(function (error) {});
                                })
                                .catch(function (error) {
                                    NotificationService.showMessage(error, 'error');
                                });
                            break;
                        case "payments.internalTransfer.fromAccounts":
                            var accountsFromExist = (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) ? true : false;
                            AccountsService.getAccountById($state.params.accountId)
                                .then(function (response) {
                                    //set toAccount in case of Card account type
                                    if (
                                        response.accountType.group === 'CardAccountTypes' ||
                                        response.accountType.group === 'SavingAccountTypes' ||
                                        (vm.countryCode == 'mne' && response.accountType.id.substr(0, 2) == '60')
                                    ) {
                                        if (accountsFromExist) {
                                            vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
                                        }
                                        vm.tempIntObj.toAccountSelect = '';
                                        vm.tempIntObj.toAccountSelect = response;
                                        vm.fromAccounts = true;
                                        var tempList = [];
                                        tempList.push(vm.tempIntObj.toAccountSelect);
                                        vm.accountsTo = tempList;
                                        singleToAccount = true;

                                        if (vm.countryCode == 'sl' || vm.countryCode == 'bh') {
                                            //remove nonEur currencies from FROM list for savings paying - Andreja TSK 8249
                                            _.remove(vm.accountsFrom, function (item) {
                                                return item.currencySymbol != vm.tempIntObj.toAccountSelect.currencySymbol;
                                            });

                                            if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
                                                vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
                                            }

                                        }

                                    } else {
                                        if (accountsFromExist) {
                                            vm.tempIntObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(vm.accountsFrom, $state.params.accountId);
                                        }
                                        //filter to account list
                                        if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") filterAccountToSameCurrency();
                                        if (_.isArray(vm.accountsTo) && vm.accountsTo.length > 0) {
                                            vm.tempIntObj.toAccountSelect = vm.accountsTo[0];
                                          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
                                            vm.accountChanged = true;
                                            setToAccountList (vm.tempIntObj.fromAccountSelect.accountId);
                                          }
                                        }
                                    }

                                })
                                .catch(function (error) {
                                    NotificationService.showMessage(error, 'error');
                                });
                            break;
                        case "payments.internalTransfer.loan":
                            vm.fromLoan = true;
                            vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
                            if (vm.countryCode == 'sl') {
                                vm.tempIntObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.accountsFrom);
                            }

                            if (vm.countryCode == 'bh')
                                removeNonDefaultCurrency(vm.accountsFrom);

                            loadAccountById($state.params.loanId);
                            break;
                        case "payments.internalTransfer":
                            if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
                                vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
                            }
                            if (vm.countryCode == 'sl') {
                                vm.tempIntObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.accountsFrom);
                                vm.accountChanged = true;
                                setToAccountList(vm.tempIntObj.fromAccountSelect.accountId);
                            }


                            filterAccountToSameCurrency();
                            if (_.isArray(vm.accountsTo) && vm.accountsTo.length > 0) {
                                vm.tempIntObj.toAccountSelect = vm.accountsTo[0];
                            }
                            break;

                        case "payments.internalTransfer.fromTemplate":
                            if ($state.params.templateId) {
                                vm.fromTemplate = true;
                                vm.populateFromTemplate($state.params.templateId);
                            }
                            break;
                        }
                    }
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.populateFromTemplate = function (id) {
            PaymentTemplatesService.getById(id)
                .then(function (response) {
                  var template = response;
                  if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
                    vm.tempIntObj.amountInput = response.amount;
                  }
                  if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" && template.paymentTypeGroup == "ForeignCurrencyTransfer") {


                    var fromAccountFound = _.find(vm.accountsFrom, function (account) {
                      return (account.parentAccountId && (account.parentAccountId.toString() == template.accountId &&  account.currencySymbol == template.currencyCode));
                    })
                    vm.tempIntObj.fromAccountSelect = (fromAccountFound) ? fromAccountFound : {};
                    vm.accountChanged = true;
                    setToAccountList(vm.tempIntObj.fromAccountSelect.accountId)
                      .then(function (response) {
                        console.log(vm.accountsTo);
                        var toAccountFound = _.find(vm.accountsTo, function (account) {
                          return (account.iban.replace(/ /g,'') == response.toAccountNumber.replace(/ /g,'') && account.currencySymbol == template.currencyCode);
                        });
                        vm.tempIntObj.toAccountSelect = (toAccountFound) ? toAccountFound : {};
                      });




                  } else {
                    if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
                      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
                        vm.tempIntObj.fromAccountSelect = _.find(vm.accountsFrom, {accountId: response.accountId})
                      } else {
                        vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
                      }
                    }

                    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
                      vm.tempIntObj.toAccountSelect = _.find(vm.accountsFrom, {iban: response.toAccountNumber.replace(/ /g,''), currencySymbol: response.currencyCode });
                    } else {
                      vm.tempIntObj.toAccountSelect = _.find(vm.accountsTo, {iban: response.toAccountNumber.replace(/ /g,'')});
                    }

                    if (!vm.tempIntObj.toAccountSelect)
                      vm.tempIntObj.toAccountSelect = vm.accountsTo[0];
                  }

                }, function (error) {
                    console.log(error);
                });
        };



        var loadAccountById = function (accountId) {
            AccountsService.getAccountById(accountId)
                .then(function (response) {
                    vm.tempIntObj.toAccountSelect = response;
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                })
        };

        vm.openDatePicker = function () {
            vm.datePickerOpened = true;
        };

        vm.goBack = function () {
            $state.go('payments.paymentsOverview');
        };

        vm.getDebitAccountList = function () {
            return PaymentTypesService.getByGroup({
                    paymentTypeGroup: 'LocalCurrencyTransfer'
                })
                .then(function (response) {
                    if (_.isArray(response.result) && response.result.length > 0) {
                        vm.accountsFrom = response.result[0].debitAccountList;
                        vm.accountsTo = response.result[0].creditAccountList;

                        _.forEach(vm.accountsFrom, function (item) {
                            if (item.accountDescription) {
                                item.optionValue = item.accountDescription + ' - ' + item.iban;
                            }
                        });
                        _.forEach(vm.accountsTo, function (item) {
                            if (item.accountDescription) {
                                item.optionValue = item.accountDescription + ' - ' + item.iban;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    $log.error(error);
                });
        };

        vm.internalTransferSubmit = function () {
            if (vm.nextButtonPressed) {
              return;
            }

            vm.tempIntObj.formSubmitted = true;
            if ($state.params.editable) {
                vm.forUpdate = true;
                vm.paymentResponse.id = $stateParams.paymentId;
            }
            if (!vm.internalTransferForm.$valid) {
                return;
            }
            if (vm.tempIntObj.fromAccountSelect.accountId == vm.tempIntObj.toAccountSelect.accountId) {
                vm.isSameAccounts = true;
                $translate(['core.sameAccounts']).then(function (translations) {
                    var txt = translations['core.sameAccounts'];
                    toastr.error(txt);
                });
                return;
            } else {
                vm.isSameAccounts = false;
            }

            if (!vm.formSubmitted) {
                if (vm.countryCode == 'sl')
                    sessionStorage.setItem('paymentSubTypeGroup', 'LocalCurrencyTransfer');
                var internalTransferPaymentObject = createPaymentObject();
                vm.nextButtonPressed = true;
                if (vm.forUpdate) {
                    internalTransferPaymentObject.id = vm.paymentResponse.id;
                    PaymentsService.updatePayment(vm.paymentResponse.id, internalTransferPaymentObject)
                        .then(function (response) {
                            postPaymentAction(response);
                            vm.forUpdate = false;
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                            vm.forUpdate = false;
                            // vm.internalTransferForm.$valid = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                            vm.nextButtonPressed = false;
                        });
                } else {
                    PaymentsService.insertPayment(internalTransferPaymentObject)
                        .then(function (response) {
                            postPaymentAction(response);
                            var gtmObject = HelperService.processUrlForGTM('payment');
                            GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
                        })
                        .catch(function (error) {
                            vm.paymentResponse = {};
                            NotificationService.showMessage(error, 'error');
                            $log.error("Payment error:", error);
                            // vm.internalTransferForm.$valid = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                            vm.nextButtonPressed = false;
                        });
                }
            }
        };

        var createPaymentObject = function () {
            var tempDueDate = vm.tempIntObj.dueDateInput;
            var tempToAccountNumber = vm.tempIntObj.toAccountSelect.iban;
            var tempPaymentTypeGroup = (vm.tempIntObj.fromAccountSelect.currencySymbol != $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency) ? 'ForeignCurrencyTransfer' : 'LocalCurrencyTransfer';
            if (vm.countryCode == 'sr' || vm.countryCode == 'mne') {
                tempDueDate = new Date();
                if (vm.fromAccounts && vm.countryCode == 'mne') {
                    tempToAccountNumber = vm.tempIntObj.toAccountSelect.accountNumber;
                }
            }

            if (vm.countryCode == 'sl') {
                tempToAccountNumber = vm.tempIntObj.toAccountSelect.accountNumber;
            }


            if (vm.countryCode == 'bh' || vm.countryCode == 'mne' || vm.countryCode == 'sr') {
                tempPaymentTypeGroup = 'LocalCurrencyTransfer';
            }
            return {
                accountId: vm.tempIntObj.fromAccountSelect.accountId,
                fromAccountNumber: vm.tempIntObj.fromAccountSelect.iban,
                toAccountNumber: tempToAccountNumber,
                currencySymbol: vm.tempIntObj.fromAccountSelect.currencySymbol,
                amount: vm.tempIntObj.amountInput,
                paymentTypeGroup: tempPaymentTypeGroup,
                dueDate: tempDueDate
            }
        };

        vm.signPayment = function () {
            if (vm.confirmButtonPressed) return;
            //if route has this param it should request for PUT method
            if ($state.params.myParam == 'changeProcessed') {
                updatePayment();
                return;
            }

            if (PaymentsService.isVerificationRequiredForSign(vm.paymentResponse)) {
                // If verification is required, go to payment sign state
                $state.go('payments.sign', {
                    paymentId: vm.paymentResponse.id,
                    type: 'internal'
                });
            } else {
                // If verification is not required, then sign payment and go to final step
                var params = {
                    id: vm.paymentResponse.id,
                    payment: vm.paymentResponse
                };
                vm.confirmButtonPressed = true;
                return PaymentsService.signPayment(params)
                    .then(function (signedPayment) {
                        var id = (signedPayment.id) ? signedPayment.id : vm.paymentResponse.id;
                        // TODO: Use signed payment on response
                        $state.go('payments.finalStep', {
                            paymentId: id,
                            type: 'internal'
                        });
                    }).catch(function (error) {
                        $log.error(error);
                        NotificationService.showMessage(error, 'error');
                    }).finally(function() {
                      vm.confirmButtonPressed = false;
                  });
            }
        };

        function updatePayment() {
            var params = {
                id: $state.params.paymentId,
                payment: vm.paymentResponse
            };

            if ($state.params.myParam == 'changeProcessed') {
                params.payment.relatedSourceType = 'ChangePayment';
            }


            PaymentsService.updatePayment(params.id, params.payment, 'changeProcessed')
                .then(function (signedPayment) {
                    var id = (signedPayment.id) ? signedPayment.id : vm.paymentResponse.id;
                    $state.go('payments.finalStep', {
                        paymentId: id,
                        type: "internal"
                    });
                })
                .catch(function (response, error) {
                    $log.error(error);
                    NotificationService.showMessage(error, 'error');
                })
                .finally(function () {
                    vm.formSubmitted = false;
                    vm.forUpdate = false;
                });


        }

        vm.editPayment = function () {
            mapWithPaymentResponse();
            vm.forUpdate = true;
            vm.currentStep = 1;
        };

        var mapWithPaymentResponse = function () {
            vm.tempIntObj.amountInput = vm.paymentResponse.amount;
            vm.tempIntObj.dueDateInput = new Date(vm.paymentResponse.dueDate);

            /*      var accountIndex = _.findIndex(vm.accountsFrom, function (item) {
              return item.accountId == vm.paymentResponse.accountId;
            });

            var accountToIndex = _.findIndex(vm.accountsTo, function (item) {
              return item.iban == vm.paymentResponse.toAccountNumber;
            });

            vm.tempIntObj.fromAccountSelect = vm.accountsFrom[accountIndex];
            vm.tempIntObj.toAccountSelect = vm.accountsTo[accountToIndex];*/

        };


        var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        $rootScope.$on('appLanguageChangedHeader', function () {
            sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        });

        vm.deletePayment = function () {
            AlertService.confirmationAlert()
                .then(function (result) {
                    if (result) {
                        PaymentsService.deletePayment(vm.paymentResponse.id)
                            .then(function (response) {
                                AlertService.deletedPaymentAlert({
                                        paymentName: vm.paymentResponse.id,
                                        type: 'payment'
                                    })
                                    .then(function () {
                                        $state.go('payments.paymentsOverview');
                                    });
                            })
                            .catch(function (error) {
                                NotificationService.showMessage(error, 'error');
                            });
                    }
                })
                .catch(function () {
                    $log.debug('Example failed!');
                });

        };

        var setToAccountList = function (accountId) {
            if (vm.accountChanged) {

               return PaymentTypesService.getByGroup({
                        paymentTypeGroup: 'LocalCurrencyTransfer',
                        masterAccountId: accountId
                    })
                    .then(function (response) {
                        if (_.isArray(response.result) && response.result.length > 0) {
                            vm.accountsTo = response.result[0].creditAccountList;
                            filterAccountToSameCurrency();
                            vm.setAccountTo(vm.accountsTo[0]);
                            _.forEach(vm.accountsTo, function (item) {
                                if (item.accountDescription) {
                                    item.optionValue = item.accountDescription + ' - ' + item.iban;
                                }
                            });
                        }
                        vm.accountChanged = false;
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });
            }
        };

        var fromAccountChanged = function () {
            //if($state.current.name !== 'payments.internalTransfer.fromPayment' && $state.current.name !== 'payments.internalTransfer.fromAccounts')
            if (!singleToAccount) {
                setToAccountList(vm.tempIntObj.fromAccountSelect.accountId);
            }
        };

        var postPaymentAction = function (response) {
            vm.paymentResponse = response;
            vm.paymentResponse.paymentTypeGroup = 'LocalCurrencyTransfer';
            Notification.clearAll();
            vm.currentStep = 2;
        };

        vm.toggleFromAccount = function () {
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" && vm.fromTemplate) {
            return;
          }
          vm.fromAccountDropdown = !vm.fromAccountDropdown;
        };

        vm.setAccountFrom = function (item) {
            if (item.accountId !== vm.tempIntObj.fromAccountSelect.accountId) {
                vm.accountChanged = true;
            } else {
                vm.accountChanged = false;
            }
            vm.tempIntObj.fromAccountSelect = item;
            vm.fromAccountDropdown = false;
            if (!vm.fromLoan) {
                vm.toAccountDropdown = false;
                fromAccountChanged();
            }
        };

        vm.toggleToAccount = function () {
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" && vm.fromTemplate) {
            return;
          }
          if (vm.accountsTo.length > 1)
                vm.toAccountDropdown = !vm.toAccountDropdown;
        };

        vm.setAccountTo = function (item) {
            vm.tempIntObj.toAccountSelect = item;
            vm.fromAccountDropdown = false;
            vm.toAccountDropdown = false;
        };

        var clearInput = function () {
            vm.templateName = '';
            vm.tempIntObj.fromAccountSelect = vm.accountsFrom[0];
            setToAccountList(vm.tempIntObj.fromAccountSelect.accountId);
            vm.tempIntObj.toAccountSelect = vm.accountsTo[0];
            vm.tempIntObj.amountInput = '';
            vm.tempIntObj.dueDateInput = new Date();
            vm.tempIntObj.formSubmitted = false;
            vm.smsPasswordInput = '';
            vm.smsAuthentificate = false;
        };

        vm.backToInternalTransfer = function () {
            clearInput();
            $state.go('payments.internalTransfer', {}, {reload: true})
        };

        // in BiH environment accounts must have same currency
        function filterAccountToSameCurrency() {
            if (vm.countryCode !== 'bh' && vm.countryCode !== 'sl') {
                return;
            }
            _.remove(vm.accountsTo, function (item) {
                return item.currencySymbol != vm.tempIntObj.fromAccountSelect.currencySymbol;
            });
            $log.debug("Accounts to ", vm.accountsTo);
        };

        //vm.getDefaultData = function () {
        //  return ConfigEndpointService.getDefaultData()
        //    .then(function (response) {
        //    if (response.APILocation) {
        //      $localStorage.code = response.APILocation;
        //      vm.countryCode = $localStorage.code;
        //      vm.bankCharges = response.BankCharges;
        //    }
        //  })
        //    .catch(function (error) {
        //    NotificationService.showMessage(error, 'error');
        //  });
        //};

        vm.init();
    }
}());
