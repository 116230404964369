<div class="wb-list-wrapper app-white-card m-b-15" ng-class="{'exchange-rates-ctn': exchangeRates.countryCode !=='mne', 'o-auto': exchangeRates.countryCode !=='hr'}" scrollable>

  <div>
      <span translate="currencyCalculator.updated">Updated</span>
    <span>{{exchangeRates.currentDate | date:'shortDate' : '+0200'}}</span>
  </div>

  <div ng-show="exchangeObjectList.result.length > 0">
    <!-- Data table -->
    <!--<div class="col-sm-12 hidden-xs p-t-10 p-b-10 rates-header">-->
      <!--<div class="col-sm-5" translate="payments.currencyName"></div>-->
      <!--<div class="col-sm-1" translate="payments.code"></div>-->
      <!--<div class="col-sm-1" translate="payments.unit"></div>-->
      <!--<div class="col-sm-2" translate="payments.buyRate"></div>-->
      <!--<div class="col-sm-2" translate="payments.sellRate"></div>-->
      <!--<div class="col-sm-1" translate="payments.action"></div>-->
    <!--</div>-->
    <div class="col-sm-12 p-t-5 p-b-5 exchange-list-item no-padding"
         data-ng-repeat="exchangeItem in ::exchangeObjectList.result"
         data-ng-class="{'last-list-item': $last, 'list-colored-el': $odd}">
      <div class="col-md-6 col-sm-4 currency-ctn">
        <div class="hidden-xs l-height-65">
          <img data-ng-src="img/hypo/flags/currency/{{exchangeItem.currencySymbol}}.png" height="40px" alt="">
          <span class="p-l-10 bold-font">{{::exchangeItem.currencySymbol}}</span>
        </div>
        <div class="hidden-sm hidden-md hidden-lg">
          <table class="table">
            <thead>
            <tr>
              <th colspan="2">
                <img data-ng-src="img/hypo/flags/currency/{{exchangeItem.currencySymbol}}.png" height="25px" alt="">
                {{::exchangeItem.currencySymbol}}
              </th>
              <!--<th>-->
                <!--<button type="button" class="btn btn-default app-btn-blue"-->
                        <!--data-ng-click="exchangeRates.buy(exchangeItem);"-->
                        <!--translate="payments.buy">-->
                <!--</button>-->
              <!--</th>-->
            </tr>
            </thead>
            <tbody>
            <!--<tr>-->
              <!--<td>Code</td>-->
              <!--<td>{{::exchangeItem.currencySymbol}}</td>-->
            <!--</tr>-->
            <tr>
              <td translate="payments.unit"></td>
              <td> {{exchangeRates.countryCode ==='hr'? '': '1'}} {{::exchangeItem.currencySymbol}}</td>
            </tr>
            <tr>
              <td translate="payments.buyRate"></td>
              <td ng-if="exchangeRates.countryCode != 'bh'" >1 {{::exchangeItem.buyRate}}</td>
              <td ng-if="exchangeRates.countryCode == 'bh'" >1 {{::exchangeItem.buyRate | number:10}}</td>
            </tr>
            <tr ng-if="exchangeRates.countryCode != 'sl'">
              <td translate="payments.middleRate"></td>
              <td ng-if="exchangeRates.countryCode != 'bh'" >1 {{::exchangeItem.calculationRate}}</td>
              <td ng-if="exchangeRates.countryCode == 'bh'" >1 {{::exchangeItem.calculationRate | number:10}}</td>
            </tr>
            <tr>
              <td translate="payments.sellRate"></td>
              <td ng-if="exchangeRates.countryCode != 'bh'" >{{::exchangeItem.sellRate}}</td>
              <td ng-if="exchangeRates.countryCode == 'bh'" >{{::exchangeItem.sellRate | number:10}}</td>
            </tr>

            </tbody>
          </table>
        </div>

      </div>
      <!--<div class="col-sm-1 hidden-xs">{{::exchangeItem.currencySymbol}}</div>-->
      <div class="col-md-2 col-sm-2 hidden-xs l-height-65"> {{exchangeRates.countryCode !== 'hr' ? exchangeItem.currency.currencyUnit : ''}} {{::exchangeItem.currencySymbol}}</div>
      <div class="col-md-4 col-sm-6 hidden-xs bold-font ex-rates">

        <div class="row">
          <div class="col-sm-6 col-lg-7"  translate="payments.buyRate"></div>
          <div ng-if="exchangeRates.countryCode != 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.buyRate}}</div>
          <div ng-if="exchangeRates.countryCode == 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.buyRate | number:10}}</div>
        </div>

        <div class="row" ng-if="exchangeRates.countryCode != 'sl'">
          <div class="col-sm-6 col-lg-7" translate="payments.middleRate"></div>
          <div ng-if="exchangeRates.countryCode != 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.calculationRate}}</div>
          <div ng-if="exchangeRates.countryCode == 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.calculationRate | number:10}}</div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-7" translate="payments.sellRate"></div>
          <div ng-if="exchangeRates.countryCode != 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.sellRate}}</div>
          <div ng-if="exchangeRates.countryCode == 'bh'" class="col-sm-6 col-lg-4">{{::exchangeItem.sellRate | number:10}}</div>
        </div>

      </div>

      <!--<div class="col-sm-1 hidden-xs">-->
        <!--<button type="button" class="btn btn-default app-btn-blue" data-ng-click="exchangeRates.buy(exchangeItem);"-->
                <!--translate="payments.buy">-->
        <!--</button>-->
      <!--</div>-->
    </div>

  </div>
  <div ng-show="exchangeObjectList.result.length == 0">
    No exchange rates info!
  </div>
</div>
