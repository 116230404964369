(function () {
  'use strict';

  /* @ngdoc object
   * @name core
   * @description
   *
   */
  angular
    .module('core', [
      'ui.router',
      'ngStorage',
      'ui.bootstrap',
      'ngSanitize'
    ]);
}());
