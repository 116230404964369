(function () {
  'use strict';

  /**
   * @author Dino Dujmovic
   * @ngdoc object
   * @name payments.controller:InternationalPaymentSloCtrl
   *
   * @description International Payment Controller for Slovenia
   *
   */
  angular
    .module('payments')
    .controller('InternationalPaymentSloCtrl', InternationalPaymentSloCtrl);

  function InternationalPaymentSloCtrl($rootScope, $scope, $state, $log, $q, $timeout, $filter, $http, $uibModal, $translate,
                                       CTLocalizationService, UserSettingsService, BicBanksService, CountriesService, PaymentTypesService,
                                       PaymentsService, HelperService, PaymentTemplatesService, CurrencyService, ValidationService,
                                       CreditAccountRegisterService, AlertService, ImagesService, NotificationService) {

    var vm = this;
    vm.dataLoaded = false;
    vm.isFromTemplate = false;
    vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
    // ======================================
    // =          Initialize                =
    // ======================================

    function init() {

      // --
      // Flags
      // --
      vm.forUpdate = false;
      vm.datePickerOpened = false;
      vm.steps = {
        step1: true,
        step2: false,
        step3: false
      };

      vm.tempForpObj = {
        receiversAddressInput: '',
        receiversPostalCodeAndCityInput: '',
        fromAccountSelect: {},
        amountInput: '',
        dueDateInput: new Date(),
        paymentPurposeInput: '',
        paymentMode: '',
        recipientStatusRadiobutton: {
          type: '2'
        },
        fullNameCompanyInput: '',
        recipientAddressInput: '',
        recipientCityInput: '',
        recipientCountrySelect: {},
        recipientAccountInput: '',
        bankNameInput: '',
        bankAddressInput: '',
        bankCityInput: '',
        bankCountrySelect: {},
        swiftCodeInput: '',
        bankChargesRadiobutton: {
          type: '0'
        },
        formSubmitted: false,
        receiversCountry: " " //choose first from the list
      };

      vm.paymentResponse = {};
      vm.showAdditionalData = true;

      vm.templateName = '';
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
      vm.uiMaskOptions = ValidationService.getUiMaskOptions().international.iban;
      // vm.uiMask = ValidationService.getUiMask().sl.internationalSI;
      vm.uiMask = ValidationService.getUiMask().sl.international;

      vm.dateOptions = {
        minDate: new Date(),
        maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
      };


      vm.disableAllFields = false;
      vm.disableAllFieldsForProcessedPayment = false;
      loadAllData();
    }

    // ======================================
    // =          Private methods           =
    // ======================================


    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.setChargeAccount = function (item) {
      vm.tempForpObj.totalAmountCurrency = item.currencySymbol;
      vm.tempForpObj.chargeAccountInput = item;
      vm.tempForpObj.fromAccountSelect = item;
      vm.chargeAccountOptions = _.find(vm.accountsFrom, {
        iban: item.iban
      });
      // console.log(vm.chargeAccountOptions);
    };

    function validateIBANLengthByAccountNumber(accountNumber) {
      if (!accountNumber) return;
      var ibanSI56 = accountNumber.substring(0, 4);
      if (ibanSI56 == "SI56") {
        vm.uiMask = ValidationService.getUiMask().sl.internationalSI;
      } else {
        vm.uiMask = ValidationService.getUiMask().sl.international;
      }
    }

    var populatePaymentFieldFromDomestic = function (payment) {
      var item = payment;
      var fromAccount = _.find(vm.accountsFrom, {accountId: payment.accountId});

      vm.setChargeAccount(fromAccount);

      vm.tempForpObj.amountInput = item.amount;
      vm.tempForpObj.fromAccountSelect = (fromAccount) ? fromAccount : vm.accountsFrom[0];
      var currencyOption = _.find(vm.currenciesOptions, {symbol: payment.currencySymbol});
      vm.tempForpObj.totalAmountCurrency = (currencyOption) ? currencyOption.symbol : 'EUR';

      validateIBANLengthByAccountNumber(item.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim());
      vm.tempForpObj.toAccountInput = item.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      vm.selectCountryBasedOnIban(vm.tempForpObj.toAccountInput);
      vm.tempForpObj.receiversAddressInput = item.domesticPayment.receiverAddress1.substring(0, 35);
      vm.tempForpObj.receiversNameInput = item.domesticPayment.receiverName.substring(0, 35);
      vm.tempForpObj.receiversPostalCodeAndCityInput = item.domesticPayment.receiverCity.substring(0, 35);
      vm.tempForpObj.paymentPurposeInput = item.purpose.substring(0, 35);
      // vm.tempForpObj.dueDateInput = new Date(payment.dueDate);
      vm.tempForpObj.dueDateInput = new Date();

      // Populate BIC fields from template
      // vm.tempForpObj.beneficiarySWIFTCode = item.beneficiarySWIFTCode;
      vm.getBicByIban(vm.tempForpObj.toAccountInput);
      var bankCountry = _.find(vm.bankCountries, {countryCode: payment.domesticPayment.receiverCountry});
      vm.tempForpObj.receiversCountry = (bankCountry) ? bankCountry : vm.bankCountries[0];

      vm.onCountrySelect(_.find(vm.countriesOptions, {
        countryCode: item.domesticPayment.receiverCountry
      }));

      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;
    };


    vm.selectCountryBasedOnIban = function (input) {
      if (!input) {
        vm.tempForpObj.countryByIban = '';
        return;
      }
      var prefix = input.substring(0, 2);
      var bankCountry = _.find(vm.bankCountries, {countryCode: prefix});
      vm.tempForpObj.countryByIban = (bankCountry) ? bankCountry : '';
      if (vm.tempForpObj.countryByIban && vm.tempForpObj.countryByIban.countryIsEgp) {
        vm.tempForpObj.bankChargesRadiobutton.type = "0";
      }
    };

    vm.selectCountry = function (input) {
      if (!input) return;
      var prefix = input.substring(0, 2);
      var bankCountry = _.find(vm.bankCountries, {countryCode: prefix});
      vm.tempForpObj.receiversCountry = (bankCountry) ? bankCountry : vm.bankCountries[0];
      vm.selectCountryBasedOnIban(input);
    };

    function loadPaymentData(paymentId) {
      if (!paymentId)
        return;

      PaymentsService.getById(paymentId)
        .then(function (response) {

          var country = _.find(vm.countriesOptions, {
            code: response.beneficiaryBankCountryCode
          });
          if (country) {
            vm.countrySearchFilter = country.description;
            vm.tempForpObj.bankCountrySelect = country;
          }

          vm.tempForpObj.amountInput = response.amount;
          vm.tempForpObj.toAccountInput = response.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
          vm.selectCountryBasedOnIban(vm.tempForpObj.toAccountInput);
          vm.tempForpObj.recipientAccountInput = response.toAccountNumber;
          //vm.payeeSearchFilter = response.receiverName;
          vm.tempForpObj.receiversNameInput = response.receiverName;
          vm.tempForpObj.receiversAddressInput = response.receiverAddress1;
          vm.tempForpObj.paymentPurposeInput = response.purpose;
          vm.tempForpObj.fullNameCompanyInput = response.payeeName || '';
          vm.tempForpObj.recipientAddressInput = response.payeeAddress1 || '';
          vm.tempForpObj.recipientCityInput = response.payeeCity || '';
          vm.tempForpObj.bankAddressInput = response.beneficiaryBankAddress;
          vm.tempForpObj.bankCityInput = response.beneficiaryBankCity;
          vm.tempForpObj.bankNameInput = response.beneficiaryBankName;
          vm.tempForpObj.modalityCode = response.modalityCode;
          vm.tempForpObj.bankChargesRadiobutton.type = response.paymentCharges || '0';
          vm.tempForpObj.recipientStatusRadiobutton.type = response.payeeLegalForm || '2';
          vm.tempForpObj.beneficiarySWIFTCode = response.beneficiarySWIFTCode;
          vm.tempForpObj.receiversPostalCodeAndCityInput = response.receiverCity;
          vm.tempForpObj.receiversCountry = _.find(vm.bankCountries, {code: response.receiverCountryCode});
//            vm.bicCodeSearchFilter = response.beneficiarySWIFTCode;
          loadAccountData(response.accountId);
          // vm.setChargeAccount(vm.tempForpObj.fromAccountSelect);

          vm.paymentResponse.id = paymentId;
          if ($state.params.editable)
            vm.forUpdate = true;

          if ($state.params.myParam == 'repeatCancelled') {
            vm.tempForpObj.dueDateInput = new Date(response.dueDate);
            vm.disableAllFields = true;
            vm.disableAllFieldsForProcessedPayment = true;
//select total currency based on response.currencySymbol not on fromAccount.currencySymbol
            if (response.currencySymbol) vm.tempForpObj.totalAmountCurrency = response.currencySymbol;
          }
          if ($state.params.myParam == 'changeProcessed') {
            vm.tempForpObj.dueDateInput = new Date(response.dueDate);
            vm.disableAllFields = true;
            vm.disableAllFieldsForProcessedPayment = false;
//select total currency based on response.currencySymbol not on fromAccount.currencySymbol
            if (response.currencySymbol) vm.tempForpObj.totalAmountCurrency = response.currencySymbol;
          }          
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }


    function getUserSettingsPage() {
      UserSettingsService.getPage({page: 0, pageSize: 1000})
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempForpObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempForpObj.payersAddressInput = userSettings.address1;
          vm.tempForpObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.error(error);
        });
    }

    function getCountries() {
      return CountriesService.getPage({page: 0, pageSize: 1000})
        .then(function (response) {
          vm.bankCountries = response.result;
          // vm.bankCountries.unshift({
          //   code: " ",
          //   countryCode: " ",
          //   countryCodeShort: " ",
          //   description: "internationalPayment.chooseCountry"
          // });
          vm.tempForpObj.receiversCountry = vm.bankCountries[0];
        })
        .catch(function (error) {
          $log.error(error);
        });
    }

    function getDebitAccountList() {
      return PaymentTypesService.getByGroup({paymentTypeGroup: 'ForeignPayment'})
        .then(function (response) {
          if (_.isArray(response.result) && response.result.length > 0) {
            vm.accountsFrom = response.result[0].debitAccountList;
            _.forEach(vm.accountsFrom, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.accountDescription + ' - ' + item.iban;
              }
            });
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    }

    function getPayees() {
      var paymentParams = {
        paymentTypeGroup: 'ForeignPayment',
        orderBy: 'receiverName ASC',
        page: 0,
        pageSize: 1000
      };

      PaymentTemplatesService.getPage(paymentParams)
        .then(function (response) {
          vm.payeeList = response.result;
          if ($state.params && $state.params.templateId) {
            vm.selectTemplate($state.params.templateId);
          }
        }).catch(function (error) {
        $log.error(error);
      });
    }


    function createPaymentObject() {
      var paymentObject = {};
      paymentObject.accountId = vm.tempForpObj.fromAccountSelect.accountId;
      paymentObject.fromAccountNumber = vm.tempForpObj.fromAccountSelect.iban;

      paymentObject.toAccountNumber = (vm.tempForpObj.toAccountInput) ? vm.tempForpObj.toAccountInput.replace(/\s/g, '') : "";
      paymentObject.currencySymbol = vm.tempForpObj.totalAmountCurrency;
      paymentObject.purpose = vm.tempForpObj.paymentPurposeInput;
      paymentObject.dueDate = vm.tempForpObj.dueDateInput;
      paymentObject.amount = vm.tempForpObj.amountInput;
      paymentObject.paymentTypeGroup = 'ForeignPayment';
      paymentObject.foreignPayment = {
        receiverName: vm.tempForpObj.receiversNameInput,
        receiverAddress1: vm.tempForpObj.receiversAddressInput,
        receiverCity: vm.tempForpObj.receiversPostalCodeAndCityInput,
        receiverCountryCode: vm.tempForpObj.receiversCountry.code,
        senderName: vm.tempForpObj.fromAccountSelect.ownerName,
        senderAddress1: vm.tempForpObj.fromAccountSelect.ownerAddress,
        senderCity: vm.tempForpObj.fromAccountSelect.ownerCity,
        beneficiarySWIFTCode: vm.tempForpObj.beneficiarySWIFTCode,
        beneficiaryBankName: vm.tempForpObj.bankNameInput,
        beneficiaryBankAddress: vm.tempForpObj.bankAddressInput,
        beneficiaryBankCity: vm.tempForpObj.bankCityInput,
        beneficiaryBankCountryCode: vm.tempForpObj.bankCountrySelect.code,
        beneficiaryBankCountry: vm.tempForpObj.bankCountrySelect.description,
        //beneficiaryBankCountry: "",
        foreignBankAddress3: "",
        //TODO: Seems like recieverCountryCode selects first country from the list of countries and there is no way to choose one - but is required by backend!!!!!
        //receiverCountryCode: vm.tempForpObj.bankCountrySelect.code,
        //referenceFrom: null,
        referenceTo: null,
        // need to add field on payment form
        chargeAccount: (vm.tempForpObj.bankChargesRadiobutton.type != 2) ? vm.tempForpObj.chargeAccountInput.accountNumber + ' ' + vm.tempForpObj.chargeAccountInput.currencySymbol : "",
        payeeLegalForm: vm.tempForpObj.recipientStatusRadiobutton.type,
        paymentCharges: vm.tempForpObj.bankChargesRadiobutton.type,
        // paymentUsedMode: vm.tempForpObj.paymentUsedMode
        modalityCode: vm.tempForpObj.modalityCode
      };

      if ($state.params.myParam == 'changeProcessed') {
        paymentObject.relatedSourceType = 'ChangePayment';
      }

      return paymentObject;
    }

    // ======================================
    // =          Public methods            =
    // ======================================

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.selectPayee = function (id) {
      vm.selectTemplate(id);
      PaymentTemplatesService.getById(id);
    };

    vm.removeSearch = function () {
      vm.tempForpObj.receiversNameInput = '';
      vm.tempForpObj.toAccountInput = '';
      vm.tempForpObj.receiversAddressInput = '';
      vm.tempForpObj.receiversPostalCodeAndCityInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempForpObj.receiversNameInput) || '') !== -1 ||
        angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempForpObj.receiversNameInput) || '') !== -1);
    };


    /*
     * START: SELECT FROM TEMPLATE - METHODS
     */
    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;
      if (vm.searchTemplateFilterExpanded === true) {
        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      getPaymentTemplatesPage();
      vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
    };

    function getPaymentTemplatesPage() {
      var templatesParams = {
        paymentTypeGroup: 'ForeignPayment',
        page: 0,
        pageSize: 1000
      };

      PaymentTemplatesService.getPage(templatesParams)
        .then(function (response) {
          vm.templateList = response;
          if (vm.templateList)
            populateTemplatesWithImages();
          $log.debug(vm.templateList);
        }).catch(function (error) {
        $log.error(error);
      });
    }

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    vm.selectedTemplate = {};
    vm.selectTemplate = function (id) {
      PaymentTemplatesService.getById(id)
        .then(function (response) {
          var item = response;
          loadAccountData(item.accountId);
          // vm.tempForpObj.amountInput = item.amount;
          vm.tempForpObj.toAccountInput = item.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
          vm.selectCountryBasedOnIban(vm.tempForpObj.toAccountInput);
          vm.tempForpObj.receiversAddressInput = item.receiverAddress1;
          vm.tempForpObj.receiversNameInput = item.receiverName;
          vm.tempForpObj.receiversPostalCodeAndCityInput = item.receiverCity;
          vm.tempForpObj.paymentPurposeInput = item.purpose;
          vm.bicCodeDisabled = false;
          // Populate BIC fields from template
          if (item.beneficiarySWIFTCode) {
            console.log("ovdje");
            vm.tempForpObj.beneficiarySWIFTCode = item.beneficiarySWIFTCode;
            vm.bicCodeDisabled = true;
          } else {
            vm.getBicByIban(vm.tempForpObj.toAccountInput);
          }
//            vm.bicCodeSearchFilter = item.beneficiarySWIFTCode;
          vm.tempForpObj.bankAddressInput = item.beneficiaryBankAddress;
          vm.tempForpObj.bankCityInput = item.beneficiaryBankCity;
          vm.tempForpObj.bankNameInput = item.beneficiaryBankName;
          vm.tempForpObj.modalityCode = item.modalityCode;
          vm.tempForpObj.bankChargesRadiobutton.type = item.paymentCharges || '0';
          vm.tempForpObj.recipientStatusRadiobutton.type = item.payeeLegalForm || '2';

          var bankCountry = _.find(vm.bankCountries, {code: item.beneficiaryBankCountryCode});
          vm.tempForpObj.receiversCountry = (bankCountry) ? bankCountry : vm.bankCountries[0];

          var country = _.find(vm.countriesOptions, {
            code: item.beneficiaryBankCountryCode
          });
          if (country) {
            console.log(country);
            vm.onCountrySelect(country);
          }
          vm.templateName = item.name;
          //vm.payeeSearchFilter = item.receiverName;
          vm.tempForpObj.receiversNameInput = item.receiverName;
          vm.searchFilterExpanded = !vm.searchFilterExpanded;
          vm.searchFilterExpanded = false;
          vm.searchTemplateFilterExpanded = false;

          vm.isFromTemplate = true;

          vm.selectedTemplate.imageId = item.imageId;
          setImage(vm.selectedTemplate);

        }, function (error) {
          AlertService.infoAlert({text: "Loading from template #" + id + " failed!"});
        });
    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    };

    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      //vm.payeeSearchFilter = '';
      vm.tempForpObj.receiversNameInput = '';
      vm.countrySearchFilter = '';
      vm.bicCodeSearchFilter = '';
      vm.tempForpObj.swiftCodeInput = "";
      vm.tempForpObj.bankAddressInput = "";
      vm.tempForpObj.bankCityInput = "";
      vm.tempForpObj.bankNameInput = "";
      vm.tempForpObj.beneficiarySWIFTCode = "";
      vm.tempForpObj.paymentPurposeInput = "";
      vm.tempForpObj.receiversCountry = vm.bankCountries[0];
      // vm.tempForpObj = {};
      vm.tempForpObj.toAccountInput = '';
      vm.tempForpObj.receiversAddressInput = '';
      vm.tempForpObj.receiversPostalCodeAndCityInput = '';
      vm.tempForpObj.amountInput = '';
      vm.isFromTemplate = false;
      vm.bicCodeDisabled = false;
      vm.selectedTemplate = '';
      vm.selectCountryBasedOnIban(null);
    };
    /*
     * END: SELECT FROM TEMPLATE - METHODS
     */

    var fillReceiversInput = function (response) {
      if (response.result.length > 0) {
        vm.tempForpObj.receiversNameInput = response.result[0].receiverName;
        //vm.payeeSearchFilter = response.result[0].receiverName;
        vm.tempForpObj.receiversAddressInput = response.result[0].receiverAddress1;
        vm.tempForpObj.receiversPostalCodeAndCityInput = response.result[0].receiverCity;
        // TODO: Populate bic number (currently request returns nothing)
      }
    };

    vm.fillReceiver = function (toAccountInput) {
      if (!toAccountInput)
        return;
      toAccountInput = toAccountInput.replace(/\s/g, '');
      if (toAccountInput.substring(0, 2) === 'HR') {
        CreditAccountRegisterService.getByIban(toAccountInput)
          .then(function (response) {
            fillReceiversInput(response);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      } else {
        CreditAccountRegisterService.getPage({
          accountNumber: toAccountInput
        })
          .then(function (response) {
            fillReceiversInput(response);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.steps = {
        step1: true,
        step2: false,
        step3: false
      };
    };

    var loadAccountData = function (accountId) {
      var fromAccount = _.find(vm.accountsFrom, function (item) {
        return item.accountId == accountId;
      });
      vm.tempForpObj.fromAccountSelect = fromAccount;
      vm.setChargeAccount(fromAccount);
    };

    vm.addSpaceForIban = function (toAccountInput) {
      if (!vm.tempForpObj.toAccountInput)
        return;
      //vm.tempForpObj.toAccountInput = toAccountInput.replace(/[^\dA-Z-/]/g, '').replace(/(.{4})/g, '$1 ').trim();
      validateIBANLength();
    };

    function validateIBANLength() {
      var ibanSI56 = vm.tempForpObj.toAccountInput.substring(0, 4);
      var ibanLength = vm.tempForpObj.toAccountInput.length;
      // var spaceNumber = Math.floor(ibanLength / 4);
      if (ibanSI56 === "SI56") {
        if (ibanLength < 19) {
          vm.uiMask = ValidationService.getUiMask().sl.internationalSI;
          vm.paymentForm.toAccountInput.$setValidity("minlength", false);
        } else {
          vm.paymentForm.toAccountInput.$setValidity("minlength", true);
        }
        // vm.ibanMaxlength = 19 + spaceNumber;
        vm.ibanMaxlength = 19;
      } else {
        vm.uiMask = ValidationService.getUiMask().sl.international;
        vm.paymentForm.toAccountInput.$setValidity("minlength", true);
        // vm.ibanMaxlength = 34 + spaceNumber;
        vm.ibanMaxlength = 34;
      }
    }

    vm.getBicByIban = function (iban) {
      if (!iban) {
        vm.bicCodeDisabled = false;
        vm.tempForpObj.beneficiarySWIFTCode = '';
        return;
      }

      iban = iban.replace(/\s/g, '');
      PaymentsService.getBicBanks(undefined, {
        iban: iban,
        paymentTypeGroup: 'ForeignPayment'
      })
        .then(function (response) {
          var bicBank = response.result[0];
          if (bicBank && bicBank.bicCode) {
            vm.tempForpObj.beneficiarySWIFTCode = bicBank.bicCode;
            vm.bicCodeDisabled = true;
          } else {
            vm.bicCodeDisabled = false;
            vm.tempForpObj.beneficiarySWIFTCode = '';
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
          vm.bicCodeDisabled = false;
          vm.tempForpObj.beneficiarySWIFTCode = '';
        });
    };

    vm.checkCountryAndSymbol = function () {
      var code = vm.tempForpObj.bankCountrySelect.code;
      var symbol = vm.tempForpObj.totalAmountCurrency;
      if (!code) {
        vm.EUCountrySelected = false;
      }
      if (!symbol) {
        vm.EUSymbolSelected = false;
      }
      if (!code || !symbol)
        return;

      PaymentsService.getCountries(false, {countryCode: code})
        .then(function (response) {
          var country = response.result[0] || false;
          if (country.countryIsEgp) {
            vm.EUCountrySelected = true;
            PaymentsService.getCountries(false, {countryCurrencySymbol: symbol})
              .then(function (response) {
                var result = response.result;
                var symbolCountry = result[0] || false;
                if (symbolCountry.countryIsEgp) {
                  // Disable Charge option if EU country is selected because option must be "SHA"
                  vm.tempForpObj.bankChargesRadiobutton.type = '0';
                  vm.EUSymbolSelected = true;
                }
                else
                  vm.EUSymbolSelected = false;
              })
              .catch(function (error) {
                NotificationService.showMessage(response, 'error');
              });

          } else {
            vm.EUCountrySelected = false;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(response, 'error');
        });
    };

    vm.newPayment = function () {
      $state.go('payments.internationalPayment', {}, {reload: true})
    };

    vm.removeBicCodeSearch = function () {
      vm.bicCodeSearchFilter = "";
      vm.tempForpObj.swiftCodeInput = "";
      vm.tempForpObj.bankAddressInput = "";
      vm.tempForpObj.bankCityInput = "";
      vm.tempForpObj.bankNameInput = "";
    };

    /*
     * Countries selectbox - on list click
     * */
    vm.onCountrySelect = function (country) {
      console.log(country);
      vm.countrySearchFilter = country.code + " " + country.description;
      vm.tempForpObj.bankCountrySelect = country;
      vm.countrySearchFilterExpanded = false;
      vm.checkCountryAndSymbol();
    };

    /*
     * Countries selectbox - remove
     * */
    vm.removeSelectedCountry = function () {
      vm.countrySearchFilter = "";
      vm.tempForpObj.bankCountrySelect = {};
      vm.countrySearchFilterExpanded = true;
      vm.EUCountrySelected = false;
      vm.EUSymbolSelected = false;
    };

    // ======================================
    // =          Button methods            =
    // ======================================

    vm.submitInternationalPayment = function (event) {
      // Check if form is valid
      vm.tempForpObj.formSubmitted = true;
      if (!vm.paymentForm.$valid) {
        return false;
      }

      // If form is valid create object for update
      var paymentObject = createPaymentObject();

      // If selected country has amlControl call statement confirmation modal
      if (vm.tempForpObj.bankCountrySelect.amlControl) {
        // PaymentsService.openStatementConfirmModal(vm.tempForpObj.bankCountrySelect)
        PaymentsService.openStatementConfirmModal(vm.tempForpObj)
          .result.then(function (result) {
          // Add more information to payment object
          if (!result)
            return;
          else {
            paymentObject.foreignPayment.beneficiaryBankCountry = result.stateOption;
            paymentObject.foreignPayment.foreignBankAddress3 = result.information;
          }
          upsertInternationalPayment(paymentObject);

        });
        return;
      }

      upsertInternationalPayment(paymentObject);
    };

    function upsertInternationalPayment(paymentObject) {
      if (vm.nextButtonPressed) return;

      if (!vm.formSubmitted) {
        vm.formSubmitted = true;

        vm.nextButtonPressed = true;
        if (vm.forUpdate) {
          paymentObject.id = vm.paymentResponse.id;
          PaymentsService.updatePayment(vm.paymentResponse.id, paymentObject)
            .then(function (response) {
              postPaymentAction(response);
            })
            .catch(function (response) {
              if (response.hasWarnings){ 
                var isAmountWarning = response.resultList[0].key == 'Amount';
                if (isAmountWarning) {
                  NotificationService.showMessage(response, 'error');
                } else if (response.resultList[0].key == 'ToAccountNumber') {
                  //show action message modal
                  PaymentsService.showModalWithActions(response, paymentObject);
                } else {
                  NotificationService.showMessage(response, 'error');
                }
              } else {
                NotificationService.showMessage(response, 'error');
              }
            })
            .finally(function () {
              vm.forUpdate = false;
              vm.formSubmitted = false;
              vm.nextButtonPressed = false;
            });
        } else {

          var insertedDueDate = paymentObject.dueDate;

          PaymentsService.insertPayment(paymentObject)
            .then(function (response) {
              var validatedDueDate = new Date(response.dueDate);
              if (response.dueDateChanged) {
                AlertService.confirmationAlert({text: "payments.paymentDueDateChangedMsg"})
                  .then(function (accept) {
                    if (!accept) return;
                    secondValidate(response);
                  })
              } else {
                postPaymentAction(response);
              }
            })
            .catch(function (response) {

              if (response.hasWarnings) {
                var isAmountWarning = response.resultList[0].key == 'Amount';
                if (isAmountWarning) {
                  NotificationService.showMessage(response, 'error');
                } else {
                  PaymentsService.showModalWithActions(response, paymentObject);
                }
                //show action message modal
              }
              else {
                NotificationService.showMessage(response, 'error');
              }
            })
            .finally(function () {
              vm.formSubmitted = false;
              vm.nextButtonPressed = false;
            });

        }

      }
    }

    function secondValidate(validatedUPOrder) {
      if (vm.secondValidateInProgress) return;
      vm.secondValidateInProgress = true;
      PaymentsService.insertPayment(validatedUPOrder)
        .then(function (response) {
          postPaymentAction(response);
        })
        .catch(function (response, error) {
          if (response && response.hasWarnings) {
            //show action message modal
            var isAmountWarning = response.resultList[0].key == 'Amount';
            if (isAmountWarning) {
              NotificationService.showMessage(response, 'error');
            } else {
              PaymentsService.showModalWithActions(response, validatedUPOrder);
            }
          } else {
            NotificationService.showMessage(response, 'error');
          }
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.nextButtonPressed = false;
          vm.secondValidateInProgress = false;
        });
    }

    vm.sign = function () {
      if (vm.requestOtpInput)
        vm.paymentResponse.signData = vm.requestOtpInput;
      else
        vm.paymentResponse.signData = '';

      if (vm.actionLinks.sign) {
        $http.post(vm.actionLinks.sign, vm.paymentResponse)
          .then(function (response) {
            vm.insertedPaymentObject = response.data;
            $state.go('payments.finalStep', {
              paymentId: vm.insertedPaymentObject.id,
              paymentObject: vm.insertedPaymentObject,
              type: 'international',
              isFromTemplate: vm.isFromTemplate
            });
            //vm.currentStep = 3;
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          })
          .finally(function () {
            vm.formSubmitted = false;
            vm.forUpdate = false;
            vm.signLink = null;
          });
      }
    };

    function postPaymentAction(response) {
      vm.paymentResponse = response;

      if (vm.paymentResponse && vm.paymentResponse.foreignPayment && 
        (vm.paymentResponse.foreignPayment.conversionRate === '' || vm.paymentResponse.foreignPayment.conversionRate === null)
        ) {
        vm.showAdditionalData = false;
      } else {
        vm.showAdditionalData = true;
      }

      

      vm.tempForpObj.dueDateInput = new Date(response.dueDate);
      vm.paymentResponse.dueDate = new Date(response.dueDate);
      vm.paymentResponse.paymentTypeGroup = 'ForeignPayment';

      if (response.foreignPayment.receiverCountryCode) {
        var recCountry = _.find(vm.countriesOptions, {
          code: response.foreignPayment.receiverCountryCode
        });
        if (recCountry) {
          vm.paymentResponse.foreignPayment.receiverCountry = recCountry.description;
        }
      }

      switch (response.paymentCharges) {
        case "1":
          vm.paymentResponse.bankCharges = "Payers pays charges";
          break;
        case "2":
          vm.paymentResponse.bankCharges = "Beneficiary pays charges";
          break;
        case "3":
          vm.paymentResponse.bankCharges = "Shared charges";
          break;
      }

      var country = _.find(vm.countriesOptions, {
        code: vm.paymentResponse.beneficiaryBankCountryCode
      });
      if (country) {
        vm.paymentResponse.beneficiaryBankCountryCode = country.code + " " + country.description;
      }

      vm.paymentResponse.payeeListId = vm.tempForpObj.recipientStatusRadiobutton.type;
      vm.steps = {
        step1: false,
        step2: true,
        step3: false
      };
    }

    vm.deletePayment = function () {
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deletePayment(vm.paymentResponse.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({paymentName: vm.paymentResponse.id, type: 'payment'})
                  .then(function () {
                    $state.go('payments.paymentsOverview');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    vm.signOrFinishPayment = function () {

      // Umjesto ovog dijela ispod dodan poziv na api kako bi se provjerili svi paymentTemplates po toAccountNumber
      // ostala logika ostaje ista
      //
      // var ibanInTemplates = vm.isFromTemplate || !_.every(vm.templateList.result, function (item) {
      //   return item.toAccountNumber.replace(/ /g, '') !== vm.tempForpObj.toAccountInput.replace(/ /g, '');
      // });

      PaymentTemplatesService.getPage({toAccountNumber: vm.tempForpObj.toAccountInput.replace(/ /g, '')})
        .then(function (response) {

          var ibanInTemplates = '';
          if (response.result.length) {
            ibanInTemplates = true;
          }

          if ($state.params.myParam === 'changeProcessed' && ibanInTemplates) {
            updatePayment();
            return;
          }

          if (vm.paymentResponse.linkList && vm.paymentResponse.linkList.itemList && vm.paymentResponse.linkList.itemList.requestOTP) {

            //TSK-114327
            if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
              vm.paymentResponse.paymentIdTemp = $state.params.paymentId;

            $state.go("payments.sign", {
              payment: vm.paymentResponse,
              paymentId: vm.paymentResponse.id,
              type: 'international'
            });
            return;
          }

          if (vm.isFromTemplate || ibanInTemplates) {
            signPayment();
          }
          else {
            //TSK-114327
            if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
              vm.paymentResponse.paymentIdTemp = $state.params.paymentId;

            $state.go("payments.sign", {
              payment: vm.paymentResponse,
              paymentId: vm.paymentResponse.id,
              type: 'international'
            });
          }

        })
        .catch(function (response, error) {
          NotificationService.showMessage(response, 'error');
        });


      // if ($state.params.myParam === 'changeProcessed' && ibanInTemplates) {
      //   updatePayment();
      //   return;
      // }

      // if (vm.paymentResponse.linkList && vm.paymentResponse.linkList.itemList && vm.paymentResponse.linkList.itemList.requestOTP) {
      //
      //   //TSK-114327
      //   if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
      //     vm.paymentResponse.paymentIdTemp = $state.params.paymentId;
      //
      //   $state.go("payments.sign", {
      //     payment: vm.paymentResponse,
      //     paymentId: vm.paymentResponse.id,
      //     type: 'international'
      //   });
      //   return;
      // }
      // When I make UPN from template, the additional page should not be displayed. (Sign page should not be there)
      // When I make UPN to account, not saved in templates and is outsite Addiko bank, the additional page for OTP confirmation should appear.
      // When I make UPN to internal (SI5633) Addiko bank account of the company, the additional page for OTP confirmation should appear.
      // _.every Returns true if all of the values in the list pass the predicate truth test. Short-circuits and stops traversing the list if a false element is found.
      // if (vm.isFromTemplate || !_.every(vm.templateList.result, function (item) {
      //   return item.toAccountNumber.replace(/ /g, '') != vm.tempForpObj.toAccountInput.replace(/ /g, '')
      // })) {
      //   signPayment();
      // }
      // else {
      //
      //   //TSK-114327
      //   if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
      //     vm.paymentResponse.paymentIdTemp = $state.params.paymentId;
      //
      //   $state.go("payments.sign", {
      //     payment: vm.paymentResponse,
      //     paymentId: vm.paymentResponse.id,
      //     type: 'international'
      //   });
      // }
    };

    function updatePayment() {
      if (vm.signPaymentInProgress) return;
      var params = {
        id: $state.params.paymentId,
        payment: vm.paymentResponse
      };
      vm.signPaymentInProgress = true;
      PaymentsService.updatePayment(params.id, params.payment, 'changeProcessed')
        .then(function (signedPayment) {
          $state.go('payments.finalStep', {
            paymentObject: signedPayment.payment || signedPayment,
            paymentId: signedPayment.id,
            type: "international"
          });
        })
        .catch(function (response, error) {
          if (response && response.hasWarnings) {
            //show action message modal
            var isAmountWarning = response.resultList[0].key == 'Amount';
            if (isAmountWarning) {
              NotificationService.showMessage(response, 'error');
            } else {
              PaymentsService.showModalWithActions(response, objectForInsert);
            }
          } else {
            NotificationService.showMessage(response, 'error');
          }
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.signPaymentInProgress = false;
        });


    }

    function signPayment() {
      if (vm.signPaymentInProgress) return;
      var params = {
        id: vm.paymentResponse.id,
        payment: vm.paymentResponse
      };
      params.payment.signData = "";

      vm.signPaymentInProgress = true;
      PaymentsService.signPayment(params).then(function (signedPayment) {
        $state.go('payments.finalStep', {
          paymentObject: signedPayment.payment || signedPayment,
          paymentId: signedPayment.id,
          type: "international",
          isFromTemplate: vm.isFromTemplate
        });
      }).catch(function (error) {
        $log.error(error);
        if (error && error.resultList[0] && error.resultList[0].key === 'CUTOFF') {
          signLastPayment(error.resultList[0].description, params);
        } else if (error && error.resultList[0] && error.resultList[0].key === 'UpdateOldDate') {
          openUpdateSinglePaymentDueDateModal(params);
        } else {
          NotificationService.showMessage(error, 'error');
        }
      }).finally(function () {
        vm.signPaymentInProgress = false;
      });
    }

    vm.checkToAccountInputLength = function () {
      var validLength = true;
      if (vm.tempForpObj.beneficiarySWIFTCode) {
        validLength = vm.tempForpObj.beneficiarySWIFTCode.length === 8 || vm.tempForpObj.beneficiarySWIFTCode.length === 11;
      }
      vm.paymentForm.bicAddress.$setValidity('properLength', validLength);
    };

    vm.goBack = function () {
      $state.go('payments.paymentsOverview');
    };

    vm.templateImagesFilter = {
      category: "Templates"
    };
    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        if (images)
          vm.images = images.result;
      }).catch(function (err) {
        $log.error(err);
      });
    };

    function loadAllData() {
      getPaymentTemplatesPage();
      getUserSettingsPage();

      var promises = [
        CountriesService.getPage({pageSize: 1000}),
        CurrencyService.getCurrencyPage({pageSize: 1000}),
        getCountries(),
        getDebitAccountList()
      ];

      $q.all(promises)
        .then(function (responses) {
          getPayees();
          vm.countriesOptions = responses[0].result;
          vm.currenciesOptions = responses[1].result;
          vm.currenciesOptions = _.without(vm.currenciesOptions, _.find(vm.currenciesOptions, {symbol: "BAM"}));

          if ($state.params && $state.params.paymentId) {
            loadPaymentData($state.params.paymentId);
          } else if ($state.current && $state.current.name == 'payments.internationalPayment.fromTemplatesToPay' && $state.params.templateId) {
            // vm.setChargeAccount(vm.accountsFrom[0]);
            vm.selectTemplate($state.params.templateId);
          }
          else if ($state.current && $state.current.name == 'payments.internationalPayment.fromDomestic') {
            populatePaymentFieldFromDomestic($state.params.transactionDetails);
          }
          else {
            if (vm.bankCountries.length > 0) {
              vm.tempForpObj.recipientCountrySelect = vm.bankCountries[0];
              vm.tempForpObj.bankCountrySelect = vm.bankCountries[0];
            }
            if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
              vm.tempForpObj.fromAccountSelect = vm.accountsFrom[0];

              vm.tempForpObj.fromAccountSelect = PaymentsService.setFromAccountsToDefaulCurrency(vm.accountsFrom);
              $log.debug(vm.tempForpObj.fromAccountSelect);
              vm.setChargeAccount(vm.tempForpObj.fromAccountSelect);
            }
          }

          vm.dataLoaded = true;
          loadImages();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    init();

  }
}());
