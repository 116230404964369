<div ng-controller="SavingsSloCtrl as savingsSlo">
  <section id="main">
    <div class="container-fluid">
      <!-- START Title -->
      <div style="padding: 15px">
        <div class="row">
          <div class="domp-header-wrapper col-sm-12 p-o">
            <div class="domp-header-row">
              <h2 translate="savingsSlo.title">Savings account</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- END Title-->
      <div class="row">
        <div class="col-md-12">
          <div ng-if="cashWithdrawal.countryCode != 'sl'" class="alert alert-info"
               style="background: white; color: black; border: 1px solid lightgray" role="alert">

            <p translate="savingsSlo.infoNote1"></p>
            <p translate="savingsSlo.infoNote2"></p>
            <p translate="savingsSlo.infoNote3"></p>
          </div>
        </div>
      </div>
      <div class="p-b-30">
        <div class="p-b-30">
          <ng-form name="savingsSlo.savingsForm">
            <div class="cash-withdrawing-section-frame">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label class="domp-form-label" translate="savingsSlo.vrstaVracevanja"></label>
                    <select name="vrstaVracevanja"
                            class="form-control input-field p-l-15 p-r-10"
                            ng-options="option.name for option in savingsSlo.vrstaVracevanja"
                            ng-model="savingsSlo.vrstaVracevanjaSelect"
                            required>
                    </select>
                  </div>
                </div>
                <div ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" class="col-xs-12 col-sm-3">

                  <div class="form-group">
                    <label class="domp-form-label" translate="savingsSlo.durationInMonths"></label>
                    <select name="durationInMonths"
                            class="form-control input-field p-l-15 p-r-10"
                            ng-options="option.name for option in savingsSlo.durationInMonths"
                            ng-model="savingsSlo.durationInMonthsSelect"
                            required>
                    </select>
                  </div>
                </div>
                <div ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" class="col-xs-12 col-sm-3">
                  <label class="domp-form-label" translate="savingsSlo.maturityDate"></label>
                  <input type="text" class="form-control input-field" name="date"
                         ng-model="savingsSlo.maturityDate"
                         readonly>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">

                  <label ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" class="domp-form-label" translate="savingsSlo.transfersFromAndToAccount"></label>
                  <label ng-if="savingsSlo.vrstaVracevanjaSelect.value == '0'" class="domp-form-label" translate="savingsSlo.initialAmountFromAccount"></label>
                  <div class="accounts-dropdown">
                    <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                         data-ng-click="cashWithdrawal.showAccountToDropdown = !cashWithdrawal.showAccountToDropdown" ng-style="cashWithdrawal.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + cashWithdrawal.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                          {{cashWithdrawal.selectedAccount.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{cashWithdrawal.selectedAccount.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{cashWithdrawal.selectedAccount.availableBalance | number:2}}
                        {{cashWithdrawal.selectedAccount.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon">
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
              </span>
                    </div>
                    <div class="from-accounts-list m-l-15" ng-show="cashWithdrawal.showAccountToDropdown" scrollable>
                      <div class="accounts-list-item p-0"
                           data-ng-repeat="account in cashWithdrawal.accountList"
                           data-ng-click="cashWithdrawal.selectAccount(account); cashWithdrawal.showAccountToDropdown = !cashWithdrawal.showAccountToDropdown">
                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                          <h4 class="no-wrap-text bold-font m-b-0">
                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{account.friendlyName}}</b>
                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                          </h4>
                          <p class="m-b-0 m-t-0 iban-style">{{account.iban}}</p>
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                            {{account.availableBalance | number:2}} {{account.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- START Amount and Date -->
                <div class="col-xs-12 col-sm-6">
                  <!-- START Amount -->
                  <div class="row m-0">
                    <div class="form-group">
                      <label ng-if="savingsSlo.vrstaVracevanjaSelect.value == '0'" class="domp-form-label" translate="savingsSlo.initialAmount"></label>
                      <label ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" class="domp-form-label" translate="savingsSlo.montlyDepositAmount"></label>
                      <div class="input-group">
                        <input id="amount" name="amount" type="text" class="form-control input-field t-a-r"
                               amount-input-mask
                               ng-model="cashWithdrawal.model.amount"
                               ng-class="{'domp-error': cashWithdrawal.cashWithdrawalForm.amount.$invalid && cashWithdrawal.formSubmitted}"
                               maxlength="12"
                               placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                               autocomplete="off"
                               required>
                        <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">
                  {{savingsSlo.currencySymbol}}
                </button>
              </span>
                      </div>
                      <label class="c-red"
                             ng-show="cashWithdrawal.cashWithdrawalForm.amount.$error.required && cashWithdrawal.formSubmitted"
                             translate="savingsSlo.amountRequired">
                      </label>
                      <label class="c-red"
                             ng-show="!cashWithdrawal.cashWithdrawalForm.amount.$error.required && cashWithdrawal.cashWithdrawalForm.amount.$error.min && cashWithdrawal.formSubmitted">
                        {{"savingsSlo.amountMinAmountError" | translate}} {{cashWithdrawal.minAmount | currency:""}} {{cashWithdrawal.model.currencySymbol}}
                      </label>
                    </div>
                  </div>

                  <!-- END Amount-->
                  <!-- START Date-->


                  <div class="row">
                    <div class="col-xs-6">
                      <label class="domp-form-label" translate="savingsSlo.realInterestRate"></label>

                      <input type="text" class="form-control input-field" name="text"

                             ng-model="savingsSlo.realInterestRate"
                             readonly>

                    </div>
                    <div class="col-xs-6">
                      <label class="domp-form-label" translate="savingsSlo.totalInterestRate"></label>
                      <input type="text" class="form-control input-field" name="text"

                             ng-model="savingsSlo.totalInterestRate"
                             readonly>


                    </div>
                  </div>
                  <!-- END Date-->
                </div>

              </div>
              <div ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" class="row p-t-20">
                <div class="col-xs-6">
                  <div class="form-group">
                    <label class="domp-form-label" translate="savingsSlo.standingOrderDay"></label>
                    <select name="branch"
                            class="form-control input-field p-l-15 p-r-10"
                            ng-options="option.name for option in savingsSlo.standingOrderDays"
                            ng-model="savingsSlo.standingOrderDaysSelect"
                            required>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row p-t-20">
                <div class="col-xs-12">
                  <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value" >
                    <input type="checkbox" ng-model="savingsSlo.savingsSlo.termsAndCondtions">
                    <i class="input-helper"></i>
                    <span translate="savingsSlo.termsAndCondtionsInfo"></span>
                    <br>
                    <span ng-if="savingsSlo.vrstaVracevanjaSelect.value == '1'" translate="savingsSlo.info"></span>
                  </label>
                </div>
              </div>
              <section class="row p-t-20">
                <div class="form-group">
                  <div class="col-md-6">
                    <button class="btn btn-primary pull-left" type="button" translate="savingsSlo.cancel"></button>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-default pull-right" type="submit" translate="savingsSlo.confirm" ng-click="savingsSlo.submitPayment();"></button>
                  </div>
                </div>
              </section>
            </div>
          </ng-form>


        </div>
      </div>
    </div>
  </section>

</div>
