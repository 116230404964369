(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:DomesticPaymentCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('DomesticPaymentCtrl', DomesticPaymentCtrl);

  function DomesticPaymentCtrl($rootScope) {
    var vm = this;
    vm.ctrlName = 'DomesticPaymentCtrl';
    vm.location =  $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
  }
}());
