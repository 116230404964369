(function () {
    'use strict';
  
    angular
      .module('localization')
      .config(config);
  
    function config($translateProvider) {
  
      var data = {
        documents: {
          "header": "Primljeni dokumenti",
          "loadEvents": "Učitaj još",
          "noItemData": "Nema podataka",
          "download": "Preuzmi",
          "titleModalInfo":"Dokument je trenutno nedostupan",
          "messageModalInfo":"Ovaj dokument je trenutno nedostupan. Molimo pokušajte ponovo ili kontaktirajte kontakt centar.",
          "closeModalInfo":"Zatvori",
          "documentDownloadFailed": "Ovaj dokument je trenutno nedostupan. Molimo pokušajte ponovo ili kontaktirajte kontakt centar"
        }
  
      };
  
      $translateProvider
        .translations('hr', data);
    }
  }());
  