(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name dashboard.controller:DashboardWidgetModalCtrl
     *
     * @description
     *
     */
    angular
        .module('dashboard')
        .controller('DashboardWidgetModalCtrl', DashboardWidgetModalCtrl);

    function DashboardWidgetModalCtrl($rootScope, accountsList, $filter, $uibModalInstance, AccountsService, $log,HelperService, NotificationService) {
        var vm = this;
        vm.ctrlName = 'DashboardWidgetModalCtrl';

        vm.accountsList = accountsList;

        vm.currentAndCreditAccounts = [];
        vm.loanAccounts = [];
        vm.depositAccounts = [];
        vm.savingAccounts = [];
        vm.giroAccounts = [];

        if (vm.accountsList.result.length !== 0) {
          _.forEach(vm.accountsList.result, function(value){
            if (value.accountType.group === 'CardAccountTypes' || value.accountType.group === 'CurrentAccount') {
              vm.currentAndCreditAccounts.push(value);
            } else if (value.accountType.group === 'LoanAccountType') {
              vm.loanAccounts.push(value);
            } else if (value.accountType.group === 'DepositAccountTypes') {
              vm.depositAccounts.push(value);
            } else if (value.accountType.group === 'SavingAccountTypes') {
              vm.savingAccounts.push(value);
            } else if (value.accountType.group === 'GiroAccount') {
              vm.giroAccounts.push(value);
            }
          });
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
            vm.currentAndCreditAccounts = $filter('orderBy')(vm.currentAndCreditAccounts, ['-accountType.group', '-isOwn', 'sortOrderId'])
            vm.depositAccounts = $filter('orderBy')(vm.depositAccounts, [ '-isFavorite', '-isOwn', '-accountType.group'])
            vm.savingAccounts = $filter('orderBy')(vm.savingAccounts, [ '-isFavorite', '-isOwn', '-accountType.group'])
          }

        }

        vm.markAccountAsFavorite = function (accountObj) {
            if (vm.markAsFavoriteInProgress) return;
            vm.markAsFavoriteInProgress = true;
            AccountsService.updateAccount(accountObj.accountId, { "isFavorite": accountObj.isFavorite} )
                .then(function (response) {
                    $log.debug("marked success ", response);
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                    $log.error(error);
                })
              .finally(function () {
                vm.markAsFavoriteInProgress = false;
              })
        };

        vm.closeDashboardWidgetModal = function (broadcastCloseEvent) {
            $uibModalInstance.close(broadcastCloseEvent);
        };
    }
}());
