<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index: 1">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="settings.title">Settings</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <div class="row"
      ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <div class="col-md-12">
        <div class="po-type-container">
          <div class="no-wrap-text cursor-pointer"
            translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'settings.tabAccount' : 'settings.tabAccountHR'}}"
            ng-click="settings.selectTab('account', 'settings.tabAccount')"
            ng-class="{'selected': settings.selectedTab == 'account',
                          'settings-tab cro': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl',
                          'settings-tab width-100 text-left': (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && !APP_GLOBAL_SETTINGS.defaultData.ShowUserSettingsNotification),
                          'settings-tab': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && APP_GLOBAL_SETTINGS.defaultData.ShowUserSettingsNotification }">
            Account
          </div>
          <!-- Sakriti Notifications tab -->
          <div class="no-wrap-text cursor-pointer"
            ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' && APP_GLOBAL_SETTINGS.defaultData.ShowUserSettingsNotification) || APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'"
            translate="settings.tabNotifications" ng-class="{'selected': settings.selectedTab == 'notifications',
                          'settings-tab cro': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl',
                          'settings-tab': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' }"
            ng-click="settings.selectTab('notifications', 'settings.tabNotifications')">
            Notifications
          </div>
          <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
            class="settings-tab cro no-wrap-text cursor-pointer" translate="settings.tabSecurity"
            ng-class="{'selected': settings.selectedTab == 'security'}"
            ng-click="settings.selectTab('security', 'settings.tabSecurity')">
            Security
          </div>
        </div>
      </div>
    </div>

    <div ng-switch="settings.selectedTab">
      <!-- ======================  -->
      <!-- START: ACCOUNT TAB      -->
      <!-- ======================  -->
      <div ng-switch-when="account" class="settings-content m-b-30">
        <div class="row">
          <div class="col-md-12">
            <h4 class="settings-conc-info">
              <span
                translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' ? 'settings.contactInfoHr':'settings.contactInfo'}}"
                style="font-weight: bold"><b>Your contact information</b></span>
            </h4>
          </div>
        </div>
        <div ng-if="!settings.editUserSettingsActive">
          <!-- START View User settings-->
          <div class="row">
            <div class="form-group col-md-4">
              <label class="sett-conc-info" translate="settings.contactInfoName">Name</label>
              <p class="settings-details">
                {{settings.model.userSettings.firstName}}
                {{settings.model.userSettings.lastName}}
              </p>
            </div>
            <div class="form-group col-md-4">
              <label class="sett-conc-info" translate="settings.contactInfoAddress">Address</label>
              <!--<div class="settings-details">{{settings.model.userSettings.street}}-->
              <!--{{settings.model.userSettings.houseNumber}}-->
              <!--</div>-->

              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                <div class="settings-details">
                  {{settings.model.userSettings.businessAddress1}}
                  {{settings.model.userSettings.businessAddress2}}
                  <div class="settings-details">
                    {{settings.model.userSettings.businessAddress3}}
                  </div>
                </div>
              </div>
              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
                <div class="settings-details">
                  {{settings.model.userSettings.address1}}
                </div>
                <div class="settings-details">
                  {{settings.model.userSettings.postalCode}}
                  {{settings.model.userSettings.city}}
                </div>
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>
          <div class="row m-t-15" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
            <!-- ===========  -->
            <!-- START: EMAIL -->
            <!-- ===========  -->
            <div ng-switch-default class="form-group">
              <div class="col-md-4">
                <label class="sett-conc-info">Email</label>
                <p class="settings-details">
                  {{settings.model.userSettings.notificationEmail}}
                </p>
              </div>
            </div>
            <!-- Croatia -->
            <div ng-switch-when="hr" class="form-group">
              <div class="col-md-4">
                <label class="sett-conc-info">Email</label>
                <p class="settings-details">
                  {{settings.model.userSettings.email}}
                </p>
              </div>
            </div>
            <!-- Slovenia -->
            <div ng-switch-when="sl" class="form-group clearfix">
              <!-- View -->

              <div class="col-md-4" ng-if="!settings.editEmail">
                <label class="sett-conc-info" translate="settings.emailAddress">Email</label>
                <p class="settings-details">
                  {{settings.model.userSettings.notificationEmail}}
                </p>
              </div>
              <!-- Edit -->
              <form name="settings.emailForm">
                <div class="col-md-4" ng-show="settings.editEmail">
                  <input type="email" class="form-control" name="mail"
                    ng-model="settings.model.userSettingsEditInput.notificationEmail" required />
                  <div class="c-red" ng-show="settings.emailForm.mail.$error.required"
                    translate="settings.emailRequiredErroMsg"></div>
                  <div class="c-red" ng-show="settings.emailForm.mail.$error.email"
                    translate="settings.invalidEmailRequiredErroMsg"></div>
                </div>

                <div class="col-md-4" style="margin: -5px">
                  <button class="btn btn-default m-l-5" ng-if="!settings.editEmail" translate="settings.changeEMail"
                    ng-click="settings.editUserEmail()"></button>
                  <button class="btn btn-primary" ng-if="settings.editEmail" translate="settings.contactInfoCancelBtn"
                    ng-click="settings.editEmail = false"></button>
                  <button class="btn btn-default" ng-if="settings.editEmail" translate="settings.contactInfoConfirmBtn"
                    ng-disabled="settings.emailForm.$invalid" ng-click="settings.confirmUserEmailChange()"></button>
                </div>
              </form>
            </div>
            <!-- ===========  -->
            <!-- END: EMAIL -->
            <!-- ===========  -->
          </div>

          <div class="row">
            <div ng-if="settings.country != 'sl'" class="form-group col-md-4">
              <label class="sett-conc-info"
                translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' ? 'settings.contactInfoPhoneHr':'settings.contactInfoPhone'}}">Phone</label>
              <p class="settings-details">
                {{settings.model.userSettings.dayPhoneCountryCode}}
                {{settings.model.userSettings.dayPhoneOperator}}
                {{settings.model.userSettings.dayPhone}}
              </p>
            </div>
            <div class="form-group clearfix">
              <div class="col-md-4">
                <label class="sett-conc-info"
                  translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' ? 'settings.gsmHr':'settings.gsm'}}">GSM</label>
                <p class="settings-details">
                  {{settings.model.userSettings.gsmCountryCode}}
                  {{settings.model.userSettings.gsmOperator}}
                  {{settings.model.userSettings.gsm}}
                </p>
              </div>

              <div class="col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                <button class="btn btn-default" translate="mobilePhoneSettings.setMobilePhoneNumber"
                  ng-click="settings.setMobilePhoneNumber(settings.model.userSettings.gsm)"></button>
              </div>
            </div>
          </div>
        </div>
        <!-- END View User settings-->
        <!-- START Edit User settings-->
        <ng-form name="settings.contactForm" ng-if="settings.editUserSettingsActive">
          <div class="row form-group">
            <div class="col-md-4 col-sm-6">
              <label translate="settings.contactInfoName" class="sett-conc-info">Name</label>
              <p class="settings-details">
                {{settings.model.userSettings.firstName}}
                {{settings.model.userSettings.lastName}}
              </p>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="col-lg-12 p-0">
                <label translate="settings.contactInfoAddress" class="sett-conc-info">Address</label>
              </div>
              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                <div class="row form-group m-0 p-0">
                  <div class="col-lg-8 col-xs-8 p-0 p-b-15">
                    <input type="text" name="street" class="col-xs-12 settings-input"
                      placeholder="{{'settings.street'| translate}}"
                      ng-model="settings.model.userSettingsEditInput.street" capitalize required />
                  </div>
                  <div class="col-xs-4 col-lg-4 p-0 p-l-5 p-b-15">
                    <input type="text" name="houseNumber" class="col-xs-12 settings-input"
                      placeholder="{{'settings.houseNumber'| translate}}"
                      ng-model="settings.model.userSettingsEditInput.houseNumber" required />
                  </div>
                </div>
                <div class="row form-group m-0 p-0">
                  <div class="col-lg-4 col-xs-4 p-0 p-b-15">
                    <input type="text" name="postalCode" class="col-xs-12 settings-input"
                      placeholder="{{'settings.postalCode'| translate}}"
                      ng-model="settings.model.userSettingsEditInput.postalCode"
                      ng-required="!settings.model.userSettingsEditInput.city" />
                  </div>
                  <div class="col-lg-8 col-xs-8 p-0 p-l-5 p-b-15">
                    <input type="text" name="city" class="col-xs-12 settings-input"
                      placeholder="{{'settings.city'| translate}}" ng-model="settings.model.userSettingsEditInput.city"
                      capitalize="" ng-required="!settings.model.userSettingsEditInput.postalCode" />
                  </div>
                </div>
              </div>
              <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                <!--<div class="settings-details">{{settings.model.userSettings.street}}-->
                <!--{{settings.model.userSettings.houseNumber}}-->
                <!--</div>-->

                <div class="settings-details">
                  {{settings.model.userSettings.address1}}
                </div>
                <div class="settings-details">
                  {{settings.model.userSettings.postalCode}}
                  {{settings.model.userSettings.city}}
                </div>
              </div>

              <div class="c-red" ng-show="settings.contactForm.street.$error.required"
                translate="settings.streetRequiredErrMsg"></div>
              <div class="c-red" ng-show="settings.contactForm.houseNumber.$error.required"
                translate="settings.houseNumRequiredErrMsg"></div>
              <div class="c-red"
                ng-show="settings.contactForm.postalCode.$error.required && settings.contactForm.city.$error.required"
                translate="settings.cityOrPostalCodeRequiredErrMsg"></div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4 col-xs-12 col-sm-6">
              <div class="col-lg-12 p-0">
                <label class="sett-conc-info">Email</label>
              </div>
              <div class="col-lg-12 col-xs-12 p-0 p-b-15">
                <input type="email" class="form-control" name="mail"
                  ng-model="settings.model.userSettingsEditInput.notificationEmail" required />
                <div class="c-red" ng-show="settings.contactForm.mail.$error.required"
                  translate="settings.emailRequiredErroMsg"></div>
                <div class="c-red" ng-show="settings.contactForm.mail.$error.email"
                  translate="settings.invalidEmailRequiredErroMsg"></div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div ng-if="settings.country != 'sl'" class="col-md-4 col-xs-12 col-sm-6">
              <div class="col-lg-12 p-0">
                <label
                  translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' ? 'settings.contactInfoPhoneHr':'settings.contactInfoPhone'}}"
                  class="sett-conc-info">Phone</label>
              </div>
              <div>
                <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                  <div ng-switch-when="sl">
                    <div class="col-lg-12 col-xs-12 p-0 p-b-15">
                      <!--<input type="text" class="form-control" ng-pattern-restrict="{{settings.regexPatterns.phoneValidation}}"-->
                      <!--ng-model="settings.model.userSettingsEditInput.dayPhone">-->
                      <p class="settings-details">
                        {{settings.model.userSettings.dayPhone}}
                      </p>
                    </div>
                  </div>
                  <div ng-switch-default>
                    <div class="col-lg-3 col-xs-3 p-0 p-b-15">
                      <input type="text" class="form-control" maxlength="4"
                        ng-pattern-restrict="{{settings.regexPatterns.phoneValidationWithPlus}}"
                        ng-model="settings.model.userSettingsEditInput.dayPhoneCountryCode" />
                    </div>
                    <div class="col-lg-3 col-xs-3 p-0 p-b-15">
                      <input type="text" class="form-control" maxlength="4"
                        ng-pattern-restrict="{{settings.regexPatterns.phoneValidations}}"
                        ng-model="settings.model.userSettingsEditInput.dayPhoneOperator" />
                    </div>
                    <div class="col-lg-6 col-xs-6 p-0 p-b-15">
                      <input type="text" class="form-control"
                        ng-pattern-restrict="{{settings.regexPatterns.phoneValidation}}"
                        ng-model="settings.model.userSettingsEditInput.dayPhone" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12 col-sm-6">
              <div class="col-lg-12 p-0">
                <label
                  translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' ? 'settings.gsmHr':'settings.gsm'}}"
                  class="sett-conc-info">GSM</label>
              </div>
              <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                <div ng-switch-when="sl" class="col-lg-12 col-xs-12 p-0 p-b-15">
                  <!--<input type="text" class="form-control" ng-pattern-restrict="{{settings.regexPatterns.phoneValidation}}"-->
                  <!--ng-model="settings.model.userSettingsEditInput.gsm">-->
                  <p class="settings-details">
                    {{settings.model.userSettingsEditInput.gsm}}
                  </p>
                </div>
                <div ng-switch-default>
                  <div class="col-lg-3 col-xs-3 p-0 p-b-15">
                    <input type="text" class="form-control" maxlength="4"
                      ng-pattern-restrict="{{settings.regexPatterns.phoneValidationWithPlus}}"
                      ng-model="settings.model.userSettingsEditInput.gsmCountryCode" />
                  </div>
                  <div class="col-lg-3 col-xs-3 p-0 p-b-15">
                    <input type="text" class="form-control" maxlength="4"
                      ng-pattern-restrict="{{settings.regexPatterns.phoneValidations}}"
                      ng-model="settings.model.userSettingsEditInput.gsmOperator" />
                  </div>
                  <div class="col-lg-6 col-xs-6 p-0 p-b-15">
                    <input type="text" class="form-control"
                      ng-pattern-restrict="{{settings.regexPatterns.phoneValidation}}"
                      ng-model="settings.model.userSettingsEditInput.gsm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-form>
        <!-- END Edit User settings-->
        <!--<hr>-->
        <div ng-if="settings.country != 'sl' && settings.country != 'hr'" class="form-group">
          <div class="row">
            <div class="col-md-12">
              <h4 class="settings-conc-info" translate="settings.statementsDelivery">
                Statement delivery
              </h4>
            </div>
            <div ng-show="settings.editUserSettingsActive">
              <div class="col-md-6">
                <label>
                  <input type="radio" ng-model="settings.model.userSettingsEditInput.mailUserType" value="0" />
                  <span translate="settings.statementsDeliveryPaper" class="stat-deliv">Home delivery of paper
                    statements</span>
                </label>
              </div>
              <div class="col-md-6">
                <label>
                  <input type="radio" ng-model="settings.model.userSettingsEditInput.mailUserType" value="1" />
                  <span translate="settings.statementsDeliveryElectronic" class="stat-deliv">
                    Electronic statements in HYPOnet</span>
                </label>
              </div>
            </div>
            <div class="form-group"></div>
            <div ng-show="!settings.editUserSettingsActive">
              <div class="col-md-6">
                <label>
                  <input type="radio" ng-model="settings.model.userSettings.mailUserType" disabled value="0" />
                  <span translate="settings.statementsDeliveryPaper" class="stat-deliv">Home delivery of paper
                    statements</span>
                </label>
              </div>
              <div class="col-md-6">
                <label>
                  <input type="radio" ng-model="settings.model.userSettings.mailUserType" disabled value="1" />
                  <span translate="settings.statementsDeliveryElectronic" class="stat-deliv">
                    Electronic statements in HYPOnet</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div ng-if="settings.country == 'hr' && settings.editUserSettingsActive">
          <addiko-mtoken-sign
            isActionDisabled="settings.contactForm.$invalid" 
            placeholder="settings.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
            qr-challenge="settings.qrChallenge" show-challenge="settings.showChallenge" 
            challenge1="settings.challengeFirstCode" challenge2="settings.challengeSecondCode"></addiko-mtoken-sign>
        </div>

        <div class="row form-group">
          <div class="col-xs-6 col-sm-6">
            <button class="btn btn-primary" ng-show="settings.editUserSettingsActive"
              ng-click="settings.cancelUserSettingsEdit()" translate="settings.contactInfoCancelBtn">
              Cancel
            </button>
            <!--    <button   class="btn btn-primary"
        data-ui-sref="dashboard"
        ng-show="settings.editUserSettingsActive"
        ng-click="settings.cancelUserSettingsEdit()"
        translate="settings.contactInfoCancelBtn">Cancel</button> -->
          </div>
          <div class="col-xs-6 col-sm-6 t-a-r" ng-show="settings.editUserSettingsActive">
            <button ng-if="settings.country != 'hr'" class="btn btn-default" 
              ng-disabled="settings.contactForm.$invalid" 
              ng-click="settings.confirmUserSettingsEdit()"
              translate="settings.contactInfoConfirmBtn">
              Confirm
            </button>
            <button ng-if="settings.country == 'hr' && !settings.qrChallenge" class="btn btn-default"
              ng-disabled="settings.contactForm.$invalid || !settings.challengeResponse || !settings.secondChallengeResponse" 
              ng-click="settings.confirmUserSettingsEdit()"
              translate="settings.contactInfoConfirmBtn">
              Confirm
            </button>
            
          </div>
          <div class="col-xs-offset-6 col-xs-6 col-sm-6 t-a-r">
            <button class="btn btn-default" ng-show="!settings.editUserSettingsActive"
              ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" ng-click="settings.editUserSettings()"
              translate="settings.contactInfoBtn">
              Edit
            </button>
          </div>
        </div>
      </div>
      <!-- ======================  -->
      <!-- END: ACCOUNT TAB        -->
      <!-- ======================  -->

      <!-- ======================  -->
      <!-- START: NOTIFICATIONS TAB  -->
      <!-- ======================  -->
      <div ng-switch-when="notifications" class="settings-content">
        <!-- SLOVENIA -->
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="row form-group">
          <div class="col-md-12">
            <p class="p-t-10 p-b-10">
              <span translate="settings.notificationsNote"></span>
              <a class="underline" ng-href="https://www.addiko.si/ceniki" target="_blank"
                translate="settings.notificationsNoteLink"></a>
            </p>

            <h4 class="settings-conc-info p-b-15" translate="settings.userBasedNotifications">
              <b>User based notifications</b>
            </h4>
            <table class="table settings-notifications-table">
              <tbody>
                <tr>
                  <td><b>EMAIL</b></td>
                  <td><b>SMS</b></td>
                  <td></td>
                </tr>
                <tr ng-repeat="item in settings.userBasedNotificationsList">
                  <td ng-if="item.emailNotificaton.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.disableMail" ng-click="settings.changeSettings(item, true, 'EMAIL', false);">
                  </td>
                  <td ng-if="!item.emailNotificaton.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.enableMail" ng-click="settings.changeSettings(item, false, 'EMAIL', false);">
                  </td>
                  <td ng-if="item.smsNotification.enabled && !item.pushNotification.enabled && item.subOrderId != '10'"
                    class="col-md-2 underline cursor-pointer" translate="settings.disableSms"
                    ng-click="settings.changeSettings(item, true, 'SMS', false);"></td>
                  <td ng-if="!item.smsNotification.enabled && !item.pushNotification.enabled && item.subOrderId != '10'"
                    class="col-md-2 underline cursor-pointer" translate="settings.enableSms"
                    ng-click="settings.changeSettings(item, false, 'SMS', false);"></td>
                  <td ng-if="item.pushNotification.enabled && item.subOrderId != '10'" class="col-md-2">
                    <div class="d-flex">
                      <span>
                        {{'settings.smsNotAvailable' | translate}}
                      </span>
                      <span class="cursor-pointer" popover-append-to-body="true" uib-popover="{{'settings.smsNotAvailableInfo' | translate}}"
                        popover-trigger="'mouseenter'">
                        <svg style="height: 20px; width: 20px;" aria-hidden="true" focusable="false" data-prefix="far"
                          data-icon="info-circle" role="img" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16 fa-lg">
                          <path fill="currentColor"
                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                            class=""></path>
                        </svg>
                      </span>
                    </div>
                  </td>
                  <td ng-if="item.subOrderId == '10'" class="col-md-2 underline cursor-pointer"></td>
                  <td class="col-md-8">{{item.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" />
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="row form-group">
          <div class="col-md-12">
            <h4 class="settings-conc-info p-b-15" translate="settings.accountBasedNotifications">
              <b>Account based notifications</b>
            </h4>
          </div>
          <div class="form-group">
            <label class="
                col-lg-2 col-md-2 col-sm-12 col-xs-12 col-form-label
                settings-conc-info
              " style="font-weight: normal" translate="settings.forAccount">For account:</label>
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <select name="trrAccounts" class="form-control input-field p-l-15 p-r-10"
                ng-options="(option.iban + ' ' + option.friendlyName) for option in settings.trrAccounts"
                ng-change="settings.loadSmsAndNotifications();" ng-model="settings.selectedTrrAccount"></select>
            </div>
          </div>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="row form-group">
          <div class="col-md-12">
            <table class="table settings-notifications-table">
              <tbody>
                <tr>
                  <td><b>EMAIL</b></td>
                  <td><b>SMS</b></td>
                  <td></td>
                </tr>
                <tr ng-repeat="item in settings.accountBasedNotificationsList">
                  <td ng-if="item.emailNotificaton.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.disableMail" ng-click="settings.changeSettings(item, true, 'EMAIL', true);">
                  </td>
                  <td ng-if="!item.emailNotificaton.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.enableMail" ng-click="settings.changeSettings(item,false, 'EMAIL', true);"></td>
                  <td ng-if="item.smsNotification.enabled && !item.pushNotification.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.disableSms" ng-click="settings.changeSettings(item, true, 'SMS', true);"></td>
                  <td ng-if="!item.smsNotification.enabled && !item.pushNotification.enabled" class="col-md-2 underline cursor-pointer"
                    translate="settings.enableSms" ng-click="settings.changeSettings(item,false, 'SMS', true);"></td>
                    <td ng-if="item.pushNotification.enabled" class="col-md-2">
                      <div class="d-flex">
                        <span>
                          {{'settings.smsNotAvailable' | translate}}
                        </span>
                        <span class="cursor-pointer" popover-append-to-body="true" uib-popover="{{'settings.smsNotAvailableInfo' | translate}}"
                          popover-trigger="'mouseenter'">
                          <svg style="height: 20px; width: 20px;" aria-hidden="true" focusable="false" data-prefix="far"
                            data-icon="info-circle" role="img" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16 fa-lg">
                            <path fill="currentColor"
                              d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                              class=""></path>
                          </svg>
                        </span>
                      </div>
                    </td>
                  <td class="col-md-8">{{item.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- CROATIA -->
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" class="row form-group">
          <div class="col-md-12">
            <!-- <div class="row">
              <div class="col-md-8">
                <h3 translate="settings.showNotificationFor"></h3>
              </div>
              <div class="col-md-4 notif-checkbox">
                <input type="checkbox" id="cbx" style="display: none" ng-model="settings.notificationCheckbox" ng-click="settings.toggleNotifications(settings.possibleNotifications)" checked/>
                <label for="cbx" class="toggle"><span></span></label>
              </div>
            </div> -->

            <div>
              <ul class="
                  col-4 col-md-12
                  no-top-border
                  list-unstyled
                  possible-notifications-wrapper
                ">
                <li ng-repeat="item in settings.possibleNotifications">
                  <div ng-if="item.notificationGroupType != 'HIBIS_NOTIF_CARDS'" class="toggle-radio-button-wrapper">
                    <label>
                      <span>{{'settings.notifs.' + item.notificationGroupType |
                        translate}}</span></label>
                    <div class="notif-checkbox">
                      <input type="checkbox" id="smsCheckBox_{{$index}}" style="display: none"
                        ng-model="item.smsEnabled" ng-click="settings.toggleNotifications(item.smsEnabled)" checked />
                      <label for="smsCheckBox_{{$index}}" class="toggle"><span></span></label>
                    </div>
                  </div>
                  <div ng-if="item.notificationGroupType == 'HIBIS_NOTIF_CARDS'" class="toggle-radio-button-wrapper">
                    <label>
                      <span>{{'settings.notifs.' + item.notificationGroupType |
                        translate}}</span></label>
                    <div class="notif-checkbox">
                      <input type="checkbox" id="smsCheckBox_{{$index}}" style="display: none"
                        ng-checked="item.HIBISEnabled" ng-click="settings.toggleNotificationsHIBIS(item)" />
                      <label for="smsCheckBox_{{$index}}" class="toggle"><span></span></label>
                    </div>
                  </div>
                  <div ng-if="item.notificationGroupType == 'HIBIS_NOTIF_CARDS'" class="hibis-notif-wrapper">
                    <div class="hibis-notif-single-item">
                      <input id="pushEnabled" type="radio" name="hibis_notif"
                        ng-click="settings.toggleHIBIS(item, 'push')" ng-checked="item.pushEnabled"
                        ng-disabled="!item.HIBISEnabled" />
                      <label for="pushEnabled"><span>{{'settings.notifs.PUSH' | translate}}</span></label>
                    </div>
                    <div class="hibis-notif-single-item">
                      <input id="emailEnabled" type="radio" name="hibis_notif"
                        ng-click="settings.toggleHIBIS(item, 'email')" ng-checked="item.eMailEnabled"
                        ng-disabled="!item.HIBISEnabled" />
                      <label for="emailEnabled"><span>{{ 'settings.notifs.EMAIL' | translate}}</span></label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" />
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" class="row form-group">
          <div class="col-md-12">
            <label class="p-l-10 notification-additional-infos">
              {{"settings.notificationSmsNumber" | translate}}
              {{settings.model.userSettings.gsmCountryCode}}
              {{settings.model.userSettings.gsmOperator}}
              {{settings.model.userSettings.gsm}}
            </label>
          </div>
        </div>
      </div>
      <!-- ======================  -->
      <!-- END: NOTIFICATIONS TAB  -->
      <!-- ======================  -->

      <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' && settings.selectedTab === 'notifications'"
        class="row p-t-15">
        <div class="col-md-12">
          <button class="btn btn-primary pull-left" ng-click="settings.editUserSettings()"
            translate="payments.cancel"></button>
          <button class="btn btn-default pull-right" ng-click="settings.updateUserSettings()"
            translate="settings.contactInfoConfirmBtn"></button>
        </div>
      </div>

      <!-- =================== -->
      <!-- START: SECURITY TAB -->
      <!-- =================== -->
      <div ng-switch-when="security" class="settings-content">
        <ng-form ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" name="settings.securityForm">
          <!-- SLOVENIA -->
          <div ng-switch-when="sl">
            <h4 class="settings-conc-info bold-font" translate="settings.additionalTransactionsInfo">
              <b>Additional transactions confirmation</b>
            </h4>
            <div class="row form-group">
              <div class="col-md-6" ng-if="!APP_GLOBAL_SETTINGS.userSettings.gsm || APP_GLOBAL_SETTINGS.userSettings.gsm == '' ||
                                            settings.model.security.transactionConfirmation != 1">
                <label>
                  <input type="radio" ng-model="settings.model.security.transactionConfirmation" value="0"
                    ng-disabled="!settings.securityEditActive" />
                  <span translate="settings.passwordFromOTP" class="stat-deliv">Password from OTP password
                    generator</span>
                </label>
              </div>
              <div class="col-md-6" ng-if="settings.showGSMOption">
                <label>
                  <input type="radio" ng-model="settings.model.security.transactionConfirmation" value="1"
                    ng-disabled="!settings.securityEditActive" />
                  <span translate="settings.passwordFromSMS" class="stat-deliv">Receive password via SMS on mobile
                    phone</span>
                </label>
              </div>
            </div>
            <p>
              <span translate="settings.transactionsConfirmationText"></span><a
                href="https://www.addiko.si/potrjevanje-transakcij-v-spletni-banki-addiko-ebank" class="underline"
                target="blank" translate="settings.transactionsConfirmationLink"></a>
            </p>
            <div class="row form-group">
              <div class="col-md-12">
                <span>
                  <button class="btn btn-primary" ng-if="settings.securityEditActive"
                    ng-click="settings.securityEditActive = !settings.securityEditActive"
                    translate="settings.contactInfoCancelBtn">
                    Cancel Cancel
                  </button>
                </span>
                <span class="pull-right">
                  <button class="btn btn-default" ng-if="!settings.securityEditActive"
                    ng-click="settings.enableSecurityEdit()" translate="settings.contactInfoBtn">
                    Edit
                  </button>
                  <button class="btn btn-default" ng-if="settings.securityEditActive"
                    ng-click="settings.securityEditConfirm()" ng-disabled="settings.confirmButtonPressed"
                    translate="settings.contactInfoConfirmBtn">
                    Save
                  </button>
                  <button class="btn btn-default" data-ui-sref="settings.orderSoftToken"
                    translate="settings.orderSoftToken">
                    Order soft token
                  </button>
                </span>
              </div>
            </div>

            <hr />
            <h4 class="settings-conc-info bold-font" translate="settings.blockadeOfAddikoEbank">
              <b>BLOCKADE OF ADDIKO EBANK OPERATIONS</b>
            </h4>
            <p translate="settings.blockadeOfAddikoEBankText">
              You can disable the us of the Addiko EBank and/or the Addiko
              Mobile bank for a fixed or indefinite period of time.
            </p>
            <div class="row form-group">
              <div class="col-md-12 text-right">
                <button class="btn btn-default" style="text-transform: uppercase"
                  ng-click="settings.securitySetBlockade()" translate="settings.setBlockade">
                  Set blockade
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <h4 class="settings-conc-info bold-font" translate="settings.unsubscribeHeader"></h4>
                <p translate="settings.unsubscribeText"></p>
              </div>
              <div class="col-md-12 text-right">
                <button class="btn btn-default" ng-disabled="!settings.model.userSettings.mobileBankEnabled"
                  ng-click="settings.updateUserMobileSetting()" translate="settings.unsubscribeButton">
                  Set blockade
                </button>
              </div>
            </div>
          </div>

          <!-- SERBIA/MONTENEGRO/BIH -->
          <div ng-switch-default>
            <div class="row form-group">
              <div class="col-md-6">
                <label class="domp-form-label" translate="settings.oldPass">Old password</label>
                <input type="password" name="oldPass" id="oldPass" ng-model="settings.model.security.oldPassword"
                  minlength="{{settings.inputOptions.minLengthOld}}" maxlength="{{settings.inputOptions.maxLength}}"
                  ng-paste="settings.preventDefault($event)" ng-keypress="settings.preventForbiddenCharacters($event)"
                  ng-if="settings.country == 'sr'" ng-blur="settings.inputOptions.oldPassValid = true"
                  class="form-control" required />
                <input type="password" name="oldPass" id="oldPass" ng-model="settings.model.security.oldPassword"
                  maxlength="{{settings.inputOptions.maxLength}}" ng-if="settings.country != 'sr'"
                  ng-blur="settings.inputOptions.oldPassValid = true" class="form-control" required />
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="(settings.country == 'sr' || settings.country == 'bh' || settings.country == 'mn'  || settings.country == 'mne') &&
                      settings.inputOptions.oldPassValid && (settings.securityForm.oldPass.$error.minlength || settings.securityForm.oldPass.$error.maxlength)"
                  data-translate-values="{ value: settings.inputOptions.minLengthOld }"
                  data-translate="settings.lengthOldError">
                </span>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="(settings.country == 'mn' || settings.country == 'mne' || settings.country == 'bh') &&
                 (settings.securityForm.oldPass.$touched || settings.securityForm.newPass.$dirty || settings.securityForm.newPass.$touched) && settings.securityForm.oldPass.$error.required"
                  data-translate="settings.requiredOldPassword">
                </span>

              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label class="domp-form-label" translate="settings.newPass">New password</label>
                <input type="password" class="form-control" name="newPass" id="newPass"
                  minlength="{{settings.inputOptions.minLength}}" ng-maxlength="settings.inputOptions.maxLength"
                  ng-paste="settings.preventDefault($event)" ng-keypress="settings.preventForbiddenCharacters($event)"
                  ng-keyup="settings.validateMaxLength('newPassword'); settings.compareNewPassword(); settings.validateMinLength('newPassword')"
                  ng-blur="settings.checkForValidation(); settings.inputOptions.oldPassValid = true;"
                  ng-model="settings.model.security.newPassword"
                  ng-change="settings.compareNewPassword(); settings.validateMinLength('newPassword')" required />
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="(settings.country == 'bh' || settings.country == 'sr' || settings.country == 'mn' || settings.country == 'mne') && (settings.securityForm.newPass.$dirty || settings.securityForm.newPass.$touched) && settings.securityForm.newPass.$error.minlength"
                  data-translate-values="{ value: settings.inputOptions.minLength }"
                  translate="settings.lengthError"></span>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="settings.securityForm.newPass.$touched && settings.securityForm.newPass.$error.maxlength"
                  data-translate-values="{ value: settings.inputOptions.maxLength }"
                  translate="settings.lengthMaxError"></span>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="(settings.country == 'mn' || settings.country == 'mne') &&
                 (settings.securityForm.newPass.$dirty || settings.securityForm.newPass.$touched) && settings.securityForm.newPass.$error.required"
                  data-translate="settings.requiredNewPassword">
              </div>
            </div>
            <div class="row form-group"
              ng-class="{'domp-to-section p-0 p-b-20': (settings.country == 'bh' || settings.country == 'sr')}">
              <div class="col-md-6">
                <label class="domp-form-label" translate="settings.againPass">Again new password</label>
                <input name="confirmNewPassword" type="password" class="form-control"
                  minlength="{{settings.inputOptions.minLength}}" maxlength="{{settings.inputOptions.maxLength}}"
                  ng-paste="settings.preventDefault($event)" ng-keypress="settings.preventForbiddenCharacters($event)"
                  ng-model="settings.model.security.confirmNewPassword" ng-keyup="settings.compareNewPassword()"
                  required />
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="settings.securityForm.confirmNewPassword.$error.incorrect && !settings.securityForm.confirmNewPassword.$error.required"
                  translate="settings.noMatch"></span>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="settings.country == 'sr' && settings.securityForm.confirmNewPassword.$touched && settings.securityForm.confirmNewPassword.$error.required"
                  translate="settings.required"></span>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                  ng-show="settings.country != 'sr' && settings.securityForm.confirmNewPassword.$touched && settings.securityForm.confirmNewPassword.$error.required"
                  translate="settings.againPassError"></span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-default pull-right" type="submit" translate="settings.change"
                  ng-click="settings.change()"></button>
              </div>
            </div>
          </div>
        </ng-form>
        <ng-form name="settings.userForm" ng-if="settings.country == 'bh'">
          <div ng-if="settings.model.userSettings.linkList.itemList.mothersLastName" class="row form-group">
            <div class="col-xs-6">
              <label class="domp-form-label" translate="settings.mothersLastName ">Mothers last name</label>
              <input name="confirmNewPassword" type="text" class="form-control"
                ng-model="settings.model.security.mothersLastName" />
              <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                ng-show="settings.securityForm.mothersLastName.$error.incorrect" translate="settings.required"></span>
            </div>
          </div>
          <div ng-if="settings.model.userSettings.linkList.itemList.email" class="row form-group">
            <div class="col-xs-6">
              <label class="domp-form-label" translate="settings.email">Email</label>
              <input name="email" type="text" class="form-control" ng-model="settings.model.security.email" />
              <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                ng-show="settings.securityForm.email.$error.incorrect" translate="settings.required"></span>
            </div>
          </div>
          <div
            ng-if="settings.model.userSettings.linkList.itemList.email || settings.model.userSettings.linkList.itemList.mothersLastName"
            class="row">
            <div class="col-md-6">
              <button class="btn btn-default pull-right" type="submit" translate="settings.change"
                ng-click="settings.changeUserData()"></button>
            </div>
          </div>
        </ng-form>
      </div>
      <!-- ==================  -->
      <!-- END SECURITY TAB -->
      <!-- =================== -->
    </div>
  </div>
</section>