(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      blockade: {
        title: "Blokada poslovanja v Addiko EBank/Addiko Mobile",
        timeRestrictedBlockadeText: "Če želite poslovanje kasneje spet omogočiti, morate obiskati eno od poslovalnic Addiko Bank d.d. in izpolniti zahtevek za deblokado poslovanja v Addiko EBanki.",
        info1: "Za določen ali nedoločen čas lahko onemogočite uporabo Addiko EBanke/Addiko Mobile. Opozorilo: V času blokade poslovanje v Addiko EBanki in/ali Addiko Mobile ne bo mogoče. Če izberete blokado za nedoločen čas, je za ponovno aktivacijo delovanja potreben obisk ene od Addiko poslovalnic.",
        info2: "",
        blockadeReason: "Razlog za blokado",
        blockadeOf: "Blokiranje",
        timeRestrictedBlockade: "Časovna omejitev blokade",
        dateFrom: "Od dne",
        dateTo: "Do dne",
        orderDate: "Datum naročila",
        orderTypeLabel: "Vrsta naročila",
        orderTypeText: "Blokada poslovanja",
        and: "in",
        blockadeTypeLabel: "Vrsta blokade",
        blockadeTypeText: "Blokada poslovanja med",
        blockadeUnrestricted: "Časovno neomejena blokada",
        blockadeFinishedTitle: "Blokada je izvršena.",
        blockadeFinishedMsg1: "V času med",
        blockadeFinishedMsg2: "poslovanje v",
        blockadeFinishedMsg3: "ne bo mogoče.",


        errors: {
          requiredField: "Polje je obvezno.",
          blockadeOfErr: "Izbrano mora biti vsaj eno blokiranje.",
          dateToAndFromRequired: "Pri časovno omejeni blokadi morata biti datuma blokade izpolnjena.",
          timeRestrictedBlockadeErr: "The end date of the blockade (to) must be greater than or equal to the start date (from)!"
        }
      }
    };

    $translateProvider
      .translations('sl', data);

  }
}());
