<div class="bw-list-wrapper app-blue-card">
    <a href="">
        <div class="bw-list-item-lg">{{'dashboard.contact' | translate}}<i class="zmdi zmdi-bwli-icon zmdi-comments pull-right"></i></div>
    </a>
    <a href="">
        <div class="bw-list-item-lg">{{'dashboard.hypoClub' | translate}}<i class="zmdi zmdi-bwli-icon zmdi-ticket-star pull-right"></i></div>
    </a>
    <a href="">
        <div class="bw-list-item-lg">{{'dashboard.etrade' | translate}}<i class="zmdi zmdi-bwli-icon zmdi-case pull-right"></i></div>
    </a>
    <a href="">
        <div class="bw-list-item-lg bw-list-item-last">{{'dashboard.branch' | translate}}<i class="zmdi zmdi-bwli-icon zmdi-pin-drop pull-right"></i></div>
    </a>
</div>
