(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name core.controller:addikoMTokenSign
     *
     * @description
     *
     */
    angular
        .module('core')
        .directive('addikoMtokenSign', addikoMTokenSign);

    function addikoMTokenSign($rootScope, ValidationService) {
        return {
            restrict: 'E',
            scope: {
                challengeFirstCode: '=challenge1',
                challengeSecondCode: '=challenge2',
                qrChallenge: '=qrChallenge',
                placeholder: '=',
                showChallenge: '=showChallenge',
                isActionDisabled: '=actionDisabled',
                challengeInput: '=challengeInput',
                secondChallengeInput: '=secondChallengeInput',
                showCancelButton: '=showCancel'
            },
            templateUrl: 'core/components/mtoken-sign/mtoken-sign.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;
                vm.challengeInput = '';
                vm.secondChallengeInput = '';
                vm.emitAction = function() {
                    $rootScope.$broadcast('mtoken.confirm', {firstCode: vm.challengeInput, secondCode: vm.secondChallengeInput});
                }

                vm.emitCancel = function() {
                    $rootScope.$broadcast('mtoken.cancel', true);
                }
                vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

            }
        };
    }
}());