<div class="col-sm-12 col-md-12 col-xs-12 p-l-0 p-r-0 bg-white" style="min-height: 400px">

  <div class="msg-tab-container cursor-pointer" ng-if="accounts.countryCode !== 'bh'">
    <div class=" msg-tab"
         ng-class="{'selected':accounts.showSubmenuItemType=='transactions'}"
         ng-click='accounts.showSubmenu("transactions")'>
      <a translate="accounts.accTransactions"></a>
    </div>
    <div class=" msg-tab cursor-pointer"
         ng-class="{'selected':accounts.showSubmenuItemType=='statements'}"
         ng-click='accounts.showSubmenu("statements")'>
      <a translate="accounts.accPdfStatements"></a>
    </div>
  </div>

  <!--Nothing smarter on my mind at the exact moment :D-->
  <div class="msg-tab-container cursor-pointer" ng-if="accounts.countryCode === 'bh'">
    <div class=" msg-tab"
         ng-class="{'selected':accounts.showSubmenuItemType=='transactions',
         'msg-tab-bh':accounts.checkForAccountTypeId(accounts.selectedAccount.accountType.id) && accounts.selectedAccount.linkList.itemList.payInReservations}"
         ng-click='accounts.showSubmenu("transactions")'>
      <a translate="accounts.accTransactions"></a>
    </div>
    <div class=" msg-tab cursor-pointer"
         ng-if="accounts.checkForAccountTypeId(accounts.selectedAccount.accountType.id);"
         ng-class="{'selected':accounts.showSubmenuItemType=='statements',
         'msg-tab-bh': accounts.checkForAccountTypeId(accounts.selectedAccount.accountType.id) && accounts.selectedAccount.linkList.itemList.payInReservations}"
         ng-click='accounts.showSubmenu("statements")'>
      <a translate="accounts.accPdfStatements"></a>
    </div>
    <!-- Show reservations related to account just for BiH environment -->
    <div class=" msg-tab left-border-bh"
         ng-if="accounts.selectedAccount.linkList.itemList.payInReservations"
         ng-class="{'selected':accounts.showSubmenuItemType=='reservations',
           'msg-tab-bh': accounts.checkForAccountTypeId(accounts.selectedAccount.accountType.id) && accounts.selectedAccount.linkList.itemList.payInReservations}"
         ng-click='accounts.showSubmenu("reservations")'>
      <a translate="accounts.accReservations"></a>
    </div>
  </div>



<div class="col-md-12" style="padding-top:20px;">
    <div class="col-md-6 p-0" ng-hide="accounts.showSubmenuItemType=='statements' || accounts.showSubmenuItemType=='reservations'">
      <!--<transactions-filter ng-if="accounts.currenciesList.length" transactions="accounts.selectedAccount.transactions.result"-->
                           <!--transaction-currency-list="accounts.currenciesList"-->
                           <!--allow-custom-datepicker="true"-->
                           <!--data-statements="(accounts.selectedAccount.accountType.group == 'CardAccountTypes' && accounts.countryCode === 'sl')"-->
                           <!--statement-dates="accounts.detailedFilter.statementDates"-->
                           <!--data-default-dates="accounts.defaultDates"-->
                           <!--transaction-filter-change="accounts.refreshTransactionsByFilter(filterParams)"-->
                           <!--currency="accounts.defaultCurrency">-->
      <!--</transactions-filter>-->

      <div data-ng-include="'accounts/accounts-transaction-filter.tpl.html'"></div>
    </div>

    <div class="col-sm-6 col-md-3" ng-hide="accounts.showSubmenuItemType=='statements' || accounts.showSubmenuItemType=='reservations'" ng-if="accounts.countryCode !== 'sl'">
      <div id="periodViewSelector" class="period-selector" ng-click='accounts.showSubmenu("transactions")'>
        <div class="period-view-select-div left"><span translate="accounts.accPeriod"></span>:</div>
        <div class="period-view-select-div right period-margin">
          <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
            <button class="btn btn-default dropdown-toggle" style="font-size: 14px;padding: 0 5px"
                    type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="true">
              {{accounts.selectedPeriod.description | translate}}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li data-ng-repeat="period in accounts.periodList">
                <a href ng-click="accounts.changeTransactionPeriod(period)">{{period.description | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  <div class="col-sm-6" ng-hide="accounts.showSubmenuItemType=='statements' || accounts.showSubmenuItemType=='reservations'" ng-if="accounts.countryCode === 'sl'">
    <div>
      <span ng-if="accounts.selectedAccount.isOwn" ng-click="accounts.exportTransactions()" class="position-absolute icon-excel"></span>
      <div id="periodViewSelector" class="period-selector" ng-click='accounts.showSubmenu("transactions")'>
        <div class="period-view-select-div left"><span translate="accounts.accPeriod"></span>:</div>
        <div class="period-view-select-div right period-margin">
          <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
            <button class="btn btn-default dropdown-toggle" style="font-size: 14px;padding: 0 5px"
                    type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="true">
              {{accounts.selectedPeriod.description | translate}}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li data-ng-repeat="period in accounts.periodList">
                <a href ng-click="accounts.changeTransactionPeriod(period)">{{period.description | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-3" ng-hide="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne' || accounts.showSubmenuItemType=='statements' || accounts.showSubmenuItemType=='reservations'" ng-if="accounts.countryCode !== 'sl'">
    <div id="reportViewSelector" class="period-selector" ng-click='accounts.showSubmenu("transactions")'>
      <div class="period-view-select-div left"><span>Izvještaj</span>:</div>
      <div class="period-view-select-div right period-margin">
        <div class="dropdown acc-period-select" ng-init="reportSelectorLabel='Izaberite format'">
          <button style="margin-left: 23px; font-size: 14px;padding: 0 5px" class="btn btn-default dropdown-toggle"
                  type="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="true">
            <span ng-if="accounts.selectedReportType">{{accounts.selectedReportType.description | translate}}</span>
            <span ng-if="!accounts.selectedReportType">Izaberite format</span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li data-ng-repeat="reportType in accounts.reportTypeList">
              <a href ng-click="accounts.changeReportType(accounts.selectedAccount.accountId,reportType)">
                {{reportType.description | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div ng-if="accounts.showSubmenuItemType=='transactions'"
       data-ng-include="'accounts/accounts-transactions.tpl.html'"></div>
</div>
<div ng-if="accounts.showSubmenuItemType=='statements'" data-ng-include="'accounts/accounts-statements.tpl.html'"></div>
  <!-- reservations view just for BiH environment -->
  <div ng-if="accounts.showSubmenuItemType=='reservations'">
    <div class="t-a-c m-t-10 p-10 no-data-image" ng-show="accounts.selectedAccount.reservations.result.length == 0">
      <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
      <h3 class="no-data-label" translate="reservations.noData"></h3>
    </div>
    <div class="timeline-row col-sm-12 p-l-0 p-r-0" infinite-scroll='accounts.loadAccountReservations()' infinite-scroll-disabled='accounts.busy' infinite-scroll-distance='1' ng-show="accounts.selectedAccount.reservations.result.length > 0">
      <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0 no-top-radius">
        <ul class="hypo-timeline statements-timeline">
          <li data-ng-repeat="reservation in accounts.selectedAccount.reservations.result" ng-class="{'time-label': reservation.isVirtual === true, 'tl-badge-icon': reservation.isVirtual === undefined}">
            <span ng-if="reservation.isVirtual === true">{{reservation.valueDate | date: 'shortDate' : '+0200'}}</span>
            <i ng-if="reservation.isVirtual == undefined" data-ng-class="{'icon icon-priliv': transaction.credit != null,'icon icon-odliv': transaction.credit == null}"></i>
            <div ng-if="reservation.isVirtual == undefined" class="hypo-timeline-item transaction-item" data-ng-click="reservation.selected = !reservation.selected;" ng-class="{'opened': reservation.selected}">
              <div class="hypo-timeline-body">
                <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
                  <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{::reservation.description}}</b></h4>
                  <p class="m-b-0 m-t-0 no-wrap-text">
                    <span>{{::reservation.transactionType}}</span>
                  </p>
                </div>
                <div style="font-weight:600; font-size:22px;" class="price-payment col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::reservation.amount | number:2}}</b></span></div>
              </div>
              <div ng-if="reservation.selected" class="m-l-0 transaction-details">
                <div data-ng-include="'accounts/acc-reservation-details.tpl.html'"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
