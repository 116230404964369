(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:EndpointsService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('EndpointsService', EndpointsService);

  function EndpointsService($http, $q, $log, ConfigService) {
    var EndpointsServiceBase = {};
    /*var url = window.location.search.match(/url=([^&]+)/);
     if (url && url.length > 1) {
     url = decodeURIComponent(url[1]);
     }*/

    var linkList = {};
    $log.debug("ConfigService.url: "+ConfigService.url);
    EndpointsServiceBase.url = ConfigService.url;

    EndpointsServiceBase.links = null;

    EndpointsServiceBase.getLinks = function(){
      var deferred = $q.defer();
      //we show cached version if existing
      if(EndpointsServiceBase.links != null){
        deferred.resolve(EndpointsServiceBase.links);
      }
      else{
        $http.get(EndpointsServiceBase.url)
          .then(function(data) {
              EndpointsServiceBase.links = data.data;
              deferred.resolve(data.data);
          }).catch(function(msg, code) {
            deferred.reject(msg.data);
            $log.error(msg, code);
          });

      }
      return deferred.promise;
    };
    return EndpointsServiceBase;
  }
}());
