(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:ReferenceModelService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('ReferenceModelService', ReferenceModelService);

  function ReferenceModelService($q, EndpointsService, $log, $http) {
    var ReferenceModelServiceBase = {};
    ReferenceModelServiceBase.someValue = 'ReferenceModelService';

    ReferenceModelServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.referenceModels, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    return ReferenceModelServiceBase;
  }
}());
