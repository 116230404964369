<div class="statementModal alert-msg-frame">
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div style="color: white; padding-top: 43px;">
          <!--     <i class="icon icon-add pull-right close-icon-x" ng-click="$close()" style="font-size: 40px;"></i> -->
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-2 col-sm-3 col-xs-2">
        <i class="icon alert-icon icon-delete alert-icon-f"></i>
      </div>
      <div class="col-md-10 col-sm-9 col-xs-10">
        <span class="c-white alert-msg" translate="alert.deleteAction"></span>
      </div>
    </div>
    <div class="row">
      <div class="logout-modal-header-2 col-sm-12" ng-switch="vm.data.type">
        <div ng-switch-when="payment">
          {{'alert.payment' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
        <div ng-switch-when="template">
          {{'paymentTemplates.deleteSuccess1' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
        <div ng-switch-when="investment">
          {{'alert.investment' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
        <div ng-switch-when="termDeposit">
          {{'alert.termDeposit' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
        <div ng-switch-when="eSavingBook">
          {{'alert.ebookOrder' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
        <div ng-switch-when="cancelation">
          {{'alert.cancelation' | translate}}
          "{{vm.data.paymentName}}" {{"paymentTemplates.deleteSuccess2"| translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close()">Ok</button>
  </div>
</div>
