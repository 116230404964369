<section id="main">
    <!--<div class="new-msg-title" ng-if="newMessage.showSuccessSection === false" >
        <span ng-if="!newMessage.isReplyMessage" translate="newMessage.titleLabel"></span>
        <span ng-if="newMessage.isReplyMessage" translate="newMessage.titleReplyLabel"></span>
    </div>-->
    <div class="container-fluid">
        <div class="row exchange-rates">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <div
                    ng-if="newMessage.showSuccessSection === false"
                    class="domp-header-wrapper col-sm-12 p-0"
                >
                    <div class="domp-header-row">
                        <h2
                            class="f-28"
                            ng-if="!newMessage.isReplyMessage"
                            translate="newMessage.titleLabel"
                        >
                            New message
                        </h2>
                        <h2
                            class="f-28"
                            ng-if="newMessage.isReplyMessage"
                            translate="newMessage.titleReplyLabel"
                        >
                            Reply message
                        </h2>
                    </div>
                </div>
                <div
                    class="domp-body-wrapper"
                    ng-if="newMessage.showSuccessSection === false"
                >
                    <div class="p-t-20 p-b-20">
                        <form
                            name="sendMessageForm"
                            class="new-msg-form"
                            role="form"
                            novalidate
                        >
                            <div
                                ng-if="newMessage.countryCode != 'sl'"
                                class="form-group"
                            >
                                <label
                                    class="new-msg-form-label p-t-20 f-18"
                                    for="msgSubject"
                                    translate="newMessage.subjectLabel"
                                    >Subject</label
                                >
                                <input
                                    ng-required="newMessage.countryCode != 'sl'"
                                    name="msgSubject"
                                    ng-model="newMessage.sendMessageObject.subject"
                                    type="msgSubject"
                                    maxlength="{{newMessage.inputOptions.maxLength}}"
                                    class="form-control"
                                    id="msgSubject"
                                    placeholder="{{'newMessage.subjectPlaceholder'|translate}}"
                                    ng-class="{'b-c-error': sendMessageForm.msgSubject.$invalid}"
                                    ng-pattern-restrict="^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš][- A-Za-z0-9.ČčĆćžĐđŽžŠš:]*$"
                                />
                            </div>
                            <div class="form-group">
                                <label
                                    class="new-msg-form-label f-18"
                                    for="msgBody"
                                    translate="newMessage.bodyLabel"
                                    >Message</label
                                >
                                <textarea
                                    required
                                    ng-pattern-restrict="{{newMessage.regex.disallowStarQutoesEtc}}"
                                    ng-model="newMessage.sendMessageObject.body"
                                    class="form-control"
                                    name="msgBody"
                                    id="msgBody"
                                    cols="30"
                                    rows="10"
                                    maxlength="{{newMessage.countryCode == 'sl' ? 1600:''}}"
                                    ng-class="{'b-c-error': sendMessageForm.msgBody.$invalid}"
                                ></textarea>
                            </div>

                            <div
                                ng-if="newMessage.countryCode === 'hr'"
                                class="dropzone dropzone-cro-custom"
                                upload-multi-files="[ application/pdf, image/jpeg, image/png]"
                                files="newMessage.files"
                                file-object="newMessage.fileObject"
                                data-max-file-size="3"
                            >
                                <div
                                    class="custom-upload-button"
                                    data-ng-click="newMessage.openFileUploadUI()"
                                >
                                    <span translate="newMessage.uploadButton">
                                        Click to upload
                                    </span>
                                </div>
                                <input
                                    style="
                                        visibility: hidden;
                                        position: absolute;
                                    "
                                    type="file"
                                    multiple
                                    accept="application/pdf, image/jpeg, image/png"
                                    custom-on-change="newMessage.addFilesByClick"
                                    id="multi-file-upload-input"
                                />
                                <span
                                    ng-if="!newMessage.files.length"
                                    style="color: #cdcfdb"
                                    translate="newMessage.dragAndDrop"
                                ></span>
                                <div
                                    ng-if="newMessage.files.length"
                                    class="file-name file-name-cro-custom"
                                >
                                    <div
                                        class="file-item-cro"
                                        ng-repeat="sFile in newMessage.files"
                                        ng-value="{{$index}}"
                                    >
                                        <div>{{sFile.name}}</div>
                                        <div
                                            ng-click="newMessage.removeAttachment($index);"
                                            class="icon icon-close remove-image"
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-6 col-sm-6 t-a-l">
                                    <button
                                        class="btn btn-primary"
                                        data-ui-sref="messages"
                                        translate="newMessage.backBtn"
                                    >
                                        BACK
                                    </button>
                                </div>
                                <div class="col-xs-6 col-sm-6 t-a-r">
                                    <button
                                        class="btn btn-default"
                                        ng-click="newMessage.sendMessage(sendMessageForm);"
                                        ng-disabled="newMessage.sendButtonPressed || !sendMessageForm.$valid"
                                        translate="newMessage.sendBtn"
                                    >
                                        SEND
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!--<div class="msg-success-section" ng-if="newMessage.showSuccessSection === true">
                    <div class="msg-sent-success m-b-30">
                        <div class="icon-wrapper">
                            <i class="zmdi zmdi-check zmdi-hc-2x"></i>
                        </div>
                        <div class="label-wrapper"><span class="no-wrap-text">
                            <b class="f-18" translate="newMessage.successLabel1">Message sent</b>
                            <br> {{'newMessage.successLabel2'|translate}} "{{newMessage.successfulMessageSubject}}"</span>
                        </div>
                    </div>
                    <div>
                        <div class="col-xs-12 col-sm-4 p-0">
                            <div class="pointer msa-round-btn-container" ng-click="newMessage.showSuccessSection = false;newMessage.isReplyMessage = false;">
                                <div class="msa-round-btn"><i class="zmdi zmdi-plus zmdi-hc-2x"></i></div>
                                <div class="msa-round-btn-label" translate="newMessage.newLabel">Create new message</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-8 p-0" data-ui-sref="messages">
                            <div class="pointer msa-round-btn-container">
                                <div class="msa-round-btn"><i class="zmdi zmdi-comments zmdi-hc-2x"></i></div>
                                <div class="msa-round-btn-label" translate="newMessage.goToMsgsLabel">Go to messages</div>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div
                    class="currency-conversion-msg p-t-20"
                    ng-if="newMessage.showSuccessSection === true"
                >
                    <div class="payment-successful-wrapper">
                        <div class="row p-t-30 p-b-30">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <div class="currency-check-icon-no-line-height">
                                    <i
                                        class="icon icon-confirm c-white zmdi-hc-5x"
                                    ></i>
                                </div>
                            </div>
                            <div
                                class="col-xs-12 col-sm-6 col-lg-8 hidden-xs hidden-md"
                            >
                                <h2
                                    class="c-white f-28"
                                    translate="newMessage.successLabel1"
                                >
                                    Message sent
                                </h2>
                                <div class="f-15">
                                    <div ng-if="newMessage.countryCode != 'sl'">
                                        <span
                                            >{{'newMessage.successLabel2'|translate}}"{{newMessage.successfulMessageSubject}}"{{'newMessage.successLabel3'|translate}}</span
                                        >
                                    </div>
                                    <div ng-if="newMessage.countryCode == 'sl'">
                                        <span
                                            >{{'newMessage.successLabel2'|translate}}
                                            {{'newMessage.successLabel3'|translate}}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-xs-12 col-lg-8 col-md-8 col-sm-6 visible-xs visible-md t-a-c"
                            >
                                <h2
                                    class="c-white f-28"
                                    translate="newMessage.successLabel1"
                                >
                                    Message sent
                                </h2>
                                <div class="f-15">
                                    <div ng-if="newMessage.countryCode != 'sl'">
                                        <span
                                            >{{'newMessage.successLabel2'|translate}}"{{newMessage.successfulMessageSubject}}"{{'newMessage.successLabel3'|translate}}</span
                                        >
                                    </div>
                                    <div ng-if="newMessage.countryCode == 'sl'">
                                        <span
                                            >{{'newMessage.successLabel2'|translate}}
                                            {{'newMessage.successLabel3'|translate}}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row m-t-20 p-l-10 p-b-20">
                        <!-- Save template only for Domestic or foreign payments-->
                        <div class="col-xs-12 col-sm-4">
                            <a href="" ng-click="newMessage.createNewMessage()">
                                <span class="money-exchange-icon"
                                    ><i class="icon icon-messnew zmdi-hc-2x"></i
                                ></span>
                                <span
                                    class="f-18 bold-font"
                                    translate="newMessage.newLabel"
                                    >Create new message</span
                                >
                            </a>
                        </div>

                        <div class="col-xs-12 col-sm-4">
                            <a data-ui-sref="messages">
                                <span class="money-exchange-icon"
                                    ><i class="icon icon-message zmdi-hc-2x"></i
                                ></span>
                                <span
                                    class="f-18 bold-font"
                                    translate="newMessage.goToMsgsLabel"
                                    >Go to messages</span
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
