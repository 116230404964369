<section id="main">
  <div class="container">

    <div class="exchange-rate-header">
      <div class="row">
        <div class="col-sm-6 col-xs-12 f-20" translate="paymentTemplates.saveTemplate"></div>
      </div>
    </div>

    <div class="col-xs-12 m-t-15">
      <div class="col-sm-3 col-xs-5">
          <span class="template-headers" translate="paymentTemplates.templateIcon">
            Template icon
          </span>
          <div class="dropdown">
            <div class="dropdown-toggle img-selector-btn" type="button" data-toggle="dropdown">

              <div class="circ-img-container">
                <img ng-src="{{newTemplate.selectedImage.downloadLink}}" alt="Ico"/>
              </div>

                <span class="caret-icon caret-cursor">
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                </span>

            </div>
            <div class="dropdown-menu tmpl-img-selector" role="menu" aria-labelledby="menu1">
              <div ng-repeat="image in newTemplate.images" data-ng-click="newTemplate.selectImage(image)">
                <div class="col-xs-12 img-option">
                  <img class="tmpl-img" ng-src="{{image.downloadLink}}" alt="Ico">
                </div>
              </div>

            </div>
          </div>

      </div>
      <div class="col-sm-9 col-xs-7">
          <span class="template-headers" translate="paymentTemplates.templateName">
            Template name
          </span>
        <input type="text" class="form-control input-field"
               data-ng-model="newTemplate.templateNameInput"
               placeholder="{{'internalTransfer.templateName' | translate}}"
               maxlength="35">
      </div>
    </div>

    <div class="col-xs-12">
      <button class="btn btn-default m-t-20 col-sm-3 col-xs-6 pull-right m-r-15"
              data-ng-click="newTemplate.saveAsTemplate();"
              translate="paymentTemplates.save"
              ng-disabled="newTemplate.templateNameInput === '' || newTemplate.formSubmitted">
        Save
      </button>
    </div>

  </div>
</section>
