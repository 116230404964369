(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name viber.marketingBanner.directive:marketingBanner
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="viber.marketingBanner">
       <file name="index.html">
        <marketing-banner></marketing-banner>
       </file>
     </example>
   *
   */
  angular
    .module('viber')
    .directive('marketingBanner', marketingBanner);

  function marketingBanner() {
    return {
      restrict: 'EA',
      scope: {
        img: '@',
        checkViber: '&'
      },
      templateUrl: 'viber/marketing-banner/marketing-banner-directive.tpl.html',
      replace: false,
      controllerAs: 'marketingBanner',
      controller: function ($scope, $state, $rootScope, $localStorage, $window, ViberService) {
        var vm = this;
        vm.viberImageUrl = $scope.img;
        vm.checkViber = $scope.checkViber;
        console.log(vm.checkViber)
        vm.ViberMarketingInfo = $rootScope.APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo;
        console.log(vm.ViberMarketingInfo);
        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.currentLang = $localStorage.appLanguage;
        $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
          vm.currentLang = lang.langCode;
        });
        vm.onBannerClicked = function () {
          if (vm.ViberMarketingInfo && vm.ViberMarketingInfo.link) {
              $window.open(vm.ViberMarketingInfo.link, '_blank');
          } else {
            vm.checkViber();
          }
        };
      }
    };
  }
}());
