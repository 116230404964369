<!-- TRANSACTION FILTER DIRECTIVE START -->
<div class="tml-filter-container tmlf-wide"
         stlye="overflow: hidden; margin: 15px">
      <!-- START Search text input -->
      <div class="inner-section left">
        <i class="icon icon-search  icon-hc-2x m-l-5"></i>
      </div>
      <div class="inner-section center">
        <input type="text" class="search-input"
               placeholder="{{'timelineFilter.placeholder'|translate}}"
               ng-model="vm.transactionFilterOptions.genericSearch" ng-model-options="{ debounce: 300 }"
               ng-change="vm.search()">
      </div>
      <div  class="inner-section right">
        <i class="icon icon-filter icon-hc-2x i-r-0" style="z-index: 100000"
           ng-show="!vm.transactionFilterOptions.genericSearch"
           ng-click="vm.showFilterOptionsWrapper = !vm.showFilterOptionsWrapper">
        </i>
        <i class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0"
           ng-show="vm.transactionFilterOptions.genericSearch"
           ng-click="vm.clearSearch();">
        </i>
      </div>
      <div class="tml-filter-selection" ng-class="{'opened': vm.showFilterOptionsWrapper, 'h-510': ((vm.dateFromOpen || vm.dateToOpen) && vm.countryCode == 'sl')}"
           style="z-index: 6">
        <div class="tml-filter-type-selection-wrapper">
          <div ng-class="{'selected': vm.transactionFilterOptions.transactionType === undefined}" class="tmlf-type-item"
               ng-click="vm.transactionFilterOptions.transactionType = undefined"
               translate="timelineFilter.all"
               style="width: 33.333%">
          </div>
          <div ng-class="{'selected': vm.transactionFilterOptions.transactionType == 'Credit'}" class="tmlf-type-item"
               style="width: 33.333%"
               ng-click="vm.transactionFilterOptions.transactionType = 'Credit'"
               translate="timelineFilter.credit"></div>
          <div ng-class="{'selected': vm.transactionFilterOptions.transactionType == 'Debit'}" class="tmlf-type-item"
               style="width: 33.333%"
               ng-click="vm.transactionFilterOptions.transactionType = 'Debit'"
               translate="timelineFilter.debit"></div>
        </div>

        <div ng-if="vm.showStatementsFilter" class="m-t-15" style="overflow:auto">
          <div class="col-xs-6 p-l-5 p-r-5">
            <div class="form-group">
              <label class="f-12-s" translate="transactions.statementsFilter"></label>
              <select class="form-control-db"
                      ng-options="option | date:'shortDate' : '+0200' for option in vm.statementDates"
                      ng-model="vm.transactionFilterOptions.selectedStatementDate">
              </select>
            </div>
          </div>
        </div>


        <!-- START: Row Date from and date to -->
        <div class="m-t-15 clearfix" ng-if="vm.allowCustomDatepicker">
          <div class="col-xs-6 p-l-5 p-r-5">
            <div class="form-group m-b-0">
              <label class="f-12-s" translate="repaymentPlan.dateFrom">Date from</label>
              <div class="input-group">
                <input type="text" class="form-control-db"
                       ng-value="vm.transactionFilterOptions.dateFrom | date: 'shortDate' : '+0200'"
                       readonly>
                <span class="input-group-btn">
                  <button data-ng-click="vm.dateFromOpen = !vm.dateFromOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-xs-6 p-l-0 p-r-5">
            <div class="form-group m-b-0">
              <label class="f-12-s" translate="repaymentPlan.dateTo">Date to</label>
              <div class="input-group ">
                <input type="text" class="form-control-db"
                       ng-value="vm.transactionFilterOptions.dateTo | date: 'shortDate' : '+0200'"
                       readonly>
                <span class="input-group-btn">
                  <button data-ng-click="vm.dateToOpen = !vm.dateToOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <!-- Datepickers -->
          <div class="col-xs-6 p-l-0 p-r-5">
            <!-- Date from datepicker-->
            <div data-ng-model="vm.transactionFilterOptions.dateFrom"
                 show-weeks="false"
                 is-open="vm.dateFromOpen"
                 ng-change="vm.onDateFromChange()"
                 current-text="{{'core.period.today' | translate}}"
                 clear-text="{{'core.datePicker.clearBtn' | translate}}"
                 close-text="{{'core.datePicker.doneBtn' | translate}}"
                 uib-datepicker-popup="shortDate">
            </div>
            <!-- Date to datepicker-->
            <div data-ng-model="vm.transactionFilterOptions.dateTo"
                 show-weeks="false"
                 is-open="vm.dateToOpen"
                 date-disabled="vm.datesToDisabled(date, mode)"
                 current-text="{{'core.period.today' | translate}}"
                 clear-text="{{'core.datePicker.clearBtn' | translate}}"
                 close-text="{{'core.datePicker.doneBtn' | translate}}"
                 uib-datepicker-popup="shortDate">
            </div>
          </div>
        </div>
        <!-- END: Row Date from and date to -->

        <div class="m-t-15" style="overflow:auto">
          <div class="col-xs-5 p-l-5 p-r-5">
            <div class="form-group">
              <label class="f-12-s" translate="timelineFilter.amountFrom"></label>
              <input ng-keyup="$event.keyCode == 13 && vm.applyFilter()" type="text" class="form-control-db"
                     ng-model="vm.transactionFilterOptions.amountGTE"
                     amount-input-mask maxlength="12"
                     placeholder="{{'accounts.accEg' | translate}}: 000.000,00" autocomplete="off">
            </div>
          </div>
          <div class="col-xs-4 p-l-0 p-r-5">
            <div class="form-group">
              <label class="f-12-s" translate="timelineFilter.amountTo"></label>
              <input ng-keyup="$event.keyCode == 13 && vm.applyFilter()" type="text" class="form-control-db"
                     ng-model="vm.transactionFilterOptions.amountLTE"
                     amount-input-mask maxlength="12"
                     placeholder="{{'accounts.accEg' | translate}}: 000.000,00" autocomplete="off">
            </div>
          </div>
          <div class="col-xs-3 p-l-0 p-r-5 p-t-25">
            <select class="form-control-db"
                    ng-options="option.symbol as option.symbol for option in vm.currenciesList"
                    ng-model="vm.transactionFilterOptions.currencySymbol">
            </select>
          </div>
        </div>
        <div class="m-b-15" style="overflow:auto">
          <div class="col-xs-6 t-a-l p-l-5">
            <button class="btn btn-primary app-btn-gray ng-scope" ng-click="vm.resetFilter();"
                    translate="timelineFilter.reset">
            </button>
          </div>
          <div class="col-xs-6 t-a-r p-r-5">
            <button class="btn btn-default app-btn-blue w-100" ng-click="vm.applyFilter();"
                    translate="timelineFilter.apply">
            </button>
          </div>
        </div>
      </div>
      <!-- END Detail filter expanded -->

    </div>
<!-- TRANSACTION FILTER DIRECTIVE END -->
