(function() {
  "use strict";

  angular.module("localization").config(config);

  function config($translateProvider) {
    var data = {
      // http://www.xe.com/ibancalculator/sample/?ibancountry=serbia
      session: {
        inactiveUser:
          "Zbog neaktivnosti isteklo Vam je vreme za rad u aplikaciji. Molimo Vas da se prijavite ponovo."
      },
      filter: {
        maxDateRange: "Maksimalni raspon datuma je jedna godina."
      },
      inputFields: {
        IBAN: "9999 9999 9999 9999 9"
      },
      core: {
        filter: {
          maxDateRange: "Maksimalni raspon datuma je jedna godina."
        },
        moneyFormat: {
          mask: "#.###.##0,00",
          placeholder: "eg: 000.000,00"
        },
        period: {
          today: "Danas",
          yesterday: "Juče",
          thisWeek: "Sedam dana",
          thisMonth: "Mesec dana",
          thisYear: "Godina dana",
          all: "Sve",
          allMessages: "Sve poruke"
        },
        paymentOrder: "Nalog broj",
        investOrder: "Investicija broj",
        successDelete: "je uspješno izbrisan.",
        investSuccessDelete: "je uspješno izbrisan.",
        termDepositPayoutType: {
          "2": "Pripis kamate po dospijeću",
          "2e": "(kamata i glavnica zajedno)",
          "16": "Mjesečna isplata kamate na dan",
          "16e":
            "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakog 15 u mjesecu)",
          "17": "Tromjesečna isplata kamate na dan",
          "17e":
            "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svaka tri mjeseca 15 u mjesecu)",
          "18": "Šestomjesečna isplata kamate na dan",
          "18e":
            "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakih šest mjeseci 15 u mjesecu)",
          "19": "Godišnja isplata kamate na dan",
          "19e":
            "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakih godinu dana 15 u mjesecu)",
          "3": "Isplata kamate po dospijeću",
          "3e": "(samo kod automatskog produženja)"
        },
        termDepositStatus: {
          realised: "Realised",
          unrealised: "Unrealised",
          cancelled: "Cancelled",
          receivednotexecuted: "U obradi"
        },
        confirmation:
          "Reklamacija na transakciju je uspešno poslana u bazu podataka.",
        error: "Greška",
        sweetAlertTranslation: {
          yes: "Da",
          no: "Ne",
          areYouSure: "Jeste li sigurni?"
        },
        logoutModalTranslation: {
          logoutBtn: "Da",
          logoutMsg: "Jeste li sigurni da se želite odjaviti ?",
          cancel: "Ne"
        },
        sameAccounts: "Greška: Isti računi!",
        paymentSignedStatus: {
          inprocessing: "U obradi"
        },
        today: "Danas",
        passwordChangedMsg: "Uspešno ste promenili lozinku!",
        noAccounts: "Nema dostupnih računa",
        datePicker: {
          doneBtn: "Uredu",
          clearBtn: "Obriši"
        }
      }
    };

    $translateProvider.translations("sr", data);
  }
})();
