<section id="main">
    <form novalidate name="complaint.complaintForm">
        <div class="container complaintForm">
            <div class="row m-0">
                <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
                    <h2 translate="complaints.header" class="f-28"></h2>
                </div>
            </div>
            <div class="row m-0">
                <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
                    <h3 translate="complaint.title"></h3>
                    <h4 translate="complaint.subtitle"></h4>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-md-6 z-index-4">
                    <label class="addiko-new-label" translate="complaints.category"></label>
                    <addiko-dropdown list="complaint.complaintCategories" model="categoryId" name="categoryName"
                        code="categoryId"></addiko-dropdown>
                </div>
                <div class="col-md-6 z-index-3">
                    <label class="addiko-new-label" translate="complaints.subcategory"></label>
                    <addiko-dropdown list="complaint.complaintSubCategories" name="subCategoryName"
                        model="subCategoryId" code="subCategoryId"></addiko-dropdown>
                </div>
            </div>
            <div ng-show="complaint.formConfiguration" class="row m-0">
                <div class="row m-0">
                    <div ng-if="complaint.formConfiguration.description" class="d-flex client-info col-md-12">
                        <span class="info-icon"></span>
                        <p class="client-info-text">{{complaint.formConfiguration.description}}</p>
                    </div>
                    <div ng-if="complaint.formConfiguration.isClientNotice" class="col-md-12">
                        <p class="client-info-notice" ng-bind-html="complaint.formConfiguration.clientNoticeHTML"></p>
                    </div>

                </div>
                <div ng-if="complaint.formConfiguration.transactionNumberVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionNumber"></label>
                    <input type="text" name="transactionNumber" class="addiko-new-input" ng-model="complaint.model.transactionNumber"
                        required ng-disabled="complaint.transactionNumberDisabled"
                        autocomplete="off">
                </div>
                <div ng-if="complaint.formConfiguration.cardNumberVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.cardNumber"></label>
                    <input type="text" name="cardNumber" class="addiko-new-input"
                        ui-mask="9999  99****  ********  9999"
                        ui-mask-placeholder
                        ui-mask-placeholder-char="_"
                        minlength="22"
                        ui-options="complaint.uiMaskOptions"
                        ng-disabled="complaint.cardNumberDisabled"
                        ng-model="complaint.model.cardNumber"
                        required autocomplete="off">
                </div>
                <div ng-if="complaint.formConfiguration.atmLocationVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.atmLocation"></label>
                    <input type="text" name="atmLocation" class="addiko-new-input" ng-model="complaint.model.atmLocation"
                        ng-disabled="complaint.atmLocationDisabled"
                        required autocomplete="off">
                </div>
                <div ng-show="complaint.formConfiguration.cardTypeVisible" class="col-md-6 mt-3 z-index-2">
                    <label class="addiko-new-label" translate="complaints.cardType"></label>
                    <addiko-dropdown list="complaint.cardTypes" name="cardTypeName" model="cardType"
                        code="cardTypeId" disabled="complaint.cardTypeDisabled"></addiko-dropdown>
                </div>
                <div ng-if="complaint.formConfiguration.transactionDateVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionDate"></label>
                    <addiko-datepicker model="complaint.model.transactionDate" min="complaint.calendarMinDate" max="complaint.calendarMaxDate" 
                        disabled="complaint.transactionDateDisabled"></addiko-datepicker>
                </div>
                <div ng-if="complaint.formConfiguration.transactionPayerNameVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionPayerName"></label>
                    <input type="text" name="transactionPayerName" class="addiko-new-input"
                        ng-disabled="complaint.transactionPayerNameDisabled"
                        ng-model="complaint.model.transactionPayerName" autocomplete="off" required>
                </div>
                <div ng-if="complaint.formConfiguration.transactionAmountVisible" class="col-md-4 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionAmount"></label>
                    <input type="text" name="transactionAmount" class="addiko-new-input"
                        ng-disabled="complaint.transactionAmountDisabled"
                        ng-model="complaint.model.transactionAmount" allow-empty="true" amount-input-mask autocomplete="off"
                        required>
                </div>
                <div ng-show="complaint.formConfiguration.transactionAmountVisible" class="col-md-2 chevron-fix mt-3  z-index-1">
                    <label class="addiko-new-label" translate="complaints.currency"></label>
                    <addiko-dropdown list="complaint.currencies" name="symbol" model="currency"
                        disabled="complaint.transactionAmountCurrencyDisabled"
                        code="symbol"></addiko-dropdown>
                </div>
                <div ng-if="complaint.formConfiguration.transactionDueDateVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionDueDate"></label>
                    <addiko-datepicker model="complaint.model.transactionDueDate" min="complaint.calendarMinDate" max="complaint.calendarMaxDate"
                        disabled="complaint.transactionDueDateDisabled"></addiko-datepicker>
                </div>
                <div ng-if="complaint.formConfiguration.transactionPayeeNameVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.transactionPayeeName"></label>
                    <input type="text" name="transactionPayeeName" class="addiko-new-input"
                        ng-disabled="complaint.transactionPayeeNameDisabled"
                        ng-model="complaint.model.transactionPayeeName" required autocomplete="off">
                </div>
                <div ng-if="complaint.formConfiguration.merchantNameVisible" class="col-md-6 mt-3">
                    <label class="addiko-new-label" translate="complaints.merchantName"></label>
                    <input type="text" name="merchantName" class="addiko-new-input" ng-model="complaint.model.merchantName"
                        ng-disabled="complaint.merchantNameDisabled"
                        required autocomplete="off">
                </div>
                <div ng-if="complaint.formConfiguration.complaintDescVisible" class="col-md-12 mt-3">
                    <label class="addiko-new-label" translate="complaints.description"></label>
                    <textarea class="addiko-new-textarea" rows="6" ng-model="complaint.model.complaintDesc" required></textarea>
                </div>
            </div>
            <div class="row m-0 pt-3">
                <div class="col-xs-6 col-sm-3">
                    <button translate="complaints.back" ng-click="complaint.back()"
                        class="addiko-btn-new addiko-btn-new--outline"></button>
                </div>
                <div ng-show="!complaint.formConfiguration.isClientNotice" class="col-xs-6 col-sm-3 col-sm-offset-6">
                    <button translate="complaints.confirm" ng-click="complaint.createNewComplaint()"
                        class="addiko-btn-new addiko-btn-new--primary"></button>
                </div>
            </div>
        </div>
    </form>
</section>