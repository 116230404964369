<div class="statementModal alert-msg-frame">
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div style="color: white; padding-top: 43px;">
      <!--    <i class="icon icon-add pull-right close-icon-x" ng-click="$close()" style="font-size: 40px;"></i>-->
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-2 col-sm-3 col-xs-2">
        <i class="icon alert-icon icon-info alert-icon-f"></i>
      </div>
      <div class="col-md-10 col-sm-9 col-xs-10">
        <span class="c-white alert-msg" translate="alert.information"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div ng-if="vm.data.type == 'html'" class="logout-modal-header-2">
          <span ng-bind-html="vm.data.text"></span>
        </div>
        <div ng-if="!vm.data.type" class="logout-modal-header-2">
          <span>{{vm.data.text | translate}} </span><span>{{vm.data.id}}</span><span> {{vm.data.text2 | translate}}</span>
          <a ng-if="vm.data.link" class="text-underline" href="{{vm.data.link}}" target="_blank">{{vm.data.link}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close()">
      {{(vm.data.btnKey ? vm.data.btnKey : 'alert.cancel') | translate}}
    </button>
  </div>
</div>
