(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:ChangeAtmWithdrawalLimitCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('ChangeAtmWithdrawalLimitCtrl', ChangeAtmWithdrawalLimitCtrl);

  function ChangeAtmWithdrawalLimitCtrl($rootScope, $state, AlertService, AccountsService, HelperService, AccountLimitOrderService, NotificationService) {
    var vm = this;
    vm.selectedDesiredAmount = {};
    vm.step = 1;
    vm.type = {};
    vm.selectedUser = '';
    vm.formSubmitted = false;
    vm.cardTypes = [];
    function loadAccount () {
      AccountsService.getAccountById($state.params.accountId)
        .then(function (response) {
          vm.selectedAccount = response;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function createPostObj (){
      return {
        account: vm.selectedAccount.iban,
        name: vm.user,
        personType: {
          id: vm.type.id,
          description: vm.type.description
        },
        amount: {
          id: vm.selectedDesiredAmount.id,
          description: vm.selectedDesiredAmount.description
        },
        note: " ",
        cardType: vm.selectedCardType
      };
    }

    vm.validate = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if(!vm.changeATMWithdrawalForm.$valid)
        return;

      vm.insertedOrderObject = createPostObj();
      vm.nextButtonPressed = true;
      AccountLimitOrderService.validateAccountLimitPosTerminalOrder(vm.insertedOrderObject)
        .then(function (response){
          vm.insertedOrderObject = angular.extend(response);
          vm.formSubmitted = false;
          vm.step = 2;
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        }).finally(function () {
        vm.nextButtonPressed = false;
      })
    };
    vm.edit = function () {
      vm.step = 1;
    };
    vm.submit = function () {
      if (vm.submitButtonPressed) return;
      vm.submitButtonPressed = true;
      AccountLimitOrderService.insertPosTerminalOrder(vm.insertedOrderObject)
        .then(function (response){
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              vm.goBackToAccount();
            });
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        }).finally(function(){
        vm.submitButtonPressed = false;
      })
    };

    vm.goBackToAccount = function () {
      $state.go('accounts', {
        accountId: $state.params.accountId
      });
    };

    vm.selectCardType = function (selectedType) {
      loadDesiredAmount(selectedType)
    };

    function loadDebitCardType() {
      return AccountLimitOrderService.accountLimitPosTerminalOrderCardType()
        .then(function (response) {
          vm.cardTypes = response.result;

          vm.selectedCardType = vm.cardTypes[0];
          loadDesiredAmount(vm.selectedCardType);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function loadDesiredAmount(cardType) {
      var params = {
        orderBy: 'description asc',
        accountId: ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') ? $state.params.accountId : undefined,
        cardType: cardType.id
      };
      return AccountLimitOrderService.accountLimitPosTerminalOrderAmountType(params)
        .then(function (response) {
          vm.desiredAmounts = response.result;
          vm.selectedDesiredAmount = vm.desiredAmounts[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function loadUsers() {
      var params = null;
      return AccountLimitOrderService.accountLimitPosTerminalOrderPersonType()
        .then(function (response) {
          vm.forOptions = response.result;
          setDefaultForOption();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });

    }

    vm.selectForOption= function() {
      if(vm.type.id == 'uporabnik') {
        vm.user = vm.selectedUser;
      } else {
        vm.user = '';
      }
    };

    function setDefaultForOption() {
      if(vm.forOptions){
        var temp = _.find(vm.forOptions, function (item) {
          return item.id == 'uporabnik';
        });
        if(temp){
          vm.type = temp;
          vm.user = vm.selectedUser;
        }

      }
    }


    function init () {

      var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
      vm.selectedUser = userSettings.firstName + " " + userSettings.lastName;

      loadAccount();
      loadDebitCardType(); //This method requests for loadDesiredAmount();
      // loadDesiredAmount();
      loadUsers();

    }
    init();
  }
}());
