<div id="main">
  <div ng-include="'dashboard/dashboard-notifications.tpl.html'"></div>
  <div class="container-fluid p-t-15">
    <!-- ROW START -->
    <div class="row p-b-15">
      <div class="col-md-6 col-xs-6">
        <h2 class="m-l-0 f-28 m-t-0 m-b-0 title-style" translate="dashboard.dashboardHeader"></h2>
      </div>
      <div class="col-md-6 col-xs-6">
        <div class="add-widget-container">
          <div class="add-widget-bubble pointer" ng-click="dashboard.openWidgetsModal();">
            <i class="zmdi zmdi-plus"></i> {{'dashboard.addNewWidget1'| translate}}
          </div>
          <span class="widget-label">{{'dashboard.addNewWidget2'| translate}}</span>
        </div>
      </div>
    </div>
    <!-- ROW END -->
    <!-- ROW START -->
    <div class="row p-b-15" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">

      <div ng-switch-default class="col-sm-12 col-md-6 m-b-15" data-ng-repeat="account in dashboard.accountList.result">
        <div ng-switch on="account.accountType.group">
          <div ng-switch-when="1">
            <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
          </div>
          <div ng-switch-default>
            <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
          </div>
        </div>
      </div>

      <div ng-switch-when="sl">
        <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.currentAndCreditAccounts.length" data-ng-repeat="account in dashboard.currentAndCreditAccounts">
          <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.savingAccounts.length" data-ng-repeat="account in dashboard.savingAccounts">
          <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.depositAccounts.length" data-ng-repeat="account in dashboard.depositAccounts">
          <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.loanAccounts.length" data-ng-repeat="account in dashboard.loanAccounts">
          <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.giroAccounts.length" data-ng-repeat="account in dashboard.giroAccounts">
          <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
        </div>
      </div>

      <!--Banner Campaign-->
      <div class="col-sm-12 col-md-6 m-b-15" ng-if="(dashboard.showBannerLocal && dashboard.currentLang !== 'en') ||
       (dashboard.showBannerEng && dashboard.currentLang == 'en')"
           data-ng-include="'dashboard/campaign-offer-banner.tpl.html'"></div>


      <!--Carousel Campaign-->
      <div class="col-sm-12 col-md-6 m-b-15" ng-if="(dashboard.showCarouselLocal && dashboard.currentLang !== 'en') ||
       (dashboard.showCarouselEng && dashboard.currentLang == 'en')"
           data-ng-include="'dashboard/campaign-offer-carousel.tpl.html'"></div>

      <div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.showNonViberBanner && dashboard.countryCode != 'sl'" data-ng-include="'dashboard/instant-loan.tpl.html'"></div>

      <!-- Start Viber banner -->
      <marketing-banner ng-if="dashboard.viberImageUrl" class="cursor-pointer"
                        img="{{dashboard.viberImageUrl}}" check-viber="dashboard.checkUserViberActivation()">
      </marketing-banner>

            <!-- Start Viber banner -->
      <marketing-info-banner ng-if="dashboard.countryCode == 'sl' && dashboard.marketingInfo && dashboard.bannerImageUrl" class="cursor-pointer" img="{{dashboard.bannerImageUrl}}">
      </marketing-info-banner>

      <!--<div class="col-sm-12 col-md-6 m-b-15" ng-if="dashboard.countryCode === 'sl'" data-ng-include="'dashboard/banking-survey-banner.tpl.html'"></div>-->


      <!--<div class="col-sm-12 col-md-6 m-b-15" ng-if="APP_GLOBAL_SETTINGS.userSettings.isViberUser  == 'Y'" data-ng-click="dashboard.checkUserViberActivation();">-->
        <!--<div ng-if="dashboard.countryCode != 'hr'" class="banner-image-url cursor-pointer response-img" style="height: 161px;"-->
             <!--ng-style="{'background-image': 'url(' + dashboard.viberImageUrl + ')'}">-->
        <!--</div>-->

        <!--<div ng-if="dashboard.countryCode == 'hr'" class="banner-image-url cursor-pointer" ng-style="{'background-image': 'url(' + dashboard.viberImageUrl + ')'}">-->
          <!--<div class="t-a-l" style="position:absolute; bottom: 0px; top:0px; left: 35px;">-->
            <!--<h3 class="c-white f-20" ng-if="dashboard.currentLang == 'hr'" style="">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.text1}}</h3>-->
            <!--<h3 class="c-white f-20" ng-if="dashboard.currentLang == 'en'" style="">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.textEng1}}</h3>-->
          <!--</div>-->

          <!--<div class="t-a-l" style="position:absolute; bottom: 0px; top:40px; left: 35px;">-->
            <!--<h3 class="c-white fw-cl custom-underline f-20" ng-if="dashboard.currentLang == 'hr'" style="">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.text2}}</h3>-->
            <!--<h3 class="c-white fw-cl custom-underline f-20" ng-if="dashboard.currentLang == 'en'"  style="">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.textEng2}}</h3>-->
            <!--<h3 class="c-white fw-cl custom-underline pull-left m-0 f-20" ng-if="dashboard.currentLang == 'hr'">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.text3}}</h3>-->
            <!--<h3 class="c-white fw-cl custom-underline pull-left m-0 f-20" ng-if="dashboard.currentLang == 'en'">{{APP_GLOBAL_SETTINGS.defaultData.ViberMarketingInfo.textEng3}} </h3>-->
          <!--</div>-->

        <!--</div>-->

      <!--</div>-->
      <!-- End Viber banner -->
    </div>
    <!-- ROW END -->
    <!-- ROW START -->
    <div class="row p-b-15">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-b-10">
        <a data-ui-sref="payments.domesticPayment">
          <div class="round-btn-container">
            <!--<div class="round-btn"><i class="zmdi zmdi-home zmdi-hc-3x"></i></div>-->
            <div class="round-btn"><i class="icon icon-domestic" style="font-size:2em"></i></div>
            <div class="round-btn-label">{{'dashboard.domesticPaymentBtn1'|translate}}
              <br>{{'dashboard.domesticPaymentBtn2'|translate}}</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-b-10">
        <a data-ui-sref="payments.internalTransfer">
          <div class="round-btn-container">
            <!--<div class="round-btn"><i class="zmdi zmdi-swap zmdi-hc-3x"></i></div>-->
            <div class="round-btn"><i class="icon icon-transfer" style="font-size:2em"></i></div>
            <div class="round-btn-label">{{'dashboard.transferBtn1'|translate}}
              <br>{{'dashboard.transferBtn2'|translate}}</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-b-10">
        <div class="pointer round-btn-container" ng-click="dashboard.toggleTemplatesView();">
          <div class="round-btn"><i class="icon icon-template" style="font-size:1.6em"></i></div>
          <div class="round-btn-label">{{'dashboard.templateBtn1'|translate}}
            <br>{{'dashboard.templateBtn2'|translate}}</div>
        </div>
        <div class="templates-container animated fadeIn" ng-show="dashboard.showTemplatesView">
        <div class="form-group m-b-0">
          <div class="input-group" style="height:50px;border: 1px solid black;">
            <div class="input-group-btn srch-icon" style="border:1px solid lightgray; border-right:none;">
              <button class="btn" type="submit" style="box-shadow:none;background-color: transparent;">
                <i class="glyphicon glyphicon-search" style="font-size:20px;"></i>
              </button>
            </div>
            <input id="searchTemplatesInput" style="height:50px;" data-ng-model="dashboard.tmpSearchText.name" type="text" class="form-control srch-input" placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}" ng-change="dashboard.toggleFavoriteTemplates(dashboard.tmpSearchText.name)">
          </div>
        </div>
        <div class="p-10 templates-search-container" scrollable>
          <div class="m-b-10" ng-show="false">
            <h4 translate="templateSearch.favorites"></h4>
            <div class="list-group">
              <a href="" class="list-group-item">
                <div class="row">
                  <div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>
                  <div class="col-xs-10">
                    <h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>
                    <p class="list-group-item-text">HR4015290098745696</p>
                  </div>
                  <div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>
                </div>
              </a>
              <a href="" class="list-group-item">
                <div class="row">
                  <div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>
                  <div class="col-xs-10">
                    <h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>
                    <p class="list-group-item-text">HR4015290098745696</p>
                  </div>
                  <div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>
                </div>
              </a>
              <a href="" class="list-group-item">
                <div class="row">
                  <div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>
                  <div class="col-xs-10">
                    <h5 class="list-group-item-heading"><b>Favorite template 1</b></h5>
                    <p class="list-group-item-text">HR4015290098745696</p>
                  </div>
                  <div class="col-xs-1"><i class="zmdi zmdi-star zmdi-hc-2x"></i></div>
                </div>
              </a>
            </div>
          </div>
          <div class="m-b-10">
            <h4 translate="templateSearch.templatesList"></h4>
            <div class="list-group">
              <a href="" class="list-group-item" data-ng-repeat="template in dashboard.templateList.result| filter:dashboard.tmpSearchText" ng-click="dashboard.navigateToPaymentForm({id: template.id, type: template.paymentTypeGroup, template: template})">
                <div class="row">
                  <div class="col-xs-1 p-r-0"><i class="zmdi zmdi-file-text zmdi-hc-2x"></i></div>
                  <div class="col-xs-11">
                    <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                    <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- ROW END -->
    <div ng-include="'timeline/timeline.tpl.html'" class="row"></div>
  </div>
</div>
