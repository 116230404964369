(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      transactions:{
        transactionTypes:{
          credit: "Credit"
          ,debit: "Debit"
          ,all: "All"
        }
      },
      reclamation: {
        title: "Complaint",
        number: "Reclamation number",
        paymentReference: "Payment reference",
        bookingDate: "Booking date",
        valueDate: "Value date",
        amount: "Amount",
        purpose: "Purpose of payment",
        description: "Complaint text",
        wantComplain: "Want to complain",
        confirm: "Confirm",
        cancel: "Cancel"
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
