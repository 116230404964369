<form novalidate name="transactionDivision.tDivForm">
  <div class="row">
    <div class="col-sm-12">
      <div class="container domp-from-section">
        <div class="row p-15">
          <!--<div class="col-sm-6">-->
          <div ng-if="transactionDivision.apiLocation !== 'hr'" 
               class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="creationDate"
                       translate="transactionDivision.creationDate"></label>
                <input type="text" id="creationDate" class="form-control input-field"
                       data-ng-model="transactionDivision.tempTransDivObj.creationDate" disabled>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="fullName"
                       translate="transactionDivision.fullName"></label>
                <input type="text" id="fullName" class="form-control input-field"
                       data-ng-model="transactionDivision.tempTransDivObj.fullName" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div  class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="sellingPlace"
                       translate="transactionDivision.sellingPlace"></label>
                <input type="text" id="sellingPlace" name="sellingPlace" class="form-control input-field"
                        ng-disabled="transactionDivision.apiLocation === 'sr' || transactionDivision.apiLocation === 'hr'"
                       ng-class="{'domp-error': transactionDivision.tDivForm.sellingPlace.$invalid && transactionDivision.formSubmitted}"
                       data-ng-model="transactionDivision.tempTransDivObj.transactionLocation" 
                       required>
                <div class="c-red"
                     ng-show="transactionDivision.tDivForm.sellingPlace.$error.required && transactionDivision.formSubmitted"
                     translate="transactionDivision.sellingPlaceError">
                </div>
              </div>
            </div>
            <div ng-if="transactionDivision.apiLocation !== 'hr'" class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="jmbg" translate="transactionDivision.jmbg"></label>
                <input type="text" id="jmbg" class="form-control input-field"
                       data-ng-model="transactionDivision.tempTransDivObj.jmbg" disabled>
              </div>
            </div>
            <div ng-if="(transactionDivision.transactionId || transactionDivision.reservationId) && transactionDivision.apiLocation !== 'hr'" class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="reservationID" translate="transactionDivision.reservationNumber"></label>
                <input type="text" id="reservationID" class="form-control input-field"
                       data-ng-model="transactionDivision.tempTransDivObj.reservationID" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div ng-if="transactionDivision.apiLocation !== 'hr'"  class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label" for="transactionDate"
                       translate="transactionDivision.transactionDate"></label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field" name="transactionDate" id="transactionDate"
                         uib-datepicker-popup="shortDate"
                         data-ng-model="transactionDivision.tempTransDivObj.transactionDate" show-weeks="false"
                         is-open="transactionDivision.datePickerBeginOpened"
                         datepicker-options="transactionDivision.dateOptions"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         ng-class="{ 'domp-error' : transactionDivision.tDivForm.transactionDate.$invalid && transactionDivision.formSubmitted}"
                         required
                         ng-disabled="transactionDivision.apiLocation === 'sr'"
                         readonly>
                          <span class="input-group-btn">
                            <button
                              data-ng-click="transactionDivision.datePickerBeginOpened = !transactionDivision.datePickerBeginOpened"
                              type="button"
                              class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                              style="border:1px solid #062a42;">
                              <i class="icon icon-calendar" style="font-size: 21px;"></i>
                            </button>
                          </span>
                </div>
              </div>
                    <span class="c-red"
                          ng-show="transactionDivision.tDivForm.transactionDate.$error.required && transactionDivision.formSubmitted"
                          translate="orders.dateRequired"></span>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="amountInput"
                       translate="domesticPayment.amountInputLabel"></label>
                <div class="input-group">
                  <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                         ng-disabled="transactionDivision.apiLocation === 'sr' || transactionDivision.apiLocation === 'hr'"
                         placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask
                         maxlength="12" ng-model="transactionDivision.tempTransDivObj.amountInput" required
                         ng-class="{'domp-error': transactionDivision.tDivForm.amountInput.$invalid && transactionDivision.formSubmitted}"
                         ng-maxlength="11"/>
                          <span class="input-group-btn">
                            <button type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                                    ng-if="vm.countryCode != 'sr'" style="border:1px solid #062a42;">
                              {{transactionDivision.defaultCurrency}}
                            </button>
                          </span>
                </div>
                      <span class="c-red"
                            ng-if="transactionDivision.tDivForm.amountInput.$error.required && vm.formSubmitted"
                            translate="internalTransfer.amountInputError"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" ng-style="transactionDivision.apiLocation != 'hr' ?{'font-weight': 'bold' } : {}" for="numOfInstallment"
                       translate="transactionDivision.numOfInstallment"></label>
                <div class="d-flex">
                  <button ng-if="transactionDivision.apiLocation === 'hr'"
                          ng-click="transactionDivision.changeInstallment(-1)"
                          class="installment-control">
                    <span class="f-20 glyphicon-minus"></span>
                  </button>
                  <select ng-class="{'red-border': (transactionDivision.apiLocation === 'sr' && 
                                      transactionDivision.tempTransDivObj.installmentNumber === '-')}"
                          class="form-control input-field flex-1" id="numOfInstallment"
                          data-ng-model="transactionDivision.tempTransDivObj.installmentNumber">
                    <option ng-repeat="installment in transactionDivision.installmentRange" ng-value="installment">{{installment}}</option>
                  </select>
                  <button ng-if="transactionDivision.apiLocation === 'hr'"
                          ng-click="transactionDivision.changeInstallment(1)"
                          class="installment-control right">
                    <span class="f-20 glyphicon-plus"></span>
                  </button>
                </div>
              </div>
            </div>
            <div ng-if="transactionDivision.apiLocation !== 'hr'" class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="sellingPlace"
                       translate="transactionDivision.sellingPlace"></label>
                <input type="text" id="sellingPlace" name="sellingPlace" class="form-control input-field"
                       ng-disabled="transactionDivision.apiLocation === 'sr'"
                       ng-class="{'domp-error': transactionDivision.tDivForm.sellingPlace.$invalid && transactionDivision.formSubmitted}"
                       data-ng-model="transactionDivision.tempTransDivObj.transactionLocation" 
                       required>
                <div class="c-red"
                     ng-show="transactionDivision.tDivForm.sellingPlace.$error.required && transactionDivision.formSubmitted"
                     translate="transactionDivision.sellingPlaceError">
                </div>
              </div>
            </div>
          </div>
          <div ng-if="transactionDivision.apiLocation !== 'hr'" class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="domp-form-label-review" for="lastDigits"
                       translate="transactionDivision.lastDigits"></label>
                <input type="text" id="lastDigits" name="lastDigits" class="form-control input-field"
                       data-ng-model="transactionDivision.tempTransDivObj.cardNumber"
                       ng-class="{'domp-error': transactionDivision.tDivForm.lastDigits.$invalid && transactionDivision.formSubmitted}"
                       ng-pattern-restrict="{{transactionDivision.regexPatterns.onlyNumbers}}" ng-maxlength="4"
                       maxlength="4" ng-minlength="4" minlength="4" 
                       ng-disabled="transactionDivision.apiLocation === 'sr'"
                       required>
                <div class="c-red"
                     ng-show="transactionDivision.tDivForm.lastDigits.$error.required && transactionDivision.formSubmitted"
                     translate="transactionDivision.lastDigitsError">
                </div>
                <div class="c-red"
                     ng-show="transactionDivision.tDivForm.lastDigits.$error.minlength && transactionDivision.formSubmitted"
                     translate="transactionDivision.lastDigitsLengthError">
                </div>
              </div>
            </div>
            <!--</div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="row p-10">
      <div class="col-xs-6 t-a-l">
        <button class="btn btn-primary" type="button" translate="transactionDivision.cancel" data-ui-sref="dashboard">
        </button>
      </div>
      <div class="col-xs-6 t-a-r">
        <button class="btn btn-default pull-right" type="submit" translate="transactionDivision.confirm"
                ng-click="transactionDivision.confirm()"></button>
      </div>
    </div>

    <div ng-if="transactionDivision.apiLocation !== 'hr'"  class="col-sm-12">
      <!-- TIMELINE CONTAINER -->
      <div class="row p-r-10 p-l-10">
        <div class="col-sm-12 l-h-50 p-0" style="background-color: white;border-bottom: 2px solid #9eabd5;">
          <span translate="transactionDivision.yourTransDiv" class="bold-font f-17 m-l-20 ng-scope"></span>
        </div>
      </div>


      <div class="row p-r-10 p-l-10">
        <div class="col-sm-12 no-padding bg-white" style="padding-top:10px;">
          <br>
          <div class="dropdown acc-period-select col-xl-3 col-md-3 col-sm-3">
            <button class="btn btn-default dropdown-toggle p-l-30 p-r-30" type="button" data-toggle="dropdown">
              {{transactionDivision.selectedPeriod.item.description | translate}}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li data-ng-repeat="period in transactionDivision.periodList">
                <a href="" class="f-13" data-ng-click="transactionDivision.timelineTimeFilter(period);">
                  {{period.item.description | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="timeline-row col-sm-12 no-padding">
          <div class="t-a-c p-10 no-data-image"
               ng-if="transactionDivision.transactionDivisionGrouped.result.length == 0">
            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
            <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
          </div>
          <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0"
               ng-hide="transactionDivision.transactionDivisionGrouped.result.length == 0">
            <ul class="hypo-timeline" kcd-recompile="transactionDivision.transactionDivisionGrouped">
              <li ng-class="{'time-label': tDiv.isVirtual}"
                  ng-repeat="tDiv in transactionDivision.transactionDivisionGrouped.result">
                <span class="currency-time-frame"
                      ng-if="tDiv.isVirtual === true">{{tDiv.date | date:'shortDate' : '+0200'}}</span>
                <i class="icon icon-change currency-icon-change" ng-if="!tDiv.isVirtual"></i>
                <div ng-if="!tDiv.isVirtual" class="hypo-timeline-item payment-item"
                     ng-click="tDiv.selected = !tDiv.selected;" ng-class="{'opened': tDiv.selected}">
                  <div class="hypo-timeline-body">
                    <div class="col-xs-6">
                      <h4 class="m-b-0 m-t-0 c-gray">
                        <b>{{tDiv.amount | number:2}} BAM</b>
                        <b>, {{::tDiv.transactionLocation}}</b>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">
                        {{tDiv.modificationDate | date:'shortDate' : '+0200'}}
                      </p>
                      <p ng-show="currencyConversion.showPaymentId" class="m-b-0 m-t-0" id="paymentId">
                        {{::payment.id}}</p>
                    </div>
                    <div class="col-xs-6">
                      <span class="pull-right t-a-r m-t-10">
                      <b style="font-size:17px;">
                            <span>
                              {{'transactionDivision.' + tDiv.status | translate}}
                            </span>
                      </b>
                        </span>
                    </div>
                  </div>
                  <!-- TIMELINE ITEM DETAILS -->
                  <div ng-if="tDiv.selected" class="transaction-details">
                    <i class="icon icon-change currency-icon-change-two" ng-if="!tDiv.isVirtual"></i>
                    <table class="table table-transparent table-responsive"
                           style="font-size: 17px;color: #9e9e9e !important;font-family: CharlieMedium;">
                      <tbody>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.amount">Amount:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{tDiv.amount|number:2}} BAM</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.transLocation">Transaction location:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{::tDiv.transactionLocation}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.cardNumber">Card number:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{::tDiv.cardNumber}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.status">Status:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{ 'transactionDivision.' + tDiv.status | translate}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.installmentInfo">Status:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{tDiv.installmentNumber}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label" translate="transactionDivision.creationDate">Creation date:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{tDiv.creationDate| date:'shortDate' : '+0200'}}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="po-desc-label"
                                translate="transactionDivision.transactionDate">Transaction date:</span>
                        </td>
                        <td>
                          <span class="po-value-label">{{tDiv.valueDate| date:'shortDate' : '+0200'}}</span>
                        </td>
                      </tr>
                      <!-- ACTIONS -->
                      <tr>
                        <td colspan="2">
                          <div>
                            <span class="pull-right" ng-repeat="actionObject in tDiv.allowedActionList.itemList">
                              <span ng-switch on="actionObject.methodName">
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="payments.edit"
                                            ng-if="actionObject.isEnabled" ng-switch-when="UpdateAllowed"
                                            ng-click="transactionDivision.editFromTimeline(tDiv.id)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="currencyConversion.sign"
                                            ng-switch-when="SignAllowed"
                                            data-ng-click="transactionDivision.sign(tDiv.id)">
                                      Sign payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="payments.delete"
                                            ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed"
                                            ng-click="transactionDivision.delete(tDiv.id)">
                                      Delete payment
                                    </button>
                                  </span>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <!-- END ACTIONS -->
                      </tbody>
                    </table>
                  </div>
                  <!-- END TIMELINE ITEMS DETAILS-->
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="show-more-btn ">
          <button ng-hide="transactionDivision.transactionDivisionGrouped.hasMore == false"
                  data-ng-click="transactionDivision.loadMoreTimelineItems()" type="input"
                  class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                  translate="dashboard.loadEvents">Load older events
          </button>
        </div>
      </div>
      <!-- END TIMELINE -->
    </div>

  </div>
</form>


