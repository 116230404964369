(function () {
  'use strict';

  angular
      .module('localization')
      .config(config);

  function config($translateProvider) {

    var data = {
      alert: {
        information: "Informacija",
        areYouSure: "Jeste li sigurni?",
        blockCardInfo: "Da li želite blokirati karticu ?",
        blockCardCost: "It will cost you",
        blockCard: "Blokiraj",
        payment: "Nalog broj",
        template: "Uzorak broj",
        termDeposit: "Oročeni depozit",
        investment: "Investicija broj",
        subscriptionRequest: "Uspješno ste unijeli zahtjev za pretplatu",
        successSign: "Uspješno ste potpisali nalog",
        closeEBook: "Uspješno ste zatvorili eknjižicu",
        cancelRegistration: "Uspješno ste otkazali registraciju",
        ebookOrder: "eknjižica broj",
        archiveEInvoice: "Uspješno ste arhivirali e-račun",
        saveTemplate: "Uspješno ste pohranili šablon",
        updateTemplate: "Uspješno ste izmijenili šablon",
        deleteAction: "Brisanje",
        cancel: "Zatvori",
        yes: "Da",
        no: "Ne"
      }
    };

    $translateProvider
        .translations('mne', data);

  }
}());
