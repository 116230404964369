(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsSignCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsSignCtrl', PaymentsSignCtrl);

  function PaymentsSignCtrl($state, $log, $q, $timeout,
                            $window, $scope,
                            PaymentsService, PaymentSignListService, ConfigEndpointService,
                            $localStorage, ValidationService, HelperService,
                            $rootScope, $translate, $transitions, AlertService, NotificationService) {
    var vm = this;
    vm.signButtonDisabled = true;
    vm.groupedListOfPayments = undefined;
    vm._groupByCurrencySymbol = undefined;
    vm.splitedAccount = null;
    vm.coloredNumberAccount = null;
    vm.checkSplitAccount = false;
    vm.showChallenge = false;

    HelperService.initializeMTokenSignature.call(vm, $scope, function() {
      var state = $state.current.name
      if (state == 'payments.groupSign') {
        vm.signGroupPayments();
      } else if (state == 'payments.sign') {
        vm.signSinglePayment();
      }
    }, function() {
      if ($state.current.name == 'payments.groupSign') {
        vm.cancelGroupPayments();
      }
    })

    var showChallengeFields = function (paymentId) {
      var deferred = $q.defer();
      return PaymentsService.showChallengeFields(paymentId).then(function (showChallenge) {
        vm.showChallenge = showChallenge;
      }).catch(function (error) {
        $log.error(error);
      });

    };


    vm.init = function () {

      disableSMSButton();

      vm.countryCode = $localStorage.code;

      vm.objectForSignature = {};
      vm.qrChallenge = ''; // NOTE: Initial state added to avoid jumping screen from one state to another

      switch ($state.current.name) {
        case 'payments.sign':
          if (vm.countryCode === 'hr') {
            // showChallengeFields($state.params.paymentId).then(function () {
              loadPayment($state.params.paymentId)
                .then(function () {
                  if (vm.selectedPaymentForSign.challenge) {
                    loadSinglePaymentChallengesForSignature();
                  }
                  

                  vm.objectForSignature = vm.selectedPaymentForSign;
                  if (vm.selectedPaymentForSign.linkList && vm.selectedPaymentForSign.linkList.itemList.requestOTP) {
                    // requestSMSCode(vm.selectedPaymentForSign);
                    vm.sendSMS(vm.selectedPaymentForSign);
                  }
                });
            // });
          } else {
            loadPayment($state.params.paymentId)
              .then(function () {
                vm.objectForSignature = vm.selectedPaymentForSign;
                if (vm.selectedPaymentForSign.linkList && vm.selectedPaymentForSign.linkList.itemList.requestOTP) {
                  // requestSMSCode(vm.selectedPaymentForSign);
                  vm.sendSMS(vm.selectedPaymentForSign);
                }
              });
          }
          break;
        case 'payments.groupSign':
          loadGroupOfPayments($state.params.groupId)
            .then(function () {
              if (vm.countryCode == 'hr') {
                loadGroupPaymentChallengesForSignature();
              } 
              
              vm.objectForSignature = vm.paymentsGroupList;
              if (vm.paymentsGroupList.linkList && vm.paymentsGroupList.linkList.itemList.requestOTP) {
                // requestSMSCode(vm.paymentsGroupList);
                vm.sendSMS(vm.paymentsGroupList);
              }
            });
          break;
        default:
        // Unknown state
      }

      vm.smsPasswordInput = '';
      vm.challengeResponse = '';
      vm.challengeResponse2 = '';

      // Hide SMS/Challenge response input by default.
      vm.showInput = false;

      // If there is no country code, load it
      if (!vm.countryCode || vm.countryCode === '') {
        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
          vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          vm.countryCode = vm.defaultData.APILocation;
        }
      }

      //else {
      //  // If country code is not HR, send SMS (SRB and MNE)
      //  if (vm.countryCode && vm.countryCode !== 'hr' && vm.countryCode != 'bh') {
      //    requestSMSCode();
      //  }
      //}

      vm.formSubmitted = false;
      vm.paymentType = $state.params.type || 'other';
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    };



    var loadPayment = function (paymentId) {
      var deferred = $q.defer();
      if ($state.params.payment) {
        vm.isPaymentDueDateValid = true;
        vm.dueDateUpdateAllowed = true;
        vm.selectedPaymentForSign = $state.params.payment;
        if (vm.showChallenge) {
          vm.selectedPaymentForSign.last8digitsFromAccount = $state.params.payment.toAccountNumber.substr($state.params.payment.toAccountNumber.length - 5);
        }

        deferred.resolve(vm.selectedPaymentForSign);
      }
      if (paymentId) {
        return PaymentsService.getById(paymentId).then(function (payment) {

          if (vm.countryCode === 'hr' && payment.status === 'Signed') {
            $state.go('payments.finalStep', {paymentObject: payment, paymentId: payment.id, type: 'domestic'});
          }

          vm.selectedPaymentForSign = payment;

          if (vm.showChallenge) {
            var test = payment.toAccountNumber.split(' ');
            if (test) {
              vm.selectedPaymentForSign.last8digitsFromAccount = test[0].substr(test[0].length - 8);
              vm.coloredNumberAccount = vm.selectedPaymentForSign.last8digitsFromAccount;
              vm.splitedAccount = payment.toAccountNumber.split(vm.coloredNumberAccount);
              vm.checkSplitAccount = true;
              if (vm.selectedPaymentForSign.last8digitsFromAccount.length < 8) {
                vm.selectedPaymentForSign.last8digitsFromAccount = '0'.repeat(8 - vm.selectedPaymentForSign.last8digitsFromAccount.length) + vm.selectedPaymentForSign.last8digitsFromAccount;
              }
              vm.selectedPaymentForSign.last8digitsFromAccount = vm.selectedPaymentForSign.last8digitsFromAccount.replace(/\D/g, '0');
            } else {
              vm.selectedPaymentForSign.last8digitsFromAccount = payment.toAccountNumber.substr(payment.toAccountNumber.length - 8);
            }
          }

          if (vm.countryCode !== 'sr') {
            var updateObject = _.find(payment.allowedActionList.itemList, function (item) {
              return item.methodName === 'UpdateOldPaymentDateAllowed';
            });

            if (updateObject) {
              vm.dueDateUpdateAllowed = updateObject.isEnabled;
            }

            // Use only date without hours for compare
            var currentTime = new Date();
            currentTime.setHours(0, 0, 0, 0);
            // Check if all payments have valid due date
            vm.isPaymentDueDateValid = new Date(payment.dueDate) >= currentTime;
          } else {
            vm.isPaymentDueDateValid = true;
            vm.dueDateUpdateAllowed = true;
          }

        }).catch(function (error) {
          $log.error(error);
        });
      }
      return deferred.promise;
    };

    var loadSinglePaymentChallengesForSignature = function() {
      var challengeResponse = JSON.parse(vm.selectedPaymentForSign.challenge);
      vm.challengeFirstCode = challengeResponse.challange1;
      vm.challengeSecondCode = challengeResponse.challange2;

      if (vm.selectedPaymentForSign.qrChallenge) {
        vm.qrChallenge = vm.selectedPaymentForSign.qrChallenge;
        vm.showChallenge = false; // NOTE: This is canceled in order to prevent additionalData being sent on signSinglePayment() method
      } else {
        vm.qrChallenge = null;
        vm.showChallenge = true;
      }
    }

    var loadGroupPaymentChallengesForSignature = function() {
      var challengeResponse = JSON.parse(vm.paymentsGroupList.challenge);
      vm.challengeFirstCode = challengeResponse.challange1;
      vm.challengeSecondCode = challengeResponse.challange2;

      if (vm.paymentsGroupList.qrChallenge) {
        vm.qrChallenge = vm.paymentsGroupList.qrChallenge;
        vm.showChallenge = false; // NOTE: This is canceled in order to prevent additionalData being sent on signGroupPayments() method
      } else {
        vm.qrChallenge = null;
        vm.showChallenge = true; 
      }
    }

    var loadGroupOfPayments = function (paymentGroupId) {
      var deferred = $q.defer();
      if (paymentGroupId) {
        return PaymentSignListService.getSignListById(paymentGroupId).then(function (paymentGroup) {

          vm.paymentsGroupList = paymentGroup;

          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr') {
            var _groupByCurrencySymbolTemp = _.groupBy(vm.paymentsGroupList.paymentList, 'currencySymbol');
            vm._groupByCurrencySymbol = [];
            for (var key in _groupByCurrencySymbolTemp) {
              if (_groupByCurrencySymbolTemp.hasOwnProperty(key)) {
                var tempItem = _groupByCurrencySymbolTemp[key];
                var _totalAmount = 0;

                _.forEach(tempItem, function (_element) {
                    _totalAmount += _element.amount;
                  });

                tempItem[0].totalAmount = _totalAmount;
                vm._groupByCurrencySymbol.push(tempItem);
              }
            }
          }

          if (vm.countryCode !== 'sr') {
            var updateObject = _.find(vm.paymentsGroupList.allowedActionList.itemList, function (item) {
              return item.methodName === 'UpdateOldPaymentDatesAllowed';
            });

            if (updateObject) {
              vm.dueDateUpdateAllowed = updateObject.isEnabled;
            }

            // Use only date without hours for compare
            var currentTime = new Date();
            currentTime.setHours(0, 0, 0, 0);
            // Check if all payments have valid due date
            vm.areAllPaymentsDueDateValid = _.every(vm.paymentsGroupList.paymentList, function (payment) {
              return new Date(payment.dueDate) >= currentTime;
            });
          } else {
            vm.areAllPaymentsDueDateValid = true;
            vm.dueDateUpdateAllowed = true;
          }

        }).catch(function (err) {
          $log.error(err);
        });
      }

      return deferred.promise;
    };

    /*  Single payment */
    vm.updateSinglePaymentDate = function () {
      if (vm.selectedPaymentForSign) {
        // Sign newly created list of payments.
        return PaymentsService.updatePaymentDueDate(vm.selectedPaymentForSign.id).then(function (updatedPayment) {
          if (updatedPayment) {
            // Use only date without hours for compare
            var currentTime = new Date();
            currentTime.setHours(0, 0, 0, 0);
            // Check if all payments have valid due date
            vm.isPaymentDueDateValid = new Date(updatedPayment.dueDate) >= currentTime;
            vm.selectedPaymentForSign = updatedPayment;
            if (vm.countryCode == 'hr') {
              loadSinglePaymentChallengesForSignature();
            }
          }
        }).catch(function (error) {
          $log.error(error);
        });
      }
    };

    vm.signSinglePayment = function () {


      if (vm.selectedPaymentForSign) {
        vm.formSubmitted = true;

        // Sign newly created list of payments.
        var params = {
          id: vm.selectedPaymentForSign.id
        };

        // SMS for Srb/Mne
        if (vm.smsPasswordInput !== '') {
          params.signData = vm.smsPasswordInput;
        }
        // Challenge for CRO
        if (vm.challengeResponse !== '') {
          params.signData = vm.challengeResponse;
        }

        if (vm.showChallenge) {
          params.additionalSignData = vm.challengeResponse2;
        }


        if (vm.countryCode === 'sl') {
          params.payment = $state.params.payment;
          if (vm.smsPasswordInput) {
            params.payment.signData = vm.smsPasswordInput;
          } else
            params.payment.signData = '';
        }


        return PaymentsService.signPayment(params)
          .then(function (signedPayment) {

            var successSignPaymentCallback = function (payment) {
              var _signedPayment = payment.payment;
              if (vm.countryCode === 'sl') {
                _signedPayment = payment;
              }

              $state.go('payments.finalStep', {
                paymentId: _signedPayment.id,
                paymentObject: _signedPayment,
                type: vm.paymentType
              });
            };

            if (signedPayment.hsvpWarning) {
              AlertService.confirmationAlert({
                text: 'domesticPayment.hsvpWarning'
              }).then(function (accept) {
                if (!accept) {
                  vm.cancelGroupPayments();
                  return;
                }
                PaymentsService.signPayment(params).then(function (payment) {
                  successSignPaymentCallback(payment);
                });
              });
            } else if (signedPayment.cutoffWarning) {
              signLastPayment(params, signedPayment.nextWorkingDay);
            } else {
              successSignPaymentCallback(signedPayment);
            }
            // In SLO, Sign method is actually POST method
            // in other countries Sign method is PUT method and response is different from POST

            //$state.go('payments.finalStep', {paymentObject: signedPayment.payment || signedPayment || null, paymentId: vm.selectedPaymentForSign.id || null, type: vm.paymentType});
            // var checkObject = signedPayment.payment? signedPayment.payment : (signedPayment || null);
            // $state.go('payments.finalStep', {paymentObject: checkObject , paymentId: vm.selectedPaymentForSign.id || null, type: vm.paymentType});
          }).catch(function (error) {
            $log.error(error);

            // if (error && error.resultList[0] && error.resultList[0].key === 'CUTOFF') {
            //   signLastPayment(error.resultList[0].description, params);
            // } else 
            if (error && error.resultList[0] && error.resultList[0].key === 'UpdateOldDate') {
              openUpdateSinglePaymentDueDateModal(params);
            } else {
              NotificationService.showMessage(error, 'error');
            }

            // To prevent multiple requests
            vm.formSubmitted = false;
          });
      }
    };

    var signLastPayment = function (params, nextWorkingDate) {
      if (params) {
        PaymentsService.openUpdateCurrencyDateModal(nextWorkingDate)
          .result.then(function (result) {
            if (result === 'confirm') {
              params.cutoffChecked = true;
              return PaymentsService.signPayment(params).then(function (signedPayment) {
              // TODO: Uncomment after API fix - signed payment has no data currently
              // $state.go('payments.finalStep', {paymentId: signedPayment.payment.id, paymentObject: signedPayment.payment, type: vm.paymentType });
              $state.go('payments.finalStep', {paymentId: vm.selectedPaymentForSign.id, type: vm.paymentType});
            }).catch(function (error) {
              $log.error(error);
              NotificationService.showMessage(error, 'error');
            });
            } else {
              vm.formSubmitted = false;
            }
          });
      }
    };

    var openUpdateSinglePaymentDueDateModal = function (params) {
      if (params) {
        PaymentsService.openUpdatePaymentDueDateModal()
          .result.then(function (result) {
            if (result === 'confirm') {
              params.cutoffChecked = true;
              params.useCurrentDate = true;
              return PaymentsService.signPayment(params).then(function (signedPayment) {
              console.log(signedPayment);
              // TODO: Uncomment after API fix - signed payment has no data currently
              // $state.go('payments.finalStep', {paymentId: signedPayment.payment.id, paymentObject: signedPayment.payment, type: vm.paymentType });
              $state.go('payments.finalStep', {paymentId: vm.selectedPaymentForSign.id, type: vm.paymentType});
            }).catch(function (error) {
              $log.error(error);
              NotificationService.showMessage(error, 'error');
            });
            }
          });
      }
    };

    /* Group payments */
    vm.updateGroupPaymentDates = function () {
      if (vm.paymentsGroupList) {
        // Update due dates of payments
        return PaymentSignListService.updateGroupPaymentDates(vm.paymentsGroupList.id).then(function (updatedPayments) {
          vm.paymentsGroupList = updatedPayments;
          if (vm.countryCode == 'hr') {
            loadGroupPaymentChallengesForSignature();
          }
          // Use only date without hours for compare
          var currentTime = new Date();
          currentTime.setHours(0, 0, 0, 0);

          // Check if all payments have valid due date
          vm.areAllPaymentsDueDateValid = _.every(vm.paymentsGroupList.paymentList, function (payment) {
            return new Date(payment.dueDate) >= currentTime;
          });
        }).catch(function (error) {
          $log.error(error);
        });
      }
    };
    vm.cancelGroupPayments = function () {
      $state.go('payments.paymentsOverview', {myParam: 'not-signed'});
    };

    vm.signGroupPayments = function () {
      if (vm.paymentsGroupList) {
        vm.formSubmitted = true;

        var listForSign = {
          id: vm.paymentsGroupList.id,
          cutoffChecked: false,
          useCurrentDate: false
        };

        if (vm.smsPasswordInput !== '') {
          listForSign.signData = vm.smsPasswordInput;
        }
        if (vm.challengeResponse !== '') {
          listForSign.signData = vm.challengeResponse;
        }

        if (vm.showChallenge) {
          listForSign.additionalSignData = vm.challengeResponse2;
        }


        // Sign newly created list of payments.
        return PaymentSignListService.signAllPaymentsOnList(listForSign)
          .then(function (signedPayments) {
            if (signedPayments.hsvpWarning) {
              AlertService.confirmationAlert({
                text: 'domesticPayment.hsvpWarning'
              }).then(function (accept) {
                if (!accept) {
                  vm.cancelGroupPayments();
                  return;
                }
                PaymentSignListService.signAllPaymentsOnList(listForSign).then(function (payment) {
                  $state.go('payments.finalStep', { paymentId: vm.paymentsGroupList.id, type: 'group' });
                });
              });
            } else if (signedPayments.cutoffWarningCollectiveSignature) {
              signLastGroupOfPayments(listForSign, signedPayments.nextWorkingDay);
            } else {
              $state.go('payments.finalStep', { paymentId: vm.paymentsGroupList.id, type: 'group' });
            }
          }).catch(function (error) {
            // $log.error(error);
          //   if (error && error.resultList[0] && error.resultList[0].key === 'CUTOFF') {
          //   signLastGroupOfPayments(error.resultList[0].description, listForSign);
          // } else 
          if (error && error.resultList[0] && error.resultList[0].key === 'UpdateOldDate') {
            openUpdateGroupPaymentsDueDateModal(listForSign);
          } else {
            NotificationService.showMessage(error, 'error');
          }

            vm.formSubmitted = false;
          });
      }
    };

    var openUpdateGroupPaymentsDueDateModal = function (params) {
      if (params) {
        PaymentsService.openUpdatePaymentDueDateModal()
          .result.then(function (result) {
            if (result === 'confirm') {
              params.cutoffChecked = true;
              params.useCurrentDate = true;
              return PaymentSignListService.signAllPaymentsOnList(params).then(function (signedPayments) {
              $state.go('payments.finalStep', {paymentId: vm.paymentsGroupList.id, type: 'group'});
              console.log(signedPayments);
            }).catch(function (error) {
              NotificationService.showMessage(error, 'error');
              $log.error(error);
            });
            }
          });
      }
    };

    var signLastGroupOfPayments = function (params, nextWorkingDay) {
      if (params) {
        PaymentsService.openUpdateCurrencyDateModal(nextWorkingDay)
          .result.then(function (result) {
            if (result === 'confirm') {
              params.cutoffChecked = true;
              return PaymentSignListService.signAllPaymentsOnList(params).then(function (signedPayments) {
              $state.go('payments.finalStep', {paymentId: vm.paymentsGroupList.id, type: 'group'});
              console.log(signedPayments);
            }).catch(function (error) {
              NotificationService.showMessage(error, 'error');
              $log.error(error);
            });
            }
          });
      }
    };

    var requestSMSCode = function (payment) {
      // SMS is already sent, no need to send it again on controller init.
      // If user wants to request SMS again, there is button for that action.
      vm.sendSMS(payment);
    };

    vm.toggleInputVisibility = function () {
      vm.showInput = !vm.showInput;
      var smsInput = document.getElementById('smsInput');
      smsInput.focus();
    };

    vm.sendSMS = function (paymentObj) {
      disableSMSButton();
      var payment = paymentObj || vm.objectForSignature;
      return PaymentsService.requestSMSCode(payment.linkList.itemList.requestOTP)
        .then(function (code) {
          $window.sessionStorage.setItem('sentSMS', 'true');
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') {
            var message = $translate.instant('signPayments.SmsSentSuccessfully');
            NotificationService.showTranslatedMessage('success', message, '', 3000);
          }
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
          $log.error(error);
        });
    };

    vm.repeatSmsCounter = 0;
    vm.requestSMSCodeForSlo = function () {
      if (vm.resendSMSInProgress || vm.repeatSmsCounter === 3) return;
      vm.resendSMSInProgress = true;
      vm.repeatSmsCounter++;
      PaymentsService.insertPayment($state.params.payment)
        .then(function (response) {
        })
        .catch(function (response, error) {
          if (response && response.hasWarnings) {
            //show action message modal
            PaymentsService.showModalWithActions(response, objectForInsert);
          } else {
            NotificationService.showMessage(response, 'error');
          }
        })
        .finally(function () {
          vm.resendSMSInProgress = false;
        });
    };

    // Delete from storage information about sent SMS
    $transitions.onSuccess({}, function () {
      $window.sessionStorage.removeItem('sentSMS');
    });

    function disableSMSButton() {
      vm.signButtonDisabled = true;
      $timeout(function () {
        vm.signButtonDisabled = false;
      }, 30000);
    }

    vm.init();
  }
}());
