(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name employeePayroll.factory:EmployeePayrollService
   *
   * @description
   *
   */
  angular
    .module('employeePayroll')
    .factory('EmployeePayrollService', EmployeePayrollService);

  function EmployeePayrollService($http, $q, $log, EndpointsService, HelperService, NotificationService) {
    var EmployeePayrollServiceBase = {};

    EmployeePayrollServiceBase.getEmployeePayrolls = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.employeePayrolls, {
              params: params
            })
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data)
            });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    EmployeePayrollServiceBase.getEmployeePdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.employeePayrolls_downloadLink_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EmployeePayrollServiceBase.downloadEmployeePdf = function (id) {

      var nonBlockingWindow = window.open('', '_self');
      EmployeePayrollServiceBase.getEmployeePdfLink(id)
        .then(function (pdfLink) {
          //pdfLink = pdfLink.data;
          if (pdfLink) {
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = pdfLink.url;
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    return EmployeePayrollServiceBase;
  }
}());
