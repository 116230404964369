(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      eInvoicesModal: {
        "headerLabel": "Plačilo eRačuna",
        "instructionLabel": "Izberite datum in potrdilo plačilo",
        "issuerLabel": "Nalogodajalec",
        "purposeLabel": "Namen plačila",
        "amountLabel": "Znesek",
        "refNumberLabel": "Referenca",
        "paymentDateLabel": "Datum plačila",
        "today": "Današnji datum",
        "dueDate": "Rok plačila",
        "otherDate": "Drugi datum",
        "btnCancel": "Prekliči",
        "btnPay": "Plačaj eRačun",
        "subscribeOrCancel": "Prijava ali odjava prejemnja eRačunov",
        "submitted": "Oddane vloge",
        "showIssued": "Prikaži izdane eRačune ",
        "showAuthorizedEinvoices": "Prikaži eRačune na pooblaščenskih računih",
        "waitingForPaymentSlo": "Prejeti eRačuni",
        "payedAndRemoved": "Plačani in umaknjeni eRačuni",
        "loadMoreEinvoices":"Prikaži več",
        "loadMoreRegistrationsDeregistrations": "Prikaži več",
        "einvoicesSlo": "eRačuni in ostali eDokumenti",
        "view": "Prikaži",
        "pay": "Plačaj",
        "noData": "Za izbrano obdobje ni podatkov za prikaz",
        "fromAccountPayLabel": "Plačaj z računa: ",
        "availableAmountLabel": "Razpoložljivi znesek: ",
        "viewEinvoice": "Prikaži eRačun",
        "amountPlaceholder": "npr. 1.000,00",
        "btnSign": "Podpiši",
        "btnEdit": "Uredi",
        "signHeaderLabel": "Preverite in potrdite plačilo",
        "fromAccount": "Račun plačnika",
        "toAccount": "Račun prejemnika",
        "receiver": "Prejemnik",
        "receiverAddress": "Naslov prejemnika",
        "amount": "Znesek",
        "description": "Namen",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Naslov banke",
        "enterComment": "Vnesite opombo",
        "delete": "Izbriši",
        "edit": "Spremeni",
        "sign": "Podpiši",
        "listOfRegistrations":"Seznam oddanih vlog",
        "processedTabSlo": "Plačani in ostali eRačuni",
        "processed": "Obdelani",
        "archived": "Umaknjeni",
        "rejected": "Zavrnjeni",
        "noAvailableEInvoices": "Ni eRačunov za prikaz",
        "creditorNameSearch": "Iskanje po nazivu izdajatelja",
        "issuerIban":"Številka računa izdajatelja",
        "issuerAddress":"Naslov izdajatelja",
        "requestType":"Tip vloge",
        "gsm":"Vaša telefonska št.",
        "subscribe":"Prijava",
        "unsubscribe":"Odjava",
        "paymentNotPossible": "Plačilo ni možno",
        "paidByOtherMeans": "Plačan na drug način",
        "paymentWithDirectDebit": "Plačilo z direktno obremenitvijo",
        "withdrawn": "Umaknjeni",
        "inProgressByOther": "V obdelavi pri drugem uporabniku",
        "msg1":"Ste prepričani, da želite umakniti izabrani e-račun med plačane in ostale e-račune ?",
        "msg2":"Plačilo tega e-računa preko Addiko EBanke po umiku ne bo več mogoče. ",
        "cancel":"Prekliči",
        "confirm":"Potrdi",
        eDocumentType: {
          'eInvoice': 'eRačun',
          'eProforma':'ePredračun',
          'eWarning':'eOpomin',
          'eCreditNote':'eDobropis',
          'eDebitNote':'eBremepis',
          'ePurchaseOrder':'eNaročilnica',
          'eDeliveryNote':'eDobavnica'
        },
        rejectDocumentAlert: {
          headerText: 'Želite zavrniti izbrani dokument?',
          labelText: 'Prosimo na kratko pojasnite vzrok zavrnitve, ki bo sporočen izdajatelju dokumenta.'
        }
      },
      eInvoiceDetail: {
        "attachments": "Priloge",
        "eInvoicePDF": "eRačun v PDF formatu",
        "eInvoiceXML": "eRačun v XML formatu",
        "export": "Izvoz",
        "unsubscribeEInvoices": "Odjava prejemanja eRačunov",
        "detail": "Pregled podrobnosti eRačuna",
        "unpaidInvoice": "Neplačan eRačun",
        "pay": "Plačaj",
        "markAsPayed": "Označi kot plačano",
        "issuer": "Izdajatelj",
        "issuerAccount": "Račun izdajatelja",
        "purpose": "Namen",
        "refNumber": "Referenca",
        "amount": "Znesek",
        "issueDate": "Datum izdaje",
        "purposeCode": "Koda namena",
        "dueDate": "Rok plačila",
        "digitalSignature": "Status digitalnega podpisa",
        "visibilityDigitalSignature": "Informacije o digitalnem podpisu",
        "signatureId": "ID podpisa",
        "digitalCertNumber": "Št. digitalnega potrdila",
        "signatureName": "Naziv podpisa",
        "certificateIssuer": "Izdajatelj digitalnega potrdila",
        "showInfo": "Prikaži ",
        "hideInfo": "Skrij ",
        "recipient": "Prejemnik",
        "recipientAccount": "Račun prejemnika",
        "noAttachments": "eRačun nima prilog.",
        "noDocAttachments": "eDokument nima prilog.",
        "goBack": "Nazaj na seznam",
        "issueDateOfEinvoice":"Datum izdaje",
        "paymentDesc":"Namen plačila",
        "archive":"Arhiviraj",
        "showDigStatus":"Prikaži status digitalnega potrdila",
        "issuerIBAN":"IBAN izdajatelja",
        "placeOfReg":"Kraj prijave",
        "dateOfReg":"Datum prijave/Datum odjave",
        "cancelReg":"Preklic prijave",
        "cancel":"Prekliči",
        "regAgain":"Ponovno prijavi",
        "regAgainSlo":"Ponovno prijavi/odjavi",
        "rejectReason":"Razlog preklica",
        "inProcess":"V izvedbi",
        "rejected":"Zavrnjen",
        "registered":"Prijavljen",
        "unregistered":"Neregistriran",
        "activeRegs":"Aktivne prijave",
        "cancelledRegs":"Preklicane prijave",
        "noteArchived": "Razlog arhiviranja",
        "noteRejected": "Razlog zavrnitve",
        "serviceName": "Naziv storitve",
        "download": "Prenesi PDF",
        "orderType": "Tip naročila",
        "registration": "Prijava",
        "deregistration": "Odjava",
        "withdraw":"Umakni",
        "valid": "Veljaven",
        "invalid": "Neveljaven",
        "otherInfo": "Ostali podatki",
        "statusOfEApplication": "Status E-računa",
        "statusOfEApplicationOpomina": "Status E-opomina",
        "statusOfEApplicationPredracun": "Status E-predračuna",
        "paymentId": "ID plačila"
      },
      eInvoicesIssuersOverview: {
        "submittedApplications": "Oddane vloge za prijavo in odjavo",
        "header": "Izveri izdajatelja eRačunov",
        "issuerName": "Naziv izdajatelja",
        "issuerTaxId": "Davčna številka izdajatelja",
        "searchByNamePlaceholder": "Išči po nazivu",
        "searchByTaxIdPlaceholder": "Išči po davčni št.",
        "showMore": "Prikaži več izdajateljev",
        "subscribe": "Prijava",
        "issuerTax": "Davčna številka / Račun",
        "issuerAddress": "Naslov izdajatelja",
        "noData": "Za izbrano obdobje ni podatkov za prikaz"
      },
      eInvoicesSubscribe: {
        "goBack": "Nazaj",
        "subscribeToEinvoice": "Naroči se na prejemanje eRačunov",
        "einvoiceIssuer": "Izdajatelj eRačunov",
        "einvoiceIssuerSlo": "Davčna številka izdajatelja - Naziv izdajatelja",
        "issuerAddress": "Naslov izdajatelja",
        "issuerAccount": "Račun izdajatelja",
        "referenceFromIssuer": "ID referenca izdajatelja",
        "einvoiceReceiver": "Prejemnik eRačuna",
        "receiversName": "Naziv prejemnika",
        "receiversAddress": "Naslov prejemnika",
        "receiversTaxId": "Davčna št. prejemnika",
        "yourContactEmail": "Vaš email",
        "tooltipInfo": "Potrebujete pomoč?",
        "tooltipInfoEmail": "Vneseni email se bo uporabljal samo pri prijavi prejemanja eRačunov.",
        "einvoiceReferenceFromIssuerInputError": "Vnos reference je obvezen!",
        "einvoiceSubscribeEmailInputError": "Vnos emaila je obvezen!",
        "reviewandSubmit": "Preverite podatke in potrdite",
        "changeEmail": "Spremeni email",
        "updateEmail": "Spremeni",
        "cancel": "Prekliči",
        "ok": "V redu",
        "statement":"IZJAVA",
        "termsAndConditionsSlo": "Z elektronsko oddajo vloge v Addiko EBanki potrjujem, da sem seznanjen s Splošnimi pogoji poslovanja.",
        "statementTitle":"Splošni pogoji banke ",
        "statementText":"Sprejemam ",
        "statementTextLink1":"Splošne pogoje za vodenje transakcijskega računa in opravljanje plačilnih storitev ",
        "statementTextAnd":"in ",
        "statementTextLink2":"Splošne pogoje za uporabo storitve spletne banke Addiko EBank",
        "statementTextEnd":"ki veljajo in se uporabljajo za storitev e-računi.",
        "specialConditions": "Strinjam se s pogoji izdajatelja",
        "newRegistration": "Vloga za prijavo/odjavo prejemanja eRačunov",
        "selectIssuer":"Izberite izdajatelja",
        "registrationNew":"Nova prijava/odjava",
        "generalTerms":"Strinjam se s pogoji uporabe",
        "register":"Potrdi",
        "applicationComplete": "Uspešna oddana vloga",
        "applicationCompleteTextSlo": "Uspešno ste oddali vlogo za prijavo oz. odjavo prejemanja eRačunov izdajatelja {{name}}.",
        "goToList": "Nazaj na seznam",
        "goToListSlo": "Nazaj na seznam Pregled oddanih vlog",
        "einvoiceIssuerInputError": "Vnos izdajatelja je obvezen!",
        "referenceExplanation":"Check issuers explanations of entering reference",
        "statementTitle2":"Splošni pogoji izdajatelja",
        "statementText2":"Pogoji sodelovanja so objavljeni na naših spletnih straneh"
      },
      eInvoiceRegistration: {
        "all": "Vsi",
        listOfRegistrations: "Pregled oddanih vlog",
        "unregistered": "Odjavljena",
        "Unregistered": "Odjavljena",
        "rejected": "Zavrnjena",
        "Rejected": "Zavrnjena",
        "rejectedSlo": "Zavrnjena",
        "registered": "Prijavljena",
        "Registered": "Prijavljena",
        "inProcess": "Vložena",
        "InProcess":"Vložena",
        "inProcessSlo": "Vložena",
        "sent":"Poslana",
        "Sent":"Poslana",
        "undelivered":"Nedostavljena",
        "Undelivered":"Nedostavljena",
        "Delivered":"Dostavljena",
        "delivered":"Dostavljena"
      },
      eInvoiceOverview:{
        "listOfEinvoices":"Seznam eRačunov",
        "eInvoicesToPay":"eRačuni za plačilo",
        "register":"Prijava/odjava na eRačun",
        "pay":"Plačaj",
        "success":"Uspešno!",
        "archivedEinvoces":"Arhivirani eRačuni",
        "successfulyArchived": "Uspješno ste arhivirali eRačun",
        "copy": "Kopiraj plačilni nalog",
        "inProgress": "V obdelavi",
        "documentsTab": "eDokumenti",
        "umakni": "Umakni",
        "reject": "Zavrni"
      },
      eInvoicePayment: {
        "recAccount":"Račun prejemnika",
        "purpose":"Namen",
        "accNumber":"Številka računa",
        "toPayee":"Prejemnik",
        "recAddress":"Naslov prejemnika",
        "recPostalCity":"Poštna številka",
        "instantDateError": "If payment is tagged as instant, only today's date is allowed as payment date.",
      },
      eInvoiceStatus:{
        "InProcess":"Vložen",
        "Unregistered":"Odjavljen",
        "Registered":"Prijavljen",
        "Rejected":"Zavrnjen",
        "Undelivered":"Nedostavljen",
        "Delivered":"Dostavljen",
        "Sent":"Vložen",
        "InProgress": "V obdelavi",
        "Inserted":"Prejet",
        "Processed": "Obdelan",
        "Archived": "Umaknjen",
        "PaymentNotPossible": "Plačilo ni možno",
        "PaidByOtherMeans": "Plačan na drug način",
        "PaymentWithDirectDebit": "Plačilo z direktno obremenitvijo/trajnikom",
        "Withdrawn": "Umaknjen",
        "InProgressByOther": "V obdelavi pri drugem uporabniku",
        "Reviewed": "Pregledan",
        "Cancelled": "Plačilo preklicano"
      }
    };

    $translateProvider
      .translations('sl', data);
  }
}());
