(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:MasterCardOrderLimitCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('MasterCardOrderLimitCtrl', MasterCardOrderLimitCtrl);

  function MasterCardOrderLimitCtrl($q, $state, $translate, AlertService, HelperService, AccountsService, UserSettingsService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'MasterCardOrderLimitCtrl';

    vm.step = 1;

    vm.iAmAware = false;
    vm.selectedAccount = {
      currencySymbol: 'EUR'
    };


    vm.cardTypes = [
      {id: 'basic',description:'masterCardOrder.basic'},
      {id: 'additional',description:'masterCardOrder.additional'}
    ];

    vm.selectedAccount.cardType = vm.cardTypes[0];

    vm.tempObj = {
      dateFrom: new Date(),
      dateTo: new Date()
    };
    vm.dateOptions = {
      dateFrom: {
        minDate: new Date()
      },
      dateTo: {
        //
      }
    };

    vm.tempObj = {
      cardAccount: ''
    };

    var selectCardbyId = function (id) {
      var card = _.find(vm.selectedAccount.cardList, function (item) {
        return item.cardId == id;
      });
      if(card)
        vm.selectedAccount.selectedCard = card;
      else
        vm.selectedAccount.selectedCard = vm.selectedAccount.cardList[0];

    };

    function loadPerceontOfLimitTypes() {
      var params = null;
      return AccountsService.creditCardLimitOrderPercentOfLimitType(params)
        .then(function (response) {
          vm.limits = response.result;
          vm.tempObj.limit = vm.limits[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }
    function loadLimitTypeTypes() {
      var params = null;
      return AccountsService.creditCardLimitOrderLimitTypeType(params)
        .then(function (response) {
          vm.limitTypes = response.result;
          vm.tempObj.limitType = vm.limitTypes[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    var init = function () {
      var params = {
        includeList: ['CardAccount','cardList','ParentAccount'],
        isOwn: true
      };
      var promises = [
        AccountsService.getAccountById($state.params.accountId,params),
        UserSettingsService.getPage({pageSize: 1000}),
        loadPerceontOfLimitTypes(),
        loadLimitTypeTypes()
      ];

      $q.all(promises).then(function(responses){
        vm.selectedAccount = responses[0];
        selectCardbyId($state.params.cardId);

        vm.selectedAccount.cardType = vm.cardTypes[vm.selectedAccount.selectedCard.cardType]; // 0 for basic , 1 for additional

        vm.tempObj.cardAccount = vm.selectedAccount.accountNumber + " " + vm.selectedAccount.friendlyName;

          vm.userSettings = responses[1].result[0];
      }).catch(function(error){
        NotificationService.showMessage(error, 'error');
      });
    };

    function createObject () {

      var tempObj = {
        "amount": vm.tempObj.amount,
        "cardNumber": vm.selectedAccount.selectedCard.cardNumber,
        "cardType": vm.selectedAccount.selectedCard.cardType,
        "limitType": {
          "id": vm.tempObj.limitType.id,
          "description": vm.tempObj.limitType.description
        },
        "emailAddress": vm.userSettings.email,
        "note": " ",
        "accountId": vm.selectedAccount.accountId,
        "limitTypeTempDateFrom": new Date(),
        "limitTypeTempDateTo": new Date()
      };

      if(vm.selectedAccount.cardType.id == 'additional'){
        tempObj.percentOfLimit =  {
          "id": vm.tempObj.limit.id,
          "description": vm.tempObj.limit.description
        };
      }

      if(vm.tempObj.limitType.id == 'Temporary'){
        tempObj.limitTypeTempDateFrom = vm.tempObj.dateFrom;
        tempObj.limitTypeTempDateTo = vm.tempObj.dateTo;
      }

      return tempObj;
    }

    vm.validate = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if(!vm.masterCardOrderForm.$valid){
        return;
      }
      vm.insertedOrderObject = createObject();
      vm.nextButtonPressed = true;
      AccountsService.validateCreditCardLimitOrder(vm.insertedOrderObject)
        .then(function (response) {
          vm.step = 2;
          vm.insertedOrderObject = angular.extend(response);
          // amount missing in response
          vm.insertedOrderObject.amount = vm.tempObj.amount;
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.nextButtonPressed = false;
        });
    };
    vm.confirm = function () {
      if (vm.confirmButtonPressed) return;
      vm.confirmButtonPressed = true;
      AccountsService.creditCardLimitOrder(vm.insertedOrderObject)
        .then(function (response) {
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              vm.goBackToAccount();
            });
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.confirmButtonPressed = true;
        });
    };
    vm.edit = function () {
      vm.step = 1;
      vm.iAmAware = false;
    };

    vm.goBackToAccount = function () {
      $state.go('accounts', {
        accountId: $state.params.accountId
      });
    };

    init();
  }
}());
