<section id="main">
  <div class="container">
    <div>
      <div class="exchange-rate-header">
        <div class="row">
          <div class="col-sm-6 col-xs-12 f-28" translate="paymentSignModal.title"></div>

        </div>
      </div>

      <!-- Larger screens start -->
      <!-- Header for payments sign -->
      <div class="row group-sign-header p-t-20  m-t-15 hidden-xs">
        <div class="col-sm-5">
                    <span class="col-sm-6 group-sign-header" translate="payments.fromAccount">
              From account
            </span>
          <span class="col-sm-6" translate="payments.toAccount">
              To account
            </span>
        </div>
        <span class="col-sm-2" translate="loanPayment.purpose">
            Purpose
          </span>
        <span class="col-sm-2 " translate="domesticPayment.amountInputLabel">
            Amount
          </span>
        <div class="col-sm-3">
                    <span ng-if="paymentsSign.selectedPaymentForSign.dateCreated" class="col-sm-6"
                          translate="signPayments.dateCreated">
            Date created
            </span>
          <span class="col-sm-6" translate="signPayments.dueDate">
              Due date
            </span>
        </div>
      </div>

      <!-- Details for single payment -->
      <div class="row group-sign-content hidden-xs p-b-15 word-break">

        <div ng-if="!paymentsSign.showChallenge" class="col-sm-5">
          <span class="col-sm-6">
            {{paymentsSign.selectedPaymentForSign.fromAccountNumber}}
          </span>
          <span class="col-sm-6">
            {{paymentsSign.selectedPaymentForSign.toAccountNumber}}
          </span>
        </div>

        <div ng-if="paymentsSign.showChallenge">
          <span class="col-sm-2">
            {{paymentsSign.selectedPaymentForSign.fromAccountNumber}}
          </span>
          <span ng-if="!paymentsSign.checkSplitAccount" class="col-sm-3">
            <p>{{paymentsSign.selectedPaymentForSign.toAccountNumber.substr(0,paymentsSign.selectedPaymentForSign.toAccountNumber.length -8 )}}<span style="background-color: lightskyblue">{{paymentsSign.selectedPaymentForSign.toAccountNumber.substr(paymentsSign.selectedPaymentForSign.toAccountNumber.length -8)}}</span></p>
          </span>
          <span ng-if="paymentsSign.checkSplitAccount" class="col-sm-3">
            <p>{{paymentsSign.splitedAccount[0]}}<span style="background-color: lightskyblue">{{paymentsSign.coloredNumberAccount}}</span>{{paymentsSign.splitedAccount[1]}}</p>
          </span>
        </div>

        <span class="col-sm-2" title="{{payment.purpose}}">
            {{ paymentsSign.selectedPaymentForSign.purpose | limitTo: 40 }}{{paymentsSign.selectedPaymentForSign.purpose.length > 40 ? '...' : ''}}
        </span>
        <span class="col-sm-2">
            {{paymentsSign.selectedPaymentForSign.amount|number:2}} {{::paymentsSign.selectedPaymentForSign.currencySymbol}}
        </span>

        <div class="col-sm-3">
                    <span ng-if="paymentsSign.selectedPaymentForSign.dateCreated" class="col-sm-6">
            {{paymentsSign.selectedPaymentForSign.dateCreated| date:'shortDate' : '+0200'}}
            </span>
          <span class="col-sm-6">
              {{paymentsSign.selectedPaymentForSign.dueDate| date:'shortDate' : '+0200'}}
            </span>
        </div>
      </div>
      <!-- Larger screens end -->

      <!-- Small screens start -->
      <div class="row visible-xs sign-payment-mobile">

        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="payments.fromAccount">
            From account
          </span>
          <span class="col-xs-6">
            {{paymentsSign.selectedPaymentForSign.fromAccountNumber}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="payments.toAccount">
            To account
          </span>
          <span class="col-xs-6">
            {{paymentsSign.selectedPaymentForSign.toAccountNumber}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="loanPayment.purpose">
            Purpose
          </span>
          <span class="col-xs-6" title="{{payment.purpose}}">
            {{ paymentsSign.selectedPaymentForSign.purpose | limitTo: 40 }}{{paymentsSign.selectedPaymentForSign.purpose.length > 40 ? '...' : ''}}
          </span>
        </div>
        <div class="col-xs-12">
          <span class="col-xs-6 f-w-bold" translate="domesticPayment.amountInputLabel">
            Amount
          </span>
          <span class="col-xs-6">
            {{paymentsSign.selectedPaymentForSign.amount|number:2}} {{::paymentsSign.selectedPaymentForSign.currencySymbol}}
          </span>
        </div>
        <div class="col-xs-12">
          <span ng-if="paymentsSign.selectedPaymentForSign.dateCreated" class="col-xs-6 f-w-bold"
                translate="signPayments.dateCreated">
            Date created
          </span>
          <span class="col-xs-6">
            {{paymentsSign.selectedPaymentForSign.dateCreated| date:'shortDate' : '+0200'}}
          </span>
        </div>
        <div class="col-xs-12">
                    <span class="col-xs-6 f-w-bold" translate="signPayments.dueDate">
              Due date
            </span>
          <span class="col-xs-6">
              {{paymentsSign.selectedPaymentForSign.dueDate| date:'shortDate' : '+0200'}}
            </span>
        </div>
      </div>
      <!-- Small screens end -->

      <!-- Actions for single payment -->
      <div class="row m-b-10">
        <span class="col-xs-12 m-t-20" ng-show="!paymentsSign.isPaymentDueDateValid"
              translate="signPayments.singlePaymentInvalidDueDate"></span>
        <span class="col-xs-12 payments-filter-active"
              ng-show="paymentsSign.dueDateUpdateAllowed && !paymentsSign.isPaymentDueDateValid"
              data-ng-click="paymentsSign.updateSinglePaymentDate()" translate="signPayments.updatePaymentDueDate">
          </span>

        <!-- SMS Validation -->
        <div class="col-xs-12 m-t-30" ng-if="paymentsSign.selectedPaymentForSign.linkList.itemList.requestOTP">
          <!--<form id="nform1" autocomplete="off" novalidate>-->
            <div class="col-xs-12 m-b-20">
              <span ng-if="paymentsSign.countryCode != 'bh' && paymentsSign.countryCode != 'sl'" class="f-w-bold">
                {{'signPayments.SmsDescription' | translate}}
              </span>
              <span ng-if="paymentsSign.countryCode === 'bh'" class="f-w-bold">
                {{'signPayments.SmsDescriptionBh' | translate}}
              </span>
              <span ng-if="paymentsSign.countryCode === 'sl'" class="f-w-bold">
                {{'signPayments.SmsDescriptionSLO' | translate}}
              </span>
            </div>
            <div class="row p-10">
              <div ng-if="paymentsSign.countryCode === 'mne'" class="col-xs-12 m-5">
                <p><span translate="signPayments.infoDomMne0"></span>
                  <span><a href="{{'signPayments.infoDomMneLink' | translate}}"
                           translate="signPayments.infoDomMne1"></a></span></p>
              </div>
              <div ng-if="paymentsSign.countryCode === 'sr'" class="col-xs-12 m-5">
                <p><span translate="signPayments.infoDomSrb0"></span>
                  <span><a href="{{'signPayments.infoDomSrbLink' | translate}}"
                           translate="signPayments.infoDomSrb1"></a></span></p>
              </div>
            </div>
            <div class="col-xs-6 col-sm-4">
              <div class="form-group">
                <span class="f-w-bold">{{'signPayments.SmsCode' | translate}}</span>
                <div class="input-group">
                  <input id="nedim1" name="nedim1" type="text"
                         class="form-control input-field text-uppercase"
                         data-ng-model="paymentsSign.smsPasswordInput"
                         placeholder="{{'currencyConversion.smsPassword' | translate}}"
                         autocomplete="off"
                         auto-focus>
                  <span class="input-group-btn">
                    <!--<button id="smsInput" class="btn btn-default input-group-app-btn domp-btn-height"-->
                    <!--data-ng-click="paymentsSign.toggleInputVisibility()">-->
                    <!--<i data-ng-show="paymentsSign.showInput" class="zmdi zmdi-lock"></i>-->
                    <!--<i data-ng-show="!paymentsSign.showInput" class="zmdi zmdi-lock-open"></i>-->
                    <!--</button>-->
                  </span>
                  <span class="input-group-btn" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                    <button class="btn btn-default input-group-app-btn domp-btn-height"
                            data-ng-click="paymentsSign.sendSMS()"
                            ng-disabled="paymentsSign.signButtonDisabled"
                            uib-tooltip="{{'signPayments.resendSms' | translate}}">
                      <i class="zmdi zmdi-refresh-sync"></i>
                    </button>
                  </span>
                  <span class="input-group-btn" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.additionalTransactionConfirmation.id == 1">
                    <button class="btn btn-default input-group-app-btn domp-btn-height"
                            data-ng-click="paymentsSign.requestSMSCodeForSlo()"
                            ng-disabled="paymentsSign.resendSMSInProgress || paymentsSign.repeatSmsCounter == 3"
                            uib-tooltip="{{'signPayments.resendSmsSlo' | translate}}" tooltip-class="tooltip-fullwidth">
                      <i class="zmdi zmdi-refresh-sync"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-sm-offset-4  m-t-20">
              <!-- TODO: Refactor ng-disabled statement -->
              <button id="signConfirm" class="btn btn-default pull-right" data-ng-click="paymentsSign.signSinglePayment()"
                      ng-disabled="paymentsSign.formSubmitted ||!paymentsSign.isPaymentDueDateValid || paymentsSign.smsPasswordInput === ''"
                      translate="signPayments.confirm"></button>
            </div>
          <!--</form>-->
        </div>

        <!-- Challenge response validation -->
        <div ng-if="!paymentsSign.selectedPaymentForSign.linkList.itemList.requestOTP && !paymentsSign.selectedPaymentForSign.linkList.itemList.requestMiniToken"
          class="col-xs-12 m-t-30">
            <addiko-mtoken-sign
              show-cancel="false"
              placeholder="paymentsSign.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
              qr-challenge="paymentsSign.qrChallenge" show-challenge="paymentsSign.showChallenge" 
              challenge1="paymentsSign.challengeFirstCode" challenge2="paymentsSign.challengeSecondCode"
              action-disabled="!paymentsSign.isPaymentDueDateValid" challenge-input="paymentsSign.challengeResponse"
              second-challenge-input="paymentsSign.last8digitsFromAccountResponse"></addiko-mtoken-sign>
        </div>


        <!-- MiniToken validation (FOR NOW ONLY BIH ENVIRONMENT) -->
        <div ng-if="paymentsSign.selectedPaymentForSign.linkList.itemList.requestMiniToken" class="col-xs-12 m-t-30">
          <!--<form id="miniTokenForm" autocomplete="off" novalidate>-->
            <div class="col-xs-12 m-b-20">
              <span class="f-w-bold">{{'signPayments.OtpDescriptionBh' | translate}}</span>
            </div>
            <div class="col-xs-6 col-md-6">
              <div class="form-group">
                <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.otpLabel' |
                  translate}}
                </div>
                <input id="spahaMT" name="spahaMT" type="text" class="form-control input-field"
                       data-ng-model="paymentsSign.challengeResponse"
                       ng-pattern-restrict="{{paymentsSign.regexPatterns.onlyNumbers}}"
                       placeholder="{{'signPayments.challengeSecondCodeBh' | translate}}" autocomplete="off" auto-focus>
              </div>
            </div>
            <div class="col-sm-4 col-xs-12 m-t-20 pull-right">
              <button id="signConfirm" class="btn btn-default col-sm-8 col-xs-4 pull-right"
                      data-ng-click="paymentsSign.signSinglePayment()"
                      ng-disabled="paymentsSign.formSubmitted || !paymentsSign.isPaymentDueDateValid "
                      translate="signPayments.confirm"></button>
            </div>
          <!--</form>-->
        </div>

      </div>
    </div>
  </div>
</section>
