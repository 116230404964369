<div class="tml-filter-container" style="overflow: hidden;" ng-class="{'tmlf-wide': dashboard.searchInputExpanded}" id="searchFilterExpandedDiv"
     click-outside="dashboard.searchFilterExpanded = false;">
    <div id="searchBtnLeftDiv" class="inner-section left">
        <i class="icon icon-search  icon-hc-2x m-l-5"></i>
    </div>
    <div id="searchBtnCenterDiv" class="inner-section center">
        <input id="searchTimelineInput" type="text" class="search-input"
               placeholder="{{'timelineFilter.placeholder'|translate}}"
               ng-model="dashboard.timelineSearchFilter.descriptionFTS" ng-model-options="{ debounce: 300 }"
              ng-change="dashboard.timelineSearchAction();">
    </div>
    <div ng-show="dashboard.searchInputExpanded" id="searchBtnRightDiv" class="inner-section right pull-right">
      <i ng-show="dashboard.timelineSearchFilter.descriptionFTS" class="zmdi zmdi-tag-close pull-left zmdi-hc-2x m-r-0"
         ng-click="dashboard.timelineSearchFilter.descriptionFTS = ''; dashboard.timelineSearchAction();">
      </i>
      <i class="icon icon-filter icon-hc-2x i-r-0 pull-right"
         ng-click="dashboard.searchFilterExpanded = !dashboard.searchFilterExpanded"></i>
    </div>
    <div class="tml-filter-selection" ng-class="{'opened': dashboard.searchFilterExpanded}">
        <div class="tml-filter-type-selection-wrapper">
            <div ng-class="{'selected': dashboard.timelineItemTypeSelected === 1}" class="tmlf-type-item" ng-click="dashboard.selectTimelineItemType('all')" translate="timelineFilter.all"></div>
            <div ng-class="{'selected': dashboard.timelineItemTypeSelected === 2}" class="tmlf-type-item" ng-click="dashboard.selectTimelineItemType('credit')" translate="timelineFilter.credit"></div>
            <div ng-class="{'selected': dashboard.timelineItemTypeSelected === 3}" class="tmlf-type-item" ng-click="dashboard.selectTimelineItemType('debit')" translate="timelineFilter.debit"></div>
            <div ng-class="{'selected': dashboard.timelineItemTypeSelected === 4}" class="tmlf-type-item" style="border-right:none !important" ng-click="dashboard.selectTimelineItemType('others')" translate="timelineFilter.others"></div>
        </div>
        <div class="m-t-15" style="overflow:auto">
            <div class="col-xs-5 p-l-5 p-r-5">
                <div class="form-group">
                    <label class="f-12-s" translate="timelineFilter.amountFrom"></label>
                    <input ng-keyup="$event.keyCode == 13 && dashboard.applyTimelineFilter()" type="text" class="form-control-db" ng-model="dashboard.timelineFilter.amountGTE" amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" autocomplete="off">
                </div>
            </div>
            <div class="col-xs-5 p-l-0 p-r-5">
                <div class="form-group">
                    <label class="f-12-s" translate="timelineFilter.amountTo"></label>
                    <input ng-keyup="$event.keyCode == 13 && dashboard.applyTimelineFilter()" type="text" class="form-control-db" ng-model="dashboard.timelineFilter.amountLTE" amount-input-mask placeholder="{{'accounts.accEg' | translate}}: 000.000,00" maxlength="12" autocomplete="off">
                </div>
            </div>
            <div class="col-xs-2 p-l-0 p-r-5 p-t-25" >
                <select name="" id="" class="form-control-db" ng-options="option.symbol for option in dashboard.currenciesList" ng-model="dashboard.currencySelectObject">
                </select>
            </div>
        </div>
        <div class="m-b-15" style="overflow:auto">
            <div class="col-xs-6 t-a-l p-l-5">
                <button class="btn btn-primary app-btn-gray" ng-click="dashboard.timelineItemTypeSelected = 1; dashboard.resetTimelineFilter();dashboard.searchFilterExpanded = false;dashboard.applyTimelineFilter();" translate="timelineFilter.reset"></button>
            </div>
            <div class="col-xs-6 t-a-r p-r-5">
                <button class="btn btn-default app-btn-blue w-100" ng-click="dashboard.applyTimelineFilter();" translate="timelineFilter.apply"></button>
            </div>
        </div>
    </div>
</div>
