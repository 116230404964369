(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name accounts.factory:AccountLimitOrderEffectiveTypeService
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .factory('AccountLimitOrderEffectiveTypeService', AccountLimitOrderEffectiveTypeService);

    function AccountLimitOrderEffectiveTypeService($q, $http, EndpointsService, $log) {
        var AccountLimitOrderEffectiveTypeServiceBase = {};
        AccountLimitOrderEffectiveTypeServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("AccountLimitOrderEffectiveTypeServiceBase.getPage:", params);
                $http.get(response.links.accountLimitOrderEffectiveType, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
        return AccountLimitOrderEffectiveTypeServiceBase;
    }
}());