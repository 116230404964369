(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            orders: {
                "accounts": "Računi",
                "cards": "Kartice",
                "savingsDeposits": "Varčevanja in depoziti",
                "security": "Varnost",
                "loans": "Krediti",
                "payments": "Plačila",
                accountOverdraft: "Sprememba dovoljenega limita",
                largeAmount: "Dvig višjega zneska gotovine",
                atmWithdrawal: "Sprememba dnevnega limita na bankomatu in POS terminalu",
                "orderMastercard": "Naročilo plačilne kartice Mastercard",
                "changeMastercard": "Sprememba limita na kartici Mastercard",
                "makeTermDeposit": "Skleni depozit",
                "cancelAutomatic": "Preklic avtomatskega podaljševanja",
                "openSavings": "Odpri varčevalni račun",
                "orderSoftToken": "Naročilo programskega generatorja gesel",
                "getExpressLoan": "Informacija o možnosti pridobitve EXPRESS kredita",
                "authorizationOrders": "Pooblastilo za trajni nalog",
                "cancelAuthorizationOrders": "Ukinitev pooblastila za trajni nalog",
                "goBackToOrders": "Nazaj na naložbe",
                "subscribeEInvoice": "Naročilo na prejemenje eRačunov ali odjava od prejemanja",
                "title": "Trajni nalog",
                "dateBegin": "Datum začetka",
                "dateCancel": "Datum poteka",
                "note": "Opomba",
                "note1": "",
                "note2": "",
                "note3": "",
                "dateRequired": "Vnos datuma je obvezen!",
                "note1Slo": "Trajni nalog za prenos sredstev na poljubni transakcijski račun ali imetniški varčevalni račun uporabnika Addiko EBanke oziroma varčevalni račun, na katerem je imetnik pooblaščen, morate naročiti najmanj 15 dni pred želenim datumom bremenitve. Addiko Bank d.d. vam bo provizijo za izvajanje plačil preko trajnih nalogov zaračunala po vsakokrat veljavnem ",
                "note2SloLink": "Ceniku storitev Addiko Bank d.d.",
                "beneficiaryName": "Naziv prejemnika",
                "beneficiaryNameRequired": "Vnos prejemnika je obvezen!",
                "beneficiaryIBAN": "Račun prejemnika",
                "beneficiaryIBANforSavings":"Varčevalni račun uporabnika Addiko EBanke",
                "description": "Namen",
                "standingOrders": "Trajni nalogi",
                "cancel": "Prekliči",
                "descriptionRequired": "Vnos namena je obvezen!",
                "titleConfirmation": "Potrditev trajnega naloga",
                "edit": "Uredi",
                "completeText": "Naročilo je bilo uspešno oddano.",
                "completeCancelText": "Naročilo je bilo uspešno oddano",
                "makeNew": "Naroči nov trajni nalog",
                "Opened": "Oddan",
                "Signed": "Podpisan",
                "paymentDayOfEachMonth": "Plačilo vsak mesec na dan",
                "nonWorkingDayMsg": "oziroma naslednji delovni dan, če je na ta dan sobota, nedelja, praznik ali drug dela prost dan.",
                "address": "Naslov prejemnika",
                "receviersAddressRequired": "Naslov prejemnika je obvezen!",
                "purpose": "Namen",
                "next": "Naprej",
                "back": "Nazaj",
                "maturityDateChangedConfirm": "Prosimo vas, da z izbiro potrditvenega polja potrdite spremembo datuma.",
                "maturityDateChangedErr": "Datum zapadlosti pade na nedelovni dan, zato je bil spremenjen. Prosimo, potrdite spremembo.",
                "maturityDateNonWorkingErr": "Datum zapadlosti pade na nedelovni dan.",
                "successOrder": "Naročilo je bilo uspešno oddano.",
                "agreeTerms":"Strinjam se, da v primeru, če na dogovorjeni dan ne bom zagotovil/a zadostnega kritja na računu, banka ni obvezana izvršiti nakazila po tem pooblastilu.",
                "cancelAuthorisation": "Ukinitev pooblastila za trajni nalog",
                "permanentAthorisation": "Pooblastilo za trajni nalog",
                "Submitted": "Oddan",
                "Closed": "Zaprt",
                "InProcess": "V obdelavi",
                "Canceled": "Preklican",
                "Executed": "Izvršen",
                "Rejected": "Zavrnjen",
                "InError": "Z napako",
                "PartialySigned": "Delno podpisan",
                "ForProcessing": "Pripravljen za obdelavo",
                "FailureAfterComplaint": "Zavrnjen po pritožbi",
                "SuccessAfterComplaint": "Izvršen po pritožbi",
                types: {
                  "trr":"Transakcijski račun prejemnika",
                  "hypo":"Varčevalni račun uporabnika Addiko EBanke"
                }
            },
            termDeposit: {
                "termDepositHeader": "Vezava depozita",
                "nameInputLabel": "Ime in priimek",
                "addressInputLabel": "Naslov",
                "cityInputLabel": "Kraj",
                "debitAccountSelectLabel": "Z računa",
                "typeSelectLabel": "Vrsta depozita",
                "interestRateOverviewLabel": "Prikaz obrestnih mer",
                "realRate": "Realna obrestna mera",
                "referenceRate": "Referenčna obrestna mera",
                "amountInputLabel": "Znesek",
                "baseInterestRateInputLabel": "Osnovna stopnja obrestne mere",
                "marginInputLabel": "Pribitek",
                "stimulativeInterestRateInputLabel": "Stimulativna stopnja obrestne mere ",
                "totalInterestRateInputLabel": "Skupna obrestna mera",
                "maturityInMonthsSelectLabel": "Rok v mesecih",
                "expiryDateInputLabel": "Datum poteka",
                "interestRatePayoutTypeSelectLabel": "Način izplačila obresti",
                "informativeCalculationLinkLabel": "Izračun efektivne obrestne mere",
                "automaticProlongationRadioOptionLabel": "Automatsko podalševanje",
                "automaticProlongationYesOptionLabel": "Da",
                "automaticProlongationNoOptionLabel": "Ne",
                "accountForMaturityInputLabel": "Račun za dospele obresti in glavnico:",
                "contractDateInputLabel": "Datum pogodbe",
                "contractDraftLinkLabel": "Depozitna pogodba",
                "generalConditionsLinkLabel1": "Potrjujem, da sem seznanjen/a s Splošnimi pogoji depozitov in z njimi soglašam.",
                "generalConditionsLinkLabel2": " ",
                "confirmBtnLabel": "Potrdi",
                "cancelBtnLabel": "Prekliči",
                "editActionLabel": "Spremeni",
                "cancelActionLabel": "Prekliči",
                "signActionLabel": "Podpiši",
                "successMsg": "Uspešno!",
                "errorMsg": "Napaka!",
                "minAmountLabel": "Minimalni znesek je",
                "month": "mesecev",
                "oneMonth": "mesec",
                "signTitle": "Podpis",
                "request": "zahteva",
                "newDepositOrder": "Skleni nov depozit",
                "assignmentOfInterestAnnual": "Letno poročanje",
                "assignmentOfInterestAfter": "Poročanje po poteku",
                "interestPayoutMonthly": "Mesečno",
                "interestPayoutAfterMaturity": "Po poteku",
                "earlyTermination": "Predčasna ukinitev depozita",
                "depositType": "Tip depozita",
                "assignmentOfInterest": "Razmejitev obresti",
                "letnaAssignmentOfInterest": "Letna razmejitev obresti",
                "interestPayout": "Izplačilo obresti",
                "termDepositDuration": "Doba vezave ",
                "durationInDays": "dni",
                "durationOr": "ali",
                "durationInMonths": " mesecev",
                "maturityDate": "Datum zapadlosti",
                "executionDate": "Datum  obdelave",
                "minDepDurationErr": "Najmanjša doba vezave je ",
                "maxDepDurationErr": "Doba vezave je lahko največ ",
                "minRastociDepositDuration": "Najmanjša doba vezave je 31 dni.",
                "maxRastociDepositDuration": "Doba vezave je lahko največ 35 dni.",
                "durationRastociDepozitMsg": "Doba mora biti med 31 in 35 dni.",
                "durationZlataJesenMsg": "Doba mora biti med {{min}} and {{max}} dni.",
                "days": "dni.",
                "termsAndConditions": "Potrjujem, da sem seznanjen/a s  ",
                "termsAndConditions1": "Splošnimi pogoji depozitov",
                "termsAndConditions2": "in z njimi soglašam. Seznanjen/a sem tudi s tipom in trenutnimi pogoji obrestnih mer za izbrani produkt.",
                "taxationOfInterestTitle": "Obdavčitev obresti",
                "taxationOfInterestMsg": "Skladno z določbami Zakona o dohodnini in Zakona o davčnem postopku se v davčno osnovo za obračun dohodnine vštevajo obresti, ki letno presegajo skupni znesek 1.000 EUR. Pri dolgoročnih depozitih banka priporoča, da se odločite za letno poročanje obresti, saj se s tem zmanjša možnost, da ob izteku varčevanja znesek obresti preseže 1.000 EUR. Pri sklenitvi pogodbe o dolgoročnem depozitu se izdela tudi Obvestilo o uveljavljanju davčne osnove po 84. členu ZDoh-2.",
                "headerLink1": "Osnovne informacije o depozitih",
                "headerLink2": "Veljavne obrestne mere",
                "headerLink3": "Jamstva za vloge",
                "wrongDepositCurrency": "Depozita izbranega tipa ni mogoče vezati v tej valuti.",
                "wrongDepositDuration": "Vneseno obdobje ni mogoče pri izbranem depozitu.",
                "wrongDepositAmount": "Vneseni znesek ni dovoljen pri izbranem depozitu.",
                "wrongMinDepositAmount": "Minimalni znesek je ",
                "wrongMaxDepositAmount": "Najvišji znesek je ",
                "makeTermDepositTimelineTitle": "On-line sklenitev",
                "vrstaDepozita": "Vrsta depozita",
              "termDepositStatus": {
                "entered": "Oddan",
                "realised": "Izveden",
                "unrealised": "Neizveden",
                "cancelled": "Preklican",
                "receivednotexecuted":"V izvedbi"
              }
            },
            cancelDeposit: {
                "cancelDepositHeader": "Depozit",
                "cancelDepositSubheader": "Prekinitev avtomatskega podaljševanja sklenjenih depozitov",
                "capitalAmountLabel": "Znesek glavnice:",
                "approvedInteresLabel": "Priznane obresti:",
                "approvedInteresAmountLabel": "Priznane obresti v znesku:",
                "chargesLabel": "Strošek predčasne prejkinitve depozita:",
                "totalAmountLabel": "Skupni znesek za prenos:",
                "accountForFundLabel": "Račun za izplačilo:",
                "confirmBtn": "Potrdi",
                "cancelBtn": "Prekliči",
                "successInfoLabel1": "Naročilo za predčasno prekinitev depozita",
                "successInfoLabel2": "je bilo uspešno oddano",
                "requestBtn": "Pregled oddanih naročil",
            },
            depositDetails: {
                "contractDate": "Datum sklenitve pogodbe",
                "depositType": "Tip depozita",
                "account": "Račun za izplačilo",
                "note": "Opomba",
                "contract": "Pogodba",
                "payoutType": "Način izplačila",
                "domesticType": "Depozit v evrih",
                "foreignType": "Depozit v tuji valuti",
                "termsAndConditions": "Splošni pogoji"

            },
            orderDetails: {
                "name": "Naziv prejemnika",
                "payeeAccount": "IBAN prejemnika",
                "payeeAddress": "Kraj prejemnika",
                "amount": "Znesek",
                "orderId": "ID naročila",
                "orderTypeId": "ID vrsta naročila",
                "orderTypeDesc": "Opis naročila",
                "dateCreated": "Datum vnosa",
                "firstPaymentDate": "Datum prvega plačila",
                "lastPaymentDate": "Datum zadnjega plačila",
                "close": "Zapri",
                "closeMsg": "Nalog uspešno oddan",
                "purpose": "Namen"
            },
            savingsOrder: {
                "title": "Sklenitev varčevanja",
                "headerLink1": "Osnovne informacije o Addiko varčevalnem računu",
                "headerLink2": "Osnovne informacije o Addiko Plus Fix varčevanjih",
                "headerLink3": "Veljavne obrestne mere",
                "headerLink4": "Jamstva za vloge",
                "headerLink5": "Splošni pogoji Addiko varčevalnega računa",
                "firstPayment": "Prvo plačilo ",
                "lastPayment": "Zadnje plačilo",
                "orderPaymentDay": "Dan plačila trajnega naloga",
                "savingsType": "Vrsta varčevanja",
                "duration": "Doba varčevanja",
                "minSavingDuration": "Najmanjša doba varčevanja je ",
                "maxSavingDuration": "Najdaljša doba varčevanja je ",
                "fromAccount": "Z računa",
                "amount": "Mesečni polog",
                "orderpaymentDay": "Dan plačila trajnega naloga",
                "termsAndConditions": "Potrjujem, da sem seznanjen/a s",
                "termsAndConditions1": " Splošni pogoji varčevanj",
                "termsAndConditions2": " in z njimi soglašam. Seznanjen/a sem tudi s tipom in trenutnimi pogoji obrestnih mer za izbrani produkt.",
                "termsAndConditions3AddikoPlus": " Prav tako se strinjam, da v primeru, da na dogovorjeni dan ne bom zagotovil/a zadostnega kritja na računu, banka ni obvezana izvršiti nakazila po tem pooblastilu za trajni nalog.",
              "newSavingsOrder": "Sklenitev varčevanja",
                "initialDepositAmount": "Prvi polog",
                "monthlyDepositAmount": "Mesečni polog",
              "taxationOfInterestMsg": "Skladno z določbami Zakona o dohodnini in Zakona o davčnem postopku se v davčno osnovo za obračun dohodnine vštevajo obresti, ki letno presegajo skupni znesek 1.000 EUR. Pri dolgoročnih verčevanjih banka priporoča, da se odločite za letno poročanje obresti, saj se s tem zmanjša možnost, da ob izteku varčevanja znesek obresti preseže 1.000 EUR. Pri sklenitvi pogodbe o dolgoročnem varčevanju se izdela tudi Obvestilo o uveljavljanju davčne osnove po 84. členu ZDoh-2.",
                "newSavingsOrderTimelineTitle": "Sklenitev varčevanja",
              "dateOfEntry": "Datum vnosa",
              "executionDate": "Datum zapadlosti",
              "userNumber": "Številka uporabnika",
              "savingsCurrency": "Valuta varčevanja",
              "fromSAccount": "Številka računa",
              "nameAndSurname": "Ime in priimek",
              "initialAmount": "Prvi polog",
              "disbursementAccount": "Račun sprostitve sredstev",
              "receiveMonthlyStatementsVia": "Pošiljanje izpiskov",
              "vpoType": "VPO tip",
              "typeOfSaving": "Oznaka varčevanja",
              "realInterestRate": "Realna obrestna mera",
              "referenceInterestRate": "Referenčna obrestna mera",
              "currency": "Valuta",
              "dateOfOpening": "Datum sklenitve varčevanja",
              "openStandingOrder": "Otvoritev trajnega naloga",
              "orderComment": "Opomba naročila",
              "savingOrderName": "Namen varčevenja"
            },
            addNewExpressLoan: {
                "header": "Informacija o možnosti pridobitve EXPRESS kredita",
                "header_SLO": "Informacija o možnosti pridobitve Addiko kredita",
                "note1": "EXPRESS kredit je gotovinski kredit brez dodatne dokumentacije. Banka bo v naslednjih dveh delovnih dneh obravnavala vaše naročilo. Kredit bo v primeru odobritve nakazan na vaš račun po obisku pri osebnem bančniku v vaši poslovalnici, kjer boste podpisali kreditno pogodbo.",
                "note2": "Pogoji za najem EXPRESS kredita so na voljo v vsakokrat veljavnem",
                "note1_SLO": "Addiko kredit je gotovinski kredit brez dodatne dokumentacije. Banka bo v naslednjih dveh delovnih dneh obravnavala vaše naročilo. Kredit bo v primeru odobritve nakazan na vaš račun po obisku pri osebnem bančniku v vaši poslovalnici, kjer boste podpisali kreditno pogodbo.",
                "note2_SLO": "Pogoji za najem Addiko kredita so na voljo v vsakokrat veljavnem",
                "noteLink1": "Ceniku storitev Addiko bank d.d.",
                "noteAnd": "ter v",
                "noteLink2": "Obrestnih merah Addiko bank d.d.",
                "cardType": "Doba odplačila (v mesecih)",
                "account": "Račun",
                "amount": "Znesek",
                "paymentDay": "Želeni dan odplačevanja",
                "cancel": "Prekliči",
                "confirm": "Potrdi",
                "approvalDate": "Želeni datum odobritve",
                "paymentAccount": "Račun za plačilo",
                "addNewExpressLoan": "Nov kredit"
            }
        };

        $translateProvider
            .translations('sl', data);
    }
} ());
