(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name documents.factory:DocumentsService
   *
   * @description
   *
   */
  angular
    .module('documents')
    .factory('DocumentsService', DocumentsService);

  function DocumentsService($rootScope, $http, $q, $log, ConfigService, EndpointsService, HelperService) {
    var DocumentsServiceBase = {};

    var country = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

    var endpointsMapByCountry = {
      'sl': 'documents',
      'hr': 'documentation'
    }

    var currentEndpoint = endpointsMapByCountry[country];

    DocumentsServiceBase.getDocuments = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

          if (country == 'hr') {
            params.dtCreatedGTE = params.dateGTE;
            params.dtCreatedLTE = params.dateLTE;
            params.orderBy = 'dtSent desc';
            delete params.dateGTE; 
            delete params.dateLTE; 
          }
          $http.get(response.links[currentEndpoint], {
            params: params
          })
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data)
            });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    DocumentsServiceBase.downloadDocument = function(id, documentName) {
      sessionStorage.setItem('downloadInProgress', 'download');
      return $q(function(resolve, reject) {
        var url = ConfigService.url + 'documentation/downloadByFilter?id=' + id + '&documentType=DocumentRequest'
        HelperService.manageDownload(url, documentName, null, function(error) {
          if (error) {
            return reject(error);
          }
          resolve(true);
        });
      });
    }

    DocumentsServiceBase.getDocumentPdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links[currentEndpoint + "_downloadLink_id"].replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DocumentsServiceBase.downloadDocumentPdf= function (id) {
      var nonBlockingWindow = window.open('', '_self');
      return $q(function(resolve, reject) {
        DocumentsServiceBase.getDocumentPdfLink(id)
          .then(function (pdfLink) {
            //pdfLink = pdfLink.data;

            if (pdfLink) {
              // If we want to open link in same tab (immediate download)
              // window.location.href = pdfLink.url;
              // Opening in new empty tab
              // flagToMarkDownload
              sessionStorage.setItem('downloadInProgress', 'download');
              nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
            }
            resolve("success");
          })
          .catch(function (error) {
            nonBlockingWindow.close();
            reject();
            // NotificationService.showMessage(error, 'error');
            $log.debug(error);
          });
        })
    };

    return DocumentsServiceBase;
  }
}());
