<form name="loanPaymentForm" novalidate>
  <div class="domp-to-section">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
          <label class="d-block domp-form-label-resume">{{loanPayment.paymentResponse.fromAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
              <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel">Amount</label>
            <label class="d-block domp-form-label-resume">
              {{loanPayment.paymentResponse.amount | currency:""}}
              {{loanPayment.paymentResponse.currencySymbol}}
            </label>
          </div>
          <!--<div class="col-sm-6">-->
            <!--<label class="domp-form-label-review" translate="domesticPayment.bankCharges">Bank charges</label>-->
              <!--<a ng-href="{{loanPayment.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume">Check the bank charges</a>-->
          <!--</div>-->
        </div>
        <div class="row">
          <div class="col-sm-12 p-t-10">
            <label class="domp-form-label-review" translate="loanPayment.when">When</label>
            <label class="d-block domp-form-label-resume">{{loanPayment.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="domp-to-section m-t-10">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.toAccount">To account</label>
          <label class="d-block domp-form-label-resume">{{loanPayment.paymentResponse.toAccountNumber }}</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="domp-form-label-review"  translate="loanPayment.referenceNumber">Reference number</label>
          <label class="d-block domp-form-label-resume">
            {{loanPayment.tempLoanObj.receiverNumberCountryCode}} {{loanPayment.tempLoanObj.receiverNumber1}} {{loanPayment.tempLoanObj.receiverNumber2}}
          </label>
        </div>
      </div>
    </div>
    <!--<div class="row">
     <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="exchange-resume-label"  translate="loanPayment.exchangeRate">Exchange rate</label>
          <label class="d-block exchange-resume-val">no info</label>
        </div>
      </div>
    </div>-->
  </div>

  <!-- SMS auth -->
<!--  <div class="domp-to-section m-t-10">
    <div class="row">
      <div class="col-sm-6 col-xs-12" ng-if="loanPayment.smsAuthentificate != true">
        <div class="form-group">
          <label class="exchange-resume-label" translate="currencyConversion.smsAuthentification"></label>
          <button class="btn btn-default confirm-btn d-block" translate="currencyConversion.sendSms"
                  data-ng-click="loanPayment.showSMSAuthentification();"></button>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12" ng-if="loanPayment.smsAuthentificate == true">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control input-field"
                   data-ng-model="loanPayment.smsPasswordInput"
                   placeholder="{{'currencyConversion.smsPassword' | translate}}">
          <span class="input-group-btn">
            <button class="btn btn-default input-group-app-btn domp-btn-height">
              <i class="zmdi zmdi-lock"></i>
            </button>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="m-t-10">
    <div class="row">
      <div class="col-xs-12 col-sm-9 hidden-xs">
        <button class="btn btn-primary " translate="currencyConversion.back"
                ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                data-ng-click="loanPayment.backToLoanPayment();">
        </button>
        <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-primary" translate="payments.edit"
                data-ng-click="loanPayment.editPayment();">
        </button>
        <button ng-if="loanPayment.countryCode === 'sl'" class="btn btn-primary" translate="domesticPayment.back"
                data-ng-click="loanPayment.editPayment();">
        </button>
        <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-primary-warning" translate="payments.delete"
                data-ng-click="loanPayment.deletePayment();">
        </button>
      </div>

      <div class="col-xs-12 col-sm-9 visible-xs">
        <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-default col-xs-12 m-b-5"
                translate="currencyConversion.sign"
                data-ui-sref="payments.sign({payment: loanPayment.paymentResponse, paymentId: loanPayment.paymentResponse.id, type: 'loan'})">
        </button>
        <button ng-if="loanPayment.countryCode === 'sl'" class="btn btn-default col-xs-12 m-b-5"
                translate="currencyConversion.sign"
                ng-disabled="loanPayment.confirmButtonPressed"
                ng-click="loanPayment.sign()">

        </button>

        <button class="btn btn-primary col-xs-12 m-b-5" translate="currencyConversion.back"
                ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                data-ng-click="loanPayment.backToLoanPayment();">
        </button>
        <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-primary col-xs-6 " translate="payments.edit"
                data-ng-click="loanPayment.editPayment();">
        </button>
        <button ng-if="loanPayment.countryCode === 'sl'" class="btn btn-primary col-xs-6 " translate="domesticPayment.back"
                data-ng-click="loanPayment.editPayment();">
        </button>
        <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-primary-warning col-xs-6 " translate="payments.delete"
                data-ng-click="loanPayment.deletePayment();">
        </button>
      </div>

      <div class="col-xs-6 col-sm-3 col-xs-6 hidden-xs">
        <div class="pull-right">
<!--          <button class="btn btn-default confirm-btn"
                  translate="currencyConversion.sign"
                  ng-disabled="!loanPaymentForm.$dirty && loanPayment.smsAuthentificate == false"
                  data-ng-click="loanPayment.signPayment();">
          </button>-->
          <button ng-if="loanPayment.countryCode != 'sl'" class="btn btn-default"
                  translate="currencyConversion.sign"
                  data-ui-sref="payments.sign({payment: loanPayment.paymentResponse, paymentId: loanPayment.paymentResponse.id, type: 'loan'})">
          </button>
          <button ng-if="loanPayment.countryCode === 'sl'" class="btn btn-default"
                  ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation"
                  ng-disabled="loanPayment.confirmButtonPressed"
                  ng-click="loanPayment.sign()">
            <span ng-switch-when="sl" translate="domesticPayment.confirmBtn"></span>
            <span ng-switch-default translate="currencyConversion.sign"></span>
          </button>
        </div>

      </div>
    </div>
  </div>

</form>

