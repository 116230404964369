(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name accounts.controller:AccountsCtrl
   *
   * @description
   *
   */
  angular.module("core").controller("MainController", MainController);

  function MainController(
    $rootScope,
    $q,
    UserSettingsService,
    ConfigEndpointService,
    AccountsService,
    AlertService,
    SettingsService,
    $log,
    ViberService,
    EndpointsService,
    HelperService,
    $uibModal,
    CampaignOfferService,
    $translate,
    ConfigService,
    SessionService,
    $state,    
    $scope,
    $window,
    $transitions,
    NotificationService
  ) {
    var vm = this;
    $rootScope.APP_GLOBAL_SETTINGS = { counter: {} };

    // Constants
    var MILISECONDS_UNTIL_AUTO_LOGOUT = ConfigService.logoutTokenTimeout; // in mins
    var REFRESH_TOKEN_TIMEOUT = ConfigService.refreshTokenTimeout; // in mins
    var CHECK_INTERVAL = 1000; // in ms
    var SETTING_onbeforeunload = ConfigService.onBeforeLoadOn; // in mins

    // Flags
    var f5WasPressed = false;
    var previousToken = null;
    vm.isAuthenticated = false;
    vm.isSessionExpired = false;
    // Helpers
    var refreshTokenTimer = null;
    vm.lastAction = Date.now();
    vm.countryCode = {};
    vm.currentLang = "";
    $scope.currentState = '';
    $rootScope.currentState = '';
    $scope.$on('$routeChangeSuccess', function(event, currentRoute, previousRoute) {
      console.log('Current state changed from ' + currentRoute + ' to ' + previousRoute);
    });
    $rootScope.$on('$stateChangeSuccess', function(event, currentRoute, previousRoute) {
      console.log('Current state changed from ' + currentRoute + ' to ' + previousRoute);
    });
    function initiateCampaignCheck() {
      vm.answeredPopUpCampaignsEng = JSON.parse(
        sessionStorage.getItem("answeredPopUpCampaignsEng")
      );
      if (vm.answeredPopUpCampaignsEng === null) {
        vm.answeredPopUpCampaignsEng = [];
      }

      vm.answeredPopUpCampaignsLocal = JSON.parse(
        sessionStorage.getItem("answeredPopUpCampaignsLocal")
      );
      if (vm.answeredPopUpCampaignsLocal === null) {
        vm.answeredPopUpCampaignsLocal = [];
      }
    }

    function initRefreshTokenTimeout() {
      refreshTokenTimer = setTimeout(function () {
        SessionService.getRefreshToken(function (token) {
          previousToken = token;
          clearTimeout(refreshTokenTimer);
          initRefreshTokenTimeout();
        });
      }, REFRESH_TOKEN_TIMEOUT);
    }

    function executeInitialLogic(defaultData, userSettings) {
      vm.loadingStarted = true;
      if (defaultData.APILocation === 'sl') {
        // Initiate refresh token logic if initial API call is OK for SLO specific environment
        previousToken = $window.sessionStorage.getItem('ngStorage-ct_accessToken')
        var hasRefreshed = $window.sessionStorage.getItem('refreshed');
        if (hasRefreshed) {
          SessionService.getRefreshToken(function (token) {
            previousToken = token;
            initRefreshTokenTimeout();
            $window.sessionStorage.removeItem('refreshed');
          });
        } else {
          initRefreshTokenTimeout();
        }
      }

      vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      if (vm.countryCode == 'hr') {
        $transitions.onFinish({}, function (trans) {
          var toState = trans.targetState();
          var newRoute = toState._definition.self.url;
          try {
            if (window['candice']) {
              if (newRoute.includes('login')) {
                // Do nothing for now
              } else if(newRoute.includes('restriction')){
                window['candice']('change_details_retail');
              } else if (newRoute.includes('/new-ebook') || newRoute.includes('/term-deposit') || newRoute.includes('/sepa-refusal') && 
                         newRoute.includes('term-deposit') || newRoute.includes('/e-invoices/register') || newRoute.includes('/international-payment') &&
                        newRoute.includes('/internal-transfer') || newRoute.includes('/domestic-payment') ) {
                window['candice']('transaction_retail');
              } else if (newRoute.includes('/dashboard')) {
                window['candice']('main_account_retail');
              } else {
                window['candice']('other_retail');
              }
            }
          } catch (error) { }
        });
      }
      
      // Check for CRO environment only
      if (defaultData.APILocation === 'hr') {
        AccountsService.getStatementDeliveryWarning().then(function (response) {
          if (response && response.showWarning) {
            AlertService.statementDeliveryWarning().then(function (modalResponse) {
              var statementDeliveryModel = null;
              if (modalResponse != false) {
                statementDeliveryModel = modalResponse.value;
              }
              if (statementDeliveryModel != null) {
                AccountsService.processStatementDeliveryResponse(statementDeliveryModel).then(function (response) { });
              }
            });
          }
        });
      }
      
      // Check if password change is requested for SRB environment only
      if (defaultData.APILocation === "sr" || defaultData.APILocation === 'bh' || defaultData.APILocation === 'mne' || defaultData.APILocation === 'mn') {
        if (userSettings.isPasswordChangeRequested) {
          HelperService.isPasswordChangeRequested = true;
          AlertService.infoAlert({
            text: "settings.forceNewPassword",
            btnKey: "OK",
          }).then(function () {
            $state.go("settings");
          });
        } else if (userSettings.isAuthChangeNeeded) {
          AlertService.infoAlert({
            text: "settings.passwordWillExpire",
            btnKey: "OK",
          }).then();
        } 
      }
      //check if user activated Viber only for BiH environment
      if (defaultData.APILocation !== "sl") {
        ViberService.checkActivation()
          .then(function (response) {
            $rootScope.APP_GLOBAL_SETTINGS.isViberActivated =
              response.isViberUser;

            $rootScope.APP_GLOBAL_SETTINGS.viberData = {
              phoneNumber: response.phoneNumber,
              activationDate: response.activationDate,
            };

            vm.checkAccountsForVirtualizationMessage();
            if (
              !(
                defaultData.APILocation === "sr" ||
                defaultData.APILocation === "mne" ||
                defaultData.APILocation === "bh"
              )
            ) {
              vm.eSavingBookContractWarning();
              vm.checkSecuritySettingsRestrictions();
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, "error");
          });
      }
      
      vm.initInterval();
      CampaignOfferService.getOffer()
        .then(function (response) {
          if (!(response.data && response.data.hasErrors)) {
            vm.currentLang = $translate.proposedLanguage() || $translate.use();
            if (
              response[0] &&
              response[0] !== [] &&
              response[0].result &&
              response[0].result.length > 0 &&
              vm.currentLang === "en"
            ) {
              response[0].currentLang = "en";
              response[0].result = response[0].result.filter(function (
                campaign
              ) {
                return campaign.templateType === "Popup";
              });
              response[0] = filterAnsweredCampaigns(
                response[0],
                vm.answeredPopUpCampaignsEng
              );
              if (response[0].result.length > 0) {
                var sorted = response[0].result;
                sorted.sort(function (a, b) {
                  return b.priority - a.priority;
                });
                vm.openCampaignOfferPopup(sorted[0]);
              }
            }

            if (
              response[1] &&
              response[1] !== [] &&
              response[1].result &&
              response[1].result.length > 0 &&
              vm.currentLang !== "en"
            ) {
              response[1].result = response[1].result.filter(function (
                campaign
              ) {
                return campaign.templateType === "Popup";
              });
              response[1] = filterAnsweredCampaigns(
                response[1],
                vm.answeredPopUpCampaignsLocal
              );
              if (response[1].result.length > 0) {
                var sorted = response[1].result;
                sorted.sort(function (a, b) {
                  return b.priority - a.priority;
                });
                vm.openCampaignOfferPopup(sorted[0]);
              }
            }
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        });
    }

    function onInit() {
      // Initiate logic for campaigns
      initiateCampaignCheck();

      // Set listener of multisession error
      $rootScope.$on("onSessionExpired", function (evt) {
        AlertService.infoAlert({
          text: "session.expired.message",
        }).then(function () {
          vm.isSessionExpired = true;
        });
      });
      var country = ConfigService.country;
      if (HelperService.detectIEEdge() && country === 'sl') {
        AlertService.infoAlert({
          text: "session.expired.wrongBrowser",
          link: 'https://www.addiko.si/obcani/e-bancnistvo/addiko-ebank/tehnicne-zahteve/'
        }).then(function () {
          vm.isSessionExpired = true;
        });
        return;
      }

      // Initial API call to get endpoints
      EndpointsService.getLinks()
        .then(function (response) {
          $rootScope.APP_GLOBAL_SETTINGS.counters = {};

          // Load config, user settings and init application logic
          $q.all([
            ConfigEndpointService.getDefaultData(),
            UserSettingsService.getPage(),
          ])
            .then(function (responses) {
              // 1. response
              var defaultData = responses[0];
              $rootScope.APP_GLOBAL_SETTINGS.defaultData = defaultData;
              // 2. response
              var userSettings = responses[1].result[0];
              $rootScope.APP_GLOBAL_SETTINGS.userSettings = userSettings;
              // Execute logic needed on inital application load
              executeInitialLogic(defaultData, userSettings);
              // Set inial flag that user has been authenticated and all initial logic is finished
              vm.isAuthenticated = true;
            })
            .catch(function (error) {
              //vm.isAuthenticated = false;
              $log.debug("loadGlobalSettings error: ", error);
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        });
    }

    var filterAnsweredCampaigns = function (campaigns, filter) {
      if (filter.length === 0) {
        return campaigns;
      } else {
        var filteredArray = [];
        filteredArray = campaigns.filter(function (item) {
          return !filter.includes(item.campaignId);
        });
        return filteredArray;
      }
    };

    vm.openCampaignOfferPopup = function (campaignData) {
      return $uibModal.open({
        templateUrl: "dashboard/campaign-offer-popup.tpl.html",
        size: "md",
        controller: "CampaignOfferPopupCtrl",
        controllerAs: "campaignOfferPopup",
        resolve: {
          campaignData: campaignData,
        },
        backdrop: "static",
      });
    };

    vm.initInterval = function () {
      document.body.addEventListener("click", function () {
        vm.reset();
      });
      document.body.addEventListener("keydown", function () {
        vm.reset();
      });
      document.body.addEventListener('wheel', function () {
        vm.reset();
      });
      setInterval(function () {
        vm.check();
      }, CHECK_INTERVAL);
    };

    vm.check = function () {
      if (vm.isSessionExpired) {
        return;
      }
      var now = Date.now();
      var timeleft = vm.lastAction + MILISECONDS_UNTIL_AUTO_LOGOUT;
      var diff = timeleft - now;
      var isTimeout = diff < 0;

      if (isTimeout && SessionService.isAuthenticated()) {
        vm.reset();
        SessionService.clearSessionWeb();
        AlertService.infoAlert({
          text: "session.inactiveUser",
        }).then(function () {
          SessionService.logout();
        });
      }
    };

    vm.reset = function () {
      vm.lastAction = Date.now();
    };

    vm.checkAccountsForVirtualizationMessage = function () {
      var accountsFilter = {
        page: 0,
        pageSize: 100,
        orderBy: "isFavorite desc,accountType,iban,currencySymbol",
      };
      var _toShow150K = false;
      var _toShow100K = false;
      AccountsService.getPage(accountsFilter)
        .then(function (response) {
          response.result.forEach(function (item) {
            if (item.inflowAmount) {
              if (item.inflowAmount > 150000) {
                _toShow150K = true;
                return;
              } else if (item.inflowAmount > 100000) {
                _toShow100K = true;
                return;
              }
            }
          });
          if (_toShow150K) {
            AlertService.virtualizationRestriction({
              messageTag: "moreThan150K",
            });
          } else if (_toShow100K) {
            AlertService.virtualizationRestriction({
              messageTag: "moreThan100K",
            });
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    vm.eSavingBookContractWarning = function () {
      var hasActiveEBook = false;
      vm.savingBookObject = {};
      AccountsService.eSavingBookContractWarning()
        .then(function (response) {
          if (typeof response.eSavingBookContractWarning !== "undefined") {
            vm.savingBookObject = response.eSavingBookContractWarning;
            hasActiveEBook = true;
          }
          if (hasActiveEBook) {
            AlertService.eBookWarning(vm.savingBookObject);
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    vm.checkSecuritySettingsRestrictions = function () {
      SettingsService.securitySettingsIsSecurityMessageSeen()
        .then(function (response) {
          if (!response) {
            AlertService.securityMessageRestriction();
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    onInit();

    $rootScope.$off = function (name, listener) {
      var namedListeners = this.$$listeners[name];
      if (namedListeners) {
          // Loop through the array of named listeners and remove them from the array.
          for (var i = 0; i < namedListeners.length; i++) {
              if (namedListeners[i] === listener) {
                  return namedListeners.splice(i, 1);
              }
          }
      }
  }

    document.onkeydown = function (e) {
      e = e || window.event;
      if (f5WasPressed) return;

      if (e.keyCode === 116) {
        f5WasPressed = true;
      } else {
      }
    };

    window.onbeforeunload = function (event) {
      if (ConfigService.debugInfoEnabled) {
        return;
      }
      var _temp = sessionStorage.getItem("downloadInProgress");
      console.log("### temp", _temp);
      if (f5WasPressed) {
        $window.sessionStorage.setItem('refreshed', 'true');
      }
      console.log(_temp);
      if (SETTING_onbeforeunload && !f5WasPressed && _temp !== "download") {
        console.log("### nije download");
        SessionService.clearSessionWeb();
      }

      if (_temp === "download") {
        sessionStorage.setItem("downloadInProgress", "");
      }
    };
  }
})();
