(function () {
    'use strict';
    /**
     * @ngdoc object
     * @name sepa.controller:SepaCtrl
     *
     * @description
     *
     */
    angular
        .module('sepa')
        .controller('SepaCtrl', SepaCtrl);

    function SepaCtrl($rootScope, $scope, $state, currentAccounts, SepaService, $log, HelperService, AlertService, NotificationService) {
        var vm = this;

        init();

        function init() {
            vm.model = {};
            vm.currentAccounts = currentAccounts.result;
            vm.model.fromAccountSelect = currentAccounts.result[0];
            getSepaSettings(true);
        }

        function getSepaSettings(reset) {
            SepaService.getSepaSettings({
                    AccountId: vm.model.fromAccountSelect.accountId,
                    pageSize: 10000
                })
                .then(function (response) {
                    vm.debitAccountList = [];

                    vm.debitAccountList = HelperService.mergeRowsForTimeline({
                        existingData: vm.debitAccountList,
                        newData: {
                            result: response.result
                        },
                        groupFunction: function (item) {
                            var valueDate = new Date(item.changeDate);
                            valueDate.setHours(0, 0, 0, 0);
                            return valueDate;
                        },
                        virtualRowFunction: function (e) {
                            var virtualRow = {
                                isVirtual: true,
                                date: new Date(e)
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    }).result;
                }, function (error) {
                    vm.debitAccountList = [];
                });
        }

      var cleanLanguageChangeListener = $rootScope.$on('appLanguageChangedHeader', function (evt, data) {
        getSepaSettings(true);
      });

      $scope.$on('$destroy', function() {
        cleanLanguageChangeListener();
      });


        vm.setAccountFrom = function (account) {
            vm.model.fromAccountSelect = account;
            vm.fromAccountDropdown = false;
            getSepaSettings(true);
        };

        var selectedToEdit = {};
        vm.edit = function (data) {
          selectedToEdit.editInProgress = false;
            if (data.editInProgress) return;
          selectedToEdit = data;
          data.editInProgress = true;
            $state.go("sepa.new", {
                id: data.id
            }).then(function () {
              data.editInProgress = false;
            })
        };

        vm.addNewSetting = function () {
          if (vm.addNewSepaSettingLoading) return;
          vm.addNewSepaSettingLoading = true;
          $state.go("sepa.new", {id: null})
            .finally (function () {
              vm.addNewSepaSettingLoading = false;
            });
        }

        vm.openSwitchItemActiveStateModal = function (item) {
            var confirmationText = "sepa.changeToActivated";
            var confirmationSuccessText;
            if (item.activated) {
                confirmationText = "sepa.changeToDeactivated";
                confirmationSuccessText = "sepa.deactivateSuccess";
            } else {
                confirmationText = "sepa.changeToActivated";
                confirmationSuccessText = "sepa.activateSuccess";
            }


            AlertService.confirmationAlert({
                    text: confirmationText
                })
                .then(function (accept) {
                    if (!accept) return;
                    var putObject = {
                        "id": item.id,
                        "accountId": item.accountId,
                        "maxAmount":item.maxAmount,
                        "settingType": {
                            "id": item.settingType.id,
                            "description": item.settingType.description
                        },
                        "note": item.note,
                        "activated": !item.activated,
                        "changeDate": item.changeDate
                    };
                    SepaService.updateSepaSetting(item.id, putObject)
                        .then(function (response) {
                            AlertService.infoAlert({
                                text: confirmationSuccessText
                                })
                                .then(function () {
                                    getSepaSettings(true);
                                });
                        }, function (error) {
                          NotificationService.showMessage(error, 'error');
                        });
                });
        };

    }
}());
