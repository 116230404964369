<div class="modal-header statement-confirm-header p-10">

</div>
<div class="modal-body text-center">
  <h3 translate="accounts.title">Information</h3>
    <p ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
      <span ng-switch-default translate="accounts.message"></span>
      <span ng-switch-when="sl" translate="accounts.message_SLO"></span>
      <span ng-switch-when="bh" translate="accounts.message_BH"></span>
    </p>

</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-default" ng-click="$close()"><span
        translate="accounts.close"></span></button>
    </div>
  </div>
</div>
