<div ng-controller="DomesticPaymentCroCtrl as domesticPaymentCro">
  <div class="domp-header-wrapper" ng-show="domesticPaymentCro.currentStep != 3">
    <div class="row">
      <div class="col-md-12">
        <h2 class="f-28" translate="domesticPayment.header">Domestic Payment</h2>
      </div>
    </div>
  </div>
  <div class="domp-body-wrapper">
    <div ng-if="domesticPaymentCro.currentStep === 1" class="domp-step-1">
      <form novalidate name="domesticPaymentCro.domesticPaymentForm">

        <div>
          <!--SELECT TEMPLATE CUSTOM-->
          <div class="domp-template-filter-container" style="position: relative">
            <div class="domp-select-template" ng-click="domesticPaymentCro.toggleTemplateFilterSelection()" ng-show="domesticPaymentCro.templateName == ''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from template</label>
                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>
            <div class="domp-selected-template" ng-show="domesticPaymentCro.templateName != ''">
              <div class="select-template label-template-selected p-l-10 p-t-5" ng-show="domesticPaymentCro.templateName != ''">
                <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->

                <img ng-if="domesticPaymentCro.selectedTemplate.imageId" ng-src="{{domesticPaymentCro.selectedTemplate.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img-40"/>
                <img ng-if="!domesticPaymentCro.selectedTemplate.imageId" class="tpl-rounded-img-40" alt="Ico"/>

                <label class="p-l-10 selected-template-label top-2"><b>{{domesticPaymentCro.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5" ng-click="domesticPaymentCro.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection" ng-class="{'opened': domesticPaymentCro.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width:50%;">
                  <input id="searchTemplatesInput" type="text" class="search-input" ng-model="domesticPaymentCro.tmpSearchText.name" ng-change="domesticPaymentCro.searchTemplate(domesticPaymentCro.tmpSearchText.name)" placeholder="{{'dashboard.searchTemplatesPlaceholder'| translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item" data-ng-repeat="template in domesticPaymentCro.templateList.result| filter:domesticPaymentCro.tmpSearchText" ng-click="domesticPaymentCro.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
                          <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                          <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                          <img ng-if="!template.imageId" class="tpl-rounded-img" alt="Ico"/>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15"  style="padding-left: 50px !important;">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
        </div>
        <div class="domp-from-section p-20">
          <section class="row">
            <!-- START CUSTOM ACCOUNTS DROPDOWN -->
            <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <label class="domp-form-label" translate="payments.fromAccount"></label>
              <div class="accounts-dropdown">
                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="domesticPaymentCro.toggleFromAccount();" ng-style="domesticPaymentCro.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + domesticPaymentCro.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{domesticPaymentCro.tempDompObj.fromAccountSelect.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':domesticPaymentCro.tempDompObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!domesticPaymentCro.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{domesticPaymentCro.tempDompObj.fromAccountSelect.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{domesticPaymentCro.tempDompObj.fromAccountSelect.availableBalance| number:2}} {{domesticPaymentCro.tempDompObj.fromAccountSelect.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                </div>
                <div class="from-accounts-list" ng-show="domesticPaymentCro.fromAccountDropdown" scrollable>
                  <div class="accounts-list-item p-0" data-ng-repeat="item in domesticPaymentCro.paymentTypeGroupDomestic.result[0].debitAccountList" data-ng-click="domesticPaymentCro.setAccountFrom(item);">

                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{item.availableBalance| number:2}} {{item.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END CUSTOM ACCOUNTS DROPDOWN -->

            <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="form-group">
                <label class="domp-form-label" for="amountInput" translate="domesticPayment.amountInputLabel">Amount:</label>
                <div class="input-group">
                  <input type="text" class="form-control input-field t-a-r" 
                         ng-change="domesticPaymentCro.HSVPValidation();domesticPaymentCro.instantPaymentValidation()"
                         name="amountInput" id="amountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask maxlength="12" 
                         ng-model="domesticPaymentCro.tempDompObj.amountInput" required 
                         ng-class="{'domp-error': domesticPaymentCro.domesticPaymentForm.amountInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                            ng-if="domesticPayment.countryCode != 'sr'" style="border:1px solid #062a42;">{{domesticPaymentCro.defaultCurrency}}</button>
                  </span>
                </div>
                <span class="c-red" ng-show="domesticPaymentCro.domesticPaymentForm.amountInput.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
              </div>

              <div class="form-group">
                <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                         uib-datepicker-popup="shortDate"
                         data-ng-model="domesticPaymentCro.tempDompObj.dateInput"
                         show-weeks="false" is-open="domesticPaymentCro.datePickerOpened"
                         datepicker-options="domesticPaymentCro.dateOptions"
                         ng-change="domesticPaymentCro.instantPaymentValidation()"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         date-disabled="domesticPaymentCro.disabled(date, mode)" readonly ng-if="domesticPaymentCro.disabledSunday">
                  <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                         uib-datepicker-popup="shortDate" data-ng-model="domesticPaymentCro.tempDompObj.dateInput"
                         show-weeks="false" is-open="domesticPaymentCro.datePickerOpened"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         datepicker-options="domesticPaymentCro.dateOptions" readonly ng-if="!domesticPaymentCro.disabledSunday">
                  <span class="input-group-btn">
                    <button data-ng-click="domesticPaymentCro.openDatePicker()" type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <uib-accordion close-others="true" class="domp-sepa-accordion">
                <div uib-accordion-group>
                  <uib-accordion-heading>
                    <div class="sepa-heading input-field">
                      <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                        <label class="domp-form-label-sepa" translate="domesticPayment.sepaDebitorLabel"></label>
                      </div>
                      <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                    </div>
                  </uib-accordion-heading>
                  <input type="text" class="form-control input-field" ng-model="domesticPaymentCro.tempDompObj.payersSepaInput1" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" style="margin-bottom:10px;" maxlength="35">
                  <input type="text" class="form-control input-field" ng-model="domesticPaymentCro.tempDompObj.payersSepaInput2" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" maxlength="35">
                </div>
              </uib-accordion>
            </div>
          </section>
        </div>


        <div class="domp-from-section p-20">
          <section class="row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabel">
                Account number
              </label>
              <input type="text" class="form-control input-field" capitalize ui-mask="HR 99 9999 9999 9999 9999 9" ui-mask-placeholder ui-mask-placeholder-char="_" 
                     ui-options="domesticPaymentCro.uiMaskOptions" minlength="21" ng-model="domesticPaymentCro.tempDompObj.toAccountInput" 
                     ng-class="{'domp-error': domesticPaymentCro.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}" 
                     name="toAccountInput" id="toAccountInput" required ng-blur="domesticPaymentCro.fillReceiver(domesticPaymentCro.tempDompObj.toAccountInput)">
              <span class="c-red" ng-show="domesticPaymentCro.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="domesticPayment.receiverAccountError"></span>
              <span class="c-red" ng-show="!domesticPaymentCro.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentCro.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted" translate="domesticPayment.ibanStructError"></span>
            </div>

            <div click-outside="domesticPaymentCro.searchFilterExpanded = false;" outside-if-not="expandPayeeSearchFilterCRO">
              <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee2">To payee</label>
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentCro.domesticPaymentForm.payeeSearchFilter.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}" style="margin-bottom:15px;">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input" ng-model="domesticPaymentCro.tempDompObj.receiversNameInput" ng-change="domesticPaymentCro.searchPayee()" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" required maxlength="70" ng-maxlength="70" autocomplete="off">
                  </div>
                  <div id="expandPayeeSearchFilterCRO" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right" ng-click="domesticPaymentCro.toggleFilterSelection()">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentCro.tempDompObj.receiversNameInput != ''" ng-click="domesticPaymentCro.removeSearch()"></i>
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right" ng-show="domesticPaymentCro.tempDompObj.receiversNameInput == ''"></i>
                  </div>
                  <div class="domp-filter-selection search-row" ng-class="{'opened': domesticPaymentCro.searchFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper" ng-repeat="payee in domesticPaymentCro.payeeList| filter:domesticPaymentCro.searchPayeeBy" ng-click="domesticPaymentCro.selectPayee(payee.id)">
                      <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <i class="zmdi zmdi-account-circle"></i>
                      </div>
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                        <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentCro.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></span>
              </div>
            </div>
          </section>
          <section class="row">
            <div class="form-group col-md-offset-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <!-- <label class="domp-form-label" for="receiversAddressInput" translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label> -->
              <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentCro.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}" name="receiversAddressInput" id="receiversAddressInput" ng-model="domesticPaymentCro.tempDompObj.receiversAddressInput" ng-blur="domesticPaymentCro.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentCro.tempDompObj.receiversAddressInput)" maxlength="35" ng-maxlength="35" required>
              <span class="c-red" ng-show="domesticPaymentCro.domesticPaymentForm.receiversAddressInput.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="domesticPayment.receiverAddressError"></span>
            </div>
          </section>
          <section class="row">
            <div class="form-group col-md-offset-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentCro.domesticPaymentForm.receiversPostalCodeAndCityInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}" name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput" ng-model="domesticPaymentCro.tempDompObj.receiversPostalCodeAndCityInput" ng-blur="domesticPaymentCro.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', domesticPaymentCro.tempDompObj.receiversPostalCodeAndCityInput)" maxlength="35" ng-maxlength="35">
            </div>
          </section>
          <section class="row">
            <div class="form-group col-md-offset-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

              <uib-accordion close-others="true" class="domp-sepa-accordion">
                <div uib-accordion-group>
                  <uib-accordion-heading>
                    <div class="sepa-heading input-field">
                      <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                        <label class="domp-form-label-sepa" translate="domesticPayment.sepaReceiverLabel"></label>
                      </div>
                      <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                    </div>
                  </uib-accordion-heading>
                  <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" ng-model="domesticPaymentCro.tempDompObj.receiversSepaInput1" style="margin-bottom:10px;" maxlength="35">
                  <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allowedCharsDPS}}" ng-model="domesticPaymentCro.tempDompObj.receiversSepaInput2" maxlength="35">
                </div>
              </uib-accordion>
            </div>
          </section>
        </div>

        <div class="domp-from-section p-20">
          <section class="row">
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
              <div class="form-group">
                <label class="domp-form-label" for="purposeCodeSelect" translate="domesticPayment.purposeCodeSelectLabel">Purpose code</label>
                <div class="p-l-0">
                  <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect" ng-options="option.optionValue for option in domesticPaymentCro.purposeCodesList.result" ng-model="domesticPaymentCro.tempDompObj.purposeCodeSelect" ng-change="domesticPaymentCro.changePurpose(domesticPaymentCro.tempDompObj.purposeCodeSelect)"></select>
                </div>
              </div>
              <div class="form-group">
                <!-- <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label> -->
                <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput" translate="domesticPayment.purposeOfPaymentInputLabelCRO">Purpose</label>
                <div class="p-0">
                  <input type="text"
                         class="form-control input-field"
                         ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.purposeOfPaymentValidation}}"
                         name="purposeOfPaymentInput" id="purposeOfPaymentInput"
                         ng-maxlength="140"
                         maxlength="140"
                         ng-model="domesticPaymentCro.tempDompObj.purposeOfPaymentInput" required
                         ng-class="{'domp-error': domesticPaymentCro.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentCro.tempDompObj.formSubmitted}" ng-blur="domesticPaymentCro.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentCro.tempDompObj.purposeOfPaymentInput)">
                  <span class="c-red" ng-show="domesticPaymentCro.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="internalTransfer.paymentPurposeInputError"></span>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-xs-12 col-sm-12">
              <div class="col-xs-12 p-l-0">
                <!-- <label class="domp-form-label" translate="domesticPayment.referenceNumberLabel">Reference number:
                </label> -->
                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabelCRO">Reference number:
                </label>
              </div>
              <div class="col-sm-3 col-xs-3 p-l-0 p-r-5">
                <input type="text" class="form-control input-field" ng-model="domesticPaymentCro.referenceHR" readonly>
              </div>
              <div class="col-sm-3 col-xs-3 p-l-0 p-r-5">
                <div class="form-group m-b-0">
                  <!--<select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"
id="referenceModelSelect" ng-model="domesticPaymentCro.tempDompObj.referenceModelSelect">
<option ng-if="domesticPaymentCro.referenceModelList" ng-repeat="item in domesticPaymentCro.referenceModelList">{{item}}</option>
</select>-->
                  <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelSelect" ng-model="domesticPaymentCro.tempDompObj.referenceModelSelect" ng-options="item as item for item in domesticPaymentCro.referenceModelList" ng-change="domesticPaymentCro.clearReferenceNumber()">
                  </select>

                </div>
              </div>
              <div class="col-sm-6 col-xs-6 p-l-0 p-r-0" ng-if="domesticPaymentCro.tempDompObj.referenceModelSelect != '99'">
                <div class="form-group m-b-0">
                  <input type="text" id="referenceNumberInput" name="referenceNumberInput" maxlength="22" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentCro.regexPatterns.allNumbersDashes}}" ng-model="domesticPaymentCro.tempDompObj.referenceNumberInput">
                </div>
              </div>
              <div class="col-sm-6 col-xs-6 p-l-0 p-r-0" ng-if="domesticPaymentCro.tempDompObj.referenceModelSelect == '99'">
                <div class="form-group m-b-0">
                  <input type="text" class="form-control input-field" name="referenceNumberInput" id="referenceNumberInput2" readonly>
                </div>
              </div>
              <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="domesticPaymentCro.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentCro.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputError"></span>
            </div>
          </section>
        </div>



        <section class="row d-flex p-t-15">
          <div class="col p-l-30">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
              <input ng-readonly="domesticPaymentCro.disableAllFields" type="checkbox"
                ng-model="domesticPaymentCro.tempDompObj.isInstantPaymentCheckBox"
                ng-disabled="!domesticPaymentCro.instantPaymentEnabled"
                ng-change="domesticPaymentCro.toggleUrgencyField('isInstantPaymentCheckBox')">
              <i class="input-helper"></i>
              <span uib-tooltip="{{'domesticPayment.instantMsg'| translate}}" tooltip-append-to-body="true"
              tooltip-trigger="'mouseenter'"
              tooltip-placement="top" ng-class="{'disabled-label': !domesticPaymentCro.instantPaymentEnabled}">{{'domesticPayment.selectInstantLabel'| translate}}
              </span>
            </label>
          </div>
          <div class="col p-l-30">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value" ng-if="domesticPaymentCro.hideUrgency === false">
                <input type="checkbox" ng-model="domesticPaymentCro.tempDompObj.isUrgentPaymentCheckbox" ng-click="domesticPaymentCro.pushGtmUrgentPaymentEvent();"
                       ng-change="domesticPaymentCro.toggleUrgencyField('isUrgentPaymentCheckbox')">
              <i class="input-helper"></i>
              <span uib-tooltip="{{'domesticPayment.urgentMsg'| translate}}"
                    tooltip-append-to-body="true" tooltip-trigger="'mouseenter'"
                    tooltip-placement="top">{{'domesticPayment.selectUrgentLabel'| translate}}
              </span>
            </label>
          </div>
          <div class="col-sm-12 col-lg-6 col-md-6 p-l-30 t-a-r" style="line-height: 39px;">
            <a ng-href="{{domesticPaymentCro.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges">
              Check the bank charges
            </a>
          </div>
        </section>
        <section class="row clearfix p-15">
          <div class="form-group">
            <div class="col-md-6">
              <button class="btn btn-primary pull-left" type="button" translate="payments.cancel"
                      data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
                    <div class="col-md-6">
              <button class="btn btn-default pull-right" type="button"
                      translate="domesticPayment.confirmBtn" ng-click="domesticPaymentCro.submitPayment();"></button>
            </div>
          </div>
        </section>

    </form>
    </div>
  </div>
  <!-- Step 2 and 3 -->
  <div ng-if="domesticPaymentCro.currentStep === 2" class="domp-step-2" ng-include="'payments/domestic-payment-resume.tpl.html'"></div>
  <div ng-if="domesticPaymentCro.currentStep == 3">
    <div data-ng-include="'payments/domestic-payment-final.tpl.html'"></div>
  </div>
</div>

