<div ng-hide="accounts.showSubmenuItemType=='transactions'"></div>
<!-- <div class="statement-break col-sm-12 col-md-6" ng-hide="accounts.showSubmenuItemType=='transactions'"></div> -->
<div class="t-a-c m-t-10 p-10 no-data-image" ng-show="accounts.selectedAccount.statements.result.length == 0">
  <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
   <h3 class="no-data-label" translate="transactions.noStatementData"></h3>
</div>
<div class="timeline-row col-sm-12 p-l-0 p-r-0" ng-show="accounts.selectedAccount.statements.result.length > 0">
  <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0 no-top-radius">
    <ul class="hypo-timeline">
      <li data-ng-repeat="statement in accounts.selectedAccount.statements.result" ng-class="{'time-label': statement.isVirtual === true, 'tl-badge-icon': statement.isVirtual === undefined}">
        <span ng-if="statement.isVirtual === true" class="bg-white">{{statement.closeDateYear}}</span>
        <i ng-if="statement.isVirtual == undefined" class="zmdi icon f-18 lh-27 c-nar icon-acrobat"></i>

        <div ng-if="statement.isVirtual == undefined" class="hypo-timeline-item">

          <div class="hypo-timeline-body">
            <div class="row">
              <div ng-if="statement.serviceName != 'DOMIS'" class="col-md-9 col-sm-6 col-xs-12 t-a-l test">{{statement.closeDate | date}} - {{statement.statementId}}</div>
              <div ng-if="statement.serviceName == 'DOMIS'" class="col-md-9 col-sm-6 col-xs-12 t-a-l test">{{statement.notice}}</div>
              <div class="col-md-3 col-sm-6 col-xs-12 t-a-c test" ng-if="accounts.countryCode !== 'bh'">
                <button type="input" class="btn btn-default app-btn-orange m-b-0" translate="accounts.accDownloadPdf" ng-click="accounts.DownloadPDFStatement(statement.statementId, statement)"
                        ng-disabled="statement.downloadInProgress"></button>
              </div>
              <!-- Statements download button for BiH environment -->
              <div class="col-md-3 col-sm-6 col-xs-12 t-a-c test"
                   ng-if="accounts.countryCode === 'bh' && statement.linkList.itemList.downloadStatement">
                <button type="input" class="btn btn-default app-btn-orange m-b-0" translate="accounts.accDownloadPdf"
                        ng-click="accounts.DownloadPDFStatement(statement.linkList.itemList.downloadStatement)"></button>
              </div>
              <!-- End statements download button for BiH environment -->
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div ng-if="accounts.selectedAccount.statements.hasMore == true" class="col-xs-12 t-a-c m-t-15 m-b-15">
  <button data-ng-click="accounts.loadMoreStatements()"
          type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
          translate="paymentsOverview.loadMoreBtn">Load more</button>
</div>
