(function () {
  'use strict';

  /* @ngdoc object
   * @name materialAdmin
   * @description
   *
   */
  angular
    .module('materialAdmin', [
      //'ngAnimate', --if we enable this. current version of ui.bootstrap and angular prevents carousel from working!!!
      'ngResource',
      'ui.router',
      'ui.bootstrap',
      'angular-loading-bar',
      'nouislider'
    ])
    .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider){
      cfpLoadingBarProvider.includeBar = false;

    }]);
}());
