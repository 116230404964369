(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      payments: {
        "quickPaymentTransfer": "Brzo plaćanje ili transfer",
        "domesticPayments": "Opći/opšti nalog",
        "currencyConversion": "Konverzija valuta",
        "internationalPayment": "Devizni nalog",
        "rejectedPayments": "Odbijene uplate",
        "pendingPayments": "Uplate na čekanju",
        "templates": "Uzorci",
        "searchTemplateName": "Traži uzorak po nazivu",
        "topUsed": "Najčešće upotrebljeni",
        "alphabetically": "Po abecedi",
        "to": "ka",
        "showMoreTemplates": "Prikaži više uzoraka",
        "eInvoices": "eRačuni",
        "showMoreInvoices": "Prikaži sve eRačune",
        "sepaDirectDebit": "SEPA izravno dugovanje",
        "viewAllTransactions": "Pregled svih transakcija",
        "due": "dospio",
        "allPastPayments": "Pregled svih izvršenih plaćanja",
        "exchangeRatesList": "Tečajna/kursna lista",
        "pay": "Plati",
        "internalTransfer": "Interni prijenos/prenos",
        "noEinvoices": "Nema podataka za eRačune",
        "noTemplates": "Nema podataka za uzorak",
        "forCurrencyConversion": "pretvorba/konverzija valuta",
        "midrate": "srednja stopa",
        "forForeignNotes": "strane bilješke, čekovi",
        "onDate": "dana ",
        "buy": "Kupi",
        "goBackToPayments": "Povratak na plaćanja",
        "currencyName": "Naziv valute",
        "code": "Kod",
        "unit": "Jedinica",
        "buyRate": "Kupovni tečaj/kurs",
        "middleRate": "Srednji tečaj/kurs",
        "sellRate": "Prodajni tečaj/kurs",
        "action": "Akcija",
        "noRejectedPayments": "Za odabrani period nema odbijenih uplata",
        "noPendingPayments": "Za odabrani period nema uplata na čekanju",
        "donationToCharity": "Donacija u dobrotvorne svrhe",
        "subscribe": "Pretplata",
        "settings": "Postavke",
        "moreRejected": "Još odbijenih plaćanja",
        "morePending": "Još plaćanja na čekanju",
        "currencyExchange": "Menjačnica",
        "exchange": "Razmjeni",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "willSell": "Prodaja",
        "willBuy": "Kupovina",
        "available": "Dostupno",
        "delete": "Obriši",
        "edit": "Promjeni",
        "loanPayment": "Plaćanje kredita",
        "paymentMode": "Svrha plaćanja",
        "toAcc": "na račun",
        "fromAcc": "sa računa",
        "bicNumber": "BIC broj",
        "bicBankName": "Banka primatelja/primaoca",
        "bicAddress": "BIC adresa",
        "bicCity": "BIC grad",
        "bicCountry":"Država primatelja/primaoca",
        "bicRecipientStatus": "Primatelj/Primalac (osoba)",
        "bicRecipientStatusOptionLegal": "Pravna",
        "bicRecipientStatusOptionPhysical": "Fizička",
        "bicBankCharges": "Troškovna opcija",
        "bicNumberRequired": "BIC adresa je obavezna!",
        "bicBankNameRequired": "Banka primatelja/primaoca je obavezna!",
        "bicCityRequired": "BIC grad je obavezan!",
        "bicCountryRequired": "Država primatelja/primaoca je obavezna!",
        "resend": "Pošalji ponovo",
        "minimalAccountNumber": "Račun nije ispravan!",
        "cancel": "Odustani",
        "templateSavedSuccessfully": "Uspješno ste kreirali uzorak!",
        "templateUpdatedSuccessfully": "Promjena uzorka je uspješno izvršena!",
        "bankAddressInputRequired": "Adresa banke je obavezna!."
      },
      paymentsOverview: {
        "pohReciver": " za ",
        "pohAmount": " u iznosu od ",
        "pohDate": " na dan ",
        "povlStatus": "Status",
        "povlId": "Id naloga",
        "povlReceiver": "Naziv primatelja/primaoca",
        "povlFrom": "Sa računa",
        "povlTo": "Na račun",
        "povlAmount": "Iznos",
        "povlDueDate": "Datum izvršenja",
        "povlValueDate": "Datum valutacije",
        "povlUrgency": "Hitno",
        "povlUrgencyYes": "Da",
        "povlUrgencyNo": "Ne",
        "filterStatus": "Filtriraj po statusu",
        "loadMoreBtn": "Prikaži još naloga",
        "referenceTo": "Poziv na broj",
        "titleLabel": "Pregled naloga",
        "signatoryName": "Potpisnik",
        "signDate": "Datum akcije",
        "paymentsType": {
          "all": "Svi",
          "opened": "Otvoreni",
          "partiallysigned": "Djelimično potpisani",
          "cancelled": "Otkazani",
          "processed": "Obrađeni",
          "rejected": "Odbijeni",
          "closed": "Zatvoreni",
          "signed": "Potpisani",
          "notSigned": "Nepotpisani",
          "inprocessing": "U obradi",
          "forcancelling": "Za otkazivanje",
          "storned": "Stornirani",
          "rejectedaftercomplaint": "Odbijeni nakon žalbe",
          "acceptedaftercomplaint": "Prihvaćeni nakon žalbe",
          "allTab": "Svi",
          "realizedTab": "Realizirani/Realizovani nalozi",
          "pendingTab": "Nalozi na čekanju",
          "rejectedTab": "Odbijeni nalozi"
        },
        dateRange: "Filtriraj prema razdoblju",
        "dateFrom": "vrijeme od",
        "dateTo": "vrijeme do",
        "filterByPurpose": "Pretraži po namjeni",
        "noPaymentData": "Za odabrani filter nema naloga.",
        "selectAll": "Odaberi sve",
        "selectNone": "Poništi izbor",
        "signSelectedPayments": "Potpiši odabrana plaćanja",
        "povlNote":"Napomena",
        "referenceNumber": "Referentni broj",
        "cancelled": "Opozvan",
        "processed": "Izvršen",
        "rejected": "Odbijen",
        "open": "Kreiran - nepotpisan",
        "inProcessing": "U obradi",
        "waitingQueque": "Na čekanju",
        "signed": "Potpisan",
        "Cancelled": "Opozvan",
        "Processed": "Izvršen",
        "Rejected": "Odbijen",
        "Opened": "Kreiran - nepotpisan",
        "InProcessing": "U obradi",
        "WaitingQueue": "Na čekanju",
        "Signed": "Potpisan"
      },
      domesticPayment: {
        "header": "Opći/opšti nalog",
        "fromAccountSelectLabel": "Sa računa",
        "availableAmountLabel": "Raspoloživo",
        "purposeCodeSelectLabel": "Šifra plaćanja",
        "purposeOfPaymentInputLabel": "Svrha plaćanja",
        "amountInputLabel": "Iznos",
        "dateInputLabel": "Datum",
        "bicCodeInputLabel": "BIC kod",
        "toAccountInputLabel": "Račun primatelja/primaoca",
        "toAccountInputLabelSRB": "Račun primatelja/primaoca",
        "toPayee": "Primatelj/Primalac",
        "toAccountInputBtn": "Dopuni primatelja/primaoca",
        "referenceNumberLabel": "Poziv na broj",
        "referenceModelLabel": "Reference model",
        "receiversNameInputLabel": "Naziv primatelja/primaoca",
        "receiversAddressInputLabel": "Adresa primatelja/primaoca",
        "receiversPostalCodeAndCityInputLabel": "Poštanski broj i grad primatelja/primaoca",
        "receiversCountrySelectLabel": "Zemlja primatelja/primaoca",
        "payersNameInputLabel": "Ime i prezime pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljaoca",
        "payersCityInputLabel": "Grad pošiljaoca",
        "amountPlaceholder": "npr. 1.000,00",
        "reviewAndSubmitBtn": "Pregledajte i pošaljite",
        "selectUrgentLabel": "Označite kao hitnu uplatu",
        selectUrgentLabelSRB: "Označite kao hitnu uplatu",
        "urgentPaymentInfo": "Hitna plaćanja se naplaćuju po važećoj Tarifi Banke",
        "processingFeeLabel": "Naknada za obradu naloga",
        "receiverAccountError": "Račun primatelja/primaoca je obavezan!",
        "receiverAccountErrorHR":"IBAN / Broj računa primatelja/primaoca je obavezan!",
        "receiverNameError": "Naziv primatelja/primaoca je obavezan!",
        "receiverCityError": "Grad primatelja/primaoca je obavezan!!",
        "receiverAddressError": "Adresa primatelja/primaoca je obavezna!",
        "sepaDebitorLabel": "Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primatelj/primalac",
        "confirmBtn": "Potvrdi",
        "selectFromTemplate": "Plaćanje iz uzorka",
        "listOfTemplates": "Uzorci",
        "bankCharges": "Provizija",
        "urgentPayment": "Ovaj nalog je hitan",
        "checkBankCharges": "Provjeri dodatne naknade",
        "referenceModelInputError": "Poziv na broj je obavezan!",
        "referenceModelInputInvalid": "Poziv na broj nije ispravan!",
        "toPayeeInputError": "Naziv primatelja/primaoca je obavezan!",
        "toPayee2":"primatelj/primalac (naziv/ime i adresa)",
        "newPayment":"Novi nalog",
        "delete":"Obriši",
        "edit":"Promjeni",
        "sign":"Potpiši",
        "ibanStructError":"IBAN nije ispravan",
        "taxPayerNumber": "Broj poreznog obveznika",
        "paymentType": "Vrsta uplate",
        "revenueCode": "Vrsta prihoda",
        "municipality": "Općina/opština",
        "taxPeriodFrom": "Poreski period od",
        "taxPeriodTo": "Poreski period do",
        "budgetOrganisation": "Budžetska organizacija",
        "taxPayerError": "Broj poreznog obveznika je obavezan!",
        "revenueCodeError": "Vrsta prihoda je obavezna!",
        "budgetOrganizationError": "Budžetska organizacija je obavezna!",
        "municipalityError": "Općina/opština je obavezna!",
        "municipalityExistanceError": "Općina/opština ne postoji!",
        "taxPeriodRangeError": "Poreski period nije u ispravnom opsegu!",
        "revenueCodeTypeError": "Tip prihoda ne odgovara tipu računa!",
        "revenueCodeExistanceError": "Vrsta prihoda ne postoji!",
        "receiverAccountMinLengthError": "Račun primatelja/primaoca mora imati 8 ili 16 karaktera!",
        "receiverAccountModuleError": "Račun primatelja/primaoca nije ispravan!",
        "referenceNumberRequired": "Poziv na broj je obavezan za javne prihode!",
        "referenceNumberMaxLength": "Poziv na broj je prevelik!",
        "referenceNumberError": "Poziv na broj nije validan!",
        "receiverAccountLengthError": "Račun primatelja/primaoca mora imati 8 ili 16 cifara!",
        "domesticReceiverAccountLengthError": "Račun primatelja/primaoca mora imati 16 cifara!"
      },
      internationalPayment: {
        international: "Devizni nalog",
        "fromAccount": "Sa računa",
        "chargeAccount": "Račun naknade",
        "totalAmount": "Ukupan iznos i valuta",
        "paymentDate": "Datum plaćanja",
        "paymentPurpose": "Svrha uplate",
        "recipient": "primatelj/primalac",
        "purposeCode": "Šifra svrhe",
        "recipientStatus": "Status primatelja/primaoca",
        "companyName": "Ime i prezime ili puni naziv tvrtke",
        "address": "Adresa",
        "city": "Grad",
        "country": "Država",
        "recipientAccount": "Račun primatelja/primaoca",
        "recipientBank": "Banka primatelja/primaoca",
        "bankName": "Naziv banke",
        "swiftCode": "SWIFT kod",
        "bankCharges": "Bankovna naknada",
        "sharedCharges": "Zajednički troškovi",
        "payersPaysCharges": "Isplatitelj/Isplatilac plaća troškove",
        "beneficiaryPaysCharges": "Korisnik plaća troškove",
        "naturalPerson": "Fizičko lice",
        "legalPerson": "Pravno lice",
        "tooltipInfo": "Trebate pomoć?",
        "fillData": "Popunite podatke o banci",
        "reviewandSubmit": "Pregled i potvrda",
        "companyNameInputError": "Ime i prezime je obavezno!",
        "recipientAddressInputError": "Adresa primatelja/primaoca je obavezna!",
        "recipientCityInputError": "Grad primatelja/primaoca je obavezan!",
        "recipientAccountInputError": "Račun primatelja/primaoca je obavezan!",
        "bankNameInputError": "Naziv banke je obavezan!",
        "bankAddressInputError": "Adresa banke je obevezna!",
        "bankCityInputError": "Grad banke je obavezan!",
        "swiftCodeInputError": "SWIFT kod je obavezan!",
        "payersNameInputLabel": "Ime i prezime pošiljatelja/pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljatelja/pošiljaoca",
        "payersCityInputLabel": "Grad pošiljatelja/pošiljaoca",
        "sepaDebitorLabel": " Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primatelj/primalac",
        "paymentMode": "Svrha plaćanja",
        "toPayee2":"primatelj/primalac (naziv/ime i adresa)",
        "toAccountInputLabel": "IBAN / Broj računa primatelja/primaoca",
        "bicBankName": "Banka primatelja/primaoca",

      },
      internalTransfer: {
        "transferToAccount": "Prijenos/Prenos na moj račun",
        "transferToAccountLink": "Interni nalog",
        "fromAccount": "Račun platitelja/platioca (IBAN)",
        "toAccount": "Račun primatelja/primaoca (IBAN)",
        "dueDate": "Datum izvršenja",
        "valueDate": "Datum kreiranja",
        "amount": "Iznos",
        "sign": "Potpiši",
        "saveAsTemplate": "Pohrani kao uzorak",
        "templateName": "Naziv uzorak",
        "newPayment": "Kreiraj novi nalog",
        "toPaymentOverview": "Idi na pregeled svih plaćanja",
        "signSuccess": "Platni nalog je uspješno potpisan.",
        "toAccountInputError": "Račun primatelja/primaoca je obavezan!",
        "paymentPurposeInputError": "Svrha uplate je obavezna!",
        "amountInputError": "Iznos je obavezan!",
        "dueDateError": "Datum izvršenja je obavezan!",
        "templateNameError": "Naziv je obavezan!",
        "when": "Datum",
        "transferComplete": "Prenos/prijenos je završen",
        "makeAnotherPayment": "Kreiraj novi nalog",
        "successfullyTransfer": "Uspješno ste prebacili",
        "amountLengthErrorBh": "Maksimalni broj znakova je 9.",
        "nodata": "Nema podataka za prikaz"
      },
      paymentSignModal: {
        title: "Potpisivanje",
        challengeResponse: "Unesite kod",
        ok: "U redu"
      },
      loanPayment: {
        "loanPayment": "Plaćanje kredita",
        "recieverNumber": "Broj primatelja/primaoca",
        "purpose": "Svrha plaćanja",
        "when":"Datum",
        "exchangeRate":"Tečaj/Kurs",
        "referenceNumber":"Poziv na broj"
      },
      currencyConversion: {
        "exchangeOffice": "Kupoprodaja deviza",
        "exchangeRatesCalculator": "Pregled valuta i kalkulator",
        "sellingAmount": "Prodaja",
        "buyingAmount": "Kupovina",
        "availableBalance": "Raspoloživo stanje",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "amount": "Iznos",
        "sign": "Potpiši",
        "confirm": "Potvrdi",
        "back": "Novi nalog",
        "smsAuthentification": "SMS autentifikacija plaćanja",
        "sendSms": "Pošalji SMS kod",
        "smsPassword": "SMS jednokratna lozinka",
        "moneyExchangeComplete": "Novčana razmjena je završena",
        "successfullyExchange": "Uspješno ste razmjenili",
        "makeAnotherExchange": "Napravi još jednu razmjenu",
        "goToPayments": "Idi na pregled naloga",
        "exchangeRateRatio": "Tečajna/kursna lista",
        "exchangeOrder": "Pregled naloga za kupoprodaju deviza",
        "signPayment": "Potpiši nalog",
        "deletePayment": "Obriši nalog",
        "recallPayment": "Opozovi nalog",
        "balance":"Stanje računa",
        "exchangeRate":"Tečaj/kurs menjačnice",
        "bankCharges":"Cjenovnik",
        "when":"Datum",
        "buying":"Kupovni tečaj/kurs",
        "selling":"Prodajni tečaj/kurs",
        "calculatedAmount":"Preračunati iznos",
        "agreedRate":"Tečaj/kurs"
      },
      paymentsAndTransferOverview: {
        rejectedLabel:"Posljednji neplaćeni nalozi",
        eInvoicesLabel:"Neplaćeni eRačuni",
        groupedPaymentsLabel:"Grupa naloga za potpis",
        repeatAction:"Ponovi",
        payAction:"Plati",
        signAllAction:"Potpiši sve",
        domPayToSign:"općih/opštih naloga za potpis",
        paymentsToSign: "naloga za potpis",
        "confirm": "Potvrdi"
      },
      ebook: {
        "ebookPayIn":"Uplata na eKnjižicu",
        "ebookPayOut": "Isplata sa eKnjižice"
      },
      savings: {
        "savingsPayIn": "Interni devizni prenos/prijenos"
      },
      signPayments: {
        "paymentsInvalidDueDate": "Neki od naloga imaju datum valute manji od tekućeg. Želite li ažurirati te datume na današnji?",
        "singlePaymentInvalidDueDate": "Odabrani nalog ima datum valute manji od tekućeg. Želite li ažurirati datum na današnji?",
        "updatePaymentsDueDate": "Ažuriraj krajnje datume plaćanja",
        "updatePaymentDueDate": "Ažuriraj datum izvršenja",
        "dateCreated": "Datum kreiranja",
        "dueDate": "Datum izvršenja",
        "confirm": "Potvrdi",
        "challengeResponse": "Kod potvrde",
        "challenge": "Validacijski kod",
        "challengeFirstCode": "Šifra 1 (unosi se u token)",
        "otpLabel": "OTP",
        "challengeSecondCode": "Šifra 2 (iz tokena)",
        "challengeSecondCodeBh": "Šifra (iz tokena)",
        "challengeDescription": "Uključite token, unesite PIN i odmah na prazan ekran (piše samo CHAL na vrhu) unesite broj koji je ispisan na ekranu 'Šifra 1', i kliknite na gumb/dugme 'OK'. Token će ispisati šifru 2 koju je potrebno prepisati u polje 'Šifra 2'. Kada ste završili akciju, odaberite gumb/dugme 'POTVRDI'.",
        "finalStepSuccessfullySigned": "Uspješno ste potpisali nalog",
        "finalStepPaymentServices": "Za usluge platnog prometa naknada će biti obračunata u skladu sa službenim ",
        "finalStepBankTariff": "cjenovnikom Banke.",
        "finalStepStatus": "Status: ",
        "finalStepPaymentID": "Broj transakcije: ",
        "finalStepNumberOfPayments": "Broj naloga: ",
        "finalStepSentForProcessing": "Nalog je poslat u Banku na obradu.",
        "finalStepPaymentSloHeaderEx": "N/A",
        "finalStepPaymentSloHeaderPen": "N/A",
        "finalStepPaymentSloSubHeader": "N/A",
        "finalStepPaymentSloSubHeaderLink": "N/A",
        "finalStepPaymentSloTextEx": "N/A",
        "finalStepPaymentSloTextPen": "N/A",
        "cancel":"Odustani",
        "resendSms": "Ponovo pošalji SMS",
        "SmsCode": "SMS kod",
        "SmsDescription": "SMS poruka je poslata na Vaš uređaj. Unesite kod iz SMS poruke u polje 'SMS kod'. Nakon unosa koda, odaberite opciju 'POTVRDI'. SMS kod važi 120 sekundi i nakon toga se ne može koristiti.",
        "SmsDescriptionBh": "SMS poruka je poslata na Vaš uređaj. Unesite kod iz SMS poruke u polje 'SMS kod'. Nakon unosa koda, odaberite opciju 'POTVRDI'. SMS kod važi 90 sekundi i nakon toga se ne može koristiti.",
        "OtpDescriptionBh": "Potvrdite potpis naloga tako sto ćete unijeti dinamičku lozinku (OTP) sa identifikacionog sredstva.",
        "SmsSentSuccessfully": "SMS poruka uspješno poslana!",
        "paymentExecutionDateTitle": "Promjena datuma valute",
        "paymentExecutionDateBody": "Obrada naloga je završena. Da li se slažete da se datum valute promeni na sledeći radni dan ?",
        "infoDomSrb0":"Vaše plaćanje će biti izvršeno u skladu sa definisanim vremenskim okvirima ",
        "infoDomSrb1":"(Terminski plan).",
        "infoDomSrbLink":""
      },
      paymentActions: {
        "repeat": "Ponovi nalog",
        "copy": "Kopiraj",
        "delete": "Obriši",
        "print": "Štampa",
        "recall": "Opozovi nalog",
        "sign": "Potpiši",
        "cancel": "Otkaži",
        "rejectRecall": "Odbij zahtjev",
        "approveRecall": "Prihvati zahtjev",
        "edit":"Izmjeni",
        "confirmation": "Potvrda"
      },
      statementConfirmModalTranslations: {
        title: "Izjava",
        p1: "Sukladno/Prema Zakonu o međunarodnim mjerama ograničavanja u Banci za provedbu naloga, potrebno je popuniti ovu Izjavu. U protivnom, nalog neće biti proveden.",
        p2: "Pod kaznenom i materijalnom odgovornošću izjavljujem da je, za nalog u iznosu od ",
        p2_2: " za državu",
        p2_0: " osnova plaćanja",
        p2_1: "detaljno obrazloženje (specifikacija)*",
        p4: "Izjava je kreirana u elektroničkom obliku i važeća je bez pečata i potpisa.",
        radioMerchandise: "Roba*",
        radioPresent: "Poklon",
        radioOther: "Ostalo*",
        confirmButton: "Potvrdi",
        cancelButton: "Odustani"
      },
      "currencyCalculator": {
        "exchangeCalculator": "Tečajni/kursni kalkulator",
        "exchangeRates": "Tečajna/kursna lista",
        "msg1": "",
        "msg2": "" +
        "tel.:  " +
        "tel: ",
        "updated":"Ažurirano"
      },
      paymentsReturn: {
        openedTab: "Otvoreni",
        archivedTab: "Arhivirani",
        titleLabel: "Suglasnost/Saglasnost za povrat sredstava",
        modalTitle: "Odaberite razlog za povrat sredstava",
        recallReason: "Razlog za povrat",
        additionalInfo: "Dodatne informacije",
        checkAdditionalInfo: "Ukloni dodatne informacije",
        additionalInfoError: "Ovo polje je obavezno",
        reasonTech: "Tehnički problemi koji imaju za rezultat pogrešan platni nalog kreditnog transfera",
        reasonFrad: "Lažno iniciranje platnog naloga kreditnog transfera (required additiona info field)",
        reasonDupl: "Duplo slanje"
      },
      paymentsFinal: {
        "InProcessing":"U obradi",
        "Signed":"Potpisan",
        "WaitingQueue":"Nalog je poslat na čekanje",
        "Opened":"Otvoren"
      },
      paymentsTypeGroup: {
        "CurrencyExchange":"Kupoprodaja",
        "LocalCurrencyTransfer":"Interni prijenos/prenos"
      }
    };

    $translateProvider
      .translations('bh', data);
  }
}());

