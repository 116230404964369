(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name payments.factory:BanksService
     *
     * @description
     *
     */
    angular
        .module('payments')
        .factory('BanksService', BanksService);

    function BanksService(EndpointsService, $q, $http, $log) {
        var BanksServiceBase = {};
        BanksServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("Params:", params);
                $http.get(response.links.banks, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        BanksServiceBase.getById = function (id) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.banks_id.replace('{id}', id))
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        BanksServiceBase.instantCategoryByIbanCheck = function (iban) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links['banks_instantCategoryByIbanCheck?iban={iban}'].replace('{iban}', iban))
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        
        return BanksServiceBase;
    }
}());