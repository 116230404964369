<table class="table table-transparent table-responsive">
  <tbody ng-include="'accounts/merchant-info-details/merchant-info-details.tpl.html'"></tbody>
  <tbody>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.detail.purpose}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
      <td class="right-col">
        <span class="tridm-value-label outcome-tr" ng-if="transaction.debit"><span ng-if="accounts.countryCode == 'sr'">- </span>{{transaction.debit | number:2}} {{transaction.currencySymbol}}</span>
        <span class="tridm-value-label income-tr" ng-if="transaction.credit">{{transaction.credit | number:2}} {{transaction.currencySymbol}}</span>
      </td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountNumber}}</span></td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.card"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountType.description}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.bookingDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.bookingDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.valueDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.valueDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
    <tr  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td class="left-col"><span class="tridm-key-label" translate="accounts.creditCardNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.cardNumber}}</span></td>
    </tr>
    <tr ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh') && transaction.referenceNumber && transaction.referenceNumber != '0'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.connCardReferenceAccount"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.referenceNumber}}</span></td>
    </tr>
    <tr  ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh') && transaction.sourceAmount">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.transactionAndOriginalCurrency"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.sourceAmount | currency: ""}} {{transaction.sourceCurrencySymbol}}</span></td>
    </tr>
    <tr  ng-if="transaction.hasOwnProperty('datePeriodStart') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.statementDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.datePeriodStart | date:'shortDate' : '+0200'}}</span></td>
    </tr>

    <tr ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh') && !transaction.credit &&
               !transaction.transactionDivision && accounts.additionalCheck(transaction, accounts.selectedAccount.accountType.id)">
      <td class="left-col"></td>
      <td class="right-col">
        <button type="button" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                data-ui-sref="transactionDivison.create({transactionId: transaction.id,
                  accountId:accounts.selectedAccount.accountId, 
                  card: card.cardNumber.substr(card.cardNumber.length - 4, 4),
                  currencySymbol: vm.transactionCardsFilterOptions.currencySymbol, 
                  accountTypeId: accounts.selectedAccount.accountType.id})">
                  {{'accounts.transDivisionBtn1' | translate}} {{'accounts.transDivisionBtn2' | translate}}
        </button>
      </td>
    </tr>

    <tr ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr') && transaction.isTransactionDivisionAllowed">
      <td class="left-col"></td>
      <td class="right-col">
        <button type="button" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
        ng-click="accounts.checkIfAllowed(false, transaction.id, 
                                card.cardNumber.substr(card.cardNumber.length - 4, 4),
                                accounts.transactionCardsFilterOptions.currencySymbol)">
                  {{'accounts.transDivisionBtn1' | translate}} {{'accounts.transDivisionBtn2' | translate}}
        </button>
      </td>
    </tr>

  </tbody>
</table>
