<form name="savingsPayInForm" novalidate>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="exchange-resume-label" translate="domesticPayment.fromAccountSelectLabel"></label>
          <label class="d-block exchange-resume-val">{{savingsPayIn.paymentResponse.fromAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
            <label class="exchange-resume-label" translate="domesticPayment.amountInputLabel">Amount</label>
            <label class="d-block exchange-resume-val">
              {{savingsPayIn.paymentResponse.amount | number:2}}
              {{savingsPayIn.paymentResponse.currencySymbol}}
            </label>
          </div>
          <div class="col-sm-6">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="exchange-resume-label" translate="domesticPayment.dateInputLabel">When</label>
            <label class="d-block exchange-resume-val">{{savingsPayIn.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="exchange-resume-label" translate="currencyConversion.toAccount">To account</label>
          <label class="d-block exchange-resume-val">{{savingsPayIn.paymentResponse.toAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="exchange-resume-label" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
          <label class="d-block exchange-resume-val">{{savingsPayIn.paymentResponse.purpose}}</label>
        </div>
      </div>
    </div>
  </div>

  <!-- SMS auth old -->
<!--  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-12" ng-if="savingsPayIn.smsAuthentificate != true">
        <div class="form-group">
          <label class="exchange-resume-label" translate="currencyConversion.smsAuthentification"></label>
          <button class="btn btn-default confirm-btn d-block" translate="currencyConversion.sendSms"
                  data-ng-click="savingsPayIn.showSMSAuthentification();"></button>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12" ng-if="savingsPayIn.smsAuthentificate == true">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control input-field"
                   data-ng-model="savingsPayIn.smsPasswordInput"
                   placeholder="{{'currencyConversion.smsPassword' | translate}}">
            <span class="input-group-btn">
              <button class="btn btn-default input-group-app-btn domp-btn-height">
                <i class="zmdi zmdi-lock"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="m-10">
    <div class="row">
      <div class="col-sm-9 col-xs-6">
        <button class="btn btn-primary" translate="currencyConversion.back"
                data-ng-click="savingsPayIn.backTosavingsPayIn();">
        </button>
        <button class="btn btn-primary" translate="payments.edit"
                data-ng-click="savingsPayIn.editPayment();"
                >
        </button>
        <button class="btn btn-primary-warning" translate="payments.delete"
                data-ng-click="savingsPayIn.deletePayment();">
        </button>
      </div>

      <div class="col-sm-3 col-xs-6">
        <div class="pull-right">
<!--          <button class="btn btn-primary confirm-btn"
                  translate="currencyConversion.sign"
                  ng-disabled="!savingsPayInForm.$dirty && savingsPayIn.smsAuthentificate == false"
                  data-ng-click="savingsPayIn.signPayment();">
          </button>-->
          <button class="btn btn-default"
                  translate="currencyConversion.sign"
                  data-ui-sref="payments.sign({paymentId: savingsPayIn.paymentResponse.id, type: 'saving'})">
          </button>
        </div>

      </div>
    </div>
  </div>

</form>

