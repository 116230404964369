<div class="modal-header" style="background-color: #062a42; padding: 15px">
  <div class="row">
    <div class="col-xs-12">
      <a class="pointer pull-right" ng-click="campaignOfferPopup.closeModal(campaignOfferPopup.dontShowModalAgain, campaignOfferPopup.campaignData, campaignOfferPopup.campaignData.button3)">
        <i class="zmdi zmdi-close zmdi-hc-2x" style="font-size: 26px; color: white"></i>
      </a>
    </div>
  </div>

</div>
<div class="modal-body" style="padding: 0px">
  <div class="settings-content" style="padding: 0px;">
    <div class="banner-image-url"
         ng-style="{'background-image': 'url(' + campaignOfferPopup.campaignData.mainImage + ')'}"
         style="position:relative">
      <label style="white-space: pre-wrap;background-color: transparent; font-family: Charlie, sans-serif; font-size: large; padding: 10px" class="campaign-text">{{campaignOfferPopup.campaignData.subjectText}}</label>
      <div class="t-a-l padding-buttons">
        <div class="banner-buttons">
          <button ng-if="campaignOfferPopup.campaignData.button1Label && campaignOfferPopup.campaignData.button1Label!==''"
                  data-ng-click="campaignOfferPopup.goToBannerLink(campaignOfferPopup.campaignData,campaignOfferPopup.campaignData.button1, 'button1' )"
                  target="_blank"
                  class="btn btn-default button-campaign-offer-popUp"
                  style="background-color: #062a42; border: 1px solid #062a42;">
            <span class="round-marketing-text padding-buttons">{{campaignOfferPopup.campaignData.button1Label}}</span>
          </button>
          <button ng-if="campaignOfferPopup.campaignData.button2Label && campaignOfferPopup.campaignData.button2Label!==''"
                  data-ng-click="campaignOfferPopup.goToBannerLink(campaignOfferPopup.campaignData,campaignOfferPopup.campaignData.button2, 'button2' )"
                  target="_blank"
                  class="btn btn-default button-campaign-offer-popUp"
                  style="background-color: white; border: 1px solid white; color: #062a42; ">
            <span class="round-marketing-text padding-buttons">{{campaignOfferPopup.campaignData.button2Label}}</span>
          </button>
          <button ng-if="campaignOfferPopup.campaignData.button3Label && campaignOfferPopup.campaignData.button3Label!==''"
                  data-ng-click="campaignOfferPopup.goToBannerLink(campaignOfferPopup.campaignData,campaignOfferPopup.campaignData.button3, 'button3' )"
                  target="_blank"
                  class="btn btn-default button-campaign-offer-popUp"
                  style="background-color: white; border: 1px solid white; color: #062a42; margin-right: 0%;">
            <span class="round-marketing-text padding-buttons">{{campaignOfferPopup.campaignData.button3Label}}</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>







