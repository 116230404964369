(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name materialAdmin.controller:HeaderCtrl
   *
   * @description
   *
   */
  angular
    .module('materialAdmin')
    .controller('headerCtrl', HeaderCtrl);

  function HeaderCtrl($rootScope, $state, $timeout, MessagesService, messageService, $log, EInvoiceDocumentsService,
                      CTLocalizationService, $localStorage, UserSettingsService, ConfigService, PaymentsService,
                       growlService, HelperService, ConfigEndpointService, $scope, SessionService, $uibModal, GoogleTagManagerHelperService) {

    var vm = this;
    vm.ctrlName = 'HeaderCtrl';

    var setAppLanguage = function () {
      var lang = $localStorage.appLanguage;

        if (lang == undefined) {
          if (ConfigService.defaultLocale == undefined || ConfigService.defaultLocale == 'undefined') {
            $localStorage.appLanguage = angular.copy($localStorage.code);

          } else {
//                    $localStorage.appLanguage = ConfigService.defaultLocale;
            $localStorage.appLanguage = angular.copy($localStorage.code);
          }
          lang = $localStorage.appLanguage;
        }

        $rootScope.$broadcast('language.changed', lang);

      vm.changeLanguage(lang);
    };

    vm.getDate = function () {
      return new Date();
    };

    this.isModuleActive = function (moduleList) {
      return $state.includes(moduleList);
    };

    //GET UNREAD MESSAGES

    vm.numberOfMessagesToShow = 10;
    vm.messageToShow = {};
    vm.openReplySection = false;
    vm.messageMarkedAsReadId = null;
    vm.messageSuccessfullySent = false;

    vm.sendMessageObject = {
      subject: "",
      body: ""
    };

    vm.resetSendMessageObject = function () {
      vm.sendMessageObject.subject = "";
      vm.sendMessageObject.body = "";
    };

    vm.sendMessage = function (e) {

      MessagesService.sendMessage(vm.sendMessageObject)
        .then(function (response) {
            $log.debug("message send response", response);
            vm.messageSuccessfullySent = true;
            $timeout(function () {
              vm.openReplySection = false;
              vm.messageToShow = {};
              vm.resetSendMessageObject();
            }, 900);

          }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

    };

    vm.markMessageAsRead = function (message) {

      MessagesService.markAsRead(message.id)
        .then(function (response) {
            if (response === true) {
              $log.debug("markAsRead Response ", response);
              vm.messageMarkedAsReadId = message.id;
              $timeout(function () {
                vm.messageMarkedAsReadId = null;
                vm.getMessagesPage();
              }, 900);


            }

          }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

      vm.openReplySection = false;
      vm.messageToShow = {};
    };

    vm.messagesFilter = {
      page: 0,
      unreadMessages: true,
      orderBy: "creationDate DESC",
      pageSize: 10
    };

    vm.messagesList = {};
    // vm.getMessagesPage = function () {

    //   MessagesService.getPage(vm.messagesFilter)
    //     .then(function (response) {
    //         vm.messagesList = response;
    //         //$log.debug("messagesList", vm.messagesList);
    //       }
    //       /*, function (error) {
    //        console.log(error);
    //        }*/
    //     )
    //     .catch(function (error) {
    //       $log.error(error);
    //     });

    // };

    vm.openMessagesNotificationLink = function () {
      vm.messageToShow = {};
      vm.openReplySection = false;
      vm.messageSuccessfullySent = false;
    };

    vm.openReplySectionAction = function (e, message) {
      vm.openReplySection = true;
      e.preventDefault();
      vm.messageToShow = message;
      vm.sendMessageObject.subject = "RE: " + message.subject;
    };
    vm.cancelReply = function () {
      vm.messageSuccessfullySent = false;
      vm.openReplySection = false;
      vm.messageToShow = {};
      vm.resetSendMessageObject();

    };

    //GET E-INVOICES
    vm.eInvoiceDocumentsFilter = {
      page: 0,
      orderBy: "valueDate DESC",
      pageSize: 1000
    };
    vm.eInvoiceDocumentsList = {};
    vm.geteInvoiceDocumentsPage = function () {

      EInvoiceDocumentsService.getPage(vm.eInvoiceDocumentsFilter)
        .then(function (response) {
            if (response) {
              vm.eInvoiceDocumentsList = response;
              //$log.debug(vm.eInvoiceDocumentsList);
            }
          }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

    };

    vm.openEInvoicesModal = function (eInvoice) {
      EInvoiceDocumentsService.openModal(eInvoice);
    };

    //GET REJECTED PAYMENTS
    vm.rejectedPaymentsFilter = {
      page: 0,
      status: 'Rejected',
      orderBy: 'dueDate DESC',
      pageSize: 10
    };
    vm.rejectedPaymentsList = {};
    vm.getRejectedPaymentsPage = function () {

      PaymentsService.getRejectedPayments(vm.rejectedPaymentsFilter)
        .then(function (response) {
            vm.rejectedPaymentsList = response;
            //$log.debug("rejectedPaymentsList", vm.rejectedPaymentsList);
          }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

    };
    //GET PENDING PAYMENTS
    vm.pendingPaymentsFilter = {
      page: 0,
      status: 'InProcessing',
      orderBy: 'dueDate DESC',
      pageSize: 10
    };
    vm.pendingPaymentsList = {};
    vm.getPendingPaymentsPage = function () {

      PaymentsService.getPendingPayments(vm.pendingPaymentsFilter)
        .then(function (response) {
            vm.pendingPaymentsList = response;
            //$log.debug("pendingPaymentsList", vm.pendingPaymentsList);
          }
          /*, function (error) {
           console.log(error);
           }*/
        )
        .catch(function (error) {
          $log.error(error);
        });

    };

    vm.openEInvoicesModal = function (eInvoice) {
      EInvoiceDocumentsService.openModal(eInvoice);
    };

    //LANG SETTINGS
    vm.changeLanguage = function (lang) {
      CTLocalizationService.setLanguage(lang);
      vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
        return language.code === lang;
      });
      CTLocalizationService.translateSweetAlertOptions();
      $rootScope.$broadcast('appLanguageChangedHeader', {langCode: lang});
      GoogleTagManagerHelperService.pushEvent('AppHeader', 'TabLanguageFilter', lang);
    };

    $rootScope.$on('appLanguageChangedNavigation', function (evt, lang) {
      if (lang && lang.langCode) {
        vm.selectedLanguage = _.find(vm.languageOptions, function (language) {
          return language.code === lang.langCode;
        });
      }
    });

    //USER SETTINGS

    vm.userSettings = {};
    vm.userSettingsFilter = {
      page: 0,
      pageSize: 1000
    };

    vm.getUserSettingsPage = function () {
      vm.userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
//       UserSettingsService.getPage(vm.userSettingsFilter)
//         .then(function (response) {
//             vm.userSettings = response;
// /*          var daysOneMonthAgo = new Date();
//            daysOneMonthAgo.setMonth(daysOneMonthAgo.getMonth() - 2);
//            vm.userSettings.result[0].lastLoginDate = daysOneMonthAgo;*/
//             $log.debug("userSettings", vm.userSettings);
//           }
//         )
//         .catch(function (error) {
//           $log.error(error);
//         });
    };


    //CONTROLLER INIT
    vm.init = function () {
      vm.getUserSettingsPage();
//          Commented on 18.06. Not used anymore?!
      /*            vm.getPendingPaymentsPage();
       vm.getRejectedPaymentsPage();
       vm.geteInvoiceDocumentsPage();*/
      getDefaultData();
    };


    vm.countryCode = '';
    function getDefaultData() {
      var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
      if (defaultData.APILocation) {
        $localStorage.code = defaultData.APILocation;
        vm.countryCode = defaultData.APILocation;
        vm.languageOptions = CTLocalizationService.getLocalLanguageOptions(vm.countryCode);
        setAppLanguage();
      } else {
        vm.languageOptions = CTLocalizationService.getLanguageOptions();
        setAppLanguage();
      }

      // return ConfigEndpointService.getDefaultData()
      //   .then(function (response) {
      //     if (response.APILocation) {
      //       $localStorage.code = response.APILocation;
      //       vm.countryCode = response.APILocation;
      //       vm.languageOptions = CTLocalizationService.getLocalLanguageOptions(vm.countryCode);
      //       setAppLanguage();
      //     } else {
      //       vm.languageOptions = CTLocalizationService.getLanguageOptions();
      //       setAppLanguage();
      //     }
      //   })
      //   .catch(function (error) {
      //     NotificationService.showMessage(error, 'error');
      //   });
    }

    vm.logOut = function () {

        GoogleTagManagerHelperService.pushEvent('Dashboard', 'LogOut', 'Open');

      var modalInstance = $uibModal.open({
        templateUrl: 'core/components/logout-confirm-modal.tpl.html',
        backdrop: 'static',
        size: 'lg'
      })
        .result.then(function(result){
        if(result == "logout"){
            GoogleTagManagerHelperService.pushEvent('Dashboard', 'LogOut', 'SignedOut');
            SessionService.logout();
        }else{
            GoogleTagManagerHelperService.pushEvent('Dashboard', 'LogOut', 'Stayed');
        }
      });



      /*SessionService.clearSession()
       .then(function (response) {
       console.log(response.msg);
       SessionService.authorize();
       });*/
    };

    vm.init();

  }
}());
