(function () {
  'use strict';

  angular
    .module('sepa')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('sepa', {
        url: '/sepa',
        // templateUrl: 'sepa/sepa.tpl.html',
        // controller: 'SepaCtrl',
        // controllerAs: 'sepa'
      })
      .state('sepa.new', {
        url: '/new-sepa/:id',
        resolve: {
          sepaSettingTypes: function (SepaService) {
            return SepaService.getSepaSettingsTypes({pageSize: 1000})
          },
          currentAccounts: function (AccountsService) {
            return AccountsService.getPage({
              accountTypeGroupList: "CurrentAccount",
              isOwn: true,
              isParentAccount: true,
              pageSize: 10000
            })
          },
          sepaSetting: function ($q, $stateParams, SepaService) {
            if ($stateParams.id) {
              return $q(function (resolve, reject) {
                SepaService.getSepaSettingsById($stateParams.id)
                  .then(function (response) {
                    resolve(response);
                  }, function (error) {
                    return reject(error.data);
                  })
              })
            }
          }
        },
        views: {
          '@': {
            templateUrl: 'sepa/sepa-new.tpl.html',
            controller: 'SepaNewCtrl',
            controllerAs: 'sepa'
          }
        }
      })
      .state('sepa.new-cro', {
        url: '/new',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-new-cro.tpl.html',
            controller: 'SepaNewCroCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.refusal-cro', {
        url: '/sepa-refusal',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-refusal-cro.tpl.html',
            controller: 'SepaRefusalCroCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.accepted-refusal-cro', {
        url: '/sepa-accepted-refusal/:transactionId',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-accepted-refusal-cro.tpl.html',
            controller: 'SepaAcceptedRefusalCroCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.order-refund-cro', {
        url: '/sepa-order-refund/:transactionId',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-order-refund-cro.tpl.html',
            controller: 'SepaOrderRefundCroCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.settings', {
        url: '/settings',
        resolve: {
          currentAccounts: function (AccountsService) {
            return AccountsService.getPage({
              accountTypeGroupList: "CurrentAccount",
              isOwn: true,
              isParentAccount: true,
              pageSize: 10000
            })
          }
        },
        views: {
          '@': {
            templateUrl: 'sepa/sepa.tpl.html',
            controller: 'SepaCtrl',
            controllerAs: 'sepa'
          }
        }
      })
      .state('sepa.settings-cro', {
        url: '/sepaSettings',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-cro.tpl.html',
            controller: 'SepaCroCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.transactions', {
        url: '/sepa-overview',
        params: {
          selectedTab: null
        },
        views: {
          '@': {
            templateUrl: 'sepa/sepa-transactions-overview.tpl.html',
            controller: 'SepaTransactionsOverviewCtrl',
            controllerAs: 'sepaTrans'
          }
        }
      })
      .state('sepa.complaint', {
        url: '/sepa-overview/:transactionId/complaint',
        params: {
          type: "complaint",
          status: null
        },
        resolve: {
          transaction: function ($stateParams, SepaService) {
            return SepaService.getById($stateParams.transactionId)
          },
          validate: function ($q, transaction) {
            if (!transaction) $q.reject();
          }
        },
        views: {
          '@': {
            templateUrl: 'sepa/sepa-complaint-refund.tpl.html',
            controller: 'SepaComplaintRefundCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.refund', {
        url: '/sepa-overview/:transactionId/refund',
        params: {
          type: "refund",
          status: null
        },
        resolve: {
          transaction: function ($stateParams, SepaService) {
            return SepaService.getById($stateParams.transactionId)
          },
          validate: function ($q, transaction) {
            if (!transaction) $q.reject();
          }
        },
        views: {
          '@': {
            templateUrl: 'sepa/sepa-complaint-refund.tpl.html',
            controller: 'SepaComplaintRefundCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('sepa.direct-debit', {
        url: '/direct-debit',
        views: {
          '@': {
            templateUrl: 'sepa/sepa-direct-debit.tpl.html',
            controller: 'SepaDirectDebitCtrl',
            controllerAs: 'sepaDirectDebit'
          }
        }
      });
  }
}());
