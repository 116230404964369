(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name payments.directive:exchangeRates
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="payments">
       <file name="index.html">
        <exchange-rates></exchange-rates>
       </file>
     </example>
   *
   */
  angular
    .module('payments')
    .directive('exchangeRates', exchangeRates);

  function exchangeRates() {
    return {
      restrict: 'EA',
      scope: {
        exchangeObjectList:'=exchanges',
        currentDate: '=exchangeRatesDate',
        countryCode: '@'
      },
      templateUrl: 'payments/exchange-rates-directive.tpl.html',
      replace: false,
      controllerAs: 'exchangeRates',
      controller: function ($scope, $log) {
        var vm = this;
        vm.name = 'exchangeRates';
        vm.countryCode = $scope.countryCode;
        vm.currentDate = $scope.currentDate || new Date();
        vm.rateType = {};
        vm.buy = function (item) {
          $log.debug("Buy method is not implemented!");
          $log.debug("Exchange item: " + JSON.stringify(item));
        };
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
