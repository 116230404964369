<section id="main">
    <div class="container-fluid">
        <div class="row">
            <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">
                <h2 translate="newMessage.header" class="f-28"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-lg-4 m-b-20">
                <div
                    class="account-item-add-message app-dblue-card"
                    data-ui-sref="newMessage({messageId: null})"
                >
                    <div class="round-btn-container2">
                        <div class="round-btn-m">
                            <i
                                class="icon icon-add icon-add-msg bold-font f-24"
                            ></i>
                        </div>
                        <div class="round-btn-label-msg">
                            <h3 class="round-btn-label-msg f-20">
                                {{'messages.createBtn'| translate}}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 p-r-0 no-padding-on-phone">
                <div class="msg-view-container m-b-20">
                    <!--MESSAGES - Tabs container START-->
                    <div class="msg-tab-container">
                        <div
                            class="msg-tab cursor-pointer"
                            ng-class="{'selected': messages.messageSelectedTab === 1}"
                            ng-click="messages.selectTab(1, 'messages.receivedTab')"
                            translate="messages.receivedTab"
                        >
                            Received
                        </div>
                        <div
                            class="msg-tab cursor-pointer"
                            style="border-right: none"
                            ng-class="{'selected': messages.messageSelectedTab === 2}"
                            ng-click="messages.selectTab(2,'messages.sentTab')"
                            translate="messages.sentTab"
                        >
                            Sent
                        </div>
                    </div>
                    <!--MESSAGES - Tabs container END-->

                    <div class="msg-content-container">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group search-field">
                                    <div
                                        id="searchBtnLeftDiv"
                                        class="inner-section left"
                                    >
                                        <i
                                            class="icon icon-search icon-hc-2x m-l-5 tml-search-container"
                                        ></i>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control input-field"
                                        ng-model="messages.messageSearchInput.subject"
                                        ng-pattern-restrict="^$|^[A-Za-z0-9.ČčĆćžĐđŽžŠš][- A-Za-z0-9.ČčĆćžĐđŽžŠš]*$"
                                    />
                                </div>
                            </div>

                            <div class="col-sm-6 col-xs-12">
                                <div
                                    class="dropdown acc-period-select pull-right"
                                    style="display: inline-block"
                                >
                                    <button
                                        class="btn btn-default dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                    >
                                        {{messages.selectedPeriod.item.description
                                        | translate}}
                                        <span class="caret"></span>
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenu1"
                                    >
                                        <li
                                            data-ng-repeat="period in messages.periodList"
                                        >
                                            <a
                                                href=""
                                                class="f-13"
                                                data-ng-click="messages.timeFilter(period)"
                                            >
                                                {{period.item.description|
                                                translate}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!--/--------------------------/-->
                        <!-- Unread Messages Part START -->
                        <!--/--------------------------/-->
                        <div
                            class="received-section m-t-20"
                            ng-show="messages.messageSelectedTab === 1"
                        >
                            <h4
                                ng-if="messages.unreadMessagesList.result.length !== 0"
                                translate="messages.unreadLabel"
                            >
                                Unread messages
                            </h4>
                            <div
                                class="m-b-10"
                                ng-repeat="message in messages.unreadMessagesList.result | filter:messages.messageSearchInput | limitTo: messages.paginationLimitUnread()"
                            >
                                <div
                                    class="container invoice-card"
                                    style="border-bottom: solid 1px #e1e3eb"
                                >
                                    <div
                                        class="row invoice-card-top vertical-align pointer-cst"
                                        style="border-bottom: none"
                                        ng-click="messages.expandMessage(message, 'unread')"
                                    >
                                        <div class="col-sm-1 col-xs-1">
                                            <div
                                                class="invoice-icon img-responsive"
                                            >
                                                <i
                                                    class="icon icon-messagein bold-font msg-ico-style"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-xs-4">
                                            <div>
                                                <h3
                                                    class="invoice-type-message"
                                                >
                                                    {{::message.subject}}
                                                </h3>
                                                <h4
                                                    ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && message.hasOwnProperty('objectId')"
                                                >
                                                    <span
                                                        ng-if="message.messageType == 'Payment'"
                                                        translate="messages.paymentCode"
                                                    ></span>
                                                    <span
                                                        ng-if="message.messageType == 'Order'"
                                                        translate="messages.orderCode"
                                                    ></span>
                                                    <a
                                                        ng-class="{'underline': message.messageType == 'Payment'}"
                                                        href=""
                                                        ng-click="messages.openPayment(message); $event.stopPropagation()"
                                                    >
                                                        {{::message.objectId}}
                                                    </a>
                                                </h4>
                                                <span class="text-muted-message"
                                                    >{{::message.creationDate|
                                                    date:'medium'}}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-5 col-xs-7">
                                            <div class="row">
                                                <div
                                                    class="col-sm-2 col-xs-2 pull-right"
                                                >
                                                    <span
                                                        class="icon icon-arrdown i-c31"
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="row invoice-card-detail"
                                        style="border-top: solid 1px #e1e3eb"
                                        ng-if="message.selected"
                                    >
                                        <div>
                                            <!--<div class="col-sm-11 col-sm-offset-1">-->
                                            <!--<h4 ng-if="message.orderType.description">{{message.orderType.description}}</h4>-->
                                            <!--</div>-->
                                        </div>
                                        <div class="invoice-content container">
                                            <div class="row m-b-20">
                                                <div
                                                    class="col-sm-11 col-sm-offset-1"
                                                >
                                                    <h4>
                                                        <span
                                                            class="pre-line-text"
                                                            ng-bind-html="message.body"
                                                        ></span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class="msg-actions">
                                                <!--                                                <div class="col-xs-6 col-sm-6 p-0">
                                                                            <button ng-if="message.messageType !== 'Order' && message.messageType !== 'Global'" class="btn btn-primary-warning" ng-click="messages.deleteMessage(message.id, 'unread');" translate="messages.deleteBtn">Delete</button>
                                                                        </div>-->
                                                <div
                                                    class="col-xs-12 t-a-r p-0"
                                                >
                                                    <button
                                                        ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                                                        class="btn btn-primary"
                                                        translate="messages.printBtn"
                                                        data-ng-click="messages.printMessage(message.id)"
                                                    >
                                                        Print
                                                    </button>
                                                    <button ng-if="!message.noReply"
                                                        class="btn btn-default"
                                                        ng-click="messages.replyToMessage(message.id);"
                                                        translate="messages.replyBtn"
                                                    >
                                                        Reply
                                                    </button>
                                                    <button
                                                        ng-if="message.attachmentList"
                                                        class="btn btn-primary"
                                                        ng-click="messages.downloadMessageAttachment(message.linkList);"
                                                        translate="messages.downloadAttachment"
                                                    >
                                                        Download attachment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="m-b-10"
                                ng-if="messages.unreadMessagesList.hasMore == true"
                            >
                                <button
                                    class="btn btn-default"
                                    ng-click="messages.loadMoreMessages('u')"
                                    translate="messages.loadMore"
                                >
                                    Load more
                                </button>
                            </div>

                            <h4
                                ng-if="messages.readMessagesList.result.length !== 0"
                                class="text-muted-msg m-t-30"
                                translate="messages.readLabel"
                            >
                                Read messages
                            </h4>
                            <div
                                class="m-b-10"
                                ng-repeat="message in messages.readMessagesList.result| filter:messages.messageSearchInput | limitTo: messages.paginationLimitRead()"
                            >
                                <div
                                    class="container invoice-card"
                                    style="border-bottom: solid 1px #e1e3eb"
                                >
                                    <div
                                        class="row invoice-card-top vertical-align pointer-cst"
                                        style="border-bottom: none"
                                        ng-click="messages.expandMessage(message, 'read'); einvoicesOverview.archiveObj.archiveCtnOpened = false;"
                                    >
                                        <div class="col-sm-1 col-xs-1">
                                            <div
                                                class="invoice-icon img-responsive"
                                            >
                                                <i
                                                    class="icon icon-messagein bold-font msg-ico-style"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-xs-4">
                                            <div>
                                                <h3
                                                    class="invoice-type-message"
                                                >
                                                    {{::message.subject}}
                                                </h3>
                                                <h4
                                                    ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && message.hasOwnProperty('objectId')"
                                                >
                                                    <span
                                                        ng-if="message.messageType == 'Payment'"
                                                        translate="messages.paymentCode"
                                                    ></span>
                                                    <span
                                                        ng-if="message.messageType == 'Order'"
                                                        translate="messages.orderCode"
                                                    ></span>
                                                    <a
                                                        ng-class="{'underline': message.messageType == 'Payment'}"
                                                        href=""
                                                        ng-click="messages.openPayment(message); $event.stopPropagation()"
                                                    >
                                                        {{::message.objectId}}
                                                    </a>
                                                </h4>
                                                <span class="text-muted-message"
                                                    >{{::message.creationDate|
                                                    date:'medium'}}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-5 col-xs-7">
                                            <div class="row vertical-align">
                                                <div
                                                    class="col-sm-5 col-xs-5"
                                                ></div>
                                                <div
                                                    class="col-sm-5 col-xs-5"
                                                ></div>
                                                <div class="col-sm-2 col-xs-2">
                                                    <span
                                                        class="icon icon-arrdown i-c31"
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="row invoice-card-detail"
                                        style="border-top: solid 1px #e1e3eb"
                                        ng-class="{collapse: !message.selected}"
                                    >
                                        <div>
                                            <div
                                                class="col-sm-11 col-sm-offset-1"
                                            >
                                                <h4
                                                    ng-if="message.orderType.description"
                                                >
                                                    {{message.orderType.description}}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="invoice-content container">
                                            <div class="row m-b-20">
                                                <div
                                                    class="col-sm-11 col-sm-offset-1"
                                                >
                                                    <!--<p class="f-15 message-body text-muted-message pre-line-text " ng-bind-html="message.body"></p>-->
                                                    <h4>
                                                        <span
                                                            class="pre-line-text"
                                                            ng-bind-html="message.body"
                                                        ></span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class="msg-actions">
                                                <div
                                                    class="col-xs-6 col-sm-6 p-0"
                                                ></div>
                                                <div
                                                    class="col-xs-6 col-sm-6 t-a-r p-0"
                                                >
                                                    <button
                                                        class="btn btn-primary"
                                                        translate="messages.printBtn"
                                                        ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                                                        data-ng-click="messages.printMessage(message.id);"
                                                        style="width: 100px"
                                                    >
                                                        Print
                                                    </button>
                                                    <button
                                                        ng-if="!message.noReply"
                                                        class="btn btn-default"
                                                        ng-click="messages.replyToMessage(message.id);"
                                                        translate="messages.replyBtn"
                                                        style="width: 100px"
                                                    >
                                                        Reply
                                                    </button>
                                                    <button
                                                        ng-if="message.attachmentList"
                                                        class="btn btn-primary"
                                                        ng-click="messages.downloadMessageAttachment(message.linkList);"
                                                        translate="messages.downloadAttachment"
                                                    >
                                                        Download attachment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="m-b-10"
                                ng-if="messages.readMessagesList.hasMore == true"
                            >
                                <button
                                    class="btn btn-default"
                                    data-ng-click="messages.loadMoreMessages('r')"
                                    translate="messages.loadMore"
                                >
                                    Load more
                                </button>
                            </div>

                            <div
                                class="t-a-c p-10 m-t-20 no-data-image"
                                ng-show="messages.unreadMessagesList.result.length == 0 && messages.readMessagesList.result.length == 0"
                            >
                                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                                <h3
                                    class="no-data-label"
                                    translate="messages.noData"
                                ></h3>
                            </div>
                        </div>
                        <!--/--------------------------/-->
                        <!-- Unread Messages Part END -->
                        <!--/--------------------------/-->

                        <!--/--------------------------/-->
                        <!-- Read Messages Part START -->
                        <!--/--------------------------/-->
                        <div
                            class="sent-section m-t-20"
                            ng-show="messages.messageSelectedTab === 2"
                        >
                            <div
                                class="m-b-10"
                                ng-repeat="message in messages.sentMessagesList.result | filter:messages.messageSearchInput"
                            >
                                <div
                                    class="container invoice-card"
                                    style="border-bottom: solid 1px #e1e3eb"
                                >
                                    <div
                                        class="row invoice-card-top vertical-align pointer-cst"
                                        style="border-bottom: none"
                                        ng-click="messages.expandMessage(message, 'sent')"
                                    >
                                        <div class="col-sm-1 col-xs-1">
                                            <div
                                                class="invoice-icon img-responsive"
                                            >
                                                <i
                                                    class="icon icon-messageout bold-font msg-ico-style"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-xs-4">
                                            <div class="invoice-type">
                                                <h3
                                                    class="invoice-type-message"
                                                >
                                                    {{::message.subject}}
                                                </h3>
                                                <span class="text-muted-message"
                                                    >{{::message.creationDate|
                                                    date:'medium'}}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-5 col-xs-7">
                                            <div class="row vertical-align">
                                                <div
                                                    class="col-sm-5 col-xs-5"
                                                ></div>
                                                <div
                                                    class="col-sm-5 col-xs-5"
                                                ></div>
                                                <div class="col-sm-2 col-xs-2">
                                                    <span
                                                        class="icon icon-arrdown i-c31"
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="row invoice-card-detail"
                                        style="border-top: solid 1px #e1e3eb"
                                        ng-if="message.selected"
                                    >
                                        <div class="invoice-content container">
                                            <div class="row">
                                                <div
                                                    class="col-sm-11 col-sm-offset-1"
                                                >
                                                    <!--<p class="f-14 pre-line-text" ng-bind-html="message.body"></p>-->
                                                    <h4>
                                                        <span
                                                            class="pre-line-text 2"
                                                            ng-bind-html="message.body"
                                                        ></span>
                                                    </h4>

                                                    <div
                                                        ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
                                                    >
                                                    
                                                        <div ng-if="message.attachmentList.length > 0">
                                                            <div style="font-size: 14px;" translate="messages.attachmentList"></div>
                                                            <div class="d-flex">
                                                                <a ng-repeat="attachmentItem in message.attachmentList"
                                                                     class="cursor-pointer file-download-link" 
                                                                    ng-click="messages.downloadAttachment(attachmentItem)">
                                                                    {{attachmentItem.filename}}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--New design end-->
                            </div>
                            <div
                                class="m-b-10"
                                ng-if="messages.sentMessagesList.hasMore == true"
                            >
                                <button
                                    class="btn btn-default"
                                    data-ng-click="messages.loadMoreMessages('s')"
                                    translate="messages.loadMore"
                                >
                                    Load more
                                </button>
                            </div>

                            <div
                                class="t-a-c p-10 m-t-20 no-data-image"
                                ng-show="messages.sentMessagesList.result.length == 0"
                            >
                                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                                <h3
                                    class="no-data-label"
                                    translate="messages.noData"
                                ></h3>
                            </div>
                        </div>
                        <!--/--------------------------/-->
                        <!-- Read Messages Part END -->
                        <!--/--------------------------/-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
