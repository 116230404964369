(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:ConfigEndpointService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('ConfigEndpointService', ConfigEndpointService);

  function ConfigEndpointService($q, $http, EndpointsService) {
    var ConfigEndpointServiceBase = {};

    ConfigEndpointServiceBase.getDefaultData = function (){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.config)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return ConfigEndpointServiceBase;
  }
}());
