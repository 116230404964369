(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      employee:{
        "header":"Platna lista",
        "noTimelineData":"Nema podataka",
        "downloadPdf":"Preuzmi PDF"
      }
    };

    $translateProvider
      .translations('hr', data);

  }
}());
