
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 receiver-section">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
      <div class="form-group">
        <label class="domp-form-label-review ebook-label" translate="ebook.fullName">Name</label>
        <label class="domp-form-label-resume ebook-review">{{newEBook.insertedPaymentObject.createdBy}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
      <label class="domp-form-label-review ebook-label" translate="ebook.eSavingsBookName"></label>
      <label class="domp-form-label-resume ebook-review">{{newEBook.tempObj.nameInput}}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
        <div class="form-group m-t-15 m-b-0">
        <label class="domp-form-label-review ebook-label" translate="ebook.mainAccount"></label>
        <label class="domp-form-label-resume" style="font-weight:300;font-size:18px;color:#ff4d5a;">{{newEBook.tempObj.fromAccountSelect.friendlyName}}</label>
        <label class="domp-form-label-accnumber ebook-review" >{{newEBook.insertedPaymentObject.mainAccountNumber}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
      <div class="m-t-15 m-b-0">
        <label class="domp-form-label-review ebook-label" translate="ebook.dateOfCreation"></label>
        <label class="domp-form-label-resume ebook-review">{{newEBook.insertedPaymentObject.dateOfCreation | date:'shortDate' : '+0200'}}</label>
      </div>
    </div>
  </div>

</div>

<div class="domp-footer-wrapper">
  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0" class="m-t-10">
    <button class="col-lg-2 col-md-2 col-sm-2 col-xs-3 m-r-10 btn btn-primary-warning " translate="ebook.delete" ng-click="newEBook.deletePayment();">Delete</button>

    <button class="col-lg-2 col-md-2 col-sm-2 col-xs-3 btn btn-primary " translate="ebook.edit" ng-click="newEBook.editPayment();">Edit</button>

    <button ng-if="newEBookSavingsDivider.countryCode != 'hr'" class="col-lg-2 col-md-2 col-sm-2 col-xs-3  m-r-10 btn btn-default pull-right" translate="ebook.confirm" ng-click="newEBook.sign()">Sign</button>
    <button ng-if="newEBookSavingsDivider.countryCode == 'hr'"class="col-lg-2 col-md-2 col-sm-2 col-xs-3  m-r-10 btn btn-default pull-right" translate="ebook.signActionLabel" ng-click="newEBook.goToSignStep()">Sign</button>

  </div>
</div>
