<div class="col-sm-12 col-md-12 p-r-0">
    <!--    <div class="col-sm-12 col-md-6 p-0 p-t-5">
            <div ng-include="'accounts/connected-cards-filter.tpl.html'" style="height:40px"></div>
    </div>-->
    <div id="periodViewSelector" class="period-selector">
        <div class="period-view-select-div left"><span translate="accounts.accPeriod"></span>:</div>
        <div class="period-view-select-div right">
            <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    {{accounts.connectedCardsSelectedPeriod.description | translate}}
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li data-ng-repeat="period in accounts.periodList">
                        <a href ng-click="accounts.changeConnectedCardsReservationPeriod(period)">{{period.description | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="t-a-c p-10 m-t-20 m-b-30 no-data-image" ng-show="accounts.connectedCardReservationsList.result.length == 0">
    <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
    <h3 class="no-data-label" translate="transactions.noData"></h3>
</div>

<!--<div class="hypo-timeline-header-line col-sm-12 no-bottom-radius no-top-radius visible-md-block visible-sm-block visible-lg-block" ng-show="accounts.selectedAccount.transactions.result.length > 0">
<div class="col-md-6 col-sm-4 t-a-l p-0" translate="accounts.accDescription"></div>
<div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accAmount"></div>
<div class="col-md-3 col-sm-4 t-a-r p-0" translate="accounts.accBalance"></div>
</div>-->

<div class="timeline-row col-sm-12 p-l-0 p-r-0" infinite-scroll='accounts.loadMoreConnectedCardsReservations(false)' infinite-scroll-disabled='accounts.busy' infinite-scroll-distance='1' ng-show="accounts.connectedCardReservationsList.result.length > 0">
    <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0 no-top-radius">
        <ul class="hypo-timeline statements-timeline">
            <li data-ng-repeat="transaction in accounts.connectedCardReservationsList.result" ng-class="{'time-label': transaction.isVirtual === true, 'tl-badge-icon': transaction.isVirtual === undefined}">
                <span ng-if="transaction.isVirtual === true">{{transaction.valueDate | date: 'shortDate' : '+0200'}}</span>
                <i ng-if="transaction.isVirtual == undefined" style="z-index: 1;"
                data-ng-style="transaction.transactionLogo ?{'background-image': 'url(' + transaction.transactionLogo +')'}: {}"
                 ng-class="'icon icon-'+ transaction.icon"></i>
                <div ng-if="transaction.isVirtual == undefined" class="hypo-timeline-item transaction-item" data-ng-click="transaction.selected = !transaction.selected; accounts.getTransactionDetails(transaction);" ng-class="{'opened': transaction.selected}">
                    <div class="hypo-timeline-body">
                        <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
                            <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{transaction.merchantName || transaction.purpose}}</b></h4>
                            <p class="m-b-0 m-t-0 no-wrap-text">{{::transaction.purpose}}</p>
                        </div>
                        <div class="col-xs-6 col-sm-4 p-l-0 p-r-0">
                            <span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.amount }} {{::transaction.currency}}</b></span>
                            <!--<span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.amount | number:2}} {{::transaction.currency}}</b></span>-->
                        </div>
                        <!--<div ng-if="transaction.transactionType == 'C'" class="col-xs-6 col-sm-4 p-l-0 p-r-0"><span class="pull-right t-a-r m-t-5 f-18"><b>{{::transaction.sourceAmount | number:2}} {{::transaction.currency}}</b></span></div>-->

                    </div>
                    <div ng-if="transaction.selected" class="m-l-0 transaction-details">
                        <div data-ng-include="'accounts/connected-cards-reser-details.tpl.html'"></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
