<div class="col-xs-12 m-b-20">
  <span class="f-w-bold">
    {{placeholder | translate}}
  </span>
</div>
<div class="row mtoken-input-wrap">
  <div class="col-md-4 col-sm-6 col-xs-6">
    <div class="form-group">
      <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeFirstCode' |
        translate}}
      </div>
      <input type="text" class="form-control input-field" disabled data-ng-model="challengeFirstCode">
    </div>
    <div ng-if="challengeSecondCode" class="form-group">
      <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
        translate}}
      </div>
      <input id="nedim" name="nedim" type="text" class="form-control input-field" disabled
        data-ng-model="challengeSecondCode">
    </div>
  </div>

  <div ng-if="qrChallenge != null" class="col-md-4 col-sm-6 col-xs-6">
    <div ng-class="showCancelButton? 'm-t-10 m-l-30' :'m-t-10'">
      <image width="150" height="150" class="m-l-30" data-ng-src="{{'data:image/bmp;base64,' + qrChallenge}}"></image>
    </div>
  </div>

  <div ng-if="qrChallenge == null && showChallenge" class="col-md-4 col-sm-6 col-xs-6">
    <div class="form-group">
      <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
        translate}}
      </div>
      <input id="nedim" name="nedim" type="text" class="form-control input-field" data-ng-model="vm.challengeInput"
        ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
        placeholder="{{'signPayments.challengeSecondCode' | translate}}" autocomplete="off" auto-focus>
    </div>
    <div ng-if="challengeSecondCode" class="form-group">
      <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
        translate}}
      </div>
      <input id="nedim" name="nedim" type="text" class="form-control input-field"
        data-ng-model="vm.secondChallengeInput" ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
        placeholder="{{'signPayments.challengeSecondCode' | translate}}" autocomplete="off" auto-focus>
    </div>
  </div>
  <!-- Empty column to send third input and button below  -->
  <div ng-if="qrChallenge != null" class="col-md-4 col-sm-12 col-xs-12"></div>
  <!-- Third code input and confirm button  -->
  <div ng-if="qrChallenge != null" class="col-md-4 col-sm-6 col-xs-6">
    <div class="form-group">
      <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeThirdCode' |
        translate}}
      </div>
      <input type="text" class="form-control input-field" ng-pattern-restrict="{{vm.regexPatterns.onlyNumbers}}"
        data-ng-model="vm.challengeInput" placeholder="{{'signPayments.challengeThirdCode' | translate}}"
        autocomplete="off" auto-focus>
    </div>
  </div>
  <div ng-class="'mtoken-actions col-md-4 col-sm-6 col-xs-12 d-flex ' + (qrChallenge != null ? 'align-items-center' : 'm-t-20')">
    <div ng-if="showCancelButton" class="col-xs-6">
      <button class="btn btn-primary w-100" data-ng-click="vm.emitCancel()"
              translate="signPayments.cancel"></button>
    </div>
    <div ng-class="showCancelButton? 'col-xs-6' :'col-xs-12'">
      <button id="signConfirm" class="btn btn-default w-100" data-ng-click="vm.emitAction()"
              ng-disabled="isActionDisabled || !vm.challengeInput" translate="signPayments.confirm"></button>
    </div>
  </div>
</div>