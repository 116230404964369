/* eslint-disable wrap-iife */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
(function () {
  "use strict";

  angular.module("localization").config(config);

  function config($translateProvider) {
    var data = {
      settings: {
        title: "Postavke",
        tabAccountHR: "Korisnički profil",
        tabNotifications: "Obavijesti",
        tabSecurity: "NA",
        contactInfoHr: "Kontakt podaci",
        contactInfoBtn: "Promijeni",
        contactInfoConfirmBtn: "Spremi izmjene",
        contactInfoCancelBtn: "Otkaži",
        successfulDataUppdate: 'Uspješno ste autorizirali zahtjev za promjenom kontakt podataka',
        contactInfoName: "Ime",
        contactInfoAddress: "Adresa",
        contactInfoPhoneHr: "Fiksni telefon",
        blocades: "Blokade",
        blockLogin: "Blokiranje logiranja u internet bankarstvo",
        blockMobile: "Blokiranje logiranja u mobilno bankarstvo",
        blockPayment: "Blokiranje kartice za plaćanje",
        accountSettings: "Postavke računa",
        defaultAccount: "Podrazumijevani račun",
        statementsDelivery: "Dostava izvoda",
        statementsDeliveryPaper: "Dostava izvoda na kućnu adresu",
        statementsDeliveryElectronic: "Dostava izvoda Internet bankarstvom",
        messagesSettings: "Postavke poruka",
        messagesSettingsLabel:
          "Označite ako želite da sve poruke od banke primate i na email",
        securitySettings: "Sigurnosne postavke",
        transactionsConfirmation: "Potvrda o transakcijama",
        transactionsConfirmationSMS: "Slanjem SMS-a na mobitel",
        transactionsConfirmationOTP: "Generisanjem jednokratne zaporke",
        notificationSettings: "Postavke obaviještenja",
        gsmHr: "Mobilni telefon",
        street: "Ulica",
        houseNumber: "Kućni broj",
        postalCode: "Poštanski broj",
        city: "Grad",
        streetRequiredErrMsg: "Unos ulice je obavezan!",
        houseNumRequiredErrMsg: "Unos kućnog broja je obavezan!",
        cityOrPostalCodeRequiredErrMsg:
          "Unos grada ili poštanskog broja je obavezan!",
        emailRequiredErroMsg: "e-mail unos je obavezan!",
        invalidEmailRequiredErroMsg: "e-mail je neispravan!",
        showNotificationFor: "Želim primati obavijesti:",
        notificationSmsNumber: "SMS broj za isporuku obavjesti:",
        notificationMailAddress: "Email adresa za isporuku obavjesti:",
        notifs: {
          SignForeignPaymentSMS: "Obavijest o potpisu deviznog naloga",
          SignPaymentOrderLimitSMS:
            "Obavijest o potpisanom platnom nalogu u iznosu većem od limita",
          RejectedPaymentOrderSMS: "Obavijest o odbijenom platnom nalogu",
          LoginFromAnotherBrowserSMS:
            "Obavijest u slučaju prijave s drugog browsera",
          LoginFromAnotherCountrySMS:
            "Obavijest u slučaju prijave iz druge države",
          MinimumAmountDebitedPerCard: "Obavijest o trošku kreditne kartice",
          MaturityOfTheLoan: "Obavijest o dospijeću rate kredita",
          LimitChangesSMS: "Obavijest o promjeni limita",
          CA_ACCOUNT: "Notifikacija za tekući račun",
          GA_ACCOUNT: "Notifikacija za žiro račun",
          PREPAID: "Notifikacija za PrePaid račun",
          DIRECTDEBIT: "Notifikacija za direktna plaćanja",
          HIBIS_NOTIF_CARDS: "Notifikacija za tečajne razlike",
          PUSH: "PUSH/SMS",
          EMAIL: "e-Mail"
        },
        notificationInfo: "Vaš zahtjev je uspješno poslan",
      },
      security: {
        buttons: {
          logout: "Odjava",
        },
        title: "Sigurnost",
        tabAddikoMobile: "Addiko Mobile",
        tabAddikoEbank: "Addiko EBank",
        paymentRestrictionsPageInfoEBank:
          "Podešavanjem postavki u nastavku definirate određena ograničenja vezana za plaćanja putem Addiko EBank usluge. Detaljne upute i objašnjenje svakog pojedinog pravila možete pronaći u Uputi za korištenje Ograničenja plaćanja.",
        paymentRestrictionsPageInfoMBank:
          "Podešavanjem postavki u nastavku definirate određena ograničenja vezana za plaćanja putem Addiko Mobile usluge. Detaljne upute i objašnjenje svakog pojedinog pravila možete pronaći u Uputi za korištenje Ograničenja plaćanja.",
        company: "Company",
        paymentAmounts: "Iznosi plaćanja",
        paymentRestrictions: {
          individualAmount: "Pojedinačan iznos",
          dailyAmount: "Ukupan dnevni iznos",
          individualAmountDesc:
            "Naknada za pojedinačnu platnu transakciju nije uključena u definirani dnevni limit plaćanja.",
          dailyAmountDesc:
            "Svi iznosi u stranim valutama će biti preračnati u HRK po informativnom tečaju banke.",
          saveChanges: "Spremi izmjene",
          paymentAmounts: "Iznosi plaćanja:",
          locationAndCurrency: "Lokacija i valuta:",
          forbidAll: "Zabrani sva plaćanja",
          forbidAllOutsideCro: "Zabrani plaćanja izvan granica Hrvatske",
          forbidAllOutsideEU: "Zabrani plaćanja izvan granica EU",
          allowOnlyInCro: "Dozvoli plaćanje samo u kunama unutar RH",
          additionalLimit: "Dodatna ograničenja po računu",
          whiteList: "Bijela lista",
          blackList: "Crna lista",
          ibanOfBen: "IBAN primatelja",
          accountNumber: "Račun primatelja",
          country: "Država",
          nameOfBen: "Naziv primatelja",
          typeOfRule: "Vrsta pravila",
          definitionOfRule: "Definicija pravila",
          remove: "Ukloni",
          settingType: "Tip postavke",
          settingDefinition: "Definicija postavke",
          paymentRestrictionHeading: "Poštovani klijenti,",

          paymentRestrictionBody:
            "'Ograničenja plaćanja' omogućavaju definiranje pravila i ograničenja " +
            "na platnim nalozima kreiranim putem Addiko EBank i Addiko Mobile. " +
            "Detaljne upute i pojašnjenja možete pročitati " +
            "na linku ",

          paymentRestrictionCheckbox:
            "Prihvaćam korištenje sučelja 'Ograničenja plaćanja'.",
          warningBlackList:
            "Da biste aktivirali crnu listu bijela lista mora biti deaktivirana!",
          warningWhiteList:
            "Da biste aktivirali bijelu listu crna lista mora biti deaktivirana!",
        },
      },
    };

    $translateProvider.translations("hr", data);
  }
})();
