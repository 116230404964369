(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl($scope, $http,$resource, EndpointsService) {
    var vm = this;
    vm.ctrlName = EndpointsService.url;
  }
}());
