<header id="header" data-current-skin={{mactrl.currentSkin}} data-ng-include="'template/header.html'" data-ng-controller="headerCtrl as hctrl"></header>

<section id="main" data-ng-controller="MaterialAdminCtrl as mactrl">
    <div class="container" ng-init="cddMenuSelected=1">
        <div class="row">
            <!--LEFT COLUMN-->
            <div class="col-md-3">
                <div id="paymentMenuAccounts" class="pm-wrapper app-white-card">
                    <div class="pm-item">Make a payment <span class="icon-angle-right pull-right"></span></div>
                    <div class="pm-item">Manage payments</div>
                    <div class="pm-item">Manage payees</div>
                    <div class="pm-item pm-item-last">Manage cards</div>
                </div>
                <br/>
                <div id="quickPaymentFormDiv" class="app-white-card">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-btn srch-icon" style="border:1px solid lightgray; border-right:none;">
                                <button class="btn btn-default" type="submit" style="box-shadow:none;">
                                    <i class="glyphicon glyphicon-search"></i>
                                </button>
                            </div>
                            <input type="text" class="form-control srch-input" placeholder="Search" name="srch-term" id="srch-term">

                        </div>
                    </div>

                    <br/>
                    <form id="quickPaymentForm">
                        <div class="form-group">
                            <label for="qpAccountFrom">Filter by type:</label>
                            <div id="customCheckbox" class="checkbox-group-div">
                                <div class="hypo-cb-item">
                                    <div class="radio m-b-0">
                                        <label>
                                            <input type="radio" name="sample" value="">
                                            <i class="input-helper"></i> All
                                        </label>
                                    </div>
                                </div>
                                <div class="hypo-cb-item">
                                    <div class="radio m-b-0">
                                        <label>
                                            <input type="radio" name="sample" value="">
                                            <i class="input-helper"></i> Credit (+)
                                        </label>
                                    </div>
                                </div>
                                <div class="hypo-cb-item">
                                    <div class="radio m-b-0">
                                        <label>
                                            <input type="radio" name="sample" value="">
                                            <i class="input-helper"></i> Debit (-)
                                        </label>
                                    </div>
                                </div>
                                <div class="hypo-cb-item">
                                    <div class="radio m-b-0">
                                        <label>
                                            <input type="radio" name="sample" value="">
                                            <i class="input-helper"></i> ATM withdrawals
                                        </label>
                                    </div>
                                </div>
                                <div class="hypo-cb-item hypo-cb-item-last">
                                    <div class="radio m-b-0">
                                        <label>
                                            <input type="radio" name="sample" value="">
                                            <i class="input-helper"></i> Standing orders
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label for="qpAmount">Amount range</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="qpAmount" placeholder="Enter amount">
                                <div class="input-group-addon qp-amount">RSD</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="qpAmount">To</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="qpAmount" placeholder="Enter amount">
                                <div class="input-group-addon qp-amount">RSD</div>
                            </div>
                        </div>
                        <button id="qpSubmitBtn" type="submit" style="width:100%;" class="btn btn-default app-btn-gray">Reset</button>
                        <div style="height:10px;"></div>
                        <button id="qpSubmitBtn" type="submit" style="width:100%;" class="btn btn-default app-btn-blue">Apply</button>
                    </form>
                </div>
            </div>
            <div class="visible-xs-block visible-sm-block" style="height:10px;"></div>
            <!--CENTER COLUMN-->
            <div class="col-md-9">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                    <div id="timelineViewSelector" ng-init="timelineViewSelectorSelected=1">
                        <div class="timeline-view-select-label" ng-click="timelineViewSelectorSelected=1" ng-class="{selected: timelineViewSelectorSelected == 1}">Transactions</div>
                        <div class="timeline-view-select-label" ng-click="timelineViewSelectorSelected=2" ng-class="{selected: timelineViewSelectorSelected == 2}">Pdf statements</div>
                    </div>
                    <!-- Wrapper for slides -->
                    <div class="carousel-inner" role="listbox">

                        <div class="item active">
                            <div id="" class="slide-padding">
                                <div class="col-md-12">
                                    <h4 class="no-bottom-margin">Euro account</h4>
                                    <p>Acc no: 1234567890</p>
                                    <h1>1.200,000,32 RSD</h1>
                                    <br/>
                                    <p class="no-bottom-margin">
                                        <b>Available to spend: 602.14 RSD</b>
                                    </p>
                                    <p>
                                        <b>Overdraft limit: 2,000 RSD</b>
                                    </p>
                                    <br/>
                                    <p>
                                        <a style="color:black;">
                                            <u>More information</u>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div id="" class="slide-padding">
                                <div class="col-md-12">
                                    <h4 class="no-bottom-margin">Card account</h4>
                                    <p>Acc no: 9876543210</p>
                                    <h1>2.300,32 RSD</h1>
                                    <br/>
                                    <p class="no-bottom-margin">
                                        <b>Available to spend: 1602.14 RSD</b>
                                    </p>
                                    <p>
                                        <b>Overdraft limit: 8,000 RSD</b>
                                    </p>
                                    <br/>
                                    <p>
                                        <a>
                                            <u>More information</u>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="periodViewSelector" ng-init="timelineViewSelectorSelected=1" ng-show="timelineViewSelectorSelected == 1">
                        <div class="period-view-select-div left">Period:</div>
                        <div class="period-view-select-div right">
                            <div class="dropdown acc-period-select" ng-init="periodSelectorLabel='This week'">
                                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    {{periodSelectorLabel}}
                                    <span class="caret" style="color:#FF4D5A;"></span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                    <li>
                                        <a ng-click="periodSelectorLabel='Yesterday'">Yesterday</a>
                                    </li>
                                    <li>
                                        <a ng-click="periodSelectorLabel='This Month'">This Month</a>
                                    </li>
                                    <li>
                                        <a ng-click="periodSelectorLabel='This year'">This year</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Left and right controls -->
                    <a ng-non-bindable class="left carousel-control" class="left carousel-control" href="#myCarousel" role="button" data-slide="prev" style="background-image: none;width:10%;">
                        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a ng-non-bindable class="right carousel-control" class="right carousel-control" href="#myCarousel" role="button" data-slide="next" style="background-image: none;width:10%;">
                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <br>
                <div class="hypo-timeline-header-line  visible-md-block visible-sm-block visible-lg-block">
                    <div class="row">
                        <div class="col-sm-3 t-a-c">Description</div>
                        <div class="col-sm-3 t-a-c">Out</div>
                        <div class="col-sm-3 t-a-c">In</div>
                        <div class="col-sm-3 t-a-c">Balance</div>
                    </div>
                </div>
                <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">
                    <ul class="hypo-timeline">
                        <!-- timeline time label -->
                        <li class="time-label">
                            <span class="bg-white">04/02</span>
                        </li>
                        <!-- /.timeline-label -->
                        <!-- timeline item -->
                        <li>
                            <i class="fa fa-income"></i>

                            <div class="hypo-timeline-item">

                                <div class="hypo-timeline-body">
                                    <div class="row">
                                        <div class="col-sm-3 t-a-c">Aman Market</div>
                                        <div class="col-sm-3 t-a-c"></div>
                                        <div class="col-sm-3 t-a-c">+1.500,00</div>
                                        <div class="col-sm-3 t-a-c">+700,00</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <i class="fa fa-outcome"></i>

                            <div class="hypo-timeline-item">

                                <div class="hypo-timeline-body">
                                    <div class="row">
                                        <div class="col-sm-3 t-a-c">Aman Market</div>
                                        <div class="col-sm-3 t-a-c">-2.000,00</div>
                                        <div class="col-sm-3 t-a-c"></div>
                                        <div class="col-sm-3 t-a-c">+700,00</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- END timeline item -->
                        <!-- timeline item -->
                        <li>
                            <i class="fa fa-sort-asc"></i>

                            <div class="hypo-timeline-item">

                                <div class="hypo-timeline-body">
                                    <div class="row">
                                        <div class="col-sm-3 col-xs-12 t-a-c" style="margin-top: 5px">November</div>
                                        <div class="col-sm-3 col-xs-12 t-a-c"></div>
                                        <div class="col-sm-3 col-xs-12 t-a-c">
                                            <p class="statement-action no-bottom-margin">Download</p>
                                        </div>
                                        <div class="col-sm-3 col-xs-12 t-a-c">
                                            <p class="statement-action no-bottom-margin">View statement</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>


                <!--TRANSACTIONS TIMELINE-->
                <div class="table-responsive" ng-show="timelineViewSelectorSelected == 1">
                    <table class="table table-fixed">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="background-color:#EDECEC;">Description</th>
                                <th style="background-color:#EDECEC;">Account</th>
                                <th style="background-color:#EDECEC;">Out</th>
                                <th style="background-color:#EDECEC;">In</th>
                                <th style="background-color:#EDECEC;">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/line.png') no-repeat center;background-size: auto 100%;">
                                    <span class="tl-badge-label">{{timelineData[0].desc}}</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/shopping.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>{{data.name}}</td>
                                <td>Euro Account</td>
                                <td>-100,00</td>
                                <td></td>
                                <td>+1300,32</td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>Aman Market</td>
                                <td>Euro Account</td>
                                <td>-100,00</td>
                                <td></td>
                                <td>+1300,32</td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>Aman Market</td>
                                <td>Euro Account</td>
                                <td>-100,00</td>
                                <td></td>
                                <td>+1300,32</td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/line.png') no-repeat center;background-size: auto 100%;">
                                    <span class="tl-badge-label">4/2</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/shopping.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>Aman Market</td>
                                <td>Euro Account</td>
                                <td>-100,00</td>
                                <td></td>
                                <td>+1300,32</td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/page2xh.png') no-repeat center;background-size: auto 100%;"></td>
                                <td colspan="5">
                                    <div class="bubble">
                                        <p class="msg-label no-bottom-margin">October statement for Euro current Account is available
                                            <span class="glyphicon glyphicon-star-empty pull-right"></span>
                                        </p>
                                        <div>
                                            <p class="msg-action no-bottom-margin">Dismiss</p>
                                            <p class="msg-action no-bottom-margin">View statement</p>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>
                                    November
                                </td>
                                <td colspan="4" class="statement-btn-cell">
                                    <p class="statement-action no-bottom-margin">Download</p>
                                    <p class="statement-action no-bottom-margin">View statement</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--STATEMENTS TABLE-->
                <div class="table-responsive" ng-show="timelineViewSelectorSelected == 2">
                    <table class="table table-fixed">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="background-color:#EDECEC; color:#EDECEC !important;">Description</th>
                                <th style="background-color:#EDECEC; color:#EDECEC !important;">Account</th>
                                <th style="background-color:#EDECEC; color:#EDECEC !important;">Out</th>
                                <th style="background-color:#EDECEC; color:#EDECEC !important;">In</th>
                                <th style="background-color:#EDECEC; color:#EDECEC !important;">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/line.png') no-repeat center;background-size: auto 100%;">
                                    <span class="tl-badge-label">
                                        2016
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>
                                    November
                                </td>
                                <td colspan="4" class="statement-btn-cell">
                                    <p class="statement-action no-bottom-margin">Download</p>
                                    <p class="statement-action no-bottom-margin">View statement</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>
                                    Oktober
                                </td>
                                <td colspan="4" class="statement-btn-cell">
                                    <p class="statement-action no-bottom-margin">Download</p>
                                    <p class="statement-action no-bottom-margin">View statement</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>
                                    September
                                </td>
                                <td colspan="4" class="statement-btn-cell">
                                    <p class="statement-action no-bottom-margin">Download</p>
                                    <p class="statement-action no-bottom-margin">View statement</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="tl-badge-icon" style="background:url('../img/hypo/timeline/money.png') no-repeat center;background-size: auto 100%;"></td>
                                <td>
                                    August
                                </td>
                                <td colspan="4" class="statement-btn-cell">
                                    <p class="statement-action no-bottom-margin">Download</p>
                                    <p class="statement-action no-bottom-margin">View statement</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<footer id="footer" data-ng-include="'template/footer.html'"></footer>
<!--<footer id="footer-hypo" data-ng-include="'template/footer-hypo.html'"></footer>-->
