(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name accounts.factory:AccountLimitOrderNotificationTypeService
     *
     * @description
     *
     */
    angular
        .module('accounts')
        .factory('AccountLimitOrderNotificationTypeService', AccountLimitOrderNotificationTypeService);

    function AccountLimitOrderNotificationTypeService($q, $http, EndpointsService, $log) {
        var AccountLimitOrderNotificationTypeServiceBase = {};
        AccountLimitOrderNotificationTypeServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("AccountLimitOrderNotificationTypeServiceBase.getPage:", params);
                $http.get(response.links.accountLimitOrderNotificationType, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
        return AccountLimitOrderNotificationTypeServiceBase;
    }
}());
