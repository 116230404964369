<!-- Tablet and desktop -->
<div id="secondaryNavigationLarge" class="sn-body-container hidden-xs" ng-if="navigation.isSecondaryNavigationOpen"
  click-outside="navigation.closeSecondaryNavigation()" outside-if-not="primaryNavigationLarge">

  <!-- Accounts -->
  <div ng-show="navigation.currentNavigationItem === 'Accounts'">
    <div class="navigation-item" data-ui-sref="accountsOverview"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('AccountsOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.allAccountsOverview"></span>
    </div>

    <div class="navigation-scrollable-height" scrollable>
      <!-- First show user favorite items, then sort by iban -->
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeSecondaryNavigation()">
        <div class="navigation-item">

          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <span ng-if="navigation.countryCode != 'sl' || item.accountType.group != 'CardAccountTypes'"
            class="navigation-item-iban">{{item.iban | formatIban}}</span>
          <span ng-if="navigation.countryCode == 'sl' && (item.accountType.group === 'CardAccountTypes')"
            class="navigation-item-iban" ng-bind="item.accountNumber"></span>

          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>

          <!-- CRO CARD ACCOUNTS ONLY START -->
          <span ng-show="navigation.countryCode === 'hr' && item.accountType.group === 'CardAccountTypes'" class="d-inline-block">
            <amount-formatter amount="{{item.availableBalance| number:2}}" custom-classes="bold-font"
              class="float-left"
              currency="{{item.currencySymbol}}" from-type="navigation"
              aditional-val="{{item.availableBalance| number:2}}">
            </amount-formatter>
            <amount-formatter ng-if="navigation.isNuN(item.availableBalance2 )" amount="{{item.availableBalance2| number:2}}"
              custom-classes="nav-amount-lbl" currency="{{item.currencySymbol2}}" from-type="navigation"
              class="float-left m-t-5"
              is-secondary-value="true"
              aditional-val="{{item.availableBalance2| number:2}}" has-bracket="true">
            </amount-formatter>
          </span>
          <!-- CRO CARD ACCOUNTS ONLY END -->

          <span ng-show="!(navigation.countryCode === 'hr' && item.accountType.group === 'CardAccountTypes')" class="d-inline-block">
            <amount-formatter amount="{{item.balance| number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
              class="float-left"
              currency="{{item.currencySymbol}}" from-type="navigation"
              aditional-val="{{item.availableBalance| number:2}}">
            </amount-formatter>
            <amount-formatter ng-if="navigation.isNuN(item.balance2 ) && navigation.countryCode === 'hr'"
              class="float-left m-t-5"
              ng-show="!(navigation.countryCode === 'hr' && item.accountType.group === 'CardAccountTypes')"
              is-secondary-value="true"
              amount="{{item.balance2| number:2}}" custom-classes="nav-amount-lbl" currency="{{item.currencySymbol2}}"
              from-type="navigation" aditional-val="{{item.availableBalance2| number:2}}" has-bracket="true">
            </amount-formatter>
          </span>

          <span ng-if="navigation.countryCode == 'sl' && item.accountType.group === 'CardAccountTypes'"
            class="navigation-item-description" translate="accounts.accBalance"></span>

          <!-- CRO CARD ACCOUNTS ONLY START -->
          <span ng-if="navigation.countryCode == 'hr' && item.accountType.group === 'CardAccountTypes'"
            class="navigation-item-description" translate="navigation.availableBalance"></span>
          <!-- CRO CARD ACCOUNTS ONLY END -->

          <span ng-if="!(navigation.countryCode == 'sl' && item.accountType.group === 'CardAccountTypes') &&
                       !(navigation.countryCode == 'hr' && item.accountType.group === 'CardAccountTypes')"
            class="navigation-item-description" translate="accounts.balance"></span>

        </div>
      </li>
    </div>
  </div>

  <!-- Deposits -->
  <div ng-show="navigation.currentNavigationItem === 'Deposit'">
    <div class="navigation-item" data-ui-sref="depositAccountsOverview"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('DepositAccountsOverview', true);">
      <span class="navigation-item-header-smaller"
        ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'"
        translate="navigation.allDepositOverview"></span>
      <span class="navigation-item-header-smaller"
        ng-if="navigation.countryCode == 'sr' || navigation.countryCode == 'mne'"
        translate="navigation.allDepositOverviewSerbMne"></span>
    </div>
    <div class="navigation-scrollable-height" scrollable>
      <!-- First show user favorite items, then sort by iban -->
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeSecondaryNavigation()">
        <div class="navigation-item">

          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <span class="navigation-item-iban" ng-bind="item.iban"></span>

          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>

          <!--<span class="navigation-item-value" ng-bind="(item.availableBalance | number:2) + ' ' + (item.currencySymbol)"></span>-->
          <!-- <div ng-if="item.accountType.group !== 'SavingAccountTypes' && item.accountType.group !== 'DepositAccountTypes'">
            <amount-formatter amount="{{item.availableBalance| number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
                              currency="{{item.currencySymbol}}" from-type="navigation" aditional-val="{{item.balance| number:2}}"></amount-formatter>
            <span class="navigation-item-description" translate="navigation.availableBalance"></span>
          </div>-->
          <div class="d-inline-block">
            <amount-formatter amount="{{item.balance| number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
            class="float-left"
            currency="{{item.currencySymbol}}" from-type="navigation"
              aditional-val="{{item.availableBalance| number:2}}"></amount-formatter>
            <amount-formatter ng-if="navigation.isNuN(item.availableBalance2 ) && navigation.countryCode === 'hr'"
              class="float-left m-t-5"
              amount="{{item.availableBalance2| number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
              is-secondary-value="true"
              currency="{{item.currencySymbol2}}" from-type="navigation"
              aditional-val="{{item.availableBalance2| number:2}}">
              <span class="navigation-item-description" translate="navigation.balance"></span>
          </div>
        </div>
      </li>
    </div>

  </div>


  <!-- Payments -->
  <div ng-show="navigation.currentNavigationItem === 'Payments'">
    <!-- <div class="navigation-item" data-ui-sref="payments.paymentsAndTransferOverview" data-ng-click="navigation.closeSecondaryNavigation()">
     <span class="navigation-item-header-smaller" translate="navigation.paymentsAndTransfers"></span>
   </div>
    -->
    <div class="navigation-scrollable-height" scrollable>
      <div class="navigation-item" data-ui-sref="payments.paymentsOverview"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('PaymentsOverview', true);">
        <span class="navigation-item-header-smaller" translate="navigation.paymentsList"></span>
      </div>
      <div class="navigation-item" data-ui-sref="payments.domesticPayment"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('DomesticPayment', true);">
        <span ng-if="navigation.countryCode != 'sl'" class="navigation-item-header-smaller"
          translate="navigation.domesticPayment"></span>
        <span ng-if="navigation.countryCode == 'sl'" class="navigation-item-header-smaller"
          translate="navigation.domesticPaymentSl"></span>
      </div>

      <div class="navigation-item" data-ui-sref="payments.internalTransfer"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('TransferToMyAccount', true);">
        <span class="navigation-item-header-smaller" translate="navigation.transferToMyAccount"></span>
      </div>

      <div class="navigation-item" data-ui-sref="payments.internationalPayment"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('InternationalPayment', true);"
        ng-if="navigation.countryCode === 'hr' || navigation.countryCode === 'sl' || (navigation.countryCode === 'mne' && navigation.showInternationalPayment)">
        <span class="navigation-item-header-smaller" translate="navigation.internationalPayment"></span>
      </div>

      <!--<div class="navigation-item" data-ui-sref="payments.currencyConversion" data-ng-click="navigation.closeSecondaryNavigation()">-->
      <!--<span class="navigation-item-header-smaller" translate="navigation.exchangeOffice"></span>-->
      <!--</div>-->

      <div class="navigation-item" data-ui-sref="payments.currencyConversion"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('CurrencyConversion', true);"
        ng-if="navigation.countryCode != 'mne'">
        <span class="navigation-item-header-smaller" translate="navigation.exchangeOffice"></span>
      </div>

      <div class="navigation-item" data-ui-sref="payments.exchangeRates()"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('ExchangeRates', true);"
        ng-if="navigation.countryCode == 'mne'">
        <span class="navigation-item-header-smaller" translate="navigation.exchangeRates"></span>
      </div>

      <div class="navigation-item" data-ui-sref="eInvoices.einvoicesOverview"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('eInvoicesOverview', true);"
        ng-if="navigation.links.eInvoiceSubscriptions">
        <span class="navigation-item-header-smaller"
          translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'navigation.eInvoicesSlo':'navigation.eInvoices' }}"></span>
      </div>

      <a href="void()" click=""></a>

      <!--
        <div class="navigation-item" data-ui-sref="payments.paymentsOverview" data-ng-click="navigation.closeSecondaryNavigation()">
        <span class="navigation-item-header-smaller" translate="navigation.paymentsList"></span>
      </div>
      -->

      <!--<div class="navigation-item">-->
      <!--<span class="navigation-item-header-smaller" translate="navigation.directDebitsList"></span>-->
      <!--</div>-->

      <div class="navigation-item" data-ui-sref="paymentTemplates.templateListOverview"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('TemplateListOverview', true);">
        <span class="navigation-item-header-smaller" translate="navigation.myTemplatesList"></span>
      </div>

      <div
        ng-hide="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'N') || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
        class="navigation-item" data-ui-sref="orders"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('StandingOrders', true);"
        ng-if="navigation.links.orders">
        <span class="navigation-item-header-smaller" translate="navigation.standingOrders"></span>
      </div>

      <div class="navigation-item" data-ui-sref="payments.paymentsReturn"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('PaymentsReturn', true);"
        ng-if="navigation.countryCode === 'hr'">
        <span class="navigation-item-header-smaller" translate="navigation.paymentsReturn"></span>
      </div>

      <!-- TODO: needs better implementation, waiting for API -->

      <div class="navigation-item" data-ui-sref="sepa.transactions"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaTransactions', true);"
        ng-if="navigation.countryCode === 'sl'">
        <span class="navigation-item-header-smaller" translate="navigation.sepaTransactions"></span>
      </div>
      <div class="navigation-item" data-ui-sref="sepa.transactions"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaTransactions', true);"
        ng-if="navigation.countryCode === 'hr'">
        <span class="navigation-item-header-smaller" translate="navigation.sepaTransactions_CRO"></span>
      </div>
      <div class="navigation-item" data-ui-sref="sepa.settings"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaSettings', true);"
        ng-if="navigation.countryCode === 'sl'">
        <span class="navigation-item-header-smaller" translate="navigation.sepa"></span>
      </div>
      <div class="navigation-item" data-ui-sref="sepa.settings-cro"
        data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaSettings', true);"
        ng-if="navigation.countryCode === 'hr'">
        <span class="navigation-item-header-smaller" translate="navigation.sepa_CRO"></span>
      </div>

      <!--      <div class="navigation-item" data-ui-sref="sepa.direct-debit"
           data-ng-click="navigation.closeSecondaryNavigation();
           navigation.pushGtmNavigationEvent('SepaDirectDebit', true);" ng-if="navigation.countryCode === 'hr'">
        <span class="navigation-item-header-smaller" translate="navigation.directDebit"></span>
      </div>-->
    </div>
  </div>

  <!-- Loans -->
  <div ng-show="navigation.currentNavigationItem === 'Loans'" ui-sref="loansOverview"
    data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('LoansOverview', true);">
    <div class="navigation-item">
      <span class="navigation-item-header-smaller" translate="navigation.loansOverview"></span>
    </div>

    <div class="navigation-scrollable-height" scrollable>
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeSecondaryNavigation()">
        <div class="navigation-item">
          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>
          <!--<span class="navigation-item-value"-->
          <!--ng-bind="(item.loanAccount.loanAmount | number:2) + ' ' + (item.loanAccount.loanAmountCurrencySymbol)"></span>-->

          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
            <span ng-switch-when="hr">
              <amount-formatter amount="{{item.loanAccount.loanAmount| number:2}}"
                custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.loanAccount.loanAmountCurrencySymbol}}" from-type="navigation"
                aditional-val="{{item.balance| number:2}}"></amount-formatter>
              <span class="navigation-item-description" translate="navigation.allowedLoan"></span>
            </span>
            <span ng-switch-when="sl">
              <amount-formatter ng-if="item.accountType.group == 'LoanAccountType' && item.balance"
                amount="{{item.balance | number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
                currency="EUR" from-type="navigation" aditional-val="{{item.balance| number:2}}"></amount-formatter>

              <amount-formatter ng-if="item.accountType.group != 'LoanAccountType'" amount="{{item.balance | number:2}}"
                custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.loanAccount.loanAmountCurrencySymbol}}" from-type="navigation"
                aditional-val="{{item.balance| number:2}}"></amount-formatter>
              <span ng-if="item.balance" class="navigation-item-description" translate="navigation.loanBalance"></span>
            </span>
            <span ng-switch-default>
              <!--BIH, Serbia, Montenegro-->
              <amount-formatter ng-if="item.accountType.group == 'LoanAccountType' && item.balance"
                amount="{{item.balance | number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.currencySymbol}}" from-type="navigation" aditional-val="{{item.balance| number:2}}">
              </amount-formatter>
              <span ng-if="item.balance" class="navigation-item-description" translate="navigation.loanBalance"></span>
            </span>
          </div>

          <!--          <span class="navigation-item-description"
                          ng-bind="(item.loanAccount.installmentAmount) + ' ' + (item.loanAccount.installmentCurrencySymbol)"></span>-->

        </div>
      </li>
    </div>
  </div>


  <!-- Settings -->
  <div
    ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' && navigation.currentNavigationItem === 'SettingsHR'">
    <div class="navigation-item" data-ui-sref="settings"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SettingsOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.settings"></span>
    </div>
    <div class="navigation-item d-flex-justify-content-between" data-ui-sref="securitySelectCompany"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SecurityOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.security"></span>
      <div class="ni-icon">
        <i class="icon i-p24 icon-lock icon-yellow"></i>
      </div>
    </div>
  </div>

  <div
    ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr' && navigation.currentNavigationItem === 'Settings'">
    <div class="navigation-item" data-ui-sref="settings"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('Settings', true);">
      <span class="navigation-item-header-smaller" data-ui-sref="settings" translate="navigation.settings"></span>
    </div>
  </div>


  <!-- Buttons -->
  <div class="accounts-overview-navigation-footer navigation-item" ng-if="(navigation.currentNavigationItem == 'Accounts' || navigation.currentNavigationItem == 'Deposit') &&
       (navigation.countryCode != 'sr' && navigation.countryCode != 'sl')">
    <div class="term-deposit-navigation-btn" ng-if="navigation.links.openingPermanentOrders"
      data-ui-sref="orders.termDeposit"
      ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('NewTermDeposit', true);">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label" translate="accountsOverview.newTermDepositButtonLabel"></span>
    </div>

    <div class="term-deposit-navigation-btn" ng-if="navigation.links.eSavingBookRequests"
      data-ui-sref="newEbook({myParam:navigation.generalTerms, accountId: null})"
      ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('eSavingBookRequests', true);">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <div ng-switch="navigation.countryCode">
        <span ng-switch-when="sl" class="new-term-deposit-label"
          translate="accountsOverview.newSavingButtonLabel_SLO"></span>
        <span ng-switch-default class="new-term-deposit-label"
          translate="accountsOverview.newESavingAccountButtonLabel"></span>
      </div>
    </div>

    <!--    <button ng-if="navigation.countryCode === 'hr'" ui-sref="orders.termDeposit" ng-click="navigation.closeSecondaryNavigation()">
      <b translate="accountsOverview.newTermDepositButtonLabel"></b>
    </button>-->
    <!--    <button ng-if="navigation.countryCode != 'hr'" >
      <b translate="accountsOverview.newTermDepositButtonLabel"></b>
    </button>-->
    <!--    <button ui-sref="newEbook" ng-click="navigation.closeSecondaryNavigation()"
            ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'">
      <b translate="accountsOverview.newESavingAccountButtonLabel" ></b>
    </button>-->
  </div>


  <!-- Button Group For Slovenia-->
  <div class="accounts-overview-navigation-footer navigation-item"
    ng-if="(navigation.currentNavigationItem == 'Accounts' || navigation.currentNavigationItem == 'Deposit' || navigation.currentNavigationItem == 'Loans') && (navigation.countryCode == 'sl')">

    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Accounts' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="masterCardNew" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label" translate="accountsOverview.newSavingButtonLabel_SLO">New Mastercard
        Order</span>
    </div>

    <div class="term-deposit-navigation-btn"
      ng-if="navigation.links.openingPermanentOrders && navigation.currentNavigationItem == 'Deposit' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.termDeposit" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label" translate="accountsOverview.newTermDepositButtonLabel"></span>
    </div>

    <!-- Treba ga pokazati - Not yet implemented -->
    <!--<div class="term-deposit-navigation-btn" ng-if="navigation.links.eSavingBookRequests && navigation.currentNavigationItem == 'Deposit'"  ui-sref="newEbook({myParam:navigation.generalTerms})" ng-click="navigation.closeSecondaryNavigation()">-->
    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Deposit' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.newSavingsOrder" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <div>
        <span class="new-term-deposit-label" translate="accountsOverview.newESavingAccountButtonLabel_SLO"></span>
      </div>
    </div>

    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Loans' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.addNewExpressLoan" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label" translate="addNewExpressLoan.addNewExpressLoan">New Express Loan Order</span>
    </div>

  </div>

</div>


<!-------------------------------------------------->
<!--           On small devices                   -->
<!-------------------------------------------------->

<div id="secondaryNavigationMobile" class="sn-body-container visible-xs"
  ng-if="navigation.isMobileSecondaryNavigationOpen" click-outside="navigation.closeMobileNavigation()">

  <!-- Accounts -->
  <div ng-show="navigation.currentNavigationItem === 'Accounts'">
    <div class="navigation-item" data-ui-sref="accountsOverview"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('AccountsOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.allAccountsOverview"></span>
    </div>

    <div class="navigation-scrollable-height" scrollable>
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeMobileNavigation()">
        <div class="navigation-item">
          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <span class="navigation-item-description" ng-bind="item.iban"></span>
          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>
          <!--<span class="navigation-item-value" ng-bind="(item.balance | number:2) + ' ' + (item.currencySymbol)"></span>-->
          <div class="d-inline-block">
            <amount-formatter amount="{{item.balance| number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
              class="float-left"
              currency="{{item.currencySymbol}}" from-type="navigation"></amount-formatter>
            <amount-formatter ng-if="navigation.isNuN(item.availableBalance2 ) && navigation.countryCode == 'hr'"
              amount="{{item.availableBalance2| number:2}}" custom-classes="nav-amount-lbl"
              class="float-left m-t-5"
              is-secondary-value="true"
              currency="{{item.currencySymbol2}}" from-type="navigation" aditional-val="{{item.balance2| number:2}}"
              has-bracket="true"></amount-formatter>
          </div>
          
          <span class="navigation-item-description" translate="navigation.availableBalance"></span>
        </div>
      </li>
    </div>
  </div>


  <div ng-show="navigation.currentNavigationItem === 'Deposit'">
    <div class="navigation-item" data-ui-sref="depositAccountsOverview"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('DepositAccountsOverview', true);">
      <span class="navigation-item-header-smaller"
        ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'"
        translate="navigation.allDepositOverview"></span>
      <span class="navigation-item-header-smaller"
        ng-if="navigation.countryCode == 'sr' || navigation.countryCode == 'mne'"
        translate="navigation.allDepositOverviewSerbMne"></span>
    </div>
    <div class="navigation-scrollable-height" scrollable>
      <!-- First show user favorite items, then sort by iban -->
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeMobileNavigation()">
        <div class="navigation-item">

          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <span class="navigation-item-iban" ng-bind="item.iban"></span>

          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>

          <!--<span class="navigation-item-value" ng-bind="(item.availableBalance | number:2) + ' ' + (item.currencySymbol)"></span>-->
          <amount-formatter amount="{{item.availableBalance| number:2}}"
            class="float-lef"
            custom-classes="navigation-item-value nav-amount-lbl" currency="{{item.currencySymbol}}"
            from-type="navigation" aditional-val="{{item.balance| number:2}}"></amount-formatter>

          <amount-formatter ng-if="navigation.isNuN(item.availableBalance2 ) && navigation.countryCode == 'hr'"
            amount="{{item.availableBalance2| number:2}}" custom-classes="nav-amount-lbl"
            class="float-left m-t-5"
            is-secondary-value="true"
            currency="{{item.currencySymbol2}}" from-type="navigation" aditional-val="{{item.balance2| number:2}}"
            has-bracket="true"></amount-formatter>
          <span class="navigation-item-description" translate="navigation.availableBalance"></span>
        </div>
      </li>
    </div>
  </div>


  <div class="accounts-overview-navigation-footer navigation-item" ng-if="(navigation.currentNavigationItem == 'Accounts' || navigation.currentNavigationItem == 'Deposit') &&
       (navigation.countryCode != 'sr' && navigation.countryCode != 'sl')">
    <div class="term-deposit-navigation-btn" ng-if="navigation.links.openingPermanentOrders"
      ui-sref="orders.termDeposit" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label m-t-10" translate="accountsOverview.newTermDepositButtonLabel"></span>
    </div>

    <div class="term-deposit-navigation-btn" ng-if="navigation.links.eSavingBookRequests"
      data-ui-sref="newEbook({myParam:navigation.generalTerms, accountId: null})"
      ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <div ng-switch="navigation.countryCode">
        <span ng-switch-default class="new-term-deposit-label m-t-10"
          translate="accountsOverview.newESavingAccountButtonLabel"></span>
        <span ng-switch-when="sl" class="new-term-deposit-label m-t-10"
          translate="accountsOverview.newSavingButtonLabel_SLO"></span>
      </div>
    </div>


    <!--    <button ui-sref="orders.termDeposit" data-ng-click="navigation.closeMobileNavigation()">
      <b translate="accountsOverview.newTermDepositButtonLabel"></b>
    </button>-->
    <!--    <button ui-sref="newEbook" data-ng-click="navigation.closeMobileNavigation()" ng-if="navigation.countryCode != 'sr' && navigation.countryCode != 'mne'">
      <b translate="accountsOverview.newESavingAccountButtonLabel"></b>
    </button>-->
  </div>

  <div class="accounts-overview-navigation-footer navigation-item" ng-if="navigation.countryCode == 'sl'"
    style="bottom: 40px;">

    <div class="term-deposit-navigation-btn" ng-if="navigation.currentNavigationItem == 'Accounts'"
      ng-click="navigation.openPayLifeBalancePage(); navigation.closeSecondaryNavigation()">

      <span class="new-term-deposit-label">Prepaid Mastercard balance</span>
    </div>
    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Accounts' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="masterCardNew" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label">New Mastercard Order</span>
    </div>

    <div class="term-deposit-navigation-btn"
      ng-if="navigation.links.openingPermanentOrders && navigation.currentNavigationItem == 'Deposit' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.termDeposit" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label m-t-10" translate="accountsOverview.newTermDepositButtonLabel"></span>
    </div>

    <!--<div class="term-deposit-navigation-btn" ng-if="navigation.links.eSavingBookRequests"  ui-sref="newEbook({myParam:navigation.generalTerms})" ng-click="navigation.closeSecondaryNavigation()">-->
    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Deposit' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.newSavingsOrder" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label" translate="accountsOverview.newESavingAccountButtonLabel_SLO"></span>
    </div>

    <div class="term-deposit-navigation-btn"
      ng-if="navigation.currentNavigationItem == 'Loans' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'Y'"
      ui-sref="orders.addNewExpressLoan" ng-click="navigation.closeSecondaryNavigation()">
      <div class="add-term-deposit-navigation-wrapper">
        <i class="icon icon-add c-white f-w-bolder"></i>
      </div>
      <span class="new-term-deposit-label">New Express Loan Order</span>
    </div>

  </div>

  <!-- Payments -->
  <div ng-show="navigation.currentNavigationItem === 'Payments'" scrollable>
    <!-- <div class="navigation-item" data-ui-sref="payments.paymentsAndTransferOverview" data-ng-click="navigation.closeMobileNavigation()">
    <span class="navigation-item-header-smaller" translate="navigation.paymentsAndTransfers"></span>
  </div>
    -->
    <div class="navigation-item" data-ui-sref="payments.paymentsOverview"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('PaymentsOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.paymentsList"></span>
    </div>
    <div class="navigation-item" data-ui-sref="payments.domesticPayment"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('DomesticPayment', true);">
      <span ng-if="navigation.countryCode != 'sl'" class="navigation-item-header-smaller"
        translate="navigation.domesticPayment"></span>
      <span ng-if="navigation.countryCode == 'sl'" class="navigation-item-header-smaller"
        translate="navigation.domesticPaymentSl"></span>
    </div>

    <div class="navigation-item" data-ui-sref="payments.internalTransfer"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('TransferToMyAccount', true);">
      <span class="navigation-item-header-smaller" translate="navigation.transferToMyAccount"></span>
    </div>

    <div class="navigation-item" data-ui-sref="payments.internationalPayment"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('InternationalPayment', true);"
      ng-if="navigation.countryCode === 'hr' || navigation.countryCode === 'sl' || navigation.countryCode === 'mne'">
      <span class="navigation-item-header-smaller" translate="navigation.internationalPayment"></span>
    </div>

    <!--<div class="navigation-item" data-ui-sref="payments.currencyConversion" data-ng-click="navigation.closeMobileNavigation()">-->
    <!--<span class="navigation-item-header-smaller" translate="navigation.exchangeOffice"></span>-->
    <!--</div>-->

    <div class="navigation-item" data-ui-sref="payments.currencyConversion"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('CurrencyConversion', true);"
      ng-if="navigation.countryCode != 'mne'">
      <span class="navigation-item-header-smaller" translate="navigation.exchangeOffice"></span>
    </div>

    <div class="navigation-item" data-ui-sref="payments.exchangeRates"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('ExchangeRates', true);"
      ng-if="navigation.countryCode == 'mne'">
      <span class="navigation-item-header-smaller" translate="navigation.exchangeRates"></span>
    </div>

    <div class="navigation-item"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('eInvoicesOverview', true);"
      data-ui-sref="eInvoices.einvoicesOverview" ng-if="navigation.links.eInvoiceSubscriptions">
      <span class="navigation-item-header-smaller"
        translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'navigation.eInvoicesSlo':'navigation.eInvoices'}}"></span>
    </div>

    <!--<div class="navigation-item" data-ui-sref="payments.paymentsOverview" data-ng-click="navigation.closeMobileNavigation()">
      <span class="navigation-item-header-smaller" translate="navigation.paymentsList"></span>
    </div>-->

    <!--    <div class="navigation-item">
          <span class="navigation-item-header-smaller" translate="navigation.directDebitsList"></span>
        </div>-->

    <div class="navigation-item" data-ui-sref="paymentTemplates.templateListOverview"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('TemplateListOverview', true);">
      <span class="navigation-item-header-smaller" translate="navigation.myTemplatesList"></span>
    </div>

    <div
      ng-hide="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.isResident == 'N') || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
      class="navigation-item"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('StandingOrders', true);"
      data-ui-sref="orders" ng-if="navigation.links.orders">
      <span class="navigation-item-header-smaller" translate="navigation.standingOrders"></span>
    </div>

    <div class="navigation-item" data-ui-sref="payments.paymentsReturn"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('PaymentsReturn', true);"
      ng-if="navigation.countryCode === 'hr'">
      <span class="navigation-item-header-smaller" translate="navigation.paymentsReturn"></span>
    </div>


    <!-- TODO: needs better implementation, waiting for API -->


    <div class="navigation-item" data-ui-sref="sepa.transactions"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaTransactions', true);"
      ng-if="navigation.countryCode === 'sl'">
      <span class="navigation-item-header-smaller" translate="navigation.sepaTransactions"></span>
    </div>
    <div class="navigation-item" data-ui-sref="sepa.transactions"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaTransactions', true);"
      ng-if="navigation.countryCode === 'hr'">
      <span class="navigation-item-header-smaller" translate="navigation.sepaTransactions_CRO"></span>
    </div>
    <div class="navigation-item" data-ui-sref="sepa.settings"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaSettings', true);"
      ng-if="navigation.countryCode === 'sl'">
      <span class="navigation-item-header-smaller" translate="navigation.sepa"></span>
    </div>
    <div class="navigation-item" data-ui-sref="sepa.settings-cro"
      data-ng-click="navigation.closeSecondaryNavigation(); navigation.pushGtmNavigationEvent('SepaSettings', true);"
      ng-if="navigation.countryCode === 'hr'">
      <span class="navigation-item-header-smaller" translate="navigation.sepa_CRO"></span>
    </div>
  </div>

  <!-- Loans -->
  <div ng-show="navigation.currentNavigationItem === 'Loans'">
    <div class="navigation-item"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('LoansOverview', true);"
      data-ui-sref="loansOverview">
      <span class="navigation-item-header-smaller" translate="navigation.loansOverview"></span>
    </div>

    <div class="navigation-scrollable-height" scrollable>
      <li class="no-bullet-list" data-ng-repeat="item in navigation.navigationSubItems"
        data-ui-sref="accounts({accountId: item.accountId})" data-ng-click="navigation.closeMobileNavigation()">
        <div class="navigation-item">
          <span class="navigation-item-header">
            {{ item.friendlyName | limitTo: 15 }}{{item.friendlyName.length > 15 ? '...' : ''}}
          </span>
          <i ng-if="!item.isFavorite" class="zmdi zmdi-star-outline zmdi-hc-2x pull-right favorite-icon"></i>
          <i ng-if="item.isFavorite" class="zmdi zmdi-star zmdi-hc-2x pull-right favorite-icon"></i>
          <!--<span class="navigation-item-value"-->
          <!--ng-bind="(item.loanAccount.loanAmount | number:2) + ' ' + (item.loanAccount.loanAmountCurrencySymbol)"></span>-->
          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
            <span ng-switch-when="hr">
              <amount-formatter amount="{{item.loanAccount.loanAmount| number:2}}"
                custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.loanAccount.loanAmountCurrencySymbol}}" from-type="navigation"
                aditional-val="{{item.balance| number:2}}"></amount-formatter>
              <span class="navigation-item-description" translate="navigation.allowedLoan"></span>
            </span>
            <span ng-switch-when="sl">
              <amount-formatter ng-if="item.accountType.group == 'LoanAccountType' && item.balance"
                amount="{{item.balance | number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
                currency="EUR" from-type="navigation" aditional-val="{{item.balance| number:2}}"></amount-formatter>

              <amount-formatter ng-if="item.accountType.group != 'LoanAccountType'" amount="{{item.balance | number:2}}"
                custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.loanAccount.loanAmountCurrencySymbol}}" from-type="navigation"
                aditional-val="{{item.balance| number:2}}"></amount-formatter>
              <span ng-if="item.balance" class="navigation-item-description" translate="navigation.loanBalance"></span>
            </span>
            <span ng-switch-default>
              <!--BIH, Serbia, Montenegro-->
              <amount-formatter ng-if="item.accountType.group == 'LoanAccountType' && item.balance"
                amount="{{item.balance | number:2}}" custom-classes="navigation-item-value nav-amount-lbl"
                currency="{{item.currencySymbol}}" from-type="navigation" aditional-val="{{item.balance| number:2}}">
              </amount-formatter>
              <span ng-if="item.balance" class="navigation-item-description" translate="navigation.loanBalance"></span>
            </span>
          </div>
          <!--          <span class="navigation-item-description"
                          ng-bind="(item.loanAccount.installmentAmount) + ' ' + (item.loanAccount.installmentCurrencySymbol)"></span>-->
        </div>
      </li>
    </div>
  </div>

  <!-- Settings -->
  <div ng-show="navigation.currentNavigationItem === 'Settings'">
    <div class="navigation-item" data-ui-sref="settings"
      data-ng-click="navigation.closeMobileNavigation(); navigation.pushGtmNavigationEvent('Settings', true);">
      <span class="navigation-item-header-smaller" translate="navigation.settings"></span>
    </div>
  </div>

</div>