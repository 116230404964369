<section id="main" ng-if="vm.pageLoaded">

  <div class="container-fluid m-b-40">
    <div class="row">
      <div class="col-md-12">
        <h3 translate="sepa.title"></h3>
      </div>
    </div>

    <!-- ============= -->
    <!-- START FORM    -->
    <!-- ============= -->
    <div ng-if="vm.step == 'start'" ng-form class="bg-white p-20 m-t-15 clearfix" name="vm.sepaForm" novalidate>
      <div class="row">
        <div class="col-md-6">
          <label class="domp-form-label ng-scope" translate="sepa.toAccount">To acccount</label>
          <div class="accounts-dropdown m-b-10" ng-if="vm.accountsFrom.length > 0">
            <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                 data-ng-click="vm.fromAccountDropdown = !vm.fromAccountDropdown"
                 ng-style="vm.model.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + vm.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
              <h4 class="no-wrap-text bold-font m-b-0">
                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{vm.model.fromAccountSelect.friendlyName}}</b>
                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account':vm.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!vm.model.fromAccountSelect.isFavorite}"></i>
                </span>
              </h4>
              <p class="m-b-0 m-t-0 iban-style">{{vm.model.fromAccountSelect.iban}}</p>
              <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                {{vm.model.fromAccountSelect.availableBalance| number:2}}
                {{vm.model.fromAccountSelect.currencySymbol}}
              </p>
              <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
              <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
            </div>
            <div class="from-accounts-list m-l-15" ng-show="vm.fromAccountDropdown" scrollable>
              <div class="accounts-list-item p-0" data-ng-repeat="item in vm.accountsFrom"
                   data-ng-click="vm.setAccountFrom(item);">

                <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                     ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                        <i class="zmdi"
                           ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i>
                    </span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{item.availableBalance| number:2}} {{item.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="accounts-dropdown m-b-10" ng-if="vm.accountsFrom.length == 0">

            <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                 ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
              <div class="m-t-30 m-b-30">
                <p translate="internalTransfer.nodata"></p>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="domp-form-label ng-scope" translate="sepa.settingType">Setting type</label>
            <select class="form-control input-field"
                    name="sepaType"
                    ng-model="vm.model.sepaSettingType"
                    ng-options="'sepa.executionType.' + option.id | translate for option in vm.sepaSettingsTypesOptions"
                    ng-change="vm.onSettingTypeChange(vm.model.sepaSettingType)"
                    required>
            </select>
            <p class="m-t-10 c-red" ng-if="vm.model.sepaSettingType.descriptionAlert">
              {{vm.model.sepaSettingType.descriptionAlert}}
            </p>
          </div>
        </div>
      </div>
      <hr>
      <!-- START: Date from and date to (Always shown) -->
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.dateFrom">Datum od</label>
            <div class="input-group ">
              <input type="text" class="form-control input-field"
                     id="dateFrom"
                     name="dateFrom"
                     uib-datepicker-popup="shortDate"
                     data-ng-model="vm.model.dateFrom"
                     show-weeks="false"
                     is-open="vm.dateFromDatepickerOpen"
                     datepicker-options="vm.dateOptions"
                     date-disabled="vm.datesFromDisabled(date, mode)"
                     ng-change="vm.onDateFromChange()"
                     show-button-bar="false"
                     readonly
                     required>
              <span class="input-group-btn">
                <button data-ng-click="vm.dateFromDatepickerOpen = !vm.dateFromDatepickerOpen" type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                        style="border:1px solid #062a42;">
                  <i class="icon icon-calendar" style="font-size: 21px;"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.dateTo">Datum do</label>
            <div class="input-group ">
              <input type="text" class="form-control input-field"
                     id="dateTo" name="dateTo"
                     uib-datepicker-popup="shortDate"
                     data-ng-model="vm.model.dateTo"
                     show-weeks="false"
                     is-open="vm.dateToDatepickerOpen"
                     datepicker-options="vm.dateOptions"
                     date-disabled="vm.datesToDisabled(date, mode)"
                     show-button-bar="false"
                     readonly>
              <span class="input-group-btn">
                <button data-ng-click="vm.dateToDatepickerOpen = !vm.dateToDatepickerOpen" type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                        style="border:1px solid #062a42;">
                  <i class="icon icon-calendar" style="font-size: 21px;"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Date from and date to (Always shown) -->
      <!-- START: Id primatelja, naziv primatelja (Shown if different than BLOCK_ALL) -->
      <div class="row" ng-if="vm.model.sepaSettingType.id != 'BLOCK_ALL'">
        <div class="col-md-4">
          <div class="form-group">
            <div click-outside="vm.searchFilterExpanded = false;"
                 outside-if-not="expandReceiverSearch">
              <label class="domp-form-label" translate="sepa.receiverId">
                Identifikator primatelja
              </label>
              <input type="text" name="receiverId" id="receiverId"
                     class="input-field form-control"
                     ng-class="{'domp-error': vm.formSubmitted && vm.sepaForm.receiverId.$invalid }"
                     ng-model="vm.model.receiverId"
                     ng-blur="vm.findIdInCreditorList(vm.model.receiverId)"
                     maxlength="32"
                     autocomplete="off" required>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverName">
              Naziv primatelja
            </label>
          
            <input ng-class="{'domp-error': vm.formSubmitted && vm.sepaForm.receiverName.$invalid }"
                   type="text" class="form-control input-field"
                   id="receiverName"
                   name="receiverName"
                   ng-model="vm.model.receiverName"
                   ng-readonly="vm.creditorInListExist"
                   required/>
          </div>
        </div>
      </div>
      <!-- END: Id primatelja, naziv primatelja -->
      <span ng-if="vm.model.sepaSettingType.id == 'AMOUNT' || vm.model.sepaSettingType.id == 'WHITE_LIST'">
        <!-- START: Identifikator suglasnosti, maksimalni iznos terećenja, dinamika izvršenja -->
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="domp-form-label" translate="sepa.identifierApproval">
                Identifikator suglasnosti
              </label>
              <input type="text" class="form-control input-field"
                     id="identifierApproval"
                     name="identifierApproval"
                     ng-model="vm.model.identifierApproval"
                     maxlength="35"/>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="domp-form-label" translate="sepa.dynamicExecution">Dinamika izvršenja</label>
              <select class="form-control input-field"
                      id="dynamicExecution"
                      name="dynamicExecution"
                      ng-model="vm.model.dynamicExecution"
                      ng-options="'sepa.executionPeriod.' + option.id | translate for option in vm.executionPeriodTypesOptions">
              </select>
            </div>
          </div>
        </div>
        <!-- END: Identifikator suglasnosti, dinamika izvršenja -->
        <!-- START: maksimalni iznos terećenja-->
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="domp-form-label" translate="sepa.singleChargeMaxAmount">
                Maksimalni iznos pojedinačnog terećenja
              </label>
              <input type="text" class="form-control input-field"
                     id="singleChargeMaxAmount"
                     name="singleChargeMaxAmount"
                     ng-model="vm.model.singleChargeMaxAmount"
                     amount-input-mask
                     allow-empty="true"/>
            </div>
          </div>
        </div>
        <!-- END: maksimalni iznos terećenja-->
      </span>
      <!-- BUTTONS -->
      <div class="row m-t-20">
        <div class="col-md-12">
          <button class="btn btn-primary"
                  type="button"
                  translate="sepa.cancelBtnLabel"
                  ui-sref="sepa.settings-cro">
          </button>

          <button class="btn btn-default pull-right"
                  type="submit"
                  translate="sepa.confirm"
                  ng-click="vm.submit(vm.sepaForm);"
                  ng-disabled="vm.sepaBtnDisabled || vm.settingTypeInvalid">
          </button>
        </div>
      </div>
    </div>

    <!-- ============= -->
    <!-- RESUME FORM   -->
    <!-- ============= -->
    <div ng-if="vm.step == 'resume'" class="bg-white p-20 m-t-15 clearfix">
      <div class="row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.toAccount"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.fromAccountSelect.iban }}</label>
        </div>

        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.settingType"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.sepaSettingType.description }}</label>
        </div>
      </div>
      <hr>

      <div class="row">
        <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.dateFrom"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.dateFrom | date: "shortDate" }}</label>
        </div>
        <div ng-show="vm.model.dateTo" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.dateTo"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.dateTo | date: "shortDate" }}</label>
        </div>
      </div>

      <div class="row" ng-if="vm.model.sepaSettingType.id != 'BLOCK_ALL'">
        <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.receiverId"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.receiverId }}</label>
        </div>
        <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <label class="domp-form-label-review ng-scope" translate="sepa.receiverName"></label>
          <label class="domp-form-label-resume ng-binding">{{ vm.model.receiverName }}</label>
        </div>
      </div>

      <span ng-if="vm.model.sepaSettingType.id == 'AMOUNT' || vm.model.sepaSettingType.id == 'WHITE_LIST'">
        <div class="row">
          <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12"
               ng-if="vm.model.identifierApproval != ''">
            <label class="domp-form-label-review ng-scope" translate="sepa.identifierApproval"></label>
            <label class="domp-form-label-resume ng-binding">{{ vm.model.identifierApproval }}</label>
          </div>
          <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12"
               ng-if="vm.model.dynamicExecution.description != ''">
            <label class="domp-form-label-review ng-scope" translate="sepa.dynamicExecution"></label>
            <label class="domp-form-label-resume ng-binding">{{ vm.model.dynamicExecution.description }}</label>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="domp-form-label-review ng-scope" translate="sepa.singleChargeMaxAmount"></label>
            <label class="domp-form-label-resume ng-binding">{{ vm.model.singleChargeMaxAmount | currency:"" }}</label>
          </div>
        </div>
      </span>

      <div class="row m-t-20">
        <div class="col-md-12">

          <button class="btn btn-default pull-right"
                  type="submit"
                  translate="eInvoicesModal.sign"
                  ng-click="vm.step = 'sign';">
          </button>
        </div>
      </div>
    </div>

    <!-- ============= -->
    <!-- SIGN FORM   -->
    <!-- ============= -->
    <div class="bg-white p-20 m-t-15 clearfix" ng-if="vm.step == 'sign'">
      <addiko-mtoken-sign
          show-cancel="false"
          placeholder="vm.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
          qr-challenge="vm.qrChallenge" show-challenge="vm.showChallenge" 
          challenge1="vm.challengeFirstCode" challenge2="vm.challengeSecondCode"
          action-disabled="vm.confirmSignBtnDisabled"></addiko-mtoken-sign>
    </div>


    <!-- ============= -->
    <!-- NOTE -->
    <!-- ============= -->
    <p class="p-15 bold-font" ng-if="vm.step == 'start'">
      <span translate="sepa.newSepaHint1"></span>
      <a class="cursor-pointer" style="text-decoration: underline"
            ng-href="{{APP_GLOBAL_SETTINGS.defaultData.BankChargesMain}}" target="_blank"
            translate="sepa.newSepaHint2">
      </a>
    </p>
  </div>
</section>


