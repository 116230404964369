(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name settings.factory:SoftTokenOrderService
     *
     * @description
     *
     */
    angular
        .module('settings')
        .factory('SoftTokenOrderService', SoftTokenOrderService);

    function SoftTokenOrderService($q, $http, EndpointsService, $log) {
        var SoftTokenOrderServiceBase = {};

        SoftTokenOrderServiceBase.insertOrder = function (orderObject) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("SoftTokenOrderServiceBase.insertOrder orderObject:", orderObject);
                $http.post(response.links.softTokenOrder, orderObject)
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

      SoftTokenOrderServiceBase.validateSoftTokenOrder = function (object) {
        return $q(function (resolve, reject) {
          return EndpointsService.getLinks().then(function (response) {
            $http.put(response.links.softTokenOrder_validate, object)
              .then(function (data) {
              resolve(data.data);
            })
              .catch(function (error) {
              reject(error.data);
            })
          }, function (error) {
            reject(error.data);
          });
        });
      };
        return SoftTokenOrderServiceBase;
    }
}());
