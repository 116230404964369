<div class="container invoice-card">
  <!--E DOCUMENT MAIN ROW START-->
  <div class="row invoice-card-top vertical-align pointer-cst"
       ng-click="einvoicesOverview.showEDocumentDetails(eDocument)">

    <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12 p-15">
      <div class="invoice-type">
        <div><b>{{eDocument.creditorName}}</b></div>
        <div class="text-muted"> {{eDocument.postedDate | date:'shortDate' : '+0200'}} - <span ng-if="eDocument.documentType">{{'eInvoicesModal.eDocumentType.' + eDocument.documentType | translate }}</span></div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
      <span ng-if="eDocument.amount" class="einvoice-value pull-right">{{eDocument.amount| number:2}} <span>{{eDocument.currencySymbol}}</span> </span>
    </div>
    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6 t-a-r">
      <label class="f-16 blue-label m-t-10" translate="eInvoiceStatus.{{eDocument.status}}">Status</label>
      <!--<button-->
        <!--class="btn btn-default p-5 pull-right" ng-click="einvoicesOverview.pay(eInvoice, $event)"-->
        <!--translate="eInvoiceOverview.pay">-->
      <!--</button>-->
    </div>
    <div class="col-lg-1 col-md-1 hidden-sm hidden-xs t-a-r">
      <span class="glyphicon glyphicon-chevron-down"></span>
    </div>
  </div>
  <!--E DOCUMENT MAIN ROW END -->

  <!-- E DOCUMENT DETAILS START -->
  <div class="row invoice-card-detail" ng-class="{collapse: !eDocument.isCollapsed1}">
    <div class="invoice-content container">
      <div class="row">
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuer"></span></span>
          <p>
            <b>
              {{eDocument.creditorName}}<br/>
              {{eDocument.creditorAddress}}<br/>
              {{eDocument.creditorCity}}
            </b>
          </p>
        </div>
        <div class="col-sm-6">
          <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipient"></span></span>
          <p>
            <b>
              {{eDocument.debtorName}}<br/>
            </b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 ">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.issuerAccount"></span></span>
            <b>{{eDocument.creditorAccount | formatIban}}</b>
          </p>
        </div>
        <div class="col-sm-6">
          <p>
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.recipientAccount"></span></span>
            <b>{{eDocument.debtorAccount | formatIban}}</b>
          </p>
        </div>
      </div>
    </div>

    <div class="invoice-content container">

      <!-- BUTTONS CONTAINER START -->
      <div class="row">

        <!--<div class="text-right pull-right p-r-5 p-l-0 " ng-if="eDocument.status == 'Reviewed' || eDocument.status == 'Inserted' || eDocument.status == 'Delivered' ">-->
          <!--<button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.reject(eDocument, $event)"-->
                  <!--translate="eInvoiceOverview.reject">-->
          <!--</button>-->
        <!--</div>-->
        <!--<div class="pull-right text-right p-r-5 p-l-0">-->
          <!--<button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.archiveEDocument(eDocument)"-->
                  <!--ng-disabled="eDocument.archiveEInvoiceInProgress"-->
                  <!--translate="eInvoiceDetail.withdraw">Archive-->
          <!--</button>-->
        <!--</div>-->

        <div data-ng-repeat="doc in eDocument.eInvoiceDocumentAttachmentList" class="pull-right text-right p-r-5 p-l-0">
          <button class="btn btn-default col-xs-12" ng-click="einvoicesOverview.downloadDocumentPdf(eDocument.id, doc.id)"
                  ng-disabled="einvoicesOverview.downloadButtonPressed">{{doc.name}}
          </button>
        </div>
      </div>
      <!-- BUTTONS CONTAINER END -->

      <!-- SIGNATURE CONTAINER START -->
      <div ng-class="{collapse: !collapsedSignature}">
        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title" translate="eInvoiceDetail.digitalCertNumber"></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureId"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.signatureName"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
          <div class="col-sm-6">
            <span class="invoice-inline-title"><span translate="eInvoiceDetail.certificateIssuer"></span></span>
            <p>
              <b>
                no info
              </b>
            </p>
          </div>
        </div>

      </div>
      <!-- SIGNATURE CONTAINER END -->
    </div>
  </div>
  <!-- E DOCUMENT DETAILS END -->
</div>
