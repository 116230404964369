(function () {
  'use strict';

  angular
    .module('timeline')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('timeline', {
        url: '/timeline',
        templateUrl: 'timeline/timeline.tpl.html',
        controller: 'TimelineCtrl',
        controllerAs: 'timeline'
      });
  }
}());
