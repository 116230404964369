(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      accounts:{
        "accAllAccountsOverview":"Pregled vseh računov",
        "accAccountsAndCardsOverview": "Pregled vseh računov in kartic",
        "accShowAccounts":"Prikaži več računov",
        "accFilter":"Filtriraj po tipu",
        "accAmountRange":"Razpon zneska",
        "accTo":"Do",
        "accApply":"Potrdi",
        "accReset":"Pobriši filtre",
        "accPeriod":"Obdobje",
        "accDescription":"Opis",
        "accAmount":"Znesek",
        "accBalance":"Porabljen limit",
        "accBalanceSlo":"Odobren limit",
        "accShowOnDashboard":"Prikaži na prvi strani",
        "accTransactions":"Transakcije",
        "accPdfStatements":"PDF izpiski",
        "accDownloadPdf":"Prenesi PDF",
        "accChangeName":"Sprmeni naziv računa",
        "accResetName":"Ponastavi na prvotno ime",
        "accQuickPayment":"Hitro plačilo",
        "accRequestLimit":"Naročilo spremembe limita",
        "accAvailableToSpend":"Razpoložljivo stanje",
        "accReservations":"Rezervacije",
        "accOverdraftLimit":"Limit",
        "accOverdraftLimitDue":"Datum poteka limita",
        "accPayIn":"Vplačilo",
        "accRepaymentPlan":"Plan odplačila",
        "accLoanAmount":"Odobreni znesek",
        "accLoanBalance":"Stanje kredita",
        "accInterestRate":"Obrestna mera",
        "accAnnuity":"Obrok odplačila (anuiteta)",
        "accStartDate":"Datum začetka",
        "accEndDate":"Datum zapadlosti",
        "accDisbursementDate":"Datum odobritve",
        "accDuration":"Čas trajanja",
        "accViewMore":"Prikaži več podatkov",
        "accViewLess":"Prikaži manj podatkov",
        "accTypeOfRate":"Vrsta obrestne mere",
        "accPenaltyInterests":"Zamudne obresti",
        "accOverpayments":"Preplačilo",
        "accOtherFees":"Ostale provizije",
        "accChangeDate":"Datum zadnje spremembe",
        "accAlreadySpent":"Porabljena sredstva",
        "accPayCard":"Poplačilo kartice",
        "accApprovedLimit":"Odobren limit",
        "approvedLimit": "Odobren limit",
        "accDueDate":"Dan plačila porabe",
        "accClosingDate":"Datum poteka",
        "accMonthlyDeposit amount":"N/A",
        "accStatementDeliveryMethod":"Način prejemanja izpiskov",
        "accAccountForRelease":"Račun za sprostitev sredstev",
        "accAnnualAssignment":"Letni pripis obresti",
        "accPayOut":"Izplačilo",
        "accCloseAccount":"Zapri račun",
        "accOpenNewSavingsAccount":"Odpri nov varčevalni račun",
        "accEg":"npr.",
        "accSearchTransactionsPlaceholder":"Išči transakcije",
        "accCancelDeposit":"Prekliči depozit",
        "accCancelAutoExtension":"Prekliči avtomatsko podaljševanje depozita",
        "accNewDeposit":"Nov depozit",
        "accPrincipal":"Glavnica",
        "accInterest":"Obresti",
        "accMaturityDate":"Datum zapadlosti",
        "accCurrentBalance":"Trenutno stanje",
        "accCurrentBalance_SLO":"Razpoložljivo stanje",
        "accAtmWithdrawalLimit":"Dnevni limit za dvige na bankomatu",
        "accPosPaymentLimit":"Prosti limit",
        "accDateLastChanged":"Knjiženo stanje na dan",
        "accBalanceDateLastChanged":"Datum zadnje spremembe",
        "accAvailableBalance":"Razpoložljivo stanje",
        "accSearchAccountsPlaceholder":"Išči račun",
        "accShowMatured":"Prikaži zaprte račune",
        "accAuthorizedPersons":"Pooblaščene osebe",
        "accCardStatus":"Status kartice",
        "accContractNumber":"Številka pogodbe",
        "accDepositStartDate":"Datum sklenitve",
        "accDepositStartDate_SLO":"Datum sklenitve",
        "accDepositEndDate":"Datum zapadlosti",
        "accDepositEndDate_SLO":"Datum zapadlosti",
        "rename": "Preimenuj",
        "accAccountAndCardsMoreDetails": "Prikaz podrobnosti",
        "accAccountAndCardsMoreDetails1": "Prikaz podrobnosti in kartic",
        "accAccountAndCardsLessDetails": "Skrij podatke",
        "accInstallmentsPayed":"Št. plačanih obrokov",
        "accInstallmentNumber": "Skupno število obrokov",
        "accInstallmentsRemain":"Št. preostalih obrokov",
        "accNextInstallmentDate":"Datum sledečega obroka",
        "accNextInstallmentAmount":"Znesek sledečega obroka",
        "connectedDebitCards": "Kartice na računu",
        "blockCard": "Blokiraj kartico",
        "unblockCard": "Odblokiraj kartico",
        "blockCardRequest": "Zahteva za blokado kartice",
        "unblockCardRequest": "Zahteva za deblokado kartice",
        "accDueAmount": "Skupni dolg",
        "accMatureInterest":"Obračunane obresti",
        "accNewEbook": "Nova eKnjižica",
        "closeEbook": "Zapri eKnjižico",
        "accPaymInQue": "Št. nerealiziranih čekov",
        "accReservedAmount": "Rezervirani znesek",
        "accPlanedDebit": "Rezervacija",
        "accDepositInterestRate": "Obrestna mera",
        "accDepositTypeExternal": "Vrsta depozita",
        "accDepositPrincipal": "Glavnica depozita",
        "accLimitValidUntilDate": "Datum poteka kartice",
        "accObligationAmount": "Porabljena sredstva",
        "accOutstandingAmount": "Preostali znesek za plačilo",
        "accCardNumber": "Številka kartice",
        "accInstallmentPaidNumber": "Število plačanih obrokov",
        "accNotMatureCapital": "Nedospela glavnica",
        "accNewDebitDate": "Datum sledečega obroka",
        "title": "Informacija",
        "message":"Blokada oziroma odstranitev blokade kartice prek spletne banke nista mogoči. Prosimo, kontaktirajte banko na telefon +386 1 580 43 00 ali email ebank.si@addiko.com.",
        "message_SLO":"V primeru suma zlorabe, izgube ali kraje kartice morate to takoj prijaviti procesnemu centru, na telefonsko številko +386 (0)1 58 34 183 ali najbližji poslovalnici Addiko banke oziroma kateri koli banki z oznako Mastercard.",
        "close": "Zapri",
        "accHideTransactions": "Skrij transakcije",
        "balance": "Stanje na računu",
        "accParentAccountIban": "Številka računa",
        "accAnnuityConverted": "Obrestna mera",
        "accNotMatureCapitalConverted": "Nedospela glavnica v ",
        "closeRepaymentPlan":"Zapri plan odplačevanja",
        "accHideReservations": "Skrij rezervacije",
        "depositCantBeCanceledError": "Predčasno zaprtje depozita je mogoče samo, če je bil depozit sklenjen prek spletne banke.",
        "Active":"Aktiven",
        "BlockedFromEBank":"Blokirano iz EBanke",
        "expDate":"Datum veljavnosti kartice: ",
        "expDate_SLO":"Veljavnost: ",
        "accTotalMatureConverted": "Skupen dospeli dolg ",
        "Closed":"Odpovedana",
        "eBookPayIn": "Vplačilo na eKnjižico",
        "eBookPayOut": "Izplačilo z eKnjižice",
        "Blocked":"Blokiran",
        "limitForATM": "Limit za dvig gotovine",
        "interestRate": "Obrestna mera",
        "dataNotAvailable": "Podatki niso na voljo",
        "destinationAccount": "Račun sprostitve depozita",
        "interestToDate": "Obresti na dan",
        "extensionType": "Način podaljšanja",
        "depositProlongationNumLeft": "Število preostalih podaljšanj",
        "bindingSpan": "Doba vezave",
        "accountNumber": "Številka računa",
        "untilRevocation": "Do preklica",
        "limitAmountATM": "Dnevni limit za dvige na bankomatih",
        "limitAmountPOS": "Dnevni limit za plačila na POS terminalih",
        "limitAmountNET": "Dnevni limit za plačilo preko spleta",
        "creditCardNumber": "Številka kartice ",
        "BlockedTemporary":"Začasno blokiran",
        "openingDate":"Datum otvoritve",
        "closingDate":"Datum ukinitve",
        "minSavingBalance":"Mesečni polog",
        "minSavingBalance2":"Minimalno stanje na varčevanju",
        "statementDeliveryMethod":"Način pošiljanja izpiskov",
        "annualAssignmentOfInterests":"Letna razmejitev obresti",
        "days": "dni",
        "destinationAccount_SLO": "Račun sprostitve",
        "savedFunds": "Privarčevani znesek",
        "PaidOut":"Izplačan",
        "Extended": "Podaljšan"
      },
      merchant: {
        'address': 'Adresa',
        'contact': 'Kontakt',
        'website': 'Website',
        'location': 'Lokacija',
      },
      repaymentPlan: {
        "instalment": "Obresti",
        "loanBalance": "Znesek dolga",
        "date": "Datum",
        "dateFrom":"Datum od",
        "dateTo":"Datum do",
        "principalAmount": "Glavnica",
        "paymentAmount": "Anuiteta",
        "interestAmount":"Obresti",
        "totalAnnuity":"Skupen znesek za odplačilo",
        "balance": "Stanje"
      },
      transactions: {
        "description":"Opis",
        "amount":"Znesek",
        "availableAmount":"Razpoložljivi znesek",
        "accountNumber":"Številka računa",
        "bookingDate":"Datum knjiženja",
        "valueDate":"Datum valute",
        "cardTransactionDetails":"Podrobnosti transakcije",
        "transactionDetails":"Podrobnosti transakcije",
        "close":"Zapri",
        "card":"Kartica",
        "iban":"IBAN",
        "noData":"Za izbrano obdobje ni podatkov za prikaz",
        "noStatementData":"Za izbrani račun ni podatkov za prikaz",
        "retrieval": "Vračilo",
        "ibanFrom": "IBAN plačnika",
        "accountFrom": "Z računa",
        "accountTo": "Na račun",
        "referenceNumber":"Referenca",
        "creditorName": "Plačnik",
        "debitorName": "Prejemnik",
        "creditorAddress": "Naslov plačnika",
        "debitorAddress": "Naslov prejemnika",
        "transactionAndOriginalCurrency": "Poraba v originalni valuti",
        "statementDate": "Datum izpiska",
        "creditCardNumber": "Številka kartice",
        "connCardReferenceAccount": "Referenca",
        "payeeCity": "Kraj prejemnika",
        "statementPeriod": "Datum izpiska",
        "balanceSumCredit": "Skupaj v dobro:",
        "balanceSumDebit": "Skupaj v breme:",
        "statementsFilter":"Transakcije z izpiska"

      },
      ebook: {
        "header": "Zahtjev za otvaranje nove eKnjižice",
        "date": "Datum",
        "fullName": "Ime i prezime",
        "firstName": "Ime",
        "lastName": "Prezime",
        "fromAccount": "Račun",
        "eSavingsBookName": "Naziv eKnjižice",
        "termsAndConditions": "Prihvaćam opće uvjete poslovanja",
        "confirm": "Potvrdi",
        "cancel": "Odustani",
        "dateOfCreation":"Datum kreiranja",
        "mainAccount":"Glavni račun",
        "delete":"Izbriši",
        "ebookOrder":"eKnjižica",
        "sign":"Potpiši",
        "name":"Naziv",
        "edit":"Promijeni",
        "enterName": "Unesite naziv",
        "nameError": "Naziv eKnjižice je obavezan!",
        "createEbook": "Kreiraj eKnjižicu",
        "successfullRequest": "Uspješno ste kreirali eKnjižicu",
        "completeRequest": "Zahtjev uspješan!",
        "eBookRequests": "Zahtjevi za eKnjižicu",
        "createdBy": "Kreirao",
        "iban": "IBAN",
        "requestType": "Tip zahtjeva",
        "status": "Status",
        "timelineHeader":"Vremenski prikaz zahtjeva za eKnjižice",
        "showAllRequests": "Prikaži zahteve za vse račune",
        "ebookNumber": "Račun eKnjižice"
      },
      closeEBookPageTranslation: {
        title: "Zahtjev za zatvaranje eKnjižice",
        account: "Broj eKnjižice",
        accountName: "Naziv eKnjižice",
        currency: "Valuta",
        balance: "Stanje",
        recognisedInterestRate: "Priznata kamatna stopa",
        recognisedInterestAmount: "Iznos priznate kamatne stope",
        fee: "Naknada",
        transferAmount: "Iznos za prijenos",
        transferAccount: "Račun za prijenos",
        confirmButton: "Potvrdi",
        cancelButton: "Otkaži"
      },
      accountsOverview: {
        "title": "Pregled osebnih računov in kartic",
        "newTermDepositButtonLabel": "Skleni nov depozit",
        "newESavingAccountButtonLabel": "Dodaj novo eKnjižico",
        "newESavingAccountButtonLabel_SLO": "Skleni novo varčevanje",
        "newSavingButtonLabel_SLO": "Naročilo nove Mastercard kartice",
        "masterCardStateButtonLabel_SLO": "Stanje na predplačniški Mastercard kartici"
      },
      depositAccountsOverview: {
        "title": "Pregled depozitov in varčevanj",
        "titleForSlo": "Pregled depozitov in varčevanj",
        "titleForSerbAndMne": "Pregled vseh depozitov"
      },
      laonsOverview: {
        title: "Pregled kreditov",
        noData: "Ni podatkov za prikaz."
      },
      cashWithdrawalTranslate: {
        title: "Napoved dviga višjega zneska gotovine",
        infoNote1sl: "Za evro (EUR) je potrebno napovedati dvig gotovine za znesek v višini 3.000,00 EUR in več. Za valute: švicarski frank (CHF), britanski funt (GBP), ameriški dolar (USD) in hrvaška kuna (HRK) pa je potrebno napovedati dvig gotovine ne glede na višino zneska. Naročilo je potrebno oddati najmanj dva delovna dneva pred nameravanim dvigom gotovine do 10. ure dopoldne.",
        infoNote2sl: "",
        fromAccount: "Z računa",
        amount: "Znesek",
        amountRequired: "Vnos zneska je obvezen!",
        dateOfPayout: "Datum dviga",
        phoneNumber: "Telefonska številka",
        placeOfCashTakeover: "Poslovalnica",
        placeOfCashTakeoverRequiredMsg: "Izbira poslovalnice je obvezna!",
        note: "Opomba",
        note_SLO: "Gotovino želim prejeti v bankovcih določene višine:",
        confirmBtnLabel: "Potrdi",
        cancelBtnLabel: "Prekliči",
        amountMinAmountError: "Minimalni znesek je ",
        until: "Do 13:00 ure",
        after: "Po 13:00 uri",
        timeOfPayout: "Ura dviga",
        "info": "Seznanjen sem, da bo banka z namenom ocenjevanja moje kreditne sposobnosti in ugotavljanjem kreditnega tveganja opravila poizvedbo v sistemu SISBON."
      },
      reservations: {
        "reservationDate":"Datum rezervacije",
        "transactionType":"Tip transakcije"
      },
      changeWithdrawal: {
        "header":"Sprememba dnevnega limita kartice računa",
        "infoNote1":"Banka bo v naslednjih dneh obravnavala vaše naročilo za pridobitev informacije o možnosti odobritve izrednega limita na transakcijskem računu. O poteku postopka boste v roku 8 delovnih dni obveščeni s strani vašega skrbnika. Stroški odobritve limita se obračunajo po vsakokrat veljavnem",
        "infoNote2":"",
        "infoNote3":"Ceniku storitev Addiko Bank d.d.",
        "fromAccount":"Za račun",
        "amount":"Želena višina prekoračitve sredstev",
        "time":"Za obdobje",
        "spremembo":"Spremembo želim",
        "cancelBtnLabel":"Prekliči",
        "confirmBtnLabel":"Potrdi",
        "amountRequired": "Vnos zneska je obvezen",
        "6months":"6 mesecev",
        "12months":"12 mesecev",
        "option1":"Takoj",
        "option2":"Po poteku obstoječe dovoljene prekoračitve sredstev"
      },
      reorderPinAccounts: {
        "header":"Ponovno naročilo PIN-a za kartico računa",
        "infoNote1":"Ponovno naročilo PIN-a za izbrano kartico računa bo izvedeno najkasneje naslednji delovni dan.",
        "infoNote2" : "Stroški ponovnega naročila PINa se obračunajo po vsakokrat veljavnem ",
        "infoLink" : "Ceniku storitev Addiko Bank d.d",
        "additionalText" : "Če se boste odločili za prevzem PIN-a preko SMS-a, na številko 030 400 405 pošljite besedilo sestavljeno iz ključne besede ABSPIN, vaše davčne številke in zadnjih 4 številk vaše kartice: ABSPIN XXXXXXXX XXXX. S strani pošiljatelja \"Addiko Bank\" v odgovor prejmete 4-mestno številko - PIN za vašo kartico.",
        "nameSurname": "Ime",
        "fromAccount":"Za račun",
        "desiredAmount": "Želena višina limita (v EUR)",
        "pinReceiptMethod": "Način prejema PIN-a",
        "for": "Za",
        "nameSurnameRequired":"Ime in priimek sta obvezna!",
        "cardType": "Vrsta debetne kartice"
      },
    changeATMWithdrawal: {
      "header": "Sprememba dnevnega limita kartice računa",
      "infoNote1": "Banka bo vašo vlogo obravnavala v naslednjih dneh. O poteku postopka v zvezi z vašo vlogo boste obveščeni s strani vašega skrbnika.",
      "nameSurname": "Ime",
      "desiredAmount": "Želena višina limita (v EUR)",
      "for": "Za",
      "nameSurnameRequired":"Ime in priimek sta obvezna!",
      "cardType": "Vrsta debetne kartice"
    },
      savingsSlo: {
        "title":"Open Savings account",
        "infoNote1":"Info 1",
        "infoNote2":"Info 2",
        "infoNote3":"Info 3",
        "vrstaVracevanja":"Vrsta varcevanja",
        "durationInMonths":"Duration in months",
        "maturityDate":"Matrurity date",
        "transfersFromAndToAccount":"Transfers from and to account",
        "initialAmountFromAccount":"Initial amount from account",
        "initialAmount":"Initial account",
        "montlyDepositAmount":"Monthly deposit amount",
        "standingOrderDay":"Standing order day",
        "realInterestRate":"Real interest day",
        "totalInterestRate":"Total interest day",
        "termsAndCondtionsInfo":"I certify that I am aware of Gerenal term and conditions of savings and I agree with them.",
        "info":"Stringam se, da v primeru, če na dogovrjeni....",
        "cancel":"Cancel",
        "confirm":"Confirm"

      },
      masterCard: {
        "header":"Informacija o možnosti pridobitve Mastercard kartice",
        "info":"Banka bo v naslednjih dneh obravnavala vaše naročilo za pridobitev informacije o možnosti pridobitve Mastercard kartice. O poteku postopka v zvezi z vašim naročilom boste obveščeni s strani vašega skrbnika. Stroški se obračunajo po vsakokrat veljavnem ",
        "infoPrice":"Ceniku storitev Addiko Bank d.d.",
        "infoLink0":"Več informacij o obročni Mastercard kartici",
        "infoLink1":"Več informacij o plačilni Mastercard kartici",
        "infoLink2":"Več informacij o zlati obročni Mastercard kartici",
        "infoLink3":"Več informacij o kreditni Mastercard kartici",
        "cardType":"Tip kartice",
        "creditLimit":"Želeni znesek limita",
        "account":"Račun",
        "paymentDay":"Dan bremenitve",
        "cancel":"Prekliči",
        "confirm":"Potrdi",
        "amount":"Želeni znesek limita",
        cardTypes: {
          "charge":"Addiko plačilna Mastercard kartica",
          "gold":"Addiko zlata Mastercard kartica",
          "revolving":"Addiko kreditna Mastercard kartica",
        },
        "percentType":"Delež obveznosti mesečno"
      },
      masterCardOrder: {
        "orderLimit":"Spremeni limit",
        "reorderPIN":"Ponovno naročilo PIN-a",
        "header":"Informacija o možnosti spremembe limita na Mastercard kartici",
        "info1": "Limit porabe na Mastercard kartici lahko spremenite za določeno ali nedoločeno časovno obdobje. Informacijo o možnosti spremembe limita porabe lahko pridobite samo za svoj kartični račun. O poteku postopka v zvezi z vašim naročilom boste v roku 8 delovnih dni od dneva oddaje obveščeni s strani vašega skrbnika. Stroški pristopa k storitvi se obračunajo po vsakokrat veljavnem",
        "infoLink": "Ceniku storitev Addiko Bank d.d.",
        "info2":"",
        "cardAccount":"Kartični račun",
        "cardType":"Tip kartice",
        "cardNumber":"Številka plačilne kartice",
        "desiredLimit":"Želeni znesek limita",
        "cancel":"Prekliči",
        "confirm":"Potrdi",
        "amount":"Znesek",
        "limitType":"Vrsta limita",
        "dateFrom":"Od",
        "dateTo":"Do",
        "percentage":"Odstotek od limita osnovne kartice",
        "additional":"Dodatna",
        "basic":"Osnovna"
      },
      reorderPinMasterCard: {
        "orderLimit":"Spremeni limit",
        "reorderPIN":"Ponovno naročilo PIN-a",
        "header":"Ponovno naročilo PIN-a za kartico Mastercard",
        "info1": "Ponovno naročilo PIN-a za izbrano kartico Mastercard bo izvedeno najkasneje naslednji delovni dan.",
        "infoLink": "Ceniku storitev Addiko Bank d.d.",
        "info2": "Stroški ponovnega naročila PINa se obračunajo po vsakokrat veljavnem ",
        "additionalText": "Če se boste odločili za prevzem PIN-a preko SMS-a, na številko 030 400 405 pošljite besedilo sestavljeno iz ključne besede ABSPIN, vaše davčne številke in zadnjih 4 številk vaše kartice: ABSPIN XXXXXXXX XXXX. S strani pošiljatelja \"Addiko Bank\" v odgovor prejmete 4-mestno številko - PIN za vašo kartico.",
        "cardAccount":"Kartični račun",
        "cardType":"Tip kartice",
        "cardNumber":"Številka plačilne kartice",
        "desiredLimit":"Želeni znesek limita",
        "pinReceiptMode":"Način prejema PIN-a",
        "cancel":"Prekliči",
        "confirm":"Potrdi",
        "amount":"Znesek",
        "limitType":"Vrsta limita",
        "dateFrom":"Od",
        "dateTo":"Do",
        "percentage":"Odstotek od limita osnovne kartice",
        "additional":"Dodatna",
        "basic":"Osnovna"
      }
    };

    $translateProvider
      .translations('sl', data);

  }
}());
