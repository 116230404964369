<ng-form name="vm.refundForm">
  <section id="main">
    <div class="container">
      <div class="exchange-rate-header m-b-10">
        <div class="row" ng-switch="vm.type">
          <div class="col-sm-12 f-20" ng-switch-when="refund" translate="sepa.refundComplaint.refundTitle"></div>
          <div class="col-sm-12 f-20" ng-switch-when="complaint" translate="sepa.refundComplaint.complaintTitle"></div>
        </div>
      </div>
      <!-- STEP 1 -->
      <div class="trans-complain-container">
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.paymentStatus"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{'sepa.sepaStatus.'+vm.model.status | translate}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.executionDate"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.paymentDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.payersAccNumber"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.senderAccount}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.recipient"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.receiverName}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.recAccNumber"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.receiverAccount}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.uniqueMandateReference"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.mandateReference}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.recIdentifier"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.receiverIdentificator}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.recRefNumber"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.receiverReference}}</label>
          </div>
        </div>
        <div class="row m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="reclamation.amount"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.amount | currency:""}}
              {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}</label>
          </div>
        </div>
        <div class="row  m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.valuteDate"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.valuteDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
        <div class="row  m-t-10">
          <div class="col-md-6">
            <label class="po-desc-label" translate="sepa.refundComplaint.paymentPurpose"></label>
          </div>
          <div class="col-md-6">
            <label class="po-value-label">{{vm.model.purpose}}</label>
          </div>
        </div>
        <!-- STEP 1-->
        <div ng-if="vm.formStep == 1">
          <div class="row m-t-10">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <div ng-switch="vm.type">
                  <label class="po-desc-label" ng-switch-when="refund" translate="sepa.refundComplaint.reasonForRefund"></label>
                  <label class="po-desc-label" ng-switch-when="complaint" translate="sepa.refundComplaint.reasonForComplaint"></label>
                </div>
                <select class="form-control" ng-model="vm.inputModel.reasonForcomplaint"
                        name="reasonForComplaint"
                        ng-options="reason.description for reason in vm.complaintReasonTypes"
                        ng-class="{'domp-error': vm.refundForm.reasonForComplaint.$invalid && vm.formSubmitted}"
                        required>
                </select>
              </div>
            </div>
          </div>
          <div class="row" ng-if="vm.inputModel.reasonForcomplaint.id == 'DOD_OBRAZ'">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="po-desc-label" translate="sepa.refundComplaint.additionalExplanation"></label>
                <textarea ng-pattern-restrict="{{vm.regexPatterns.allowedCharsDPStextArea}}"
                          ng-model="vm.inputModel.extra"
                          name="extra"
                          style="resize: none;"
                          class="form-control"
                          id="reclamationInput"
                          cols="30"
                          rows="5">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- STEP 2-->
        <div ng-if="vm.formStep == 2">
          <div class="row m-t-10">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <div ng-switch="vm.type">
                  <label class="po-desc-label" ng-switch-when="refund" translate="sepa.refundComplaint.reasonForRefund"></label>
                  <label class="po-desc-label" ng-switch-when="complaint" translate="sepa.refundComplaint.reasonForComplaint"></label>
                </div>
                <br>
                <label class="po-value-label">{{vm.inputModel.reasonForcomplaint.description}}</label>
              </div>
            </div>
          </div>
          <div class="row" ng-if="vm.inputModel.reasonForcomplaint.id == 'DOD_OBRAZ' && vm.inputModel.extra">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="po-desc-label" translate="sepa.refundComplaint.additionalExplanation"></label>
                <br>
                <label class="po-value-label">{{vm.inputModel.extra}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTONS -->
    <div ng-if="vm.formStep == 1" class="row m-t-10">
      <div class="col-sm-12 c-red bold-font">
        <div class="col-lg-3 col-sm-6 ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0"
                  translate="sepa.refundComplaint.cancel"
                  ui-sref="sepa.transactions({selectedTab:vm.status})">

          </button>
        </div>
        <div class="col-lg-3 col-sm-6 pull-right ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0 pull-right"
                  translate="sepa.refundComplaint.next"
                  ng-disabled="vm.confirmButtonDisabled"
                  ng-click="vm.next()">
          </button>
        </div>
      </div>
    </div>
    <div ng-if="vm.formStep == 2" class="row m-t-10">
      <div class="col-sm-12 c-red bold-font">
        <div class="col-lg-3 col-sm-6 ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0"
                  translate="orders.back"
                  ng-click="vm.back();">
          </button>
        </div>
        <div class="col-lg-3 col-sm-6 pull-right ">
          <button class="btn btn-default app-btn-blue domp-sign-btn m-0 pull-right"
                  translate="domesticPayment.confirmBtn"
                  ng-disabled="vm.confirmButtonDisabled"
                  ng-click="vm.confirm()">
          </button>
        </div>
      </div>
    </div>
  </section>
</ng-form>
