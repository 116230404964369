(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:capitalize
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="core">
   <file name="index.html">
   <capitalize></capitalize>
   </file>
   </example>
   *
   */
  angular
    .module('core')
    .directive('capitalize', capitalize);

  function capitalize() {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, modelCtrl) {
        var capitalize = function (inputValue) {
          if (inputValue == undefined) inputValue = '';
          var capitalized = inputValue.toUpperCase();
          if (capitalized !== inputValue) {
            modelCtrl.$setViewValue(capitalized);
            modelCtrl.$render();
          }
          return capitalized;
        };
        modelCtrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]); // capitalize initial value
      }
    };
  }
}());




