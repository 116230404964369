(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SecurityCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('SecurityCtrl', SecurityCtrl);

  function SecurityCtrl($scope, $q, $rootScope, $translate, $sce, $uibModal, $state, $stateParams, CreditAccountRegisterService, $localStorage, ValidationService, CountriesService, SettingsService, HelperService, AlertService, AccountsService, GoogleTagManagerHelperService, NotificationService) {

    var vm = this;

    var _token;

    vm.tempDompObj = {};
    vm.challengeResponse = '';
    vm.challenge = '';
    vm.entrySubmitted = false;
    vm.isSecurityAcceptedFormShow = false;
    vm.countryCode = $localStorage.code;
    vm.togglePaymentAmountsLoading = false;
    vm.savePaymentAmountsChangesLoading = false;

    vm.toggleLocationAndCurrencyLoading = false;
    vm.saveLocationAndCurrencyChangesLoading = false;

    vm.toggleWhiteListLoading = false;
    vm.toggleBlackListLoading = false;

    vm.addSecuritySettingLoading = false;

    vm.countrySearchFilter = '';
    vm.countrySearchFilterExpanded = false;
    vm.bankCountrySelect = {};
    vm.formSubmitted = false;
    vm._securitySettings = {};
    vm.securitySettingsLoaded = true;

    vm.paymentRestrictionHeading = $translate.instant('security.paymentRestrictions.paymentRestrictionHeading');
    vm.paymentRestrictionBody = $translate.instant('security.paymentRestrictions.paymentRestrictionBody');
    vm.infoText = $translate.instant('security.paymentRestrictionsPageInfoEBank');
    vm.challengeDescrioption = $translate.instant('signPayments.challengeDescription');
    vm.accountOfReceiverTranslate = $translate.instant('security.paymentRestrictions.accountNumber');

    vm.inputOptions = {
      minLengthOld: -1,
      maxLength: -1,
      minLength: -1,
      checkForValidation: false
    };


    vm.selectTab = function (tab, translateLabel) {
      if (tab == vm.selectedTab) return;
      vm.selectedTab = tab;
      if (vm.selectedTab === "eBank") {
        vm.infoText = $translate.instant('security.paymentRestrictionsPageInfoEBank');
      } else {
        vm.infoText = $translate.instant('security.paymentRestrictionsPageInfoMBank');
      }
      init(vm.selectedTab);
      // loadData();
    }

    vm.onCountrySelect = function (country) {
      vm.countrySearchFilter = country.code + " " + country.description;
      vm.bankCountrySelect = country;
      vm.countrySearchFilterExpanded = false;
    };

    vm.logout = function () {

        SettingsService.securitySettingsLogout(_token)
        .then(function (response) {
          if (response)
          $state.go('securitySelectCompany');
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.checkForValidation = function () {
      vm.inputOptions.checkForValidation = true;
    };

    init();

    function init(_selectedTab) {
      vm.qrChallenge = ''; // NOTE: Initial state added to avoid jumping screen from one state to another
      $rootScope.$on('$translateChangeSuccess', function () {
        vm.paymentRestrictionHeading = $translate.instant('security.paymentRestrictions.paymentRestrictionHeading');
        vm.paymentRestrictionBody = $translate.instant('security.paymentRestrictions.paymentRestrictionBody');
        vm.accountOfReceiverTranslate = $translate.instant('security.paymentRestrictions.accountNumber');
        if (vm.selectedTab === "eBank") {
          vm.infoText = $translate.instant('security.paymentRestrictionsPageInfoEBank');
        } else {
          vm.infoText = $translate.instant('security.paymentRestrictionsPageInfoMBank');
        }

        vm.challengeDescrioption = $translate.instant('signPayments.challengeDescription');
      });

      if ($stateParams.token) {
        _token = $stateParams.token;
      } else {
        $state.go('securitySelectCompany');
      }

      vm.model = {};
      vm.model.paymentAmounts = {};
      vm.model.whiteList = {};
      vm.model.blackList = {};
      vm.model.locationAndCurrency = {};
      vm.model.securitySettingsUsage = {};
      vm.model.whiteAndBlackListAddType = 'IBAN'

      vm.model.userSettings = {};
      vm.model.security = {};
      vm.model.security.transactionConfirmation = "0";
      vm.model.userSettingsEditInput = {};
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

      var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
      vm.country = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      vm.selectedTab = _selectedTab ? _selectedTab : "eBank";

      loadData();
    }

    vm.onPaymentAmountsCheckboxToggle = function (value) {
      if (vm.togglePaymentAmountsLoading) {
        return;
      }
      vm.togglePaymentAmountsLoading = true;

      var data = {
        type: "AmountLimit",
        on: vm.model.paymentAmounts.checked,
        token: _token,
        serviceId: vm.selectedTab
      };

      SettingsService.securitySettingsSettingsOnOff(data)
      .then(function (response) {
        vm.model.paymentAmounts.totalDailyAmount = '';
        vm.model.paymentAmounts.individualAmount = '';
        loadsecuritySettingsAll();
        vm.togglePaymentAmountsLoading = false;
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
        vm.model.paymentAmounts.checked = false;
        vm.togglePaymentAmountsLoading = false
      });
    }

    vm.showWhiteListBlackListErrorMessage = function (value) {
      if (value === 'white' && vm.model.blackList.checked) {
        var message = $translate.instant('security.paymentRestrictions.warningWhiteList');
        NotificationService.showTranslatedMessage('error', message, '', 3000);
      } else if (value === 'black' && vm.model.whiteList.checked) {
        var message = $translate.instant('security.paymentRestrictions.warningBlackList');
        NotificationService.showTranslatedMessage('error', message, '', 3000);
      }
    };

    vm.getReceiverNameByIBAN = function (_iban) {
      _iban = _iban.split(' ').join('');
      CreditAccountRegisterService.getByIban(_iban)
      .then(function (response) {
        if (response && response.result && response.result.length > 0) {
          vm.model.nameOfBen = response.result[0].receiverName;
        }
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.removeRestriction = function (_id) {
      var data = {
        id: _id,
        token: _token
      };

      SettingsService.securitySettingsDelete(data)
      .then(function (response) {
        loadsecuritySettingsAll();
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.onWhiteListCheckboxToggle = function (value) {
      if (vm.model.blackList.checked) {
        setTimeout(function () {
          vm.model.whiteList.checked = false;
        }, 50);

        return;
      }
      if (vm.toggleWhiteListLoading || vm.toggleBlackListLoading) {
        return;
      }
      vm.toggleWhiteListLoading = true;

      var data = {
        type: "WhiteList",
        on: vm.model.whiteList.checked,
        token: _token,
        serviceId: vm.selectedTab
      };

      vm.model.whiteAndBlackListAddType = 'IBAN';
      vm.model.selectedCountry = '';

      SettingsService.securitySettingsSettingsOnOff(data)
      .then(function (response) {
        vm.model.whiteAndBlackListAddType = 'IBAN';
        vm.bankCountrySelect = {};
        loadsecuritySettingsAll();
        vm.toggleWhiteListLoading = false;
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
        vm.model.whiteList.checked = false;
        vm.toggleWhiteListLoading = false
      });
    }

    vm.onBlackListCheckboxToggle = function (value) {
      if(vm.model.whiteList.checked) {
        setTimeout(function () {
          vm.model.blackList.checked = false;
        }, 50);
        return;
      }

      if (vm.toggleBlackListLoading || vm.toggleWhiteListLoading) {
        return;
      }
      vm.toggleBlackListLoading = true;

      vm.model.whiteAndBlackListAddType = 'IBAN';
      vm.model.selectedCountry = {};

      var data = {
        type: "BlackList",
        on: vm.model.blackList.checked,
        token: _token,
        serviceId: vm.selectedTab
      };

      SettingsService.securitySettingsSettingsOnOff(data)
      .then(function (response) {
        vm.model.whiteAndBlackListAddType = 'IBAN';
        vm.bankCountrySelect = {};
        loadsecuritySettingsAll();
        vm.toggleBlackListLoading = false;
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
        vm.model.blackList.checked = false;
        vm.toggleBlackListLoading = false;
      });
    }

    vm.whiteAndBlackListAddTypeChange = function (value) {
      vm.model.ibanOfBen = '';
      vm.model.nameOfBen = '';
      vm.model.accountNumber = '';
      vm.bankCountrySelect = {};
      vm.model.searchCountry = '';
      vm.entrySubmitted = false;
    }

    vm.onLocationAndCurrencyCheckboxToggle = function (value) {
      if (vm.toggleLocationAndCurrencyLoading) {
        return;
      }
      vm.toggleLocationAndCurrencyLoading = true;
      var data = {
        type: "Area",
        on: vm.model.locationAndCurrency.checked,
        token: _token,
        serviceId: vm.selectedTab
      };
      vm.toggleLocationAndCurrencyLoading = false;

      SettingsService.securitySettingsSettingsOnOff(data)
      .then(function (response) {
        // vm.settings.model.locationAndCurrency.subTypeId = {};
        loadsecuritySettingsAll()
        vm.toggleLocationAndCurrencyLoading = false;
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
        vm.model.locationAndCurrency.checked = false;
        vm.toggleLocationAndCurrencyLoading = false;
      });
    };

    vm.accessRestrictions = function () {

      var data = {
        additionalSignData: vm.challengeResponse
      }

      if (vm.additionalChallenge) {
        data.signData = vm.additionalChallenge;
      }

      SettingsService.securitySettingsCheckTransactionVerificationResponse(data)
        .then(function (_checkTransactionVerificationResponse) {
          _token = _checkTransactionVerificationResponse;

          SettingsService.securitySettingsIsSecurityAccepted(_token)
          .then(function (_IsSecurityAcceptedResponse) {

            if (_token &&
                _IsSecurityAcceptedResponse &&
                _IsSecurityAcceptedResponse.typeId === 'ACCEPTED' &&
                _IsSecurityAcceptedResponse.subTypeId==='USAGE_CONFIRMED') {

                $state.go('security', {
                  token: _token
                });
            } else {
              vm.isSecurityAcceptedFormShow = true;
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.accessRestrictionsFinally = function () {
      SettingsService.securitySettingsSetSecurityAccepted(_token)
      .then(function (_setSecurityResponse) {
        $state.go('security', {
          token: _setSecurityResponse
        });
      })
      .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.cancelPaymentRestriction = function () {
      // _token = "";
      $state.go('dashboard');
    };

    vm.addSettingToList = function (form) {
      vm.entrySubmitted = true;
      if (vm.addSecuritySettingLoading || form.invalid ||
        (vm.model.whiteAndBlackListAddType === 'IBAN' && (vm.model.ibanOfBen === '' || vm.model.nameOfBen === '')) ||
        (vm.model.whiteAndBlackListAddType === 'ACCOUNT_NUMBER' && (vm.model.accountNumber === '' || vm.model.nameOfBen === '')) ||
        (vm.model.whiteAndBlackListAddType === 'COUNTRY' && vm.countrySearchFilter === '')
        ) {
        return;
      }
      var data = {
        type: (vm.model.whiteList.checked) ? 'WhiteList' : 'BlackList',
        token: _token,
        serviceId: vm.selectedTab
      };

      switch (vm.model.whiteAndBlackListAddType) {
        case 'IBAN':
          data.iban = vm.model.ibanOfBen;
          data.payeeName = vm.model.nameOfBen;
          break;
        case 'ACCOUNT_NUMBER':
          data.accountNumber = vm.model.accountNumber;
          data.payeeName2 = vm.model.nameOfBen;
          break;
        case 'COUNTRY':
          data.countryCode = (vm.bankCountrySelect && vm.bankCountrySelect.code) ? vm.bankCountrySelect.code : '';
          break;
      }

      vm.addSecuritySettingLoading = true;

      SettingsService.securitySettingsPost(data)
      .then(function (response) {
        vm.model.ibanOfBen = '';
        vm.model.nameOfBen = '';
        vm.model.accountNumber = '';
        vm.model.selectedCountry = {};
        vm.bankCountrySelect = {}
        vm.model.searchCountry = '';
        vm.addSecuritySettingLoading = false;
        vm.entrySubmitted = false;
        loadData();
      })
      .catch(function (error) {
        vm.addSecuritySettingLoading = false;
        NotificationService.showMessage(error, 'error');
      });
    }

    vm.removeSelectedCountry = function () {
      vm.countrySearchFilter = "";
      vm.bankCountrySelect = {};
      vm.countrySearchFilterExpanded = true;
    };

    vm.savePaymentAmountsInput = function () {
      if (vm.savePaymentAmountsChangesLoading) {
        return;
      }
      vm.savePaymentAmountsChangesLoading = true;

      var data = {
        type: 'AmountLimit',
        token: _token,
        serviceId: vm.selectedTab
      };
      if (vm.model.paymentAmounts.individualAmount) {
        data.amount = vm.model.paymentAmounts.individualAmount;
      }
      if (vm.model.paymentAmounts.totalDailyAmount) {
        data.amountDaily = vm.model.paymentAmounts.totalDailyAmount;
      }

      SettingsService.securitySettingsPost(data)
      .then(function (response) {
        vm.savePaymentAmountsChangesLoading = false;
      })
      .catch(function (error) {
        vm.savePaymentAmountsChangesLoading = false;
        NotificationService.showMessage(error, 'error');
      });
    }

    vm.saveLocationAndCurrencySelection = function () {
      if (vm.saveLocationAndCurrencyChangesLoading) {
        return;
      }
      vm.saveLocationAndCurrencyChangesLoading = true;

      var data = {
        type: 'Area',
        subTypeId: vm.model.locationAndCurrency.subTypeId,
        token: _token,
        serviceId: vm.selectedTab
      };

      SettingsService.securitySettingsPost(data)
      .then(function (response) {
        vm.saveLocationAndCurrencyChangesLoading = false;
      })
      .catch(function (error) {
        vm.saveLocationAndCurrencyChangesLoading = false;
        NotificationService.showMessage(error, 'error');
      });
    }

    function loadsecuritySettingsAll() {
      SettingsService.securitySettingsAll(100000, _token, vm.selectedTab)
      .then(function (response) {
        vm.model.locationAndCurrency.subTypeId = {};
        // vm.model.locationAndCurrency.checked = false;
        // vm.model.paymentAmounts.checked = false;
        vm.model.paymentAmounts.individualAmount = '';
        vm.model.paymentAmounts.totalDailyAmount = '';
        // vm.model.paymentAmounts = {};
        vm.model.whiteList.list = [];
        vm.model.blackList.list = [];
        var temp_securitySettings = response;
        _.each(temp_securitySettings.result, function (singleSetting) {

          switch (singleSetting.type) {
            case 'AmountLimit':
              vm.model.paymentAmounts.checked = true;
              vm.model.paymentAmounts.individualAmount = singleSetting.amount;
              vm.model.paymentAmounts.totalDailyAmount = singleSetting.amountDaily;
              break;
            case 'Area':
              vm.model.locationAndCurrency.checked = true;
              vm.model.locationAndCurrency.subTypeId = singleSetting.subTypeId
              break;
            case 'WhiteList':
              if (singleSetting.isDeleted === 'N') {
                vm.model.whiteList.checked = true;
                vm.model.whiteList.list.push(singleSetting);
              }
              break;
            case 'BlackList':
              if (singleSetting.isDeleted === 'N') {
                vm.model.blackList.checked = true;
                vm.model.blackList.list.push(singleSetting);
              }
              break;
          }
        });
      })
      .catch(function (error) {
        vm.saveLocationAndCurrencyChangesLoading = false;
        NotificationService.showMessage(error, 'error');
      });
    }

    function loadData() {
      var promises = [];
      promises.push(CountriesService.getPage({pageSize: 1000}));
      if (!_token) {
        if (vm.countryCode == 'hr') {
          promises.push(SettingsService.securitySettingsGetChallenges());
        } else {
          promises.push(SettingsService.securitySettingsGetChallenge());
        }
      } else {
        promises.push(SettingsService.securitySettingsAll(100000, _token, vm.selectedTab));
      }

      $q.all(promises)
        .then(function (responses) {
          vm.countriesOptions = responses[0].result;
          if (!_token) {
            if (vm.countryCode == 'hr') {
              var challengeResponse = responses[1];
              if (challengeResponse.qrChallenge) {
                vm.challengeFirstCode = challengeResponse.challenge1;
                vm.challengeSecondCode = challengeResponse.challenge2;
                vm.qrChallenge = challengeResponse.qrChallenge;
                vm.showChallenge = false; // NOTE: This is canceled in order to prevent additionalData being sent on signSinglePayment() method
              } else {
                vm.qrChallenge = null;
                vm.challenge = challengeResponse.challenge1;
                vm.challenge2 = challengeResponse.challenge2;
                vm.showChallenge = true;
              }
            } else {
              vm.challenge = responses[1];
            }
          } else {

            vm._securitySettings = responses[1];

            vm.model.whiteList.list = [];
            vm.model.blackList.list = [];

            _.each(vm._securitySettings.result, function (singleSetting) {

              switch (singleSetting.type) {
                case 'AmountLimit':
                  vm.model.paymentAmounts.checked = true;
                  vm.model.paymentAmounts.individualAmount = singleSetting.amount;
                  vm.model.paymentAmounts.totalDailyAmount = singleSetting.amountDaily;
                  break;
                case 'Area':
                  vm.model.locationAndCurrency.checked = true;
                  vm.model.locationAndCurrency.subTypeId = singleSetting.subTypeId
                  break;
                case 'WhiteList':
                  if (singleSetting.isDeleted === 'N') {
                    vm.model.whiteList.checked = true;
                    vm.model.whiteList.list.push(singleSetting);
                  }
                  break;
                case 'BlackList':
                  if (singleSetting.isDeleted === 'N') {
                    vm.model.blackList.checked = true;
                    vm.model.blackList.list.push(singleSetting);
                  }
                  break;
              }
            });
          }

          vm.isPageLoaded = true;
        }, function (error) {
          console.log(error);
        })
    }

    // SecurityCtrl END
  }
}());
