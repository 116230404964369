<div class="modal-header smsModalHeader">
  <div class="row">
    <div class="col-xs-11" translate="statementDeliveryModal.modalTitle"></div>
    <div class="col-xs-1">
      <i class="icon icon-add pull-right close-icon-x" ng-click="$close(false)"  style="font-size: 40px;"></i>
    </div>

  </div>
</div>

<div class="p-t-20">
  <div class="modal-body statementDeliveryModal">
    <div class="row m-0">
      <div class="col-xs-12"> 
        <p class="domp-form-label-review" translate="statementDeliveryModal.message1"></p> <br>
        <p class="domp-form-label-review" translate="statementDeliveryModal.message2"></p> <br>
        <p class="domp-form-label-review" translate="statementDeliveryModal.message3"></p> <br>
        <p class="domp-form-label-review" translate="statementDeliveryModal.message5"></p> <br>
      </div>
    </div>
    <div class="row p-b-10 m-0">
      <div class="col-xs-12 col-md-6">
          <div class="form-group">
            <label class="domp-form-label-review f-700" translate="statementDeliveryModal.chooseChannel"></label>
            <select class="f-16 form-control-db text-center"
                  ng-options="option.translateKey | translate for option in vm.deliveryTypes"
                  ng-default="'Izaberite kanal'"
                  ng-model="vm.statementDeliveryModel">
            </select>
          </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-xs-12">
        <label class="domp-form-label-review" translate="statementDeliveryModal.message4"></label> <br><br>
      </div>
    </div>
  </div>

  <div class="modal-footer text-left">
    <div class="col-xs-12">
      <button class="btn btn-default m-5" ng-click="$close(vm.statementDeliveryModel);" translate="statementDeliveryModal.confirm">
      </button>
      <button class="btn btn-default m-5" ng-click="$close({value:0});" translate="statementDeliveryModal.refuse"></button>
      
    </div>

  </div>
</div>
