(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name orders.service:SavingsOrdersService
   *
   * @description
   *
   */
  angular
    .module('orders')
    .service('SavingsOrdersService', SavingsOrdersService);

  function SavingsOrdersService($rootScope, $http, $q, EndpointsService, HelperService) {

    var SavingsOrdersServiceBase = {};

    SavingsOrdersServiceBase.getSavingsOrders = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.savingOrder, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.getSavingsOrderById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.savingOrder_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.downloadContract = function (id) {
      var deferred = $q.defer();
      var nonBlockingWindow = window.open('', '_self');
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.savingOrder_downloadLink_id.replace('{id}', id))
            .then(function (data) {
              data =  data.data;
              if (data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              } else {
                nonBlockingWindow.close();
              }
              deferred.resolve();
            }).catch(function (msg, code) {
            nonBlockingWindow.close();
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.downloadTermsAndConditions = function (id) {
      var deferred = $q.defer();
      var nonBlockingWindow = window.open('', '_self');
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.savingOrder_downloadTermsLink_id.replace('{id}', id))
            .then(function (data) {
              data = data.data;
              if (data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              } else {
                nonBlockingWindow.close();
              }
              deferred.resolve();
            }).catch(function (msg, code) {
            nonBlockingWindow.close();
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.getSavingsOrderTypes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.savingOrderTypes, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.validateSavingsOrder = function (insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.savingOrder_validate, insertObject, {headers: {"Accept-language": "sl"}})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SavingsOrdersServiceBase.insertSavingOrder = function (insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.savingOrder, insertObject, {headers: {"Accept-language": "sl"}})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return SavingsOrdersServiceBase;
  }
}());
