(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name payments.factory:BicBanksService
     *
     * @description
     *
     */
    angular
        .module('payments')
        .factory('BicBanksService', BicBanksService);

    function BicBanksService(EndpointsService, $q, $http, $log) {
        var BicBanksServiceBase = {};
        BicBanksServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $log.debug("Params:", params);
                $http.get(response.links.bicBanks, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        BicBanksServiceBase.getByIban = function (iban){
          var predefinedParams = {
            iban: iban
          };
          return BicBanksServiceBase.getPage(predefinedParams);
        };

        BicBanksServiceBase.getById = function (id) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.bicBanks_id.replace('{id}', id))
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };
        return BicBanksServiceBase;
    }
}());
