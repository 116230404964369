(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:PaymentSignListService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('PaymentSignListService', PaymentSignListService);

  function PaymentSignListService($rootScope, EndpointsService, $q, $http, $log) {
    var PaymentsServiceBase = {};

    PaymentsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.paymentSignLists, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PaymentsServiceBase.addPaymentSignList = function (paymentList) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.paymentSignLists, paymentList)
            .then(function (data) {
              deferred.resolve(data.data);
            })
            .catch(function (msg, code) {
              deferred.reject(msg.data)
            });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    function signPayment(params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

          $http.put(response.links.paymentSignLists_sign, params)
            .then(function (data) {
              $rootScope.$broadcast("paymentInserted");
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PaymentsServiceBase.signAllPaymentsOnList = function (params) {
      return signPayment(params);
    };

    PaymentsServiceBase.updateGroupPaymentDates = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.paymentSignLists_id_updateOldPaymentDates.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.getSignListById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        var paramsObject = {
          includeList: ['PaymentList']
        };

          $http.get(response.links.paymentSignLists_id.replace('{id}', id),{
            params: paramsObject
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    return PaymentsServiceBase;
  }
}());
