<div ng-if="dashboard.currentLang == 'en'" class="campaign-banner-image-url" ng-style="{'background-image': 'url(' + dashboard.bannerCampaignEng[0].mainImage + ')'}" style="position:relative">
  <!--<div class="line" style="height:50%"></div>-->
  <label style="white-space: pre-wrap; background-color: transparent; font-family: Charlie, sans-serif; font-size: large; margin: 2%" class="campaign-text">{{dashboard.bannerCampaignEng[0].subjectText}}</label>
  <div class="t-a-l padding-buttons">
      <div class="banner-buttons">
        <button ng-if="dashboard.bannerCampaignEng[0].button1Label && dashboard.bannerCampaignEng[0].button1Label!==''"
                data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignEng[0],dashboard.bannerCampaignEng[0].button1, 'button1', 'banner' )"
                target="_blank"
                class="btn btn-default button-campaign-offer " style="background-color: #062a42; border: 1px solid #062a42;">
          <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignEng[0].button1Label}}</span>
        </button>
        <button ng-if="dashboard.bannerCampaignEng[0].button2Label && dashboard.bannerCampaignEng[0].button2Label!==''"
                data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignEng[0],dashboard.bannerCampaignEng[0].button2, 'button2', 'banner' )"
                target="_blank"
                class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; ">
          <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignEng[0].button2Label}}</span>
        </button>
        <button ng-if="dashboard.bannerCampaignEng[0].button3Label && dashboard.bannerCampaignEng[0].button3Label!==''"
                data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignEng[0],dashboard.bannerCampaignEng[0].button3, 'button3', 'banner' )"
                target="_blank"
                class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; margin-right: 0%; ">
          <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignEng[0].button3Label}}</span>
        </button>
      </div>
    </div>
</div>

<div ng-if="dashboard.currentLang != 'en'" class="campaign-banner-image-url" ng-style="{'background-image': 'url(' + dashboard.bannerCampaignLocal[0].mainImage + ')'}" style="position:relative">
<!--  <div class="line" style="height:50%"></div>-->
  <label style="white-space: pre-wrap; background-color: transparent; font-family: Charlie, sans-serif; font-size: large; margin: 2%" class="campaign-text">{{dashboard.bannerCampaignLocal[0].subjectText}}</label>
  <div class="t-a-l padding-buttons">
    <div class="banner-buttons">
      <button ng-if="dashboard.bannerCampaignLocal[0].button1Label && dashboard.bannerCampaignLocal[0].button1Label!==''"
              data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignLocal[0],dashboard.bannerCampaignLocal[0].button1, 'button1', 'banner')"
              target="_blank"
              class="btn btn-default button-campaign-offer " style="background-color: #062a42; border: 1px solid #062a42;">
        <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignLocal[0].button1Label}}</span>
      </button>
      <button ng-if="dashboard.bannerCampaignLocal[0].button2Label && dashboard.bannerCampaignLocal[0].button2Label!==''"
              data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignLocal[0],dashboard.bannerCampaignLocal[0].button2, 'button2', 'banner' )"
              target="_blank"
              class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; ">
        <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignLocal[0].button2Label}}</span>
      </button>
      <button ng-if="dashboard.bannerCampaignLocal[0].button3Label && dashboard.bannerCampaignLocal[0].button3Label!==''"
              data-ng-click="dashboard.goToBannerLink(dashboard.bannerCampaignLocal[0],dashboard.bannerCampaignLocal[0].button3, 'button3', 'banner' )"
              target="_blank"
              class="btn btn-default button-campaign-offer" style="background-color: white; border: 1px solid white; color: #062a42; margin-right: 0%; ">
        <span class="round-marketing-text padding-buttons">{{dashboard.bannerCampaignLocal[0].button3Label}}</span>
      </button>
    </div>
  </div>
</div>
