<section id="main">
    <div class="container-fluid">
        <div class="exchange-rate-header-2" ng-if="currencyConversion.currentStep != 3">
            <div class="row m-t-20">
                <div class="col-sm-8 col-xs-12 f-28" translate="currencyConversion.exchangeOffice"></div>
                <div class="col-sm-4 col-xs-12 wrap-text" ng-if="currencyConversion.currentStep == 1" data-ng-click="currencyConversion.openCurrencyCalculate();">
                    <!--<i class="icon icon-change zmdi-hc-2x exchange-office-icon" style="color: #ff4d5a;"></i>-->
                    <span class="f-14 pointer-cst m-l-10" translate="currencyConversion.exchangeRatesCalculator" style="text-decoration: underline;color: #ff4d5a;"></span>
                </div>
            </div>
        </div>

        <div ng-if="currencyConversion.currentStep == 1">
          <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="alert alert-info"
               style="background: white; color: black; border: 1px solid lightgray" role="alert">
            <p>
              <a translate="currencyConversion.bankCurrencyInfoMsg11"></a>
              <a class="underline" href="https://icalc.addiko.si/" target="blank" translate="currencyConversion.bankCurrencyInfoMsg12"></a>
            </p>
          </div>

            <div class="exchange-rates-body">
                <form name="currencyConversion.currencyForm" novalidate>
                    <div class="row m-b-10">
                        <div class="col-md-5 col-sm-5">
                            <label class="bold-font f-18" translate="payments.fromAccount"></label>

                            <!-- CUSTOM Accounts dropdown -->
                            <div class="accounts-dropdown m-b-10" ng-if="currencyConversion.fromAccountsList.length > 0">
                                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="currencyConversion.toggleFromAccount();"  ng-style="currencyConversion.tempExchangeObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + currencyConversion.tempExchangeObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                    <h4 class="no-wrap-text bold-font m-b-0">
                                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{currencyConversion.tempExchangeObj.fromAccountSelect.friendlyName}}</b>
                                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':currencyConversion.tempExchangeObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!currencyConversion.tempExchangeObj.fromAccountSelect.isFavorite}"></i></span>
                                    </h4>
                                    <p class="m-b-0 m-t-0 iban-style">{{currencyConversion.tempExchangeObj.fromAccountSelect.iban | formatIban}}</p>
                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                        {{currencyConversion.tempExchangeObj.fromAccountSelect.availableBalance | number:2}} {{currencyConversion.tempExchangeObj.fromAccountSelect.currencySymbol}}
                                    </p>
                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                    <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                                </div>
                                <div class="from-accounts-list m-l-15 m-r-15" ng-show="currencyConversion.fromAccountDropdown" scrollable>
                                    <div class="accounts-list-item p-0" data-ng-repeat="item in currencyConversion.fromAccountsList" data-ng-click="currencyConversion.setAccountFrom(item);">

                                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                            <h4 class="no-wrap-text bold-font m-b-0">
                                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                            </h4>
                                            <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                            </p>
                                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="accounts-dropdown m-b-10" ng-if="currencyConversion.fromAccountsList.length == 0">
                                <div class="debit-account-widget dashboard-widget-item" style="height: 141px;" ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                  <div class="m-t-30 m-b-30">
                                    <p translate="internalTransfer.nodata"></p>
                                  </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-12">
                                    <label class="exchange-rate-label f-18" translate="currencyConversion.sellingAmount"></label>
                                    <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="input-group z-index-0">
                                        <input ng-switch-default type="text" class="form-control input-field t-a-r" name="amountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                                               amount-input-mask data-ng-model="currencyConversion.tempExchangeObj.fromAccountAmountInput"
                                               ng-blur="currencyConversion.setFromCurrency();"
                                               ng-class="{'domp-error': currencyConversion.currencyForm.amountInput.$invalid && currencyConversion.tempExchangeObj.formSubmitted}"
                                               required>
                                      <input ng-switch-when="sl" type="text" class="form-control input-field t-a-r" name="amountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                                               amount-input-mask data-ng-model="currencyConversion.tempExchangeObj.fromAccountAmountInput"
                                               maxlength="14"
                                               maximal-amount
                                               minimal-amount
                                               ng-disabled="currencyConversion.disableInputFields"
                                             ng-keyup="$event.keyCode == 13 && currencyConversion.setFromCurrency($event);"
                                               ng-blur="currencyConversion.setFromCurrency();"
                                               ng-class="{'domp-error': currencyConversion.currencyForm.amountInput.$invalid && currencyConversion.tempExchangeObj.formSubmitted}"
                                               required>
                                        <span class="input-group-btn">
                 <button type="button" class="btn input-group-app-btn disabled ng-scope" style="border:1px solid #062a42;">
                   {{currencyConversion.tempExchangeObj.sellCurrencyInput}}
                 </button>
                </span>
                                    </div>
                                    <span class="c-red" ng-show="currencyConversion.currencyForm.amountInput.$error.required && currencyConversion.tempExchangeObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                                    <span class="c-red" ng-show="currencyConversion.currencyForm.amountInput.$error.minimalAmount">
                                      <span  translate="internalTransfer.minAmount10Error"></span> {{10 | currency:""}} EUR
                                    </span>
                                    <span class="c-red" ng-show="currencyConversion.currencyForm.amountInput.$error.maximalAmount" >
                                      <span translate="internalTransfer.amountLengthError"></span> EUR
                                   </span>
                                </div>
                            </div>
                        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && currencyConversion.tempExchangeObj.sellCurrencyInput"
                              ng-bind="('currencyConversion.exchangeRateRatio' | translate) + ' 1 '+ (currencyConversion.localCurrency) +
                              ' = ' + (currencyConversion.fromAccountCalculationRate | currency:'':4) + ' ' + (currencyConversion.tempExchangeObj.sellCurrencyInput)" class="exchange-rate">
                        </span>
                        </div>

                        <div class="col-md-2 col-sm-2 col-xs-12 p-0">
                            <div class="t-a-c exchange-office-middle">
                                <!--<i class="icon icon-change zmdi-hc-2x" style="font-size:35px;color:#ff4d5a;"></i>-->
                                <i class="zmdi zmdi-long-arrow-right zmdi-hc-2x" style="font-size:35px;color:#ff4d5a;"></i>
                            </div>
                        </div>

                        <div class="col-md-5 col-sm-5">
                            <label class="bold-font f-18" translate="payments.toAccount"></label>

                            <!-- CUSTOM Accounts dropdown -->
                            <div class="accounts-dropdown m-b-10" ng-if="currencyConversion.tempExchangeObj.toAccountSelect && currencyConversion.toAccountsList.length > 0">
                                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="currencyConversion.toggleToAccount();" ng-style="currencyConversion.tempExchangeObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + currencyConversion.tempExchangeObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                    <h4 class="no-wrap-text bold-font m-b-0">
                                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{currencyConversion.tempExchangeObj.toAccountSelect.friendlyName}}</b>
                                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':currencyConversion.tempExchangeObj.toAccountSelect.isFavorite,'zmdi-star-outline':!currencyConversion.tempExchangeObj.toAccountSelect.isFavorite}"></i></span>
                                    </h4>
                                    <p class="m-b-0 m-t-0 iban-style">{{currencyConversion.tempExchangeObj.toAccountSelect.iban | formatIban}}</p>
                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                        {{currencyConversion.tempExchangeObj.toAccountSelect.availableBalance | number:2}} {{currencyConversion.tempExchangeObj.toAccountSelect.currencySymbol}}
                                    </p>
                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                    <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                                </div>
                                <div class="from-accounts-list m-l-15" ng-show="currencyConversion.toAccountDropdown" scrollable>
                                    <div class="accounts-list-item p-0" data-ng-repeat="item in currencyConversion.toAccountsList" data-ng-click="currencyConversion.setAccountTo(item);">

                                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                            <h4 class="no-wrap-text bold-font m-b-0">
                                                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                            </h4>
                                            <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                            </p>
                                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="accounts-dropdown m-b-10" ng-if="!currencyConversion.tempExchangeObj.toAccountSelect  || currencyConversion.toAccountsList.length == 0">
                                <div class="debit-account-widget dashboard-widget-item domp-acc-widget"  ng-style="currencyConversion.tempExchangeObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + currencyConversion.tempExchangeObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                  <div class="m-t-30 m-b-30">
                                    <p class="m-b-0 m-t-0 iban-style" translate="internalTransfer.nodata"></p>
                                    <p class="m-b-0 m-t-0 iban-style">{{currencyConversion.tempExchangeObj.toAccountSelect.iban}}</p>
                                  </div>
                                </div>
                            </div>

                            <div class="row"  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                                <div class="col-sm-12">
                                    <label class="exchange-rate-label f-18" translate="currencyConversion.buyingAmount"></label>
                                    <div class="row">
                                        <div class="col-sm-8 col-xs-8 p-r-0">
                                            <input type="text" class="t-a-c form-control input-field t-a-r" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                                                   name="toAmountInput"
                                                   amount-input-mask

                                                   data-ng-model="currencyConversion.tempExchangeObj.toAccountAmountInput" ng-blur="currencyConversion.setToCurrency();"
                                                   ng-class="{'domp-error': currencyConversion.currencyForm.amountInput.$invalid && currencyConversion.tempExchangeObj.formSubmitted}" required>

                                          <span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.required && currencyConversion.tempExchangeObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                                          <!--<span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.maxlength && currencyConversion.tempExchangeObj.formSubmitted" translate="internalTransfer.amountInputError"></span>-->
                                        </div>

                                        <!-- Set buying currency -->
                                        <div class="col-sm-4 col-xs-4 p-l-0" ng-if="currencyConversion.localCurrency === 'EUR' && currencyConversion.tempExchangeObj.sellCurrencyInput == currencyConversion.localCurrency">
                                            <select class="form-control input-field" ng-options="item.symbol for item in currencyConversion.currencyList" 
                                                    data-ng-model="currencyConversion.tempExchangeObj.buyCurrencyInput" data-ng-change="currencyConversion.changeSellCurrency();"
                                                    ng-disabled="currencyConversion.tempExchangeObj.sellCurrencyInput != currencyConversion.localCurrency">
                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-xs-4 p-l-0" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                                          <button type="button" class="btn input-group-app-btn disabled ng-scope" style="border:1px solid #062a42;">
                                            {{currencyConversion.tempExchangeObj.buyCurrencyInput.symbol}}
                                          </button>
                                            <!--<select class="form-control input-field" ng-options="item.symbol for item in currencyConversion.currencyList" data-ng-model="currencyConversion.tempExchangeObj.buyCurrencyInput" data-ng-change="currencyConversion.changeSellCurrency();">-->
                                            <!--</select>-->
                                        </div>
                                        <!--<div class="col-sm-4 col-xs-4 p-l-0" ng-if="currencyConversion.localCurrency === 'RSD' || currencyConversion.localCurrency === 'EUR'">-->
                                        <!--<input type="text" class="form-control input-field" data-ng-model="currencyConversion.tempExchangeObj.buyCurrencyInput.symbol" readonly>-->
                                        <!--</div>-->
                                        <div class="col-sm-4 col-xs-4 p-l-0" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && (currencyConversion.tempExchangeObj.sellCurrencyInput != currencyConversion.localCurrency || currencyConversion.localCurrency != 'EUR')">
                                            <input type="text" class="t-a-c form-control input-field" data-ng-model="currencyConversion.tempExchangeObj.buyCurrencyInput.symbol" readonly>
                                        </div>

                                    </div>
                                </div>
                            </div>

                          <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">

                            <div class="col-sm-12">
                              <label class="exchange-rate-label f-18" translate="currencyConversion.buyingAmount"></label>
                              <div class="input-group z-index-0">
                                <input type="text" class="t-a-c form-control input-field t-a-r" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                                       name="toAmountInput"
                                       amount-input-mask
                                       maxlength="14"
                                       maximal-amount
                                       minimal-amount
                                       ng-keyup="$event.keyCode == 13 && currencyConversion.setToCurrency($event);"
                                       ng-disabled="currencyConversion.disableInputFields"
                                       data-ng-model="currencyConversion.tempExchangeObj.toAccountAmountInput" ng-blur="currencyConversion.setToCurrency();"
                                       ng-class="{'domp-error': currencyConversion.currencyForm.amountInput.$invalid && currencyConversion.tempExchangeObj.formSubmitted}">

                                <span class="input-group-btn">
                 <button type="button" class="btn input-group-app-btn disabled ng-scope" style="border:1px solid #062a42;">
                                            {{currencyConversion.tempExchangeObj.toAccountSelect.currencySymbol}}
                 </button>
                </span>
                              </div>
                              <span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.required && currencyConversion.tempExchangeObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                              <span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.minimalAmount">
                                            <span  translate="internalTransfer.minAmount10Error"></span> {{10 | currency:""}} EUR
                              </span>
                              <span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.maximalAmount" >
                                      <span translate="internalTransfer.amountLengthError"></span> EUR
                             </span>
                              <!--<span class="c-red" ng-show="currencyConversion.currencyForm.toAmountInput.$error.maxlength && currencyConversion.tempExchangeObj.formSubmitted" translate="internalTransfer.amountInputError"></span>-->
                            </div>
                          </div>

                         <span ng-if="currencyConversion.countryCode != 'bh' && currencyConversion.countryCode != 'sl' && currencyConversion.countryCode != 'hr'"
                               ng-bind="('currencyConversion.exchangeRateRatio' | translate) + ' 1 '+ (currencyConversion.foreignCurrency) +
                          ' = ' + (currencyConversion.fixedAmount) + ' ' + (currencyConversion.localCurrency)"
                                                       class="exchange-rate" >
                          </span>
                          <span ng-if="currencyConversion.countryCode == 'sl'"
                               ng-bind="('currencyConversion.exchangeRateRatio' | translate) + ' 1 '+ (currencyConversion.localCurrency) +
                          ' = ' + (currencyConversion.fixedAmount | currency: '':4) + ' ' + (currencyConversion.foreignCurrency)"
                                                       class="exchange-rate" >
                          </span>
                          <span ng-if="currencyConversion.countryCode == 'bh'"
                                ng-bind="('currencyConversion.exchangeRateRatio' | translate) + ' 1 '+ (currencyConversion.foreignCurrency) +
                          ' = ' + (currencyConversion.fixedAmount | number:6) + ' ' + (currencyConversion.localCurrency)"
                                class="exchange-rate" >
                          </span>
                        </div>

                    </div>

                  <div class="row m-t-10">
                    <div class="col-xs-6 t-a-l">
                      <button class="btn btn-primary" type="button" translate="payments.cancel"
                              data-ui-sref="payments.paymentsOverview">
                      </button>
                    </div>
                    <div class="col-xs-6 t-a-r">
                      <button ng-if="currencyConversion.countryCode != 'hr' && currencyConversion.countryCode != 'sl'" class="btn btn-default"
                              translate="currencyConversion.confirm"
                              data-ng-click="currencyConversion.submitCurrencyConversion();"
                              ng-disabled="currencyConversion.currencyForm.$invalid">
                      </button>
                      <a ng-if="currencyConversion.countryCode === 'sl'" class="btn btn-default p-t-10" translate="domesticPayment.confirmBtnSlo"
                              data-ng-click="currencyConversion.submitCurrencyConversion($event);"
                              ng-disabled="currencyConversion.nextButtonPressed || (currencyConversion.fromAccountsList.length == 0 ||
                              currencyConversion.toAccountsList.length == 0 || currencyConversion.currencyForm.$invalid)">
                      </a>
                      <button ng-if="currencyConversion.countryCode == 'hr'" class="btn btn-default"
                              translate="currencyConversion.confirm"
                              data-ng-click="currencyConversion.submitCurrencyConversion();"
                              ng-disabled="currencyConversion.fromAccountsList.length == 0 || !currencyConversion.currencyForm.$valid">
                      </button>

                    </div>
                  </div>
                </form>
            </div>
            <!-- TIMELINE CONTAINER -->
            <div class="col-sm-12 l-h-50 p-0" style="background-color: white;border-bottom: 2px solid #9eabd5;">
                <span translate="currencyConversion.exchangeOrder" class="bold-font f-17 m-l-20 ng-scope"></span>
            </div>

            <div class="col-sm-12 no-padding bg-white" style="padding-top:10px;">

                <br>
               <div class="dropdown acc-period-select col-xl-3 col-md-3 col-sm-3">
                    <button class="btn btn-default dropdown-toggle p-l-30 p-r-30" type="button" data-toggle="dropdown">
                        {{currencyConversion.selectedPeriod.item.description | translate}}
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li data-ng-repeat="period in currencyConversion.periodList">
                            <a href="" class="f-13" data-ng-click="currencyConversion.timelineTimeFilter(period);">
                {{period.item.description | translate}}
              </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="timeline-row col-sm-12 no-padding">
                <div class="t-a-c p-10 no-data-image" ng-if="currencyConversion.currencyExchangeListGrouped.result.length == 0">
                    <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                    <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
                </div>
                <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0" ng-hide="currencyConversion.currencyExchangeListGrouped.result.length == 0">
                    <ul class="hypo-timeline" kcd-recompile="currencyConversion.currencyExchangeListGrouped">
                        <li ng-class="{'time-label': payment.isVirtual}" ng-repeat="payment in currencyConversion.currencyExchangeListGrouped.result">
                            <span class="currency-time-frame" ng-if="payment.isVirtual === true">{{payment.date | date:'shortDate' : '+0200'}}</span>
                            <i class="icon icon-change currency-icon-change" ng-if="!payment.isVirtual"></i>
                            <div ng-if="!payment.isVirtual" class="hypo-timeline-item payment-item" ng-click="payment.selected = !payment.selected;" ng-class="{'opened': payment.selected}">
                                <div class="hypo-timeline-body">
                                    <div class="col-xs-6">
                                        <h4 class="m-b-0 m-t-0 c-gray">
                      <b ng-if="!payment.transactionAmount2">{{::payment.amount |number:2}} {{::payment.currencySymbol}}</b>
                      <b ng-if="payment.transactionAmount2">{{::payment.transactionAmount2 |number:2}} {{::payment.destinationCurrencySymbol}}</b>
<!--                      <span translate="payments.to"></span>
                      <b>{{::payment.toAccountNumber}}</b> -->
                    </h4>
                                        <p class="m-b-0 m-t-0 iban-style">
                                          {{::payment.fromAccountNumber}} -
                                          {{::payment.toAccountNumber}}
                                        </p>
                                      <p ng-show="currencyConversion.showPaymentId" class="m-b-0 m-t-0" id="paymentId">{{::payment.id}}</p>
                                    </div>
                                  <div class="col-xs-6"><span class="pull-right t-a-r m-t-10"><b style="font-size:17px;">
                                      <b>
                                        <span class="po-value-label" ng-class="{'text-danger': payment.status === 'Rejected'}" ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"></span>
                                        <span class="po-value-label" ng-if="payment.status === 'Processed'" translate="paymentsOverview.processed"></span>
                                        <span class="po-value-label" ng-if="payment.status === 'Cancelled'" translate="paymentsOverview.cancelled"></span>
                                        <span class="po-value-label" ng-if="payment.status === 'InProcessing'" translate="paymentsOverview.inProcessing"></span>
                                        <span class="po-value-label" ng-if="payment.status === 'Opened'" translate="paymentsOverview.open"></span>
                                        <span class="po-value-label" ng-if="payment.status === 'WaitingQueue'" translate="paymentsOverview.waitingQueque"></span>
                                      </b>
                                  </div>
                                </div>
                                <div ng-if="payment.selected" class="transaction-details" >
                                     <i class="icon icon-change currency-icon-change-two" ng-if="!payment.isVirtual"></i>
                                    <div data-ng-include="'payments/tl-currency-details.tpl.html'"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="show-more-btn ">
                <button ng-hide="currencyConversion.currencyExchangeListGrouped.hasMore == false" data-ng-click="currencyConversion.loadMoreTimelineItems()" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">Load older events</button>
            </div>

            <!-- END TIMELINE -->
        </div>

        <!-- Currency conversion STEP 2 -->
        <div ng-if="currencyConversion.currentStep == 2" ng-include="'payments/currency-conversion-resume.tpl.html'"></div>

        <!-- Currency conversion STEP 3 -->
        <div ng-if="currencyConversion.currentStep == 3" ng-include="'payments/currency-conversion-final.tpl.html'"></div>

    </div>
</section>
