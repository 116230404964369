<div>
    <table class="table table-transparent table-responsive">
        <tbody>
            <tr ng-if="order.id">
              <td>
                <span class="po-desc-label" translate="orderDetails.orderId"></span>
              </td>
              <td>
                <span class="po-value-label">{{order.id }}</span>
              </td>
            </tr>
            <tr ng-if="order.payeeName">
                <td>
                    <span class="po-desc-label" translate="orderDetails.name"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.payeeName }}</span>
                </td>
            </tr>
            <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && order.payeeAddress">
                <td>
                    <span class="po-desc-label" translate="orderDetails.payeeAddress"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.payeeAddress }}</span>
                </td>
            </tr>
            <tr ng-if="order.fromAccountNumber && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
              <td>
                <span class="po-desc-label" translate="paymentsOverview.povlFrom"></span>
              </td>
              <td>
                <span class="po-value-label">{{order.fromAccountNumber}}</span>
              </td>
            </tr>
            <tr ng-if="order.payeeAccount">
                <td>
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" class="po-desc-label" translate="orderDetails.payeeAccount"></span>
                    <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="po-desc-label" translate="paymentsOverview.povlTo"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.payeeAccount}}</span>
                </td>
            </tr>
            <tr ng-if="order.amount">
                <td>
                    <span class="po-desc-label" translate="orderDetails.amount"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.amount | number:2}} {{order.currencySymbol}}</span>
                </td>
            </tr>

            <!--<tr ng-if="order.orderType.id">-->
                <!--<td>-->
                    <!--<span class="po-desc-label" translate="orderDetails.orderTypeId"></span>-->
                <!--</td>-->
                <!--<td>-->
                    <!--<span class="po-value-label">{{order.orderType.id}}</span>-->
                <!--</td>-->
            <!--</tr>-->
            <tr ng-if="order.purpose">
                <td>
                    <span class="po-desc-label" translate="orderDetails.purpose"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.purpose}}</span>
                </td>
            </tr>
            <tr ng-if="order.dateCreated">
                <td>
                    <span class="po-desc-label" translate="orderDetails.dateCreated"></span>
                </td>
                <td>
                    <span class="po-value-label">{{order.dateCreated | date: 'shortDate' : '+0200'}}</span>
                </td>
            </tr>
            <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && order.firstPaymentDate">
              <td>
                <span class="po-desc-label" translate="orderDetails.firstPaymentDate"></span>
              </td>
              <td>
                <span class="po-value-label">{{order.firstPaymentDate | date: 'shortDate' : '+0200'}}</span>
              </td>
            </tr>
            <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && order.lastPaymentDate">
              <td>
                <span class="po-desc-label" translate="orderDetails.lastPaymentDate"></span>
              </td>
              <td>
                <span class="po-value-label">{{order.lastPaymentDate | date: 'shortDate' : '+0200'}}</span>
              </td>
            </tr>
            <tr ng-if="order.status != 'Signed' && vm.countryCode != 'sl' && vm.countryCode!= 'hr'">
                <td colspan="2">
                    <div>
                        <span>
                            <button
                            ng-click="vm.closeOrder(order.id);"
                            class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                            translate="orderDetails.close">Close order</button>
                          </span>
                    </div>
                </td>
            </tr>
            <tr ng-if="order.status === 'Opened' && vm.countryCode == 'hr'">
              <td colspan="2">
                <div>
                        <span>
                            <button
                              ng-click="vm.deleteOrder(order.id);"
                              class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                              translate="orderDetails.delete">Delete order</button>
                          </span>
                </div>
              </td>
            </tr>
            <tr ng-if="order.status === 'Processed' && vm.countryCode == 'hr'">
              <td colspan="2">
                <div>
                        <span>
                            <button
                              ng-click="vm.closeOrder(order.id);"
                              class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                              translate="orderDetails.close">Close order</button>
                          </span>
                </div>
              </td>
            </tr>
        </tbody>
    </table>
</div>
