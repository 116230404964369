<table class="table table-transparent table-responsive">
  <tbody>
    <tr>
      <td>
        <span class="po-desc-label" translate="ebook.createdBy"></span>
      </td>
      <td>
        <span class="po-value-label">{{::eBookSavings.createdBy}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="accounts.accParentAccountIban">IBAN</span>
      </td>
      <td>
        <span class="po-value-label">{{::eBookSavings.iban}}</span>
      </td>
    </tr>
    <tr ng-if="eBookSavings.eSavingBookNumber">
      <td>
        <span class="po-desc-label" translate="ebook.ebookNumber">Account number</span>
      </td>
      <td>
        <span class="po-value-label">{{::eBookSavings.eSavingBookNumber}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="ebook.requestType">Request type</span>
      </td>
      <td>
        <span class="po-value-label">{{::eBookSavings.requestType}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="ebook.status"></span>
      </td>
      <td>
        <span class="po-value-label">{{::eBookSavings.status}}</span>
      </td>
    </tr>

    <!-- DOCUMENTS -->
    <tr ng-if="eBookSavings.documents">
      <td>
        <span class="po-desc-label" translate="ebook.documents"></span>
      </td>
      <td>
        <span class="po-value-label" ng-repeat="doc in eBookSavings.documents">
          <div class="row m-r-0">
            <a ng-if="!doc.link" data-ng-click="newEBook.manageDocumentDownload(doc.name, eBookSavings.id, eBookSavings.accountId)" style="text-decoration: underline; cursor: pointer;"
              translate="{{doc.translations}}">
            </a>
            <a ng-if="doc.link" ng-href="{{doc.link}}" target="_blank" style="text-decoration: underline; cursor: pointer;"
              translate="{{doc.translations}}">
            </a>
          </div>
        </span>
      </td>
    </tr>

    <!-- DOCUMENTS -->
    <tr ng-if="eBookSavings.documents">
      <td colspan="2">
        <span class="po-desc-label">
          <div class="row m-l-0 text-left">
            <span translate="ebook.otherDocs1">docs desc</span>
            <span>&nbsp;</span>
            <a href="https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo/" target="_blank" style="text-decoration: underline;" translate="ebook.otherDocs2">link</a>
          </div>
        </span>
      </td>
    </tr>

  </tbody>
</table>
