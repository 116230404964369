<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="reorderPinAccounts.header">Change ATM withdrawal limit</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <!-- START Note -->
    <div ng-if="reorderPinAccounts.step == 1" class="row">
      <div class="col-md-12">
        <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
          <div>
            <p translate="reorderPinAccounts.infoNote1" style="margin-bottom: 0"></p>
            <p translate="reorderPinAccounts.infoNote2" style="display: inline"></p>
            <a href="https://www.addiko.si/ceniki" target="blank" class="underline"
               translate="reorderPinAccounts.infoLink"></a>
            <div>
            </div>
            <p ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" translate="reorderPinAccounts.additionalText" style="margin-bottom: 0; margin-top: 20px;"></p>
          </div>
        </div>
        <!-- END Note -->

        <div ng-if="reorderPinAccounts.step == 1" class="p-b-30">
          <ng-form name="reorderPinAccounts.reorderPinAccountsForm">
            <div class="cash-withdrawing-section-frame">
              <div class="row">
                <div class="col-md-6">

                  <label class="domp-form-label" translate="reorderPinAccounts.fromAccount">Form account</label>
                  <!-- START Dropdown account -->
                  <div class="accounts-dropdown">
                    <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                         data-ng-click="changeWithdrawal.showAccountToDropdown = !changeWithdrawal.showAccountToDropdown"
                         ng-style="changeWithdrawal.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + changeWithdrawal.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                          {{reorderPinAccounts.selectedAccount.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': reorderPinAccounts.selectedAccount.isFavorite,'zmdi-star-outline':!reorderPinAccounts.selectedAccount.isFavorite}"></i>
                </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{reorderPinAccounts.selectedAccount.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{reorderPinAccounts.selectedAccount.availableBalance | number:2}}
                        {{reorderPinAccounts.selectedAccount.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>

                    </div>
                  </div>
                  <!-- END Dropdown account -->

                </div>
                <div class="col-md-6">

                  <div class="col-xs-12 p-0">
                    <div class="form-group">
                      <label class="domp-form-label" translate="reorderPinAccounts.cardType"></label>
                      <select class="form-control input-field p-l-15 p-r-10"
                              ng-options="amount.description for amount in reorderPinAccounts.cardTypes"
                              data-ng-model="reorderPinAccounts.selectedCardType" required>
                      </select>
                    </div>
                  </div>

                  <!-- START PIN Reorder -->
                  <div class="col-xs-12 p-0">
                    <div class="form-group">
                      <label class="domp-form-label" translate="reorderPinAccounts.pinReceiptMethod">PIN Reorder</label>
                      <select class="form-control input-field p-l-15 p-r-10"
                              ng-options="type.description for type in reorderPinAccounts.newPinOrderDeliveryTypes"
                              data-ng-model="reorderPinAccounts.selectedNewPinOrderDeliveryType" required>
                      </select>
                    </div>
                  </div>
                  <!-- END PIN Reorder-->

                </div>

              </div>


              <div class="row p-t-30">

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="domp-form-label" translate="reorderPinAccounts.for">For</label>
                    <select ng-change="reorderPinAccounts.selectForOption();"
                            class="form-control input-field p-l-15 p-r-10"
                            ng-options="option.description for option in reorderPinAccounts.forOptions"
                            ng-model="reorderPinAccounts.type">
                    </select>

                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="domp-form-label" translate="reorderPinAccounts.nameSurname">Name and surname</label>
                    <input type="text"
                           name="nameSurname"
                           id="nameSurname"
                           class="form-control input-field p-l-15 p-r-10"
                           data-ng-model="reorderPinAccounts.user"

                           maxlength="50"
                           required
                           ng-disabled="reorderPinAccounts.type.id == 'uporabnik'">
                    <div class="c-red"
                         ng-show="reorderPinAccounts.reorderPinAccountsForm.nameSurname.$error.required && reorderPinAccounts.formSubmitted"
                         translate="reorderPinAccounts.nameSurnameRequired"></div>

                  </div>
                </div>

              </div>


              <div class="row p-b-20 p-t-20">
                <div class="col-md-12">
                  <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left"
                          ng-click="reorderPinAccounts.goBackToAccount()">
                    <span translate="changeWithdrawal.cancelBtnLabel">Cancel</span>
                  </button>
                  <button class="btn btn-default  pull-right" ng-click="reorderPinAccounts.validate();"
                          ng-disabled="reorderPinAccounts.nextButtonPressed">
                    <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-form>
        </div>
      </div>
    </div>
    <div ng-if="reorderPinAccounts.step == 2">
      <div class="domp-to-section m-10">
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="reorderPinAccounts.fromAccount"></label>
              <label class="d-block domp-form-label-resume">{{reorderPinAccounts.selectedAccount.iban}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="reorderPinAccounts.cardType"></label>
              <label
                class="d-block domp-form-label-resume">{{reorderPinAccounts.selectedCardType.description}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="reorderPinAccounts.pinReceiptMethod"></label>
              <label class="d-block domp-form-label-resume">{{reorderPinAccounts.selectedNewPinOrderDeliveryType.description}}</label>
            </div>
          </div>
        </div>
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="reorderPinAccounts.for"></label>
            <label class="d-block domp-form-label-resume">{{reorderPinAccounts.type.description}}</label>
          </div>
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="reorderPinAccounts.nameSurname"></label>
            <label class="d-block domp-form-label-resume">{{reorderPinAccounts.user}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button class="btn btn-primary " translate="domesticPayment.back"
                data-ng-click="reorderPinAccounts.edit();">
        </button>
        <button class="btn btn-default pull-right" data-ng-click="reorderPinAccounts.submit()"
                ng-disabled="reorderPinAccounts.submitButtonPressed">
          <span translate="domesticPayment.confirmBtn"></span>
        </button>
      </div>
    </div>

  </div>
</section>
