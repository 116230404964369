<section id="main">
  <div class="container">
    <div class="currency-conversion-msg">
      <div class="payment-successful-wrapper">
        <div class="row">
          <div class="col-xs-2 m-t-30 m-b-30 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-10 m-t-30 m-b-30 int-payment">

            <div class="f-15">

              <h2 class="c-white f-28" translate="instantPayment.finalStepSuccessfullySigned"></h2>

              <div ng-if="paymentsFinal.defaultData.bankCharges || paymentsFinal.defaultData.BankChargesLink">
                <span translate="signPayments.finalStepPaymentServices"></span>
                <a ng-if="paymentsFinal.defaultData.bankCharges" ng-href="{{paymentsFinal.bankCharges}}"
                   class="bank-tariff-link" target="_blank" translate="signPayments.finalStepBankTariff"
                   class="pull-right"></a>
                <a ng-if="paymentsFinal.defaultData.BankChargesLink"
                   ng-href="{{paymentsFinal.defaultData.BankChargesLink}}" class="bank-tariff-link" target="_blank"
                   translate="signPayments.finalStepBankTariff" class="pull-right"></a>
              </div>
              <div class="m-t-15">
                <span>
                  {{'signPayments.finalStepStatus' | translate}} {{'instantPayment.statuses.'+paymentsFinal.selectedPayment.status | translate}}</span>
              </div>
              <div>
                <span translate="instantPayment.finalStepPaymentID"></span>
                <span>{{paymentsFinal.selectedPayment.id}}</span>
              </div>
              <div class="m-t-15">
                <span translate="instantPayment.orderSent"></span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row m-t-20" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
        <!-- Save template only for Domestic or foreign payments-->
        <div ng-switch-default class="col-xs-12 col-sm-4" ng-show="paymentsFinal.canBeSavedToTemplate">
          <a href=""
             data-ui-sref="paymentTemplates.saveTemplate({paymentId: paymentsFinal.selectedPayment.id, type: paymentsFinal.paymentType, paymentObject: paymentsFinal.selectedPayment })">
            <span class="money-exchange-icon"><i class="icon icon-archive zmdi-hc-2x" style="color:#062a42;"></i></span>
            <span class="f-18 money-exchange-text" translate="internalTransfer.saveAsTemplate"></span>
          </a>
        </div>
        <div ng-switch-when="sl" ng-if="!paymentsFinal.isFromTemplate" class="col-xs-12 col-sm-4"
             ng-show="paymentsFinal.canBeSavedToTemplate">
          <a href=""
             data-ui-sref="paymentTemplates.saveTemplate({paymentId: paymentsFinal.selectedPayment.id, type: paymentsFinal.paymentType, paymentObject: paymentsFinal.selectedPayment })">
            <span class="money-exchange-icon"><i class="icon icon-archive zmdi-hc-2x" style="color:#062a42;"></i></span>
            <span class="f-18 money-exchange-text" translate="internalTransfer.saveAsTemplate"></span>
          </a>
        </div>

        <div class="col-xs-12" ng-if="paymentsFinal.paymentType !== 'group'"
             ng-class="{'col-sm-6': !paymentsFinal.canBeSavedToTemplate, 'col-sm-4': paymentsFinal.canBeSavedToTemplate}">
          <a href="" data-ng-click="paymentsFinal.makeAnotherPayment();">
            <span class="money-exchange-icon"><i class="icon icon-reclam zmdi-hc-2x" style="color:#062a42;"></i></span>
            <span class="f-18 money-exchange-text" translate="internalTransfer.makeAnotherPayment"></span>
          </a>
        </div>

        <div class="col-xs-12 col-sm-4"
             ng-class="{'col-sm-6': !paymentsFinal.canBeSavedToTemplate, 'col-sm-4': paymentsFinal.canBeSavedToTemplate}">
          <a href="" data-ng-click="paymentsFinal.goToListOfPayments()">
            <span class="money-exchange-icon"><i class="icon icon-clean zmdi-hc-2x" style="color:#062a42;"></i></span>
            <span class="f-18 money-exchange-text" translate="currencyConversion.goToPayments"></span>
          </a>
        </div>
      </div>
    </div>

  </div>
</section>
