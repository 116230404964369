(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:NonWorkingDays
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('NonWorkingDays', NonWorkingDays);

  function NonWorkingDays($q, $http, EndpointsService, ConfigService) {
    var NonWorkingDaysBase = {};
    NonWorkingDaysBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.nonWorkingDays, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    NonWorkingDaysBase.isWorkingDate = function (date) {
      var deferred = $q.defer();
      var url = ConfigService.url + '/nonWorkingDays/isWorkingDay';
      $http.get(url, {
        params: {
          date: date
        }
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
        deferred.reject(msg.data);
      });
      return deferred.promise;
    };

    NonWorkingDaysBase.nextWorkingDate = function (date) {
      var deferred = $q.defer();
      var url = ConfigService.url + '/nonWorkingDays/nextWorkingDay';
      $http.get(url, {
        params: {
          date: date
        }
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
        deferred.reject(msg.data);
      });
      return deferred.promise;
    };
    return NonWorkingDaysBase;
  }
}());
