<section id="main">
  <div class="container">
    <div class="row exchange-rates">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
        <div class="domp-header-wrapper p-r-20 p-l-10" ng-show="savingsPayIn.currentStep!=3">
          <div class="row domp-header-row">
            <div class="domp-header-left col-sm-12 col-md-12 col-lg-12 col-xs-12">
              <h2 translate="savings.savingsPayIn"></h2>
            </div>
          </div>
        </div>
        <!----------------------->
        <div class="internal-transfer-body" ng-show="savingsPayIn.currentStep == 1">
          <form name="savingsPayIn.savingsPayInForm" novalidate>
            <div class="p-10"> </div>
            <div class="exchange-rates-body">
              <div class="row m-b-30">
                <div class="col-sm-5">
                  <!-- CUSTOM FROM Accounts dropdown-->
                  <label class="bold-font f-18" translate="payments.fromAccount"></label>
                  <div class="accounts-dropdown m-b-10" ng-if="savingsPayIn.accountsFrom.length > 0">
                    <div class="debit-account-widget dashboard-widget-item" data-ng-click="savingsPayIn.toggleFromAccount();"  ng-style="savingsPayIn.tempObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + savingsPayIn.tempObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                             <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                        {{savingsPayIn.tempObj.fromAccountSelect.friendlyName}}</b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                          <i class="zmdi "
                             ng-class="{'zmdi-star favorite-account':savingsPayIn.tempObj.fromAccountSelect.isFavorite,
                                       'zmdi-star-outline':!savingsPayIn.tempObj.fromAccountSelect.isFavorite}"></i>
                        </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{savingsPayIn.tempObj.fromAccountSelect.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{savingsPayIn.tempObj.fromAccountSelect.availableBalance | number:2}} {{savingsPayIn.tempObj.fromAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon" ng-if="savingsPayIn.accountsFrom.length > 1">
                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                      </span>
                    </div>
                    <div class="accounts-list m-l-15 m-r-15" ng-show="savingsPayIn.fromAccountDropdown" scrollable>
                      <div class="accounts-list-item p-0" data-ng-repeat="item in savingsPayIn.accountsFrom" data-ng-click="savingsPayIn.setAccountFrom(item);">
                          <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                              <h4 class="no-wrap-text bold-font m-b-0">
                        <span class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</span>
                                   <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                              </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">{{item.availableBalance | number:2}} {{item.currencySymbol}}</p>
                         <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>
                    </div>
                  </div>
                    </div>
                  <div class="accounts-dropdown m-b-10" ng-if="savingsPayIn.accountsFrom.length == 0">
                    <div class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px" translate="core.noAccounts">
                      No available accounts
                    </div>
                  </div>

                  <label class="bold-font" translate="internalTransfer.amount"></label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="fromAccountAmountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask maxlength="12" data-ng-model="savingsPayIn.tempObj.fromAccountAmountInput"
          ng-class="{'domp-error': savingsPayIn.savingsPayInForm.fromAccountAmountInput.$invalid && savingsPayIn.tempObj.formSubmitted}" required>
                    <span class="input-group-btn">
                      <button type="button" class="btn input-group-app-btn domp-btn-height" disabled>
                        {{savingsPayIn.tempObj.fromAccountSelect.currencySymbol}}
                      </button>
                    </span>
                  </div>
                  <span class="c-red" ng-show="savingsPayIn.savingsPayInForm.fromAccountAmountInput.$error.required &&
                                               savingsPayIn.tempObj.formSubmitted" translate="internalTransfer.amountInputError">
                  </span>
                  <span class="c-red" ng-show="savingsPayIn.savingsPayInForm.fromAccountAmountInput.$error.maxlength &&
                                               savingsPayIn.tempObj.formSubmitted" translate="internalTransfer.amountLengthError">
                  </span>

         <!--         <div class="col-lg-12 col-md-8 no-padding-on-phone p-r-0 p-l-0 p-t-10">
                    <label class="bold-font" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
                    <div class="p-0">
                      <input type="text" class="form-control input-field" name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="135" maxlength="135" ng-model="savingsPayIn.tempObj.purposeOfPaymentInput" required ng-class="{'domp-error': savingsPayIn.savingsPayInForm.purposeOfPaymentInput.$invalid && savingsPayIn.tempObj.formSubmitted}" ng-blur="savingsPayIn.replaceUnallowedCharsDPS('purposeOfPaymentInput', savingsPayIn.tempObj.purposeOfPaymentInput)">
                      <span class="c-red" ng-show="savingsPayIn.savingsPayInForm.purposeOfPaymentInput.$error.required && savingsPayIn.tempObj.formSubmitted" translate="internalTransfer.paymentPurposeInputError"></span>
                    </div>
                  </div>-->
                </div>
                <div class="col-sm-2">
                  <div class="t-a-c exchange-office-middle">
                    <i class="zmdi zmdi-long-arrow-right zmdi-hc-2x"></i>
                  </div>
                </div>

                <div class="col-sm-5">
                  <!-- CUSTOM TO Accounts dropdown -->
                  <label class="bold-font f-18" translate="payments.toAccount"></label>
                  <div class="accounts-dropdown m-b-10" ng-if="savingsPayIn.accountTo.length > 0">
                    <div class="debit-account-widget dashboard-widget-item domp-acc-widget-foreign" data-ng-click="savingsPayIn.toggleToAccount();" ng-style="savingsPayIn.tempObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + savingsPayIn.tempObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                           <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                        {{savingsPayIn.tempObj.toAccountSelect.friendlyName}} </b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                          <i class="zmdi "
                             ng-class="{'zmdi-star favorite-account':account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                        </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style" ng-if="!savingsPayIn.tempObj.toAccountSelect.iban">{{savingsPayIn.tempObj.toAccountSelect.accountNumber}}</p>
                      <p class="m-b-0 m-t-0 iban-style" ng-if="savingsPayIn.tempObj.toAccountSelect.iban">{{savingsPayIn.tempObj.toAccountSelect.iban}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{savingsPayIn.tempObj.toAccountSelect.availableBalance | number:2}} {{savingsPayIn.tempObj.toAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon" ng-if="savingsPayIn.accountTo.length > 1">
                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                      </span>
                    </div>
                    <div class="from-accounts-list m-l-15" ng-show="savingsPayIn.toAccountDropdown" scrollable>
                      <div class="accounts-list-item p-0" data-ng-repeat="item in savingsPayIn.accountTo" data-ng-click="savingsPayIn.setAccountTo(item);">
                          <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">

                          <h4 class="no-wrap-text bold-font m-b-0">
                       <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                              <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                              </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">{{item.availableBalance | number:2}} {{item.currencySymbol}}</p>
                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      </div>
                    </div>
                  </div>

                  <!-- END TO ACCOUNT CONTROL -->

                  <!--<div class="row">-->
                    <!--<div class="col-lg-12 col-sm-8 col-md-8 p-t-10">-->
                      <!--<label class="bold-font" translate="internalTransfer.when"></label>-->
                      <!--<div class="input-group">-->
                        <!--<input type="text" class="form-control input-field" name="dueDateInput" uib-datepicker-popup="shortDate" data-ng-model="savingsPayIn.tempObj.dueDateInput" show-weeks="false" is-open="savingsPayIn.datePickerOpened" datepicker-options="savingsPayIn.dateOptions" ng-class="{'domp-error': savingsPayIn.savingsPayInForm.dueDateInput.$invalid && savingsPayIn.tempObj.formSubmitted}" required>-->
                        <!--<span class="input-group-btn">-->
                          <!--<button data-ng-click="savingsPayIn.openDatePicker()" type="button"-->
                                  <!--class="btn input-group-app-btn domp-btn-height"><i class="icon icon-calendar" style="font-size: 21px;"></i>-->
                          <!--</button>-->
                        <!--</span>-->
                      <!--</div>-->
                    <!--</div>-->
                  <!--</div>-->
<!--
                  <label class="bold-font" translate="currencyConversion.amount"></label>

                  <div class="row">
                    <div class="col-sm-8 col-xs-8 p-r-0">
                      <input type="text" class="form-control input-field t-a-r" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" data-input-mask="'core.moneyFormat.mask'" data-input-mask-options="{reverse: true,optional: true}" data-ng-model="savingsPayIn.tempObj.toAccountAmountInput" disabled>
                    </div>
                    <div class="col-sm-4 col-xs-4 p-l-0">
                      <select class="form-control input-field" ng-options="item.symbol for item in savingsPayIn.currencyList" data-ng-model="savingsPayIn.tempObj.toCurrencySymbol" data-ng-change="savingsPayIn.changeSellCurrency();" ng-disabled="savingsPayIn.tempObj.fromCurrencyInput != savingsPayIn.localCurrency">
                      </select>
                    </div> -->
                    <!--<div class="col-sm-4 col-xs-4 p-l-0">
<input type="text" class="form-control input-field" data-ng-model="savingsPayIn.tempObj.buyCurrencyInput.symbol">
</div> -->
                </div>

                  <!-- In case of empty to account list -->
                  <div class="accounts-dropdown m-b-10" ng-if="savingsPayIn.accountTo.length == 0">
                    <div class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px" translate="core.noAccounts">
                    </div>
                  </div>

                  <label class="bold-font" translate="internalTransfer.when"></label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field" name="dueDateInput"
                           uib-datepicker-popup="shortDate" data-ng-model="savingsPayIn.tempObj.dueDateInput"
                           show-weeks="false" is-open="savingsPayIn.datePickerOpened"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           datepicker-options="savingsPayIn.dateOptions"
                           ng-class="{'domp-error': savingsPayIn.savingsPayInForm.dueDateInput.$invalid && savingsPayIn.tempObj.formSubmitted}"
                           required>
                        <span class="input-group-btn">
                          <button data-ng-click="savingsPayIn.openDatePicker()" type="button"
                                  class="btn input-group-app-btn domp-btn-height"><i class="icon icon-calendar"
                                                                                     style="font-size: 21px;"></i>
                          </button>
                        </span>
                  </div>

              </div>
          </div>
              <div class="row">
                <div class="col-xs-6 t-a-l">
                  <button class="btn btn-primary" translate="payments.cancel"
                          data-ui-sref="payments.paymentsOverview">
                  </button>
                </div>
                <div class="col-xs-6 t-a-r">
                  <button class="btn btn-default domp-review-and-submit-btn" translate="currencyConversion.confirm"
                          data-ng-click="savingsPayIn.savingsPayInSubmit();"
                          ng-disabled="savingsPayIn.accountsFrom.length==0 || savingsPayIn.accountTo.length==0">
                    <!--  ng-disabled="savingsPayIn.accountsFrom.length == 0" -->

                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Internal transfer STEP 2 -->
        <div ng-show="savingsPayIn.currentStep === 2">
          <div data-ng-include="'payments/savings-pay-in-resume.tpl.html'">
          </div>
        </div>

        <!-- Internal transfer STEP 3 -->
        <div ng-show="savingsPayIn.currentStep === 3">
          <div data-ng-include="'payments/savings-pay-in-final.tpl.html'"></div>
        </div>

      </div>
    </div>
  </div>
</section>
