/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable func-style */
/* eslint-disable curly */
/* eslint-disable wrap-iife */
/* eslint-disable no-undef */
/* eslint-disable no-inline-comments */
/* eslint-disable spaced-comment */
/* eslint-disable one-var */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
(function () {
  "use strict";

  /**
   * @ngdoc object
   * @name settings.controller:SettingsCtrl
   *
   * @description
   *
   */
  angular.module("settings").controller("SettingsCtrl", SettingsCtrl);

  function SettingsCtrl(
    $scope,
    $q,
    $rootScope,
    $translate,
    $uibModal,
    $state,
    userSettingsData,
    UserSettingsService,
    ValidationService,
    SettingsService,
    NotificationService,
    AlertService,
    AccountsService,
    HelperService,
    $sce,
    GoogleTagManagerHelperService
  ) {
    var vm = this;
    var defaultData;
    var passwordPolicy;
    var unregister;
    var userSettings;
    var houseNumber;
    var street;
    var postalCodeAndCity;

    var updateData;
    var navigateToDashboard;
    var clearForm;
    var createDompObj;

    vm.showGSMOption = true;
    vm.tempDompObj = {};

    vm.trrAccounts = [];
    vm.selectedTrrAccount = {};

    vm.possibleNotifications = [];
    vm.possibleNotificationsCopy = [];
    vm.notificationCheckbox = false;

    vm.inputOptions = {
      minLengthOld: -1,
      maxLength: -1,
      minLength: -1,
      checkForValidation: false,
    };
    vm.lengthMaxError = false;
    vm.lengthMaxErrorShown = false;

    vm.lengthMinError = false;
    vm.lengthMinErrorShown = false;
    //vm.selectedTab = "account";

    vm.checkForValidation = function () {
      vm.inputOptions.checkForValidation = true;
    };
    vm.userBasedNotificationsList = [];
    vm.accountBasedNotificationsList = [];
    vm.loadSmsAndNotifications = loadSmsAndNotifications;

    $rootScope.$on('mtoken.confirm', onMTokenConfirm);

    init();

    function onMTokenConfirm(event, data) {
        vm.challengeInput = data.firstCode;
        vm.secondChallengeInput = data.secondCode;
        vm.confirmUserSettingsEdit();
    }

    function prepareNotificationListCRO() {
      return UserSettingsService.getNotifications().then(
        function (response) {
          vm.possibleNotifications = [];
          _.map(response.result, function (items) {
            vm.possibleNotifications.push({
              id: items.id,
              smsEnabled: items.smsEnabled,
              HIBISEnabled: items.smsEnabled && items.pushEnabled || items.eMailEnabled,
              smsActive: items.smsActive,
              eMailEnabled: items.eMailEnabled,
              pushEnabled: items.pushEnabled,
              pushActive: items.pushActive,
              eMailActive: items.eMailActive,
              notificationGroupType: items.notificationGroupType,
            });
            if (items.smsEnabled) {
              vm.notificationCheckbox = true;
            }
          });
        },
        function (error) {
          NotificationService.showMessage(error, "error");
          //refresh list to initial list
          prepareNotificationListCRO();
        }
      );
    }

    function init() {
      vm.model = {};
      vm.model.userSettings = {};
      vm.model.security = {};
      vm.model.security.transactionConfirmation = "0";
      vm.model.userSettingsEditInput = {};
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

      defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
      vm.country = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      vm.selectedTab =
        vm.country === "hr" || vm.country === "sl" ? "account" : "security";

      if (defaultData.APILocation === "bh") {
        vm.inputOptions.minLengthOld = 6;
        vm.inputOptions.minLength = 8;
        vm.inputOptions.maxLength = 15;
      } else if (defaultData.APILocation === "sr") {
        passwordPolicy = defaultData.PasswordPolicy;
        vm.inputOptions.minLengthOld = passwordPolicy.OldPasswordMinimalLength;
        vm.inputOptions.minLength = passwordPolicy.PasswordMinimalLength;
        vm.inputOptions.maxLength = passwordPolicy.PasswordMaximalLength;
      } else if (defaultData.APILocation === "mn" || defaultData.APILocation === "mne"){
        vm.inputOptions.minLengthOld = 6;
        vm.inputOptions.minLength = 8;
        vm.inputOptions.maxLength = 15;
      }

      if (defaultData.APILocation === "sl") {
        loadAccounts(); //includes loading SmsAndNotifiacions
        loadAdditionalTransactionInformation();
      }

      if (defaultData.APILocation === "hr") {
        prepareNotificationListCRO();
      }

      loadData();
    }

    vm.selectTab = function (tab, translateLabel) {
      if (tab == vm.selectedTab) return;
      vm.selectedTab = tab;
      GoogleTagManagerHelperService.pushEvent(
        "Settings",
        "Tab",
        $translate.instant(translateLabel)
      );
    };

    function loadAdditionalTransactionInformation() {
      SettingsService.getAdditionalTransactionConfirmation()
        .then(function (response) {
          vm.model.security.transactionConfirmation =
            response.result[0].id.toString();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        });
    }

    function loadAccounts() {
      AccountsService.getPage({
        accountTypeGroup: "CurrentAccount",
        isOwn: true,
        isParentAccount: true,
      })
        .then(function (response) {
          vm.trrAccounts = _.filter(response.result, function (account) {
            return account.isOwn;
          });
          vm.trrAccounts = _.uniq(vm.trrAccounts, function (item) {
            return item.iban;
          });
          vm.selectedTrrAccount = vm.trrAccounts[0];

          unregister = $rootScope.$on(
            "appLanguageChangedHeader",
            function (evt, data) {
              if (vm.selectedTab == "notifications") {
                loadSmsAndNotifications();
              }
            }
          );
          $scope.$on("$destroy", function () {
            unregister();
            $rootScope.$off('mtoken.confirm', onMTokenConfirm);
          });

          loadSmsAndNotifications();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        });
    }

    function loadSmsAndNotifications() {
      if (!vm.selectedTrrAccount) return;

      SettingsService.getSmsAndNotifications({
        orderBy: "subOrderId",
        accountId: vm.selectedTrrAccount.accountId,
      })
        .then(function (response) {
          vm.userBasedNotificationsList = response.result;
          vm.accountBasedNotificationsList = _.remove(
            vm.userBasedNotificationsList,
            function (item) {
              return item.accountId;
            }
          );
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        });
    }

    vm.changeSettings = function (item, value, type, accountBased) {
      return $uibModal
        .open({
          templateUrl: "settings/sms-and-notification-modal.tpl.html",
          size: "lg",
          controller: "SmsAndNotificationModalCtrl",
          controllerAs: "smsAndNotificationModal",
          resolve: {
            object: {
              user: vm.model.userSettings,
              //orderItem: item,
              orderObject: item,
              type: type, // SMS or EMAIl
              value: value,
              accountBased: accountBased,
              account: vm.selectedTrrAccount,
            },
          },
          backdrop: "static",
        })
        .result.then(function (result) {
          loadSmsAndNotifications();
        });
    };

    vm.validateMaxLength = function (passwordField) {
      if (
        $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sr" &&
        vm.model.security[passwordField]
      ) {
        if (
          vm.model.security[passwordField].length === 15 &&
          !vm.lengthMaxErrorShown
        ) {
          vm.lengthMaxError = true;
          vm.lengthMaxErrorShown = true;
        } else if (vm.lengthMaxErrorShown) {
          // vm.lengthMaxError = false;
        }
      }
    };

    vm.validateMinLength = function (passwordField) {
      if (
        $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "mn" || $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "mne" &&
        vm.model.security[passwordField]
      ) {
        if (
          vm.model.security[passwordField].length < 8 
        ) {
          vm.lengthMinError = true;
          vm.lengthMinErrorShown = true;
        } 
      }
    }

    function loadData() {
      $rootScope.APP_GLOBAL_SETTINGS.userSettings = userSettingsData.result[0];
      userSettings = userSettingsData.result[0];
      userSettings.businessAddress3 = userSettings.businessAddress3
        ? userSettings.businessAddress3.replace(/:/g, " ")
        : null;
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== "hr") {
        userSettings.houseNumber = userSettings.address1
          .split(/[\s]+/)
          .pop()
          .trim();
        userSettings.street = userSettings.address1
          .replace(new RegExp(userSettings.houseNumber, "g"), "")
          .trim();
      } else {
        // 1.
        if (userSettings.businessAddress2) {
          userSettings.houseNumber = userSettings.businessAddress2;
        } else {
          houseNumber = userSettings.address1.split(/[\s]+/).pop().trim();
          userSettings.houseNumber = houseNumber;
          userSettings.businessAddress2 = houseNumber;
        }
        // 2.
        if (userSettings.businessAddress1) {
          userSettings.street = userSettings.businessAddress1;
        } else {
          street = userSettings.address1
            .replace(new RegExp(userSettings.houseNumber, "g"), "")
            .trim();
          userSettings.businessAddress1 = street;
          userSettings.street = street;
        }
        if (userSettings.businessAddress3) {
          postalCodeAndCity = userSettings.businessAddress3.split(" ");
          if (postalCodeAndCity && postalCodeAndCity.length > 1) {
            userSettings.postalCode = postalCodeAndCity[0];
            postalCodeAndCity.shift(); // remove postal code (first from array);
            userSettings.city = postalCodeAndCity.join(" ");
          }
        } else {
          userSettings.businessAddress3 =
            userSettings.postalCode + " " + userSettings.city;
        }
      }
      console.log(userSettings);

      userSettings.mailUserType = 1;
      vm.model.userSettings = userSettings;

      if (vm.country === "sl") {
        if (!userSettings.gsmAuthorized) {
          vm.showGSMOption = false;
        }
      } else if (vm.country == 'hr') {
        var challengeResponse = JSON.parse(userSettings.challenge);
        vm.challengeFirstCode = challengeResponse.challange1;
        vm.challengeSecondCode = challengeResponse.challange2;
        var qrChallenge= userSettings.qrChallenge;
        if (qrChallenge) {
          vm.qrChallenge = qrChallenge;
          vm.showChallenge = false; 
        } else {
          vm.showChallenge = true; 
        }
      }
    }

    // gsmCountryCode, gsmOperator i gsm
    // dayPhoneCountryCode, dayPhoneOperator  i dayPhone
    function prepareDataForUpdate(userSettings) {
      return {
        email: vm.model.userSettingsEditInput.notificationEmail,
        dataConfirmation: true,
        mailUsername: userSettings.firstName + " " + userSettings.lastName,
        mailStreet: vm.model.userSettingsEditInput.street,
        mailHouseNumber: vm.model.userSettingsEditInput.houseNumber,
        mailCity: vm.model.userSettingsEditInput.city,
        mailPostalCode: vm.model.userSettingsEditInput.postalCode,
        mailUserType: vm.model.userSettingsEditInput.mailUserType,
        gsmCountryCode: vm.model.userSettingsEditInput.gsmCountryCode,
        gsmOperator: vm.model.userSettingsEditInput.gsmOperator,
        gsm: vm.model.userSettingsEditInput.gsm,
        dayPhoneCountryCode: vm.model.userSettingsEditInput.dayPhoneCountryCode,
        dayPhoneOperator: vm.model.userSettingsEditInput.dayPhoneOperator,
        dayPhone: vm.model.userSettingsEditInput.dayPhone,
      };
    }

    vm.confirmUserSettingsEdit = function () {
      if (vm.country == 'hr') {
        if (vm.challengeInput !== '') {
          updateData = {};
          updateData.signData = vm.challengeInput;
        } else {
          return;
        }
  
        if (vm.showChallenge) {
          updateData.additionalSignData = vm.secondChallengeInput;
        }
      } else {
        updateData = {};
      }

      vm.editUserSettingsActive = false;
      Object.assign(updateData, prepareDataForUpdate(vm.model.userSettingsEditInput));
      UserSettingsService.updateUserInfoOrders(updateData).then(
        function (response) {
          if (vm.country == 'hr') {
            AlertService.infoAlert({
              text: "settings.successfulDataUppdate"
            }).then();
          }
           GoogleTagManagerHelperService.pushEvent(
            "Settings",
            "Settings updated",
            "Settings updated successfully!"
          );
          if (response) {
            loadData();
          } else {
            vm.model.userSettings = angular.copy(
              vm.model.userSettingsEditInput
            );
          }
        },
        function (error) {
          NotificationService.showMessage(error, 'error');
          vm.editUserSettingsActive = true;
        }
      );
    };

    vm.editUserSettings = function () {
      prepareNotificationListCRO();
      vm.editUserSettingsActive = true;
      vm.model.userSettingsEditInput = angular.copy(vm.model.userSettings);
      if (vm.country === "hr" && !vm.model.userSettings.dayPhoneCountryCode) {
        vm.model.userSettingsEditInput.dayPhoneCountryCode = "+385";
      }

      if (vm.country === "hr" && !vm.model.userSettings.gsmCountryCode) {
        vm.model.userSettingsEditInput.gsmCountryCode = "+385";
      }

      if (vm.country == 'hr') {
        vm.model.userSettingsEditInput.notificationEmail = vm.model.userSettings.email;
      }
    };

    vm.editUserEmail = function () {
      
      if (vm.country == 'sl') {
        $state.go("settings.contactDataVerificationEmail");
        return;
      }
      vm.editEmail = true;
      vm.model.userSettingsEditInput.notificationEmail = angular.copy(
        vm.model.userSettings.notificationEmail
      );

    };

    vm.toggleNotifications = function (notification) {
      notification = !notification;
    };

    vm.toggleNotificationsHIBIS = function (notification) {
      if (notification.HIBISEnabled == true) {
        notification.pushEnabled = false;
        notification.eMailEnabled = false;
        notification.smsEnabled = false;
      } else {
        notification.pushEnabled = true;
        notification.smsEnabled = true;
      }

      notification.HIBISEnabled = !notification.HIBISEnabled;
    };

    vm.toggleHIBIS = function (item, type) {
      if (type === 'push') {
        item.pushEnabled = true;
        item.smsEnabled = true;
        item.eMailEnabled = false;
      } else {
        item.pushEnabled = false;
        item.smsEnabled = false;
        item.eMailEnabled = true;
      }
    };

    vm.confirmUserEmailChange = function () {
      var updateData;

      vm.editEmail = false;
      updateData = prepareDataForUpdate(vm.model.userSettingsEditInput);
      UserSettingsService.updateUserInfoOrders(updateData).then(
        function (response) {
          if (response) {
            loadData();
          } else {
            vm.model.userSettings = angular.copy(
              vm.model.userSettingsEditInput
            );
          }
          vm.model.userSettings.notificationEmail = angular.copy(
            vm.model.userSettingsEditInput.notificationEmail
          );
        },
        function (error) {
          vm.editEmail = true;
        }
      );
    };

    vm.cancelUserSettingsEdit = function () {
      vm.editUserSettingsActive = false;
      vm.model.userSettingsEditInput = {};
    };

    vm.compareNewPassword = function () {
      if (
        vm.model.security.newPassword === vm.model.security.confirmNewPassword
      ) {
        vm.securityForm.confirmNewPassword.$setValidity("incorrect", true);
      } else {
        vm.securityForm.confirmNewPassword.$setValidity("incorrect", false);
      }
    };

    createDompObj = function () {
      return {
        oldPassword: vm.model.security.oldPassword,
        newPassword: vm.model.security.newPassword,
        confirmedNewPassword: vm.model.security.confirmNewPassword,
      };
    };

    clearForm = function () {
      vm.model.security.oldPassword = "";
      vm.model.security.newPassword = "";
      vm.model.security.confirmNewPassword = "";
      vm.securityForm.$setPristine();
      vm.securityForm.$setUntouched();
    };

    vm.preventDefault = function (event) {
      var apiLocation = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      if (apiLocation === "sr" || apiLocation === 'mn' || apiLocation === 'mne' || apiLocation === 'bh') {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    vm.preventForbiddenCharacters = function (event) {
      var apiLocation = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      if (apiLocation === "sr" || apiLocation === 'mn' || apiLocation === 'mne' || apiLocation === 'bh') {
        if (event.key === " ") {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    };

    vm.changeUserData = function() {
      var temp = {};
      temp.email = vm.model.security.email || null;
      temp.mothersLastName = vm.model.security.mothersLastName || null;
      if (temp.email || temp.mothersLastName) {
        UserSettingsService.updateInvoiceEmail(temp, vm.model.userSettings.id)
          .then(function (response) {
            GoogleTagManagerHelperService.pushEvent(
              "Settings",
              "Settings updated",
              "Settings updated successfully"
            );
            vm.model.userSettings = response;
            clearForm();
            translatedSuccessMessage = $translate.instant(
              "core.additionalDataChangedMsg"
            );
            NotificationService.showTranslatedMessage(
              "success",
              translatedSuccessMessage,
              "",
              5000,
              true,
              2
            );
          })
          .catch(function (error) {
            NotificationService.showTranslatedMessage(
              "error",
              error.resultList[0].description,
              error.resultList[0].key
            );
          })
          .finally(function () {});
      }
    };

    vm.change = function () {
      var translatedSuccessMessage;
      var dompObj;
      if (vm.securityForm.$valid) {
        dompObj = createDompObj();
        var apiLocation =  $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        SettingsService.changePassword(dompObj)
          .then(function (response) {
            if (
              apiLocation === "sr" || apiLocation === 'bh' || apiLocation === 'mne'
            ) {
              if (
                HelperService
                  .isPasswordChangeRequested
              ) {
                HelperService.isPasswordChangeRequested = false;
              }
            }
            translatedSuccessMessage = $translate.instant(
              "core.passwordChangedMsg"
            );
            NotificationService.showTranslatedMessage(
              "success",
              translatedSuccessMessage,
              "",
              apiLocation == 'sr' ? 120000 : 5000,
              true,
              2
            );
            GoogleTagManagerHelperService.pushEvent(
              "Settings",
              "Settings updated",
              "Settings updated successfully!"
            );
            if (vm.country === 'bh' || apiLocation == 'mn' || vm.country === 'mne') {
              navigateToDashboard();
            }
          })
          .catch(function (error) {
            //$log.error(error);
            if (apiLocation == 'mn' || apiLocation == 'mne' || apiLocation == 'bh') {
              // Smece kod, ali ne znas sta ocekivati sa API, ko ga radi dobro ikako ista radi :) 
              if (error) {
                if (error && error.resultList) {
                  if (error.resultList[0]) {
                    var text = error.resultList[0].description;
                    AlertService.infoAlert({
                      text: $sce.trustAsHtml(text),
                      btnKey: 'OK',
                      type: 'html'
                    });
                  } else {
                    NotificationService.showMessage(error, "error", null, null, 5000);
                  }

                } else {
                  NotificationService.showMessage(error, "error", null, null, 5000);
                }
              }
             
            } else {
              NotificationService.showMessage(error, "error", null, null, apiLocation == 'sr' ? 120000: 5000);
            }

          })
          .finally(function () {
            clearForm();
          });
      }
    };

    vm.enableSecurityEdit = function () {
      vm.securityEditActive = !vm.securityEditActive;

      if (!vm.showGSMOption) {
        vm.model.security.transactionConfirmation = "0";
      }
    };

    vm.securityEditConfirm = function () {
      if (vm.confirmButtonPressed) return;
      // DO POST
      vm.confirmButtonPressed = true;
      SettingsService.changeAdditionalTransactionConfirmation({
        id: vm.model.security.transactionConfirmation,
        parameter:
          vm.model.security.transactionConfirmation == 0 ? "OTP" : "SMS",
      })
        .then(function (response) {
          $rootScope.APP_GLOBAL_SETTINGS.userSettings.additionalTransactionConfirmation =
            response;
          vm.securityEditActive = false;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, "error");
        })
        .finally(function () {
          vm.confirmButtonPressed = false;
        });
    };

    vm.securitySetBlockade = function () {
      $state.go("blockade");
    };

    navigateToDashboard = function () {
      $state.go("dashboard");
    };

    vm.setMobilePhoneNumber = function (phone) {
      $state.go("settings.contactDataVerificationPhone");
    };

    function updateNotifications(updateData) {
      //let preparedData = prepareData(updateData);
      return UserSettingsService.updateNotifications(updateData).then(
        function (response) {
          var message = $translate.instant("settings.notificationInfo");
          NotificationService.showTranslatedMessage(
            "success",
            message,
            "",
            3000
          );
        },
        function (error) {
          NotificationService.showMessage(error, "error");
          //refresh list to initial list
          prepareNotificationListCRO();
        }
      );
    }

    // function prepareData(data) {
    //   const allowedKeys = ["id", "smsEnabled", "eMailEnabled", "pushEnabled"];
    //   let notificationSettingsList = [];

    //   data.notificationSettingsList.forEach((notificationElement) => {
    //     let pickedNotificationElement = _.pick(
    //       notificationElement,
    //       allowedKeys
    //     );
    //     notificationSettingsList.push(pickedNotificationElement);
    //   });

    //   let updateData = {
    //     notificationSettingsList
    //   }
    //   return updateData;
    // }

    vm.updateUserSettings = function () {
      var promises = [];

      var updateData = {
        notificationSettingsList: vm.possibleNotifications,
      };
      promises.push(updateNotifications(updateData));

      $q.all(promises).then(function (responses) {
        //
      });
    };

    vm.updateNotificationItem = function (item, type) {
      if (type === "sms") {
        if (item.isEmail === true && item.isActive === true)
          item.isEmail = false;
      }
      if (type === "email") {
        if (item.isEmail === true && item.isActive === true)
          item.isActive = false;
      }
    };

    vm.updateUserMobileSetting = function () {
      AlertService.updateMobileSetting().then(
        function (result) {
          if (result) {
            UserSettingsService.updateUserMobileSetting(false).then(
              function (response) {
                vm.model.userSettings.mobileBankEnabled =
                  response.mobileBankEnabled;
                // NotificationService.showTranslatedMessage('success', 'message','',3000);
              },
              function (error) {
                NotificationService.showMessage(error, "error");
              }
            );
          }
        },
        function (error) {}
      );
    };

    vm.isSmsNeeded = function (type) {
      return !(
        type === "MinimumAmountDebitedPerCard" ||
        type === "MaturityOfTheLoan" ||
        type === "LimitChangesSMS"
      );
    };
  }
})();
