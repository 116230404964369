(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:StatementConfirmModalCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('StatementConfirmModalCtrl', StatementConfirmModalCtrl);

  function StatementConfirmModalCtrl($uibModalInstance, data) {

    var vm = this;
    vm.model = {
      stateOption: 0,
      information: ""
    };

    vm.selectedCountry = data;

    vm.confirm = function () {
      vm.statementForm.submitted = true;
      if (!vm.statementForm.$valid) return;
      $uibModalInstance.close(vm.model);
    }
  }
}());
