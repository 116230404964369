(function () {
  'use strict';

  angular
    .module('transactions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('transactions', {
        url: '/transactions',
        abstract: true
        //templateUrl: 'transactions/transactions.tpl.html',
        //controller: 'TransactionsCtrl',
        //controllerAs: 'transactions'
      })
      .state('transactions.reclamation', {
        url: '/reclamation/:transactionId',
        params: {
          transactionId: null,
          accountId: null,
          url: null
        },
        views: {
          '@': {
            templateUrl: 'transactions/transactions-reclamation.tpl.html',
            controller: 'TransactionsReclamationCtrl',
            controllerAs: 'tReclamation'
          }
        }
      });
  }
}());
