<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="security.title">Security</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->
    <div ng-if="settings.countryCode == 'hr' && !settings.isSecurityAcceptedFormShow && settings.qrChallenge != null && !!settings.qrChallenge">
      <div class="col-xs-12 m-b-20">
        <span class="f-w-bold">
          {{'signPayments.challengeDescriptionQR' | translate}}
        </span>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-6">
          <div class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeFirstCode' |
              translate}}
            </div>
            <input type="text" class="form-control input-field" disabled
              data-ng-model="settings.challengeFirstCode">
          </div>
          <div class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
              translate}}
            </div>
            <input id="nedim" name="nedim" type="text" class="form-control input-field" disabled
              data-ng-model="settings.challengeSecondCode">
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-6">
          <div class="m-t-10">
            <image width="150" height="150" class="m-l-30"
                   data-ng-src="{{'data:image/bmp;base64,' + settings.qrChallenge}}"></image>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-4 col-sm-6 col-xs-6">
          <div class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeThirdCode' |
              translate}}
            </div>
            <input type="text" class="form-control input-field"
                    ng-pattern-restrict="{{settings.regexPatterns.onlyNumbers}}" data-ng-model="settings.challengeResponse"
                    placeholder="{{'signPayments.challengeThirdCode' | translate}}" autocomplete="off" auto-focus>
          </div>
        </div>
        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-primary col-xs-12 " data-ng-click="settings.cancelPaymentRestriction()"
                  translate="signPayments.cancel"></button>
        </div>
        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-default col-xs-12 " data-ng-click="settings.accessRestrictions()"
                  ng-disabled="settings.formSubmitted || settings.challengeResponse === ''"
                  translate="paymentsAndTransferOverview.confirm"></button>
        </div>
      </div>
      
    
    </div>
    <div class="row" ng-if="settings.countryCode == 'hr' && !settings.isSecurityAcceptedFormShow && settings.qrChallenge == null">
      <!-- Challenge response validation -->
      <div class="col-xs-12 m-t-30">
        <div class="col-xs-12 m-b-20">
          <span class="f-w-bold">
            {{'signPayments.challengeDescription' | translate}}
          </span>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-6">
          <div class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeFirstCode' |
              translate}}
            </div>
            <input type="text" class="form-control input-field" disabled
              data-ng-model="settings.challenge">
          </div>
          <div ng-if="settings.showChallenge" class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeFirstCode' |
              translate}}
            </div>
            <input type="text" class="form-control input-field" disabled
              data-ng-model="settings.challenge2">
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-6">
          <div class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
              translate}}
            </div>
            <input id="nedim" name="nedim" type="text" class="form-control input-field"
              data-ng-model="settings.challengeResponse" ng-pattern-restrict="{{settings.regexPatterns.onlyNumbers}}"
              placeholder="{{'signPayments.challengeSecondCode' | translate}}" autocomplete="off" auto-focus>
          </div>
          <div ng-if="settings.showChallenge" class="form-group">
            <div class="wrap-text f-w-bold" style="font-weight:500;">{{'signPayments.challengeSecondCode' |
              translate}}
            </div>
            <input id="nedim" name="nedim" type="text" class="form-control input-field"
              data-ng-model="settings.additionalChallenge"
              ng-pattern-restrict="{{settings.regexPatterns.onlyNumbers}}"
              placeholder="{{'signPayments.challengeSecondCode' | translate}}" autocomplete="off" auto-focus>
          </div>
        </div>

        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-primary col-xs-12 " data-ng-click="settings.cancelPaymentRestriction()"
                  translate="signPayments.cancel"></button>
        </div>
        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-default col-xs-12 " data-ng-click="settings.accessRestrictions()"
                  ng-disabled="settings.formSubmitted || settings.challengeResponse === '' || !settings.additionalChallenge"
                  translate="paymentsAndTransferOverview.confirm"></button>
        </div>

      </div>
    </div>
    <div class="row" ng-if="settings.countryCode !== 'hr' && !settings.isSecurityAcceptedFormShow && settings.qrChallenge == null">
      <!-- Challenge response validation -->
      <div class="col-xs-12 m-t-30">
        <div class="col-xs-12 m-b-20">
          <span class="f-w-bold" ng-bind-html="settings.challengeDescrioption"></span>
        </div>
        <div class="col-sm-4 col-xs-6">
          <span class="f-w-bold">{{'signPayments.challengeFirstCode' | translate}}</span>
          <input type="text" class="form-control input-field" disabled
                 data-ng-model="settings.challenge">
        </div>
        <div class="col-sm-4 col-xs-6">
          <div class="form-group">
            <span class="f-w-bold">{{'signPayments.challengeSecondCode' | translate}}</span>
            <div class="input-group">
              <input type="text" class="form-control input-field"
                     data-ng-model="settings.challengeResponse"
                     placeholder="{{'signPayments.challengeSecondCode' | translate}}"
                     auto-focus>
          <span class="input-group-btn">
            <!--<button class="btn btn-default input-group-app-btn domp-btn-height"-->
                    <!--data-ng-click="paymentsSign.toggleInputVisibility()">-->
                  <!--&lt;!&ndash;<i data-ng-show="paymentsSign.showInput" class="zmdi zmdi-lock"></i>&ndash;&gt;-->
                  <!--<i data-ng-show="!paymentsSign.showInput" class="zmdi zmdi-lock-open"></i>-->
            <!--</button>-->
          </span>
            </div>
          </div>
        </div>

        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-primary col-xs-12 " data-ng-click="settings.cancelPaymentRestriction()"
                  translate="signPayments.cancel"></button>
        </div>
        <div class="col-sm-2 col-xs-6 m-t-20">
          <button class="btn btn-default col-xs-12 " data-ng-click="settings.accessRestrictions()"
                  ng-disabled="settings.formSubmitted || settings.challengeResponse === ''"
                  translate="paymentsAndTransferOverview.confirm"></button>
        </div>

      </div>
    </div>

    <div class="row" ng-if="settings.isSecurityAcceptedFormShow">
        <!-- Challenge response validation -->
        <div class="col-xs-12 m-t-30">
          <div class="col-xs-12 m-b-20">
            <span class="f-w-bold domp-form-label-15" data-ng-bind-html="settings.paymentRestrictionHeading"></span>
          </div>

          <div class="col-xs-12 m-b-20">
              <span class="f-w-bold domp-form-label-15" data-ng-bind-html="settings.paymentRestrictionBody"></span>
              <a class="f-w-bold domp-form-label-15" href='https://www.addiko.hr/opci-uvjeti-poslovanja-i-naknade-gradanstvo' target='_blank'>https://www.addiko.hr/opci-uvjeti-poslovanja-i-naknade-gradanstvo</a>
          </div>

          <div class="col-xs-12 m-b-20">
            <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value domp-form-label-15">
              <input type="checkbox" ng-model="settings.securitySettingsUsage.checked">
              <i class="input-helper"></i>
              <span translate="security.paymentRestrictions.paymentRestrictionCheckbox"></span>
            </label>
          </div>
  
          <div class="col-xs-12 m-b-20">
            <div class="col-sm-3 col-xs-6 m-t-20">
              <button class="btn btn-primary col-xs-12 " data-ng-click="settings.cancelPaymentRestriction()"
                      translate="signPayments.cancel"></button>
            </div>
            <div class="col-sm-3 col-xs-6 m-t-20">
              <button class="btn btn-default col-xs-12 " data-ng-click="settings.accessRestrictionsFinally()"
                      ng-disabled="!settings.securitySettingsUsage.checked"
                      translate="paymentsAndTransferOverview.confirm"></button>
            </div>
          </div>

        </div>
    </div>

  </div>
</section>
