(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name core.controller:addikoDropdown
     *
     * @description
     *
     */
    angular
      .module('core')
      .directive('addikoDropdown', addikoDropdown);
  
    function addikoDropdown(ValidationService) {
        return {
            restrict: 'E',
            scope: {
                amountInput: '@amount',
                controlName: '@name',
                parentForm: '@form',
                searchItemBy: '@',
                formSubmitted: '@',
                code: '@',
                placeholderKey: '@placeholder',
                searchList: '=list',
                model: '=',
                isDisabled: '=disabled'
            },
            templateUrl: 'core/components/dropdown/dropdown.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;
                vm.searchFilterExpanded = false;
                vm.searchInput = ''; 
                vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
                if (!$scope.code) {
                    $scope.code = 'id';
                }
                if (!$scope.controlName) {
                    $scope.controlName = 'name';
                }
                $scope.searchItemBy =  $scope.controlName;
                
                vm.toggleFilterSelection = function () {
                    if ($scope.isDisabled) {
                        return;
                    }
                    vm.searchFilterExpanded = !vm.searchFilterExpanded;
                };

                vm.search = function() {
                };

                vm.selectItem = function(item) {
                    vm.searchInput = item[$scope.controlName];
                    $scope.model = item[$scope.code];
                    vm.searchFilterExpanded = false;
                }

                vm.removeSearch = function() {
                    vm.searchInput = '';
                }
                vm.onOutsideClick = function() {
                    vm.searchFilterExpanded = false
                }
                $scope.$watch('model', function(newValue, oldValue) {
                    if (!newValue) {
                        vm.searchInput = '';
                    }
                });

                $scope.$watch('searchList', function(newValue, oldValue) {
                    if (newValue) {
                        var selectedItem = $scope.searchList.find(function(item) {
                            return item[$scope.code] === $scope.model;
                        });
                        if (selectedItem) {
                            vm.searchInput = selectedItem[$scope.controlName];
                        }
                    }
                });
               
                if ($scope.model && $scope.searchList) {
                    var selectedItem = $scope.searchList.find(function(item) {
                        return item[$scope.code] === $scope.model;
                    });
                    if (selectedItem) {
                        vm.selectItem(selectedItem);
                    }
                }
            }
        };
    }
  }());
  