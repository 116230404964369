(function () {
  'use strict';

  /* @ngdoc object
   * @name timeline
   * @description
   *
   */
  angular
    .module('timeline', [
      'ui.router'
    ]);
}());
