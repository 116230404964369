(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:DomesticPaymentBhCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('DomesticPaymentBhCtrl', DomesticPaymentBhCtrl);

  function DomesticPaymentBhCtrl($rootScope, CTLocalizationService, UserSettingsService, $state, $log, HelperService,
                                 ValidationService, PurposeCodesService, BanksService, CountriesService,
                                 PaymentTypesService, PaymentsService, CreditAccountRegisterService, $q,
                                 PaymentTemplatesService, ConfigEndpointService, $timeout, ReferenceModelService,
                                  $stateParams, PaymentFilterService, Notification, AlertService, ImagesService, PublicRevenueService, GoogleTagManagerHelperService, $location, NotificationService) {
    var vm = this;
    vm.images = {};
    vm.isRetrieval = false;
    if ($stateParams.myParam == 'retrieval') {
      vm.isRetrieval = true;
    }

    vm.currentStep = 1;

    vm.paymentObject = {};
    vm.tempDompObj = {
      fromAccountSelect: {},
      purposeCodeSelect: {},
      purposeOfPaymentInput: '',
      amountInput: '',
      dateInput: new Date(),
      bicCodeInput: '',
      toAccountInput: '',
      referenceCodeInput: '',
      //referenceModelSelect: {},
      referenceNumberInput: '',
      payersNameInput: '',
      payersAddressInput: '',
      payersCityInput: '',
      payersSepaInput1: '',
      payersSepaInput2: '',
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      receiversCountrySelect: {},
      receiversSepaInput1: '',
      receiversSepaInput2: '',
      isUrgentPaymentCheckbox: false,
      formSubmitted: false,
      // related to public revenue payment
      selectedRevenueAccount: '',
      taxPeriodFrom: new Date(),
      taxPeriodTo: new Date(),
      taxPayerId: '',
      revenueCode: '',
      municipality: '',
      reference: '',
      budgetOrganization: '',
      municipalities: [],
      budgetOrganizations: [],
      revenueCodes: [],
      paymentTypes: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      //internal transfer
      isInternal: false
    };
    vm.tempDompObj.paymentType = vm.tempDompObj.paymentTypes[0];
    vm.revenueAccounts = [];
    vm.revenueAccountsLoaded = false;
    vm.toAccountInputLength = 16;
    vm.split = function (input) {
      return HelperService.splitReferenceNumber(input);
    }
    vm.locationBL = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BiHLocation == "Banja Luka"
    vm.defaultCurrency = '';
    vm.forUpdate = false;
    vm.smsAuthentificate = false;
    vm.smsPasswordInput = '';
    vm.bankCharges = '';

    vm.datePickerOpened = false;
    vm.taxPeriodFromOpened = false;
    vm.taxPeriodToOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.publicRevenueDateOptions = {
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.openTaxPeriodFrom = function () {
      vm.taxPeriodFromOpened = true;
    };

    vm.openTaxPeriodTo = function () {
      vm.taxPeriodToOpened = true;
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000
    };

    vm.banksList = {};
    vm.banksFilter = {
      pageSize: 1000
    };

    vm.countriesCodeList = {};
    vm.countriesCodeFilter = {
      pageSize: 1000
    };

    vm.payeeFilter = {
      paymentTypeGroupList: ['DomesticPayment', 'RevenuePayment'],
      orderBy: 'receiverName ASC',
      page: 0,
      pageSize: 1000
    };

    vm.templatesFilter = {
      paymentTypeGroupList: ['DomesticPayment', 'RevenuePayment'],
      page: 0,
      pageSize: 1000,
      orderBy: 'name ASC'
    };

    vm.templateList = {};

    vm.tmpSearchText = {
      name: ''
    };

    vm.templateName = '';

    vm.fromAccountDropdown = false;
    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.searchFilterExpanded = false;

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.formSubmittedFlag = false;

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    //default validation for reference number
    vm.referenceMaxLength = 22;
    vm.referencePattern = vm.regexPatterns.onlyNumbersAndDashes;

    //function checkModul97Reference() {
    //  var isValid = ValidationService.checkModul97Reference(vm.tempDompObj.toAccountInput);
    //  vm.domesticPaymentForm.referenceNumberInput.$setValidity('modul97reference', isValid)
    //};

    vm.getPaymentTypeGroup = function () {
      return PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.getPurposeCodesPage = function () {
      return PurposeCodesService.getPage(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            value.optionValue = value.id + ' - ' + value.description;
          });
          vm.purposeCodesList = response;
          if (vm.purposeCodesList.result.length > 0) {
            vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
            if (vm.locationBL) {
              vm.tempDompObj.purposeOfPaymentInput = vm.tempDompObj.purposeCodeSelect.id !== '000001'? vm.tempDompObj.purposeCodeSelect.description : '';
            } else {
              vm.tempDompObj.purposeOfPaymentInput = vm.tempDompObj.purposeCodeSelect.description;
            }
          }
        })
        .catch(function () {
          $log.catch('Error loading purpose codes!');
        });
    };

    vm.getBanksPage = function () {
      return BanksService.getPage(vm.banksFilter)
        .then(function (response) {
          vm.banksList = response;
        })
        .catch(function () {
          $log.catch('Error loading banks codes!');
        });
    };

    vm.changePurpose = function (purposeChange) {
      // Check if input is coming from purpose code list
      var purpose = vm.purposeCodesList.result.find(function(item) {
        return item.id == purposeChange.id;
      });
      if (purpose) {
        if (vm.locationBL) {
          vm.tempDompObj.purposeOfPaymentInput = purpose.id !== '000001'? purpose.description : '';
        } else {
          vm.tempDompObj.purposeOfPaymentInput = purpose.description;
        }
      }
    };

    vm.getCountriesPage = function () {
      return CountriesService.getPage(vm.countriesCodeFilter)
        .then(function (response) {
          vm.countriesCodeList = response;
          var countryIndex = _.findIndex(vm.countriesCodeList.result, function (o) {
            return o.code == '191';
          });
          vm.tempDompObj.receiversCountrySelect = vm.countriesCodeList.result[countryIndex];
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };
    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.catch(error);
        });
    };

    var createPaymentObject = function () {
      var paymentObject = {};
      paymentObject.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      paymentObject.fromAccountNumber = vm.tempDompObj.fromAccountSelect.iban;
      paymentObject.toAccountNumber = vm.tempDompObj.toAccountInput.replace(/-/g, '');
      paymentObject.currencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
      paymentObject.purpose = vm.tempDompObj.purposeOfPaymentInput;
      paymentObject.dueDate = vm.tempDompObj.dateInput;
      paymentObject.amount = vm.tempDompObj.amountInput;


      //map payment as LocalCurrencyTransfer
      if (vm.tempDompObj.isInternal) {
        paymentObject.paymentTypeGroup = "LocalCurrencyTransfer";
        paymentObject.domesticPayment = {
          receiverName: vm.tempDompObj.receiversNameInput
        };
        paymentObject.paymentSubTypeGroup = 'DomesticPayment';
      } else {
        paymentObject.domesticPayment = {};
        paymentObject.purposeCodeDPS = vm.tempDompObj.purposeCodeSelect.id;
        paymentObject.paymentTypeGroup = 'DomesticPayment';
        paymentObject.domesticPayment.receiverName = vm.tempDompObj.receiversNameInput;
        paymentObject.domesticPayment.receiverAddress1 = vm.tempDompObj.receiversAddressInput;
        paymentObject.domesticPayment.urgency = vm.tempDompObj.isUrgentPaymentCheckbox ? '1' : '0';
        paymentObject.domesticPayment.destinationCurrencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
        if (vm.tempDompObj.referenceNumberInput) {
          paymentObject.domesticPayment.reference = vm.tempDompObj.referenceNumberInput;
        }

        //mapping for public revenue
        if (vm.tempDompObj.selectedRevenueAccount) {
          paymentObject.paymentTypeGroup = 'RevenuePayment';
          paymentObject.domesticPayment.taxPayerId = vm.tempDompObj.taxPayerId;
          paymentObject.domesticPayment.revenuePaymentType = vm.tempDompObj.paymentType;
          paymentObject.domesticPayment.revenueCode = vm.tempDompObj.revenueCode;
          paymentObject.domesticPayment.municipality = vm.tempDompObj.municipality;
          paymentObject.domesticPayment.budgetOrganization = vm.tempDompObj.budgetOrganization;
          paymentObject.domesticPayment.taxPeriodFrom = vm.tempDompObj.taxPeriodFrom;
          paymentObject.domesticPayment.taxPeriodTo = vm.tempDompObj.taxPeriodTo;
          paymentObject.domesticPayment.purposeCode = vm.tempDompObj.purposeCodeSelect.id;
        }
      }


      return paymentObject;
    };

    vm.insertedPaymentObject = {};

    var postPaymentAction = function (response) {
      //vm.insertedPaymentObject.paymentTypeGroup = "DomesticPayment";
      Notification.clearAll();
      vm.currentStep = 2;
      HelperService.scrollToTop();
    };

    vm.submitPayment = function () {
      vm.tempDompObj.formSubmitted = true;
      if (!vm.domesticPaymentForm.$valid) {
        return;
      }

      if (!vm.formSubmittedFlag) {
        vm.formSubmittedFlag = true;
        var objectForInsert = createPaymentObject();
        if (vm.forUpdate) {
          objectForInsert.id = vm.insertedPaymentObject.id;
          PaymentsService.updatePayment(vm.insertedPaymentObject.id, objectForInsert)
            .then(function (response) {
              vm.insertedPaymentObject = response;
              loadBankCharges(response)
                .then(function () {
                  postPaymentAction(response);
                  vm.forUpdate = false;
                  vm.formSubmittedFlag = false;
                });
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.forUpdate = false;
              // vm.domesticPaymentForm.$valid = false;
              vm.formSubmittedFlag = false;
            })
            .finally(function () {

            });
        }
        else {
          PaymentsService.insertPayment(objectForInsert)
            .then(function (response) {
              var gtmObject = HelperService.processUrlForGTM('payment');
              GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
              vm.insertedPaymentObject = response;
              loadBankCharges(response)
                .then(function () {
                  postPaymentAction(response);
                  vm.formSubmittedFlag = false;
                })
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              // vm.domesticPaymentForm.$valid = false;
              vm.formSubmittedFlag = false;

            })
            .finally(function () {

            });
        }
      }
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePayment = function () {
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deletePayment(vm.insertedPaymentObject.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({
                  paymentName: vm.insertedPaymentObject.id,
                  type: 'payment'
                })
                  .then(function () {
                    $state.go('payments.paymentsOverview');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    var mapWithPaymentResponse = function () {
      vm.tempDompObj.amountInput = vm.insertedPaymentObject.amount;
      vm.tempDompObj.toAccountInput = vm.insertedPaymentObject.toAccountNumber;
      vm.tempDompObj.dateInput = new Date(vm.insertedPaymentObject.dueDate);
      vm.tempDompObj.purposeOfPaymentInput = vm.insertedPaymentObject.purpose;
      var accountIndex = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == vm.insertedPaymentObject.accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[accountIndex];

      if (vm.insertedPaymentObject.paymentTypeGroup !== "LocalCurrencyTransfer") {
        vm.tempDompObj.receiversNameInput = vm.insertedPaymentObject.receiverName;
        vm.tempDompObj.receiversAddressInput = vm.insertedPaymentObject.receiverAddress1;
        var purposeCodeIndex = _.findIndex(vm.purposeCodesList.result, function (item) {
          return item.id == vm.insertedPaymentObject.purposeCodeDPS;
        });
        vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[purposeCodeIndex];

        // mapping related to public revenue
        if (vm.insertedPaymentObject.revenuePaymentDetails) {
          vm.tempDompObj.budgetOrganization = vm.insertedPaymentObject.revenuePaymentDetails.budgetOrganization;
          vm.tempDompObj.municipality = vm.insertedPaymentObject.revenuePaymentDetails.municipality;
          vm.tempDompObj.referenceNumberInput = vm.insertedPaymentObject.revenuePaymentDetails.reference;
          vm.tempDompObj.revenueCode = vm.insertedPaymentObject.revenuePaymentDetails.revenueCode;
          vm.tempDompObj.taxPayerId = vm.insertedPaymentObject.revenuePaymentDetails.taxPayerId;
          vm.tempDompObj.taxPeriodFrom = new Date(vm.insertedPaymentObject.revenuePaymentDetails.taxPeriodFrom);
          vm.tempDompObj.taxPeriodTo = new Date(vm.insertedPaymentObject.revenuePaymentDetails.taxPeriodTo);
          vm.tempDompObj.paymentType = _.find(vm.tempDompObj.paymentTypes, function (item) {
            return item == vm.insertedPaymentObject.revenuePaymentDetails.priority;
          })
        }
      } else {
        vm.tempDompObj.isInternal = true;
      }

    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.currentStep = 1;
      mapWithPaymentResponse();
    };

    vm.signPayment = function () {
      PaymentsService.signPayment(vm.insertedPaymentObject.id)
        .then(function (response) {
          vm.currentStep = 3;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var loadAccountData = function (accountId) {
      var index = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[index];
    };

    //////////////////////////CUSTOM SELECT//////////////////////
    vm.searchFilterExpanded = false;

    vm.toggleFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchFilterExpanded = !vm.searchFilterExpanded;
      }
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.getPayees = function () {
      PaymentTemplatesService.getPage(vm.payeeFilter)
        .then(function (response) {
          vm.payeeList = response.result;
        }).catch(function (error) {
        $log.catch(error);
      });
    };

    function isRevenueAccount() {
      vm.tempDompObj.selectedRevenueAccount = _.find(vm.revenueAccounts, function (item) {
        return vm.tempDompObj.toAccountInput == item.revenueAccountNumber;
      });
      if (vm.tempDompObj.selectedRevenueAccount) {
        vm.referenceMaxLength = 10;
        vm.referencePattern = vm.regexPatterns.onlyNumbers;
      } else {
        vm.referenceMaxLength = 22;
        vm.referencePattern = vm.regexPatterns.onlyNumbersAndDashes;
      }
    };

    vm.selectPayee = function (payeeItem) {
      populateTemplate(payeeItem);
    };

    vm.removeSearch = function () {
      vm.tempDompObj.receiversNameInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1 ||
      angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1);
    };

    ///select template component

    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;

      if (vm.searchTemplateFilterExpanded === true) {

        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
        if (vm.searchTemplateFilterExpanded && (!vm.templateList.result || vm.templateList.result.length === 0)) {
          vm.getPaymentTemplatesPage();
        }
      }
    };

    vm.getPaymentTemplatesPage = function () {
      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
          vm.templateList = response;
          if (vm.templateList)
            populateTemplatesWithImages();
          $log.debug(vm.templateList);
        }).catch(function (error) {
        $log.catch(error);
      });
    };
    vm.selectedTemplate = {};

    function populateTemplate(item) {

      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.toAccountInput = item.toAccountNumber;
      //check is revenue account
      isRevenueAccount();
      vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.tempDompObj.purposeOfPaymentInput = item.purpose;

      if (item.purposeCodeDPS && vm.purposeCodesList.result) {
        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          return purpose.id == item.purposeCodeDPS;
        });
        if (purpose) {
          vm.tempDompObj.purposeCodeSelect = purpose;
          vm.tempDompObj.purposeOfPaymentInput = item.purpose || purpose.description;
        }
      }

      vm.tempDompObj.referenceNumberInput = item.reference;
      vm.validateReferenceNumber();
      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;

      if (item.imageId) {
        vm.selectedTemplate.imageId = item.imageId;
        setImage(vm.selectedTemplate);
      }

      // in case of revenue payment
      if (item.paymentTypeGroup == "RevenuePayment") {
        vm.tempDompObj.budgetOrganization = item.budgetOrganization;
        vm.tempDompObj.municipality = item.municipality;
        vm.tempDompObj.revenueCode = item.revenueCode;
        vm.tempDompObj.taxPayerId = item.taxPayerId;
        vm.tempDompObj.paymentType = _.find(vm.tempDompObj.paymentTypes, function (val) {
          return item.revenuePaymentType = val;
        });
        vm.tempDompObj.taxPeriodFrom = new Date(item.taxPeriodFrom);
        vm.tempDompObj.taxPeriodTo = new Date(item.taxPeriodTo);
      }

      item.urgency == 1 ? vm.tempDompObj.isUrgentPaymentCheckbox = true : vm.tempDompObj.isUrgentPaymentCheckbox = false;
    };

    vm.selectTemplate = function (id) {

        GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentTemplateSelection', 'Template with id ' + id + ' selected');

      var item = _.find(vm.payeeList, {
        id: id
      });

      vm.templateName = item.name;

      populateTemplate(item);

    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    }
    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      vm.tempDompObj.purposeOfPaymentInput = '';
      vm.tempDompObj.referenceNumberInput = '';
      vm.selectedTemplate = {};

    };

    //from account component
    vm.toggleFromAccount = function () {
      if (!vm.isRetrieval) {
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
      }
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    vm.showSMSAuthentification = function () {
      vm.smsAuthentificate = !vm.smsAuthentificate;
    };


    function fillReceiver() {
      if (!vm.tempDompObj.toAccountInput) return;
      if (vm.domesticPaymentForm.toAccountInput.$valid) {
        CreditAccountRegisterService.getPage({
          accountNumber: vm.tempDompObj.toAccountInput.replace(/-/g, '')
        })
          .then(function (response) {

            if (response.result.length > 0) {
              vm.tempDompObj.receiversNameInput = response.result[0].receiverName.substring(0, 35);
              vm.tempDompObj.receiversAddressInput = response.result[0].receiverAddress1.substring(0, 35);
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.getDefaultDetails = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response) {
            vm.defaultCurrency = response.DefaultCurrency;
            vm.bankCharges = response.BankCharges;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.newPayment = function () {
      clearInput();
      vm.tempDompObj.formSubmitted = false;
      vm.forUpdate = false;
      vm.tempDompObj.selectedRevenueAccount = null;
      vm.currentStep = 1;
    };

    var clearInput = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.dateInput = new Date();
      vm.tempDompObj.referenceNumberInput = '';
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      vm.tempDompObj.purposeOfPaymentInput = '';
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
      //fields relative to public revenue
      vm.tempDompObj.revenueCode = '';
      vm.tempDompObj.municipality = '';
      vm.tempDompObj.budgetOrganization = '';
      vm.tempDompObj.taxPayerId = '';
      vm.tempDompObj.taxPeriodFrom = new Date();
      vm.tempDompObj.taxPeriodTo = new Date();
      vm.tempDompObj.paymentType = vm.tempDompObj.paymentTypes[0];
      vm.tempDompObj.isUrgentPaymentCheckbox = false;

    };

    vm.init = function () {
      if ($state.params.editable) {
        vm.insertedPaymentObject.id = $state.params.paymentId;
        vm.forUpdate = true;
      }

      ////AKO JE POVRAT/////
      if (vm.isRetrieval) {
        var promises = [vm.getPurposeCodesPage(), vm.getDefaultDetails(), vm.getPaymentTypeGroup(), getRevenueAccounts(), loadImages()];
        $q.all(promises)
          .then(function () {
            if (vm.purposeCodesList.result.length > 0) {
              //var index = _.findIndex(vm.purposeCodesList.result, function (item) {
              //  return item.id === '89';
              //});
            }
            vm.tempDompObj.amountInput = $stateParams.transactionDetails.amount;
            vm.tempDompObj.toAccountInput = $stateParams.transactionDetails.detail.toAccountNumber;
            vm.tempDompObj.fromAccountSelect = $stateParams.transactionDetails.account;
            vm.tempDompObj.receiversAddressInput = $stateParams.transactionDetails.detail.payeeAddress1;
            vm.tempDompObj.receiversNameInput = $stateParams.transactionDetails.detail.payeeName;

          })
      } else {
        vm.getPayees();

        // related to public revenue payment
        getMunicipalities();
        getRevenueCodes();
        getBudgetOrganizations();


        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
          vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          vm.defaultCurrency = vm.defaultData.DefaultCurrency;
          vm.bankCharges = vm.defaultData.BankCharges;
        }

        if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
          var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        }

        var promises = [vm.getPurposeCodesPage(), vm.getBanksPage(), vm.getCountriesPage(), vm.getPaymentTypeGroup(), getRevenueAccounts(), loadImages()];
        $q.all(promises)
          .then(function () {
            //set default form params

            var params = {
              fromAccountList: vm.paymentTypeGroupDomestic.result[0].debitAccountList,
              purposeCodeList: vm.purposeCodesList.result
              //referenceModelList: vm.referenceModelList
            };

            switch ($state.current && $state.current.name) {
              case 'payments.domesticPayment.fromTemplate':
                PaymentTemplatesService.loadDomesticPaymentData($state.params.templateId, vm.tempDompObj, params)
                  .then(function () {
                    vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                    vm.tempDompObj.isUrgentPaymentCheckbox = params.urgency;
                    isRevenueAccount();
                  })
                  .catch(function (error) {
                    $log.catch('loadDomesticPaymentData', error);
                  });
                break;
              case 'payments.domesticPayment.fromPayment':
                PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                  .then(function () {
                    vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                    vm.tempDompObj.isUrgentPaymentCheckbox = params.urgency;
                    vm.tempDompObj.isInternal = params.isInternal;
                    if($state.params.myParam == 'edit'){
                      vm.tempDompObj.dateInput = new Date(vm.tempDompObj.dueDateTemp);
                    }
                    isRevenueAccount();
                  })
                  .catch(function (error) {
                    $log.catch('loadDomesticPaymentData()', error);
                  });
                break;
              case 'payments.domesticPayment':
                if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
                  vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
                }
                if (vm.purposeCodesList.result.length > 0) {
                }
                break;
              case 'payments.domesticPayment.fromAccount':
                if ($state.params.accountId) {
                  vm.tempDompObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, $state.params.accountId);
                }
                break;
            }

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.templateImagesFilter = {
      category: "Templates"
    };

    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        console.log(JSON.stringify(images));
        vm.images = images.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    function getMunicipalities() {
      PublicRevenueService.getMunicipalities({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.tempDompObj.municipalities = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    function getRevenueCodes() {
      PublicRevenueService.getRevenueCodes({
        page: 0,
        pageSize: 6000
      })
        .then(function (response) {
          vm.tempDompObj.revenueCodes = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    function getBudgetOrganizations() {
      PublicRevenueService.getBudgetOrganizations({
        page: 0,
        pageSize: 1000
      })
        .then(function (response) {
          vm.tempDompObj.budgetOrganizations = response.result;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    // custom municipalities autocomplete
    vm.municipalityFilterExpanded = false;
    vm.openMunicipalities = function () {
      vm.municipalityFilterExpanded = true;
      validateMunicipalityExistance();
    };

    vm.toggleMunicipalities = function () {
      vm.municipalityFilterExpanded = !vm.municipalityFilterExpanded;
    };

    vm.removeMunicipalities = function () {
      vm.tempDompObj.municipality = '';
    };

    vm.selectMunicipality = function (municipality) {
      vm.tempDompObj.municipality = municipality.codeString;
      vm.municipalityFilterExpanded = false;
      validateMunicipalityExistance();
    };

    // fcn for filtering municipalities
    vm.filterMunicipalities = function (item) {
      return (angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempDompObj.municipality) || '') !== -1 ||
      angular.$$lowercase(item.codeString).indexOf(angular.$$lowercase(vm.tempDompObj.municipality) || '') !== -1);
    };

    // custom revenue type autocomplete
    vm.revenueCodeFilterExpanded = false;
    vm.openRevenueCodes = function () {
      vm.revenueCodeFilterExpanded = true;
      vm.tempDompObj.selectedRevenueCode = null;
      validateRevenueCodeExistance();
      validateRevenueCodeType();
    };

    vm.toggleRevenueCodes = function () {
      vm.revenueCodeFilterExpanded = !vm.revenueCodeFilterExpanded;
    };

    vm.closeRevenueCodes = function () {
      vm.tempDompObj.revenueCode = '';
    };

    vm.selectRevenueCode = function (revenueCode) {
      vm.tempDompObj.revenueCode = revenueCode.code;
      vm.tempDompObj.selectedRevenueCode = revenueCode;
      vm.revenueCodeFilterExpanded = false;
      validateRevenueCodeExistance();
      validateRevenueCodeType();
    };

    // fcn for filtering revenue codes
    vm.filterRevenueCodes = function (item) {
      return (angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempDompObj.revenueCode) || '') !== -1 ||
      angular.$$lowercase(item.code.toString()).indexOf(angular.$$lowercase(vm.tempDompObj.revenueCode) || '') !== -1);
    };

    // custom budget organizations autocomplete
    vm.budgetOrganizationFilterExpanded = false;
    vm.openBudgetOrganizations = function () {
      vm.budgetOrganizationFilterExpanded = true;
    };

    vm.toggleBudgetOrganizations = function () {
      vm.budgetOrganizationFilterExpanded = !vm.budgetOrganizationFilterExpanded;
    };

    vm.closeBudgetOrganization = function () {
      vm.tempDompObj.budgetOrganization = '';
    };

    vm.selectBudgetOrganization = function (budgetOrg) {
      vm.tempDompObj.budgetOrganization = budgetOrg.budgetOrganisationNumber;
      vm.budgetOrganizationFilterExpanded = false;
    };

    vm.toggleInternalPayment = function () {

    GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentIsInternalSelection', (vm.tempDompObj.isInternal)?"Internal payment selected":"Internal payment deselected");
      clearInput();
      if (vm.forUpdate) {
        vm.forUpdate = false;
      }
    };

    // fcn for filtering revenue codes
    vm.filterBudgetOrganizations = function (item) {
      return (angular.$$lowercase(item.budgetOrganisationNumber).indexOf(angular.$$lowercase(vm.tempDompObj.budgetOrganization) || '') !== -1 ||
      angular.$$lowercase(item.description).indexOf(angular.$$lowercase(vm.tempDompObj.budgetOrganization) || '') !== -1);
    };

    function getRevenueAccounts() {
      PublicRevenueService.getRevenueAccounts({
        page: 0,
        pageSize: 2000
      })
        .then(function (response) {
          vm.revenueAccounts = response.result;
          vm.revenueAccountsLoaded = true;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    // custom form validation related to public revenue
    vm.checkIsRevenueAccount = function () {
      if (!vm.tempDompObj.isInternal) {
        isRevenueAccount();
        fillReceiver();
        vm.validateReferenceNumber();
        validateRevenueCodeType();
      }
    };

    vm.checkToAccountInputLength = function () {
      var validLength = true;
      vm.tempDompObj.referenceNumberInput = '';
      if (vm.tempDompObj.toAccountInput) {
        if (vm.tempDompObj.isInternal) {
          if (vm.tempDompObj.toAccountInput && (vm.tempDompObj.toAccountInput.substr(0, 3) === "552" || vm.tempDompObj.toAccountInput.substr(0, 3) === "306")) {
            vm.toAccountInputLength = 16;
          } else {
            vm.toAccountInputLength = 8;
          }
        } else {
          vm.toAccountInputLength = 16;
        }
        if (vm.tempDompObj.toAccountInput.length == vm.toAccountInputLength) {
          validLength = true;
        } else {
          validLength = false;
        }
      }
      vm.domesticPaymentForm.toAccountInput.$setValidity('properLength', validLength);
    };

    vm.validateTaxPeriod = function () {
      var isRangeValid = false;
      if (vm.tempDompObj.taxPeriodFrom <= vm.tempDompObj.taxPeriodTo) {
        isRangeValid = true;
      }
      vm.domesticPaymentForm.taxPeriodFrom.$setValidity('taxPeriodRange', isRangeValid);
    };

    function checkRevenueItemsType (code, type) {
      if(!code){
        return true;
      }
      var codes = _.filter(vm.tempDompObj.revenueCodes, function (item){
        return item.code == code;
      });
      for(var i=0; i<codes.length; i++){
        if(type == codes[i].type){
          return true;
        }
      }
      return false;
    };

    function validateRevenueCodeType() {
      if (vm.tempDompObj.selectedRevenueAccount) {
        var correctRevenueCode = false;
        if (vm.tempDompObj.revenueCode === '000000') {
          correctRevenueCode = true;
        } else {
          correctRevenueCode = checkRevenueItemsType(vm.tempDompObj.revenueCode,vm.tempDompObj.selectedRevenueAccount.revenueType);
        }

        if (vm.domesticPaymentForm.revenueCodeFilter) {
          vm.domesticPaymentForm.revenueCodeFilter.$setValidity('revenueCodeType', correctRevenueCode);
        }
      }
    };

    function checkIfRevenueCodeExists() {
      var exists = _.find(vm.tempDompObj.revenueCodes, function (item) {
        return vm.tempDompObj.revenueCode == item.code;
      });
      if (exists) {
        return true;
      }
      return false;
    };

    function checkIfMunicipalityExists() {
      var exists = _.find(vm.tempDompObj.municipalities, function (item) {
        return vm.tempDompObj.municipality == item.codeString;
      });
      if (exists) {
        return true;
      }
      return false;
    };

    function validateRevenueCodeExistance() {
      var exist = checkIfRevenueCodeExists();
      if (!vm.tempDompObj.revenueCode) {
        exist = true;
      }
      vm.domesticPaymentForm.revenueCodeFilter.$setValidity('revenueCodeExistance', exist);
    };

    function validateMunicipalityExistance() {
      var exist = checkIfMunicipalityExists();
      if (!vm.tempDompObj.municipality) {
        exist = true;
      }
      vm.domesticPaymentForm.municipalityFilter.$setValidity('municipalityExistance', exist);
    };

    vm.completeReferenceNumber = function () {
      if (vm.tempDompObj.selectedRevenueAccount && vm.domesticPaymentForm.referenceNumberInput.$valid) {
        if (!vm.tempDompObj.referenceNumberInput) {
          vm.tempDompObj.referenceNumberInput = '';
        }
        var refLength = vm.tempDompObj.referenceNumberInput.length;
        var zeros = '';
        if (refLength < 10) {
          for (var i = 0; i < (10 - refLength); i++) {
            zeros += '0';
          }
        }
        vm.tempDompObj.referenceNumberInput = zeros + vm.tempDompObj.referenceNumberInput;
      }
    };

    vm.validateReferenceNumber = function () {
      var requiredReference = true;
      if (vm.tempDompObj.selectedRevenueAccount && (vm.tempDompObj.selectedRevenueAccount.revenueType === '92' || vm.tempDompObj.selectedRevenueAccount.revenueType === '91')) {
        requiredReference = vm.tempDompObj.referenceNumberInput ? true : false;
      }
      vm.domesticPaymentForm.referenceNumberInput.$setValidity('referenceNumberRequired', requiredReference);
    };

    vm.checkReferenceNumber = function () {
      // var allZeros = false;
      // if (vm.tempDompObj.referenceNumberInput) {
      //   allZeros = true;
      //   for (var i = 0; i < vm.tempDompObj.referenceNumberInput.length; i++) {
      //     //moguce je unijeti samo jednu nulu i potrebno je da je to validan unos - naredna logika je u completeReferenceNumber();
      //     if(i === 0 &&
      //       vm.tempDompObj.referenceNumberInput[i] === '0' && $rootScope.APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka' &&
      //       (vm.tempDompObj.selectedRevenueAccount.revenueType === '70' ||
      //       vm.tempDompObj.selectedRevenueAccount.revenueType === '21')){
      //       allZeros = false;
      //     }
      //     else if (vm.tempDompObj.referenceNumberInput[i] !== '0') {
      //       allZeros = false;
      //       break;
      //     }
      //   }
      // }
      // vm.domesticPaymentForm.referenceNumberInput.$setValidity('validReferenceNumber', !allZeros);
      var validReferenceNumber = true;
      if(vm.tempDompObj.referenceNumberInput){

        var specificRevenueType = vm.tempDompObj.selectedRevenueAccount.revenueType === '91' || vm.tempDompObj.selectedRevenueAccount.revenueType === '92';
        var banjaLuka = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka';

        //if Banja Luka and revenueType is 91 or 92 OR if isNOT Banja Luka then check if referenceNumber have one number different from zero
        //this means:
        //if Banja Luka i revenueType 91 or 92 AND referenceNumber (00000) shouldnt be valid
        //OR
        //if isNOT Banja Luka referenceNumber (00000) shouldnt be valid also


        if((banjaLuka && specificRevenueType) || !banjaLuka){
          validReferenceNumber = checkIfReferenceNumberHaveOneNumberDifferentFromZero(vm.tempDompObj.referenceNumberInput);
        }

        vm.domesticPaymentForm.referenceNumberInput.$setValidity('validReferenceNumber', validReferenceNumber);
      }
    };

    function checkIfReferenceNumberHaveOneNumberDifferentFromZero(referenceNumber) {
      for (var i = 0; i < referenceNumber.length; i++) {
        if(referenceNumber[i] !== '0') return true;
      }
      return false;
    }

    function loadBankCharges(data) {
      var urg = true;
      data.urgency === "1" ? urg = true : urg = false;
      var params = {
        isUrgent: urg,
        amount: data.amount,
        internalAccount: data.toAccountNumber.substr(0, 3)
      };

      return PaymentsService.getBankCharges(params)
        .then(function (response) {
          vm.insertedPaymentObject.bankCharges = response.result[0];

          //vm.bankChargesList = response.result;
          // var accountCode = data.toAccountNumber.substr(0, 3);
          // if (data.urgency === "1") {
          //   vm.insertedPaymentObject.bankCharges = _.find(vm.bankChargesList, function (item) {
          //     return item.isUrgent == true;
          //   });
          // } else if (accountCode != "306" || accountCode != "552") {
          //   vm.insertedPaymentObject.bankCharges = _.find(vm.bankChargesList, function (item) {
          //     return item.isInternal == true;
          //   });
          // }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.init();

      vm.pushGtmUrgentPaymentEvent = function () {
          GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentIsUrgentSelection', (vm.tempDompObj.isUrgentPaymentCheckbox)?'Urgent payment checked':'Urgent payment unchecked');
      };

  }
}());
