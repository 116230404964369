(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name orders.controller:AddNewExpressLoanCtrl
     *
     * @description
     *
     */
    angular
        .module('orders')
        .controller('AddNewExpressLoanCtrl', AddNewExpressLoanCtrl);

  function AddNewExpressLoanCtrl($q, $log, AccountsService, HelperService, UserSettingsService, AlertService, ValidationService, $state, NotificationService) {
        var vm = this;
        vm.ctrlName = 'AddNewExpressLoanCtrl';
        vm.tempObj = {
          dateInput: HelperService.setCustomMonthPeriodByDays(new Date(), 1)
        };
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.selectedAccount = {};
        vm.paymentDays = [];
        vm.currencySymbol = 'EUR';
        vm.formSubmitted = false;
        vm.accountList = [];
        vm.iAmAware = false;
        vm.disabled = function (date, mode) {
          return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        vm.numberOfMonths = [{
            description: 4
        }, {
            description: 5
        }, {
            description: 6
        }, {
            description: 7
        }, {
            description: 8
        }, {
            description: 9
        }, {
            description: 10
        }, {
            description: 11
        }, {
            description: 12
        }];

        vm.steps = {
          step1: true,
          step2: false,
          step3: false
        };

        var today = new Date(); // Friday
        //alert(today);
        today.setDate(today.getDate()+2); // t+2
        while (today.getDay() == 6 || today.getDay() == 0) today.setDate(today.getDate() + 1);
        //alert(today);

        vm.dateOptions = {
          // minDate: HelperService.setCustomMonthPeriodByDays(new Date(), 5),
          minDate: new Date(today),
          maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };

        vm.tempObj.dateInput = new Date(today);


        vm.datePickerOpened = false;
        vm.openDatePicker = function () {
          vm.datePickerOpened = true;
        };

        vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
        // vm.tempObj.monthsOfCredit = vm.numberOfMonths[0];
        vm.tempObj.monthsOfCredit = "";

        vm.back = function () {
          vm.steps = {
            step1: true,
            step2: false,
            step3: false
          };

          vm.imAware = false;
        };

        vm.addNewExpressLoan = function () {
          $state.go("orders.addNewExpressLoan", {}, {reload: true});
        };

        function loadPaymentDays() {
            var params = null;
            return AccountsService.creditPossibilityOrderTearDateType(params)
                .then(function (response) {
                    vm.paymentDays = _.sortBy(response.result, function(day) {
                      return parseInt(day.id.replace(/\D+/g, ''));
                    });
                    vm.tempObj.paymentDay = vm.paymentDays[0];
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }

        function init() {
            var promises = [
                AccountsService.getPage({
                    accountTypeGroupList: 'CurrentAccount'
                }),
                UserSettingsService.getPage(),
                loadPaymentDays()
            ];

            $q.all(promises).then(function (responses) {

              _.forEach(responses[0].result, function (account) {
                if (account.isOwn && account.currencySymbol == "EUR")
                  vm.accountList.push(account);
              });

              if(vm.accountList.length > 0) {
                  vm.selectedAccount = vm.accountList[0];
                }

                vm.tempObj.userSettings = responses[1].result[0];

            }).catch(function (error) {
                NotificationService.showMessage(error, 'error');
            });

        }

        vm.selectAccount = function (account) {
            if (!account) return;
            vm.selectedAccount = account;
        };

        function createObject() {

            return {
                creditTransferAccount: vm.selectedAccount.iban,
                paymentAccount: vm.selectedAccount.iban,
                approvalDate: vm.tempObj.dateInput,
                amount: vm.tempObj.amount,
                phoneNumber: vm.tempObj.userSettings.gsm,
                email: vm.tempObj.userSettings.email,
                period: vm.tempObj.monthsOfCredit,
                orderTearDate: {
                  id: vm.tempObj.paymentDay.id,
                  description: vm.tempObj.paymentDay.description
                },
                notificationType: {
                  id: "phone",
                  description: "string"
                },
              note: " "
            };
        }

          vm.validateCreditPossibilityOrder = function () {
            if(vm.nextButtonPressed) return;

            vm.formSubmitted = true;
            if (!vm.newExpressLoanForm.$valid) {
                return;
            }
            var obj = createObject();
            vm.nextButtonPressed = true;
            AccountsService.validateCreditPossibilityOrder(obj)
                .then(function (response) {
              vm.responseModel = response;
              vm.steps = {
                step1: false,
                step2: true,
                step3: false
              };
            })
            .catch(function (error) {
                NotificationService.showMessage(error, 'error');
            }).finally(function() {
              vm.nextButtonPressed = false;
            });

        };

        vm.confirmCreditPossibilityOrder = function () {
          if (vm.confirmButtonPressed) return;
          vm.confirmButtonPressed = true;
          AccountsService.creditPossibilityOrder(createObject()).then(function (response) {
            vm.steps = {
              step1: false,
              step2: false,
              step3: true
            };
          }).catch(function (error) {
            NotificationService.showMessage(error, 'error');
          }).finally(function () {
            vm.confirmButtonPressed = false;
          });
        };


        init();
    }
}());
