(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      paymentTemplates: {
        "payingFrom": "Uplata sa:",
        "payingTo": "Uplata na:",
        "howMuch": "Iznos",
        "description": "Opis",
        "when": "Datum",
        "fullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "showFullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "pay": "Plati",
        "delete": "Obriši",
        "save": "Snimi",
        "signPayment": "Potpiši",
        "cancel": "Odustani",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Adresa banke",
        "dueDate": "Datum dospjeća",
        "fromAccount": "Račun pošiljatelja/pošiljaoca",
        "toAccount": "Račun primatelja/primaoca",
        "receiver": "Primatelj/primalac",
        "receiverAddress": "Adresa primatelja/primaoca",
        "amount": "Iznos",
        "signMsg": "Provjerite unesene podatke i potpišite",
        "saveTemplate": "Novi uzorak",
        "invalidDate": "Datum nije validan!",
        "descriptionError": "Opis je obavezan!",
        "templateName": "Naziv uzorka",
        "templateIcon": "Slika uzorka",
        "deleteSuccess1": "Uzorak",
        "deleteSuccess2": "je uspješno obrisan."
      },
      templatesList: {
        "header":"Lista uzoraka",
        "searchTemplates": "Pretraži uzorke",
        "addNewTemplate": "+ Dodaj novi uzorak",
        "listOfTemplates":"Uzorci",
        "fromAccount":"Sa računa",
        "amount":"Iznos",
        "toPayee":"Primatelj/Primalac",
        "accountNumber":"Broj računa",
        "payeeStreet":"Adresa primatelja/primaoca",
        "payeeCity":"Grad primatelja/primaoca",
        "purposeCode":"Šifra plaćanja",
        "referenceModel":"Model",
        "referenceNumber":"Model i poziv na broj",
        "addTemplateToFavorites":"Dodaj uzorak u favorite",
        "edit":"Izmijeni",
        "save": "Snimi",
        "cancel": "Odustani"
      }
    };

    $translateProvider
      .translations('bh', data);
  }
}());
