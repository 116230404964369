(function () {
  'use strict';

  angular
    .module('sepa')
    .factory('SepaService', SepaService);

  function SepaService($log, $q, $http, EndpointsService) {

    var SepaServiceBase = {};

    SepaServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.sepaDD, {
          params: params
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDD_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaSettings = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDDSettings, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaDDComplaints = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDDComplaint, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaSettingsById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDDSettings_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.addSepaSetting = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
      $http.post(response.links.sepaDDSettings, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    SepaServiceBase.addSepaDDComplaint = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
      $http.post(response.links.sepaDDComplaint, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.deactivateSepaSetting = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
      $http.put(response.links.sepaDDSettings_deactivate, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.signSepaComplaint = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
      $http.put(response.links.sepaDDComplaint_sign, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.signSepaSetting = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
      $http.put(response.links.sepaDDSettings_sign, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.updateSepaSetting = function(id, data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.sepaDDSettings_id.replace('{id}', id), data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaSettingsTypes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDDSettingsTypes, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaSettingsTypesByAccountId = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links["sepaDDSettings_settingTypesList?accountId={accountId}"].replace('{accountId}', id), {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaCreditorsList = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        var getUrl = response.links['sepaDDSettings_creditorsList?CreditorId={CreditorId}&CompanyId={CompanyId}&HasContract={HasContract}']
          .replace('{CreditorId}', params['creditorId']).replace('{CompanyId}', '').replace('{HasContract}', '');
        // changed in order to satisfy url returned from API, should be changed on API side to remove uneccesary query params from the link
        // TODO: not sure if the creditorId param is being passed at all, needed further discussion and check
        $http.get(getUrl, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    SepaServiceBase.getSepaDDSettingsExecutionPeriodTypes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.sepaDDSettings_executionPeriodTypes, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    SepaServiceBase.getSepaDDComplaintReasonTypes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.sepaDDComplaintReasonTypes, {
          params: params
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return SepaServiceBase;
  }
}());
