<table class="table table-transparent table-responsive">
  <tbody>
  <tr>
    <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
    <td class="right-col"><span class="tridm-value-label">{{reservation.description}}</span></td>
  </tr>
  <tr>
    <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
    <td class="right-col"><span class="tridm-value-label">{{reservation.amount}} BAM</span></td>
  </tr>
  <tr>
    <td class="left-col"><span class="tridm-key-label" translate="reservations.originAmount"></span></td>
    <td class="right-col"><span class="tridm-value-label">{{reservation.sourceAmount}} {{reservation.sourceCurrency}}</span></td>
  </tr>
  <tr>
    <td class="left-col"><span class="tridm-key-label" translate="reservations.reservationDate"></span></td>
    <td class="right-col"><span class="tridm-value-label">{{reservation.reservationDate | date:'shortDate' : '+0200'}}</span></td>
  </tr>
  <tr>
    <td class="left-col"><span class="tridm-key-label" translate="reservations.type"></span></td>
    <td class="right-col"><span class="tridm-value-label">{{reservation.transactionType}}</span></td>
  </tr>
  </tbody>
</table>
