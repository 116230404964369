<section id="main" ng-if="investmentsOverview.selectedFund">
  <div class="container-fluid">

    <!-- Account informations -->
    <div class="account-header-container clearfix">

      <!-- Carousel navigation start -->
      <div class="row" data-ng-show="investmentsOverview.fundList.length > 1">
        <ol class="custom-carousel-indicators pull-right">
          <li data-ng-repeat="fund in investmentsOverview.fundList"
              data-ng-class="{'active': investmentsOverview.currentIndex === fund.index}"
              data-ng-click="investmentsOverview.chooseFromCarousel(fund.id);">
          </li>
        </ol>
      </div>

      <div class="pull-right icon-container m-r-15" ng-if="investmentsOverview.isNextButtonVisible"
           data-ng-click="investmentsOverview.goToNextItem()">
        <a class="account-navigation-icons">
          <i class="zmdi zmdi-chevron-right zmdi-hc-4x"></i>
        </a>
      </div>

      <div class="pull-left icon-container m-l-15" ng-if="investmentsOverview.isPreviousButtonVisible"
           data-ng-click="investmentsOverview.goToPreviousItem()">
        <a class="account-navigation-icons">
          <i class="zmdi zmdi-chevron-left zmdi-hc-4x"></i>
        </a>
      </div>
      <!-- Carousel navigation end -->

      <!--<div class="row">-->
      <div class="col-xs-8 account-header-inner m-l-25">
        <div class="row">
          <div class="col-xs-12 no-padding">
            <h2 class="account-name m-l-15">
              {{investmentsOverview.selectedFund.fundName}}
            </h2>
            <h4 class="m-15 c-white">{{investmentsOverview.selectedFund.shareAccount}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 white-text-block" ng-if="investmentsOverview.selectedFund.totalShareValue != undefined">
            <div class="f-24">{{investmentsOverview.selectedFund.totalShareValue| number:2}}
              {{investmentsOverview.selectedFund.currency}}
            </div>
            <hr class="account-header-line"/>
            <div class="f-16" translate="investOverview.totalShareValue"></div>
          </div>
          <div class="col-sm-6 white-text-block" ng-if="investmentsOverview.selectedFund.shareQuantity != undefined">
            <div class="f-24">{{investmentsOverview.selectedFund.shareQuantity| number:4}}
            </div>
            <hr class="account-header-line"/>
            <div class="f-16" translate="investOverview.shareQuantity"></div>
          </div>
        </div>


        <div ng-if="investmentsOverview.investmentDetailsOpen" class="row p-t-20 p-b-20">
          <div class="col-sm-6 white-text-block m-b-10">
            <h4 class="c-white">{{investmentsOverview.selectedFund.isin}}</h4>
            <div class="f-16">ISIN</div>
          </div>
          <div class="col-sm-6 white-text-block m-b-10">
            <h4 class="f-20 c-white">{{investmentsOverview.selectedFund.validationDate| date: "shortDate" }}</h4>
            <div class="f-16" translate="invest.date">Date</div>
          </div>
          <div class="col-sm-6 white-text-block m-b-10" ng-if="investmentsOverview.selectedFund.fundType">
            <h4 class="f-20 c-white">{{investmentsOverview.selectedFund.fundType}}</h4>
            <div class="f-16" translate="investOverview.fundType"></div>
          </div>
          <div class="col-sm-6 white-text-block m-b-10" ng-if="investmentsOverview.selectedFund.accountType">
            <h4 class="f-20 c-white">{{investmentsOverview.selectedFund.accountType}}</h4>
            <div class="f-16" translate="invest.accountType"></div>
          </div>
          <div class="col-sm-6 white-text-block m-b-10" ng-if="investmentsOverview.selectedFund.sharePrice">
            <h4 class="f-20 c-white">{{investmentsOverview.selectedFund.sharePrice| number:2}} {{investmentsOverview.selectedFund.currency}}</h4>
            <div class="f-16" translate="investOverview.sharePrice"></div>
          </div>
        </div>


      </div>
      <!--</div>-->

    </div>
    <div class="row m-b-10">
      <div class="col-md-12 text-center">
        <div style="background-color: white; color: red" ng-if="!investmentsOverview.investmentDetailsOpen"
             data-ng-click="investmentsOverview.investmentDetailsOpen = !investmentsOverview.investmentDetailsOpen;investmentsOverview.toggleGtmDetailsOpenEvent(investmentsOverview.investmentDetailsOpen);"
             translate="accounts.accAccountAndCardsMoreDetails">
        </div>
        <div  style="background-color: white; color: red" ng-if="investmentsOverview.investmentDetailsOpen"
             data-ng-click="investmentsOverview.investmentDetailsOpen = !investmentsOverview.investmentDetailsOpen;investmentsOverview.toggleGtmDetailsOpenEvent(investmentsOverview.investmentDetailsOpen);"
              translate="accounts.accAccountAndCardsLessDetails">
        </div>
      </div>
    </div>
    <!-- Investments button section -->
    <div class="row">
      <div class="p-10 clearfix">
        <div class="col-xs-12 col-sm-4 m-b-20">
          <a data-ui-sref="investments.form.new({orderType: 'new', fundId: investmentsOverview.selectedFund.id})">
              <div class="round-btn-container" ng-click="investmentsOverview.pushGTMevent('BuyStocks');">
              <div class="round-btn"><i class="icon icon-stockbuy" style="font-size:2em"></i></div>
              <div class="round-btn-label inv-btn-height" translate="investOverview.buyStocks"></div>
            </div>
          </a>
        </div>
        <div class="col-xs-12 col-sm-4 m-b-20" ng-if="investmentsOverview.selectedFund.accountType === 'Investitor'">
          <a href="" data-ui-sref="investments.form.new({orderType: 'sell', fundId: investmentsOverview.selectedFund.id})">
              <div class="round-btn-container" ng-click="investmentsOverview.pushGTMevent('SellStocks');">
              <div class="round-btn"><i class="icon icon-stocksell" style="font-size:2em"></i></div>
              <div class="round-btn-label inv-btn-height" translate="investOverview.sellStocks"></div>
            </div>
          </a>
        </div>
        <div class="col-xs-12 col-sm-4 m-b-20" ng-if="investmentsOverview.selectedFund.accountType === 'Investitor' && !investmentsOverview.FundExchangeOff">
          <a href="" data-ui-sref="investments.form.new({orderType: 'replace', fundId: investmentsOverview.selectedFund.id})">
              <div class="round-btn-container" ng-click="investmentsOverview.pushGTMevent('ReplaceStocks');">
              <div class="round-btn"><i class="icon icon-stock-change" style="font-size:2em"></i></div>
              <div class="round-btn-label inv-btn-height" translate="investOverview.replaceStocks">
                Replacement funds
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- TAB section -->
    <div class="msg-tab-container">
      <div class="msg-tab" ng-class="{'selected':investmentsOverview.selectedTab === 1}"
           ng-click="investmentsOverview.selectTab(1, 'investOverview.transactions')"
           translate="investOverview.transactions">
      </div>
      <div class="msg-tab" style="border-right:none;" ng-class="{'selected':investmentsOverview.selectedTab === 2}"
           ng-click="investmentsOverview.selectTab(2, 'investOverview.orders');"
           translate="investOverview.orders">
      </div>
    </div>

    <!-- Filter container  -->
    <!-- TODO: fiters not implemented on API -->
    <div>

    </div>

    <!-- ============================= -->
    <!-- START: TRANSACTIONS TIMELINE -->
    <!-- ============================= -->
    <!-- TODO: map with transactions from API-->
    <div ng-show="investmentsOverview.selectedTab == 1">
      <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">
        <ul class="hypo-timeline" kcd-recompile="investmentsOverview.investmentTransactionListGrouped">

          <div class="t-a-c p-10 no-data-image" ng-if="investmentsOverview.investmentTransactionListGrouped.result.length == 0">
            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
            <h3 class="no-data-label" translate="investOverview.noTransactionData"></h3>
          </div>
          <li ng-class="{'time-label': transaction.isVirtual}" ng-repeat="transaction in investmentsOverview.investmentTransactionListGrouped.result">
            <span class="currency-time-frame" ng-if="transaction.isVirtual == true">{{transaction.date| date:'shortDate' : '+0200'}}</span>
            <i ng-if="transaction.isVirtual == undefined" data-ng-class="{'zmdi zmdi-caret-up': transaction.type == 'Credit','zmdi zmdi-caret-down': transaction.type == 'Debit'}"></i>
            <!--<i class="zmdi zmdi-money" ng-if="!transaction.isVirtual"></i>-->
            <div ng-if="!transaction.isVirtual" class="hypo-timeline-item payment-item" ng-click="transaction.selected = !transaction.selected;" ng-class="{'opened': transaction.selected}">
              <div class="hypo-timeline-body">
                <div class="col-xs-6 col-sm-8 p-l-0 p-r-0">
                  <h4 class="m-b-0 m-t-0 c-gray no-wrap-text"><b>{{::transaction.fundName}}</b></h4>
                  <!--<p class="m-b-0 m-t-0 no-wrap-text">{{::transaction.detail.purpose}}</p>-->
                </div>
                <div class="col-xs-6 col-sm-4 p-l-0 p-r-0">
                  <span class="pull-right t-a-r m-t-5 f-18"><b>
                      {{transaction.amount| number:2}} {{::transaction.currency}}</b>
                  </span>
                </div>
              </div>
              <div ng-if="transaction.selected" class="transaction-details">
                <div data-ng-include="'investments/tl-investment-transactions-details.tpl.html'"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show-more-btn">
        <button ng-show="investmentsOverview.investmentTransactionListGrouped.hasMore" data-ng-click="investmentsOverview.loadMoreInvestmentTransaction()"
                class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">
        </button>
      </div>
    </div>
    <!-- ============================= -->
    <!-- END: TRANSACTIONS TIMELINE    -->
    <!-- ============================= -->

    <!-- ========================= -->
    <!-- START: ORDERS TIMELINE       -->
    <!-- ========================= -->
    <div ng-show="investmentsOverview.selectedTab == 2">
      <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">
        <ul class="hypo-timeline" kcd-recompile="investmentsOverview.investmentOrderListGrouped">

          <div class="t-a-c p-10 no-data-image" ng-if="investmentsOverview.investmentOrderListGrouped.result.length == 0">
            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
            <h3 class="no-data-label" translate="dashboard.noOrderData"></h3>
          </div>

          <li ng-class="{'time-label': order.isVirtual}" ng-repeat="order in investmentsOverview.investmentOrderListGrouped.result">
            <span class="currency-time-frame" ng-if="order.isVirtual === true">{{order.date| date: 'shortDate' : '+0200'}}</span>
            <i class="zmdi zmdi-money" ng-if="!order.isVirtual"></i>
            <div ng-if="!order.isVirtual" class="hypo-timeline-item payment-item"  ng-class="{'opened': order.selected}">
              <div class="hypo-timeline-body" ng-click="order.selected = !order.selected;">
                <div class="col-xs-4">
                  <div ng-switch="order.amountType">
                    <h4 class="m-b-0 m-t-0 c-gray" ng-switch-when="2">
                      <b>{{order.invAmount|number:2}} {{::order.amountCurCode}}</b>
                    </h4>
                    <h4 class="m-b-0 m-t-0 c-gray" ng-switch-when="1">
                      <b>{{order.invAmount|number:2}} {{::order.sellingCurSym}}</b>
                    </h4>
                    <h4 class="m-b-0 m-t-0 c-gray" ng-switch-when="3">
                      <b>{{order.invAmount|number:2}}</b>
                    </h4>
                  </div>

                  <p class="m-b-0 m-t-0 iban-style">{{::order.accountNumberBuy}}</p>

                  <h4 ng-switch="order.orderStatus">
                    <span ng-switch-when="0" translate="investOverview.status_created">Created</span>
                    <span ng-switch-when="2" translate="investOverview.status_recalled">Recalled</span>
                    <span ng-switch-when="3" translate="investOverview.status_processed">Processed</span>
                    <span ng-switch-when="4" translate="investOverview.status_rejected">Rejected</span>
                    <span ng-switch-when="5" translate="investOverview.status_closed">Closed</span>
                    <span ng-switch-when="6" translate="investOverview.status_signed">Signed</span>
                    <span ng-switch-when="7" translate="investOverview.status_inProcess">In Process</span>
                    <span ng-switch-when="8" translate="investOverview.status_canceled">Canceled</span>
                  </h4>
                </div>
                <div class="col-xs-4" ng-switch="order.orderType">
                  <h4 class="m-b-0 m-t-0 c-gray"  ng-switch-when="1" translate="investOverview.buyStocks"></h4>
                  <h4  class="m-b-0 m-t-0 c-gray" ng-switch-when="2" translate="investOverview.sellStocks"></h4>
                  <h4 class="m-b-0 m-t-0 c-gray" ng-switch-when="3" translate="investOverview.replaceStocks"></h4>
                </div>
                <div class="col-xs-4"><span class="pull-right t-a-r m-t-10"><b>{{::order.validationDate| date:'shortDate' : '+0200'}}</b></span></div>
              </div>
              <div ng-if="order.selected" class="transaction-details">
                <div data-ng-include="'investments/tl-investment-order-details.tpl.html'"></div>
                <div class="pull-right" ng-if="order.orderStatus == 0">
                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="investOverview.copyOrder" ng-click="investmentsOverview.copyOrder(investmentsOverview.selectedFund.id, order)">Copy order</button>
                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="investOverview.editOrder" ng-click="investmentsOverview.editOrder(investmentsOverview.selectedFund.id, order)">Edit order</button>
                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="investOverview.deleteOrder" ng-disabled="investmentsOverview.deleteButtonDisabled" ng-click="investmentsOverview.deleteInvestment(order.orderId)">Delete order</button>
                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="investOverview.signOrder" ng-click="investmentsOverview.signOrder(order)">Sign order</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show-more-btn">
        <button ng-show="investmentsOverview.investmentOrderListGrouped.hasMore" data-ng-click="investmentsOverview.loadMoreInvestmentOrders()"
                class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn" translate="dashboard.loadEvents">
        </button>
      </div>
    </div>
    <!-- ========================= -->
    <!-- END: ORDERS TIMELINE      -->
    <!-- ========================= -->
  </div>
</section>
