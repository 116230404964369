(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name orders.controller:TermDepositCtrl
     *
     * @description
     *
     */
    angular
        .module('orders')
        .controller('TermDepositCtrl', TermDepositCtrl);

    function TermDepositCtrl() {
        var vm = this;
        vm.ctrlName = 'TermDepositCtrl';
    }
}());
