<section id="main">
    <div class="container-fluid">
        <!-- START Title -->
        <div class="row">
            <div class="col-md-12" style="z-index:1;">
                <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
                    <h2 class="title-style" translate="orderSoftToken.title">Naročilo programskega generatorja gesel</h2>
                </div>
            </div>
        </div>
        <!-- END Title -->


        <div class="row p-l-15">
            <div class="col-sm-12 ost-info-section">
                <p class="ost-info-txt" translate="orderSoftToken.infoTxt1">Oddate lahko naročilo za programski generator gesel, ki ga lahko uporabljate namesto strojnega generatorja gesel. Programski generator je aplikacija na vašem mobilnem telefonu, ki omogoča generiranje enkratnih gesel za vstop v Addiko EBanko ter Addiko Mobile in lahko v celoti nadomešča strojni generator gesel.
                </p>
                <p class="ost-info-txt m-b-0">
                  <span translate="orderSoftToken.infoTxt2">Po uspešni izvedbi naročila boste v poštni predal Addiko EBanke ter na številko vašega mobilnega telefona prejeli 2 ločena aktivacijska ključa za aktiviranje programskega generatorja gesel (Soft tokena), ki služi generiranju enkratnih gesel. Več informacij o programskem generatorju gesel lahko dobite</span> <span translate="orderSoftToken.linkTxt" class="underline pointer-cst" ng-click="orderSoftToken.openWebsite();">na naši spletni strani. [https://www.addiko.si/obcani/e-bancnistvo/addiko-ebank/potrjevanje-transakcij/]</span>
                </p>
            </div>
          <form name="orderSoftToken.orderSoftTokenForm" novalidate ng-if="orderSoftToken.steps.step1">
            <div class="col-sm-12 col-xs-12 ost-edit-section bg-white">
              <div class="col-md-6 col-xs-12 p-r-0">
                  <label class="ost-label col-md-12 p-l-0" translate="settings.emailAddress"></label>
                  <div class="col-md-12 p-l-0">
                    <div class="col-md-8 p-l-0" ng-if="orderSoftToken.userSettings.notificationEmail && orderSoftToken.changeEmailButton">
                      <p class="ost-value m-b-0">{{orderSoftToken.userSettings.notificationEmail}}</p>
                    </div>

                    <!--<input type="text" class="form-control disabled" name="notificationEmail"-->
                         <!--data-ng-model="orderSoftToken.notificationEmail"-->
                         <!--data-ng-class="{'domp-error': orderSoftToken.orderSoftTokenForm.notificationEmail.$invalid && orderSoftToken.formSubmitted}"-->
                         <!--ng-disabled="orderSoftToken.changeEmailButton && orderSoftToken.userSettings.notificationEmail"-->
                         <!--ng-if="!orderSoftToken.userSettings.notificationEmail || !orderSoftToken.changeEmailButton"-->
                         <!--required>-->

                    <!--<span class="c-red"
                          ng-show="orderSoftToken.orderSoftTokenForm.notificationEmail.$error.required && orderSoftToken.formSubmitted"
                          translate="orderSoftToken.notificationEmailError"></span>-->
                  </div>
                <!--<div class="col-md-12 p-l-0 p-t-15 col-xs-12 p-r-0">-->
                    <!--<div class="col-md-6 col-xs-12 p-r-0 p-l-0 p-b-20" ng-show="orderSoftToken.changeEmailButton && orderSoftToken.userSettings.notificationEmail">-->
                      <!--<button class="btn btn-primary app-btn-blue" data-ng-model="orderSoftToken.changeEmailButton" data-ng-click="orderSoftToken.changeEmail()" translate="orderSoftToken.changeEmail">Change email</button>-->
                    <!--</div>-->
                  <!--<div class="col-md-3 col-xs-3 pull-right" ng-show="!orderSoftToken.changeEmailButton || !orderSoftToken.userSettings.notificationEmail">-->
                    <!--<button class="btn btn-primary app-btn-blue pull-right" data-ng-model="orderSoftToken.changeEmailButton" data-ng-click="orderSoftToken.changeEmailOk()" translate="orderSoftToken.okBtn">OK</button>-->
                  <!--</div>-->
                  <!--<div class="col-md-3 col-xs-3 pull-right" ng-show="!orderSoftToken.changeEmailButton || !orderSoftToken.userSettings.notificationEmail">-->
                      <!--<button class="btn btn-primary app-btn-blue pull-right" data-ng-model="orderSoftToken.changeEmailButton" data-ng-click="orderSoftToken.changeEmailCancel()" translate="orderSoftToken.cancelBtn">Cancel</button>-->
                   <!--</div>-->
                  <!--</div>-->

                </div>

                <div class="col-md-6 col-xs-12 p-r-0">
                    <label class="ost-label col-md-12 p-l-0" translate="settings.gsm"></label>
                    <div class="col-md-12 p-l-0">
                      <div class="col-md-8 p-l-0" >
                        <p ng-if="orderSoftToken.userSettings.gsm" class="ost-value m-b-0">{{orderSoftToken.userSettings.gsm}}</p>
                        <button ng-if="!orderSoftToken.userSettings.gsm" class="btn btn-default" translate="mobilePhoneSettings.setMobilePhoneNumber"  ng-click="orderSoftToken.setMobilePhoneNumber(orderSoftToken.userSettings.gsm)"></button>
                      </div>

                    <!--<input type="text" class="form-control disabled" name="gsm"-->
                           <!--data-ng-model="orderSoftToken.gsm"-->
                           <!--data-ng-class="{'domp-error': orderSoftToken.orderSoftTokenForm.gsm.$invalid && orderSoftToken.formSubmitted}"-->
                           <!--ng-disabled="orderSoftToken.changeGsmButton && orderSoftToken.userSettings.gsm"-->
                           <!--ng-if="!orderSoftToken.userSettings.gsm || !orderSoftToken.changeGsmButton"-->
                           <!--required>-->

                    <!--<span class="c-red"
                          ng-show="orderSoftToken.orderSoftTokenForm.gsm.$error.required && orderSoftToken.formSubmitted"
                          translate="orderSoftToken.gsm"></span>-->
                  </div>
                  <!--<div class="col-md-12 p-l-0 p-t-15 col-xs-12 p-r-0">-->
                    <!--<div class="col-md-6 col-xs-12 p-r-0 p-l-0 p-b-20" ng-show="orderSoftToken.changeGsmButton && orderSoftToken.userSettings.gsm">-->
                      <!--<button class="btn btn-primary app-btn-blue" data-ng-model="orderSoftToken.changeGsmButton" data-ng-click="orderSoftToken.changeGsm()" translate="orderSoftToken.changeGsm">Change email</button>-->
                    <!--</div>-->
                    <!--<div class="col-md-3 pull-right col-xs-3" ng-show="!orderSoftToken.changeGsmButton || !orderSoftToken.userSettings.gsm">-->
                      <!--<button class="btn btn-primary app-btn-blue pull-right" data-ng-model="orderSoftToken.changeGsmButton" data-ng-click="orderSoftToken.changeGsmOk()" translate="orderSoftToken.okBtn">OK</button>-->
                    <!--</div>-->
                    <!--<div class="col-md-3 pull-right col-xs-3" ng-show="!orderSoftToken.changeGsmButton || !orderSoftToken.userSettings.gsm">-->
                      <!--<button class="btn btn-primary app-btn-blue pull-right" data-ng-model="orderSoftToken.changeGsmButton" data-ng-click="orderSoftToken.changeGsmCancel()" translate="orderSoftToken.cancelBtn">Cancel</button>-->
                    <!--</div>-->
                  <!--</div>-->
                </div>
                <div class="col-sm-12 p-l-0 p-r-0 p-b-0 p-t-15 col-xs-12">

                        <div class="col-md-6">
                            <button class="btn btn-primary pull-left ng-scope" type="button" translate="orderSoftToken.cancelBtn" data-ui-sref="settings">Cancel</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-default pull-right ng-scope" type="submit" translate="orderSoftToken.nextBtn" ng-disabled="orderSoftToken.validationInProgress || (!orderSoftToken.userSettings.gsm || !orderSoftToken.userSettings.notificationEmail)" ng-click="orderSoftToken.submitOrder();">Next</button>
                        </div>

                </div>
            </div>
          </form>
          <!-- ================= -->
          <!-- ====  STEP 2 ==== -->
          <!-- ================= -->
          <div class="col-sm-12 col-xs-12 ost-edit-section" ng-if="orderSoftToken.steps.step2">
            <div class="intp-form-container bottom-border">
              <div class="row">
                <div class="col-md-4">
                  <label class="domp-form-label" translate="orderSoftToken.nameAndSurmane"></label>
                </div>
                <div class="col-md-8">
                  <label class="domp-form-label-resume">
                    {{orderSoftToken.responseModel.nameAndSurname}}
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label class="domp-form-label" translate="settings.emailAddress"></label>
                </div>
                <div class="col-md-8">
                  <label class="domp-form-label-resume">
                    {{orderSoftToken.responseModel.email}}
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label class="domp-form-label" translate="settings.gsm"></label>
                </div>
                <div class="col-md-8">
                  <label class="domp-form-label-resume">
                    {{orderSoftToken.responseModel.phoneNumber}}
                  </label>
                </div>
              </div>
            </div>
            <div class="intp-form-container">
              <div class="row">
                <div class="col-xs-6 t-a-l">
                  <button class="btn btn-primary pull-left ng-scope" type="button" translate="orderSoftToken.backBtn" ng-click="orderSoftToken.back();">Back</button>
                </div>
                <div class="col-xs-6 t-a-r">
                  <button class="btn btn-default pull-right ng-scope" type="submit" translate="orderSoftToken.okBtn" ng-disabled="orderSoftToken.confirmationInProgress" ng-click="orderSoftToken.confirmSoftTokenOrder();">Next</button>
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>
</section>
