<section id="main">
  <div class="container-fluid">
    <div class="row exchange-rates">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
        <div class="domp-header-wrapper p-r-20 p-l-10" ng-show="eBookPay.currentStep!=3">
          <div class="row domp-header-row">
            <div class="domp-header-left col-sm-12 col-md-12 col-lg-12 col-xs-12">
              <h2 ng-if="eBookPay.ebookType === 'Pay In'" translate="ebook.ebookPayIn"></h2>
              <h2 ng-if="eBookPay.ebookType === 'Pay Out'" translate="ebook.ebookPayOut"></h2>
            </div>
          </div>
        </div>
        <!----------------------->
        <div class="internal-transfer-body" ng-if="eBookPay.currentStep == 1">
          <form name="eBookPay.eBookPayForm" novalidate>
            <div class="p-10">
            </div>
            <div class="exchange-rates-body">
              <div class="row m-b-30">
                <div class="col-sm-5">
                  <!-- CUSTOM FROM Accounts dropdown-->

                  <label class="bold-font f-18" translate="payments.fromAccount"></label>
                  <div class="accounts-dropdown m-b-10" ng-if="eBookPay.accountsFrom.length > 0">
                    <div class="debit-account-widget dashboard-widget-item" ng-click="eBookPay.toggleFromAccount()" ng-style="eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      <h4 class="no-wrap-text bold-font m-b-0">
                        <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                          {{eBookPay.tempEbookObj.fromAccountSelect.friendlyName}} </b>
                        <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                          <i class="zmdi "
                             ng-class="{'zmdi-star favorite-account':eBookPay.tempEbookObj.fromAccountSelect.isFavorite,
                                       'zmdi-star-outline':!eBookPay.tempEbookObj.fromAccountSelect.isFavorite}"></i>
                        </span>
                      </h4>
                      <p class="m-b-0 m-t-0 iban-style">{{eBookPay.tempEbookObj.fromAccountSelect.accountNumber}}</p>
                      <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        {{eBookPay.tempEbookObj.fromAccountSelect.availableBalance | number:2}}
                        {{eBookPay.tempEbookObj.fromAccountSelect.currencySymbol}}
                      </p>
                      <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                      <span class="caret-icon" ng-if="eBookPay.accountsFrom.length > 1">
                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                      </span>
                    </div>
                    <div class="accounts-list m-l-15 m-r-15" ng-show="eBookPay.fromAccountDropdown" scrollable>
                      <div class="accounts-list-item p-0" data-ng-repeat="item in eBookPay.accountsFrom"
                           data-ng-click="eBookPay.setAccountFrom(item);">
                        <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"  ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}" >
                          <h4 class="no-wrap-text bold-font m-b-0">
                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                          </h4>
                          <p class="m-b-0 m-t-0 iban-style">{{item.accountNumber}}</p>
                          <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                            {{item.availableBalance | number: 2}}
                            {{item.currencySymbol}}
                          </p>
                          <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance">Available balance</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accounts-dropdown m-b-10" ng-if="eBookPay.accountsFrom.length == 0">
                    <div class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px"  ng-style="eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                      No available accounts
                    </div>
                  </div>


                  <label class="bold-font" translate="internalTransfer.amount"></label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r"
                           name="fromAccountAmountInput"
                           placeholder="{{'eInvoicesModal.amountPlaceholder' | translate}}"
                           amount-input-mask
                           maxlength="12"
                           data-ng-model="eBookPay.tempEbookObj.fromAccountAmountInput"
                           ng-if="eBookPay.ebookType==='Pay In'"
                           ng-blur="eBookPay.exchangeCurrency(false)"
                           ng-class="{'domp-error': eBookPay.eBookPayForm.fromAccountAmountInput.$invalid &&
                                     eBookPay.tempEbookObj.formSubmitted}"
                           required >
                    <input type="text" class="form-control input-field t-a-r"
                           name="fromAccountAmountInput"
                           placeholder="{{'eInvoicesModal.amountPlaceholder' | translate}}"
                           amount-input-mask
                           maxlength="12"
                           data-ng-model="eBookPay.tempEbookObj.fromAccountAmountInput"
                           ng-if="eBookPay.ebookType==='Pay Out'"
                           ng-class="{'domp-error': eBookPay.eBookPayForm.fromAccountAmountInput.$invalid &&
                                     eBookPay.tempEbookObj.formSubmitted}"
                           required>
                    <span class="input-group-btn">
                      <button type="button" class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;" disabled>
                        {{eBookPay.tempEbookObj.fromAccountSelect.currencySymbol}}
                      </button>
                    </span>
                  </div>
                  <span class="c-red" ng-show="eBookPay.eBookPayForm.fromAccountAmountInput.$error.required &&
                                               eBookPay.tempEbookObj.formSubmitted" translate="internalTransfer.amountInputError">
                  </span>
                  <span class="c-red" ng-show="eBookPay.eBookPayForm.fromAccountAmountInput.$error.maxlength &&
                                               eBookPay.tempEbookObj.formSubmitted"
                        translate="internalTransfer.amountLengthError">
                  </span>
                </div>
                <div class="col-sm-2">
                  <div class="t-a-c exchange-office-middle">
                    <i class="zmdi zmdi-long-arrow-right zmdi-hc-2x"></i>
                  </div>
                </div>
                <div class="col-md-5 col-sm-5">
                  <label class="bold-font f-18" translate="payments.toAccount"></label>
                  <!-- CUSTOM TO Accounts dropdown -->

                  <div class="accounts-dropdown">

                    <div class="accounts-dropdown m-b-10" ng-if="eBookPay.accountsTo.length > 0">
                      <div class="debit-account-widget dashboard-widget-item"
                           data-ng-click="eBookPay.toggleToAccount();" ng-style="eBookPay.tempEbookObj.toAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + eBookPay.tempEbookObj.toAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                        <h4 class="no-wrap-text bold-font m-b-0">
                          <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                            {{eBookPay.tempEbookObj.toAccountSelect.friendlyName}} </b>
                          <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                            <i class="zmdi "
                               ng-class="{'zmdi-star favorite-account':account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                          </span>
                        </h4>
                        <p class="m-b-0 m-t-0 iban-style">{{eBookPay.tempEbookObj.toAccountSelect.accountNumber}}</p>
                        <p ng-show="eBookPay.tempEbookObj.hasEbookWithSelectedCurrency && !eBookPay.tempEbookObj.toAccountSelect.newCurrencyMessage" class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                          {{eBookPay.tempEbookObj.toAccountSelect.availableBalance | number:2}}
                          {{eBookPay.tempEbookObj.toAccountSelect.currencySymbol}}
                        </p>

                        <p ng-show="eBookPay.tempEbookObj.hasEbookWithSelectedCurrency && eBookPay.tempEbookObj.toAccountSelect.newCurrencyMessage" class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                        0,00 {{eBookPay.tempEbookObj.masterAccountCurrencySymbol}}
                        </p>

                        <p ng-show="!eBookPay.tempEbookObj.hasEbookWithSelectedCurrency" translate="internalTransfer.nodata"
                           class="e-book-pay-in-no-data"></p>

                        <span class="d-block m-t-5 m-b-10" ng-hide="eBookPay.tempEbookObj.toAccountSelect.newCurrencyMessage" translate="currencyConversion.availableBalance"></span>
                        <span class="d-block m-t-5 m-b-10" ng-if="eBookPay.tempEbookObj.toAccountSelect.newCurrencyMessage" translate="currencyConversion.newCurrencyWillCreate">Kreirati će se nova valuta</span>
                        <span class="caret-icon" ng-if="eBookPay.accountsTo.length > 1">
                          <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                        </span>
                      </div>
                      <div class="from-accounts-list" ng-show="eBookPay.toAccountDropdown" scrollable>
                        <div class="accounts-list-item p-0" data-ng-repeat="item in eBookPay.accountsTo"
                             data-ng-click="eBookPay.setAccountTo(item);">
                          <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"  ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}" >
                            <h4 class="no-wrap-text bold-font m-b-0">
                              <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                              <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                            </h4>
                            <p class="m-b-0 m-t-0 iban-style">{{item.accountNumber}}</p>
                            <p ng-show="!item.newCurrencyMessage" class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                              {{item.availableBalance | number: 2}}
                              {{item.currencySymbol}}
                            </p>
                            <p ng-show="item.newCurrencyMessage" class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                              0,00 {{eBookPay.tempEbookObj.masterAccountCurrencySymbol}}
                            </p>
                            <span class="d-block m-t-5 m-b-10" ng-hide="item.newCurrencyMessage" translate="currencyConversion.availableBalance">Available balance</span>
                            <span class="d-block m-t-5 m-b-10" ng-hide="!item.newCurrencyMessage" translate="currencyConversion.newCurrencyWillCreate">Kreirati će se nova valuta</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accounts-dropdown m-b-10" ng-if="eBookPay.accountsTo.length == 0">
                    <div class="debit-account-widget dashboard-widget-item" style="height: 133px; line-height: 133px" ng-style="eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + eBookPay.tempEbookObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}" >
                      No available accounts
                    </div>
                  </div>

                  <!-- END TO ACCOUNT CONTROL -->

                  <!--<label class="bold-font" translate="currencyConversion.amount" ng-if="eBookPay.ebookType === 'Pay In'"></label>-->

                  <!--<div class="row" ng-if="eBookPay.ebookType === 'Pay In'">-->
                    <!--<div class="col-sm-8 col-xs-8 p-r-0">-->
                      <!--<input type="text" class="form-control input-field t-a-r"-->
                             <!--placeholder="{{'eInvoicesModal.amountPlaceholder' | translate}}"-->
                             <!--amount-input-mask-->
                             <!--maxlength="12"-->
                             <!--data-ng-model="eBookPay.tempEbookObj.toAccountAmountInput" disabled>-->
                    <!--</div>-->
                    <!--<div class="col-sm-4 col-xs-4 p-l-0">-->
                      <!--<select class="form-control input-field" ng-options="item for item in eBookPay.currencyList"-->
                              <!--data-ng-model="eBookPay.tempEbookObj.toCurrencySymbol"-->
                              <!--data-ng-change="eBookPay.changeSellCurrency(true);"-->
                              <!--ng-disabled="eBookPay.tempEbookObj.fromCurrencyInput != eBookPay.localCurrency" style="border:1px solid #062a42;">-->
                      <!--</select>-->
                    <!--</div>-->
                    <!--<div class="col-sm-4 col-xs-4 p-l-0">
<input type="text" class="form-control input-field" data-ng-model="eBookPay.tempEbookObj.buyCurrencyInput.symbol">
</div> -->
                  <!--</div>-->
                  <div class="row">
                    <div class="col-sm-8 col-md-12 p-t-10">
                      <label class="bold-font" translate="internalTransfer.when"></label>
                      <div class="input-group">
                        <input type="text" class="form-control input-field" name="dueDateInput"
                               uib-datepicker-popup="shortDate" data-ng-model="eBookPay.tempEbookObj.dueDateInput"
                               current-text="{{'core.period.today' | translate}}"
                               clear-text="{{'core.datePicker.clearBtn' | translate}}"
                               close-text="{{'core.datePicker.doneBtn' | translate}}"
                               show-weeks="false" is-open="eBookPay.datePickerOpened"
                               datepicker-options="eBookPay.dateOptions"
                               ng-class="{'domp-error': eBookPay.eBookPayForm.dueDateInput.$invalid && eBookPay.tempEbookObj.formSubmitted}"
                               required>
                        <span class="input-group-btn">
                          <button data-ng-click="eBookPay.openDatePicker()" type="button"
                                  class="btn input-group-app-btn domp-btn-height" style="border:1px solid #062a42;"><i class="icon icon-calendar" style="font-size: 21px;"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-6 t-a-l">
                  <button class="btn btn-primary" translate="payments.cancel"
                          data-ui-sref="payments.paymentsOverview">
                  </button>
                </div>
                <div class="col-xs-6 t-a-r">
                  <div class="pull-right">
                    <button class="btn btn-default app-btn-blue w-100 domp-review-and-submit-btn"
                            translate="currencyConversion.confirm"
                            data-ng-click="eBookPay.eBookPaySubmit();"
                            ng-disabled="eBookPay.submitButtonDisabled"
                            >
                      <!--  ng-disabled="eBookPay.accountsFrom.length == 0" -->

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Internal transfer STEP 2 -->
        <div ng-if="eBookPay.currentStep == 2">
          <div data-ng-include="'payments/e-book-pay-resume.tpl.html'">
          </div>
        </div>

        <!-- Internal transfer STEP 3 -->
        <div ng-if="eBookPay.currentStep == 3">
          <div data-ng-include="'payments/e-book-pay-final.tpl.html'"></div>
        </div>

      </div>
    </div>
  </div>
</section>
