(function () {
  'use strict';

  angular
    .module('comtradeBankingWeb')
    .config(config);

  function config($urlRouterProvider, $httpProvider, $locationProvider, NotificationProvider, $compileProvider, ConfigServiceProvider) {
    $httpProvider.interceptors.push('SessionInjectorService');
    $urlRouterProvider.otherwise('/dashboard');
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.headers.common = {};
    $httpProvider.defaults.headers.post = {};
    $httpProvider.defaults.headers.put = {};
    $httpProvider.defaults.headers.patch = {};

    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common["X-Requested-With"];

    //NOTE: Disabling POST options with this
    //$httpProvider.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    //$httpProvider.defaults.headers.common["Accept"] = "*/*";
    $httpProvider.defaults.headers.common["content-type"] = "application/json";

    //$httpProvider.defaults.headers.common['X-User-Agent'] = "MyClient" ;
    //$locationProvider.html5Mode(true).hashPrefix('!');
    $locationProvider.hashPrefix('!');
    //alert(SessionService.accessToken);

    NotificationProvider.setOptions({
      delay: null,
      startTop: 70,
      startRight: 0,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      positionX: 'center',
      positionY: 'top',
      replaceMessage: null,
      maxCount: null
    });

    if (!ConfigServiceProvider.debugInfoEnabled) {
      $compileProvider.debugInfoEnabled(false);
    }

  }
}());
