<section id="main">
  <div class="container">
    <div class="row f-14">
      <div class="col-sm-4 no-padding-on-phone">

        <div class="wb-list-wrapper app-white-card m-b-15">
          <div>
            <div class="cell-display">
              <i class="zmdi zmdi-chevron-left zmdi-hc-2x"></i>
            </div>
            <div class="cell-display p-l-20 align-vertical-middle">
              <a href="" data-ng-click="einvoiceSubscribe.goBack();" class="custom-link" translate="eInvoicesSubscribe.goBack"></a>
            </div>
          </div>
        </div>
        <div class="wb-list-wrapper app-white-card m-b-15 hidden-xs">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
        </div>
      </div>
      <!-- CENTER -->
      <div class="col-sm-8 no-padding-on-phone">
        <div class="app-white-card m-b-15">
          <div class="sbscr-header-wrapper bottom-border">
            <div class="sbscr-header-card row no-margin ">
              <h2 class="col-md-8 header-label" translate="eInvoicesSubscribe.subscribeToEinvoice"></h2>

              <div class="col-md-4 col-xs-4">
                <h2>
                  <span class="sbscr-step-icon pull-right">
                    <i class="zmdi zmdi-circle"
                       ng-class="{'sbscr-active-step': einvoiceSubscribe.steps.step1}"></i>
                    <i class="zmdi zmdi-circle"
                       ng-class="{'sbscr-active-step': einvoiceSubscribe.steps.step2}"></i>
                    <i class="zmdi zmdi-circle"
                       ng-class="{'sbscr-active-step': einvoiceSubscribe.steps.step3}"></i>
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div class="sbscr-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</div>
          <div ng-show="einvoiceSubscribe.steps.step1">
            <form name="einvoiceSubscribe.einvoiceSubscribeForm" novalidate>
              <div class="sbscr-form-container light-bcg bottom-border">
                <div>
                  <div class="row" data-ng-repeat="error in einvoiceSubscribe.customErrorLabels">
                    <div class="col-sm-6">
                      <div class="c-red">{{error}}</div>
                    </div>
                  </div>
                </div>
                <!-- ISSUER-->
                <div class="row m-l-0">
                  <div class="col-md-4 col-sm-6 p-l-0 sbscr-sbttl-blue">
                    <div class="p-l-10" > <h4 class="sbscr-sbttl" translate="eInvoicesSubscribe.einvoiceIssuer"></h4>
                    </div>
                  </div>
                  <div class="col-md-8"></div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label sbscr-label-height" translate="eInvoicesSubscribe.einvoiceIssuer">
                    </div>
                  </div>
                  <div class="col-sm-6 sbscr-label-value">{{einvoiceSubscribe.issuerObject.name}}</div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.issuerAddress">
                    </div>
                  </div>
                  <div class="col-sm-6 sbscr-label-value">Adresa</div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.issuerAccount">
                    </div>
                  </div>
                  <div class="col-sm-6 sbscr-label-value">{{einvoiceSubscribe.issuerObject.iban}}</div>
                </div>
                <div class="row">
                  <div class="col-sm-5 col-md-5">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.referenceFromIssuer">
                    </div>
                  </div>
                  <div class="col-sm-1 col-xs-1 col-md-1 p-r-0">
                    <span class="pull-right" uib-tooltip="{{'eInvoicesSubscribe.tooltipInfo' | translate}}">
                      <i class="zmdi zmdi-help-outline zmdi-hc-lg"></i>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input type="text" class="form-control" name="einvoiceReferenceFromIssuerInput"
                             data-ng-model="einvoiceSubscribe.tempObj.einvoiceReferenceFromIssuerInput"
                             data-ng-class="{'domp-error': einvoiceSubscribe.einvoiceSubscribeForm.einvoiceReferenceFromIssuerInput.$invalid && einvoiceSubscribe.tempObj.formSubmitted}"
                             required>
                      <span class="c-red"
                            ng-show="eInvoicesSubscribe.einvoiceSubscribeForm.einvoiceReferenceFromIssuerInput.$error.required && einvoiceSubscribe.tempObj.formSubmitted"
                            translate="eInvoicesSubscribe.einvoiceReferenceFromIssuerInputError"></span>
                    </div>
                  </div>
                </div>
                <!-- RECEIVER-->
                <div class="row m-l-0">
                  <div class="col-md-4 col-sm-6 p-l-0 sbscr-sbttl-blue">
                    <div class="p-l-10"> <h4 class="sbscr-sbttl" translate="eInvoicesSubscribe.einvoiceReceiver"></h4>
                    </div>
                  </div>
                  <div class="col-md-8"></div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label sbscr-label-height" translate="eInvoicesSubscribe.receiversName">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="sbscr-label-value">{{einvoiceSubscribe.userSettings.result[0].firstName}} {{einvoiceSubscribe.userSettings.result[0].lastName}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.receiversAddress">
                    </div>
                  </div>
                  <div class="col-sm-6 sbscr-label-value">{{einvoiceSubscribe.userSettings.result[0].address1}}</div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.receiversTaxId">
                    </div>
                  </div>
                  <div class="col-sm-6 sbscr-label-value">{{einvoiceSubscribe.userSettings.result[0].identificationNumber}}</div>
                </div>

                <div class="row">
                  <div class="col-sm-5 col-md-5">
                    <div class="sbscr-group-label intp-label-height" translate="eInvoicesSubscribe.yourContactEmail">
                    </div>
                  </div>
                  <div class="col-sm-1 col-xs-1 col-md-1 p-r-0">
                    <span class="pull-right" uib-tooltip="{{'eInvoicesSubscribe.tooltipInfoEmail' | translate}}">
                    <i class="zmdi zmdi-help-outline zmdi-hc-lg"></i>
                  </span>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input type="text" class="form-control disabled" name="einvoiceSubscribeEmailInput"
                             data-ng-model="einvoiceSubscribe.tempObj.einvoiceSubscribeEmailInput"
                             data-ng-class="{'domp-error': eInvoicesSubscribe.einvoiceSubscribeForm.einvoiceSubscribeEmailInput.$invalid && einvoiceSubscribe.tempObj.formSubmitted}"
                             ng-disabled="einvoiceSubscribe.changeEmailButton" required>
                      <span class="c-red"
                            ng-show="eInvoicesSubscribe.einvoiceSubscribeForm.einvoiceSubscribeEmailInput.$error.required && einvoiceSubscribe.tempObj.formSubmitted"
                            translate="eInvoicesSubscribe.einvoiceSubscribeEmailInputError"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <label class="checkbox checkbox-inline-inline ebrm-checkbox-value">
                      <input type="checkbox" ng-model="einvoiceSubscribe.tempObj.userApproves">
                      <span class="input-helper terms-label"><a href="" target="_blank" translate="eInvoicesSubscribe.termsAndConditions"></a></span>
                    </label>
                  </div>
                  <div class="col-md-4" ng-show="einvoiceSubscribe.changeEmailButton">
                    <button class="btn btn-primary app-btn-blue pull-right" data-ng-model="einvoiceSubscribe.changeEmailButton" data-ng-click="einvoiceSubscribe.changeEmail()" translate="eInvoicesSubscribe.changeEmail">Change email</button>
                  </div>

                  <div class="col-md-3 reset-btn" ng-show="!einvoiceSubscribe.changeEmailButton">
                    <button class="btn btn-primary app-btn-blue pull-right" data-ng-model="einvoiceSubscribe.changeEmailButton" data-ng-click="einvoiceSubscribe.changeEmailCancel()" translate="eInvoicesSubscribe.cancel">Cancel</button>
                  </div>
                  <div class="col-md-1" ng-show="!einvoiceSubscribe.changeEmailButton">
                    <button class="btn btn-primary app-btn-blue pull-right" data-ng-model="einvoiceSubscribe.changeEmailButton" data-ng-click="einvoiceSubscribe.changeEmailOk()" translate="eInvoicesSubscribe.ok">OK</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8" ng-show="einvoiceSubscribe.specialConditionsApprovementVisible">
                    <label class="checkbox checkbox-inline-inline ebrm-checkbox-value">
                      <input type="checkbox">
                      <span class="input-helper terms-label"><a href="" target="_blank" translate="eInvoicesSubscribe.specialConditions"></a></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="intp-form-container">
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn main-form-btn" data-ng-click="einvoiceSubscribe.einvoiceSubscribeSubmit()" translate="eInvoicesSubscribe.reviewandSubmit">
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div ng-show="internalTransfer.steps.step2">
            <div data-ng-include="'payments/internal-transfer-resume.tpl.html'">
            </div>
          </div>

          <div ng-show="internalTransfer.steps.step3">
            <payment-final-step payment="internalTransfer.paymentResponse" is-modal="false"></payment-final-step>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
