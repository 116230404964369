<form name="eBookPayForm" novalidate>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
          <label class="d-block domp-form-label-resume">{{eBookPay.paymentResponse.fromAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel">Amount</label>
            <label class="d-block domp-form-label-resume">
              {{eBookPay.paymentResponse.amount | currency:""}}
              {{eBookPay.paymentResponse.currencySymbol}}
            </label>
          </div>
          <div class="col-sm-6">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel">When</label>
            <label class="d-block domp-form-label-resume">{{eBookPay.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.toAccount">To account</label>
          <label class="d-block domp-form-label-resume">{{eBookPay.paymentResponse.toAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6" ng-if="eBookPay.ebookType==='Pay In'">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
          <label class="d-block domp-form-label-resume">{{eBookPay.paymentResponse.purpose}}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="m-10">
    <div class="row">
      <div class="col-sm-9 col-xs-6">
        <button class="btn btn-primary" translate="currencyConversion.back"
                data-ng-click="eBookPay.backToEbookPay();">
        </button>
        <button class="btn btn-primary" translate="payments.edit"
                data-ng-click="eBookPay.editPayment();"
                >
        </button>
        <button class="btn btn-primary-warning" translate="payments.delete"
                data-ng-click="eBookPay.deletePayment();">
        </button>
      </div>

      <div class="col-sm-3 col-xs-6">
        <div class="pull-right">
          <button class="btn btn-default"
                  translate="currencyConversion.sign"
                  data-ui-sref="payments.sign({paymentId: eBookPay.paymentResponse.id, type: 'ebook'})">
          </button>
        </div>
      </div>
    </div>
  </div>

</form>

