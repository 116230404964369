(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:amountInputMask
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="core">
   <file name="index.html">
   <amount-input-mask></amount-input-mask>
   </file>
   </example>
   *
   */
  angular
      .module('core')
      .directive('amountInputMask', amountInputMask);

  function amountInputMask($rootScope, $filter, $timeout, $locale) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
         "allowEmpty": "@allowEmpty"
      },
      link: function (scope, element, attrs, ngModel) {

        var regex;
        var oldValue;

        $rootScope.$on('$translateChangeEnd', function (evt, data) {
          // For some reason $locale is not updated after translate change end
          // so little bit timeout is needed to reformat input after language change
          $timeout(function () {
            reformatViewValue();
          }, 100);
        });

        // Replace comma with dot or dot with comma - depends on selected language
        // Example: en - comma to dot, others - dot to comma
        element.on("keypress", function (evt) {
          var position = element[0].selectionStart;
          var keycode = evt.which || evt.keyCode;

          if ($locale.id == "en") {
            // keycode 44 (comma)
            if (keycode == 44) {
              replaceSeparator(".", position);
              element[0].setSelectionRange(position + 1, position + 1);
              evt.preventDefault();
              return false;
            }
          } else {
            // keycode 46 (dot)
            if (keycode == 46) {
              replaceSeparator(",", position);
              element[0].setSelectionRange(position + 1, position + 1);
              evt.preventDefault();
              return false;
            }
          }
        });

        function replaceSeparator(character, position) {
          var value = element.val();
          var newValue = value.substr(0, position) + character + value.substr(position);
          ngModel.$setViewValue(newValue);
          ngModel.$render();
        }

        if (attrs.ngCurrency === 'false')
          return;

        attrs.$observe('min', function (v) {
          scope.min = v;
        });
        attrs.$observe('max', function (v) {
          scope.max = v;
        });

        attrs.$observe('ngRequired', function (v) {
          scope.ngRequired = v;
        });

        // attrs.$observe('allowEmpty', function (v) {
        //   scope.allowEmpty = v;
        // });

        attrs.$observe('maxlength', function (v) {
          scope.maxlength = v;
        });

        attrs.$observe('maxAmount', function(v){
          scope.maxAmount = v;
        });

        attrs.$observe('minAmount', function(v){
          scope.minAmount = v;
        });

        // number of decimals - default is 2
        scope.fraction = (typeof attrs.fraction !== 'undefined') ? +attrs.fraction : 2;


        function decimalRex(dChar) {
          return RegExp("\\d|\\-|\\" + dChar, 'g');
        }

        function clearRex(dChar) {
          return RegExp("\\-{0,1}((\\" + dChar + ")|([0-9]{1,}\\" + dChar + "?))&?[0-9]{0," + scope.fraction + "}", 'g');
        }

        function clearValue(value) {
          value = String(value);
          var dSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
          var cleared = null;

          if (value.indexOf($locale.NUMBER_FORMATS.DECIMAL_SEP) == -1 &&
              value.indexOf('.') != -1 &&
              scope.fraction)
          {
            dSeparator = '.';
          }

          // Replace negative pattern to minus sign (-)
//          var neg_dummy = $filter('currency')("1", undefined, scope.fraction);
          var neg_regexp = RegExp("[0-9." + $locale.NUMBER_FORMATS.DECIMAL_SEP + $locale.NUMBER_FORMATS.GROUP_SEP + "]+");
//          var neg_dummy_txt = neg_dummy.replace(neg_regexp.exec(neg_dummy), "");
          var value_dummy_txt = value.replace(neg_regexp.exec(value), "");


          if (decimalRex(dSeparator).test(value))
          {
            cleared = value.match(decimalRex(dSeparator))
                .join("").match(clearRex(dSeparator));
            cleared = cleared ? cleared[0].replace(dSeparator, ".") : null;
          }

          return cleared;
        }


        function reformatViewValue() {
          var formatters = ngModel.$formatters,
              idx = formatters.length;

          var viewValue = ngModel.$$rawModelValue;
          while (idx--) {
            viewValue = formatters[idx](viewValue);
          }

          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        }

        ngModel.$parsers.push(function (viewValue) {
          // Validate and only allow numbers, dot and commas
          // TODO: It would be good to allow only one dot or coma decimal separator!
          regex = new RegExp(/^[\d,.]*$/i);
          if (regex.test(viewValue)) {
            oldValue = viewValue;
          } else {
            ngModel.$setViewValue(oldValue);
            ngModel.$render();
          }
          return viewValue;
        });

        ngModel.$parsers.push(function (viewValue) {
          var cVal = clearValue(viewValue);
          // Check for fast digitation (-. or .)
          if (cVal == "." || cVal == "-.")
          {
            cVal = ".0";
          }
          return parseFloat(cVal);
        });

        element.on("blur", function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });

        ngModel.$formatters.push(function (value) {
          if (value == "" || typeof value == "undefined" || value == null)
            return;
          return $filter('currency')(value, '', scope.fraction).trim();
        });

        ngModel.$validators.min = function (cVal) {
          if (!scope.ngRequired && isNaN(cVal)) {
            return true;
          }
          if (typeof scope.min !== 'undefined') {
            return cVal >= parseFloat(scope.min);
          }
          return true;
        };

        ngModel.$validators.maxAmount = function(cVal) {
          if(cVal > scope.maxAmount) return false;
          return true;
        };


         ngModel.$validators.minAmount = function(cVal) {
          if(cVal < scope.minAmount) return false;
          return true;
        };

        scope.$watch('min', function (val) {
          ngModel.$validate();
        });

        ngModel.$validators.max = function (cVal) {
          if (!scope.ngRequired && isNaN(cVal)) {
            return true;
          }
          if (typeof scope.max !== 'undefined') {
            return cVal <= parseFloat(scope.max);
          }
          return true;
        };

        ngModel.$validators.maxlength = function (cVal) {
          if (scope.allowEmpty && scope.allowEmpty.toLowerCase() == 'true') {
            return true;
          }

          var valueLen;
          if (!cVal)
            return false;

          if (cVal % 1 != 0) {
            valueLen = cVal.toString().length;
          } else {
            // +1 is for dot
            valueLen = cVal.toString().length + scope.fraction + 1;
          }

          if (valueLen > scope.maxlength)
            return false;
          return true;
        };

        scope.$watch('max', function (val) {
          ngModel.$validate();
        });


//        ngModel.$validators.fraction = function (cVal) {
//          if (!!cVal && isNaN(cVal)) {
//            return false;
//          }
//
//          return true;
//        };

        scope.$on('currencyRedraw', function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });

        element.on('focus', function () {
          var viewValue = ngModel.$$rawModelValue;

          if (isNaN(viewValue) || viewValue === '' || viewValue == null)
          {
            viewValue = '';
          } else
          {
            viewValue = parseFloat(viewValue).toFixed(scope.fraction).toString().replace(".", $locale.NUMBER_FORMATS.DECIMAL_SEP);
          }
          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        });
      }
    };
  }
}());



