(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:ChangePaymentDateModalCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('ChangePaymentDateModalCtrl', ChangePaymentDateModalCtrl);

  function ChangePaymentDateModalCtrl(obj) {
    var vm = this;
    vm.ctrlName = 'ChangePaymentDateModalCtrl';
    vm.obj = obj;
  }
}());
