<div class="statementModal">
       <div class="row">
        <div class="col-md-3">
      <i class="icon icon-statement zmdi-hc-4x p-r-10 col-sm-1 p-l-0" style="color:white;margin-left:102px; margin-top:45px;"></i>
      
        </div>
        <div class="col-md-9">
            <div class="modal-header statement-confirm-header" style="padding-bottom: 8px;">
                <h3 class="state-title-top" translate="statementConfirmModalTranslations.title"></h3>
            </div>
            <div class="modal-body text-center">
                <ng-form name="statementConfirmModal.statementForm" style="color:white">
                    <p translate="statementConfirmModalTranslations.p1" class="state-title-par"></p>
                    <h3 translate="statementConfirmModalTranslations.title" class="state-title-top"></h3>
                    <p style="text-align:left;" class="state-title-par">
                        <span translate="statementConfirmModalTranslations.p2"></span> {{statementConfirmModal.selectedCountry.amountInput}} {{statementConfirmModal.selectedCountry.chargeAccountInput.currencySymbol}}
                        <span translate="statementConfirmModalTranslations.p2_2"></span> {{statementConfirmModal.selectedCountry.bankCountrySelect.description}}
                        <span translate="statementConfirmModalTranslations.p2_0"></span>
                    </p>
                    <p>
                        <label class="radio-inline state-title-par sel-spacing">
                            <input ng-model="statementConfirmModal.model.stateOption" value="0" type="radio">
                            <span translate="statementConfirmModalTranslations.radioMerchandise"></span>
                        </label>
                        <label class="radio-inline state-title-par sel-spacing">
                            <input ng-model="statementConfirmModal.model.stateOption" value="1" type="radio">
                            <span translate="statementConfirmModalTranslations.radioPresent"></span>
                        </label>
                        <label class="radio-inline state-title-par sel-spacing">
                            <input ng-model="statementConfirmModal.model.stateOption" value="2" type="radio">
                            <span translate="statementConfirmModalTranslations.radioOther"></span>
                        </label>
                    </p>
                    <p translate="statementConfirmModalTranslations.p2_1" class="state-title-par">
                        detailed explanation (specification)*
                    </p>

                    <div class="row">
                        <div class="col-md-12">
                            <textarea name="information" class="form-control statement-confirm-textarea" ng-class="{'statement-confirm-information-error': statementConfirmModal.statementForm.$error.required && statementConfirmModal.statementForm.submitted}" ng-model="statementConfirmModal.model.information" ng-required="statementConfirmModal.model.stateOption != 1" maxlength="200" style="border-radius: 7px;border-style:none;">
                            </textarea>
                            <p translate="statementConfirmModalTranslations.p4" class="state-title-par" style="text-align:left;margin-top:9px;"></p>
                        </div>
                    </div>
                </ng-form>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-primary-red statementBtnConf" ng-click="statementConfirmModal.confirm()"><span translate="statementConfirmModalTranslations.confirmButton"></span></button>
                        <button class="btn btn-primary-red statementBtnClose" ng-click="$close()"><span translate="statementConfirmModalTranslations.cancelButton"></span></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>