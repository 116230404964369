(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:EBookPayCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('EBookPayCtrl', EBookPayCtrl);

  function EBookPayCtrl($rootScope, $stateParams, $state, PaymentsService, $log, HelperService, AccountsService, PaymentTypesService,
                        $timeout, CurrencyService, ExchangeRatesService, Notification, NotificationService) {
    var vm = this;
    vm.ctrlName = 'EBookPayCtrl';
    vm.currentStep = 1;
    vm.ebookType = $stateParams.payType;
    var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
    vm.paymentObject = {};
    vm.tempEbookObj = {
      fromAccountSelect: {},
      toAccountSelect: {},
      fromAccountAmountInput: '',
      toAccountAmountInput: '',
      dueDateInput: new Date(),
      formSubmitted: false,
      masterAccountCurrencySymbol: '',
      fromCurrencySymbol: '',
      toCurrencySymbol: '',
      currentDate: HelperService.getYesterday().firstDay,
      hasEbookWithSelectedCurrency: true
    };
    vm.defaultCurrency = defaultData.DefaultCurrency;
    vm.paymentResponse = {};
    vm.toAccountDropdown = false;
    vm.newCurrencyMessage = false;
    vm.forUpdate = false;
    vm.accountsFrom = [];
    vm.accountsTo = [];
    vm.currencyList = [];
    var tempId = '';

    vm.templatesFilter = {
      paymentTypeGroup: 'ESavingPayment',
      page: 0,
      pageSize: 1000
    };

    vm.accountsFilter = {
      accountId: $stateParams.accountId,
      accountTypeGroupList: 'CurrentAccount',
      includeList: ['subAccountList']
    };

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.toggleToAccount = function () {
      if (vm.accountsTo.length > 1)
        vm.toAccountDropdown = !vm.toAccountDropdown;
    };

    vm.toggleFromAccount = function () {
      if (vm.accountsFrom.length > 1)
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    var setToAccountList = function (accountId) {
      vm.accountsTo = _.filter(vm.accountsFrom, function (item) {
        return item.accountId != accountId;
      });
    };

    var setToAccountListByCurrency = function (currency) {

      PaymentTypesService.getByGroup({
        paymentTypeGroup: 'ESavingPayment',
        masterAccountId: vm.tempEbookObj.fromAccountSelect.accountId
      })
        .then(function (response) {

          if (_.isArray(response.result) && response.result.length > 0) {

            vm.toAccountsList = response.result[0].creditAccountList;


            var element = _.find(vm.toAccountsList, function (x) {
              // return x.currencySymbol.includes(currency)
              return x.currencySymbol.indexOf(currency) !== -1;
            });
            if (vm.toAccountsList.length > 0) {
              if (element) {

                vm.newCurrencyMessage = false;

                var racunTo = _.filter(vm.toAccountsList, function (item) {
                  return item.currencySymbol == currency;
                });

                vm.setAccountTo(racunTo[0]);
                vm.accountsTo = _.filter(vm.toAccountsList, function (item) {
                  return item.currencySymbol == currency;
                });
              }
              else {
                vm.toAccountsList[0].currencySymbol = currency;
                vm.setAccountTo(vm.toAccountsList[0]);
                vm.newCurrencyMessage = true;
              }
            }
          }

        })
        .catch(function (error) {
          $log.error(error);
        });


    };

    var setToAccount = function (accountNumber) {
      setToAccountList(vm.tempEbookObj.fromAccountSelect.accountId);
      vm.tempEbookObj.toAccountSelect = vm.accountsTo[0];
    };

    vm.setAccountTo = function (item) {
      vm.tempEbookObj.toAccountSelect = item;
      vm.tempEbookObj.toCurrencySymbol = item.currencySymbol;
      vm.tempEbookObj.hasEbookWithSelectedCurrency = true;
      vm.fromAccountDropdown = false;
      vm.toAccountDropdown = false;
      vm.changeSellCurrency();
      // setFromAccountListByCurrency(vm.tempEbookObj.toCurrencySymbol);
    };

    vm.setAccountFrom = function (item) {
      vm.tempEbookObj.fromAccountSelect = item;
      vm.tempEbookObj.fromCurrencySymbol = item.currencySymbol;
      vm.fromAccountDropdown = false;
      vm.toAccountDropdown = false;
      vm.currentFromAccountId = vm.tempEbookObj.fromAccountSelect.accountId;
      setToAccountListByCurrency(vm.tempEbookObj.fromCurrencySymbol);
    };

    vm.getDebitAccountList = function () {
      return PaymentTypesService.getByGroup({
        paymentTypeGroup: 'ESavingPayment'
      })
        .then(function (response) {
          if (_.isArray(response.result) && response.result.length > 0) {
            vm.accountsFrom = response.result[0].debitAccountList;
            _.forEach(vm.accountsFrom, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.accountDescription + ' - ' + item.iban;
              }
            });
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    var setDefaultCurrency = function () {
      if (vm.tempEbookObj.toAccountSelect) {
        var index = _.findIndex(vm.currencyList, function (item) {
          return item === vm.tempEbookObj.toAccountSelect.currencySymbol;
        });

        if (index != -1) {
          vm.tempEbookObj.toCurrencySymbol = vm.currencyList[index];
        }
      } else {
        vm.tempEbookObj.toCurrencySymbol = vm.currencyList[0].symbol;
      }
    };

    var clearInput = function () {
      vm.templateName = '';
      vm.tempEbookObj.fromAccountAmountInput = '';
      vm.tempEbookObj.dueDateInput = new Date();
      //vm.tempEbookObj.purposeOfPaymentInput = '';
      vm.tempEbookObj.toAccountAmountInput = '';
      setDefaultCurrency();
      vm.tempEbookObj.formSubmitted = false;
    };

    var loadCurrencies = function () {
      return CurrencyService.getCurrencyPage({
        isCurrencyForPayment: true,
        paymentType: 'ESavingPayment',
        pageSize: 1000
      })
        .then(function (response) {
          _.forEach(response.result, function (item) {
            vm.currencyList.push(item.symbol);
          });
          vm.tempEbookObj.toCurrencySymbol = vm.currencyList[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var prepareCurrencyExchangeObject = function () {
      var tempObj = {
        currencySymbolFrom: vm.tempEbookObj.fromAccountSelect.currencySymbol,
        amount: vm.tempEbookObj.fromAccountAmountInput,
        currencySymbolTo: vm.tempEbookObj.toCurrencySymbol,
        fixedAmount: 'D'
      };
      return tempObj;
    };

    vm.exchangeCurrency = function (flag) {
      if (vm.tempEbookObj.fromAccountSelect.currencySymbol !== vm.tempEbookObj.toCurrencySymbol) {
        $timeout(function () {
          if (vm.tempEbookObj.fromAccountAmountInput) {
            var exchangeRateParams = prepareCurrencyExchangeObject();
            ExchangeRatesService.calculateExchangeRate(exchangeRateParams)
              .then(function (response) {
                if (exchangeRateParams.fixedAmount === 'D') {
                  vm.tempEbookObj.toAccountAmountInput = response.calculatedAmount.toFixed(5);
                }
                if (flag)
                  setDefaultCurrency();
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        }, 0);
      } else {
        vm.tempEbookObj.toAccountAmountInput = vm.tempEbookObj.fromAccountAmountInput;
      }
    };

    vm.changeSellCurrency = function (changedFromList) {

      if (changedFromList && vm.tempEbookObj.toCurrencySymbol) {
        // Find first account with selected currency
        var firstAccountWithSelectedCurrency = _.find(vm.accountsTo, function (account) {
          return account.currencySymbol == vm.tempEbookObj.toCurrencySymbol && account.accountNumber == vm.tempEbookObj.toAccountSelect.accountNumber
        });

        // Set selected "to account" based on currency (if exists)
        if (firstAccountWithSelectedCurrency) {
          vm.tempEbookObj.toAccountSelect = firstAccountWithSelectedCurrency;
          vm.tempEbookObj.hasEbookWithSelectedCurrency = true;
        } else {
          vm.tempEbookObj.hasEbookWithSelectedCurrency = false;
        }
      }

      if (vm.tempEbookObj.fromAccountAmountInput) {
        vm.exchangeCurrency(false);
      }
    };

    ////SUBMIT/////
    var createPaymentObject = function () {
      if (vm.ebookType === 'Pay In') {
        return {
          accountId: vm.tempEbookObj.fromAccountSelect.accountId,
          fromAccountNumber: HelperService.parseAccountNumber(vm.tempEbookObj.fromAccountSelect.accountNumber),
          toAccountNumber: HelperService.parseAccountNumber(vm.tempEbookObj.toAccountSelect.accountNumber),
          amount: vm.tempEbookObj.fromAccountAmountInput,
          dueDate: vm.tempEbookObj.dueDateInput,
          currencySymbol: vm.tempEbookObj.fromAccountSelect.currencySymbol,
          paymentTypeGroup: 'ESavingPayment',
          //purpose: vm.tempEbookObj.purposeOfPaymentInput,
          domesticPayment: {
            destinationCurrencySymbol: vm.tempEbookObj.fromAccountSelect.currencySymbol,
            purposeCode: 'I'
          }
        };
      } else {
        return {
          accountId: vm.tempEbookObj.fromAccountSelect.accountId,
          fromAccountNumber: HelperService.parseAccountNumber(vm.tempEbookObj.fromAccountSelect.accountNumber),
          toAccountNumber: HelperService.parseAccountNumber(vm.tempEbookObj.toAccountSelect.accountNumber),
          amount: vm.tempEbookObj.fromAccountAmountInput,
          dueDate: vm.tempEbookObj.dueDateInput,
          currencySymbol: vm.tempEbookObj.fromAccountSelect.currencySymbol,
          paymentTypeGroup: 'ESavingPayment',
          domesticPayment: {
            destinationCurrencySymbol: vm.tempEbookObj.toAccountSelect.currencySymbol,
            purposeCode: 'O'
          }
        };
      }
      ;
    };

    var postPaymentAction = function (response) {
      vm.paymentResponse = response;
      vm.paymentResponse.paymentTypeGroup = 'ESavingPayment';
      Notification.clearAll();
      vm.currentStep = 2;
    };

    vm.eBookPaySubmit = function () {
      vm.tempEbookObj.formSubmitted = true;
      if (!vm.eBookPayForm.$valid) {
        return;
      }
      var temPaymentObj = createPaymentObject();
      tempId = vm.tempEbookObj.toAccountSelect.accountId;
      if ($stateParams.editable) {
        vm.forUpdate = true;
      }

      if (vm.forUpdate) {
        vm.submitButtonDisabled = true;
        if (vm.paymentResponse.id)
          temPaymentObj.id = vm.paymentResponse.id
        else
          temPaymentObj.id = $stateParams.paymentId;

        console.log(temPaymentObj);

        PaymentsService.updatePayment(temPaymentObj.id, temPaymentObj)
          .then(function (response) {
            postPaymentAction(response);
            vm.forUpdate = false;
            vm.submitButtonDisabled = false;
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
            vm.forUpdate = false;
            vm.submitButtonDisabled = false;
          });
      } else {
        PaymentsService.insertPayment(temPaymentObj)
          .then(function (response) {
            postPaymentAction(response);
            vm.submitButtonDisabled = false;

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
            vm.submitButtonDisabled = false;
          });
      }
    };

    vm.getAccountsFromEbook = function (currentIdFrom) {
      if (currentIdFrom) {
        AccountsService.getAccountById(currentIdFrom)
          .then(function (response) {
            vm.tempEbookObj.toAccountSelect = response;
            vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
            $log.debug('toEbook', response);
            if (response.parentAccountId) {
              vm.getMasterAccountFromPaymentTypes(response.parentAccountId, response.currencySymbol, true, 'ebook', response.accountId);
            }
          }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }
      else {
        switch ($state.current.name) {
          case 'payments.ebookPay.payIn':
            AccountsService.getAccountById($stateParams.accountId)
              .then(function (response) {
                vm.tempEbookObj.toAccountSelect = response;
                vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
                $log.debug('toEbook', response);
                if (response.parentAccountId) {
                  vm.getMasterAccountFromPaymentTypes(response.parentAccountId, response.currencySymbol, true, 'ebook', response.accountId);
                }
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
            break;
          case 'payments.ebookPay.payOut':
            AccountsService.getAccountById($stateParams.accountId)
              .then(function (response) {
                vm.tempEbookObj.fromAccountSelect = response;
                vm.accountsFrom.push(vm.tempEbookObj.fromAccountSelect);
                $log.debug('fromAccountSelect', response);
                if (response.parentAccountId) {
                  vm.getMasterAccountFromPaymentTypes(response.parentAccountId, response.currencySymbol, false);
                }
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              })
            break;
        }
      }
    };

    vm.getMasterAccountFromPaymentTypes = function (id, symbol, payIn, flag, ebookId) {
      vm.paymentTypesFilter = {
        masterAccountId: id,
        paymentTypeGroup: 'ESavingPayment',
        currencySymbol: symbol,
        payIn: payIn
      };
      PaymentTypesService.getByGroup(vm.paymentTypesFilter)
        .then(function (response) {
          console.log('payin ' + payIn);
          if (!payIn) {
            vm.accountsTo = response.result[0].creditAccountList;
            if (vm.accountsTo.length > 0) {
              vm.accountsTo = _.filter(vm.accountsTo, function (item) {
                return item.currencySymbol == symbol;
              });
              if (vm.accountsTo.length > 0) {
                vm.tempEbookObj.toAccountSelect = vm.accountsTo[0];
              }
              else {
                vm.tempEbookObj.toAccountSelect = (response.result[0] && response.result[0].creditAccountList) ? response.result[0].creditAccountList[0] : {} ;
                vm.tempEbookObj.toAccountSelect.currencySymbol = vm.tempEbookObj.fromAccountSelect.currencySymbol;
                vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
                vm.newCurrencyMessage = true;
              }
            }
          } else {
            // pokupi racune iz responsea, debitni su accountsFrom a kreditni su accountsTo
            vm.accountsFrom = response.result[0].debitAccountList;
            vm.accountsTo = response.result[0].creditAccountList;

            // provjeri koji racuni ne postoje u selektovanoj valuti
            vm.accountsTo.forEach(function (account) {
              if (account.currencySymbol !== symbol) {
                account.newCurrencyMessage = true;
              }
            });

            if (vm.accountsFrom.length > 0) {
              // pokusaj naci prvi koji postoji u selektovanoj valuti
              var index = _.findIndex(vm.accountsFrom, function (item) {
                return item.currencySymbol === symbol;
              });
              // postavi prvi ako nema pronadjenog u selektovanoj valuti, otherwise postavi pronadjenog
              if (index != -1) {
                vm.tempEbookObj.fromAccountSelect = vm.accountsFrom[index];
              } else {
                vm.tempEbookObj.fromAccountSelect = vm.accountsFrom[0];
              }
            }
            if (vm.accountsTo.length > 0) {
              vm.racunTo = vm.accountsTo[0];
              // pokusaj naci prvi koji postoji u selektovanoj valuti i koji postoji
              var index = _.findIndex(vm.accountsTo, function (item) {
                if (ebookId) {
                  return item.currencySymbol === symbol && item.accountId === ebookId;
                } else {
                  return item.currencySymbol === symbol;
                }
              });
              // postavi prvi ako nema pronadjenog u selektovanoj valuti, otherwise postavi pronadjenog
              if (index != -1) {
                vm.tempEbookObj.toAccountSelect = vm.accountsTo[index];
              } else {
                vm.tempEbookObj.toAccountSelect = vm.accountsTo[0];
              }

              //ako se dolazi s ebook na pay in, da ostane samo ta knjizica u to account
              if (flag == 'ebook') {
                vm.accountsTo = [];
                vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
              }
              //ako se dolazi s current na pay in, da ostane samo taj racun u from account
              if (flag == 'current') {
                vm.accountsFrom = [];
                vm.accountsFrom.push(vm.tempEbookObj.fromAccountSelect);
              }
            }
            if (vm.tempEbookObj.fromAccountSelect && vm.tempEbookObj.fromAccountSelect.currencySymbol !== vm.defaultCurrency) {
              filterCurrencyList();
            }
            setDefaultCurrency();
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.getAccountsFromCurrent = function (id, symbol) {
      vm.tempEbookObj.masterAccountCurrencySymbol = symbol;
      vm.getMasterAccountFromPaymentTypes(id, symbol, true, 'current');
    };

    var mapWithPaymentResponse = function () {
      vm.tempEbookObj.fromAccountAmountInput = vm.paymentResponse.amount.toFixed(5);
      vm.tempEbookObj.dueDateInput = new Date(vm.paymentResponse.dueDate);
    };

    vm.backToEbookPay = function () {
      clearInput();
      vm.currentStep = 1;
    };

    vm.editPayment = function () {
      mapWithPaymentResponse();
      vm.forUpdate = true;
      vm.currentStep = 1;
    };

    vm.deletePayment = function () {
      PaymentsService.deletePayment(vm.paymentResponse.id)
        .then(function (response) {
          HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], vm.paymentResponse.id);
          $state.go('payments.paymentsOverview');
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.getAccountsFromPayment = function (accountId, toAccountNumber, destinationCurrencySymbol) {
      AccountsService.getAccountById(accountId)
        .then(function (response) {
          if (response.accountType.group === 'CurrentAccount') {
            vm.ebookType = 'Pay In';
            vm.tempEbookObj.fromAccountSelect = response;
            vm.accountsFrom.push(vm.tempEbookObj.fromAccountSelect);
            loadCurrencies()
              .then(function (res) {
                PaymentTypesService.getByGroup({
                  paymentTypeGroup: 'ESavingPayment',
                  payIn: true,
                  masterAccountId: accountId,
                  currencySymbol: response.currencySymbol
                })
                  .then(function (paymentTypeResponse) {
                    if (paymentTypeResponse.result[0].creditAccountList.length > 0) {
                      var index = _.findIndex(paymentTypeResponse.result[0].creditAccountList, function (item) {
                        var temp = toAccountNumber.replace(/\D+$/g, "");
                        return item.accountNumber === temp && item.currencySymbol === destinationCurrencySymbol;
                      });
                      vm.tempEbookObj.toAccountSelect = paymentTypeResponse.result[0].creditAccountList[index];
                      vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
                      vm.tempEbookObj.toCurrencySymbol = vm.tempEbookObj.toAccountSelect.currencySymbol;
                      vm.exchangeCurrency(true);
                    }
                  })
                  .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });

          } else {
            vm.ebookType = 'Pay Out';
            vm.tempEbookObj.fromAccountSelect = response;
            vm.accountsFrom.push(vm.tempEbookObj.fromAccountSelect);
            // AccountsService.getAccountById(response.parentAccountId) //(old version)
            var numberAndSymbol = toAccountNumber.split(" ");
            AccountsService.getPage({accountNumber: numberAndSymbol[0], currencySymbol: numberAndSymbol[1]})
              .then(function (response) {
                // vm.tempEbookObj.toAccountSelect = response; //(old version)
                vm.tempEbookObj.toAccountSelect = response.result[0];
                vm.accountsTo.push(vm.tempEbookObj.toAccountSelect);
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.init = function () {
      switch ($state.current.name) {
        case 'payments.ebookPay.payIn':
          vm.ebookType = 'Pay In';
          loadCurrencies()
            .then(function (response) {
              AccountsService.getAccountById($stateParams.accountId)
                .then(function (accountResponse) {
                  if (!accountResponse.parentAccountId) {
                    vm.getAccountsFromCurrent(accountResponse.accountId, accountResponse.currencySymbol);
                  }
                  if (accountResponse.parentAccountId && accountResponse.accountType.group === 'SavingAccountTypes') {
                    vm.getAccountsFromEbook();
                  }
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
          break;
        case 'payments.ebookPay.payOut':
          vm.ebookType = 'Pay Out';
          vm.getAccountsFromEbook();
          break;
        case 'payments.ebookPay.fromPayment':
          if ($stateParams.paymentId) {
            PaymentsService.getById($stateParams.paymentId)
              .then(function (response) {
                vm.paymentObject = response;
                vm.tempEbookObj.fromAccountAmountInput = response.amount;
                vm.getAccountsFromPayment(response.accountId, response.toAccountNumber, response.destinationCurrencySymbol);
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
          break;
      }
    };

    // If from account currency symbol is not default currency, use only default currency and that value.
    var filterCurrencyList = function () {
      vm.currencyList = _.filter(vm.currencyList, function (item) {
        return item === vm.defaultCurrency || item === vm.tempEbookObj.fromAccountSelect.currencySymbol;
      });
    };

    vm.init();
  }
}());
