(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      viber: {
        title: "Putem aplikacije Viber možete aktivirati uslugu Addiko Chat Banking",
        activationWay: "Odaberite način aktivacije",
        scanQrCode: "Skeniranje QR kôda",
        activationCode: "Aktivacijski kôd",
        agree: "Prihvaćam Opće uvjete korištenja usluga direktnog bankarstva za potrošače",
        activate: "Aktiviraj",
        deactivateText: "Vaš korisnički račun je trenutno aktivan",
        deactivate: "Deaktiviraj",
        deactivateModalText: "Jeste li sigurni da želite deaktivirati uslugu Addiko Chat Banking?",
        cancel: "Odustani",
        activationSuccess: "Usluga Addiko Chat Banking je uspješno deaktivirana",
        viberCondition1: "Opći uvjeti korištenja usluga direktnog bankarstva za potrošače ",
        viberCondition2: " primjenjuju se i na usluge koje se obavljaju putem Addiko Chat Bankinga. Viber korisnički račun koji ćete koristiti za pristup ovoj usluzi Addiko banke, koristit će se u skladu s ",
        viberCondition3: "uvjetima i politikom aplikacije Viber ",
        viberCondition4: "za koju ste već ranije dali suglasnost, prilikom prijave na Viber servis.",
        scanQrCodeC1: "Skenirajte prikazani QR kôd putem aplikacije Viber (opcija 'More', 'QR code scanner').",
        scanQrCodeC2: "Nakon skeniranja ćete automatski biti preusmjereni na javni Viber korisnički račun Addiko banke gdje će Vas dočekati automatska chat aplikacija 'Addiko chat bot' te od Vas zatražiti da potvrdite odnosno pošaljete aktivacijski kôd. Pošaljite kôd putem Viber chat poruke.",
        scanQrCodeC3: "Poruku s verifikacijskim kôdom primit ćete putem SMS poruke na broj telefona koji ste naveli prilikom ugovaranja usluge internetskog ili mobilnog bankarstva.",
        scanQrCodeC4: "Prepišite verifikacijski kôd iz SMS poruke u Viber chat i pošaljite ga.",
        scanQrCodeC5: "Osmislite svoj 4-znamenkasti PIN. 'Addiko chat bot' će od Vas tražiti da ovaj PIN unesete 2 puta kako biste završili proces aktivacije Addiko Viber Chat Bankinga.",
        scanQrCodeC6: "NAPOMENA: Ovaj PIN ćete ubuduće koristiti za pristup usluzi Addiko Viber Chat Banking, kao i prilikom potvrde platnih naloga obavljenih putem Vibera tako da ga obavezno upamtite!",
        activationCodeC1: "Pronađite javni korisnički račun Addiko banke na Viberu i pratite nas! ('Follow' / 'Pratitelj')",
        activationCodeC2: "Pokrenite chat opciju koja se nalazi u gornjem desnom kutu aplikacije Viber.",
        activationCodeC3: "U dnu zaslona uređaja na kojem koristite Viber (mobilni telefon, tablet, računalo) nalaze se opcije za uslugu Addiko Viber Chat Banking. Pokrenite opciju 'Aktiviraj'.",
        activationCodeC4: "Prepišite aktivacijski kôd sa stranice internetskog bankarstva Addiko EBank u Viber chat. Pošaljite poruku s upisanim aktivacijskim kôdom.",
        activationCodeC5: "Poruku s verifikacijskim kôdom primit ćete putem SMS poruke na broj telefona koji ste naveli prilikom ugovaranja usluge internetskog ili mobilnog bankarstva.",
        activationCodeC6: "Prepišite verifikacijski kôd iz SMS poruke u Viber chat i pošaljite ga.",
        activationCodeC7: "Osmislite svoj 4-znamenkasti PIN. 'Addiko chat bot' će od Vas tražiti da ovaj PIN unesete 2 puta kako biste završili proces aktivacije Addiko Viber Chat Bankinga.",
        activationCodeC8: "NAPOMENA: Ovaj PIN ćete ubuduće koristiti za pristup usluzi Addiko Chat Banking, kao i prilikom potvrde transakcija obavljenih putem Vibera, tako da ga obavezno upamtite!",
        yourActivationCode: "Vaš kôd za aktivaciju:",
        scanQrCodeC7: "Pronađite javni profil Addiko banke (Addiko Bank Hrvatska) u aplikaciji Viber, u dijelu Public Accounts i pratite nas! ('Follow' / 'Prati')",
        scanQrCodeC8: "Skenirajte prikazani QR kôd putem aplikacije Viber (opcija 'More - QR code scanner' u Viber izborniku). Nakon skeniranja kôda bit ćete preusmjereni na javni profil Addiko banke na Viberu, a aktivacijski kôd će se automatski upisati u poruku. Pošaljite poruku s aktivacijskim kôdom.",
        activationCodeC9: "Pronađite javni profil Addiko banke (Addiko Bank Hrvatska) u aplikaciji Viber, u dijelu Public Accounts i pratite nas! ('Follow' / 'Prati')",
        activationCodeC10: "Pokrenite chat opciju koja se nalazi u gornjem desnom kutu aplikacije Viber. U dnu zaslona uređaja na kojem koristite Viber nalaze se opcije za uslugu Addiko Chat Banking.  Pokrenite opciju 'Aktiviraj'. Unesite više prikazani aktivacijski kôd u Viber poruku i pošaljite je.",
        additionalActivation1: "Nakon slanja aktivacijskog kôda, putem SMS poruke primit ćete verifikacijski kôd. Poruka će Vam stići na broj mobilnog telefona kojeg ste zadnjeg dali Banci. Prepišite verifikacijski kôd iz SMS poruke u Viber chat  i pošaljite ga.",
        additionalActivation2: "Osmislite svoj 4-znamenkasti PIN. Da biste uspješno završili proces aktivacije, bit će potrebno ovaj PIN unijeti dva puta kako biste ga potvrdili.",
        additionalActivation3: "OBAVEZNO  zapamtite ovaj PIN jer ćete ga ubuduće koristiti za pristup usluzi Addiko Chat Banking na Viberu, kao i prilikom potvrde transakcija obavljenih putem Addiko Chat Bankinga",
        step1oth: "Odaberite opciju „Prati“. Primit ćete SMS poruku s poveznicom koja će Vas preusmjeriti izravno na javni profil Addiko banke na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime ćete postati pratilac našeg javnog profila.",
        step2oth: "Vratite se u EBank i započnite aktivaciju usluge odabirom opcije 'Aktiviraj'. Primit ćete SMS poruku s aktivacijskom poveznicom koja će Vas preusmjeriti izravno na Addiko Chat Banking na Viberu. Kroz Viber aplikaciju pošaljite aktivacijski kod koji će Vam automatski biti upisan u polje Viber poruke.",
        step3oth: 'Nakon uspješne potvrde aktivacijskog koda, primit ćete SMS s jednokratnom zaporkom. Tu zaporku upišite u polje za poruke u Addiko Chat Bankingu i pošaljite kroz aplikaciju Viber.',
        step4oth: 'Nakon uspješne potvrde unesene zaporke, kreirajte Vaš osobni PIN unutar Addiko Chat Bankinga. Ovaj PIN ćete ubuduće koristiti prilikom prijave i potvrde plaćanja putem Addiko Chat Bankinga.',
        step1hr: "Odaberite opciju „Prati“. Primit ćete SMS poruku s poveznicom koja će Vas preusmjeriti izravno na javni profil Addiko banke na Viberu. U aplikaciji Viber odaberite opciju „Prati/Follow“ čime ćete postati pratilac našeg javnog profila.",
        step2hr: "Vratite se u EBank i započnite aktivaciju usluge odabirom opcije 'Aktiviraj'. Primit ćete SMS poruku s aktivacijskom poveznicom koja će Vas preusmjeriti izravno na Addiko Chat Banking na Viberu. Kroz Viber aplikaciju pošaljite aktivacijski kod koji će Vam automatski biti upisan u polje Viber poruke.",
        step3hr: 'Nakon uspješne potvrde aktivacijskog koda, primit ćete SMS s jednokratnom zaporkom. Tu zaporku upišite u polje za poruke u Addiko Chat Bankingu i pošaljite kroz aplikaciju Viber.',
        step4hr: 'Nakon uspješne potvrde unesene zaporke, kreirajte Vaš osobni PIN unutar Addiko Chat Bankinga. Ovaj PIN ćete ubuduće koristiti prilikom prijave i potvrde plaćanja putem Addiko Chat Bankinga.',
        becomeFollower: 'Prati',
        conditionHeader: "Obavljajte bankarske poslove putem Vibera jednostavno i brzo.",
        conditionHeader2: 'Usluga Addiko Chat Banking omogućuje Vam:',
        text1: 'pregled stanja Vaših računa',
        text2: 'plaćanje putem postojećih predložaka te pregled plaćanja',
        text3: 'lociranje najbližeg Addiko bankomata ili poslovnice',
        text4: 'informacije o trenutno aktualnoj Addiko ponudi',
        activeMessage: 'Vaš Addiko Chat Banking je aktivan.',
        mobileNumber: 'Broj telefona uz koji je povezana usluga Addiko Chat Bankinga:',
        activationDate: 'Datum aktivacije usluge:',
        successfulActivation: 'Uspješna aktivacija.',
        unsuccessfulActivation: 'Neuspješna aktivacija.',
        smsSent: 'SMS poruka je uspješno poslana. Molim Vas kliknite na poveznicu koju ste primili u SMS poruci.',
        successFollower: 'Postali ste pratilac Viber profila Addiko banke. Dobro došli!',
        becomeFollowerAlert: "Ako ste već pratilac Addiko javnog profila na Viberu, ovaj korak nije potreban. Želite li preskočiti ovaj korak i nastaviti s opcijom 'Aktiviraj'?",
        yes: "Da",
        no: "Ne",
        agree2: "Suglasan/a sam s Izjavom o zaštiti osobnih podataka",
        personalData: "Potvrđujem da prihvaćanjem ove Izjave dopuštam Addiko Bank d.d. da u sklopu usluge Addiko Chat Banking na Viberu koristi moje osobne podatke koje sam već ranije stavio/la na raspolaganje prilikom ugovaranja usluge internetskog bankarstva Addiko EBank. Također dopuštam da Banka u istu svrhu koristi i moje identifikacijske podatke na platformi Viber (Viber ID, Viber Username) koje joj stavljam na raspolaganje u trenutku kada postajem ‘pratilac’ javnog profila Addiko banke na Viberu. Potvrđujem da sam više spomenute podatke dobrovoljno stavio/la na raspolaganje, te da dopuštam da se spomenuti podaci koriste u svrhu u koju su dani, odnosno za korištenje usluge Addiko Chat Banking na Viberu.",
        personalData1: "Banka ne prodaje, iznajmljuje niti posuđuje osobne podatke korisnika trećim stranama te moje osobne podatke štiti od neovlaštenog pristupa. Prihvaćam da Banka može proslijediti moje osobne podatke pouzdanim poslovnim partnerima za određene svrhe kao što su razne statističke analize, omogućavanje korisničke potpore, provjeru zadovoljstva korisnika uslugama Banke ili slične potrebe. U takvim slučajevima Banka će svom poslovnom partneru zabraniti korištenje mojih osobnih podataka u drugu svrhu osim ugovorene te će obvezati poslovnog partnera na čuvanje povjerljivosti osobnih podataka."
      }
    };

    $translateProvider.translations('hr', data);

  }
} ());


