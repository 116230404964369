(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name orders.factory:DepositOrdersService
   *
   * @description
   *
   */
  angular
    .module('orders')
    .factory('DepositOrdersService', DepositOrdersService);

  function DepositOrdersService($rootScope, EndpointsService, $translate, $q, $http, ConfigService, $localStorage, $window, HelperService, $log, SessionService, NotificationService) {
    var DepositOrdersServiceBase = {};

    DepositOrdersServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.depositOrders, {
            params: params
          })
          .then(function (data) {
            if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'){
              if(data.result)
                data.result = HelperService.sortAccounts(data.result);
            }
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.getManualDepositOrders = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.manualDepositOrders, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.getManualDepositOrdersById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.manualDepositOrders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    DepositOrdersServiceBase.getDepositOrderInterestRangeType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.depositOrderInterestRangeType, {
            params: params,
            headers: {
              "Accept-Language": $localStorage.appLanguage
            }
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.getAll = function (params) {
      var predefinedParams = {
        page: 0,
        pageSize: 1000
      };
      var extended = _.extend(predefinedParams, params);
      return DepositOrdersServiceBase.getPage(extended);
    };

    DepositOrdersServiceBase.getById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.depositOrders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    function insert(insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        SessionService.saveStorageObject(insertObject, 'depositOrder');
        $http.post(response.links.depositOrders, insertObject)
          .then(function (data) {
            SessionService.removeStorageObject();
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    }

    DepositOrdersServiceBase.insertDepositOrder = function (insertObject) {
      return insert(insertObject);
    };

    DepositOrdersServiceBase.insertManualDepositOrder = function (insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        SessionService.saveStorageObject(insertObject, 'depositOrder');
        $http.post(response.links.manualDepositOrders, insertObject, {headers: {"Accept-language": "sl"}})
          .then(function (data) {
            SessionService.removeStorageObject();
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.validateDepositOrder = function (insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.depositOrders_validate, insertObject)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.validateManualDepositOrder = function (insertObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.manualDepositOrders_validate, insertObject, {headers: {"Accept-language": "sl"}})
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.updateDepositOrderById = function (id, insertOsject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.depositOrders_id.replace('{id}', id), insertOsject)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.cancelDepositOrderById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.depositOrders_id.replace('{id}', id), {
            cancelDepositOrder: true
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.deleteDepositOrderById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.delete(response.links.depositOrders_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.signDepositOrderById = function (id, challengeResponse, additionalSignData) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.depositOrders_sign, {
            id: id,
            signData: challengeResponse,
            additionalSignData: additionalSignData
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    function getFileName(documentType) {
      if (documentType === 'Contract') {
        return $translate.instant('termDeposit.hrDoc6');
      } else if (documentType === 'PreContractInfo') {
        return $translate.instant('termDeposit.hrDoc7');
      } else if (documentType === 'PreContractForm') {
        return $translate.instant('termDeposit.hrDoc6b');
      } else return 'file';
    }

    DepositOrdersServiceBase.depositOrders_downloadByFilter = function (term, documentType) {
        if (!term) {
            term = {};
        }
      var deferred = $q.defer();
      var documentName = getFileName(documentType);
      EndpointsService.getLinks().then(function (response) {
        var tempId = term.id || ((term && term.depositAccount && term.depositAccount.depositIdExternal) ? term.depositAccount.depositIdExternal : '') || '';
        var tempLink = response.links['depositOrders_downloadByFilter?AccountId={AccountId}&CurrencyCode={CurrencyCode}&PayoutType={PayoutType}&Amount={Amount}&JobPurpose={JobPurpose}&TermPeriod={TermPeriod}&IsRenewal={IsRenewal}&Id={Id}&DocumentType={DocumentType}']

        tempLink = tempLink.substring(0, tempLink.indexOf('?'));
        var params = '';

        if (tempId) {
          params+= '&id=' + tempId;
        }
        if (term.accountId) {
          params+= '&accountId=' + term.accountId;
        }
        if (term.currencyCode) {
          params+= '&currencyCode=' + term.currencyCode;
        }
        if (term.payoutType) {
          params+= '&payoutType=' + term.payoutType;
        }
        if (term.amount) {
          params+= '&amount=' + term.amount;
        }
        if (term.jobPurpose) {
          params+= '&jobPurpose=' + term.jobPurpose;
        }
        if (term.termPeriod) {
          params+= '&termPeriod=' + term.termPeriod;
        }
        if (term.isRenewal) {
          params+= '&IsRenewal=' + term.isRenewal;
        }
        if (documentType) {
          params+= '&documentType=' + documentType;
        }

        if (params) {
          params = params.substring(1, params.length);
          tempLink = tempLink + '?' + params;
        }

        console.log('SamerLog Download ', tempLink);
        HelperService.manageDownload(tempLink, documentName, '');
      },
      function (error) {
        deferred.reject(error.data);
      });

      return deferred.promise;
    };

    DepositOrdersServiceBase.getContractOverviewLink = function (insertObject) {
      var nonBlockingWindow = window.open('', '_self');
      var params = {
        AccountId: insertObject.accountId,
        CurrencyCode: insertObject.currencyCode,
        PayoutType: insertObject.payoutType,
        Amount: insertObject.amount,
        JobPurpose: insertObject.jobPurpose,
        TermPeriod: insertObject.termPeriod,
        IsRenewal: insertObject.isRenewal
      };

      var url = ConfigService.url + 'depositOrders/downloadLinkDraft';

      var deferred = $q.defer();
      $http.get(url, {
          params: params
        })
        .then(function (data) {
          data = data.data;
          if(data && data.url){
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
          } else {
            nonBlockingWindow.close();
          }
          deferred.resolve();
        }).catch(function (msg, code) {
        nonBlockingWindow.close();
        deferred.reject(msg.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.downloadContract = function (contractName) {
      var nonBlockingWindow = window.open('', '_self');
      DepositOrdersServiceBase.getContractPdfLink(contractName)
        .then(function (pdfLink) {
          //pdfLink = pdfLink.data;

          if (pdfLink) {
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    // New method for statements download...
    DepositOrdersServiceBase.getContractPdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.manualDepositOrders_downloadLink_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    DepositOrdersServiceBase.depositAccountCloseOverView = function (params) {

      var url = ConfigService.url;
      url = url + "accounts/depositAccountCloseOverView";

      var deferred = $q.defer();
      // TODO: Remove endpoints service call, no need for that
      EndpointsService.getLinks().then(function (response) {
        $http.get(url, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });

      return deferred.promise;
    };

    DepositOrdersServiceBase.downloadContract = function (id) {
      var deferred = $q.defer();
      var nonBlockingWindow = window.open('', '_self');
      EndpointsService.getLinks().then(function (response) {
        var link = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') ? response.links.manualDepositOrders_downloadLink_id : response.links.depositOrders_downloadLink_id;
          $http.get(link.replace('{id}', id))
            .then(function (data) {
              data = data.data;
              if (data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              } else {
                nonBlockingWindow.close();
              }
              deferred.resolve();
            }).catch(function (msg, code) {
            nonBlockingWindow.close();
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    DepositOrdersServiceBase.downloadTermsAndConditions = function (id) {
      var deferred = $q.defer();
      var nonBlockingWindow = window.open('', '_self');
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.manualDepositOrders_downloadTermsLink_id.replace('{id}', id))
            .then(function (data) {
              data = data.data;
              if (data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              } else {
                nonBlockingWindow.close();
              }
              deferred.resolve();
            }).catch(function (msg, code) {
            nonBlockingWindow.close();
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };


    return DepositOrdersServiceBase;
  }
}());
