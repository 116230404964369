<form name="currencyResume" novalidate>
  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
          <label class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.fromAccountNumber}}</label>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.toAccount">To account</label>
          <label class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.toAccountNumber}}</label>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6">
        <div class="row">
          <div class="col-sm-6">
            <label class="domp-form-label-review" translate="currencyConversion.amount">Amount</label>
            <label class="d-block domp-form-label-resume">
              <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                {{currencyConversion.tempExchangeObj.fromAccountAmountInput | currency:""}} {{currencyConversion.tempExchangeObj.sellCurrencyInput}}
              </label>
              <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && currencyConversion.paymentResponse.amount">{{currencyConversion.paymentResponse.amount| currency:""}}</label>
              <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && (currencyConversion.paymentResponse.transactionAmount2 || currencyConversion.paymentResponse.currencyExchange.transactionAmount2)">{{currencyConversion.paymentResponse.transactionAmount2| currency:""}}{{currencyConversion.paymentResponse.currencyExchange.transactionAmount2| currency:""}}</label>
              <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && (!currencyConversion.paymentResponse.transactionAmount2 && !currencyConversion.paymentResponse.currencyExchange.transactionAmount2)">
                {{currencyConversion.paymentResponse.currencySymbol}}
              </span>




              <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && (currencyConversion.paymentResponse.transactionAmount2 || currencyConversion.paymentResponse.currencyExchange.transactionAmount2)">
                {{currencyConversion.paymentResponse.destinationCurrencySymbol}}{{currencyConversion.paymentResponse.currencyExchange.destinationCurrencySymbol}}
              </span>
            </label>
          </div>
          <div class="col-sm-6" ng-if="currencyConversion.countryCode !== 'bh'">
            <label class="domp-form-label-review" translate="currencyConversion.bankCharges">Bank charges</label>
            <!--<label class="d-block exchange-resume-val" translate="domesticPayment.checkBankCharges">no info</label>-->
            <a ng-if="!currencyConversion.paymentResponse.hasOwnProperty('paymentChargesAmount')" ng-href="{{currencyConversion.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" class="d-block domp-form-label-resume">Check the bank charges</a>
            <label ng-if="currencyConversion.paymentResponse.hasOwnProperty('paymentChargesAmount')" class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.paymentChargesAmount | currency:""}} {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}</label>
            <a ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="d-block domp-form-label-resume" href="https://icalc.addiko.si/" target="_blank" translate="currencyCalculator.exchangeRates"></a>
          </div>
        </div>
        <!--<div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">-->
          <!--<div class="col-sm-12">-->
            <!--<label class="domp-form-label-review" translate="currencyConversion.exchangeRate">Exchange rate</label>-->
            <!--<label class="d-block domp-form-label-resume">-->
            <!--<span-->
                  <!--ng-bind="'1 '+ (currencyConversion.localCurrency) +-->
                          <!--' = ' + (currencyConversion.fromAccountCalculationRate | number:4) + ' ' + (currencyConversion.tempExchangeObj.sellCurrencyInput)">-->
            <!--</span>-->
            <!--</label>-->
          <!--</div>-->
        <!--</div>-->
        <div class="row">
          <div class="col-sm-12">
            <label class="domp-form-label-review" translate="currencyConversion.when">Date</label>
            <label class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="domp-to-section m-10">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'" class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.toAccount">To account</label>
          <label class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.toAccountNumber}}</label>
        </div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.provision">Provision</label>
          <label class="d-block domp-form-label-review" translate="currencyConversion.exchangeRate">Exchange rate</label>
        </div>
      </div>
      <div class="col-md-3 col-xs-12" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <label class="domp-form-label-review" translate="currencyConversion.amount">Amount to</label>
        <label class="d-block domp-form-label-resume">
          <label>{{currencyConversion.tempExchangeObj.toAccountAmountInput | currency:""}} {{currencyConversion.tempExchangeObj.buyCurrencyInput.symbol}}</label>
        </label>
      </div>
      <div class="col-sm-3 col-xs-12" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <div class="form-group">
        
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'" class="domp-form-label-review"
            translate="currencyConversion.exchangeRate">Exchange rate</label>
        
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
            class="domp-form-label-review">{{(0.00) | number:2}} EUR
        
            <span uib-tooltip-html="currencyConversion.htmlTooltip">
              <svg style="height: 20px; width: 20px;" aria-hidden="true" focusable="false" data-prefix="far"
                data-icon="info-circle" role="img" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16 fa-lg">
                <path fill="currentColor"
                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                  class=""></path>
              </svg>
            </span>
        
          </label>
        
          <label class="d-block domp-form-label-resume">
            <span ng-if="currencyConversion.countryCode == 'sr'"
              ng-bind="'1 '+ (currencyConversion.foreignCurrency) + ' = ' + (currencyConversion.fixedAmount | number:4) + ' ' + (currencyConversion.localCurrency)"
              class="ng-binding">
            </span>
            <span ng-if="currencyConversion.countryCode == 'bh'"
              ng-bind="'1 '+ (currencyConversion.foreignCurrency) + ' = ' + (currencyConversion.fixedAmount | number:6) + ' ' + (currencyConversion.localCurrency)"
              class="ng-binding">
            </span>
            <span ng-if="currencyConversion.countryCode == 'hr' || currencyConversion.countryCode == 'mne'"
              ng-bind="'1 '+ (currencyConversion.foreignCurrency) + ' = ' + (currencyConversion.fixedAmount | number:2) + ' ' + (currencyConversion.localCurrency)"
              class="ng-binding">
            </span>
          </label>
        </div>
        <!--<div class="form-group" ng-if="currencyConversion.toAccountCalculationRate && (currencyConversion.countryCode == 'hr')">-->
            <!--<div class="col-md-offset-6 col-md-3 col-xs-12">-->
              <!--<div class="form-group">-->
                <!--<label class="domp-form-label-review" translate="currencyConversion.exchangeRate">Exchange rate</label>-->
                <!--<label class="d-block domp-form-label-resume">-->
                  <!--<span ng-bind="'1 '+ (currencyConversion.localCurrency) +-->
                              <!--' = ' + (currencyConversion.toAccountCalculationRate | number:4) + ' ' + (currencyConversion.tempExchangeObj.buyCurrencyInput.symbol)">-->
                 <!--</span>-->
                <!--</label>-->
              <!--</div>-->
            <!--</div>-->
        <!--</div>-->
      </div>
        <div ng-if="currencyConversion.countryCode == 'sr'" class="col-sm-3 col-xs-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="currencyConversion.calculatedAmount">Calculated amount</label>
          <label class="d-block domp-form-label-resume">{{currencyConversion.paymentResponse.calculatedAmount | number:2}}
              <span ng-if="currencyConversion.paymentResponse.transactionAmount2">
                {{currencyConversion.paymentResponse.currencySymbol}}
              </span>
              <span ng-if="!currencyConversion.paymentResponse.transactionAmount2">
                {{currencyConversion.paymentResponse.destinationCurrencySymbol}}
              </span>

          </label>

        </div>
      </div>
    </div>
    <div class="row" ng-if="currencyConversion.toAccountCalculationRate && (currencyConversion.countryCode == 'mne')">
      <div class="col-md-offset-6 col-md-3 col-xs-12">
          <div class="form-group">
            <label class="domp-form-label-review" translate="currencyConversion.exchangeRate">Exchange rate</label>
            <label class="d-block domp-form-label-resume">
              <span ng-bind="'1 '+ (currencyConversion.localCurrency) +
                          ' = ' + (currencyConversion.toAccountCalculationRate | number:4) + ' ' + (currencyConversion.tempExchangeObj.buyCurrencyInput.symbol)">
             </span>
            </label>
          </div>
      </div>
    </div>

  </div>


  <div class="m-10">
    <div class="row">
      <div class="col-sm-9 col-xs-9">
        <button ng-if="currencyConversion.countryCode != 'sl'" class="btn btn-primary" translate="currencyConversion.back"
                data-ng-click="currencyConversion.backToCurrencyConversion();"></button>
        <button ng-if="currencyConversion.countryCode != 'sl'" class="btn btn-primary" translate="payments.edit"
                data-ng-click="currencyConversion.editPayment();"></button>
        <button ng-if="currencyConversion.countryCode == 'sl'" class="btn btn-primary" translate="domesticPayment.back"
                data-ng-click="currencyConversion.editPayment();"></button>
        <button ng-if="currencyConversion.countryCode != 'sl'" class="btn btn-primary-warning " translate="payments.delete"
                data-ng-click="currencyConversion.deletePaymentAction(undefined, 'step2');"></button>
      </div>

      <div class="col-sm-3 col-xs-3">
        <div class="pull-right">
<!--          <button class="btn btn-default confirm-btn"
                  translate="currencyConversion.sign"
                  data-ui-sref="payments.sign({paymentId: currencyConversion.paymentResponse.id, type: 'exchange'})">

          </button>-->
          <button ng-if="currencyConversion.countryCode != 'sl'" class="btn btn-default"
                  translate="currencyConversion.sign"
                  data-ng-click="currencyConversion.signPaymentAction(currencyConversion.paymentResponse);">
          </button>
          <button ng-if="currencyConversion.countryCode == 'sl'" class="btn btn-default"
                  translate="domesticPayment.confirmBtn"
                  ng-disabled="currencyConversion.confirmButtonPressed"
                  data-ng-click="currencyConversion.signPaymentAction(currencyConversion.paymentResponse);">
          </button>
        </div>

      </div>
    </div>
  </div>
</form>

