<header id="header" data-current-skin={{mactrl.currentSkin}} data-ng-include="'template/header.html'" data-ng-controller="headerCtrl as hctrl"></header>

<section id="main">
  <div class="container" data-ng-controller="headerCtrl as hctrl">
    <div class="row">
      <div class="col-md-3">
        <div id="changeDashboardDisplay" class="app-white-card">
          <a href class="col-md-6 cdd-menu cdd-menu-left" data-ui-sref="dashboard">Accounts</a>
          <a href class="col-md-6 cdd-menu cdd-menu-right" data-ui-sref="dashboard.timeline" data-ng-class="{ 'selected': hctrl.isModuleActive('dashboard.timeline') }">Timeline</a>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-3"></div>
    </div>

    <br/>

  </div>
</section>

<footer id="footer" data-ng-include="'template/footer.html'"></footer>
<!--<footer id="footer-hypo" data-ng-include="'template/footer-hypo.html'"></footer>-->
