<div class="login">
  <div class="container-fluid session-container">
    <div class="row flex-container">
      <div class="col-sm-7 left">
        <div class="row">
          <div class="col-sm-12 header header-title">
            <div class="content-wrapper">
              <h5 ng-show="!sessionExpiredCtrl.SLOVENIA" class="section-title">{{'session.expired.title' | translate}}
              </h5>
              <h5 ng-show="!sessionExpiredCtrl.SLOVENIA" class="section-title">
                {{'session.expired.subtitle' | translate}}
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 header header-title">
            <div class="content-wrapper">
              <p ng-if="!sessionExpiredCtrl.isIE" class="alert-text f-20">{{'session.expired.message' | translate}}</p>
              <p ng-if="sessionExpiredCtrl.isIE" class="alert-text f-20">{{'session.expired.wrongBrowser' | translate}}
                <a class="text-underline"
                  href="https://www.addiko.si/obcani/e-bancnistvo/addiko-ebank/tehnicne-zahteve/"
                  target="_blank">https://www.addiko.si/obcani/e-bancnistvo/addiko-ebank/tehnicne-zahteve/</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5 right">
        <div class="row">
          <div class="col-sm-12 header">
            <a class="cursor-pointer language-link text-decoration-underline uppercase"
              ng-click="sessionExpiredCtrl.toggleLanguage()">{{sessionExpiredCtrl.selectedLanguage.code |
              translate}}</a>
            <div id="logoContainer" class="logo-container">
              <!--   <a href="index.html"> -->
              <svg class="logo-image" height="auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="182.037px" viewBox="0 0 182.037 23.18"
                enable-background="new 0 0 182.037 23.18" xml:space="preserve">
                <g>
                  <defs>
                    <rect id="SVGID_1_" width="182.036" height="23.18" />

                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />



                  </clipPath>
                  <path clip-path="url(#SVGID_2_)" fill="#FFFFFF" d="M14.298,18.352H6.344l-1.547,4.457H0L8.511,1.547h3.745l8.511,21.261h-4.92
                                            L14.298,18.352z M7.582,14.792h5.478L10.306,6.9L7.582,14.792z M29.71,6.467c0.886,0,1.671,0.119,2.352,0.356
                                            c0.681,0.237,1.258,0.542,1.733,0.913V0h4.364v14.39c0,1.322-0.197,2.518-0.587,3.591c-0.393,1.073-0.955,1.996-1.687,2.77
                                            c-0.733,0.774-1.625,1.372-2.677,1.795c-1.053,0.424-2.229,0.635-3.528,0.635c-1.218,0-2.336-0.217-3.358-0.65
                                            c-1.021-0.433-1.904-1.03-2.646-1.794c-0.743-0.764-1.321-1.657-1.733-2.678c-0.413-1.021-0.619-2.108-0.619-3.266
                                            c0-1.175,0.217-2.269,0.65-3.28c0.434-1.01,1.026-1.893,1.779-2.646c0.752-0.752,1.64-1.341,2.662-1.764
                                            C27.436,6.68,28.534,6.467,29.71,6.467 M29.771,19.404c0.64,0,1.223-0.113,1.75-0.341c0.525-0.227,0.979-0.541,1.362-0.944
                                            c0.38-0.402,0.675-0.887,0.881-1.454c0.206-0.566,0.309-1.19,0.309-1.873c0-1.361-0.392-2.46-1.176-3.295
                                            c-0.784-0.836-1.826-1.253-3.126-1.253c-1.299,0-2.341,0.417-3.125,1.253c-0.784,0.835-1.176,1.934-1.176,3.295
                                            c0,0.683,0.103,1.307,0.309,1.873c0.206,0.567,0.501,1.052,0.882,1.454c0.381,0.403,0.835,0.718,1.362,0.944
                                            C28.549,19.291,29.132,19.404,29.771,19.404 M49.021,6.467c0.887,0,1.671,0.114,2.353,0.341c0.681,0.227,1.258,0.525,1.733,0.897V0
                                            h4.363v14.39c0,1.322-0.196,2.518-0.587,3.591c-0.392,1.073-0.955,1.996-1.687,2.77c-0.732,0.774-1.625,1.372-2.677,1.795
                                            c-1.052,0.424-2.228,0.635-3.528,0.635c-1.218,0-2.337-0.217-3.358-0.65c-1.021-0.433-1.903-1.03-2.646-1.794
                                            c-0.742-0.764-1.32-1.657-1.733-2.678c-0.413-1.021-0.618-2.108-0.618-3.266c0-1.175,0.216-2.269,0.649-3.28
                                            c0.433-1.01,1.026-1.893,1.779-2.646c0.753-0.752,1.64-1.341,2.662-1.764C46.747,6.68,47.845,6.467,49.021,6.467 M49.083,19.404
                                            c0.64,0,1.222-0.113,1.749-0.341c0.526-0.227,0.979-0.541,1.362-0.944c0.381-0.402,0.676-0.887,0.882-1.454
                                            c0.206-0.566,0.31-1.19,0.31-1.873c0-1.361-0.393-2.46-1.177-3.295c-0.783-0.836-1.825-1.253-3.125-1.253s-2.342,0.417-3.126,1.253
                                            c-0.784,0.835-1.176,1.934-1.176,3.295c0,0.683,0.103,1.307,0.309,1.873c0.206,0.567,0.5,1.052,0.882,1.454
                                            c0.381,0.403,0.835,0.718,1.361,0.944C47.861,19.291,48.444,19.404,49.083,19.404 M60.72,0h4.363v4.363H60.72V0z M60.72,6.838
                                            h4.363v15.97H60.72V6.838z M76.008,15.97c-0.228,0.041-0.491,0.066-0.789,0.077c-0.299,0.01-0.532,0.015-0.696,0.015h-1.548v6.747
                                            h-4.363V0h4.363v12.348h1.145c1.382,0,2.501-0.48,3.358-1.44c0.856-0.958,1.346-2.315,1.47-4.07h4.178
                                            c0,1.548-0.309,2.972-0.928,4.271c-0.62,1.301-1.456,2.362-2.508,3.187l4.92,8.512h-4.765L76.008,15.97z M92.999,23.18
                                            c-1.176,0-2.279-0.217-3.311-0.65c-1.033-0.433-1.931-1.03-2.693-1.794c-0.763-0.764-1.362-1.65-1.795-2.663
                                            c-0.434-1.011-0.649-2.103-0.649-3.28c0-1.175,0.216-2.269,0.649-3.28c0.433-1.01,1.032-1.888,1.795-2.63
                                            c0.762-0.743,1.66-1.331,2.693-1.764c1.032-0.433,2.135-0.65,3.311-0.65s2.28,0.217,3.312,0.65s1.929,1.021,2.693,1.764
                                            c0.764,0.743,1.36,1.62,1.794,2.63c0.435,1.011,0.65,2.105,0.65,3.28c0,1.178-0.216,2.27-0.65,3.28
                                            c-0.434,1.013-1.03,1.899-1.794,2.663c-0.765,0.764-1.662,1.361-2.693,1.794C95.279,22.963,94.174,23.18,92.999,23.18
                                                M92.999,19.404c0.639,0,1.223-0.113,1.748-0.341c0.527-0.227,0.98-0.541,1.363-0.944c0.38-0.402,0.675-0.887,0.882-1.454
                                            c0.206-0.566,0.309-1.19,0.309-1.873c0-1.361-0.392-2.46-1.175-3.295c-0.785-0.836-1.827-1.253-3.127-1.253
                                            c-1.299,0-2.342,0.417-3.126,1.253c-0.783,0.835-1.176,1.934-1.176,3.295c0,0.683,0.104,1.307,0.31,1.873
                                            c0.207,0.567,0.5,1.052,0.881,1.454c0.382,0.403,0.837,0.718,1.362,0.944C91.777,19.291,92.359,19.404,92.999,19.404
                                                M124.318,7.644c0,0.928-0.206,1.759-0.618,2.491c-0.414,0.732-1.011,1.284-1.796,1.656c0.948,0.31,1.718,0.871,2.306,1.687
                                            c0.588,0.814,0.883,1.811,0.883,2.987c0,0.928-0.176,1.779-0.525,2.552c-0.352,0.774-0.838,1.444-1.457,2.013
                                            c-0.618,0.566-1.356,1.005-2.211,1.314c-0.856,0.31-1.79,0.465-2.802,0.465h-9.13V1.547h8.604c1.031,0,1.96,0.144,2.786,0.433
                                            c0.823,0.289,1.53,0.701,2.118,1.238c0.589,0.536,1.043,1.176,1.362,1.919C124.159,5.879,124.318,6.715,124.318,7.644
                                                M113.239,10.275h3.961c0.929,0,1.63-0.228,2.104-0.681c0.475-0.454,0.712-1.084,0.712-1.888c0-0.744-0.248-1.336-0.742-1.78
                                            c-0.495-0.443-1.187-0.666-2.074-0.666h-3.961V10.275z M113.239,13.864v5.199h4.487c0.929,0,1.665-0.232,2.213-0.696
                                            c0.547-0.464,0.819-1.099,0.819-1.902c0-0.868-0.263-1.518-0.789-1.951c-0.524-0.433-1.273-0.649-2.243-0.649H113.239z
                                                M139.884,22.809v-1.641c-0.475,0.598-1.113,1.078-1.919,1.439c-0.804,0.361-1.784,0.541-2.938,0.541
                                            c-1.117,0-2.147-0.2-3.097-0.604s-1.769-0.97-2.459-1.702c-0.692-0.732-1.234-1.613-1.625-2.646
                                            c-0.394-1.032-0.59-2.167-0.59-3.405c0-1.175,0.219-2.269,0.65-3.28c0.435-1.01,1.026-1.888,1.78-2.63s1.646-1.331,2.677-1.764
                                            s2.145-0.65,3.342-0.65c1.218,0,2.343,0.207,3.374,0.619C140.112,7.5,141,8.077,141.742,8.82c0.743,0.743,1.32,1.63,1.733,2.661
                                            c0.411,1.033,0.618,2.157,0.618,3.374v7.954H139.884z M135.707,10.243c-1.3,0-2.342,0.417-3.125,1.253
                                            c-0.784,0.835-1.177,1.934-1.177,3.295c0,0.683,0.104,1.307,0.31,1.873c0.205,0.567,0.501,1.052,0.883,1.454
                                            c0.38,0.403,0.834,0.718,1.361,0.944c0.525,0.228,1.109,0.341,1.748,0.341c0.64,0,1.223-0.113,1.749-0.341
                                            c0.526-0.227,0.979-0.541,1.361-0.944c0.382-0.402,0.676-0.887,0.883-1.454c0.206-0.566,0.31-1.19,0.31-1.873
                                            c0-1.361-0.394-2.46-1.178-3.295C138.049,10.661,137.007,10.243,135.707,10.243 M155.049,10.367c-1.053,0-1.877,0.325-2.476,0.975
                                            s-0.897,1.532-0.897,2.646v8.82h-4.363v-8.852c0-1.155,0.18-2.192,0.541-3.111c0.36-0.917,0.876-1.702,1.548-2.351
                                            c0.669-0.65,1.484-1.15,2.444-1.501c0.96-0.352,2.026-0.527,3.203-0.527c1.176,0,2.244,0.175,3.203,0.527
                                            c0.96,0.351,1.774,0.851,2.445,1.501c0.671,0.649,1.186,1.434,1.549,2.351c0.359,0.919,0.54,1.956,0.54,3.111v8.852h-4.363v-8.82
                                            c0-1.114-0.3-1.996-0.898-2.646C156.927,10.692,156.101,10.367,155.049,10.367 M173.402,15.97
                                            c-0.228,0.041-0.485,0.066-0.773,0.077c-0.291,0.01-0.516,0.015-0.682,0.015h-1.549v6.747h-4.361V0h4.361v12.348h1.146
                                            c1.384,0,2.503-0.48,3.36-1.44c0.854-0.958,1.346-2.315,1.469-4.07h4.178c0,1.548-0.3,2.967-0.896,4.255
                                            c-0.6,1.291-1.425,2.348-2.476,3.174l4.857,8.541h-4.827L173.402,15.97z" />

                </g>
              </svg>
              <!--   </a> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 right-side-text">
            <p class="m-b-0" ng-if="sessionExpiredCtrl.SLOVENIA && !sessionExpiredCtrl.isIE" translate>
              session.expired.contact1SLO</p>
            <p class="m-b-0" ng-if="sessionExpiredCtrl.SLOVENIA && !sessionExpiredCtrl.isIE" translate>
              session.expired.contact2SLO</p>
            <p class="m-b-0" ng-if="sessionExpiredCtrl.SLOVENIA && sessionExpiredCtrl.isIE" translate></p>
            <p class="m-b-0" ng-if="!sessionExpiredCtrl.SLOVENIA" translate>session.expired.contact1</p>
            <p class="m-b-0" ng-if="!sessionExpiredCtrl.SLOVENIA" translate>session.expired.contact2</p>
            <p class="m-b-0" ng-if="!sessionExpiredCtrl.SLOVENIA" translate>session.expired.contact3</p>
            <hr />
          </div>
        </div>
        <div class="row bottom-right-aligned">
          <div class="col-sm-12 ">
            <p ng-show="sessionExpiredCtrl.SLOVENIA" class="help-txt">
              <span class="f-20">{{'retail.title' | translate}}</span>
              <br />
              + 386 (0)1 580 43 00 <span>{{'common.callCenter' | translate}}</span>
              <br />
              ebank.si@addiko.com
              <br />
              <br />
              <span class="f-20">{{'corporate.title' | translate}}</span>
              <br />
              + 386 (0)1 580 43 00 <span>{{'common.callCenterWorkingHours1' | translate}}</span><br>
              <span>{{'common.callCenterWorkingHours2' | translate}}</span>
              ebank.si@addiko.com
              <br />
              <br />
              Addiko Bank d.d.
              <br />
              Dunajska cesta 117, 1000 Ljubljana
              <br />
              <br />
              <a href="https://www.addiko.si/varstvo-podatkov/informacije-o-obdelavi-podatkov-za-addiko-ebank/"
                target="_blank">
                Informacije o obdelavi osebnih podatkov</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>