<table class="table table-transparent table-responsive">
  <tbody ng-include="'accounts/merchant-info-details/merchant-info-details.tpl.html'"></tbody>
  <tbody>
    <tr ng-if="transaction.description">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.description"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.description}}</span></td>
    </tr>
    <tr>
      <td class="left-col"><span class="tridm-key-label" translate="transactions.amount"></span></td>
      <td class="right-col">
        <span class="tridm-value-label outcome-tr" ng-if="transaction.transactionType === 'D'">{{transaction.amount }} {{transaction.currency}}</span>
        <span class="tridm-value-label income-tr" ng-if="transaction.transactionType === 'C'">{{transaction.amount }} {{transaction.currency}}</span>
      </td>
    </tr>
    <tr ng-if="transaction.account.accountNumber">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.accountNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountNumber}}</span></td>
    </tr>
    <tr ng-if="transaction.account.accountType.description">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.card"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.account.accountType.description}}</span></td>
    </tr>
    <tr ng-if="transaction.reservationDate">
      <td class="left-col"><span class="tridm-key-label" translate="reservations.reservationDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.reservationDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.valueDate">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.valueDate"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.valueDate | date:'shortDate' : '+0200'}}</span></td>
    </tr>
    <tr ng-if="transaction.referenceNumber">
      <td class="left-col"><span class="tridm-key-label" translate="transactions.referenceNumber"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.referenceNumber }}</span></td>
    </tr>
    <tr ng-if="transaction.transactionType">
      <td class="left-col"><span class="tridm-key-label" translate="reservations.transactionType"></span></td>
      <td class="right-col"><span class="tridm-value-label">{{transaction.transactionType}}</span></td>
    </tr>
    <tr ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh')
               && !transaction.transactionDivision && accounts.additionalCheck(transaction, accounts.selectedAccount.accountType.id)">
      <td class="left-col"></td>
      <td class="right-col">
        <button type="button" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                data-ui-sref="transactionDivison.create({reservationId: transaction.reservationID,
                  accountId:accounts.selectedAccount.accountId, 
                  card: card.cardNumber.substr(card.cardNumber.length - 4, 4),
                   currencySymbol: vm.transactionCardsFilterOptions.currencySymbol, 
                   accountTypeId: accounts.selectedAccount.accountType.id})">
                   {{'accounts.transDivisionBtn1' | translate}} {{'accounts.transDivisionBtn2' | translate}}
        </button>
      </td>
    </tr>

    <tr ng-if="(APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr') && transaction.isTransactionDivisionAllowed">
      <td class="left-col"></td>
      <td class="right-col">
      <button type="button" class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
      ng-click="accounts.checkIfAllowed(true, transaction.reservationID, 
                              card.cardNumber.substr(card.cardNumber.length - 4, 4), 
                              accounts.transactionCardsFilterOptions.currencySymbol)"
          >
            {{'accounts.transDivisionBtn1' | translate}} {{'accounts.transDivisionBtn2' | translate}}
      </button>
      </td>
    </tr>

  </tbody>
</table>
