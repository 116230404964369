(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:InstantPaymentCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('InstantPaymentCtrl', InstantPaymentCtrl);

  function InstantPaymentCtrl($rootScope) {
    var vm = this;
    vm.ctrlName = 'InstantPaymentCtrl';
    vm.location =  $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
  }
}());
