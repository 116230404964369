(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EinvoicesOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EinvoicesOverviewCtrl', EinvoicesOverviewCtrl);

  function EinvoicesOverviewCtrl($scope, $state, $rootScope, EInvoiceDocumentsService, EinvoiceSubscriptionsService, $log, $translate,
                                 HelperService, $timeout, EinvoicesIssuersOverviewService,
                                 CTLocalizationService, AlertService, GoogleTagManagerHelperService, EGeneralDocumentsService, NotificationService) {
    var vm = this;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.loadMoreEinvoicesFirstTab = loadMoreEinvoicesFirstTab;
    vm.loadMoreEinvoicesSecondTab = loadMoreEinvoicesSecondTab;
    vm.pay = pay;
    vm.registerIssuer = registerIssuer;

    vm.ctrlName = 'EinvoicesOverviewCtrl';
    vm.einvoicesListFirstTab = {};
    vm.einvoicesListSecondTab = {};
    vm.registrationsListActive = {};
    vm.registrationsListCancelled = {};
    vm.documentsListTab = {};

    vm.showSubmenuItemType = 'unpaidTab';

    vm.einvoicesFilterToPay = {
      page: 0
    };
    vm.processedRegsFilterList = [];
    vm.einvoicesFilterArchived = {
      page: 0,
      statusList: ['Archived', 'Processed', 'Rejected', 'PaidByOtherMeans', 'PaymentWithDirectDebit',
        'PaymentNotPossible', 'Withdrawn', 'InProgressByOther', 'Cancelled'],
      orderBy: 'valueDate DESC',
      pageSize: 10
    };

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') {
      vm.einvoicesFilterArchived.statusList = ['Processed'];
    }


    vm.einvoicesPeriod = {};
    vm.registrationsFilterCancelled = {};

    vm.archiveObj = {
      archiveCtnOpened: false,
      userComment: ''
    };
    vm.issuers = [];
    vm.registrationDetails = [];

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') {
      vm.processedFilterList = [
        {
          item: {
            key: 'processed',
            description: 'eInvoicesModal.processed'
          }
        },
        {
          item: {
            key: 'archived',
            description: 'eInvoicesModal.archived'
          }
        },
        {
          item: {
            key: 'rejected',
            description: 'eInvoicesModal.rejected'
          }
        }
      ];
    } else {
      vm.processedFilterList = [
        {
          item: {
            key: 'all',
            description: 'core.period.all'
          }
        },
        {
          item: {
            key: 'processed',
            description: 'eInvoicesModal.processed'
          }
        },
        {
          item: {
            key: 'cancelled',
            description: 'eInvoiceStatus.Cancelled'
          }
        },
        // {
        //   item: {
        //     key: 'archived',
        //     description: 'eInvoicesModal.archived'
        //   }
        // },
        {
          item: {
            key: 'rejected',
            description: 'eInvoicesModal.rejected'
          }
        },
        {
          item: {
            key: 'PaymentNotPossible',
            description: 'eInvoicesModal.paymentNotPossible'
          }
        },
        {
          item: {
            key: 'PaidByOtherMeans',
            description: 'eInvoicesModal.paidByOtherMeans'
          }
        },
        {
          item: {
            key: 'PaymentWithDirectDebit',
            description: 'eInvoicesModal.paymentWithDirectDebit'
          }
        },
        {
          item: {
            key: 'Withdrawn',
            description: 'eInvoicesModal.withdrawn'
          }
        },
        // {
        //   item: {
        //     key: 'Reviewed',
        //     description: 'eInvoicesModal.reviewed'
        //   }
        // },
        {
          item: {
            key: 'InProgressByOther',
            description: 'eInvoicesModal.inProgressByOther'
          }
        }
      ];
    }

    vm.processedFilter = vm.processedFilterList[0];

    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: 'all',
          description: 'core.period.all'
        }
      }
    ];
    vm.einvoicesPeriod = vm.periodList[2];

    ///registrations
    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') {
      vm.processedRegsFilterList.push(
        {
          item: {
            key: 'registered',
            description: 'eInvoiceRegistration.registered'
          }
        },
        {
          item: {
            key: 'inProcess',
            description: 'eInvoiceRegistration.inProcess'
          }
        },
        {
          item: {
            key: 'rejected',
            description: 'eInvoiceRegistration.rejected'
          }
        },
        {
          item: {
            key: 'unregistered',
            description: 'eInvoiceRegistration.unregistered'
          }
        }
      );

    }

    vm.registrationsFilterActive = {
      page: 0,
      serviceState: 'Registered',
      pageSize: 10,
      orderBy: 'issuerServiceName asc'
    };

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
      vm.registrationsFilterActive.includeList = ["EInvoiceIssuer"];
    }

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
      vm.processedRegsFilterList.push(
        {
          item: {
            key: '',
            description: 'eInvoiceRegistration.all'
          }
        },
        {
          item: {
            key: 'registered',
            description: 'eInvoiceRegistration.registered'
          }
        },
        {
          item: {
            key: 'inProcess',
            description: 'eInvoiceRegistration.inProcessSlo'
          }
        },
        {
          item: {
            key: 'delivered',
            description: 'eInvoiceRegistration.delivered'
          }
        },
        {
          item: {
            key: 'undelivered',
            description: 'eInvoiceRegistration.undelivered'
          }
        },
        {
          item: {
            key: 'rejected',
            description: 'eInvoiceRegistration.rejectedSlo'
          }
        }
      );

      vm.registrationsFilterActive = {
        page: 0,
        pageSize: 10,
        orderBy: 'acceptedDate desc'
      };
    }
    vm.processedRegsFilter = vm.processedRegsFilterList[0];

    function loadMoreEinvoicesFirstTab(reset) {
      angular.extend(vm.einvoicesFilterToPay,
        {
          statusList: ['Inserted', 'InProgress'],
          orderBy: 'valueDate DESC',
          pageSize: 10
        });

      // vm.einvoicesFilterToPay = {
      //   // page: 0,
      //   statusList: ['Inserted', 'InProgress'],
      //   orderBy: 'valueDate DESC',
      //   pageSize: 2 //10
      // };
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        vm.einvoicesFilterToPay.statusList.push("Reviewed");
      }
      getEinvoices(vm.einvoicesFilterToPay, reset, 'einvoicesListFirstTab');
    }

    function loadMoreEinvoicesSecondTab(reset) {
      getEinvoices(vm.einvoicesFilterArchived, reset, 'einvoicesListSecondTab');
    }

    vm.loadMoreEinvoicesThirdTab = function (reset) {
      var filter = vm.registrationsFilterActive;
      if (reset) {
        filter.page = 0;
      } else {
        filter.page += 1;
      }

      getFilteredSubscriptions(filter, 'registrationsListActive', reset);
    };


    function loadEinvoicesOnTabChange() {
      // Invoices with status: Inserted, InProgress
      angular.extend(vm.einvoicesFilterToPay,
        {
          page: 0,
          statusList: ['Inserted', 'InProgress'],
          orderBy: 'valueDate DESC',
          pageSize: 10
        });

      // vm.einvoicesFilterToPay = {
      //   page: 0,
      //   statusList: ['Inserted', 'InProgress'],
      //   orderBy: 'valueDate DESC',
      //   pageSize: 2 //10
      // };
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        vm.einvoicesFilterToPay.statusList.push("Reviewed");
      }

      getFilteredEInvoices(vm.einvoicesFilterToPay, vm.einvoicesPeriod, 'einvoicesListFirstTab');
      // getFilteredEInvoices(vm.einvoicesFilterArchived, vm.einvoicesPeriod, 'einvoicesListSecondTab');
    }

    function getEinvoices(filter, reset, tab) {
      if (reset) {
        filter.page = 0;
      } else {
        filter.page++;
      }
      if (vm[tab].hasMore || filter.page === 0) {
        EInvoiceDocumentsService.getEinvoicesPageByFilter(filter)
          .then(function (response) {
            if (reset) {
              vm[tab] = response;
            } else {
              vm[tab].hasMore = response.hasMore;
              vm[tab].result = vm[tab].result.concat(response.result);
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    }

    function getFilteredEInvoices(filter, item, tab) {
      vm.einvoicesPeriod = item;
      getEinvoices(filter, true, tab);
    }

    //get issuers for registration details
    function getIssuers() {
      var filter = {
        page: 0,
        pageSize: 10,
        orderBy: 'name asc'
      };

      return EinvoicesIssuersOverviewService.getPage(filter)
        .then(function (response) {
          vm.issuers = response.result;
          $log.debug("list", vm.einvoicesIssuersList);
        });
    }

    function getFilteredSubscriptions(filter, tab, reset) {
      if (reset) {
        filter.page = 0;
      }
      EinvoiceSubscriptionsService.getPage(filter)
        .then(function (response) {
          ///obtain IBAN for GUI
          if (vm.issuers.length > 0 && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
            _.forEach(response.result, function (item) {
              for (var i = 0; i < vm.issuers.length; i++) {
                if (item.issuerId === vm.issuers[i].id)
                  item.iban = vm.issuers[i].iban;
              }
            });
          }

          if (reset) {
            vm[tab] = response;
          } else {
            vm[tab].hasMore = response.hasMore;
            vm[tab].result = vm[tab].result.concat(response.result);
          }

          //vm[tab] = response;
          //  filterRegistrationsByPeriod(response);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    var filterRegistrationsByPeriod = function (filter, item, tab) {
      vm.processedRegsFilter = item;
      getFilteredSubscriptions(filter, tab, true);
    };

    function loadRegsitrations(filter) {
      if (vm.countryCode != 'sl')
        getIssuers();
      // Active registrations
      vm.registrationsFilterActive = filter;

      getFilteredSubscriptions(vm.registrationsFilterActive, 'registrationsListActive', true);

    }

    function pay(eInvoice, $event) {
      $event.stopPropagation();
      $state.go('eInvoices.pay', {eInvoiceId: eInvoice.id});
    }

    function registerIssuer() {
      GoogleTagManagerHelperService.pushEvent('EInvoices Overview', 'Register/Deregister issuer', 'Clicked');
      $state.go('eInvoices.einvoicesRegisterNew');
    }


    vm.archiveEInvoice = function (eInvoice) {
      var id = eInvoice.id;
      if (eInvoice.archiveEInvoiceInProgress) return;
      eInvoice.archiveEInvoiceInProgress = true;
      if (vm.countryCode === 'sl') {
        AlertService.eInvoiceArchiveAlert({
          text: "payments.paymentDueDateChangedMsg"
        })
          .then(function (accept) {
            if (!accept) {
              eInvoice.archiveEInvoiceInProgress = false;
              return;
            }
            EInvoiceDocumentsService.archiveEInvoice(id, vm.archiveObj.userComment)
              .then(function (response) {
                vm.archiveObj.archiveCtnOpened = false;
                //$translate('eInvoiceOverview.successfulyArchived').then(function (translation) {
                //  swal(translation);
                //});
                AlertService.infoAlert({
                  text: 'alert.archiveEInvoice'
                })
                  .then(function () {
                    loadMoreEinvoicesFirstTab(true);
                    loadMoreEinvoicesSecondTab(true)
                  })
              })
              .catch(function (error) {
                eInvoice.archiveEInvoiceInProgress = false;
                NotificationService.showMessage(error, 'error');
              });
          })
      } else {
        EInvoiceDocumentsService.archiveEInvoice(id, vm.archiveObj.userComment)
          .then(function (response) {
            vm.archiveObj.archiveCtnOpened = false;
            //$translate('eInvoiceOverview.successfulyArchived').then(function (translation) {
            //  swal(translation);
            //});
            AlertService.infoAlert({
              text: 'alert.archiveEInvoice'
            })
              .then(function () {
                loadMoreEinvoicesFirstTab(true);
                loadMoreEinvoicesSecondTab(true)
              })
          })
          .catch(function (error) {
            eInvoice.archiveEInvoiceInProgress = false;
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.cancelRegistration = function (registration) {

      AlertService.confirmationAlert()
        .then(function (result) {
          registration.cancelInProgress = true;
          if (result) {
            EinvoiceSubscriptionsService.updateInvoiceSubscription(registration.id, {"unRegisterIssuer": true})
              .then(function (response) {
                AlertService.infoAlert({
                  text: 'alert.cancelRegistration'
                })
                  .then(function () {
                    registration.cancelInProgress = false;
                    getFilteredSubscriptions(vm.registrationsFilterActive, 'registrationsListActive', true);
                    getFilteredSubscriptions(vm.registrationsFilterCancelled, 'registrationsListCancelled', true);
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });

    };

    vm.processedFilterChange = function (filter) {
      vm.processedFilter = filter;
      switch (filter.item.key) {
        case 'processed':
          vm.einvoicesFilterArchived.statusList = ['Processed'];
          break;
        case 'archived':
          vm.einvoicesFilterArchived.statusList = ['Archived'];
          break;
        case 'rejected':
          vm.einvoicesFilterArchived.statusList = ['Rejected'];
          break;
        case 'PaymentNotPossible':
          vm.einvoicesFilterArchived.statusList = ['PaymentNotPossible'];
          break;
        case 'PaidByOtherMeans':
          vm.einvoicesFilterArchived.statusList = ['PaidByOtherMeans'];
          break;
        case 'PaymentWithDirectDebit':
          vm.einvoicesFilterArchived.statusList = ['PaymentWithDirectDebit'];
          break;
        case 'Withdrawn':
          vm.einvoicesFilterArchived.statusList = ['Withdrawn'];
          break;
        // case 'Reviewed':
        //   vm.einvoicesFilterArchived.statusList = ['Reviewed'];
        //   break;
        case 'InProgressByOther':
          vm.einvoicesFilterArchived.statusList = ['InProgressByOther'];
          break;
        case 'cancelled':
          vm.einvoicesFilterArchived.statusList = ['Cancelled'];
          break;
        case 'all':
          vm.einvoicesFilterArchived.statusList = ['Archived', 'Processed', 'Rejected', 'PaidByOtherMeans', 'PaymentWithDirectDebit', 'PaymentNotPossible', 'Withdrawn', 'InProgressByOther', 'Cancelled'];
          break;
      }
      getFilteredEInvoices(vm.einvoicesFilterArchived, vm.einvoicesPeriod, 'einvoicesListSecondTab');
    };

    vm.processedRegsFilterChange = function (filter) {
      vm.processedRegsFilter = filter;
      switch (filter.item.key) {
        case 'inProcess':
          vm.registrationsFilterActive.serviceState = 'InProcess';
          break;
        case 'registered':
          vm.registrationsFilterActive.serviceState = 'Registered';
          break;
        case 'unregistered':
          vm.registrationsFilterActive.serviceState = 'Unregistered';
          break;
        case 'rejected':
          vm.registrationsFilterActive.serviceState = 'Rejected';
          break;
        case 'delivered':
          vm.registrationsFilterActive.serviceState = 'Delivered';
          break;
        case 'undelivered':
          vm.registrationsFilterActive.serviceState = 'Undelivered';
          break;
        case 'sent':
          vm.registrationsFilterActive.serviceState = 'Sent';
          break;
        default:
          vm.registrationsFilterActive.serviceState = null;
          break;
      }
      getFilteredSubscriptions(vm.registrationsFilterActive, 'registrationsListActive', true);
    };

    vm.registerAgain = function (registration) {
      $state.go('eInvoices.einvoicesRegisterNew', {myParam: registration});
    };

    vm.withdrawEInvoice = function () {
      var message = $translate.instant('core.notImplemented');
      NotificationService.showTranslatedMessage('error', message, '', 3000);
    };

    vm.toggleArchiveContainer = function () {
      vm.archiveObj.archiveCtnOpened = !vm.archiveObj.archiveCtnOpened;
      vm.archiveObj.userComment = '';
    };

    vm.showEInvoiceDetails = function (eInvoice) {
      if (!eInvoice.isCollapsed1) {
        EInvoiceDocumentsService.getById(eInvoice.id, {includeList: "EInvoiceDocumentDigitalSignaturesAdded"})
          .then(function (response) {
            eInvoice.isCollapsed1 = !eInvoice.isCollapsed1;
            vm.archiveObj.archiveCtnOpened = false;
            angular.extend(eInvoice, response);
            console.log(eInvoice);
          })
      } else {
        eInvoice.isCollapsed1 = !eInvoice.isCollapsed1;
      }

    };

    vm.init = function () {

      vm.einvoicesPeriod = {
        item: {
          key: 'thisYear'
        }
      };
      // loadRegsitrations(vm.registrationsFilterActive);
      if ($state.params.tabToSelect) {
        //vm.showSubmenuItemType = 'listTab';
        vm.selectTab('listTab', 'eInvoiceRegistration.listOfRegistrations');
      }else{
        loadEinvoicesOnTabChange();
      }
    };

    vm.openEInvoicesModal = function (eInvoice) {
      EInvoiceDocumentsService.openModal(eInvoice);
    };

    vm.downloadPdf = function (id) {
      if (vm.downloadButtonPressed) return;
      $log.debug("EInvoice Id: ", id);
      vm.downloadButtonPressed = true;
      EInvoiceDocumentsService.downloadLink(id)
        .then(function () {

        })
        .finally(function () {
          vm.downloadButtonPressed = false;
        });
    };

    vm.downloadDocumentPdf = function (id, childId) {
      if (vm.downloadButtonPressed) return;
      vm.downloadButtonPressed = true;

      EGeneralDocumentsService.downloadDocumentPdf(id, childId)
        .then(function () {
        })
        .finally(function () {
          vm.downloadButtonPressed = false;
        });
    };

    vm.selectTab = function (show, translateLabel) {
      if (show === vm.showSubmenuItemType) return;

      if (show === 'unpaidTab') {
        vm.showSubmenuItemType = 'unpaidTab';
        getFilteredEInvoices(vm.einvoicesFilterToPay, vm.einvoicesPeriod, 'einvoicesListFirstTab');
      } else if (show === 'processedTab') {
        vm.showSubmenuItemType = 'processedTab';
        getFilteredEInvoices(vm.einvoicesFilterArchived, vm.einvoicesPeriod, 'einvoicesListSecondTab');
      } else if (show === 'listTab') {
        vm.showSubmenuItemType = 'listTab';
        loadRegsitrations(vm.registrationsFilterActive);
      } else if (show === 'documentsTab') {
        vm.showSubmenuItemType = 'documentsTab';
        vm.loadMoreEInvoicesFourthTab(true);
      }


      GoogleTagManagerHelperService.pushEvent('EInvoices Overview', 'Tab', $translate.instant(translateLabel));

    };

    vm.reject = function (item, $event) {
      $event.stopPropagation();
      AlertService.showRejectEDocumentWarning()
        .then(function (text) {
          if (text) {

            EGeneralDocumentsService.rejectEDocument(item.id, text)
              .then(function (response) {
                //NotificationService.showMessage(error, 'success');
                if (vm.showSubmenuItemType === 'documentType')
                  loadMoreEInvoicesFourthTab(true);
                if (vm.showSubmenuItemType === 'unpaidTab')
                  loadMoreEinvoicesFirstTab(true);
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');

              })
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    // -------------------------
    // FOURTH TAB OPTIONS START
    // -------------------------

    vm.searchEDocumentToPay = {
      creditorName: ''
    };

    vm.archiveEDocument = function (eDocument) {
      var id = eDocument.id;
      if (eDocument.archiveEInvoiceInProgress) return;
      eDocument.archiveEInvoiceInProgress = true;

      AlertService.eInvoiceArchiveAlert({
        text: "payments.paymentDueDateChangedMsg"
      })
        .then(function (accept) {
          if (!accept) {
            eDocument.archiveEInvoiceInProgress = false;
            return;
          }
          EGeneralDocumentsService.archiveEDocument(id, vm.archiveObj.userComment)
            .then(function (response) {
              vm.archiveObj.archiveCtnOpened = false;

              AlertService.infoAlert({
                text: 'alert.archiveEInvoice'
              })
                .then(function () {
                  loadMoreEinvoicesFirstTab(true);
                  loadMoreEinvoicesSecondTab(true)
                })
            })
            .catch(function (error) {
              eDocument.archiveEInvoiceInProgress = false;
              NotificationService.showMessage(error, 'error');
            });
        })
    };

    vm.showEDocumentDetails = function (eInvoice) {
      if (!eInvoice.isCollapsed1) {
        EGeneralDocumentsService.getById(eInvoice.id, {includeList: "EInvoiceDocumentAttachmentList"})
          .then(function (response) {
            eInvoice.isCollapsed1 = !eInvoice.isCollapsed1;
            vm.archiveObj.archiveCtnOpened = false;
            angular.extend(eInvoice, response);
            console.log(eInvoice);
          })
      } else {
        eInvoice.isCollapsed1 = !eInvoice.isCollapsed1;
      }

    };

    vm.documentsFilterActive = {
      page: 0,
      pageSize: 10,
      orderBy: 'postedDate desc'
    };

    vm.loadMoreEInvoicesFourthTab = function (reset) {
      var filter = vm.documentsFilterActive;
      if (reset) {
        filter.page = 0;
      } else {
        filter.page += 1;
      }
      EGeneralDocumentsService.getPage(filter)
        .then(function (response) {

          if (reset) {
            vm.documentsListTab = response;
          } else {
            vm.documentsListTab.hasMore = response.hasMore;
            vm.documentsListTab.result = vm.documentsListTab.result.concat(response.result);
          }

        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    // -------------------------
    // FOURTH TAB OPTIONS END
    // -------------------------


    vm.init();
  }
}());
