(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name core.factory:SessionService
   *
   * @description
   *
   */
  angular.module("core").factory("SessionService", SessionService);

  function SessionService(
    $log,
    $window,
    $rootScope,
    ConfigService,
    $interval,
    $injector,
    $httpParamSerializer
  ) {
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function parseJwt(token) {
      $log.debug("### 07-3 ###");
      $log.debug(token);
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }

    function toHexadecimal(decimal) {
      return ("0" + decimal.toString(16)).substr(-2);
    }

    function generateRandomCodeVerifier() {
      var array = new Uint32Array(56 / 2);
      var crypto = $window.crypto || $window.msCrypto;
      crypto.getRandomValues(array);
      return Array.from(array, toHexadecimal).join("");
    }

    function encryptSha256(codeVerifier) {
      // returns promise ArrayBuffer
      const data = encode(codeVerifier);
      var crypto = $window.crypto || $window.msCrypto;
      return crypto.subtle.digest("SHA-256", data);
    }

    function encode(codeVerifier) {
      if (window.TextEncoder) {
        return new TextEncoder().encode(codeVerifier);
      } else {
        var utf8 = unescape(encodeURIComponent(codeVerifier));
        var result = new Uint8Array(utf8.length);
        for (var i = 0; i < utf8.length; i++) {
          result[i] = utf8.charCodeAt(i);
        }
        return result;
      }
    }

    function generateCodeChallenge(a) {
      var str = "";
      var bytes = new Uint8Array(a);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
      }
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }

    function transformUrlEncoded(data) {
      return $httpParamSerializer(data);
    }

    function successfullLoginCallback(data) {
      if (data) {
        var dataList = data.split("&");
        var accessTokenData = dataList[0].split("=");
        var tokenTypeData = dataList[1].split("=");
        var expiresInData = dataList[2].split("=");
        var stateData = dataList[3].split("=");

        $window.sessionStorage.setItem(
          "ngStorage-ct_accessToken",
          accessTokenData[1]
        );
        $window.sessionStorage.setItem(
          "ngStorage-token_type",
          tokenTypeData[1]
        );
        $window.sessionStorage.setItem(
          "ngStorage-expires_in",
          expiresInData[1]
        );
        $window.sessionStorage.setItem("ngStorage-state", stateData[1]);

        $log.debug("### 07-1 ###");
        $log.debug($window.sessionStorage.getItem("ngStorage-ct_accessToken"));

        var host = window.location;
        var pathname = location.pathname.substring(
          0,
          location.pathname.lastIndexOf("/")
        );
        var redirectUrl = host.protocol + "//" + host.host + pathname + "/";

        // flush storage
        // $window.sessionStorage.$apply();

        // If there was previous
        if ($window.sessionStorage.lastUrl) {
          var lastUrl = $window.sessionStorage.lastUrl;
          delete $window.sessionStorage.lastUrl;
          // window.location.href = lastUrl;
          SessionServiceBase.waitForAccessTokenChangeAndRedirect(
            accessTokenData[1],
            lastUrl
          );
        } else {
          // window.location.href = redirectUrl;
          SessionServiceBase.waitForAccessTokenChangeAndRedirect(
            accessTokenData[1],
            redirectUrl
          );
        }
      } else {
        console.log("Session service -- no data from OAuth");

        SessionServiceBase.errorKey = getParameterByName("error");
        SessionServiceBase.errorDescription = getParameterByName(
          "error_description"
        );
      }
    }

    function successfullLoginCallbackSlo(data, state) {
      var $http = $injector.get("$http");
      if (data) {
        var requestParams = ConfigService.authorizationUrlParams;
        requestParams["code"] = data;
        requestParams["code_verifier"] = $window.sessionStorage.getItem(
          "ngStorage_ct_codeVerifier"
        );
        $http({
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          url: ConfigService.tokenAuthorizationUrl,
          transformRequest: transformUrlEncoded,
          data: requestParams,
        })
          .then(function (response) {
            var tokenResponse = response.data;
            $window.sessionStorage.setItem(
              "ngStorage-ct_accessToken",
              tokenResponse.access_token
            );
            $window.sessionStorage.setItem(
              "ngStorage-token_type",
              tokenResponse.token_type
            );
            $window.sessionStorage.setItem(
              "ngStorage-refreshToken",
              tokenResponse.refresh_token
            );
            $window.sessionStorage.setItem(
              "ngStorage-expires_in",
              tokenResponse.expires_in
            );
            $window.sessionStorage.setItem("ngStorage-state", state);
            var lastUrl = $window.sessionStorage.lastUrl;
            delete $window.sessionStorage.lastUrl;
            if (lastUrl) {
              window.location.href = lastUrl;
            } else {
              window.location.href = "/" + ConfigService.baseHref;
            }
          })
          .catch(function (error) {
            SessionServiceBase.logout();
          });
      } else {
        var jwt = $window.sessionStorage.getItem("ngStorage-ct_accessToken");
        if (jwt) {
          window.location.href = "/" + ConfigService.baseHref;
        } else {
          var codeVerifier = generateRandomCodeVerifier();
          $window.sessionStorage.setItem(
            "ngStorage_ct_codeVerifier",
            codeVerifier
          );
          var encryptCallback = function (hashed) {
            var codeChallenge = generateCodeChallenge(hashed);
            var url =
              ConfigService.authorizationUrl +
              "&code_challenge_method=S256&code_challenge=" +
              codeChallenge;
            window.location.href = url;
          };
          var encryptAction = encryptSha256(codeVerifier); // For IE returns sync result, for others async
          if (encryptAction.then) {
            encryptAction.then(encryptCallback);
          } else {
            encryptCallback(encryptAction.result);
          }
        }
        // check for active token
        // if there is token go next
        // if not generate code_verifier and code_challenge and go to oauth
      }
    }

    var SessionServiceBase = {};
    SessionServiceBase.errorKey = "";
    SessionServiceBase.errorDescription = "";

    SessionServiceBase.init = function () {
      //Performs storing of data
      SessionServiceBase.errorKey = "";
      SessionServiceBase.errorDescription = "";

      if (ConfigService.country === "sl") {
        var queryParams = window.location.search;
        var temp = queryParams.replace("?", "").split("&");
        var data = temp[0].split("=")[1];
        var state = null;
        if (temp[1]) {
          state = temp[1].split("=")[1];
        }
        successfullLoginCallbackSlo(data, state);
      } else {
        var data = window.location.hash;
        successfullLoginCallback(data);
      }
    };

    SessionServiceBase.authorize = function () {
      window.location.href = ConfigService.authorizationUrl;
    };

    SessionServiceBase.getRefreshToken = function (callback) {
      var $http = $injector.get("$http");
      // Method to get new token
      var requestParams = ConfigService.refreshUrlParams;
      requestParams["refresh_token"] = $window.sessionStorage.getItem(
        "ngStorage-refreshToken"
      );
      $http({
        method: "POST",
        url: ConfigService.tokenAuthorizationUrl,
        data: requestParams,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        transformRequest: transformUrlEncoded,
      })
        .then(function (response) {
          var tokenResponse = response.data;
          $window.sessionStorage.setItem(
            "ngStorage-ct_accessToken",
            tokenResponse.access_token
          );
          $window.sessionStorage.setItem(
            "ngStorage-token_type",
            tokenResponse.token_type
          );
          $window.sessionStorage.setItem(
            "ngStorage-refreshToken",
            tokenResponse.refresh_token
          );
          $window.sessionStorage.setItem(
            "ngStorage-expires_in",
            tokenResponse.expires_in
          );
          callback(tokenResponse.access_token);
        })
        .catch(function (error) {
          SessionServiceBase.logout();
        });
    };

    SessionServiceBase.refreshUrl = function () {
      window.location.href = ConfigService.refreshTokenAuthorizationUrl;
    };

    SessionServiceBase.getTokenProperty = function (prop) {
      var temp = $window.sessionStorage.getItem("ngStorage-ct_accessToken");
      $log.debug("### 07-2 ###");
      $log.debug(temp);
      if (!temp) {
        return;
      }

      var token = parseJwt(temp);

      if (token) {
        return token[prop];
      }

      return null;
    };

    SessionServiceBase.isAuthenticated = function () {
      if (ConfigService.testLocal) {
        // if it is localhost environment, skip authentication check
        return true;
      }
      var temp = $window.sessionStorage.getItem("ngStorage-ct_accessToken");
      return !!temp;
    };

    function clearSessionAccessToken() {
      $window.sessionStorage.removeItem("ngStorage-ct_accessToken");
      $window.sessionStorage.removeItem("ngStorage-refreshToken");
      $window.sessionStorage.removeItem("ngStorage-expires_in");
      $window.sessionStorage.removeItem("ngStorage-token_type");
      $window.sessionStorage.removeItem("ngStorage-state");
      $window.sessionStorage.removeItem("ngStorage_ct_codeVerifier");
    }

    function clearSessionCampaignData() {
      $window.sessionStorage.removeItem("answeredBannerCampaignsEng");
      $window.sessionStorage.removeItem("answeredBannerCampaignsLocal");
      $window.sessionStorage.removeItem("answeredCarouselCampaignsLocal");
      $window.sessionStorage.removeItem("answeredCarouselCampaignsEng");
      $window.sessionStorage.removeItem("answeredPopUpCampaignsLocal");
      $window.sessionStorage.removeItem("answeredPopUpCampaignsEng");
    }

    SessionServiceBase.saveCurrentState = function () {
      if (!$window.sessionStorage.lastUrl) {
        if (window.location.href.includes("hidden-auth-component")) {
          return;
        }
        $window.sessionStorage.lastUrl = window.location.href;
      }
    };

    SessionServiceBase.saveStorageObject = function (o, t) {
      $window.sessionStorage.paymentStorageObject = {
        object: o,
        type: t,
      };
    };

    SessionServiceBase.removeStorageObject = function () {
      delete $window.sessionStorage.paymentStorageObject;
    };

    SessionServiceBase.logout = function () {
      clearSessionAccessToken();
      clearSessionCampaignData();
      window.location.href = ConfigService.logoutUrl;
    };

    SessionServiceBase.clearSessionWeb = function () {
      clearSessionAccessToken();
    };

    SessionServiceBase.waitForAccessTokenChangeAndRedirect = function (
      value,
      url
    ) {
      $log.debug("Credentials 1");
      $log.debug(value);

      // NOTE: 8.6.2020.
      // TODO: Remove in future versions after several PROD installations, added for SLO and CRO to handle different storage
      // TODO: in ngStorage-credentials and direct in ngStorage-propName
      var credentials = $window.sessionStorage.getItem("ngStorage-credentials");
      if (credentials) {
        var temp = JSON.parse(credentials);

        $window.sessionStorage.setItem(
          "ngStorage-ct_accessToken",
          temp.ct_accessToken
        );
        $window.sessionStorage.setItem("ngStorage-token_type", temp.token_type);
        $window.sessionStorage.setItem("ngStorage-expires_in", temp.expires_in);
        $window.sessionStorage.setItem("ngStorage-state", temp.state);
      }

      var token = $window.sessionStorage.getItem("ngStorage-ct_accessToken");

      if (token === value) {
        window.location.href = url;
        return;
      }

      var interval = $interval(function () {
        $log.debug("Credentials 2");
        $log.debug(value);
        if (token === value) {
          $interval.cancel(interval);
          window.location.href = url;
        }
      }, 100);
    };

    return SessionServiceBase;
  }
})();
