(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sepa.controller:SepaComplaintRefundCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaComplaintRefundCtrl', SepaComplaintRefundCtrl);

  function SepaComplaintRefundCtrl($http, $state,$scope, transaction, ValidationService, HelperService, SepaService, NotificationService) {
    var vm = this;
    vm.type = $state.params.type;
    vm.status = $state.params.status;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.formStep = 1;

    vm.model = transaction;
    vm.inputModel = {
      reasonForcomplaint: "",
      extra: ""
    };

    var defaultId  = '';
    var tempId = '';

    init();
    function init() {
      loadData();
    }

    function selectReasonType() {
      var twoMonthsFromDate = new Date().setMonth(new Date().getMonth()-2);
      var thirteenMonthsFromNow = HelperService.setCustomMonthPeriod(new Date(),13);
      if(new Date(vm.model.valuteDate) > twoMonthsFromDate){
        vm.inputModel.reasonForcomplaint = _.find(vm.complaintReasonTypes, {id: "DOD_OBRAZ"});
      }else {
        var temp = _.filter(vm.complaintReasonTypes, function (item) {
          return item.id == 'NEOD_SDD';
        });
        if(temp)
          vm.complaintReasonTypes = temp;
        vm.inputModel.reasonForcomplaint = _.find(vm.complaintReasonTypes, {id: "NEOD_SDD"});
      }
    }

    function loadData() {
      SepaService.getSepaDDComplaintReasonTypes()
        .then(function (response) {
          vm.complaintReasonTypes = response.result;
          vm.inputModel.reasonForcomplaint = _.find(vm.complaintReasonTypes, {id: "DOD_OBRAZ"});

          if($state.params.status == 'Processed'){
            // vm.complaintReasonTypes.push({ id: "DDCOMPLAINT", description: "Ugovor na izvršeno transakcijo SEPA DD" });
            // tempId = 'DDCOMPLAINT';
            selectReasonType();
          }else if($state.params.status == 'Waiting'){
            // vm.complaintReasonTypes.push({ id: "DDCOMPLAINTWQ", description: "Ugovor na transakcijo SEPA DD v čakalni vrsti" });
            // tempId = 'DDCOMPLAINTWQ';
          }
        }, function (error) {
          console.log(error);
        })
    }

    vm.next = function () {
      vm.formSubmitted = true;
      if (vm.refundForm.$invalid) return;
      vm.formStep = 2;
    };

    vm.back = function () {
      vm.formSubmitted = false;
      vm.formStep = 1;
      vm.confirmButtonDisabled = false;
      //vm.inputModel.reasonForcomplaint = defaultId;
    };

    vm.confirm = function () {
      vm.formSubmitted = true;
      if (vm.refundForm.$invalid) return;
      var input = {
        sepaDDComplaintReasonType: vm.inputModel.reasonForcomplaint,
        note: "  ",
        extra: (vm.inputModel.reasonForcomplaint.id == "DOD_OBRAZ") ? vm.inputModel.extra : "",
        status: vm.model.status
      };


      vm.confirmButtonDisabled = true;

      // Workaround - transactionId is hashed "-53593|4" need to get characters behind the |
      transaction.transactionId = transaction.transactionId.substring(transaction.transactionId.indexOf("|") + 1);

      // status must be there, whatever it is!
      var postData = angular.extend(input, transaction);
      postData.reference = postData.receiverReference;
      $http.post(transaction.linkList.itemList.complaint, postData)
        .then(function (data) {
          HelperService.confirmationReclamationMsg('core.confirmation');
          vm.confirmButtonDisabled = false;
          $state.go("sepa.transactions");
        })
        .catch(function (error) {
          vm.confirmButtonDisabled = false;
          NotificationService.showMessage(error, 'error');
        })
    }
  }
}());
