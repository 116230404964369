<section id="main">
  <div class="container-fluid">

    <div class="row">
      <div class="heading">
        <span translate="sepa.heading"></span>
      </div>
    </div>

    <div class="row p-t-20 ">
      <div class="col-xs-12 subheading">
        <p translate="sepa.subheading1">

        </p>
        <p translate="sepa.subheading2">

        </p>

        <p class="p-t-15" translate="sepa.subheading3">

        </p>
      </div>

      <div class="col-xs-12">
        <ng-form name="sepaDirectDebit.directDebitForm">

          <div class="row form-group">
            <div class="col-xs-12 col-md-6">
              <label class="domp-form-label" translate="sepa.payerIban"></label>
              <input type="text" name="ibanFrom" id="ibanFrom"
                     ng-class="{'domp-error': sepaDirectDebit.directDebitForm.ibanFrom.$invalid && sepaDirectDebit.formSubmitted}"
                     ng-model="sepaDirectDebit.model.fromAccountNumber"
                     capitalize
                     ng-trim="false"
                     ng-pattern-restrict="{{sepaDirectDebit.regexPatterns.allLettersNumbersSpaces}}"
                     ui-options="sepaDirectDebit.uiMaskOptions"
                     ng-readonly="currentStep == 2"
                     class="form-control input-field" required/>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="row">
                <div class="col-xs-12">
                  <label class="domp-form-label" translate="sepa.amountAndCurrency"></label>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-8 col-md-8">
                  <input type="text" name="amount" id="amount"
                         ng-class="{'domp-error': sepaDirectDebit.directDebitForm.amount.$invalid && sepaDirectDebit.formSubmitted}"
                         ng-model="sepaDirectDebit.model.amount"
                         amount-input-mask maxlength="12"
                         ng-readonly="currentStep == 2"
                         class="form-control input-field text-right" required/>
                </div>

                <div class="col-xs-4 col-md-4">
                  <select name="currency" id="currency" class="form-control input-field"
                          ng-options="option.symbol for option in sepaDirectDebit.currenciesList"
                          ng-disabled="currentStep == 2"
                          ng-model="sepaDirectDebit.model.currency">
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-xs-6">
              <div class="row ">
                <div class="col-xs-12">
                  <div class="form-group">
                    <label class="domp-form-label" translate="sepa.payeeIban"></label>
                    <input type="text" name="ibanTo" id="ibanTo" ng-model="sepaDirectDebit.model.toAccountNumber"

                           capitalize
                           ng-trim="false"
                           ng-pattern-restrict="{{sepaDirectDebit.regexPatterns.allLettersNumbersSpaces}}"
                           ui-options="sepaDirectDebit.uiMaskOptions"
                           ng-readonly="currentStep == 2"
                           ng-class="{'domp-error': sepaDirectDebit.directDebitForm.ibanTo.$invalid && sepaDirectDebit.formSubmitted}"
                           class="form-control input-field" required/>
                  </div>

                </div>
                <div class="col-xs-12">
                  <div class="form-group">
                    <label class="domp-form-label" translate="sepa.payeeName"></label>
                    <input type="text" name="toAccountName" id="toAccountName"
                           ng-model="sepaDirectDebit.model.toAccountName"
                           class="form-control input-field"
                           ng-readonly="currentStep == 2"
                           ng-class="{'domp-error': sepaDirectDebit.directDebitForm.toAccountName.$invalid && sepaDirectDebit.formSubmitted}"
                           minlength="3"
                           required/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="form-group">
                <label class="domp-form-label" for="dateInput" translate="sepa.date">Date:</label>
                <div class="input-group ">
                  <input type="text" class="form-control input-field input-field" name="dateInput"
                         id="dateInput"
                         uib-datepicker-popup="shortDate"
                         data-ng-model="sepaDirectDebit.model.dateOfExecution"
                         show-weeks="false"
                         is-open="sepaDirectDebit.datePickerOpened"
                         datepicker-options="sepaDirectDebit.dateOptions"
                         current-text="{{'core.period.today' | translate}}"
                         clear-text="{{'core.datePicker.clearBtn' | translate}}"
                         close-text="{{'core.datePicker.doneBtn' | translate}}"
                         date-disabled="sepaDirectDebit.disabled(date, mode)"
                         readonly>
                  <span class="input-group-btn">
                    <button data-ng-click="sepaDirectDebit.openDatePicker()" type="button"
                            ng-disabled="currentStep == 2"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                            style="border:1px solid #062a42;">
                      <i class="icon icon-calendar" style="font-size: 21px;"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-xs-12 text-center">
              <button class="btn btn-default  btn-blue-round-custom" type="submit"
                      translate="sepa.sendMsgButton"
                      ng-click="sepaDirectDebit.submit()"
                      ng-disabled="currentStep == 2"></button>
            </div>
          </div>

        </ng-form>
      </div>



    </div>


  </div>
</section>
