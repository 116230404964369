(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name investments.factory:FundsService
   *
   * @description
   *
   */
  angular
    .module('investments')
    .factory('FundsService', FundsService);

  function FundsService($http, $q, EndpointsService, ConfigService) {
    var FundsServiceBase = {};

    FundsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.funds, {
              params: params
            })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    FundsServiceBase.getFundById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.funds_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    FundsServiceBase.getUserFundById = function (accountId) {
      var deferred = $q.defer();
      var url = ConfigService.url + 'funds/getUserFundAccountById';
      $http.get(url, {
          params: {
            AccountId: accountId
          }
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      return deferred.promise;
    };

    FundsServiceBase.insertFundUserDetail = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.funds_insertFundUserDetail, params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    FundsServiceBase.getFundAccounts = function(params){
       var deferred = $q.defer();
      var url = ConfigService.url + 'funds/getFundAccounts';
      $http.get(url, {
          params: params
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      return deferred.promise;
    }

    FundsServiceBase.getUserInvestmentAccountsByFundId = function (params) {
      var deferred = $q.defer();
      var url = ConfigService.url + 'funds/getUserInvestmentAccountsByFundId';
      $http.get(url, {
          params: params
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      return deferred.promise;
    };

    return FundsServiceBase;
  }
}());
