(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name timeline.controller:TimelineItemDetailsModalCtrl
     *
     * @description
     *
     */
    angular
        .module('timeline')
        .controller('TimelineItemDetailsModalCtrl', TimelineItemDetailsModalCtrl);

    function TimelineItemDetailsModalCtrl(timelineItem, $log, TimelineService, $uibModalInstance, TransactionsService, AccountsService, EInvoiceDocumentsService, MessagesService) {
        var vm = this;
        vm.ctrlName = 'TimelineItemDetailsModalCtrl';
        vm.timelineItem = timelineItem;
        vm.closeModal = function () {
            $uibModalInstance.close();
        };

        vm.transaction = {};
        vm.account = {};
        vm.einvoice = {};
        vm.message = {};

        vm.getDateObjectfromDateString = function (dateString) {
            return new Date(dateString);
        };

        vm.init = function () {
            //    alert(1);
            if (timelineItem.referenceType == "Transaction") {
                TransactionsService.getById(timelineItem.referenceId)
                    .then(function (response) {
                        vm.transaction = response;
                        if (response.accountId) {
                            AccountsService.getAccountById(response.accountId)
                                .then(function (response) {
                                    vm.account = response;
                                })
                                .catch(function (error) {
                                    $log.error(error);
                                })
                        }
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });
            } else if (timelineItem.referenceType == "EInvoice") {
                EInvoiceDocumentsService.getById(timelineItem.referenceId)
                    .then(function (response) {
                      if(response){
                        vm.einvoice = response;
                      }
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });
            } else if (timelineItem.referenceType == "Message") {
                MessagesService.getById(timelineItem.referenceId)
                    .then(function (response) {
                        vm.message = response;
                        $log.debug("Message object by id", vm.message)
                    })
                    .catch(function (error) {
                        $log.error(error);
                    });
            }

        }
        vm.init();
    }
}());
