<section id="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 p-0">
        <div class="secure-3d-bg">
          <span  class="secure-3d-title">3D Secure</span>
          <span class="secure-3d-subtitle" translate="secure3d.text5"></span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 secure3d-container p-t-30">
        <div class="secure3d-text p-b-15" translate="secure3d.text1"></div>
        <div class="secure3d-text p-b-15" translate="secure3d.text12"></div>
        <div class="secure3d-text p-b-15">
          {{'secure3d.text2' | translate}}
          <a class="secure3d-link" href="https://www.addiko.hr/" target="_blank" translate="secure3d.text3link"></a>
          {{'secure3d.text4'| translate}}
        </div>
        <div class="secure3d-text p-b-30">
          {{'secure3d.text5' | translate}}
        </div>

      </div>

      <div class="col-sm-12 t-a-c p-t-15">

        <button class="btn btn-secure3d" title="{{ 'secure3d.buttonTitle' | translate }}" type="submit" ng-click="secure3d.goToLink();" translate="secure3d.buttonText"></button>
      </div>
    </div>
  </div>
</section>
