(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      paymentTemplates: {
        "payingFrom": "Uplata sa:",
        "payingTo": "Uplata na:",
        "howMuch": "Iznos",
        "description": "Opis",
        "when": "Datum",
        "fullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "showFullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "pay": "Plati",
        "delete": "Obriši",
        "save": "Snimi",
        "signPayment": "Potpiši",
        "cancel": "Odustani",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Adresa banke",
        "dueDate": "Datum dospjeća",
        "fromAccount": "Račun pošiljatelja",
        "toAccount": "Račun primaoca",
        "receiver": "Primalac",
        "receiverAddress": "Adresa primaoca",
        "amount": "Iznos",
        "signMsg": "Proverite unesene podatke i potpišite",
        "saveTemplate": "Novi šablon",
        "invalidDate": "Datum je nevalidan!",
        "descriptionError": "Opis je obavezan!",
        "templateName": "Naziv šablona",
        "templateIcon": "Slika",
        "deleteSuccess1": "Šablon",
        "deleteSuccess2": "je uspešno obrisan."
      },
      templatesList: {
        "header":"Lista šablona",
        "searchTemplates": "Pretraži šablone",
        "addNewTemplate": "+ Dodaj novi šablon",
        "listOfTemplates":"Šabloni",
        "fromAccount":"Sa računa",
        "amount":"Iznos",
        "toPayee":"Primalac",
        "accountNumber":"Broj računa",
        "payeeStreet":"Adresa primaoca",
        "payeeCity":"Grad primaoca",
        "purposeCode":"Šifra plaćanja",
        "referenceModel":"Model",
        "referenceNumber":"Model i poziv na broj",
        "addTemplateToFavorites":"Dodaj predložak u favorite",
        "edit":"Izmeni",
        "save": "Snimi",
        "cancel": "Odustani"
      }
    };

    $translateProvider
      .translations('sr', data);
  }
}());
