<section id="main" ng-if="vm.pageLoaded">

  <div class="container-fluid clearfix m-b-40">
    <div class="row">
      <div class="col-md-12">
        <h3 translate="sepa.refusalTitle">SEPA REFUSAL</h3>
      </div>
    </div>

    <!-- ============= -->
    <!-- START FORM    -->
    <!-- ============= -->
    <!-- ======= -->
    <!-- START -->
    <!-- ======= -->
    <div ng-form name="vm.refusalForm"
         ng-if="vm.step == 'start'"
         class="bg-white p-20 m-t-15 clearfix"
         novalidate>
      <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div class="accounts-dropdown m-b-10" ng-if="vm.currentAccounts.length > 0">
            <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                 data-ng-click="vm.fromAccountDropdown = !vm.fromAccountDropdown"
                 ng-style="vm.model.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + vm.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
              <h4 class="no-wrap-text bold-font m-b-0">
                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{vm.model.fromAccountSelect.friendlyName}}</b>
                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                <i class="zmdi"
                   ng-class="{'zmdi-star favorite-account':vm.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!vm.model.fromAccountSelect.isFavorite}">
                </i>
              </span>
              </h4>
              <p class="m-b-0 m-t-0 iban-style">{{vm.model.fromAccountSelect.iban}}</p>
              <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                {{vm.model.fromAccountSelect.availableBalance| number:2}}
                {{vm.model.fromAccountSelect.currencySymbol}}
              </p>
              <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
              <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
            </div>
            <div class="from-accounts-list m-l-15" ng-show="vm.fromAccountDropdown" scrollable>
              <div class="accounts-list-item p-0" data-ng-repeat="item in vm.currentAccounts"
                   data-ng-click="vm.setAccountFrom(item);">

                <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                     ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                        ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{item.availableBalance| number:2}} {{item.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                </div>

              </div>
            </div>
          </div>
          <div class="accounts-dropdown m-b-10" ng-if="vm.currentAccounts.length == 0">
            <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
                 ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
              <div class="m-t-30 m-b-30">
                <p translate="internalTransfer.nodata"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.executionDate">
              Datum izvršenja
            </label>
            <div class="input-group ">
              <input type="text" class="form-control input-field"
                     id="executionDate"
                     name="executionDate"
                     uib-datepicker-popup="shortDate"
                     data-ng-model="vm.model.executionDate"
                     show-weeks="false"
                     is-open="vm.executionDateDatepickerOpen"
                     datepicker-options="vm.dateOptions"
                     date-disabled="vm.datesDisabled(date, mode)"
                     ng-change="vm.onExecutionDateChange()"
                     show-button-bar="false"
                     readonly
                     required>
              <span class="input-group-btn">
                <button data-ng-click="vm.executionDateDatepickerOpen = !vm.executionDateDatepickerOpen" type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                        style="border:1px solid #062a42;">
                  <i class="icon icon-calendar" style="font-size: 21px;"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.amount">
              Iznos
            </label>
            <input type="text" class="form-control input-field"
                   id="amount"
                   name="amount"
                   ng-model="vm.model.amount"
                   amount-input-mask
                   allow-empty="true"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.identifierApproval">
              Identifikator suglasnosti
            </label>
            <input ng-class="{'domp-error': vm.formSubmitted && vm.refusalForm.identifierApproval.$invalid }"
                   type="text" class="form-control input-field"
                   id="identifierApproval"
                   name="identifierApproval"
                   ng-model="vm.model.identifierApproval"
                   required
                   maxlength="35"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverId">
              Identifikator primatelja
            </label>
            <input type="text" name="receiverId" id="receiverId"
                   class="input-field form-control"
                   ng-class="{'domp-error': vm.formSubmitted && vm.refusalForm.receiverId.$invalid }"
                   ng-model="vm.model.receiverId"
                   ng-blur="vm.findIdInCreditorList(vm.model.receiverId)"
                   maxlength="32"
                   autocomplete="off" required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverName">
              Naziv i sjedište primatelja plaćanja
            </label>
            <input ng-class="{'domp-error': vm.formSubmitted && vm.refusalForm.receiverName.$invalid }"
                   type="text" class="form-control input-field"
                   id="receiverName"
                   name="receiverName"
                   ng-model="vm.model.receiverName"
                   ng-readonly="vm.creditorInListExist"
                   required/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverAccount">
              Račun primatelja plaćanja
            </label>
            <input ng-class="{'domp-error': vm.formSubmitted && vm.refusalForm.receiverAccount.$invalid }"
                   type="text" class="form-control input-field"
                   id="receiverAccount"
                   name="receiverAccount"
                   ng-model="vm.model.receiverAccount"
                   required/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverNumber">
              Detalji plaćanja (poziv na broj primatelja)
            </label>
            <input type="text" class="form-control input-field"
                   id="receiverNumber"
                   name="receiverNumber"
                   ng-model="vm.model.receiverNumber"/>
          </div>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-md-12">
          <button class="btn btn-primary"
                  type="button"
                  translate="sepa.cancelBtnLabel"
                  ui-sref="sepa.transactions">
          </button>

          <button class="btn btn-default pull-right"
                  type="submit"
                  translate="sepa.confirm"
                  ng-click="vm.submit(vm.refusalForm);"
                  ng-disabled="vm.submitButtonDisabled">
          </button>
        </div>
      </div>
    </div>

    <!-- ======= -->
    <!-- RESUME -->
    <!-- ======= -->
    <div ng-if="vm.step == 'resume'" class="bg-white p-20 m-t-15 clearfix">
      <div class="row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.executionDate">
            Datum izvršenja
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.executionDate | date: 'shortDate' : '+0200' }}
          </label>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.amount">
            Iznos
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.amount | currency:"" }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.identifierApproval">
            Identifikator suglasnosti
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.identifierApproval }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.receiverId">
            Identifikator primatelja
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.receiverId }}
          </label>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.receiverName">
            Iznos
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.receiverName }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.receiverAccount">
            Račun primatelja plaćanja
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.receiverAccount }}
          </label>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label class="domp-form-label-review" translate="sepa.receiverNumber">
            Detalji plaćanja (poziv na broj primatelja)
          </label>
          <label class="domp-form-label-resume">
            {{ vm.model.receiverNumber }}
          </label>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-md-12">
          <!--<button class="btn btn-primary"-->
                  <!--type="button"-->
                  <!--translate="sepa.cancelBtnLabel"-->
                  <!--ng-click="vm.step = 'start'">-->
          <!--</button>-->

          <button class="btn btn-default pull-right"
                  type="submit"
                  translate="eInvoicesModal.sign"
                  ng-click="vm.step = 'sign';">
          </button>
        </div>
      </div>
    </div>
    <!-- ============= -->
    <!-- SIGN FORM   -->
    <!-- ============= -->
    <div class="bg-white p-20 m-t-15 clearfix" ng-if="vm.step == 'sign'">
      <addiko-mtoken-sign
              show-cancel="false"
              placeholder="vm.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
              qr-challenge="vm.qrChallenge" show-challenge="vm.showChallenge" 
              challenge1="vm.challengeFirstCode" challenge2="vm.challengeSecondCode"
              action-disabled="vm.confirmSignBtnDisabled"></addiko-mtoken-sign>
    </div>
  </div>
</section>
