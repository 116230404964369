(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsRecallModalCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsRecallModalCtrl', PaymentsRecallModalCtrl);

  function PaymentsRecallModalCtrl($rootScope,$translate, $uibModalInstance, data) {
    var vm = this;
    var isHR = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation=='hr';
    vm.init = function(){
      vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      vm.payment = angular.copy(data);
      vm.formSubmitted = false;

      vm.recallReasonsList = [
        {key: 'paymentsReturn.reasonTech', value: "TECH"},
        {key: 'paymentsReturn.reasonFrad', value: "FRAD"},
        {key: 'paymentsReturn.reasonDupl', value: "DUPL"}
      ];

      vm.recallReasonObject = {
        reasonCode: "TECH",
        additionalInfo: ""
      };

      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation=='hr')
      {
          vm.recallReasonsList = [
            {key: 'paymentsReturn.reasonTech', value: "TECH"},
            {key: 'paymentsReturn.reasonFrad', value: "FRAD"},
            {key: 'paymentsReturn.reasonDupl', value: "DUPL"},
            {key: 'paymentsReturn.reasonAM09', value: "AM09"},
            {key: 'paymentsReturn.reasonAC03', value: "AC03"},
            {key: 'paymentsReturn.reasonCUST', value: "CUST"}
          ];
      }
    };

    vm.confirm = function () {
      vm.formSubmitted = true;
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') vm.recallReasonObject = {};
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && !vm.recallForm.$valid) return;
      $uibModalInstance.close(vm.recallReasonObject);
    };

    vm.reasonChange = function (_reason) {
      if(isHR && data && data.toAccountNumber && _reason && _reason.reasonCode=='AC03')
      {
        if(data.toAccountNumber.indexOf('HRK')!=-1 || data.toAccountNumber.indexOf('HR')==0)
        {
          vm.recallReasonObject.additionalInfo = $translate.instant("paymentsReturn.reasonAC03HR");
        }
        else{
          vm.recallReasonObject.additionalInfo = $translate.instant("paymentsReturn.reasonAC03EN");
        }
      } else {
        vm.recallReasonObject.additionalInfo = '';
      }
    };

    vm.removeReason = function (remove, reason) {
      if(remove) {
        vm.recallReasonObject.additionalInfo = '';
      } else {
        vm.reasonChange(reason);
        //vm.recallReasonObject.additionalInfo = reason;
      }
    };

    vm.init();
  }
}());
