(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      invest:{
        "signInvestment": "Sign investment",
        "headerNew":"New investment",
        "accountFrom":"From account",
        "amountInputLabel":"Amount",
        "quantityInputLabel": "Quantity",
        "accountTo":"Account to",
        "date":"Validation date",
        "dueDate": "Investment date",
        "dateNewOrder": "Investment date",
        "amountPlaceholder": "eg. 1,000.00",
        "accountType":"Account type",
        "fundName":"Fund name",
        "fundNameReplace": "Fund for replacement",
        "comment":"Comment",

        "rulesCheckbox":"I accept General information and conditions for providing investment services and activities and ancillary services",
        "bellowComment":"The applicant confirms to be familiar with ",
        "bellowComment2_link1": "General information and conditions for providing investment services and activities and ancillary services",
        "bellowComment2_text": " as well as ",
        "bellowComment2_link2": "brochure, rules and key information on fund investors",
        "bellowComment3": " and fully accepts all documents.",

        "disclaimerText1": "The order to redeem or issue units, when committed through an intermediary (Bank) constitutes an order within the meaning of the Capital Market Act and the Bank provides the service of receipt and transfer of orders in relation to the unit of the UCITS sub-fund to the applicant (Client, You) in accordance with the conditions for providing that service enclosed to this order and on the website of the Bank.\n" +
          "\n" +
          "The Bank is obliged to prescribe and apply appropriate measures and procedures which enable prompt, up-to-date and correct execution of the client’s orders as well as other measures prescribed by the Capital Market Act. Therefore, the Bank has rendered an Order execution policy contained in the document enclosed to this order and on the website of the Bank.\n" +
          "\n" +
          "The Bank is obliged to categorize its Clients in accordance with their knowledge, experience, financial situation and investment goals into retail or professional clients.",
        "disclaimerText2": "Based on the available information, we have classified you as a retail client.",
        "disclaimerText3": "The Order execution policy applies equally to all types of Clients and although you are entitled to a different classification, it would not affect any of your rights or obligations.\n" +
          "\n" +
          "Before providing an investment service, we are obliged to inform you on certain information that in general include information about the Bank, financial instruments, types and risks of investing in financial instruments, information regarding the safeguarding of financial instruments or funds of the Client including the risks arising from the omnibus holding of assets. All the provided information as well as the information on handling the Client’s complaints can be found in the document enclosed to this order and on the website of the Bank.\n" +
          "\n" +
          "The information on the costs and related charges arising from the service or transaction shall be provided to the Client in the document enclosed to this order and on the website of the Bank.\n" +
          "\n" +
          "By signing this order, the Client confirms that he has contacted the Bank on its own initiative with a view to order the issuance/redemption of the units and at the time indicated on this order and that it represents a complete communication between the Bank and the Client and he is informed that all the communication that has led or can lead to a transaction, i.e. the receipt, transfer and execution of the order shall be recorded or registered. The Client agrees that a copy of this order constitutes a complete record of the Bank and Client's communication, which will be available on request for a period of five years and for a period of seven years at the request of the competent authority.\n" +
          "\n" +
          "If the Client's email address is known and the method of communication is selected, by signing this order the Client confirms that he has regular internet access and is offered a choice between information on paper and other durable media and gives explicit consent to the Bank that all the prescribed information shall be delivered through the website of the Bank.\n" +
          "\n" +
          "By signing this order, the Client gives explicit consent to receive all the required information relating to: the Bank and its customer service; financial instruments and related risks; the safeguarding of the Client's financial instruments and funds; the costs and related charges arising from the service or transaction as well as any modification of the aforementioned documents and information, through a durable medium on the website of the Bank.\n" +
          "\n" +
          "By signing this order, the Client gives explicit consent to the Order execution policy and agrees that any modification of that policy shall be published via the website of the Bank.\n",
        "disclaimerText4": "",
        "disclaimerText5": "",
        "disclaimerText6": "",
        "disclaimerText7": "",
        "disclaimerText8": "",
        "disclaimerText9": "",

        "investor":"Investor",
        "loan":"Loan",
        "buyingFond": "Buying fond",
        "sellingFond": "Selling fond",
        "replacementFond": "Replacement fond",
        "note": "Comment",
        "back": "Back",
        "amountMinAmountError": "Incorrect investment amount.",
        "amountMinAmountError2" : "Minimum amount of investment for the selected fund is 100.00 Kn.",
        "buyInvestmentPopup1": "This transaction opens a new account in the fund {{invFund}}",
        "buyInvestmentPopup2": "Do you want to continue?",
        "add":"Potvrdi",
        "alertInfoNoFund": "There is no available fund for selected account.",
        "amountTypeAllShares": "All shares",
        "amountTypeShareValue": "Share value",
        "amountTypeShareQuantity": "Share quantity",
        "accounts": "Accounts",
        "info": "Accuracy of data in regard to InterCapital fund share prices lies under the responsibility of InterCapital Asset Management d.o.o.",
        "info1": "Please contact InterCapital Asset Management d.o.o. - line (01) 5540 821 for additional information."
      },
      investOverview: {
        "totalShareValue": "Total share value",
        "shareQuantity": "Share quantity",
        "buyStocks": "Buy stocks",
        "sellStocks": "Sell stocks",
        "replaceStocks": "Replace stocks",
        "transactions": "Transactions",
        "orders": "Orders",
        "sharedAccount": "Shared account:",
        "invAmount": "Investment amount:",
        "invDate": "investment date:",
        "buyAccount": "Buy account:",
        "fundName": "Fund name:",
        "noTransactionData": "No transaction data.",
        "noOrderData": "No order data.",
        "shareAccount": "Share account",
        "dateOfEntry": "Date of entry",
        "shareValue": "Share value",
        "shareQuant": "Share quantity",
        "chargeValue": "Charge value",
        "fundType": "Fund type",
        "sharePrice": "Unit price",
        "copyOrder": "Copy order",
        "editOrder": "Edit order",
        "deleteOrder": "Delete order",
        "signOrder": "Sign order",
        "status_created": "Created",
        "status_recalled": "Recalled",
        "status_processed": "Processed",
        "status_rejected": "Rejected",
        "status_closed": "Closed",
        "status_signed": "Signed",
        "status_inProcess": "In Process",
        "status_canceled": "Canceled"
      },
      investmentsList: {
        "invOverview": "Investments overview",
        "shareQuantity": "Share quantity"
      },
      accessInvestment: {
        "accession": "Investment fund application form",
        "nameSurname": "Name and surname",
        "address": "Permanent residence address",
        "identificationNumber": "Number of identification documents",
        "issuer": "Issuer",
        "phone": "Phone",
        "emailNotification": "Slanje obavjesti E-mailom",
        "bank": "Bank",
        "paymentAccNumber": "Account number",
        "plannedYearInvestment": "Planned annual investments",
        "fundsInvestment": "Investment funds are realised from",
        "msg1": "By signing this Questionnaire I am confirming that all data in it are accurate and I am authorising the company InterCapital Invest d.d. (further: Company) to check the data mentioned here." +
        " I will personally inform you about any changes of the afore mentioned data and details.",
        "msg2": "By signing this Questionnaire I am authorising the Company to undertake all actions related to the processing and exchange of my personal data stated in the Questionnaire, including the information about the personal ID. These actions include collecting, storing, recording, organising, access and transfer of personal data for the purpose of conducting regular business operations of the Company.",
        "employee": "Employee",
        "firm": "Entrepreneur",
        "unemployed": "Unemployed",
        "retired": "Retired",
        "student": "Student",
        "rest": "Other",
        "personalStatus": "Personal status",
        "employer": "Type of employer",
        "privateSector": "Private sector",
        "civileService": "Civile service",
        "stateAuth": "State/regional authorities",
        "emplTrade": "State-owned companies",
        "vocation": "Vocation",
        "profession": "Profession",
        "invEmployment": "Employment",
        "invfreelancing": "Freelancing",
        "invLegal": "Ownership and ownership rights",
        "capital": "Capital",
        "invInsured": "Insurance",
        "inv1": "To 25.000,00 EUR",
        "inv2": "To 50.000,00 EUR",
        "inv3": "To 100.000,00 EUR",
        "inv4": "More than 100.000,00 EUR",
        "accountNumberInput": "Account number",
        "add": "Add"
      },
      salesReplacementTranslation: {
        title: "Business with investment funds"
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
