(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:MasterCardNewCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('MasterCardNewCtrl', MasterCardNewCtrl);

  function MasterCardNewCtrl($q, $log, $state, $rootScope, AccountsService, HelperService, UserSettingsService, AlertService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'MasterCardNewCtrl';
    vm.tempObj = {};
    vm.selectedAccount = {};
    vm.accountList = [];
    vm.cardTypes = [];
    vm.paymentDays = [];
    vm.currencySymbol = 'EUR';
    vm.formSubmitted = false;
    vm.step = 1;
    vm.iAmAware = false;

    vm.insertedOrder = {};

    function loadCardTypes() {
      var params = null;
      return AccountsService.newCreditCardOrderCardTypeType(params)
        .then(function (response) {
          vm.cardTypes = response.result;
          vm.tempObj.cardType = vm.cardTypes[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }
    function loadPaymentDays() {
      var params = null;
      return AccountsService.newCreditCardOrderTearDateType(params)
        .then(function (response) {
          vm.paymentDays = response.result;

          _.each(vm.paymentDays,function (item) {
            console.log('Int parse',item.description.replace('.',''));
            item.description = parseInt(item.description.replace('.','')); //15.11.2016 backend doesnt sort numbers in string format properly :(
          });
          vm.paymentDays.sort(function (a, b) {
            return a.description - b.description;
          });

          vm.tempObj.paymentDay = vm.paymentDays[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function init() {
      var promises = [
        AccountsService.getPage({accountTypeGroupList: 'CurrentAccount', isOwn: true}),
        UserSettingsService.getPage(),
        loadCardTypes(),
        loadPaymentDays()
      ];

      $q.all(promises).then(function(responses){
        //vm.accountList = responses[0].result;
        setAccountsToDefaultCurrency(responses[0].result);
        vm.tempObj.userSettings = responses[1].result[0];

      }).catch(function(error){
        NotificationService.showMessage(error, 'error');
      });

    }
    function setAccountsToDefaultCurrency(list) {
      if(list){
        var accs = _.filter(list, function (item) {
          return item.currencySymbol == $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
        });
        if (accs)
          vm.accountList = accs;
          vm.selectedAccount = vm.accountList[0];
      }
      else{
        vm.accountList = [];
      }
    }

    vm.selectAccount = function (account) {
      if (!account) return;
      vm.selectedAccount = account;
    };

    function createObject() {
      return {
        creditCardType:{
          id:vm.tempObj.cardType.id,
          description: vm.tempObj.cardType.description
        },
        phoneNumber:vm.tempObj.userSettings.gsm,
        email:vm.tempObj.userSettings.email,
        taxId:vm.tempObj.userSettings.taxId,
        nameAndSurname:vm.tempObj.userSettings.firstName+ " "+ vm.tempObj.userSettings.lastName,
        limitAmount:vm.tempObj.amount,
        orderTearDate:{
          id: vm.tempObj.paymentDay.id,
          description:vm.tempObj.paymentDay.description
        },
        account: vm.selectedAccount.iban,
        note: " "
      }
    }


    vm.validateMasterCardNew = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if (!vm.masterCardForm.$valid) {
        return;
      }
      vm.insertedOrder = createObject();

      vm.nextButtonPressed = true;
      AccountsService.validateNewCreditCardOrder(vm.insertedOrder)
        .then(function (response) {
          vm.insertedOrder = angular.extend(response);
          vm.step = 2;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function() {
        vm.nextButtonPressed = false;
      });
    };
    vm.confirmMasterCardNew = function () {
      if (vm.confirmButtonPressed) return;

      vm.confirmButtonPressed = true;
      AccountsService.newCreditCardOrder(vm.insertedOrder)
        .then(function (response) {
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              $state.go('accountsOverview');
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function() {
        vm.confirmButtonPressed = false;
      });
    };
    vm.editMasterCardNew = function () {
      vm.step = 1;
      vm.iAmAware = false;
    };



    init();
  }
}());
