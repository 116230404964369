<div class="statementModal logout-modal-frame"  >
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div  style="color: white; padding-top: 50px;">

        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">

      <div class="col-xs-12">
        <div class="logout-modal-header" >{{changePaymentDateModal.obj.msg}}
          <span ng-if="!changePaymentDateModal.obj.isSepaAndUrgent && changePaymentDateModal.obj.domestic && changePaymentDateModal.obj.type == 'ToAccountNumber' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            {{'domesticPayment.secondNotInSEPAMessage' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="changePaymentDateModal.obj.type == 'ToAccountNumber' && !changePaymentDateModal.obj.isSepaAndUrgent" class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close('yes')" translate="domesticPayment.changePaymentModal.yes">Yes</button>
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="domesticPayment.changePaymentModal.no">No</button>
  </div>

  <div ng-if="changePaymentDateModal.obj.type == 'ToAccountNumber' && changePaymentDateModal.obj.isSepaAndUrgent" class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="domesticPayment.changePaymentModal.ok">Ok</button>
  </div>
  <div ng-if="changePaymentDateModal.obj.type == 'UpdateOldDate'" class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close('yes')" translate="domesticPayment.changePaymentModal.confirm">Yes</button>
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="domesticPayment.changePaymentModal.cancel">No</button>
  </div>
  <div ng-if="changePaymentDateModal.obj.type == 'Info'" class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="domesticPayment.changePaymentModal.close">Close</button>
  </div>
</div>
