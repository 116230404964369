(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name investments.controller:InvestmentsListCtrl
     *
     * @description
     *
     */
    angular
            .module('investments')
            .controller('InvestmentsListCtrl', InvestmentsListCtrl);

    function InvestmentsListCtrl($log, $state, FundsService, HelperService, NotificationService) {
        var vm = this;
        vm.fundsList = [];
        vm.fundFilter = {
            page: 0,
            pageSize: 1000
        };

        var init = function () {
            //if(userHaveFunds){
            //    loadInvestmentAccounts();
            //} else {
            //    $state.go('investments.accession');
            //}
          loadInvestmentAccounts();
        };
        var loadInvestmentAccounts = function () {
            FundsService.getPage()
                    .then(function (response) {
                        vm.fundsList = response.result;
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
        };

        init();
    }
}());
