(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name core.controller:CoreCtrl
   *
   * @description
   *
   */
  angular
    .module('core')
    .controller('CoreCtrl', CoreCtrl);

  function CoreCtrl() {
    var vm = this;
    vm.ctrlName = 'CoreCtrl';
  }
}());
