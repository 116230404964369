<section id="main" ng-if="vm.pageLoaded">

  <div class="container-fluid clearfix m-b-40">
    <div class="row">
      <div class="col-md-12">
        <h3 translate="sepa.refusalAcceptedTitle">SEPA REFUSAL</h3>
      </div>
    </div>

    <!-- ============= -->
    <!-- START FORM    -->
    <!-- ============= -->
    <!-- ======= -->
    <!-- START -->
    <!-- ======= -->
    <div ng-form name="vm.refusalForm"
         ng-if="vm.step == 'start'"
         class="bg-white p-20 m-t-15 clearfix"
         novalidate>
      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.payerAccount">
              Račun platitelja
            </label>
            <input type="text" class="form-control input-field"
                   id="fromAccount"
                   name="fromAccount"
                   ng-value="vm.model.senderAccount"
                   readonly/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.amount">
              Iznos
            </label>
            <input type="text" class="form-control input-field"
                   id="amount"
                   name="amount"
                   ng-value="(vm.model.amount | currency: '') + ' EUR'"
                   readonly/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.transactionId">
              ID naloga
            </label>
            <input type="text" name="transactionId" id=transactionIdreceiverId"
                   class="input-field form-control"
                   ng-value="vm.model.transactionId"
                   readonly>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverName">
              Naziv i sjedište primatelja plaćanja
            </label>
            <input type="text" class="form-control input-field"
                   id="receiverName"
                   name="receiverName"
                   ng-value="vm.model.receiverName"
                   readonly/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverAccount">
              Račun primatelja plaćanja
            </label>
            <input type="text" class="form-control input-field"
                   id="receiverAccount"
                   name="receiverAccount"
                   ng-value="vm.model.receiverAccount"
                   readonly/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="domp-form-label" translate="sepa.receiverNumber">
              Detalji plaćanja (poziv na broj primatelja)
            </label>
            <input type="text" class="form-control input-field"
                   id="receiverNumber"
                   name="receiverNumber"
                   ng-value="vm.model.receiverNumber"
                   readonly/>
          </div>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-md-12">
          <button class="btn btn-primary"
                  type="button"
                  translate="sepa.cancelBtnLabel"
                  ui-sref="sepa.transactions">
          </button>

          <button class="btn btn-default pull-right"
                  type="submit"
                  translate="sepa.confirm"
                  ng-click="vm.submit(vm.refusalForm);"
                  ng-disabled="vm.submitButtonDisabled">
          </button>
        </div>
      </div>
    </div>

    <!-- ============= -->
    <!-- SIGN FORM   -->
    <!-- ============= -->
    <div class="bg-white p-20 m-t-15 clearfix" ng-if="vm.step == 'sign'">
      <addiko-mtoken-sign
        show-cancel="false"
        placeholder="vm.qrChallenge ? 'signPayments.challengeDescriptionQR' : 'signPayments.challengeDescription'" 
        qr-challenge="vm.qrChallenge" show-challenge="vm.showChallenge" 
        challenge1="vm.challengeFirstCode" challenge2="vm.challengeSecondCode"
        action-disabled="vm.confirmSignBtnDisabled"></addiko-mtoken-sign>
     
    </div>
  </div>
</section>
