(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name viber.controller:ViberCtrl
   *
   * @description
   *
   */
  angular
    .module('viber')
    .controller('ViberCtrl', ViberCtrl);

  function ViberCtrl($rootScope) {
    var vm = this;
    vm.agree = false;
    vm.bankAddition = "";
    vm.conditionLink = "";
    vm.agree2 = true; //default value should be true for countries different from CRO because button for activate needs this value

    if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh') {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.BiHLocation == "Banja Luka") {
        vm.bankAddition = "a.d.";
        vm.conditionLink = "https://www.addiko-rs.ba/sites/addiko.rs.ba/files/content/file/file_download/abrs_oup_e-banking_m_banking_sms_viber_20170215.pdf";
      } else {
        vm.bankAddition = "d.d.";
        vm.conditionLink = "https://www.addiko-fbih.ba/sites/addiko.ba/files/content/file/file_download/abfed_oup_e-banking_m_banking_sms_20170217.pdf";
      }
    } else if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sr') {
      // vm.conditionLink = "https://www.addiko-fbih.ba/sites/addiko.ba/files/content/file/file_download/abfed_oup_e-banking_m_banking_sms_20170217.pdf";
      vm.conditionLink = "https://www.addiko.rs/pravila-uslovi-poslovanja/";
    } else if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne') {
      vm.conditionLink = "https://www.addiko.me/static/uploads/opsti-uslovi-koriscenja-usluga-elektronskog-bankarstva-za-fizicka-lica-v.3.00.pdf";

    } else if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
      vm.conditionLink = "https://www.addiko.hr/static/uploads/Addiko_Opci-uvjeti-koristenja-usluga-direktnog-bankarstva-za-građane_12.09.2017.pdf";
      vm.agree2 = false; //for CRO initialize as false
    } else if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
      vm.conditionLink = "https://www.addiko.si/static/uploads/2a-Splo%C5%A1ni-pogoji-za-uporabo-storitev-spletne-banke-Addiko-EBank-veljavnost-od-22.02.2019_FINAL.pdf";
    }
    vm.checkViberActivation = function () {
      return $rootScope.APP_GLOBAL_SETTINGS.defaultData.ViberSmsActivation;
    };


    function init() {
      console.log("Initiate viber controller");
    };
    init();

  }
}());
