(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name eInvoices.factory:EinvoiceSubscriptionsService
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .factory('EinvoiceSubscriptionsService', EinvoiceSubscriptionsService);

  function EinvoiceSubscriptionsService($rootScope, $http, $q, EndpointsService, SessionService) {
    var EinvoiceSubscriptionsServiceBase = {};
    EinvoiceSubscriptionsServiceBase.someValue = 'EinvoiceSubscriptionsService';
    EinvoiceSubscriptionsServiceBase.someMethod = function () {
      return 'EinvoiceSubscriptionsService';
    };

    EinvoiceSubscriptionsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.eInvoiceSubscriptions, {
          params: params
        }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EinvoiceSubscriptionsServiceBase.getById = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.eInvoiceSubscriptions_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    function insert(subscriptionObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          SessionService.saveStorageObject(subscriptionObject, 'einvoice');
          $http.post(response.links.eInvoiceSubscriptions, subscriptionObject)
            .then(function (data) {
              $rootScope.$broadcast("eInvoiceSubAdded");
              SessionService.removeStorageObject();
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    EinvoiceSubscriptionsServiceBase.insertSubscriptionRequest = function (subscriptionObject) {
      return insert(subscriptionObject);
    };

    EinvoiceSubscriptionsServiceBase.updateInvoiceSubscription = function (id, data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.eInvoiceSubscriptions_id.replace('{id}', id), data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EinvoiceSubscriptionsServiceBase.updateInvoiceEmail = function (email) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.eInvoiceSubscriptions_updateInvoiceEmail, email)
          .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      },
      function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    EinvoiceSubscriptionsServiceBase.validateSubscriptionRequest = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.eInvoiceSubscriptions_validate, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return EinvoiceSubscriptionsServiceBase;
  }
}());
