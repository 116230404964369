(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      orders: {
        "accounts": "Accounts",
        "cards": "Cards",
        "savingsDeposits": "Savings and deposits",
        "security": "Security",
        "loans": "Loans",
        "payments": "Payments",
        "accountOverdraft": "Account overdraft",
        "largeAmount": "Large amount of cash withdrawal",
        "atmWithdrawal": "ATM withdrawal limit change",
        "orderMastercard": "Order Mastercard payment card",
        "changeMastercard": "Change Mastercard limit",
        "makeTermDeposit": "Make a term deposit",
        "cancelAutomatic": "Cancel automatic extensions of term deposit",
        "openSavings": "Open savings account",
        "orderSoftToken": "Order a soft token",
        "getExpressLoan": "Get express loan possibility information",
        "authorizationOrders": "Autorization for standing order",
        "cancelAuthorizationOrders": "Cancel authorization for standing order",
        "goBackToOrders": "Go back to orders",
        "subscribeEInvoice": "Subscribe to a new eInvoice or cancel an existing subscription",
        "beneficiaryName": "Recipient name",
        "beneficiaryNameRequired": "Recipient name is required!",
        "beneficiaryIBAN": "Account number",
        "beneficiaryIBANforSavings":"Addiko EBank user's savings account",
        "descriptionRequired": "Description is required!",
        "dateBegin": "Date of beginning",
        "dateCancel": "Date of cancellation",
        "description": "Order description",
        "standingOrders": "Your standing orders",
        "complete": "Standing order complete",
        "completeText": "Request for opening of standing order is successfully sent to the bank.",
        "completeCancelText": "Request for cancellation of standing order is successfully sent to the bank.",
        "makeNew": "Make new standing order",
        "title": "Standing orders",
        "titleConfirmation": "Standing order confirmation",
        "note": "Note",
        "statusOpen": "Open",
        "dateRequired": "Date is required!",
        "note1": "A standing order for the transfer of funds to any personal bank account or your savings account or savings account on which you are authorized must be requested at least 15 days prior to the desired date of payment, otherwise the Bank cannot ensure that the order will be executed on the payment date.",
        "note2": "Please, check again the correctness of the entered data prior to the final confirmation of the order. Orders which contain false information will not be executed.",
        "note3": "The actual execution of the order for permanent authorization can be checked in the monthly statement in the following month. Addiko Bank d.d. will charge a fee for the execution of payments through standing orders according to the valid Price list of services of Addiko Bank d.d.",
        "note1Slo":"A standing order for the transfer of funds to any personal bank account or your savings account or savings account on which you are authorized must be requested at least 15 days prior to the desired date of payment. Addiko Bank d.d. will charge a fee for the execution of payments through standing orders according to the valid ",
        "note2SloLink":"Price list of services of Addiko Bank d.d.",
        "cancel": "Cancel",
        "edit": "Edit",
        "Opened": "Opened",
        "Signed": "Signed",
        "paymentDayOfEachMonth": "Payment day of each month",
        "nonWorkingDayMsg": "or the next working day if this day is Saturday, Sunday or a holiday or another non-working day.",
        "address": "Receiver's address",
        "receviersAddressRequired": "Receiver's address is required!",
        "purpose": "Purpose",
        "next": "Next",
        "back": "Back",
        "maturityDateChangedConfirm": "Please confirm the changed date by selecting the checkbox.",
        "maturityDateChangedErr": "Maturity date is a non-working day and has been changed. Please confirm the change.",
        "maturityDateNonWorkingErr": "Maturity date is a non-working day.",
        "successOrder": "Order was successfully submitted.",
        "agreeTerms":"I agree that if, on the agreed day i will not provide a sufficient balance on the account, the bank is not required to execute orders under this mandate.",
        "cancelAuthorisation": "Cancellation of authorisation for standing order",
        "permanentAthorisation": "Authorisation for standing order",
        "Submitted": "Submitted",
        "Closed": "Closed",
        "InProcess": "In process",
        "Canceled": "Canceled",
        "Executed": "Executed",
        "Rejected": "Rejected",
        "InError": "In error",
        "PartialySigned": "Partialy signed",
        "ForProcessing": "For processing",
        "FailureAfterComplaint": "Failure after complaint",
        "SuccessAfterComplaint": "Success after complaint",
        "Realised": "Realised",
        "Unrealised": "Unrealised",
        "Cancelled": "Cancelled",
        "Entered": "Created",
        "ReceivedNotExecuted": "Received/Not executed",
        "Processed": "Processed",
        types: {
          "trr":"Recipient's bank account",
          "hypo":"Addiko EBank user's savings account"
        }
      },
      termDeposit: {
        "termDepositHeader": "Open term deposit",
        "nameInputLabel": "Name and surname",
        "addressInputLabel": "Address",
        "cityInputLabel": "City",
        "debitAccountSelectLabel": "Debit account",
        "typeSelectLabel": "Term deposit type",
        "interestRateOverviewLabel": "Interest rate overview",
        "realRate": "Real interest rate",
        "referenceRate": "Reference interest rate",
        "amountInputLabel": "Amount",
        "baseInterestRateInputLabel": "Base interest rate",
        "marginInputLabel": "Margin",
        "stimulativeInterestRateInputLabel": "Stimulative interest rate",
        "totalInterestRateInputLabel": "Total interest rate",
        "maturityInMonthsSelectLabel": "Term in months",
        "expiryDateInputLabel": "Expiry date",
        "interestRatePayoutTypeSelectLabel": "Type of interest rate payout",
        "informativeCalculationLinkLabel": "Informative calculation of effective interest rate",
        "automaticProlongationRadioOptionLabel": "Automatic renewal",
        "automaticProlongationYesOptionLabel": "Yes",
        "automaticProlongationNoOptionLabel": "No",
        "accountForMaturityInputLabel": "Account for maturity of interest and capital",
        "contractDateInputLabel": "Contract date",
        "contractDraftLinkLabel": "Contract draft for term deposit",
        "generalConditionsLinkLabel1": "I accept general conditions for term deposit",
        "generalConditionsLinkLabel2": "I accept general conditions for secured deposit",
        "confirmBtnLabel": "Confirm",
        "cancelBtnLabel": "Cancel",
        "editActionLabel": "Edit",
        "cancelActionLabel": "Cancel",
        "signActionLabel": "Sign",
        "successMsg": "Success!",
        "errorMsg": "Error!",
        "minAmountLabel": "Minimal amount is",
        "month": "months",
        "oneMonth": "month",
        "signTitle": "Term deposit sign",
        "request": "request",
        "newDepositOrder": "New deposit order",
        "assignmentOfInterestAnnual": "Annual reporting",
        "assignmentOfInterestAfter": "Report after maturity",
        "interestPayoutMonthly": "Monthly",
        "interestPayoutAfterMaturity": "After maturity",
        "earlyTermination": "Deposit order eary termination",
        "hrDoc1": "Draft information form before concluding a deposit agreement",
        "hrDocNew": "Informacije prije sklapanja ugovora o depozitu",
        "hrDoc2": "Izvadak iz odluke o naknadama banke",
        "hrDoc3": "Excerpt from the decision on the amount of deposit interest rates for individuals",
        "hrDoc4": "General terms and conditions",
        "hrDoc5": "General terms and conditions of time deposit / savings deposit",
        "hrDoc6": "Draft information about deposit security",
        "hrDoc6b": "Draft term of savings deposit agreement",
        "hrDoc7": "Draft information form before concluding a deposit agreement",
        "hrDocRest": "See the other documentation related to contracting the booklet ",
        "hrDocRestLink": "here.",
        "otherDocs1": "See the other documentation related to contracting the booklet ",
        "otherDocs2": "here",
        "depositType": "Deposit type",
        "assignmentOfInterest": "Assignment of interest",
        "letnaAssignmentOfInterest": "Assignment of interest",
        "interestPayout": "Interest payout",
        "termDepositDuration": "Term deposit duration",
        "durationInDays": "days",
        "durationOr": "or",
        "durationInMonths": " months",
        "maturityDate": "Maturity date",
        "executionDate": "Execution date",
        "minDepDurationErr": "Minimum deposit duration is ",
        "maxDepDurationErr": "Maximum deposit duration is ",
        "minRastociDepositDuration": "Minimum duration is 31 days.",
        "maxRastociDepositDuration": "Maximum duration is 35 days.",
        "durationRastociDepozitMsg": "Duration must be between 31 and 35 days.",
        "durationZlataJesenMsg": "Duration must be between {{min}} and {{max}} days.",
        "days": "days.",
        "termsAndConditions": "I certify that I am aware of",
        "termsAndConditions1": "General terms and conditions of deposits",
        "termsAndConditions2": "and I agree with them. I am also aware of type and current interest rate conditions of selected product.",
        "taxationOfInterestTitle": "Taxation of interest",
        "taxationOfInterestMsg": "In accordance with the Personal Income Tax Act and the Tax Procedure Act interest exceeding the total amount of 1.000 EUR shall be taken into account when calculating the tax base. It is recommended that in the case of long-term deposits you opt for an annual reporting of interest since it reduces the chance that at the end of the saving period your interest would exceed 1.000 EUR. At the conclusion of the long term deposit contract a Notice on the application of the tax base according to Article 84 of the Personal Income Tax Act will be issued.",
        "headerLink1": "Basic information on term deposits",
        "headerLink2": "Applicable interest rates",
        "headerLink3": "Bank guarantee scheme",
        "wrongDepositCurrency": "Deposit of the chosen type is not available for selected currency.",
        "wrongDepositDuration": "This duration is not possible for selected deposit.",
        "wrongDepositAmount": "This amount is not valid for selected deposit.",
        "wrongMinDepositAmount": "Minimum amount is ",
        "wrongMaxDepositAmount": "Maximum amount is ",
        "makeTermDepositTimelineTitle": "Make term deposit",
        "vrstaDepozita": "Deposit type",
        "termDepositStatus": {
          "entered":"Waiting to be processed",
          "realised": "Realised",
          "unrealised": "Unrealised",
          "cancelled": "Cancelled",
          "receivednotexecuted":"In processing"
        }
      },
      cancelDeposit: {
        "cancelDepositHeader": "Term deposit",
        "cancelDepositSubheader": "Request for premature cancellation of term deposit",
        "capitalAmountLabel": "Capital amount:",
        "approvedInteresLabel": "Approved interest:",
        "approvedInteresAmountLabel": "Approved interest in the amount of:",
        "chargesLabel": "Charges for premature cancellation of term deposit:",
        "totalAmountLabel": "Total amount for transfer:",
        "accountForFundLabel": "Account for fund payout:",
        "confirmBtn": "Confirm",
        "cancelBtn": "Cancel",
        "successInfoLabel1": "Request for premature cancellation of term deposit",
        "successInfoLabel2": "was signed successfully and sent to bank.",
        "requestBtn": "Request overview",
      },
      depositDetails: {
        "contractDate": "Contracting date",
        "depositType": "Term deposit type",
        "account": "Maturity account",
        "note": "Note",
        "contract": "Contract",
        "payoutType": "Payout type",
        "domesticType": "Domestic term deposit",
        "foreignType": "Foreign term deposit",
        "termsAndConditions": "Terms and conditions"

      },
      orderDetails: {
        "name": "Recipient name",
        "payeeAccount": "Recipient account",
        "payeeAddress": "Recipient address",
        "amount": "Amount",
        "orderId": "Order ID",
        "orderTypeId": "Order type ID",
        "orderTypeDesc": "Order description",
        "dateCreated": "Date of creation",
        "firstPaymentDate": "First payment date",
        "lastPaymentDate": "Last payment date",
        "close": "Cancel",
        "delete": "Delete",
        "closeMsg": "Order successfuly closed",
        "deleteMsg": "Order successfuly deleted",
        "purpose": "Purpose"
      },
      savingsOrder: {
        "title": "Open savings account",
        "headerLink1": "Basic information on savings account",
        "headerLink2": "Basic information on Addiko Plus savings",
        "headerLink3": "Applicable interest rates",
        "headerLink4": "Bank guarantee scheme",
        "headerLink5": "General terms and conditions of Addiko savings account",
        "savingsType": "Savings type",
        "firstPayment": "First payment",
        "lastPayment": "Last payment",
        "orderPaymentDay": "Standing order payment day",
        "fromAccount": "From account",
        "amount": "Monthly deposit amount",
        "duration": "Savings duration",
        "minSavingDuration": "Minimum savings duration is ",
        "maxSavingDuration": "Maximum savings duration is ",
        "orderpaymentDay": "Standing order payment day",
        "termsAndConditions": "I certify that I am aware of",
        "termsAndConditions1": " General terms and conditions of savings",
        "termsAndConditions2": " and I agree with them. I am also aware of type and current interest rate conditions of selected product.",
        "termsAndConditions3AddikoPlus": " I also agree that if there is not a sufficient balance on the account on the selected day for standing order, the bank is not required to execute my order.",
        "taxationOfInterestMsg": "In accordance with the Personal Income Tax Act and the Tax Procedure Act interest exceeding the total amount of 1.000 EUR shall be taken into account when calculating the tax base. It is recommended that in the case of long-term savings you opt for an annual reporting of interest since it reduces the chance that at the end of the saving period your interest would exceed 1.000 EUR. At the conclusion of the long term savings contract a Notice on the application of the tax base according to Article 84 of the Personal Income Tax Act will be issued.",
        "newSavingsOrder": "New savings order",
        "initialDepositAmount": "Initial amount",
        "monthlyDepositAmount": "Monthly deposit",
        "newSavingsOrderTimelineTitle": "Open Savings account",
        "dateOfEntry": "Date of entry",
        "executionDate": "Maturity date",
        "userNumber": "User number",
        "savingsCurrency": "Savings currency",
        "fromSAccount": "From account",
        "nameAndSurname": "Name and surname",
        "initialAmount": "Initial amount",
        "disbursementAccount": "Disbursement account",
        "receiveMonthlyStatementsVia": "Receive monthly statements via",
        "vpoType": "VPO type",
        "typeOfSaving": "Type of saving",
        "realInterestRate": "Real interest rate",
        "referenceInterestRate": "Reference interest rate",
        "currency": "Currency",
        "dateOfOpening": "Date of opening",
        "openStandingOrder": "Open standing order",
        "orderComment": "Order comment",
        "savingOrderName": "Savings type"
      },
      addNewExpressLoan: {
        "header": "Information on the possibility to obtain Express loan",
        "header_SLO": "Information on the possibility to obtain Addiko loan",
        "note1": "An EXPRESS loan is a cash loan without additional documentation. The Bank will consider your request in the next two working days. After your visit with a personal banker in a branch and the signing of the contract, the loan will be disbursed to your account.",
        "note2": "EXPRESS loan approval conditions are available in the",
        "note1_SLO": "An Addiko loan is a cash loan without additional documentation. The Bank will consider your request in the next two working days. After your visit with a personal banker in a branch and the signing of the contract, the loan will be disbursed to your account.",
        "note2_SLO": "Addiko loan approval conditions are available in the",
        "noteLink1": "Price list of services",
        "noteAnd": "and in",
        "noteLink2": "Interest rates act of Addiko bank d.d.",
        "cardType": "Duration (in months)",
        "account": "Account",
        "amount": "Amount",
        "paymentDay": "Settlement day",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "approvalDate": "Desired date of the approval",
        "paymentAccount": "Payment account",
        "addNewExpressLoan": "New loan"
      }
    };

    $translateProvider
      .translations('en', data);
  }
}());
