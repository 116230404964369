<div class="table-responsive hidden-xs">
  <table class="table table-transparent">
    <tbody>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label" translate="termDeposit.vrstaDepozita"></span>
      </td>
      <td>
        <span class="po-value-label"> {{term.depositType}} </span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="depositDetails.contractDate"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.processedDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label" translate="termDeposit.executionDate"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.acceptedDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label" translate="termDeposit.maturityDate"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.validToDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label" translate="termDeposit.totalInterestRateInputLabel"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.interestRate  | formatDecimal : 4 : true : 3}} %</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="depositDetails.depositType"></span>
      </td>
      <td >
        <span ng-if="term.currencySymbol == 'EUR'" class="po-value-label"
              translate="depositDetails.domesticType"></span>
        <span ng-if="term.currencySymbol != 'EUR'" class="po-value-label" translate="depositDetails.foreignType"></span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'">
      <td>
        <span class="po-desc-label" translate="depositDetails.payoutType"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.payoutTypeTranslate | translate}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label" translate="depositDetails.account"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.accountNumber}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">
      <td>
        <span class="po-desc-label" translate="accounts.annualAssignmentOfInterests"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.assignmentOfInterest}}</span>
      </td>
    </tr>

    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && term.allowedActionList.itemList.length">
      <tr ng-repeat-start="item in term.allowedActionList.itemList" ng-if="item.methodName === 'DownloadLinkAllowed'">
        <td>
          <span class="po-desc-label" translate="depositDetails.contract"></span>
        </td>
        <td>
            <span class="po-value-label">
              <button class="btn btn-default" ng-click="termDeposit.downloadContract(term.id)" translate="messages.downloadAttachment">Download</button>
            </span>
        </td>
      </tr>

      <tr ng-repeat-end ng-if="item.methodName === 'DownloadTermsLinkAllowed'">
        <td>
          <span class="po-desc-label" translate="depositDetails.termsAndConditions"></span>
        </td>
        <td>
          <span class="po-value-label">
            <button class="btn btn-default" ng-click="termDeposit.downloadTermsAndConditions(term.id)" translate="messages.downloadAttachment">Download</button>
          </span>
        </td>
      </tr>
    </div>

    <tr ng-if="term.referenceNumber">
      <td>
        <span class="po-desc-label" translate="depositDetails.contract"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.referenceNumber}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && term.message && (term.status == 'Unrealised' || term.status == 'Cancelled')">
      <td>
        <span class="po-desc-label" translate="depositDetails.note"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.message}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && term.orderTypeId">
      <td>
        <span class="po-desc-label" translate="depositDetails.contract"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.orderTypeId}}</span>
      </td>
    </tr>

    <!-- HR documents START -->
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && term.orderTypeId">
      <td>
        <span class="po-desc-label" translate="depositDetails.contract"></span>
      </td>
      <td>
        <span class="po-value-label">{{term.orderTypeId}}</span>
      </td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <td>
        <span class="po-desc-label" translate="ebook.documents"></span>
      </td>
      <td>
        <span class="po-value-label">
          <div class="row m-r-0">
            <a data-ng-click="termDeposit.manageDocumentDownload('Contract', term)" style="text-decoration: underline; cursor: pointer;" translate="termDeposit.hrDoc6">
            </a>
          </div>
        </span>
        <span class="po-value-label">
          <div class="row m-r-0">
            <a data-ng-click="termDeposit.manageDocumentDownload('PreContractInfo', term)" style="text-decoration: underline; cursor: pointer;" translate="termDeposit.hrDoc7">
            </a>
          </div>
        </span>
      </td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
      <td colspan="2">
        <span class="po-desc-label">
          <div class="row m-l-0 text-left">
            <span translate="termDeposit.otherDocs1">docs desc</span>
            <span>&nbsp;</span>
            <a href="https://www.addiko.hr/gradanstvo/opci-uvjeti-poslovanja-naknade-gradanstvo/" target="_blank" style="text-decoration: underline;" translate="termDeposit.otherDocs2">link</a>
          </div>
        </span>
      </td>
    </tr>
    <!-- HR documents END -->

    </tbody>
  </table>
</div>
<!-- MOBILE -->
<div class="table-responsive visible-xs">
  <table class="table table-transparent f-10">
    <tbody>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="termDeposit.vrstaDepozita"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.depositType}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.contractDate"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.processedDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="termDeposit.executionDate"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.acceptedDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="termDeposit.maturityDate"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.validToDate | date: 'shortDate' : '+0200'}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="termDeposit.totalInterestRateInputLabel"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.interestRate | currency:""}} %</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="accounts.annualAssignmentOfInterests"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.assignmentOfInterest}}</span>
      </td>
    </tr>
    <tr ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.depositType"></span>
      </td>
      <td ng-switch-default>
        <span ng-if="term.currencySymbol === 'EUR'" class="po-value-label-xs"
              translate="depositDetails.domesticType"></span>
        <span ng-if="term.currencySymbol != 'EUR'" class="po-value-label-xs"
              translate="depositDetails.foreignType"></span>
      </td>
    </tr>

    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'">
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.payoutType"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.payoutTypeTranslate | translate}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.account"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.accountNumber}}</span>
      </td>
    </tr>

    <tr ng-if="term.referenceNumber">
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.contract"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.referenceNumber}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && term.message && (term.status == 'Unrealised' || term.status == 'Cancelled')">
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.note"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.message}}</span>
      </td>
    </tr>
    <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && term.orderTypeId">
      <td>
        <span class="po-desc-label-xs" translate="depositDetails.contract"></span>
      </td>
      <td>
        <span class="po-value-label-xs">{{term.orderTypeId}}</span>
      </td>
    </tr>

    <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && term.allowedActionList.itemList.length">
      <tr ng-repeat-start="item in term.allowedActionList.itemList" ng-if="item.methodName === 'DownloadLinkAllowed'">
        <td>
          <span class="po-desc-label-xs" translate="depositDetails.contract"></span>
        </td>
        <td>
            <span class="po-value-label-xs">
              <button class="btn btn-default" ng-click="termDeposit.downloadContract(term.id)" translate="messages.downloadAttachment">Download</button>
            </span>
        </td>
      </tr>

      <tr ng-repeat-end ng-if="item.methodName === 'DownloadTermsLinkAllowed'">
        <td>
          <span class="po-desc-label-xs" translate="depositDetails.termsAndConditions"></span>
        </td>
        <td>
          <span class="po-value-label-xs">
            <button class="btn btn-default" ng-click="termDeposit.downloadTermsAndConditions(term.id)" translate="messages.downloadAttachment">Download</button>
          </span>
        </td>
      </tr>
    </div>

    </tbody>
  </table>
</div>
