(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name core.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('core')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($log, SessionService) {
    var vm = this;

    var _lastApiCallTemp = localStorage.getItem('LastApiCall');
    if (_lastApiCallTemp) {
      localStorage.removeItem('LastApiCall');
    }

    $log.debug("Login handler -- start");
    SessionService.init();
  }
}());
