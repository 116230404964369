(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      timeline: {
        "description": "Opis",
        "amount": "Iznos",
        "availableBalance": "Raspoloživi iznos",
        "accountNumber": "Broj računa",
        "bookingDate": "Datum knjiženja",
        "transactionDetails": "Detalji transakcije",
        "referenceType": "Tip reference",
        "group": "Grupa",
        "valueDate": "Datum valute",
        "einvoiceDetails": "Detalji eRačuna",
        "purpose": "Svrha",
        "creditorName": "Naziv platitelja/platioca",
        "tldmMessageDetails": "Detalji poruke:",
        "tldmMessageDate": "Datum kreiranja poruke:",
        "tldmMessageSubject": "Naslov poruke:",
        "debitorName":"Naziv primatelja/primaoca",
        "creditorAddress":"Adresa platitelja/platioca",
        "debitorAddress":"Adresa primatelja/primaoca",
        "creditorAccount":"Račun platitelja/platioca",
        "debitorAccount":"Račun primatelja/primaoca",
        "Transaction": "Transakcija",
        itemTypeStatus: {
          EInvoice: "EInvoice",
          TransactionCredit: "TransactionCredit",
          TransactionDebit: "TransactionDebit",
          Payment: "Payment",
          MessageIn: "MessageIn",
          MessageOut: "MessageOut",
          Statement: "Statement",
          LoginSuccessful: "LoginSuccessful",
          LoginFailed: "LoginFailed",
          TokenRefresh: "TokenRefresh"
        }
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());
