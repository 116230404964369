(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name viber.marketingBanner.directive:marketingBanner
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="viber.marketingBanner">
       <file name="index.html">
        <marketing-info-banner></marketing-info-banner>
       </file>
     </example>
   *
   */
  angular
    .module('viber')
    .directive('marketingInfoBanner', marketingInfoBanner);

  function marketingInfoBanner() {
    return {
      restrict: 'EA',
      scope: {
        img: '@'/*,
        checkViber: '&'*/
      },
      templateUrl: 'viber/marketing-banner/marketing-info-banner-directive.tpl.html',
      replace: false,
      controllerAs: 'marketingInfoBanner',
      controller: function ($scope, $state, $rootScope, $localStorage, $window, ViberService) {
        var vm = this;
        vm.bannerImageUrl = $scope.img;
        // vm.checkViber = $scope.checkViber;
        // console.log(vm.checkViber)
        vm.MarketingInfo = $rootScope.APP_GLOBAL_SETTINGS.defaultData.MarketingInfo;
        console.log(vm.MarketingInfo);
        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.currentLang = $localStorage.appLanguage;
        $rootScope.$on('appLanguageChangedHeader', function (evt, lang) {
          vm.currentLang = lang.langCode;
        });
        vm.onBannerClicked = function () {
          if (vm.MarketingInfo.link) {
              $window.open(vm.MarketingInfo.link, '_blank');
          } else {
            // vm.checkViber();
          }
        };
      }
    };
  }
}());
