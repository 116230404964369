<section ng-controller="TemplateListOverviewSloCtrl as templateListOverview" id="main">
  <div class="container-fluid p-b-20">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="f-28" translate="templatesList.header"></h2>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- START Search -->
        <div class="col-lg-12 col-md-12 col-sm-12 tmplt-list-section">
          <div class="col-lg-12 col-md-12 col-sm-12 ">
            <div id="searchBtnLeftDiv" class="inner-section left">
              <i class="icon icon-search  icon-hc-2x m-l-5 tml-search-container"></i>
            </div>
            <div class="form-group search-field searchrow">
              <input type="text" class="form-control input-field"
                     name="searchTemplateList" id="searchTemplateList"
                     ng-model="templateListOverview.searchTemplateListApi"
                     ng-change="templateListOverview.searchTemplate(templateListOverview.searchTemplateListApi)"
                     placeholder="{{'templatesList.searchTemplates'| translate}}"/>
            </div>
          </div>
          <!-- END Search -->

          <div class="tmplt-list-label col-lg-12 col-md-12 col-sm-12" translate="templateSearch.templatesList">
            List of templates
          </div>

          <!-- Template list -->
          <div class="col-sm-12 col-md-12 tab-field"
               data-ng-repeat="template in templateListOverview.templateList.result| filter:templateListOverview.searchTemplateList"
               ng-show="templateListOverview.templateList.result.length != 0">

            <!-- TEMPLATE DETAILS -->
            <div class="container invoice-card">
              <div class="row">
                <div class="invoice-card-top gray-card col-lg-12 col-md-12 col-sm-12 col-xs-12 m-p-0">

                  <!-- Icon -->
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 m-p-0 hidden-xs">
                    <div ng-if="!template.imageId" class="invoice-icon">ico</div>
                    <div ng-if="template.imageId"
                         ng-style="{'background-image': 'url(' + template.imageDownloadLink + ')'}"
                         class="t-icon-ctn"></div>
                  </div>

                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 p-0 visible-xs">
                    <div ng-if="!template.imageId" class="invoice-icon">ico</div>
                    <div ng-if="template.imageId"
                         ng-style="{'background-image': 'url(' + template.imageDownloadLink + ')'}"
                         class="t-icon-ctn-xs"></div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-8 col-xs-6 cust-pl-30 hidden-xs">
                    <div class="invoice-type wrap-text">
                      <b>{{::template.name}}</b>
                      <br/>
                      <span class="text-muted" ng-bind="::template.toAccountNumber"></span>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-8 col-xs-6 p-r-0 visible-xs">
                    <div class="invoice-type wrap-text f-13">
                      <b>{{::template.name}}</b>
                      <br/>
                      <span class="text-muted f-12" ng-bind="::template.toAccountNumber"></span>
                    </div>
                  </div>

                  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 m-p-0">
                    <span class="col-sm-6">
                      <i class="icon icon-arrdown i-c31 cursor-pointer"
                         ng-click="templateListOverview.toggleDetails(template)"></i>
                    </span>
                  </div>
                  <!-- Delete template -->
                  <div class="invoice-card-top-right gray-card col-lg-1 col-md-1 col-sm-1 col-xs-2 pull-right">
                    <span class="pull-right tmplt-delete-sign">
                      <i class="icon icon-close i-dblue i-lh21 cursor-pointer"
                         data-ng-click="templateListOverview.deleteTemplate(template)"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row tmplt-card-detail" ng-class="{collapse: !template.openedDetails}">
                <div class="tmplt-content tmplt-from-section container m-t-0">
                  <div ng-if="!template.editable">
                    <div class="row container-template" ng-if="template.sepaUpnIdentificators.debtorName || template.sepaUpnIdentificators.debtorName2">
                      <div class="col-sm-6">
                        <label class="invoice-inline-title text-muted"
                               translate="domesticPayment.sepaDebitorLabel"></label>
                        <label class="tmplt-detail-label-description black-text p-0">{{template.sepaUpnIdentificators.debtorName}}</label>
                        <label class="tmplt-detail-label-description black-text">{{template.sepaUpnIdentificators.debtorName2}}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ============================================== -->
                <!------------------START: DETAIL VIEW --------------->
                <!-- ============================================== -->
                <div ng-if="!template.editable">
                  <div
                    ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment' || template.paymentTypeGroup == 'LocalCurrencyTransfer'">
                    <div class="tmplt-content tmplt-payee-section">
                      <div class="row">
                        <div class="col-sm-6" ng-if="template.toAccountNumber != ''">
                          <span class="invoice-inline-title text-muted" translate="templatesList.accountNumber"><b>Account number</b></span>
                          <div ng-if="templateListOverview.updateResponse.toAccountNumber == undefined">
                            <label class="black-text"
                                   ng-if="template.toAccountNumber != undefined && template.toAccountNumber != ''">{{template.toAccountNumber}}
                            </label>
                            <label class="black-text"
                                   ng-if="template.toAccountNumber == undefined || template.toAccountNumber == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.toAccountNumber != undefined">
                            <label class="black-text">{{template.toAccountNumber}}</label>
                          </div>
                        </div>

                        <div class="col-sm-6" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                          <span class="invoice-inline-title text-muted"
                                translate="templatesList.toPayee"><b>To payee</b>
                          </span>
                          <div class="word-break" ng-if="templateListOverview.updateResponse.receiverName == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverName != undefined && template.receiverName != ''">{{template.receiverName}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverName == undefined || template.receiverName == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.receiverName != undefined">
                            <label class="black-text">{{template.receiverName}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                        <div class="col-sm-offset-6 col-sm-6">
                          <span class="invoice-inline-title text-muted" translate="templatesList.payeeStreet"><b>Payee street</b></span>
                          <div class="word-break"
                               ng-if="templateListOverview.updateResponse.receiverAddress1 == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverAddress1 != undefined && template.receiverAddress1 != ''">{{template.receiverAddress1}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverAddress1 == undefined || template.receiverAddress1 == ''">-</label>
                          </div>
                          <div class="word-break"
                               ng-if="templateListOverview.updateResponse.receiverAddress1 != undefined">
                            <label class="black-text">{{template.receiverAddress1}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                        <div class="col-sm-6 col-sm-offset-6">
                          <span class="invoice-inline-title text-muted"
                                translate="templatesList.payeeCity">
                            <b>
                            Payee city
                            </b>
                          </span>
                          <div class="word-break" ng-if="templateListOverview.updateResponse.receiverCity == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverCity != undefined && template.receiverCity != ''">{{template.receiverCity}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverCity == undefined || template.receiverCity == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.receiverCity != undefined">
                            <label class="black-text">{{template.receiverCity}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row" ng-if="template.sepaUpnIdentificators.creditorName || template.sepaUpnIdentificators.creditorName2">
                        <div class="col-sm-6"></div>
                        <div class="col-sm-6">
                            <label class="invoice-inline-title text-muted" translate="domesticPayment.sepaReceiverLabel"></label>
                            <label class="tmplt-detail-label-description black-text p-0">{{template.sepaUpnIdentificators.creditorName}}</label>
                            <label class="tmplt-detail-label-description black-text">{{template.sepaUpnIdentificators.creditorName2}}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" class="receiver-section p-20"
                       style="padding: 10px">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="eInvoiceDetail.recipientAccount">
                            Account number
                          </label>
                          <label class="domp-template-text hidden-xs">
                            {{template.toAccountNumber}}
                          </label>
                          <label class="domp-template-text visible-xs f-16">
                            {{template.toAccountNumber}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="domesticPayment.toPayee">
                            To payee
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverName}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="paymentTemplates.address">
                            Address
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverAddress1}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-md-offset-6">
                        <div class="form-group">
                          <label class="domp-template-label"
                                 translate="domesticPayment.receiversPostalCodeAndCityInputLabel">
                            Address
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverCity}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign purpose start -->

                  <div ng-if="template.paymentTypeGroup == 'ForeignPayment'">
                    <!--<div class="row">-->
                      <!--<div class="col-md-6">-->
                        <!--<div class="form-group">-->
                          <!--<label class="domp-template-label" translate="templatesList.purposeCode">-->
                            <!--Purpose code-->
                          <!--</label>-->
                          <!--<label class="domp-template-text">-->
                            <!--{{template.purposeCodeDPS}}-->
                          <!--</label>-->
                          <!--&lt;!&ndash;<label ng-if="template.purpose && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"&ndash;&gt;-->
                          <!--&lt;!&ndash;class="domp-template-text">&ndash;&gt;-->
                          <!--&lt;!&ndash;{{template.purpose}}&ndash;&gt;-->
                          <!--&lt;!&ndash;</label>&ndash;&gt;-->
                        <!--</div>-->
                      <!--</div>-->
                    <!--</div>-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="domp-template-label" translate="internationalPayment.paymentPurpose">
                          Payment purpose
                        </label>
                        <label class="domp-template-text word-break">
                          {{template.purpose}}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="receiver-section p-20" ng-if="template.paymentTypeGroup == 'LocalCurrencyTransfer'">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="domp-template-label" translate="internationalPayment.paymentPurpose">
                            Payment purpose
                          </label>
                          <label class="domp-template-text word-break">
                            {{template.purpose}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign purpose end -->

                  <!-- Foreign BIC start -->

                  <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" class="receiver-section p-20">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicNumber_SLO">
                            BIC number
                          </label>
                          <label class="domp-template-text">
                            {{template.beneficiarySWIFTCode}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicCountry_SLO">
                            Country
                          </label>
                          <label class="domp-template-text">
                            {{template.recipientCountry.code}} {{template.recipientCountry.description}}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicBankName">Bank Name</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankName}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicAddress">BIC Address</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankAddress}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicCity">BIC City</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankCity}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicBankCharges">
                            Troškovna opcija:</label>
                          <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                            <!-- Map for other-->
                            <label ng-switch-default class="domp-template-text">
                              <div ng-switch="template.paymentCharges">
                                <div ng-switch-when="3">
                                  SHA
                                </div>
                                <div ng-switch-when="1">
                                  OUR
                                </div>
                                <div ng-switch-when="2">
                                  BEN
                                </div>
                              </div>
                            </label>
                            <!-- Map for Slovenia-->
                            <label ng-switch-when="sl" class="domp-template-text">
                              <div ng-switch="template.paymentCharges">
                                <div ng-switch-when="0">
                                  SHA
                                </div>
                                <div ng-switch-when="1">
                                  OUR
                                </div>
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign BIC end -->

                  <div class="tmplt-content tmplt-purpose-section">
                    <div class="row"
                         ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'">
                      <div class="col-sm-6">
                        <span class="invoice-inline-title text-muted" translate="templatesList.purposeCode"><b>Purpose code</b></span>

                        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                          <label class="black-text">
                          <span ng-if="template.purposeCodeDPS != undefined && template.purposeCodeDPS != ''">
                            {{::template.purposeCodeDPS}} -
                          </span>
                            {{::template.purpose}}
                          </label>
                        </div>
                        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                          <label class="domp-template-text">
                            {{template.purposeCode}}
                          </label>
                          <label class="domp-template-label" translate="internationalPayment.paymentPurpose">
                            Payment purpose
                          </label>
                          <label class="domp-template-text word-break">
                            {{template.purpose}}
                          </label>


                        </div>

                        <label class="black-text"
                               ng-if="(template.purposeCodeDPS == undefined || template.purposeCodeDPS == '') && (template.purpose == undefined || template.purpose == '')">-</label>
                      </div>
                      <div class="col-sm-6">
                        <span class="invoice-inline-title text-muted" translate="templatesList.referenceNumber"><b>Reference number</b></span>
                        <label class="black-text"
                               ng-if="template.referenceTo && templateListOverview.countryCode == 'sl'">{{templateListOverview.formatReferenceNumber(template.referenceTo)}}</label>
                        <label class="black-text"
                               ng-if="!template.referenceTo">-</label>
                      </div>
                    </div>
                  </div>

                  <!-- REVENUE PAYMENT INFO -->
                  <div class="tmplt-content tmplt-purpose-section"
                       ng-if="template.paymentTypeGroup === 'RevenuePayment'">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.accountNumber"></label>
                          <label class="domp-form-label-resume">{{template.toAccountNumber}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.purposeCode"></label>
                          <label class="domp-form-label-resume">{{template.purpose + '-' +
                            template.purposeCodeDPS}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.toPayee"></label>
                          <label class="domp-form-label-resume word-break">{{template.receiverName}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.payeeStreet"></label>
                          <label class="domp-form-label-resume word-break">{{template.receiverAddress1}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPayerNumber"></label>
                          <label class="domp-form-label-resume">{{template.taxPayerId}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.paymentType"></label>
                          <label class="domp-form-label-resume">{{template.revenuePaymentType}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.revenueCode"></label>
                          <label class="domp-form-label-resume">{{template.revenueCode}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.municipality"></label>
                          <label class="domp-form-label-resume">{{template.municipality}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPeriodFrom"></label>
                          <label class="domp-form-label-resume">{{template.taxPeriodFrom | date: 'shortDate' : '+0200'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPeriodTo"></label>
                          <label class="domp-form-label-resume">{{template.taxPeriodTo | date: 'shortDate' : '+0200'}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.budgetOrganisation"></label>
                          <label class="domp-form-label-resume">{{template.budgetOrganization}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.referenceNumber"></label>
                          <label ng-if="template.reference"
                                 class="domp-form-label-resume">{{template.reference}}</label>
                          <label ng-if="!template.reference" class="domp-form-label-resume"> - </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END REVENUE PAYMENT INFO -->

                  <div class="row">


                    <div class=" text-right p-r-10 ">
                      <button class="btn btn-default editTemplate pull-right" translate="templatesList.edit"
                              ng-if="template.paymentSubTypeGroup != 'LocalCurrencyTransfer' &&
                              template.paymentSubTypeGroup != 'ForeignCurrencyTransfer' &&
                              template.paymentSubTypeGroup != 'CurrencyExchange' &&
                              template.paymentSubTypeGroup != 'PaymentNotSupported'"
                              ng-click="templateListOverview.editTemplate(template)">Edit
                      </button>
                      <button ng-if="templateListOverview.countryCode == 'sl'"
                              class="btn btn-default editTemplate pull-right" translate="templatesList.pay"
                              ng-click="templateListOverview.goToPayment(template)">Pay
                      </button>
                    </div>
                  </div>
                </div>
                <!-- ============================================== -->
                <!------------------END:  DETAIL VIEW ---------------->
                <!-- ============================================== -->


                <!--==============================================================================-->
                <!------------------------------- EDITABLE START ----------------------------------->
                <!--==============================================================================-->
                <div ng-if="template.editable">
                  <form name="templateListOverview.templatesListForm" novalidate>
                    <div class="row m-t-20" id="spaha0">
                      <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="hidden-xs t-a-c">
                          <div class="col-lg-12 text-left">
                            <span class="template-headers" translate="paymentTemplates.templateIcon">
                              Template icon
                            </span>
                          </div>
                        </div>
                        <div class="visible-xs ">
                          <div class="col-xs-12 col-md-6 col-lg-6 text-left">
                            <span class="template-headers" translate="paymentTemplates.templateIcon">
                              Template icon
                            </span>
                          </div>
                        </div>
                        <div>
                          <div class="dropdown hidden-xs t-a-c">
                            <div
                              class="btn pull-left btn-default dropdown-toggle img-selector-btn edit-tmpl-img-selector"
                              type="button"
                              data-toggle="dropdown">
                              <div>
                                <img class="img-circle edit-tmpl-selected-img"
                                     ng-src="{{templateListOverview.selectedImage.downloadLink}}" alt="Ico"/>
                              </div>
                              <span class="caret-icon edit-tmpl-caret">
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                              </span>
                            </div>
                            <div class="dropdown-menu edit-tmpl-img-container" role="menu" aria-labelledby="menu1">
                              <div ng-repeat="image in templateListOverview.images"
                                   data-ng-click="templateListOverview.selectImage(image)">
                                <div class="col-xs-12 img-option">
                                  <img class="img-circle edit-tmpl-img" ng-src="{{image.downloadLink}}" alt="Ico">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="dropdown visible-xs">
                            <div
                              class="btn pull-left btn-default dropdown-toggle img-selector-btn edit-tmpl-img-selector"
                              type="button"
                              data-toggle="dropdown">
                              <div>
                                <img class="img-circle edit-tmpl-selected-img"
                                     ng-src="{{templateListOverview.selectedImage.downloadLink}}" alt="Ico"/>
                              </div>
                              <span class="caret-icon edit-tmpl-caret">
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                              </span>
                            </div>
                            <div class="dropdown-menu edit-tmpl-img-container" role="menu" aria-labelledby="menu1">
                              <div ng-repeat="image in templateListOverview.images"
                                   data-ng-click="templateListOverview.selectImage(image)">
                                <div class="col-xs-12 img-option">
                                  <img class="img-circle edit-tmpl-img" ng-src="{{image.downloadLink}}" alt="Ico">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-6 col-lg-6">
                        <span class="invoice-inline-title text-muted" translate="paymentTemplates.templateName">
                          Template name
                        </span>
                        <div>
                          <input type="text" class="form-control input-field"
                                 id="templateNameInput" name="templateNameInput"
                                 data-ng-model="templateListOverview.tempObj.templateName"
                                 placeholder="{{'internalTransfer.templateName'| translate}}"
                                 required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.templateNameInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 maxlength="35">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.purposeOfPaymentInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="internalTransfer.paymentPurposeInputError"></span>
                        </div>
                      </div>
                    </div>

                    <div class="row m-t-20" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer' && template.paymentTypeGroup != 'ForeignPayment'">
                      <div class="col-sm-6">
                        <uib-accordion close-others="true" class="domp-sepa-accordion">
                          <div uib-accordion-group>
                            <uib-accordion-heading>
                              <div class="sepa-heading input-field">
                                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                                  <label class="domp-form-label-sepa"
                                         translate="domesticPayment.sepaDebitorLabel"></label>
                                </div>
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                                  class="icon icon-arrdown"></i></div>
                              </div>
                            </uib-accordion-heading>
                            <input type="text" class="form-control input-field"
                                   ng-model="templateListOverview.tempObj.payersSepaInput1"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                   maxlength="35">
                          </div>
                        </uib-accordion>
                      </div>
                    </div>

                    <div class="row m-t-25 p-r-15" id="spaha1">
                      <div class="row">
                        <div class="col-xs-12 col-sm-6 p-b-10">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12">
                              <div class="col-md-12 col-sm-6 m-b-15">
                                <div class="row">
                                  <div class="col-xs-12">
                                      <span class="invoice-inline-title text-muted"
                                            translate="templatesList.accountNumber"><b>Account number</b></span>
                                  </div>
                                </div>
                                <div>
                                  <label class="black-text">
                                    {{templateListOverview.tempObj.toAccountInput}}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- START: SELECT PAYEE -->
                        <div ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'"
                             class="col-xs-12 col-sm-6 p-l-30">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="templatesList.toPayee"><b>To payee</b></span>
                            </div>
                          </div>

                          <div class="row p-l-15 p-r-15">
                            <div class="col-sm-12 col-xs-12 domp-filter-container"
                                 ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.payeeSearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                <i class="icon icon-search domp-search-sign m-l-5"></i>
                              </div>
                              <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                                <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input"
                                       ng-model="templateListOverview.payeeSearchFilter"
                                       ng-change="templateListOverview.searchPayee()"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                       required
                                       maxlength="{{templateListOverview.fieldMaxLength}}" ng-maxlength="templateListOverview.fieldMaxLength" autocomplete="off">
                              </div>
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                   ng-click="templateListOverview.toggleFilterSelection()">
                                <i class="icon icon-close domp-close-sign i-lh17"
                                   ng-show="templateListOverview.payeeSearchFilter != ''"
                                   ng-click="templateListOverview.removeSearch()"></i>
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                                   ng-show="templateListOverview.payeeSearchFilter == ''"></i>
                              </div>
                              <div class="domp-filter-selection search-row"
                                   ng-class="{'opened': templateListOverview.searchFilterExpanded}">
                                <div class="domp-filter-type-selection-wrapper"
                                     ng-repeat="payee in templateListOverview.payeeList| filter:templateListOverview.searchPayeeBy"
                                     ng-click="templateListOverview.selectPayee(payee.id)">
                                  <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <i class="zmdi zmdi-account-circle" style="color:#9eabd5"></i>
                                  </div>
                                  <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                                    <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <!-- END: SELECT PAYEE -->
                      </div>

                      <div class="row" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                        <div class="col-xs-12 col-sm-6">
                        </div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <input type="text" class="form-control input-field" name="receiversAddressInput"
                                 id="receiversAddressInput" ng-maxlength="templateListOverview.fieldMaxLength" maxlength="{{templateListOverview.fieldMaxLength}}"
                                 ng-model="templateListOverview.tempObj.receiversAddressInput" required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.receiversAddressInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                 ng-change="templateListOverview.checkInputChanges(template.paymentTypeGroup)">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.receiversAddressInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.receiverAddressError"></span>
                        </div>
                      </div>
                      <div class="row" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                        <div class="col-xs-12 col-sm-6"></div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <input type="text" class="form-control input-field" name="receiversPostalCodeAndCityInput"
                                 id="receiversPostalCodeAndCityInput" ng-maxlength="templateListOverview.fieldMaxLength" maxlength="{{templateListOverview.fieldMaxLength}}"
                                 ng-model="templateListOverview.tempObj.receiversPostalCodeAndCityInput" required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.receiversPostalCodeAndCityInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                 ng-change="templateListOverview.checkInputChanges(template.paymentTypeGroup)">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.receiversPostalCodeAndCityInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.receiverCityError"></span>
                        </div>
                      </div>
                      <div class="row" ng-if="template.paymentTypeGroup != 'LocalCurrencyTransfer' && template.paymentTypeGroup != 'ForeignPayment'">
                        <div class="col-xs-12 col-sm-6"></div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <uib-accordion close-others="true" class="domp-sepa-accordion">
                            <div uib-accordion-group>
                              <uib-accordion-heading>
                                <div class="sepa-heading input-field">
                                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                                    <label class="domp-form-label-sepa"
                                           translate="domesticPayment.sepaReceiverLabel"></label>
                                  </div>
                                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                                    class="icon icon-arrdown"></i></div>
                                </div>
                              </uib-accordion-heading>
                              <input type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     ng-model="templateListOverview.tempObj.receiversSepaInput1" maxlength="35">
                            </div>
                          </uib-accordion>
                        </div>
                      </div>
                    </div>

                    <div class="row" id="spaha2">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20 m-b-10">
                        <div class="row" ng-if="template.paymentTypeGroup != 'ForeignPayment' && template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.purposeCodeSelectLabel">
                              Purpose code
                            </span>
                            <select class="form-control input-field" name="purposeCodeSelect"
                                    id="purposeCodeSelect"
                                    ng-options="option.optionValue for option in templateListOverview.purposeCodesList.result"
                                    ng-model="templateListOverview.tempObj.purposeCodeSelect"
                                    ng-change="templateListOverview.changePurpose(templateListOverview.tempObj.purposeCodeSelect)">
                            </select>
                          </div>
                        </div>
                      </div>

                      <!-- REFERENCE INPUT FOR SLO ENVIRONMENT -->
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="template.paymentTypeGroup != 'ForeignPayment' && template.paymentTypeGroup != 'LocalCurrencyTransfer'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.referenceNumberLabel">Reference number</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-6 col-sm-3 p-r-0">
                            <div class="col-xs-12 p-l-0 p-r-0">
                              <select type="text"
                                      class="form-control input-field p-l-15 p-r-10 ng-pristine ng-valid ng-not-empty ng-touched"
                                      name="referenceModelSelect" id="referenceModelSelect"
                                      ng-model="templateListOverview.tempObj.referenceModelSelect"
                                      ng-options="option.name as option.value for option in templateListOverview.referenceList "
                                      ng-change="templateListOverview.referenceModelListSelect = ''; templateListOverview.tempObj.referenceNumberInput = '';">
                              </select>
                            </div>
                          </div>
                          <span ng-if="templateListOverview.tempObj.referenceModelSelect != 'NRC'">
                          <div class="col-xs-6 col-sm-2 p-0 hidden-xs m-b-10"
                               ng-if="templateListOverview.tempObj.referenceModelSelect != 'RF'">

                            <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"
                                    id="referenceModelList"

                                    ng-model="templateListOverview.referenceModelListSelect"
                                    ng-options="item as item for item in templateListOverview.referenceModelList"
                                    ng-change="templateListOverview.tempObj.referenceNumberInput = (templateListOverview.referenceModelListSelect == '99') ? '' : templateListOverview.tempObj.referenceNumberInput">
                            </select>

                          </div>

                          <div class="col-sm-6 col-xs-6 p-l-0 p-r-0"
                               ng-if="templateListOverview.referenceModelListSelect != '99'">
                            <div class="form-group m-b-0">
                              <input type="text" id="referenceNumberInput" name="referenceNumberInput"
                                     class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersDashes}}"
                                     ng-model="templateListOverview.tempObj.referenceNumberInput" maxlength="{{(templateListOverview.tempObj.referenceModelSelect == 'RF') ? 23 : 22 }}">
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6 p-l-0 p-r-0"
                               ng-if="templateListOverview.referenceModelListSelect == '99'">
                            <div class="form-group m-b-0">
                              <input type="text" class="form-control input-field" name="referenceNumberInput"
                                     id="referenceNumberInput2" readonly>
                            </div>
                          </div>
                          </span>

                          <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                                ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.referenceModelInputError"></span>
                        </div>
                      </div>
                      <!-- END REFERENCE INPUT FOR SLO ENVIRONMENT -->
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                            <span class="invoice-inline-title text-muted"
                                  translate="domesticPayment.purposeOfPaymentInputLabel">
                              Purpose
                            </span>
                        <div class="form-group">
                          <div class="p-0">
                            <input type="text" class="form-control input-field"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.standardInput}}"
                                   name="purposeOfPaymentInput" id="purposeOfPaymentInput" maxlength="140" ng-maxlength="140"
                                   ng-model="templateListOverview.tempObj.purposeOfPaymentInput" required
                                   ng-class="{'domp-error': templateListOverview.templatesListForm.purposeOfPaymentInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                   ng-blur="templateListOverview.replaceUnallowedCharsDPS('purposeOfPaymentInput', templateListOverview.tempObj.purposeOfPaymentInput)">
                            <span class="c-red"
                                  ng-show="domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted"
                                  translate="internalTransfer.paymentPurposeInputError"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- PUBLIC REVENUE EDIT -->
                    <!-- ng-if="template.paymentTypeGroup === 'RevenuePayment'" -->
                    <div class="row" ng-if="template.paymentTypeGroup === 'RevenuePayment'">
                      <div data-ng-include="'payment-templates/public-revenue-section.tpl.html'"></div>
                    </div>
                    <!-- END PUBLIC REVENUE SECTION -->


                    <!-- DOMESTIC PAYMENT END -->
                    <!-- START BIC -->
                    <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" id="spaha3">
                      <div class="row m-t-25">
                        <div class="col-xs-12 col-sm-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicNumber">
                                BIC Number
                              </span>
                            </div>
                          </div>
                          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                            <div ng-switch-when="sl" class="row p-r-15 p-l-15">
                              <input type="text" name="bicNumber" class="form-control input-field"
                                     ng-model="templateListOverview.tempObj.swiftCodeInput"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLetters}}"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bicNumber.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     maxlength="11"
                                     capitalize>
                              <span class="c-red" translate="payments.bicNumberRequired"
                                    ng-show="templateListOverview.templatesListForm.bicNumber.$error.required && templateListOverview.tempObj.formSubmitted">
                                </span>
                            </div>
                            <div ng-switch-default class="row p-r-15 p-l-15">
                              <div class="col-xs-12 domp-filter-container"
                                   ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.bicCodeSearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                                </div>
                                <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">
                                  <input type="text" class="search-input" name="bicCodeSearchFilter"
                                         ng-model="templateListOverview.bicCodeSearchFilter"
                                         ng-change="templateListOverview.searchBICNumber(templateListOverview.bicCodeSearchFilter)"
                                         ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLetters}}"
                                         autocomplete="off"
                                         required
                                         capitalize="">
                                </div>

                                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                     ng-click="templateListOverview.bicCodeSearchFilterExpanded = !templateListOverview.bicCodeSearchFilterExpanded">
                                  <i class="icon icon-close domp-close-sign"
                                     ng-show="templateListOverview.bicCodeSearchFilter"
                                     ng-click="templateListOverview.removeBicCodeSearch()"></i>
                                  <i class="icon icon-arrdown m-r-0 pull-right"
                                     ng-show="!templateListOverview.bicCodeSearchFilter"></i>
                                </div>
                                <!--Dropdown menu -->
                                <div class="domp-filter-selection search-row"
                                     ng-class="{'opened': templateListOverview.bicCodeSearchFilterExpanded}">
                                  <div class="domp-filter-type-selection-wrapper"
                                       ng-repeat="bicBank in templateListOverview.bicBankOptions| filter:templateListOverview.bicCodeSearchFilter"
                                       ng-click="templateListOverview.onBicBankSelected(bicBank.bicCode)">
                                    <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                      <!--<i class="zmdi zmdi-account-circle"></i>-->
                                    </div>
                                    <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                      <p class="domp-list-name m-0">{{bicBank.bicCode}}</p>
                                    </div>
                                  </div>
                                </div>
                                <span class="c-red" translate="payments.bicNumberRequired"
                                      ng-show="templateListOverview.templatesListForm.bicCodeSearchFilter.$error.required && templateListOverview.tempObj.formSubmitted">
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicCountry">
                                Country
                              </span>
                            </div>
                          </div>
                          <div class="row p-r-15 p-l-15">
                            <div class="col-xs-12 domp-filter-container"
                                 ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.countrySearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                <i class="icon icon-search domp-search-sign m-l-5"></i>
                              </div>
                              <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">
                                <input type="text" class="search-input" name="countrySearchFilter"
                                       ng-model="templateListOverview.countrySearchFilter"
                                       ng-change="templateListOverview.countrySearchFilterExpanded = true;"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                                       required
                                       autocomplete="off">
                              </div>

                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                   ng-click="templateListOverview.countrySearchFilterExpanded = !templateListOverview.countrySearchFilterExpanded">
                                <i class="zmdi zmdi-close domp-close-sign"
                                   ng-show="templateListOverview.countrySearchFilter"
                                   ng-click="templateListOverview.removeSelectedCountry()"></i>
                                <i class="icon icon-arrdown  m-r-0 pull-right"
                                   ng-show="!templateListOverview.countrySearchFilter"></i>
                              </div>
                              <!--Dropdown menu -->
                              <div class="domp-filter-selection search-row"
                                   ng-class="{'opened': templateListOverview.countrySearchFilterExpanded}">
                                <div class="domp-filter-type-selection-wrapper"
                                     ng-repeat="country in templateListOverview.countriesOptions| filter: templateListOverview.countrySearchFilter"
                                     ng-click="templateListOverview.onCountrySelect(country)">
                                  <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <!--<i class="zmdi zmdi-account-circle"></i>-->
                                  </div>
                                  <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="c-red"
                                 ng-show="templateListOverview.templatesListForm.countrySearchFilter.$error.required && templateListOverview.tempObj.formSubmitted"
                                 translate="payments.bicCountryRequired"></div>

                          </div>
                        </div>
                      </div>

                      <div class="row m-t-25">
                        <div class="col-xs-12 col-md-4">
                          <!-- Required if Bic number is empty -->
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="payments.bicBankName">Bank Name</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field"
                                     name="bankNameInput"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bankNameInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     ng-model="templateListOverview.tempObj.bankNameInput"
                                     autocomplete="off"
                                     required>
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.bankNameInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="payments.bicBankNameRequired"></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">

                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicRecipientStatus">Receiver (person)</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <select class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.recipientStatusRadiobutton.type">
                                <option value=2>{{"payments.bicRecipientStatusOptionPhysical"| translate}}</option>
                                <option value=1>{{"payments.bicRecipientStatusOptionLegal"| translate}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row m-t-25">
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="payments.bicAddress">BIC Address</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     autocomplete="off"
                                     ng-model="templateListOverview.tempObj.bankAddressInput">
                            </div>
                          </div>

                        </div>
                        <div class="col-md-4">

                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicCity">BIC City</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field" name="bicCity"
                                     ng-model="templateListOverview.tempObj.bankCityInput"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bicCity.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     required>
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.bicCity.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="payments.bicCityRequired"></span>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicBankCharges">Troškovna opcija:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="col-xs-12">
                              <select ng-switch-default
                                      class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.bankChargesRadiobutton.type">
                                <option value=3>SHA</option>
                                <option value=1>OUR</option>
                                <option value=2>BEN</option>
                              </select>
                              <select ng-switch-when="sl"
                                      class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.bankChargesRadiobutton.type">
                                <option value=0>SHA</option>
                                <option value=1>OUR</option>
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- END BIC -->


                    <!-- Save/Cancel action start -->

                    <div class="row  p-t-20">
                      <button class="btn btn-primary editTemplate" translate="templatesList.cancel"
                              ng-click="templateListOverview.cancel(template)">Cancel
                      </button>
                      <button class="btn btn-default editTemplate pull-right" translate="templatesList.save"
                              ng-click="templateListOverview.saveTemplate(template)"
                              ng-disabled="templateListOverview.saveButtonPressed">Edit
                      </button>
                    </div>
                    <!-- Save/Cancel actions end -->
                  </form>
                </div>

                <!-- EDITABLE END-->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="row">
      <div class="m-10 text-center" ng-if="templateListOverview.templateList.hasMore">
        <hr>
        <button class="btn btn-default" ng-click="templateListOverview.loadMoreTemplates()"
                translate="messages.loadMore">Load more
        </button>
      </div>
    </div>

  </div>
</section>
