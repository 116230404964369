<div class="col-sm-12 col-md-12">
  <div class="timeline-row col-sm-12 no-padding">
    <div class="hypo-timeline-wrapper app-white-card p-tbl-10-r-0">
      <ul class="hypo-timeline"
          ng-repeat="loanAccountPaymentPlan in accounts.selectedAccount.loanAccountPaymentPlanList">
        <!--ng-repeat="payment in dashboard.pendingPaymentsList.result | limitTo:dashboard.numberOfPendingPaymentsToShow"-->
        <li>
          <div class="time-group-badge">{{loanAccountPaymentPlan.nextInstallmentDate | date:'mediumDate'}}</div>
        </li>
        <li class="time-label" style="margin-left: 0" ng-click="loanAccountPaymentPlan.selected = !loanAccountPaymentPlan.selected">
          <i class="zmdi zmdi-timer" style="top:20px; z-index: 1;"></i>
          <div class="hypo-timeline-item payment-item" ng-class="{'opened': loanAccountPaymentPlan.selected}">
            
            <div class="hypo-timeline-body clearfix">
              <div class="col-md-6 col-xs-12">
                <h4 translate="repaymentPlan.instalment">Instalment</h4>
              </div>
                <div class="col-md-3 col-xs-12 " 
                     ng-class="{'col-md-6 pull-right' : !loanAccountPaymentPlan.paymentAmount}">
                <div ng-show="loanAccountPaymentPlan.remainLoanBalance" class="pull-right t-a-r m-t-5  f-18">
                  <b class="price-payment" style="font-weight:600; font-size:22px;">{{::loanAccountPaymentPlan.remainLoanBalance|number:2}}
                    {{::accounts.selectedAccount.currencySymbol}}</b>
                  <br>
                  <span translate="repaymentPlan.loanBalance">Loan balance</span>
                </div>
                <!--<p class="m-b-0 m-t-0 iban-style">{{::payment.fromAccountNumber}}</p>-->
              </div>
              <div class="col-md-3 col-xs-12">
                <div ng-show="loanAccountPaymentPlan.paymentAmount" class="pull-right t-a-r m-t-5  f-15">
                  <b>{{::loanAccountPaymentPlan.paymentAmount|number:2}}
                    {{::accounts.selectedAccount.currencySymbol}}</b>
                  <br>
                  <span translate="repaymentPlan.instalment">Instalment</span>
                </div>
              </div>
            </div>
            <!-- SHOW IF OPEN -->
            <div ng-if="loanAccountPaymentPlan.selected" class="transaction-details">
              <div>
                <table class="table table-transparent table-responsive">
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.date">Date</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.nextInstallmentDateFrom | date: 'mediumDate'}}</span>
                    </td>
                  </tr>
<!--                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.dateTo">Date to:</span>
                    </td>
                    <td>
                      <span
                        class="po-value-label">{{loanAccountPaymentPlan.nextInstallmentDate | date: 'mediumDate'}}</span>
                    </td>
                  </tr>-->
                  
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.principalAmount">Principal amount:</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.matureCapital|number:2}}</span>
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.interestAmount">Interest amount:</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.interestRegularAmount|number:2}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.paymentAmount">Payment amount:</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.paymentAmount|number:2}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.totalAnnuity">Total annuity:</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.totalAnnuityAmount|number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="po-desc-label" translate="repaymentPlan.balance">Balance:</span>
                    </td>
                    <td>
                      <span class="po-value-label">{{loanAccountPaymentPlan.remainLoanBalance|number:2}} {{accounts.selectedAccount.currencySymbol}}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>
