(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsFinalStepCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsFinalStepCtrl', PaymentsFinalStepCtrl);

  function PaymentsFinalStepCtrl($rootScope, PaymentTemplatesService, $state, $log, PaymentsService, PaymentSignListService, HelperService, NotificationService) {
    var vm = this;

    vm.selectedPayment = {};
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;


    vm.isCancelation=false;
    vm.init = function () {

      if($state.params.isCancelation)
      {
        vm.isCancelation=true;
        if ($state.params.paymentId) {
          vm.selectedPayment.id = $state.params.paymentId;
        }
      }
      else{
        vm.paymentType = $state.params.type;
        vm.isFromTemplate = $state.params.isFromTemplate;

        if($rootScope.APP_GLOBAL_SETTINGS.defaultData){
          vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          vm.bankCharges = vm.defaultData.BankCharges;
        }
        if ($state.params.paymentId) {
          vm.selectedPayment.id = $state.params.paymentId;
        }

        // If payment is sent as parameter. No need to load it from database
        if ($state.params.paymentObject) {

          if(vm.paymentType === 'group'){
            vm.paymentsGroupList = $state.params.paymentObject;
            vm.canBeSavedToTemplate = false;
          }else{
            vm.selectedPayment = $state.params.paymentObject;
            if (vm.countryCode == "sl" && vm.isFromTemplate == null) {
              getTemplates();
            } else {
              vm.canBeSavedToTemplate = canBeSavedToTemplate(vm.selectedPayment.paymentTypeGroup, vm.selectedPayment);
            }
          }
        }
        // If user is reloading page, or payment was not sent through params
        else {
          if (vm.paymentType !== 'group') {
            loadPayment($state.params.paymentId);
          }else{
            loadGroupOfPayments($state.params.paymentId);
          }
        }
      }
    };


    function getTemplates () {
      // if (vm.isFromTemplate == null) {
      PaymentTemplatesService.getPage({
        paymentTypeGroup: 'DomesticPayment',
        page: 0,
        pageSize: 1000
      }).then(function (response) {
        var ibanInTemplates = _.some(response.result, function (template) {
          return template.toAccountNumber.replace(/ /g,'') == vm.selectedPayment.toAccountNumber.replace(/ /g,'')
        });
        if (ibanInTemplates) {
          vm.isFromTemplate = true;
        }
        vm.canBeSavedToTemplate = canBeSavedToTemplate(vm.selectedPayment.paymentTypeGroup, vm.selectedPayment);
      }).catch(function (error) {
        $log.error(error);
      });
      // }
    }


    var loadPayment = function (paymentId) {
      if (paymentId) {
        if(vm.countryCode === 'bh'){
          return PaymentsService.getById(paymentId, {includeList: ['RevenuePaymentDetails']}).then(function (payment) {
            vm.selectedPayment = payment;
            vm.canBeSavedToTemplate = canBeSavedToTemplate(vm.selectedPayment.paymentTypeGroup, vm.selectedPayment);
          }).catch(function (error) {
            $log.error(error);
            NotificationService.showMessage(error, 'error');
          });
        } else {
          return PaymentsService.getById(paymentId).then(function (payment) {
            vm.selectedPayment = payment;
            if (vm.countryCode == "sl") {
              getTemplates();
            } else {
              vm.canBeSavedToTemplate = canBeSavedToTemplate(vm.selectedPayment.paymentTypeGroup, vm.selectedPayment);
            }          }).catch(function (error) {
            $log.error(error);
            NotificationService.showMessage(error, 'error');
          });
        }
      }
    };

    var loadGroupOfPayments = function(paymentGroupId){
      if(paymentGroupId){
        return PaymentSignListService.getSignListById(paymentGroupId).then(function(paymentGroup){
          vm.paymentsGroupList = paymentGroup;
          vm.canBeSavedToTemplate = false;
        }).catch(function(err){
          $log.error(err);
          NotificationService.showMessage(error, 'error');
        });
      }
    };

    var canBeSavedToTemplate = function (paymentType,payment) {

      if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl"){
        const _tempSessionStorageData = sessionStorage.getItem('paymentSubTypeGroup');
        if(!vm.isFromTemplate &&
          (paymentType === 'DomesticPayment'
          || paymentType === 'ForeignPayment'
          || paymentType === 'RevenuePayment'
          || paymentType === 'SEPAPayment'
          || (paymentType === 'LocalCurrencyTransfer' && _tempSessionStorageData == 'DomesticPayment')
          )
        ){
          return true;
        }else{
          return false;
        }
      } else{
        if(
          paymentType === 'DomesticPayment'
          || payment.paymentSubTypeGroup == 'DomesticPayment'
          || paymentType === 'ForeignPayment'
          || paymentType === 'RevenuePayment'
          || paymentType === 'SEPAPayment'
          // || (paymentType === 'LocalCurrencyTransfer')
        ){
          return true;
        }
        else
          return false;
      }
    };

    vm.makeAnotherPayment = function () {
      var stateLink = '';
      switch (vm.paymentType) {
        case 'domestic':
        case 'loan':
          stateLink = 'payments.domesticPayment';
          break;
        case 'exchange':
          stateLink = 'payments.currencyConversion';
          break;
        case 'internal':
          stateLink = 'payments.internalTransfer';
          break;
        case 'international':
          stateLink = 'payments.internationalPayment';
          break;
        case 'ebook':
          stateLink = 'newEbook';
          break;
        case 'einvoice':
          stateLink = 'eInvoices.einvoicesOverview';
          break;
        case 'saving':
          stateLink = 'payments.paymentsOverview';
          break;
        default:
          stateLink = 'payments.paymentsOverview';
          break;
      }
      $state.go(stateLink);
    };

    vm.goToListOfPayments = function () {
      $state.go('payments.paymentsOverview',
        {selected: null}
      );
    };
    vm.init();
  }
}());
