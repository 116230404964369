(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:reorderPinLimitCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('ReorderPinMasterCardCtrl', ReorderPinMasterCardCtrl);

  function ReorderPinMasterCardCtrl($q, $state, $translate, AlertService, HelperService, AccountsService, UserSettingsService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'ReorderPinMasterCardCtrl';

    vm.step = 1;

    vm.iAmAware = false;
    vm.selectedAccount = {
      currencySymbol: 'EUR'
    };


    vm.cardTypes = [
      {id: 'basic', description:'masterCardOrder.basic'},
      {id: 'additional', description:'masterCardOrder.additional'}
    ];

    vm.selectedAccount.cardType = vm.cardTypes[0];

    vm.tempObj = {
      dateFrom: new Date(),
      dateTo: new Date()
    };
    vm.dateOptions = {
      dateFrom: {
        minDate: new Date()
      },
      dateTo: {
        //
      }
    };

    vm.tempObj = {
      cardAccount: ''
    };

    var selectCardbyId = function (id) {
      var card = _.find(vm.selectedAccount.cardList, function (item) {
        return item.cardId === id;
      });
      if (card)
        vm.selectedAccount.selectedCard = card;
      else
        vm.selectedAccount.selectedCard = vm.selectedAccount.cardList[0];

    };

    var init = function () {
      var params = {
        includeList: ['CardAccount', 'cardList', 'ParentAccount'],
        isOwn: true
      };

      var promises = [
        AccountsService.getAccountById($state.params.accountId, params),
        UserSettingsService.getPage({pageSize: 1000}),
        AccountsService.getNewPinOrderDeliveryType()
      ];

      /*var promises = [
        AccountsService.getAccountById($state.params.accountId, params),
        UserSettingsService.getPage({pageSize: 1000})
      ];*/

      $q.all(promises).then(function (responses) {
        vm.selectedAccount = responses[0];
        selectCardbyId($state.params.cardId);

        //TODO
        vm.selectedAccount.cardType = vm.cardTypes[vm.selectedAccount.selectedCard.cardType]; // 0 for basic , 1 for additional

        vm.tempObj.cardAccount = vm.selectedAccount.accountNumber + ' ' + vm.selectedAccount.friendlyName;

        vm.userSettings = responses[1].result[0];

        vm.newPinOrderDeliveryTypes = responses[2].result;
        console.log(vm.selectedAccount)
        if (!vm.selectedAccount.selectedCard.smsDeliveryType) {
          vm.newPinOrderDeliveryTypes =  vm.newPinOrderDeliveryTypes.filter(function (item) {
            return item.id != 'SMS';
          })
        }
        vm.tempObj.newPinOrderDeliveryType = vm.newPinOrderDeliveryTypes[0];
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    function createObject() {

      var tempObj = {
        'cardNumber': vm.selectedAccount.selectedCard.cardNumber,
        'cardType': vm.selectedAccount.selectedCard.cardType,
        'deliveryType': {
          'id': vm.tempObj.newPinOrderDeliveryType.id,
          'description': vm.tempObj.newPinOrderDeliveryType.description
        },
        'accountId': vm.selectedAccount.accountId
      };

      return tempObj;
    }

    vm.validate = function () {
      if (vm.nextButtonPressed) return;
      vm.formSubmitted = true;
      if (!vm.reorderPinMasterCardForm.$valid) {
        return;
      }
      vm.insertedOrderObject = createObject();
      vm.nextButtonPressed = true;
      AccountsService.validateCreditCardNewPinOrder(vm.insertedOrderObject)
        .then(function (response) {
          vm.step = 2;
          vm.insertedOrderObject = angular.extend(response);
          // amount missing in response
          // vm.insertedOrderObject.amount = vm.tempObj.amount;
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.nextButtonPressed = false;
        });
    };

    vm.confirm = function () {
      if (vm.confirmButtonPressed) return;
      vm.confirmButtonPressed = true;
      AccountsService.creditCardNewPinOrder(vm.insertedOrderObject)
        .then(function (response) {
          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              vm.goBackToAccount();
            });
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.confirmButtonPressed = true;
        });
    };

    vm.edit = function () {
      vm.step = 1;
      vm.iAmAware = false;
    };

    vm.goBackToAccount = function () {
      $state.go('accounts', {
        accountId: $state.params.accountId
      });
    };

    init();
  }
}());
