(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EInvoicesDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EInvoicesDetailCtrl', EInvoicesDetailCtrl);

  function EInvoicesDetailCtrl($state, $log, EInvoiceDocumentsService, HelperService,$translate, NotificationService) {
    var vm = this;
    vm.eInvoiceObject = {};
    vm.signatureDetail = false;
    //vm.translateName = 'eInvoiceDetail.showInfo';
    vm.init = function(){
      $log.debug('EInvoices detail init()');
      if($state.params.eInvoiceId){
        vm.getEInvoiceById($state.params.eInvoiceId);
      }
    }

    vm.getEInvoiceById = function (id) {
      EInvoiceDocumentsService.getById(id)
        .then(function (response) {
          if(response){
            vm.eInvoiceObject = response;
          }
        })
        .catch(function (error) {
          errorFcn(error);
        });
    };

    var errorFcn = function (error) {
      if (error && error.resultList && error.resultList.length > 0) {
        NotificationService.showMessage(error, 'error');
      }
    };

    vm.openEInvoiceModal = function () {
      EInvoiceDocumentsService.openModal(vm.eInvoiceObject);
    };

    vm.toggleSignatureDetailView = function(){
      vm.signatureDetail = !vm.signatureDetail;
    };

    vm.goBack = function () {
      $state.go('eInvoices.einvoicesOverview');
    };

    vm.init();

  }
}());
