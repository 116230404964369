<div ng-controller="DomesticPaymentMneCtrl as domesticPaymentMne">
    <div class="domp-header-wrapper" ng-show="domesticPaymentMne.currentStep != 3">
    <div class="row">
      <div class="col-md-12">
            <h2 class="f-28" translate="domesticPayment.header">Domestic Payment</h2>
      </div>
    </div>
  </div>
    <div class="domp-body-wrapper">
        <div ng-if="domesticPaymentMne.currentStep === 1" class="domp-step-1">
            <ng-form novalidate name="domesticPaymentMne.domesticPaymentForm">
                <div class="domp-select-section col-sm-12 p-0">

                    <!--SELECT TEMPLATE CUSTOM -->

                    <div class="domp-template-filter-container">
                        <div class="domp-select-template" ng-click="domesticPaymentMne.toggleTemplateFilterSelection()" ng-show="domesticPaymentMne.templateName==''">
                            <div class="select-template label-select">
                                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from template</label>
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
                            </div>
                        </div>
                        <div class="domp-selected-template" ng-show="domesticPaymentMne.templateName!=''">
                            <div class="select-template label-template-selected p-l-10 p-t-5" ng-show="domesticPaymentMne.templateName!=''">

                              <img ng-if="domesticPaymentMne.selectedTemplate.imageId" ng-src="{{domesticPaymentMne.selectedTemplate.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img-40"/>
                              <img ng-if="!domesticPaymentMne.selectedTemplate.imageId" class="tpl-rounded-img-40" alt="Ico"/>

                                <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                                <label class="p-l-10 selected-template-label top-2" ><b>{{domesticPaymentMne.templateName}}</b></label>
                                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5" ng-click="domesticPaymentMne.removeTemplate()"></i>
                            </div>
                        </div>
                        <div class="domp-template-filter-selection" ng-class="{'opened': domesticPaymentMne.searchTemplateFilterExpanded}">
                            <div class="search-template">
                                <div class="inner-section left">
                                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                                </div>
                                <div class="inner-section center" style="width:50%;">
                                    <input id="searchTemplatesInput" type="text" class="search-input" ng-model="domesticPaymentMne.tmpSearchText.name" ng-change="domesticPaymentMne.searchTemplate(domesticPaymentMne.tmpSearchText.name)" placeholder="{{'dashboard.searchTemplatesPlaceholder' | translate}}">
                                </div>
                            </div>
                            <div class="domp-template-filter">
                                <div class="m-b-10">
                                    <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                                    <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                                        <a href="" class="list-group-item" data-ng-repeat="template in domesticPaymentMne.templateList.result | filter:domesticPaymentMne.tmpSearchText" ng-click="domesticPaymentMne.selectTemplate(template.id)">
                                            <div class="row m-r-0">
                                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
                                                  <!--<i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                                                  <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                                                  <img ng-if="!template.imageId" class="tpl-rounded-img" alt="Ico"/>
                                                </div>
                                                <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15" style="padding-left: 70px !important;">
                                                    <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                                                    <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--SELECT TEMPLATE CUSTOM END-->
                </div>

                <div class="col-sm-12 domp-from-section">
                    <!-- CUSTOM ACCOUNTS DROPDOWN -->
                    <div class="col-sm-6 domp-acc-dropdown">
                        <label class="domp-form-label" translate="payments.fromAccount"></label>
                        <div class="accounts-dropdown">
                            <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="domesticPaymentMne.toggleFromAccount();"  ng-style="domesticPaymentMne.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + domesticPaymentMne.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                <h4 class="no-wrap-text bold-font m-b-0">
                                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{domesticPaymentMne.tempDompObj.fromAccountSelect.friendlyName}}</b>
                                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':domesticPaymentMne.tempDompObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!domesticPaymentMne.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                                </h4>
                                <p class="m-b-0 m-t-0 iban-style">{{domesticPaymentMne.tempDompObj.fromAccountSelect.iban}}</p>
                                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                    {{domesticPaymentMne.tempDompObj.fromAccountSelect.availableBalance | number:2}} {{domesticPaymentMne.tempDompObj.fromAccountSelect.currencySymbol}}
                                </p>
                                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                            </div>
                            <div class="from-accounts-list" ng-show="domesticPaymentMne.fromAccountDropdown" scrollable>
                                <div class="accounts-list-item p-0" data-ng-repeat="item in domesticPaymentMne.paymentTypeGroupDomestic.result[0].debitAccountList" data-ng-click="domesticPaymentMne.setAccountFrom(item);">
                                    <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                        <h4 class="no-wrap-text bold-font m-b-0">
                                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                        </h4>
                                        <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                                        <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                            {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                        </p>
                                        <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row ">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="domp-form-label" for="amountInput" translate="domesticPayment.amountInputLabel">Amount:</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput" amount-input-mask maxlength="12" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" ng-model="domesticPaymentMne.tempDompObj.amountInput" required ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.amountInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}">
                                        <span class="input-group-btn">
                    <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">{{domesticPaymentMne.defaultCurrency}}</button>
                  </span>
                                    </div>
                                    <span class="c-red" ng-show="domesticPaymentMne.domesticPaymentForm.amountInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="form-group">
                                    <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                                    <div class="input-group ">
                                      <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                                             uib-datepicker-popup="shortDate2" data-ng-model="domesticPaymentMne.tempDompObj.dateInput"
                                             ng-required="true" show-weeks="false"
                                             is-open="domesticPaymentMne.datePickerOpened"
                                             current-text="{{'core.period.today' | translate}}"
                                             clear-text="{{'core.datePicker.clearBtn' | translate}}"
                                             close-text="{{'core.datePicker.doneBtn' | translate}}"
                                             datepicker-options="domesticPaymentMne.dateOptions"
                                             date-disabled="domesticPaymentMne.disabled(date, mode)" readonly>
                                        <span class="input-group-btn">
                    <button data-ng-click="domesticPaymentMne.openDatePicker()" type="button"
                            class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
          <i class="icon icon-calendar" style="font-size: 21px;"></i></button>
                  </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <div class="col-sm-3 col-xs-4 p-l-0 p-r-5">
                                <label class="domp-form-label" translate="domesticPayment.referenceModelLabel">Model</label>
                                <div class="form-group m-b-0">
                                  <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelSelect" ng-model="domesticPaymentMne.tempDompObj.referenceModelSelectFrom">
                                    <option ng-repeat="item in domesticPaymentMne.referenceModelList">{{item}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-9 col-xs-12 p-l-0 p-r-0">
                                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabelV2">Reference number</label>
                                <div class="form-group m-b-0">
                                  <input type="text"
                                         id="referenceNumberInputFrom"
                                         ng-if="domesticPaymentMne.tempDompObj.referenceModelSelectFrom == '18'"
                                         name="referenceNumberInputFrom"
                                         maxlength="22"
                                         class="form-control input-field"
                                         ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInputFrom.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}"
                                         ng-model="domesticPaymentMne.tempDompObj.referenceNumberInputFrom"
                                         ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}"
                                         capitalize
                                         required>

                                  <input type="text"
                                         id="referenceNumberInputFrom"
                                         ng-if="domesticPaymentMne.tempDompObj.referenceModelSelectFrom == '05'"
                                         name="referenceNumberInputFrom"
                                         maxlength="22"
                                         class="form-control input-field"
                                         ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInputFrom.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}"
                                         ng-model="domesticPaymentMne.tempDompObj.referenceNumberInputFrom"
                                         ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}"
                                         capitalize
                                         required>

                                  <input type="text"
                                         id="referenceNumberInputFrom"
                                         ng-if="domesticPaymentMne.tempDompObj.referenceModelSelectFrom != '05' && domesticPaymentMne.tempDompObj.referenceModelSelectFrom != '18'"
                                         name="referenceNumberInputFrom"
                                         maxlength="22"
                                         class="form-control input-field"
                                         ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInputFrom.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}"
                                         ng-model="domesticPaymentMne.tempDompObj.referenceNumberInputFrom"
                                         ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}"
                                         capitalize>
                                </div>
                              </div>
                              <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                                   ng-if="domesticPaymentMne.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted"
                                   translate="domesticPayment.referenceModelInputError"></div>

                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="col-sm-12 receiver-section">
                    <div class="row ">
                        <div class="col-sm-6 col-xs-12 right-section">
                            <div class="form-group">
                                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabelSRB">
                                    Account number
                                </label>
                                <!--<input type="text" class="form-control input-field" ui-mask="999-9999999999999-99" ui-mask-placeholder ui-mask-placeholder-char="_" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" name="toAccountInput" id="toAccountInput" ng-model="domesticPaymentMne.tempDompObj.toAccountInput" maxlength="21" ng-maxlength="21" required>-->
                              <input type="text" class="form-control input-field" name="toAccountInput" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.onlyNumbersAndDashes}}" capitalize ng-minlength="6" dn-custom-maxlength="18" dn-exclude="-" id="toAccountInput" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" ng-model="domesticPaymentMne.tempDompObj.toAccountInput" ng-blur="domesticPaymentMne.fillReceiver(domesticPaymentMne.tempDompObj.toAccountInput)" required>
                                  <div class="c-red" ng-show="domesticPaymentMne.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="domesticPayment.receiverAccountError"></div>
                                  <div class="c-red" ng-show="domesticPaymentMne.domesticPaymentForm.$error.minlength" translate="payments.minimalAccountNumber"></div>

                            </div>
                        </div>
                        <!-- /////////// SELECT CUSTOM ///////////// -->
                        <div click-outside="domesticPaymentMne.searchFilterExpanded = false;" outside-if-not="expandPayeeSearchFilterMNE" class="col-sm-6 col-xs-12 left-section">
                            <div class="form-group">
                                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentMne.domesticPaymentForm.payeeSearchFilter.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}">
                                    <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                        <i class="icon icon-search domp-search-sign m-l-5"></i>
                                    </div>
                                    <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                                        <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allowedCharsDPS}}" ng-model="domesticPaymentMne.tempDompObj.receiversNameInput" ng-change="domesticPaymentMne.searchPayee()" required maxlength="35" ng-maxlength="35" autocomplete="off">
                                    </div>
                                    <div id="expandPayeeSearchFilterMNE" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right" ng-click="domesticPaymentMne.toggleFilterSelection()">
                                        <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentMne.tempDompObj.receiversNameInput != ''" ng-click="domesticPaymentMne.removeSearch()"></i>
                                        <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right" ng-show="domesticPaymentMne.tempDompObj.receiversNameInput == ''"></i>
                                    </div>
                                    <div class="domp-filter-selection search-row" ng-class="{'opened': domesticPaymentMne.searchFilterExpanded}">
                                        <div class="domp-filter-type-selection-wrapper" ng-repeat="payee in domesticPaymentMne.payeeList | filter:domesticPaymentMne.searchPayeeBy" ng-click="domesticPaymentMne.selectPayee(payee.id)">
                                            <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                                <i class="zmdi zmdi-account-circle"></i>
                                            </div>
                                            <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                                                <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentMne.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></span>
                            </div>
                        </div>
                        <!--//////////////SELECT CUSTOM END/////////////////////// -->

                    </div>

                    <div class="row ">
                        <div class="col-lg-6 col-md-6 col-sm-6 left-section"></div>
                        <div class="col-lg-6 col-md-6 col-sm-6 right-section">
                            <div class="form-group">
                                <label class="domp-form-label" for="receiversAddressInput" translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label>
                                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" name="receiversAddressInput" id="receiversAddressInput" ng-model="domesticPaymentMne.tempDompObj.receiversAddressInput" ng-blur="domesticPaymentMne.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentMne.tempDompObj.receiversAddressInput)" maxlength="35" ng-maxlength="35" required>
                                <span class="c-red" ng-show="domesticPaymentMne.domesticPaymentForm.receiversAddressInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="domesticPayment.receiverAddressError"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-6 col-md-6 col-sm-6 left-section"></div>
                        <div class="col-lg-6 col-md-6 col-sm-6 right-section">
                            <div class="form-group">
                                <label class="domp-form-label" for="receiversPostalCodeAndCityInput" translate="domesticPayment.receiversPostalCodeAndCityInputLabel">
                                    Receiver's postal code and city:</label>
                                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.receiversPostalCodeAndCityInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput" ng-model="domesticPaymentMne.tempDompObj.receiversPostalCodeAndCityInput" ng-blur="domesticPaymentMne.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', domesticPaymentMne.tempDompObj.receiversPostalCodeAndCityInput)" maxlength="35" ng-maxlength="35">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 purpose-section">
                    <div class="row ">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
                            <div class="form-group">
                                <div class="col-lg-12  no-padding-on-phone p-r-0 p-l-0">
                                    <label class="domp-form-label" for="purposeCodeSelect" translate="domesticPayment.purposeCodeSelectLabel">Purpose code</label>
                                    <div class="p-l-0">
                                        <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect" ng-options="option.optionValue for option in domesticPaymentMne.purposeCodesList.result" ng-model="domesticPaymentMne.tempDompObj.purposeCodeSelect" ng-change="domesticPaymentMne.changePurpose(domesticPaymentMne.tempDompObj.purposeCodeSelect)"></select>
                                    </div>
                                </div>
                                <div class="col-lg-12  no-padding-on-phone p-r-0 p-l-0 p-t-10">
                                  <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
                                    <div class="p-0">
                                        <input type="text" autocomplete="off" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.purposeOfPaymentValidation}}" name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="105" maxlength="105" ng-model="domesticPaymentMne.tempDompObj.purposeOfPaymentInput" required ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" ng-blur="domesticPaymentMne.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentMne.tempDompObj.purposeOfPaymentInput)">
                                        <span class="c-red" ng-show="domesticPaymentMne.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="domesticPayment.purposeModalInfoText"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 left-section">
<!--                            <div class="col-sm-3 col-xs-4 p-l-0">
                                <label class="domp-form-label" translate="domesticPayment.referenceModelLabel">Model</label>
                            </div>-->
                            <div class="col-sm-3 col-xs-4 p-l-0 p-r-5">
                                <label class="domp-form-label" translate="domesticPayment.referenceModelLabel">Model</label>
                                <div class="form-group m-b-0">
                                    <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelSelect" ng-model="domesticPaymentMne.tempDompObj.referenceModelSelect">
                                        <option ng-repeat="item in domesticPaymentMne.referenceModelList">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-9 col-xs-12 p-l-0 p-r-0">
                                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabel">Reference number</label>
                                <div class="form-group m-b-0">
                                    <input type="text" id="referenceNumberInput" ng-if="domesticPaymentMne.tempDompObj.referenceModelSelect == '18'" name="referenceNumberInput" maxlength="22" class="form-control input-field" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" ng-model="domesticPaymentMne.tempDompObj.referenceNumberInput" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}" capitalize required>
                                    <input type="text" id="referenceNumberInput" ng-if="domesticPaymentMne.tempDompObj.referenceModelSelect == '05'" name="referenceNumberInput" maxlength="22" class="form-control input-field" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" ng-model="domesticPaymentMne.tempDompObj.referenceNumberInput" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}" capitalize required>
                                    <input type="text" id="referenceNumberInput" ng-if="domesticPaymentMne.tempDompObj.referenceModelSelect != '05' && domesticPaymentMne.tempDompObj.referenceModelSelect != '18'" name="referenceNumberInput" maxlength="22" class="form-control input-field" ng-class="{'domp-error': domesticPaymentMne.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentMne.tempDompObj.formSubmitted}" ng-model="domesticPaymentMne.tempDompObj.referenceNumberInput" ng-pattern-restrict="{{domesticPaymentMne.regexPatterns.allLettersNumbersDashesMne}}" capitalize>
                                </div>
                            </div>
                          <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-if="domesticPaymentMne.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentMne.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputError"></div>

                            <!--   <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="domesticPayment.domesticPaymentForm.referenceModelInput.$invalid && domesticPayment.tempDompObj.referenceModelInput != '' && !domesticPayment.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputInvalid"></span> -->
                        </div>
                    </div>
                </div>
                <div class="domp-footer-wrapper">
                    <div class="row ">
                        <div class="col-sm-6 col-lg-6 col-md-6 col-xs-12 p-l-30">
                            <div class="col-xs-12 p-l-10">
                                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value" ng-click="domesticPaymentMne.pushGtmUrgentPaymentEvent();">
                                    <input type="checkbox" ng-model="domesticPaymentMne.tempDompObj.isUrgentPaymentCheckbox">
                                  <i class="input-helper"></i> {{'domesticPayment.selectUrgentLabel' | translate}} <i uib-tooltip="{{'domesticPayment.urgentPaymentInfo' | translate}}" tooltip-append-to-body="true"  tooltip-trigger="'mouseenter'" tooltip-placement="top" class="icon icon-question" style="font-weight: bolder; color:#ff4d5a;"></i>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6 col-md-6 col-xs-12">
                            <div class="col-lg-7 col-md-6 col-sm-5 "></div>
                            <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 p-r-5 p-t-10 charges-label">
                                <a ng-href="{{domesticPaymentMne.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" >Check the bank charges</a>
                            </div>
                        </div>
                    </div>
                    <div class="row p-10">
                      <div class="col-xs-6 t-a-l">
                        <button class="btn btn-primary" type="button" translate="payments.cancel"
                                data-ui-sref="payments.paymentsOverview">
                        </button>
                      </div>
                      <div class="col-xs-6 t-a-r">
                        <button class="btn btn-default domp-review-and-submit-btn" type="submit" translate="domesticPayment.confirmBtn"
                        ng-click="domesticPaymentMne.submitPayment();"></button>
                      </div>
                    </div>
                </div>
            </ng-form>
        </div>
    </div>
    <div ng-if="domesticPaymentMne.currentStep === 2" class="domp-step-2" ng-include="'payments/domestic-payment-resume.tpl.html'"></div>
    <div ng-if="domesticPaymentMne.currentStep == 3">
        <div data-ng-include="'payments/domestic-payment-final.tpl.html'"></div>
    </div>
</div>
