(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:AccountsStatementsCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('AccountsStatementsCtrl', AccountsStatementsCtrl);

  function AccountsStatementsCtrl($scope, $http, $q, $log, $stateParams, AccountsService, TransactionsService, StatementsService, HelperService) {
    var vm = this;
    vm.selectedAccount = {
      statements: {
        hasMore:false
      }
    };

    vm.accountList = [];

    vm.statementsFilter = {
      accountId: undefined
      ,orderBy: 'CloseDate DESC'
      ,page:0
      ,pageSize:100
    };
    vm.busy = false;


    vm.init = function(){
      var index = 0;
      var selectedAccountIndex = 0;
      $log.debug($stateParams);
      AccountsService.getAll()
        .then(function(response){
          $(response.result).each(function(i, e){
            e.index = index++;
            e.active = false;
            if($stateParams.accountId !== undefined){
              if($stateParams.accountId == e.accountId){
                selectedAccountIndex = e.index;
                //e.active = true;
              }
            }
          });
          vm.accountList = response.result;
          vm.selectAccount(selectedAccountIndex);

          vm.getStatements(true);
        })
        .catch(function(error){
          $log.error(error);
          //alert('last catch');
        });
    };

    vm.selectAccount = function(index){
      vm.selectedAccount = vm.accountList[index];
      vm.accountList[index].active = true;
        vm.getStatements(true);
    };


    /*$scope.$watch('accountsStatements.accountList', function(newValue) {
      // do something here
      $(newValue).each(function(i, e){
        if(e.active == true && e.accountId != vm.selectedAccount.accountId){
          vm.selectedAccount = e;
          vm.getStatements(true);
        }
      });
    }, true);*/

    vm.loadMoreStatements = function(){

      if(vm.selectedAccount.statements.hasMore !== false) {
        $log.debug("load");
        vm.statementsFilter.page += 1;
        vm.getStatements(false);
      }
      else{
        $log.debug("no more data");
      }

    };

    vm.getStatements = function(reset){

      if (vm.busy) return;
      vm.busy = true;

      if(reset){
        vm.statementsFilter.page = 0;
      }
      vm.statementsFilter.accountId = vm.selectedAccount.accountId;
      vm.groupByStatements(reset);
    };

    vm.groupByStatements = function (reset) {

      StatementsService.getPage(vm.statementsFilter)
        .then(function(response){
          vm.busy = false;


          if(vm.selectedAccount != undefined){

            vm.selectedAccount.statements = HelperService.mergeRowsForTimeline(
              {
                existingData: vm.selectedAccount.statements
                , newData: response
                ,groupFunction: function(item){
                  return  new Date(item.closeDate).getFullYear();
                },
                virtualRowFunction:function (e) {
                  return {isVirtual: true, closeDateYear:e}
                }
                ,reset: reset
                ,reverse: true
              });

          }

          //$log.debug("statements",vm.selectedAccount.statements);
        }).catch(function(error){
          $log.error(error);
        });
    };

    vm.getSelectedAccountId = function(){
      return {accountId:  vm.selectedAccount.accountId }
    };

    vm.init();
  }
}());
