<section id="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 p-r-0" style="z-index:1;">
                <div class="tmplt-list-title col-lg-12 col-md-12 col-sm-12 col-xs-12 p-r-0 p-l-0 m-b-15">
                    <h2 class="title-style" translate="paymentsReturn.titleLabel">List of payments</h2>
                </div>
            </div>
            <div class="col-xs-12" style="z-index:1;">
                <div class="po-type-container">
                    <div ng-class="{'selected': paymentsReturn.paymentsTypeSelected === 1}" ng-click="paymentsReturn.selectPaymentsType('open', 'paymentsReturn.openedTab')" class="col-xs-6 payments-return-type-tab no-wrap-text" translate="paymentsReturn.openedTab"></div>
                    <div ng-class="{'selected': paymentsReturn.paymentsTypeSelected === 2}" ng-click="paymentsReturn.selectPaymentsType('archived', 'paymentsReturn.archivedTab')" class="col-xs-6 payments-return-type-tab no-wrap-text" translate="paymentsReturn.archivedTab"></div>
                </div>

                <div class="po-filter-row clearfix p-20 bgm-white" style="z-index:1;">

                    <!-- Filter -->
                    <div class="col-md-6 col-xs-12 p-0 p-b-10">
                        <div ng-include="'payments/payments-return-filter.tpl.html'" style="height:40px"></div>
                    </div>
                    <div class="col-md-6 col-xs-12 p-0 p-b-10">
                        <div class="dropdown acc-period-select pull-right" style="display: inline-block">
                            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                {{paymentsReturn.selectedPeriod.item.description | translate}}
                                <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                                <li data-ng-repeat="period in paymentsReturn.periodList">
                                    <a href="" class="f-13" data-ng-click="paymentsReturn.paymentsPeriodFilter(period)">
                  {{period.item.description | translate}}
                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="timeline-row payments col-sm-12 no-padding">
                    <div class="hypo-timeline-wrapper app-white-card p-0 p-b-10 p-t-10">
                        <div class="t-a-c p-10 no-data-image" ng-if="paymentsReturn.paymentsOverviewList.result.length === 0">
                            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                            <h3 class="no-data-label" translate="paymentsOverview.noPaymentData"></h3>
                        </div>

                        <ul class="hypo-timeline payments-list" ng-if="paymentsReturn.paymentsOverviewList.result.length !== 0">

                            <li class="" ng-repeat="payment in paymentsReturn.paymentsOverviewList.result">
                                <i class="icon icon-change currency-icon-change icon-lh"></i>
                                <div class="hypo-timeline-item payment-item" ng-class="{'opened': payment.selected}" ng-click="paymentsReturn.togglePaymentDetails(payment)">
                                    <div class="hypo-timeline-body">
                                        <div class="col-xs-6">
                                            <h4 ng-if="payment.purpose" class="m-b-0 m-t-0 c-gray"><b>{{::payment.purpose}}</b></h4>
                                            <h4 ng-if="!payment.purpose" class="m-b-0 m-t-0 c-gray"><b>{{::payment.paymentTypeGroup}}</b></h4>
                                            <p class="m-b-0 m-t-0 iban-style">{{::payment.fromAccountNumber}}</p>
                                        </div>

                                        <div class="col-xs-6">
                                            <span class="pull-right t-a-r m-t-5 f-18">
                      <b>{{::payment.amount|number:2}} {{::payment.currencySymbol}}</b><br/>
                    <!--<span ng-class="{'text-danger': payment.status === 'Rejected'}">{{::payment.status}}</span>-->
                                            <span class="po-value-label">
                      <span class="po-value-label" ng-class="{'text-danger': payment.status === 'Rejected'}" ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"></span>
                                            <span class="po-value-label" ng-if="payment.status === 'Processed'" translate="paymentsOverview.processed"></span>
                                            <span class="po-value-label" ng-if="payment.status === 'Cancelled'" translate="paymentsOverview.cancelled"></span>
                                            <span class="po-value-label" ng-if="payment.status === 'InProcessing'" translate="paymentsOverview.inProcessing"></span>
                                            <span class="po-value-label" ng-if="payment.status === 'Opened'" translate="paymentsOverview.open"></span>
                                            <span class="po-value-label" ng-if="payment.status === 'WaitingQueue'" translate="paymentsOverview.waitingQueque"></span>
                                            </span>
                                            </span>
                                        </div>
                                        <div class="clear"></div>
                                    </div>

                                    <div ng-if="payment.selected" class="transaction-details">
                                        <div>
                                            <table class="table table-transparent table-responsive">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlStatus"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.status}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr ng-if="payment.status === 'Rejected'">
                                                        <td ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                                                            <span ng-switch-default class="po-desc-label" translate="paymentsOverview.povlNote"></span>
                                                            <span ng-switch-when="sl" class="po-desc-label" translate="paymentsOverview.povlNote_SLO"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.note}}</span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlId"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.id}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlReceiver"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.receiverName}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlFrom"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.fromAccountNumber}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlTo"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.toAccountNumber}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlAmount"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.amount|number:2}} {{::payment.currencySymbol}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr ng-if="payment.referenceTo">
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.referenceTo"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.referenceTo}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span class="po-desc-label" translate="paymentsOverview.povlDueDate"></span>
                                                        </td>
                                                        <td>
                                                            <span class="po-value-label">{{::payment.dueDate| date:'shortDate' : '+0200'}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">
                                                            <div>
                                                                <!-- Show actions only if current tab is 'Opened'-->
                                                                <span ng-if="paymentsReturn.paymentsTypeSelected === 1">
                              <button ng-click="paymentsReturn.rejectPaymentRecall(payment.id);"
                                      class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase pull-left"
                                      translate="paymentActions.rejectRecall">Reject recall</button>

                              <!-- TODO: Check will user immediately sign payment, or there will be read only form first -->
                                                                    <button ng-click="paymentsReturn.pushGtmApproveRecallEvent();" data-ui-sref="payments.sign({paymentId: payment.id})"
                                      class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                      translate="paymentActions.approveRecall">Approve recall</button>
                            </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 t-a-c m-t-15">
                <button ng-if="paymentsReturn.paymentsOverviewList.hasMore == true" data-ng-click="paymentsReturn.loadMorePayments()" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn" translate="paymentsOverview.loadMoreBtn">Load more
                </button>
            </div>
        </div>
    </div>
</section>