<section id="main">
  <div class="container">
    <div class="row main-row">
      <div class="main-column col-xs-12">
        <!-- START Title -->
        <div class="row">
          <div class="col-md-12">
            <h2>
              <h2  class="f-28" ng-if="depositAccountsOverview.countryCode === 'hr'"  translate="depositAccountsOverview.title"></h2>
              <h2  class="f-28" ng-if="depositAccountsOverview.countryCode === 'sl'"  translate="depositAccountsOverview.titleForSlo"></h2>
              <h2 class="f-28" ng-if="depositAccountsOverview.countryCode == 'sr' || depositAccountsOverview.countryCode == 'mne'"
                  translate="depositAccountsOverview.titleForSerbAndMne"></h2>
              <h2 class="f-28" ng-if="depositAccountsOverview.countryCode == 'bh'" translate="depositAccountsOverview.titleForBh"></h2>
            </h2>
          </div>
        </div>
        <div class="row" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
          <div ng-switch-default class="m-b-15 col-md-6"
               ng-repeat="account in depositAccountsOverview.accounts | orderBy:['accountType.group', '-isFavorite', 'iban']"
               data-ng-click="depositAccountsOverview.goToAccount(account.accountId)"
               ng-class="{'col-md-6': !accountsOverview.multiAccounts.length}">
            <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
          </div>
          <div ng-switch-when="sl" class="m-b-15 col-md-6"
               ng-repeat="account in depositAccountsOverview.accounts | orderBy:['-isFavorite', '-isOwn', '-accountType.group']"
               data-ng-click="depositAccountsOverview.goToAccount(account.accountId)"
               ng-class="{'col-md-6': !accountsOverview.multiAccounts.length}">
            <div data-ng-include="'dashboard/AccountItemWidget.tpl.html'"></div>
          </div>
        </div>
        <!-- START Add New Deposit and New eSaving Account Buttons -->
        <div ng-if="depositAccountsOverview.countryCode === 'hr'" class="row">
          <div class="col-md-12">
            <div class="col-md-6 m-b-10 btn-padding-left">
                <div class="account-item-add app-dblue-card " ui-sref="orders.termDeposit" ng-click="depositAccountsOverview.pushGtmNavEvent('termDeposit');">
                <div class="round-btn-container2">
                  <div class="round-btn2">
                    <i class="icon-r icon-add"></i>
                  </div>
                  <div class="round-btn-label2">
                    <b translate="accountsOverview.newTermDepositButtonLabel"></b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 btn-padding-right">
                <div class="account-item-add app-dblue-card " ui-sref="newEbookNew" ng-click="depositAccountsOverview.pushGtmNavEvent('eBook');">
                <div class="round-btn-container2">

                  <div class="round-btn2">
                    <i class="icon-r icon-add"></i>
                  </div>
                  <div class="round-btn-label2" style="width: 180px">
                    <div ng-switch="depositAccountsOverview.countryCode" class="round-btn-label2">
                      <b ng-switch-default translate="accountsOverview.newESavingAccountButtonLabel"></b>
                      <b ng-switch-when="sl" translate="accountsOverview.newSavingButtonLabel_SLO"></b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END Add New Deposit and New eSaving Account Buttons -->
      </div>
    </div>
  </div>
</section>
