(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:CurrencyCalculatorModalCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('CurrencyCalculatorModalCtrl', CurrencyCalculatorModalCtrl);

  function CurrencyCalculatorModalCtrl($rootScope, $scope, $log, $filter, $timeout, $localStorage, ExchangeRatesService,
                                        CurrencyService, HelperService, ConfigEndpointService, GoogleTagManagerHelperService, $translate, NotificationService) {
    var vm = this;
    var currenciesFilter = {
      pageSize: 1000
    };
    vm.exchangeRatesList = {};
    vm.ratesDate = null;
    vm.fromCurrencyList = [];
    vm.toCurrencyList = [];
    vm.fromCurrencySelect = {};
    vm.localCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;;
    vm.toCurrencySelect = {};
    vm.fromAmountInput = '';
    vm.toAmountInput = '';
    vm.currencyList = []; //all currencies
    vm.exchangeType = {
      from: false,
      to: false
    };
    //vm.countryCode = $localStorage.code;
    vm.countryCode =  $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.exchangeRatesFilter = {
        includeList : ['currency']
    };

    var loadExchangeRatesList = function () {
      return ExchangeRatesService.getExchangeRates(vm.exchangeRatesFilter)
        .then(function (response) {
          if(vm.countryCode === 'sl' ) vm.ratesDate = findTimeOfUpdate(response);
          vm.exchangeRatesList = response;
        })
        .catch(function () {
          $log.catch('Error loading exchange rates!');
        });
    };

    var findTimeOfUpdate = function (currencyList) {
      var maxDate = currencyList.result[0].date;
      _.forEach(currencyList.result, function (item) {
        if(new Date(item.date) > new Date(maxDate)){
          maxDate = item.date;
        }
      });

      return maxDate;
    };

    var loadCurrencies = function () {
      CurrencyService.getCurrencyPage(currenciesFilter)
        .then(function (response) {
          vm.currencyList = response.result;
          setSelectedFrom();
          setToListDifferentFromLocal();
          //filterToCurrenciesList(vm.currencyList);
          //vm.toCurrencySelect = vm.toCurrencyList[0];

        }).catch(function (error) {
          $log.catch(error);
        });
    };

    var setSelectedFrom = function () {
      vm.fromCurrencyList = vm.currencyList;
      var index = _.findIndex(vm.fromCurrencyList, function (item) {
        return item.symbol == vm.localCurrency;
      });
      vm.fromCurrencySelect = vm.fromCurrencyList[index];

    };

    var filterFromCurrenciesList = function (currencyList) {
      vm.fromCurrencyList = _.filter(currencyList, function (item) {
        return item.symbol != vm.toCurrencySelect.symbol;
      });
      //vm.fromCurrencySelect = vm.fromCurrencyList[0];

    };

    var filterToCurrenciesList = function (currencyList) {
      vm.toCurrencyList = _.filter(currencyList, function (item) {
        return item.symbol != vm.fromCurrencySelect.symbol;
      });
      //vm.toCurrencySelect = vm.toCurrencyList[0];

    };


    vm.setFromCurrency = function () {
      vm.exchangeType = {
        from: true,
        to: false
      };
      vm.calculateCurrency();
    };

    vm.setToCurrency = function () {

      vm.exchangeType = {
        from: false,
        to: true
      };
      vm.calculateCurrency();
    };

    var prepareChangeObject = function () {

      if (vm.exchangeType.from) {
        return {
          currencySymbolFrom: vm.fromCurrencySelect.symbol,
          amount: vm.fromAmountInput,
          currencySymbolTo: vm.toCurrencySelect.symbol,
          fixedAmount: 'D'
        };
      } else if (vm.exchangeType.to) {
        return {
          currencySymbolFrom: vm.fromCurrencySelect.symbol,
          amount: vm.toAmountInput,
          currencySymbolTo: vm.toCurrencySelect.symbol,
          fixedAmount: 'C'
        };
      }
    };

    vm.closeModal = function () {
      //$uibModalInstance.close();
        GoogleTagManagerHelperService.pushEvent('ExchangeRatesCalculator', 'CloseExhangeRatesCalculator', 'Close button clicked');
      $scope.$parent.modalInstance.close();
    };

    vm.init = function () {
      //if (!$localStorage.code) {
      //  loadDefaultData().then(function () {
      //    loadBasicInfo();
      //  });
      //} else {
      //  loadBasicInfo();
      //}
      loadBasicInfo();
    };

    vm.calculateCurrency = function () {
      $timeout(function () {
        if (vm.fromAmountInput || vm.toAmountInput) {
          ExchangeRatesService.calculateExchangeRate(prepareChangeObject())
            .then(function (response) {
              if (vm.exchangeType.from)
                vm.toAmountInput = response.calculatedAmount;
              else if (vm.exchangeType.to)
                vm.fromAmountInput = response.calculatedAmount;
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        }
      }, 0);
    };

    var loadBasicInfo = function () {
      loadExchangeRatesList();
      if ($localStorage.code != 'mne') {
        //setLocalCurrency();
        loadCurrencies();
      }
    };

    var setToListDifferentFromLocal = function () {
      vm.toCurrencyList = vm.currencyList;
      var index = _.findIndex(vm.currencyList, function (item) {
        return item.symbol != vm.localCurrency;
      });
      vm.toCurrencySelect = vm.currencyList[index];
    };
    var setToListToLocal = function () {
      vm.toCurrencyList = vm.currencyList;
      var index = _.findIndex(vm.currencyList, function (item) {
        return item.symbol == vm.localCurrency;
      });
      vm.toCurrencySelect = vm.currencyList[index];
    };

    var setFromListDifferentFromLocal = function () {
      vm.fromCurrencyList = vm.currencyList;
      var index = _.findIndex(vm.currencyList, function (item) {
        return item.symbol != vm.localCurrency;
      });
      vm.fromCurrencySelect = vm.currencyList[index];
    };

    var setFromListToLocal = function () {
      vm.fromCurrencyList = vm.currencyList;
      var index = _.findIndex(vm.currencyList, function (item) {
        return item.symbol == vm.localCurrency;
      });
      vm.fromCurrencySelect = vm.currencyList[index];
    };

    vm.toCurrencyChange = function () {
      if (vm.toCurrencySelect.symbol == vm.localCurrency) {
        if (vm.fromCurrencySelect.symbol == vm.localCurrency)
          setFromListDifferentFromLocal();
      } else {
        setFromListToLocal();
      }
      vm.setToCurrency();
      //vm.calculateCurrency();
    };

    vm.fromCurrencyChange = function () {
      //vm.selectedFrom = vm.fromCurrencySelect;
      if (vm.fromCurrencySelect.symbol == vm.localCurrency) {
        if (vm.toCurrencySelect.symbol == vm.localCurrency)
          setToListDifferentFromLocal();
      } else {
        setToListToLocal();
      }
      //filterFromCurrenciesList(vm.currencyList);
      vm.setFromCurrency();
      //vm.calculateCurrency();
    };

    var loadDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response.APILocation) {
            $localStorage.code = response.APILocation;
            vm.countryCode = $localStorage.code;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    //var setLocalCurrency = function () {
    //  switch ($localStorage.code) {
    //    case "hr":
    //      vm.localCurrency = "HRK";
    //      break;
    //    case "sr":
    //      vm.localCurrency = "RSD";
    //      break;
    //    case "mne":
    //      vm.localCurrency = "EUR";
    //      break;
    //    case "sl":
    //      vm.localCurrency = "EUR";
    //      break;
    //  }
    //};

    vm.init();

  }
} ());
