(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardTimelineCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard')
    .controller('DashboardTimelineCtrl', DashboardTimelineCtrl);

  function DashboardTimelineCtrl() {
    var vm = this;
    vm.ctrlName = 'DashboardTimelineCtrl';

  }
}());
