<div>
  <div class="exchange-rate-header">
    <div class="row">
      <div ng-show="paymentsOverview.selectedPaymentForSign.id" class="col-sm-12 col-xs-6 f-20" translate="paymentSignModal.title"></div>
      <div ng-show="paymentsOverview.paymentsGroupList.id" class="col-sm-12 col-xs-6 f-20" translate="paymentsAndTransferOverview.groupedPaymentsLabel"></div>
    </div>
  </div>

  <!-- Actions for payments group -->
  <div ng-show="paymentsOverview.paymentsGroupList.id" class="row m-b-10">
    <span class="col-xs-12 m-t-20" ng-show="!paymentsOverview.areAllPaymentsDueDateValid"
          translate="signPayments.paymentsInvalidDueDate"></span>
          <span class="col-xs-12 payments-filter-active" ng-show="paymentsOverview.dueDateUpdateAllowed && !paymentsOverview.areAllPaymentsDueDateValid"
                data-ng-click="paymentsOverview.updateGroupPaymentDates()" translate="signPayments.updatePaymentsDueDate">
          </span>
    <div class="col-xs-12 m-t-15">
      <button class="btn btn-primary col-xs-3 pull-right" data-ng-click="paymentsOverview.signGroupPayments()"
              ng-disabled="!paymentsOverview.areAllPaymentsDueDateValid"
              translate="paymentsAndTransferOverview.signAllAction"></button>
    </div>
  </div>

  <!-- Actions for single payment -->
  <div ng-show="paymentsOverview.selectedPaymentForSign.id" class="row m-b-10">
    <span class="col-xs-12 m-t-20" ng-show="!paymentsOverview.isPaymentDueDateValid"
          translate="signPayments.singlePaymentInvalidDueDate"></span>
          <span class="col-xs-12 payments-filter-active" ng-show="paymentsOverview.dueDateUpdateAllowed && !paymentsOverview.isPaymentDueDateValid"
                data-ng-click="paymentsOverview.updateSinglePaymentDate()" translate="signPayments.updatePaymentDueDate">
          </span>
    <div class="col-xs-12 m-t-15">
      <button class="btn btn-primary col-xs-3 pull-right" data-ng-click="paymentsOverview.signSinglePayment()"
              ng-disabled="!paymentsOverview.isPaymentDueDateValid"
              translate="paymentSignModal.title"></button>
    </div>
  </div>

  <!-- Header for payments sign -->
  <div class="row group-sign-header p-t-20 p-b-10 m-t-15">
    <div class="col-xs-5">
            <span class="col-xs-6" translate="payments.fromAccount">
              From account
            </span>
            <span class="col-xs-6" translate="payments.toAccount">
              To account
            </span>
    </div>
          <span class="col-xs-2" translate="loanPayment.purpose" >
            Purpose
          </span>
          <span class="col-xs-2 t-a-r" translate="domesticPayment.amountInputLabel">
            Amount
          </span>
    <div class="col-xs-3">
            <span class="col-xs-6" translate="signPayments.dateCreated">
            Date created
            </span>
            <span class="col-xs-6" translate="signPayments.dueDate">
              Due date
            </span>
    </div>

  </div>

  <!-- Details for group of payments -->
  <div ng-if="paymentsOverview.paymentsGroupList.id" class="row group-sign-content" data-ng-repeat="payment in paymentsOverview.paymentsGroupList.paymentList">
    <div class="col-xs-5">
            <span class="col-xs-6">
            {{payment.fromAccountNumber}}
            </span>
            <span class="col-xs-6">
              {{payment.toAccountNumber}}
            </span>
    </div>
          <span class="col-xs-2 t-a-l" title="{{payment.purpose}}">
            {{ payment.purpose | limitTo: 40 }}{{payment.purpose.length > 40 ? '...' : ''}}
          </span>
          <span class="col-xs-2 t-a-r">
            {{payment.amount|number:2}} {{::payment.currencySymbol}}
          </span>
    <div class="col-xs-3">
            <span class="col-xs-6">
            {{payment.dateCreated| date:'shortDate' : '+0200'}}
            </span>
            <span class="col-xs-6">
              {{payment.dueDate| date:'shortDate' : '+0200'}}
            </span>
    </div>

  </div>

  <!-- Details for single payment -->
  <div ng-if="paymentsOverview.selectedPaymentForSign.id" class="row group-sign-content">
    <div class="col-xs-5">
            <span class="col-xs-6">
            {{paymentsOverview.selectedPaymentForSign.fromAccountNumber}}
            </span>
            <span class="col-xs-6">
              {{paymentsOverview.selectedPaymentForSign.toAccountNumber}}
            </span>
    </div>
          <span class="col-xs-2 t-a-l" title="{{payment.purpose}}">
            {{ paymentsOverview.selectedPaymentForSign.purpose | limitTo: 40 }}{{paymentsOverview.selectedPaymentForSign.purpose.length > 40 ? '...' : ''}}
          </span>
          <span class="col-xs-2 t-a-r">
            {{paymentsOverview.selectedPaymentForSign.amount|number:2}} {{::paymentsOverview.selectedPaymentForSign.currencySymbol}}
          </span>
    <div class="col-xs-3">
            <span class="col-xs-6">
            {{paymentsOverview.selectedPaymentForSign.dateCreated| date:'shortDate' : '+0200'}}
            </span>
            <span class="col-xs-6">
              {{paymentsOverview.selectedPaymentForSign.dueDate| date:'shortDate' : '+0200'}}
            </span>
    </div>

  </div>
</div>
