(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentSignModalCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentSignModalCtrl', PaymentSignModalCtrl);

  function PaymentSignModalCtrl(paymentId, PaymentsService, $uibModalInstance) {
    var vm = this;
    vm.formSubmitted = false;

    vm.signPayment = function () {
      if (!vm.signPaymentForm.$valid) {
        return;
      }
      if(!vm.formSubmitted){
        vm.formSubmitted = true;
        PaymentsService.signPayment(paymentId)
          .then(function (response) {
            $uibModalInstance.close(true);
          })
          .catch(function (error) {
            $uibModalInstance.dismiss(error);
          })
          .finally(function(){
            vm.formSubmitted = false;
          });
      }
    };

    vm.closeModal = function (){
      $uibModalInstance.close(false);
    };
  }
}());
