(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:CloseEBookCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('CloseEBookCtrl', CloseEBookCtrl);

  function CloseEBookCtrl($rootScope, $state, $stateParams, $translate, AccountsService, HelperService, AlertService, NotificationService) {
    var vm = this;
    vm.model;
    var accountId;
    var sweetAlertOptions;
    vm.ebookName = '';
    vm.ebookNumber = '';
    vm.transferToAccount = '';

    init();

    function init() {
      vm.model = {};
      sweetAlertOptions = {};
      accountId = $stateParams.accountId;
      translateSweetAlert();
      loadData();
    }

    function loadData() {
      AccountsService.eSavingBookCloseOverview(accountId)
        .then(function (response) {
          vm.eSavingBookCloseList = response.eSavingBookCloseList;
       if (vm.eSavingBookCloseList.length > 0) {
            vm.ebookName = vm.eSavingBookCloseList[0].accountName;
            vm.ebookNumber = vm.eSavingBookCloseList[0].accountId;
            vm.transferToAccount = vm.eSavingBookCloseList[1].transferAccount;
            var tempList = [];
            for (var i = 1; i < vm.eSavingBookCloseList.length; i++) {
              tempList[i-1] = vm.eSavingBookCloseList[i];
            }
            vm.eSavingBookCloseList = tempList;
          }
        }, function (error) {});
    }

    function translateSweetAlert() {
      $translate(["core.sweetAlertTranslation.yes", "core.sweetAlertTranslation.no", "core.sweetAlertTranslation.areYouSure"]).then(function (translations) {
        sweetAlertOptions.yes = translations["core.sweetAlertTranslation.yes"];
        sweetAlertOptions.no = translations["core.sweetAlertTranslation.no"];
        sweetAlertOptions.areYouSure = translations["core.sweetAlertTranslation.areYouSure"];
      });
    }

    /*
     * Triggered when language is changed
     * */
    $rootScope.$on("appLanguageChangedHeader", function () {
      translateSweetAlert();
    });

    vm.confirm = function () {
      //swal({
      //    title: sweetAlertOptions.areYouSure,
      //    type: "info",
      //    showCancelButton: true,
      //    confirmButtonClass: "btn-default",
      //    confirmButtonText: sweetAlertOptions.yes,
      //    cancelButtonText: sweetAlertOptions.no,
      //    closeOnConfirm: true,
      //    closeOnCancel: true
      //  },
      //  function (isConfirm) {
      //    if (isConfirm) {
      //      AccountsService.updateAccount(accountId, {
      //          "closeESavingBook": true
      //        })
      //        .then(function (response) {
      //        $state.go("depositAccountsOverview");
      //        }, function (error) {
      //          NotificationService.showMessage(error, 'error')
      //        })
      //    }
      //  });

      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            AccountsService.updateAccount(accountId, {
                "closeESavingBook": true
              })
              .then(function (response) {
                AlertService.infoAlert({
                  text: 'alert.closeEBook'
                })
                  .then(function (){
                    $state.go("depositAccountsOverview");
                  })
              }, function (error) {
                NotificationService.showMessage(error, 'error')
              })
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });

    };

    vm.cancel = function () {
      $state.go("accounts", {
        accountId: accountId
      });
    }

  }
}());
