<div class="statementModal logout-modal-frame"  >
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div  style="color: white; padding-top: 50px;">

        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">

      <div class="col-xs-12">
        <div class="logout-modal-header" >{{internationalPayment.msg}}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose logout-btns" ng-click="$close('yes')" translate="domesticPayment.changePaymentModal.yes">Yes</button>
    <button class="btn btn-primary-red statementBtnClose logout-btns" ng-click="$close()" translate="domesticPayment.changePaymentModal.no">No</button>
  </div>
</div>
