(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      navigation: {
        "dashboard": "Dashboard",
        "accountsAndCards": "Accounts and Cards",
        "accounts": "Accounts",
        "payments": "Payments",
        "deposit": "Deposit and e-books",
        "loans": "Loans",
        "investments": "Investments",
        "settings": "Settings",
        "complaints": "Complaints",
        "security": "Security",
        "messages": "Messages",
        "hypoClub": "Addiko club",
        "eTrade": "Addiko ETrade",
        "contact": "Contact",
        "paymentsAndTransfers" :"Payments & Transfers overview",
        "domesticPayment": "Domestic payment",
        "domesticPaymentSl":"Universal payment order",
        "transferToMyAccount": "Transfer to my account",
        "internationalPayment": "International payment",
        "exchangeOffice" :"Exchange office",
        "exchangeRates" :"Exchange rates",
        "standingOrders": "Standing orders",
        "eInvoices": "eInvoices",
        "eInvoicesSlo": "EInvoices / eDocuments",
        "paymentsList": "List of payments",
        "paymentsReturn": "Payments return",
        "directDebitsList": "List of SEPA direct debits",
        "myTemplatesList": "List of my templates",
        "allAccountsOverview": "All accounts overview",
        "allDepositOverview": "All savings",
        "allDeposits": "Savings",
        "allDepositOverviewSerbMne": "Deposits",
        "loansOverview": "Loans overview",
        "investmentsOverview": "Investments overview",
        "availableBalance": "Available balance",
        "loanBalance": "Loan balance",
        "newMessages": "New messages: ",
        "newMessagesSlo": "New personal messages: ",
        "employeePayroll": "Employee payroll info",
        "allowedLoan":"Approved amount",
        "balance": "Balance",
        "newOrder":"Buy  stocks",
        "sepa": "SEPA DD Settings",
        "sepa_CRO": "SEPA direct debit limit",
        "documents":"Documents",
        "sepaTransactions":"SEPA DD Overview",
        "sepaTransactions_CRO":"SEPA direct debit",
        "withdrawal1":"Change account",
        "withdrawal2":"overdraft",
        "changeATMWithdrawal1":"Change of",
        "changeATMWithdrawal2":"daily limit",
        "reorderPin":"PIN Reorder",
        "masterCardOrder":"Mastercard limit",
        "viberActivation": "Activate Viber",
        "deactivateViber": "Deactivate Viber",
        "viberActivationTxt": "Confirm Viber activation?",
        "viberGenerateNewPassword": "Viber is already activated. Do you want new code for activation?",
        "viberBanking":"Addiko Chat Banking",
        "secure3d": "Secure online shopping",
        "unreadDocuments": "Unread documents: ",
        "directDebit":"Direct debit - refuse payment order",
	      "complaintsLink": "Predlozi, prigovori i sugestije",
        "availableBalanceCard": "Raspoloživi iznos kartičnog računa"
      }
    };

    $translateProvider
      .translations('en', data);

  }
}());
