(function () {
  'use strict';

  /* @ngdoc object
   * @name localization
   * @description
   *
   */
  angular
    .module('localization', [
      'ui.router',
      'pascalprecht.translate'
    ]);
}());
