(function () {
  'use strict';

  angular
    .module('eInvoices')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('eInvoices', {
      url: '/e-invoices'
      // templateUrl: 'e-invoices/e-invoices.tpl.html',
      // controller: 'EInvoicesCtrl',
      // controllerAs: 'eInvoices'
    })
      .state('eInvoices.detail', {
      url: '/detail/:eInvoiceId',
      views: {
        '@': {
          templateUrl: 'e-invoices/e-invoices-detail.tpl.html',
          controller: 'EInvoicesDetailCtrl',
          controllerAs: 'eInvoicesDetail'
        }
      }
    })
      .state('eInvoices.einvoicesOverview', {
      url: '/overview',
        params: {
          tabToSelect: null
        },
      views: {
        '@': {
          templateUrl: 'e-invoices/einvoices-overview.tpl.html',
          controller: 'EinvoicesOverviewCtrl',
          controllerAs: 'einvoicesOverview'
        }
      }
    }).state('eInvoices.einvoicesIssuersOverview', {
      url: '/issuers-overview',
      views: {
        '@': {
          templateUrl: 'e-invoices/einvoices-issuers-overview.tpl.html',
          controller: 'EinvoicesIssuersOverviewCtrl',
          controllerAs: 'einvoicesIssuersOverview'
        }
      }
    }).state('eInvoices.einvoiceSubscribe', {
      url: '/subscribe/:issuerId',
      views: {
        '@': {
          templateUrl: 'e-invoices/einvoice-subscribe.tpl.html',
          controller: 'EinvoiceSubscribeCtrl',
          controllerAs: 'einvoiceSubscribe'
        }
      }
    }).state('eInvoices.einvoicesRegisterNew', {
      url: '/register',
      params: {
        myParam: {}
      },
      views: {
        '@': {
          templateUrl: 'e-invoices/einvoices-register.tpl.html',
          controller: 'EinvoicesRegisterCtrl',
          controllerAs: 'vm'
        }
      }
    }).state('eInvoices.archive', {
      url: '/archive/:eInvoiceId',
      views: {
        '@': {
          templateUrl: 'e-invoices/e-invoice-archive.tpl.html',
          controller: 'EInvoicesArchiveCtrl',
          controllerAs: 'eInvoicesArchive'
        }
      }
    }).state('eInvoices.pay', {
      url: '/pay/:eInvoiceId',
      views: {
        '@': {
          templateUrl: 'e-invoices/e-invoices-pay.tpl.html',
          controller: 'EinvoicesPayCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
}());
