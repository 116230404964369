(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      eInvoicesModal: {
        "headerLabel": "eInvoice Payment",
        "instructionLabel": "Select payment date and confirm payment",
        "issuerLabel": "Issuer",
        "purposeLabel": "Purpose",
        "amountLabel": "Amount",
        "refNumberLabel": "Reference number",
        "paymentDateLabel": "Payment date",
        "today": "Today",
        "dueDate": "Due date",
        "otherDate": "Other date",
        "btnCancel": "Cancel",
        "btnPay": "Pay",
        "subscribeOrCancel": "Subscribe to a new eInvoice or cancel an existing subscription",
        "submitted": "Your submitted applications",
        "showIssued": "Show eInvoices issued in ",
        "showAuthorizedEinvoices": "Show eInvoices on authorized accounts",
        "waitingForPayment": "Unpaid eInvoices",
        "waitingForPaymentSlo": "Received eInvoices",
        "payedAndRemoved": "Payed and removed eInvoices",
        "loadMoreEinvoices":"Load more",
        "loadMoreRegistrationsDeregistrations": "Load more",
        "einvoices": "eInvoices",
        "einvoicesSlo": "eInvoices and other eDocuments",
        "view": "View",
        "pay": "Pay",
        "noData": "No eInvoices data for selected period",
        "fromAccountPayLabel": "Pay from account: ",
        "availableAmountLabel": "Available amount: ",
        "viewEinvoice": "View eInvoice",
        "amountPlaceholder": "eg. 1,000.00",
        "btnSign": "Sign",
        "btnEdit": "Edit",
        "signHeaderLabel": "Verify eInvoice and sign",
        "fromAccount": "From account",
        "toAccount": "To account",
        "receiver": "Recipient",
        "receiverAddress": "Recipient's address",
        "amount": "Amount",
        "description": "Description",
        "status": "Status",
        "bankName": "Bank name",
        "bankAddress": "Bank address",
        "enterComment": "Enter comment",
        "delete": "Delete",
        "edit": "Edit",
        "sign": "Sign",
        "listOfRegistrations":"List of registrations and deregistrations",
        "processedTab": "Paid and other e-invoices",
        "processedTabSlo": "Paid and other eDocuments",
        "processed": "Processed",
        "archived": "Archived",
        "rejected": "Rejected",
        "noAvailableEInvoices": "No eInvoices data available",
        "creditorNameSearch": "Search by creditor name",
        "issuerIban":"Issuer IBAN",
        "issuerAddress":"Issuer address",
        "requestType":"Request type",
        "gsm":"Your GSM number",
        "subscribe":"Register",
        "unsubscribe":"Deregister",
        "paymentNotPossible": "Payment not possible",
        "paidByOtherMeans": "Paid by other means",
        "paymentWithDirectDebit": "Payment with direct debit",
        "withdrawn": "Withdrawn",
        "inProgressByOther": "Processing for another user",
        "msg1":"Are you sure you want to remove the selected e-invoice between paid and other e-invoices ?",
        "msg2":"Payment of e-invoice via Addiko EBank after the withdrawal will no longer be possible.",
        "cancel":"Cancel",
        "confirm":"Confirm",
        eDocumentType: {
          'eInvoice': 'eInvoice',
          'eProforma':'eProforma',
          'eWarning':'eWarning',
          'eCreditNote':'eCreditNote',
          'eDebitNote':'eDebitNote',
          'ePurchaseOrder':'ePurchaseOrder',
          'eDeliveryNote':'eDeliveryNote'
        },
        rejectDocumentAlert: {
          headerText: 'Would you like to reject selected document?',
          labelText: 'Please, write a short explanation for the rejection, that will be sent to the document issuer.'
        }
      },
      eInvoiceDetail: {
        "attachments": "Attachments",
        "eInvoicePDF": "eInvoice in PDF",
        "eInvoiceXML": "eInvoice in XML",
        "export": "Export",
        "unsubscribeEInvoices": "Unsubscribe from this eInvoice",
        "detail": "eInvoices details",
        "unpaidInvoice": "Unpaid invoice",
        "pay": "Pay",
        "markAsPayed": "Mark as payed/archive",
        "issuer": "Issuer",
        "issuerAccount": "Issuer account",
        "purpose": "Purpose",
        "refNumber": "Reference number",
        "amount": "Amount",
        "issueDate": "Issue date",
        "purposeCode": "Purpose code",
        "dueDate": "Due date",
        "digitalSignature": "Digital signature status",
        "visibilityDigitalSignature": "Digital signature information",
        "signatureId": "Signature ID",
        "digitalCertNumber": "Digital certificate number",
        "signatureName": "Signature name",
        "certificateIssuer": "Digital certificate issuer",
        "showInfo": "Show ",
        "hideInfo": "Hide ",
        "recipient": "Recipient",
        "recipientAccount": "Recipient account",
        "noAttachments": "eInvoice has no attachments.",
        "noDocAttachments": "eDocument has no attachments.",
        "goBack": "Go back to eInvoices",
        "issueDateOfEinvoice":"Issue date of eInvoice",
        "paymentDesc":"Payment description",
        "archive":"Archive",
        "showDigStatus":"Show digital signature status",
        "issuerIBAN":"Issuer IBAN",
        "placeOfReg":"Place of registration",
        "dateOfReg":"Registration/Deregistration date",
        "cancelReg":"Cancel registration",
        "cancel":"Cancel",
        "regAgain":"Register again",
        "regAgainSlo":"Register/Deregister again",
        "rejectReason":"Reject reason",
        "inProcess":"InProcess",
        "rejected":"Rejected",
        "registered":"Registered",
        "unregistered":"Unregistered",
        "activeRegs":"Active registrations",
        "cancelledRegs":"Cancelled registrations",
        "noteArchived": "Comment",
        "noteRejected": "Comment",
        "serviceName": "Service name",
        "download": "Download",
        "orderType": "Order type",
        "registration": "Registration",
        "deregistration": "Deregistration",
        "withdraw":"Withdraw",
        "valid": "Valid",
        "invalid": "Invalid",
        "otherInfo": "Other information",
        "statusOfEApplication": "Status of E-invoice",
        "statusOfEApplicationOpomina": "Status E-warning",
        "statusOfEApplicationPredracun": "Status E-proforma",
        "paymentId": "Payment ID"

      },
      eInvoicesIssuersOverview: {
        "submittedApplications": "Your submitted applications",
        "header": "Select eInvoice issuer",
        "issuerName": "Issuer name",
        "issuerTaxId": "Issuer tax ID",
        "searchByNamePlaceholder": "Search by name",
        "searchByTaxIdPlaceholder": "Search by tax ID",
        "showMore": "Show more issuers",
        "subscribe": "Subscribe",
        "issuerTax": "Tax ID / Account",
        "issuerAddress": "Issuer address",
        "noData": "No eInvoices issuers data for selected period"

      },
      eInvoicesSubscribe: {
        "goBack": "Go back to previous page",
        "subscribeToEinvoice": "Subscribe to eInvoice",
        "einvoiceIssuer": "eInvoice issuer",
        "einvoiceIssuerSlo": "Issuer tax number - Issuer name",
        "issuerAddress": "Issuer address",
        "issuerAccount": "Issuer account",
        "referenceFromIssuer": "Reference from issuer",
        "einvoiceReceiver": "eInvoice receiver",
        "receiversName": "Receivers name",
        "receiversAddress": "Receivers address",
        "receiversTaxId": "Receivers Tax ID",
        "yourContactEmail": "Your contact email",
        "tooltipInfo": "Need help?",
        "tooltipInfoEmail": "If you are changing the email address - the change will be applied only to the registration of eInvoice service.",
        "einvoiceReferenceFromIssuerInputError": "Reference number is required!",
        "einvoiceSubscribeEmailInputError": "Your contact email is required!",
        "einvoiceIssuerInputError": "Issuer is required!",
        "reviewandSubmit": "Review and submit",
        "changeEmail": "Change email",
        "updateEmail": "Update email",
        "cancel": "Cancel",
        "ok": "OK",
        "statement":"Statement",
        "termsAndConditions": "I agree not to receive the invoices in paper form",
        "termsAndConditionsSlo": "With the electronic submission of my application I am confirming that I am familiar with the above stated General Terms and Conditions.",
        "statementTitle":"General Terms and Conditions of the Bank",
        "statementText":"I accept the ",
        "statementTextLink1":"General Terms and Conditions for Managing the Transaction Account for Private Individuals and Provision of Payment Services ",
        "statementTextAnd":"and ",
        "statementTextLink2":"Addiko EBank Terms and Conditions",
        "statementTextEnd":"",
        "specialConditions": "I accept special terms of the issuer",
        "newRegistration": "New eInvoice registration/deregistration",
        "registrationNew": "New registration",
        "register": "Register",
        "email": "E-mail",
        "applicationComplete": "Application complete",
        "applicationCompleteText": "You have successfully sent the eInvoice application for the issuer {{name}}.",
        "applicationCompleteTextSlo": "Application for e-Invoice registration/deregistration was successfully submitted for the issuer {{name}}.",
        "selectIssuer": "Select issuer",
        "goToList": "Go to eInvoice list",
        "goToListSlo": "Go To List of Registration",
        "generalTerms": "I accept the general terms and conditions",
        "referenceExplanation":"Check issuers explanations of entering reference",
        "statementTitle2":"General Terms and Conditions of the Issuer",
        "statementText2":"General terms are published on our web page"

      },
      eInvoiceRegistration: {
        "all": "All",
        listOfRegistrations: "List of registrations and deregistrations",
        "unregistered": "Unregistered",
        "Unregistered": "Unregistered",
        "rejected": "Rejected",
        "Rejected": "Rejected",
        "rejectedSlo": "Rejected",
        "registered": "Registered",
        "Registered": "Registered",
        "inProcess": "In process",
        "InProcess":"Submitted",
        "inProcessSlo": "Submitted",
        "sent":"Sent",
        "Sent":"Sent",
        "undelivered":"Undelivered",
        "Undelivered":"Undelivered",
        "Delivered":"Delivered",
        "delivered":"Delivered"
      },
      eInvoiceOverview:{
        "listOfEinvoices":"List of eInvoices",
        "eInvoicesToPay":"eInvoices to pay",
        "register":"Register/Deregister issuer",
        "pay":"Pay",
        "success":"Success!",
        "archivedEinvoces":"Archived eInvoices",
        "successfulyArchived": "You have successfuly archived eInvoice",
        "copy": "Copy payment",
        "inProgress": "In progress",
        "documentsTab": "eDocuments",
        "reject": "Reject"
      },
      eInvoicePayment: {
        "recAccount":"Recipient account",
        "purpose":"Purpose",
        "accNumber":"Account number",
        "toPayee":"To recipient",
        "recAddress":"Recipient's address",
        "recPostalCity":"Recipient's postal code and city",
        "instantDateError": "If payment is tagged as instant, only today's date is allowed as payment date.",
      },
      eInvoiceStatus: {
        "InProcess":"In process",
        "Unregistered":"Unregistered",
        "Registered":"Registered",
        "Rejected":"Rejected",
        "Undelivered":"Undelivered",
        "Delivered":"Delivered",
        "Sent":"Sent",
        "InProgress": "In progress",
        "Inserted":"Inserted",
        "Processed": "Processed",
        "Archived": "Archived",
        "PaymentNotPossible": "Payment not possible",
        "PaidByOtherMeans": "Paid by other means",
        "PaymentWithDirectDebit": "Payment with direct debit",
        "Withdrawn": "Withdrawn",
        "InProgressByOther": "Processing for another user",
        "Reviewed": "Reviewed",
        "Cancelled": "Payment cancelled"
      }
    };

    $translateProvider
      .translations('en', data);
  }
}());
