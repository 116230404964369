(function () {
    'use strict';
    angular.module('core').directive('uploadMultiFiles', function () {
      return {
        restrict: 'A',
        scope: {
          files: '=',
          fileObject: '='
        },
        link: function (scope, element, attrs) {
          var checkSize, isTypeValid, processDragOverOrEnter, validMimeTypes;
          processDragOverOrEnter = function(event) {
            if (event !== null) {
              event.preventDefault();
            }
            event.originalEvent.dataTransfer.effectAllowed = 'copy';
            return false;
          };
          validMimeTypes = attrs.fileDropzone;
          checkSize = function (size) {
            var _ref;
            if (((_ref = attrs.maxFileSize) === (void 0) || _ref === '') || (size / 1024) / 1024 < attrs.maxFileSize) {
              return true;
            } else {
              alert('File must be smaller than ' + attrs.maxFileSize + " MB");
              return false;
            }
          };
          isTypeValid = function(type) {
            if ((validMimeTypes === (void 0) || validMimeTypes === '') || validMimeTypes.indexOf(type) > -1) {
              return true;
            } else {
              alert('Invalid file type.  File must be one of following types ' + validMimeTypes);
              return false;
            }
          };
          element.bind('dragover', processDragOverOrEnter);
          element.bind('dragenter', processDragOverOrEnter);
          return element.bind('drop', function(event) {
                scope.files = [];
              for (var index = 0; index < event.originalEvent.dataTransfer.files.length; index++) {
                    var sFile = event.originalEvent.dataTransfer.files[index];
                    scope.files.push({
                        data: null,
                        file: sFile,
                        name: sFile.name,
                        type: sFile.type,
                        size: sFile.size
                    });
                }
                scope.fileObject = event.originalEvent.dataTransfer.files;

            var counterTemp = 0;

            var reader = new FileReader();
            reader.onload = function (evt) {
                if (checkSize(scope.files[counterTemp].size) && isTypeValid(scope.files[counterTemp].type)) {
                    return scope.$apply(function () {
                        scope.files[counterTemp].data = evt.target.result;
                        if (counterTemp === scope.files.length - 1) {
                            // do nothing
                        } else {
                            reader.readAsDataURL(scope.files[++counterTemp].file);
                        }
                    });
                }
            };

            reader.readAsDataURL(scope.files[counterTemp].file);

            return false;
          });
        }
      };
    });

  }).call(this);
