<div>
  <div ng-if="smsAndNotificationModal.step != 3" class="modal-header smsModalHeader">
    <div class="row">
      <div class="col-xs-12">{{smsAndNotificationModal.object.orderObject.description}}</div>
    </div>

  </div>
  <div class="p-t-20" ng-if="smsAndNotificationModal.step == 1">
    <ng-form name="smsAndNotificationModal.smsAndNotifForm">


    <div class="modal-body">
      <!-- FOR ACCOUNT / NAME -->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label ng-if="!smsAndNotificationModal.object.accountBased" class="domp-form-label-review"
                   translate="settings.smsModal.user"></label>
            <label ng-if="smsAndNotificationModal.object.accountBased" class="domp-form-label-review"
                   translate="settings.smsModal.forAccount"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="!smsAndNotificationModal.object.accountBased" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.firstName}} {{smsAndNotificationModal.object.user.lastName}}</label>
            <label ng-if="smsAndNotificationModal.object.accountBased" class="domp-form-label-resume">{{smsAndNotificationModal.object.account.iban | formatIban}}</label>
          </div>
        </div>
      </div>
      <!-- FOR ACCOUNT / NAME END -->

      <!--ORDER DESC-->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label class="domp-form-label-review" translate="settings.smsModal.orderDesc"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="!smsAndNotificationModal.object.value && smsAndNotificationModal.object.type == 'SMS'"
                   class="domp-form-label-resume" translate="settings.smsModal.receivingSmsStart"></label>
            <label ng-if="smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-resume"
                   translate="settings.smsModal.receivingSmsStop"></label>
            <label ng-if="!smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'EMAIL'"
                   class="domp-form-label-resume" translate="settings.smsModal.receivingEmailStart"></label>
            <label ng-if="smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-resume"
                   translate="settings.smsModal.receivingEmailStop"></label>
          </div>
        </div>
      </div>
      <!--ORDER DESC END-->

      <!--MOBILE PHONE / SMS-->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label ng-if="smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-review"
                   translate="settings.smsModal.email"></label>
            <label ng-if="smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-review"
                   translate="settings.smsModal.gsm"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.notificationEmail}}</label>
            <label ng-if="smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.gsm}}</label>
          </div>
        </div>
      </div>
      <!--MOBILE PHONE / SMS END-->

      <!-- ADDITIONAL (for specific order id == 30)-->
      <!--PERIOD & AMOUNT-->
      <div ng-if="(smsAndNotificationModal.object.orderObject.subOrderId == '30') ">
        <div  class="row p-b-10">
          <div class="col-xs-12">
            <div class="col-xs-12  col-sm-4 p-t-10">
              <label class="domp-form-label-review" translate="settings.smsModal.period"></label>
            </div>
            <div ng-if="!smsAndNotificationModal.object.value" class="col-xs-12 col-sm-6">
              <div class="row m-t-10 m-b-10">
                <div class="col-xs-6 col-sm-4">
                  <input type="radio" name="optradio" ng-model="smsAndNotificationModal.periodType" ng-value="smsAndNotificationModal.periods.periodTypes[0]">
                  <span translate="settings.smsModal.daily"></span></div>
                <div class="col-xs-6 col-sm-8"></div>
              </div>
              <div class="row m-t-10 m-b-10">
                <div class="col-xs-6 col-sm-4 p-t-10">
                  <input type="radio" name="optradio" ng-model="smsAndNotificationModal.periodType"  ng-value="smsAndNotificationModal.periods.periodTypes[2]">
                  <span translate="settings.smsModal.weekly"></span></div>
                <div class="col-xs-6 col-sm-8">
                  <select ng-disabled="smsAndNotificationModal.periodType.id != 'W'" name="weeklyTimePeriod"
                          class="form-control input-field p-l-15 p-r-10"
                          ng-options="option.description | translate for option in smsAndNotificationModal.periods.weeklyTimePeriods"
                          ng-model="smsAndNotificationModal.weeklyTimePeriod">
                  </select>
                </div>
              </div>
              <div class="row m-t-10 m-b-10">
                <div class="col-xs-6 col-sm-4 p-t-10">
                  <input type="radio" name="optradio" ng-model="smsAndNotificationModal.periodType"  ng-value="smsAndNotificationModal.periods.periodTypes[1]">
                  <span translate="settings.smsModal.monthly"></span></div>
                <div class="col-xs-6 col-sm-8">
                  <select ng-disabled="smsAndNotificationModal.periodType.id != 'M'" name="monthlyTimePeriod"
                                              class="form-control input-field p-l-15 p-r-10"
                                              ng-options="option.description for option in smsAndNotificationModal.periods.monthlyTimePeriods"
                                              ng-model="smsAndNotificationModal.monthlyTimePeriod">
                </select></div>
              </div>
            </div>
            <div ng-if="smsAndNotificationModal.object.value" class="col-xs-12 col-sm-6 p-t-10">
              <label class="domp-form-label-resume">{{smsAndNotificationModal.periodType.description | translate}}<span ng-if="smsAndNotificationModal.period">, {{smsAndNotificationModal.period.description}}</span></label>
            </div>
          </div>
        </div>
        <div class="row p-b-10">
          <div class="col-xs-12">
            <div class="col-sm-4 p-t-10">
              <label class="domp-form-label-review"  translate="settings.smsModal.dailyTimePeriod"></label>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <select ng-if="!smsAndNotificationModal.object.value" name="dailyTimePeriod"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in smsAndNotificationModal.periods.dailyTimePeriods"
                        ng-model="smsAndNotificationModal.dailyTimePeriod">
                </select>
                <label ng-if="smsAndNotificationModal.object.value" class="domp-form-label-resume p-t-10">{{ smsAndNotificationModal.dailyTimePeriod.description }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--PERIOD & AMOUNT END-->

      <!-- ADDITIONAL (for specific order id == 40 or 50)-->
      <!--PERIOD -->
      <div ng-if="(smsAndNotificationModal.object.orderObject.subOrderId == '40' || smsAndNotificationModal.object.orderObject.subOrderId == '50') ">
        <div class="row p-b-10">
          <div class="col-xs-12">
            <div class="col-sm-4 p-t-10">
              <label class="domp-form-label-review"  translate="settings.smsModal.dailyTimePeriod"></label>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <select ng-if="!smsAndNotificationModal.object.value" name="dailyTimePeriod"
                        class="form-control input-field p-l-15 p-r-10"
                        ng-options="option.description for option in smsAndNotificationModal.periods.dailyTimePeriods"
                        ng-model="smsAndNotificationModal.dailyTimePeriod">
                </select>
                <label ng-if="smsAndNotificationModal.object.value" class="domp-form-label-resume p-t-10">{{ smsAndNotificationModal.dailyTimePeriod.description }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--PERIOD & AMOUNT END-->

      <!--AMOUNT-->
      <div ng-if="smsAndNotificationModal.tempObj.showAmount" class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4 p-t-10">
            <label ng-if="smsAndNotificationModal.object.orderObject.subOrderId !== '70'" class="domp-form-label-review" translate="settings.smsModal.noticeAmount"></label>
            <label ng-if="smsAndNotificationModal.object.orderObject.subOrderId === '70'" class="domp-form-label-review" translate="settings.smsModal.noticeAmountAbove"></label>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <input ng-if="!smsAndNotificationModal.object.value " type="text" class="form-control input-field t-a-r" name="noticeAmount" id="noticeAmount"
                     amount-input-mask max-amount="99999999999.99" required
                     placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                     maxlength="17"
                     ng-class="{'domp-error': smsAndNotificationModal.smsAndNotifForm.noticeAmount.$invalid && smsAndNotificationModal.tempObj.formSubmitted}"
                     ng-model="smsAndNotificationModal.tempObj.amount">
              <label ng-if="smsAndNotificationModal.object.value" class="domp-form-label-resume p-t-10">{{ smsAndNotificationModal.tempObj.amount.replace(",",".") | currency:"" }}</label>
            </div>
          </div>
        </div>
      </div>
      <!--AMOUNT END-->

      <div class="row">
        <div class="col-xs-12 p-l-30 p-t-20">
          <label class="checkbox checkbox-inline-inline checkbox-value">
            <input type="checkbox"
                   ng-model="smsAndNotificationModal.tempOrderObj.agreeTerms">
            <i class="input-helper"></i>
            <span
              ng-if="smsAndNotificationModal.object.type == 'EMAIL' && !smsAndNotificationModal.object.value"
              translate="settings.smsModal.orderNoticeStart"></span>
            <span ng-if="smsAndNotificationModal.object.value"
                  translate="settings.smsModal.orderNoticeStop"></span>
            <span
              ng-if="smsAndNotificationModal.object.type == 'SMS' && !smsAndNotificationModal.object.value">
              <span translate="settings.smsModal.orderSmsStart1"></span>
              <span><a class="underline" href="https://www.addiko.si/splosni-pogoji" translate="settings.smsModal.orderSmsStartLink" target="_blank"></a></span>
              <span translate="settings.smsModal.orderSmsStart2"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-xs-12">

        <button class="btn btn-primary pull-left" translate="settings.smsModal.cancel" ng-click="$close()"></button>
        <button ng-disabled="!smsAndNotificationModal.tempOrderObj.agreeTerms" class="btn btn-default pull-right"
                ng-click="smsAndNotificationModal.next();"
                translate="settings.smsModal.next"></button>

      </div>
    </div>
    </ng-form>
  </div>

  <!--COMMON STEP 2 START-->
  <div class="p-t-20" ng-if="smsAndNotificationModal.step == 2">

    <div class="modal-body">
      <!-- ORDER DATE -->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label class="domp-form-label-review"
                   translate="settings.smsModal.orderDate"></label>
          </div>
          <div class="col-sm-6">
            <label class="domp-form-label-resume">{{smsAndNotificationModal.orderDate | date:'dd.MM.yyyy HH:mm:ss' }}</label>
          </div>
        </div>
      </div>
      <!-- ORDER DATE END -->

      <!-- ORDER TYPE -->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label class="domp-form-label-review"
                   translate="settings.smsModal.orderType"></label>
          </div>
          <div class="col-sm-8">
            <label class="domp-form-label-resume">{{smsAndNotificationModal.object.orderObject.description}}</label>
          </div>
        </div>
      </div>
      <!-- FORDER TYPE END -->

      <!-- FOR ACCOUNT / NAME -->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label ng-if="!smsAndNotificationModal.object.accountBased" class="domp-form-label-review"
                   translate="settings.smsModal.user"></label>
            <label ng-if="smsAndNotificationModal.object.accountBased" class="domp-form-label-review"
                   translate="settings.smsModal.forAccount"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="!smsAndNotificationModal.object.accountBased" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.firstName}} {{smsAndNotificationModal.object.user.lastName}}</label>
            <label ng-if="smsAndNotificationModal.object.accountBased" class="domp-form-label-resume">{{smsAndNotificationModal.object.account.iban | formatIban}}</label>
          </div>
        </div>
      </div>
      <!-- FOR ACCOUNT / NAME END -->

      <!--ORDER DESC-->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label class="domp-form-label-review" translate="settings.smsModal.orderDesc"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="!smsAndNotificationModal.object.value && smsAndNotificationModal.object.type == 'SMS'"
                   class="domp-form-label-resume" translate="settings.smsModal.receivingSmsStart"></label>
            <label ng-if="smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-resume"
                   translate="settings.smsModal.receivingSmsStop"></label>
            <label ng-if="!smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'EMAIL'"
                   class="domp-form-label-resume" translate="settings.smsModal.receivingEmailStart"></label>
            <label ng-if="smsAndNotificationModal.object.value  && smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-resume"
                   translate="settings.smsModal.receivingEmailStop"></label>
          </div>
        </div>
      </div>
      <!--ORDER DESC END-->

      <!--MOBILE PHONE / SMS-->
      <div class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label ng-if="smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-review"
                   translate="settings.smsModal.email"></label>
            <label ng-if="smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-review"
                   translate="settings.smsModal.gsm"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="smsAndNotificationModal.object.type == 'EMAIL'" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.notificationEmail}}</label>
            <label ng-if="smsAndNotificationModal.object.type == 'SMS'" class="domp-form-label-resume">{{smsAndNotificationModal.object.user.gsm}}</label>
          </div>
        </div>
      </div>
      <!--MOBILE PHONE / SMS END-->

      <!--ID == 30 SPECIFIC START-->
      <div ng-if="smsAndNotificationModal.object.orderObject.subOrderId == '30'">
        <!--PERIOD & AMOUNT-->
        <div>
          <div class="row p-b-10">
            <div class="col-xs-12">
              <div class="col-sm-4 p-t-10">
                <label class="domp-form-label-review" translate="settings.smsModal.period"></label>
              </div>
              <div ng-if="!smsAndNotificationModal.object.value" class="col-sm-4 p-t-10">
                <div class="form-group">
                  <label class="domp-form-label-resume" ng-if="smsAndNotificationModal.periodType.id == 'D'">
                    {{smsAndNotificationModal.periodType.description | translate}}
                  </label>
                  <label class="domp-form-label-resume" ng-if="smsAndNotificationModal.periodType.id == 'W'">
                    {{smsAndNotificationModal.periodType.description | translate}}, {{smsAndNotificationModal.weeklyTimePeriod.description | translate}}
                  </label>
                  <label class="domp-form-label-resume" ng-if="smsAndNotificationModal.periodType.id == 'M'">
                    {{smsAndNotificationModal.periodType.description | translate}}, {{smsAndNotificationModal.monthlyTimePeriod.description | translate}}
                  </label>
                </div>
              </div>
              <div ng-if="smsAndNotificationModal.object.value" class="col-sm-4 p-t-10">
                <label class="domp-form-label-resume">{{smsAndNotificationModal.periodType.description | translate}}<span ng-if="smsAndNotificationModal.period">, {{smsAndNotificationModal.period.description}}</span></label>
              </div>
            </div>
          </div>
          <div class="row p-b-10">
            <div class="col-xs-12">
              <div class="col-sm-4 p-t-10">
                <label class="domp-form-label-review" translate="settings.smsModal.dailyTimePeriod"></label>
              </div>
              <div class="col-sm-4 p-t-10">
                <div class="form-group">
                  <label ng-if="!smsAndNotificationModal.object.value"
                         class="domp-form-label-resume">{{smsAndNotificationModal.dailyTimePeriod.description}}</label>
                  <label ng-if="smsAndNotificationModal.object.value"
                         class="domp-form-label-resume ">{{ smsAndNotificationModal.dailyTimePeriod.description }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--PERIOD & AMOUNT END-->

      </div>
      <!--ID == 30 SPECIFIC END-->

      <!--ID == 40 or 50 SPECIFIC START-->
        <!--PERIOD & AMOUNT-->
        <div class="row p-b-10" ng-if="smsAndNotificationModal.object.orderObject.subOrderId == '40' || smsAndNotificationModal.object.orderObject.subOrderId == '50'">
          <div class="col-xs-12">
            <div class="col-sm-4 p-t-10">
              <label class="domp-form-label-review" translate="settings.smsModal.dailyTimePeriod"></label>
            </div>
            <div class="col-sm-4 p-t-10">
              <div class="form-group">
                <label class="domp-form-label-resume ">{{ smsAndNotificationModal.dailyTimePeriod.description }}</label>
              </div>
            </div>
          </div>
        </div>
        <!--PERIOD & AMOUNT END-->
      <!--ID == 40 or 50 SPECIFIC END-->



      <div ng-if="smsAndNotificationModal.tempObj.showAmount" class="row p-b-10">
        <div class="col-xs-12">
          <div class="col-sm-4">
            <label ng-if="smsAndNotificationModal.object.orderObject.subOrderId !== '70'" class="domp-form-label-review" translate="settings.smsModal.noticeAmount"></label>
            <label ng-if="smsAndNotificationModal.object.orderObject.subOrderId === '70'" class="domp-form-label-review" translate="settings.smsModal.noticeAmountAbove"></label>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label ng-if="!smsAndNotificationModal.object.value" class="domp-form-label-resume">{{smsAndNotificationModal.tempObj.amount | currency:""}}</label>
              <label ng-if="smsAndNotificationModal.object.value" class="domp-form-label-resume">{{smsAndNotificationModal.tempObj.amount.replace(",",".") | currency:""}}</label>
            </div>
          </div>
        </div>
      </div>

      <!--RECEIVING NOTICES AND GENERALN TERMS-->
      <div ng-if="!smsAndNotificationModal.object.value" class="row">
        <div class="col-xs-12 p-t-20">
          <div class="col-sm-4">
            <label class="domp-form-label-review" translate="settings.smsModal.agreedToReceiveNotices"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="smsAndNotificationModal.tempOrderObj.agreeTerms" translate="settings.smsModal.yes"
                   class="domp-form-label-resume"></label>
          </div>
        </div>
      </div>
      <div ng-if="smsAndNotificationModal.object.type == 'SMS'" class="row">
        <div class="col-xs-12 p-t-20">
          <div class="col-sm-4">
            <label  ng-if="!smsAndNotificationModal.object.value" class="domp-form-label-review" translate="settings.smsModal.agreedToGeneralTerms"></label>
            <label  ng-if="smsAndNotificationModal.object.value" class="domp-form-label-review" translate="settings.smsModal.agreedToStopTerms"></label>
          </div>
          <div class="col-sm-6">
            <label ng-if="smsAndNotificationModal.tempOrderObj.agreeTerms" translate="settings.smsModal.yes"
                   class="domp-form-label-resume"></label>
          </div>
        </div>
      </div>
      <!--RECEIVING NOTICES AND GENERALN TERMS-->
    </div>

    <!--FOOTER-->
    <div class="modal-footer">
      <div class="col-xs-12">
        <button class="btn btn-primary pull-left" translate="settings.smsModal.back"
                ng-click="smsAndNotificationModal.step = 1;smsAndNotificationModal.tempOrderObj.agreeTerms = false;"></button>
        <button class="btn btn-primary pull-left" translate="settings.smsModal.cancel" ng-click="$close()"></button>
        <button class="btn btn-default pull-right" ng-click="smsAndNotificationModal.confirm();"
                ng-disabled="smsAndNotificationModal.confirmationInProgress"
                translate="domesticPayment.confirmBtn"></button>
      </div>
    </div>
    <!--FOOTER END-->


  </div>

  <!--COMMON STEP 2 END-->

  <div ng-if="smsAndNotificationModal.step == 3">
    <div class="modal-body p-0">

        <div class="row">
          <div class="col-xs-4 m-t-30 m-b-30 p-l-116 msg-pad">
            <div class="currency-check-icon msg-pad">
              <i class="icon icon-confirm zmdi-hc-5x"></i>
            </div>
          </div>
          <div class="col-xs-8 m-t-30 m-b-30" style="max-width: 688px;">
            <h2 class=" f-28" translate="settings.smsModal.successMsg"></h2>
          </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-padding">
      <div class="col-xs-12">
        <button class="btn btn-default m-5 pull-right" ng-click="$close()" translate="settings.smsModal.newOrder">
        </button>
        <button class="btn btn-default m-5 pull-right" translate="settings.smsModal.archive">
        </button>
      </div>

    </div>
  </div>
</div>
