(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
          sepa: {
            header:"Sepa D.D. Ograničenja i zabrane",
            header_CRO:"Ograničenja SEPA izravnog terećenja",
            transactionsHeader: "SEPA Izravna terećenja",
            addNew:"Dodaj novo ograničenje",
            toAccount: "Račun",
            title: "Unos i promjena ograničenja",
            noData: "Nema podataka",
            name: "Naziv",
            address: "Adresa",
            place: "Mjesto",
            inputDate: "Datum unosa",
            restrictSEPA: "Zabrana izvršenja svih SEPA izravnih terećenja (račun u potpunosti nedostupan za SDD uslugu)",
            newSepaHint1: "Napomena: Banka naplaćuje unos/promjene ograničenja SEPA izravnih terećenja sukladno",
            newSepaHint2: "Odluci o naknadama za usluge Banke.",
            dateFrom: "Datum od",
            dateTo: "Datum do",
            limitType: "Vrsta ograničenja",
            whiteList: "Bijela lista",
            blackList: "Crna lista",
            amountRestriction: "Ograničenja iznosa i perioda",
            lastChangeDate: "Datum zadnje promjene",
            operater: "Operater",
            delete: "Obriši",
            confirm: "Potvrdi",
            executionConditions: "Uvjeti izvršenja",
            receiverCalculation: "Računanje identifikatora primatelja",
            receiverId: "Identifikator primatelja",
            receiverName: "Naziv primatelja",
            identifierApproval: "Identifikator suglasnosti",
            singleChargeMaxAmount: "Maksimalni iznos pojedinačnog terećenja",
            dynamicExecution: "Dinamika izvršenja",
            settingType: "Tip postavke",
            cancelBtnLabel: "Odustani",
            signSuccessfull: "SEPA obrazac je uspješno potpisan!",
            deactivate: "Deaktiviraj",
            deactivateAlert: "Jeste li sigurni da želite deaktivirati ovo ograničenje?",
            sepaSettingsRestrictions: "Pregled ograničenja izvršenja",
            sepaSettingsHistory: "Povijest ograničenja izvršenja",
            sepaRequestsOverview: "Pregled zahtjeva",
            refusalOrderBtn: "Odbijanje naloga",
            refusalTitle: "Odbijanje nezaprimljenih SDD naloga",
            refusalAcceptedTitle: "Odbijanje zaprimljenih SDD naloga",
            executionDate: "Datum izvršenja",
            amount: "Iznos",
            refusalReceiverName: "Naziv i sjedište primatelja plaćanja",
            receiverAccount: "Račun primatelja plaćanja",
            receiverNumber: "Poziv na broj primatelja",
            status_created: "Kreiran",
            status_recalled: "Otkazan",
            status_processed: "Obrađen",
            status_rejected: "Odbijen",
            activation: "Aktivacija",
            deactivation: "Deaktivacija",
            payerAccount: "Račun platitelja",
            transactionId: "ID naloga",
            transactionDetails: {
              payerAccount: "Račun platitelja",
              receiverAccount: "Račun primatelja",
              creditorId: "Identifikator primatelja"
            },
            sddPaymentReturn: "Povrat SDD naloga",
            sddPaymentReturnInfo: "Zadali ste zahtjev za povrat novčanih sredstava SDD naloga. Želite li nastaviti s transakcijom ?",
            paymentsType: {
              "rejectedTab":"Odbijeni",
              "executedTab":"Obrađeni",
              "pendingTab":"Zaprimljeni"
            },
            directDebitTabs: {
              pending: "Zaprimljeni SDD nalozi",
              processed: "Obrađeni SDD nalozi",
              rejected: "Odbijeni SDD nalozi",
            },
            "executionPeriod": {
              "":"",
              "WEEKLY":"Tjedno",
              "MONTHLY":"Mjesečno",
              "QUARTERLY":"Kvartalno",
              "HALFYEAR":"Polugodišnje",
              "YEARLY":"Godišnje"
            },
            "executionType":{
              "BLOCK_ALL":"Zabrana izvršenja svih SEPA izravnih terećenja",
              "WHITE_LIST":"Bijela lista",
              "BLACK_LIST":"Crna lista",
              "DYNAMIC_LIST":"Ograničenja iznosa i perioda",
              "AMOUNT":"Ograničenje prema iznosu i dinamici izvršenja"
            },
            "isActive": "Aktivan",
            "notActive": "Neaktivan",
            "edit":"Uredi",
            "executionPeriodLabel":"Period izvršenja: ",
            "additionalInfo":"Razlog odbijanja",
            "amountAndCurrency": "Iznos i valuta transakcije",
            "sendMsgButton": "Slanje zahtjeva",
            "payeeName": "Naziv primatelja",
            "payeeIban": "IBAN primatelja",
            "date": "Datum izvršenja",
            "payerIban": "IBAN platitelja",
            "heading": "Izravno terećenje – odbijanje platnog naloga prije izvršenja",
            "subheading1": "Odbijanje platnog naloga zaprimljenog izravnog terećenja moguće je napraviti popunjavanjem i slanjem zahtjeva preko dolje priloženog obrasca prije datuma izvršenja platne transakcije.",
            "subheading2": "Predmetnu poruku potrebno je poslati radnim danom (ponedjeljak – petak) u periodu od 8:00h - 16:00h",
            "subheading3": "Molim odbijanje sljedeće transakcije izravnog terećenja"
          }
        };

        $translateProvider
            .translations('hr', data);
    }
}());
