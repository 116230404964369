(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:NewEBookSavingsDividerCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('NewEBookSavingsDividerCtrl', NewEBookSavingsDividerCtrl);

  function NewEBookSavingsDividerCtrl($rootScope) {
    var vm = this;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
  }
}());
