<section ng-controller="TemplateListOverviewOtherCtrl as templateListOverview" id="main">
  <div class="container-fluid p-b-20">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="f-28" translate="templatesList.header"></h2>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">

        <div class="col-lg-12 col-md-12 col-sm-12 tmplt-list-section">

          <!-- Search -->
          <div class="col-lg-12 col-md-12 col-sm-12 ">
            <div id="searchBtnLeftDiv" class="inner-section left">
              <i class="icon icon-search  icon-hc-2x m-l-5 tml-search-container"></i>
            </div>
            <div class="form-group search-field searchrow">
              <input ng-if="templateListOverview.countryCode != 'sl' && templateListOverview.countryCode != 'hr'" type="text" class="form-control input-field" name="searchTemplateList" id="searchTemplateList"
                     ng-model="templateListOverview.searchTemplateList"
                     placeholder="{{'templatesList.searchTemplates'| translate}}"/>

              <input ng-if="templateListOverview.countryCode == 'sl' || templateListOverview.countryCode == 'hr'" type="text" class="form-control input-field" name="searchTemplateList" id="searchTemplateList"
                     ng-model="templateListOverview.searchTemplateListApi"
                     ng-change="templateListOverview.searchTemplate(templateListOverview.searchTemplateListApi)"
                     placeholder="{{'templatesList.searchTemplates'| translate}}"/>

            </div>
          </div>


          <div class="tmplt-list-label col-lg-12 col-md-12 col-sm-12" translate="templateSearch.templatesList">List of
            templates
          </div>


          <!-- Template list -->
          <div class="col-sm-12 col-md-12 tab-field"
               data-ng-repeat="template in templateListOverview.templateList.result| filter:templateListOverview.searchTemplateList"
               ng-show="templateListOverview.templateList.result.length != 0">

            <!-- TEMPLATE DETAILS -->
            <div class="container invoice-card">
              <div class="row">
                <div class="invoice-card-top gray-card col-lg-12 col-md-12 col-sm-12 col-xs-12 m-p-0">

                  <!-- Icon -->
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 m-p-0 hidden-xs">
                    <div ng-if="!template.imageId" class="invoice-icon">ico</div>
                    <!--<img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico"-->
                    <!--class="tpl-rounded-img"/>-->
                    <div ng-if="template.imageId"
                         ng-style="{'background-image': 'url(' + template.imageDownloadLink + ')'}"
                         class="t-icon-ctn"></div>
                  </div>

                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 p-0 visible-xs">
                    <div ng-if="!template.imageId" class="invoice-icon">ico</div>
                    <!--<img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico"-->
                    <!--class="tpl-rounded-img"/>-->
                    <div ng-if="template.imageId"
                         ng-style="{'background-image': 'url(' + template.imageDownloadLink + ')'}"
                         class="t-icon-ctn-xs"></div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-8 col-xs-6 cust-pl-30 hidden-xs">
                    <div class="invoice-type wrap-text">
                      <b>{{::template.name}}</b>
                      <br/>
                      <span class="text-muted" ng-bind="::template.toAccountNumber"></span>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-8 col-xs-6 p-r-0 visible-xs">
                    <div class="invoice-type wrap-text f-13">
                      <b>{{::template.name}}</b>
                      <br/>
                      <span class="text-muted f-12" ng-bind="::template.toAccountNumber"></span>
                    </div>
                  </div>

                  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 m-p-0">
                    <span class="col-sm-6">
                      <i class="icon icon-arrdown i-c31 cursor-pointer"
                         ng-click="templateListOverview.toggleDetails(template)"></i>
                    </span>
                  </div>
                  <!-- Delete template -->
                  <div class="invoice-card-top-right gray-card col-lg-1 col-md-1 col-sm-1 col-xs-2 pull-right">
                    <span class="pull-right tmplt-delete-sign">
                      <i class="icon icon-close i-dblue i-lh21 cursor-pointer"
                         data-ng-click="templateListOverview.deleteTemplate(template)"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row tmplt-card-detail" ng-class="{collapse: !template.openedDetails}">
                <div class="tmplt-content tmplt-from-section container m-t-0">

                  <!--         <div class="tmplt-content tmplt-from-section container m-t-0">
  <div class="row">
  <div class="col-sm-6">
  <span class="invoice-inline-title text-muted"
  translate="templatesList.fromAccount"><b>From account</b></span>
  <label class="tmplt-detail-label-description black-text">
  {{::templateListOverview.account.accountDescription}}</label>
  <label class="domp-form-label-accnumber">{{domesticPayment.insertedPaymentObject.fromAccountNumber}}</label>
  <label class="tmplt-detail-label-balance black-text">{{::templateListOverview.account.availableBalance
  | number:2}} {{::templateListOverview.account.currencySymbol}}</label>
  </div>
  </div>-->
                  <!--      <div class="col-sm-6" ng-if="template.amount!=''">
  <span class="invoice-inline-title text-muted"
  translate="templatesList.amount"><b>Amount</b></span>
  <label class="black-text">
  {{::template.amount | number:2}} {{::templateListOverview.account.currencySymbol}}
  </label>
  <br/>
  </div>-->

                  <div ng-if="!template.editable && (templateListOverview.countryCode === 'hr' || templateListOverview.countryCode === 'sl')">
                    <div class="row container-template" ng-if="template.debtorName || template.debtorName2">
                      <div class="col-sm-6">
                        <label class="invoice-inline-title text-muted"
                               translate="domesticPayment.sepaDebitorLabel"></label>
                        <label class="tmplt-detail-label-description black-text p-0">{{template.debtorName}}</label>
                        <label class="tmplt-detail-label-description black-text">{{template.debtorName2}}</label>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- NON EDITABLE START -->
                <div ng-if="!template.editable">
                  <div ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'">
                    <div class="tmplt-content tmplt-payee-section">
                      <div class="row">
                        <div class="col-sm-6" ng-if="template.toAccountNumber != ''">
                          <span class="invoice-inline-title text-muted" translate="templatesList.accountNumber"><b>Account number</b></span>
                          <div ng-if="templateListOverview.updateResponse.toAccountNumber == undefined">
                            <label class="black-text"
                                   ng-if="template.toAccountNumber != undefined && template.toAccountNumber != ''">{{template.toAccountNumber}}
                            </label>
                            <label class="black-text"
                                   ng-if="template.toAccountNumber == undefined || template.toAccountNumber == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.toAccountNumber != undefined">
                            <label class="black-text">{{template.toAccountNumber}}</label>
                            <!--<label class="black-text">{{templateListOverview.updateResponse.toAccountNumber}}</label>-->
                          </div>
                        </div>
                        <div class="col-sm-6" ng-if="templateListOverview.countryCode === 'hr'">
                          <span class="invoice-inline-title text-muted" translate="templatesList.amount"><b>Amount</b></span>
                          <label class="black-text" ng-if="template.amount != undefined && template.amount != ''">{{template.amount | currency:""}}</label>
                          <label class="black-text" ng-if="template.amount == undefined || template.amount == ''">-</label>
                        </div>

                        <div class="col-sm-6" ng-class="{'col-sm-offset-6' : templateListOverview.countryCode === 'hr' }">
                          <span class="invoice-inline-title text-muted"
                                translate="templatesList.toPayee"><b>To payee</b></span>
                          <div ng-if="templateListOverview.updateResponse.receiverName == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverName != undefined && template.receiverName != ''">{{template.receiverName}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverName == undefined || template.receiverName == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.receiverName != undefined">
                            <label class="black-text">{{template.receiverName}}</label>
                            <!--<label class="black-text">{{templateListOverview.updateResponse.receiverName}}</label>-->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-offset-6 col-sm-6">
                          <span class="invoice-inline-title text-muted" translate="templatesList.payeeStreet"><b>Payee street</b></span>
                          <div ng-if="templateListOverview.updateResponse.receiverAddress1 == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverAddress1 != undefined && template.receiverAddress1 != ''">{{template.receiverAddress1}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverAddress1 == undefined || template.receiverAddress1 == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.receiverAddress1 != undefined">
                            <label class="black-text">{{template.receiverAddress1}}</label>
                            <!--<label class="black-text">{{templateListOverview.updateResponse.receiverAddress1}}</label>-->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-offset-6 col-sm-6" ng-if="templateListOverview.countryCode !== 'bh'">
                          <span class="invoice-inline-title text-muted"
                                translate="templatesList.payeeCity"><b>Payee city</b></span>
                          <div ng-if="templateListOverview.updateResponse.receiverCity == undefined">
                            <label class="black-text"
                                   ng-if="template.receiverCity != undefined && template.receiverCity != ''">{{template.receiverCity}}</label>
                            <label class="black-text"
                                   ng-if="template.receiverCity == undefined || template.receiverCity == ''">-</label>
                          </div>
                          <div ng-if="templateListOverview.updateResponse.receiverCity != undefined">
                            <label class="black-text">{{template.receiverCity}}</label>
                            <!--<label class="black-text">{{templateListOverview.updateResponse.receiverCity}}</label>-->
                          </div>
                        </div>
                      </div>
                      <div class="row" ng-if="templateListOverview.countryCode === 'hr' || templateListOverview.countryCode === 'sl'">
                        <div class="col-sm-offset-6 col-sm-6">
                          <div ng-if="template.creditorName || template.creditorName2">
                            <label class="invoice-inline-title text-muted"
                                   translate="domesticPayment.sepaReceiverLabel"></label>
                            <label class="tmplt-detail-label-description black-text p-0">{{template.creditorName}}</label>
                            <label class="tmplt-detail-label-description black-text">{{template.creditorName2}}</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" class="receiver-section p-20"
                       style="padding: 10px">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="eInvoiceDetail.recipientAccount">
                            Account number
                          </label>
                          <label class="domp-template-text hidden-xs">
                            {{template.toAccountNumber}}
                          </label>
                          <label class="domp-template-text visible-xs f-16">
                            {{template.toAccountNumber}}
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-6" ng-if="templateListOverview.countryCode === 'hr'">
                        <div class="form-group">
                          <span class="invoice-inline-title domp-template-label" translate="templatesList.amount"><b>Amount</b></span>
                          <label class="black-text" ng-if="template.amount != undefined && template.amount != ''">{{template.amount | currency:""}}</label>
                          <label class="black-text" ng-if="template.amount == undefined || template.amount == ''">-</label>
                        </div>
                      </div>
                      <div class="col-md-6" ng-class="{'col-sm-offset-6' : templateListOverview.countryCode === 'hr' }">
                        <div class="form-group">
                          <label class="domp-template-label" translate="domesticPayment.toPayee">
                            To payee
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverName}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="accessInvestment.address">
                            Address
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverAddress1}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-md-offset-6">
                        <div class="form-group">
                          <label class="domp-template-label"
                                 translate="domesticPayment.receiversPostalCodeAndCityInputLabel">
                            Address
                          </label>
                          <label class="domp-template-text">
                            {{template.receiverCity}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign purpose start -->

                  <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" class="receiver-section p-20">
                    <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="domp-template-label" translate="templatesList.purposeCode">
                            Purpose code
                          </label>
                          <label  class="domp-template-text">
                            {{template.purposeCodeDPS}}
                          </label>
                          <label ng-if="template.purpose && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-template-text">
                            {{template.purpose}}
                          </label>
                        </div>
                      </div>

                      <div class="col-md-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.paymentMode">
                            Payment mode
                          </label>
                          <label class="domp-template-text">
                            {{template.modalityFullName}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="domp-template-label" translate="internationalPayment.paymentPurpose">
                            Payment purpose
                          </label>
                          <label class="domp-template-text">
                            {{template.purpose}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign purpose end -->

                  <!-- Foreign BIC start -->

                  <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" class="receiver-section p-20">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicNumber">
                            BIC number
                          </label>
                          <label class="domp-template-text">
                            {{template.beneficiarySWIFTCode}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicCountry">
                            Country
                          </label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankCountryCode}}
                          </label>
                        </div>
                      </div>
                      <!-- CHECK !!! -->
                      <!--                      <div class="col-md-4">
  <label class="domp-form-label-review" translate="templateListOverview.chargeAccount">Charge account</label>
  <label class="domp-template-text">
  {{template.chargeAccount}}
  </label>
  </div>-->
                    </div>

                    <div class="row">
                      <div class="col-md-8 temp-bank-name">
                        <div class="form-group temp-bank-name">
                          <label class="domp-template-label" translate="payments.bicBankName">Bank Name</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankName}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4"  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                        <div class="form-group template-receiver">
                          <label class="domp-template-label" translate="payments.bicRecipientStatus">Receiver
                            (person)</label>
                          <label class="domp-template-text">
                            <div ng-switch="template.payeeLegalForm">
                              <div ng-switch-when="1">
                                {{"payments.bicRecipientStatusOptionLegal"| translate}}
                              </div>
                              <div ng-switch-when="2">
                                {{"payments.bicRecipientStatusOptionPhysical"| translate}}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicAddress">BIC Address</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankAddress}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicCity">BIC City</label>
                          <label class="domp-template-text">
                            {{template.beneficiaryBankCity}}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="domp-template-label" translate="payments.bicBankCharges">
                            Troškovna opcija:</label>
                          <span ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                            <!-- Map for other-->
                            <label ng-switch-default class="domp-template-text">
                              <div ng-switch="template.paymentCharges">
                                <div ng-switch-when="3">
                                  SHA
                                </div>
                                <div ng-switch-when="1">
                                  OUR
                                </div>
                                <div ng-switch-when="2">
                                  BEN
                                </div>
                              </div>
                            </label>
                            <!-- Map for Croatia-->
                            <label ng-switch-when="hr" class="domp-template-text">
                              <div ng-switch="template.paymentCharges">
                                <div ng-switch-when="3">
                                  SHA
                                </div>
                                <div ng-switch-when="1">
                                  OUR
                                </div>
                              </div>
                            </label>
                            <!-- Map for Slovenia-->
                            <label ng-switch-when="sl" class="domp-template-text">
                              <div ng-switch="template.paymentCharges">
                                <div ng-switch-when="0">
                                  SHA
                                </div>
                                <div ng-switch-when="1">
                                  OUR
                                </div>
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Foreign BIC end -->

                  <div class="tmplt-content tmplt-purpose-section">
                    <div class="row" ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'">
                      <div class="col-sm-6">
                        <span class="invoice-inline-title text-muted" translate="templatesList.purposeCode"><b>Purpose code</b></span>

                        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                          <label class="black-text">
                          <span ng-if="template.purposeCodeDPS != undefined && template.purposeCodeDPS != ''">
                            {{::template.purposeCodeDPS}} -
                          </span>
                            {{::template.purpose}}
                          </label>
                        </div>
                        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                          <label  class="domp-template-text">
                            {{template.purposeCode}}
                          </label>
                          <label class="domp-template-label" translate="internationalPayment.paymentPurpose">
                            Payment purpose
                          </label>
                          <label class="domp-template-text">
                            {{template.purpose}}
                          </label>



                        </div>

                        <label class="black-text"
                               ng-if="(template.purposeCodeDPS == undefined || template.purposeCodeDPS == '') && (template.purpose == undefined || template.purpose == '')">-</label>
                      </div>
                      <div class="col-sm-3" ng-class="{'col-sm-6': templateListOverview.countryCode == 'slo'}" ng-if="templateListOverview.countryCode !== 'bh'">
                        <span class="invoice-inline-title text-muted" translate="templatesList.referenceNumber"><b>Reference number</b></span>
                        <label class="black-text"
                               ng-if="template.referenceTo && templateListOverview.countryCode === 'hr'">HR{{template.referenceTo}}</label>
                        <label class="black-text"
                               ng-if="template.referenceTo && templateListOverview.countryCode !== 'hr' && templateListOverview.countryCode !== 'sl'">{{template.referenceTo}}</label>
                        <label class="black-text"
                               ng-if="template.referenceTo && templateListOverview.countryCode == 'sl'">{{templateListOverview.formatReferenceNumber(template.referenceTo)}}</label>
                        <label class="black-text"
                               ng-if="!template.referenceTo">-</label>
                      </div>
                      <div class="col-sm-3" ng-if="templateListOverview.countryCode === 'bh' && template.reference">
                        <span class="invoice-inline-title text-muted" translate="templatesList.referenceNumber"><b>Reference number</b></span>
                        <label class="black-text">{{template.reference}}</label>
                      </div>
                    </div>
                  </div>

                  <!-- REVENUE PAYMENT INFO -->
                  <div class="tmplt-content tmplt-purpose-section" ng-if="template.paymentTypeGroup === 'RevenuePayment'">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.accountNumber"></label>
                          <label class="domp-form-label-resume">{{template.toAccountNumber}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.purposeCode"></label>
                          <label class="domp-form-label-resume">{{template.purpose + '-' + template.purposeCodeDPS}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.toPayee"></label>
                          <label class="domp-form-label-resume">{{template.receiverName}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.payeeStreet"></label>
                          <label class="domp-form-label-resume">{{template.receiverAddress1}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPayerNumber"></label>
                          <label class="domp-form-label-resume">{{template.taxPayerId}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.paymentType"></label>
                          <label class="domp-form-label-resume">{{template.revenuePaymentType}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.revenueCode"></label>
                          <label class="domp-form-label-resume">{{template.revenueCode}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.municipality"></label>
                          <label class="domp-form-label-resume">{{template.municipality}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPeriodFrom"></label>
                          <label class="domp-form-label-resume">{{template.taxPeriodFrom | date: 'shortDate' : '+0200'}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.taxPeriodTo"></label>
                          <label class="domp-form-label-resume">{{template.taxPeriodTo | date: 'shortDate' : '+0200'}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="domesticPayment.budgetOrganisation"></label>
                          <label class="domp-form-label-resume">{{template.budgetOrganization}}</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone">
                        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
                          <label class="domp-form-label-review domp-reference-number-label"
                                 translate="templatesList.referenceNumber"></label>
                          <label ng-if="template.reference" class="domp-form-label-resume">{{template.reference}}</label>
                          <label ng-if="!template.reference" class="domp-form-label-resume"> - </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END REVENUE PAYMENT INFO -->

                  <div class="row">


                    <div class=" text-right p-r-10 ">
                      <!--        <div class="col-md-8 add-template-fav ">
                                <span class="col-lg-1 col-md-1 col-sm-1 "><i class="zmdi zmdi-star-outline zmdi-hc-2x template-fav"></i></span>
                                <span class="col-lg-10 col-md-10 col-sm-10 p-l-0 m-t-5"
                                      translate="templatesList.addTemplateToFavorites" style="font-weight: bold;"><p>Add this template to favorites</p></span>
                              </div> -->

                      <button class="btn btn-default editTemplate pull-right" translate="templatesList.edit"
                              ng-click="templateListOverview.editTemplate(template)">Edit
                      </button>
                      <button ng-if="templateListOverview.countryCode == 'sl'" class="btn btn-default editTemplate pull-right" translate="templatesList.pay"
                              ng-click="templateListOverview.goToPayment(template)">Pay
                      </button>
                    </div>
                  </div>
                </div>
                <!-- NON EDITABLE END-->

                <!--==========================-->
                <!-- EDITABLE START -->
                <!--==========================-->
                <div ng-if="template.editable">
                  <form name="templateListOverview.templatesListForm" novalidate>
                    <div class="row m-t-20" id="spaha0">
                      <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="hidden-xs t-a-c">
                          <div class="col-lg-12 text-left">
                            <span class="template-headers" translate="paymentTemplates.templateIcon">
                              Template icon
                            </span>
                          </div>
                        </div>
                        <div class="visible-xs ">
                          <div class="col-xs-12 col-md-6 col-lg-6 text-left">
                            <span class="template-headers" translate="paymentTemplates.templateIcon">
                              Template icon
                            </span>
                          </div>
                        </div>
                        <div>
                          <div class="dropdown hidden-xs t-a-c">
                            <div class="btn pull-left btn-default dropdown-toggle img-selector-btn edit-tmpl-img-selector"
                                 type="button"
                                 data-toggle="dropdown">
                              <div>
                                <img class="img-circle edit-tmpl-selected-img"
                                     ng-src="{{templateListOverview.selectedImage.downloadLink}}" alt="Ico"/>
                              </div>
                              <span class="caret-icon edit-tmpl-caret">
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                              </span>
                            </div>
                            <div class="dropdown-menu edit-tmpl-img-container" role="menu" aria-labelledby="menu1">
                              <div ng-repeat="image in templateListOverview.images"
                                   data-ng-click="templateListOverview.selectImage(image)">
                                <div class="col-xs-12 img-option">
                                  <img class="img-circle edit-tmpl-img" ng-src="{{image.downloadLink}}" alt="Ico">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="dropdown visible-xs">
                            <div class="btn pull-left btn-default dropdown-toggle img-selector-btn edit-tmpl-img-selector"
                                 type="button"
                                 data-toggle="dropdown">
                              <div>
                                <img class="img-circle edit-tmpl-selected-img"
                                     ng-src="{{templateListOverview.selectedImage.downloadLink}}" alt="Ico"/>
                              </div>
                              <span class="caret-icon edit-tmpl-caret">
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
                              </span>
                            </div>
                            <div class="dropdown-menu edit-tmpl-img-container" role="menu" aria-labelledby="menu1">
                              <div ng-repeat="image in templateListOverview.images"
                                   data-ng-click="templateListOverview.selectImage(image)">
                                <div class="col-xs-12 img-option">
                                  <img class="img-circle edit-tmpl-img" ng-src="{{image.downloadLink}}" alt="Ico">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-6 col-lg-6">
                        <span class="invoice-inline-title text-muted" translate="paymentTemplates.templateName">
                          Template name
                        </span>
                        <div>
                          <input type="text" class="form-control input-field"
                                 id="templateNameInput" name="templateNameInput"
                                 data-ng-model="templateListOverview.tempObj.templateName"
                                 placeholder="{{'internalTransfer.templateName'| translate}}"
                                 required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.templateNameInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 maxlength="35">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.purposeOfPaymentInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="internalTransfer.paymentPurposeInputError"></span>
                        </div>
                      </div>

                      <div class="col-xs-12 col-sm-offset-6 col-sm-6" ng-show="templateListOverview.countryCode === 'hr'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted" translate="templatesList.amount"><b>Amount</b></span>
                          </div>
                          <div class="col-xs-12">
                            <input type="text"
                                   class="form-control input-field text-right"
                                   placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                                   amount-input-mask
                                   allow-empty="true"
                                   maxlength="12"
                                   ng-model="templateListOverview.tempObj.amount"
                                   name="amountInput" id="amountInput">
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row m-t-20" ng-if="template.editable && (templateListOverview.countryCode === 'hr' || templateListOverview.countryCode === 'sl')" >
                      <div class="col-sm-6">
                        <uib-accordion close-others="true" class="domp-sepa-accordion">
                          <div uib-accordion-group>
                            <uib-accordion-heading>
                              <div class="sepa-heading input-field">
                                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                                  <label class="domp-form-label-sepa" translate="domesticPayment.sepaDebitorLabel"></label>
                                </div>
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                                  class="icon icon-arrdown"></i></div>
                              </div>
                            </uib-accordion-heading>
                            <input type="text" class="form-control input-field"
                                   ng-model="templateListOverview.tempObj.payersSepaInput1"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                   maxlength="35">
                            <input type="text" class="form-control input-field"
                                   ng-model="templateListOverview.tempObj.payersSepaInput2"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                   maxlength="35">
                          </div>
                        </uib-accordion>
                      </div>
                    </div>

                    <div class="row m-t-25 p-r-15" id="spaha1">

                      <div class="row">
                        <div class="col-xs-12 col-sm-6 p-b-10" ng-if="templateListOverview.countryCode === 'hr' || templateListOverview.countryCode === 'sl'">

                          <div class="row">
                            <div class="col-xs-12 col-sm-12">

                              <div ng-if="templateListOverview.countryCode === 'hr'">
                                <div class="col-xs-12">
                                  <span class="invoice-inline-title text-muted" translate="templatesList.accountNumber"><b>Account number</b></span>
                                </div>
                                <div class="col-xs-12 ">
                                  <input ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'" type="text"
                                         class="form-control input-field" capitalize
                                         ui-mask="{{templateListOverview.uiMask}}"
                                         ui-mask-placeholder ui-mask-placeholder-char="_"
                                         ui-options="templateListOverview.croIbanMaskOptions"
                                         ng-model="templateListOverview.tempObj.toAccountInput"
                                         ng-class="{'domp-error': templateListOverview.templatesListForm.toAccountInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                         name="toAccountInput" id="toAccountInput" required
                                         ng-blur="templateListOverview.checkInputChanges(template.paymentTypeGroup)">
                                </div>
                              </div>
                              <div ng-if="templateListOverview.countryCode === 'sl'">

                                <div class="col-md-12 col-sm-6 m-b-15">
                                  <div class="row">
                                    <div class="col-xs-12">
                                      <span class="invoice-inline-title text-muted" translate="templatesList.accountNumber"><b>Account number</b></span>
                                    </div>
                                  </div>
                                  <!--<input ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'" type="text"-->
                                  <!--class="form-control input-field" capitalize-->
                                  <!--ui-mask="{{templateListOverview.uiMask}}"-->
                                  <!--ui-mask-placeholder ui-mask-placeholder-char="_"-->
                                  <!--ui-options="templateListOverview.croIbanMaskOptions"-->
                                  <!--ng-model="templateListOverview.tempObj.toAccountInput"-->
                                  <!--ng-class="{'domp-error': templateListOverview.templatesListForm.toAccountInput.$invalid && templateListOverview.tempObj.formSubmitted}"-->
                                  <!--name="toAccountInput" id="toAccountInput" required-->
                                  <!--ng-blur="templateListOverview.getBicByIban2(templateListOverview.tempObj.toAccountInput)">-->
                                  <div ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'">
                                    <label class="black-text">
                                      {{templateListOverview.tempObj.toAccountInput}}
                                    </label>
                                  </div>
                                </div>
                                <!--<div ng-if="template.paymentTypeGroup === 'DomesticPayment' || template.paymentTypeGroup === 'SEPAPayment'" class="col-xs-12 col-sm-6 m-b-15">-->
                                <!--<div class="row">-->
                                <!--<div class="col-xs-12">-->
                                <!--<span class="invoice-inline-title text-muted" translate="templatesList.bicBankPrejemnika"><b>BICBank</b></span>-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--<input type="text"-->
                                <!--class="form-control input-field" capitalize-->

                                <!--ng-model="templateListOverview.tempObj.beneficiarySWIFTCode"-->
                                <!--ng-class="{'domp-error': templateListOverview.templatesListForm.beneficiarySWIFTCode.$invalid && templateListOverview.tempObj.formSubmitted}"-->
                                <!--name="beneficiarySWIFTCode" id="beneficiarySWIFTCode"-->
                                <!--&gt;-->
                                <!--</div>-->

                              </div>


                              <input ng-if="template.paymentTypeGroup === 'ForeignPayment' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" type="text"
                                     class="form-control input-field"
                                     capitalize
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersSpaces}}"
                                     ui-options="templateListOverview.uiMaskOptions"
                                     ng-model="templateListOverview.tempObj.toAccountInput"
                                     ng-class="{'domp-error': templateListOverview.paymentForm.toAccountInputForp.$invalid && templateListOverview.tempForpObj.formSubmitted}"
                                     name="toAccountInputForp" id="toAccountInputForp"
                                     required
                                     maxlength="39"
                                     ng-trim="false"
                                     ng-change="templateListOverview.addSpaceForIban(templateListOverview.tempForpObj.toAccountInputForp)"
                                     ng-blur="templateListOverview.fillReceiver(templateListOverview.tempForpObj.toAccountInputForp);
                                           templateListOverview.getBicByIban(templateListOverview.tempForpObj.toAccountInputForp)">
                              <div class="col-xs-12" ng-if="template.paymentTypeGroup === 'ForeignPayment' && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                                <label  class="black-text">
                                  {{templateListOverview.tempObj.toAccountInput}}
                                </label>
                              </div>

                              <span class="c-red p-l-15"
                                    ng-show="templateListOverview.templatesListForm.toAccountInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="domesticPayment.receiverAccountError"></span>
                              <span class="c-red p-l-15"
                                    ng-show="templateListOverview.templatesListForm.toAccountInputForp.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="domesticPayment.receiverAccountError"></span>
                            </div>
                          </div>
                        </div>


                        <div class="col-xs-12 col-sm-6 p-b-10" ng-if="templateListOverview.countryCode !== 'hr' && templateListOverview.countryCode !== 'sl'">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12">
                              <span class="invoice-inline-title text-muted" translate="templatesList.accountNumber"><b>Account number</b></span>
                            </div>
                          </div>

                          <div class="row" ng-if="templateListOverview.countryCode !== 'bh'">
                            <div class="col-xs-12 col-sm-12">
                              <input id="toAccountInputSrb" name="toAccountInputSrb"
                                     type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersDashes}}"
                                     capitalize
                                     dn-custom-maxlength="18" dn-exclude="-"
                                     ng-blur="templateListOverview.formatAccountNumber(templateListOverview.tempObj.toAccountInput)"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.toAccountInputSrb.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     ng-model="templateListOverview.tempObj.toAccountInput" required
                                     ng-disabled="templateListOverview.isRetrieval">
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.toAccountInputSrb.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="domesticPayment.receiverAccountError"></span>
                              <span class="c-red" ng-show="templateListOverview.templatesListForm.$error.minlength"
                                    translate="payments.minimalAccountNumber"></span>
                            </div>
                          </div>

                          <!-- TO ACCOUNT INPUT FOR BIH ENVIRONMENT -->
                          <div class="row" ng-if="templateListOverview.countryCode === 'bh'">
                            <div class="col-xs-12 col-sm-12">
                              <input type="text" class="form-control input-field" name="toAccountInput"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbers}}"
                                     ng-maxlength="16" maxlength="16"
                                     ng-minlength="16"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.toAccountInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     ng-model="templateListOverview.tempObj.toAccountInput"
                                     ng-blur="templateListOverview.checkIsRevenueAccount()"
                                     required>
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.toAccountInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="domesticPayment.receiverAccountError"></span>
                              <span class="c-red" ng-show="templateListOverview.templatesListForm.$error.minlength"
                                    translate="payments.minimalAccountNumber"></span>
                            </div>
                          </div>
                          <!-- END TO ACCOUNT INPUT FOR BIH ENVIRONMENT -->

                        </div>

                        <!-- /////////// SELECT PAYEE ///////////// -->
                        <div class=" col-xs-12 col-sm-6 p-l-30">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="templatesList.toPayee"><b>To payee</b></span>
                            </div>
                          </div>

                          <div class="row p-l-15 p-r-15">
                            <div class="col-sm-12 col-xs-12 domp-filter-container"
                                 ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.payeeSearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                <i class="icon icon-search domp-search-sign m-l-5"></i>
                              </div>
                              <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                                <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" class="search-input"
                                       ng-model="templateListOverview.payeeSearchFilter"
                                       ng-change="templateListOverview.searchPayee()"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                       required
                                       maxlength="70" ng-maxlength="70" autocomplete="off">
                              </div>
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                   ng-click="templateListOverview.toggleFilterSelection()">
                                <i class="icon icon-close domp-close-sign i-lh17"
                                   ng-show="templateListOverview.payeeSearchFilter != ''"
                                   ng-click="templateListOverview.removeSearch()"></i>
                                <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                                   ng-show="templateListOverview.payeeSearchFilter == ''"></i>
                              </div>
                              <div class="domp-filter-selection search-row"
                                   ng-class="{'opened': templateListOverview.searchFilterExpanded}">
                                <div class="domp-filter-type-selection-wrapper"
                                     ng-repeat="payee in templateListOverview.payeeList| filter:templateListOverview.searchPayeeBy"
                                     ng-click="templateListOverview.selectPayee(payee.id)">
                                  <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <i class="zmdi zmdi-account-circle" style="color:#9eabd5"></i>
                                  </div>
                                  <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                                    <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <!--//////////////SELECT PAYEE END/////////////////////// -->
                      </div>


                      <div class="row">
                        <div class="col-xs-12 col-sm-6">

                        </div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <!--   <span class="invoice-inline-title text-muted"
                                   translate="domesticPayment.receiversAddressInputLabel"><b>Payee street</b></span> -->
                          <input type="text" class="form-control input-field" name="receiversAddressInput"
                                 id="receiversAddressInput" ng-maxlength="70" maxlength="70"
                                 ng-model="templateListOverview.tempObj.receiversAddressInput" required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.receiversAddressInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                 ng-change="templateListOverview.checkInputChanges(template.paymentTypeGroup)">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.receiversAddressInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.receiverAddressError"></span>
                        </div>
                      </div>
                      <div class="row" ng-if="templateListOverview.countryCode !== 'bh'">
                        <div class="col-xs-12 col-sm-6"></div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <!--<span class="invoice-inline-title text-muted"
                                translate="domesticPayment.receiversPostalCodeAndCityInputLabel"><b>Payee city</b></span> -->
                          <input type="text" class="form-control input-field" name="receiversPostalCodeAndCityInput"
                                 id="receiversPostalCodeAndCityInput" ng-maxlength="70" maxlength="70"
                                 ng-model="templateListOverview.tempObj.receiversPostalCodeAndCityInput" required
                                 ng-class="{'domp-error': templateListOverview.templatesListForm.receiversPostalCodeAndCityInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                 ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                 ng-change="templateListOverview.checkInputChanges(template.paymentTypeGroup)">
                          <span class="c-red"
                                ng-show="templateListOverview.templatesListForm.receiversPostalCodeAndCityInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.receiverCityError"></span>
                        </div>
                      </div>
                      <div class="row" ng-if="templateListOverview.countryCode === 'hr' || templateListOverview.countryCode === 'sl'">
                        <div class="col-xs-12 col-sm-6" ></div>
                        <div class="col-xs-12 col-sm-6 m-t-10 p-l-30">
                          <uib-accordion close-others="true" class="domp-sepa-accordion">
                            <div uib-accordion-group>
                              <uib-accordion-heading>
                                <div class="sepa-heading input-field">
                                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                                    <label class="domp-form-label-sepa"
                                           translate="domesticPayment.sepaReceiverLabel"></label>
                                  </div>
                                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                                    class="icon icon-arrdown"></i></div>
                                </div>
                              </uib-accordion-heading>
                              <input type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     ng-model="templateListOverview.tempObj.receiversSepaInput1" maxlength="35">
                              <input type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     ng-model="templateListOverview.tempObj.receiversSepaInput2" maxlength="35">
                            </div>
                          </uib-accordion>

                        </div>
                      </div>
                    </div>

                    <div class="row m-t-25" id="spaha2">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20 m-b-10">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.purposeCodeSelectLabel">Purpose code</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12">
                            <select class="form-control input-field " name="purposeCodeSelect"
                                    id="purposeCodeSelect"
                                    ng-options="option.optionValue for option in templateListOverview.purposeCodesList.result"
                                    ng-model="templateListOverview.tempObj.purposeCodeSelect"
                                    ng-change="templateListOverview.changePurpose(templateListOverview.tempObj.purposeCodeSelect)"></select>
                          </div>
                        </div>

                        <div class="row m-t-20">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted"
                                  translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</span>
                          </div>
                        </div>
                        <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                          <div class="col-xs-12">
                            <input type="text" class="form-control input-field "
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.purposeOfPaymentValidation}}"
                                   name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="135"
                                   maxlength="135"
                                   ng-model="templateListOverview.tempObj.purposeOfPaymentInput" required
                                   ng-class="{'domp-error': templateListOverview.templatesListForm.purposeOfPaymentInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                   ng-blur="templateListOverview.replaceUnallowedCharsDPS('purposeOfPaymentInput', templateListOverview.tempObj.purposeOfPaymentInput)">
                            <span class="c-red"
                                  ng-show="templateListOverview.templatesListForm.purposeOfPaymentInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                  translate="internalTransfer.paymentPurposeInputError"></span>
                          </div>
                        </div>



                        <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="p-0" ng-switch="templateListOverview.tempObj.referenceModelSelect">
                                <input type="text" class="form-control input-field"
                                       ng-switch-when="NRC"
                                       placeholder="test1"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.standardInput}}"
                                       name="purposeOfPaymentInput" id="purposeOfPaymentInput" maxlength="140"
                                       ng-model="templateListOverview.tempObj.purposeOfPaymentInput" required
                                       ng-class="{'domp-error': templateListOverview.templatesListForm.purposeOfPaymentInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                       ng-blur="templateListOverview.replaceUnallowedCharsDPS('purposeOfPaymentInput', templateListOverview.tempObj.purposeOfPaymentInput)">

                                <input type="text" class="form-control input-field"
                                       ng-switch-default
                                       placeholder="test2"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.standardInput}}"
                                       name="purposeOfPaymentInput" id="purposeOfPaymentInput" maxlength="34"
                                       ng-model="templateListOverview.tempObj.purposeOfPaymentInput" required
                                       ng-class="{'domp-error': templateListOverview.templatesListForm.purposeOfPaymentInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                       ng-blur="templateListOverview.replaceUnallowedCharsDPS('purposeOfPaymentInput', templateListOverview.tempObj.purposeOfPaymentInput)">
                                <span class="c-red" ng-show="domesticPaymentSlo.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentSlo.tempDompObj.formSubmitted" translate="internalTransfer.paymentPurposeInputError"></span>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="templateListOverview.countryCode && templateListOverview.countryCode === 'hr' && template.paymentTypeGroup !== 'ForeignPayment'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.referenceNumberLabel">Reference number</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-6 col-sm-2">
                            <input type="text" class="form-control input-field t-a-c" value="HR" readonly>
                          </div>
                          <div class="col-xs-6 col-sm-2 p-0 hidden-xs m-b-10">

                            <select class="form-control input-field t-a-c" name="referenceModelSelect"
                                    id="referenceModelSelect"
                                    ng-model="templateListOverview.tempObj.referenceModelSelect">
                              <option ng-repeat="item in templateListOverview.referenceModelList">{{item}}</option>
                            </select>

                          </div>
                          <div class="col-xs-6 col-sm-2 visible-xs m-b-10">

                            <select class="form-control input-field t-a-c" name="referenceModelSelect"
                                    id="referenceModelSelect"
                                    ng-model="templateListOverview.tempObj.referenceModelSelect">
                              <option ng-repeat="item in templateListOverview.referenceModelList">{{item}}</option>
                            </select>

                          </div>
                          <div class="col-xs-12 col-sm-8"
                               ng-if="templateListOverview.tempObj.referenceModelSelect != '99'">

                            <input type="text" name="referenceNumberInput" maxlength="22"
                                   class="form-control input-field"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allNumbersDashes}}"
                                   ng-if="templateListOverview.tempObj.referenceModelSelect != '99'"
                                   ng-model="templateListOverview.tempObj.referenceNumberInput">

                          </div>
                          <div class="col-xs-12 col-sm-8"
                               ng-if="templateListOverview.tempObj.referenceModelSelect == '99'">

                            <input type="text" class="form-control input-field"
                                   name="referenceNumberInput" readonly>

                          </div>
                          <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                                ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.referenceModelInputError"></span>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="templateListOverview.countryCode === 'hr' && template.paymentTypeGroup === 'ForeignPayment'">
                        <div class="row">
                          <div class="col-xs-12">
                            <label translate="payments.paymentMode" class="domp-form-label">
                              Payment mode:
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12">
                            <select name="paymentMode"
                                    class="form-control input-field p-l-15 p-r-10"
                                    ng-options="item.id as (item.id +         ' - ' +         item.description) for item in templateListOverview.paymentModeOptions"
                                    ng-model="templateListOverview.tempObj.modalityCode">
                            </select>
                          </div>
                        </div>
                      </div>

                      <!-- REFERENCE INPUT FOR BIH ENVIRONMENT -->
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="templateListOverview.countryCode === 'bh'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.referenceNumberLabel">Reference number</span>
                          </div>
                        </div>
                        <input type="text" id="referenceNumberInput" class="form-control input-field" name="referenceNumberInput"
                               ng-class="{'domp-error': templateListOverview.templatesListForm.referenceNumberInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                               ng-maxlength="{{templateListOverview.referenceMaxLength}}"  ng-pattern-restrict="{{templateListOverview.referencePattern}}"
                               ng-model="templateListOverview.tempObj.referenceNumberInput"
                               ng-change="templateListOverview.validateReferenceNumber(); templateListOverview.checkReferenceNumber();"
                               ng-blur="templateListOverview.completeReferenceNumber();">
                        <div class="c-red" ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.referenceNumberRequired && templateListOverview.tempObj.formSubmitted"
                             translate="domesticPayment.referenceNumberRequired">
                        </div>
                        <div class="c-red" ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.maxlength && templateListOverview.tempObj.formSubmitted"
                             translate="domesticPayment.referenceNumberMaxLength">
                        </div>
                        <div class="c-red" ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.validReferenceNumber && templateListOverview.tempObj.formSubmitted"
                             translate="domesticPayment.referenceNumberError">
                        </div>
                      </div>
                      <!-- END REFERENCE INPUT FOR BIH ENVIRONMENT -->

                      <!-- REFERENCE INPUT FOR SLO ENVIRONMENT -->
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="templateListOverview.countryCode === 'sl' && template.paymentTypeGroup != 'ForeignPayment'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.referenceNumberLabel">Reference number</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-6 col-sm-3 p-r-0">
                            <div class="col-xs-12 p-l-0 p-r-0">
                              <!--<input type="text" class="form-control input-field" ng-model="domesticPaymentSlo.referenceHR" readonly>-->
                              <select type="text" class="form-control input-field p-l-15 p-r-10 ng-pristine ng-valid ng-not-empty ng-touched"
                                      name="referenceModelSelect" id="referenceModelSelect"
                                      ng-model="templateListOverview.tempObj.referenceModelSelect"
                                      ng-change="templateListOverview.checkPurposeOfPaymentLength()">
                                <option value="SI">SI</option>
                                <option value="RF">RF</option>
                                <option value="NRC">NRC</option>
                              </select>
                            </div>
                          </div>
                          <span ng-if="templateListOverview.tempObj.referenceModelSelect != 'NRC'">
                          <div class="col-xs-6 col-sm-2 p-0 hidden-xs m-b-10" ng-if="templateListOverview.tempObj.referenceModelSelect != 'RF'">

                            <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelList"

                                    ng-model="templateListOverview.referenceModelListSelect"
                                    ng-options="item as item for item in templateListOverview.referenceModelList"
                                    ng-change="templateListOverview.tempObj.referenceNumberInput = (templateListOverview.referenceModelListSelect == '99') ? '' : templateListOverview.tempObj.referenceNumberInput">
                            </select>

                          </div>

                          <div class="col-sm-6 col-xs-6 p-l-0 p-r-0" ng-if="templateListOverview.referenceModelListSelect != '99'">
                            <div class="form-group m-b-0">
                              <input type="text" id="referenceNumberInput" name="referenceNumberInput" class="form-control input-field" ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersDashes}}" ng-model="templateListOverview.tempObj.referenceNumberInput" maxlength='22'>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6 p-l-0 p-r-0" ng-if="templateListOverview.referenceModelListSelect == '99'">
                            <div class="form-group m-b-0">
                              <input type="text" class="form-control input-field" name="referenceNumberInput" id="referenceNumberInput2" readonly>
                            </div>
                          </div>
                          </span>


                          <!--<div class="col-xs-6 col-sm-2 visible-xs m-b-10">-->
                          <!--<div ng-switch="templateListOverview.tempObj.referenceModelSelect" class="form-group m-b-0">-->
                          <!--<input ng-switch-when="NRC" type="text" id="referenceNumberInput" name="referenceNumberInput" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.allNumbersDashes}}" ng-model="domesticPaymentSlo.tempDompObj.referenceNumberInput" maxlength='140'>-->
                          <!--&lt;!&ndash; SL or RF &ndash;&gt;-->
                          <!--<input ng-switch-default type="text" id="referenceNumberInput" name="referenceNumberInput" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSlo.regexPatterns.allNumbersDashes}}" ng-model="domesticPaymentSlo.tempDompObj.referenceNumberInput" maxlength='34'>-->
                          <!--</div>-->

                          <!--</div>-->
                          <!--<div class="col-xs-12 col-sm-7">-->

                          <!--<input type="text" name="referenceNumberInput" maxlength="22"-->
                          <!--class="form-control input-field"-->
                          <!--ng-pattern-restrict="{{templateListOverview.regexPatterns.allNumbersDashes}}"-->
                          <!--ng-model="templateListOverview.tempObj.referenceNumberInput">-->

                          <!--</div>-->

                          <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                                ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                translate="domesticPayment.referenceModelInputError"></span>
                        </div>
                      </div>
                      <!-- END REFERENCE INPUT FOR SLO ENVIRONMENT -->

                      <!--SRB/MNE RESP-->
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20"
                           ng-if="templateListOverview.countryCode !== 'hr' && templateListOverview.countryCode !== 'bh' && templateListOverview.countryCode !== 'sl'">
                        <div class="row">
                          <div class="col-xs-12">
                            <span class="invoice-inline-title text-muted m-b-5"
                                  translate="domesticPayment.referenceNumberLabel">Reference number</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-3">

                            <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"
                                    ng-model="templateListOverview.tempObj.referenceModelSelect"
                                    ng-change="templateListOverview.tempObj.referenceNumberInput = ''">
                              <option ng-repeat="item in templateListOverview.referenceModelList track by $index">{{item}}</option>
                            </select>

                          </div>
                          <div class="col-xs-12 col-sm-9">

                            <!-- Reference number for Serbia allows only uppercase letters, numbers and dashes-->
                            <!-- capitalize (attribute) directive forces uppercase letters-->
                            <input type="text" class="form-control input-field" name="referenceNumberInput"
                                   ng-class="{'domp-error': templateListOverview.templatesListForm.referenceNumberInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                   maxlength="23"
                                   ng-keyup="templateListOverview.checkForUNallowedChars(templateListOverview.tempObj.referenceNumberInput)"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersDashes}}"
                                   ng-if="templateListOverview.tempObj.referenceModelSelect != '97'"
                                   ng-model="templateListOverview.tempObj.referenceNumberInput" capitalize/>
                            <input type="text"
                                   ng-class="{'domp-error': templateListOverview.templatesListForm.referenceNumberInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                   class="form-control input-field" name="referenceNumberInput" maxlength="26"
                                   ng-keyup="templateListOverview.checkForUNallowedChars(templateListOverview.tempObj.referenceNumberInput)"
                                   ng-pattern-restrict="{{templateListOverview.regexPatterns.allLettersNumbersDashes}}"
                                   ng-blur="templateListOverview.checkModul97Reference('97' + templateListOverview.tempObj.referenceNumberInput)"
                                   ng-if="templateListOverview.tempObj.referenceModelSelect == '97'"
                                   ng-model="templateListOverview.tempObj.referenceNumberInput" capitalize required/>

                          </div>
                          <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-if="templateListOverview.tempObj.referenceModelSelect == '97' &&
                                    templateListOverview.templatesListForm.referenceNumberInput.$error.modul97reference == true"
                               translate="domesticPayment.referenceModelInputInvalid">
                          </div>
                          <!-- START Error messages -->
                          <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                               ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.required && templateListOverview.tempObj.formSubmitted"
                               translate="domesticPayment.referenceModelInputError"></div>
                          <!-- END Error messages -->
                        </div>
                      </div>
                          <!--END SRB/MNE RESP-->
                    </div>
                    <div class="row"
                         ng-if="(templateListOverview.countryCode == 'mn' || templateListOverview.countryCode == 'mne') && template.paymentTypeGroup == 'ForeignPayment'">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20 p-0">
                            <div class="row">
                              <div class="col-xs-12">
                                <span class="invoice-inline-title text-muted m-b-5"
                                      translate="internationalPayment.email"></span>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-12">
                              <input id="email" name="email"
                                    type="email" class="form-control input-field"
                                    ng-class="{'domp-error': templateListOverview.templatesListForm.email.$invalid && templateListOverview.tempObj.formSubmitted}"
                                    ng-model="templateListOverview.tempObj.email" >
                            </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-t-20 p-0">
                            <div class="row">
                              <div class="col-xs-12">
                                <span class="invoice-inline-title text-muted m-b-5"
                                      translate="internationalPayment.phone"></span>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-12">
                              <input id="phone" name="phone"
                                    type="phone" class="form-control input-field"
                                    ng-class="{'domp-error': templateListOverview.templatesListForm.phone.$invalid && templateListOverview.tempObj.formSubmitted}"
                                    ng-model="templateListOverview.tempObj.phone" >
                            </div>
                      </div>
                    </div>

                    <!-- PUBLIC REVENUE EDIT -->
                    <!-- ng-if="template.paymentTypeGroup === 'RevenuePayment'" -->
                    <div class="row" ng-if="template.paymentTypeGroup === 'RevenuePayment'">
                      <div data-ng-include="'payment-templates/public-revenue-section.tpl.html'"></div>
                    </div>
                    <!-- END PUBLIC REVENUE SECTION -->


                    <!-- Cro reference number start -->

                    <!--<div class="col-sm-6 left-section"-->
                    <!--ng-if="templateListOverview.countryCode && templateListOverview.countryCode === 'hr' && template.paymentTypeGroup !== 'ForeignPayment'">-->
                    <!--<div class="col-xs-12 p-l-0">-->
                    <!--<span class="invoice-inline-title text-muted"-->
                    <!--translate="domesticPayment.referenceNumberLabel">Reference-->
                    <!--number:</span>-->
                    <!--</div>-->
                    <!--<div class="col-sm-2 col-xs-2 p-l-0 p-r-5">-->
                    <!--<input type="text" class="form-control input-field" value="HR" readonly>-->
                    <!--</div>-->
                    <!--<div class="col-sm-2 col-xs-2 p-l-0 p-r-5">-->
                    <!--<div class="form-group m-b-0">-->
                    <!--<select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"-->
                    <!--id="referenceModelSelect"-->
                    <!--ng-model="templateListOverview.tempObj.referenceModelSelect">-->
                    <!--<option ng-repeat="item in templateListOverview.referenceModelList">{{item}}</option>-->
                    <!--</select>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<div class="col-sm-8 col-xs-8 p-l-0 p-r-0"-->
                    <!--ng-if="templateListOverview.tempObj.referenceModelSelect!='99'">-->
                    <!--<div class="form-group m-b-0">-->
                    <!--<input type="text" name="referenceNumberInput" maxlength="22"-->
                    <!--class="form-control input-field"-->
                    <!--ng-pattern-restrict="{{templateListOverview.regexPatterns.allNumbersDashes}}"-->
                    <!--ng-if="templateListOverview.tempObj.referenceModelSelect!='99'"-->
                    <!--ng-model="templateListOverview.tempObj.referenceNumberInput">-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<div class="col-sm-8 col-xs-8 p-l-0 p-r-0"-->
                    <!--ng-if="templateListOverview.tempObj.referenceModelSelect=='99'">-->
                    <!--<div class="form-group m-b-0">-->
                    <!--<input type="text" class="form-control input-field"-->
                    <!--name="referenceNumberInput" readonly>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"-->
                    <!--ng-show="templateListOverview.templatesListForm.referenceNumberInput.$error.required && templateListOverview.tempObj.formSubmitted"-->
                    <!--translate="domesticPayment.referenceModelInputError"></span>-->
                    <!--</div>-->

                    <!-- Cro reference number end -->

                    <!-- Cro foreign payment mode start -->

                    <!--<div-->
                    <!--ng-if="templateListOverview.countryCode === 'hr' && template.paymentTypeGroup === 'ForeignPayment'">-->
                    <!--<div class="col-md-6">-->
                    <!--<label translate="payments.paymentMode" class="domp-form-label">-->
                    <!--Payment mode:-->
                    <!--</label>-->
                    <!--<div class="p-l-0">-->
                    <!--<select name="paymentMode"-->
                    <!--class="form-control input-field p-l-15 p-r-10"-->
                    <!--ng-options="item.id as (item.id + ' - ' + item.description) for item in templateListOverview.paymentModeOptions"-->
                    <!--ng-model="templateListOverview.tempObj.modalityCode">-->
                    <!--</select>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--</div>-->

                    <!-- Cro foreign payment mode end -->


                    <!-- DOMESTIC PAYMENT END -->


                    <!-- START BIC -->
                    <div ng-if="template.paymentTypeGroup === 'ForeignPayment'" id="spaha3">
                      <div class="row m-t-25">
                        <div class="col-xs-12 col-sm-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicNumber">
                                BIC Number
                              </span>
                            </div>
                          </div>
                          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                            <div ng-switch-when="sl" class="row p-r-15 p-l-15">
                              <input type="text" name="bicNumber" class="form-control input-field"
                                     ng-model="templateListOverview.tempObj.swiftCodeInput"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLetters}}"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bicNumber.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     capitalize
                                     required>
                              <span class="c-red" translate="payments.bicNumberRequired"
                                    ng-show="templateListOverview.templatesListForm.bicNumber.$error.required && templateListOverview.tempObj.formSubmitted">
                                </span>
                            </div>
                            <div ng-switch-default class="row p-r-15 p-l-15">
                              <div class="col-xs-12 domp-filter-container"
                                   ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.bicCodeSearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                                </div>
                                <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">
                                  <input type="text" class="search-input" name="bicCodeSearchFilter"
                                         ng-model="templateListOverview.bicCodeSearchFilter"
                                         ng-change="templateListOverview.searchBICNumber(templateListOverview.bicCodeSearchFilter)"
                                         ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLetters}}"
                                         autocomplete="off"
                                         required
                                         capitalize="">
                                </div>

                                <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                     ng-click="templateListOverview.bicCodeSearchFilterExpanded = !templateListOverview.bicCodeSearchFilterExpanded">
                                  <i class="icon icon-close domp-close-sign"
                                     ng-show="templateListOverview.bicCodeSearchFilter"
                                     ng-click="templateListOverview.removeBicCodeSearch()"></i>
                                  <i class="icon icon-arrdown m-r-0 pull-right"
                                     ng-show="!templateListOverview.bicCodeSearchFilter"></i>
                                </div>
                                <!--Dropdown menu -->
                                <div class="domp-filter-selection search-row"
                                     ng-class="{'opened': templateListOverview.bicCodeSearchFilterExpanded}">
                                  <div class="domp-filter-type-selection-wrapper"
                                       ng-repeat="bicBank in templateListOverview.bicBankOptions| filter:templateListOverview.bicCodeSearchFilter"
                                       ng-click="templateListOverview.onBicBankSelected(bicBank.bicCode)">
                                    <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                      <!--<i class="zmdi zmdi-account-circle"></i>-->
                                    </div>
                                    <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                      <p class="domp-list-name m-0">{{bicBank.bicCode}}</p>
                                    </div>
                                  </div>
                                </div>
                                <span class="c-red" translate="payments.bicNumberRequired"
                                      ng-show="templateListOverview.templatesListForm.bicCodeSearchFilter.$error.required && templateListOverview.tempObj.formSubmitted">
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicCountry">
                                Country
                              </span>
                            </div>
                          </div>
                          <div class="row p-r-15 p-l-15">
                            <div class="col-xs-12 domp-filter-container"
                                 ng-class="{'domp-error-custom-payee': templateListOverview.templatesListForm.countrySearchFilter.$invalid && templateListOverview.tempObj.formSubmitted}">
                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                                <i class="icon icon-search domp-search-sign m-l-5"></i>
                              </div>
                              <div class="col-sm-9 col-md-9 col-lg-9 col-xs-9 inner-section input-div searchrow">
                                <input type="text" class="search-input" name="countrySearchFilter"
                                       ng-model="templateListOverview.countrySearchFilter"
                                       ng-change="templateListOverview.countrySearchFilterExpanded = true;"
                                       ng-pattern-restrict="{{templateListOverview.regexPatterns.onlyNumbersAndLettersWithSuskavci}}"
                                       required
                                       autocomplete="off">
                              </div>

                              <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                                   ng-click="templateListOverview.countrySearchFilterExpanded = !templateListOverview.countrySearchFilterExpanded">
                                <i class="zmdi zmdi-close domp-close-sign"
                                   ng-show="templateListOverview.countrySearchFilter"
                                   ng-click="templateListOverview.removeSelectedCountry()"></i>
                                <i class="icon icon-arrdown  m-r-0 pull-right"
                                   ng-show="!templateListOverview.countrySearchFilter"></i>
                              </div>
                              <!--Dropdown menu -->
                              <div class="domp-filter-selection search-row"
                                   ng-class="{'opened': templateListOverview.countrySearchFilterExpanded}">
                                <div class="domp-filter-type-selection-wrapper"
                                     ng-repeat="country in templateListOverview.countriesOptions| filter: templateListOverview.countrySearchFilter"
                                     ng-click="templateListOverview.onCountrySelect(country)">
                                  <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <!--<i class="zmdi zmdi-account-circle"></i>-->
                                  </div>
                                  <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <p class="domp-list-name m-0">{{country.code}} {{country.description}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="c-red"
                                 ng-show="templateListOverview.templatesListForm.countrySearchFilter.$error.required && templateListOverview.tempObj.formSubmitted"
                                 translate="payments.bicCountryRequired"></div>

                          </div>
                        </div>
                      </div>

                      <div class="row m-t-25">
                        <div class="col-xs-12 col-md-4">
                          <!-- Required if Bic number is empty -->
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="payments.bicBankName">Bank Name</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field"
                                     name="bankNameInput"
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bankNameInput.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     ng-model="templateListOverview.tempObj.bankNameInput"
                                     autocomplete="off"
                                     required>
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.bankNameInput.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="payments.bicBankNameRequired"></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">

                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicRecipientStatus">Receiver (person)</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <select class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.recipientStatusRadiobutton.type">
                                <option value=2>{{"payments.bicRecipientStatusOptionPhysical"| translate}}</option>
                                <option value=1>{{"payments.bicRecipientStatusOptionLegal"| translate}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row m-t-25">
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted"
                                    translate="payments.bicAddress">BIC Address</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     autocomplete="off"
                                     ng-model="templateListOverview.tempObj.bankAddressInput">
                            </div>
                          </div>

                        </div>
                        <div class="col-md-4">

                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicCity">BIC City</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-12">
                              <input type="text" class="form-control input-field" name="bicCity"
                                     ng-model="templateListOverview.tempObj.bankCityInput"
                                     ng-pattern-restrict="{{templateListOverview.regexPatterns.allowedCharsDPS}}"
                                     replace-specific-chars
                                     ng-class="{'domp-error': templateListOverview.templatesListForm.bicCity.$invalid && templateListOverview.tempObj.formSubmitted}"
                                     required>
                              <span class="c-red"
                                    ng-show="templateListOverview.templatesListForm.bicCity.$error.required && templateListOverview.tempObj.formSubmitted"
                                    translate="payments.bicCityRequired"></span>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-xs-12">
                              <span class="invoice-inline-title text-muted" translate="payments.bicBankCharges">Troškovna opcija:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="col-xs-12" >
                              <select ng-switch-default
                                      class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.bankChargesRadiobutton.type">
                                <option value=3>SHA</option>
                                <option value=1>OUR</option>
                                <option value=2>BEN</option>
                              </select>
                              <select ng-switch-when="hr"
                                      class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.bankChargesRadiobutton.type">
                                <option value=3>SHA</option>
                                <option value=1>OUR</option>
                              </select>
                              <select ng-switch-when="sl"
                                      class="form-control input-field"
                                      ng-model="templateListOverview.tempObj.bankChargesRadiobutton.type">
                                <option value=0>SHA</option>
                                <option value=1>OUR</option>
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- END BIC -->


                    <!-- Save/Cancel action start -->

                    <div class="row  p-t-20">
                      <button class="btn btn-primary editTemplate" translate="templatesList.cancel"
                              ng-click="templateListOverview.cancel(template)">Cancel
                      </button>
                      <button class="btn btn-default editTemplate pull-right" translate="templatesList.save"
                              ng-click="templateListOverview.saveTemplate(template)">Edit
                      </button>
                    </div>
                    <!-- Save/Cancel actions end -->
                  </form>
                </div>
                <!-- EDITABLE END-->

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="row">
      <div class="m-10 text-center" ng-if="templateListOverview.templateList.hasMore">
        <hr>
        <button class="btn btn-default" ng-click="templateListOverview.loadMoreTemplates()"
                translate="messages.loadMore">Load more
        </button>
      </div>
    </div>

  </div>
</section>
