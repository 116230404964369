(function () {
    'use strict';
  
    angular
      .module('localization')
      .config(config);
  
    function config($translateProvider) {
  
      var data = {
        'common': {
            'addikoBankLocation': 'Lokacija Addiko Bank centrale',
            'openInGoogleMap': 'Open in Google Maps'
        }
      }

      $translateProvider.translations('en', data);
    }

}());