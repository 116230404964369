<div ng-controller="DomesticPaymentSrbCtrl as domesticPaymentSrb">
  <div class="domp-header-wrapper col-sm-12 p-0" ng-show="domesticPaymentSrb.currentStep!=3">
    <div class="domp-header-row">
        <h2 class="f-28" translate="domesticPayment.header">Domestic Payment</h2>
    </div>
  </div>
  <div class="domp-body-wrapper">
    <div ng-if="domesticPaymentSrb.currentStep === 1" class="domp-step-1">
      <form novalidate name="domesticPaymentSrb.domesticPaymentForm">
        <div class="domp-select-section col-sm-12 p-0">

          <!--SELECT TEMPLATE CUSTOM -->

          <div class="domp-template-filter-container">
            <div class="domp-select-template" ng-click="domesticPaymentSrb.toggleTemplateFilterSelection()" ng-show="domesticPaymentSrb.templateName==''">
              <div class="select-template label-select">
                <label class="p-t-10 f-18 p-t-5" translate="domesticPayment.selectFromTemplate">Select from template</label>
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x pull-right m-t-5"></i>
              </div>
            </div>

              <!-- Icon
                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3">
                      <div ng-if="!template.imageId" class="invoice-icon">ico</div>
                      <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico"
                           class="tpl-rounded-img"/>
                    </div>
              -->
            <div class="domp-selected-template" ng-show="domesticPaymentSrb.templateName!=''">
              <div class="select-template label-template-selected p-l-10 p-t-5" ng-show="domesticPaymentSrb.templateName!=''">
<!--                <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;" >

                </i>-->

                  <img ng-if="domesticPaymentSrb.selectedTemplate.imageId" ng-src="{{domesticPaymentSrb.selectedTemplate.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img-40"/>
                  <div ng-if="!domesticPaymentSrb.selectedTemplate.imageId" class="invoice-icon tpl-rounded-img-40" >ico</div>
                <label class="p-l-10 selected-template-label top-2"><b>{{domesticPaymentSrb.templateName}}</b></label>
                <i class="zmdi zmdi-close zmdi-hc-2x pull-right p-t-5" ng-click="domesticPaymentSrb.removeTemplate()"></i>
              </div>
            </div>
            <div class="domp-template-filter-selection" ng-class="{'opened': domesticPaymentSrb.searchTemplateFilterExpanded}">
              <div class="search-template">
                <div class="inner-section left">
                  <i class="icon icon-search domp-search-sign m-l-5"></i>
                </div>
                <div class="inner-section center" style="width: 50%;">
                  <input id="searchTemplatesInput" type="text" class="search-input" ng-model="domesticPaymentSrb.tmpSearchText.name" ng-change="domesticPaymentSrb.searchTemplate(domesticPaymentSrb.tmpSearchText.name)" placeholder="{{'dashboard.searchTemplatesPlaceholder' | translate}}">
                </div>
              </div>
              <div class="domp-template-filter">
                <div class="m-b-10">
                  <h4 class="p-l-5 m-t-20" translate="domesticPayment.listOfTemplates"></h4>
                  <div class="list-group" style="border-top: 2px solid #9eabd7;" scrollable>
                    <a href="" class="list-group-item" data-ng-repeat="template in domesticPaymentSrb.templateList.result | filter:domesticPaymentSrb.tmpSearchText" ng-click="domesticPaymentSrb.selectTemplate(template.id)">
                      <div class="row m-r-0">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2 p-r-0">
<!--                            <i class="zmdi zmdi-account-circle zmdi-hc-2x" style="font-size: 2.8em;"></i>-->
                            <img ng-if="template.imageId" ng-src="{{template.imageDownloadLink}}" alt="Ico" class="tpl-rounded-img"/>
                            <div ng-if="!template.imageId" class="invoice-icon tpl-rounded-img">ico</div>
                        </div>
                          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 domp-name-label p-t-15" style="padding-left: 50px !important;">
                          <h5 class="list-group-item-heading"><b>{{template.name}}</b></h5>
                          <p class="list-group-item-text">{{template.toAccountNumber}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--SELECT TEMPLATE CUSTOM END-->
        </div>


        <div class="col-sm-12 domp-from-section">
          <!-- CUSTOM ACCOUNTS DROPDOWN -->
          <div class="col-sm-6 domp-acc-dropdown">
            <label class="domp-form-label" translate="payments.fromAccount"></label>
            <div class="accounts-dropdown">
              <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="domesticPaymentSrb.toggleFromAccount();"  ng-style="domesticPaymentSrb.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + domesticPaymentSrb.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}" >
                <h4 class="no-wrap-text bold-font m-b-0">
                                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{domesticPaymentSrb.tempDompObj.fromAccountSelect.friendlyName}}</b>
                                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':domesticPaymentSrb.tempDompObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!domesticPaymentSrb.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                                </h4>
                <p class="m-b-0 m-t-0 iban-style">{{domesticPaymentSrb.tempDompObj.fromAccountSelect.iban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{domesticPaymentSrb.tempDompObj.fromAccountSelect.availableBalance | number:2}} {{domesticPaymentSrb.tempDompObj.fromAccountSelect.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                <span class="caret-icon" ng-hide="domesticPaymentSrb.isRetrieval"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
              </div>
              <div class="from-accounts-list" ng-show="domesticPaymentSrb.fromAccountDropdown" scrollable>
                <div class="accounts-list-item p-0" data-ng-repeat="item in domesticPaymentSrb.paymentTypeGroupDomestic.result[0].debitAccountList" data-ng-click="domesticPaymentSrb.setAccountFrom(item);">
                  <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                    <h4 class="no-wrap-text bold-font m-b-0">
                                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                        </h4>
                    <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                      {{item.availableBalance | number:2}} {{item.currencySymbol}}
                    </p>
                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row m-r-0">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput" translate="domesticPayment.amountInputLabel">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput" placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask maxlength="12" ng-model="domesticPaymentSrb.tempDompObj.amountInput" required ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.amountInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" ng-disabled="domesticPaymentSrb.isRetrieval">
                    <span class="input-group-btn">
                                              <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">{{domesticPaymentSrb.defaultCurrency}}</button>
                                         </span>
                  </div>
                  <span class="c-red" ng-show="domesticPaymentSrb.domesticPaymentForm.amountInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="internalTransfer.amountInputError"></span>
                </div>
              </div>
            </div>
            <div class="row m-r-0">
              <div class="col-sm-8">
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                  <div class="input-group ">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="shortDate" data-ng-model="domesticPaymentSrb.tempDompObj.dateInput"
                           show-weeks="false" is-open="domesticPaymentSrb.datePickerOpened"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           datepicker-options="domesticPaymentSrb.dateOptions"  readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="domesticPaymentSrb.openDatePicker()" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 receiver-section">
          <div class="row m-r-0">
            <!-- Start Account number - input -->
            <div class="col-sm-6 col-xs-12 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabelSRB">
                  Account number
                </label>
                <input type="text" class="form-control input-field" name="toAccountInput" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.onlyNumbersAndDashes}}" capitalize ng-minlength="6" dn-custom-maxlength="18" dn-exclude="-" ng-blur="domesticPaymentSrb.fillReceiver(domesticPaymentSrb.tempDompObj.toAccountInput); domesticPaymentSrb.fillReceiver(domesticPaymentSrb.tempDompObj.toAccountInput);" id="toAccountInput" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.toAccountInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" ng-model="domesticPaymentSrb.tempDompObj.toAccountInput" required ng-disabled="domesticPaymentSrb.isRetrieval">
                <div class="c-red" ng-show="domesticPaymentSrb.domesticPaymentForm.toAccountInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.receiverAccountError"></div>
                <div class="c-red" ng-show="domesticPaymentSrb.domesticPaymentForm.$error.minlength" translate="payments.minimalAccountNumber"></div>
              </div>
            </div>
            <!-- END Account number - input -->
            <!-- /////////// SELECT CUSTOM ///////////// -->
            <div click-outside="domesticPaymentSrb.searchFilterExpanded = false;" outside-if-not="expandPayeeSearchFilterSRB" class="col-sm-6 col-xs-12 left-section">
              <div  class="form-group" ng-hide="domesticPaymentSrb.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <div class="col-sm-12 col-xs-12 domp-filter-container" ng-class="{'domp-error-custom-payee': domesticPaymentSrb.domesticPaymentForm.payeeSearchFilter.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}">
                  <div  class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i  class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" name="payeeSearchFilter" id="payeeSearchFilter" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.allowedCharsDPS}}"
                           class="search-input" ng-model="domesticPaymentSrb.tempDompObj.receiversNameInput" ng-change="domesticPaymentSrb.searchPayee()"
                           required maxlength="35" ng-maxlength="35" autocomplete="off"
                           >
                  </div>
                  <div id="expandPayeeSearchFilterSRB" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right" ng-click="domesticPaymentSrb.toggleFilterSelection()">
                    <i class="zmdi zmdi-close domp-close-sign" ng-show="domesticPaymentSrb.tempDompObj.receiversNameInput!=''" ng-click="domesticPaymentSrb.removeSearch()"></i>
                      <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right" ng-show="domesticPaymentSrb.tempDompObj.receiversNameInput==''"></i>
                  </div>
                    <div class="domp-filter-selection search-row" ng-class="{'opened': domesticPaymentSrb.searchFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper" ng-repeat="payee in domesticPaymentSrb.payeeList | filter:domesticPaymentSrb.searchPayeeBy" ng-click="domesticPaymentSrb.selectPayee(payee.id)">
                      <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <i class="zmdi zmdi-account-circle"></i>
                      </div>
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                        <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentSrb.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></span>
              </div>
              <div class="form-group" ng-hide="!domesticPaymentSrb.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" name="payeeSearchFilter" id="payeeSearchFilter" ng-model="domesticPaymentSrb.tempDompObj.receiversNameInput" ng-blur="domesticPaymentSrb.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentSrb.tempDompObj.receiversAddressInput)" maxlength="35" ng-maxlength="35" required ng-disabled="domesticPaymentSrb.isRetrieval">
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0" ng-show="domesticPaymentSrb.domesticPaymentForm.payeeSearchFilter.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.toPayeeInputError"></span>
              </div>
            </div>
            <!--//////////////SELECT CUSTOM END/////////////////////// -->
          </div>

          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 left-section"> </div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="receiversAddressInput" translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label>
                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.receiversAddressInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" name="receiversAddressInput" id="receiversAddressInput" ng-model="domesticPaymentSrb.tempDompObj.receiversAddressInput" ng-blur="domesticPaymentSrb.replaceUnallowedCharsDPS('receiversAddressInput', domesticPaymentSrb.tempDompObj.receiversAddressInput)" maxlength="35" ng-maxlength="35" required ng-disabled="domesticPaymentSrb.isRetrieval">
                <span class="c-red" ng-show="domesticPaymentSrb.domesticPaymentForm.receiversAddressInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.receiverAddressError"></span>
              </div>
            </div>
          </div>
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 left-section"> </div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="receiversPostalCodeAndCityInput" translate="domesticPayment.receiversPostalCodeAndCityInputLabel">Receiver's postal code and city:
                </label>
                <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.allowedCharsDPS}}" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.receiversPostalCodeAndCityInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput" ng-model="domesticPaymentSrb.tempDompObj.receiversPostalCodeAndCityInput" ng-blur="domesticPaymentSrb.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', domesticPaymentSrb.tempDompObj.receiversPostalCodeAndCityInput)" maxlength="35" ng-maxlength="35" ng-disabled="domesticPaymentSrb.isRetrieval">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section">
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
              <div class="form-group">
                <div class="col-lg-12 col-md-4 no-padding-on-phone p-r-0 p-l-0">
                  <label class="domp-form-label" for="purposeCodeSelect" translate="domesticPayment.purposeCodeSelectLabel">Purpose code</label>
                  <div class="p-l-0">
                    <select class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect" id="purposeCodeSelect" ng-options="option.optionValue for option in domesticPaymentSrb.purposeCodesList.result" ng-model="domesticPaymentSrb.tempDompObj.purposeCodeSelect" ng-change="domesticPaymentSrb.changePurpose(domesticPaymentSrb.tempDompObj.purposeCodeSelect)" ng-disabled="domesticPaymentSrb.isRetrieval"></select>
                  </div>
                </div>
                <div class="col-lg-12 col-md-8 no-padding-on-phone p-r-0 p-l-0 p-t-10">
                  <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput" translate="domesticPayment.purposeOfPaymentInputLabel">Purpose</label>
                  <div class="p-0">
                    <input type="text" class="form-control input-field" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.purposeOfPaymentValidation}}" name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="105" maxlength="105" ng-model="domesticPaymentSrb.tempDompObj.purposeOfPaymentInput" required ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.purposeOfPaymentInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" ng-blur="domesticPaymentSrb.replaceUnallowedCharsDPS('purposeOfPaymentInput', domesticPaymentSrb.tempDompObj.purposeOfPaymentInput)" ng-disabled="domesticPaymentSrb.isRetrieval">
                    <span class="c-red" ng-show="domesticPaymentSrb.domesticPaymentForm.purposeOfPaymentInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="internalTransfer.paymentPurposeInputError"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- START Reference number - input -->
            <div class="col-lg-6 col-md-6 col-sm-6 left-section">
              <div class="col-xs-12 p-l-0">
                <label class="domp-form-label" translate="domesticPayment.referenceNumberLabel">Reference number:</label>
              </div>
              <div class="col-sm-3 col-xs-4 p-l-0 p-r-5">
                <div class="form-group m-b-0">
                  <select class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect" id="referenceModelSelect" ng-model="domesticPaymentSrb.tempDompObj.referenceModelSelect" >
                    <option ng-repeat="item in domesticPaymentSrb.referenceModelList">{{item}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-9 col-xs-8 p-l-0 p-r-0">
                <div class="form-group m-b-0">
                  <!-- Reference number for Serbia allows only uppercase letters, numbers and dashes-->
                  <!-- capitalize (attribute) directive forces uppercase letters-->
                  <input type="text" id="referenceNumberInput" class="form-control input-field" name="referenceNumberInput" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" maxlength="23" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.srb.allowedModuleEmptyChars}}" ng-keyup="domesticPaymentSrb.checkForUNallowedChars(domesticPaymentSrb.tempDompObj.referenceNumberInput)" ng-if="domesticPaymentSrb.tempDompObj.referenceModelSelect!='97' && domesticPaymentSrb.tempDompObj.referenceModelSelect!='11'" ng-model="domesticPaymentSrb.tempDompObj.referenceNumberInput" capitalize/>
                  <input type="text" id="referenceNumberInput" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" class="form-control input-field" name="referenceNumberInput" maxlength="26" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.srb.allowedModule97Chars}}" ng-blur="domesticPaymentSrb.checkModul97Reference('97' + domesticPaymentSrb.tempDompObj.referenceNumberInput)" ng-keyup="domesticPaymentSrb.checkForUNallowedChars(domesticPaymentSrb.tempDompObj.referenceNumberInput)" ng-if="domesticPaymentSrb.tempDompObj.referenceModelSelect=='97'" ng-model="domesticPaymentSrb.tempDompObj.referenceNumberInput" capitalize required/>
                  <input type="text" id="referenceNumberInput" ng-class="{'domp-error': domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$invalid && domesticPaymentSrb.tempDompObj.formSubmitted}" class="form-control input-field" name="referenceNumberInput" maxlength="23" ng-pattern-restrict="{{domesticPaymentSrb.regexPatterns.srb.allowedModule11Chars}}" ng-keyup="domesticPaymentSrb.checkForUNallowedChars(domesticPaymentSrb.tempDompObj.referenceNumberInput)" ng-if="domesticPaymentSrb.tempDompObj.referenceModelSelect=='11'" ng-model="domesticPaymentSrb.tempDompObj.referenceNumberInput" capitalize required/>
                </div>
              </div>
              <!-- START Error messages -->
              <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-if="domesticPaymentSrb.tempDompObj.referenceModelSelect=='97' &&
                 domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$error.modul97reference == true " translate="domesticPayment.referenceModelInputInvalid">
              </div>
              <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-if="domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputError">
              </div>
              <!--<div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="domesticPaymentSrb.domesticPaymentForm.referenceNumberInput.$error.required && domesticPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputError"></div>-->
              <!--   <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="domesticPayment.domesticPaymentForm.referenceModelInput.$invalid && domesticPayment.tempDompObj.referenceModelInput != '' && !domesticPayment.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputInvalid"></span> -->
              <!-- END Error messages -->
            </div>
            <!-- END Reference number input -->
          </div>
        </div>
        <div class="domp-footer-wrapper">
          <div class="row m-r-0">
            <div class="col-sm-6 col-lg-6 col-md-6 p-l-30">
              <div class="col-xs-12 p-l-10">
                <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                    <input type="checkbox" ng-model="domesticPaymentSrb.tempDompObj.isUrgentPaymentCheckbox" ng-click="domesticPaymentSrb.pushGtmUrgentPaymentEvent();">
                  <i class="input-helper"></i> {{'domesticPayment.selectUrgentLabelSRB' | translate}} <i uib-tooltip="{{'domesticPayment.urgentPaymentInfo' | translate}}" tooltip-append-to-body="true" tooltip-trigger="'mouseenter'" tooltip-placement="top" class="icon icon-question" style="font-weight: bolder; color:#ff4d5a;"></i>
                </label>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-md-6">
              <div class="col-lg-7 col-md-6 col-sm-5"></div>
              <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 p-r-5 p-t-10 charges-label">
                  <a ng-href="{{domesticPaymentSrb.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" class="pull-right">Check the bank charges</a>
              </div>
            </div>
          </div>
          <div class="row m-r-0">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary" type="button" translate="payments.cancel"
                      data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
            <div class="col-xs-6 t-a-r">
              <button class="btn btn-default" type="submit"
                      translate="domesticPayment.confirmBtn"
                      ng-click="domesticPaymentSrb.submitPayment();"></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div ng-if="domesticPaymentSrb.currentStep === 2" class="domp-step-2" ng-include="'payments/domestic-payment-resume.tpl.html'"></div>
    <div ng-if="domesticPaymentSrb.currentStep == 3">
      <div data-ng-include="'payments/domestic-payment-final.tpl.html'"></div>
    </div>
  </div>
</div>
