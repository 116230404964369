<section id="main">
    <div class="container">

        <div class="currency-conversion-msg">
            <div class="payment-successful-wrapper">
                <div class="row">
                    <div class="col-xs-2 m-t-30 m-b-30 msg-pad">
                        <div class="currency-check-icon msg-pad">
                            <i ng-if="paymentsFinal.selectedPayment.status !== 'Rejected'" class="icon icon-confirm c-white zmdi-hc-5x"></i>
                            <i ng-if="paymentsFinal.selectedPayment.status === 'Rejected'" class="icon icon-close c-white zmdi-hc-5x"></i>
                        </div>
                    </div>
                  <!-- Old design. Commented on 14.06 -->
<!--                    <div class="col-xs-10 m-t-30 m-b-30">
                        <h2 class="c-white f-28" ng-if="paymentsFinal.selectedPayment.paymentTypeGroup !== 'CurrencyExchange'" translate="internalTransfer.transferComplete"></h2>
                        <h2 class="c-white f-28" ng-if="paymentsFinal.selectedPayment.paymentTypeGroup === 'CurrencyExchange'" translate="currencyConversion.moneyExchangeComplete"></h2>
                        <div class="f-15">
                            <span ng-if="paymentsFinal.selectedPayment.paymentTypeGroup !== 'CurrencyExchange'" translate="internalTransfer.successfullyTransfer"></span>
                            <span ng-if="paymentsFinal.selectedPayment.paymentTypeGroup === 'CurrencyExchange'" translate="currencyConversion.successfullyExchange"></span>
                            <span>
                              {{paymentsFinal.selectedPayment.amount}}
                              {{paymentsFinal.selectedPayment.currencySymbol}}
                            </span>
                            <span translate="payments.fromAcc">from</span>
                            <span>{{paymentsFinal.selectedPayment.fromAccountNumber}}</span>
                            <span translate="payments.toAcc">to</span>
                            <span>{{paymentsFinal.selectedPayment.toAccountNumber}}</span>
                        </div>
                    </div>-->
                  <div class="col-xs-10 m-t-30 m-b-30 int-payment">



                    <!-- For all countries except SLO -->
                    <div ng-if="paymentsFinal.countryCode != 'sl'" class="f-15">

                      <h2 class="c-white f-28" ng-if="!paymentsFinal.isCancelation" translate="signPayments.finalStepSuccessfullySigned"></h2>
                      <h2 class="c-white f-28" ng-if="paymentsFinal.isCancelation" translate="instantPayment.finalStepSuccessfullySigned"></h2>

                      <div ng-if="paymentsFinal.defaultData.bankCharges || paymentsFinal.defaultData.BankChargesLink">
                        <span translate="signPayments.finalStepPaymentServices"></span>
                        <a ng-if="paymentsFinal.defaultData.bankCharges" ng-href="{{paymentsFinal.bankCharges}}" class="bank-tariff-link" target="_blank" translate="signPayments.finalStepBankTariff" class="pull-right"></a>
                        <a ng-if="paymentsFinal.defaultData.BankChargesLink" ng-href="{{paymentsFinal.defaultData.BankChargesLink}}" class="bank-tariff-link" target="_blank" translate="signPayments.finalStepBankTariff" class="pull-right"></a>
                      </div>
                      <div class="m-t-15" >
                        <span ng-if="!paymentsFinal.isCancelation" translate="signPayments.finalStepStatus"></span>
                        <span ng-if="paymentsFinal.paymentType !== 'group' && !paymentsFinal.isCancelation">
                        <!--{{paymentsFinal.selectedPayment.status | translate}}-->
                        {{'paymentsFinal.'+paymentsFinal.selectedPayment.status | translate}}
                        </span>
                        <span ng-if="paymentsFinal.paymentType === 'group' && !paymentsFinal.isCancelation">
                        {{'paymentsFinal.'+paymentsFinal.paymentsGroupList.statusId  | translate}}
                        </span>
                      </div>
                      <div>
                        <span translate="signPayments.finalStepPaymentID"></span>
                        <span ng-if="paymentsFinal.paymentType !== 'group'">
                        {{paymentsFinal.selectedPayment.id}}
                        </span>
                        <span ng-if="paymentsFinal.paymentType === 'group'">
                        {{paymentsFinal.paymentsGroupList.id}}
                        </span>
                      </div>
                      <div ng-if="paymentsFinal.paymentType === 'group'">
                        <span translate="signPayments.finalStepNumberOfPayments"></span>
                        <span>
                        {{paymentsFinal.paymentsGroupList.paymentList.length}}
                        </span>
                      </div>
                      <div class="m-t-15" >
                        <span translate="signPayments.finalStepSentForProcessing"></span>
                      </div>
                    </div>

                    <!-- For SLO -->
                    <div ng-if="paymentsFinal.countryCode === 'sl' && paymentsFinal.selectedPayment.status !== 'Rejected'">

                        <h2 class="c-white f-28" translate="signPayments.finalStepPaymentSloHeader"></h2>

                        <p class="f-16">
                            <span translate="signPayments.finalStepPaymentID"></span>
                            <span id="selectedPaymentId">{{paymentsFinal.selectedPayment.id}}</span>
                        </p>

                        <p class="f-16">
                            <span translate="signPayments.finalStepPaymentSloSubHeader"></span><a href="#!/payments/payments-overview" translate="navigation.paymentsList"></a>.<br>
                            <span translate="signPayments.finalStepPaymentSloText"></span>
                        </p>

                    </div>

                    <div ng-if="paymentsFinal.countryCode === 'sl' && paymentsFinal.selectedPayment.status === 'Rejected'">

                      <h2 class="c-white f-28" translate="signPayments.finalStepPaymentRejectedSloHeader"></h2>

                      <p class="f-16">
                          <span translate="signPayments.finalStepPaymentID"></span>
                          <span id="selectedPaymentId">{{paymentsFinal.selectedPayment.id}}</span>
                      </p>

                      <p class="f-16">
                          <span>{{paymentsFinal.selectedPayment.note}}</span>.
                          <span translate="signPayments.finalStepPaymentRejectedSloText"></span>
                          <a href="#!/payments/payments-overview" class="underline" translate="navigation.paymentsList"></a>.<br>
                      </p>

                  </div>

                  </div>
                </div>
            </div>

            <div class="row m-t-20" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                <!-- Save template only for Domestic or foreign payments-->
                <div ng-switch-default class="col-xs-12 col-sm-4" ng-show="paymentsFinal.canBeSavedToTemplate">
                    <a href="" data-ui-sref="paymentTemplates.saveTemplate({paymentId: paymentsFinal.selectedPayment.id, type: paymentsFinal.paymentType, paymentObject: paymentsFinal.selectedPayment })" >
                        <span class="money-exchange-icon"><i class="icon icon-archive zmdi-hc-2x" style="color:#062a42;"></i></span>
                        <span class="f-18 money-exchange-text" translate="internalTransfer.saveAsTemplate"></span>
                    </a>
                </div>
              <div ng-switch-when="sl" ng-if="!paymentsFinal.isFromTemplate" class="col-xs-12 col-sm-4" ng-show="paymentsFinal.canBeSavedToTemplate">
                    <a href="" data-ui-sref="paymentTemplates.saveTemplate({paymentId: paymentsFinal.selectedPayment.id, type: paymentsFinal.paymentType, paymentObject: paymentsFinal.selectedPayment })" >
                        <span class="money-exchange-icon"><i class="icon icon-archive zmdi-hc-2x" style="color:#062a42;"></i></span>
                        <span class="f-18 money-exchange-text" translate="internalTransfer.saveAsTemplate"></span>
                    </a>
                </div>

                <div class="col-xs-12" ng-if="paymentsFinal.paymentType !== 'group'" ng-class="{'col-sm-6': !paymentsFinal.canBeSavedToTemplate, 'col-sm-4': paymentsFinal.canBeSavedToTemplate}">
                    <a href="" data-ng-click="paymentsFinal.makeAnotherPayment();" >
                        <span class="money-exchange-icon"><i class="icon icon-reclam zmdi-hc-2x" style="color:#062a42;"></i></span>
                        <span class="f-18 money-exchange-text" translate="internalTransfer.makeAnotherPayment" ></span>
                    </a>
                </div>

                <div class="col-xs-12 col-sm-4" ng-class="{'col-sm-6': !paymentsFinal.canBeSavedToTemplate, 'col-sm-4': paymentsFinal.canBeSavedToTemplate}">
                    <a href="" data-ng-click="paymentsFinal.goToListOfPayments()">
                        <span class="money-exchange-icon"><i class="icon icon-clean zmdi-hc-2x" style="color:#062a42;"></i></span>
                        <span class="f-18 money-exchange-text" translate="currencyConversion.goToPayments"></span>
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>
