(function () {
    'use strict';

    angular
        .module('navigation')
        .config(config);

    function config($translateProvider) {

        var data = {
            messages: {
                "createBtn": "Kreiraj novu poruku",
                "receivedTab": "Primljene",
                "sentTab": "Poslane",
                "unreadLabel": "Nepročitane poruke",
                "readLabel": "Pročitane poruke",
                "replyBtn": "Odgovori",
                "printBtn": "Ispis",
                "deleteBtn": "Izbriši",
                "attachmentList": "Lista priloga:",
                "loadMore":"Prikaži više",
                "downloadAttachment": "Preuzmi privitak"
            },
            "newMessage": {
                "titleLabel": "Nova poruka",
                "titleReplyLabel": "Odgovori na poruku",
                "subjectLabel": "Naslov",
                "subjectPlaceholder": "Unesite naslov poruke ovdje",
                "bodyLabel": "Sadržaj",
                "header": "Poruke",
                "backBtn": "Nazad",
                "sendBtn": "Pošalji",
                "uploadButton": "Dodaj priloge",
                "dragAndDrop": "Povucite priloge ovdje",
                "successLabel1": "Poruka poslana",
                "successLabel2": "Poruka ",
                "successLabel3": " je uspješno poslana.",
                "newLabel": "Kreiraj novu poruku",
                "goToMsgsLabel": "Idi na poruke"
            }
        };

        $translateProvider
            .translations('hr', data);

    }
}());
