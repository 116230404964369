(function () {
  'use strict';

  angular
    .module('paymentTemplates')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('paymentTemplates', {
        url: '/payment-templates',
        templateUrl: 'payment-templates/payment-templates.tpl.html',
        controller: 'PaymentTemplatesCtrl',
        controllerAs: 'paymentTemplates'
      })
      .state('paymentTemplates.templateListOverview', {
        url: '/template-list-overview',
        views: {
          '@': {
            templateUrl: 'payment-templates/template-list-overview.tpl.html',
            controller: 'TemplateListOverviewCtrl',
            controllerAs: 'templateListOverview'
          }
        }
      })
      .state('paymentTemplates.saveTemplate', {
        url: '/:paymentId/:type/save-template',
        params: {
          paymentObject: null,
          type: 'other'
        },
        views: {
          '@': {
            templateUrl: 'payment-templates/payment-new-template.tpl.html',
            controller: 'PaymentNewTemplateCtrl',
            controllerAs: 'newTemplate'
          }
        }
      });
  }
}());
