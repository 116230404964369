(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name orders.controller:TermDepositCtrlSlo
     *
     * @description
     *
     */
    angular
      .module('orders')
      .controller('TermDepositCtrlSlo', TermDepositCtrlSlo);

    function TermDepositCtrlSlo($state, $rootScope, AccountsService, $log, $q, DepositOrderTypesService, CurrencyService, $scope, $timeout, DepositOrdersService, HelperService,
                                $translate, $filter, ConfigEndpointService, $window, ValidationService, UserSettingsService, AlertService, $localStorage, GoogleTagManagerHelperService, NotificationService) {
      var vm = this;
      var selectedLanguage = $localStorage.appLanguage;
      var depositTypes = [];
      var accountsFrom = [];
      var daysBetweenMonths = 0;
      // Depozit Zlata Jesen - default
      vm.depositTypeGroup = 3;
      var depositFindParams = {
        currency: "",
        duration: "",
        interestPayout: ""
      };

      vm.dateOptions = {
        minDate: new Date()
      };

      vm.accountsFrom = {};
      vm.assignmentOfInterestisShown = true;
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
      vm.model = {
        depositType: "",
        fromAccountSelect: {},
        totalInterestRate: 0,
        maturityDate: new Date(),
        amount: "",
        currencyCode: "",
        assignmentRange: "",
        interestPayout: "",
        maturityInDays: ""
      };

      vm.responseModel = {};

      vm.periods = "";

      // For interestRange property
      var assignmentOfInterestOptionsEnglish = [
        {id: 1, value: "termDeposit.assignmentOfInterestAnnual", name: "Annual reporting"},
        {id: 2, value: "termDeposit.assignmentOfInterestAfter", name: "Report after maturity"}
      ];

      var assignmentOfInterestOptionsSlovenian = [
        {id: 1, value: "termDeposit.assignmentOfInterestAnnual", name: "Letno poročanje"},
        {id: 2, value: "termDeposit.assignmentOfInterestAfter", name: "Poročanje po poteku"}
      ];

      vm.assignmentOfInterestOptions = [
        {id: 1, value: "termDeposit.assignmentOfInterestAnnual"},
        {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
      ];

      vm.assignmentOfInterestId = vm.assignmentOfInterestOptions[0].id;

      vm.steps = {
        step1: true,
        step2: false,
        step3: false
      };

      vm.disabled = function (date, mode) {
        return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
      };

      $rootScope.$on('$translateChangeEnd', function (evt, data) {
        selectedLanguage = data.language;
      });

      vm.interestPayoutOptions = [
        {
          id: 0,
          value: "termDeposit.interestPayoutMonthly",
          name: "Monthly"
        },
        {
          id: 1,
          value: "termDeposit.interestPayoutAfterMaturity",
          name: "After maturity"
        }
      ]
      vm.model.interestPayout = vm.interestPayoutOptions[1];

      // ===========
      // INITIALIZE
      // ===========
      init();

      function init() {
        loadData();
      };

      function loadData() {
        var promises = [];
        promises.push(DepositOrderTypesService.getAll({
          pageSize: 1000,
          includeList: ["depositOrderTypeCalculationList"],
          orderBy: "sortOrderId"
        }));
        promises.push(AccountsService.getDepositOrderAccounts({pageSize: 1000}));
        // promises.push(CurrencyService.getCurrencyPage({pageSize: 5000}));

        $q.all(promises)
          .then(function (responses) {
            // ==========
            // Request 1
            // ==========
            vm.depositOrderGroupOptions = _.groupBy(responses[0].result, 'groupDescription');
            // Select first by default
            for (var key in vm.depositOrderGroupOptions) {
              depositTypes = vm.depositOrderGroupOptions[key];
              vm.depositGroup = vm.depositOrderGroupOptions[key];
              break;
            }
            findAndSetMinPeriod(depositTypes);

            // ==========
            // Request 2
            // ==========
            var accounts = responses[1].result;
            accounts = _.filter(accounts, function (account) {
              return account.isOwn;
            })

            _.each(accounts, function (account) {
              accountsFrom.push(account);
              _.each(account.subAccountList, function (subAccount) {
                if (account.isOwn) subAccount.isOwn = true;
                if (subAccount.currencySymbol == "CHF" || subAccount.currencySymbol == "USD" || subAccount.currencySymbol == "GBP") accountsFrom.push(subAccount);
              })
            })

            vm.accountsFrom = accountsFrom;
            vm.setAccountFrom(vm.accountsFrom[0]);

            vm.onDepositGroupChange(vm.depositGroup);
            // findDeposit();
            vm.isPageLoaded = true;
          }, function (error) {
            console.log(error);
          })
      }

      // ========
      // VIEW
      // ========

      function findAndSetMinPeriod(depositTypes) {
        // Za Depozit Zlatna jesen i Addiko Rastuci - defaultno minimalni broj dana postaviti
        if (depositTypes[0].group != 2 && depositTypes[0].group != 3) {
          vm.model.maturityInDays = 365;
          return;
        }
        var minDurations = [];
        _.each(depositTypes, function (deposit) {
          minDurations.push(parseInt(deposit.depositOrderTypeCalculationList[0].periodFrom));
        })
        vm.model.maturityInDays = _.min(minDurations);
      }

      function findDeposit() {
        depositFindParams.currency = vm.model.currency;
        depositFindParams.interestPayout = vm.model.interestPayout.id;
        depositFindParams.duration = daysBetweenMonths;

        // Don't ask !!

        // First - Find deposits by currency
        var depositsByCurrency = _.filter(depositTypes, function (depositType) {
          return (depositType.currency == depositFindParams.currency);
        })
        if (depositsByCurrency.length) {
          vm.termDepositForm.amount.$setValidity("currency", true);
        } else {
          vm.termDepositForm.amount.$setValidity("currency", false);
        }

        // Then - find deposits by duration
        var depositsByDuration = _.filter(depositsByCurrency, function (depositType) {
          var minDuration = parseInt(depositType.depositOrderTypeCalculationList[0].periodFrom);
          var maxDuration = parseInt(depositType.depositOrderTypeCalculationList[0].periodTo);

          vm.periods = {
            min: minDuration,
            max: maxDuration
          };

          return (minDuration <= parseInt(depositFindParams.duration) && parseInt(depositFindParams.duration) <= maxDuration)
        });
        if (depositsByDuration.length) {
          vm.termDepositForm.maturityInDays.$setValidity("duration", true);
        } else {
          vm.termDepositForm.maturityInDays.$setValidity("duration", false);
        }

        // Hide duration error message if there are no deposits for selected currency
        if (!depositsByCurrency.length) {
          vm.termDepositDurationMessageShown = false;
        } else {
          vm.termDepositDurationMessageShown = true;
        }

        // Then - if theres multiple results filter by monthly release, if not select only 1
        var finalResult;
        // ako je na osnovi ostalih parametra moguč samo jedan tip depozita, koji ima monthlyRelease = 1, onda samo value "After maturity" je moguč u dropdownu;
        // ako su moguče depozit types, koji neki imaju monthlyRelease = 1 i neki monthlyRelease = 0, onda u dropdownu možeš birati "Monthly",  i  "After maturity";
        if (depositsByDuration.length > 1) {
          finalResult = _.find(depositsByDuration, function (depositType) {
            return (depositType.monthlyRelease == depositFindParams.interestPayout)
          })
          vm.interestPayoutDisabled = false;
        } else if (depositsByDuration.length == 1) {
          finalResult = depositsByDuration[0];
          // Select Interest payout for specific term deposit and disable selection of Ipayout (not needed)
          vm.model.interestPayout = _.find(vm.interestPayoutOptions, {id: +finalResult.monthlyRelease});
          vm.interestPayoutDisabled = true;
        } else {
          vm.interestPayoutDisabled = false;
        }

        // Lock for deposit type
        if (vm.depositTypeGroup == 3) {
          vm.interestPayoutDisabled = true;
        }

        // Select deposit
        vm.model.depositType = finalResult;
        if (vm.model.depositType && vm.model.depositType.depositOrderTypeCalculationList) {
          vm.model.depositType.depositOrderTypeCalculationList = _.sortBy(vm.model.depositType.depositOrderTypeCalculationList, function (item) {
            return item.fromAmount;
          })
          vm.model.depositType.depositOrderTypeCalculationList = vm.model.depositType.depositOrderTypeCalculationList.reverse();
        }
        onDepositTypeChange(vm.model.depositType);
      }

      vm.setAccountFrom = function (item) {
        vm.model.fromAccountSelect = item;
        vm.model.currency = vm.model.fromAccountSelect.currencySymbol;
        // vm.depositsFilter.accountId = item.accountId;
        vm.fromAccountDropdown = false;
        vm.paymentsPeriodFilter(vm.selectedPeriod);
        // vm.getTimelineItemsGrouped(true);
        findDeposit();
      };

      vm.toggleFromAccount = function () {
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
      };

      vm.onDepositGroupChange = function (depositGroup) {
        depositTypes = angular.copy(depositGroup);
        vm.depositTypeGroupName = depositGroup[0].groupDescription;
        vm.depositTypeGroup = depositGroup[0].group;
        findAndSetMinPeriod(depositTypes);
        if (vm.depositTypeGroup == 3) {
          // For groupDescription "DEPOZIT ZLATA JESEN", we only get monthlyRelease "1", so only 'After maturity" should be available in dropdown Interest payout.
          vm.model.interestPayout = vm.interestPayoutOptions[1];
          vm.interestPayoutDisabled = true;

          vm.accountsFrom = _.filter(accountsFrom, function (account) {
            return (account.currencySymbol == "EUR")
          })
          if (!_.find(vm.accountsFrom, {accountId: vm.model.fromAccountSelect.accountId})) {
            vm.model.fromAccountSelect = vm.accountsFrom[0];
            vm.model.currency = vm.model.fromAccountSelect.currencySymbol;
            // vm.depositsFilter.accountId = vm.model.fromAccountSelect.accountId;
            vm.fromAccountDropdown = false;
          }
        } else if (vm.depositTypeGroup == 2) {
          vm.accountsFrom = _.filter(accountsFrom, function (account) {
            return (account.currencySymbol == "EUR")
          })
        } else {
          vm.accountsFrom = angular.copy(accountsFrom);
          vm.interestPayoutDisabled = false;
        }

        vm.calculateMaturityDateWithDays(vm.model.maturityInDays)
        findDeposit();
      }

      vm.onInterestPayoutChange = function () {
        findDeposit();
      }

      function onDepositTypeChange(depositType) {
        if (!depositType) {
          vm.assignmentOfInterestOptions = [
            {id: 1, value: "termDeposit.assignmentOfInterestAnnual"},
            {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
          ];
          vm.model.assignmentOfInterest = vm.assignmentOfInterestOptions[0].id;
          vm.assignmentOfInterestisShown = true;
          vm.assignemntOfInterestIsDisabled = false;
          vm.durationReadonly = false;
          return;
        }

        // // "revewal" = "1" i ako ima, stavi input field za periodu na READ-ONLY i popuni ga sa value "periodFrom / 30", tako da dobije broj mjeseca.
        // if (depositType.renewal == 1) {
        //   vm.model.maturityInDays = depositType.depositOrderTypeCalculationList[0].periodFrom;
        //   vm.model.maturityInMonths = Math.round(depositType.depositOrderTypeCalculationList[0].periodFrom / 30);
        //   vm.durationReadonly = true;
        // } else {
        //   vm.durationReadonly = false;
        // }

        calculateInterestRate();
        vm.model.currencyCode = depositType.currencyCode;

        // K = 1
        // D = 2
        if (depositType.periodType == 1) {
          vm.assignmentOfInterestOptions = [
            {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
          ];
          vm.assignmentOfInterestId = vm.assignmentOfInterestOptions[0].id;
          vm.assignmentOfInterestisShown = true;
          vm.assignemntOfInterestIsDisabled = true;
        }
        else if (depositType.periodType == 2) {
          vm.assignmentOfInterestOptions = [
            {id: 1, value: "termDeposit.assignmentOfInterestAnnual"},
            {id: 2, value: "termDeposit.assignmentOfInterestAfter"}
          ];
          vm.model.assignmentOfInterest = vm.assignmentOfInterestOptions[0].id;
          vm.assignmentOfInterestisShown = true;
          vm.assignemntOfInterestIsDisabled = false;
        } else if (!depositType.periodType) {
          vm.model.assignmentOfInterest = "";
          vm.assignmentOfInterestisShown = false;
          vm.assignemntOfInterestIsDisabled = false;
        }
        // validate amount
        vm.amountChanged(vm.model.amount);
      }

      vm.amountChanged = function (amount) {
        if (!amount) amount = 0;
        if (vm.model.depositType && (amount < parseInt(vm.model.depositType.minAmount))) {
          vm.model.totalInterestRate = 0;
          vm.termDepositForm.amount.$setValidity("minamount", false);
        } else {
          calculateInterestRate();
          vm.termDepositForm.amount.$setValidity("minamount", true);
        }
      }

      function calculateInterestRate() {
        if (!vm.model.depositType) {
          vm.model.totalInterestRate = 0;
          return;
        }
        var dl = angular.copy(vm.model.depositType.depositOrderTypeCalculationList);
        for (var i = 0; i < dl.length; i++) {
          var item = dl[i];
          // First one that is bigger than amount
          if (vm.model.amount >= item.fromAmount) {
            vm.model.totalInterestRate = item.interestRateString;
            break;
          } else {
            vm.model.totalInterestRate = 0;
          }
        }
      }

      vm.calculateMaturityDays = function (date) {
        vm.model.maturityInMonths = "";
        daysBetweenMonths = vm.model.maturityInDays = Math.round((date - new Date()) / 86400000);
        findDeposit();
      }

      vm.calculateMaturityDate = function (durationInMonths) {
        if (!durationInMonths) {
          return;
        }
        if (durationInMonths.length > 3) return;

        // resetMaturityDateValidation();
        vm.model.maturityDate = HelperService.setCustomMonthPeriod(new Date(), parseInt(durationInMonths));
        daysBetweenMonths = Math.round((vm.model.maturityDate - new Date()) / 86400000);
        vm.model.maturityInDays = daysBetweenMonths;
        findDeposit();
        // validateMaturityDate(daysBetweenMonths);
      }

      vm.calculateMaturityDateWithDays = function (durationInDays) {
        if (!durationInDays) {
          durationInDays = 0;
        }

        if (daysBetweenMonths != durationInDays) {
          vm.model.maturityInMonths = "";
        }

        if (durationInDays.length > 6) return;
        // resetMaturityDateValidation();
        daysBetweenMonths = durationInDays;
        vm.model.maturityDate = HelperService.setCustomMonthPeriodByDays(new Date, parseInt(durationInDays));
        findDeposit();
        // validateMaturityDate(durationInDays);
      }

      // function validateMaturityDate(daysBetweenMonths) {
      //   if (vm.minDurationInDays > parseInt(daysBetweenMonths)) {
      //     vm.termDepositForm.maturityInMonths.$setValidity("minvalue", false);
      //   } else {
      //     vm.termDepositForm.maturityInMonths.$setValidity("minvalue", true);
      //   }
      //
      //   if (daysBetweenMonths > parseInt(vm.maxDurationInDays)) {
      //     vm.termDepositForm.maturityInMonths.$setValidity("maxvalue", false);
      //   } else {
      //     vm.termDepositForm.maturityInMonths.$setValidity("maxvalue", true);
      //   }
      // }

      // function resetMaturityDateValidation() {
      //   vm.termDepositForm.maturityInMonths.$setValidity("minvalue", true);
      //   vm.termDepositForm.maturityInMonths.$setValidity("maxvalue", true);
      // }

      vm.showOrderDetails = function (term) {
        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
          term.selected = !term.selected;
          return;
        }
        if (!term.selected) {
          DepositOrdersService.getManualDepositOrdersById(term.id)
            .then(function (response) {
              angular.extend(term, response);
              term.translatedStatus = "orders." + term.status;
              term.payoutTypeTranslate = 'core.termDepositPayoutType.' + term.payoutType;
              term.selected = !term.selected;
            }, function (error) {
              console.log(error);
            });
        } else {
          term.selected = false;
        }
      };


      vm.loadAllAccountsTimeline = function () {
        vm.getTimelineItemsGrouped(true);
      };

      function createInsertObject() {
        // if (selectedLanguage == "en") {
        //   vm.model.interestRange = _.find(assignmentOfInterestOptionsEnglish, {id: vm.assignmentOfInterestId});
        // } else if (selectedLanguage == "sl") {
        //   vm.model.interestRange = _.find(assignmentOfInterestOptionsSlovenian, {id: vm.assignmentOfInterestId});
        // }
        vm.model.interestRange = _.find(assignmentOfInterestOptionsSlovenian, {id: vm.assignmentOfInterestId});

        return {
          accountId: vm.model.fromAccountSelect.iban,
          amount: vm.model.amount,
          currencyCode: vm.model.currencyCode,
          depositType: vm.model.depositType.typeCode,
          maturityDate: vm.model.maturityDate,
          interestPayout: vm.model.interestPayout.id,
          termPeriod: vm.model.maturityInDays,
          interestRange: {
            id: vm.model.interestRange.name,
            description: vm.model.interestRange.name
          },
          // assignmentOfInterest: vm.model.assignmentOfInterest.id,

          depositNrOfExtensions: 0,
          jobPurpose: " ",
          isRenewal: "true"
        };
      }

      vm.next = function () {
        if (vm.nextButtonPressed) return;
        if (vm.termDepositForm.$invalid) {
          return;
        }
        vm.formSubmitted = true;

        vm.nextButtonPressed = true;
        DepositOrdersService.validateManualDepositOrder(createInsertObject()).then(function (response) {
          if (response.changedDate) {
            AlertService.confirmationAlert({text: "orders.maturityDateChangedErr"})
              .then(function (accept) {
                if (!accept) return;
                vm.model.maturityDate = new Date(response.maturityDate);
                vm.calculateMaturityDays(vm.model.maturityDate);
                vm.responseModel = angular.copy(vm.model);
                vm.steps = {
                  step1: false,
                  step2: true,
                  step3: false
                };
              })
          } else {
            vm.responseModel = angular.copy(vm.model);
            vm.steps = {
              step1: false,
              step2: true,
              step3: false
            };
          }

        }).catch(function (error) {
          // vm.calculateMaturityDate(vm.model.maturityInMonths);
          NotificationService.showMessage(error, 'error');
        }).finally(function (error) {
          vm.nextButtonPressed = false;
        });
      };

      vm.confirm = function () {
        if (vm.confirmButtonPressed) return;
        vm.confirmButtonPressed = true;
        DepositOrdersService.insertManualDepositOrder(createInsertObject()).then(function (response) {
          vm.steps = {
            step1: false,
            step2: false,
            step3: true
          };
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function () {
          vm.confirmButtonPressed = false;
        });
      }

      vm.back = function () {
        vm.steps = {
          step1: true,
          step2: false,
          step3: false
        };
      };

      vm.newTermDeposit = function () {
        $state.go("orders.termDeposit", {}, {reload: true});
      }


      // =========================
      // START Timeline settings
      // =========================

      vm.recompile = {
        timeline: true
      };
      vm.timelineListGrouped = {};

      vm.loadMoreTimelineItemsGrouped = function () {
        vm.getTimelineItemsGrouped(false);
      };

      var timeRange = HelperService.getDateRange('thisMonth');
      vm.selectedAccountId = '';
      vm.depositsFilter = {
        page: 0,
        orderBy: 'dateCreated DESC', //signDate
        pageSize: 15,
        signDateGTE: null,
        signDateLTE: null,
        accountId: null,
        userId: null,
      };

      vm.userSettingsFilter = {
        page: 0,
        pageSize: 1000
      };

      vm.periodList = [
        {
          item: {
            key: 'today',
            description: 'core.period.today'
          }
        }
        , {
          item: {
            key: 'yesterday',
            description: 'core.period.yesterday'
          }
        }
        , {
          item: {
            key: 'thisWeek',
            description: 'core.period.thisWeek'
          }
        }
        , {
          item: {
            key: 'thisMonth',
            description: 'core.period.thisMonth'
          }
        }
        , {
          item: {
            key: 'thisYear',
            description: 'core.period.thisYear'
          }
        }
        , {
          item: {
            key: 'all',
            description: 'core.period.all'
          }
        }
      ];

      vm.selectedPeriod = vm.periodList[3];

      vm.paymentsPeriodFilter = function (item) {
        vm.selectedPeriod = item;
        var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
        vm.depositsFilter.signDateGTE = timeRange.valueDateGTE;
        vm.depositsFilter.signDateLTE = timeRange.valueDateLTE;
        vm.getTimelineItemsGrouped(true);
        GoogleTagManagerHelperService.pushEvent('TermDeposit', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

      };

      vm.togglePaymentDetails = function (payment) {
        payment.selected = !payment.selected;
      };

      var translatePaymentProperties = function (orders) {
        _.forEach(orders, function (item) {
          item.translatedStatus = "orders." + item.status;
          item.payoutTypeTranslate = 'core.termDepositPayoutType.' + item.payoutType;
        });
      };

      vm.getTimelineItemsGrouped = function (reset) {

        if (reset) {
          vm.depositsFilter.page = 0;
        } else {
          vm.depositsFilter.page += 1;
        }


        // if (vm.allRequests) {
        //   vm.depositsFilter.accountId = null;
        // } else {
        //   if (vm.model.fromAccountSelect)
        //     vm.depositsFilter.accountId = vm.model.fromAccountSelect.accountId;
        //
        // }

        //vm.depositsFilter.userId = vm.userSettings.id;

        DepositOrdersService.getManualDepositOrders(vm.depositsFilter)
          .then(function (response) {
            translatePaymentProperties(response.result);
            vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
              existingData: vm.timelineListGrouped,
              newData: response,
              groupFunction: function (item) {
                var date = new Date(item.dateCreated);
                // If we want to group only by date component, without hours
                date.setHours(0, 0, 0, 0);
                return date;
              },
              virtualRowFunction: function (e) {
                var month = new Date(e).getMonth() + 1;
                var day = new Date(e).getDate();
                var virtualRow = {
                  isVirtual: true,
                  valueDate: new Date(e),
                  month: month,
                  day: day
                };
                $log.debug("virtualRow", e, virtualRow);
                return virtualRow;
              },
              reset: reset,
              reverse: false
            });
            $log.debug("groupedEDespositOrdersTimeline", vm.timelineListGrouped);
            //alert(2);
            vm.recompile.timeline = true;
          }).catch(function (error) {
          $log.error(error);
        });
      };
      // =========================
      // END Timeline settings
      // =========================

      vm.downloadContract = function (id) {
        DepositOrdersService.downloadContract(id)
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      };

      vm.downloadTermsAndConditions = function (id) {
        DepositOrdersService.downloadTermsAndConditions(id)
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }

    }
  }
  ()
)
;
