<section id="main" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <div class="container-fluid">
    <div class="row">
      <div class="tmplt-list-title col-md-12 p-r-0 m-b-15" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
        <h2 ng-switch-default translate="sepa.transactionsHeader" class="f-28"></h2>
        <h2 ng-switch-when="hr" translate="navigation.sepaTransactions_CRO" class="f-28"></h2>
      </div>
    </div>
    <!-- ================ -->
    <!-- DEFAULT -->
    <!-- =============== -->
    <div ng-switch-default>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-5 m-b-20">
          <div class="account-item-add-message app-dblue-card " ng-click="sepaTrans.goToNewSepa()">
            <div class="round-btn-container2">
              <div class="round-btn-m">
                <i class="icon icon-add icon-add-msg bold-font f-24"></i>
              </div>
              <div class="round-btn-label-msg">
                <h3 class="round-btn-label-msg f-20" translate="sepa.addNew"></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" style="z-index:1;">
          <!-- Easier way to duplicate code - than modifying CSS width-->
          <div class="po-type-container">
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 1}"
                 ng-click="sepaTrans.selectPaymentsType('Waiting', 'sepa.paymentsType.pendingTab')"
                 class="po-type-tab no-wrap-text" style="width:33%"
                 translate="sepa.paymentsType.pendingTab"></div>
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 2}"
                 ng-click="sepaTrans.selectPaymentsType('Rejected', 'sepa.paymentsType.rejectedTab')"
                 class="po-type-tab no-wrap-text" style="width:34%">
              {{ 'sepa.paymentsType.rejectedTab' | translate}}
              <!--            <div class="pull-right tab-bubble">
                            <div class="dn-item-bubble">123</div>
                          </div>-->
            </div>
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 3}"
                 ng-click="sepaTrans.selectPaymentsType('Processed', 'sepa.paymentsType.executedTab')"
                 class="po-type-tab no-wrap-text" style="width:33%"
                 translate="sepa.paymentsType.executedTab"></div>
          </div>

          <div class="po-filter-row col-sm-12 clearfix p-20 bgm-white" style="z-index:1;">

            <div class="col-sm-6 col-xs-12  p-0" style="height:40px">
              <!--<div class="tml-filter-container tmlf-wide" id="searchFilterExpandedDiv">-->
              <!--<div id="searchBtnLeftDiv" class="inner-section left">-->
              <!--<i class="icon icon-search i-p24 m-l-5"></i>-->
              <!--</div>-->
              <!--<div id="searchBtnCenterDiv" class="inner-section center">-->
              <!--<input id="searchTimelineInput" type="text" class="search-input"
              placeholder="{{'timelineFilter.placeholder'|translate}}"
              ng-model="sepaTrans.paymentSearch" ng-model-options="{debounce: 300}">-->
              <!--</div>-->
              <!--<div id="searchBtnRightDiv" class="inner-section right">-->
              <!--<i ng-show="sepaTrans.paymentSearch.length" class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0" ng-click="sepaTrans.paymentSearch = '';"></i>-->
              <!--</div>-->
              <!--</div>-->
              <div ng-include="'sepa/sepa-overview-filter.tpl.html'" style="height:40px"></div>
            </div>

            <div class="col-sm-6 col-xs-12 p-0 m-m-t-10">
              <div class="dropdown acc-period-select pull-right" style="display: inline-block">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">
                  {{ sepaTrans.selectedPeriod.item.description | translate}}
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li data-ng-repeat="period in sepaTrans.periodList">
                    <a href="" class="f-13" data-ng-click="sepaTrans.sepaPeriodFilter(period)">
                      {{period.item.description| translate}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div class="timeline-row payments col-sm-12 no-padding">
            <div class="hypo-timeline-wrapper app-white-card p-0 p-b-10 p-t-10">
              <div class="t-a-c p-10 no-data-image" ng-if="!sepaTrans.timelineListGrouped.result.length">
                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                <h3 class="no-data-label" translate="sepa.noData"></h3>
              </div>
              <ul class="hypo-timeline payments-list" ng-if="sepaTrans.timelineListGrouped.result.length !== 0">
                <li class=""
                    ng-repeat="payment in sepaTrans.timelineListGrouped.result| filter: sepaTrans.paymentSearch">
                  <span class="currency-time-frame" style="margin-left:14px;" ng-if="payment.isVirtual">{{payment.valueDate| date:'shortDate' : '+0200'}}</span>
                  <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change icon-lh"></i>
                  <div ng-if="!payment.isVirtual" class="hypo-timeline-item payment-item"
                       ng-class="{'opened': payment.selected}" ng-click="sepaTrans.showDetails(payment)">
                    <div class="hypo-timeline-body">
                      <div class="col-xs-9">
                        <h4 ng-if="payment.purpose" class="m-b-0 m-t-0 c-gray"
                            ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                          <div class="bold-font-payment wrap-text" ng-switch-default>{{::payment.purpose}}</div>
                          <div class="bold-font-payment" style="white-space:normal; word-wrap: break-word"
                               ng-switch-when="sl">{{::payment.purpose}}
                          </div>
                        </h4>
                        <h4 ng-if="!payment.purpose" class="m-b-0 m-t-0 bold-font-payment">
                          <div>{{'paymentsTypeGroup.' + payment.paymentTypeGroup | translate}}</div>
                        </h4>

                        <p class="m-b-0 m-t-0 iban-style-paym wrap-text">{{::payment.toAccountNumber}}
                          {{::payment.receiverName}}</p>
                        <p ng-show="paymentsOverview.showPaymentId" class="m-b-0 m-t-0 iban-style" id="paymentId">
                          {{::payment.id}}</p>
                      </div>

                      <!-- Other payment types -->
                      <div ng-if="sepaTrans.paymentsTypeSelected !== 5" class="col-xs-3">
                      <span class="pull-right t-a-r f-18">
                        <b class="price-payment" style="font-weight:600; font-size:22px;"
                           ng-if="::payment.sepaComplaintAmount2">{{::payment.sepaComplaintAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</b>
                        <b class="price-payment" style="font-weight: 600; font-size:22px; "
                           ng-if="::!payment.sepaComplaintAmount2">{{::payment.amount|number:2}} EUR</b>
                        <br/>
                        <span ng-class="{'text-danger': payment.status === 'Rejected'}"
                              ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"
                              style="color:#d70303;"></span>
                        <span ng-if="payment.status === 'Processed'" translate="paymentsOverview.processed"
                              style="color:#042603;"></span>
                        <span ng-if="payment.status === 'Cancelled'" translate="paymentsOverview.cancelled"></span>
                        <span style="color:#9eabd5;" ng-if="payment.status === 'InProcessing'"
                              translate="paymentsOverview.inProcessing"></span>
                        <span ng-if="payment.status === 'Opened'" translate="paymentsOverview.open"></span>
                        <span ng-if="payment.status === 'WaitingQueue'"
                              translate="paymentsOverview.waitingQueque"></span>
                        <span ng-if="payment.status === 'Signed'" translate="paymentsOverview.signed"></span>
                      </span>
                      </div>
                      <div class="clear"></div>
                    </div>
                    <div ng-if="payment.selected" class="sepaComplaint-details">
                      <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change-2"></i>
                      <div>
                        <table class="table table-transparent table-responsive">
                          <tbody>
                          <tr ng-if="paymentsOverview.paymentsTypeSelected === 5">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlStatus"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-class="{'text-danger': payment.status === 'Rejected'}"
                                    ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Processed'"
                                    translate="paymentsOverview.processed"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Cancelled'"
                                    translate="paymentsOverview.cancelled"></span>
                              <span class="po-value-label" ng-if="payment.status === 'InProcessing'"
                                    translate="paymentsOverview.inProcessing"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Opened'"
                                    translate="paymentsOverview.open"></span>
                              <span class="po-value-label" ng-if="payment.status === 'WaitingQueue'"
                                    translate="paymentsOverview.waitingQueque"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Signed'"
                                    translate="paymentsOverview.signed"></span>
                            </td>
                          </tr>
                          <!-- START: DETAILS -->
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.payerAccNumber"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.senderAccount}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.recAccNumber"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.receiverAccount}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.uniMandateRef"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.mandateReference}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.dueDate"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.paymentDate| date:'shortDate' : '+0200'}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.creditorInd"></span>
                            </td>
                            <td>
                              <span class="po-value-label"><span ng-if="!payment.receiverIdentificator">/</span>{{::payment.receiverIdentificator}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.recReference"></span>
                            </td>
                            <td>
                              <span class="po-value-label"><span ng-if="!payment.receiverReference">/</span>{{::payment.receiverReference}}</span>
                            </td>
                          </tr>
                          <tr ng-if="payment.status === 'Rejected'">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.messageAboutError"></span>
                            </td>
                            <td>
                            <span class="po-value-label"><span
                              ng-if="!payment.message">/</span>{{::payment.message}}</span>
                            </td>
                          </tr>
                          <!-- END: DETAILS -->

                          <tr>
                            <td colspan="2">
                              <div>
                                <!--TEST BUTTON-->
                                <!--<button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"-->
                                <!--translate="paymentActions.recall"-->
                                <!--ng-click="sepaTrans.refund(payment)">-->
                                <!--Recall payment-->
                                <!--</button>-->
                                <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                                  <span ng-switch="actionObject.methodName">
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="paymentActions.recall"
                                            ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed"
                                            ng-click="sepaTrans.refund(payment)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="paymentActions.sign"
                                            ng-if="actionObject.isEnabled && payment.status != 'WaitingQueue'"
                                            ng-switch-when="SignAllowed"
                                            data-ng-click="sepaTrans.signPayment(payment)">
                                      Sign payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="paymentActions.delete"
                                            ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed"
                                            ng-click="sepaTrans.deletePaymentAction(payment.id)">
                                      Delete payment
                                    </button>
                                  </span>
                                </span>
                                <span ng-if="payment.linkList.itemList.complaint">
                                  <button ng-if="payment.status == 'Waiting'"
                                          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                          translate="sepa.complaint"
                                          ng-disabled="payment.isDisabled"
                                          ng-click="sepaTrans.complaint(payment); $event.stopPropagation();">
                                    Complaint
                                  </button>
                                  <button ng-if="payment.status == 'Processed'"
                                          class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                          translate="sepa.refund"
                                          ng-disabled="payment.isDisabled"
                                          ng-click="sepaTrans.refund(payment);">
                                    Refund
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xs-12 t-a-c m-t-15">
          <button ng-if="sepaTrans.timelineListGrouped.hasMore == true"
                  data-ng-click="sepaTrans.loadMore()" type="input"
                  class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
                  translate="paymentsOverview.loadMoreBtn">Load more
          </button>
        </div>
      </div>
    </div>
    <!-- ================ -->
    <!-- CROATIA -->
    <!-- =============== -->
    <div ng-switch-when="hr">
      <!-- START: Timeline -->
      <div class="row">
        <div class="col-xs-12" style="z-index:1;">
          <!-- START: Tabs  -->
          <div class="po-type-container">
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 1}"
                 ng-click="sepaTrans.selectPaymentsType('Waiting', 'sepa.directDebitTabs.pending')"
                 class="po-type-tab no-wrap-text" style="width:33%"
                 translate="sepa.directDebitTabs.pending"></div>
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 3}"
                 ng-click="sepaTrans.selectPaymentsType('Processed', 'sepa.directDebitTabs.processed')"
                 class="po-type-tab no-wrap-text" style="width:33%"
                 translate="sepa.directDebitTabs.processed">
            </div>
            <div ng-class="{'selected': sepaTrans.paymentsTypeSelected === 2}"
                 ng-click="sepaTrans.selectPaymentsType('Rejected', 'sepa.directDebitTabs.rejected')"
                 class="po-type-tab no-wrap-text" style="width:34%"
                 translate="sepa.directDebitTabs.rejected">
            </div>
          </div>
          <!-- END: Tabs  -->
          <div class="po-filter-row col-sm-12 clearfix p-20 bgm-white" style="z-index:1;">
            <div class="col-sm-6 col-xs-12  p-0" style="height:40px">
              <div ng-include="'sepa/sepa-overview-filter.tpl.html'" style="height:40px"></div>
            </div>
            <div class="col-sm-6 col-xs-12 p-0 m-m-t-10">
              <div class="dropdown acc-period-select pull-right" style="display: inline-block">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">
                  {{ sepaTrans.selectedPeriod.item.description | translate}}
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li data-ng-repeat="period in sepaTrans.periodList">
                    <a href="" class="f-13" data-ng-click="sepaTrans.sepaPeriodFilter(period)">
                      {{period.item.description| translate}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="timeline-row payments col-sm-12 no-padding">
            <div class="hypo-timeline-wrapper app-white-card p-0 p-b-10 p-t-10">
              <div class="t-a-c p-10 no-data-image" ng-if="!sepaTrans.timelineListGrouped.result.length">
                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                <h3 class="no-data-label" translate="sepa.noData"></h3>
              </div>
              <ul class="hypo-timeline payments-list" ng-if="sepaTrans.timelineListGrouped.result.length !== 0">
                <li class=""
                    ng-repeat="payment in sepaTrans.timelineListGrouped.result| filter: sepaTrans.paymentSearch">
                  <span class="currency-time-frame" style="margin-left:14px;" ng-if="payment.isVirtual">{{payment.valueDate| date:'shortDate' : '+0200'}}</span>
                  <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change icon-lh"></i>
                  <div ng-if="!payment.isVirtual" class="hypo-timeline-item payment-item"
                       ng-class="{'opened': payment.selected}" ng-click="sepaTrans.showDetails(payment)">
                    <div class="hypo-timeline-body">
                      <div class="col-xs-9">
                        <h4 ng-if="payment.purpose" class="m-b-0 m-t-0 c-gray"
                            ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                          <div class="bold-font-payment wrap-text" ng-switch-default>{{::payment.purpose}}</div>
                          <div class="bold-font-payment" style="white-space:normal; word-wrap: break-word"
                               ng-switch-when="sl">{{::payment.purpose}}
                          </div>
                        </h4>
                        <h4 ng-if="!payment.purpose" class="m-b-0 m-t-0 bold-font-payment">
                          <div>{{'paymentsTypeGroup.' + payment.paymentTypeGroup | translate}}</div>
                        </h4>

                        <p class="m-b-0 m-t-0 iban-style-paym wrap-text">{{::payment.toAccountNumber}}
                          {{::payment.receiverName}}</p>
                        <p ng-show="paymentsOverview.showPaymentId" class="m-b-0 m-t-0 iban-style" id="paymentId">
                          {{::payment.id}}</p>
                      </div>

                      <!-- Other payment types -->
                      <div ng-if="sepaTrans.paymentsTypeSelected !== 5" class="col-xs-3">
                      <span class="pull-right t-a-r f-18">
                        <b class="price-payment" style="font-weight:600; font-size:22px;"
                           ng-if="::payment.sepaComplaintAmount2">{{::payment.sepaComplaintAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</b>
                        <b class="price-payment" style="font-weight: 600; font-size:22px; "
                           ng-if="::!payment.sepaComplaintAmount2">{{::payment.amount|number:2}} {{::payment.curr}}</b>
                        <br/>
                        <span ng-class="{'text-danger': payment.status === 'Rejected'}"
                              ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"
                              style="color:#d70303;"></span>
                        <span ng-if="payment.status === 'Processed'" translate="paymentsOverview.processed"
                              style="color:#042603;"></span>
                        <span ng-if="payment.status === 'Cancelled'" translate="paymentsOverview.cancelled"></span>
                        <span style="color:#9eabd5;" ng-if="payment.status === 'InProcessing'"
                              translate="paymentsOverview.inProcessing"></span>
                        <span ng-if="payment.status === 'Opened'" translate="paymentsOverview.open"></span>
                        <span ng-if="payment.status === 'WaitingQueue'"
                              translate="paymentsOverview.waitingQueque"></span>
                        <span ng-if="payment.status === 'Signed'" translate="paymentsOverview.signed"></span>
                      </span>
                      </div>
                      <div class="clear"></div>
                    </div>
                    <div ng-if="payment.selected" class="sepaComplaint-details">
                      <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change-2"></i>
                      <div>
                        <table class="table table-transparent table-responsive">
                          <tbody>
                          <tr ng-if="paymentsOverview.paymentsTypeSelected === 5">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlStatus"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-class="{'text-danger': payment.status === 'Rejected'}"
                                    ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Processed'"
                                    translate="paymentsOverview.processed"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Cancelled'"
                                    translate="paymentsOverview.cancelled"></span>
                              <span class="po-value-label" ng-if="payment.status === 'InProcessing'"
                                    translate="paymentsOverview.inProcessing"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Opened'"
                                    translate="paymentsOverview.open"></span>
                              <span class="po-value-label" ng-if="payment.status === 'WaitingQueue'"
                                    translate="paymentsOverview.waitingQueque"></span>
                              <span class="po-value-label" ng-if="payment.status === 'Signed'"
                                    translate="paymentsOverview.signed"></span>
                            </td>
                          </tr>
                          <!-- START: DETAILS -->
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="sepa.transactionId"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.transactionId}}</span>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span class="po-desc-label" translate="sepa.transactionDetails.payerAccount"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.senderAccount}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="sepa.transactionDetails.receiverAccount"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.receiverAccount}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="sepa.receiverName"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.receiverName}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="sepa.receiverNumber"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.receiverReference}}</span>
                            </td>
                          </tr>
                          <tr ng-if="sepaTrans.paymentsTypeSelected === 2 && payment.message">
                            <td>
                              <span class="po-desc-label" translate="sepa.additionalInfo"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.message}}</span>
                            </td>
                          </tr>
                          <!--<tr>-->
                            <!--<td>-->
                              <!--<span class="po-desc-label" translate="paymentsOverview.dueDate"></span>-->
                            <!--</td>-->
                            <!--<td>-->
                              <!--<span class="po-value-label">{{::payment.paymentDate| date:'shortDate' : '+0200'}}</span>-->
                            <!--</td>-->
                          <!--</tr>-->
                          <!--<tr>-->
                            <!--<td>-->
                              <!--<span class="po-desc-label" translate="paymentsOverview.creditorInd"></span>-->
                            <!--</td>-->
                            <!--<td>-->
                              <!--<span class="po-value-label"><span ng-if="!payment.receiverIdentificator">/</span>{{::payment.receiverIdentificator}}</span>-->
                            <!--</td>-->
                          <!--</tr>-->
                          <!--<tr>-->
                            <!--<td>-->
                              <!--<span class="po-desc-label" translate="paymentsOverview.recReference"></span>-->
                            <!--</td>-->
                            <!--<td>-->
                              <!--<span class="po-value-label"><span ng-if="!payment.receiverReference">/</span>{{::payment.receiverReference}}</span>-->
                            <!--</td>-->
                          <!--</tr>-->
                          <!--<tr ng-if="payment.status === 'Rejected'">-->
                            <!--<td>-->
                              <!--<span class="po-desc-label" translate="paymentsOverview.messageAboutError"></span>-->
                            <!--</td>-->
                            <!--<td>-->
                            <!--<span class="po-value-label"><span-->
                              <!--ng-if="!payment.message">/</span>{{::payment.message}}</span>-->
                            <!--</td>-->
                          <!--</tr>-->
                          <!-- END: DETAILS -->

                          <tr ng-if="sepaTrans.paymentsTypeSelected === 1 && payment.complaint">
                            <td colspan="2">
                              <div>
                                <span class="pull-right">
                                   <button class="btn btn-default app-btn-blue-inverse pull-right" ui-sref="sepa.accepted-refusal-cro({transactionId: payment.transactionId})" translate="sepa.refusalOrderBtn">
                                    Refusal
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                          <!--U detaljima obradjenih SDD naloga prikazati opciju Povrat SDD naloga samo ako je complaint=true-->
                          <tr ng-if="sepaTrans.paymentsTypeSelected === 3 && payment.complaint">
                            <td colspan="2">
                              <div>
                                <span class="pull-right">
                                   <button class="btn btn-default app-btn-blue-inverse pull-right"
                                           ng-click="sepaTrans.refuseSepaOrder(payment.transactionId)"
                                           translate="sepa.sddPaymentReturn">
                                    Refusal
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xs-12 t-a-c m-t-15">
          <button ng-if="sepaTrans.timelineListGrouped.hasMore == true"
                  data-ng-click="sepaTrans.loadMore()" type="input"
                  class="btn btn-default app-btn-blue-inverse show-all-accounts-btn"
                  translate="paymentsOverview.loadMoreBtn">Load more
          </button>
        </div>
      </div>
      <!-- END: Timeline -->

      <div class="row m-t-20 m-b-20">
        <div class="col-xs-12 col-sm-6 col-lg-6 m-b-20">
          <div class="account-item-add-message app-dblue-card " ui-sref="sepa.refusal-cro">
            <div class="round-btn-container2">
              <div class="round-btn-m">
                <i class="icon icon-add icon-add-msg bold-font f-24"></i>
              </div>
              <div class="round-btn-label-msg">
                <h3 class="round-btn-label-msg f-20" translate="sepa.refusalTitle"></h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="timeline-row col-md-12">
          <div class="t-a-c p-10 no-data-image" ng-if="sepaTrans.sepaDDComplaints.length == 0">
            <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
            <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
          </div>
          <div class="app-white-card p-t-15"
               ng-hide="sepaTrans.sepaDDComplaints.result.length == 0">
            <ul class="hypo-timeline p-t-10">
              <li ng-class="{'time-label p-t-15': term.isVirtual}"
                  ng-repeat="sepaComplaint in sepaTrans.sepaDDComplaints.result">
              <span class="currency-time-frame" style="margin-left:14px"
                    ng-if="sepaComplaint.isVirtual === true">
                {{(sepaComplaint.valueDate) | date:'shortDate' : '+0200'}}
              </span>
                <i class="zmdi zmdi-timer" ng-if="!sepaComplaint.isVirtual"></i>
                <div ng-if="!sepaComplaint.isVirtual" class="hypo-timeline-item payment-item"
                     ng-click="sepaTrans.showComplaintDetails(sepaComplaint)" ng-class="{'opened': sepaComplaint.selected}">
                  <div class="hypo-timeline-body">
                    <div class="col-xs-8">
                      <h4 class="m-b-0 m-t-10 ">
                        <b class="m-r-10">{{sepaComplaint.sepaDDComplaintOrderType.description}} </b> <b class="c-gray"> {{sepaComplaint.receiverName}}</b>
                      </h4>
                    </div>
                    <!--<div class="col-xs-8 visible-xs p-l-0">-->
                      <!--<h4 class="m-b-0 m-t-10 f-12 c-gray">-->
                        <!--<b>{{sepaComplaint.receiverName}}</b>-->
                      <!--</h4>-->
                    <!--</div>-->

                    <div class="col-xs-4">
                      <span class="pull-right t-a-r m-t-5 f-18">
                        <b>{{sepaComplaint.amount|number:2}} EUR
                        </b>
                        <br/>
                        <span ng-switch="sepaComplaint.statusCode">
                          <span ng-switch-when="0" translate="sepa.status_created"></span>
                          <span ng-switch-when="1" translate="sepa.status_processed"></span>
                          <span ng-switch-when="2" translate="sepa.status_rejected"></span>
                          {{sepaComplaint.translatedStatus | translate}}
                        </span>
                      </span>
                    </div>
                    <div class="clear"></div>
                  </div>
                  <div ng-if="sepaComplaint.selected" class="transaction-details">
                  <div data-ng-include="'sepa/sepa-complaints-timeline-details.tpl.html'"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more-btn">
            <button ng-if="sepaTrans.sepaDDComplaints.hasMore"
                    data-ng-click="sepaTrans.loadMoreSepaDDComplaints()"
                    type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                    translate="messages.loadMore">
              Load older events
            </button>
          </div>
        </div>

      </div>

    </div>

  </div>
</section>
