(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:ExchangeRatesService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('ExchangeRatesService', ExchangeRatesService);

  function ExchangeRatesService(EndpointsService, $http, $log, $q, ConfigService) {
    var ExchangeRatesServiceBase = {};

    ExchangeRatesServiceBase.getExchangeRates = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.exchangeRates, {
            params: params
          })
          .then(function (data) {
            deferred.resolve(data.data);
          })
          .catch(function (msg, code) {
            deferred.reject(msg.data);
          })
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    ExchangeRatesServiceBase.calculateExchangeRate = function (params) {
      var deferred = $q.defer();
      var url = ConfigService.url;
      url = url + "exchangeRates/exchangeRateCalculate";
      $http.get(url, {
          params: params
        })
        .then(function (data) {
          deferred.resolve(data.data);
        })
        .catch(function (msg) {
          deferred.reject(msg.data);
        });
      return deferred.promise;
    };

    return ExchangeRatesServiceBase;
  }
}());
