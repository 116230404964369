<div ng-if="dashboard.countryCode === 'hr'" class="banner-image-url"
     data-ng-click="dashboard.marketButtonText ? return : dashboard.goToMarketingLinkCro()"
     ng-style="{'background-image': 'url(' + dashboard.bannerImageUrl + ')',
                'cursor': dashboard.marketButtonText ? 'default' : 'pointer'}">
  <div class="t-a-l" style="padding: 20px">
    <div class="row">
      <div class="col-xs-12">
        <div class="primary-header-text" ng-if="dashboard.currentLang == 'hr'">{{dashboard.marketText1}}</div>
        <div class="primary-header-text" ng-if="dashboard.currentLang == 'en'"  style="">{{dashboard.marketText1ENG}}</div>
        <h4 class="secondary-header-text p-t-5" ng-if="dashboard.currentLang == 'hr'">{{dashboard.marketText2}}</h4>
        <h4 class="secondary-header-text p-t-5" ng-if="dashboard.currentLang == 'en'">{{dashboard.marketText2ENG}} </h4>
        <h4 class="secondary-header-text" ng-if="dashboard.currentLang == 'hr'"  style="">{{dashboard.marketText3}}</h4>
        <h4 class="secondary-header-text" ng-if="dashboard.currentLang == 'en'"  style="">{{dashboard.marketText3ENG}}</h4>
      </div>
    </div>
      <div style="position: absolute; bottom: 10px" ng-click="dashboard.pushGtmLinkEvent('Banner ' + dashboard.bannerImageUrl);">
        <button ng-if="dashboard.marketButtonText && dashboard.currentLang == 'hr'" data-ng-click="dashboard.goToMarketingLinkCro()" target="_blank"
           class="btn btn-default text-uppercase round-marketing-button" style="background-color: #062a42;line-height: 2.6;" >
          <span class="glyphicon glyphicon-chevron-right round-marketing-icon" aria-hidden="true"></span>
          <span class="round-marketing-text">
            {{dashboard.marketButtonText}}
          </span>
        </button>

        <button ng-if="dashboard.marketButtonTextENG && dashboard.currentLang == 'en'" data-ng-click="dashboard.goToMarketingLinkCro()" target="_blank"
                class="btn btn-default text-uppercase round-marketing-button" style="background-color: #062a42;line-height: 2.6;" >
          <span class="glyphicon glyphicon-chevron-right round-marketing-icon" aria-hidden="true"></span>
          <span class="round-marketing-text">
            {{dashboard.marketButtonTextENG}}
          </span>
        </button>
    </div>

    </div>
</div>

<div ng-if="dashboard.countryCode === 'bh'" ng-click="dashboard.pushGtmLinkEvent('Reklamni banner');">
  <a href="{{dashboard.currentLang == 'en' ? dashboard.bannerUrlEng : dashboard.bannerUrl}}" target="_blank">
    <div class="banner-image-url-custom" ng-style="{'background-image': 'url(' + dashboard.bannerImageUrl + ')'}">
    </div>
  </a>
</div>

<div ng-if="dashboard.countryCode === 'sr'" ng-click="dashboard.pushGtmLinkEvent('Banner - Kredit za refinansiranje');">
    <a href="{{dashboard.currentLang == 'en' ? dashboard.bannerUrlEng : dashboard.bannerUrl}}" target="_blank">
        <div class="banner-image-url-custom" ng-style="{'background-image': 'url(' + dashboard.bannerImageUrl + ')'}">
        </div>
    </a>
</div>

<div ng-if="dashboard.countryCode === 'mne'" ng-click="dashboard.pushGtmLinkEvent('Banner - Restart kredit');">
  <a href="{{dashboard.currentLang == 'en' ? dashboard.bannerUrlEng : dashboard.bannerUrl}}" target="_blank">
    <div class="banner-image-url-custom" ng-style="{'background-image': 'url(' + dashboard.bannerImageUrl + ')'}">
    </div>
  </a>
</div>

<!-- Remove Addiko Club banner  -->

<!--<div ng-if="dashboard.countryCode === 'sl'" style='height: 161px; background-position: center; background-size: cover;background-color: #ff4d5a;background-image: url("img/hypo/addiko-klub.png");' class="pointer-cst" ng-click="dashboard.openAddikoClubSl();">-->
    <!--<div class="p-20">-->
        <!--<h3 class="c-white m-t-0 m-b-30"><b class="col-xs-12 p-0 f-30 no-wrap-text">Addiko klub</b></h3>-->
        <!--<div style="padding-top: 13px">-->

            <!--<amount-formatter amount="{{dashboard.loyaltyPointsResult.loyaltyCurrentPoints|number:2}}" custom-classes="{{'c-white'}}" currency="{{'dashboard.loyaltyCurrentPoints' | translate}}" from-type="dashboard" aditional-val="" has-underline="true">-->
            <!--</amount-formatter>-->
            <!--<amount-formatter amount="{{dashboard.loyaltyPointsResult.loyaltyPreviousPoints|number:2}}" custom-classes="{{'c-blue'}}" currency="{{'dashboard.loyaltyPreviousPoints' | translate}}" from-type="addikoKlub" aditional-val="" has-underline="false">-->
            <!--</amount-formatter>-->
        <!--</div>-->

    <!--</div>-->
<!--</div>-->
