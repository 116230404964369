(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name alert.factory:AlertService
   *
   * @description
   *
   */
  angular
      .module('alert')
      .factory('AlertService', AlertService);

  function AlertService($uibModal,AccountsService,HelperService, NotificationService) {

    var AlertServiceBase = {};

    AlertServiceBase.infoAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/info-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.confirmationAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/confirmation-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.statementDeliveryWarning = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/statement-delivery-warning/statement-delivery-warning-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
          this.deliveryTypes = [
            {translateKey: 'statementDeliveryModal.HYPOnet', value: 4},
            {translateKey: 'statementDeliveryModal.eMail', value: 3}
          ];
          this.statementDeliveryModel = this.deliveryTypes[0];
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.complaintConversationModal = function (complaint) {
      return $uibModal.open({
        templateUrl: 'complaints/complaint-conversation/complaint-conversation.tpl.html',
        size: 'lg',
        bindToController: true,
        windowClass: 'complaints-modal',
        controllerAs: 'vm',
        controller: 'ComplaintConversationCtrl',
        resolve: {
          data: {
            complaintId: complaint.complaintId,
            creationDate: complaint.creationDate,
            forbidReply: complaint.forbidReply,
            categoryName: complaint.categoryName,
            subCategoryName: complaint.subCategoryName,
            complaintDesc: complaint.complaintDesc,
            messages: complaint.messages,
            attachments: complaint.attachments
          }
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.eInvoiceArchiveAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/archive-einvoice-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    /*
     *  Required data:
     *  {paymentName: "Name"}
     */
    AlertServiceBase.deletedPaymentAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/deleted-payment-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.blockCardAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/block-card-alert.tpl-html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.imageAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/image-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.viberDeactivateAlert = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/viber-deactivation.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.paymentsReturnInfo = function () {
      return $uibModal.open({
        templateUrl: 'alert/payments-return-info.tpl.html',
        size: 'md',
        bindToController: true,
        controllerAs: 'vm',
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.virtualizationRestriction = function (data) {
      return $uibModal.open({
        templateUrl: 'alert/virtualization-restriction.tpl.html',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'vm',
        controller: function (data) {
          // console.log('masdasasd', data);
          this.messageTag = data.messageTag;
        },
        resolve: {
          data: data
        }
      }).result;
    };

    AlertServiceBase.securityMessageRestriction = function () {
      return $uibModal.open({
        templateUrl: 'alert/security-message-restriction.tpl.html',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'vm',
        controller: function ($scope, $uibModalInstance, SettingsService) {
          var vm = this;
          vm.setSecurityMessageSeen = function () {

            SettingsService.securitySettingsSetSecurityMessageSeen()
            .then(function (response) {
              $uibModalInstance.close();
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });

          }
        }
      }).result;
    };

    AlertServiceBase.cardActivation = function () {
      return $uibModal.open({
        templateUrl: 'alert/card-activation.tpl.html',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'vm',
        controller: function () {
          var vm = this;
          vm.last4Digits = '';
        }
      }).result;
    };

    AlertServiceBase.eBookWarning = function (data) {
      /*if (window.innerHeight <= 800 && window.innerWidth <= 600){*/
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        data.isMobile = true;
      }
      return $uibModal.open({
        templateUrl: 'alert/eBookWarning.tpl.html',
        size: 'lg',
        backdrop: 'static',
        keyboard :false,
        controllerAs: 'vm',
        controller: function (data,$uibModalStack) {

          var vm = this;
          vm.isMobile = false;
          vm.challenge = data.challenge;
          vm.object = {};
          vm.object.id = data.id;
          vm.object.signData = data.signData;
          vm.currentDate = HelperService.getYesterday().firstDay;
          // Detact Mobile Browser
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          /*if (window.innerHeight <= 800 && window.innerWidth <= 600){*/
            vm.isMobile = true;
          }

          vm.eSavingBookContractWarningContractSign = function (data) {
            vm.object.accepted = data;
            AccountsService.eSavingBookContractWarningContractSign(vm.object).then(function(result){
              var top = $uibModalStack.getTop();
              if (top) {
                $uibModalStack.dismiss(top.key);
              }
            }).catch(
              function (result) {
                NotificationService.showMessage(result, 'error');
              }
            );

          };

        },
        resolve: {
          data: data
        }
      }).result;
    };

    AlertServiceBase.updateMobileSetting = function () {
      return $uibModal.open({
        templateUrl: 'alert/update-mobile-setting.tpl.html',
        size: 'lg',
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.showRejectEDocumentWarning = function () {
      return $uibModal.open({
        templateUrl: 'alert/reject-e-document-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function () {
          var vm = this;
          vm.tempObj = {
            note: ''
          }
        },
        resolve: {

        },
        backdrop: 'static'
      }).result;
    };

    AlertServiceBase.becomeFollowerAlert = function(data) {
      return $uibModal.open({
        templateUrl: 'viber/become-follower-alert.tpl.html',
        size: 'lg',
        bindToController: true,
        controllerAs: 'vm',
        controller: function (data) {
          this.data = data;
        },
        resolve: {
          data: data
        },
        backdrop: 'static'
      }).result;
    };

    return AlertServiceBase;
  }

}());

