(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name orders.controller:OrdersOtherCtrl
     *
     * @description
     *
     */
    angular
        .module('orders')
        .controller('OrdersOtherCtrl', OrdersOtherCtrl);

    function OrdersOtherCtrl($rootScope, $location, $translate, HelperService, AccountsService, PaymentTypesService, ValidationService, ReferenceModelService,
                              PermanentOrdersService, PermanentOrderTypesService, $log, $q, GoogleTagManagerHelperService, NotificationService) {
        var vm = this;
        vm.ctrlName = 'OrdersCtrl';
        vm.orderType = {};
        vm.fromAccountDropdown = false;
        vm.amountInput = '';
        vm.dayOfBeginning = 8;
        vm.dateBegin = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') ? new Date() : new Date().setDate(vm.dayOfBeginning);
        vm.dateCancel = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl') ? new Date() : new Date(vm.dateBegin);
        vm.formSubmitted = false;
        vm.referenceModelList = [];
        vm.orderTypes = [];
        vm.referenceModelSelect = {};
        vm.currentOrder = null;
        vm.step = 'order';
        vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
        vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;
        vm.ordersListGrouped = {};
        vm.referenceNumber = '';
        vm.descriptionMaxLength = 50;
        vm.last8digitsFromAccountResponse = '';
        vm.last8digitsFromAccount = '';
        vm.additionalChallangeRequired = false;

        vm.inputOptions = {
            receviersAddress: {
                maxLength: -1
            },
            beneficiaryName: {
                maxLength: -1
            }
        };

        vm.forUpdate = false;

        var timeRange = HelperService.getDateRange('thisMonth');

        vm.ordersFilter = {
            page: 0,
            orderBy: 'dateCreated DESC',
            pageSize: 10,
            dateCreatedGTE: timeRange.valueDateGTE,
            dateCreatedLTE: timeRange.valueDateLTE
        };

        vm.timelineTimeFilter = timelineTimeFilter;
        vm.loadMoreTimelineItems = loadMoreTimelineItems;

        vm.dateOfBeginningOptions = {
            minDate: new Date()
        };

        vm.dateOfCancellationOptions = {
            minDate: new Date(),
            maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };

        vm.defaultCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
        vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.bankAccount = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BankAccount;
        vm.bankName = $rootScope.APP_GLOBAL_SETTINGS.defaultData.BankName;

        vm.beneficiaryIBANPrefix = 'HR';


        vm.dateBegin = HelperService.setCustomMonthPeriodByDays(new Date(), 9);
        vm.dateOptions = {
            minDate: new Date(vm.dateBegin),
            maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };
        vm.descriptionMaxLength = 200;

        vm.setAccountFrom = setAccountFrom;

        vm.periodList = [
            {
                item: {
                    key: 'today',
                    description: 'core.period.today'
                }
      }
      , {
                item: {
                    key: 'yesterday',
                    description: 'core.period.yesterday'
                }
      }
      , {
                item: {
                    key: 'thisWeek',
                    description: 'core.period.thisWeek'
                }
      }
      , {
                item: {
                    key: 'thisMonth',
                    description: 'core.period.thisMonth'
                }
      }
      , {
                item: {
                    key: 'thisYear',
                    description: 'core.period.thisYear'
                }
      }
      , {
                item: {
                    key: 'all',
                    description: 'core.period.all'
                }
      }
    ];
        vm.selectedPeriod = vm.periodList[3];

        function getPaymentTypeGroup() {
            var paymentTypesGroupFilter = {
                paymentTypeGroup: 'DomesticPayment'
            };
            return PaymentTypesService.getByGroup(paymentTypesGroupFilter)
                .then(function (response) {
                    vm.accountList = response.result[0].debitAccountList;
                    vm.ordersFilter.accountId = response.result[0].debitAccountList[0].accountId;
                    _.forEach(vm.accountList, function (value) {
                        value.optionValue = value.accountDescription + ' - ' + value.iban;
                    });

                    if (vm.accountList.length > 0) {
                        vm.fromAccountSelect = vm.accountList[0];
                    }
                })
                .catch(function () {
                    $log.error('Error loading paymentTypeGroupDomestic!');
                });
        }

        function getReferenceModels() {
            var referenceModelFilter = {
                pageSize: 1000
            };
            return ReferenceModelService.getPage(referenceModelFilter)
                .then(function (response) {
                    _.forEach(response.result, function (value) {
                        vm.referenceModelList.push(value.model);
                    });
                })
                .catch(function () {
                    $log.error('Error loading bic banks codes!');
                });
        }


        function loadSavingAccounts() {
            AccountsService.getPage({
                    accountTypeGroup: 'SavingAccountTypes',
                    currencySymbol: $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency
                }) //12.11.2016 - filter by currencySymbol doesnt work
                .then(function (response) {
                    vm.savingAccounts = response.result;

                    // _.forEach(vm.accountList, function (value) {
                    //   value.optionValue = value.accountDescription + ' - ' + value.iban;
                    // });

                    if (vm.savingAccounts) {
                        var tempList = _.filter(vm.savingAccounts, function (item) {
                            return item.currencySymbol == $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
                        });
                        if (tempList)
                            vm.savingAccounts = tempList;
                    }

                    if (vm.savingAccounts.length > 0) {
                        vm.selectedSavingAccount = vm.savingAccounts[0];
                    }
                }, function (error) {
                    console.log(error);
                })
        }

        function getOrderTypes() {
            var orderTypeFilter = {
                pageSize: 1000
            };

            return PermanentOrderTypesService.getPage(orderTypeFilter)
                .then(function (response) {
                    vm.orderTypes = response.result;
                    vm.orderType = vm.orderTypes[0]; 
                })
                .catch(function () {
                    $log.error('Error loading order types');
                });
        }

        function getFromAccountList(accountIdList) {
            AccountsService.getPage({
                    accountTypeGroup: 'CurrentAccount',
                    isOwn: true,
                    currencySymbol: $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency
                }) //12.11.2016 - filter by currencySymbol doesnt work
                .then(function (response) {
                    vm.accountList = response.result;
                    vm.ordersFilter.accountId = vm.accountList.accountId;
                    _.forEach(vm.accountList, function (value) {
                        value.optionValue = value.accountDescription + ' - ' + value.iban;
                    });

                    if (vm.countryCode == 'sl') {
                        filterAccountsToEurAccount(); //12.11.2016 - filter by currencySymbol doesnt work
                    }

                    if (vm.accountList.length > 0) {
                        vm.fromAccountSelect = vm.accountList[0];
                    }
                }, function (error) {
                    console.log(error);
                })
        }

        function filterAccountsToEurAccount() {
            if (vm.accountList) {
                var tempList = _.filter(vm.accountList, function (item) {
                    return item.currencySymbol == $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
                });
                if (tempList)
                    vm.accountList = tempList;
            }
        }

        function getOrders(reset) {
            if (reset) {
                vm.ordersFilter.page = 0;
                vm.ordersListGrouped = {};
            } else {
                vm.ordersFilter.page += 1;
            }

            PermanentOrdersService.getPage(vm.ordersFilter)
                .then(function (response) {
                    if (response) {
                        if (reset) {
                            vm.ordersListGrouped = response;
                        } else {
                            vm.ordersListGrouped.result = vm.ordersListGrouped.result.concat(response.result);
                            vm.ordersListGrouped.hasMore = response.hasMore;
                        }
                    }

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }

        function loadMoreTimelineItems() {
            getOrders(false);
        }

        function timelineTimeFilter(item) {
            vm.selectedPeriod = item;
            var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
            vm.ordersFilter.dateCreatedGTE = timeRange.valueDateGTE;
            vm.ordersFilter.dateCreatedLTE = timeRange.valueDateLTE;
            getOrders(true);
            GoogleTagManagerHelperService.pushEvent('Orders', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
        }

        function setAccountFrom(item) {
            vm.fromAccountSelect = item;
            vm.ordersFilter.accountId = vm.fromAccountSelect.accountId;
            getOrders(true);
            vm.fromAccountDropdown = false;
        }

        var showChallengeFields = function (paymentId) {
        return PermanentOrdersService.additionalChallangeRequired(paymentId).then(function (additionalChallangeRequired) {
          vm.additionalChallangeRequired = additionalChallangeRequired;
        }).catch(function (error) {
          $log.error(error);
        });

      }

        vm.confirm = function () {
            vm.formSubmitted = true;
            if (!vm.orderForm.$valid) {
                return;
            }

            if (vm.formSubmitted) {
                var order = createOrderObject();

                if (vm.forUpdate) {
                    order.id = vm.insertedOrderObject.id;
                    PermanentOrdersService.update(vm.insertedOrderObject.id, order)
                        .then(function (response) {
                          if(vm.countryCode === 'hr') {
                            var params = {
                              id: response.id
                            };
                            showChallengeFields(params);
                          }
                           vm.last8digitsFromAccount = response.payeeAccount.substr(response.payeeAccount.length - 8);
                            postOrderAction(response);
                            vm.forUpdate = false;
                            vm.step = 'confirm';
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                            // vm.orderForm.$valid = false;
                            vm.forUpdate = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                        });
                } else {
                    PermanentOrdersService.create(order)
                        .then(function (response) {
                          if(vm.countryCode === 'hr') {
                            var params = {
                              id: response.id
                            };
                            showChallengeFields(params);
                          }
                            postOrderAction(response);
                            vm.step = 'confirm';
                             vm.last8digitsFromAccount = response.payeeAccount.substr(response.payeeAccount.length - 8);
                            GoogleTagManagerHelperService.pushVirtualPageView('orders/toconfirm', 'orders toconfirm', $location.host() + 'orders/toconfirm');
                        })
                        .catch(function (error) {
                            if (error && error.resultList && error.resultList.length > 0) {
                                NotificationService.showMessage(error, 'error');
                                // vm.orderForm.$valid = false;
                            }
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                        });
                }
            }
        };

        vm.insertedOrderObject = {};

        var postOrderAction = function (response) {
            vm.insertedOrderObject = response;
            //vm.insertedOrderObject.orderTypeId = vm.orderType.typeId;
            vm.insertedOrderObject.fromAccountNumber = response.fromAccountNumber || vm.fromAccountSelect.iban; //SLO or HR
            vm.insertedOrderObject.payeeName = vm.beneficiaryName;
            vm.insertedOrderObject.amount = vm.amountInput;
            vm.insertedOrderObject.currencySymbol = vm.defaultCurrency;
            vm.insertedOrderObject.firstPaymentDate = vm.dateBegin;
            vm.insertedOrderObject.lastPaymentDate = vm.dateCancel;
            vm.insertedOrderObject.purpose = vm.description;
            vm.insertedOrderObject.note = vm.description;
        };

        function createOrderObject() {
            var paymentObject = {
                orderTypeId: vm.orderType.typeId,
                payeeAccount: (vm.beneficiaryIBAN) ? vm.beneficiaryIBAN.replace(/\s/g, '') : "",
                payeeName: vm.beneficiaryName,
                amount: vm.amountInput,
                currencySymbol: vm.defaultCurrency,
                firstPaymentDate: new Date(vm.dateBegin),
                purpose: vm.description,
                note: vm.description,
                referenceModel: vm.referenceModelSelect,
                referenceNumber: vm.referenceNumber
            };

            paymentObject.fromAccountNumber = vm.fromAccountSelect.accountId;


            if (vm.countryCode == 'hr' && !vm.forUpdate) {
                paymentObject.payeeAccount = vm.beneficiaryIBANPrefix + vm.beneficiaryIBAN
            }

            return paymentObject;
        }

        vm.back = function () {
            vm.step = 'order';
            vm.formSubmitted = false;
        };

        vm.editOrder = function () {
            vm.forUpdate = true;
            vm.step = 'order';
            vm.agreedWithTerms = false;
            mapWithOrderResponse();
        };

        var mapWithOrderResponse = function () {
            vm.orderType.typeId = vm.insertedOrderObject.orderTypeId || vm.insertedOrderObject.payeeAccountType;
            vm.beneficiaryName = vm.insertedOrderObject.payeeName;
            vm.amountInput = vm.insertedOrderObject.amount;
            vm.defaultCurrency = vm.insertedOrderObject.currencySymbol;
            vm.dateBegin = new Date(vm.insertedOrderObject.firstPaymentDate);
            vm.dateCancel = new Date(vm.insertedOrderObject.lastPaymentDate);
            vm.description = vm.insertedOrderObject.purpose;
            vm.note = vm.insertedOrderObject.note;
            vm.beneficiaryIBAN = vm.insertedOrderObject.payeeAccount;

            var accountIndex = _.findIndex(vm.accountList, function (item) {
                return item.accountId == vm.insertedOrderObject.accountId;
            });

            vm.fromAccountSelect = vm.accountList[accountIndex];
        };

        vm.sign = function () {
            vm.insertedOrderObject.statusId = 6;

            vm.signOrder = vm.insertedOrderObject;
            if (vm.countryCode != 'sl') {
                vm.signOrder = {};
                vm.signOrder.id = vm.insertedOrderObject.id;
                vm.signOrder.signData = vm.model.password2;
            }
              if(vm.countryCode == 'hr' && vm.additionalChallangeRequired) {
                vm.signOrder.additionalSignData  = vm.last8digitsFromAccountResponse;
              }


            vm.confirmSignedButtonDisabled = true;
            PermanentOrdersService.sign(vm.signOrder)
                .then(function (response) {
                    vm.insertedOrderObject = response;
                    vm.confirmSignedButtonDisabled = false;
                    vm.step = 'complete';
                })
                .catch(function (error) {
                    vm.confirmSignedButtonDisabled = false;
                    NotificationService.showMessage(error, 'error');
                });
        };

        /**
         * Resets order form and shows first step.
         */
        vm.newOrder = function () {
            vm.step = 'order';
            vm.amountInput = '';
            vm.description = '';
            vm.beneficiaryIBAN = '';
            vm.beneficiaryName = '';
            vm.dateBegin = new Date();
            vm.dateCancel = new Date();
            vm.formSubmitted = false;
            vm.forUpdate = false;
            vm.orderSigned = false;
            vm.receviersAddress = '';
            vm.note = '';
            //setDefaultIBAN();
        };


        vm.deleteOrder = function () {
            PermanentOrdersService.remove(vm.insertedOrderObject.id)
                .then(function (response) {
                    vm.newOrder();
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.closeOrder = function (orderId) {
            if (orderId) {
                var order = {};
                order.orderId = orderId;
                PermanentOrdersService.close(order)
                    .then(function (response) {
                        getOrders(true);
                        var text = $translate.instant('orderDetails.closeMsg');
                        NotificationService.showTranslatedMessage('info', text, '');
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
        };

      vm.deleteOrder = function (orderId) {
        if (orderId) {
          PermanentOrdersService.remove(orderId)
            .then(function (response) {
              getOrders(true);
              var text = $translate.instant('orderDetails.deleteMsg');
              NotificationService.showTranslatedMessage('info', text, '');
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
        }
      };

        vm.init = function () {
            var promises = [getReferenceModels()]; //getDefaultDetails(),
            promises.push(getPaymentTypeGroup());

            $q.all(promises)
                .then(function () {
                    getOrders(true);
                    getOrderTypes();

                    if (vm.referenceModelList.length > 0) {
                        var index = _.findIndex(vm.referenceModelList, function (o) {
                            return o === '99';
                        });
                        vm.referenceModelSelect = vm.referenceModelList[index];
                    }
                });
        };

        vm.init();


        vm.addSpaceForIban = function (toAccountInput) {
            if (!toAccountInput)
                return;
            vm.beneficiaryIBAN = toAccountInput.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        };


        vm.expandDetails = function (order) {
            // if (vm.countryCode == 'sl' && !order.selected) {
            //   PermanentOrdersService.getById(order.id)
            //     .then(function (response) {
            //       angular.extend(order, response);
            //     }, function (error) {
            //       console.log(error);
            //     });
            // }
        };
    }
}());
