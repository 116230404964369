<div class="modal-header statement-confirm-header p-10">

</div>
<div class="modal-body text-center">
  <h3 translate="accounts.title">Information</h3>
    <span translate="alert.paymentsReturnText"></span>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-default" ng-click="$close()"><span
        translate="accounts.close"></span></button>
    </div>
  </div>
</div>
