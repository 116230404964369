<section id="main">
  <div class="container-fluid">
    <div class="row">
      <div class="tmplt-list-title col-md-12 p-r-0 m-b-15">

        <h2 translate="sepa.header" class="f-28"></h2>

      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="accounts-dropdown m-b-10" ng-if="sepa.currentAccounts.length > 0">
          <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
               data-ng-click="sepa.fromAccountDropdown = !sepa.fromAccountDropdown"
               ng-style="sepa.model.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + sepa.model.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
            <h4 class="no-wrap-text bold-font m-b-0">
              <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{sepa.model.fromAccountSelect.friendlyName}}</b>
              <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                <i class="zmdi"
                   ng-class="{'zmdi-star favorite-account':sepa.model.fromAccountSelect.isFavorite,'zmdi-star-outline':!sepa.model.fromAccountSelect.isFavorite}">
                </i>
              </span>
            </h4>
            <p class="m-b-0 m-t-0 iban-style">{{sepa.model.fromAccountSelect.iban}}</p>
            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
              {{sepa.model.fromAccountSelect.availableBalance| number:2}}
              {{sepa.model.fromAccountSelect.currencySymbol}}
            </p>
            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
            <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
          </div>
          <div class="from-accounts-list m-l-15" ng-show="sepa.fromAccountDropdown" scrollable>
            <div class="accounts-list-item p-0" data-ng-repeat="item in sepa.currentAccounts"
                 data-ng-click="sepa.setAccountFrom(item);">

              <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget"
                   ng-style="item.linkList.itemList.imageUrl ? {'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <h4 class="no-wrap-text bold-font m-b-0">
                  <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                      ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                </h4>
                <p class="m-b-0 m-t-0 iban-style">{{item.iban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{item.availableBalance| number:2}} {{item.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
              </div>

            </div>
          </div>
        </div>
        <div class="accounts-dropdown m-b-10" ng-if="sepa.currentAccounts.length == 0">
          <div class="debit-account-widget dashboard-widget-item" style="height: 141px;"
               ng-style="internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl ? {'background-image': 'url(' + internalTransfer.tempIntObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
            <div class="m-t-30 m-b-30">
              <p translate="internalTransfer.nodata"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-lg-6 m-b-20">
        <div class="account-item-add-message app-dblue-card" ng-click="sepa.addNewSetting()">
          <div class="round-btn-container2">
            <div class="round-btn-m">
              <i class="icon icon-add icon-add-msg bold-font f-24"></i>
            </div>
            <div class="round-btn-label-msg">
              <h3 class="round-btn-label-msg f-20">{{'sepa.addNew'| translate}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NEED NEW TIMELINE !!!!!???????  -->

    <div data-ng-include="'sepa/sepa-timeline.tpl.html'"></div>

  </div>
</section>
