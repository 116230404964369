(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name paymentTemplates.controller:PaymentTemplatesModalCtrl
   *
   * @description
   *
   */
  angular
    .module('paymentTemplates')
    .controller('PaymentTemplatesModalCtrl', PaymentTemplatesModalCtrl);

  function PaymentTemplatesModalCtrl(paymentTemplate, $log, AccountsService,
                                     $uibModalInstance, $filter, PaymentTypesService, PaymentsService, HelperService,
                                     $state, PaymentTemplatesService, NotificationService) {
    var vm = this;
    vm.validDate = true;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.signSuccess = false;

    if (paymentTemplate.amount) {
      paymentTemplate.amount = $filter('number')(paymentTemplate.amount, 2);
    } else {
      paymentTemplate.amount = $filter('number')(0, 2)
    }

    vm.paymentObject = paymentTemplate;
    vm.paymentObject.dueDate = new Date();
    vm.datePickerOpened = false;

    vm.init = function () {
      if (paymentTemplate.accountId) {
        vm.loadUsersAccount();
      }
      if (paymentTemplate.formType) {
        vm.getPaymentTypeById(paymentTemplate.formType);
      }
    };

    vm.getPaymentTypeById = function (id) {
      PaymentTypesService.getById(id)
        .then(function(response){
            vm.paymentObject.paymentTypeGroup = response.paymentTypeGroup;
        })
        .catch(function(error){
          $log.error(error);
        });
    };

    vm.loadUsersAccount = function () {
      AccountsService.getAccountById(paymentTemplate.accountId)
        .then(function (response) {
          vm.paymentObject.fromAccount = response.accountDescription;
          vm.paymentObject.fromAccountNumber = response.iban;
          vm.paymentObject.accountCurrency = response.currencySymbol;
          vm.paymentObject.senderName = response.ownerName;
          vm.paymentObject.senderAddress1 = response.ownerAddress;
          vm.paymentObject.senderCity = response.ownerCity;
        })
        .catch(function () {
          $log.error('Error loading account');
        })
    };

    vm.executePayment = function () {
      vm.validDate = HelperService.validatePaymentDate(vm.paymentObject.dueDate);
      if (!vm.validDate || !vm.templateForm.$valid) {
        return;
      }

      var paymentObject = createPaymentObject();
      PaymentsService.insertPayment(paymentObject)
        .then(function (response) {
          vm.paymentResponse = response;
          vm.paymentResponse.paymentTypeGroup = paymentObject.paymentTypeGroup;
          vm.paymentResponse.senderInfo = {
            senderName: vm.paymentObject.senderName,
            senderAddress1: vm.paymentObject.senderAddress1,
            senderCity: vm.paymentObject.senderCity
          };
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var createPaymentObject = function () {
      var objForInsert = {
        accountId: vm.paymentObject.accountId,
        fromAccountNumber: vm.paymentObject.fromAccountNumber,
        toAccountNumber: vm.paymentObject.toAccountNumber,
        currencySymbol: vm.paymentObject.accountCurrency,
        purpose: vm.paymentObject.purpose,
        dueDate: vm.paymentObject.dueDate,
        amount: HelperService.parseAmountInput(vm.paymentObject.amount),
        paymentTypeGroup: vm.paymentObject.paymentTypeGroup
      };

      var subProperties = {
        receiverName: vm.paymentObject.payeeName,
        receiverAddress1: vm.paymentObject.payeeAddress1,
        receiverCity: vm.paymentObject.payeeCity,
        reference: '99',
        referenceFrom: '99',
        senderName: vm.paymentObject.senderName,
        senderAddress1: vm.paymentObject.senderAddress1,
        senderCity: vm.paymentObject.senderCity
      };

      switch (vm.paymentObject.paymentTypeGroup) {
        case "DomesticPayment":
          objForInsert.domesticPayment = subProperties;
          break;
        case "ForeignPayment":
          objForInsert.foreignPayment = subProperties;
          break;
      }
      return objForInsert;
    };

    vm.signPayment = function () {
      PaymentsService.signPayment(vm.paymentResponse.id)
        .then(function (response) {
          vm.signSuccess = true;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.closeModal = function () {
      $uibModalInstance.close();
    };

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.deleteTemplate = function () {
      PaymentTemplatesService.deleteTemplate(vm.paymentObject.id)
        .then(function (response) {
          swal('Success!', 'Payment template deleted succesfully!');
          vm.closeModal();
          $state.reload('payments');
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.saveTemplate = function () {
      var templateForSaveObj = prepareTemplateForUpdate();
      PaymentTemplatesService.updateTemplate(vm.paymentObject.id, templateForSaveObj)
        .then(function (response) {
          swal('Success!', "Template updated successfully!");
          vm.closeModal();
          $state.reload();
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var prepareTemplateForUpdate = function () {
      $log.debug('Payment object', vm.paymentObject);
      var tempForUpdate = {
        name: vm.paymentObject.name,
        accountId: vm.paymentObject.accountId,
        fromAccountNumber: vm.paymentObject.fromAccountNumber,
        toAccountNumber: vm.paymentObject.toAccountNumber,
        dueDate: new Date(vm.paymentObject.dueDate),
        currencySymbol: vm.paymentObject.accountCurrency,
        amount: HelperService.parseAmountInput(vm.paymentObject.amount),
        paymentTypeGroup: vm.paymentObject.paymentTypeGroup,
        purpose: vm.paymentObject.purpose
      };

      var subProperties = {
        receiverName: vm.paymentObject.payeeName,
        receiverAddress1: vm.paymentObject.payeeAddress1,
        receiverCity: vm.paymentObject.payeeCity,
        senderName: vm.paymentObject.senderName,
        senderAddress1: vm.paymentObject.senderAddress1,
        senderCity: vm.paymentObject.senderCity,
        reference: '99',
        referenceFrom: '99'
      };

      switch (vm.paymentObject.paymentTypeGroup) {
        case 'DomesticPayment':
          tempForUpdate.domesticPayment = subProperties;
          break;
      }
      return tempForUpdate;
    };

    vm.navigateToFullPaymentsForm = function () {
      var navigateTo = '';
      vm.closeModal();
      switch (vm.paymentObject.paymentTypeGroup) {
        case "DomesticPayment":
          navigateTo = 'payments.domesticPayment';
          break;
        case "LocalCurrencyTransfer":
          navigateTo = 'payments.internalTransfer';
          break;
        case "ForeignPayment":
          navigateTo = 'payments.internationalPayment';
          break;
        case "CurrencyExchange":
          navigateTo = 'payments.currencyConversion';
          break;
      };
      $state.go(navigateTo, {templateId: vm.paymentObject.id});
    };

    vm.init();
  }
}());
