<div class="dn-wrapper" ng-show="APP_GLOBAL_SETTINGS.counters.countersLoaded && dashboard.showNotificationsPanel" ng-class="{'animated fadeIn':APP_GLOBAL_SETTINGS.counters.countersLoaded}">
  <i class="dn-close-btn pointer zmdi zmdi-close zmdi-hc-2x" ng-click="dashboard.dismissNotifications();"></i>
  <div class="last-login-info" ng-if="dashboard.userSettings.result[0].lastLoginDate">
    <span translate="header.lastLogin"></span>
    <span>{{dashboard.userSettings.result[0].lastLoginDate | date: 'dd.MM.yyyy hh:mm:ss'}}</span>
  </div>
  <div class="row">

    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 dn-item-row">
          <div class="dn-item-bubble" ng-bind="APP_GLOBAL_SETTINGS.counters.rejectedPaymentsNotifications"></div>
            <div class="dn-item-label" translate="dashboardNotification.rejected" ui-sref="payments.paymentsOverview({myParam: 'rejected'})" ng-click="dashboard.pushNotificationsGtmEvent('RejectedPayments');"></div>
        </div>
      </div>
      <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' || APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
        <div class="col-xs-12 dn-item-row">
          <div class="dn-item-bubble" ng-bind="APP_GLOBAL_SETTINGS.counters.eInvoiceNotifications"></div>
            <div class="dn-item-label"
                 translate="{{APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'dashboardNotification.einvoiceSlo' : 'dashboardNotification.einvoice'}}" ui-sref="eInvoices.einvoicesOverview" ng-click="dashboard.pushNotificationsGtmEvent('eInvoices');"></div>
        </div>
      </div>
      <!--&& APP_GLOBAL_SETTINGS.counters.documentsNotifications > 0-->
      <div class="row" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.counters.unreadDocumentsNotifications > 0">
        <div class="col-xs-12 dn-item-row">
          <div class="dn-item-bubble" ng-bind="APP_GLOBAL_SETTINGS.counters.unreadDocumentsNotifications"></div>
          <div class="dn-item-label"
               translate="dashboardNotification.documents" ui-sref="documents" ng-click="dashboard.pushNotificationsGtmEvent('Documents');"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-5">
          <div class="dn-item-row">
            <div class="dn-item-bubble" ng-bind="APP_GLOBAL_SETTINGS.counters.unsignedPaymentsNotifications"></div>
            <div ng-switch="dashboard.countryCode">
                <div class="dn-item-label"  ng-switch-default translate="dashboardNotification.unsigned" ui-sref="payments.paymentsOverview({myParam: 'not-signed'})" ng-click="dashboard.pushNotificationsGtmEvent('UnSignedPayments');"></div>
                <div class="dn-item-label"  ng-switch-when="sl" translate="dashboardNotification.pending" ui-sref="payments.paymentsOverview({myParam: 'pending'})" ng-click="dashboard.pushNotificationsGtmEvent('UnSignedPayments');"></div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-7">
          <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.lastLoginDate" class="p-t-5 pull-right hidden-xs" >
            <span class="dn-item-label" translate="header.lastLogin"></span>
            <span class="dn-item-label">{{APP_GLOBAL_SETTINGS.userSettings.lastLoginDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
            <!--<span>{{hctrl.userSettings.result[0].lastLoginDate}}</span>-->
          </div>
          <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && APP_GLOBAL_SETTINGS.userSettings.lastLoginDate" class="p-t-5 visible-xs" >
            <span class="dn-item-label" translate="header.lastLogin"></span>
            <span class="dn-item-label">{{APP_GLOBAL_SETTINGS.userSettings.lastLoginDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
            <!--<span>{{hctrl.userSettings.result[0].lastLoginDate}}</span>-->
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
