(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sepa.controller:SepaTransactionsOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaTransactionsOverviewCtrl', SepaTransactionsOverviewCtrl);

  function SepaTransactionsOverviewCtrl($rootScope, $http, $state, SepaService, HelperService, $log, $translate, GoogleTagManagerHelperService, AlertService) {
    var vm = this;

    vm.paymentsTypeSelected = 1;


    vm.sepaFilter = {
      page: 0,
//      orderBy: 'valuteDateGTE DESC',
      pageSize: 15,
      valuteDateGTE: null,
      valuteDateLTE: null,
      accountId: null,
      userId: null,
      status: "Waiting",
      orderBy: "valuteDate DESC",
      amountGTE: null,
      amountLTE: null
    };

    // ------------------ SEARCH CUSTOM FILTER START ---------------------//
    vm.searchFilterExpanded = false;
    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.applyPaymentsFilter = function () {
      getTimelineItemsGrouped(true);
      vm.searchFilterExpanded = false;
    };

    vm.resetPaymentsFilter = function () {
      vm.sepaFilter.amountGTE = null; //ngmodel
      vm.sepaFilter.amountLTE = null; //ngmodel
      getTimelineItemsGrouped(true);
      vm.searchFilterExpanded = false;
    };
    // ------------------ SEARCH CUSTOM FILTER END ---------------------//


    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: 'all2y',
          description: 'core.period.all'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[3];
    vm.sepaPeriodFilter = function (item) {
      vm.selectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      vm.sepaFilter.valuteDateGTE = timeRange.valueDateGTE;
      vm.sepaFilter.valuteDateLTE = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl" ? timeRange.valueDateLTE : null;
      getTimelineItemsGrouped(true);
      GoogleTagManagerHelperService.pushEvent('SepaOverview', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

    };
    vm.sepaPeriodFilter(vm.periodList[3]);


    vm.selectPaymentsType = function (type, translateLabel) {
      if (type == vm.selectedTabType) return;
      vm.selectedTabType = type;
      switch (type) {
        case "Waiting":
          vm.sepaFilter.status = "Waiting";
          vm.paymentsTypeSelected = 1;
//          sepaFilter.orderBy = 'dateCreated DESC';
          break;
        case "Rejected":
          vm.sepaFilter.status = "Rejected";
          vm.paymentsTypeSelected = 2;
//          sepaFilter.orderBy = 'modificationDate DESC';
          break;
        case "Processed":
          vm.sepaFilter.status = "Processed";
          vm.paymentsTypeSelected = 3;
//          sepaFilter.orderBy = 'modificationDate DESC';
          break;
        default:
          vm.sepaFilter.status = null;
          vm.paymentsTypeSelected = 1;
//          sepaFilter.orderBy = 'dateCreated DESC';

      }
      GoogleTagManagerHelperService.pushEvent('Sepa Transactions Overview', 'Tab', $translate.instant(translateLabel));

      //clearPaymentsFilter();
      getTimelineItemsGrouped(true);
    };

    vm.paymentsOverviewList = {};

    vm.paymentsOverviewListGrouped = {};

    init();
    function init() {
      if ($state.params.selectedTab == 'Processed') {
        vm.paymentsTypeSelected = 3; //Executed transactions tab must
        vm.sepaFilter.status = "Processed";
      }

      getTimelineItemsGrouped(true);

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
        vm.sepaComplaintDDFilter = {
          page: 0,
          pageSize: 10,
          orderBy: "complaintDate DESC"
        };
        getSepaDDComplaints(true);
      }
    }

    vm.sepaDDComplaints = [];
    vm.loadMoreSepaDDComplaints = function () {
      getSepaDDComplaints(false);
    }

    function getSepaDDComplaints(reset) {
      if (reset) {
        vm.sepaComplaintDDFilter.page = 0;
      } else {
        vm.sepaComplaintDDFilter.page += 1;
      }

      SepaService.getSepaDDComplaints(vm.sepaComplaintDDFilter)
        .then(function (response) {
          vm.sepaDDComplaints = HelperService.mergeRowsForTimeline({
            existingData: vm.sepaDDComplaints,
            newData: response,
            groupFunction: function (item) {
              var date = new Date(item.complaintDate);
              // If we want to group only by date component, without hours
              date.setHours(0, 0, 0, 0);
              return date;
            },
            virtualRowFunction: function (e) {
              var month = new Date(e).getMonth() + 1;
              var day = new Date(e).getDate();
              var virtualRow = {
                isVirtual: true,
                valueDate: new Date(e),
                month: month,
                day: day
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          });
        }).catch(function (error) {
        $log.error(error);
      });
    }

    function getTimelineItemsGrouped(reset) {

      if (reset) {
        vm.sepaFilter.page = 0;
      } else {
        vm.sepaFilter.page += 1;
      }
      SepaService.getPage(vm.sepaFilter)
        .then(function (response) {

          vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.timelineListGrouped,
            newData: response,
            groupFunction: function (item) {
              return item.valuteDate;
              //var date = new Date(item.valuteDate);
              // If we want to group only by date component, without hours
              //date.setHours(0, 0, 0, 0);
              //return date;
            },
            virtualRowFunction: function (e) {
              var month = new Date(e).getMonth() + 1;
              var day = new Date(e).getDate();
              var virtualRow = {
                isVirtual: true,
                valueDate: e,
                month: month,
                day: day
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          });

          $log.debug("groupedSepaTimeline", vm.timelineListGrouped);
        }).catch(function (error) {
        $log.error(error);
      });
    }

    vm.loadMore = function () {
      getTimelineItemsGrouped(false);
    };

    vm.showDetails = function (payment) {
      if (payment.selected) {
        payment.selected = false;
        return;
      }

      SepaService.getById(payment.transactionId)
        .then(function (response) {
          angular.extend(payment, response);
          payment.selected = !payment.selected;
        });
    };

    vm.showComplaintDetails = function (item) {
      if (item.selected) {
        item.selected = false;
        return;
      }
      item.selected = true;
    };

    vm.complaint = function (transaction) {
      $state.go("sepa.complaint", {transactionId: transaction.transactionId, status: transaction.status});
    };

    vm.refund = function (transaction) {
      $state.go("sepa.refund", {transactionId: transaction.transactionId, status: transaction.status});
    };

    vm.goToNewSepa = function () {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === "sl") {
        $state.go("sepa.new",{id: null});
      } else {
        $state.go("sepa.new-cro");
      }
    };

    vm.refuseSepaOrder = function (transactionId) {
      AlertService.confirmationAlert({
        text: "sepa.sddPaymentReturnInfo"
      })
        .then(function (accept) {
          if (!accept) return;

          $state.go("sepa.order-refund-cro", {transactionId: transactionId});
        });
    }

  }
}());
