<!-- Card account details -->
<div class="m-b-10">
  <div
    ng-if="accounts.selectedAccount.hasOwnProperty('balance') && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
    <span class="account-header-value white-text-block"
      ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>
    <hr class="account-header-line" />
    <span class="m-t-neg-15 white-text-block" translate="accounts.accBalance"></span>
  </div>

  <div
    ng-if="accounts.selectedAccount.hasOwnProperty('availableBalance') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
    <span class="account-header-value white-text"
      ng-bind="(accounts.selectedAccount.availableBalance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>
    <span ng-if="accounts.isNuN(accounts.selectedAccount.availableBalance2)" class="account-header-value-eur"
      ng-bind="'/ ' + (accounts.selectedAccount.availableBalance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
    <hr class="account-header-line" />
    <span class="m-t-neg-15 white-text-block" ng-if="accounts.selectedAccount.hasOwnProperty('accountType') &&
    !(accounts.selectedAccount.accountType.id === 'PPRM' || accounts.selectedAccount.accountType.id === 'PPRO')"
      translate="accounts.accAvailableToSpend"></span>
    <span ng-if="accounts.selectedAccount.hasOwnProperty('accountType') &&
    (accounts.selectedAccount.accountType.id === 'PPRM' || accounts.selectedAccount.accountType.id === 'PPRO')"
      class="m-t-neg-15 white-text-block" translate="accounts.balance"></span>
  </div>

  <div
    ng-if="!accounts.selectedAccount.hasOwnProperty('balance') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
    <span class="account-header-value white-text-block">
      {{0 | number:2 }} {{accounts.selectedAccount.currencySymbol}}
    </span>
    <hr class="account-header-line" />
    <span class="m-t-neg-15 white-text-block" translate="accounts.accBalance"></span>
  </div>
  <div class="row account-header-bottom"
    ng-show="!accounts.isAccountDetailsOpen && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'"
    ng-class="{'m-b-40': !accounts.selectedAccount.hasOwnProperty('availableBalance')}">
    <div ng-if="accounts.selectedAccount.hasOwnProperty('availableBalance')" class="col-xs-12 col-md-4">
      <span class="f-20 white-text-block">
        {{accounts.selectedAccount.availableBalance | number:2}}
        {{accounts.selectedAccount.currencySymbol}}</span>
      <span class="white-text-block" translate="accounts.accAvailableToSpend"></span>
    </div>
  </div>

  <div class="row account-header-bottom" ng-show="!accounts.isAccountDetailsOpen && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' &&
    accounts.selectedAccount.hasOwnProperty('accountType') &&
    !(accounts.selectedAccount.accountType.id === 'PPRM' || accounts.selectedAccount.accountType.id === 'PPRO') "
    ng-class="{'m-b-40': !accounts.selectedAccount.hasOwnProperty('balance')}">
    <div ng-if="accounts.selectedAccount.hasOwnProperty('balance')" class="col-xs-12 col-md-4">
      <span class="f-20 white-text-block">
        {{accounts.selectedAccount.limit | number:2}}
        {{accounts.selectedAccount.currencySymbol}}</span>
      <span class="white-text-block" translate="accounts.accApprovedLimit"></span>
    </div>
  </div>

  <span ng-show="accounts.isAccountDetailsOpen && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
    <div class="row p-t-20">
      <div class="col-xs-6 col-md-6 p-b-20" ng-if="accounts.selectedAccount.iban">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.iban}}</span>
        <!--<span class="white-text-block" translate="accounts.accountNumber"></span>-->
      </div>
    </div>

    <div class="row p-t-20">
      <!--<div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.cardAccountType">-->
      <!--<span class="f-20 white-text-block">{{accounts.selectedAccount.cardAccount.cardAccountType}}</span>-->
      <!--</div>-->
      <!--<div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.accountNumber">-->
      <!--<span class="f-20  white-text-block">-->
      <!--{{accounts.selectedAccount.accountNumber}}</span>-->
      <!--<span class="white-text-block" translate="accounts.accountNumber"></span>-->
      <!--</div>-->

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20  white-text-block">
          {{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' :
          '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accBalanceDateLastChanged"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.closingBalanceDate">
        <span class="f-20  white-text-block">
          <!--{{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' : '+0200'}}</span>-->
          {{accounts.selectedAccount.cardAccount.closingBalanceDate | date:'shortDate' : '+0200'}}
        </span>
        <span class="white-text-block" translate="accounts.accDateLastChanged"></span>
      </div>
    </div>
  </span>

  <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">

    <div class="row p-t-20" ng-switch-when="sl" ng-show="accounts.isAccountDetailsOpen">

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.interestRateAmount">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.cardAccount.interestRateAmount}} %</span>
        <span class="white-text-block" translate="accounts.interestRate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.payDate">
        <span class="f-20 white-text-block">{{accounts.selectedAccount.cardAccount.payDate | date: 'dd.'}}</span>
        <span class="white-text-block" translate="accounts.accDueDate"></span>
      </div>
      <div class="col-xs-12 col-md-4 p-b-20"
        ng-hide="!accounts.selectedAccount.hasOwnProperty('limit') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'">
        <span class="f-20 white-text-block">
          <span ng-if="accounts.selectedAccount.hasOwnProperty('limit')">{{accounts.selectedAccount.limit |
            currency:""}}
            {{accounts.selectedAccount.currencySymbol}}</span>
          <span ng-if="!accounts.selectedAccount.hasOwnProperty('limit')" translate="accounts.dataNotAvailable"></span>
        </span>
        <span class="white-text-block" translate="accounts.accBalanceSlo"></span>
      </div>
      <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.obligationAmount">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.cardAccount.obligationAmount | number:2}}</span>
        <span class="white-text-block" translate="accounts.accObligationAmount"></span>
      </div>
      <div class="col-xs-12 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.cardAccount.outstandingAmount && accounts.selectedAccount.accountType.id === 'VISA'">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.cardAccount.outstandingAmount |
          number:2}}</span>
        <span class="white-text-block" translate="accounts.accOutstandingAmount"></span>
      </div>

      <div class="col-xs-12 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.cardAccount.hasOwnProperty('limitAmountAvailable')">
        <span class="f-20  white-text-block">
          {{accounts.selectedAccount.cardAccount.limitAmountAvailable | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accPosPaymentLimit"></span>
      </div>

      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">

        <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.limitValidUntilDate">
          <span class="f-20  white-text-block">
            {{accounts.selectedAccount.limitValidUntilDate | date:'shortDate' : '+0200'}}</span>
          <span class="white-text-block" translate="accounts.accLimitValidUntilDate"></span>
        </div>
      </div>
    </div>

    <div class="row p-t-20" ng-switch-when="hr" ng-show="accounts.isAccountDetailsOpen">

      <!-- 3u1 -->
      <!-- row 1 start -->
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12 col-md-2 p-b-20 p-r-0" ng-show="accounts.selectedAccount.hasOwnProperty('limit')
          && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
            <span class="f-20 white-text-block">
              <span ng-if="accounts.selectedAccount.hasOwnProperty('limit')">{{accounts.selectedAccount.limit |
                currency:""}}
                {{accounts.selectedAccount.currencySymbol}}</span>
              <span ng-if="!accounts.selectedAccount.hasOwnProperty('limit')"
                translate="accounts.dataNotAvailable"></span>
            </span>
            <span class="white-text-block" translate="accounts.accApprovedLimit"></span>
          </div>

          <div class="col-xs-12 col-md-2 p-b-20 p-r-0" ng-show="accounts.selectedAccount.hasOwnProperty('limit')
          && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'
          && !(accounts.selectedAccount.hasOwnProperty('accountType') &&
          (accounts.selectedAccount.accountType.id === 'PPRM' || accounts.selectedAccount.accountType.id === 'PPRO'))">
            <span class="f-20 white-text-block">
              <span ng-if="accounts.selectedAccount.hasOwnProperty('limit')">{{accounts.selectedAccount.limit |
                currency:""}}
                {{accounts.selectedAccount.currencySymbol}}</span>
              <span ng-if="!accounts.selectedAccount.hasOwnProperty('limit')"
                translate="accounts.dataNotAvailable"></span>
            </span>
            <span class="white-text-block" translate="accounts.accApprovedLimit"></span>
          </div>

          <div class="col-xs-6 col-md-2 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.hasOwnProperty('obligationAmount')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.obligationAmount | currency:''}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.obligationAmountCard"></span>
          </div>

          <div class="col-xs-6 col-md-2 p-b-20 p-r-0" ng-if="accounts.selectedAccount && accounts.selectedAccount.hasOwnProperty('cardReservationAmount')
          && APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardReservationAmount}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.reservedAmount"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0" ng-if="accounts.selectedAccount && accounts.selectedAccount.hasOwnProperty('cardReservationAmount')
          && APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardReservationAmount}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <span class="white-text-block" translate="accounts.reservedAmount"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.hasOwnProperty('outstandingAmount')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.outstandingAmount | currency:''}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <!-- <span class="white-text-block" translate="ebook.status"></span> -->
            <span class="white-text-block" translate="accounts.outstandingAmountCard"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount.hasOwnProperty('outstandingDebitAmount')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.outstandingDebitAmount | currency:''}}
              {{accounts.selectedAccount.currencySymbol}}</span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.outstandingDebitAmountCard"></span>
          </div>
        </div>
      </div>
      <!-- row 1 end -->

      <!-- row 2 start-->
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.statementDate">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.statementDate | date:'shortDate' : '+0200'}}</span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.statementDateCard"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.hasOwnProperty('usedAmount')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.usedAmount | currency:''}}
              {{accounts.selectedAccount.cardAccount.currencySymbolMCRStatement}}</span>
            </span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.usedAmountCard"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0" ng-if="accounts.selectedAccount.hasOwnProperty('minimalDebit')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.minimalDebit | currency:''}}
              {{accounts.selectedAccount.cardAccount.currencySymbolMCRStatement}}</span>
            </span>
            <span class="white-text-block" translate="accounts.minimalDebitCard"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0" ng-if="accounts.selectedAccount.minimalDebitUntilDate">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.minimalDebitUntilDate | date:'shortDate' : '+0200'}}</span>
            <span class="white-text-block" translate="accounts.minimalDebitUntilDateCard"></span>
          </div>
        </div>
      </div>
      <!-- row 2 end -->

      <!-- row 3 start-->
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-6 col-md-3 p-b-20 p-r-0" ng-if="accounts.selectedAccount.status">
            <span class="f-20  white-text-block">
              {{'accounts.'+accounts.selectedAccount.status | translate}}</span>
            <span class="white-text-block" translate="ebook.status"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.cardExpieryDate">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.cardExpieryDate | date:'shortDate' : '+0200'}}</span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.cardExpieryDateCard"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0" ng-if="accounts.selectedAccount.balanceLastChangedDate">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' : '+0200'}}</span>
            <span class="white-text-block" translate="accounts.accDateLastChanged"></span>
          </div>

          <div class="col-xs-6 col-md-3 p-b-20 p-r-0"
            ng-if="accounts.selectedAccount.cardAccount && accounts.selectedAccount.cardAccount.hasOwnProperty('interestRateAmount')">
            <span class="f-20  white-text-block">
              {{accounts.selectedAccount.cardAccount.interestRateAmount}}%</span>
            <!-- <span class="white-text-block" translate="accounts.accDateLastChanged"></span> -->
            <span class="white-text-block" translate="accounts.interestRateAmountCard"></span>
          </div>
        </div>
      </div>
      <!-- row 3 end -->

    </div>

    <div class="row p-t-20" ng-switch-default ng-show="accounts.isAccountDetailsOpen">

      <div class="col-xs-12 col-md-4 p-b-20"
        ng-hide="!accounts.selectedAccount.hasOwnProperty('limit') && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'bh'">
        <span class="f-20 white-text-block">
          <span ng-if="accounts.selectedAccount.hasOwnProperty('limit')">{{accounts.selectedAccount.limit |
            currency:""}}
            {{accounts.selectedAccount.currencySymbol}}</span>
          <span ng-if="!accounts.selectedAccount.hasOwnProperty('limit')" translate="accounts.dataNotAvailable"></span>
        </span>
        <span class="white-text-block" translate="accounts.accApprovedLimit"></span>
      </div>

      <!--<div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.payDate">-->
      <!--<span class="f-20  white-text-block">{{accounts.selectedAccount.cardAccount.payDate | date:'shortDate' : '+0200'}}</span>-->
      <!--<span class="white-text-block" translate="accounts.accDueDate"></span>-->
      <!--</div>-->
      <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.obligationAmount">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.cardAccount.obligationAmount | currency: ""}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accObligationAmount"></span>
      </div>
      <div class="col-xs-12 col-md-4 p-b-20"
        ng-if="accounts.selectedAccount.cardAccount.outstandingAmount && accounts.selectedAccount.accountType.id === 'VISA'">
        <span class="f-20  white-text-block">{{accounts.selectedAccount.cardAccount.outstandingAmount |
          number:2}}</span>
        <span class="white-text-block" translate="accounts.accOutstandingAmount"></span>
      </div>
      <!--   <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.limitAmountAtm">
             <span class="f-20  white-text-block">
               {{accounts.selectedAccount.cardAccount.limitAmountAtm | number: 2 }}
               {{accounts.selectedAccount.currencySymbol}}</span>
             <span class="white-text-block" translate="accounts.accAtmWithdrawalLimit"></span>
           </div>

           <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.cardAccount.limitAmountAvailable">
             <span class="f-20  white-text-block">
               {{accounts.selectedAccount.cardAccount.limitAmountAvailable |number:2}}
               {{accounts.selectedAccount.currencySymbol}}</span>
             <span class="white-text-block" translate="accounts.accPosPaymentLimit"></span>
           </div>-->
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.status">
        <span class="f-20  white-text-block">
          {{'accounts.'+accounts.selectedAccount.status | translate}}</span>
        <span class="white-text-block" translate="ebook.status"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
        <span class="f-20  white-text-block">
          {{accounts.selectedAccount.balanceLastChangedDate | date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accDateLastChanged"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="accounts.selectedAccount.limitValidUntilDate">
        <span class="f-20  white-text-block">
          {{accounts.selectedAccount.limitValidUntilDate | date:'shortDate' : '+0200'}}</span>
        <span class="white-text-block" translate="accounts.accLimitValidUntilDate"></span>
      </div>
      <div class="col-xs-6 col-md-4 p-b-20" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.reservedAmount
          | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="accounts.selectedAccount.planedDebit">{{accounts.selectedAccount.planedDebit | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="f-20 white-text-block"
          ng-if="!accounts.selectedAccount.reservedAmount && !accounts.selectedAccount.planedDebit">{{0 | number:2}}
          {{accounts.selectedAccount.currencySymbol}}</span>
        <span class="white-text-block" translate="accounts.accReservations"></span>
      </div>
    </div>


  </div>
</div>