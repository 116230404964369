<table class="table table-transparent table-responsive">
  <tbody>
  <tr>
    <td>
      <span class="po-desc-label" translate="investOverview.sharedAccount">:</span>
    </td>
    <td>
      <span class="tridm-value-label">{{::order.accountNumberBuy}}</span>
    </td>
  </tr>
  <tr>
    <td>
      <span class="po-desc-label" translate="investOverview.invAmount">Amount:</span>
    </td>
    <td>
      <span class="tridm-value-label">
        {{order.invAmount | number:2 }}
        {{::order.amountCurCode}}
      </span>
    </td>
  </tr>
  <tr>
    <td>
      <span class="po-desc-label" translate="investOverview.invDate">Date:</span>
    </td>
    <td>
      <span class="tridm-value-label">{{order.invAmountDate | date: 'shortDate' : '+0200'}}</span>
    </td>
  </tr>
  <tr>
    <td>
      <span class="po-desc-label" translate="investOverview.buyAccount">Buy account id:</span>
    </td>
    <td>
      <span class="tridm-value-label">{{::order.invBuyAccId}}</span>
    </td>
  </tr>
  <tr>
    <td>
      <span class="po-desc-label" translate="investOverview.fundName">Fund name:</span>
    </td>
    <td>
      <span class="tridm-value-label">{{::order.fundName}}</span>
    </td>
  </tr>
  <tr>
    <!--<td>-->
      <!--<span class="po-desc-label" translate="investOverview.povlDueDate">Due date:</span>-->
    <!--</td>-->
    <!--<td>-->
      <!--<span class="po-value-label">{{::order.dueDate| date:'shortDate' : '+0200'}}</span>-->
    <!--</td>-->
  <!--</tr>-->
  <!--<tr>-->
    <!--<td colspan="2">-->
      <!--<div>-->
                    <!--<span class="pull-right" ng-repeat="actionObject in order.allowedActionList.itemList">-->
                        <!--<span ng-switch on="actionObject.methodName">-->
                            <!--<button ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed" ng-click="currencyConversion.recallPaymentAction(payment.id);"-->
                                    <!--class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase" translate="currencyConversion.recallPayment"-->
                                    <!--translate="paymentActions.recall"></button>-->
                            <!--<button ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed" ng-click="currencyConversion.signPaymentAction(payment.id);"-->
                                    <!--class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"-->
                                    <!--translate="paymentActions.sign"></button>-->
                            <!--<button ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed" ng-click="currencyConversion.deletePaymentAction(payment.id, 'timeline');"-->
                                    <!--class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"-->
                                    <!--translate="paymentActions.delete"></button>-->
                        <!--</span>-->
                    <!--</span>-->
      <!--</div>-->
    <!--</td>-->
  <!--</tr>-->
  </tbody>
</table>
