(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accounts.service:ReservationsService
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .service('ReservationsService', ReservationsService);

  function ReservationsService(EndpointsService, $q, $http, $log, HelperService) {
    var ReservationsServiceBase = {};

    ReservationsServiceBase.getPage = function(params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function(response){
        //$log.debug("Params:", params);
        $http.get(response.links.cardReservation, { params: params})
          .then(function(data) {
            deferred.resolve(data.data);
          }).catch(function(msg, code) {
            deferred.reject(msg.data);
          });
      }, function(error){
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    ReservationsServiceBase.getById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.cardReservation_id.replace('{id}', id), {params: params})
          .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return ReservationsServiceBase;
  }
}());
