(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name complaints.controller:ComplaintConversationCtrl
     *
     * @description
     *
     */
    angular
        .module('complaints')
        .controller('ComplaintConversationCtrl', ComplaintConversationCtrl);

    function ComplaintConversationCtrl($q, $rootScope, HelperService, NotificationService, ComplaintsService, $scope, data) {
        var vm = this;
        vm.sendingInProgress = false;
        vm.data = data;
        vm.forbidReply = data.forbidReply;
        vm.tabs = [{ translateKey: 'complaints.communication', id: '1' }, { translateKey: 'complaints.documents', id: '2' }];
        $scope.selectedTab = vm.tabs[0].id;
        $scope.$watch('selectedTab', function(newValue, oldValue) {
            if (newValue != oldValue) {
            }
        });
        $rootScope.$on('complaintMessage', onComplaintMessage);
        vm.sendMessage = function() {
            if (vm.sendingInProgress) {
                return;
            }
            if (!vm.message) {
                return;
            }
            vm.sendingInProgress = true;
            $rootScope.$broadcast('updateComplaint', {message: vm.message, complaintId: vm.data.complaintId});
        }

        vm.close = function(closeFunction) {
            $rootScope.$off('complaintMessage', onComplaintMessage);
            closeFunction();
        }

        vm.downloadAttachment = function(attachment) {
            $rootScope.$broadcast('downloadComplaintAttachment', attachment);
        }

        function onComplaintMessage(event, data) {
            vm.sendingInProgress = false;
            vm.data.messages.push(data);
            vm.message = '';
            scrollToBottom();
        }

        function scrollToBottom() {
            setTimeout(function() {
                $('.messages-wrapper').scrollTop($('.messages-wrapper')[0].scrollHeight);
            } , 200);
        }

        scrollToBottom();
    }
}());
