(function () {
  'use strict';

  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        templateUrl: 'settings/settings.tpl.html',
        controller: 'SettingsCtrl',
        controllerAs: 'settings',
        resolve: {
          userSettingsData: function ($rootScope, UserSettingsService, ConfigEndpointService) {

            var params = {};
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
              if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr') {
                params = {includeList: 'UserNotificationDetailsList'};
              }
              return UserSettingsService.getPage(params);
            } else {
              return ConfigEndpointService.getDefaultData()
                .then(function (response) {
                  if (response && response.APILocation === 'hr') {
                    params = {includeList: 'UserNotificationDetailsList'};
                  }
                  return UserSettingsService.getPage(params);
                })
            }
          }
        }
      })
      .state('settings.orderSoftToken', {
        url: '/order-soft-token',
        resolve: {
          country: function (ConfigEndpointService) {
            return ConfigEndpointService.getDefaultData()
          }
        },
        views: {
          '@': {
            templateUrl: 'settings/order-soft-token.tpl.html',
            controller: 'OrderSoftTokenCtrl',
            controllerAs: 'orderSoftToken',

          }
        }
      })
      .state('settings.contactDataVerificationEmail', {
        url: '/contact-data-verification/email',
        views: {
          '@': {
            templateUrl: 'settings/contact-data-verification/contact-data-verification.tpl.html',
            controller: 'ContactDataVerificationCtrl',
            controllerAs: 'vm'
            // resolve: {
            //   userSettings: function (UserSettingsService) {
            //     return UserSettingsService.getPage();
            //   }
            // }
          }
        }
      }) .state('settings.contactDataVerificationPhone', {
        url: '/contact-data-verification/phone',
        views: {
          '@': {
            templateUrl: 'settings/contact-data-verification/contact-data-verification.tpl.html',
            controller: 'ContactDataVerificationCtrl',
            controllerAs: 'vm'
            // resolve: {
            //   userSettings: function (UserSettingsService) {
            //     return UserSettingsService.getPage();
            //   }
            // }
          }
        }
      })
      .state('security', {
        url: '/security',
        templateUrl: 'settings/security.tpl.html',
        controller: 'SecurityCtrl',
        controllerAs: 'settings',
        params: {
          token: ''
        }
      })
      .state('securitySelectCompany', {
        url: '/security-select-company',
        templateUrl: 'settings/security-select-company.tpl.html',
        controller: 'SecurityCtrl',
        controllerAs: 'settings'
      });
  }
}());
