(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:AccTransDetailModalCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('AccTransDetailModalCtrl', AccTransDetailModalCtrl);

  function AccTransDetailModalCtrl(transaction, account, $uibModalInstance, HelperService, $localStorage) {
    var vm = this;
    vm.ctrlName = 'AccTransDetailModalCtrl';
    vm.transaction = transaction;
    vm.account = account;

    vm.countryCode = $localStorage.code;

    vm.closeModal = function(){
      $uibModalInstance.close();
    };

    vm.openReceiverInfoDialog = function(event) {
    }
  }
}());
