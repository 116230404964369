(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eInvoices.controller:EinvoicesRegisterCtrl
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .controller('EinvoicesRegisterCtrl', EinvoicesRegisterCtrl);

  function EinvoicesRegisterCtrl($rootScope, $state, $q, $log, EinvoicesIssuersOverviewService, EinvoiceSubscriptionsService, UserSettingsService,
                                  HelperService, AlertService, AccountsService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'EinvoicesRegisterCtrl';
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.issuers = [];
    vm.issuer = null;
    vm.userAgrees = false;
    vm.userApproves = false;
    vm.formSubmitted = false;
    vm.step = 'register';
    vm.editingEmail = false;
    vm.taxIdSearch = "";
    vm.issuerSearchInput = "";

    var issuerParams = {
      identificationNumberFTS: "",
      nameFTS: ""
    };

    vm.tempDompObj = {
      fromAccountSelect: {}
    };

    vm.register = register;
    vm.registerNew = registerNew;
    vm.editEmail = editEmail;
    vm.updateEmail = updateEmail;
    vm.cancelEmail = cancelEmail;
    vm.disabled = true;

    vm.requestTypes = [
      { value: "eInvoicesModal.subscribe", name: "subscribe" },
      { value: "eInvoicesModal.unsubscribe", name: "unsubscribe" }
    ];

    function getIssuers() {
      var filter = {
        page: 0,
        pageSize: 10000,
        orderBy: 'name asc'
      };
      return EinvoicesIssuersOverviewService.getPage(filter)
        .then(function (response) {
        vm.issuers = response.result;
        $log.debug("list", vm.einvoicesIssuersList);
      });
    };

    function getSubscriptionsPage() {
      var subscriptionsFilter = {
        page: 0,
        pageSize: 1000
      };

      return EinvoiceSubscriptionsService.getPage(subscriptionsFilter)
        .then(function (response) {
        vm.subscriptionObjectList = response;
      })
        .catch(function (error) {
        $log.debug("error", error);
      });
    }

    function getUserSettingsPage() {
      var userSettingsFilter = {
        page: 0,
        pageSize: 1
      };
      UserSettingsService.getPage(userSettingsFilter)
        .then(function (response) {
        // vm.email = response.result[0].eInvoiceEmail;
        vm.email = response.result[0].notificationEmail;
        vm.gsm = response.result[0].gsm;
        vm.userId = response.result[0].id;
        $log.debug("E-invoice email", vm.email);
      }).catch(function (error) {
        $log.error(error);
      });
    };

    function register() {
      if (vm.registerButtonPressed) return;
      vm.formSubmitted = true;

      if (!vm.einvoiceSubscribeForm.$valid) {
        return;
      }

      var object = createRequestObject();
      if(vm.countryCode == 'sl'){
        object.requestType = vm.requestType.name;
        object.phoneNumber = vm.gsm;
        object.accountId = vm.tempDompObj.fromAccountSelect.accountId;
        object.email = vm.email;
      }
      vm.registerButtonPressed = true;
      EinvoiceSubscriptionsService.insertSubscriptionRequest(object)
        .then(function (response) {
          if(vm.countryCode != 'sl'){
            AlertService.infoAlert({
              text: 'alert.subscriptionRequest'
            })
              .then(function (response) {
                vm.userApproves = false;
                vm.step = 'done';
              });
          }else{
            vm.userApproves = false;
            vm.step = 'done';
          }

      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
        $log.error("Subscription error:", error);
      }).finally(function (){
        vm.registerButtonPressed = false;
      });
    };

    vm.resume = function () {
      vm.formSubmitted = true;

      if (!vm.einvoiceSubscribeForm.$valid) {
        return;
      }

      if(vm.countryCode === 'sl')
        validateEInvoice();
      else
        vm.step = 'resume';
    };

    function validateEInvoice() {
      var object = createRequestObject();
      object.requestType = vm.requestType.name;
      object.phoneNumber = vm.gsm;
      object.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      object.email = vm.email;

      EinvoiceSubscriptionsService.validateSubscriptionRequest(object)
        .then(function (response) {
          vm.step = 'resume';
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    }


    function registerNew() {
      // Reset input fields
      // vm.step = 'register';
      // vm.formSubmitted = false;
      // vm.userAgrees = false;
      // vm.userApproves = false;
      // vm.referenceNumber = '';
      // vm.issuer = '';
      // vm.email = '';
      // vm.emailConfirmation = '';
      $state.go("eInvoices.einvoicesRegisterNew", {}, {reload: true});
    };

    function editEmail() {
      this.originalEmail = vm.email;
      vm.editingEmail = true;
    };

    function updateEmail() {
      var request = {
        updateInvoicingEmail: true,
        invoicingEmail: vm.email
      };
      UserSettingsService.updateInvoiceEmail(request, vm.userId)
        .then(function (response) {
        vm.editingEmail = false;
        $log.debug("updated e-invoiceMail", response);
      }).catch(function (error) {
        $log.error(error);
      });
    };


    function cancelEmail() {
      vm.editingEmail = false;
      vm.email = this.originalEmail;
    };

    function createRequestObject() {
      return {
        paymentReferenceNumber: vm.referenceNumber,
        issuerId: vm.issuer.id,
        userApproves: vm.userApproves
    /*  requestType: vm.subscriptionObject.settingType,
        userServiceId: vm.referenceNumber,
        serviceType: vm.subscriptionObject.serviceType,
        serviceState: vm.subscriptionObject.serviceState,
        services: null,
        userApproves: vm.userApproves */
      };
    };

    vm.issuerSelected = function (issuer) {
      vm.issuer = issuer;
      vm.taxIdSearch = issuer.identificationNumber;
      vm.issuerSearchInput = issuer.name;
      vm.searchExpanded = false;

    };

    vm.checkDisabled = function () {
      if(vm.userApproves == true && vm.issuer && !vm.issuer.termsOfServiceLink) {
        vm.disabled = false;
      } else if (vm.userApproves == true && vm.issuer && vm.issuer.termsOfServiceLink && vm.userAgrees == true) {
        vm.disabled = false;
      }
      else if (vm.countryCode == 'sl' && vm.issuer && vm.userApproves == true){
        vm.disabled = false;
      }
      else {
        vm.disabled = true;
      }
    };

    vm.removeSelectedIssuer = function() {
      vm.issuer = null;
      vm.issuerSearchInput = "";
      vm.taxIdSearch = "";
      vm.userApproves = false;
      vm.userAgrees = false;
      vm.referenceNumber = '';
      vm.checkDisabled();
      vm.einvoiceSubscribeForm.referenceNumber.$setValidity("referenceRequired", true);
    };

    vm.searchIssuers = function (searchBy) {

      if (searchBy == "id" && vm.taxIdSearch && vm.taxIdSearch.length < 3) {
        if (vm.issuerSearchInput.length < 3) return;
      }
      if (searchBy == "name" && vm.issuerSearchInput && vm.issuerSearchInput.length < 3) {
        if (vm.taxIdSearch.length < 3) return;
      }

      // if (searchBy == "id") {
      //   if (vm.taxIdSearch.length > 3)  {}// CONTINUE;
      //   if (vm.issuerSearchInput.length > 3) {}
      // }


      // if (searchBy == "name" && vm.issuerSearchInput.length < 3 && vm.taxIdSearch.length < 3) return;
      var filter = {
        nameFTS: vm.issuerSearchInput,
        identificationNumberFTS: vm.taxIdSearch
      };

      return EinvoicesIssuersOverviewService.getPage(filter)
        .then(function (response) {
          if (response.result.length) {
            vm.issuers = response.result;
            vm.searchExpanded = true;
          } else {
            vm.issuers = [];
          }
        });
    };


    vm.init = function() {
      $log.debug('EinvoicesRegisterCtrl init()');
      //getIssuers();
      getUserSettingsPage();
      //getSubscriptionsPage();
      var promises = [getSubscriptionsPage()];
       if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') promises.push(getIssuers());
       else  promises.push(loadAccounts());
      $q.all(promises)
        .then(function (){

        if (!_.isEmpty($state.params.myParam)) {
          vm.registrationObject = $state.params.myParam;
          vm.referenceNumber = vm.registrationObject.paymentReferenceNumber;
          vm.requestType = (vm.registrationObject.serviceType.toLowerCase() === "registration") ? vm.requestTypes[0] : vm.requestTypes[1];

          // TSK-11078 (SLOW-384) e-invoice Register again
          // Select account by accountId (swagger model returns accountId for eInvoiceSubscription
          vm.setAccountFrom(_.find(vm.accounts, {accountId: vm.registrationObject.accountId}));
          var filter = {
            identificationNumberFTS: vm.registrationObject.eInvoiceIssuer.identificationNumber,
            nameFTS: vm.registrationObject.issuerName
          };
          EinvoicesIssuersOverviewService.getPage(filter)
            .then(function (response) {
              if (response.result.length) {
                vm.issuers = response.result;
                vm.issuerSelected(vm.issuers[0]);
              } else {
                vm.issuers = [];
              }
            });
        } else {
          vm.requestType = vm.requestTypes[0];
        }
      })
        .catch(function (error){
        NotificationService.showMessage(error, 'error');
      });
    };


    /////////////////from account component///////////////////////////
    var accountsFilter = {
      accountTypeGroupList: ['CurrentAccount'],
      isParentAccount: true,
      isOwn: true
    };

    vm.accounts = [];

    // Load accounts
    var loadAccounts = function () {
      AccountsService.getPage(accountsFilter)
        .then(function (response) {
          vm.accounts = response.result;
          if(vm.accounts)
            vm.tempDompObj.fromAccountSelect = vm.accounts[0];
        })
        .catch(function (error) {
          $log.error(error);
        });
    };


    vm.toggleFromAccount = function () {
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = (item) ? item: vm.accounts[0];
      vm.fromAccountDropdown = false;
    };

    vm.checkIfRequiredAndValid = function (referenceInput) {
      if(vm.issuer && vm.issuer.referenceRequired && !referenceInput){
        vm.einvoiceSubscribeForm.referenceNumber.$setValidity("referenceRequired", false);
      }else{
        vm.einvoiceSubscribeForm.referenceNumber.$setValidity("referenceRequired", true);
      }
    };

    vm.goToEInvoices = function () {
      if(vm.countryCode == 'sl')
        $state.go('eInvoices.einvoicesOverview',{tabToSelect: 2}); //0,1,2 - 2 is Third
      else
        $state.go('eInvoices.einvoicesOverview');
    };



    vm.init();
  }
}());

