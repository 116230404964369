<section id="main">
  <div class="container-fluid">

    <!--HEADER START-->
    <viber-header></viber-header>
    <!--HEADER END-->

    <div class="row">
      <div class="col-sm-12 t-a-c p-t-15 ">
        <h4 ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" class="c-appblue" translate="viber.conditionHeader"></h4>
        <h4 class="c-appblue underline" translate="viber.conditionHeader2"></h4>
      </div>
    </div>

    <div class="row p-b-15">
      <div class="col-sm-12">
        <div class="image">
          <!--<img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr'" src="img/hypo/viber/sl-v1.png" alt="" />-->
          <!--<img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'" src="img/hypo/viber/sl-v1-hr.png" alt="" />-->

          <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'bh'"
               ng-src="img/hypo/viber/telefoni-{{APP_GLOBAL_SETTINGS.defaultData.APILocation}}.png" alt=""/>

          <img ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh'"
               ng-src="img/hypo/viber/telefoni-{{APP_GLOBAL_SETTINGS.defaultData.BiHLocation}}.png" alt=""/>

            <div class="col-xs-3 p-10 t-a-c c-appblue" translate="viber.text1"></div>
            <div class="col-xs-3 p-10 t-a-c c-appblue" translate="viber.text2"></div>
            <div class="col-xs-3 p-10 t-a-c c-appblue" translate="viber.text3"></div>
            <div class="col-xs-3 p-10 t-a-c c-appblue" translate="viber.text4"></div>
        </div>
      </div>

    </div>

    <div class="row" style="margin-top:10px;">
      <div class="col-sm-12 viber-txt-center">
        <!--<label class="viber-display-inline">-->
          <!--<a ng-href="{{viber.conditionLink}}" target="_blank" style="color:blue;"-->
             <!--translate="viber.viberCondition1"></a>-->
        <!--</label>-->
        <!--<label class="viber-display-inline">{{viber.bankAddition}}-->
          <!--{{APP_GLOBAL_SETTINGS.defaultData.BiHLocation}}</label>-->
        <!--<label class="viber-display-inline" translate="viber.viberCondition2"></label>-->
        <!--<label class="viber-display-inline"><a href="https://www.viber.com/en/terms" target="_blank" style="color:blue;"-->
                                               <!--translate="viber.viberCondition3"></a></label>-->
        <!--<label class="viber-display-inline" translate="viber.viberCondition4"></label>-->


        <label class="viber-display-inline">
          <a ng-href="{{viber.conditionLink}}" target="_blank" style="color:blue;"
             translate="viber.viberCondition1"></a> {{viber.bankAddition}}
          {{APP_GLOBAL_SETTINGS.defaultData.BiHLocation}} {{"viber.viberCondition2" | translate}}
          <a href="https://www.viber.com/en/terms" target="_blank" style="color:blue;"
             translate="viber.viberCondition3"></a>{{"viber.viberCondition4" | translate}}
        </label>

      </div>

      <div class="col-sm-12 viber-txt-center p-t-10">
        <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value ng-binding">
          <label class="p-0"><span translate="viber.agree"></span>
            <a ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" href="https://www.addiko.si/varstvo-podatkov/" target="_blank" translate="viber.agreeLink" style="color:blue;"></a>
          </label>
          <input type="checkbox" ng-model="viber.agree">
          <i class="input-helper"></i>
        </label>
      </div>


      <div class="col-sm-12 viber-txt-center p-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr'">
        <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value ng-binding">
          <label class="p-0" translate="viber.agree2">Suglasan/a sam s Izjavom o zaštiti osobnih podataka</label>
          <input type="checkbox" ng-model="viber.agree2">
          <i class="input-helper"></i>
          <i class="zmdi zmdi-info zmdi-hc-lg"
             uib-tooltip-template="'personalDataTemplate.html'"
             tooltip-append-to-body="true" tooltip-trigger="'mouseenter'"
             tooltip-class="tooltip-custom-w"></i>
        </label>
      </div>


    </div>



    <div class="row">


      <div class="col-sm-12 t-a-c">
        <button class="btn btn-default" data-ui-sref="viber.activate-new"
                ng-disabled="!viber.agree || !viber.agree2" translate="viber.activate">
          Aktiviraj
        </button>
      </div>

    </div>

  </div>
</section>
<script type="text/ng-template" id="personalDataTemplate.html">
  <div class="text-left">
    <p>{{'viber.personalData' | translate}}</p>
    <p>{{'viber.personalData1' | translate}}</p>
  </div>
</script>
