<section id="main">
  <div class="container">
    <div class="exchange-rate-header m-b-10">
      <div class="row">
        <div class="col-sm-12 f-20" translate="reclamation.title"></div>
      </div>
    </div>

    <div class="trans-complain-container">
      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
            <label translate="reclamation.number"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field" ng-switch-default>{{::tReclamation.tempReclamationObj.id}}</label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field" ng-switch-when="sl">{{::tReclamation.tempReclamationObj.transactionId}}</label>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.paymentReference"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::tReclamation.tempReclamationObj.referenceNumber}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.bookingDate"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::tReclamation.tempReclamationObj.bookingDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.valueDate"></label>
            <label  class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::tReclamation.tempReclamationObj.valueDate | date: 'shortDate' : '+0200'}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.amount"></label>
            <div class="row">
              <div class="col-sm-9 p-r-0">
                <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field t-a-r">{{::tReclamation.tempReclamationObj.amount| number: 2}}</label>
              </div>
              <div class="col-sm-3 p-l-0">
                <label class="tr-input-lbl wrap-text p-l-10 p-r-10 tr-amount-ctn input-field">{{::tReclamation.tempReclamationObj.currencySymbol}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xs-6">
          <div class="form-group">
            <label translate="reclamation.purpose"></label>
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field">{{::tReclamation.tempReclamationObj.detail.purpose}}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="tr-input-lbl wrap-text p-l-10 p-r-10 input-field" translate="reclamation.wantComplain"></label>
          </div>
        </div>
      </div>

      <form name="tReclamation.paymentForm" >
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label translate="reclamation.description"></label>
            <!--<input type="text" class="form-control input-field" data-ng-model="tReclamation.tempReclamationObj.reclamationInput">-->
            <textarea ng-pattern-restrict="{{tReclamation.regexPatterns.allowedCharsDPStextArea}}"
                      ng-model="tReclamation.tempReclamationObj.reclamationInput"
                      class="form-control"
                      id="reclamationInput"
                      name="reclamationInput"
                      cols="30"
                      maxlength="255"
                      minlength="1"
                      rows="5"
                      required
                      ng-class="{'domp-error': tReclamation.formSubmitted && tReclamation.paymentForm.reclamationInput.$invalid}"
                      ></textarea>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <div class="row m-t-10">
      <div class="col-sm-12 c-red bold-font">
        <div class="col-lg-3 col-sm-6 ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0"
                  translate="reclamation.cancel"
                  data-ng-click="tReclamation.cancel();">

          </button>
        </div>
        <div class="col-lg-3 col-sm-6 pull-right ">
          <button class="btn btn-primary app-btn-blue domp-review-and-submit-btn m-0 pull-right"
                  translate="reclamation.confirm"
                  ng-disabled="tReclamation.submitInProgress"
                  data-ng-click="tReclamation.createReclamation();">

          </button>
        </div>
      </div>
    </div>
</section>
