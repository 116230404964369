(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name materialAdmin.service:Services
   *
   * @description
   *
   */
  angular
    .module('materialAdmin')

    // =========================================================================
    // Header Messages and Notifications list Data
    // =========================================================================

    .service('messageService', ['$resource', function($resource){
        this.getMessage = function(img, user, text) {
            var gmList = $resource("data/messages-notifications.json");

            return gmList.get({
                img: img,
                user: user,
                text: text
            });
        }
    }])


    // =========================================================================
    // Best Selling Widget Data (Home Page)
    // =========================================================================

    .service('bestsellingService', ['$resource', function($resource){
        this.getBestselling = function(img, name, range) {
            var gbList = $resource("data/best-selling.json");

            return gbList.get({
                img: img,
                name: name,
                range: range,
            });
        }
    }])


    // =========================================================================
    // Todo List Widget Data
    // =========================================================================

    .service('todoService', ['$resource', function($resource){
        this.getTodo = function(todo) {
            var todoList = $resource("data/todo.json");

            return todoList.get({
                todo: todo
            });
        }
    }])


    // =========================================================================
    // Recent Items Widget Data
    // =========================================================================

    .service('recentitemService', ['$resource', function($resource){
        this.getRecentitem = function(id, name, price) {
            var recentitemList = $resource("data/recent-items.json");

            return recentitemList.get ({
                id: id,
                name: name,
                price: price
            })
        }
    }])


    // =========================================================================
    // Recent Posts Widget Data
    // =========================================================================

    .service('recentpostService', ['$resource', function($resource){
        this.getRecentpost = function(img, user, text) {
            var recentpostList = $resource("data/messages-notifications.json");

            return recentpostList.get ({
                img: img,
                user: user,
                text: text
            })
        }
    }])

    // =========================================================================
    // Data Table
    // =========================================================================

//    .service('tableService', [function(){
//        this.data = {
//            "hasMore": false,
//            "result": [
//                {
//                    "id": 1604169919,
//                    "accountId": "1012927152",
//                    "payeeName": "",
//                    "payeeAddress1": "",
//                    "toAccountNumber": "3061153903179275",
//                    "name": "azra opci",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 1604169935,
//                    "accountId": "1012927152",
//                    "formType": "8889",
//                    "payeeName": "",
//                    "payeeAddress1": "",
//                    "toAccountNumber": "1340100000001672",
//                    "name": "Hamobaksuz",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 2000000257,
//                    "accountId": "1015276919",
//                    "formType": "5050",
//                    "payeeName": "PRIMATELJ N",
//                    "payeeAddress1": "",
//                    "toAccountNumber": "3061153903179275",
//                    "amount": 22,
//                    "name": "uzorak n",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 2000000262,
//                    "accountId": "1000006406",
//                    "formType": "8889",
//                    "payeeName": "PPPPP",
//                    "payeeAddress1": "DDDDD",
//                    "toAccountNumber": "3061153700162931",
//                    "amount": 333,
//                    "name": "opci n",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100000621,
//                    "accountId": "289868642",
//                    "formType": "5050",
//                    "payeeName": "ydfdsf",
//                    "payeeAddress1": "",
//                    "toAccountNumber": "BA393061123785732695",
//                    "amount": 120,
//                    "name": "uzorak",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001094,
//                    "accountId": "289868642",
//                    "formType": "5050",
//                    "payeeName": "ydfdsf",
//                    "payeeAddress1": "",
//                    "toAccountNumber": "BA393061123785732695",
//                    "amount": 120,
//                    "name": "Int",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001109,
//                    "accountId": "1012927152",
//                    "formType": "8889",
//                    "payeeName": "GGGGG",
//                    "payeeAddress1": "JJJJJ",
//                    "toAccountNumber": "3061153903179275",
//                    "amount": 10,
//                    "name": "neki",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001710,
//                    "accountId": "278547259",
//                    "formType": "8889",
//                    "payeeName": "FFFFFFFFFFFF",
//                    "payeeAddress1": "GGGGGGGGGGGGG",
//                    "toAccountNumber": "3061153903179275",
//                    "amount": 22333,
//                    "name": "grickavac novi",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001725,
//                    "accountId": "1012927152",
//                    "formType": "8889",
//                    "payeeName": "GGGGG",
//                    "payeeAddress1": "JJJJJ",
//                    "toAccountNumber": "3061153903179275",
//                    "amount": 10,
//                    "name": "mrn",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001728,
//                    "accountId": "278547259",
//                    "formType": "8889",
//                    "payeeName": "FFFFFFFFFFFF",
//                    "payeeAddress1": "GGGGGGGGGGGGG",
//                    "toAccountNumber": "3061153903179275",
//                    "amount": 22333,
//                    "name": "kod",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100001736,
//                    "accountId": "1012927152",
//                    "formType": "8888",
//                    "payeeName": "WQ",
//                    "payeeAddress1": "WE",
//                    "toAccountNumber": "1340100000001672",
//                    "amount": 120,
//                    "name": "uzorak",
//                    "payeeCity": "",
//                    "taxPayerId": "9999999999999",
//                    "revenuePaymentType": "0",
//                    "revenueCode": "000000",
//                    "municipality": "000",
//                    "reference": "0000000001",
//                    "budgetOrganization": "0000000",
//                    "taxPeriodFrom": "2014-05-30T00:00:00+02:00",
//                    "taxPeriodTo": "2014-05-30T00:00:00+02:00"
//                },
//                {
//                    "id": 9100002918,
//                    "accountId": "1012927152",
//                    "formType": "8889",
//                    "payeeName": "ORC 2 TEST",
//                    "payeeAddress1": "INCIDENT 6622",
//                    "toAccountNumber": "3060190000395672",
//                    "name": "asdadas",
//                    "payeeCity": "",
//                    "revenueCode": "      "
//                },
//                {
//                    "id": 9100002921,
//                    "accountId": "1012927152",
//                    "formType": "8888",
//                    "payeeName": "WQ",
//                    "payeeAddress1": "WE",
//                    "toAccountNumber": "1340100000001672",
//                    "name": "samra",
//                    "payeeCity": "",
//                    "taxPayerId": "9999999999999",
//                    "revenuePaymentType": "0",
//                    "revenueCode": "000000",
//                    "municipality": "000",
//                    "reference": "0000000001",
//                    "budgetOrganization": "0000000",
//                    "taxPeriodFrom": "2014-05-30T00:00:00+02:00",
//                    "taxPeriodTo": "2014-05-30T00:00:00+02:00"
//                }
//            ]
//        }
//        ;
        /*this.data = [
            {
                "id": 10238,
                "name": "Marc Barnes",
                "email": "marc.barnes54@example.com",
                "username": "MarcBarnes",
                "contact": "(382)-122-5003"
            },
            {
                "id": 10243,
                "name": "Glen Curtis",
                "email": "glen.curtis11@example.com",
                "username": "GlenCurtis",
                "contact": "(477)-981-4948"
            },
            {
                "id": 10248,
                "name": "Beverly Gonzalez",
                "email": "beverly.gonzalez54@example.com",
                "username": "BeverlyGonzalez",
                "contact": "(832)-255-5161"
            },
            {
                "id": 10253,
                "name": "Yvonne Chavez",
                "email": "yvonne.chavez@example.com",
                "username": "YvonneChavez",
                "contact": "(477)-446-3715"
            },
            {
                "id": 10234,
                "name": "Melinda Mitchelle",
                "email": "melinda@example.com",
                "username": "MelindaMitchelle",
                "contact": "(813)-716-4996"

            },
            {
                "id": 10239,
                "name": "Shannon Bradley",
                "email": "shannon.bradley42@example.com",
                "username": "ShannonBradley",
                "contact": "(774)-291-9928"
            },
            {
                "id": 10244,
                "name": "Virgil Kim",
                "email": "virgil.kim81@example.com",
                "username": "VirgilKim",
                "contact": "(219)-181-7898"
            },
            {
                "id": 10249,
                "name": "Letitia Robertson",
                "email": "letitia.rober@example.com",
                "username": "Letitia Robertson",
                "contact": "(647)-209-4589"
            },
            {
                "id": 10237,
                "name": "Claude King",
                "email": "claude.king22@example.com",
                "username": "ClaudeKing",
                "contact": "(657)-988-8701"
            },
            {
                "id": 10242,
                "name": "Roland Craig",
                "email": "roland.craig47@example.com",
                "username": "RolandCraig",
                "contact": "(932)-935-9471"
            },
            {
                "id": 10247,
                "name": "Colleen Parker",
                "email": "colleen.parker38@example.com",
                "username": "ColleenParker",
                "contact": "(857)-459-2792"
            },
            {
                "id": 10252,
                "name": "Leah Jensen",
                "email": "leah.jensen27@example.com",
                "username": "LeahJensen",
                "contact": "(861)-275-4686"
            },
            {
                "id": 10236,
                "name": "Harold Martinez",
                "email": "martinez67@example.com",
                "username": "HaroldMartinez",
                "contact": "(836)-634-9133"
            },
            {
                "id": 10241,
                "name": "Keith Lowe",
                "email": "keith.lowe96@example.com",
                "username": "KeithLowe",
                "contact": "(778)-787-3100"
            },
            {
                "id": 10246,
                "name": "Charles Walker",
                "email": "charles.walker90@example.com",
                "username": "CharlesWalker",
                "contact": "(486)-440-4716"
            },
            {
                "id": 10251,
                "name": "Lillie Curtis",
                "email": "lillie.curtis12@example.com",
                "username": "LillieCurtis",
                "contact": "(342)-510-2258"
            },
            {
                "id": 10235,
                "name": "Genesis Reynolds",
                "email": "genesis@example.com",
                "username": "GenesisReynolds",
                "contact": "(339)-375-1858"
            },
            {
                "id": 10240,
                "name": "Oscar Palmer",
                "email": "oscar.palmer24@example.com",
                "username": "OscarPalmer",
                "contact": "(544)-270-9912"
            },
            {
                "id": 10245,
                "name": "Lena Bishop",
                "email": "Lena Bishop",
                "username": "LenaBishop",
                "contact": "(177)-521-1556"
            },
            {
                "id": 10250,
                "name": "Kent Nguyen",
                "email": "kent.nguyen34@example.com",
                "username": "KentNguyen",
                "contact": "(506)-533-6801"
            }
        ];*/
//    }])


    // =========================================================================
    // Malihu Scroll - Custom Scroll bars
    // =========================================================================
    .service('scrollService', function() {
        var ss = {};
        ss.malihuScroll = function scrollBar(selector, theme, mousewheelaxis) {
            $(selector).mCustomScrollbar({
                theme: theme,
                scrollInertia: 100,
                axis:'yx',
                mouseWheel: {
                    enable: true,
                    axis: mousewheelaxis,
                    preventDefault: true
                }
            });
        }

        return ss;
    })


    //==============================================
    // BOOTSTRAP GROWL
    //==============================================

    .service('growlService', function(){
        var gs = {};
        gs.growl = function(message, type) {
            $.growl({
                message: message
            },{
                type: type,
                allow_dismiss: true,
                label: 'Cancel',
                className: 'btn-xs btn-inverse',
                placement: {
                    from: 'top',
                    align: 'right'
                },
                delay: 2500,
                animate: {
                        enter: 'animated bounceIn',
                        exit: 'animated bounceOut'
                },
                offset: {
                    x: 20,
                    y: 85
                }
            });
        }

        return gs;
    })

}());
