(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name alert.controller:AlertCtrl
   *
   * @description
   *
   */
  angular
    .module('alert')
    .controller('AlertCtrl', AlertCtrl);

  function AlertCtrl() {
    var vm = this;
    vm.ctrlName = 'AlertCtrl';
  }
}());
