<section id="main">
  <div class="container-fluid">

    <!--HEADER START-->
    <viber-header></viber-header>
    <!--HEADER END-->

    <!-- use for all countries except SRB -->
    <div ng-if="!APP_GLOBAL_SETTINGS.defaultData.ViberSmsActivation" class="row">
      <div class="col-sm-12 t-a-c">
        <p class="m-t-10 f-16" translate="viber.deactivateText">Vaš nalog je trenutno aktivan</p>
        <div class="col-sm-12">
          <button class="btn btn-primary" type="submit" translate="viber.deactivate"
                  ng-click="viberDeactivate.deactivateViber();">
            Deaktiviraj
          </button>
        </div>
      </div>
    </div>

    <!-- use only for SRB environment -->
    <div class="row p-t-15" ng-if="APP_GLOBAL_SETTINGS.defaultData.ViberSmsActivation">
      <div class="col-sm-offset-1 col-sm-10">
        <div class="row">
          <div class="col-xs-12 t-a-c">
            <h4 translate="viber.activeMessage"></h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="table-responsive table-blue-border">
              <table class="table table-striped">
                <tr>
                  <td class="po-desc-label" translate="viber.mobileNumber"></td>
                  <td class="po-desc-label">{{viberDeactivate.gsm}}</td>
                </tr>
                <tr>
                <td class="po-desc-label" translate="viber.activationDate"></td>
                <td class="po-desc-label" >{{viberDeactivate.date | date: 'short'}}.</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="row p-t-10">
          <div class="col-xs-12 t-a-c">
            <button class="btn btn-default" translate="viber.deactivate" ng-click="viberDeactivate.deactivateViber();">
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
