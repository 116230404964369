<section id="main">
  <div class="container-fluid">
    <div class="row domp-header-wrapper">
      <div class="domp-header-row col-md-12">
        <h2 ng-if="investment.model.orderType == 1" class="intp-title" translate="investOverview.buyStocks">Buy stocks</h2>
        <h2 ng-if="investment.model.orderType == 2" class="intp-title" translate="investOverview.sellStocks">Sell stock</h2>
        <h2 ng-if="investment.model.orderType == 3" class="intp-title" translate="investOverview.replaceStocks">Replace stock</h2>
      </div>
    </div>
    <!--START: DISCLAIMER-->
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info" style="background: white; color: black; border: 1px solid lightgray" role="alert">
          <p translate="invest.info"></p>
          <p translate="invest.info1"></p>
        </div>
      </div>
    </div>
    <!--END: DISCLAIMER-->
    <div class="m-b-30">
      <ui-view/>
    </div>
  </div>
</section>
