<div class="currency-conversion-msg">
  <div class="app-white-card">
    <div class="row">
      <div class="col-sm-1">
        <div class="currency-check-icon">
          <i class="zmdi zmdi-check zmdi-hc-3x"></i>
        </div>
      </div>
      <div class="col-sm-11">
        <h3 translate="currencyConversion.moneyExchangeComplete"></h3>
        <div class="f-16">
          <span translate="currencyConversion.successfullyExchange"></span>
          <span>
            {{currencyConversion.paymentResponse.amount}}
            {{currencyConversion.paymentResponse.currencySymbol}}
          </span>
          <span translate="payments.fromAcc">from</span>
          <span>{{currencyConversion.paymentResponse.fromAccountNumber}}</span>
          <span translate="payments.toAcc">to</span>
          <span>{{currencyConversion.paymentResponse.toAccountNumber}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-t-20">
    <div class="col-sm-6">
      <a href="" data-ng-click="currencyConversion.clearInput();">
        <span class="money-exchange-icon"><i class="zmdi zmdi-money-box zmdi-hc-2x"></i></span>
        <span class="f-14 bold-font" translate="currencyConversion.makeAnotherExchange"></span>
      </a>
    </div>
    <div class="col-sm-6">
      <a href="" data-ui-sref="payments.paymentsAndTransferOverview">
        <span class="money-exchange-icon"><i class="zmdi zmdi-money-box zmdi-hc-2x"></i></span>
        <span class="f-14 bold-font" translate="currencyConversion.goToPayments"></span>
      </a>
    </div>
  </div>
</div>
