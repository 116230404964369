(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      paymentTemplates: {
        "payingFrom": "Plačilo z:",
        "payingTo": "Plačilo na:",
        "howMuch": "Znesek",
        "description": "Opis",
        "when": "Datum",
        "fullPaymentForm": "Prikaži cel plačilni nalog",
        "showFullPaymentForm": "Prikaži cel plačilni nalog",
        "pay": "Plačaj",
        "delete": "Brisanje",
        "save": "Shrani",
        "signPayment": "Podpiši",
        "cancel": "Prekliči",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Naslov banke",
        "address": "Naslov prejemnika plačila",
        "dueDate": "Rok plačila",
        "fromAccount": "Račun plačnika",
        "toAccount": "Račun prejemnika",
        "receiver": "Prejemnik",
        "receiverAddress": "Naslov prejemnika",
        "amount": "Znesek",
        "signMsg": "Preverite vnesene podatke in potrdite",
        "saveTemplate": "Nova predloga",
        "invalidDate": "Datum je neveljaven!",
        "descriptionError": "Vnos opisa je obvezen!",
        "templateName": "Naziv predloge",
        "templateIcon": "Ikona predloge",
        "deleteSuccess1": "Predloga",
        "deleteSuccess2": "je izbrisana."
      },
      templatesList: {
        "header":"Pregled predlog",
        "searchTemplates": "Išči predlogo",
        "addNewTemplate": "+ Dodaj novo predlogo",
        "listOfTemplates":"Seznam predlog",
        "fromAccount":"Z računa",
        "amount":"Znesek",
        "toPayee":"Prejemnik",
        "accountNumber":"Številka računa",
        "payeeStreet":"Naslov prejemnika",
        "payeeCity":"Kraj prejemnika",
        "purposeCode":"Namen",
        "referenceModel":"Model",
        "referenceNumber":"Referenca",
        "addTemplateToFavorites":"Dodaj predlogo med priljubljene",
        "edit":"Spremeni",
        "save":"Shrani",
        "cancel": "Prekliči",
        "bicBankPrejemnika": "BIC banke prejemnika",
        "pay":"Plačaj",
        "maxLengthReached":"Dosegel največje število znakov!"
      }
    };

    $translateProvider
      .translations('sl', data);
  }
}());
