(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:HelperService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('NotificationService', NotificationService);

  function NotificationService(Notification, $rootScope) {

    var NotificationServiceBase = {};

    // Messages from API
    NotificationServiceBase.showMessage = function (error, type, replaceMessage, maxCount, duration) {
      if (error && error.resultList && error.resultList.length > 0) {

        var textLength = 1000;
        var text = error.resultList[0].description;
        if (text.length >= textLength) {
          text = text.substring(0, textLength);
        }

        //Default values from NotificationProvider
        var replaceMessageTemp = true;
        if (replaceMessage)
          replaceMessageTemp = false;

        var maxCountTemp = 1;
        if (maxCount)
          maxCountTemp = maxCount;

        switch (type) {
          case "error":
            Notification.error({
              message: text,
              templateUrl: "core/components/error-alert-template.tpl.html",
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp,
              delay: $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr' ? 7000 :  duration ? duration : 2000
            });
            break;
          case "warning":
            Notification.warning({
              message: text,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          case "success":
            Notification.success({
              message: text,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          default:
            Notification.info({
              message: text,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
        }
      }
    };

    // Already translated messages, just show text
    NotificationServiceBase.showTranslatedMessage = function (type, text, title, delay, replaceMessage, maxCount) {
      if (text) {
        // var text = $translate.instant(errorToTranslate);
        if (text.length >= 200) {
          text = text.substring(0, 200);
        }
        var d = null;
        if (delay) {
          d = delay;
        }

        //Default values from NotificationProvider
        var replaceMessageTemp = true;
        if (replaceMessage)
          replaceMessageTemp = false;

        var maxCountTemp = 1;
        if (maxCount)
          maxCountTemp = maxCount;

        switch (type) {
          case "error":
            Notification.error({
              message: text,
              templateUrl: "core/components/error-alert-template.tpl.html",
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          case "warning":
            Notification.warning({
              message: text,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          case "success":
            Notification.success({
              message: text,
              delay: d,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          case "info":
            Notification.info({
              message: text,
              templateUrl: "core/components/error-alert-template.tpl.html",
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
          default:
            Notification.info({
              message: text,
              replaceMessage: replaceMessageTemp,
              maxCount: maxCountTemp
            });
            break;
        }
      }
    };

    return NotificationServiceBase;
  }
}());
