<!--------------------------- CRO --------------------------->
<div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" class="intp-form-container bottom-border">
  <!-- START NEW -->
  <div class="receiver-section p-10" >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.fromAccount">
            From account
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.fromAccountNumber}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.fromAccountNumber}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.totalAmount">
            Total amount and valute
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.amount| number:2}} {{internationalPayment.paymentResponse.currencySymbol}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.amount}} {{internationalPayment.paymentResponse.currencySymbol}}"-->
          <!--readonly>-->
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" ng-show="false">
          <uib-accordion close-others="true" class="domp-sepa-accordion">
            <div uib-accordion-group>
              <uib-accordion-heading>
                <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                    <label class="domp-form-label-review-sepa"
                           translate="internationalPayment.sepaDebitorLabel">
                    </label>
                  </div>
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                      class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                </div>
              </uib-accordion-heading>
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput1">
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput2">
            </div>
          </uib-accordion>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentDate">
            Payment date
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.dueDate| date: 'shortDate' : '+0200'}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10" >
    <div class="row m-t-10">
      <div class="col-md-6">
        <div class="form-group" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
          <label ng-switch-default class="domp-form-label-review" translate="internationalPayment.toAccountInputLabel">
          </label>
          <label ng-switch-when="sl" class="domp-form-label-review" translate="internationalPayment.toAccountInputLabel_SLO">
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.toAccountNumber}}
          </label>
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverCountry}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.toAccountNumber}}"-->
          <!--readonly>-->
        </div>
      </div>


      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.toPayee2">
            To payee
          </label>
          <label ng-if="internationalPayment.paymentResponse.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverName}}
          </label>

          <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            <label class="domp-form-label-resume">
              {{internationalPayment.paymentResponse.foreignPayment.receiverAddress1}}
            </label>

            <label class="domp-form-label-resume">
              {{internationalPayment.paymentResponse.foreignPayment.receiverCity}}
            </label>
          </span>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.receiverName}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-6">
        <div class="form-group" ng-show="false">
          <uib-accordion close-others="true" class="domp-sepa-accordion">
            <div uib-accordion-group>
              <uib-accordion-heading>
                <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                    <label class="domp-form-label-review-sepa"
                           translate="internationalPayment.sepaDebitorLabel">
                    </label>
                  </div>
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                      class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                </div>
              </uib-accordion-heading>
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput1">
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput2">
            </div>
          </uib-accordion>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label ng-if="internationalPayment.paymentResponse.receiverAddress1" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverAddress1}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverAddress1" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverAddress1}}
          </label>
        </div>
      </div>
    </div>
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-6 col-md-offset-6">
        <div class="form-group">
          <label ng-if="internationalPayment.paymentResponse.receiverCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverCity}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverCity}}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.purposeCode">
            Purpose code
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purposeCodeValue}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.purposeCodeValue}}"-->
          <!--readonly>-->
        </div>
      </div>

      <div class="col-md-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
        <div class="form-group">
          <label class="domp-form-label-review"  translate="internationalPayment.paymentMode">
            Payment mode
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.modalityCode}}
          </label>
          <!--<select class="form-control input-field p-l-15 p-r-10" style="cursor: auto"-->
          <!--ng-options="item.id as (item.id + ' - ' + item.description) for item in internationalPayment.paymentModeOptions"-->
          <!--ng-model="internationalPayment.paymentResponse.modalityCode"-->
          <!--disabled>-->
          <!--</select>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentPurpose">
            Payment purpose
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purpose}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.purpose}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10" >
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <div class="col-md-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentPurpose">
            Payment purpose
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purpose}}
          </label>
        </div>
      </div>
    </div>

    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <div class="col-md-4">
        <label class="domp-form-label-review" translate="payments.bicNumber_SLO"></label>
        <label class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode}}
        </label>
      </div>
      <div class="col-md-4">
        <label class="domp-form-label-review" translate="payments.bicCountry"></label>
        <label class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode}} - {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountry}}
        </label>
      </div>
    </div>

    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicAddress">
            BIC Address
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiarySWIFTCode}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.beneficiarySWIFTCode}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicCountry">
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankCountryCode}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode}}
          </label>
          <!--<select class="form-control input-field p-l-15 p-r-10" style="cursor: auto"-->
          <!--ng-options="country.code as (country.code + '-' + country.description) for country in internationalPayment.countriesOptions"-->
          <!--ng-model="internationalPayment.paymentResponse.beneficiaryBankCountryCode"-->
          <!--disabled>-->
          <!--</select>-->
        </div>
      </div>
      <div class="col-md-4" >
        <label class="domp-form-label-review" translate="internationalPayment.chargeAccount">Charge account</label>
        <label ng-if="internationalPayment.paymentResponse.chargeAccount" class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.chargeAccount}}
        </label>
        <label ng-if="internationalPayment.paymentResponse.foreignPayment.chargeAccount" class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.chargeAccount}}
        </label>
        <!--<input type="text" class="form-control input-field"-->
        <!--name="chargeAccount"-->
        <!--data-ng-model="internationalPayment.paymentResponse.chargeAccount"-->
        <!--readonly>-->
      </div>
    </div>

    <div class="row m-t-10">
      <div class="col-xs-8">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankName">Bank Name</label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.beneficiaryBankName}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
        <div class="form-group">
          <label class="domp-form-label-review"  translate="payments.bicRecipientStatus">Receiver (person)</label>
          <label class="domp-form-label-resume">
            <div ng-switch="internationalPayment.paymentResponse.payeeLegalForm">
              <div ng-switch-when="1">
                {{"payments.bicRecipientStatusOptionLegal"| translate}}
              </div>
              <div ng-switch-when="2">
                {{"payments.bicRecipientStatusOptionPhysical"| translate}}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="row m-t-10">
      <div class="col-md-4">
        <div class="form-group">
           <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-review" translate="payments.bicAddress">BIC Address</label>
          <label class="domp-form-label-review" > </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankAddress" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.beneficiaryBankAddress}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
           <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-review" translate="payments.bicCity">BIC City</label>
          <label class="domp-form-label-review" > </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCity" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.beneficiaryBankCity}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankCharges">Troškovna opcija:</label>
          <label class="domp-form-label-resume">
            <div ng-switch="internationalPayment.paymentResponse.paymentCharges">
              <div ng-switch-when="3">
                SHA
              </div>
              <div ng-switch-when="1">
                OUR
              </div>
              <div ng-switch-when="2">
                BEN
              </div>
            </div>
            <a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume"></a>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div ng-if="!internationalPayment.paymentResponse.beneficiarySWIFTCode && !internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode" class="msg-ctn m-t-10 p-10" translate="internationalPayment.noBicBankInfo"></div>

</div>
<!-- START: Buttons -->
<div class="domp-footer-wrapper" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
  <div class="row">
    <div class="col-md-10 form-group">
      <button class="btn btn-primary " translate="currencyConversion.back"
              data-ng-click="internationalPayment.newPayment();">
      </button>
      <button class="btn btn-primary-warning"
              translate="domesticPayment.delete"
              ng-click="internationalPayment.deletePayment();">
        Delete
      </button>
      <button class="btn btn-primary "
              translate="domesticPayment.edit"
              ng-click="internationalPayment.editPayment();">
        Edit
      </button>
      <!--      <button class="btn btn-primary app-btn-blue domp-resume-btn pull-right"
                    ng-click="internationalPayment.signPayment();">
              Sign
            </button>-->
    </div>
    <div class="col-md-2 form-group">
      <button class="btn btn-default"
              translate="domesticPayment.sign"
              data-ui-sref="payments.sign({paymentId: internationalPayment.paymentResponse.id, type: 'international'})">
        Sign
      </button>
    </div>
  </div>
</div>
<!-- END: Buttons-->
<!--------------------------- END CRO --------------------------->

<!--------------------------- MNE --------------------------->
<div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne'" class="intp-form-container bottom-border">
  <!-- START NEW -->
  <div class="receiver-section p-10" >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.fromAccount">
            From account
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.fromAccountNumber}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.fromAccountNumber}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.totalAmount">
            Total amount and valute
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.amount| number:2}} {{internationalPayment.paymentResponse.currencySymbol}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.amount}} {{internationalPayment.paymentResponse.currencySymbol}}"-->
          <!--readonly>-->
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" ng-show="false">
          <uib-accordion close-others="true" class="domp-sepa-accordion">
            <div uib-accordion-group>
              <uib-accordion-heading>
                <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                    <label class="domp-form-label-review-sepa"
                           translate="internationalPayment.sepaDebitorLabel">
                    </label>
                  </div>
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                    class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                </div>
              </uib-accordion-heading>
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput1">
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput2">
            </div>
          </uib-accordion>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentDate">
            Payment date
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.dueDate| date: 'shortDate' : '+0200'}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
  </div>


  <div class="receiver-section p-10">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="domp-form-label" translate="internationalPayment.paymentPurposeReceiverInfo">
            Payment purpose - Receiver Info
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.memo}}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10" >
    <div class="row m-t-10">
      <div class="col-md-6">
        <div class="form-group" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
          <label ng-switch-default class="domp-form-label-review" translate="internationalPayment.toAccountInputLabel">
          </label>
          <label ng-switch-when="sl" class="domp-form-label-review" translate="internationalPayment.toAccountInputLabel_SLO">
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.toAccountNumber}}
          </label>
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverCountry}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.toAccountNumber}}"-->
          <!--readonly>-->
        </div>
      </div>


      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.toPayee2">
            To payee
          </label>
          <label ng-if="internationalPayment.paymentResponse.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverName}}
          </label>

          <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
            <label class="domp-form-label-resume">
              {{internationalPayment.paymentResponse.foreignPayment.receiverAddress1}}
            </label>

            <label class="domp-form-label-resume">
              {{internationalPayment.paymentResponse.foreignPayment.receiverCity}}
            </label>
          </span>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.receiverName}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-6">
        <div class="form-group" ng-show="false">
          <uib-accordion close-others="true" class="domp-sepa-accordion">
            <div uib-accordion-group>
              <uib-accordion-heading>
                <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                    <label class="domp-form-label-review-sepa"
                           translate="internationalPayment.sepaDebitorLabel">
                    </label>
                  </div>
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                    class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                </div>
              </uib-accordion-heading>
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput1">
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput2">
            </div>
          </uib-accordion>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label ng-if="internationalPayment.paymentResponse.receiverAddress1" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverAddress1}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverAddress1" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverAddress1}}
          </label>
        </div>
      </div>
    </div>
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-6 col-md-offset-6">
        <div class="form-group">
          <label ng-if="internationalPayment.paymentResponse.receiverCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverCity}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverCity}}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.purposeCode">
            Purpose code
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purposeCodeValue}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.purposeCodeValue}}"-->
          <!--readonly>-->
        </div>
      </div>

      <div class="col-md-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
        <div class="form-group">
          <label class="domp-form-label-review"  translate="internationalPayment.paymentMode">
            Payment mode
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.modalityCode}}
          </label>
          <!--<select class="form-control input-field p-l-15 p-r-10" style="cursor: auto"-->
          <!--ng-options="item.id as (item.id + ' - ' + item.description) for item in internationalPayment.paymentModeOptions"-->
          <!--ng-model="internationalPayment.paymentResponse.modalityCode"-->
          <!--disabled>-->
          <!--</select>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentPurpose">
            Payment purpose
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purpose}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.purpose}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-10" >
    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <div class="col-md-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentPurpose">
            Payment purpose
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purpose}}
          </label>
        </div>
      </div>
    </div>

    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
      <div class="col-md-4">
        <label class="domp-form-label-review" translate="payments.bicNumber_SLO"></label>
        <label class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode}}
        </label>
      </div>
      <div class="col-md-4">
        <label class="domp-form-label-review" translate="payments.bicCountry"></label>
        <label class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode}} - {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountry}}
        </label>
      </div>
    </div>

    <div class="row m-t-10" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicAddress">
            BIC Address
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiarySWIFTCode}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.beneficiarySWIFTCode}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicCountry">
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankCountryCode}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode}}
          </label>
          <!--<select class="form-control input-field p-l-15 p-r-10" style="cursor: auto"-->
          <!--ng-options="country.code as (country.code + '-' + country.description) for country in internationalPayment.countriesOptions"-->
          <!--ng-model="internationalPayment.paymentResponse.beneficiaryBankCountryCode"-->
          <!--disabled>-->
          <!--</select>-->
        </div>
      </div>
      <div class="col-md-4" >
        <label class="domp-form-label-review" translate="internationalPayment.chargeAccount">Charge account</label>
        <label ng-if="internationalPayment.paymentResponse.chargeAccount" class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.chargeAccount}}
        </label>
        <label ng-if="internationalPayment.paymentResponse.foreignPayment.chargeAccount" class="domp-form-label-resume">
          {{internationalPayment.paymentResponse.foreignPayment.chargeAccount}}
        </label>
        <!--<input type="text" class="form-control input-field"-->
        <!--name="chargeAccount"-->
        <!--data-ng-model="internationalPayment.paymentResponse.chargeAccount"-->
        <!--readonly>-->
      </div>
    </div>

    <div class="row m-t-10">
      <div class="col-xs-8">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankName">Bank Name</label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.beneficiaryBankName}}"-->
          <!--readonly>-->
        </div>
      </div>
      <div class="col-md-4" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr'">
        <div class="form-group">
          <label class="domp-form-label-review"  translate="payments.bicRecipientStatus">Receiver (person)</label>
          <label class="domp-form-label-resume">
            <div ng-switch="internationalPayment.paymentResponse.payeeLegalForm">
              <div ng-switch-when="1">
                {{"payments.bicRecipientStatusOptionLegal"| translate}}
              </div>
              <div ng-switch-when="2">
                {{"payments.bicRecipientStatusOptionPhysical"| translate}}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="row m-t-10">
      <div class="col-md-4">
        <div class="form-group">
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-review" translate="payments.bicAddress">BIC Address</label>
          <label class="domp-form-label-review" > </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankAddress" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.beneficiaryBankAddress}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="domp-form-label-review" translate="payments.bicCity">BIC City</label>
          <label class="domp-form-label-review" > </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCity" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.beneficiaryBankCity}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity" class="domp-form-label-resume m-t-5">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankCharges">Troškovna opcija:</label>
          <label class="domp-form-label-resume">
            <div ng-switch="internationalPayment.paymentResponse.paymentCharges">
              <div ng-switch-when="3">
                SHA
              </div>
              <div ng-switch-when="2">
                OUR
              </div>
              <div ng-switch-when="1">
                BEN
              </div>
            </div>
            <a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume"></a>
          </label>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- START: Buttons -->
<div class="domp-footer-wrapper" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne'">
  <div class="row">
    <div class="col-md-10 form-group">
      <button class="btn btn-primary " translate="currencyConversion.back"
              data-ng-click="internationalPayment.newPayment();">
      </button>
      <button class="btn btn-primary-warning"
              translate="domesticPayment.delete"
              ng-click="internationalPayment.deletePayment();">
        Delete
      </button>
      <button class="btn btn-primary "
              translate="domesticPayment.edit"
              ng-click="internationalPayment.editPayment();">
        Edit
      </button>
      <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.documentation"
              ng-hide="internationalPayment.countryCode == 'mn' && internationalPayment.paymentTypeGroup === 'ForeignPayment'"
              ng-click="internationalPayment.documentation(internationalPayment.paymentResponse);"
      >Documentation</button>
      <!--      <button class="btn btn-primary app-btn-blue domp-resume-btn pull-right"
                    ng-click="internationalPayment.signPayment();">
              Sign
            </button>-->
    </div>
    <div class="col-md-2 form-group">
      <button class="btn btn-default"
              translate="domesticPayment.sign"
              data-ui-sref="payments.sign({paymentId: internationalPayment.paymentResponse.id, type: 'international'})">
        Sign
      </button>
    </div>
  </div>
</div>
<!-- END: Buttons-->
<!--------------------------- END MNE --------------------------->

<!--------------------------- SLO --------------------------->
<div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="intp-form-container bottom-border">
  <!-- START NEW -->
  <div class="receiver-section p-20" >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.fromAccount">
            From account
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.fromAccountNumber}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.totalAmount">
            Total amount and valute
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.amount| number:2}} {{internationalPayment.paymentResponse.currencySymbol}}
          </label>
        </div>
        <div class="form-group" >
          <label class="domp-form-label-review" translate="internationalPayment.paymentChargesAmount">
            Payment charges amount
          </label>
          <label class="domp-form-label-resume">
            <!--{{internationalPayment.paymentResponse.paymentChargesAmount | currency:""}} {{internationalPayment.paymentResponse.currencySymbol}}-->
            {{internationalPayment.paymentResponse.paymentChargesAmount | currency:""}} {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}
          </label>
        </div>
      </div>

      <div class="col-md-4" ng-if="internationalPayment.showAdditionalData">
        <div class="form-group" ng-if="internationalPayment.paymentResponse.foreignPayment.hasOwnProperty('amountWithCharges')">
          <label class="domp-form-label-review" translate="internationalPayment.SLOconversionChargeAmount">
            Ocenjeni skupni znesek v EUR
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.amountWithCharges| number:2}} {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}
          </label>
        </div>
        <div class="form-group" ng-if="internationalPayment.paymentResponse.foreignPayment.hasOwnProperty('conversionChargeAmount')">
          <label class="domp-form-label-review" translate="internationalPayment.SLOamountWithCharges">
            Nadomestilo za konverzijo
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.conversionChargeAmount | currency:""}} {{APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency}}
          </label>
        </div>
        <div class="form-group" ng-if="internationalPayment.paymentResponse.foreignPayment.hasOwnProperty('conversionRate')">
          <label class="domp-form-label-review" translate="internationalPayment.SLOconversionRate">
            Informativni tečaj za konverzijo
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.conversionRate}}
          </label>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" ng-show="false">
          <uib-accordion close-others="true" class="domp-sepa-accordion">
            <div uib-accordion-group>
              <uib-accordion-heading>
                <div class="sepa-heading input-field" ng-click="internationalPayment.clearSepaPayer();">
                  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-11 p-0">
                    <label class="domp-form-label-review-sepa"
                           translate="internationalPayment.sepaDebitorLabel">
                    </label>
                  </div>
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 icon-sepa pull-right"><i
                    class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></div>
                </div>
              </uib-accordion-heading>
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput1">
              <input type="text" class="form-control input-field"
                     ng-model="internationalPayment.tempForpObj.payersSepaInput2">
            </div>
          </uib-accordion>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentDate">
            Payment date
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.dueDate| date: 'shortDate' : '+0200'}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.dueDate | date: 'shortDate' : '+0200'}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
  </div>

  <div class="receiver-section p-20" >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.toAccountInputLabel">
            Account number
          </label>
          <label class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.toAccountNumber}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverCountryCode" class="domp-form-label-resume">
            {{internationalPayment.tempForpObj.receiversCountry.description}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.toPayee2">
            To payee
          </label>
          <label ng-if="internationalPayment.paymentResponse.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.receiverName}}
          </label>

          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverAddress1" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverAddress1}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.receiverCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.receiverCity}}
          </label>

        </div>
      </div>
    </div>
  </div>


  <div class="receiver-section p-20" >
    <div ng-if="internationalPayment.paymentResponse.purpose" class="row p-b-20">
      <div class="col-xs-12">
        <div class="form-group">
          <label class="domp-form-label-review" translate="internationalPayment.paymentPurpose">Purpose of payment</label>
          <label ng-if="internationalPayment.paymentResponse.purpose" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.purpose}}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicNumber_SLO">
            Swift number
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiarySWIFTCode}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicCountry_SLO">
            Country
          </label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankCountryCode}} {{internationalPayment.paymentResponse.beneficiaryBankCountry}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountryCode}} {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCountry}}
          </label>
        </div>
      </div>

      <div class="col-md-4" ng-if="internationalPayment.showAdditionalData">
        <div class="form-group" >
          <label class="domp-form-label-review" translate="internationalPayment.SLOpaymentAmount">
            Ocenjeni znesek za prejemnika plačila
          </label>
          <label class="domp-form-label-resume">
            <!--{{internationalPayment.paymentResponse.paymentChargesAmount | currency:""}} {{internationalPayment.paymentResponse.currencySymbol}}-->
            {{internationalPayment.paymentResponse.amount | currency:""}} {{internationalPayment.paymentResponse.currencySymbol}}
          </label>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-xs-8">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankName">Bank Name</label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankName}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankName}}
          </label>
          <!--<input type="text" class="form-control input-field"-->
          <!--value="{{internationalPayment.paymentResponse.beneficiaryBankName}}"-->
          <!--readonly>-->
        </div>
      </div>
    </div>
    <div class="row p-t-10 p-b-10">
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicAddress">Bank Address</label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankAddress" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankAddress}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankAddress}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicCity">Bank City</label>
          <label ng-if="internationalPayment.paymentResponse.beneficiaryBankCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.beneficiaryBankCity}}
          </label>
          <label ng-if="internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity" class="domp-form-label-resume">
            {{internationalPayment.paymentResponse.foreignPayment.beneficiaryBankCity}}
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="domp-form-label-review" translate="payments.bicBankCharges">Troškovna opcija:</label>
          <label class="domp-form-label-resume">
            <div ng-switch="internationalPayment.paymentResponse.foreignPayment.paymentCharges">
              <div ng-switch-when="0">
                SHA
              </div>
              <div ng-switch-when="1">
                OUR
              </div>
            </div>
            <a ng-href="{{APP_GLOBAL_SETTINGS.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges" class="domp-form-label-resume">
            </a>
          </label>
        </div>
      </div>
    </div>

    <div class="row p-t-10 p-b-10" ng-if="internationalPayment.showAdditionalData">
      <div class="col-md-12">
        <div class="form-group">
          <i>
            <label style="text-align: justify;" class="domp-form-label-review" translate="internationalPayment.SLOpaymentDisclaimer">Disclaimer</label>
          </i>
        </div>
      </div>
    </div>

  </div>
  <!--<div ng-if="!internationalPayment.paymentResponse.beneficiarySWIFTCode && !internationalPayment.paymentResponse.foreignPayment.beneficiarySWIFTCode"-->
       <!--class="msg-ctn m-t-10 p-20"-->
       <!--translate="internationalPayment.noBicBankInfo"></div>-->

</div>
<!-- START: Buttons -->
<div class="p-20 clearfix"  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">
  <section class="row">
    <div class="col-xs-6 col-sm-2" >
      <button class="btn btn-primary " ng-click="internationalPayment.editPayment();" translate="domesticPayment.back">
        Edit
      </button>
    </div>
    <div class="col-xs-6 col-sm-2" ng-if="internationalPayment.actionLinks.delete">
      <button class="btn btn-primary-warning"ng-click="internationalPayment.deletePayment();"
              translate="domesticPayment.delete">Delete
      </button>
    </div>

    <div class="col-md-2 pull-right">
      <button class="btn btn-default pull-right"
              translate="domesticPayment.confirmBtn"
              ng-disabled="internationalPayment.signPaymentInProgress"
              ng-click="internationalPayment.signOrFinishPayment()"
              >
        Sign
      </button>
    </div>
  </section>
</div>
<!-- END: Buttons-->
<!--------------------------- END SLO --------------------------->
