(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsOverviewCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsOverviewCtrl', PaymentsOverviewCtrl);

  function PaymentsOverviewCtrl($rootScope, $state, $log, HelperService, PaymentsService, $translate, $scope, CurrencyService, $localStorage,
                                 ConfigEndpointService, PaymentSignListService, $uibModal, $window, $stateParams, CTLocalizationService, AccountsService, $http, $sce,$filter, AlertService,
                                GoogleTagManagerHelperService, NotificationService) {
    var vm = this;

    vm.countryCode = $localStorage.code;
    vm.showPaymentId = false;
    vm.selectedTabType  = '';
    vm.signList = [];
    vm.signer = {};
    vm.recaller = {};
    vm.processedPaymentStatuses = ['Processed'];
    vm.localCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
    vm.apiLocation = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.viewName = 'payments_overview';
    vm.dateFromOpen = false;
    vm.dateToOpen = false;
    vm.isCancelationCall = false;
    vm.isCustomDateRange = false;

    vm.getDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
        if (response.APILocation) {
          $localStorage.code = response.APILocation;
          vm.countryCode = $localStorage.code;
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.split = function(input){
      return HelperService.splitReferenceNumber(input);
    };

    vm.init = function () {
      if (vm.countryCode == undefined) {
        vm.getDefaultData();
      }

      vm.getCurrenciesListPage();
      vm.getPaymentsPage(true);

      vm.areAllPaymentsSelected = false;
      vm.areNonePaymentsSelected = true;
    };

    var timeRange = HelperService.getDateRange('thisMonth');

    vm.paymentsFilter = {
      page: 0,
      orderBy: 'dateCreated DESC',
      status: null,
      pageSize: 15,
      currencySymbol: null,
      amountGTE: null,
      amountLTE: null,
      dateCreatedGTE: timeRange.valueDateGTE,
      dateCreatedLTE: timeRange.valueDateLTE,
      statusList: null
    };

    vm.cancelationsFilter = {
      page: 0,
      pageSize: 15,
      cancelationDateGTE: timeRange.valueDateGTE,
      cancelationDateLTE: timeRange.valueDateLTE,
      orderBy : 'cancelationDate desc'
    };
    // in case of BiH info for public revenue
    if(vm.countryCode == "bh"){
      vm.paymentsFilter.includeList = "revenuePaymentDetails";
    }

    vm.paymentsOverviewList = {};

    vm.paymentsOverviewListGrouped = {};
    vm.cancelationsListGrouped = {};

    vm.getPaymentsPage = function (reset) {

      if (reset) {
        vm.paymentsFilter.page = 0;
        vm.cancelationsFilter.page = 0;
        vm.paymentsOverviewListGrouped = {};
      } else {
        vm.paymentsFilter.page += 1;
        vm.cancelationsFilter.page +=1;
      }

      if(!vm.isCancelationCall)
      {
        vm.cancelationsListGrouped={};
        PaymentsService.getPage(vm.paymentsFilter)
          .then(function (response) {
          vm.paymentsOverviewListGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.paymentsOverviewListGrouped,
            newData: response,
            groupFunction: function (item) {
              item.urgencyFlag = item.urgency === '1'?true:false;
              if(vm.selectedTabType == 'processed' || vm.selectedTabType == 'rejected'){
                var date = new Date(item.modificationDate);
              }
              else
                var date = new Date(item.dateCreated);
              // If we want to group only by date component, without hours
              date.setHours(0, 0, 0, 0);
              return date;
            },
            virtualRowFunction: function (e) {
              var virtualRow = {
                isVirtual: true,
                date: new Date(e)
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          });

          $log.debug("paymentsOverviewListGrouped", vm.paymentsOverviewListGrouped);
        })
          .catch(function (error) {
          $log.error(error);
        });
      }else
      {
        vm.paymentsOverviewListGrouped={};
        PaymentsService.getCancelationPage(vm.cancelationsFilter)

          .then(function (response) {
            vm.cancelationsListGrouped = HelperService.mergeRowsForTimeline({
              existingData: vm.cancelationsListGrouped,
              newData: response,
              groupFunction: function (item) {
                var date = new Date(item.cancelationDate);
                // If we want to group only by date component, without hours
                date.setHours(0, 0, 0, 0);
                return date;
              },
              virtualRowFunction: function (e) {
                var virtualRow = {
                  isVirtual: true,
                  date: new Date(e)
                };
                return virtualRow;
              },
              reset: reset,
              reverse: false
            });

            $log.debug("paymentsOverviewListGrouped", vm.paymentsOverviewListGrouped);
          })
          .catch(function (error) {
            $log.error(error);
          });
      }


    };

    vm.loadMorePayments = function () {
      vm.getPaymentsPage(false);

      // Not signed payments code
      vm.areNonePaymentsSelected = false;
      vm.areAllPaymentsSelected = false;
    };

    vm.selectAllPaymentsForSign = function () {
      //_.forEach(vm.paymentsOverviewList.result, function (payment) {
      _.forEach(vm.paymentsOverviewListGrouped.result, function (payment) {
        if (payment.urgency == '2' && $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'hr') {
          return;
        }
        payment.isSelectedForSign = true;
      });
      vm.areAllPaymentsSelected = true;
      vm.areNonePaymentsSelected = false;
    };

    vm.selectNonePaymentsForSign = function () {
      //_.forEach(vm.paymentsOverviewList.result, function (payment) {
      _.forEach(vm.paymentsOverviewListGrouped.result, function (payment) {
        payment.isSelectedForSign = false;
      });
      vm.areNonePaymentsSelected = true;
      vm.areAllPaymentsSelected = false;
    };

    vm.signSelectedPaymentsAction = function () {

      var paymentsToSign = [];
      //_.forEach(vm.paymentsOverviewList.result, function (payment) {
      _.forEach(vm.paymentsOverviewListGrouped.result, function (payment) {
        if (payment.isSelectedForSign && !payment.isVirtual) {
          paymentsToSign.push(payment.id);
        }
      });
      if (paymentsToSign.length > 0) {

        // Create new list of payments
        var paymentSignList = {
          paymentIdList: paymentsToSign
        };
        PaymentSignListService.addPaymentSignList(paymentSignList).then(function (response) {
          $state.go('payments.groupSign', {
            groupId: response.id
          });
        })
          .catch(function (err) {
            NotificationService.showMessage(err, 'error');
          $log.error(err);
        });
      }
    };

    vm.togglePaymentForSign = function ($event) {
      if ($event) {
        $event.stopPropagation();
      }

      // Check are none payments selected
      //vm.areNonePaymentsSelected = _.every(vm.paymentsOverviewList.result, {
      vm.areNonePaymentsSelected = _.every(vm.paymentsOverviewListGrouped.result, {
        'isSelectedForSign': false
      });

      // Check are all payments selected
      //vm.areAllPaymentsSelected = _.every(vm.paymentsOverviewList.result, {
      vm.areAllPaymentsSelected = _.every(vm.paymentsOverviewListGrouped.result, {
        'isSelectedForSign': true
      });
    };

    vm.checkRecallStatus = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.recall"));
      var params = {
        id: payment.id,
        recallStatusCheck: true
      };
      recallPayment(params);

    };

    vm.recallPaymentAction = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.recall"));
      var params = {
        id: payment.id
      };

      if (payment.status === 'InProcessing') {
        recallPayment(params);
      } else {
        PaymentsService.openRecallReasonModal(payment)
          .result.then(function (result) {

          // Add more information to payment object
          if (!result) {
            return;
          }

          // Recall payment with aditional information
          params.additionalInfo = result.additionalInfo;
          params.reasonCode = result.reasonCode;

          recallPayment(params);
        });
      }
    };

    // For Slovenia
    vm.cancelPaymentAction = function (payment) {
      var params = {
        id: payment.id
      };

      if (payment.status === 'InProcessing') {
        recallPayment(params);
      } else {
        PaymentsService.openRecallReasonModal(payment)
          .result.then(function (result) {

          // Add more information to payment object
          if (!result) {
            return;
          }
          recallPayment(params);
        });
      }
    };

    var recallPayment = function (params) {
      return PaymentsService.recallPayment(params)
        .then(function (response) {
          if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
            AlertService.infoAlert({
              text: 'payments.cancelledPaymentMsg'
            })
            $rootScope.$broadcast("paymentInserted");
          }


        vm.getPaymentsPage(true);
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.togglePaymentDetails = function (payment) {
      // If we are closing payment details, no need to load signature list
      if(payment.selected){
        payment.selected = false;
      }else{
        // Load signature list only for payments in these statuses
        if(payment.status === 'Rejected' || payment.status == "Cancelled" || payment.status === 'InProcessing' || payment.status == 'WaitingQueue' || payment.status === 'Processed') {
          vm.signList = [];
          vm.signer = {};
          vm.recaller = {};
          PaymentsService.getSignatureList(payment.id)
            .then(function(response) {
              vm.signList = response.signaturesList;
              angular.extend(payment, response);
              payment.selected = true;
            }).catch (function (error) {
            NotificationService.showMessage(error, 'error');
          });
        //  Othwerwise open payment details without loading signature list
        }else{
          payment.selected = true;
        }
      }
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePaymentAction = function (paymentId) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.delete"));

      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {

            if(vm.isCancelationCall)
            {
              PaymentsService.deleteCancelation(paymentId)
                .then(function (response) {
                  AlertService.deletedPaymentAlert({paymentName: paymentId, type: 'cancelation'})
                    .then(function () {
                      vm.getPaymentsPage(true);
                    });
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            }
            else{
              PaymentsService.deletePayment(paymentId)
                .then(function (response) {
                  AlertService.deletedPaymentAlert({paymentName: paymentId, type: 'payment'})
                    .then(function () {
                      vm.getPaymentsPage(true);
                    });
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            }
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });

    };

    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" ? "all2y" : 'all',
          description: 'core.period.all'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[3];

    function clearPaymentsFilterOnly() {
      vm.paymentsFilter.amountGTE = null;
      vm.paymentsFilter.amountLTE = null;
      vm.currencySelectObject = vm.defaultCurrencySelectObject;
      vm.paymentsFilter.currencySymbol = null;
      vm.paymentsFilter.purposeFTS = "";
      vm.searchFilterExpanded = false;
      vm.paymentsFilter.dateCreatedGTE = "";
      vm.paymentsFilter.dateCreatedLTE = "";
    }

    vm.paymentsPeriodFilter = function (item) {

      if(vm.countryCode === 'sl' ) {
        clearPaymentsFilterOnly()
      }



      vm.selectedPeriod = item;
      vm.isCustomDateRange = false;
      vm.transactionFilterYearDateRangeValid = true;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      if(vm.isCancelationCall)
      {
        vm.cancelationsFilter.cancelationDateGTE = timeRange.valueDateGTE;
        vm.cancelationsFilter.cancelationDateLTE= timeRange.valueDateLTE;
      }
      vm.paymentsFilter.dateCreatedGTE = timeRange.valueDateGTE;
      vm.paymentsFilter.dateCreatedLTE = timeRange.valueDateLTE;



      vm.getPaymentsPage(true);
      GoogleTagManagerHelperService.pushEvent('PaymentsOverview', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

    };


    if ($stateParams.myParam === 'not-signed') {
      vm.paymentsFilter.status = 'Opened';
      vm.paymentsTypeSelected = 5;
    } else if ($stateParams.myParam === 'rejected') {
      vm.paymentsFilter.status = 'Rejected';
      vm.paymentsTypeSelected = 4;
    } else if(vm.countryCode =='sl' && $stateParams.myParam === 'pending'){
      vm.paymentsFilter.statusList = ['InProcessing','WaitingQueue','Opened'];
      vm.paymentsTypeSelected = 3;
    } else {
      if ($stateParams.selected != null) {
        vm.paymentsFilter.status = 'Processed';
        vm.paymentsTypeSelected = 2;
      } else {
        vm.paymentsFilter.status = null;
        vm.paymentsTypeSelected = 1;
      }
    }

    var selectedPaymentsType = "all";
    vm.selectPaymentsType = function (type, translateLabel) {
      if (type == selectedPaymentsType) return;
      selectedPaymentsType = type;
      vm.paymentsFilter.statusList = null;
      vm.paymentsFilter.status = null;
      vm.selectedTabType = type;
      vm.isCancelationCall = false;
      switch (type) {
        case "all":
          vm.paymentsFilter.status = null;
          vm.paymentsTypeSelected = 1;
          vm.paymentsFilter.orderBy = 'dateCreated DESC';
          break;
        case "processed":
          vm.paymentsFilter.statusList = ['Processed','Cancelled'];
          vm.paymentsTypeSelected = 2;
          vm.paymentsFilter.orderBy = 'modificationDate DESC';
          break;
        case "pending":
          if(vm.countryCode == 'sl'){
            vm.paymentsFilter.statusList = ['InProcessing','WaitingQueue','Opened'];
          }else{
            vm.paymentsFilter.statusList = ['InProcessing','WaitingQueue'];
          }
          vm.paymentsTypeSelected = 3;
          vm.paymentsFilter.orderBy = 'modificationDate DESC';
          break;
        case "rejected":
          vm.paymentsFilter.status = 'Rejected';
          vm.paymentsTypeSelected = 4;
          vm.paymentsFilter.orderBy = 'modificationDate DESC';
          break;
        case "not-signed":
          vm.paymentsFilter.status = 'Opened';
          vm.paymentsTypeSelected = 5;
          vm.paymentsFilter.orderBy = 'dateCreated desc';
          break;
        case "cancelations":
          vm.paymentsTypeSelected = null;
          vm.isCancelationCall = true;
          //vm.paymentsFilter.status = 'Cancelations';
          //vm.paymentsTypeSelected = 6;
          vm.cancelationsFilter.orderBy = 'cancelationDate desc';
          break;
        default:
          vm.paymentsFilter.status = null;
          vm.paymentsTypeSelected = 1;
          vm.paymentsFilter.orderBy = 'dateCreated DESC';

      }
      GoogleTagManagerHelperService.pushEvent('PaymentsOverview', 'Tab', $translate.instant(translateLabel));

      clearPaymentsFilter();
      // vm.getPaymentsPage(true);
    };

    var clearPaymentsFilter = function(){
      vm.paymentsFilter.amountGTE = null;
      vm.paymentsFilter.amountLTE = null;
      vm.currencySelectObject = vm.defaultCurrencySelectObject;
      vm.paymentsFilter.currencySymbol = null;
      vm.paymentsFilter.purposeFTS = "";
      vm.searchFilterExpanded = false;
      vm.paymentsFilter.dateCreatedGTE = "";
      vm.paymentsFilter.dateCreatedLTE = "";
      vm.transactionFilterYearDateRangeValid = true;
      vm.paymentsPeriodFilter(vm.selectedPeriod);
    };

    vm.searchFilterExpanded = false;
    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      if(vm.searchFilterExpanded && vm.currenciesList.length === 0){
        vm.getCurrenciesListPage();
      }
    };

    vm.resetPaymentsFilter = function () {
      clearPaymentsFilter();
      //vm.getPaymentsPage(true);
    };

    vm.applyPaymentsFilter = function () {
      if (vm.countryCode !== 'sr') {
        if(!vm.transactionFilterYearDateRangeValid) return;
        if (!vm.paymentsFilter.dateCreatedGTE || !vm.paymentsFilter.dateCreatedLTE) return;
      }
      
      vm.paymentsFilter.currencySymbol = vm.currencySelectObject.symbol;
      $log.debug("paymentsFilter", vm.paymentsFilter);
      vm.getPaymentsPage(true);
      vm.searchFilterExpanded = false;
    };

    vm.currencySelectObject = {};
    vm.currenciesList = [];
    vm.currenciesFilter = {
      pageSize: 1000
    };

    vm.getCurrenciesListPage = function () {

      CurrencyService.getCurrencyPage(vm.currenciesFilter)
        .then(function (response) {
        vm.currenciesList = response.result;

        //var countryIndex = _.findIndex(vm.currenciesList, function (o) {
        //  if (vm.countryCode == 'hr') {
        //    return o.symbol == 'HRK';
        //  } else if (vm.countryCode == 'sr') {
        //    return o.symbol == 'RSD';
        //  }
        //});
          var countryIndex = _.findIndex(vm.currenciesList, function(item){
            return item.symbol == vm.localCurrency;
          });
        vm.currencySelectObject = vm.currenciesList[countryIndex];
        vm.defaultCurrencySelectObject = vm.currenciesList[countryIndex];

        //$log.debug("currenciesList", vm.currenciesList);
      }).catch(function (error) {
        $log.error(error);
      });
    };

    vm.repeatPayment = function (paymentObj, value) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.copy"));

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl" && paymentObj.paymentSubTypeGroup == "NotSupportedPayment") {
       AlertService.infoAlert({
          text: "paymentsOverview.notSupportedPaymentMsg"
        })
        return;
      }

      var stateLink;
      var params = {
        paymentId: paymentObj.id,
        accountId: paymentObj.accountId,
        myParam: 'repeat'
      };

      if(value == 'repeatCancelled'){
        params.myParam = value;
      }


      if(paymentObj.paymentSubTypeGroup && paymentObj.paymentSubTypeGroup == 'DomesticPayment' )
        paymentObj.paymentTypeGroup = paymentObj.paymentSubTypeGroup;

      switch (paymentObj.paymentTypeGroup) {
        case "DomesticPayment":
        case "RevenuePayment":
        case "SEPAPayment":
          stateLink = 'payments.domesticPayment.fromPayment';
          break;
        case "LocalCurrencyTransfer":
          // stateLink = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') ? 'payments.domesticPayment.fromPayment' : 'payments.internalTransfer.fromPayment';
          stateLink = 'payments.internalTransfer.fromPayment';
          break;
        case "CurrencyExchange":
          stateLink = 'payments.currencyConversion.fromPayment';
          break;
        case "ForeignPayment":
          stateLink = 'payments.internationalPayment.fromPayment';
          break;
        case "ForeignCurrencyTransfer":
          stateLink = 'payments.savingsPayIn.fromPayment';
          break;
        case "ESavingPayment":
          stateLink = 'payments.ebookPay.fromPayment';
          break;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
        if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && (paymentObj.toAccountNumber.substring(0, 4) != "SI56" || paymentObj.fromAccountNumber.substring(0, 4) != "SI56")) {
          stateLink = 'payments.internalTransfer.fromPayment';
        } else  if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && paymentObj.toAccountNumber.substring(0, 4) == "SI56") {
          stateLink = 'payments.domesticPayment.fromPayment'
        }

        // Using subtypeGroup
        // if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && paymentObj.paymentSubTypeGroup == "SEPAPayment") {
        //   stateLink = 'payments.domesticPayment.fromPayment';
        // } else if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer"  && paymentObj.paymentSubTypeGroup == "LocalCurrencyTransfer") {
        //   stateLink = 'payments.internalTransfer.fromPayment';
        // }


        if (paymentObj.paymentTypeGroup == "ForeignCurrencyTransfer") {
          stateLink = 'payments.internalTransfer.fromPayment';
        }
      }


      if (stateLink) {
        $state.go(stateLink, params);
      }
    };

    vm.documentation = function (paymentObj) {
      var stateLink = 'payments.internationalPayment.documentationPayment';
      var params = {
        paymentId: paymentObj.id
      };
      if (stateLink) {
        $state.go(stateLink, params);
      }
    };

    vm.cancelationOnPayment = function (paymentObj, value) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.copy"));

      var stateLink;
      var params = {
        paymentId: paymentObj.id,
        accountId: paymentObj.accountId,
        editable: true,
        myParam: 'cancelation'
      };

      if(paymentObj.paymentSubTypeGroup && paymentObj.paymentSubTypeGroup == 'DomesticPayment' )
        paymentObj.paymentTypeGroup = paymentObj.paymentSubTypeGroup;

      switch (paymentObj.paymentTypeGroup) {
        case "DomesticPayment":
          stateLink = 'payments.instantPayment.fromPayment';
          break;
      }

      if (stateLink) {
        $state.go(stateLink, params);
      }
    };

    vm.changePaymentAction = function (paymentObj) {
      var stateLink;
      var params = {
        paymentId: paymentObj.id,
        accountId: paymentObj.accountId,
        myParam: 'changeProcessed'
      };

      if(paymentObj.paymentSubTypeGroup && paymentObj.paymentSubTypeGroup == 'DomesticPayment' )
        paymentObj.paymentTypeGroup = paymentObj.paymentSubTypeGroup;

      switch (paymentObj.paymentTypeGroup) {
        case "DomesticPayment":
        case "RevenuePayment":
        case "SEPAPayment":
          stateLink = 'payments.domesticPayment.fromPayment';
          break;
        case "LocalCurrencyTransfer":
          // stateLink = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') ? 'payments.domesticPayment.fromPayment' : 'payments.internalTransfer.fromPayment';
          stateLink = 'payments.internalTransfer.fromPayment';
          break;
        case "CurrencyExchange":
          stateLink = 'payments.currencyConversion.fromPayment';
          break;
        case "ForeignPayment":
          stateLink = 'payments.internationalPayment.fromPayment';
          break;
        case "ForeignCurrencyTransfer":
          stateLink = 'payments.savingsPayIn.fromPayment';
          break;
        case "ESavingPayment":
          stateLink = 'payments.ebookPay.fromPayment';
          break;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
        if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && (paymentObj.toAccountNumber.substring(0, 4) != "SI56" || paymentObj.fromAccountNumber.substring(0, 4) != "SI56")) {
          stateLink = 'payments.internalTransfer.fromPayment';
        } else  if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && paymentObj.toAccountNumber.substring(0, 4) == "SI56") {
          stateLink = 'payments.domesticPayment.fromPayment'
        }

        if (paymentObj.paymentTypeGroup == "ForeignCurrencyTransfer") {
          stateLink = 'payments.internalTransfer.fromPayment';
        }
      }

      if (stateLink) {
        $state.go(stateLink, params);
      }
    };



    vm.editPayment = function (paymentObj) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.edit"));

      if(vm.isCancelationCall)
      {
        var stateLink;
        var params = {
          paymentId: paymentObj.payment.id,
          accountId: paymentObj.payment.accountId,
          cancelationId: paymentObj.id,
          editable: true,
          myParam: 'cancelation'
        };

        switch (paymentObj.payment.paymentTypeGroup) {
          case "DomesticPayment":
            stateLink = 'payments.instantPayment.edit';
            break;
        }

        if (stateLink) {
          $state.go(stateLink, params);
        }
      }
      else{
        var stateLink;
        var params = {
          paymentId: paymentObj.id,
          editable: true,
          accountId: paymentObj.accountId,
          myParam: 'edit'
        };

        if(paymentObj.paymentSubTypeGroup && paymentObj.paymentSubTypeGroup == 'DomesticPayment' )
          paymentObj.paymentTypeGroup = paymentObj.paymentSubTypeGroup;
        switch (paymentObj.paymentTypeGroup) {
          case "DomesticPayment":
          case "RevenuePayment":
            stateLink = 'payments.domesticPayment.fromPayment';
            break;
          case "LocalCurrencyTransfer":
            // stateLink = ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') ? 'payments.domesticPayment.fromPayment' : 'payments.internalTransfer.fromPayment';
            stateLink = 'payments.internalTransfer.fromPayment';
            break;
          case "CurrencyExchange":
            stateLink = 'payments.currencyConversion.fromPayment';
            break;
          case "ForeignPayment":
            stateLink = 'payments.internationalPayment.fromPayment';
            break;
          case "ForeignCurrencyTransfer":
            stateLink = 'payments.savingsPayIn.fromPayment';
            break;
          case "ESavingPayment":
            stateLink = 'payments.ebookPay.fromPayment';
            break;
        }

        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
          if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && (paymentObj.toAccountNumber.substring(0, 4) != "SI56" || paymentObj.fromAccountNumber.substring(0, 4) != "SI56")) {
            stateLink = 'payments.internalTransfer.fromPayment';
          } else  if (paymentObj.paymentTypeGroup == "LocalCurrencyTransfer" && paymentObj.toAccountNumber.substring(0, 4) == "SI56") {
            stateLink = 'payments.domesticPayment.fromPayment'
          }

          if (paymentObj.paymentTypeGroup == "ForeignCurrencyTransfer") {
            stateLink = 'payments.internalTransfer.fromPayment';
          }
        }

        if (stateLink) {
          $state.go(stateLink, params);
        }
      }

    };

    vm.printCancelation = function (cancelationId) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.print"));

      PaymentsService.downloadCancelationPdf(cancelationId);
    };

    vm.printPayment = function (paymentId) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.print"));

      PaymentsService.downloadPaymentPdf(paymentId);
    };

    vm.signPayment = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.sign"));

      if (PaymentsService.isVerificationRequiredForSign(payment)) {
        // If verification is required, go to payment sign state
        $state.go('payments.sign', {paymentId: payment.id});
      } else {

        var updateObject = _.find(payment.allowedActionList.itemList, function (item) {
          return item.methodName === 'UpdateOldPaymentDateAllowed';
        });

        if (updateObject) {
          vm.dueDateUpdateAllowed = updateObject.isEnabled;
        }

        // Use only date without hours for compare
        var currentTime = new Date();
        currentTime.setHours(0, 0, 0, 0);
        // Check if all payments have valid due date
        var isPaymentDueDateValid = new Date(payment.dueDate) >= currentTime;


        // If verification is not required, then sign payment and go to final step
        var params = {
          id: payment.id
        };

        if (isPaymentDueDateValid) {
          return PaymentsService.signPayment(params).then(function (signedPayment) {
            // TODO: Use signed payment on response
            $state.go('payments.finalStep', {paymentId: payment.id});
          }).catch(function (error) {
            $log.error(error);
            NotificationService.showMessage(error, 'error');
          });
        } else {
          PaymentsService.openUpdatePaymentDueDateModal()
            .result.then(function (result) {
            if (result == "confirm") {
              params.useCurrentDate = true;
              return PaymentsService.signPayment(params).then(function (signedPayment) {
                // TODO: Use signed payment on response
                $state.go('payments.finalStep', {paymentId: payment.id});
              }).catch(function (error) {
                $log.error(error);
                NotificationService.showMessage(error, 'error');
              });
            }
          });
        }
      }
    };

    vm.signCancelation = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.sign"));

      if (PaymentsService.isVerificationRequiredForSign(payment)) {
        // If verification is required, go to payment sign state
        $state.go('payments.sign-instant', {paymentId: payment.payment.id, cancelationId: payment.id, cancelation:payment});
      } else {

        var updateObject = _.find(payment.allowedActionList.itemList, function (item) {
          return item.methodName === 'UpdateOldPaymentDateAllowed';
        });

        if (updateObject) {
          vm.dueDateUpdateAllowed = updateObject.isEnabled;
        }

        // Use only date without hours for compare
        var currentTime = new Date();
        currentTime.setHours(0, 0, 0, 0);
        // Check if all payments have valid due date
        var isPaymentDueDateValid = new Date(payment.cancelationDate) >= currentTime;

        // If verification is not required, then sign payment and go to final step
        var params = {
          id: payment.id
        };

        if (isPaymentDueDateValid) {
          return PaymentsService.signCancelation(params).then(function (signedPayment) {
            // TODO: Use signed payment on response
            $state.go('payments.finalStep', {paymentObject: null, paymentId: vm.selectedPaymentForSign.id, type: vm.paymentType});
          }).catch(function (error) {
            var errorItem = error.resultList.find( function (x) {
              return x.key==='PaymentCancelSignExpired';
            });

            if(error && errorItem)
            {
              AlertService.confirmationAlert({text: "signPayments.cancelationSignMessage"})
                .then(function (accept) {
                  if (!accept) return;
                  vm.deletePaymentAction(payment.id);
                })
            }
            else{
              $log.error(error);
              NotificationService.showMessage(error, 'error');
            }
          });
        } else {
          PaymentsService.openUpdateCancelationDueDateModal()
            .result.then(function (result) {
            if (result == "confirm") {
              params.useCurrentDate = true;
              return PaymentsService.signCancelation(params).then(function (signedPayment) {
                // TODO: Use signed payment on response
                $state.go('payments.finalStep', {paymentObject: null, paymentId: vm.selectedPaymentForSign.id, type: vm.paymentType});
              }).catch(function (error) {
                $log.error(error);
                NotificationService.showMessage(error, 'error');
              });
            }
          });
        }
      }
    };



    vm.confirmRealizedPayment = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.confirmation"));

      var modalInstance = $uibModal.open({
           animation: true,
           templateUrl: 'payments/payment-confirmation-modal-slo.tpl.html',
           controller: 'PaymentConfirmationModalSloCtrl',
           controllerAs: 'vm',
           size: "lg",
           resolve: {
             payment: function () {
               return payment;
             }
           }
         });

         modalInstance.result.then(function (selectedItem) {
           console.log(selectedItem);
         }, function () {
           $log.info('Modal dismissed at: ' + new Date());
         });

    };

    vm.checkProcessedPaymentMne = function(payment, isCancellationStatus) {
      if (vm.apiLocation != 'mne' && vm.apiLocation != 'mn') {
        return false;
      }

      var statusToCheck = isCancellationStatus ? payment.paymentCancelationStatus : payment.status;
      return payment.paymentTypeGroup == 'ForeignPayment' && vm.processedPaymentStatuses.includes(statusToCheck);
    }

    vm.complaintPayment = function (payment) {
      GoogleTagManagerHelperService.pushEvent('PaymentDetails', 'PaymentActionSelection', $translate.instant("paymentActions.complaint"));
      $state.go("payments.reclamation", {paymentId: payment.id});

      // var message = $translate.instant('core.SmsSentSuccessfully');
      // NotificationService.showTranslatedMessage('success', message,'',3000);
    };

    vm.checkDate= function (date) {
      return new Date(date) > new Date();
    };

    vm.transactionFilterYearDateRangeValid = true;
    vm.onDateFromChange = function () {
      vm.isCustomDateRange = true;
      if(!vm.isCancelationCall)
      {
        if (vm.paymentsFilter.dateCreatedLTE < vm.paymentsFilter.dateCreatedGTE) {
          vm.paymentsFilter.dateCreatedLTE = angular.copy(vm.paymentsFilter.dateCreatedGTE);
        }
        // if (vm.paymentsFilter.dateCreatedGTE) {
        //   var yearAfter = new Date(vm.paymentsFilter.dateCreatedGTE.getFullYear() + 1, vm.paymentsFilter.dateCreatedGTE.getMonth(), vm.paymentsFilter.dateCreatedGTE.getDate());
        //   if (yearAfter <= (vm.paymentsFilter.dateCreatedLTE && vm.paymentsFilter.dateCreatedLTE.getTime())) {
        //     vm.paymentsFilter.dateCreatedLTE = yearAfter;
        //   }
        // }
        if (vm.countryCode == 'sr') {
          return;
        }
        if (vm.paymentsFilter.dateCreatedGTE && vm.paymentsFilter.dateCreatedLTE) {
          if (daysBetween(vm.paymentsFilter.dateCreatedGTE, vm.paymentsFilter.dateCreatedLTE) < 365) {
            vm.transactionFilterYearDateRangeValid = true;
          } else {
            vm.transactionFilterYearDateRangeValid = false;
          }
        }
      } else{

        if (vm.cancelationsFilter.cancelationDateLTE < vm.paymentsFilter.cancelationDateGTE) {
          vm.cancelationsFilter.cancelationDateLTE = angular.copy(vm.paymentsFilter.cancelationDateGTE);
        }
        if (vm.countryCode == 'sr') {
          return;
        }
        if (vm.cancelationsFilter.cancelationDateGTE && vm.cancelationsFilter.cancelationDateLTE) {
          if (daysBetween(vm.cancelationsFilter.cancelationDateGTE, vm.cancelationsFilter.cancelationDateLTE) < 365) {
            vm.transactionFilterYearDateRangeValid = true;
          } else {
            vm.transactionFilterYearDateRangeValid = false;
          }
        }
      }


    };

    vm.onDateToChange = function () {
      vm.isCustomDateRange = true; 
      if(!vm.isCancelationCall) {
        if (daysBetween(vm.paymentsFilter.dateCreatedGTE, vm.paymentsFilter.dateCreatedLTE) < 365) {
          vm.transactionFilterYearDateRangeValid = true;
        } else {
          vm.transactionFilterYearDateRangeValid = false;
        }
      }else{
        if (daysBetween(vm.cancelationsFilter.cancelationDateGTE, vm.cancelationsFilter.cancelationDateLTE) < 365) {
          vm.transactionFilterYearDateRangeValid = true;
        } else {
          vm.transactionFilterYearDateRangeValid = false;
        }
      }
    };

    vm.datesToDisabled = function (date, mode) {
      // if (!vm.paymentsFilter.dateCreatedGTE) return true;
      // var yearAfter = new Date(vm.paymentsFilter.dateCreatedGTE.getFullYear() + 1, vm.paymentsFilter.dateCreatedGTE.getMonth(), vm.paymentsFilter.dateCreatedGTE.getDate() + 1);
      // return (mode === 'day' && (date.getTime() < vm.paymentsFilter.dateCreatedGTE || (date.getTime() >= yearAfter)));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
      return (mode === 'day' && (date.getTime() < vm.paymentsFilter.dateCreatedGTE));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
    };

    function daysBetween (firstDate, secondDate) {
      var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
      var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime())/(oneDay)));
      return diffDays;
    }

    vm.init();


  }
}());
