<div>

  <div class="row p-0 m-0" style="height: 50px; background-color: #ff4d5a;">
    <div class="col-md-11 c-white t-a-c">
      <p class="viber-deactivation-title">Addiko Chat Banking</p>
    </div>
    <div class="col-md-1">
      <div class="c-white">
        <i class="icon icon-add pull-right close-icon-x" ng-click="$close()" style="font-size: 40px;"></i>
      </div>
    </div>
  </div>

  <div class="alert-msg-frame">
    <div class="row t-a-c m-t-10 m-b-10">
      <div class="f-18 m-t-10 m-b-10" translate="viber.deactivateModalText">Da li ste sigurni da želite da deaktivirate Addiko Viber Banking uslugu?</div>
    </div>
    <div class="row p-b-20 t-a-c">
      <div class="col-sm-12">
        <button class="btn btn-default viber-w-btn" translate="viber.deactivate" ng-click="$close(true)">Deaktiviraj</button>
      </div>
      <div class="col-sm-12 m-t-10">
        <button class="btn btn-primary viber-w-btn" translate="viber.cancel" ng-click="$close(false)">Odustani</button>
      </div>
    </div>
  </div>

</div>
