(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      navigation: {
        "dashboard": "Početna stranica",
        "accountsAndCards": "Računi i Kartice",
        "accounts": "Računi",
        "documents": "Dokumenti",
        "payments": "Plaćanja",
        "deposit": "Oročenja i eKnjižice",
        "loans": "Krediti",
        "investments": "Investicije",
        "settings": "Postavke",
        "security": "Sigurnost",
        "messages": "Poruke",
        "complaints": "Prigovori",
        "hypoClub": "Addiko Club",
        "eTrade": "Addiko ETrade",
        "paymentsAndTransfers" :"Pregled plaćanja i prijenosa",
        "domesticPayment": "Domaći platni nalog",
        "transferToMyAccount": "Prijenos na moj račun",
        "internationalPayment": " Devizni nalog",
        "exchangeOffice" :"Mjenjačnica",
        "exchangeRates" :"Tečajna lista",
        "standingOrders": "Trajni nalog",
        "eInvoices": "eRačuni",
        "paymentsList": "Pregled naloga",
        "paymentsReturn": "Povrat priljeva",
        "directDebitsList": "Lista SEPA izravnih dugovanja",
        "myTemplatesList": "Predlošci",
        "allAccountsOverview": "Pregled svih računa",
        "allDepositOverview": "Sve štednje",
         "allDeposits": "Štednja",
        "allDepositOverviewSerbMne": "Oročenja",
        "loansOverview": "Pregled kredita",
        "investmentsOverview": "Pregled investicija",
        "availableBalance": "Raspoloživi iznos",
        "loanBalance": "Stanje kredita",
        "newMessages": "Nove poruke: ",
        "employeePayroll": "Djelatnici",
        "allowedLoan":"Odobreni iznos",
        "balance": "Stanje računa",
        "newOrder":"Kupnja udjela",
        "sepa":"Sepa D.D. ograničenja i zabrane",
        "sepa_CRO": "Ograničenja SEPA izravnog terećenja",
        "sepaTransactions":"SEPA izravno terećenje",
        "sepaTransactions_CRO":"SEPA izravno terećenje",
        "secure3d": "Sigurna internetska kupnja",
        "viberBanking":"Addiko Chat Banking",
        "directDebit":"Izravno terećenje - odbijanje",
        "availableBalanceCard": "Raspoloživi iznos kartičnog računa"
      }
    };

    $translateProvider
      .translations('hr', data);

  }
}());
