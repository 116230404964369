(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:OrderSoftTokenCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('OrderSoftTokenCtrl', OrderSoftTokenCtrl);

  function OrderSoftTokenCtrl(country, UserSettingsService, $log, SoftTokenOrderService, AlertService, $location, HelperService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'OrderSoftTokenCtrl';

    vm.userSettings = {};
    vm.formSubmitted = false;
    vm.changeEmailButton = false;
    vm.changeGsmButton = false;

    vm.steps = {
      step1: true,
      step2: false
    };

    vm.back = function () {
      vm.steps = {
        step1: true,
        step2: false
      };
    };

    vm.init = function () {
      UserSettingsService.getPage({
        pageSize: 1000
      })
        .then(function (response) {
          vm.userSettings = response.result[0];
          vm.notificationEmail = vm.userSettings.notificationEmail;
          vm.gsm = vm.userSettings.gsm;
          $log.debug("userSettings", vm.userSettings);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });

      vm.changeEmailButton = true
      vm.changeGsmButton = true;
    };

    vm.changeEmail = function () {
      vm.notificationEmail = vm.userSettings.notificationEmail;
      vm.changeEmailButton = false;
    };

    vm.changeEmailOk = function () {
      if (vm.notificationEmail) {
        vm.userSettings.notificationEmail = vm.notificationEmail;
      }
      vm.changeEmailButton = true;
    };

    vm.changeEmailCancel = function () {
      vm.changeEmailButton = true;
      vm.notificationEmail = vm.userSettings.notificationEmail;
    };

    vm.changeGsm = function () {
      vm.gsm = vm.userSettings.gsm;
      vm.changeGsmButton = false;
    };

    vm.changeGsmOk = function () {
      if (vm.gsm) {
        vm.userSettings.gsm = vm.gsm;
      }
      vm.changeGsmButton = true;
    };

    vm.changeGsmCancel = function () {
      vm.changeGsmButton = true;
      vm.gsm = vm.userSettings.gsm;
    };

    function createObject() {
      return {
        "nameAndSurname": vm.userSettings.firstName + " " + vm.userSettings.lastName,
        "email": vm.userSettings.notificationEmail,
        "phoneNumber": vm.userSettings.gsm,
        "note": ""
      }
    };

    vm.submitOrder = function () {
      if (vm.validationInProgress) return;
      vm.formSubmitted = true;
      if (!vm.orderSoftTokenForm.$valid) {
        return;
      }
      var orderObject = createObject();
      vm.validationInProgress = true;
      SoftTokenOrderService.validateSoftTokenOrder(orderObject)
        .then(function (response) {
          vm.responseModel = response;
          vm.steps = {
            step1: false,
            step2: true
          };
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function (error) {
        vm.validationInProgress = false;
      });
    };

    vm.confirmSoftTokenOrder = function () {
      if (vm.confirmationInProgress) return;
      var orderObject = createObject();
      vm.confirmationInProgress = true;
      SoftTokenOrderService.insertOrder(orderObject)
        .then(function (response) {
          $log.debug("SoftTokenOrderService.insertOrder post response", response);

          AlertService.infoAlert({
            text: 'orderSoftToken.successTxt'
          })
            .then(function () {
              $location.path('/settings');
            });
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        }).finally(function () {
        vm.confirmationInProgress = false;
      });
    };

    vm.openWebsite = function () {
      var nonBlockingWindow = window.open('', '_blank');
      nonBlockingWindow.location.href = "https://www.addiko.si/pristop-k-spletni-banki-addiko-ebank";
    };

    vm.setMobilePhoneNumber = function (phone) {
      if (!phone) {
        AlertService.infoAlert({
          text: 'settings.noNumber'
        })
        return;
      }

      $state.go("settings.contactDataVerificationPhone");
    }

    vm.init();
  }
}());
