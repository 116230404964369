(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name payments.controller:InternationalPaymentCtrl
     *
     * @description
     *
     */
    angular
        .module('payments')
        .controller('InternationalPaymentCroCtrl', InternationalPaymentCroCtrl);

    function InternationalPaymentCroCtrl($rootScope, $location, CTLocalizationService, UserSettingsService, $state, $log, PurposeCodesService, BanksService, BicBanksService, CountriesService,
        PaymentTypesService, PaymentsService, HelperService, $q, $timeout, PaymentTemplatesService, ValidationService, CreditAccountRegisterService, AlertService, GoogleTagManagerHelperService, NotificationService) {
        var vm = this;

        vm.paymentResponse = {};
        var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        vm.defaultCurrency = defaultData.DefaultCurrency;
        vm.instantPaymentAutoFillData = defaultData.InstantPaymentAutoFillData;
        vm.purposeCodesFilter = {
            page: 0,
            pageSize: 1000
        };

        vm.countriesFilter = {
            page: 0,
            pageSize: 1000
        };

        vm.payeeFilter = {
            paymentTypeGroup: 'ForeignPayment',
            orderBy: 'receiverName ASC',
            page: 0,
            pageSize: 1000
        };

        vm.templateName = '';
        vm.templatesFilter = {
            paymentTypeGroup: 'ForeignPayment',
            page: 0,
            pageSize: 1000
        };

        vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
        vm.uiMaskOptions = ValidationService.getUiMaskOptions().international.iban;
        vm.instantPaymentEnabled = false;
        vm.isBankInSCTInstScheme = false;
        vm.tempForpObj = {
            payersNameInput: '',
            payersAddressInput: '',
            payersCityInput: '',
            fromAccountSelect: {},
            amountInput: '',
            dueDateInput: new Date(),
            paymentPurposeInput: '',
            paymentMode: '',
            purposeCodeSelect: {},
            recipientStatusRadiobutton: {
                type: '2'
            },
            fullNameCompanyInput: '',
            recipientAddressInput: '',
            recipientCityInput: '',
            recipientCountrySelect: {},
            recipientAccountInput: '',
            bankNameInput: '',
            bankAddressInput: '',
            bankCityInput: '',
            bankCountrySelect: {},
            swiftCodeInput: '',
            bankChargesRadiobutton: {
                type: '3'
            },
            chargeAccountInput: '',
            formSubmitted: false,
            receiversSepaInput1: '',
            receiversSepaInput2: '',
            payersSepaInput1: '',
            payersSepaInput2: '',
            recieverType: '', // Primatelj (osoba)
            spentOption: '', // Tro�kovna opcija
            isUrgentPaymentCheckbox: false,
        };

        vm.forUpdate = false;

        vm.datePickerOpened = false;

        vm.goBack = function () {
            $state.go('payments.paymentsOverview');
        };

        vm.dateOptions = {
            minDate: new Date(),
            maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
        };

        vm.disabled = function (date, mode) {
            return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
        };

        vm.steps = {
            step1: true,
            step2: false,
            step3: false
        };

        vm.isEUCountry = false;

        vm.goBack = function () {
            $state.go('payments');
        };

        function setChargeOption(option) { // sha 3,our 1,ben 2
          vm.tempForpObj.bankChargesRadiobutton.type = option || '3';
        }


        vm.getUserSettingsPage = function () {

            UserSettingsService.getPage(vm.userSettingsFilter)
                .then(function (response) {
                    var userSettings = response.result[0];
                    vm.tempForpObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
                    vm.tempForpObj.payersAddressInput = userSettings.address1;
                    vm.tempForpObj.payersCityInput = userSettings.city;


                })
                .catch(function (error) {
                    $log.error(error);
                });
        };

        vm.loadPurposeCode = function () {
            return PurposeCodesService.getPage(vm.purposeCodesFilter)
                .then(function (response) {
                    _.forEach(response.result, function (value) {
                        value.optionValue = value.id + ' - ' + value.description;
                    });
                    vm.purposeCodes = response.result;
                })
                .catch(function (error) {

                });
        };

        vm.loadCountries = function () {
            return CountriesService.getPage(vm.countriesFilter)
                .then(function (response) {
                    vm.bankCountries = response.result;
                })
                .catch(function () {

                });
        };

        vm.getDebitAccountList = function () {
            return PaymentTypesService.getByGroup({
                    paymentTypeGroup: 'ForeignPayment'
                })
                .then(function (response) {
                    if (_.isArray(response.result) && response.result.length > 0) {
                        vm.accountsFrom = response.result[0].debitAccountList;
                        _.forEach(vm.accountsFrom, function (item) {
                            if (item.accountDescription) {
                                item.optionValue = item.accountDescription + ' - ' + item.iban;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    $log.error(error);
                });
        };

        vm.purposeCodeChange = function (purposeCode) {
            vm.tempForpObj.purposeCodeSelect = purposeCode;
            // vm.tempForpObj.paymentPurposeInput = purposeCode.description;
            var purpose = _.find(vm.purposeCodes, {
                description: vm.tempForpObj.paymentPurposeInput
            });
            if (purpose) {
                vm.tempForpObj.paymentPurposeInput = purposeCode.description;
            }

            // var purposeCode = _.findIndex(vm.purposeCodes, function (item) {
            //   vm.tempForpObj.paymentPurposeInput = item.description;
            //   return item.id == code.id;
            // });
        };

        vm.openDatePicker = function () {
            vm.datePickerOpened = true;
        };

        vm.getBankInfo = function () {
            BicBanksService.getByIban(vm.tempForpObj.recipientAccountInput)
                .then(function (response) {
                    if (_.isArray(response.result) && response.result.length > 0) {
                        vm.tempForpObj.bankNameInput = response.result[0].institutionName;
                        vm.tempForpObj.bankCityInput = response.result[0].city;
                        vm.tempForpObj.swiftCodeInput = response.result[0].swiftCode;
                    }
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        /////////////////////////////CUSTOM SELECT////////////////////////////

        vm.toggleFilterSelection = function () {
            vm.searchFilterExpanded = !vm.searchFilterExpanded;
        };

        vm.searchPayee = function () {
            vm.searchFilterExpanded = true;
        };

        vm.getPayees = function () {
            PaymentTemplatesService.getPage(vm.payeeFilter)
                .then(function (response) {
                    vm.payeeList = response.result;
                    if ($state.params && $state.params.templateId) {
                        vm.selectTemplate($state.params.templateId);
                    }
                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.selectPayee = function (id) {
            var item = _.find(vm.payeeList, {
                id: id
            });
            vm.tempForpObj.toAccountInput = item.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            vm.tempForpObj.receiversAddressInput = item.receiverAddress1;
            vm.tempForpObj.receiversNameInput = item.receiverName;
            vm.tempForpObj.receiversPostalCodeAndCityInput = item.receiverCity;
            /*if(item.amount)
              vm.tempForpObj.amountInput = $filter('number')(item.amount, 2);*/
            // vm.tempForpObj.recipientCountrySelect.countryCode = item.receiverCountryCode;
            var country = _.find(vm.countriesOptions, {
                code: item.receiverCountryCode
            });
            if (country) {
                vm.onCountrySelect(country);
            }

            // Bind to BIC
            vm.getBicByIban(vm.tempForpObj.toAccountInput);

            vm.tempForpObj.receiversNameInput = item.receiverName;
            vm.searchFilterExpanded = !vm.searchFilterExpanded;
        };

        vm.removeSearch = function () {
            vm.tempForpObj.receiversNameInput = '';
            vm.tempForpObj.toAccountInput = '';
            vm.tempForpObj.receiversAddressInput = '';
            vm.tempForpObj.receiversPostalCodeAndCityInput = '';
            // vm.tempForpObj.swiftCodeInput = "";
            // vm.tempForpObj.bankAddressInput = "";
            // vm.tempForpObj.bankCityInput = "";
            // vm.tempForpObj.bankNameInput = "";
            // vm.countrySearchFilter = '';
            // vm.bicCodeSearchFilter = '';
            // Bank remove ?
        };

        vm.searchPayeeBy = function (searchrow) {
            return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempForpObj.receiversNameInput) || '') !== -1 ||
                angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempForpObj.receiversNameInput) || '') !== -1);
        };


        ////////////////select template component//////////////////////
        vm.searchTemplate = function () {
            vm.searchTemplateFilterExpanded = true;
            if (vm.searchTemplateFilterExpanded === true) {
                $timeout(function () {
                    var searchTemplatesInput = angular.element('#searchTemplatesInput');
                    searchTemplatesInput.focus();
                }, 500);
            }
        };

        vm.toggleTemplateFilterSelection = function () {
            vm.getPaymentTemplatesPage();
            vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
        };

        vm.getPaymentTemplatesPage = function () {
            PaymentTemplatesService.getPage(vm.templatesFilter)
                .then(function (response) {
                    vm.templateList = response;
                    $log.debug(vm.templateList);
                }).catch(function (error) {
                    $log.error(error);
                });
        };

        vm.selectTemplate = function (id) {
            GoogleTagManagerHelperService.pushEvent('InternationalPayment', 'PaymentTemplateSelection', 'Template with id ' + id + ' selected');
            var item = _.find(vm.payeeList, {
                id: +id
            });
            loadAccountData(item.accountId);
            var recipientIban = item.toAccountNumber;
            vm.tempForpObj.toAccountInput = item.toAccountNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            vm.tempForpObj.receiversAddressInput = item.receiverAddress1;
            vm.tempForpObj.receiversNameInput = item.receiverName;
            vm.tempForpObj.receiversPostalCodeAndCityInput = item.receiverCity;
            vm.tempForpObj.paymentPurposeInput = item.purpose;

            if (item.purposeCodeDPS && vm.purposeCodes.length > 0) {
                var index = _.findIndex(vm.purposeCodes, function (value) {
                    return value.id === item.purposeCodeDPS;
                });
                if (index && index != -1) {
                    vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[index];
                } else {
                    vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[0];
                }
            }
            //vm.tempForpObj.amountInput = $filter('number')(item.amount, 2);
            // vm.tempForpObj.recipientCountrySelect.countryCode = item.receiverCountryCode;
            // Bind to BIC
            // vm.getBicByIban(vm.tempForpObj.toAccountInput);

            vm.setChargeAccount(vm.tempForpObj.fromAccountSelect);
            // Populate BIC fields from template
            vm.tempForpObj.swiftCodeInput = item.beneficiarySWIFTCode;
            vm.bicCodeSearchFilter = item.beneficiarySWIFTCode;
            vm.tempForpObj.bankAddressInput = item.beneficiaryBankAddress;
            vm.tempForpObj.bankCityInput = item.beneficiaryBankCity;
            vm.tempForpObj.bankNameInput = item.beneficiaryBankName;
            //vm.tempForpObj.modalityCode = item.modalityCode;
            vm.tempForpObj.bankChargesRadiobutton.type = item.paymentCharges != '2' ? item.paymentCharges || '3' : '3';
            //vm.tempForpObj.recipientStatusRadiobutton.type = item.payeeLegalForm || '2';

            var country = _.find(vm.countriesOptions, {
                code: item.beneficiaryBankCountryCode
            });
            if (country) {
                vm.onCountrySelect(country);
            }
            vm.templateName = item.name;
            //vm.payeeSearchFilter = item.receiverName;
            vm.tempForpObj.receiversNameInput = item.receiverName;
            vm.searchFilterExpanded = !vm.searchFilterExpanded;
            vm.searchFilterExpanded = false;
            vm.searchTemplateFilterExpanded = false;
            vm.isInstantPaymentCheckBox = item.urgency == '2';
            vm.isUrgentPaymentCheckbox = item.urgency == '1';

            if (vm.tempForpObj.toAccountInput) {
                BanksService.instantCategoryByIbanCheck(recipientIban).then(function(response) {
                  vm.checkBankSCTInst(response);
                })
              }
            // TSK-117872 CRO: Zahtjev_Troškovna opcija SHA _ ZPP 2018
            vm.getBicByIbanOnlyCountryCheck(vm.tempForpObj.toAccountInput);
        };

        vm.removeTemplate = function () {
            vm.searchTemplateFilterExpanded = false;
            vm.templateName = '';
            //vm.payeeSearchFilter = '';
            vm.tempForpObj.receiversNameInput = '';
            vm.countrySearchFilter = '';
            vm.bicCodeSearchFilter = '';
            vm.tempForpObj.swiftCodeInput = "";
            vm.tempForpObj.bankAddressInput = "";
            vm.tempForpObj.bankCityInput = "";
            vm.tempForpObj.bankNameInput = "";
            vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[0];
            vm.tempForpObj.paymentPurposeInput = vm.purposeCodes[0].description;
            //vm.tempForpObj.modalityCode = vm.paymentModeOptions[0];

            // vm.tempForpObj = {};
            vm.tempForpObj.toAccountInput = '';
            vm.tempForpObj.receiversAddressInput = '';
            vm.tempForpObj.receiversPostalCodeAndCityInput = '';
            vm.tempForpObj.amountInput = '';
        };

        var fillReceiversInput = function (response) {
            if (response.result.length > 0) {
                vm.tempForpObj.receiversNameInput = response.result[0].receiverName;
                //vm.payeeSearchFilter = response.result[0].receiverName;
                vm.tempForpObj.receiversAddressInput = response.result[0].receiverAddress1;
                vm.tempForpObj.receiversPostalCodeAndCityInput = response.result[0].receiverCity;
                // TODO: Populate bic number (currently request returns nothing)
            }
        };
            
        vm.toggleUrgencyField = function(checkboxName) {
            var clickedCheckbox = checkboxName;
            var otherCheckbox = checkboxName === 'isUrgentPaymentCheckbox' ? 'isInstantPaymentCheckBox' : 'isUrgentPaymentCheckbox';
            if (vm.tempForpObj[clickedCheckbox]) {
                vm.tempForpObj[otherCheckbox] = false;
            }
        }

        vm.fillReceiver = function (toAccountInput) {
            if (!toAccountInput) return;
            toAccountInput = toAccountInput.replace(/\s/g, '');
            if (toAccountInput.substring(0, 2) === 'HR') {
                $q.all([CreditAccountRegisterService.getByIban(toAccountInput), BanksService.instantCategoryByIbanCheck(toAccountInput)])
                    .then(function (responses) {
                        fillReceiversInput(responses[0]);
                        if (responses[1]) {
                            vm.checkBankSCTInst(responses[1]);
                        }
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            } else {
                $q.all([CreditAccountRegisterService.getPage({
                        accountNumber: toAccountInput
                    }), BanksService.instantCategoryByIbanCheck(toAccountInput)])
                    .then(function (responses) {
                        fillReceiversInput(responses[0]);
                        if (responses[1]) {
                            vm.checkBankSCTInst(responses[1]);
                        }
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
        };

        /*      vm.signPayment = function () {
         PaymentsService.openSignModal(vm.paymentResponse.id)
         .then(function (signed) {
         if (signed) {
         vm.steps = {
         step1: false,
         step2: false,
         step3: true
         };
         }
         })
         .catch(function (error) {
         NotificationService.showMessage(error, 'error');
         });
         };*/

        var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        $rootScope.$on('appLanguageChangedHeader', function () {
            sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
        });

        vm.deletePayment = function () {
            //swal({
            //  title: sweetAlertOptions.areYouSure,
            //  type: "info",
            //  showCancelButton: true,
            //  confirmButtonClass: "btn-default",
            //  confirmButtonText: sweetAlertOptions.yes,
            //  cancelButtonText: sweetAlertOptions.no,
            //  closeOnConfirm: true,
            //  closeOnCancel: true
            //}, function (isConfirm) {
            //  if (isConfirm) {
            //    PaymentsService.deletePayment(vm.paymentResponse.id)
            //      .then(function (response) {
            //      HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], vm.paymentResponse.id);
            //      $state.go('payments.paymentsOverview');
            //    })
            //      .catch(function (error) {
            //      NotificationService.showMessage(error, 'error');
            //    });
            //  }
            //});

            AlertService.confirmationAlert()
                .then(function (result) {
                    if (result) {
                        PaymentsService.deletePayment(vm.paymentResponse.id)
                            .then(function (response) {
                                AlertService.deletedPaymentAlert({
                                        paymentName: vm.paymentResponse.id,
                                        type: 'payment'
                                    })
                                    .then(function () {
                                        $state.go('payments.paymentsOverview');
                                    });
                            })
                            .catch(function (error) {
                                NotificationService.showMessage(error, 'error');
                            });
                    }
                })
                .catch(function () {
                    $log.debug('Example failed!');
                });
        };

        vm.editPayment = function () {
            vm.forUpdate = true;
            vm.steps = {
                step1: true,
                step2: false,
                step3: false
            };
        };

        vm.setChargeAccount = function (item) {
          vm.tempForpObj.fromAccountSelect = item;

          var _tempObj = {
            accountNumber: item.accountNumber || '',
            currencySymbol: vm.defaultCurrency
          }

          vm.tempForpObj.chargeAccountInput = _tempObj.accountNumber?_tempObj.accountNumber + ' ' + _tempObj.currencySymbol:'';
        };


        vm.clearSepaReceiver = function (e) {
            vm.tempForpObj.receiversSepaInput1 = '';
            vm.tempForpObj.receiversSepaInput2 = '';
        };

        vm.clearSepaPayer = function () {
            vm.tempForpObj.payersSepaInput1 = '';
            vm.tempForpObj.payersSepaInput2 = '';
        };


        var loadAccountData = function (accountId) {
            var fromAccount = _.find(vm.accountsFrom, function (item) {
                return item.accountId == accountId;
            });
            vm.tempForpObj.fromAccountSelect = fromAccount;
        };

        //var loadPaymentDataFromTemplate = function (templateId) {
        //  if (templateId) {
        //    PaymentTemplatesService.getById(templateId)
        //      .then(function (response) {
        //      vm.tempForpObj.amountInput = $filter('number')(response.amount, 2);
        //      vm.tempForpObj.recipientAccountInput = response.toAccountNumber;
        //      vm.tempForpObj.paymentPurposeInput = response.purpose;
        //      vm.tempForpObj.fullNameCompanyInput = response.payeeName || '';
        //      vm.tempForpObj.recipientAddressInput = response.payeeAddress1 || '';
        //      vm.tempForpObj.recipientCityInput = response.payeeCity || '';
        //      loadAccountData(response.accountId);
        //    })
        //      .catch(function (error) {
        //      NotificationService.showMessage(error, 'error');
        //    });
        //  }
        //};

        var loadPaymentData = function (paymentId) {
            if (paymentId) {
                PaymentsService.getById(paymentId)
                    .then(function (response) {

                        var country = _.find(vm.countriesOptions, {
                            code: response.beneficiaryBankCountryCode
                        });
                        if (country) {
                          vm.onCountrySelect(country);
                          // vm.countrySearchFilter = country.description;
                          // vm.tempForpObj.bankCountrySelect = country;
                        }

                        vm.tempForpObj.amountInput = response.amount;
                        var recipientIban = '' + response.toAccountNumber;
                        vm.tempForpObj.toAccountInput = recipientIban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                        vm.tempForpObj.recipientAccountInput = response.toAccountNumber;
                        //vm.payeeSearchFilter = response.receiverName;
                        vm.tempForpObj.receiversNameInput = response.receiverName;
                        vm.tempForpObj.receiversAddressInput = response.receiverAddress1;
                        vm.tempForpObj.paymentPurposeInput = response.purpose;
                        vm.tempForpObj.fullNameCompanyInput = response.payeeName || '';
                        vm.tempForpObj.recipientAddressInput = response.payeeAddress1 || '';
                        vm.tempForpObj.recipientCityInput = response.payeeCity || '';
                        vm.tempForpObj.bankAddressInput = response.beneficiaryBankAddress;
                        vm.tempForpObj.bankCityInput = response.beneficiaryBankCity;
                        vm.tempForpObj.bankNameInput = response.beneficiaryBankName;
                        //vm.tempForpObj.modalityCode = response.modalityCode;
                        vm.tempForpObj.bankChargesRadiobutton.type = response.paymentCharges != '2' ? response.paymentCharges || '3' : '3';
                        vm.tempForpObj.recipientStatusRadiobutton.type = response.payeeLegalForm || '2';
                        vm.tempForpObj.swiftCodeInput = response.beneficiarySWIFTCode;
                        vm.tempForpObj.receiversPostalCodeAndCityInput = response.receiverCity;
                        vm.tempForpObj.isUrgentPaymentCheckbox = response.urgency == '1' ? true : false;
                        vm.tempForpObj.isInstantPaymentCheckBox = response.urgency == '2' ? true : false;
                        vm.bicCodeSearchFilter = response.beneficiarySWIFTCode;
                        loadAccountData(response.accountId);
                        vm.setChargeAccount(vm.tempForpObj.fromAccountSelect);
                        vm.getBicByIbanOnlyCountryCheck(vm.tempForpObj.toAccountInput);

                        var index = _.findIndex(vm.purposeCodes, function (item) {
                            return response.purposeCodeDPS === item.id;
                        })
                        if (index) {
                            vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[index];
                        } else {
                            vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[0];
                        }

                        vm.paymentResponse.id = paymentId;
                        if ($state.params.editable) {
                            vm.forUpdate = true;
                        }
                        BanksService.instantCategoryByIbanCheck(recipientIban).then(function (response) {
                            vm.checkBankSCTInst(response);
                        })
                    })
                    .catch(function (error) {
                        NotificationService.showMessage(error, 'error');
                    });
            }
        };

        // --
        // INITIALIZE
        // --
        var populateFieldsWithData = function () {

        };
        vm.init = function () {
            vm.getPaymentTemplatesPage();
            vm.getUserSettingsPage();
            var promises = [
        PaymentsService.getPaymentModes(),
        CountriesService.getPage({
                    pageSize: 1000
                }),
        vm.loadPurposeCode(),
        vm.loadCountries(),
        vm.getDebitAccountList()
      ];

            $q.all(promises)
                .then(function (responses) {
                    vm.getPayees();
                    // Payment Modes Options
                    vm.paymentModeOptions = responses[0].result;
                    // Countries
                    vm.countriesOptions = responses[1].result;

                    //        if ($state.params && $state.params.templateId) {
                    //          loadPaymentDataFromTemplate($state.params.templateId);
                    //        } else
                    if ($state.params && $state.params.paymentId) {
                        loadPaymentData($state.params.paymentId);
                    } else {
                        if (vm.purposeCodes.length > 0) {
                            vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[0];
                        }
                        if (vm.bankCountries.length > 0) {
                            vm.tempForpObj.recipientCountrySelect = vm.bankCountries[0];
                            vm.tempForpObj.bankCountrySelect = vm.bankCountries[0];
                        }
                        if (_.isArray(vm.accountsFrom) && vm.accountsFrom.length > 0) {
                            vm.tempForpObj.fromAccountSelect = vm.accountsFrom[0];
                            vm.setChargeAccount(vm.tempForpObj.fromAccountSelect);
                        }
                    }

                    if (vm.purposeCodes.length > 0) {
                        vm.tempForpObj.purposeCodeSelect = vm.purposeCodes[0];
                        vm.tempForpObj.paymentPurposeInput = vm.purposeCodes[0].description;
                    }
                    if (vm.bankCountries.length > 0) {
                        vm.tempForpObj.recipientCountrySelect = vm.bankCountries[0];
                        vm.tempForpObj.bankCountrySelect = vm.bankCountries[0];
                    }
                   
                  //setChargeOption('2');
                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        }

        // region Private methods

        /*
         * Create object for POST
         * */
        function createPaymentObject() {
            var paymentObject = {};
            paymentObject.accountId = vm.tempForpObj.fromAccountSelect.accountId;
            paymentObject.fromAccountNumber = vm.tempForpObj.fromAccountSelect.iban;

            paymentObject.toAccountNumber = (vm.tempForpObj.toAccountInput) ? vm.tempForpObj.toAccountInput.replace(/\s/g, '') : "";
            paymentObject.currencySymbol = vm.tempForpObj.fromAccountSelect.currencySymbol;
            paymentObject.purpose = vm.tempForpObj.paymentPurposeInput;
            paymentObject.dueDate = vm.tempForpObj.dueDateInput;
            paymentObject.amount = vm.tempForpObj.amountInput;
            paymentObject.paymentTypeGroup = 'ForeignPayment';
            paymentObject.purposeCodeDPS = vm.tempForpObj.purposeCodeSelect ? vm.tempForpObj.purposeCodeSelect.id : '';
            paymentObject.foreignPayment = {
                receiverName: vm.tempForpObj.receiversNameInput,
                receiverAddress1: vm.tempForpObj.receiversAddressInput,
                receiverCity: vm.tempForpObj.receiversPostalCodeAndCityInput,
                senderName: vm.tempForpObj.fromAccountSelect.ownerName,
                senderAddress1: vm.tempForpObj.fromAccountSelect.ownerAddress,
                senderCity: vm.tempForpObj.fromAccountSelect.ownerCity,
                beneficiarySWIFTCode: vm.tempForpObj.swiftCodeInput,
                beneficiaryBankName: vm.tempForpObj.bankNameInput,
                beneficiaryBankAddress: vm.tempForpObj.bankAddressInput,
                beneficiaryBankCity: vm.tempForpObj.bankCityInput,
                //beneficiaryBankCountryCode: vm.tempForpObj.bankCountrySelect.code,
                // beneficiaryBankCountry: vm.tempForpObj.bankCountrySelect.description,
                beneficiaryBankCountry: "",
                foreignBankAddress3: "",
                //TODO: Seems like recieverCountryCode selects first country from the list of countries and there is no way to choose one - but is required by backend!!!!!
                receiverCountryCode: vm.tempForpObj.bankCountrySelect.code,
                //referenceFrom: null,
                referenceTo: null,
                // need to add field on payment form
                chargeAccount: (vm.tempForpObj.bankChargesRadiobutton.type != 2) ? vm.tempForpObj.chargeAccountInput: "",
                //payeeLegalForm: vm.tempForpObj.recipientStatusRadiobutton.type,
                paymentCharges: vm.tempForpObj.bankChargesRadiobutton.type,
                // paymentUsedMode: vm.tempForpObj.paymentUsedMode
                //modalityCode: vm.tempForpObj.modalityCode
                urgency: vm.tempForpObj.isInstantPaymentCheckBox ? '2' : vm.tempForpObj.isUrgentPaymentCheckbox ? '1' : '0'

            };
            paymentObject.sepaUpnIdentificators = {
                debtorName: vm.tempForpObj.payersSepaInput1,
                debtorName2: vm.tempForpObj.payersSepaInput2,
                creditorName: vm.tempForpObj.receiversSepaInput1,
                creditorName2: vm.tempForpObj.receiversSepaInput2
            };
            return paymentObject;
        }

        // endregion

        // region Public methods

        /*
         * Autocompletes Bank/BIC data when BIC Number is selected
         * */
        vm.onBicBankSelected = function (bbank) {
            // bicBanks API for Slovenia - returns {bicCode: "BSLJSI2X", id: "21"} - not good
            var bicBank = _.find(vm.bicBankOptions, {
                swiftCode: bbank.swiftCode
            });
            vm.tempForpObj.swiftCodeInput = bicBank.swiftCode;
            vm.tempForpObj.bankAddressInput = bicBank.branchInformation;
            vm.tempForpObj.bankCityInput = bicBank.city;
            vm.tempForpObj.bankNameInput = bicBank.institutionName;

            vm.bicCodeSearchFilter = bicBank.swiftCode;
            vm.bicCodeSearchFilterExpanded = false;

            var country = _.find(vm.countriesOptions, {
                countryCodeShort: bicBank.swiftCode.substring(4, 6)
            });
            if (country) vm.onCountrySelect(country);

        };

        vm.addSpaceForIban = function (toAccountInput) {
            if (!vm.tempForpObj.toAccountInput) return;
            vm.tempForpObj.toAccountInput = toAccountInput.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        };

        vm.getBicByIban = function (iban) {
            vm.isEUCountry = false;
            if (!iban) {
                return;
            }
            iban = iban.replace(/\s/g, '');
            PaymentsService.getBicBanks(undefined, {
                    iban: iban
                })
                .then(function (response) {

                    var bicBank = response.result[0];
                    if (bicBank) {
                        vm.bicCodeSearchFilter = bicBank.swiftCode;
                        vm.tempForpObj.swiftCodeInput = bicBank.swiftCode;
                        vm.tempForpObj.bankAddressInput = bicBank.branchInformation;
                        vm.tempForpObj.bankCityInput = bicBank.city;
                        vm.tempForpObj.bankNameInput = bicBank.institutionName;
                        var country = _.find(vm.countriesOptions, {
                            countryCodeShort: bicBank.swiftCode.substring(4, 6)
                        });
                        if (country) vm.onCountrySelect(country);

                        vm.isEUCountry = bicBank.euCountry;
                        if(vm.isEUCountry) setChargeOption('3'); //sha = 3
                    }
                }, function (error) {

                })
        };

        vm.checkBankSCTInst = function (instantCategory) {
            vm.isBankInSCTInstScheme = instantCategory;
            vm.instantPaymentValidation();
          }

          vm.instantPaymentValidation = function () {
            const date = vm.tempForpObj.dueDateInput;
            const todayDate = new Date();
            const today = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), 0, 0, 0, 0).toISOString();
            const dateInput =  new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate(), 0, 0, 0, 0).toISOString();
            if (vm.tempForpObj.amountInput <= 100000 && vm.isBankInSCTInstScheme && today == dateInput) { // ako je danasnji datum
              vm.instantPaymentEnabled = true;
              vm.tempForpObj.isUrgentPaymentCheckbox = false;
              if (vm.instantPaymentAutoFillData) {
                vm.tempForpObj.isInstantPaymentCheckBox = false;
              }
            } else {
              vm.instantPaymentEnabled = false;
              vm.tempForpObj.isInstantPaymentCheckBox = false;
            }
          };

        vm.getBicByIbanOnlyCountryCheck = function (iban) {
          vm.isEUCountry = false;
          // TSK-117872 CRO: Zahtjev_Troškovna opcija SHA _ ZPP 2018
          if (!iban) {
            return;
          }
          iban = iban.replace(/\s/g, '');
          PaymentsService.getBicBanks(undefined, {
            iban: iban
          })
            .then(function (response) {

              var bicBank = response.result[0];
              if (bicBank) {
                vm.isEUCountry = bicBank.euCountry;
                if(vm.isEUCountry) setChargeOption('3'); //sha = 3
              }
            }, function (error) {

            });
      };

        vm.searchBICNumber = function (bicInput) {
            vm.bicCodeSearchFilterExpanded = true;
            vm.tempForpObj.swiftCodeInput = bicInput;
            searchBicNumber(bicInput);
        };

        function searchBicNumber(bicInput) {
            PaymentsService.getBicBanks(undefined, {
                    bicCode: bicInput
                })
                .then(function (response) {
                    vm.bicBankOptions = response.result;
                }, function (error) {

                })
        }

        vm.newPayment = function () {
            $state.go('payments.internationalPayment', {}, {
                reload: true
            })
        };

        vm.removeBicCodeSearch = function () {
            vm.bicCodeSearchFilter = "";
            vm.tempForpObj.swiftCodeInput = "";
            vm.tempForpObj.bankAddressInput = "";
            vm.tempForpObj.bankCityInput = "";
            vm.tempForpObj.bankNameInput = "";
        };

        /*
         * Countries selectbox - on list click
         * */
        vm.onCountrySelect = function (country) {
            console.log(country);
            vm.countrySearchFilter = country.code + " " + country.description;
            vm.tempForpObj.bankCountrySelect = country;
            vm.countrySearchFilterExpanded = false;

            if (country.countryUnion == "EU") {
                vm.EUCountrySelected = true;
                // Disable Charge option if EU country is selected because option must be "SHA"
                vm.tempForpObj.bankChargesRadiobutton.type = '3';
            } else {
                vm.EUCountrySelected = false;
            }
        };

        /*
         * Countries selectbox - remove
         * */
        vm.removeSelectedCountry = function () {
            vm.countrySearchFilter = "";
            vm.tempForpObj.bankCountrySelect = {};
            vm.countrySearchFilterExpanded = true;
            vm.EUCountrySelected = false;
        };


        /*
         * Called when Review and submit button is clicked
         * */
        vm.submitInternationalPayment = function (event) {
            // Check if form is valid
            vm.tempForpObj.formSubmitted = true;
            if (!vm.paymentForm.$valid) {
                return false;
            }


            // If form is valid create object for update
            var paymentObject = createPaymentObject();

            // If selected country has amlControl call statement confirmation modal
            if (vm.tempForpObj.bankCountrySelect.amlControl) {
                // PaymentsService.openStatementConfirmModal(vm.tempForpObj.bankCountrySelect)
                PaymentsService.openStatementConfirmModal(vm.tempForpObj)
                    .result.then(function (result) {
                        // Add more information to payment object
                        if (!result)
                            return;
                        else {
                            paymentObject.foreignPayment.beneficiaryBankCountry = result.stateOption;
                            paymentObject.foreignPayment.foreignBankAddress3 = result.information;
                        }
                        upsertInternationalPayment(paymentObject);

                    });
                return;
            }

            upsertInternationalPayment(paymentObject);
        };

        function upsertInternationalPayment(paymentObject) {
            if (!vm.formSubmitted) {
                vm.formSubmitted = true;
                if (vm.forUpdate) {
                    paymentObject.id = vm.paymentResponse.id;
                    PaymentsService.updatePayment(vm.paymentResponse.id, paymentObject)
                        .then(function (response) {
                            postPaymentAction(response);
                            vm.forUpdate = false;
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                            // vm.forUpdate = false;
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                        });
                } else {
                    PaymentsService.insertPayment(paymentObject)
                        .then(function (response) {
                            postPaymentAction(response);
                            GoogleTagManagerHelperService.pushVirtualPageView('payments/international-payment/toconfirm', 'payments international-payment toconfirm', $location.host() + $location.host() + 'payments/international-payment/toconfirm');
                        })
                        .catch(function (error) {
                            NotificationService.showMessage(error, 'error');
                        })
                        .finally(function () {
                            vm.formSubmitted = false;
                        });
                }
            }
        }

        function postPaymentAction(response) {
            vm.paymentResponse = response;
            vm.paymentResponse.dueDate = new Date(response.dueDate);
            vm.paymentResponse.paymentTypeGroup = 'ForeignPayment';
            vm.paymentResponse.purposeCodeValue = vm.tempForpObj.purposeCodeSelect.optionValue;
            switch (response.paymentCharges) {
            case "1":
                vm.paymentResponse.bankCharges = "Payers pays charges";
                break;
            case "2":
                vm.paymentResponse.bankCharges = "Beneficiary pays charges";
                break;
            case "3":
                vm.paymentResponse.bankCharges = "Shared charges";
                break;
            }

            // Remap payment mode
            var modality = _.find(vm.paymentModeOptions, {
                id: vm.paymentResponse.modalityCode
            });
            if (modality) {
                vm.paymentResponse.modalityCode = modality.id + " " + modality.description;
            }

            var country = _.find(vm.countriesOptions, {
                code: vm.paymentResponse.beneficiaryBankCountryCode
            });
            if (country) {
                vm.paymentResponse.beneficiaryBankCountryCode = country.code + " " + country.description;
            }

            vm.paymentResponse.payeeListId = vm.tempForpObj.recipientStatusRadiobutton.type;
            vm.steps = {
                step1: false,
                step2: true,
                step3: false
            };
        }

        vm.pushGtmUrgentPaymentEvent = function () {
            GoogleTagManagerHelperService.pushEvent('InternationalPayment', 'PaymentIsUrgentSelection', (vm.tempForpObj.isUrgentPaymentCheckbox)?'Urgent payment checked':'Urgent payment unchecked');
        };
        // endregion
        vm.init();
    }
}());
