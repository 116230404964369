(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:autoFocus
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="core">
       <file name="index.html">
        <auto-focus></auto-focus>
       </file>
     </example>
   *
   */
  angular
    .module('core')
    .directive('autoFocus', autoFocus);

  function autoFocus($timeout) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'core/directives/auto-focus-directive.tpl.html',
      replace: false,
      controllerAs: 'autoFocus',
      controller: function () {
        var vm = this;
        vm.name = 'autoFocus';
      },
      link: function(scope, element) {
        $timeout(function(){
          element[0].focus();
        }, 0);
      }
    };
  }
}());
