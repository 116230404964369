(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name documents.controller:DocumentsCtrl
   *
   * @description
   *
   */
  angular
    .module('documents')
    .controller('DocumentsCtrl', DocumentsCtrl);

  function DocumentsCtrl($rootScope, $log, HelperService, AlertService, DocumentsService, $translate, GoogleTagManagerHelperService) {
    var vm = this;
    vm.ctrlName = 'DocumentsCtrl';
    vm.country = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.selectPeriod = function (item) {
      vm.selectedPeriod = item;

      //var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      //vm.timelineFilter.dateGTE = timeRange.valueDateGTE;
      //vm.timelineFilter.dateLTE = timeRange.valueDateLTE;

      vm.resetTimelineFilter();

      //in case of ALL time filter, return from .getDateRange is null so GTE should be 01.01.2017 (time of start for documents)
      //and LTE is current date
      //TSK-114653
      vm.timelineFilter.dateGTE = HelperService.getDateRange(vm.selectedPeriod.item.key).valueDateGTE || new Date('January 1, 2017');
      vm.timelineFilter.dateLTE = HelperService.getDateRange(vm.selectedPeriod.item.key).valueDateLTE || new Date();

      vm.getTimelineItemsGrouped(true);
      GoogleTagManagerHelperService.pushEvent('Documents', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
    };

    vm.resetTimelineFilter = function () {

      vm.searchFilterExpanded = false;
    };


    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      },
      {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }, {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }, {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }, {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      },
      {
        item: {
          key: 'all',
          description: 'core.period.all'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[3];

    vm.timelineFilter = {
      page: 0,
      dateGTE: HelperService.getDateRange(vm.selectedPeriod.item.key).valueDateGTE,
      dateLTE: HelperService.getDateRange(vm.selectedPeriod.item.key).valueDateLTE,
      orderBy: 'date desc, id desc',
      pageSize: 10
    };
    vm.recompile = {
      timeline: true
    };
    vm.timelineListGrouped = {};


    vm.loadMoreTimelineItemsGrouped = function () {
      vm.getTimelineItemsGrouped(false);

    };

    vm.getTimelineItemsGrouped = function (reset) {

      if (reset) {
        vm.timelineFilter.page = 0;
      } else {
        vm.timelineFilter.page += 1;
      }


      DocumentsService.getDocuments(vm.timelineFilter)
        .then(function (response) {
          vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.timelineListGrouped,
            newData: response,
            groupFunction: function (item) {
              var dateYear = new Date(item.date || item.dtSent);

              // If we want to group only by date component, without hours
              dateYear.setHours(0, 0, 0, 0);
              return dateYear;
            },
            virtualRowFunction: function (e) {
              var year = new Date(e).getFullYear();
              var virtualRow = {
                isVirtual: true,
                date: vm.country == 'hr' ? new Date(e) : year
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset
          });

          $log.debug("groupedDocumentsTimeline", vm.timelineListGrouped);
          vm.recompile.timeline = true;
        }).catch(function (error) {
          $log.error(error);
        });
    };

    vm.downloadPdf = function (item) {
      var id = item.id;
      if (item.downloadPdfInProgress) return;
      item.downloadPdfInProgress = true;
      var action;
      if (vm.country == 'hr') {
        action = DocumentsService.downloadDocument(id, item.title)
      } else {
        action = DocumentsService.downloadDocumentPdf(id)
      }

      action.then(function () {
        $rootScope.$broadcast("documentDownloaded");
      }, function (error) {
          AlertService.infoAlert({
            text: 'documents.documentDownloadFailed'
          });
        }).finally(function () {
          item.downloadPdfInProgress = false;
        })

    };
    vm.init = function () {
      vm.getTimelineItemsGrouped(true);
    };

    vm.init();
  }
}());
