(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name accounts.factory:AccountsService
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .factory('AccountsService', AccountsService);

  function AccountsService($rootScope, EndpointsService, $q, $localStorage, $http, $log, $uibModal, SessionService, HelperService, NotificationService) {
    var AccountsServiceBase = {};

    AccountsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.accounts, {
          params: params
        })
          .then(function (data) {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
              // START Add property for "eur" first filtering
              _.each(data.result, function (account) {
                if (account.isParentAccount) {
                  account.sortOrderId = (account.currencySymbol == "EUR") ? 1 : 2;
                  _.each(account.subAccountList, function (subAcc) {
                    subAcc.sortOrderId = (subAcc.currencySymbol == "EUR") ? 1 : 2;
                  })
                }
              });
              // END Add property for "eur" first filtering
            }

            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getAll = function (params) {
      var predefinedParams = {
        page: 0,
        pageSize: 1000,
        orderBy: 'accountType asc,iban,currencySymbol, accountDescription'
      };
      var extended = _.extend(predefinedParams, params);
      return AccountsServiceBase.getPage(extended);
    };

    AccountsServiceBase.openModal = function (modalObject) {
      var modalInstance = $uibModal.open({
        templateUrl: 'accounts/acc-trans-detail-modal.tpl.html',
        controller: 'AccTransDetailModalCtrl',
        controllerAs: 'accTransDetailModal',
        resolve: {
          transaction: function () {
            return modalObject.transaction
          },
          account: function () {
            return modalObject.account
          }
        },
        backdrop: 'static'
      });
    };

    AccountsServiceBase.openBlockModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'accounts/connected-cards-block-modal.tpl.html',
        controller: 'ConnectedCardsBlockModalCtrl',
        controllerAs: 'connectedCardsBlockModal',
        backdrop: 'static'
      });
    };

    var paramsObject = {
      includeList: ['PaymentList']
    };

    AccountsServiceBase.getAccountById = function (id, paramsObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.accounts_id.replace('{id}', id), {
          params: paramsObject
        }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.eSavingBookCloseOverview = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links["accounts_eSavingBookCloseOverView?id=id"].replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.eSavingBookContractWarning = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links["accounts_eSavingBookContractWarning"])
          .then(function (data) {
            // console.log("data",data);

            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.eSavingBookContractWarningContractSign = function (object) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        // console.log("response",response);
        // console.log("object",object);
        $http.put(response.links["eSavingBookRequests_contractSign"], object)
          .then(function (data) {
            // console.log("data",data);
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.updateAccount = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.accounts_id.replace('{id}', id), params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.openEbookRequestModal = function (ebook) {
      var modalInstance = $uibModal.open({
        templateUrl: 'accounts/e-book-request-modal.tpl.html',
        controller: 'EBookRequestModalCtrl',
        controllerAs: 'eBookRequestModal',
        resolve: {
          ebook: function () {
            return ebook
          }
        },
        backdrop: 'static'
      });
    };

    // Method to load all account and set all accounts ids in session storage (for carousel)
    AccountsServiceBase.loadAllAccounts = function () {
      var deferred = $q.defer();

      var accountsQuery = {
        page: 0,
        pageSize: 100,
        orderBy: 'isFavorite desc,accountType,iban,currencyCode',
        //space required - if accountType,iban,... uses desc/asc, filter should be: 'isFavorite desc, accountType desc,iban, currencyCode desc'
        includeList: ['loanAccount', 'depositAccount', 'cardList', 'userAccountList']
      };

      // Include list for Cro is different than for Srb/Mne
      if ($localStorage.code !== "sr" && $localStorage.code !== "mne") {
        accountsQuery.includeList = ['loanAccount', 'depositAccount', 'cardList', 'userAccountList', 'cardAccount'];
      }

      this.getPage(accountsQuery).then(function (response) {
        deferred.resolve(response.result);
        /*          var allAccountIds = [];
                  _.forEach(response.result, function(account){
                    allAccountIds.push({id: account.accountId, type: account.accountType.group });
                  });
                  sessionStorage.setItem('accounts', JSON.stringify({accountObjects: allAccountIds}));*/

      }).catch(function (err) {
        $log.error(err);
      });

      return deferred.promise;
    };

    /*
        AccountsServiceBase.refreshAccountsStorage = function (items) {
          sessionStorage.removeItem('accounts');

          // If we are just updating current list. No need for API call
          if (items) {
            sessionStorage.setItem('accounts', JSON.stringify({accountObjects: items}));
          } else {
            // In case we want full reload of all accounts
            return this.loadAllAccounts();
          }
        };*/

    AccountsServiceBase.updateCardStatus = function (accountId, card) {

      var data = {
//        blockCard: (card.temporaryStatus == 'SentUnblockRequest') ? true : false,
        cardId: card.cardId
      };
      if (card.temporaryStatus) {
        data.BlockCard = (card.temporaryStatus == 'SentUnblockRequest') ? true : false;
        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sr") {
          data.ActivateCard = (card.temporaryStatus == 'SentActivationRequest') ? true : false;
          if (data.ActivateCard) {
            data.last4Digits = card.last4Digits;
          }
        }
      } else {
        data.BlockCard = (card.cardStatus === 'Active') ? true : false;
        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sr") {
          data.ActivateCard = (card.cardStatus == 'Inactive') ? true : false;
          if (data.ActivateCard) {
            data.last4Digits = card.last4Digits;
          }
        }
      }

      return EndpointsService.getLinks().then(function (response) {
        return $http.put(response.links.accounts_id.replace('{id}', accountId), data);
      })
    };

    AccountsServiceBase.getCashWithdrawalOrder = function (id) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.cashWithdrawalOrders_id.replace('{id}', id))
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error.data);
        });
      });
    };

    function insert(order) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          SessionService.saveStorageObject(order, 'cashWithdrawalOrder');
          $http.post(response.links.cashWithdrawalOrders, order)
            .then(function (data) {
              SessionService.removeStorageObject();
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error.data);
        });
      });
    }

    AccountsServiceBase.confirmCashWithdrawalOrder = function (order) {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        return validateOrder(order);
      }
      return insert(order);
    };

    function validateOrder(request) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.cashWithdrawalOrders_validate, request)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    AccountsServiceBase.updateCashWithdrawalOrder = function (id, order) {

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        return validateOrder(order);
      }

      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.cashWithdrawalOrders_id.replace('{id}', id), order)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.deleteCashWithdrawalOrder = function (id) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.delete(response.links.cashWithdrawalOrders_id.replace('{id}', id))
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error);
        });
      });
    };

    AccountsServiceBase.signCashWithdrawalOrder = function (params, payment) {

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "sl") {
        return insert(payment);
      }


      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          console.log(response);
          $http.put(response.links.cashWithdrawalOrders_sign, params)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error);
        });
      });
    };

    
    AccountsServiceBase.getStatementDeliveryWarning = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.accounts_statementDeliveryWarning, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.processStatementDeliveryResponse = function (selectedChannel) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.accounts_processStatementDeliveryResponse, {
          userResponse: selectedChannel
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getDepositOrderAccounts = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.accounts_depositOrderAccounts, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getOrderInvAccounts = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.accounts_getOrderInvAccounts, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };


    AccountsServiceBase.getAllCards = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.cards, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getReservations = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.reservations, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.newCardOrderMonthlyShareOfLiabilitiesType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.newCardOrderMonthlyShareOfLiabilitiesType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };
    AccountsServiceBase.newCreditCardOrderCardTypeType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.newCreditCardOrderCardTypeType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.newCreditCardOrderTearDateType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.newCreditCardOrderTearDateType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    AccountsServiceBase.newCreditCardOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.newCreditCardOrder, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error);
        });
      });
    };

    AccountsServiceBase.validateNewCreditCardOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.newCreditCardOrder_validate, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })
        }, function (error) {
          reject(error);
        });
      });
    };

    AccountsServiceBase.creditPossibilityOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.creditPossibilityOrder, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error);
        });
      });
    };

    AccountsServiceBase.validateCreditPossibilityOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.creditPossibilityOrder_validate, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })
        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.creditPossibilityOrderTearDateType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.creditPossibilityOrderTearDateType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    AccountsServiceBase.creditCardLimitOrderLimitTypeType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.creditCardLimitOrderLimitTypeType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };
    AccountsServiceBase.creditCardLimitOrderPercentOfLimitType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.creditCardLimitOrderPercentOfLimitType, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getNewPinOrderDeliveryType = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if (response.links.newPinOrderDeliveryType) {
          $http.get(response.links.newPinOrderDeliveryType, {
            params: params
          }).then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.validateCreditCardLimitOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.creditCardLimitOrder_validate, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.validateCreditCardNewPinOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          if(response.links.creditCardNewPinOrder_validate) {
            $http.put(response.links.creditCardNewPinOrder_validate, object)
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (error) {
                reject(error.data);
              })
          }
        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.validateAccountNewPinOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          if(response.links.accountNewPinOrder_validate) {
            $http.put(response.links.accountNewPinOrder_validate, object)
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (error) {
                reject(error.data);
              })
          }
        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.creditCardLimitOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          $http.post(response.links.creditCardLimitOrder, object)
            .then(function (data) {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error.data);
            })

        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.creditCardNewPinOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          if(response.links.creditCardNewPinOrder) {
            $http.post(response.links.creditCardNewPinOrder, object)
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (error) {
                reject(error.data);
              })
          }
        }, function (error) {
          reject(error.data);
        });
      });
    };

    AccountsServiceBase.accountNewPinOrder = function (object) {
      return $q(function (resolve, reject) {
        return EndpointsService.getLinks().then(function (response) {
          if(response.links.accountNewPinOrder) {
            $http.post(response.links.accountNewPinOrder, object)
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (error) {
                reject(error.data);
              })
          }
        }, function (error) {
          reject(error.data);
        });
      });
    };


    // New method for transactions downlaoding
    AccountsServiceBase.getTransactionReportLink = function (id, dateFrom, dateTo, reportType) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

        var property = "transactions_downloadTransactionsLink?accountId={accountId}&datefrom={datefrom}&dateto={dateto}&parameter={parameter}&transactionType={transactionType}&extendedTransactionType={extendedTransactionType}&amountGTE={amountGTE}&amountLTE={amountLTE}&creditorOrDebtorName={creditorOrDebtorName}&creditorOrDebtorAccount={creditorOrDebtorAccount}";

        var url = response.links[property].replace("{accountId}", id).replace("{datefrom}", dateFrom).replace
        ("{dateto}", dateTo).replace("{parameter}", reportType).replace("{transactionType}", 0).replace("{extendedTransactionType}", 0).replace
        ("{amountGTE}", 0).replace("{amountLTE}", 10000000000).replace("{creditorOrDebtorName}", "/").replace("{creditorOrDebtorAccount}", "/");

        $http.get(url)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.getTransactionReportLinkSlo = function (id, dateFrom, dateTo, reportType) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {

        var property = "transactions_downloadTransactionsLink?accountId={accountId}&datefrom={datefrom}&dateto={dateto}&parameter={parameter}&transactionType={transactionType}&extendedTransactionType={extendedTransactionType}&amountGTE={amountGTE}&amountLTE={amountLTE}&creditorOrDebtorName={creditorOrDebtorName}&creditorOrDebtorAccount={creditorOrDebtorAccount}";

        var url = response.links[property].replace("{accountId}", id).replace("{datefrom}", dateFrom).replace("{dateto}", dateTo).replace('{parameter}', 'DownloadTurnoversExcel')
        .replace("&transactionType={transactionType}&extendedTransactionType={extendedTransactionType}&amountGTE={amountGTE}&amountLTE={amountLTE}&creditorOrDebtorName={creditorOrDebtorName}&creditorOrDebtorAccount={creditorOrDebtorAccount}", "");

        $http.get(url)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    AccountsServiceBase.downloadTransactionReport = function (id, dateFrom, dateTo, reportType) {
      var nonBlockingWindow = window.open('', '_self');
      AccountsServiceBase.getTransactionReportLink(id, dateFrom, dateTo, reportType)
        .then(function (reportLink) {
          console.log("reportLink", reportLink);
          //pdfLink = pdfLink.data;
          if (reportLink) {
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(reportLink.url);
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    
    
    return AccountsServiceBase;
  }
}());
