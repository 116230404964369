<section id="main">
  <div class="container-fluid">
    <div>

      <div class="redesign-title col-sm-12">
        <h2 class="f-28" translate="{{ APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'eInvoicesModal.einvoicesSlo' : 'eInvoicesModal.einvoices'}}"> </h2>
      </div>
      <div class="row">
         <div class="col-xs-12 col-sm-6 m-b-20">
              <div class="account-item-add-message app-dblue-card" ng-click="einvoicesOverview.registerIssuer()">
                <div class="round-btn-container2" >
                  <div class="round-btn-m">
                    <i class="icon-r icon-add icon-add-msg"></i>
                  </div>
                  <div class="round-btn-label-msg" >
                    <h3 class="round-btn-label-msg">{{'eInvoiceOverview.register' | translate}}</h3>
                  </div>
                </div>
              </div>
      </div>
    </div>

      <div class="row">
        <div class="col-xs-12">
        <div class="msg-tab-container cursor-pointer">
          <div class="msg-tab-bh einv-tab"
               ng-class="{'selected': einvoicesOverview.showSubmenuItemType == 'unpaidTab', 'msg-tab-bh-fourth' : APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' }"
               ng-click="einvoicesOverview.selectTab('unpaidTab', 'eInvoicesModal.waitingForPayment')">
            <a class="ver--align" translate="{{ APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'eInvoicesModal.waitingForPaymentSlo' : 'eInvoicesModal.waitingForPayment'}}"></a>
          </div>
          <div class="einv-tab msg-tab-bh cursor-pointer"
               ng-class="{'selected': einvoicesOverview.showSubmenuItemType=='processedTab', 'msg-tab-bh-fourth' : APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'}"
               ng-click="einvoicesOverview.selectTab('processedTab', 'eInvoicesModal.processedTab')">
            <a class="ver--align" translate="{{ APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' ? 'eInvoicesModal.processedTabSlo' : 'eInvoicesModal.processedTab'}}"></a>
          </div>

          <!--FOURTH TAB - eGeneralDocs for SLO-->
          <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'"
               class="einv-tab msg-tab-bh-fourth"
               ng-class="{'selected': einvoicesOverview.showSubmenuItemType=='documentsTab'}"
               ng-click="einvoicesOverview.selectTab('documentsTab', 'eInvoiceOverview.documentsTab')">
            <a class="ver--align" translate="eInvoiceOverview.documentsTab"></a>
          </div>
          <!--FOURTH TAB - eGeneralDocs for SLO END-->

          <div class="einv-tab msg-tab-bh"
               ng-class="{'selected': einvoicesOverview.showSubmenuItemType=='listTab', 'msg-tab-bh-fourth' : APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'}"
               ng-click="einvoicesOverview.selectTab('listTab', 'eInvoiceRegistration.listOfRegistrations')">
            <a class="ver--align" translate="eInvoiceRegistration.listOfRegistrations"></a>
          </div>


        </div>
        </div>
      </div>
      <!-- TABS -->
      <div class="row">
      <div class="col-sm-12">
        <div class="bg-white">
          <!--UNPAID TAB-->
            <div  ng-if="einvoicesOverview.showSubmenuItemType == 'unpaidTab'">
              <!-- eInvoices to pay -->
              <div kcd-recompile="einvoicesOverview.einvoicesListFirstTab.result">

                <div class="row">
                  <!-- Filter e-invoices to pay by name -->
                  <div ng-if="einvoicesOverview.einvoicesListFirstTab.result.length > 0">
                    <div class="col-sm-12 col-md-12">
                      <div class="row m-5">
                        <div class="col-xs-12"> <h4 translate="eInvoiceOverview.eInvoicesToPay" class="subtitle"></h4></div>
                      </div>

                    </div>
                    <div class="col-sm-5">
                      <div class="row m-5">
                        <div class="col-xs-12">
                          <div class="form-group search-field">
                            <input type="text" class="form-control input-field" data-ng-model="einvoicesOverview.searcheinvoicesToPay.creditorName"
                                   placeholder="{{'eInvoicesModal.creditorNameSearch' | translate}}"/>
                            <i class="glyphicon glyphicon-search"></i>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div ng-show="einvoicesOverview.einvoicesListFirstTab.result.length != 0"
                       class="col-sm-12 col-md-12 tab-field"
                       data-ng-repeat="eInvoice in einvoicesOverview.einvoicesListFirstTab.result | filter: einvoicesOverview.searcheinvoicesToPay">

                    <div class="p-20" data-ng-include="'e-invoices/e-invoice-detail.tpl.html'"></div>
                  </div>
                </div>
              </div>
              <div class="t-a-c p-10 m-t-20 no-data-image" ng-show="einvoicesOverview.einvoicesListFirstTab.result.length == 0 || !einvoicesOverview.einvoicesListFirstTab.result">
                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                <h3 class="no-data-label" translate="eInvoicesModal.noAvailableEInvoices"></h3>
              </div>
              <br/>
              <div class="show-more-btn ">
                <button ng-show="einvoicesOverview.einvoicesListFirstTab.hasMore == true"
                        data-ng-click="einvoicesOverview.loadMoreEinvoicesFirstTab(false)"
                        type="input"
                        class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                        translate="eInvoicesModal.loadMoreEinvoices"></button>
              </div>
              <div class="clear"></div>
            </div>
          <!--UNPAID TAB END-->

          <!--PROCESSED TAB-->
            <div ng-if="einvoicesOverview.showSubmenuItemType == 'processedTab'">
              <!-- eInvoices to pay -->
              <div kcd-recompile="einvoicesOverview.einvoicesListSecondTab.result">

                <div class="row">

                  <!-- Status filter -->
                  <div class="col-xs-6 col-xs-offset-6">
                    <div class="dropdown acc-period-select m-30 pull-right" style="display: inline-block">
                      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="true">
                        {{einvoicesOverview.processedFilter.item.description | translate}}
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li data-ng-repeat="filter in einvoicesOverview.processedFilterList">
                          <a href="" class="f-13" data-ng-click="einvoicesOverview.processedFilterChange(filter)">
                            {{filter.item.description | translate}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-xs-12 tab-field"
                       data-ng-repeat="eInvoice in einvoicesOverview.einvoicesListSecondTab.result"
                       ng-show="einvoicesOverview.einvoicesListSecondTab.result.length != 0">

                    <div class="p-20" data-ng-include="'e-invoices/e-invoice-detail.tpl.html'"></div>
                  </div>
                </div>
              </div>
              <div class="t-a-c p-10 m-t-20 no-data-image" ng-show="einvoicesOverview.einvoicesListSecondTab.result.length == 0 || !einvoicesOverview.einvoicesListSecondTab.result">
                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                <h3 class="no-data-label" translate="eInvoicesModal.noAvailableEInvoices"></h3>
              </div>
              <br/>
              <div class="show-more-btn ">
                <button ng-show="einvoicesOverview.einvoicesListSecondTab.hasMore == true"
                        data-ng-click="einvoicesOverview.loadMoreEinvoicesSecondTab(false)"
                        type="input"
                        class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                        translate="eInvoicesModal.loadMoreEinvoices"></button>
              </div>
            </div>
          <!--PROCESSED TAB END -->

          <!--LIST OF REGISTRATION TAB-->
            <div ng-if="einvoicesOverview.showSubmenuItemType == 'listTab'">
              <div kcd-recompile="einvoicesOverview.registrationsListActive.result">
                <div class="row">
                  <div class="col-xs-6 col-xs-offset-6">
                    <div class="dropdown acc-period-select m-30 pull-right" style="display: inline-block">
                      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="true">
                        {{einvoicesOverview.processedRegsFilter.item.description | translate}}
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li data-ng-repeat="filter in einvoicesOverview.processedRegsFilterList">
                          <a href="" class="f-13" data-ng-click="einvoicesOverview.processedRegsFilterChange(filter)">{{filter.item.description | translate}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 tab-field"
                       data-ng-repeat="registration in einvoicesOverview.registrationsListActive.result"
                       ng-show="einvoicesOverview.registrationsListActive.result.length != 0">

                    <div class="p-20" data-ng-include="'e-invoices/e-invoice-registration-detail.tpl.html'"></div>
                  </div>
                </div>
              </div>
              <div class="t-a-c p-10 m-t-20 no-data-image" ng-show="einvoicesOverview.registrationsListActive.result.length == 0 || !einvoicesOverview.registrationsListActive.result">
                <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
                <h3 class="no-data-label" translate="eInvoicesModal.noData"></h3>
              </div>
              <br/>
              <div class="show-more-btn ">
                <button ng-show="einvoicesOverview.registrationsListActive.hasMore == true"
                        data-ng-click="einvoicesOverview.loadMoreEinvoicesThirdTab(false)"
                        type="input"
                        class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                        translate="eInvoicesModal.loadMoreRegistrationsDeregistrations"></button>
              </div>
            </div>
          <!--LIST OF REGISTRATION TAB END-->

          <!-- DOCUMENTS TAB START -->
          <div ng-if="einvoicesOverview.showSubmenuItemType == 'documentsTab'">
            <div kcd-recompile="einvoicesOverview.documentsListTab.result">
              <div class="row">
                <div ng-if="einvoicesOverview.documentsListTab.result.length > 0">

                  <div class="col-sm-5 p-t-30">
                    <div class="row m-5">
                      <div class="col-xs-12">
                        <div class="form-group search-field">
                          <input type="text" class="form-control input-field" data-ng-model="einvoicesOverview.searchEDocumentToPay.creditorName"
                                 placeholder="{{'eInvoicesModal.creditorNameSearch' | translate}}"/>
                          <i class="glyphicon glyphicon-search"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 tab-field"
                     data-ng-repeat="eDocument in einvoicesOverview.documentsListTab.result  | filter: einvoicesOverview.searchEDocumentToPay"
                     ng-show="einvoicesOverview.documentsListTab.result.length != 0">

                  <div class="p-20" data-ng-include="'e-invoices/e-document-details.tpl.html'"></div>
                </div>
              </div>
            </div>
            <div class="t-a-c p-10 m-t-20 no-data-image" ng-show="einvoicesOverview.documentsListTab.result.length == 0 || !einvoicesOverview.documentsListTab.result">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h3 class="no-data-label" translate="eInvoicesModal.noData"></h3>
            </div>
            <br/>
            <div class="show-more-btn ">
              <button ng-show="einvoicesOverview.documentsListTab.hasMore == true"
                      data-ng-click="einvoicesOverview.loadMoreEInvoicesFourthTab(false)"
                      type="input"
                      class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                      translate="eInvoicesModal.loadMoreRegistrationsDeregistrations"></button>
            </div>
          </div>
          <!-- DOCUMENTS TAB END -->


        </div>
      </div>
      </div>
      <!-- TABS END -->
    </div>
  </div>
</section>
