(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SmsAndNotificationModalCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('SmsAndNotificationModalCtrl', SmsAndNotificationModalCtrl);

  function SmsAndNotificationModalCtrl($q, $uibModalInstance, SettingsService, object, HelperService, AlertService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'SmsAndNotificationModalCtrl';
    vm.object = object;
    console.log('ORDER OBJECT ',vm.object);

      // user: vm.model.userSettings,
      // orderItem: item,
      // orderObject: obj,
      // type: type,
      // accountBased: accountBased,
      // account: vm.selectedTrrAccount,
      // value: value

    vm.step = 1;
    vm.tempObj = {
      amount: 0,
      showAmount: false
    };
    vm.orderDate = new Date();


    function createMonthDays() {
      var days = [];
      for(var i = 1;i<=new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate(); i++){
        days.push({id:i+".", description:i+"."});
      }
      return days;
    }
    function createDayHours() {
      var hours = [];
      for(var i = 1;i<25;i++){
        hours.push({id:i+"",description:i+":00"});
      }
      return hours;
    }

    vm.periods = {};
    // vm.periods.periodTypes = [
    //   {id: 'D', description: 'settings.smsModal.daily'},{id: 'W', description: 'settings.smsModal.weekly'}, {id:'M', description: 'settings.smsModal.monthly'}
    // ];
    // vm.periods.dailyTimePeriods = createDayHours();
    // vm.periods.weeklyTimePeriods = [{id: 'Monday', description: 'settings.smsModal.weekDays.monday'},
    //   {id: 'Tuesday', description: 'settings.smsModal.weekDays.tuesday'},
    //   {id: 'Wednesday', description: 'settings.smsModal.weekDays.wednesday'},
    //   {id: 'Thursday', description: 'settings.smsModal.weekDays.thursday'},
    //   {id: 'Friday', description: 'settings.smsModal.weekDays.friday'},
    //   {id: 'Saturday', description: 'settings.smsModal.weekDays.saturday'},
    //   {id: 'Sunday', description: 'settings.smsModal.weekDays.sunday'}];
    // vm.periods.monthlyTimePeriods = createMonthDays();

    vm.period = '';
    //vm.periodType = vm.periods.periodTypes[0]; //D, M, W
    // vm.dailyTimePeriod = vm.periods.dailyTimePeriods[0]; //1:00, 2:00, ..
    // vm.weeklyTimePeriod = vm.periods.weeklyTimePeriods[0]; //Monday, Tuesday, ...
    // vm.monthlyTimePeriod = vm.periods.monthlyTimePeriods[0]; //1, 2, 3, ...

    function loadPeriodTypes() {
      var params = null;
      return SettingsService.getPeriodTypes(params)
        .then(function (response) {
          vm.periods.periodTypes = response.result;
          vm.periodType = vm.periods.periodTypes[0];
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }
    function loadDailyTimes() {
      var params = null;
      return SettingsService.getTimeTypes(params)
        .then(function (response) {
          vm.periods.dailyTimePeriods = _.sortBy(response.result, function (day) {
            return parseInt(day.id);
          });
          vm.dailyTimePeriod = vm.periods.dailyTimePeriods[0]; //1:00, 2:00, ..
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }
    function loadWeeklyTimes() {
      var params = null;
      return SettingsService.getPeriodOfWeekTypes(params)
        .then(function (response) {
          vm.periods.weeklyTimePeriods = response.result;
          vm.weeklyTimePeriod = vm.periods.weeklyTimePeriods[0]; //Monday, Tuesday, ...
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }
    function loadMonthlyTimes() {
      var params = null;
      return SettingsService.getPeriodOfMonthTypes(params)
        .then(function (response) {
          vm.periods.monthlyTimePeriods = _.sortBy(response.result, function (month) {
            return parseInt(month.id);
          });
          vm.monthlyTimePeriod = vm.periods.monthlyTimePeriods[0]; //1, 2, 3, ..
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    }

    function init() {
      var promises = [];
      if(vm.object.orderObject.subOrderId == '30' || vm.object.orderObject.subOrderId == '40' || vm.object.orderObject.subOrderId == '50'){
        promises.push(loadPeriodTypes(), loadDailyTimes(),loadWeeklyTimes(),loadMonthlyTimes());
      }

      populateSubOrderId70();


      if(promises.length > 0){
        $q.all(promises)
          .then(function(responses){
          populateSubOrderId30();
          populateSubOrderId40Or50();
          console.log('RESPONSENS', responses);
        }).catch(function(error){
          NotificationService.showMessage(error, 'error');
        });
      }
    }

    function populateSubOrderId30() {
      if(vm.object.type == 'SMS'){
        angular.extend(vm.object.orderObject, vm.object.orderObject.smsNotification)
      }else{
        angular.extend(vm.object.orderObject, vm.object.orderObject.emailNotificaton)
      }
      if (vm.object.orderObject.subOrderId == '30') {
        var dailyTime = _.find(vm.periods.dailyTimePeriods, {id: vm.object.orderObject.periodHour});
        if (dailyTime)
          vm.dailyTimePeriod = dailyTime;
        else
          vm.dailyTimePeriod = vm.periods.dailyTimePeriods[0];

        vm.periodType = _.find(vm.periods.periodTypes, {id: vm.object.orderObject.periodType});
        if (vm.periodType) {
          if (vm.periodType.id == 'D') {
            vm.period = '';
          }
          if (vm.periodType.id == 'W')
            vm.period = _.find(vm.periods.weeklyTimePeriods, {id: vm.object.orderObject.period});

          if (vm.periodType.id == 'M')
            vm.period = _.find(vm.periods.monthlyTimePeriods, {id: vm.object.orderObject.period+"."});
        } else {
          vm.periodType = vm.periods.periodTypes[0];
          vm.period = '';
        }
      }
    }

    function populateSubOrderId40Or50() {
      if(vm.object.type == 'SMS'){
        angular.extend(vm.object.orderObject, vm.object.orderObject.smsNotification)
      }else{
        angular.extend(vm.object.orderObject, vm.object.orderObject.emailNotificaton)
      }
      if (vm.object.orderObject.subOrderId == '40' || vm.object.orderObject.subOrderId == '50') {
        vm.tempObj.showAmount = true;
        var dailyTimeTemp = _.find(vm.periods.dailyTimePeriods, {id: vm.object.orderObject.periodHour});
        if (dailyTimeTemp)
          vm.dailyTimePeriod = dailyTimeTemp;
        else
          vm.dailyTimePeriod = vm.periods.dailyTimePeriods[0];

        vm.periodType = _.find(vm.periods.periodTypes, {id: 'D'});

        if (vm.object.orderObject.amount)
          vm.tempObj.amount = vm.object.orderObject.amount;
      }


    }

    function populateSubOrderId70() {
      if(vm.object.orderObject.subOrderId == '70'){

        if(vm.object.type == 'SMS'){
          angular.extend(vm.object.orderObject, vm.object.orderObject.smsNotification);
        }else {
          angular.extend(vm.object.orderObject, vm.object.orderObject.emailNotificaton);
        }

        vm.tempObj.showAmount = true;

        if (vm.object.orderObject.amount)
          vm.tempObj.amount = vm.object.orderObject.amount;
      }
    }

    // if(vm.object.orderObject.amount){
    //   vm.tempObj.amount = vm.object.orderObject.amount;
    // }

    function createOrderObject() {

      var temp = {
        "accountId":vm.object.account.accountNumber,
        "description":vm.object.orderObject.description,
        "customerName": vm.object.user.firstName + " " + vm.object.user.lastName,
        "gsm": vm.object.user.gsm,
        "email": vm.object.user.notificationEmail,
        "terms": true,
        "note":"string",
        "notificationSubType": {
          "id": vm.object.orderObject.subOrderId,
          "description": vm.object.orderObject.description
        },
        "notificationType": {
          "id": vm.object.type == 'EMAIL' ? 'SMTP':'SMS',
          "description": "string"
        },
        "disableNotification": vm.object.value


      };

      if(vm.object.orderObject.subOrderId == '30'){
        temp.period = vm.periodType;
        temp.notificationTime = vm.dailyTimePeriod;
        if(temp.period.id == 'W'){
          temp.periodDayOfWeek = vm.weeklyTimePeriod;
        }
        else if(temp.period.id == 'M'){
          temp.periodDayOfMonth = vm.monthlyTimePeriod;
          temp.periodDayOfMonth.id = temp.periodDayOfMonth.id.substring(0,temp.periodDayOfMonth.id.length-1); //when sending { id: "1."... it fails but id:"1" is fine
        }
      }

      if(vm.object.orderObject.subOrderId == '40' || vm.object.orderObject.subOrderId == '50'){
        temp.amount = vm.tempObj.amount;
        temp.period = vm.periodType;
        temp.notificationTime = vm.dailyTimePeriod;
      }

      if(vm.object.orderObject.subOrderId == '70'){
        temp.amount = vm.tempObj.amount;
      }

      return temp;

    }

    vm.next = function () {
      vm.tempObj.formSubmitted = true;
      if(!vm.smsAndNotifForm.$valid)
        return;

      vm.step = 2;

    };

    vm.confirm = function () {
      if (vm.confirmationInProgress) return;
      vm.confirmationInProgress = true;
      if (vm.object.accountBased) {
        SettingsService.changeTrrBasedNotifications(createOrderObject())
          .then(function (response) {
            //vm.step = 3;
            $uibModalInstance.close();
            AlertService.infoAlert({
              text: 'orderSoftToken.successTxt'
            });
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          }).finally(function() {
            vm.confirmationInProgress = false;
        });
      } else {
        SettingsService.changeUserBasedNotifications(createOrderObject())
          .then(function (response) {
            // vm.step = 3;
            $uibModalInstance.close();
            AlertService.infoAlert({
              text: 'orderSoftToken.successTxt'
            });
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          }).finally(function() {
          vm.confirmationInProgress = false;
        });
      }
    };

    init();
  }
}());
