<div class="thank-you-header" style="padding-bottom: 0">
  <div class="row">
    <div class="col-xs-12">
      <a class="pointer pull-right" ng-click="$close()">
        <i class="zmdi zmdi-close zmdi-hc-2x" style="font-size: 26px; color: white"></i>
      </a>
    </div>
  </div>
</div>
<div class="modal-body thank-you-body">
  <div class="settings-content" style="background-color: #FF4D5A; padding: 0px;">
    <div class="row">
      <div class="col-lg-12">
        <h3 style="white-space: pre-wrap;" class="thank-you-text">{{thankYouPopup.tYDText2}}</h3>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="background-color: #FF4D5A; padding: 0px;">
    <div class="col-lg-12">
      <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh' && thankYouPopup.currentLang !== 'en' && APP_GLOBAL_SETTINGS.defaultData.BiHLocation"
             translate="{{(APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka')
          ? 'dashboard.tydUnsubscribeBL' : 'dashboard.tydUnsubscribeSA'}}"
             class="thank-you-text" style="text-align: left">
      </label>
      <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'bh' && thankYouPopup.currentLang === 'en' && APP_GLOBAL_SETTINGS.defaultData.BiHLocation"
             translate="{{(APP_GLOBAL_SETTINGS.defaultData.BiHLocation === 'Banja Luka')
          ? 'dashboard.tydUnsubscribebl' : 'dashboard.tydUnsubscribesa'}}"
             class="thank-you-text" style="text-align: left">
      </label>
      <label ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'bh'"
             translate="{{(thankYouPopup.currentLang !== 'en')
          ? 'dashboard.tydUnsubscribe' : 'dashboard.tydUnsubscribe' + APP_GLOBAL_SETTINGS.defaultData.APILocation}}"
             class="thank-you-text" style="text-align: left">
      </label>
    </div>
</div>








