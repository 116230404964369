(function () {
  'use strict';

  angular
    .module('messages')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('messages', {
        url: '/messages',
        templateUrl: 'messages/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'messages'
      })
      .state('newMessage', {
        url: '/new-message/:messageId',
        views: {
          '@': {
            templateUrl: 'messages/new-message.tpl.html',
            controller: 'NewMessageCtrl',
            controllerAs: 'newMessage'
          }
        }
      });
  }
}());
