(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($translateProvider) {

    var data = {
      messages: {
        "createBtn": "Kreiraj novu poruku",
        "receivedTab": "Primljene",
        "sentTab": "Poslate",
        "unreadLabel": "Nepročitane poruke",
        "readLabel": "Pročitane poruke",
        "replyBtn": "Odgovori",
        "printBtn": "Ispis",
        "deleteBtn": "Izbriši",
        "loadMore":"Učitaj više",
        "downloadAttachment": "Preuzmi privitak/prilog"
      },
      "newMessage": {
        "titleLabel": "Nova poruka",
        "titleReplyLabel": "Odgovori na poruku",
        "subjectLabel": "Naslov",
        "subjectPlaceholder": "Unesite naslov poruke ovdje",
        "bodyLabel": "Sadržaj",
        "header": "Poruke",
        "backBtn": "Nazad",
        "sendBtn": "Pošalji",
        "successLabel1": "Poruka poslana",
        "successLabel2": "Poruka ",
        "successLabel3": " je uspješno poslana!",
        "newLabel": "Kreiraj novu poruku",
        "goToMsgsLabel": "Idi na poruke"
      }
    };

    $translateProvider
      .translations('bh', data);

  }
}());
