<div ng-controller="InstantPaymentSrbCtrl as instantPaymentSrb">
  <div class="domp-header-wrapper col-sm-12 p-0" ng-show="instantPaymentSrb.cancelationStep!=3">
    <div class="domp-header-row">
      <h2 class="f-28" translate="instantPayment.reimbursementHeader">Povraćaj</h2>
    </div>
  </div>
  <div class="domp-body-wrapper">
    <div ng-if="instantPaymentSrb.cancelationStep === 1" class="domp-step-1">
      <form novalidate name="instantPaymentSrb.instantPaymentForm">
        <div class="domp-select-section col-sm-12 p-0">
        </div>
        <div class="col-sm-12 domp-from-section">
          <!-- CUSTOM ACCOUNTS DROPDOWN -->
          <div class="col-sm-6 domp-acc-dropdown">
            <label class="domp-form-label" translate="payments.fromAccount"></label>
            <div class="accounts-dropdown" ng-disabled="true">
              <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
                   data-ng-click="instantPaymentSrb.toggleFromAccount();"
                   ng-style="instantPaymentSrb.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + instantPaymentSrb.tempDompObj.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                <h4 class="no-wrap-text bold-font m-b-0">
                  <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{instantPaymentSrb.tempDompObj.fromAccountSelect.friendlyName}}</b>
                  <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi"
                                                                                      ng-class="{'zmdi-star favorite-account':instantPaymentSrb.tempDompObj.fromAccountSelect.isFavorite,'zmdi-star-outline':!instantPaymentSrb.tempDompObj.fromAccountSelect.isFavorite}"></i></span>
                </h4>
                <p class="m-b-0 m-t-0 iban-style">{{instantPaymentSrb.tempDompObj.fromAccountSelect.iban}}</p>
                <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                  {{instantPaymentSrb.tempDompObj.fromAccountSelect.availableBalance | number:2}}
                  {{instantPaymentSrb.tempDompObj.fromAccountSelect.currencySymbol}}
                </p>
                <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                <span class="caret-icon" ng-hide="instantPaymentSrb.isRetrieval"><i
                  class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
              </div>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="row m-r-0">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="domp-form-label" for="amountInput"
                         translate="domesticPayment.amountInputLabel">Amount:</label>
                  <div class="input-group">
                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput"
                           placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask
                           maxlength="12" ng-model="instantPaymentSrb.tempDompObj.amountInput" required
                           ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.amountInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                           ng-disabled="true">
                    <span class="input-group-btn">
                                              <button type="button"
                                                      class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero"
                                                      style="border:1px solid #062a42;">{{instantPaymentSrb.defaultCurrency}}</button>
                                         </span>
                  </div>
                  <span class="c-red"
                        ng-show="instantPaymentSrb.instantPaymentForm.amountInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                        translate="internalTransfer.amountInputError"></span>
                </div>
              </div>
            </div>
            <div class="row m-r-0">
              <div class="col-sm-8">
                <div class="form-group">
                  <label class="domp-form-label" for="dateInput"
                         translate="domesticPayment.dateInputLabel">Date:</label>
                  <div class="input-group ">
                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput"
                           uib-datepicker-popup="shortDate" data-ng-model="instantPaymentSrb.tempDompObj.dateInput"
                           show-weeks="false" is-open="instantPaymentSrb.datePickerOpened"
                           current-text="{{'core.period.today' | translate}}"
                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                           datepicker-options="instantPaymentSrb.dateOptions" readonly>
                    <span class="input-group-btn">
                            <button data-ng-click="instantPaymentSrb.openDatePicker()" ng-disabled="true" type="button"
                                    class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                                    style="border:1px solid #062a42;"><i
                              class="icon icon-calendar" style="font-size: 21px;"></i></button>
                          </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 receiver-section">
          <div class="row m-r-0">
            <!-- Start Account number - input -->
            <div class="col-sm-6 col-xs-12 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toAccountInputLabelSRB">
                  Account number
                </label>
                <input type="text" class="form-control input-field" name="toAccountInput"
                       ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.onlyNumbersAndDashes}}" capitalize
                       ng-minlength="6" dn-custom-maxlength="18" dn-exclude="-"
                       ng-blur="instantPaymentSrb.fillReceiver(instantPaymentSrb.tempDompObj.toAccountInput); instantPaymentSrb.fillReceiver(instantPaymentSrb.tempDompObj.toAccountInput);"
                       id="toAccountInput"
                       ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.toAccountInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                       ng-model="instantPaymentSrb.tempDompObj.toAccountInput" required ng-disabled="true">
                <div class="c-red"
                     ng-show="instantPaymentSrb.instantPaymentForm.toAccountInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                     translate="domesticPayment.receiverAccountError"></div>
                <div class="c-red" ng-show="instantPaymentSrb.instantPaymentForm.$error.minlength"
                     translate="payments.minimalAccountNumber"></div>
              </div>
            </div>
            <!-- END Account number - input -->
            <!-- /////////// SELECT CUSTOM ///////////// -->
            <div click-outside="instantPaymentSrb.searchFilterExpanded = false;"
                 outside-if-not="expandPayeeSearchFilterSRB" class="col-sm-6 col-xs-12 left-section">
              <div class="form-group" ng-hide="instantPaymentSrb.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <div class="col-sm-12 col-xs-12 domp-filter-container"
                     ng-class="{'domp-error-custom-payee': instantPaymentSrb.instantPaymentForm.payeeSearchFilter.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}">
                  <div class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section left">
                    <i class="icon icon-search domp-search-sign m-l-5"></i>
                  </div>
                  <div class="col-sm-9 col-md-9 col-lg-10 col-xs-9 inner-section input-div searchrow">
                    <input type="text" style="background-color: white;" name="payeeSearchFilter" id="payeeSearchFilter"
                           ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allowedCharsDPS}}"
                           class="search-input" ng-model="instantPaymentSrb.tempDompObj.receiversNameInput"
                           ng-change="instantPaymentSrb.searchPayee()"
                           required maxlength="35" ng-maxlength="35" autocomplete="off" ng-disabled="true"
                    >
                  </div>
                  <div id="expandPayeeSearchFilterSRB" class="col-sm-1 col-md-1 col-lg-1 col-xs-1 inner-section right"
                       ng-click="instantPaymentSrb.toggleFilterSelection()">
                    <i class="zmdi zmdi-chevron-down zmdi-hc-2x m-r-0 pull-right"
                       ng-show="instantPaymentSrb.tempDompObj.receiversNameInput==''"></i>
                  </div>
                  <div class="domp-filter-selection search-row"
                       ng-class="{'opened': instantPaymentSrb.searchFilterExpanded}">
                    <div class="domp-filter-type-selection-wrapper"
                         ng-repeat="payee in instantPaymentSrb.payeeList | filter:instantPaymentSrb.searchPayeeBy"
                         ng-click="instantPaymentSrb.selectPayee(payee.id)">
                      <div class="domp-list icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <i class="zmdi zmdi-account-circle"></i>
                      </div>
                      <div class="domp-list payee col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <p class="domp-list-name m-0">{{payee.receiverName}}</p>
                        <p class="domp-list-number m-0">{{payee.toAccountNumber}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="instantPaymentSrb.instantPaymentForm.payeeSearchFilter.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                      translate="domesticPayment.toPayeeInputError"></span>
              </div>
              <div class="form-group" ng-hide="!instantPaymentSrb.isRetrieval">
                <label class="domp-form-label" for="toAccountInput" translate="domesticPayment.toPayee">To payee</label>
                <input type="text" class="form-control input-field"
                       ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allowedCharsDPS}}"
                       ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.receiversAddressInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                       name="payeeSearchFilter" id="payeeSearchFilter"
                       ng-model="instantPaymentSrb.tempDompObj.receiversNameInput"
                       ng-blur="instantPaymentSrb.replaceUnallowedCharsDPS('receiversAddressInput', instantPaymentSrb.tempDompObj.receiversAddressInput)"
                       maxlength="35" ng-maxlength="35" required ng-disabled="true">
                <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0"
                      ng-show="instantPaymentSrb.instantPaymentForm.payeeSearchFilter.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                      translate="domesticPayment.toPayeeInputError"></span>
              </div>
            </div>
            <!--//////////////SELECT CUSTOM END/////////////////////// -->
          </div>

          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 left-section"></div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="receiversAddressInput"
                       translate="domesticPayment.receiversAddressInputLabel">Receiver's address:</label>
                <input type="text" class="form-control input-field"
                       ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allowedCharsDPS}}"
                       ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.receiversAddressInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                       name="receiversAddressInput" id="receiversAddressInput"
                       ng-model="instantPaymentSrb.tempDompObj.receiversAddressInput"
                       ng-blur="instantPaymentSrb.replaceUnallowedCharsDPS('receiversAddressInput', instantPaymentSrb.tempDompObj.receiversAddressInput)"
                       maxlength="35" ng-maxlength="35" required ng-disabled="true">
                <span class="c-red"
                      ng-show="instantPaymentSrb.instantPaymentForm.receiversAddressInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                      translate="domesticPayment.receiverAddressError"></span>
              </div>
            </div>
          </div>
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 left-section"></div>
            <div class="col-lg-6 col-md-6 col-sm-6 right-section">
              <div class="form-group">
                <label class="domp-form-label" for="receiversPostalCodeAndCityInput"
                       translate="domesticPayment.receiversPostalCodeAndCityInputLabel">Receiver's postal code and city:
                </label>
                <input type="text" class="form-control input-field"
                       ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allowedCharsDPS}}"
                       ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.receiversPostalCodeAndCityInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                       name="receiversPostalCodeAndCityInput" id="receiversPostalCodeAndCityInput"
                       ng-model="instantPaymentSrb.tempDompObj.receiversPostalCodeAndCityInput"
                       ng-blur="instantPaymentSrb.replaceUnallowedCharsDPS('receiversPostalCodeAndCityInput', instantPaymentSrb.tempDompObj.receiversPostalCodeAndCityInput)"
                       maxlength="35" ng-maxlength="35" ng-disabled="true">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 purpose-section">
          <div class="row m-r-0">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 left-section">
              <div class="form-group">
                <div class="col-lg-12 col-md-4 no-padding-on-phone p-r-0 p-l-0">
                  <label class="domp-form-label" for="purposeCodeSelect"
                         translate="instantPayment.reimbursementCode">Purpose code</label>
                  <div class="p-l-0">
                    <select ng-disabled="instantPaymentSrb.cancelationStep2"
                            class="form-control input-field p-l-15 p-r-10" name="purposeCodeSelect"
                            id="purposeCodeSelect"
                            ng-options="option.optionValue for option in instantPaymentSrb.purposeCodesList.result"
                            ng-model="instantPaymentSrb.tempDompObj.purposeCodeSelect"
                            ng-change="instantPaymentSrb.changePurpose(instantPaymentSrb.tempDompObj.purposeCodeSelect)"></select>
                  </div>
                </div>
                <div class="col-lg-12 col-md-8 no-padding-on-phone p-r-0 p-l-0 p-t-10">
                  <label class="domp-form-label domp-purpose-label" for="purposeOfPaymentInput"
                         translate="instantPayment.reimbursementReason">Purpose</label>
                  <div class="p-0">
                    <input ng-disabled="instantPaymentSrb.cancelationStep2" type="text"
                           class="form-control input-field"
                           ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.purposeOfPaymentValidation}}"
                           name="purposeOfPaymentInput" id="purposeOfPaymentInput" ng-maxlength="105" maxlength="105"
                           ng-model="instantPaymentSrb.tempDompObj.purposeOfPaymentInput" required
                           ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.purposeOfPaymentInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                           >
                    <span class="c-red"
                          ng-show="instantPaymentSrb.instantPaymentForm.purposeOfPaymentInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                          translate="internalTransfer.paymentPurposeInputError"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- START Reference number - input -->
            <div class="col-lg-6 col-md-6 col-sm-6 left-section">
              <div class="col-xs-12 p-l-0">
                <label class="domp-form-label" translate="instantPayment.modelAndReference">Reference
                  number:</label>
              </div>
              <div class="col-sm-3 col-xs-4 p-l-0 p-r-5">
                <div class="form-group m-b-0">
                  <input ng-disabled="true" class="form-control input-field p-l-15 p-r-10" name="referenceModelSelect"
                         id="referenceModelSelect" ng-model="instantPaymentSrb.tempDompObj.referenceModelSelect"/>
                </div>
              </div>
              <div class="col-sm-9 col-xs-8 p-l-0 p-r-0">
                <div class="form-group m-b-0">
                  <!-- Reference number for Serbia allows only uppercase letters, numbers and dashes-->
                  <!-- capitalize (attribute) directive forces uppercase letters-->
                  <input ng-disabled="true" type="text" id="referenceNumberInput" class="form-control input-field"
                         name="referenceNumberInput"
                         ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.referenceNumberInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                         maxlength="25"
                         ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allLettersNumbersDashes}}"
                         ng-if="instantPaymentSrb.tempDompObj.referenceModelSelect!='97' && instantPaymentSrb.tempDompObj.referenceModelSelect!='11'"
                         ng-model="instantPaymentSrb.tempDompObj.referenceNumberInput" capitalize/>
                  <input ng-disabled="true" type="text" id="referenceNumberInput"
                         ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.referenceNumberInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                         class="form-control input-field" name="referenceNumberInput" maxlength="25"
                         ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allLettersNumbersDashes}}"
                         ng-blur="instantPaymentSrb.checkModul97Reference('97' + instantPaymentSrb.tempDompObj.referenceNumberInput)"
                         ng-if="instantPaymentSrb.tempDompObj.referenceModelSelect=='97'"
                         ng-model="instantPaymentSrb.tempDompObj.referenceNumberInput" capitalize required/>
                  <input ng-disabled="true" type="text" id="referenceNumberInput"
                         ng-class="{'domp-error': instantPaymentSrb.instantPaymentForm.referenceNumberInput.$invalid && instantPaymentSrb.tempDompObj.formSubmitted}"
                         class="form-control input-field" name="referenceNumberInput" maxlength="25"
                         ng-pattern-restrict="{{instantPaymentSrb.regexPatterns.allLettersNumbersDashes}}"
                         ng-if="instantPaymentSrb.tempDompObj.referenceModelSelect=='11'"
                         ng-model="instantPaymentSrb.tempDompObj.referenceNumberInput" capitalize required/>
                </div>
              </div>
              <!-- START Error messages -->
              <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-if="instantPaymentSrb.tempDompObj.referenceModelSelect=='97' &&
                 instantPaymentSrb.instantPaymentForm.referenceNumberInput.$error.modul97reference == true "
                   translate="domesticPayment.referenceModelInputInvalid">
              </div>
              <div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"
                   ng-if="instantPaymentSrb.instantPaymentForm.referenceNumberInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted"
                   translate="domesticPayment.referenceModelInputError">
              </div>
              <!--<div class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="instantPaymentSrb.instantPaymentForm.referenceNumberInput.$error.required && instantPaymentSrb.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputError"></div>-->
              <!--   <span class="c-red col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0" ng-show="domesticPayment.instantPaymentForm.referenceModelInput.$invalid && domesticPayment.tempDompObj.referenceModelInput != '' && !domesticPayment.tempDompObj.formSubmitted" translate="domesticPayment.referenceModelInputInvalid"></span> -->
              <!-- END Error messages -->
            </div>
            <!-- END Reference number input -->
          </div>
        </div>
        <div class="domp-footer-wrapper">
          <div class="row m-r-0">
            <div class="col-sm-6 col-lg-6 col-md-6 p-l-30">
              <div class="col-xs-12 p-l-10">
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-md-6">
              <div class="col-lg-7 col-md-6 col-sm-5"></div>
              <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 p-r-5 p-t-10 charges-label">
                <a ng-href="{{instantPaymentSrb.bankCharges}}" target="_blank"
                   translate="domesticPayment.checkBankCharges" class="pull-right">Check the bank charges</a>
              </div>
            </div>
          </div>
          <div class="row m-r-0">
            <div class="col-xs-6 t-a-l">
              <button class="btn btn-primary" type="button" translate="payments.cancel"
                      data-ui-sref="payments.paymentsOverview">
              </button>
            </div>
            <div class="col-xs-6 t-a-r">
              <button class="btn btn-default" type="submit"
                      translate="domesticPayment.confirmBtn"
                      ng-click="instantPaymentSrb.submitPayment();"></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div ng-if="instantPaymentSrb.cancelationStep === 2" class="domp-step-2"
         ng-include="'payments/instant-payment-resume.tpl.html'"></div>
  </div>
</div>
