<section id="main">
  <div class="container-fluid">
    <!-- START Title -->
    <div class="row">
      <div class="col-md-12" style="z-index:1;">
        <div class="tmplt-list-title col-md-12 p-r-0 p-l-0 m-b-15">
          <h2 class="title-style" translate="changeATMWithdrawal.header">Change ATM withdrawal limit</h2>
        </div>
      </div>
    </div>
    <!-- END Title -->

    <!-- START Note -->
    <div ng-if="changeATM.step == 1" class="row">
      <div class="col-md-12">
        <div class="alert alert-info " style="background: white; color: black; border: 1px solid lightgray">
          <p translate="changeATMWithdrawal.infoNote1"></p>
        </div>
      </div>
    </div>
    <!-- END Note -->

    <div ng-if="changeATM.step == 1" class="p-b-30">
      <ng-form name="changeATM.changeATMWithdrawalForm">
        <div class="cash-withdrawing-section-frame">
          <div class="row">
            <div class="col-md-6">

              <label class="domp-form-label" translate="changeWithdrawal.fromAccount">Form account</label>
              <!-- START Dropdown account -->
              <div class="accounts-dropdown">
                <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="changeWithdrawal.showAccountToDropdown = !changeWithdrawal.showAccountToDropdown" ng-style="changeWithdrawal.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + changeWithdrawal.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                  <h4 class="no-wrap-text bold-font m-b-0">
                    <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
                      {{changeATM.selectedAccount.friendlyName}}</b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': changeATM.selectedAccount.isFavorite,'zmdi-star-outline':!changeATM.selectedAccount.isFavorite}"></i>
                </span>
                  </h4>
                  <p class="m-b-0 m-t-0 iban-style">{{changeATM.selectedAccount.iban}}</p>
                  <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                    {{changeATM.selectedAccount.availableBalance | number:2}} {{changeATM.selectedAccount.currencySymbol}}
                  </p>
                  <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>

                </div>
              </div>
              <!-- END Dropdown account -->

            </div>
            <div class="col-md-6">

              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="changeATMWithdrawal.cardType"></label>
                  <select class="form-control input-field p-l-15 p-r-10"
                          ng-options="amount.description for amount in changeATM.cardTypes"
                          ng-change="changeATM.selectCardType(changeATM.selectedCardType)"
                          data-ng-model="changeATM.selectedCardType" required>
                  </select>
                </div>
              </div>

              <!-- START Amount -->
              <div class="col-xs-12 p-0">
                <div class="form-group">
                  <label class="domp-form-label" translate="changeATMWithdrawal.desiredAmount">Desired amount (in EUR)</label>
                  <select class="form-control input-field p-l-15 p-r-10"
                          ng-options="amount.description for amount in changeATM.desiredAmounts"
                  data-ng-model="changeATM.selectedDesiredAmount">
                  </select>
                </div>
              </div>
              <!-- END Amount-->

            </div>

          </div>


          <div class="row p-t-30">

            <div class="col-md-6">
              <div class="form-group">
                <label class="domp-form-label" translate="changeATMWithdrawal.for">For</label>
                <select ng-change="changeATM.selectForOption();" class="form-control input-field p-l-15 p-r-10" ng-options="option.description for option in changeATM.forOptions" ng-model="changeATM.type">
                </select>

              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="domp-form-label" translate="changeATMWithdrawal.nameSurname">Name and surname</label>
                <input type="text"
                       name="nameSurname"
                       id="nameSurname"
                       class="form-control input-field p-l-15 p-r-10"
                       data-ng-model="changeATM.user"

                       maxlength="50"
                       required
                       ng-disabled="changeATM.type.id == 'uporabnik'">
                <div class="c-red"
                     ng-show="changeATM.changeATMWithdrawalForm.nameSurname.$error.required && changeATM.formSubmitted"
                     translate="changeATMWithdrawal.nameSurnameRequired"></div>

              </div>
            </div>

          </div>


          <div class="row p-b-20 p-t-20">
            <div class="col-md-12">
              <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left" ng-click="changeATM.goBackToAccount()">
                <span translate="changeWithdrawal.cancelBtnLabel">Cancel</span>
              </button>
              <button class="btn btn-default  pull-right" ng-click="changeATM.validate();" ng-disabled="changeATM.nextButtonPressed">
                <span translate="domesticPayment.confirmBtnSlo">Confirm</span>
              </button>
            </div>
          </div>
        </div>
      </ng-form>
    </div>

    <div ng-if="changeATM.step == 2">
      <div class="domp-to-section m-10">
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="changeWithdrawal.fromAccount"></label>
              <label class="d-block domp-form-label-resume">{{changeATM.selectedAccount.iban}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="changeATMWithdrawal.cardType"></label>
              <label class="d-block domp-form-label-resume">{{changeATM.selectedCardType.description}}</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">

          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group">
              <label class="domp-form-label-review" translate="changeATMWithdrawal.desiredAmount"></label>
              <label class="d-block domp-form-label-resume">{{changeATM.selectedDesiredAmount.description}}</label>
            </div>
          </div>
        </div>
        <div class="row p-b-20">
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review" translate="changeATMWithdrawal.for"></label>
            <label class="d-block domp-form-label-resume">{{changeATM.type.description}}</label>
          </div>
          <div class="col-xs-12 col-sm-6">
            <label class="domp-form-label-review"  translate="changeATMWithdrawal.nameSurname"></label>
            <label class="d-block domp-form-label-resume">{{changeATM.user}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <button class="btn btn-primary " translate="domesticPayment.back"
                data-ng-click="changeATM.edit();">
        </button>
        <button class="btn btn-default pull-right" data-ng-click="changeATM.submit()" ng-disabled="changeATM.submitButtonPressed">
          <span translate="domesticPayment.confirmBtn"></span>
        </button>
      </div>
    </div>

  </div>
</section>
