(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name timeline.factory:TimelineService
     *
     * @description
     *
     */
    angular
        .module('timeline')
        .factory('TimelineService', TimelineService);

    function TimelineService(EndpointsService, $q, $http, $log, $uibModal) {
        var TimelineServiceBase = {};

        TimelineServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.timeline, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        TimelineServiceBase.getAll = function (params) {
            var predefinedParams = {
                page: 0,
                pageSize: 1000
            };
            var extended = _.extend(predefinedParams, params);
            return TimelineServiceBase.getPage(extended);
        };

        TimelineServiceBase.openTimelineItemDetailsModal = function (timelineItem) {
            var modalInstance = $uibModal.open({
                templateUrl: 'timeline/timeline-item-details-modal.tpl.html',
                controller: 'TimelineItemDetailsModalCtrl',
                controllerAs: 'tlItemDetailsModal',
                resolve: {
                    timelineItem: function () {
                        return timelineItem;
                    }
                },
                backdrop: 'static'
            });
        };

      TimelineServiceBase.syncTimeline = function () {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.put(response.links.sync)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data, code);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };

        return TimelineServiceBase;


    }
}());
