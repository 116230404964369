<div class="statementModal logout-modal-frame">
  <div class="modal-body p-0">
    <!--X-->
    <div class="row">
      <div class="col-md-12">
        <div style="color: white; padding-top: 43px;">
       <!--   <i class="icon icon-add pull-right close-icon-x" ng-click="$close()"></i> -->
        </div>
      </div>
    </div>
    <!--END X-->
    <div class="row">
      <div class="col-md-2 col-sm-3 col-xs-3">
        <i class="icon alert-icon icon-info alert-icon-f"></i>
      </div>
      <div class="col-md-10 col-sm-9 col-xs-9">
        <span class="c-white alert-msg" translate="alert.blockCardInfo"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-padding">
    <button class="btn btn-primary-red pull-right statementBtnClose" ng-click="$close()" translate="alert.cancel">Ok</button>
  </div>
</div>
