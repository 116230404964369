<div class="chat-search">
    <div class="fg-line">
        <input type="text" class="form-control" placeholder="Search People">
    </div>
</div>

<div class="listview">
    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left p-relative">
                <img class="lv-img-sm" src="img/profile-pics/2.jpg" alt="">
                <i class="chat-status-busy"></i>
            </div>
            <div class="media-body">
                <div class="lv-title">Jonathan Morris</div>
                <small class="lv-small">Available</small>
            </div>
        </div>
    </a>

    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left">
                <img class="lv-img-sm" src="img/profile-pics/1.jpg" alt="">
            </div>
            <div class="media-body">
                <div class="lv-title">David Belle</div>
                <small class="lv-small">Last seen 3 hours ago</small>
            </div>
        </div>
    </a>

    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left p-relative">
                <img class="lv-img-sm" src="img/profile-pics/3.jpg" alt="">
                <i class="chat-status-online"></i>
            </div>
            <div class="media-body">
                <div class="lv-title">Fredric Mitchell Jr.</div>
                <small class="lv-small">Availble</small>
            </div>
        </div>
    </a>

    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left p-relative">
                <img class="lv-img-sm" src="img/profile-pics/4.jpg" alt="">
                <i class="chat-status-online"></i>
            </div>
            <div class="media-body">
                <div class="lv-title">Glenn Jecobs</div>
                <small class="lv-small">Availble</small>
            </div>
        </div>
    </a>

    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left">
                <img class="lv-img-sm" src="img/profile-pics/5.jpg" alt="">
            </div>
            <div class="media-body">
                <div class="lv-title">Bill Phillips</div>
                <small class="lv-small">Last seen 3 days ago</small>
            </div>
        </div>
    </a>

    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left">
                <img class="lv-img-sm" src="img/profile-pics/6.jpg" alt="">
            </div>
            <div class="media-body">
                <div class="lv-title">Wendy Mitchell</div>
                <small class="lv-small">Last seen 2 minutes ago</small>
            </div>
        </div>
    </a>
    <a class="lv-item" href="">
        <div class="media">
            <div class="pull-left p-relative">
                <img class="lv-img-sm" src="img/profile-pics/7.jpg" alt="">
                <i class="chat-status-busy"></i>
            </div>
            <div class="media-body">
                <div class="lv-title">Teena Bell Ann</div>
                <small class="lv-small">Busy</small>
            </div>
        </div>
    </a>
</div>
