<section id="main">
  <div class="container">

    <div class="container status-container">
      <div class="row vertical-align">
        <div class="col-sm-1 text-right">
          <span class="glyphicon glyphicon-ok"></span>
        </div>

        <div class="col-sm-11 ">
          <h2>Archiving complete</h2>
          You have successfully archived the E-invoice.
        </div>
      </div>

    </div>

    <div class="container status-container">
      <div class="row vertical-align">
        <div class="col-sm-1 text-right">
          <span class="glyphicon glyphicon-info-sign"></span>
        </div>
        <div class="col-sm-11">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit
          amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
          dis parturient montes, nascetur ridiculus mus.
        </div>
      </div>
    </div>

    <br/><br/>
    <a href="#!e-invoices/overview">Go to e-invoice list</a>

  </div>
</section>
