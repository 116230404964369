(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name investments.controller:AccessInvestmentsCtrl
   *
   * @description
   *
   */
  angular
    .module('investments')
    .controller('AccessInvestmentsCtrl', AccessInvestmentsCtrl);

  function AccessInvestmentsCtrl($log, HelperService, FundsService, ConfigEndpointService, UserSettingsService, ValidationService,
                                 $state, $translate, NotificationService) {
    var vm = this;

    vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;
    vm.formSubmitted = false;

    vm.personalStatusData = [
      {
        key: 'accessInvestment.employee',
        val: 0
      },
      {
        key: 'accessInvestment.firm',
        val: 1
      },
      {
        key: 'accessInvestment.unemployed',
        val: 2
      },
      {
        key: 'accessInvestment.retired',
        val: 3
      },
      {
        key: 'accessInvestment.student',
        val: 4
      },
      {
        key: 'accessInvestment.rest',
        val: 99
      }
    ];

    vm.employerTypeData = [
      {
        key: 'accessInvestment.privateSector',
        val: 0
      },
      {
       key: 'accessInvestment.civileService',
        val: 1
      },
      {
        key: 'accessInvestment.stateAuth',
        val: 2
      },
      {
        key: 'accessInvestment.emplTrade',
        val: 3
      }
    ];

    vm.plannedInvestmentsType = [
      {
        key: 'accessInvestment.inv1',
        val: 0
      },
      {
        key: 'accessInvestment.inv2',
        val: 1
      },
      {
        key: 'accessInvestment.inv3',
        val: 2
      },
      {
        key: 'accessInvestment.inv4',
        val: 3
      }
    ];

    vm.investFromType = [
      {
        key: 'accessInvestment.invEmployment',
        val: 0
      },
      {
        key: 'accessInvestment.invfreelancing',
        val: 1
      },
      {
        key: 'accessInvestment.invLegal',
        val: 2
      },
      {
        key: 'accessInvestment.capital',
        val: 3
      },
      {
        key: 'accessInvestment.invInsured',
        val: 4
      },
      {
        key: 'accessInvestment.rest',
        val: 5
      }
    ];

    vm.tempAccessionObj = {
      nameSurnameInput: '',
      addressInput: '',
      identificationNumberInput: '',
      personalHomePhoneInput: '',
      issuerInput: '',
      personalEmailInput: '',
      personalStatus: vm.personalStatusData[0],
      employerType: vm.employerTypeData[0],
      plannedInvestments: vm.plannedInvestmentsType[0],
      investFrom: vm.investFromType[0],
      bankInput: '',
      toAccount2Input: '',
      professionInput: '',
      occupationInput: '',
      sendViaEmail: false
    };

    var defaultData = {}, userSettings = {};

    var init = function (){
      getDefaultData();
      getUserSettings();
    };

    var createInsertObject = function () {
      var insertObj = {
        personalHomePhone: vm.tempAccessionObj.personalHomePhoneInput,
        personalEmail: userSettings.email,
        toAccount2: "HR" + vm.tempAccessionObj.toAccount2Input,
        personalStatus: vm.tempAccessionObj.personalStatus.val,
        personalStatusDesc: $translate.instant(vm.tempAccessionObj.personalStatus.key),
        employerType: vm.tempAccessionObj.employerType.val,
        employerTypeDesc: $translate.instant(vm.tempAccessionObj.employerType.key),
        profession: vm.tempAccessionObj.professionInput,
        occupation: vm.tempAccessionObj.occupationInput,
        plannedInvestments: vm.tempAccessionObj.plannedInvestments.val,
        plannedInvestmentsDesc: $translate.instant(vm.tempAccessionObj.plannedInvestments.key),
        investFrom: vm.tempAccessionObj.investFrom.val,
        investFromDesc: $translate.instant(vm.tempAccessionObj.investFrom.key),
        oib: '',
        mb: '',
        sendViaEmail: vm.tempAccessionObj.sendViaEmail,
        bank: vm.tempAccessionObj.bankInput,
        deliveryMailAddress: vm.tempAccessionObj.personalEmailInput,
        fondPmxID: vm.tempAccessionObj.identificationNumberInput,
        fondPmxID1: vm.tempAccessionObj.issuerInput
      };

      return insertObj;
    };

    vm.submit = function (){
      vm.formSubmitted = true;

      if(!vm.tempAccessionObj.toAccount2Input || vm.tempAccessionObj.toAccount2Input.length < 19) return;

      FundsService.insertFundUserDetail(createInsertObject())
        .then(function (response){
          vm.formSubmitted = false;
          $state.go('dashboard');
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        });
    };

    var getDefaultData = function (){
      return ConfigEndpointService.getDefaultData()
        .then(function (response){
          defaultData = response;
          vm.tempAccessionObj.bankInput = response.BankName;
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        })
    };

    var getUserSettings = function () {
      return UserSettingsService.getPage()
        .then(function (response){
          if(response.result && response.result.length > 0) {
            userSettings = response.result[0];
            vm.tempAccessionObj.nameSurnameInput = userSettings.firstName + ' ' + userSettings.lastName;
            vm.tempAccessionObj.addressInput = userSettings.address1;
            vm.tempAccessionObj.personalEmailInput = userSettings.email;
          }
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        })
    };

    init();
  }
}());
