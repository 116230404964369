(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orders.controller:CancelDepositCtrl
   *
   * @description
   *
   */
  angular
    .module('orders')
    .controller('CancelDepositCtrl', CancelDepositCtrl);

  function CancelDepositCtrl($rootScope, HelperService, $log, $state, DepositOrdersService, $translate, CurrencyService, AccountsService, AlertService, NotificationService ) {
    var vm = this;
    vm.ctrlName = 'CancelDepositCtrl';

    vm.steps = {
      step1: true,
      step2: false
    };

    vm.depositReferenceNumber = '';
    vm.depositOrder = {};
    vm.canceledDepositOrder = {};

    vm.depositAccountCloseOverViewResponse = {};

    vm.depositAccount = {};
    vm.accountId = '';
    vm.currenciesList = [];

    vm.init = function () {
      CurrencyService.getCurrencyPage({
        pageSize: 5000
      }).then(function (response) {
        vm.currenciesList = response.result;
        if ($state.params.accountId) {
          vm.accountId = $state.params.accountId;

          AccountsService.getAccountById(vm.accountId).then(function (response) {
            vm.depositAccount = response;
            vm.depositReferenceNumber = vm.depositAccount.depositAccount.depositIdExternal;
            vm.getDepositOrderByReference();
          }).catch(function (error) {
            NotificationService.showMessage(error, "error");
          });
        }
      }).catch(function (error) {
        $log.error(error);
      });
    };

    vm.confirmCancelDeposit = function () {
      if (vm.depositOrder) {

        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
          AlertService.confirmationAlert()
            .then(function (result) {
              if (result) {
                DepositOrdersService.cancelDepositOrderById(vm.depositOrder.id)
                  .then(function (response) {
                    vm.canceledDepositOrder = response;
                    AlertService.infoAlert({
                      text: 'cancelDeposit.successInfoLabel1',
                      id: vm.canceledDepositOrder.id,
                      text2: 'cancelDeposit.successInfoLabel2'
                    })
                      .then(function () {
                        $state.go("depositAccountsOverview");
                      })
                  }, function (error) {
                    NotificationService.showMessage(error, 'error')
                  })
              }
            })
            .catch(function () {
              $log.debug('Example failed!');
            });
        } else {

          DepositOrdersService.cancelDepositOrderById(vm.depositOrder.id).then(function (response) {
            vm.canceledDepositOrder = response;
            vm.steps = {
              step1: false,
              step2: true
            };
          })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              $log.error(error);
            });

        }
      }
    };

    vm.getDepositAccountCloseOverview = function (params) {
      DepositOrdersService.depositAccountCloseOverView(params).then(function (response) {
        $log.debug('depositAccountCloseOverView', response);
        if(response){
          vm.depositAccountCloseOverViewResponse = response.depositAccountClose;
        }
      }).catch(function (error) {
        NotificationService.showMessage(error, "error");
      });
    };

    vm.getDepositOrderByReference = function () {
      DepositOrdersService.getPage({
        referenceNumber: vm.depositReferenceNumber
      }).then(function (response) {
        if (response && response.result[0]) {
          vm.depositOrder = response.result[0];
          var currencyList = _.find(vm.currenciesList, function (o) {
            return o.code == vm.depositOrder.currencyCode
          });

          if (currencyList) {
            vm.depositOrder.currencySymbol = currencyList.symbol;
          }
          $log.debug('depositOrder', vm.depositOrder);

          var params = {};
          params.AccountId = vm.depositAccount.accountId;
          params.DepositIdExternal = vm.depositAccount.depositAccount.depositIdExternal;
          params.DepositStartDate = new Date(vm.depositAccount.depositAccount.depositStartDate);

          vm.getDepositAccountCloseOverview(params);
        } else {
          // TODO: Response empty...
        }

      }).catch(function (error) {
        NotificationService.showMessage(error, "error");
      });
    };

    vm.backToAccount = function () {
      $state.go('accounts', {accountId: vm.accountId});
    };

    vm.init();
  }
}());
