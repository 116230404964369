(function () {
  'use strict';

  angular
    .module('documents')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('documents', {
        url: '/documents',
        templateUrl: 'documents/documents.tpl.html',
        controller: 'DocumentsCtrl',
        controllerAs: 'documents'
      });
  }
}());
