<div class="tml-filter-container tmlf-wide" id="searchFilterExpandedDiv">
    <div id="searchBtnLeftDiv" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation" class="inner-section left">
  <i ng-switch-default class="icon icon-search i-p24 m-l-5"></i>
  <i ng-switch-when="sl" ng-click="paymentsOverview.applyPaymentsFilter();" class="icon icon-search i-p24 m-l-5"></i>
</div>
  <div id="searchBtnCenterDiv" class="inner-section center">
    <input id="searchTimelineInput" type="text" class="search-input" placeholder="{{'timelineFilter.placeholder'|translate}}"
           ng-model="paymentsOverview.paymentsFilter.purposeFTS" ng-model-options="{ debounce: 300 }"
           ng-keyup="$event.keyCode == 13 && paymentsOverview.applyPaymentsFilter()"
    >
  </div>
  <div id="searchBtnRightDiv" class="inner-section right pull-right" ng-init="paymentsOverview.showDeleteInputBtn = false">
    <i ng-show="paymentsOverview.paymentsFilter.purposeFTS" class="zmdi zmdi-tag-close  zmdi-hc-2x m-r-0"
       ng-click="paymentsOverview.paymentsFilter.purposeFTS = ''; paymentsOverview.showDeleteInputBtn = false"></i>
    <i class="icon icon-filter i-p24 i-lh12 m-r-0 pull-right"
       ng-click="paymentsOverview.searchFilterExpanded = !paymentsOverview.searchFilterExpanded"></i>

  </div>
    <div class="tml-filter-selection" ng-class="{'opened': paymentsOverview.searchFilterExpanded,'h-250': ((paymentsOverview.dateFromOpen || paymentsOverview.dateToOpen) && paymentsOverview.isCancelationCall), 'h-510': ((paymentsOverview.dateFromOpen || paymentsOverview.dateToOpen) && paymentsOverview.countryCode === 'sl')}">
      <!-- START: Row Date from and date to -->
      <div class="m-t-15 m-b-15 clearfix" ng-if="paymentsOverview.viewName == 'payments_overview'">
        <div class="col-xs-6 p-l-5 p-r-5">
          <div class="form-group m-b-0">
            <label class="f-12-s" translate="paymentsOverview.dateFrom">Date from</label>
            <div class="input-group">
              <input type="text" class="form-control-db"
                     ng-value="paymentsOverview.cancelationsFilter.cancelationDateGTE | date: 'shortDate' : '+0200'"
                     readonly>
              <span class="input-group-btn">
                  <button data-ng-click="paymentsOverview.dateFromOpen = !paymentsOverview.dateFromOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
            </div>
          </div>
        </div>
        <div class="col-xs-6 p-l-0 p-r-5">
          <div class="form-group m-b-0">
            <label class="f-12-s" translate="paymentsOverview.dateTo">Date to</label>
            <div class="input-group">
              <input type="text" class="form-control-db"
                     ng-value="paymentsOverview.cancelationsFilter.cancelationDateLTE | date: 'shortDate' : '+0200'"
                     readonly>
              <span class="input-group-btn">
                  <button data-ng-click="paymentsOverview.dateToOpen = !paymentsOverview.dateToOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero"
                          style="border:1px solid #062a42; height: 35px">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
            </div>
          </div>
        </div>
        <div class="col-xs-6 p-l-0 p-r-5">
          <!-- Date from datepicker-->
          <div data-ng-model="paymentsOverview.cancelationsFilter.cancelationDateGTE"
               show-weeks="false"
               show-button-bar="false"
               is-open="paymentsOverview.dateFromOpen"
               ng-change="paymentsOverview.onDateFromChange()"
               uib-datepicker-popup="shortDate"
                required="">
          </div>
          <!-- Date to datepicker-->
          <div data-ng-model="paymentsOverview.cancelationsFilter.cancelationDateLTE"
               show-weeks="false"
               show-button-bar="false"
               is-open="paymentsOverview.dateToOpen"
               ng-change="paymentsOverview.onDateToChange()"
               date-disabled="paymentsOverview.datesToDisabled(date, mode)"
               uib-datepicker-popup="shortDate">
          </div>
        </div>
      </div>
      <span class="p-l-5 p-t-5 c-red" ng-if="!paymentsOverview.transactionFilterYearDateRangeValid">
        <span translate="core.filter.maxDateRange"></span>
      </span>
        <!-- END: Row Date from and date to -->

        <div class="m-b-15" style="overflow:auto">
            <div class="col-xs-6 t-a-l p-l-5">
                <button class="btn btn-primary app-btn-gray" ng-click="paymentsOverview.resetPaymentsFilter();" translate="timelineFilter.reset"></button>
            </div>
            <div class="col-xs-6 t-a-r p-r-5">
                <button class="btn btn-default app-btn-blue w-100" ng-disabled="!paymentsOverview.transactionFilterYearDateRangeValid"
                        ng-click="paymentsOverview.applyPaymentsFilter();" translate="timelineFilter.apply"></button>
            </div>
        </div>
    </div>
</div>
