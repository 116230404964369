(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      payments: {
        "quickPaymentTransfer": "Brzo plaćanje ili transfer",
        "domesticPayments": "Nalog za prenos",
        "currencyConversion": "Konverzija valuta",
        "internationalPayment": "Internacionalno plaćanje",
        "rejectedPayments": "Odbijene uplate",
        "pendingPayments": "Uplate na čekanju",
        "templates": "Predlošci",
        "searchTemplateName": "Traži predložak po nazivu",
        "topUsed": "Najčešće upotrebljeni",
        "alphabetically": "Po abecedi",
        "to": "ka",
        "showMoreTemplates": "Prikaži više predložaka",
        "eInvoices": "eRačuni",
        "showMoreInvoices": "Prikaži sve eRačune",
        "sepaDirectDebit": "SEPA izravno dugovanje",
        "viewAllTransactions": "Pregled svih transakcija",
        "due": "dospio",
        "allPastPayments": "Pregled svih izvršenih plaćanja",
        "exchangeRatesList": "Tečajna lista",
        "pay": "Plati",
        "internalTransfer": "Interni prenos",
        "noEinvoices": "Nema podataka za eRačune",
        "noTemplates": "Nema podataka za predloške",
        "forCurrencyConversion": "pretvorba valuta",
        "midrate": "srednja stopa",
        "forForeignNotes": "strane bilješke, čekovi",
        "onDate": "dana ",
        "buy": "Kupi",
        "goBackToPayments": "Povratak na plaćanja",
        "currencyName": "Naziv valute",
        "code": "Kod",
        "unit": "Jedinica",
        "buyRate": "Kupovni kurs",
        "middleRate": "Srednji kurs",
        "sellRate": "Prodajni kurs",
        "action": "Akcija",
        "noRejectedPayments": "Za odabrani period nema odbijenih uplata",
        "noPendingPayments": "Za odabrani period nema uplata na čekanju",
        "donationToCharity": "Donacija u dobrotvorne svrhe",
        "subscribe": "Pretplata",
        "settings": "Postavke",
        "moreRejected": "Još odbijenih plaćanja",
        "morePending": "Još plaćanja na čekanju",
        "currencyExchange": "Menjačnica",
        "exchange": "Razmeni",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "willSell": "Prodaja",
        "willBuy": "Kupovina",
        "available": "Dostupno",
        "delete": "Obriši",
        "edit": "Promeni",
        "loanPayment": "Plaćanje kredita",
        "paymentMode": "Svrha plaćanja",
        "toAcc": "na račun",
        "fromAcc": "s računa",
        "bicNumber": "BIC broj",
        "bicBankName": "Banka primaoca",
        "bicAddress": "BIC adresa",
        "bicCity": "BIC grad",
        "bicCountry":"Država",
        "bicRecipientStatus": "Primalac (osoba)",
        "bicRecipientStatusOptionLegal": "Pravna",
        "bicRecipientStatusOptionPhysical": "Fizička",
        "bicBankCharges": "Troškovna opcija",
        "bicNumberRequired": "BIC adresa je obavezna!",
        "bicBankNameRequired": "Banka primaoca je obavezna!",
        "bicCityRequired": "BIC grad je obavezan!",
        "bicCountryRequired": "Država je obavezna!",
        "resend": "Pošalji ponovo",
        "minimalAccountNumber": "Račun nije ispravan!",
        "cancel": "Odustani",
        "templateSavedSuccessfully": "Uspešno ste kreirali šablon !",
        "templateUpdatedSuccessfully": "Promena šablona je uspešno izvršena !",
        "bankAddressInputRequired": "Bank address is required!."
      },
      paymentsOverview: {
        "custom": "Proizvoljno",
        "pohReciver": " za ",
        "pohAmount": " u iznosu od ",
        "pohDate": " na dan ",
        "povlStatus": "Status",
        "povlId": "Id naloga",
        "povlReceiver": "Naziv primaoca",
        "povlFrom": "Sa računa",
        "povlTo": "Na račun",
        "povlAmount": "Iznos",
        "povlDueDate": "Datum izvršenja",
        "povlValueDate": "Datum valutacije",
        "filterStatus": "Filtriraj po statusu",
        "loadMoreBtn": "Prikaži još naloga",
        "referenceTo": "Model i poziv na broj",
        "titleLabel": "Lista naloga",
        "signatoryName": "Potpisnik",
        "signDate": "Datum akcije",
        "MCC": "MCC",
        "POSReference": "Referenca transakcije na prodajnom mjestu",
        "paymentsType": {
          "all": "Svi",
          "opened": "Za potpis",
          "partiallysigned": "Djelimično potpisani",
          "cancelled": "Otkazani",
          "processed": "Izvršeni",
          "rejected": "Odbijeni",
          "closed": "Zatvoreni",
          "signed": "Potpisani",
          "notSigned": "Za potpis",
          "cancellations": "Pregled povraćaja",
          "inprocessing": "U obradi",
          "forcancelling": "Za otkazivanje",
          "storned": "Stornirani",
          "rejectedaftercomplaint": "Odbijeni nakon žalbe",
          "acceptedaftercomplaint": "Prihvaćeni nakon žalbe",
          "allTab": "Svi",
          "realizedTab": "Realizovani nalozi",
          "pendingTab": "Nalozi na čekanju",
          "rejectedTab": "Odbijeni nalozi"
        },
        dateRange: "Filtriraj prema razdoblju",
        "dateFrom": "Vreme od",
        "dateTo": "Vreme do",
        "filterByPurpose": "Pretraži po namjeni",
        "noPaymentData": "Za odabrani filter nema naloga.",
        "selectAll": "Izaberi sve",
        "selectNone": "Poništi izbor",
        "signSelectedPayments": "Potpiši odabrana plaćanja",
        "povlNote":"Napomena",
        "referenceNumber": "Referentni broj",
        "referenceNumberPayment": "Referentni broj orginalnog naloga",
        "referenceNumberCancelations": "Referentni broj zahteva za povraćaj",
        "cancelled": "Opozvan",
        "processed": "Izvršen",
        "rejected": "Odbijen",
        "open": "Kreiran - nepotpisan",
        "inProcessing": "U obradi",
        "waitingQueque": "Na čekanju",
        "signed": "Potpisan",
        "Cancelled": "Opozvan",
        "Processed": "Izvršen",
        "Rejected": "Odbijen",
        "Opened": "Kreiran - nepotpisan",
        "InProcessing": "U obradi",
        "WaitingQueue": "Na čekanju",
        "Signed": "Potpisan",
        "ipsPopoverText": "Instant plaćanje"
      },
      domesticPayment: {
        "header": "Nalog za prenos",
        "fromAccountSelectLabel": "Sa računa",
        "availableAmountLabel": "Raspoloživo",
        "purposeCodeSelectLabel": "Šifra plaćanja",
        "purposeOfPaymentInputLabel": "Svrha plaćanja",
        "amountInputLabel": "Iznos",
        "dateInputLabel": "Datum",
        "bicCodeInputLabel": "BIC kod",
        "toAccountInputLabel": "Račun primaoca",
        "toAccountInputLabelSRB": "Račun primaoca",
        "toPayee": "Primalac",
        "toAccountInputBtn": "Dopuni primaoca",
        "referenceNumberLabel": "Model i poziv na broj",
        "referenceModelLabel": "Reference model",
        "receiversNameInputLabel": "Naziv primaoca",
        "receiversAddressInputLabel": "Adresa primaoca",
        "receiversPostalCodeAndCityInputLabel": "Mesto primaoca",
        "receiversCountrySelectLabel": "Zemlja primaoca",
        "payersNameInputLabel": "Ime i prezime pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljaoca",
        "payersCityInputLabel": "Grad pošiljaoca",
        "amountPlaceholder": "eg. 1.000,00",
        "reviewAndSubmitBtn": "Pregledajte i pošaljite",
        "selectUrgentLabel": "Označite kao hitnu uplatu",
        selectUrgentLabelSRB: "Označite kao hitnu uplatu",
        "urgentPaymentInfo": "Hitna plaćanja se naplaćuju po važećoj Tarifi Banke",
        "processingFeeLabel": "Naknada za obradu naloga",
        "receiverAccountError": "Račun primaoca je obavezan!",
        "receiverAccountErrorHR":"IBAN / Broj računa primaoca je obavezan!",
        "receiverNameError": "Naziv primaoca je obavezan!",
        "receiverCityError": "Grad primaoca je obavezan!!",
        "receiverAddressError": "Adresa primaoca je obavezna!",
        "sepaDebitorLabel": "Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primalac",
        "confirmBtn": "Potvrdi",
        "selectFromTemplate": "Plaćanje iz šablona",
        "listOfTemplates": "Šabloni",
        "bankCharges": "Provizija",
        "urgentPayment": "Ovaj nalog je hitan",
        "checkBankCharges": "Proveri dodatne naknade",
        "referenceModelInputError": "Poziv na broj je obavezan!",
        "referenceModelInputInvalid": "Poziv na broj nije ispravan!",
        "toPayeeInputError": "Naziv primaoca je obavezan!",
        "toPayee2":"Primalac (naziv/ime i adresa)",
        "newPayment":"Novi nalog",
        "delete":"Obriši",
        "edit":"Promeni",
        "sign":"Potpiši",
        "ibanStructError":"IBAN nije ispravan",
        "taxPayerNumber": "Broj poreznog obveznika",
        "paymentType": "Vrsta uplate",
        "revenueCode": "Vrsta prihoda",
        "municipality": "Općina/opština",
        "taxPeriodFrom": "Poreski period od",
        "taxPeriodTo": "Poreski period do",
        "budgetOrganisation": "Budžetska organizacija",
        "taxPayerError": "Broj poreznog obveznika je obavezan!",
        "revenueCodeError": "Vrsta prihoda je obavezna!",
        "budgetOrganizationError": "Budžetska organizacija je obavezna!",
        "municipalityError": "Općina/opština je obavezna!",
        "municipalityExistanceError": "Općina/opština ne postoji!",
        "taxPeriodRangeError": "Poreski period nije u ispravnom opsegu!",
        "revenueCodeTypeError": "Tip prihoda ne odgovara tipu računa!",
        "revenueCodeExistanceError": "Vrsta prihoda ne postoji!",
        "receiverAccountMinLengthError": "Račun primaoca mora imati 16 karaktera!",
        "receiverAccountModuleError": "Račun primaoca nije korektan!",
        "referenceNumberRequired": "Poziv na broj je obavezan za javne prihode!",
        "referenceNumberMaxLength": "Poziv na broj je prevelik!",
        "referenceNumberError": "Poziv na broj nije validan!"
      },
      instantPayment: {
        "header": "Povraćaj",
        "purposeCodeSelectLabel": "Šifra povraćaja",
        "purposeOfPaymentInputLabel": "Svrha povraćaja",
        "signTitle":"Potpisivanje povraćaja",
        "finalStepSuccessfullySigned": "Uspešno ste potpisali zahtjev za povraćaj.",
        "orderSent": "Zahtev je poslat u banku na obradu.",
        "buttons": {
          "cancellation": "Povraćaj",
          "edit": "Izmeni",
          "delete": "Obriši",
          "sign": "Potpiši"
        },
        "reimbursementHeader": "Povraćaj",
        "reimbursementCode": "Šifra povraćaja",
        "reimbursementReason": "Svrha povraćaja",
        "reimbursementSignHeader": "Potpisivanje povraćaja",
        "finalStepPaymentID": "Broj zahteva: ",
        "statuses": {
          "InProcessing":"U obradi",
          "Signed":"Potpisan",
          "WaitingQueue":"Zahtjev je poslat na čekanje",
          "Processed":"u obradi"
        },
        "modelAndReference": "Model i poziv na broj"
      },
      internationalPayment: {
        international: "Internacionalno plaćanje",
        "fromAccount": "Sa računa",
        "chargeAccount": "Račun naknade",
        "totalAmount": "Ukupan iznos i valuta",
        "paymentDate": "Datum plaćanja",
        "paymentPurpose": "Svrha uplate",
        "recipient": "Primalac",
        "purposeCode": "Šifra svrhe",
        "recipientStatus": "Status primaoca",
        "companyName": "Ime i prezime ili puni naziv tvrtke",
        "address": "Adresa",
        "city": "Grad",
        "country": "Država",
        "recipientAccount": "Račun primaoca",
        "recipientBank": "Banka primaoca",
        "bankName": "Naziv banke",
        "swiftCode": "SWIFT kod",
        "bankCharges": "Bankovna naknada",
        "sharedCharges": "Zajednički troškovi",
        "payersPaysCharges": "Isplatitelj plaća troškove",
        "beneficiaryPaysCharges": "Korisnik plaća troškove",
        "naturalPerson": "Fizičko lice",
        "legalPerson": "Pravno lice",
        "tooltipInfo": "Trebate pomoć?",
        "fillData": "Popunite podatke o banci",
        "reviewandSubmit": "Pregled i potvrda",
        "companyNameInputError": "Ime i prezime je obavezno!",
        "recipientAddressInputError": "Adresa primaoca je obavezna!",
        "recipientCityInputError": "Grad primaoca je obavezan!",
        "recipientAccountInputError": "Račun primaoca je obavezan!",
        "bankNameInputError": "Naziv banke je obavezan!",
        "bankAddressInputError": "Adresa banke je obevezna!",
        "bankCityInputError": "Grad banke je obavezan!",
        "swiftCodeInputError": "SWIFT kod je obavezan!",
        "payersNameInputLabel": "Ime i prezime pošiljaoca",
        "payersAddressInputLabel": "Adresa pošiljaoca",
        "payersCityInputLabel": "Grad pošiljaoca",
        "sepaDebitorLabel": " Stvarni dužnik",
        "sepaReceiverLabel": "Krajnji primalac",
        "paymentMode": "Svrha plaćanja",
        "toPayee2":"Primalac (naziv/ime i adresa)",
        "toAccountInputLabel": "IBAN / Broj računa primaoca",
        "bicBankName": "Banka primaoca",

      },
      internalTransfer: {
        "transferToAccount": "Prenos na moj račun",
        "fromAccount": "Račun platitelja (IBAN)",
        "toAccount": "Račun primaoca (IBAN)",
        "dueDate": "Datum izvršenja",
        "valueDate": "Datum kreiranja",
        "amount": "Iznos",
        "sign": "Potpiši",
        "saveAsTemplate": "Sačuvaj kao šablon",
        "templateName": "Naziv šablona",
        "newPayment": "Kreiraj novi nalog",
        "toPaymentOverview": "Idi na pregeled svih plaćanja",
        "signSuccess": "Platni nalog je uspješno potpisan.",
        "toAccountInputError": "Račun primaoca je obavezan!",
        "paymentPurposeInputError": "Svrha uplate je obavezna!",
        "amountInputError": "Iznos je obavezan!",
        "dueDateError": "Datum izvršenja je obavezan!",
        "templateNameError": "Naziv je obavezan!",
        "when": "Datum",
        "transferComplete": "Prenos je završen",
        "makeAnotherPayment": "Kreiraj novi nalog",
        "successfullyTransfer": "Uspješno ste prebacili",
        "amountLengthError": "Maksimalni iznos je 9",
        "nodata": "Nema podataka za prikaz"
      },
      paymentSignModal: {
        title: "Potpisivanje",
        challengeResponse: "Unesite kod",
        ok: "Uredu"
      },
      loanPayment: {
        "loanPayment": "Plaćanje kredita",
        "recieverNumber": "Broj primaoca",
        "purpose": "Svrha plaćanja",
        "when":"Datum",
        "exchangeRate":"Kurs",
        "referenceNumber":"Model i poziv na broj"
      },
      currencyConversion: {
        "exchangeOffice": "Kupoprodaja deviza",
        "exchangeRatesCalculator": "Lista valuta i kalkulator",
        "sellingAmount": "Prodaja",
        "buyingAmount": "Kupovina",
        "availableBalance": "Raspoloživo stanje",
        "fromAccount": "Sa računa",
        "toAccount": "Na račun",
        "amount": "Iznos",
        "sign": "Potpiši",
        "confirm": "Potvrdi",
        "back": "Novi nalog",
        "smsAuthentification": "SMS autentifikacija plaćanja",
        "sendSms": "Pošalji SMS kod",
        "smsPassword": "SMS jednokratna šifra",
        "moneyExchangeComplete": "Novčana razmjena je završena",
        "successfullyExchange": "Uspješno ste razmjenili",
        "makeAnotherExchange": "Napravi još jednu razmjenu",
        "goToPayments": "Idi na pregled naloga",
        "exchangeRateRatio": "Kursna lista",
        "exchangeOrder": "Lista naloga za kupoprodaju deviza",
        "signPayment": "Potpiši nalog",
        "deletePayment": "Obriši nalog",
        "recallPayment": "Opozovi nalog",
        "balance":"Stanje računa",
        "exchangeRate":"Kurs menjačnice",
        "bankCharges":"Cenovnik",
        "when":"Datum",
        "buying":"Kupovni kurs",
        "selling":"Prodajni kurs",
        "calculatedAmount":"Preračunati iznos",
        "agreedRate":"Kurs"
      },
      paymentsAndTransferOverview: {
        rejectedLabel:"Poslednji neplaćeni nalozi",
        eInvoicesLabel:"Neplaćeni eRačuni",
        groupedPaymentsLabel:"Grupa naloga za potpis",
        repeatAction:"Ponovi",
        payAction:"Plati",
        signAllAction:"Potpiši sve",
        domPayToSign:"opštih naloga za potpis",
        paymentsToSign: "naloga za potpis",
        "confirm": "Potvrdi"
      },
      ebook: {
        "ebookPayIn":"Uplata na eknjižicu",
        "ebookPayOut": "Isplata sa eknjižice"
      },
      savings: {
        "savingsPayIn": "Interni devizni prenos"
      },
      signPayments: {
        "paymentsInvalidDueDate": "Neka od plaćanja imaju neispravan datum izvršenja. Da li želite da se datumi ažuriraju na prvi ispravan datum prema terminskom planu banke?",
        "singlePaymentInvalidDueDate": "Odabrani nalozi imaju neispravan datum izvršenja. Da li želite da ažurirate datum na prvi naredni radni dan u skladu sa terminskim planom?",
        "cancelationsInvalidDueDate": "Povraćaj ima neispravan datum izvršenja. Da li želite da ažurirate datum na prvi naredni radni dan u skladu sa terminskim planom?",
        "updatePaymentsDueDate": "Ažuriraj krajnje datume plaćanja",
        "updatePaymentDueDate": "Ažuriraj datum izvršenja",
        "dateCreated": "Datum kreiranja",
        "dueDate": "Datum izvršenja",
        "confirm": "Potvrdi",
        "challengeResponse": "Kod potvrde",
        "challenge": "Validacijski kod",
        "challengeFirstCode": "Šifra 1 (unosi se u token)",
        "challengeSecondCode": "Šifra 2 (iz tokena)",
        "challengeDescription": "Uključite token, unesite PIN i odmah na prazan ekran (piše samo CHAL na vrhu) unesite broj koji je ispisan na ekranu 'Šifra 1', i kliknite na gumb 'OK'. Token će ispisati šifru 2 koju je potrebno prepisati u polje 'Šifra 2'. Kada ste završili akciju, odaberite gumb 'POTVRDI'.",
        "finalStepSuccessfullySigned": "Uspešno ste potpisali nalog",
        "finalStepPaymentServices": "Za usluge platnog prometa naknada će biti obračunata u skladu sa službenim ",
        "finalStepBankTariff": "cenovnikom Banke.",
        "finalStepStatus": "Status: ",
        "finalStepPaymentID": "Broj transakcije: ",
        "finalStepNumberOfPayments": "Broj naloga: ",
        "finalStepSentForProcessing": "Nalog je poslat u Banku na obradu.",
        "finalStepPaymentSloHeaderEx": "N/A",
        "finalStepPaymentSloHeaderPen": "N/A",
        "finalStepPaymentSloSubHeader": "N/A",
        "finalStepPaymentSloSubHeaderLink": "N/A",
        "finalStepPaymentSloTextEx": "N/A",
        "finalStepPaymentSloTextPen": "N/A",
        "cancel":"Odustani",
        "resendSms": "Ponovo pošalji SMS",
        "SmsCode": "SMS kod",
        "SmsDescription": "SMS poruka je poslata na Vaš uređaj. Unesite kod iz SMS poruke u polje 'SMS kod'. Nakon unosa koda, odaberite opciju 'POTVRDI'. SMS kod važi 90 sekundi i nakon toga se ne može koristiti.",
        "SmsSentSuccessfully": "SMS poruka uspešno poslata!",
        "paymentExecutionDateTitle": "Promena datuma valute",
        "paymentExecutionDateBody": "Obrada naloga je završena. Da li se slažete da se datum valute promeni na sledeći radni dan ?",
        "infoDomSrb0":"Vaše plaćanje će biti izvršeno u skladu sa definisanim vremenskim okvirima ",
        "infoDomSrb1":"(Terminski plan).",
        "infoDomSrbLink":"",
        "cancelationSignMessage": "Istekao je zakonski rok od 10 dana predviđen za povraćaj za ovaj Zahtev. Da li želite da obrišete ovaj Zahtev?"
      },
      paymentActions: {
        "repeat": "Ponovi nalog",
        "copy": "Dupliraj",
        "delete": "Obriši",
        "print": "Štampa",
        "recall": "Opozovi nalog",
        "sign": "Potpiši",
        "signCancelation": "Potpiši",
        "cancel": "Otkaži",
        "rejectRecall": "Odbij zahtjev",
        "approveRecall": "Prihvati zahtjev",
        "edit":"Izmeni",
        "confirmation": "Order confirmation",
        "instant": "Povraćaj"
      },
      statementConfirmModalTranslations: {
        title: "Izjava",
        p1: "Sukladno Zakonu o međunarodnim mjerama ograničavanja u Banci za provedbu naloga, potrebno je popuniti ovu Izjavu. U protivnom, nalog neće biti proveden.",
        p2: "Pod kaznenom i materijalnom odgovornošću izjavljujem da je, za nalog u iznosu od ",
        p2_2: " za državu",
        p2_0: " osnova plaćanja",
        p2_1: "detaljno obrazloženje (specifikacija)*",
        p4: "Izjava je kreirana u elektroničkom obliku i važeća je bez pečata i potpisa.",
        radioMerchandise: "Roba*",
        radioPresent: "Poklon",
        radioOther: "Ostalo*",
        confirmButton: "Potvrdi",
        cancelButton: "Odustani"
      },
      "currencyCalculator": {
        "exchangeCalculator": "Valutni kalkulator",
        "exchangeRates": "Valutni kurs",
        "msg1": "Tečajna lista primjenjuje se za kupnju i prodaju deviza do iznosa protuvrijednosti od HRK 150.000,00",
        "msg2": "Za veće iznose molimo kontaktirajte Odjel Prodaje proizvoda Riznice Addiko Banke na " +
        "tel.: 01 603 3513, 01 603 1746, 01 603 0616, 01 603 3533. ili 01/603-0889, i za regiju Slavonija i Baranja " +
        "tel: 031/231-553 i 031/231-223.",
        "updated":"Ažurirano"
      },
      paymentsReturn: {
        openedTab: "Za potpis",
        archivedTab: "Arhivirani",
        titleLabel: "Suglasnost za povrat sredstava",
        modalTitle: "Odaberite razlog za povrat sredstava",
        recallReason: "Razlog za povrat",
        additionalInfo: "Dodatne informacije",
        checkAdditionalInfo: "Ukloni dodatne informacije",
        additionalInfoError: "Ovo polje je obavezno",
        reasonTech: "Tehnički problemi koji rezultiraju pogrešnim platnim nalogom kreditnog transfera",
        reasonFrad: "Lažno iniciranje platnog naloga kreditnog transfera (required additiona info field)",
        reasonDupl: "Duplo slanje",
        reasonAM09: "Pogrešan iznos",
        reasonAC03: "Pogrešan IBAN",
        reasonCUST: "Ostali razlozi",
        reasonAC03EN: "Please provide additional information about beneficiary in case of negative response to the recall request",
        reasonAC03HR: "Molimo dodatne podatke o primatelju u slučaju negativnog odgovora na zahtjev povrata"
      },
      paymentsFinal: {
        "InProcessing":"U obradi",
        "Signed":"Potpisan",
        "WaitingQueue":"Nalog je poslat na čekanje",
        "Processed":"Obrađen"
      },
      paymentsTypeGroup: {
        "CurrencyExchange":"Kupo-prodaja",
        "LocalCurrencyTransfer":"Interni prenos",
        }
    };

    $translateProvider
      .translations('sr', data);
  }
}());
