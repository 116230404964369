<div class="currency-conversion-msg">
  <div class="payment-successful-wrapper">
    <div class="row">
      <div class="col-xs-2 m-t-30 m-b-30 p-l-116 msg-pad">
        <div class="currency-check-icon msg-pad">
          <i class="icon icon-confirm c-white zmdi-hc-5x"></i>
        </div>
      </div>
      <div class="col-xs-10 m-t-30 m-b-30">
        <h2 class="c-white f-28" translate="ebook.completeRequest"></h2>
        <div class="f-16">
          <span translate="ebook.successfullRequest"></span>
      </div>
    </div>
  </div>
</div>
</div>