(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name materialAdmin.factory:FooterService
   *
   * @description
   *
   */
  angular
    .module('materialAdmin')
    .factory('FooterService', FooterService);

  function FooterService($uibModal) {
    var FooterServiceBase = {};

    FooterServiceBase.openModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'material-admin/in-depth-modal.tpl.html',
        controller: 'FooterCtrl',
        controllerAs: 'footer',

        backdrop: 'static'
      });
    };
    return FooterServiceBase;
  }
}());
