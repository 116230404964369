<section id="main">
  <div class="container-fluid">
    <div class="exchange-rates">
      <div class="no-padding-on-phone">
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'" data-ng-include="'payments/international-payment-cro.tpl.html'"></div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" data-ng-include="'payments/international-payment-slo.tpl.html'"></div>
        <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'mne'" data-ng-include="'payments/international-payment-mne.tpl.html'"></div>
      </div>
    </div>
  </div>
</section>
