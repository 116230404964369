(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name transactions.factory:TransactionsService
   *
   * @description
   *
   */
  angular
    .module('transactions')
    .factory('TransactionsService', TransactionsService);

  function TransactionsService(EndpointsService, $q, $http, $log, HelperService, NotificationService) {
    var TransactionsServiceBase = {};

    TransactionsServiceBase.getPage = function(params){
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function(response){
        $log.debug("Params:", params);
        $http.get(response.links.transactions, { params: params})
          .then(function(data) {
            deferred.resolve(data.data);
          }).catch(function(msg, code) {
            deferred.reject(msg.data);
          });
      }, function(error){
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    TransactionsServiceBase.getById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.transactions_id.replace('{id}', id), {params: params})
          .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    // New method for transactions downlaoding
    TransactionsServiceBase.getTransactionPdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.transactions_downloadLink_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    TransactionsServiceBase.downloadTransactionPdf = function(id){
      var nonBlockingWindow = window.open('', '_self');
      TransactionsServiceBase.getTransactionPdfLink(id)
        .then(function (pdfLink) {
          //pdfLink = pdfLink.data;
          if(pdfLink){
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    TransactionsServiceBase.insertComplaint = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.orders, params)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return TransactionsServiceBase;
  }
}());
