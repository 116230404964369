(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            timeline: {
                "description": "Description",
                "amount": "Amount",
                "availableBalance": "Available balance",
                "accountNumber": "Account number",
                "bookingDate": "Booking date",
                "transactionDate": "Transaction date",
                "transactionDetails": "Transaction details",
                "close": "Close",
                "referenceType": "Type",
                "group": "Account",
                "valueDate": "Value date",
                "einvoiceDetails": "eInvoice details",
                "purpose": "Purpose",
                "creditorName": "Payer",
                "tldmMessageDetails": "Message details:",
                "tldmMessageDate": "Message creation date:",
                "tldmMessageSubject": "Message subject:",
                "debitorName":"Recipient",
                "creditorAddress":"Payer address",
                "debitorAddress":"Recipient's address",
                "creditorAccount":"From IBAN",
                "debitorAccount":"To account",
                "Transaction": "Transaction",
              itemTypeStatus: {
                EInvoice: "EInvoice",
                TransactionCredit: "TransactionCredit",
                TransactionDebit: "TransactionDebit",
                Payment: "Payment",
                MessageIn: "MessageIn",
                MessageOut: "MessageOut",
                Statement: "Statement",
                LoginSuccessful: "LoginSuccessful",
                LoginFailed: "LoginFailed",
                TokenRefresh: "TokenRefresh"
              }

            }
        };

        $translateProvider
            .translations('en', data);

    }
}());
