(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name core.directive:scrollable
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="core">
       <file name="index.html">
        <scrollable></scrollable>
       </file>
     </example>
   *
   */
  angular
    .module('core')
    .directive('scrollable', scrollable);

  function scrollable() {
    return {
      restrict: 'A',
      scope: {},
      replace: false,
      link: function (scope, element, attrs) {
        element.mCustomScrollbar({
          autoHideScrollbar: false,
          theme: 'minimal-dark',
          advanced:{
            updateOnContentResize: true
          }
        });
      }
    };
  }
}());
