<section id="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12" style="z-index:1;">
        <div class="tmplt-list-title col-lg-12 col-md-12 col-sm-12 col-xs-12 p-r-0 m-b-15 p-0">
          <h2 class="title-style" translate="paymentsOverview.titleLabel">List of payments</h2>
        </div>
      </div>
      <div class="col-xs-12" style="z-index:1;">
        <!-- Easier way to duplicate code - than modifying CSS width-->
        <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
          <div class="po-type-container" ng-switch-default>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 1}" ng-click="paymentsOverview.selectPaymentsType('all', 'paymentsOverview.paymentsType.allTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.allTab"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 2}" ng-click="paymentsOverview.selectPaymentsType('processed', 'paymentsOverview.paymentsType.realizedTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.realizedTab"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 3}" ng-click="paymentsOverview.selectPaymentsType('pending', 'paymentsOverview.paymentsType.pendingTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.pendingTab"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 4}" ng-click="paymentsOverview.selectPaymentsType('rejected', 'paymentsOverview.paymentsType.rejectedTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.rejectedTab"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 5}" ng-click="paymentsOverview.selectPaymentsType('not-signed', 'paymentsOverview.paymentsType.notSigned')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.notSigned"></div>
          </div>
          <div class="po-type-container" ng-switch-when="sr">
            <div style="width:11%;" ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 1}" ng-click="paymentsOverview.selectPaymentsType('all', 'paymentsOverview.paymentsType.allTab')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.allTab"></div>
            <div style="width:20%;" ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 2}" ng-click="paymentsOverview.selectPaymentsType('processed', 'paymentsOverview.paymentsType.realizedTab')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.realizedTab"></div>
            <div style="width:20%;" ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 3}" ng-click="paymentsOverview.selectPaymentsType('pending', 'paymentsOverview.paymentsType.pendingTab')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.pendingTab"></div>
            <div style="width:20%;" ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 4}" ng-click="paymentsOverview.selectPaymentsType('rejected', 'paymentsOverview.paymentsType.rejectedTab')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.rejectedTab"></div>
            <div style="width:11%;" ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 5}" ng-click="paymentsOverview.selectPaymentsType('not-signed', 'paymentsOverview.paymentsType.notSigned')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.notSigned"></div>
            <div style="width:18%;" ng-class="{'selected': paymentsOverview.isCancelationCall}" ng-click="paymentsOverview.selectPaymentsType('cancelations', 'paymentsOverview.paymentsType.cancelations')" class="po-type-tab-srb no-wrap-text" translate="paymentsOverview.paymentsType.cancellations"></div>
          </div>
          <div class="po-type-container" ng-switch-when="sl">
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 1}" ng-click="paymentsOverview.selectPaymentsType('all', 'paymentsOverview.paymentsType.allTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.allTab" style="width: 25%"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 2}" ng-click="paymentsOverview.selectPaymentsType('processed', 'paymentsOverview.paymentsType.realizedTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.realizedTab" style="width: 25%"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 3}" ng-click="paymentsOverview.selectPaymentsType('pending', 'paymentsOverview.paymentsType.pendingTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.pendingTab" style="width: 25%"></div>
            <div ng-class="{'selected': paymentsOverview.paymentsTypeSelected === 4}" ng-click="paymentsOverview.selectPaymentsType('rejected', 'paymentsOverview.paymentsType.rejectedTab')" class="po-type-tab no-wrap-text" translate="paymentsOverview.paymentsType.rejectedTab" style="width: 25%"></div>
          </div>
        </div>
        <div class="po-filter-row col-sm-12 clearfix p-20 bgm-white" style="z-index:1;">

          <!-- Show only for not signed payments -->
          <div ng-if="paymentsOverview.paymentsTypeSelected === 5" class="col-xs-12 m-t-10 m-b-10 p-r-0">

            <div class="col-xs-6">
              <span translate="paymentsOverview.selectNone" class="f-uppercase f-18 cursor-pointer" data-ng-click="paymentsOverview.selectNonePaymentsForSign()" data-ng-class="{'payments-filter-active': paymentsOverview.areNonePaymentsSelected}"></span> /
              <span translate="paymentsOverview.selectAll" class="f-uppercase f-18 cursor-pointer" data-ng-click="paymentsOverview.selectAllPaymentsForSign()" data-ng-class="{'payments-filter-active': paymentsOverview.areAllPaymentsSelected}"></span>
            </div>

            <button ng-hide="paymentsOverview.areNonePaymentsSelected" class="btn btn-primary col-xs-4 pull-right p-r-0" data-ng-click="paymentsOverview.signSelectedPaymentsAction()" translate="paymentsOverview.signSelectedPayments"></button>

          </div>

          <div ng-if="!paymentsOverview.isCancelationCall" class="col-sm-6 col-xs-12  p-0">
            <div ng-include="'payments/payments-overview-filter.tpl.html'" style="height:40px"></div>
          </div>
          <div ng-if="paymentsOverview.isCancelationCall" class="col-sm-6 col-xs-12  p-0">
            <div ng-include="'payments/payments-overview-cancelations-filter.tpl.html'" style="height:40px"></div>
          </div>
          <div class="col-sm-6 col-xs-12 p-0 m-m-t-10">
            <div class="dropdown acc-period-select pull-right" style="display: inline-block">
              <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                {{(paymentsOverview.countryCode == 'sr' && paymentsOverview.isCustomDateRange ?
                'paymentsOverview.custom': paymentsOverview.selectedPeriod.item.description) | translate}}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li data-ng-repeat="period in paymentsOverview.periodList">
                  <a href="" class="f-13" 
                     data-ng-click="paymentsOverview.paymentsPeriodFilter(period)">
                    {{period.item.description| translate}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>



        <div class="timeline-row payments col-sm-12 no-padding">
          <div class="hypo-timeline-wrapper app-white-card p-0 p-b-10 p-t-10">
            <div class="t-a-c p-10 no-data-image" ng-if="paymentsOverview.paymentsOverviewList.result.length === 0">
              <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
              <h3 class="no-data-label" translate="paymentsOverview.noPaymentData"></h3>
            </div>

            <div ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl' && paymentsOverview.paymentsTypeSelected === 3" class="row p-b-15"
                 ng-show="paymentsOverview.paymentsOverviewListGrouped.result.length > 0">
              <div class="col-xs-12 t-a-c hidden-xs">
                <label class="balance-sum-label ng-scope">
                  {{'paymentsOverview.totalPendingSum' | translate}} <span ng-repeat="(currencyKey, sum) in paymentsOverview.paymentsOverviewListGrouped.result[1].sumAmount">{{sum | number:2}} {{currencyKey | uppercase}} | </span>
                </label>
              </div>
            </div>
            <ul class="hypo-timeline payments-list" ng-if="paymentsOverview.paymentsOverviewListGrouped.result.length !== 0">
              <li class="" ng-repeat="payment in paymentsOverview.paymentsOverviewListGrouped.result">
                <span class="currency-time-frame" style="margin-left:14px;" ng-if="payment.isVirtual">{{payment.date| date:'shortDate'}}</span>
                <i ng-if="!payment.isVirtual"
                   data-ng-class="(APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && payment.paymentType === '2') ? 'icon icon-posips' : 'icon icon-change currency-icon-change icon-lh'">
                </i>
                <span
                  uib-popover="{{'paymentsOverview.ipsPopoverText' | translate}}"
                  popover-class="ips-custom-popover"
                  popover-append-to-body="true"
                  popover-enable="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && payment.channelType === 'IPS'}}"
                  popover-trigger="'mouseenter'"
                  ng-if="!payment.isVirtual && APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && payment.channelType === 'IPS'"
                  class="ips-text">IPS</span>
<!--                <span-->
<!--                  ng-if="!payment.isVirtual && APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && payment.paymentType === '2'"-->
<!--                  class="ips-text" translate="paymentsOverview.POSIPS">POS_IPS-->
<!--                </span>-->

                <div ng-if="!payment.isVirtual" class="hypo-timeline-item payment-item" ng-class="{'opened': payment.selected}" ng-click="paymentsOverview.togglePaymentDetails(payment)" >
                  <div class="hypo-timeline-body">
                    <div class="col-xs-8">
                      <h4 ng-if="payment.purpose" class="m-b-0 m-t-0 c- no-wrap-text" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                        <span class="bold-font-payment" ng-switch-default>{{::payment.purpose}}</span>
                        <span class="bold-font-payment" style="white-space: normal" ng-switch-when="sl">
                          {{::payment.purpose}}
                        </span>
                      </h4>
                      <h4 ng-if="!payment.purpose" class="m-b-0 m-t-0 bold-font-payment">{{'paymentsTypeGroup.' + payment.paymentTypeGroup| translate}}</h4>

                      <p class="m-b-0 m-t-0 iban-style-paym wrap-text">{{::payment.toAccountNumber}} {{::payment.receiverName}}</p>
                      <p ng-show="paymentsOverview.showPaymentId" class="m-b-0 m-t-0 iban-style" id="paymentId">{{::payment.id}}</p>
                    </div>

                    <!-- Not signed payments -->
                    <div class="col-xs-4" ng-if="paymentsOverview.paymentsTypeSelected === 5">
                      <span class="col-xs-9 t-a-r price-payment" ng-if="payment.transactionAmount2"><b>{{::payment.transactionAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</b></span>
                      <span class="col-xs-9 t-a-r price-payment" ng-if="!payment.transactionAmount2"><b>{{::payment.amount|number:2}} {{::payment.currencySymbol}}</b></span>
                      <span class="col-xs-3 f-18 pull-right">
                        <div class="checkbox checkbox-height" 
                             ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl' && 
                                   (APP_GLOBAL_SETTINGS.defaultData.APILocation != 'hr' || (APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && payment.urgency != '2'))">
                          <label>
                            <input type="checkbox" ng-model="payment.isSelectedForSign"
                                   ng-click="paymentsOverview.togglePaymentForSign($event);">
                            <i class="input-helper"></i>
                          </label>
                        </div>
                      </span>
                    </div>

                    <!-- Other payment types -->
                    <div ng-if="paymentsOverview.paymentsTypeSelected !== 5" class="col-xs-4">
                      <span class="pull-right t-a-r f-18">
                        <b class="price-payment" style="font-weight:600; font-size:22px;" ng-if="::payment.transactionAmount2">{{::payment.transactionAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</b>
                        <b class="price-payment" style="font-weight: 600; font-size:22px; " ng-if="::!payment.transactionAmount2">{{::payment.amount|number:2}} {{::payment.currencySymbol}}</b>
                        <br/>
                        <span ng-class="{'text-danger': payment.status === 'Rejected', 'payment-gray-status': payment.status === 'InProcessing'}"
                              translate="{{paymentsOverview.checkProcessedPaymentMne(payment)? 
                                'paymentsOverview.processed2' : 'paymentsOverview.'+payment.status}}" ></span>
                              
                      </span>
                    </div>
                    <div class="clear"></div>
                  </div>
                  <div ng-if="payment.selected" class="transaction-details">
                    <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change-2"></i>
                    <div>
                      <table class="table table-transparent table-responsive">
                        <tbody>
                          <tr ng-if="paymentsOverview.paymentsTypeSelected === 5">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlStatus"></span>
                            </td>
                            <td>
                              <span ng-class="{'text-danger': payment.status === 'Rejected', 'payment-gray-status': payment.status === 'InProcessing'}"
                              translate="{{paymentsOverview.checkProcessedPaymentMne(payment) ? 
                                        'paymentsOverview.processed2' : 'paymentsOverview.'+payment.status}}" ></span>

                            </td>
                          </tr>
                          <tr ng-if="payment.status === 'Rejected'">
                            <td ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                              <span ng-switch-default class="po-desc-label" translate="paymentsOverview.povlNote"></span>
                              <span ng-switch-when="sl" class="po-desc-label" translate="paymentsOverview.povlNote_SLO"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.note}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td >
                              <span class="po-desc-label" translate="paymentsOverview.povlId"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.id}}</span>
                            </td>
                          </tr>
                          <tr ng-if="::payment.referenceNumber">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.referenceNumber"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.referenceNumber}}</span>
                            </td>
                          </tr>
                          <tr ng-if="::payment.receiverName">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlReceiver"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.receiverName}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlFrom"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.fromAccountNumber}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlTo"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.toAccountNumber}}</span>
                            </td>
                          </tr>

                          <tr ng-if="::payment.paymentType === '2'">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.MCC">MCC</span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.mcc}}</span>
                            </td>
                          </tr>

                          <tr ng-if="::payment.paymentType === '2'">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.POSReference">POS Reference</span>
                            </td>
                            <td>
                              <span class="po-value-label">{{::payment.posReference}}</span>
                            </td>
                          </tr>


                          <tr>
                            <td>
                              <span ng-if="paymentsOverview.countryCode !== 'sl' || payment.paymentTypeGroup !== 'CurrencyExchange'" class="po-desc-label" translate="paymentsOverview.povlAmount"></span>
                              <span ng-if="paymentsOverview.countryCode === 'sl' && payment.paymentTypeGroup === 'CurrencyExchange'" class="po-desc-label" translate="paymentsOverview.povlAmountSlo"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-if="::payment.transactionAmount2">{{::payment.transactionAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</span>
                              <span class="po-value-label" ng-if="::!payment.transactionAmount2">{{::payment.amount|number:2}} {{::payment.currencySymbol}}</span>
                            </td>
                          </tr>
                          <tr ng-if="::payment.agreedRate && paymentsOverview.countryCode === 'sr'">
                            <td>
                              <span class="po-desc-label" translate="currencyConversion.agreedRate"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-if="::payment.calculatedAmount">{{::payment.agreedRate| number:4}} </span>
                            </td>
                          </tr>
                          <tr ng-if="::payment.calculatedAmount && paymentsOverview.countryCode === 'sr'">
                            <td>
                              <span class="po-desc-label" translate="currencyConversion.calculatedAmount"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-if="::payment.calculatedAmount && !payment.transactionAmount2">{{::payment.calculatedAmount| number:2}} {{::payment.destinationCurrencySymbol}}</span>
                              <span class="po-value-label" ng-if="::payment.calculatedAmount && payment.transactionAmount2">{{::payment.calculatedAmount| number:2}} {{::payment.currencySymbol}}</span>
                            </td>
                          </tr>

                          <tr  ng-if="payment.referenceTo && payment.paymentTypeGroup === 'DomesticPayment'"
                               ng-hide="payment.referenceTo === '00' && (paymentsOverview.countryCode === 'mn' || paymentsOverview.countryCode === 'mne' || paymentsOverview.countryCode === 'sr')">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.referenceTo"></span>
                            </td>
                            <td>
                              <span ng-if="paymentsOverview.countryCode != 'hr'" class="po-value-label">{{ ::paymentsOverview.split(payment.referenceTo)}}</span>
                              <span ng-if="paymentsOverview.countryCode === 'hr'" class="po-value-label">{{ ::payment.referenceTo}}</span>
                            </td>
                          </tr>

                          <tr  ng-if="payment.paymentTypeGroup === 'ForeignPayment' && (paymentsOverview.countryCode === 'mn' || paymentsOverview.countryCode === 'mne')">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.paymentGroupType"></span>
                            </td>
                            <td>
                              <span ng-switch="payment.paymentCharges">
                                <span class="po-value-label" ng-switch-when="3">
                                  SHA
                                </span>
                                <span class="po-value-label" ng-switch-when="2">
                                  OUR
                                </span>
                                <span class="po-value-label" ng-switch-when="1">
                                  BEN
                                </span>
                              </span>  
                            </td>
                          </tr>
                          
                          <!-- reference field related to BiH environment-->
                          <tr ng-if="payment.revenuePaymentDetails.reference">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.referenceNumberLabel"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.reference}}</span>
                            </td>
                          </tr>
                          <!-- end reference field related to BiH environment-->
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="signPayments.dateCreated"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.dateCreated| date:'shortDate'}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlDueDate"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.dueDate| date:'shortDate'}}</span>
                            </td>
                          </tr>
                          <tr ng-if="paymentsOverview.countryCode == 'bh' && payment.paymentTypeGroup == 'DomesticPayment'">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlUrgency"></span>
                            </td>
                            <td>
                              <span class="po-value-label" > {{ payment.urgency == '0' ? 'paymentsOverview.povlUrgencyNo' : 'paymentsOverview.povlUrgencyYes' | translate}}  </span>
                            </td>
                          </tr>

                          <!--IntegrMsg START-->
                          <tr ng-if="payment.integrMsg">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.povlNote"></span>
                            </td>
                            <td>
                              <span class="po-value-label" ng-bind="payment.integrMsg"></span>
                            </td>
                          </tr>
                          <!--IntegrMsg END-->


                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length === 1">
                            <td>
                              <span class="po-desc-label">{{paymentsOverview.signList[0].action}}</span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[0].signerName}}</span>
                            </td>
                          </tr>
                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length === 1">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[0].signDate| date: 'shortDate'}} {{paymentsOverview.signList[0].signTime| date: 'mediumTime'}}</span>
                            </td>
                          </tr>
                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                            <td>
                              <span class="po-desc-label">{{paymentsOverview.signList[0].action}}</span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[0].signerName}}</span>
                            </td>
                          </tr>
                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[0].signDate| date: 'shortDate'}} {{paymentsOverview.signList[0].signTime| date: 'mediumTime'}}</span>
                            </td>
                          </tr>
                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                            <td>
                              <span class="po-desc-label">{{paymentsOverview.signList[1].action}}</span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[1].signerName}}</span>
                            </td>
                          </tr>
                          <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                            <td>
                              <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{paymentsOverview.signList[1].signDate| date: 'shortDate'}} {{paymentsOverview.signList[1].signTime| date: 'mediumTime'}}</span>
                            </td>
                          </tr>

                          <!-- PUBLIC REVENUE SECTON DETAILS -->
                          <tr ng-if="payment.revenuePaymentDetails.taxPayerId">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.taxPayerNumber"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.taxPayerId}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.revenuePaymentType">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.paymentType"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.revenuePaymentType}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.revenueCode">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.revenueCode"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.revenueCode}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.municipality">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.municipality"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.municipality}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.taxPeriodFrom">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.taxPeriodFrom"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.taxPeriodFrom | date: 'shortDate'}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.taxPeriodTo">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.taxPeriodTo"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.taxPeriodTo | date: 'shortDate'}}</span>
                            </td>
                          </tr>

                          <tr ng-if="payment.revenuePaymentDetails.budgetOrganization">
                            <td>
                              <span class="po-desc-label" translate="domesticPayment.budgetOrganisation"></span>
                            </td>
                            <td>
                              <span class="po-value-label">{{payment.revenuePaymentDetails.budgetOrganization}}</span>
                            </td>
                          </tr>
                          <!-- END PUBLIC REVENUE SECTON DETAILS -->

                          <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                            <td colspan="2">
                              <div>
                                <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                                  <span ng-switch on="actionObject.methodName">
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.checkRecallStatus"
                                            ng-if="actionObject.isEnabled" ng-switch-when="RecallStatusCheckAllowed"
                                            ng-click="paymentsOverview.checkRecallStatus(payment)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.recall"
                                            ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed"
                                            ng-click="paymentsOverview.recallPaymentAction(payment)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.sign"
                                            ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed"
                                            data-ng-click="paymentsOverview.signPayment(payment)">
                                      Sign payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.delete"
                                             ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed"
                                             ng-click="paymentsOverview.deletePaymentAction(payment.id)">
                                      Delete payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.complaint"
                                             ng-if="actionObject.isEnabled && paymentsOverview.countryCode == 'sl'" ng-switch-when="AppealAllowed"
                                             ng-click="paymentsOverview.complaintPayment(payment)">
                                      Complaint payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.repeat"
                                             ng-if="actionObject.isEnabled && paymentsOverview.countryCode == 'sl'" ng-switch-when="InsertAllowed"
                                             ng-click="paymentsOverview.repeatPayment(payment)">
                                      Copy payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.instant"
                                             ng-if="actionObject.isEnabled" ng-switch-when="CancelPaymentAllowed"
                                             ng-click="paymentsOverview.cancelationOnPayment(payment)">
                                      Cancellation On Payment
                                    </button>
                                  </span>
                                </span>
                                <span>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.documentation"
                                          ng-if="paymentsOverview.countryCode == 'mn' && payment.paymentTypeGroup === 'ForeignPayment'"
                                          ng-click="paymentsOverview.documentation(payment);"
                                  >Documentation</button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.copy"
                                          ng-hide="paymentsOverview.countryCode == 'sl' || (paymentsOverview.countryCode == 'sr' && payment.paymentTypeGroup != 'DomesticPayment') || (payment.paymentTypeGroup == 'LocalCurrencyTransfer' && paymentsOverview.countryCode == 'bh')"
                                          ng-if="payment.status != 'Rejected'"
                                          ng-click="paymentsOverview.repeatPayment(payment);"
                                          >Copy payment</button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.edit"
                                          ng-if="payment.status === 'Opened' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                                          ng-click="paymentsOverview.editPayment(payment);">
                                    Edit payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.repeat"
                                          ng-hide="(payment.paymentTypeGroup == 'LocalCurrencyTransfer' && paymentsOverview.countryCode == 'bh') || paymentsOverview.countryCode == 'sr'"
                                          ng-if="payment.status === 'Rejected' && paymentsOverview.countryCode != 'sl'"
                                          ng-click="paymentsOverview.repeatPayment(payment);">
                                    Repeat payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.print"
                                          ng-hide="payment.status === 'Opened'"
                                          ng-click="paymentsOverview.printPayment(payment.id);">
                                    Print payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.confirmation"
                                          ng-click="paymentsOverview.confirmRealizedPayment(payment)"
                                          ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && payment.status != 'Cancelled'">
                                    Confirmation
                                  </button>
<!--
                                  todo: izbrisati "Cancelation On Payment" btn iz fiksnog dijela
-->
<!--                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.instant"
                                          ng-click="paymentsOverview.cancelationOnPayment(payment)">
                                    Cancellation On Payment
                                  </button>-->
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
                            <td colspan="2">
                              <div>
                                <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.confirmation"
                                        ng-if="payment.status == 'Processed'"
                                        ng-click="paymentsOverview.confirmRealizedPayment(payment)">
                                  Confirmation
                                </button>
                                <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                                  <span ng-switch on="actionObject.methodName">
                                    <!-- methodName missing-->
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.printSlo"
                                            ng-if="actionObject.isEnabled"
                                            ng-switch-when="DownloadLinkAllowed"
                                            ng-click="paymentsOverview.printPayment(payment.id);">
                                      Print payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.complaint"
                                             ng-if="actionObject.isEnabled"
                                             ng-switch-when="AppealAllowed"
                                             ng-click="paymentsOverview.complaintPayment(payment)">
                                      Complaint payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.repeat"
                                                  ng-if="actionObject.isEnabled"
                                                  ng-switch-when="InsertAllowed"
                                                  ng-click="paymentsOverview.repeatPayment(payment);">
                                      Repeat payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.cancelPayment"
                                            ng-if="actionObject.isEnabled"
                                            ng-switch-when="RecallAllowed"
                                            ng-click="paymentsOverview.cancelPaymentAction(payment)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.changePayment"
                                            ng-if="actionObject.isEnabled"
                                            ng-switch-when="UpdateAllowed"
                                            ng-click="paymentsOverview.changePaymentAction(payment)">
                                      Recall payment
                                    </button>
                                  </span>
                                </span>

                                <span class="pull-right">
                                  <!-- SLO SPECIFIC show Execute/Izvedba button only if Cancelled and dueDate > new Date() -->
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.execute"
                                    ng-if="payment.status === 'Cancelled' && paymentsOverview.checkDate(payment.dueDate)"
                                    ng-click="paymentsOverview.repeatPayment(payment, 'repeatCancelled');">
                                    Repeat payment
                                    </button>

                                  <!--Button for testing :D -->
                                  <!--<button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.execute"-->
                                  <!--ng-if="paymentsOverview.countryCode == 'sl' && payment.status === 'Cancelled'"-->
                                  <!--ng-click="paymentsOverview.repeatPayment(payment, 'repeatCancelled');">-->
                                  <!--Change payment-->
                                  <!--</button>-->
                                  <!--SLO SPECIFIC END-->
                                </span>
                                <!--<button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.copy"-->
                                        <!--ng-if="payment.status != 'Rejected'"-->
                                        <!--ng-click="paymentsOverview.repeatPayment(payment);">-->
                                  <!--Copy payment-->
                                <!--</button>-->

                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <!--cancelationsListGrouped-->
            <ul class="hypo-timeline payments-list" ng-if="paymentsOverview.cancelationsListGrouped.result.length !== 0">
              <li class="" ng-repeat="payment in paymentsOverview.cancelationsListGrouped.result">
                <span class="currency-time-frame" style="margin-left:14px;" ng-if="payment.isVirtual">{{payment.date | date:'shortDate'}}</span>
                <i ng-if="!payment.isVirtual"
                   class="icon icon-change currency-icon-change icon-lh"
                   style="z-index: 3;"
                   uib-popover="{{'paymentsOverview.ipsPopoverText' | translate}}"
                   popover-class="ips-custom-popover"
                   popover-append-to-body="true"
                   popover-enable="{{APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && payment.channelType === 'IPS'}}"
                   popover-trigger="'mouseenter'"></i>
                <div ng-if="!payment.isVirtual" class="hypo-timeline-item payment-item" ng-class="{'opened': payment.selected}" ng-click="paymentsOverview.togglePaymentDetails(payment)" >
                  <div class="hypo-timeline-body">
                    <div class="col-xs-8">
                      <h4 ng-if="payment.cancelationReasonDescription" class="m-b-0 m-t-0 c- no-wrap-text" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                        <span class="bold-font-payment" ng-switch-default>{{::payment.cancelationReasonDescription}}</span>
                      </h4>

                      <p class="m-b-0 m-t-0 iban-style-paym wrap-text">{{::payment.payment.toAccountNumber}} {{::payment.payment.receiverName}}</p>
                      <p ng-show="paymentsOverview.showPaymentId" class="m-b-0 m-t-0 iban-style" id="paymentId">{{::payment.payment.id}}</p>
                    </div>
                    <!-- Other payment types -->
                    <div class="col-xs-4">
                      <span class="pull-right t-a-r f-18">
                        <b class="price-payment" style="font-weight: 600; font-size:22px; " >{{::payment.payment.amount|number:2}} {{::payment.payment.currencySymbol}}</b>
                        <br/>
                        <span ng-class="{'text-danger': payment.paymentCancelationStatus === 'Rejected', 'payment-gray-status': payment.paymentCancelationStatus === 'InProcessing'}"
                              translate="{{paymentsOverview.checkProcessedPaymentMne(payment, true) ?
                               'paymentsOverview.processed2' : 'paymentsOverview.'+payment.paymentCancelationStatus}}" ></span>
                      </span>
                    </div>
                    <div class="clear"></div>
                  </div>
                  <div ng-if="payment.selected" class="transaction-details">
                    <i ng-if="!payment.isVirtual" class="icon icon-change currency-icon-change-2"></i>
                    <div>
                      <table class="table table-transparent table-responsive">
                        <tbody>
                        <tr ng-if="payment.paymentCancelationStatus === 'Rejected'">
                          <td ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
                            <span ng-switch-default class="po-desc-label" translate="paymentsOverview.povlNote"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.note}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td >
                            <span class="po-desc-label" translate="paymentsOverview.povlId"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.id}}</span>
                          </td>
                        </tr>
                        <tr ng-if="::payment.payment.referenceNumber">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.referenceNumberPayment"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.referenceNumber}}</span>
                          </td>
                        </tr>
                        <tr ng-if="::payment.referenceNumber">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.referenceNumberCancelations"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.referenceNumber}}</span>
                          </td>
                        </tr>
                        <tr ng-if="::payment.payment.receiverName">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.povlReceiver"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.receiverName}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.povlFrom"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.fromAccountNumber}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.povlTo"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.toAccountNumber}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.povlAmount"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{::payment.payment.amount|number:2}} {{::payment.payment.currencySymbol}}</span>
                          </td>
                        </tr>
                        <tr ng-if="::payment.payment.agreedRate && paymentsOverview.countryCode === 'sr'">
                          <td>
                            <span class="po-desc-label" translate="currencyConversion.agreedRate"></span>
                          </td>
                          <td>
                            <span class="po-value-label" ng-if="::payment.payment.calculatedAmount">{{::payment.payment.agreedRate| number:4}} </span>
                          </td>
                        </tr>
                        <tr ng-if="::payment.payment.calculatedAmount && paymentsOverview.countryCode === 'sr'">
                          <td>
                            <span class="po-desc-label" translate="currencyConversion.calculatedAmount"></span>
                          </td>
                          <td>
                            <span class="po-value-label" ng-if="::payment.payment.calculatedAmount && !payment.payment.transactionAmount2">{{::payment.payment.calculatedAmount| number:2}} {{::payment.payment.destinationCurrencySymbol}}</span>
                            <span class="po-value-label" ng-if="::payment.payment.calculatedAmount && payment.payment.transactionAmount2">{{::payment.payment.calculatedAmount| number:2}} {{::payment.payment.currencySymbol}}</span>
                          </td>
                        </tr>

                        <tr  ng-if="payment.payment.referenceTo && payment.payment.paymentTypeGroup === 'DomesticPayment'"
                             ng-hide="payment.payment.referenceTo === '00' && (paymentsOverview.countryCode === 'mn' || paymentsOverview.countryCode === 'mne' || paymentsOverview.countryCode === 'sr')">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.referenceTo"></span>
                          </td>
                          <td>
                            <span ng-if="paymentsOverview.countryCode != 'hr'" class="po-value-label">{{ ::paymentsOverview.split(payment.referenceTo)}}</span>
                          </td>
                        </tr>
                        <!-- reference field related to BiH environment-->
                        <tr ng-if="payment.payment.revenuePaymentDetails.reference">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.referenceNumberLabel"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.payment.revenuePaymentDetails.reference}}</span>
                          </td>
                        </tr>
                        <!-- end reference field related to BiH environment-->
                        <tr>
                          <td>
                            <span class="po-desc-label" translate="signPayments.dateCreated"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.cancelationDate| date:'shortDate'}}</span>
                          </td>
                        </tr>

                        <!--IntegrMsg START-->
                        <tr ng-if="payment.payment.integrMsg">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.povlNote"></span>
                          </td>
                          <td>
                            <span class="po-value-label" ng-bind="payment.payment.integrMsg"></span>
                          </td>
                        </tr>
                        <!--IntegrMsg END-->


                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length === 1">
                          <td>
                            <span class="po-desc-label">{{paymentsOverview.signList[0].action}}</span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[0].signerName}}</span>
                          </td>
                        </tr>
                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length === 1">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[0].signDate| date: 'shortDate'}} {{paymentsOverview.signList[0].signTime| date: 'mediumTime'}}</span>
                          </td>
                        </tr>
                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                          <td>
                            <span class="po-desc-label">{{paymentsOverview.signList[0].action}}</span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[0].signerName}}</span>
                          </td>
                        </tr>
                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[0].signDate| date: 'shortDate'}} {{paymentsOverview.signList[0].signTime| date: 'mediumTime'}}</span>
                          </td>
                        </tr>
                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                          <td>
                            <span class="po-desc-label">{{paymentsOverview.signList[1].action}}</span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[1].signerName}}</span>
                          </td>
                        </tr>
                        <tr ng-if="(payment.status === 'Rejected' || payment.status === 'InProcessing' || payment.status === 'Processed') && paymentsOverview.signList.length > 1">
                          <td>
                            <span class="po-desc-label" translate="paymentsOverview.signDate"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{paymentsOverview.signList[1].signDate| date: 'shortDate'}} {{paymentsOverview.signList[1].signTime| date: 'mediumTime'}}</span>
                          </td>
                        </tr>

                        <!-- PUBLIC REVENUE SECTON DETAILS -->
                        <tr ng-if="payment.revenuePaymentDetails.taxPayerId">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.taxPayerNumber"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.taxPayerId}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.revenuePaymentType">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.paymentType"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.revenuePaymentType}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.revenueCode">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.revenueCode"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.revenueCode}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.municipality">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.municipality"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.municipality}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.taxPeriodFrom">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.taxPeriodFrom"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.taxPeriodFrom | date: 'shortDate'}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.taxPeriodTo">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.taxPeriodTo"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.taxPeriodTo | date: 'shortDate'}}</span>
                          </td>
                        </tr>

                        <tr ng-if="payment.revenuePaymentDetails.budgetOrganization">
                          <td>
                            <span class="po-desc-label" translate="domesticPayment.budgetOrganisation"></span>
                          </td>
                          <td>
                            <span class="po-value-label">{{payment.revenuePaymentDetails.budgetOrganization}}</span>
                          </td>
                        </tr>
                        <!-- END PUBLIC REVENUE SECTON DETAILS -->

                        <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
                          <td colspan="2">
                            <div>
                                <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                                  <span ng-switch on="actionObject.methodName">
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.recall"
                                            ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed"
                                            ng-click="paymentsOverview.recallPaymentAction(payment)">
                                      Recall payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.sign"
                                            ng-if="actionObject.isEnabled && !paymentsOverview.isCancelationCall" ng-switch-when="SignAllowed"
                                            data-ng-click="paymentsOverview.signPayment(payment)">
                                      Sign payment
                                    </button>
                                    <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                            translate="instantPayment.buttons.sign"
                                            ng-if="actionObject.isEnabled && paymentsOverview.isCancelationCall" ng-switch-when="SignAllowed"
                                            data-ng-click="paymentsOverview.signCancelation(payment)">
                                      Sign cancelation
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                             translate="{{ paymentsOverview.isCancelationCall ? 'instantPayment.buttons.delete' : 'paymentActions.delete'}}"
                                             ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed"
                                             ng-click="paymentsOverview.deletePaymentAction(payment.id)">
                                      Delete payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.complaint"
                                             ng-if="actionObject.isEnabled && paymentsOverview.countryCode == 'sl'" ng-switch-when="AppealAllowed"
                                             ng-click="paymentsOverview.complaintPayment(payment)">
                                      Complaint payment
                                    </button>
                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.repeat"
                                             ng-if="actionObject.isEnabled && paymentsOverview.countryCode == 'sl'" ng-switch-when="InsertAllowed"
                                             ng-click="paymentsOverview.repeatPayment(payment)">
                                      Copy payment
                                    </button>
<!--                                    <button  class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.instant"
                                             ng-if="actionObject.isEnabled" ng-switch-when="CancelAllowed"
                                             ng-click="paymentsOverview.cancelationOnPayment(payment)">
                                      Cancellation On Payment
                                    </button>-->
                                  </span>
                                </span>
                              <span>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.copy"
                                          ng-hide="paymentsOverview.countryCode == 'sl' || (paymentsOverview.countryCode == 'sr' && payment.paymentTypeGroup != 'DomesticPayment') || (payment.paymentTypeGroup == 'LocalCurrencyTransfer' && paymentsOverview.countryCode == 'bh')"
                                          ng-if="payment.paymentCancelationStatus != 'Rejected'"
                                          ng-click="paymentsOverview.repeatPayment(payment);"
                                  >Copy payment</button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10"
                                          translate="{{ paymentsOverview.isCancelationCall ? 'instantPayment.buttons.edit' : 'paymentActions.edit'}}"
                                          ng-if="payment.paymentCancelationStatus === 'Opened' && APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                                          ng-click="paymentsOverview.editPayment(payment);">
                                    Edit payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.repeat"
                                          ng-hide="(payment.paymentTypeGroup == 'LocalCurrencyTransfer' && paymentsOverview.countryCode == 'bh') || paymentsOverview.countryCode == 'sr'"
                                          ng-if="payment.paymentCancelationStatus === 'Rejected' && paymentsOverview.countryCode != 'sl'"
                                          ng-click="paymentsOverview.repeatPayment(payment);">
                                    Repeat payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.print"
                                          ng-hide="payment.paymentCancelationStatus === 'Opened'"
                                          ng-click="paymentsOverview.printCancelation(payment.id);">
                                    Print payment
                                  </button>
                                  <button class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase m-m-b-10" translate="paymentActions.confirmation"
                                          ng-click="paymentsOverview.confirmRealizedPayment(payment)"
                                          ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && payment.paymentCancelationStatus != 'Cancelled'">
                                    Confirmation
                                  </button>
                                </span>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div>
      <div class="col-xs-12 t-a-c m-t-15">
        <button ng-if="paymentsOverview.paymentsOverviewListGrouped.hasMore == true || paymentsOverview.cancelationsListGrouped.hasMore == true" data-ng-click="paymentsOverview.loadMorePayments()" type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn" translate="paymentsOverview.loadMoreBtn">Load more</button>
      </div>
    </div>
  </div>
</section>


