<section id="main">
    <div class="container">
        <div class="row f-14">
            <!--<div class="col-sm-4 no-padding-on-phone">

                <div class="wb-list-wrapper app-white-card m-b-15">
                    <div>
                        <div class="cell-display">
                            <i class="zmdi zmdi-chevron-left zmdi-hc-2x"></i>
                        </div>
                        <div class="cell-display p-l-20 align-vertical-middle">
                            <a href="" data-ng-click="eInvoicesDetail.goBack();" class="custom-link" translate="eInvoiceDetail.goBack"></a>
                        </div>
                    </div>
                </div>

                <div class="app-white-card m-b-15 hidden-xs">
                    <div class="header-card">
                        <span translate="eInvoiceDetail.export"></span>
                    </div>
                    <div class="list">
                        <div class="list-item inner-list-el">
                            <a href="" class="cutom-link-title" translate="eInvoiceDetail.eInvoicePDF"></a>
                        </div>
                        <div class="list-item inner-list-el">
                            <a href="" class="cutom-link-title" translate="eInvoiceDetail.eInvoiceXML"></a>
                        </div>
                    </div>
                </div>

                <div class="app-white-card m-b-15 hidden-xs">
                    <div class="header-card">
                        <span translate="eInvoiceDetail.attachments"></span>
                    </div>
                    <div class="list">
                        <div class="list-item inner-list-el" ng-if="eInvoicesDetail.eInvoiceDocumentAttachmentList" data-ng-repeat="invoiceAttachment in ::eInvoicesDetail.eInvoiceDocumentAttachmentList">
                            <a href="" class="cutom-link-title">Attachment 1</a>
                        </div>

                        <div class="list-item inner-list-el t-a-c no-data-image" ng-if="!eInvoicesDetail.eInvoiceDocumentAttachmentList">
                            <i class="zmdi zmdi-cloud-off zmdi-hc-3x"></i>
                            <h6 class="no-data-label" translate="eInvoiceDetail.noAttachments"></h6>
                        </div>

                    </div>
                </div>

                <div class="app-white-card m-b-15 hidden-xs">
                    <div class="list">
                        <div class="list-item inner-list-el">
                            <a href="" class="cutom-link-title" translate="eInvoiceDetail.unsubscribeEInvoices"></a>
                        </div>
                    </div>
                </div>

            </div>-->
            <div class="col-sm-12 no-padding-on-phone">
                <div class="app-white-card m-b-15">
                    <div class="header-card">
                        <span translate="eInvoiceDetail.detail"></span>
                    </div>
                    <div class="p-t-10 p-l-15 p-r-15">
                        <div class="row bottom-border p-b-10">
                            <h4 class="col-sm-6" translate="eInvoiceDetail.unpaidInvoice">
              </h4>
                            <div class="col-sm-6">
                                <button type="input" class="btn btn-default app-btn-blue" data-ng-click="eInvoicesDetail.openEInvoiceModal()" translate="eInvoiceDetail.pay"></button>
                                <button type="input" class="btn btn-default app-btn-blue m-l-20" translate="eInvoiceDetail.markAsPayed"></button>
                            </div>
                        </div>

                        <div class="row bottom-border p-t-10 p-b-10 light-bcg">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceOverview.issuer"></div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.creditorName}}</b>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.issuerAccount">t</div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.creditorAccount}}</b>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.recipient"></div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.creditorName}}</b>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.recipientAccount"></div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.creditorAccount}}</b>
                                </div>
                            </div>
                        </div>

                        <div class="row bottom-border p-t-10 p-b-10">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.purpose"></div>
                                    <div class="wrap-text">
                                        <b>{{::eInvoicesDetail.eInvoiceObject.purpose}}</b>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.refNumber">t</div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.paymentReferenceNumber}}</b>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.amount">t</div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.amount}} {{::eInvoicesDetail.eInvoiceObject.currencySymbol}}</b>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.issueDate"></div>
                                    <b>{{::eInvoicesDetail.eInvoiceObject.valueDate | date:'shortDate' : '+0200'}}</b>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.purposeCode"></div>
                                    <b>no info</b>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.dueDate"></div>
                                    <b>no info</b>
                                </div>
                            </div>
                        </div>

                        <div class="row p-t-10 p-b-10 light-bcg">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.digitalSignature"></div>
                                    <div class="wrap-text">
                                        <b>no info</b>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="clickable-link eim-link" data-ng-click="eInvoicesDetail.toggleSignatureDetailView();">
                                        <!--<span translate="eInvoicesDetail.translateName"></span>-->
                                        <span translate="eInvoiceDetail.visibilityDigitalSignature"></span>
                                        <span class="inherit-font-size" ng-class="eInvoicesDetail.signatureDetail? 'zmdi zmdi-chevron-up': 'zmdi zmdi-chevron-down'"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row p-b-10 light-bcg" ng-show="eInvoicesDetail.signatureDetail">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.signatureId"></div>
                                    <div class="wrap-text">
                                        <b>no info</b>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.digitalCertNumber"></div>
                                    <div class="wrap-text">
                                        <b>no info</b>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.signatureName"></div>
                                    <div class="wrap-text">
                                        <b>no info</b>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div translate="eInvoiceDetail.certificateIssuer"></div>
                                    <div class="wrap-text">
                                        <b>no info</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-white-card m-b-15 hidden-sm hidden-md hidden-lg">
                <div class="header-card">
                    <span translate="eInvoiceDetail.export"></span>
                </div>
                <div class="list">
                    <div class="list-item inner-list-el">
                        <a href="" class="cutom-link-title" translate="eInvoiceDetail.eInvoicePDF"></a>
                    </div>
                    <div class="list-item inner-list-el">
                        <a href="" class="cutom-link-title" translate="eInvoiceDetail.eInvoiceXML"></a>
                    </div>
                </div>
            </div>

            <div class="app-white-card m-b-15 hidden-sm hidden-md hidden-lg">
                <div class="header-card">
                    <span translate="eInvoiceDetail.attachments"></span>
                </div>
                <div class="list">
                    <div class="list-item inner-list-el">
                        <a href="" class="cutom-link-title">Attachment 1</a>
                    </div>
                    <div class="list-item inner-list-el">
                        <a href="" class="cutom-link-title">Attachment 2</a>
                    </div>
                </div>
            </div>

            <div class="app-white-card m-b-15 hidden-sm hidden-md hidden-lg">
                <div class="list">
                    <div class="list-item inner-list-el">
                        <a href="" class="cutom-link-title" translate="eInvoiceDetail.unsubscribeEInvoices"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>