<!-- TIMELINE CONTAINER -->
<div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
  <div class="row p-15">
    <div class="col-xs-4 dropdown acc-period-select " style="display: inline-block">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="true">
        {{savingsAccount.selectedPeriod.item.description | translate}}
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li data-ng-repeat="period in savingsAccount.periodList">
          <a href="" class="f-13" data-ng-click="savingsAccount.paymentsPeriodFilter(period)">
            {{period.item.description | translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-xs-8 pull-right" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <label class="checkbox checkbox-inline m-l-15 pull-right">
        <input type="checkbox" data-ng-model="savingsAccount.allRequests"
               data-ng-click="savingsAccount.loadAllAccountsTimeline()">
        <span class="input-helper" translate="ebook.showAllRequests">Prikaži zahtjeve za sve račune</span>
      </label>
    </div>
  </div>
  <!-- ================== -->
  <!-- Slovenia -->
  <!-- ================== -->
  <div ng-switch-when="sl">
    <div class="timeline-row col-sm-12 no-padding">
      <div class="t-a-c p-10 no-data-image" ng-if="savingsAccount.timelineListGrouped.result.length == 0">
        <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
        <h3 class="no-data-label" translate="dashboard.noTimelineData"></h3>
      </div>
      <div class="hypo-timeline-wrapper app-white-card" ng-hide="savingsAccount.timelineListGrouped.result.length == 0">
        <ul class="hypo-timeline" kcd-recompile="savingsAccount.timelineListGrouped">
          <li ng-class="{'time-label': term.isVirtual}" ng-repeat="term in savingsAccount.timelineListGrouped.result">
          <span class="currency-time-frame"
                ng-if="term.isVirtual === true">{{(term.valueDate) | date:'shortDate' : '+0200'}}</span>
            <i class="zmdi zmdi-timer z-index-1" ng-if="!term.isVirtual"></i>
            <div ng-if="!term.isVirtual" class="hypo-timeline-item payment-item"
                 ng-click="savingsAccount.showOrderDetails(term)" ng-class="{'opened': term.selected}">
              <div class="hypo-timeline-body">
                <div class="col-xs-8 hidden-xs">
                  <h4 class="m-b-0 m-t-10 c-gray">
                    <b translate="savingsOrder.newSavingsOrderTimelineTitle"></b><b>,</b>
                    <b>{{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}<span ng-if="term.durationInMonths != 0">, {{term.durationInMonths}} {{ 'termDeposit.month' | translate }}</span></b>
                  </h4>
                </div>

                <div class="col-xs-8 visible-xs p-l-0">
                  <h4 class="m-b-0 m-t-10 f-12 c-gray">
                    <b translate="savingsOrder.newSavingsOrderTimelineTitle"></b><b>,</b>
                    <b>{{'termDeposit.request' | translate}}</b>
                    <b>{{term.id}}, {{term.durationInMonths}} {{ 'termDeposit.month' | translate }}</b>

                  </h4>
                </div>
                <!-- Other term types -->
                <div class="col-xs-4 hidden-xs">
                            <span class="pull-right t-a-r m-t-5 f-18">
                            <span  ng-if="term.firstDepositAmount"><b>{{term.firstDepositAmount|currency:""}} {{::term.currency}}</b><br/></span>
                            <span ng-if="term.monthlyDepositAmount"><b>{{term.monthlyDepositAmount|currency:""}} {{::term.currency}}</b><br/></span>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>
                <div class="col-xs-4 visible-xs p-r-0">
                            <span class="pull-right t-a-r m-t-5 f-12">
                            <span ng-if="term.firstDepositAmount"><b >{{term.firstDepositAmount|currency:""}} {{::term.currency}}</b><br/></span>
                            <span ng-if="term.monthlyDepositAmount"><b>{{term.monthlyDepositAmount|currency:""}} {{::term.currency}}</b><br/></span>
                            <span>{{term.translatedStatus | translate}}</span>
                            </span>
                </div>
                <div class="clear"></div>
              </div>

              <!-- ========= -->
              <!-- DETAILS -->
              <!-- ========= -->
              <div ng-if="term.selected" class="transaction-details">

                <div class="table-responsive hidden-xs">
                  <table class="table table-transparent">
                    <tbody>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.dateOfEntry"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.savingEnteredDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>
                    <tr ng-if="term.endDate">
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.executionDate"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.endDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.savingOrderName"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.savingPurpose }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.savingsCurrency"></span>
                      </td>
                      <td>
                        <span class="po-value-label">{{ term.savingCurrencyType }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.fromAccount"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.sourceAccount }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <!--<span class="po-desc-label" translate="savingsOrder.initialAmount"></span>-->
                        <span class="po-desc-label" ng-if="term.firstDepositAmount"
                              translate="savingsOrder.initialDepositAmount"></span>
                        <span class="po-desc-label" ng-if="term.monthlyDepositAmount"
                              translate="savingsOrder.monthlyDepositAmount"></span>
                      </td>
                      <td>
                        <span class="po-value-label" ng-if="term.firstDepositAmount">{{term.firstDepositAmount|currency:""}}</span>
                        <span class="po-value-label" ng-if="term.monthlyDepositAmount">{{term.monthlyDepositAmount|currency:""}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.currency"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.currency }}</span>
                      </td>
                    </tr>
                    <tr  ng-if="term.hasOwnProperty('savingRate')">
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.realInterestRate"></span>
                      </td>
                      <td >
                        <span class="po-value-label">
                          {{ term.savingRate }} %</span>
                      </td>
                    </tr>
                    <tr ng-if="term.hasOwnProperty('referenceSavingRate')">
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.referenceInterestRate"></span>
                      </td>
                      <td>
                        <span class="po-value-label">
                          {{ term.referenceSavingRate }} %</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label" translate="savingsOrder.dateOfOpening"></span>
                      </td>
                      <td>
                        <span class="po-value-label"> {{ term.savingEnteredDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>

                    <tr ng-if="term.durationInMonths >= 1">
                      <td><span class="po-desc-label" translate="accounts.annualAssignmentOfInterests"></span></td>
                      <td><span class="po-value-label" translate="alert.yes"></span></td>
                    </tr>

                    <div
                      ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && term.allowedActionList.itemList.length">
                      <tr ng-repeat-start="item in term.allowedActionList.itemList"
                          ng-if="item.methodName === 'DownloadLinkAllowed'">
                        <td>
                          <span class="po-desc-label" translate="depositDetails.contract"></span>
                        </td>
                        <td>
                        <span class="po-value-label">
                          <button class="btn btn-default" ng-click="savingsAccount.downloadContract(term.id)"
                                  translate="messages.downloadAttachment">Download</button>
                        </span>
                        </td>
                      </tr>

                      <tr ng-repeat-end ng-if="item.methodName === 'DownloadTermsLinkAllowed'">
                        <td>
                          <span class="po-desc-label" translate="depositDetails.termsAndConditions"></span>
                        </td>
                        <td>
                          <span class="po-value-label">
                            <button class="btn btn-default"
                                    ng-click="savingsAccount.downloadTermsAndConditions(term.id)"
                                    translate="messages.downloadAttachment">Download</button>
                          </span>
                        </td>
                      </tr>
                    </div>

                    </tbody>
                  </table>
                </div>
                <!-- ========= -->
                <!-- MOBILE -->
                <!-- ========= -->
                <div class="table-responsive visible-xs">
                  <table class="table table-transparent f-10">
                    <tbody>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.dateOfEntry"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.savingEnteredDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>
                    <tr ng-if="term.endDate">
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.executionDate"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.endDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.savingOrderName"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.savingPurpose }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.savingsCurrency"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs">{{ term.savingCurrencyType }}</span>
                      </td>
                    </tr>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.fromAccount"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.sourceAccount }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <!--<span class="po-desc-label-xs" translate="savingsOrder.initialAmount"></span>-->
                        <span class="po-desc-label-xs" ng-if="term.firstDepositAmount"
                              translate="savingsOrder.initialDepositAmount"></span>
                        <span class="po-desc-label-xs" ng-if="term.monthlyDepositAmount"
                              translate="savingsOrder.monthlyDepositAmount"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs" ng-if="term.firstDepositAmount">{{term.firstDepositAmount|currency:""}}</span>
                        <span class="po-value-label-xs" ng-if="term.monthlyDepositAmount">{{term.monthlyDepositAmount|currency:""}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.currency"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.currency }}</span>
                      </td>
                    </tr>
                    <tr ng-if="term.hasOwnProperty('savingRate')">
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.realInterestRate"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.savingRate }} %</span>
                      </td>
                    </tr>
                    <tr ng-if="term.hasOwnProperty('referenceSavingRate')">
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.referenceInterestRate"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.referenceSavingRate }} %</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="po-desc-label-xs" translate="savingsOrder.dateOfOpening"></span>
                      </td>
                      <td>
                        <span class="po-value-label-xs"> {{ term.savingEnteredDate | date: 'shortDate' : '+0200' }}</span>
                      </td>
                    </tr>
                    <tr ng-if="term.durationInMonths >= 1">
                      <td><span class="po-desc-label" translate="accounts.annualAssignmentOfInterests"></span></td>
                      <td><span class="po-value-label" translate="alert.yes"></span></td>
                    </tr>
                    <div
                      ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && term.allowedActionList.itemList.length">
                      <tr ng-repeat-start="item in term.allowedActionList.itemList"
                          ng-if="item.methodName === 'DownloadLinkAllowed'">
                        <td>
                          <span class="po-desc-label-xs" translate="depositDetails.contract"></span>
                        </td>
                        <td>
                        <span class="po-value-label-xs">
                          <button class="btn btn-default" ng-click="savingsAccount.downloadContract(term.id)"
                                  translate="messages.downloadAttachment">Download</button>
                        </span>
                        </td>
                      </tr>

                      <tr ng-repeat-end ng-if="item.methodName === 'DownloadTermsLinkAllowed'">
                        <td>
                          <span class="po-desc-label-xs" translate="depositDetails.termsAndConditions"></span>
                        </td>
                        <td>
                          <span class="po-value-label-xs">
                            <button class="btn btn-default"
                                    ng-click="savingsAccount.downloadTermsAndConditions(term.id)"
                                    translate="messages.downloadAttachment">Download</button>
                          </span>
                        </td>
                      </tr>
                    </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="show-more-btn ">
        <button ng-if="savingsAccount.timelineListGrouped.hasMore"
                data-ng-click="savingsAccount.loadMoreTimelineItemsGrouped()"
                type="input" class="btn btn-default app-btn-blue-inverse show-all-accounts-btn load-events-btn"
                translate="dashboard.loadEvents">Load older events
        </button>
      </div>
    </div>

  </div>
</div>

<!-- END TIMELINE -->
