(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name core.controller:addikoDropdown
     *
     * @description
     *
     */
    angular
      .module('core')
      .directive('addikoTabsContainer', addikoDropdown);
  
    function addikoDropdown() {
        return {
            restrict: 'EA',
            scope: {
                tabs: '=tabs',
                selectedTab: '=selected'
            },
            templateUrl: 'core/components/tabs-container/tabs-container.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;
                vm.tabs = $scope.tabs;
                if (!$scope.selectedTab && vm.tabs.length) {
                    vm.switchTab(vm.tabs[0]);
                }
                console.log(vm.selectedTab)
                console.log($scope.selectedTab)
                vm.switchTab = function(tab) {
                    if (tab.id != $scope.selectedTab) {
                        $scope.selectedTab = tab.id;
                    }
                  }
            }
        };
    }
}());