<table class="table table-transparent table-responsive" style="font-size: 17px;color: #9e9e9e !important;font-family: CharlieMedium;" >
  <tbody>
  <!--<tr>-->
    <!--<td>-->
      <!--<span class="po-desc-label" translate="paymentsOverview.povlStatus">Status:</span>-->
    <!--</td>-->
    <!--<td>-->
      <!--<span class="po-value-label">-->
        <!--<span class="po-value-label" ng-class="{'text-danger': payment.status === 'Rejected'}" ng-if="payment.status === 'Rejected'" translate="paymentsOverview.rejected"></span>-->
        <!--<span class="po-value-label" ng-if="payment.status === 'Processed'" translate="paymentsOverview.processed"></span>-->
        <!--<span class="po-value-label" ng-if="payment.status === 'Cancelled'" translate="paymentsOverview.cancelled"></span>-->
        <!--<span class="po-value-label" ng-if="payment.status === 'InProcessing'" translate="paymentsOverview.inProcessing"></span>-->
        <!--<span class="po-value-label" ng-if="payment.status === 'Opened'" translate="paymentsOverview.open"></span>-->
        <!--<span class="po-value-label" ng-if="payment.status === 'WaitingQueue'" translate="paymentsOverview.waitingQueque"></span>-->
      <!--</span>-->
    <!--</td>-->
  <!--</tr>-->
  <tr>
    <td>
      <span class="po-desc-label" translate="paymentsOverview.povlId">Payment id:</span>
    </td>
    <td>
      <span class="po-value-label">{{::payment.id}}</span>
    </td>
  </tr>
  <!--<tr>-->
    <!--<td>-->
      <!--<span class="po-desc-label" translate="paymentsOverview.povlReceiver">Receiver name:</span>-->
    <!--</td>-->
    <!--<td>-->
      <!--<span class="po-value-label">{{::payment.receiverName}}</span>-->
    <!--</td>-->
  <!--</tr>-->
  <tr>
    <td>
      <span class="po-desc-label" translate="paymentsOverview.povlFrom">From account:</span>
    </td>
    <td>
      <span class="po-value-label">{{::payment.fromAccountNumber}}</span>
    </td>
  </tr>
  <tr>
    <td>
      <span class="po-desc-label" translate="paymentsOverview.povlTo">To account:</span>
    </td>
    <td>
      <span class="po-value-label">{{::payment.toAccountNumber}}</span>
    </td>
  </tr>
  <tr>
    <td>
      <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'" class="po-desc-label" translate="paymentsOverview.povlAmount">Amount:</span>
      <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" class="po-desc-label" translate="paymentsOverview.povlAmountSlo">Amount:</span>
    </td>
    <td ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl'">
      <span class="po-value-label" ng-if="!payment.transactionAmount2">{{payment.amount|number:2}} {{::payment.currencySymbol}}</span>
      <span class="po-value-label" ng-if="payment.transactionAmount2">{{payment.transactionAmount2|number:2}} {{::payment.destinationCurrencySymbol}}</span>
    </td>
    <td ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'">
      <span class="po-value-label" ng-if="payment.amount">{{payment.amount|number:2}} {{::payment.currencySymbol}}</span>
    </td>
  </tr>

  <tr>
    <td>
      <span class="po-desc-label" translate="currencyConversion.when">Due date:</span>
    </td>
    <td>
      <span class="po-value-label">{{::payment.dueDate| date:'shortDate' : '+0200'}}</span>
    </td>
  </tr>
  <tr ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl' && payment.status == 'Rejected'">
    <td>
      <span class="po-desc-label" translate="paymentsOverview.povlNote_SLO">Opis greske</span>
    </td>
    <td>
      <span class="po-value-label">{{::payment.note}}</span>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <div>
                    <span class="pull-right" ng-repeat="actionObject in payment.allowedActionList.itemList">
                        <span ng-switch on="actionObject.methodName">
                            <button ng-if="actionObject.isEnabled" ng-switch-when="RecallAllowed" ng-click="currencyConversion.recallPaymentAction(payment.id);"
                                    class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase" translate="currencyConversion.recallPayment"
                                    translate="paymentActions.recall"></button>
                            <button ng-if="actionObject.isEnabled" ng-switch-when="SignAllowed" ng-click="currencyConversion.signPaymentAction(payment);"
                                    class="btn btn-primary app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                    translate="paymentActions.sign"></button>
                            <button ng-if="actionObject.isEnabled" ng-switch-when="DeleteAllowed" ng-click="currencyConversion.deletePaymentAction(payment.id, 'timeline');"
                                    class="btn btn-primary-warning app-btn-blue btn-sm m-r-5 m-l-5 f-uppercase"
                                    translate="paymentActions.delete"></button>
                        </span>
                    </span>
      </div>
    </td>
  </tr>
  </tbody>
</table>
