<div class="p-l-15 p-r-15 f-14" ng-hide="eInvoicesModal.signSuccess">
  <div class="row eim-header p-10 bottom-border">
    <b class="f-18 p-l-10" translate="eInvoicesModal.headerLabel">E-Invoice Payment</b>
    <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst eim-header-btn-close" data-ng-click="eInvoicesModal.closeModal();"></i>
  </div>
  <div class="row p-10 bottom-border">
    <form name="eInvoicePaymentForm">
      <div class="eim-details col-md-12 col-sm-12 no-padding-on-phone">
        <div class="form-group col-md-6 col-sm-6 p-l-0">
          <label class="eim-label" translate="eInvoicesModal.issuerLabel">Issuer:</label>
          <br>
          <div class="eim-value-label">{{eInvoicesModal.eInvoiceObject.creditorName}}</div>
          <div class="eim-value-label">{{eInvoicesModal.eInvoiceObject.creditorCity}}</div>
        </div>
        <div class="form-group col-md-6 col-sm-6 p-r-0">
          <label class="eim-label" translate="eInvoicesModal.purposeLabel">Purpose:</label>
          <br>
          <div class="eim-value-label">{{eInvoicesModal.eInvoiceObject.purpose}}</div>
        </div>
        <div class="form-group">
          <div class="cell-display col-md-6 col-sm-6 p-l-0">
            <label class="eim-label" translate="eInvoicesModal.amountLabel ">Amount:</label>
            <br>
            <div class="input-group">
              <input type="text" class="form-control t-a-r" data-ng-model="eInvoicesModal.tempDompObj.amount" name="amount" id="amount" placeholder="{{'eInvoicesModal.amountPlaceholder'| translate}}" amount-input-mask required ng-class="{'domp-error': eInvoicePaymentForm.amount.$invalid}">
              <span class="input-group-btn">
                <button type="button" class="btn btn-default input-group-app-btn disabled">{{eInvoicesModal.eInvoiceObject.currencySymbol}}</button>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="cell-display col-md-6 col-sm-6 p-r-0">
            <label class="eim-label" translate="eInvoicesModal.refNumberLabel ">Reference number:</label>
            <br>
            <div class="eim-value-label">{{eInvoicesModal.eInvoiceObject.paymentReferenceNumber}}</div>
          </div>
        </div>
      </div>
      <div class="eim-instruction col-md-12 col-sm-12">
        <b translate="eInvoicesModal.instructionLabel">Select payment date and confirm payment</b>
      </div>
      <div class="col-md-6 col-sm-6 p-l-10">
        <div class="form-group">
          <label class="domp-form-label" for="fromAccountSelect" translate="eInvoicesModal.fromAccountPayLabel"></label>
          <select class="form-control" name="fromAccountSelect" id="fromAccountSelect" ng-options="option.optionValue for option in eInvoicesModal.paymentTypeGroupDomestic.result[0].debitAccountList" ng-model="eInvoicesModal.tempDompObj.fromAccountSelect"></select>
          <!--    <label class="m-b-0 m-t-0 f-13"><span translate="eInvoicesModal.availableAmountLabel"></span>{{eInvoicesModal.tempDompObj.fromAccountSelect.balance | number:2}} {{eInvoicesModal.tempDompObj.fromAccountSelect.currencySymbol}}</label> -->
        </div>
      </div>
      <div class="col-md-6 col-sm-6 p-r-0">
        <div class="form-group">
          <div class="m-b-15 p-l-10">
            <span class="eim-label" translate="eInvoicesModal.paymentDateLabel ">Payment date</span>
          </div>
          <div class="radio-ctn" ng-init="eInvoicesModal.paymentDateOption.val=1">
            <div class="col-md-12 col-sm-12 m-b-10">
              <label class="radio radio-inline m-r-20 radio-value">
                <input type="radio" data-ng-model="eInvoicesModal.paymentDateOption.val" name="paymentDateOptions " value="1">
                <i class="input-helper p-l-5"></i> {{'eInvoicesModal.today' | translate}}
              </label>
            </div>

            <div class="col-md-12 col-sm-12 m-b-10">
              <label class="radio radio-inline m-r-20 radio-value">
                <input type="radio" data-ng-model="eInvoicesModal.paymentDateOption.val" name="paymentDateOptions " value="2">
                <i class="input-helper p-l-5"></i> {{'eInvoicesModal.dueDate' | translate}}
              </label>
            </div>

            <div class="col-md-12 col-sm-12 m-b-10">
              <label class="radio radio-inline m-r-20 radio-value">
                <input type="radio" data-ng-model="eInvoicesModal.paymentDateOption.val" name="paymentDateOptions " value="3">
                <i class="input-helper p-l-5"></i> {{'eInvoicesModal.otherDate' | translate}}
              </label>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 form-group" ng-if="eInvoicesModal.paymentDateOption.val==3">
            <div class="input-group">
              <input type="text" class="form-control no-border-input" name="dueDateInput"
                     uib-datepicker-popup="shortDate" data-ng-model="eInvoicesModal.tempDompObj.dateInput"
                     show-weeks="false" is-open="eInvoicesModal.datePickerOpened"
                     current-text="{{'core.period.today' | translate}}"
                     clear-text="{{'core.datePicker.clearBtn' | translate}}"
                     close-text="{{'core.datePicker.doneBtn' | translate}}"
                     datepicker-options="eInvoicesModal.dateOptions" required>
              <span class="input-group-btn">
                <button data-ng-click="eInvoicesModal.openDatePicker()" type="button"
                        class="btn btn-default input-group-app-btn"><i class="icon icon-calendar" style="font-size: 21px;"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
</div>
<div class="row p-10 eim-footer">
  <a href="" class="clickable-link eim-link p-l-10 p-t-10" data-ng-click="eInvoicesModal.goToEInvoiceDetail();">
    <span translate="eInvoicesModal.viewEinvoice"></span>
  </a>
  <div class="eim-btn p-r-5">
    <button class="btn btn-default app-btn-blue ng-scope pull-right eim-action-button" data-ng-click="eInvoicesModal.executePayment(eInvoicesModal.eInvoiceObject); " translate="eInvoicesModal.btnPay ">
      Pay
    </button>
    <button class="btn btn-default app-btn-blue ng-scope pull-right m-r-15 eim-action-button" data-ng-click="eInvoicesModal.closeModal(); " translate="eInvoicesModal.btnCancel ">
      Cancel
    </button>
  </div>
</div>
</div>
<!-- verify and sign -->
<div ng-hide="eInvoicesModal.paymentResponse && !eInvoicesModal.signSuccess" class="f-14">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="eim-sgn-header p-l-10" colspan="2">
          <b class="f-18" translate="eInvoicesModal.signHeaderLabel"></b>
          <i class="zmdi zmdi-close zmdi-hc-2x pull-right pointer-cst eim-header-btn-close" data-ng-click="eInvoicesModal.closeModal();"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr ng-if="eInvoicesModal.paymentResponse.fromAccountNumber">
        <td class="bold-font" translate="eInvoicesModal.fromAccount"></td>
        <td>{{eInvoicesModal.paymentObject.fromAccountNumber}}</td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.toAccountNumber">
        <td class="bold-font" translate="eInvoicesModal.toAccount"></td>
        <td>{{eInvoicesModal.paymentObject.toAccountNumber}}</td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.receiverName">
        <td class="bold-font" translate="eInvoicesModal.receiver"></td>
        <td>{{eInvoicesModal.paymentResponse.receiverName}}</td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.receiverAddress1 && eInvoicesModal.paymentResponse.receiverCity">
        <td class="bold-font" translate="eInvoicesModal.receiverAddress"></td>
        <td>{{eInvoicesModal.paymentResponse.receiverAddress1 + ', ' + eInvoicesModal.paymentResponse.receiverCity}}
        </td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.amount">
        <td class="bold-font" translate="eInvoicesModal.amount"></td>
        <td>{{eInvoicesModal.paymentResponse.amount | currency: ""}} {{eInvoicesModal.paymentResponse.currencySymbol}}
        </td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.purpose">
        <td class="bold-font" translate="eInvoicesModal.description"></td>
        <td>{{eInvoicesModal.paymentResponse.purpose}}</td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.intermediateBankName">
        <td class="bold-font" translate="eInvoicesModal.bankName"></td>
        <td>{{eInvoicesModal.paymentResponse.intermediateBankName}}</td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.intermediateBankAddress">
        <td class="bold-font" translate="eInvoicesModal.bankAddress"></td>
        <td>{{eInvoicesModal.paymentResponse.intermediateBankAddress + ', ' + eInvoicesModal.paymentResponse.intermediateBankAddress2}}
        </td>
      </tr>
      <tr ng-if="eInvoicesModal.paymentResponse.dueDate">
        <td class="bold-font" translate="eInvoiceDetail.dueDate"></td>
        <td>{{eInvoicesModal.paymentResponse.dueDate | date:'shortDate' : '+0200'}}</td>
      </tr>
      <tr class="row eim-footer">
        <td class="eim-btn p-r-5">
          <!--<button class="btn btn-default app-btn-blue ng-scope pull-right eim-action-button" data-ng-click="eInvoicesModal.signPayment();" translate="eInvoicesModal.btnSign">-->
          <button class="btn btn-default app-btn-blue ng-scope pull-right eim-action-button" data-ui-sref="payments.sign({paymentId: eInvoicesModal.paymentResponse.id})" translate="eInvoicesModal.btnSign">
            Sign
          </button>
          <button class="btn btn-default app-btn-blue ng-scope pull-right m-r-15 eim-action-button" data-ng-click="eInvoicesModal.goBack();" translate="eInvoicesModal.btnEdit">
            Back
          </button>
          <button class="btn btn-default app-btn-blue ng-scope pull-right m-r-15 eim-action-button" data-ng-click="eInvoicesModal.closeModal(); " translate="eInvoicesModal.btnCancel">
            Cancel
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
