<div class="p-b-30">
<ng-form name="cashWithdrawal.cashWithdrawalForm">
  <div class="cash-withdrawing-section-frame">
    <div class="row">
      <div class="col-md-6">

        <label class="domp-form-label" translate="cashWithdrawalTranslate.fromAccount">Form account</label>
        <div class="accounts-dropdown">
          <div class="debit-account-widget dashboard-widget-item domp-acc-widget"
               data-ng-click="cashWithdrawal.showAccountToDropdown = !cashWithdrawal.showAccountToDropdown" ng-style="cashWithdrawal.selectedAccount.linkList.itemList.imageUrl ?{'background-image': 'url(' + cashWithdrawal.selectedAccount.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
            <h4 class="no-wrap-text bold-font m-b-0">
                <b class="col-xs-10 p-0 m-t-10  no-wrap-text">
              {{cashWithdrawal.selectedAccount.friendlyName}}</b>
                <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container">
                  <i class="zmdi"
                     ng-class="{'zmdi-star favorite-account': account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                </span>
            </h4>
            <p class="m-b-0 m-t-0 iban-style">{{cashWithdrawal.selectedAccount.iban}}</p>
            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
              {{cashWithdrawal.selectedAccount.availableBalance | number:2}}
              {{cashWithdrawal.selectedAccount.currencySymbol}}
            </p>
            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
              <span class="caret-icon">
                  <i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i>
              </span>
          </div>
          <div class="from-accounts-list m-l-15" ng-show="cashWithdrawal.showAccountToDropdown" scrollable>
            <div class="accounts-list-item p-0"
                 data-ng-repeat="account in cashWithdrawal.accountList"
                 data-ng-click="cashWithdrawal.selectAccount(account); cashWithdrawal.showAccountToDropdown = !cashWithdrawal.showAccountToDropdown">
                <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="account.linkList.itemList.imageUrl ?{'background-image': 'url(' + account.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                       <h4 class="no-wrap-text bold-font m-b-0">
            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{account.friendlyName}}</b>
                             <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                        </h4>
              <p class="m-b-0 m-t-0 iban-style">{{account.iban}}</p>
              <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                            {{account.availableBalance | number:2}} {{account.currencySymbol}}
                                        </p>
              <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
            </div>
          </div>
        </div>
      </div>
        </div>
      <!-- START Amount and Date -->
      <div class="col-md-6">
        <!-- START Amount -->
        <div class="form-group">
          <label class="domp-form-label" translate="cashWithdrawalTranslate.amount">Amount</label>
          <div class="input-group" ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">
            <input id="amount" name="amount" type="text" class="form-control input-field t-a-r"
                   ng-switch-default
                   amount-input-mask
                   placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                   ng-model="cashWithdrawal.model.amount"
                   ng-class="{'domp-error': cashWithdrawal.cashWithdrawalForm.amount.$invalid && cashWithdrawal.formSubmitted}"
                   maxlength="{{cashWithdrawal.amountInputSettings.maxLength}}"
                   min="{{cashWithdrawal.minAmount}}"
                   autocomplete="off"
                   required>
            <input id="amount" name="amount" type="text" class="form-control input-field t-a-r"
                   ng-switch-when="sl"
                   amount-input-mask
                   placeholder="{{'domesticPayment.amountPlaceholder'| translate}}"
                   ng-model="cashWithdrawal.model.amount"
                   ng-class="{'domp-error': cashWithdrawal.cashWithdrawalForm.amount.$invalid && cashWithdrawal.formSubmitted}"
                   maxlength="{{cashWithdrawal.amountInputSettings.maxLength}}"
                   max-amount="{{cashWithdrawal.amountInputSettings.maxAmount}}"
                   min="{{cashWithdrawal.minAmount}}"
                   autocomplete="off"
                   required>
              <span class="input-group-btn">
                <button type="button"
                        class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" style="border:1px solid #062a42;">
                  {{cashWithdrawal.model.currencySymbol}}
                </button>
              </span>
          </div>
          <label class="c-red"
                 ng-show="cashWithdrawal.cashWithdrawalForm.amount.$error.required && cashWithdrawal.formSubmitted"
                 translate="cashWithdrawalTranslate.amountRequired">
          </label>
          <span class="c-red" ng-show="cashWithdrawal.cashWithdrawalForm.amount.$error.maxAmount" >
          <span translate="internalTransfer.amountLengthError"></span> {{cashWithdrawal.model.currencySymbol}}</span>

          <label class="c-red"
                 ng-show="!cashWithdrawal.cashWithdrawalForm.amount.$error.required && cashWithdrawal.cashWithdrawalForm.amount.$error.min && cashWithdrawal.formSubmitted">
            {{"cashWithdrawalTranslate.amountMinAmountError" | translate}} {{cashWithdrawal.minAmount | currency:""}} {{cashWithdrawal.model.currencySymbol}}
          </label>
        </div>
        <!-- END Amount-->
        <!-- START Date-->
        <div class="row">
          <div class="col-xs-12" ng-class="{'col-sm-6': cashWithdrawal.countryCode == 'sl'}">
            <label class="domp-form-label" translate="cashWithdrawalTranslate.dateOfPayout">Date of payout</label>
            <div class="input-group" ng-class="{'ui-datepicker-today-hide': APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'}">
              <input type="text" class="form-control input-field" name="date"
                     ng-class="{'domp-error': cashWithdrawal.cashWithdrawalForm.date.$invalid && cashWithdrawal.formSubmitted}"
                     uib-datepicker-popup="shortDate" show-weeks="false"
                     ng-model="cashWithdrawal.model.withdrawalDate"
                     show-weeks="false" is-open="datePickerOpen"
                     current-text="{{'core.period.today' | translate}}"
                     clear-text="{{'core.datePicker.clearBtn' | translate}}"
                     close-text="{{'core.datePicker.doneBtn' | translate}}"
                     datepicker-options="cashWithdrawal.datePickerOptions"
                     date-disabled="cashWithdrawal.dateDisabled({date: date, mode: mode})"
                     required
                     readonly>
              <span class="input-group-btn">
                  <button data-ng-click="datePickerOpen = !datePickerOpen" type="button"
                          class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                    <i class="icon icon-calendar" style="font-size: 21px;"></i>
                  </button>
                </span>
            </div>
          </div>
          <div ng-if="cashWithdrawal.countryCode == 'sl'" class="col-xs-12 col-sm-6">
            <label class="domp-form-label" translate="cashWithdrawalTranslate.timeOfPayout">Time of payout</label>
            <select name="withdrawalTime"
                    class="form-control input-field p-l-15 p-r-10"
                    ng-options="option.id as option.value | translate for option in cashWithdrawal.withdrawalTimes"
                    ng-model="cashWithdrawal.withdrawalTimeId"
                    ng-change="cashWithdrawal.onTimeOfPayoutChange()"
                    required>
            </select>
          </div>

        </div>
        <!-- END Date-->
      </div>

    </div>
  </div>
  <div class="cash-withdrawing-section-frame">
    <div class="row">
      <div class="col-md-6" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
        <div class="form-group">
          <label class="domp-form-label" translate="cashWithdrawalTranslate.phoneNumber">Phone number</label>
          <input name="branch"
                 class="form-control input-field"
                 ng-pattern-restrict="{{cashWithdrawal.regexPatterns.phoneValidation}}"
                 ng-model="cashWithdrawal.model.phoneNumber">
          </input>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label class="domp-form-label" translate="cashWithdrawalTranslate.placeOfCashTakeover">
            Place of cash takeover
          </label>
          <select name="branch"
                  class="form-control input-field p-l-15 p-r-10"
                  ng-options="branch.id as branch.name for branch in cashWithdrawal.branchList"
                  ng-model="cashWithdrawal.model.branchId"
                  required>
          </select>
          <label class="c-red"
                 ng-show="cashWithdrawal.cashWithdrawalForm.branch.$error.required && cashWithdrawal.formSubmitted"
                 translate="cashWithdrawalTranslate.placeOfCashTakeoverRequiredMsg">
          </label>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div ng-switch="APP_GLOBAL_SETTINGS.defaultData.APILocation">

            <div ng-switch-when="sl">
              <label class="domp-form-label"
                     translate="cashWithdrawalTranslate.note_SLO"></label>
              <textarea name="note" class="form-control cash-withdrawing-note"
                        ng-pattern-restrict="{{cashWithdrawal.regexPatterns.allowedCharsDPS}}"
                        maxlength="200"
                        ng-model="cashWithdrawal.model.withdrawalNotes"></textarea>
            </div>
              <div ng-switch-default>
              <label class="domp-form-label"
                     translate="cashWithdrawalTranslate.note" >Note</label>



            <textarea name="note" class="form-control cash-withdrawing-note"
                      ng-pattern-restrict="{{cashWithdrawal.regexPatterns.allowedCharsDPS}}"
                      maxlength="{{cashWithdrawal.maxlength}}"
                      ng-model="cashWithdrawal.model.note"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="row p-b-30">
    <div class="col-md-12">
      <button class="btn btn-primary app-btn-blue pull-right domp-review-and-submit-btn pull-left"
              ng-click="cashWithdrawal.cancelCashWithdrawing()">
        <span translate="cashWithdrawalTranslate.cancelBtnLabel">Cancel</span>
      </button>
      <button class="btn btn-default  pull-right "
              ng-click="cashWithdrawal.confirmCashWithdrawing()"
              ng-disabled="cashWithdrawal.confirmCashWithdrawalBtnDisabled">
        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" translate="cashWithdrawalTranslate.confirmBtnLabel">Confirm</span>
        <span ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl'" translate="domesticPayment.confirmBtnSlo">Next</span>
      </button>
    </div>
  </div>
</ng-form>
