(function () {
    'use strict';

    angular
        .module('navigation')
        .config(config);

    function config($translateProvider) {

        var data = {
            messages: {
                "createBtn": "Create new message",
                "receivedTab": "Received",
                "receivedPersonalTab": "Received personal",
                "receivedGlobalTab": "Received global",
                "sentTab": "Sent",
                "unreadLabel": "Unread messages",
                "globalLabel": "Global messages",
                "readLabel": "Read messages",
                "replyBtn": "Reply",
                "attachmentList": "Attachments list:",
                "printBtn": "Print",
                "deleteBtn": "Delete",
                "loadMore":"Load more",
                "noData": "No messages for selected period",
                "downloadAttachment": "Download attachment",
                "paymentCode": "Payment code:",
                "orderCode":"Order code:"
            },
            "newMessage": {
                "titleLabel": "New message",
                "titleReplyLabel": "Reply message",
                "subjectLabel": "Subject",
                "subjectPlaceholder": "Enter message subject here",
                "dragAndDrop": "Drag & drop files here...",
                "bodyLabel": "Message",
                "header": "Messages",
                "backBtn": "Back",
                "sendBtn": "Send",
                "uploadButton": "Click to upload",
                "successLabel1": "Message sent ",
                "successLabel2": "Your message ",
                "successLabel3": " has been sent successfully.",
                "newLabel": "Create new message",
                "goToMsgsLabel": "Go to messages"
            }
        };

        $translateProvider
            .translations('en', data);

    }
}());
