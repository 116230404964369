(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name messages.controller:NewMessageCtrl
   *
   * @description
   *
   */
  angular
    .module('messages')
    .controller('NewMessageCtrl', NewMessageCtrl);

  function NewMessageCtrl($scope, $rootScope, MessagesService, ValidationService, $log, $timeout, $state, HelperService, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'NewMessageCtrl';
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.isReplyMessage = false;
    vm.inputOptions = {
      maxLength: ''
    };

    vm.dummyInput = '';

    vm.files = [];
    vm.fileObject = [];

    vm.addFilesByClick = function (event) {
      var f = document.getElementById('multi-file-upload-input').files;
      var files = [];
      for (var index = 0; index < f.length; index++) {
        var sFile = f[index];
        files.push({
          data: null,
          file: sFile,
          name: sFile.name,
          type: sFile.type,
          size: sFile.size
        });
        vm.fileObject.push(sFile);
      }
      return $scope.$apply(function() {
        vm.files =  vm.files.concat(files);
      })
    }

    vm.openFileUploadUI = function() {
        var tempElement = document.getElementById('multi-file-upload-input');
        if (tempElement) {
            tempElement.click();
        }
    }

    if (vm.countryCode != 'hr') { //only BH, SR, MNE have maxlength , SLO dont have subject
      vm.inputOptions.maxLength = 60;
    }

    vm.init = function () {
      vm.regex = ValidationService.getRegexPatternsForValidation();

      if ($state.params.messageId) {
        MessagesService.getById($state.params.messageId)
          .then(function (response) {
            $log.debug("messageByID", response);

            vm.sendMessageObject.subject = "RE: " + response.subject;
            vm.sendMessageObject.body = HelperService.formatResponseBody(response.body, true);

            vm.isReplyMessage = true;
            $timeout(function () {
              setCaretToPos(document.getElementById("msgBody"), 0);
            });
          })
          .catch(function (error) {
            $log.error(error);
            NotificationService.showMessage(error, 'error');
          });

      }
    };

    vm.showSuccessSection = false;

    vm.sendMessageObject = {
      subject: "",
      body: "",
    };

    vm.resetSendMessageObject = function () {
      vm.sendMessageObject.subject = "";
      vm.sendMessageObject.body = "";
    };

    vm.removeAttachment = function (index) {
        var dt = new DataTransfer()

        for (var i = 0; i < vm.fileObject.length; i++) {
            var file = vm.fileObject[i]
            if (index !== i) {
              dt.items.add(file) // here you exclude the file. thus removing it.
            }
        }

        vm.fileObject = dt.files // Assign the updates list
        vm.files.splice(index,1);

    };


    vm.successfulMessageSubject = "";


    vm.sendMessage = function (sendMessageForm) {
      var formData = new FormData();

      if (vm.sendButtonPressed) return;
      if (sendMessageForm.$invalid) return;
      vm.sendButtonPressed = true;

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
        if (vm.fileObject) {
          for (var i = 0; i < vm.fileObject.length; i++) {
            var file = vm.fileObject[i]
            formData.append('file', file);
          }
        }

        formData.append('body', vm.sendMessageObject.body);
        formData.append('subject', vm.sendMessageObject.subject);

        MessagesService.sendMessageWithAttachments(formData)
        .then(function (response) {
          $log.debug("message send response", response);
          vm.showSuccessSection = true;
          vm.resetSendMessageObject();
          vm.successfulMessageSubject = response.subject;
          GoogleTagManagerHelperService.pushEvent('Messages', 'MessageSent', 'Message sent successfully!');
        })
        .catch(function (error) {
          $log.error(error);
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.sendButtonPressed = true;
        });
      } else {
        MessagesService.sendMessage(vm.sendMessageObject)
        .then(function (response) {
          $log.debug("message send response", response);
          vm.showSuccessSection = true;
          vm.resetSendMessageObject();
          vm.successfulMessageSubject = response.subject;
          GoogleTagManagerHelperService.pushEvent('Messages', 'MessageSent', 'Message sent successfully!');
        })
        .catch(function (error) {
          $log.error(error);
          NotificationService.showMessage(error, 'error');
        })
        .finally(function () {
          vm.sendButtonPressed = true;
        });
      }




    };

    vm.createNewMessage = function () {
      vm.showSuccessSection = false;
      vm.isReplyMessage = false;
      $state.go("newMessage", {}, {reload: true})
    }

    function setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
      } else if (input.createTextRange) {
        var range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
      }
    }

    function setCaretToPos(input, pos) {
      setSelectionRange(input, pos, pos);
    }

    /*function formatResponseBody(body) {
     var replyText ="\n\n----------------------------------------------------------------------------------------------------------------------------";
     body = body ? '\n' + String(body).replace(/<[^>]+>/gm, '') : '';
     return replyText + body;
     }*/

    vm.init();
  }
}());
