(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name core.factory:ImagesService
   *
   * @description
   *
   */
  angular
    .module('core')
    .factory('ImagesService', ImagesService);

  function ImagesService(EndpointsService, $q, $http, $log, ConfigService) {
    var ImagesServiceBase = {};

    var rootUrl = ConfigService.url + 'images/';

    ImagesServiceBase.getImages = function (params) {
      params.pageSize = 100;
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.images) {
          $http.get(response.links.images,
            { params: params})
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };


    return ImagesServiceBase;
  }
}());
