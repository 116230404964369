(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:DomesticPaymentSrbCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('DomesticPaymentSrbCtrl', DomesticPaymentSrbCtrl);

  function DomesticPaymentSrbCtrl($rootScope, $location, CTLocalizationService, UserSettingsService, $state, $log, HelperService,
                                  ValidationService, PurposeCodesService, BanksService, CountriesService,
                                  PaymentTypesService, PaymentsService, CreditAccountRegisterService, $q,
                                  PaymentTemplatesService, ConfigEndpointService, $timeout, ReferenceModelService,
                                  $stateParams, PaymentFilterService, Notification, AlertService, ImagesService, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;
    vm.ctrlName = 'DomesticPaymentSrbCtrl';
    vm.images = {};
    vm.isRetrieval = false;
    if ($stateParams.myParam == 'retrieval') {
      vm.isRetrieval = true;
    }

    vm.currentStep = 1;

    vm.paymentObject = {};
    vm.tempDompObj = {
      fromAccountSelect: {},
      purposeCodeSelect: {},
      purposeOfPaymentInput: '',
      amountInput: '',
      dateInput: new Date(),
      bicCodeInput: '',
      toAccountInput: '',
      referenceCodeInput: '',
      referenceModelSelect: {},
      referenceNumberInput: '',
      payersNameInput: '',
      payersAddressInput: '',
      payersCityInput: '',
      payersSepaInput1: '',
      payersSepaInput2: '',
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      receiversCountrySelect: {},
      receiversSepaInput1: '',
      receiversSepaInput2: '',
      isUrgentPaymentCheckbox: false,
      formSubmitted: false
    };

    vm.split = function (input) {
      return HelperService.splitReferenceNumber(input);
    }

    vm.formSubmittedFlag = false;
    vm.defaultCurrency = '';
    vm.forUpdate = false;
    vm.smsAuthentificate = false;
    vm.smsPasswordInput = '';
    vm.bankCharges = '';

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    /*vm.disabled = function (date, mode) {
        if(vm.countryCode != 'sr')
            return (mode === 'day' && (date.getDay() === 0));
        return;
    };*/

    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000
    };

    vm.banksList = {};
    vm.banksFilter = {
      pageSize: 1000
    };

    vm.countriesCodeList = {};
    vm.countriesCodeFilter = {
      pageSize: 1000
    };

    vm.referenceModelList = [];
    vm.referenceModelFilter = {
      pageSize: 1000
    };

    vm.payeeFilter = {
      paymentTypeGroup: 'DomesticPayment',
      orderBy: 'receiverName ASC',
      page: 0,
      pageSize: 1000
    };

    vm.templatesFilter = {
      paymentTypeGroup: 'DomesticPayment',
      page: 0,
      pageSize: 1000
    };

    vm.templateList = {};

    vm.tmpSearchText = {
      name: ''
    };

    vm.templateName = '';

    vm.fromAccountDropdown = false;
    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.searchFilterExpanded = false;

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment'
    };

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();

    vm.checkModul97Reference = function (reference) {
      var isValid = ValidationService.checkModul97Reference(reference);
      vm.domesticPaymentForm.referenceNumberInput.$setValidity('modul97reference', isValid)
    };

    vm.checkForUNallowedChars = function (reference) {
      var returnValue = ValidationService.DontAllowCharsToRepeat(reference,2);

      returnValue = ValidationService.DontAllowCharsToRepeatNumeric(returnValue,24);

      vm.tempDompObj.referenceNumberInput = returnValue;
    };

    vm.getPaymentTypeGroup = function () {
      return PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);
          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.getPurposeCodesPage = function () {
      return PurposeCodesService.getPage(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            value.optionValue = value.id + ' - ' + value.description;
            vm.tempDompObj.purposeOfPaymentInput = value.description
          });
          vm.purposeCodesList = response;
          /*if (vm.purposeCodesList.result.length > 0) {
  vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
}
*/
          if (vm.purposeCodesList.result.length > 0) {
            var index = _.findIndex(vm.purposeCodesList.result, function (item) {
              return item.id === '89';
            });
            console.log("Ovdje", index)
            vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[index];
            vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[index].description;
          }
          $log.debug("purposeCodesList", vm.purposeCodesList);
        })
        .catch(function () {
          $log.catch('Error loading purpose codes!');
        });
    };

    vm.getBanksPage = function () {
      return BanksService.getPage(vm.banksFilter)
        .then(function (response) {
          vm.banksList = response;
          $log.debug("banksList", vm.banksList);
        })
        .catch(function () {
          $log.catch('Error loading banks codes!');
        });
    };

    vm.changePurpose = function (purposeChange) {
      // Check if input is coming from purpose code list
      var purpose = _.find(vm.purposeCodesList.result, {
        description: vm.tempDompObj.purposeOfPaymentInput
      });
      if (purpose) {
        vm.tempDompObj.purposeOfPaymentInput = purposeChange.description;
      }
      // var purposeCode = _.findIndex(vm.purposeCodesList.result, function (item) {
      //     vm.tempDompObj.purposeOfPaymentInput = item.description;
      //     return item.id == code.id;
      // });
    };

    vm.getCountriesPage = function () {
      return CountriesService.getPage(vm.countriesCodeFilter)
        .then(function (response) {
          vm.countriesCodeList = response;
          var countryIndex = _.findIndex(vm.countriesCodeList.result, function (o) {
            return o.code == '191';
          });
          vm.tempDompObj.receiversCountrySelect = vm.countriesCodeList.result[countryIndex];

          $log.debug("countriesCodeList", vm.countriesCodeList);
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };
    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.catch(error);
        });
    };

    var createPaymentObject = function () {
      var paymentObject = {};
      paymentObject.domesticPayment = {};
      paymentObject.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      paymentObject.fromAccountNumber = vm.tempDompObj.fromAccountSelect.iban;
      paymentObject.toAccountNumber = vm.tempDompObj.toAccountInput.replace(/-/g, '');
      paymentObject.currencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
      paymentObject.purpose = vm.tempDompObj.purposeOfPaymentInput;
      paymentObject.dueDate = vm.tempDompObj.dateInput;
      paymentObject.amount = vm.tempDompObj.amountInput;
      paymentObject.paymentTypeGroup = 'DomesticPayment';
      paymentObject.domesticPayment.receiverName = vm.tempDompObj.receiversNameInput;
      paymentObject.domesticPayment.receiverAddress1 = vm.tempDompObj.receiversAddressInput;
      paymentObject.domesticPayment.receiverCity = vm.tempDompObj.receiversPostalCodeAndCityInput;
      paymentObject.domesticPayment.referenceFrom = "";
      paymentObject.domesticPayment.urgency = vm.tempDompObj.isUrgentPaymentCheckbox ? '1' : '0';

      // if (vm.tempDompObj.referenceModelSelect) {
      //     if (vm.tempDompObj.referenceModelSelect === "  ") {
      //         paymentObject.domesticPayment.referenceTo = '00' + vm.tempDompObj.referenceNumberInput;
      //     } else
      //         paymentObject.domesticPayment.referenceTo =
      //         //vm.tempDompObj.referenceModelSelect + ' ' + vm.tempDompObj.referenceNumberInput;
      //         vm.tempDompObj.referenceModelSelect.concat(vm.tempDompObj.referenceNumberInput);
      // } else if ((!vm.tempDompObj.referenceModelSelect) && (vm.tempDompObj.referenceNumberInput)) {
      //     vm.tempDompObj.referenceModelSelect = '00';
      //     /** paymentObject.domesticPayment.referenceTo = vm.tempDompObj.referenceModelSelect + ' ' + vm.tempDompObj.referenceNumberInput;**/
      //     vm.tempDompObj.referenceModelSelect.concat(vm.tempDompObj.referenceNumberInput);
      // } else {
      //     paymentObject.domesticPayment.referenceTo = '00';
      // }
      if (!vm.tempDompObj.referenceModelSelect && !vm.tempDompObj.referenceNumberInput) {
        paymentObject.domesticPayment.referenceTo = '00';
      } else if (!vm.tempDompObj.referenceModelSelect && vm.tempDompObj.referenceNumberInput) {
        paymentObject.domesticPayment.referenceTo = '00' + vm.tempDompObj.referenceNumberInput;
      } else
        paymentObject.domesticPayment.referenceTo = "" + vm.tempDompObj.referenceModelSelect + vm.tempDompObj.referenceNumberInput;

      ///zbog povrata
      if (vm.isRetrieval) {
        paymentObject.purposeCodeDPS = '9' + vm.tempDompObj.purposeCodeSelect.id;
      } else {
        paymentObject.purposeCodeDPS = '2' + vm.tempDompObj.purposeCodeSelect.id;
      }
      return paymentObject;
    };

    vm.insertedPaymentObject = {};
    vm.insertedPaymentObjectDomp = {};

    var postPaymentAction = function (response) {
      vm.insertedPaymentObject = response;
      vm.insertedPaymentObject.paymentTypeGroup = "DomesticPayment";
      vm.insertedPaymentObject.senderInfo = {
        senderName: vm.tempDompObj.fromAccountSelect.ownerName,
        senderAddress1: vm.tempDompObj.fromAccountSelect.ownerAddress,
        senderCity: vm.tempDompObj.fromAccountSelect.ownerCity
      };
      vm.insertedPaymentObject.debtorName = vm.tempDompObj.payersSepaInput1;
      vm.insertedPaymentObject.debtorName2 = vm.tempDompObj.payersSepaInput2;
      vm.insertedPaymentObject.creditorName = vm.tempDompObj.receiversSepaInput1;
      vm.insertedPaymentObject.creditorName2 = vm.tempDompObj.receiversSepaInput2;
      vm.insertedPaymentObject.purposeCodeValue = vm.tempDompObj.purposeCodeSelect;

      vm.insertedPaymentObject.receiversNameInput = vm.tempDompObj.receiversNameInput;
      vm.insertedPaymentObjectDomp.referenceTo = vm.insertedPaymentObject.referenceTo;
      if (vm.insertedPaymentObject.referenceTo) {
        var model = vm.insertedPaymentObject.referenceTo.substring(0, 2);
        var number = vm.insertedPaymentObject.referenceTo.substring(2);
        if (model === "00") {
          vm.insertedPaymentObject.referenceTo = number;
        }
      }
      Notification.clearAll();
      vm.currentStep = 2;

      HelperService.scrollToTop();

    };

    vm.submitPayment = function () {
      vm.tempDompObj.formSubmitted = true;
      if (!vm.domesticPaymentForm.$valid) {
        return;
      }

      if (!vm.formSubmittedFlag) {
        vm.formSubmittedFlag = true;
        var objectForInsert = createPaymentObject();
        if (vm.forUpdate) {
          objectForInsert.id = vm.insertedPaymentObject.id;
          PaymentsService.updatePayment(vm.insertedPaymentObject.id, objectForInsert)
            .then(function (response) {
              postPaymentAction(response);
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;
            })
            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.forUpdate = false;
              vm.formSubmittedFlag = false;
              // vm.domesticPaymentForm.$valid = false;
            })
            .finally(function () {

            });
        }
        else {
          PaymentsService.insertPayment(objectForInsert)
            .then(function (response) {
              postPaymentAction(response);
              vm.formSubmittedFlag = false;
              GoogleTagManagerHelperService.pushVirtualPageView('payments/domestic-payment/toconfirm', 'domestic-payment toconfirm', $location.host() + 'payments/domestic-payment/toconfirm');
            })

            .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.formSubmittedFlag = false;
              // vm.domesticPaymentForm.$valid = false;
            })
            .finally(function () {

            });
        }
      }
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePayment = function () {
      //swal({
      //  title: sweetAlertOptions.areYouSure,
      //  type: "info",
      //  showCancelButton: true,
      //  confirmButtonClass: "btn-default",
      //  confirmButtonText: sweetAlertOptions.yes,
      //  cancelButtonText: sweetAlertOptions.no,
      //  closeOnConfirm: true,
      //  closeOnCancel: true
      //}, function (isConfirm) {
      //  if (isConfirm) {
      //    PaymentsService.deletePayment(vm.insertedPaymentObject.id)
      //      .then(function (response) {
      //      HelperService.deleteInformationMsg(['core.paymentOrder', 'core.successDelete'], vm.insertedPaymentObject.id);
      //      $state.go('payments.paymentsOverview');
      //    })
      //      .catch(function (error) {
      //      NotificationService.showMessage(error, 'error');
      //    });
      //  }
      //});

      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deletePayment(vm.insertedPaymentObject.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({
                  paymentName: vm.insertedPaymentObject.id,
                  type: 'payment'
                })
                  .then(function () {
                    $state.go('payments.paymentsOverview');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    var mapWithPaymentResponse = function () {
      vm.tempDompObj.amountInput = vm.insertedPaymentObject.amount
      vm.tempDompObj.receiversNameInput = vm.insertedPaymentObject.receiverName;
      vm.tempDompObj.receiversAddressInput = vm.insertedPaymentObject.receiverAddress1;
      vm.tempDompObj.receiversPostalCodeAndCityInput = vm.insertedPaymentObject.receiverCity;
      vm.tempDompObj.toAccountInput = vm.insertedPaymentObject.toAccountNumber;
      vm.tempDompObj.dateInput = new Date(vm.insertedPaymentObject.dueDate);
      vm.tempDompObj.purposeOfPaymentInput = vm.insertedPaymentObject.purpose;

      var accountIndex = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == vm.insertedPaymentObject.accountId;
      });

      var purposeCodeIndex = _.findIndex(vm.purposeCodesList.result, function (item) {
        return item.id == vm.insertedPaymentObject.purposeCodeDPS;
      });

      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[accountIndex];
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[purposeCodeIndex];
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.currentStep = 1;

      if (vm.tempDompObj.referenceModelSelect === "00") {
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      } else {
        var index = _.findIndex(vm.referenceModelList, function (item) {
          return item == vm.tempDompObj.referenceModelSelect;
        });
        vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
      }

      // mapWithPaymentResponse();
    };

    vm.signPayment = function () {
      PaymentsService.signPayment(vm.insertedPaymentObject.id)
        .then(function (response) {
          vm.currentStep = 3;
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var loadAccountData = function (accountId) {
      var index = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[index];
    };

    //var loadPaymentDataFromTemplate = function (templateId) {
    //  if (templateId) {
    //    PaymentTemplatesService.getById(templateId)
    //      .then(function (response) {
    //        vm.tempDompObj.amountInput = $filter('number')(response.amount, 2);
    //        vm.tempDompObj.toAccountInput = response.toAccountNumber;
    //        vm.tempDompObj.purposeOfPaymentInput = response.purpose;
    //        vm.tempDompObj.receiversNameInput = response.receiverName || '';
    //        vm.tempDompObj.receiversAddressInput = response.receiverAddress1 || '';
    //        vm.tempDompObj.receiversPostalCodeAndCityInput = response.receiverCity || '';
    //        loadAccountData(response.accountId);
    //      })
    //      .catch(function (error) {
    //        NotificationService.showMessage(error, 'error');
    //      });
    //  }
    //};

    //////////////////////////CUSTOM SELECT//////////////////////
    vm.searchFilterExpanded = false;

    vm.toggleFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchFilterExpanded = !vm.searchFilterExpanded;
      }
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.getPayees = function () {
      PaymentTemplatesService.getPage(vm.payeeFilter)
        .then(function (response) {
          vm.payeeList = response.result;
        }).catch(function (error) {
        $log.catch(error);
      });
    };

    vm.selectPayee = function (id) {
      var index = _.findIndex(vm.payeeList, function (item) {
        vm.tempDompObj.toAccountInput = item.toAccountNumber;
        vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
        vm.tempDompObj.receiversNameInput = item.receiverName;
        vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
        return item.id == id;
      });

      vm.formatAccountNumber(vm.payeeList[index].toAccountNumber);
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.removeSearch = function () {
      vm.tempDompObj.receiversNameInput = '';
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1 ||
        angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1);
    };

    ///select template component

    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;

      if (vm.searchTemplateFilterExpanded === true) {

        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      if (!vm.isRetrieval) {
        vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
        if (vm.searchTemplateFilterExpanded && (!vm.templateList.result || vm.templateList.result.length === 0)) {
          vm.getPaymentTemplatesPage();
        }
      }
    };

    vm.getPaymentTemplatesPage = function () {
      PaymentTemplatesService.getPage(vm.templatesFilter)
        .then(function (response) {
          vm.templateList = response;
          if (vm.templateList)
            populateTemplatesWithImages();
          $log.debug(vm.templateList);
        }).catch(function (error) {
        $log.catch(error);
      });
    };
    vm.selectedTemplate = {};
    vm.selectTemplate = function (id) {
      GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentTemplateSelection', 'Template with id ' + id + ' selected');
      var item = _.find(vm.payeeList, {
        id: id
      });
      vm.templateName = item.name;
      vm.tempDompObj.toAccountInput = item.toAccountNumber;
      vm.formatAccountNumber(item.toAccountNumber);
      vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
      //vm.tempDompObj.amountInput = $filter('number')(item.amount, 2);

      if (item.referenceTo) {
        if (item.referenceTo.length > 2) {
          vm.tempDompObj.referenceModelSelect = item.referenceTo.substring(0, 2);
          vm.tempDompObj.referenceNumberInput = item.referenceTo.substring(2);
        } else {
          vm.tempDompObj.referenceModelSelect = item.referenceTo;
          vm.tempDompObj.referenceNumberInput = "";
        }
      }
      if (item.purposeCodeDPS && vm.purposeCodesList.result) {

        // Remove leading character from string
        var leading_char = item.purposeCodeDPS.substring(0, 1);
        if (item.purposeCodeDPS.length > 2 && leading_char === '2' || leading_char === '9') {
          item.purposeCodeDPS = item.purposeCodeDPS.substring(1);
        }

        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          return purpose.id == item.purposeCodeDPS;
        });
        if (purpose) {
          vm.tempDompObj.purposeCodeSelect = purpose;
          vm.tempDompObj.purposeOfPaymentInput = item.purpose || purpose.description;
        }
      }

      vm.tempDompObj.receiversNameInput = item.receiverName;
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
      vm.searchFilterExpanded = false;
      vm.searchTemplateFilterExpanded = false;

      vm.selectedTemplate.imageId = item.imageId;
      setImage(vm.selectedTemplate);
    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    }
    vm.removeTemplate = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
      vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
      vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      vm.tempDompObj.referenceNumberInput = '';
      vm.selectedTemplate = {};

    };

    //from account component
    vm.toggleFromAccount = function () {
      if (!vm.isRetrieval) {
        vm.fromAccountDropdown = !vm.fromAccountDropdown;
      }
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
    };

    vm.showSMSAuthentification = function () {
      vm.smsAuthentificate = !vm.smsAuthentificate;
    };

    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.getReferenceModels = function () {
      return ReferenceModelService.getPage(vm.referenceModelFilter)
        .then(function (response) {
          vm.referenceModelList.push(""); //First item in refModelList is empty string
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
          vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.formatAccountNumber = function (accountNumber) {
      if (!accountNumber) return;
      var accountNumber = accountNumber.replace(/-/g, ""); // remove dashes
      if (accountNumber.length < 6) {
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", false);
        return;
      } else {
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", true);
      }
      var str1 = accountNumber.substring(0, 3);
      var str2 = ("0000000000000" + accountNumber.substring(3, accountNumber.length - 2)).slice(-13); // if 5 returns 0000..005
      var str3 = accountNumber.substring(accountNumber.length - 2);

      vm.tempDompObj.toAccountInput = str1 + "-" + str2 + "-" + str3;
    };

    vm.fillReceiver = function (toAccountInput) {
      if (!toAccountInput) return;
      if (vm.domesticPaymentForm.toAccountInput.$valid) {
        vm.formatAccountNumber(vm.tempDompObj.toAccountInput);
        //CreditAccountRegisterService.getPage({accountNumber: toAccountInput.replace(/-/g, '')})
        CreditAccountRegisterService.getPage({
          accountNumber: vm.tempDompObj.toAccountInput.replace(/-/g, '')
        })
          .then(function (response) {

            if (response.result.length > 0) {
              var rName = response.result[0].receiverName;
              var rAddressInput = response.result[0].receiverAddress1;
              var rPostalCodeAndCityInput = response.result[0].receiverCity;
              vm.tempDompObj.receiversNameInput = rName.substring(0, 35);
              vm.tempDompObj.receiversAddressInput = rAddressInput.substring(0, 35);
              vm.tempDompObj.receiversPostalCodeAndCityInput = rPostalCodeAndCityInput.substring(0, 35);
            } else {
              vm.tempDompObj.receiversNameInput = '';
              vm.tempDompObj.receiversAddressInput = '';
              vm.tempDompObj.receiversPostalCodeAndCityInput = '';
            }
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.getDefaultDetails = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response) {
            vm.defaultCurrency = response.DefaultCurrency;
            vm.bankCharges = response.BankCharges;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.newPayment = function () {
      clearInput();
      vm.tempDompObj.formSubmitted = false;
      vm.forUpdate = false;
      vm.currentStep = 1;
    }

    var clearInput = function () {
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = '';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.dateInput = new Date();
      vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
      vm.tempDompObj.referenceNumberInput = '';

      var index = _.findIndex(vm.purposeCodesList.result, function (item) {
        return item.id === '89';
      });

      vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[index];
      vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[index].description;

      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
    }

    vm.init = function () {

      if ($state.params.editable) {
        vm.insertedPaymentObject.id = $state.params.paymentId;
        vm.forUpdate = true;
      }

      ////AKO JE POVRAT/////
      if (vm.isRetrieval) {
        var promises = [vm.getPurposeCodesPage(), vm.getReferenceModels(), vm.getDefaultDetails(), vm.getPaymentTypeGroup(), loadImages()];
        $q.all(promises)
          .then(function () {
            if (vm.purposeCodesList.result.length > 0) {
              var index = _.findIndex(vm.purposeCodesList.result, function (item) {
                return item.id === '89';
              });

              vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[index];
              vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[index].description;
            }
            vm.tempDompObj.amountInput = $stateParams.transactionDetails.amount;
            vm.tempDompObj.toAccountInput = $stateParams.transactionDetails.detail.toAccountNumber;
            vm.tempDompObj.fromAccountSelect = $stateParams.transactionDetails.account;
            vm.tempDompObj.receiversAddressInput = $stateParams.transactionDetails.detail.payeeAddress1;
            vm.tempDompObj.receiversNameInput = $stateParams.transactionDetails.detail.payeeName;

          })
      } else {
        vm.getPayees();
        // vm.getUserSettingsPage();
        // , vm.getDefaultDetails()

        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
          vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
          vm.defaultCurrency = vm.defaultData.DefaultCurrency;
          vm.bankCharges = vm.defaultData.BankCharges;
        }

        if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
          var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        }

        var promises = [vm.getPurposeCodesPage(), vm.getBanksPage(), vm.getCountriesPage(), vm.getPaymentTypeGroup(), vm.getReferenceModels(), loadImages()];
        $q.all(promises)
          .then(function () {
            //set default form params

            var params = {
              fromAccountList: vm.paymentTypeGroupDomestic.result[0].debitAccountList,
              purposeCodeList: vm.purposeCodesList.result,
              referenceModelList: vm.referenceModelList
            };

            switch ($state.current && $state.current.name) {
              case 'payments.domesticPayment.fromTemplate':
                PaymentTemplatesService.loadDomesticPaymentData($state.params.templateId, vm.tempDompObj, params)
                  .then(function () {
                    vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  })
                  .catch(function (error) {
                    $log.catch('loadDomesticPaymentData', error);
                  });
                break;
              case 'payments.domesticPayment.fromPayment':
                PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                  .then(function () {
                    vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  })
                  .catch(function (error) {
                    $log.catch('loadDomesticPaymentData()', error);
                  });
                break;
              case 'payments.domesticPayment':
                if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
                  vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
                }
                if (vm.purposeCodesList.result.length > 0) {
                  vm.tempDompObj.purposeCodeSelect = PaymentFilterService.filterPurposeCodeSelect(vm.purposeCodesList.result, '89');
                  vm.tempDompObj.purposeOfPaymentInput = vm.tempDompObj.purposeCodeSelect.description;
                }
                break;
              case 'payments.domesticPayment.fromAccount':
                if ($state.params.accountId) {
                  vm.tempDompObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, $state.params.accountId);
                }
                break;
            }

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.templateImagesFilter = {
      category: "Templates"
    };

    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        console.log(JSON.stringify(images));
        vm.images = images.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    vm.pushGtmUrgentPaymentEvent = function () {
      GoogleTagManagerHelperService.pushEvent('DomesticPayment', 'PaymentIsUrgentSelection', (vm.tempDompObj.isUrgentPaymentCheckbox) ? 'Urgent payment checked' : 'Urgent payment unchecked');
    };
    vm.init();

  }
}());
