(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:PaymentsReturnCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('PaymentsReturnCtrl', PaymentsReturnCtrl);

  function PaymentsReturnCtrl($state, $log, HelperService, PaymentsService, CurrencyService, $localStorage,
                              ConfigEndpointService, $window,$stateParams, $translate, GoogleTagManagerHelperService, NotificationService) {
    var vm = this;

    vm.init = function () {

      if($localStorage.code){
        vm.countryCode = $localStorage.code;
      }else{
        getDefaultData();
      }

      var timeRange = HelperService.getDateRange('thisMonth');

      vm.paymentsFilter = {
        page: 0,
        orderBy: 'dateCreated DESC',
        status: 'Opened',
        pageSize: 15,
        currencySymbol: null,
        amountGTE: null,
        amountLTE: null,
        dueDateGTE: timeRange.valueDateGTE,
        dueDateLTE: timeRange.valueDateLTE,
        paymentTypeGroupList: "SEPARecall"
      };

      // 1- Opened, 2 - Archived payments
      vm.paymentsTypeSelected = 1;

      vm.paymentsOverviewList = {};

      vm.periodList = [
        {
          item: {
            key: 'today',
            description: 'core.period.today'
          }
        }
        , {
          item: {
            key: 'yesterday',
            description: 'core.period.yesterday'
          }
        }
        , {
          item: {
            key: 'thisWeek',
            description: 'core.period.thisWeek'
          }
        }
        , {
          item: {
            key: 'thisMonth',
            description: 'core.period.thisMonth'
          }
        }
        , {
          item: {
            key: 'thisYear',
            description: 'core.period.thisYear'
          }
        }
        , {
          item: {
            key: 'all',
            description: 'core.period.all'
          }
        }
      ];

      vm.currencySelectObject = {};
      vm.currenciesList = [];
      vm.currenciesFilter = {
        pageSize: 1000
      };

      vm.selectedPeriod = vm.periodList[3];
      vm.searchFilterExpanded = false;

      vm.getCurrenciesListPage();
      vm.getPaymentsPage(true);
    };

    vm.getDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response.APILocation) {
            $localStorage.code = response.APILocation;
            vm.countryCode = $localStorage.code;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.getPaymentsPage = function (reset) {

      if (reset) {
        vm.paymentsFilter.page = 0;
        vm.paymentsOverviewList = {};
      } else {
        vm.paymentsFilter.page += 1;
      }

      PaymentsService.getPage(vm.paymentsFilter)
        .then(function (response) {
          if (reset) {
            vm.paymentsOverviewList = response;
          } else {
            var tmpList = {};
            tmpList.hasMore = response.hasMore;
            tmpList.result = vm.paymentsOverviewList.result.concat(response.result);
            vm.paymentsOverviewList = tmpList;
          }
          $log.debug("paymentsOverviewList", vm.paymentsOverviewList);
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    vm.getCurrenciesListPage = function () {
      return CurrencyService.getCurrencyPage(vm.currenciesFilter)
        .then(function (response) {
          vm.currenciesList = response.result;

          var currencyIndex = _.findIndex(vm.currenciesList, function (o) {
            if (vm.countryCode == 'hr') {
              return o.symbol == 'EUR';
            }
          });
          vm.currencySelectObject = vm.currenciesList[currencyIndex];
          vm.defaultCurrencySelectObject = vm.currenciesList[currencyIndex];

          //$log.debug("currenciesList", vm.currenciesList);
        }).catch(function (error) {
        $log.error(error);
      });
    };

    vm.loadMorePayments = function () {
      vm.getPaymentsPage(false);
    };

    vm.pushGtmApproveRecallEvent = function () {
        GoogleTagManagerHelperService.pushEvent('PaymentsReturndConfirmationDetails', 'ApproveRecallAction', $translate.instant('paymentActions.approveRecall'));
    };

    vm.rejectPaymentRecall = function(paymentId){
        GoogleTagManagerHelperService.pushEvent('PaymentsReturndConfirmationDetails', 'RejectRecallAction', $translate.instant('paymentActions.rejectRecall'));
      var params = {
        id: paymentId,
        answer: "N"
      };
      PaymentsService.recallPayment(params)
        .then(function (response) {
          vm.getPaymentsPage(true);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        })
    };

    vm.togglePaymentDetails = function (payment) {
      payment.selected = !payment.selected;
    };

    vm.paymentsPeriodFilter = function (item) {
      vm.selectedPeriod = item;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      vm.paymentsFilter.dueDateGTE = timeRange.valueDateGTE;
      vm.paymentsFilter.dueDateLTE = timeRange.valueDateLTE;
      vm.getPaymentsPage(true);
      GoogleTagManagerHelperService.pushEvent('PaymentsReturn', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));

    };

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.resetPaymentsFilter = function () {
      vm.paymentsFilter.amountGTE = null;
      vm.paymentsFilter.amountLTE = null;
      vm.paymentsFilter.currencySymbol = null;
      vm.currencySelectObject = vm.defaultCurrencySelectObject;
      // $log.debug("paymentsFilter", vm.paymentsFilter);
    };

    vm.applyPaymentsFilter = function () {
      vm.paymentsFilter.currencySymbol = vm.currencySelectObject.symbol;
      $log.debug("paymentsFilter", vm.paymentsFilter);
      vm.getPaymentsPage(true);
      vm.searchFilterExpanded = false;
    };

    vm.printPayment = function (paymentId) {
      PaymentsService.downloadPaymentPdf(paymentId);
    };

    vm.selectedTabType = "open";
    vm.selectPaymentsType = function (type, translateLabel) {
      if (type ==  vm.selectedTabType) return;
      vm.paymentsFilter.statusList = null;
      vm.paymentsFilter.status = null;
      vm.selectedTabType = type;
      vm.selectedTabType = type;
      switch (type) {
        case "open":
          vm.paymentsFilter.status = 'Opened';
          vm.paymentsTypeSelected = 1;
          break;
        case "archived":
          // All statuses except 'Opened'. API does not have other filter to exclude Opened payments
          vm.paymentsFilter.statusList = ['WaitingQueue','Cancelled', 'Processed','Rejected','Closed','Signed', 'InProcessing', 'ForCancelling', 'Storned', 'RejectedAfterComplaint', 'AcceptedAfterComplaint'];
          vm.paymentsTypeSelected = 2;
          break;
        default:
          vm.paymentsFilter.status = 'Opened';
          vm.paymentsTypeSelected = 1;

      }
      GoogleTagManagerHelperService.pushEvent('PaymentsReturn', 'Tab', $translate.instant(translateLabel));

      vm.resetPaymentsFilter();
      vm.getPaymentsPage(true);
    };

    vm.init();
  }
}());
