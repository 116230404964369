(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name core.controller:SessionExpiredCtrl
     *
     * @description
     *
     */
    angular
      .module('core')
      .directive('loadMore', LoadMoreCtrl);
  
    function LoadMoreCtrl($rootScope, CTLocalizationService, $localStorage, ConfigService, HelperService) {
      return {
        restrict: 'EA',
        scope: {
            rawContent: '=loadMore',
        },
        templateUrl: 'core/directives/load-more/load-more-directive.tpl.html',
        replace: false,
        controllerAs: 'vm',
        controller: function ($scope) {
            var vm = this;
            vm.sliceFactor= 380;
            vm.slicedContent = '';

            if (!$scope.rawContent) {
              $scope.rawContent = '';
            }

            if ($scope.rawContent.length > vm.sliceFactor) {
              vm.slicedContent = $scope.rawContent.slice(0, vm.sliceFactor);
              vm.loadedMore = false;
            } else {
              vm.loadedMore = true;
            }
        },
        link: function (scope, element, attrs) {
            /* jshint unused:false */
            /* eslint "no-unused-vars": [2, {"args": "none"}] */
        }
    };
    }
}());
