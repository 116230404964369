(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name messages.factory:MessagesService
     *
     * @description
     *
     */
    angular
        .module('messages')
        .factory('MessagesService', MessagesService);

    function MessagesService($rootScope,EndpointsService, ConfigService, $q, $http, $log, SessionService, HelperService) {
        var MessagesServiceBase = {};

        MessagesServiceBase.getPage = function (params) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.get(response.links.messages, {
                        params: params
                    })
                    .then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

        MessagesServiceBase.getById = function (messageId) {

            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                    $http.get(response.links.messages_id.replace('{id}', messageId))
                        .then(function (data) {
                            deferred.resolve(data.data);
                        }).catch(function (msg, code) {
                            deferred.reject(msg.data);
                        });
                },
                function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        MessagesServiceBase.deleteMessageById = function (messageId) {

            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                    $http.delete(response.links.messages_id.replace('{id}', messageId))
                        .then(function (data) {
                            deferred.resolve(data.data);
                        }).catch(function (msg, code) {
                            deferred.reject(msg.data);
                        });
                },
                function (error) {
                    deferred.reject(error.data);
                });
            return deferred.promise;
        };

        MessagesServiceBase.markAsRead = function (messageId) {
            var deferred = $q.defer();
            EndpointsService.getLinks().then(function (response) {
                $http.put(response.links.messages_id_markAsRead.replace("{id}", messageId))
                    .then(function (data) {
                          $rootScope.$broadcast("markAsRead");
                        deferred.resolve(data.data);
                    }).catch(function (msg, code) {
                        deferred.reject(msg.data);
                    });
            }, function (error) {
                deferred.reject(error.data);
            });
            return deferred.promise;
        };

      function insert(sendObject) {
        var insert = {};
        insert.subject = sendObject.subject;
        insert.body = sendObject.body;
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            SessionService.saveStorageObject(sendObject, 'message');
            $http.post(response.links.messages, insert)
              .then(function (data) {
                SessionService.removeStorageObject();
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      }

      MessagesServiceBase.sendMessage = function (sendObject) {
        return insert(sendObject);
      };

      MessagesServiceBase.downloadAttachment = function(attachment) {
        console.log(attachment)
        var deferred = $q.defer();
        var url = ConfigService.url + 'messages/downloadAttachmentLink/' + attachment.id;
        $http({
          method: 'GET',
          url: url
        }).then(function (response) {
            HelperService.manageDownload(response.data.url, attachment.filename, '');
            deferred.resolve();
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        return deferred.promise;
      };

      MessagesServiceBase.sendMessageWithAttachments = function (sendObject) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            SessionService.saveStorageObject(sendObject, 'message');
            $http({
                method: 'POST',
                url: response.links.messages_insertWithAttachment,
                headers: {'Content-Type': undefined},
                data: sendObject
              })
              .then(function (data) {
                SessionService.removeStorageObject();
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      MessagesServiceBase.printMessage = function (id) {
        var deferred = $q.defer();
        var nonBlockingWindow = window.open('', '_self');
        EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.messages_downloadLink_id.replace('{id}', id))
              .then(function (data) {
                data = data.data;
                if (data && data.url) {
                  // flagToMarkDownload
                  sessionStorage.setItem('downloadInProgress', 'download');
                  nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
                } else {
                  nonBlockingWindow.close();
                }
                deferred.resolve();
              }).catch(function (msg, code) {
              nonBlockingWindow.close();
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      MessagesServiceBase.downloadMessageAttachment = function (url) {
        var deferred = $q.defer();
        var nonBlockingWindow = window.open('', '_self');
        $http.get(url)
          .then(function (data) {
            data = data.data;
            if (data && data.url) {
              // flagToMarkDownload
              sessionStorage.setItem('downloadInProgress', 'download');
              nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
            } else {
              nonBlockingWindow.close();
            }
            deferred.resolve();
          }).catch(function (msg, code) {
          nonBlockingWindow.close();
          deferred.reject(msg.data);
        });
        return deferred.promise;
      };

      MessagesServiceBase.getOrderById = function (id) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          $http.get(response.links.orders_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        }, function (error) {
          deferred.reject(error.data);
        });
        return deferred.promise;
      };


        return MessagesServiceBase;
    }
}());
