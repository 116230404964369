(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      paymentTemplates: {
        "payingFrom": "Uplata sa:",
        "payingTo": "Uplata na:",
        "howMuch": "Iznos",
        "description": "Opis",
        "when": "Datum",
        "fullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "showFullPaymentForm": "Prikaži cijeli obrazac za plaćanje",
        "pay": "Plati",
        "delete": "Izbriši",
        "save": "Snimi",
        "signPayment": "Potpiši",
        "cancel": "Odustani",
        "status": "Status",
        "bankName": "Naziv banke",
        "bankAddress": "Adresa banke",
        "dueDate": "Datum dospjeća",
        "fromAccount": "Račun pošiljatelja",
        "toAccount": "Račun primatelja",
        "receiver": "Primatelj",
        "receiverAddress": "Adresa primatelja",
        "amount": "Iznos",
        "signMsg": "Provjerite unesene podatke i potpišite",
        "saveTemplate": "Novi predložak",
        "invalidDate": "Datum je nevalidan!",
        "descriptionError": "Opis je obavezan!",
        "templateName": "Naziv predloška",
        "templateIcon": "Slika predloška",
        "deleteSuccess1": "Predložok",
        "deleteSuccess2": "je uspješno izbrisan."
      },
      templatesList: {
        "header":"Pregled mojih predložaka",
        "searchTemplates": "Pretraži predloške",
        "addNewTemplate": "+ Dodaj novi predložak",
        "listOfTemplates":"Lista predložaka",
        "fromAccount":"S računa",
        "amount":"Iznos",
        "toPayee":"Primatelj",
        "accountNumber":"Broj računa",
        "payeeStreet":"Adresa primatelja",
        "payeeCity":"Grad primatelja",
        "purposeCode":"Šifra namjene",
        "referenceModel":"Model",
        "referenceNumber":"Poziv na broj",
        "addTemplateToFavorites":"Dodaj predložak u favorite",
        "edit":"Izmijeni",
        "save":"Snimi",
        "cancel": "Odustani"
      }
    };

    $translateProvider
      .translations('hr', data);
  }
}());
