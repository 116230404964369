<!-- Giro account details -->
<div class="m-b-10">
  <span class="account-header-value white-text"
    ng-bind="(accounts.selectedAccount.balance | number:2) + ' ' + (accounts.selectedAccount.currencySymbol) + ' '"></span>
  <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 ) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
    class="account-header-value-eur"
    ng-bind="'/ ' + (accounts.selectedAccount.balance2 | number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
  <hr class="account-header-line" />
  <span class="m-t-neg-15 white-text-block" translate="accounts.accCurrentBalance"></span>

  <div class="row account-header-bottom">
    <div class="col-xs-12 col-md-4">
      <span class="f-18 white-text"
        ng-bind="(accounts.selectedAccount.availableBalance|number:2) + ' ' + (accounts.selectedAccount.currencySymbol)"></span>
      <span ng-if="accounts.isNuN(accounts.selectedAccount.balance2 ) && APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'"
        class="f-16 white-text"
        ng-bind="'/ ' + (accounts.selectedAccount.availableBalance2|number:2) + ' ' + (accounts.selectedAccount.currencySymbol2)"></span>
      <span class="white-text-block" translate="accounts.accAvailableBalance"></span>
    </div>
  </div>

  <div class="row p-t-20" ng-show="accounts.isAccountDetailsOpen">

    <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.reservedAmount"
      ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
      <span class="f-20 white-text-block">
        {{accounts.selectedAccount.reservedAmount|number:2}} {{accounts.selectedAccount.currencySymbol}}
      </span>
      <span class="white-text-block" translate="accounts.accReservations"></span>
    </div>

    <div class="col-xs-12 col-md-4 p-b-20" ng-if="accounts.selectedAccount.balanceLastChangedDate">
      <span class="f-20 white-text-block">
        {{accounts.selectedAccount.balanceLastChangedDate|date:'shortDate' : '+0200'}}</span>
      <span class="white-text-block" translate="accounts.accDateLastChanged"></span>
    </div>
  </div>
</div>